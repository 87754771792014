import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { openEditAddressModal } from 'redux/address/actions';
import { openReassignMemberGroupModal } from 'redux/group/actions';
import { openUpdateMemberModal } from 'redux/member/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import PersonalDetails from './PersonalDetails.layout';
import { PersonalDetailsDispatchProps, PersonalDetailsOwnProps, PersonalDetailsStateProps } from './PersonalDetails.types';


const mapStateToProps = (state: RootState):PersonalDetailsStateProps => ({ enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation });

const mapDispatchToProps = (dispatch:Dispatch) :PersonalDetailsDispatchProps => ({
    dispatchOpenUpdateMemberModal: (initialValues) => dispatch(openUpdateMemberModal(initialValues)),

    dispatchOpenReassignMemberGroupModal: (initialData, locationPathname) => dispatch(openReassignMemberGroupModal(initialData, locationPathname)),
    dispatchOpenEditAddressModal: ({ modalType, modalTitle, addressId, initialValues, addressType }) => dispatch(openEditAddressModal({
        modalType,
        modalTitle,
        addressId,
        initialValues,
        addressType,
    })),
});


const decorate = compose<ComponentType<PersonalDetailsOwnProps>>(
    withTranslation(['common', 'member']),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(PersonalDetails);
