import { setQueryParams } from 'redux/application/actions';
import { beneficiaryReducerName } from 'redux/beneficiary/reducer';
import {
    initialiseAddEditBeneficiaryForm,
    submitAddEditBeneficiaryFormStep,
    clearAddEditBeneficiaryForm,
} from 'redux/beneficiary-add/actions';
import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { clearSupportedCurrencies, fetchSupportedCurrencies } from 'redux/currencies-supported/actions';
import { supportedCurrenciesListsPerLocationStoreKeyName, supportedCurrenciesReducerName } from 'redux/currencies-supported/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddEditBeneficiary from './AddEditBeneficiary.layout';


const mapStateToProps = (state) => ({
    clients: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    currentUserClientId: state[currentUserReducerName].userData?.clientId,
    isLoadingUpdateBeneficiary: state[beneficiaryReducerName].isLoadingUpdateBeneficiary,

    isLoadingBeneficiariesRequirements: state[beneficiaryAddReducerName].isLoadingBeneficiariesRequirements,
    isLoadingAddNewBeneficiary: state[beneficiaryAddReducerName].isLoadingAddNewBeneficiary,
    contextData: state[beneficiaryAddReducerName].contextData,
    formData: state[beneficiaryAddReducerName].formData,
    currentStep: state[beneficiaryAddReducerName].currentStep,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchInitialiseAddEditBeneficiaryForm: (ownerContext, formData) => dispatch(initialiseAddEditBeneficiaryForm(ownerContext, formData)),
    dispatchSubmitAddEditBeneficiaryFormStep: (step, formData) => dispatch(submitAddEditBeneficiaryFormStep(step, formData)),
    dispatchClearAddEditBeneficiaryForm: () => dispatch(clearAddEditBeneficiaryForm()),

    dispatchFetchSupportedCurrencies: (queryParams, locationPathname) => dispatch(fetchSupportedCurrencies(queryParams, locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: supportedCurrenciesReducerName,
        fieldName: supportedCurrenciesListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchClearSupportedCurrencies: (locationPathname) => dispatch(clearSupportedCurrencies(locationPathname)),
});


const decorate = compose(
    withTranslation('beneficiaries'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddEditBeneficiary);
