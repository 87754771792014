import styled, { css } from 'styled-components';


export const FiltersAndButtonsWrapper = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 1rem;
  
  ${({ $isLoading }) => $isLoading && css`
    pointer-events: none;        
    user-select: none;
  `};
  
  ${({ $hasButtons, $hasFilters }) => $hasButtons && $hasFilters && css`
    grid-template-columns: minmax(0, auto) minmax(0, auto);
  `};

  ${({ $hasButtons, $hasFilters }) => $hasButtons && !$hasFilters && css`
    grid-template-columns: minmax(0, 1fr);
  `};

  ${({ $hasButtons, $hasFilters }) => !$hasButtons && $hasFilters && css`
    grid-template-columns: minmax(0, 1fr);
  `};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;

  & > * {
    min-width: 200px;
    max-width: 300px;
  }
  
  .client-select {
    width: 280px;
    max-width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat( ${({ $buttonsCount }) => (`${$buttonsCount}`)}, minmax(min-content, 0));
  grid-column-gap: 10px;
  justify-content: right;
`;

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-column-gap: 1ch;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  
  .ant-checkbox {
    top: 0;
  }
  
  .anticon-info-circle {
    margin-left: 0;
  }
`;


export default {
    FiltersAndButtonsWrapper,
    FiltersWrapper,
    ButtonsWrapper,
    CheckboxWrapper,
};
