import React, { JSX } from 'react';

import Styled from './StatusTimeline.styled';
import { StatusTimelineProps } from './StatusTimeline.types';


function StatusTimeline({
    listData,
    statusItemRenderer,
    arrowRenderer,
    t,
}: StatusTimelineProps): JSX.Element {
    const statusItemsCount = listData?.items?.length || 0;
    const arrowsCount = listData?.items ? statusItemsCount - 1 : 0;

    const generateGridTemplateColumns = (itemsCount: number, arrowsCount: number) => {
        let templateColumns = '';

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < itemsCount; i++) {
            templateColumns += 'fit-content(100%)';
            if (i < arrowsCount) {
                templateColumns += ' minmax(40px, auto) ';
            }
        }

        return templateColumns;
    };

    const gridTemplateColumnsValue = generateGridTemplateColumns(statusItemsCount, arrowsCount);
    const isLastItem = (index: number, items?: any[]) => !!(items && items.length - 1 === index);

    return (
        <Styled.TimelineWrapper gridTemplateColumns={gridTemplateColumnsValue}>
            {(listData?.items || []).map((item, index) => (
                <React.Fragment key={item?.externalId}>
                    {statusItemRenderer({ item, t, isLastItem: isLastItem(index, listData?.items) })}
                    {index < arrowsCount && arrowRenderer({ itemBefore: item, itemAfter: listData.items[index + 1] })}
                </React.Fragment>
            ))}
        </Styled.TimelineWrapper>
    );
}


export default StatusTimeline;
