import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { AddEditBeneficiarySteps } from 'constants/BeneficiariesModel';

import { Form, Skeleton, Spin } from 'antd';
import dotObject from 'dot-object';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import AccountDetails from './Steps/AccountDetails';
import Address from './Steps/Address';
import BasicData from './Steps/BasicData';
import Review from './Steps/Review';
import StepsIndicator from './Steps/StepsIndicator';


function AddEditBeneficiary({
    contextData,
    formData,
    currentStep,
    isLoadingBeneficiariesRequirements,
    isLoadingAddNewBeneficiary,
    isLoadingUpdateBeneficiary,

    dispatchSubmitAddEditBeneficiaryFormStep,
    dispatchInitialiseAddEditBeneficiaryForm,
    dispatchClearAddEditBeneficiaryForm,
    dispatchClearSupportedCurrencies,
    dispatchSetQueryParams,
    dispatchFetchSupportedCurrencies,
    t,
}) {
    const [form] = Form.useForm();
    const location = useLocation(); // TODO use enhancedLocation


    const queryParams = {
        ...(location?.state?.ownerContext?.memberId > 0 ? { memberId: location?.state?.ownerContext?.memberId } : {}),
        ...(location?.state?.ownerContext?.companyId > 0 ? { companyId: location?.state?.ownerContext?.companyId } : {}),
        ...(location?.state?.ownerContext?.clientId > 0 ? { clientId: location?.state?.ownerContext?.clientId } : {}),
    };

    useEffect(() => {
        dispatchInitialiseAddEditBeneficiaryForm(location?.state?.ownerContext, location?.state?.formData);
        dispatchSetQueryParams(queryParams, location.pathname);
        dispatchFetchSupportedCurrencies(queryParams, location.pathname);

        return () => {
            dispatchClearAddEditBeneficiaryForm();
            dispatchClearSupportedCurrencies(location.pathname);
        };
    }, []);

    const pageTitle = useMemo(() => {
        if (contextData && !contextData.isEditMode) {
            return t('createScreenTitle', { ownerName: contextData.name });
        }
        if (contextData && contextData.isEditMode) {
            return t('editScreenTitle', { beneficiaryName: contextData.name });
        }
        return '';
    }, [contextData]);

    const loadingData = useMemo(() => {
        if (isLoadingBeneficiariesRequirements) {
            return { text: t('actionMessages.fetchingData'), isLoading: true };
        }

        if (isLoadingUpdateBeneficiary) {
            return { text: t('actionMessages.updatingBeneficiary'), isLoading: true };
        }

        if (isLoadingAddNewBeneficiary) {
            return { text: t('actionMessages.creatingBeneficiary'), isLoading: true };
        }

        return { text: t('actionMessages.fetchingData'), isLoading: false };
    }, [
        isLoadingBeneficiariesRequirements,
        isLoadingAddNewBeneficiary,
        isLoadingUpdateBeneficiary,
    ]);

    return (
        <Skeleton
            active
            loading={contextData === null}
        >
            <StandardPageHeading title={pageTitle} />
            <StandardPageWrapper>
                <Spin tip={loadingData.text} spinning={loadingData.isLoading}>
                    <div style={{ padding: 10, background: 'white' }}>
                        <StepsIndicator />

                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={contextData?.isEditMode ? formData : {}}
                            onFinish={(values) => {
                                const dataToSend = dotObject.object({ ...values });
                                dispatchSubmitAddEditBeneficiaryFormStep(currentStep, dataToSend);
                            }}
                        >
                            {currentStep === AddEditBeneficiarySteps.BASIC_DATA && (
                                <BasicData form={form} />
                            )}

                            {currentStep === AddEditBeneficiarySteps.ADDRESS && (
                                <Address form={form} />
                            )}

                            {currentStep === AddEditBeneficiarySteps.ACCOUNT_DETAILS && (
                                <AccountDetails form={form} />
                            )}

                            {currentStep === AddEditBeneficiarySteps.REVIEW && (
                                <Review />
                            )}

                        </Form>
                    </div>
                </Spin>
            </StandardPageWrapper>
        </Skeleton>
    );
}

AddEditBeneficiary.propTypes = {
    isLoadingBeneficiariesRequirements: PropTypes.bool,
    isLoadingAddNewBeneficiary: PropTypes.bool,
    isLoadingUpdateBeneficiary: PropTypes.bool,
    currentStep: PropTypes.string,
    dispatchSubmitAddEditBeneficiaryFormStep: PropTypes.func.isRequired,
    dispatchInitialiseAddEditBeneficiaryForm: PropTypes.func.isRequired,
    dispatchClearAddEditBeneficiaryForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    dispatchClearSupportedCurrencies: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchFetchSupportedCurrencies: PropTypes.func.isRequired,
};

export default AddEditBeneficiary;

