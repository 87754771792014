import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { createBeneficiaryDetailsTabsConfig } from 'components/pages/authorised/BeneficiaryDetailsPage/BeneficiaryDetailsPage.helpers';
import BeneficiaryInfo from 'components/pages/authorised/BeneficiaryDetailsPage/BeneficiaryInfo';
import { AccessControlProps } from 'constants/AccessControlModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';
import { isValidV4Uuid } from 'utils/validation-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';


function BeneficiaryDetailsPage({
    accessControl,

    entityDetailsData,
    enhancedCurrentLocation,

    dispatchFetchBeneficiaryDetails,
    dispatchClearBeneficiaryDetails,
    dispatchSetQueryParams,
    isLoadingDeleteBeneficiary,
    t,
}) {
    const { beneficiaryUuid } = useParams();
    const isValidUuid = isValidV4Uuid(beneficiaryUuid);
    const baseURLSegmentsCount = 2; // e.g. /(1) beneficiary /(2) BENEFICIARY_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const contextEnforcedQueryParams = { beneficiaryUuid };

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchBeneficiaryDetails,
        dispatchClearEntityDetails: dispatchClearBeneficiaryDetails,
        dispatchSetQueryParams,
    });

    const tabsConfig = useMemo(
        () => (beneficiaryUuid && entityDetailsData?.entityDetails?.client_id > 0
            ? createBeneficiaryDetailsTabsConfig({
                beneficiaryUuid,
                accessControl,
                t,
            })
            : undefined),
        [beneficiaryUuid, accessControl],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('detailsScreenTitle', { beneficiaryUuid })}
            baseURLSegmentsCount={baseURLSegmentsCount}
            tabsConfig={tabsConfig}
            isValidEntity={{ type: 'beneficiaryUuid', isValid: isValidUuid }}
            entityClientId={entityDetailsData?.entityDetails?.client_id}
        >
            <BeneficiaryInfo
                t={t}
                accessControl={accessControl}
                areDetailsReady={isDetailsPageInitialStateDefined}
                isBusy={entityDetailsData?.isLoadingDetails}
                isLoadingDeleteBeneficiary={isLoadingDeleteBeneficiary}
                beneficiaryDetails={entityDetailsData?.entityDetails}
                onRefresh={() => dispatchFetchBeneficiaryDetails({ beneficiaryUuid }, baseUrlWithoutTabsSlugs)}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                locationPathname={enhancedCurrentLocation?.pathname}
            />
        </StandardEntityDetailsWithTabs>
    );
}

BeneficiaryDetailsPage.propTypes = {
    t: PropTypes.func.isRequired,
    dispatchFetchBeneficiaryDetails: PropTypes.func.isRequired,
    dispatchClearBeneficiaryDetails: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    accessControl: AccessControlProps,
    entityDetailsData: PropTypes.shape({
        entityDetails: PropTypes.shape({}),
        isLoadingDetails: PropTypes.bool,
    }),
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
};


export default BeneficiaryDetailsPage;
