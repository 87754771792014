import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import EditClientSettingsForm from 'components/pages/authorised/EditClientSettings/EditClientSettingsForm';

import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';


function EditClientSettingsPage({ t }) {
    const location = useLocation();
    const { clientId } = useParams();

    const clientDataFromNavigation = useMemo(
        () => location?.state,
        [location],
    );

    return (
        <>
            <StandardPageHeading
                title={t('editClientSettings.screenTitle', {
                    clientId,
                    clientName: clientDataFromNavigation?.clientName,
                })}
            />

            <StandardPageWrapper>
                {location?.pathname
                    ? (
                        <EditClientSettingsForm
                            locationPathname={location.pathname}
                            clientId={Number(clientId)}
                        />
                    )
                    : (<Skeleton loading active />)}

            </StandardPageWrapper>
        </>
    );
}

EditClientSettingsPage.propTypes = { t: PropTypes.func.isRequired };

export default EditClientSettingsPage;
