import StandardTooltip from 'components/common/molecules/StandardTooltip';
import {
    currentStepNumberInAvailableSteps,
    getLastStepExceptSuccessFromAvailableSteps,
} from 'redux/multi-steps-form/epics.helpers';

import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { renderPrimaryButton, renderSecondaryButton, renderTrinaryButton } from './MultiStepFormButtons.helpers';
import Styled from './MultiStepFormButtons.styled';


function MultiStepFormButtons({
    isEditMode,
    isLoading,
    onSuccessNewLocationPathname,
    goBackPathname,
    onSuccessNewLocationLabel,
    onSuccessGoBackLabel,

    availableSteps,
    availableSubSteps,
    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,

    dispatchRequestSetMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormSubStep,
    dispatchRequestNavigation,
    t,
}) {
    const currentStepNumber = useMemo(
        () => currentStepNumberInAvailableSteps(availableSteps, currentMultiStepsFormStep),
        [currentMultiStepsFormStep, availableSteps],
    );

    const currentSubStepNumber = useMemo(
        () => {
            if (availableSubSteps && availableSubSteps?.length > 1 && currentMultiStepsFormSubStep) {
                return currentStepNumberInAvailableSteps(availableSubSteps, currentMultiStepsFormSubStep);
            }
            return undefined;
        },
        [currentMultiStepsFormSubStep, availableSubSteps],
    );

    const lastStepExceptSuccess = useMemo(
        () => getLastStepExceptSuccessFromAvailableSteps(availableSteps),
        [availableSteps],
    );

    return (
        <Styled.MultiStepFormButtonsWrapper>

            {renderTrinaryButton({
                currentStepNumber,
                currentSubStepNumber,
                currentMultiStepsFormStep,
                dispatchRequestNavigation,
                goBackPathname,
                t,
            })}

            {renderSecondaryButton({
                currentStepNumber,
                currentSubStepNumber,
                currentMultiStepsFormStep,
                currentMultiStepsFormSubStep,
                dispatchRequestNavigation,
                dispatchRequestSetMultiStepsFormStep,
                dispatchRequestSetMultiStepsFormSubStep,
                goBackPathname,
                onSuccessGoBackLabel,
                t,
            })}

            {renderPrimaryButton({
                isLoading,
                lastStepExceptSuccess,
                currentMultiStepsFormStep,
                currentSubStepNumber,
                availableSubSteps,
                dispatchRequestNavigation,
                onSuccessNewLocationPathname,
                onSuccessNewLocationLabel,
                t,
            })}

            {isEditMode && availableSubSteps?.length > 0
        && (
            <Styled.EditModeInfoWrapper>
                <StandardTooltip
                    title={'In edit mode you can navigate by clicking on sub step name.'}
                    placement={'right'}
                >
                    <InfoCircleOutlined style={{ color: 'blue', cursor: 'help' }} />
                </StandardTooltip>
            </Styled.EditModeInfoWrapper>
        )}
        </Styled.MultiStepFormButtonsWrapper>
    );
}

MultiStepFormButtons.propTypes = {
    currentMultiStepsFormStep: PropTypes.string,
    isLoading: PropTypes.bool,
    dispatchRequestNavigation: PropTypes.func,
    t: PropTypes.func,
    onSuccessNewLocationPathname: PropTypes.string,
    goBackPathname: PropTypes.string,
    onSuccessNewLocationLabel: PropTypes.string,
    onSuccessGoBackLabel: PropTypes.string,
};


export default MultiStepFormButtons;
