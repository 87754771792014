import ListWithStatusTimeline from 'components/common/templates/ListWithStatusTimeline';
import { statusHistoryRouteSegmentPath } from 'config/routes';
import { PN } from 'constants/PermissionsModel';
import { LocationPathname, NavigationMeta } from 'models/app/navigation';
import { AccessControlContent } from 'models/domain/accessControl';
import { fetchTransactionStatusHistory, clearTransactionStatusHistory } from 'redux/transaction-status-history/actions';
import {
    defaultTransactionStatusHistoryQueryParams,
    transactionStatusHistoryListPerLocationStoreKeyName,
    transactionStatusHistoryReducerName,
} from 'redux/transaction-status-history/reducer';

import { HistoryOutlined } from '@ant-design/icons';
import { TransactionDetails } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React from 'react';
import JSONPretty from 'react-json-pretty';


import Styled from './TransactionDetailsPage.styled';
import AppliedFeesList from './tabs/AppliedFeesList';
import CardTransactionInfo from './tabs/CardTransactionInfo';
import TransactionStatusTimelineArrow from './tabs/StatusHistory/TransactionStatusTimelineArrow';
import TransactionStatusTimelineItem from './tabs/StatusHistory/TransactionStatusTimelineItem';
import createTransactionStatusColumns from './tabs/StatusHistory/tableColumns';
import TransactionDescription from './tabs/TransactionDescription';

// TODO reduce complexity
// eslint-disable-next-line complexity
export const createTransactionDetailsTabsConfig = ({
    transactionDetails,
    accessControl,
    dispatchRequestNavigation,
    t,
}: {
  transactionDetails: TransactionDetails,
  accessControl: AccessControlContent
  t: TFunction,
  dispatchRequestNavigation: (locationPathname: LocationPathname, meta?: NavigationMeta) => void
}) => [
    {
        label: t('transactions:transactionDetails.tabs.description.tabName'),
        activeMainTabSegmentURL: 'description',
        content: <TransactionDescription transactionDetails={transactionDetails} t={t} />,
    },
    ...(accessControl.hasPermission(PN.TRANSACTIONS.READ_HISTORY_BY_ID)
        ? [{
            icon: <HistoryOutlined />,
            label: t('transactions:transactionDetails.tabs.statusHistory.tabName'),
            activeMainTabSegmentURL: statusHistoryRouteSegmentPath,
            content: <ListWithStatusTimeline
                defaultItemsListQueryParams={defaultTransactionStatusHistoryQueryParams}
                reducerName={transactionStatusHistoryReducerName}
                listsByLocationStoreKeyName={transactionStatusHistoryListPerLocationStoreKeyName}
                fetcherListAction={fetchTransactionStatusHistory}
                clearListAction={clearTransactionStatusHistory}
                contextEnforcedQueryParams={{ transactionUuid: transactionDetails?.transactionUuid }}
                createTableColumns={createTransactionStatusColumns}
                statusItemRenderer={({ item, t, isLastItem }) => <TransactionStatusTimelineItem item={item} t={t} isLastItem={isLastItem} />}
                arrowRenderer={({ itemBefore, itemAfter }) => <TransactionStatusTimelineArrow beforeItem={itemBefore} afterItem={itemAfter} t={t} />}
            />,
        }] : []),

    ...(transactionDetails?.appliedFees?.length ? [{
        label: t('transactions:transactionDetails.tabs.appliedFees.tabName'),
        activeMainTabSegmentURL: 'applied-fees',
        hasList: true,
        content: (
            <AppliedFeesList
                dispatchRequestNavigation={dispatchRequestNavigation}
                t={t}
                accessControl={accessControl}
                appliedFees={transactionDetails?.appliedFees}
            />
        ),
    }] : []),

    ...(transactionDetails.cardTransactionData ? [
        {
            label: t('transactions:transactionDetails.tabs.cardTransactionDetails.tabName'),
            activeMainTabSegmentURL: 'card-transaction',
            content: <CardTransactionInfo transactionDetails={transactionDetails} t={t} />,
        },
    ] : []),

    ...(transactionDetails?.cardTransactionData?.decision ? [
        {
            label: t('transactions:transactionDetails.tabs.decisionLogic.tabName'),
            activeMainTabSegmentURL: 'decision',
            content: (
                <div>
                    <JSONPretty id="json-pretty-decision" data={transactionDetails.cardTransactionData.decision} />
                </div>
            ),
        },
    ] : []),

    ...(transactionDetails?.cardTransactionData?.settlements ? [
        {
            label: t('transactions:transactionDetails.tabs.decisionLogic.tabName'),
            activeMainTabSegmentURL: 'decision',
            content: (
                <div>
                    <JSONPretty id="json-pretty-settlements" data={transactionDetails.cardTransactionData.settlements} />
                </div>
            ),
        },
    ] : []),

    ...(transactionDetails?.cardTransactionData?.request?.header || transactionDetails?.cardTransactionData?.request?.isoMsg ? [
        {
            label: t('transactions:transactionDetails.tabs.request.tabName'),
            activeMainTabSegmentURL: 'request',
            content: (
                <Styled.TransactionRequestWrapper>

                    {transactionDetails.cardTransactionData.request.header && (
                        <div>
                            <h3>{t('transactions:transactionDetails.tabs.request.subHeadings.header')}</h3>
                            <JSONPretty id="json-pretty-card-request-header" data={transactionDetails.cardTransactionData.request.header} />
                        </div>
                    )}

                    {transactionDetails.cardTransactionData.request.isoMsg && (
                        <div>
                            <h3>{t('transactions:transactionDetails.tabs.request.subHeadings.isoMsg')}</h3>
                            <JSONPretty id="json-pretty-card-request-isoMsg" data={transactionDetails.cardTransactionData.request.isoMsg} />
                        </div>
                    )}
                </Styled.TransactionRequestWrapper>
            ),
        },
    ] : []),

    ...(transactionDetails?.cardTransactionData?.cdcResponseData ? [
        {
            label: t('transactions:transactionDetails.tabs.response.tabName'),
            activeMainTabSegmentURL: 'cdc-response',
            content: (
                <div>
                    <JSONPretty id="json-pretty-card-response" data={transactionDetails.cardTransactionData.cdcResponseData} />
                </div>
            ),
        },
    ] : []),

];


export default { createTransactionDetailsTabsConfig };
