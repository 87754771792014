import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Styled from './AddressSameAsOtherAddress.styled';


function AddressSameAsOtherAddress({
    shippingAddressSameAsCompanyAddress,
    billingAddressSameAsCompanyAddress,
    storeFieldName,
    label,
    dispatchToggleAddressSameAsOtherAddress,
}) {
    const isShipping = useMemo(
        () => storeFieldName === 'shippingAddressSameAsCompanyAddress',
        [storeFieldName],
    );


    const handleOnChange = (newValue) => {
        dispatchToggleAddressSameAsOtherAddress(storeFieldName, newValue);
    };


    return (
        <Styled.TogglerAndLabelWrapper>
            <Switch
                checked={isShipping ? shippingAddressSameAsCompanyAddress : billingAddressSameAsCompanyAddress}
                onChange={handleOnChange}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
            />
            <Styled.Label>{label}</Styled.Label>
        </Styled.TogglerAndLabelWrapper>
    );
}


AddressSameAsOtherAddress.propTypes = {
    storeFieldName: PropTypes.string,
    copyAddressData: PropTypes.shape({
        shippingAddressSameAsCompanyAddress: PropTypes.bool,
        billingAddressSameAsCompanyAddress: PropTypes.bool,
        dispatchToggleAddressSameAsOtherAddress: PropTypes.func,
    }),
};

export default AddressSameAsOtherAddress;
