import { setClipboardContent } from 'redux/application/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import CopyToClipboard from './CopyToClipboard.layout';


const mapStateToProps = (state) => ({ clipboardContent: state.application.clipboardContent });

const mapDispatchToProps = (dispatch) => ({ dispatchSetClipboardContent: (text) => dispatch(setClipboardContent(text)) });


const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CopyToClipboard);

