import { standardPageSize } from 'config/config';
import { clientRootRoutePath, feesRouteSegmentPath } from 'config/routes';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchFeesList,
    fetchFeesListSuccess,
    fetchFeesListFailure,
    clearFeesList,
} from './actions';


export const feesReducerName = 'fees';
export const feesListsPerLocationStoreKeyName = 'feesListsPerLocation';

export const defaultFeesListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: undefined,
    sortOrder: undefined,
};

const initialFeesListState = {
    [feesListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    // ?
};


const initialState = {
    ...initialFeesListState,
    // ?
};

const handlers = {
    [fetchFeesList]: (state, action) => {
        state[feesListsPerLocationStoreKeyName] = updateStandardLists(state[feesListsPerLocationStoreKeyName], action);
    },
    [fetchFeesListSuccess]: (state, action) => {
        state[feesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[feesListsPerLocationStoreKeyName], action);
    },
    [fetchFeesListFailure]: (state, action) => {
        state[feesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[feesListsPerLocationStoreKeyName], action);
    },
    [clearFeesList]: (state, action) => {
        state[feesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[feesListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[feesListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[feesListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [feesRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === feesReducerName
      && action.payload?.fieldName === feesListsPerLocationStoreKeyName
        ) {
            state[feesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[feesListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
