import StandardMultiStepForm from 'components/common/organisms/StandardMultiStepForm';
import { createActionsForStepSuccessfulValidation } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/common.helpers';
import { createRoleDetailsPath } from 'config/routes.helpers';
import { ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { getLastStepExceptSuccessFromAvailableSteps } from 'redux/multi-steps-form/epics.helpers';

import { Form, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import { createEditRoleStepsConfiguration } from './EditRoleForm.helpers';


function EditRoleForm({
    locationPathname,
    // isLoadingUpdateRole,
    // isLoadingRoleDetails,
    roleId,
    roleDetails,
    operationsLists,

    dispatchUpdateRole,
    dispatchFetchRoleDetails,
    dispatchFetchOperationsList,

    availableSteps,
    currentMultiStepsFormStep,

    dispatchRequestSetMultiStepsFormStep,

    t,
}) {
    const [form] = Form.useForm();

    const lastStepExceptSuccess = useMemo(
        () => getLastStepExceptSuccessFromAvailableSteps(availableSteps),
        [availableSteps],
    );

    useEffect(
        () => {
            if (!roleDetails || roleDetails?.id !== roleId) {
                dispatchFetchRoleDetails(roleId);
            }
            if (!operationsLists) {
                dispatchFetchOperationsList();
            }
        },
        [],
    );

    const finalInitialFormData = useMemo(
        () => true,
        [roleDetails, operationsLists],
    );

    const stepsConfiguration = useMemo(
        () => {
            if (finalInitialFormData) {
                return createEditRoleStepsConfiguration({
                    t,
                    initialFormData: finalInitialFormData,
                });
            }

            return undefined;
        },
        [finalInitialFormData],
    );

    const onFinishHandler = (values) => {
        createActionsForStepSuccessfulValidation({
            values,

            locationPathname,
            currentMultiStepsFormStep,
            dispatchFinalApiCall: dispatchUpdateRole,
            dispatchRequestSetMultiStepsFormStep,
            lastStepExceptSuccess,
        });
    };

    return finalInitialFormData && stepsConfiguration
        ? (
            <StandardMultiStepForm
                isEditMode
                formName="editRole"
                form={form}
                isLoading={false}
                locationPathname={locationPathname}
                stepsConfiguration={stepsConfiguration}
                initialFormData={finalInitialFormData}
                reasonForBlockedNavigation={ReasonsForBlockedNavigation.NOT_PRISTINE_EDIT_ROLE_FORM}
                onFinishHandler={onFinishHandler}
                onSubmitSpinnerCopy={"Updating role's settings..."}
                goBackPathname={createRoleDetailsPath(roleId)}
            />
        )
        : (<Skeleton loading active />);
}

EditRoleForm.propTypes = {
    roleId: PropTypes.number,
    dispatchUpdateRole: PropTypes.func,
    dispatchFetchRoleDetails: PropTypes.func,
    dispatchRequestSetMultiStepsFormStep: PropTypes.func,
    dispatchFetchOperationsList: PropTypes.func,
};


export default EditRoleForm;
