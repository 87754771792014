import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import { ADD_COMPANY_RISK_DETAILS, FETCH_COMPANY_DETAILS, OPEN_ADD_COMPANY_RISK_DETAILS_MODAL, UPDATE_COMPANY } from 'redux/company/action.types';
import {
    addCompanyRiskDetailsSuccess,
    addCompanyRiskDetailsFailure,
    fetchCompanyDetails,
    fetchCompanyDetailsFailure,
    fetchCompanyDetailsSuccess,
    updateCompanySuccess,
    updateCompanyFailure,
} from 'redux/company/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';
import { fetchRiskScoreHistoryList } from 'redux/risk-score-history/actions';
import { riskScoreHistoryListsPerLocationStoreKeyName, riskScoreHistoryReducerName } from 'redux/risk-score-history/reducer';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { companyDetailsPerLocationsStoreKeyName, companyReducerName } from './reducer';


export const onOpenAddCompanyRiskDetailsModal = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_ADD_COMPANY_RISK_DETAILS_MODAL),
    switchMap(({ payload }) => {
        const pathname = payload.locationPathname;
        const entityDetails = state$.value[companyReducerName][companyDetailsPerLocationsStoreKeyName]?.[pathname]?.entityDetails;
        return of(
            showModal({
                modalType: ModalName.CHANGE_COMPANY_RISK_SCORE_MODAL,
                modalProps: {
                    title: i18n.t('Change Risk Score'),
                    initialValues: entityDetails,
                },
            }),
        );
    }),
);


export const onAddCompanyRiskDetails = (action$, state$, { companies, i18n }) => action$.pipe(
    ofType(ADD_COMPANY_RISK_DETAILS),
    switchMap(({ payload }) => from(companies.createCompanyRiskDetails(payload.companyId, payload.formData))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                // eslint-disable-next-line max-len
                const currentRiskScoreHistoryQueryPrams = state$.value[riskScoreHistoryReducerName][riskScoreHistoryListsPerLocationStoreKeyName]?.[pathname]?.entityDetails?.queryParams;

                return of(
                    ...(currentRiskScoreHistoryQueryPrams
                        ? [fetchRiskScoreHistoryList({ companyId: payload.companyId }, pathname)]
                        : []),
                    addCompanyRiskDetailsSuccess(response.data),
                    showSuccessToast(i18n.t('companies:actionMessages.companyRiskScoreUpdateSuccess')),
                    fetchCompanyDetails({ companyId: payload.companyId }, pathname),
                    hideModal(),
                );
            }),
            catchError(() => of(
                addCompanyRiskDetailsFailure(),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);


export const onFetchCompanyDetails = (action$, state$, { companies }) => action$.pipe(
    ofType(FETCH_COMPANY_DETAILS),
    switchMap(({ payload: { queryParams, locationPathname } }) => from(companies.getCompanyDetails(queryParams))
        .pipe(
            switchMap((response) => of(fetchCompanyDetailsSuccess(response.data, locationPathname))),
            catchError(() => of(fetchCompanyDetailsFailure(locationPathname))),
        )),
);

export const onUpdateCompany = (action$, state$, { companies, i18n }) => action$.pipe(
    ofType(UPDATE_COMPANY),
    switchMap(({ payload }) => from(companies.updateCompany(payload.companyId, payload.formData))
        .pipe(
            switchMap(() => of(
                updateCompanySuccess(payload.formData, payload.locationPathname),
                showSuccessToast(i18n.t('companies:actionMessages.updateCompanySuccess')),
                hideModal(),
                fetchCompanyDetails({ companyId: payload.companyId }, payload.locationPathname),
            )),
            catchError(() => of(
                updateCompanyFailure(),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);


export default [
    onFetchCompanyDetails,
    onUpdateCompany,
    onOpenAddCompanyRiskDetailsModal,
    onAddCompanyRiskDetails,
];
