import PropTypes from 'prop-types';


export const StatusChangeChannels = Object.freeze({
    BACKOFFICE: 'BACKOFFICE',
    SCHEDULER: 'SCHEDULER',
    API_USER: 'API_USER',
    SYSTEM: 'SYSTEM',
    PERSONAL_MOBILE_APP: 'PERSONAL_MOBILE_APP',
    BUSINESS_WEB_APP: 'BUSINESS_WEB_APP',
});
export const StatusChangesChannelProp = PropTypes.oneOf(Object.keys(StatusChangeChannels));
