import { createClientDetailsGroupsListPath } from 'config/routes.helpers';
import { deleteGroup } from 'redux/group/actions';
import { fetchGroupsList } from 'redux/groups/actions';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { setModalProps } from 'redux/modal/actions';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DeleteGroup from './DeleteGroup.layout';


const mapStateToProps = (state, ownProps) => ({
    clientGroupsListData: getStandardListData({
        state,
        reducerName: groupsReducerName,
        listsByLocationStoreKeyName: groupsListsPerLocationStoreKeyName,
        customLocation: createClientDetailsGroupsListPath(ownProps?.initialValues?.client_id),
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchGroups: ({ clientId }) => dispatch(fetchGroupsList({ clientId }, createClientDetailsGroupsListPath(clientId))),
    dispatchDeleteGroup: (groupId, newGroupId, meta) => dispatch(deleteGroup(groupId, newGroupId, meta)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});

const decorate = compose(
    withTranslation('groups'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(DeleteGroup);
