import withAccessControl from 'hocs/withAccessControl';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ClientSelect from './ClientSelect.layout';


const mapStateToProps = (state) => ({
    clientsData: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

    currentUserClientId: state.currentUser.userData?.clientId,
});

const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps),
);

export default decorate(ClientSelect);
