import { convertHexToRGB } from 'utils/css-tools';

import { Table } from 'antd';
import styled from 'styled-components';


const StandardListTable = styled(Table)`

  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: inset 10px 0 18px -8px rgb(${convertHexToRGB('#397dcc')} / 95%);
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: inset -10px 0 18px -8px rgb(${convertHexToRGB('#397dcc')} / 95%);
  }

  .ant-table-thead .ant-table-cell {
    width: max-content;
    max-width: 100%;
  }


  .ant-pagination.ant-table-pagination.ant-table-pagination-right {
    position: relative;
  }
`;

const ErrorOuterWrapper = styled.div`
    display: grid;
    height: 70%;
    align-items: center;
    justify-content: center;
`;

const ErrorWrapper = styled.div`
    text-align: center;
`;
export default { StandardListTable, ErrorOuterWrapper, ErrorWrapper };

