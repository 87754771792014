import { CardType } from 'constants/CardModel';
import { goToPreviousOrderStep } from 'redux/card-order/actions';
import { cardsOrderReducerName } from 'redux/card-order/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ReviewStep from './ReviewStep.layout';


const mapStateToProps = (state) => ({
    isLoadingOrderCards: state[cardsOrderReducerName].isLoadingOrderCards,
    cardOrderReview: state[cardsOrderReducerName].cardOrderReview,
    isPhysicalCard: state[cardsOrderReducerName].data.card_type === CardType.PHYSICAL,
});

const mapDispatchToProps = (dispatch) => ({ dispatchGoToPreviousOrderStep: (currentStep) => dispatch(goToPreviousOrderStep(currentStep)) });

const decorate = compose(
    withTranslation('cards'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ReviewStep);
