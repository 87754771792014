import { Tabs } from 'antd';
import styled from 'styled-components';


const StyledStandardTabsWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const StyledStandardTabs = styled(Tabs)`
  height: 100%;
  width: 100%;

  /* fix for animation left when tab content overlaps with tab nav list */
  .ant-tabs-content-holder .ant-tabs.ant-tabs-left .ant-tabs-nav-wrap {
    background: #fff;
    margin-left: -10px;
    z-index: 99;
    position: relative;
  }
`;

const StyledStandardTabsSkeletonWrapper = styled.div`
  height: 100%;
  background: #fff;
  
  .ant-skeleton {
    height: 100%;
    padding: 1rem;
  }

  .ant-skeleton-content {
    height: 100%;
  }

  .ant-skeleton-paragraph {
    margin: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      height: auto !important;
      max-height: 30px;
      min-height: 10px;
      display: flex;
      flex: 1 1 100%;
    }
  }
`;


export default { StyledStandardTabsWrapper, StyledStandardTabs, StyledStandardTabsSkeletonWrapper };
