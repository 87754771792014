import { MultiStepFormStepSectionFieldsLayout } from 'constants/MultiStepsFormModel';

import styled, { css } from 'styled-components';


const StandardFormSectionWrapper = styled.div`
  outline: none;
`;

const StandardFormSectionTitle = styled.div`
  align-items: center;
`;

const StandardFormSectionFieldsWrapper = styled.div`
  display: grid;
  
  ${({ $fieldsLayout }) => $fieldsLayout === MultiStepFormStepSectionFieldsLayout.VERTICAL && css`
    grid-template-columns: repeat(${({ $colCount = 1 }) => $colCount}, minmax(0, 1fr));
    grid-column-gap: 51px;
  `};
  
  ${({ $fieldsLayout }) => $fieldsLayout === MultiStepFormStepSectionFieldsLayout.EVEN_HORIZONTAL && css`
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-column-gap: 31px;
  `};

  ${({ $fieldsLayout }) => $fieldsLayout === MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL && css`
    grid-template-columns: repeat(2, minmax(min-content, 1fr));
    grid-column-gap: 31px;
  `};
`;


export default {
    StandardFormSectionWrapper,
    StandardFormSectionTitle,
    StandardFormSectionFieldsWrapper,
};
