import { Dispatch } from 'models/meta/action';
import { openEditAddressModal } from 'redux/address/actions';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';


import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CommonAddressDisplay from './CommonAddressDisplay.layout';
import { CommonAddressDisplayDispatchProps, CommonAddressDisplayOwnProps } from './CommonAddressDisplay.types';


const mapStateToProps = (state: RootState) => ({
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});


const mapDispatchToProps = (dispatch: Dispatch): CommonAddressDisplayDispatchProps => ({
    dispatchOpenEditAddressModal: ({ modalType, modalTitle, addressId, initialValues }) => dispatch(openEditAddressModal({
        modalType,
        modalTitle,
        addressId,
        initialValues,
    })),
});

const decorate = compose<ComponentType<CommonAddressDisplayOwnProps>>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('common'),
);

export default decorate(CommonAddressDisplay);
