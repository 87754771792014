import { StandardTwoColumnsLabel, StandardTwoColumnsValueWrapper } from 'components/common/atoms/styled/StandardColumns/StandardColumns.styled';
import StandardTooltip from 'components/common/molecules/StandardTooltip';

import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Skeleton, Popconfirm, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Styled from './CommonSection.styled';


// TODO reduce complexity
// eslint-disable-next-line complexity
function CommonSection({
    sectionLabel,
    sectionEditOnClick,
    sectionEditTooltip,
    sectionInfoTooltip,

    sectionDeleteTooltip,
    sectionDeleteConfirmationText,
    sectionDeleteOnClick,
    sectionAdditionalButtons,

    children,
    fieldsConfig,
    isLoading,
}) {
    const renderLabel = (label) => (
        <StandardTwoColumnsLabel key={label}>
            {`${label}:`}
        </StandardTwoColumnsLabel>
    );

    const renderTextValue = (inputValue) => {
        const hasValue = inputValue && String(inputValue) && String(inputValue)?.trim()?.length > 0;
        const value = hasValue ? String(inputValue)?.trim() : '-';

        return hasValue ? (
            <StandardTooltip title={value}>
                <Styled.ValueWithEllipsis>{value}</Styled.ValueWithEllipsis>
            </StandardTooltip>
        ) : value;
    };

    const renderValue = (value, index) => (
        <StandardTwoColumnsValueWrapper key={`value-${index}`}>
            {React.isValidElement(value) ? value : renderTextValue(value)}
        </StandardTwoColumnsValueWrapper>
    );

    const renderFields = (fieldsConfig = []) => {
        const renderData = fieldsConfig.reduce((acc, fieldConfig) => {
            if (fieldConfig.value || fieldConfig.showEmpty) {
                return {
                    labels: [
                        ...acc.labels,
                        fieldConfig.label,
                    ],
                    values: [
                        ...acc.values,
                        fieldConfig.value,
                    ],
                };
            }
            return acc;
        }, { labels: [], values: [] });


        return (
            <Styled.LabelsValuesRow gutter={24}>
                <Styled.LabelsColumn key="labels-col">
                    {renderData.labels.map((label) => renderLabel(label))}
                </Styled.LabelsColumn>
                <Styled.ValuesColumn key="values-col">
                    {renderData.values.map((value, index) => renderValue(value, index))}
                </Styled.ValuesColumn>
            </Styled.LabelsValuesRow>
        );
    };


    return (
        <Skeleton loading={isLoading} active>
            <Styled.CommonSection>
                {sectionLabel
          || (sectionEditTooltip && sectionEditOnClick)
          || (sectionDeleteTooltip && sectionDeleteOnClick && sectionDeleteConfirmationText)
          || sectionInfoTooltip
          || sectionAdditionalButtons
                    ? (
                        <Styled.CommonSectionHeadingWrapper className={'commonSection-heading'}>
                            {sectionLabel && (
                                <Styled.CommonSectionHeadingText className={'commonSection-heading-text'}>
                                    {sectionLabel}
                                </Styled.CommonSectionHeadingText>
                            )}

                            <div className={'commonSection-heading-buttonsWrapper'}>
                                <Space>
                                    {sectionEditTooltip && sectionEditOnClick && (
                                        <StandardTooltip title={sectionEditTooltip}>
                                            <Styled.CommonSectionEditButton
                                                onClick={sectionEditOnClick}
                                                shape="circle"
                                                icon={<EditOutlined />}
                                            />
                                        </StandardTooltip>
                                    )}

                                    {sectionDeleteTooltip && sectionDeleteOnClick && sectionDeleteConfirmationText && (
                                        <StandardTooltip title={sectionDeleteTooltip}>
                                            <Popconfirm title={sectionDeleteConfirmationText} onConfirm={sectionDeleteOnClick}>
                                                <Styled.CommonSectionEditButton shape="circle" icon={<DeleteOutlined />} />
                                            </Popconfirm>
                                        </StandardTooltip>
                                    )}

                                    {sectionInfoTooltip && (
                                        <StandardTooltip title={sectionInfoTooltip}>
                                            <InfoCircleOutlined style={{ color: 'blue', cursor: 'help' }} />
                                        </StandardTooltip>
                                    )}

                                    {sectionAdditionalButtons}
                                </Space>
                            </div>
                        </Styled.CommonSectionHeadingWrapper>
                    ) : null}


                <Styled.CommonSectionContentWrapper className={'commonSection-contentWrapper'}>
                    {renderFields(fieldsConfig)}

                    {children ? (
                        <Styled.CommonSectionOptionalContentWrapper>
                            {children}
                        </Styled.CommonSectionOptionalContentWrapper>
                    ) : null}

                </Styled.CommonSectionContentWrapper>
            </Styled.CommonSection>
        </Skeleton>
    );
}

CommonSection.propTypes = {
    sectionLabel: PropTypes.string,
    sectionEditOnClick: PropTypes.func,
    sectionEditTooltip: PropTypes.string,
    sectionInfoTooltip: PropTypes.string,

    sectionDeleteTooltip: PropTypes.string,
    sectionDeleteOnClick: PropTypes.func,
    sectionDeleteConfirmationText: PropTypes.string,
    sectionAdditionalButtons: PropTypes.node,

    fieldsConfig: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        showEmpty: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.node,
        ]),
    })).isRequired,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
};

export default CommonSection;

