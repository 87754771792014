import styled from 'styled-components';


const RiskDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-column-gap: 16px;
  gap: 24px;
`;
export default { RiskDetailsWrapper };

