import {
    CLEAR_MULTI_STEPS_FORM_DATA,
    CLEAR_MULTI_STEPS_FORM_META_DATA,
    REQUEST_SET_MULTI_STEPS_FORM_STEP,
    REQUEST_SET_MULTI_STEPS_FORM_SUB_STEP, SET_MULTI_STEPS_FORM_DATA, SET_MULTI_STEPS_FORM_META_DATA,
    SET_MULTI_STEPS_FORM_STEP,
    SET_MULTI_STEPS_FORM_SUB_STEP,
} from 'redux/multi-steps-form/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const setMultiStepsFormMetaData = createAction(SET_MULTI_STEPS_FORM_META_DATA, (formMetaData, locationPathname) => ({ payload: { formMetaData, locationPathname } }));
export const clearMultiStepsFormMetaData = createAction(CLEAR_MULTI_STEPS_FORM_META_DATA, (locationPathname) => ({ payload: { locationPathname } }));

export const requestSetMultiStepsFormStep = createAction(
    REQUEST_SET_MULTI_STEPS_FORM_STEP,
    (currentStep, direction, locationPathname) => ({ payload: { currentStep, direction, locationPathname } }),
);
export const setMultiStepsFormStep = createAction(SET_MULTI_STEPS_FORM_STEP, (newCurrentStep, locationPathname) => ({ payload: { newCurrentStep, locationPathname } }));

export const requestSetMultiStepsFormSubStep = createAction(
    REQUEST_SET_MULTI_STEPS_FORM_SUB_STEP,
    (queryParams, locationPathname) => ({
        payload: {
            parentStep: queryParams.parentStep,
            currentSubStep: queryParams.currentSubStep,
            direction: queryParams.direction,
            locationPathname,
        },
    }),
);
export const setMultiStepsFormSubStep = createAction(
    SET_MULTI_STEPS_FORM_SUB_STEP,
    (parentStep, newCurrentSubStep, locationPathname) => ({ payload: { parentStep, newCurrentSubStep, locationPathname } }),
);

export const setMultiStepsFormData = createAction(SET_MULTI_STEPS_FORM_DATA, (formData, locationPathname) => ({ payload: { formData, locationPathname } }));
export const clearMultiStepsFormData = createAction(CLEAR_MULTI_STEPS_FORM_DATA, (locationPathname) => ({ payload: { locationPathname } }));
