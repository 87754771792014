import { createAction } from '@reduxjs/toolkit';

import {
    OPEN_CREATE_NEW_GROUP_MODAL,
    CREATE_NEW_GROUP,
    CREATE_NEW_GROUP_FAILURE,
    CREATE_NEW_GROUP_SUCCESS,
    FETCH_GROUPS_LIST,
    CLEAR_GROUPS_LIST,
    FETCH_GROUPS_LIST_FAILURE, FETCH_GROUPS_LIST_SUCCESS,
} from './actions.types';


export const fetchGroupsList = createAction(FETCH_GROUPS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchGroupsListSuccess = createAction(FETCH_GROUPS_LIST_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchGroupsListFailure = createAction(FETCH_GROUPS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearGroupsList = createAction(CLEAR_GROUPS_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export const openCreateNewGroupModal = createAction(OPEN_CREATE_NEW_GROUP_MODAL, ({ clientId, locationPathname }) => ({ payload: { clientId, locationPathname } }));
export const createNewGroup = createAction(CREATE_NEW_GROUP, ({ clientId, groupName, locationPathname }) => ({ payload: { clientId, groupName, locationPathname } }));
export const createNewGroupSuccess = createAction(CREATE_NEW_GROUP_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const createNewGroupFailure = createAction(CREATE_NEW_GROUP_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
