import { MultiStepFormStepChangeDirections } from 'constants/MultiStepsFormModel';


export function createActionsForStepSuccessfulValidation({
    locationPathname,
    currentMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormStep,
    dispatchFinalApiCall,
    values,
    lastStepExceptSuccess,
}) {
    const handlers = [
        {
            // last step or last sub step in last step (last expect SUCCESS step)
            predicate: () => (currentMultiStepsFormStep === lastStepExceptSuccess),
            handler: () => dispatchFinalApiCall(values),
        },

        {
            // regular case -> advance step
            predicate: () => true,
            handler: () => dispatchRequestSetMultiStepsFormStep(
                currentMultiStepsFormStep,
                MultiStepFormStepChangeDirections.FORWARD,
                locationPathname,
            ),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export default { createActionsForStepSuccessfulValidation };
