import { uploadClientMigrationFile } from 'redux/client-migrations/actions';

import { connect } from 'react-redux';

import AddUserMigrationModal from './AddUserMigrationModal.layout';


const mapDispatchToProps = (dispatch) => ({
    dispatchUploadClientMigrationFile: (queryParams, locationPathname) => dispatch(
        uploadClientMigrationFile(
            queryParams,
            locationPathname,
        ),
    ),
});

export default connect(null, mapDispatchToProps)(AddUserMigrationModal);
