import CardsList from 'components/common/templates/lists/CardsList';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { cardsOrderRootNestedRoutePath } from 'config/routes';
import { AccessControlProps } from 'constants/AccessControlModel';
import { permissionSetOrderCardsTargetSelection } from 'constants/PermissionsModel';

import PropTypes from 'prop-types';
import React from 'react';


function Cards({
    accessControl,
    dispatchRequestNavigation,
    t,
}) {
    return (
        <>
            <StandardPageHeading title={t('Cards')} />

            <StandardPageWrapper>
                <CardsList
                    showSearchField
                    showPartnerSelect
                    allowInactivePartners
                    onOrderCardsButtonClick={
                        accessControl.hasPermissions(permissionSetOrderCardsTargetSelection)
                            ? () => dispatchRequestNavigation(cardsOrderRootNestedRoutePath)
                            : undefined
                    }
                />
            </StandardPageWrapper>
        </>
    );
}


Cards.propTypes = {
    accessControl: AccessControlProps,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default Cards;
