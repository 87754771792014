import { OutgoingTransferStep } from 'models/app/outgoingTransfer';

import { Button } from 'antd';
import React from 'react';

import { BackButtonProps } from './BackButton.types';


function BackButton({
    currentStep,
    dispatchGoToPreviousOutgoingTransferFormStep,
    t,
}: BackButtonProps) {
    if (!currentStep) return null;

    return currentStep !== OutgoingTransferStep.ACCOUNT_SELECTION ? (
        <Button
            onClick={() => dispatchGoToPreviousOutgoingTransferFormStep(currentStep)}
            data-test-id="back-button"
            style={{ marginRight: 10 }}
        >
            {t('common:buttons.back.text')}
        </Button>
    ) : null;
}
export default BackButton;
