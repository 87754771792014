import styled from 'styled-components';

// XXX - \00a0 - non braking space, must be as unicode
const CountriesOfOperationsWrapper = styled.div`
  .multiple-inline-values > span:not(:last-of-type) > div > div{
    
    &:after {
      content: ",\\00a0";
    }
  }
`;

export default { CountriesOfOperationsWrapper };
