import withAccessControl from 'hocs/withAccessControl';
import { updateBusinessUser } from 'redux/business-user/actions';
import { setModalProps } from 'redux/modal/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import EditBusinessUserModal from './EditBusinessUserModal.layout';


const mapDispatchToProps = (dispatch) => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchUpdateBusinessUser: (requestPayload, listOfOriginLocationPathname) => dispatch(updateBusinessUser(requestPayload, listOfOriginLocationPathname)),
});


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(null, mapDispatchToProps),
);

export default decorate(EditBusinessUserModal);
