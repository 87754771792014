import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { renderFilter, renderFilterSectionButtons } from './FiltersSection.helpers';
import Styled from './FiltersSection.styled';


function FiltersSection({
    filtersConfiguration = [],
    buttonsConfiguration = [],
    onGenericDropdownChange,
    onSearchUpdate,
    queryParams,
    isLoading,
    t,
}) {
    const [searchQuery, setSearchQuery] = useState(queryParams?.search);

    const debouncedOnChange = useCallback(
        debounce((newValue) => onSearchUpdate(newValue), 250),
        [onSearchUpdate],
    );


    useEffect(() => {
        if (queryParams?.search !== searchQuery) {
            setSearchQuery(queryParams?.search);
        }
    }, [queryParams]);


    const onSearchInputChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);

        if (onSearchUpdate) {
            debouncedOnChange(value);
        }
    };

    const hasFilters = filtersConfiguration && filtersConfiguration.length > 0;
    const hasButtons = buttonsConfiguration && buttonsConfiguration.length > 0;

    return (
        <Styled.FiltersAndButtonsWrapper
            $hasButtons={hasButtons}
            $hasFilters={hasFilters}
            $isLoading={isLoading}
        >
            {hasFilters && (
                <Styled.FiltersWrapper>
                    {filtersConfiguration.map((filterItemConfig) => renderFilter({
                        filterItemConfig,
                        onSearchInputChange,
                        searchQuery,
                        queryParams,
                        onGenericDropdownChange,
                        t,
                    }))}
                </Styled.FiltersWrapper>
            )}

            {hasButtons && renderFilterSectionButtons(buttonsConfiguration)}

        </Styled.FiltersAndButtonsWrapper>
    );
}

FiltersSection.propTypes = {
    filtersConfiguration: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        showSearch: PropTypes.bool,
        queryParamName: PropTypes.string,
        onChange: PropTypes.func,
        filterOption: PropTypes.func,
        style: PropTypes.shape({}),
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            label: PropTypes.string.isRequired,
        })),
    })),

    buttonsConfiguration: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.node,
        dataTestId: PropTypes.string,
        type: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        text: PropTypes.string.isRequired,
        tooltipContent: PropTypes.string,
    })),
    isLoading: PropTypes.bool,
    onSearchUpdate: PropTypes.func,
    onGenericDropdownChange: PropTypes.func,
};

FiltersSection.defaultProps = {
    filtersConfiguration: [],
    buttonsConfiguration: [],
};

export default FiltersSection;
