import PropTypes from 'prop-types';


export const IndustryObjectProps = PropTypes.shape({
    created_at: PropTypes.string.isRequired, // "2020-11-16T20:55:51.000Z"
    deleted: PropTypes.number.isRequired, // 0
    deleted_at: PropTypes.string, // "2020-11-16T20:55:51.000Z"
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired, // "Agriculture"
    risk_score: PropTypes.number.isRequired,
    updated_at: PropTypes.string.isRequired, // "2020-11-16T20:55:51.000Z"
});

export const IndustriesListProps = PropTypes.arrayOf(IndustryObjectProps);

export default {
    IndustryObjectProps,
    IndustriesListProps,
};
