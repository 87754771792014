import styled from 'styled-components';


const StandardFormItemWrapper = styled.div`
  align-items: center;
  
  /* CSS issue with tooltips being cut by overflow */
  .ant-form-item-label {
    display: block !important;
    overflow: visible !important;
  }
`;

const StandardFormItemLabelWrapper = styled.span`
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  grid-column-gap: 1ch;
`;

const StandardFormItemLabelText = styled.span`
  align-items: center;
`;

export default { StandardFormItemWrapper, StandardFormItemLabelWrapper, StandardFormItemLabelText };
