import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import OffboardingButtons from 'components/common/molecules/OffboardingButtons';
import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonAddressDisplay from 'components/common/organisms/CommonAddressDisplay';
import CommonSection from 'components/common/organisms/CommonSection';
import { CompanyDetailsProps } from 'constants/CompaniesModel';

import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createCompanyBaseDetailsFields, createCompanySystemDetailsFields } from './CompanyInformation.helpers';


function CompanyInformation({
    t,
    companyDetails,
    companyId,
    onDetailsEdit,
    onAddressEdit,
    areDetailsReady,
    onRefresh,
    queryParamsMeta,
    isLoading,
}) {
    const companyBaseDetailsFields = useMemo(
        () => createCompanyBaseDetailsFields({
            companyDetails,
            t,
        }),
        [companyDetails],
    );

    const companySystemDetailsFields = useMemo(
        () => createCompanySystemDetailsFields({
            companyDetails,
            t,
        }),
        [companyDetails],
    );


    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={!areDetailsReady || isLoading}
                minHeight={'280px'}
                sectionsRowsCount={8}
                sectionsCount={3}
            >
                <DetailsSectionsWrapper>
                    <Row gutter={24} size={10} direction="horizontal">
                        {/* company information details */}
                        <CommonSection
                            sectionLabel={t('subHeadings.companyInformation.text')}
                            sectionEditTooltip={onDetailsEdit ? t('subHeadings.companyInformation.tooltip') : undefined}
                            sectionEditOnClick={onDetailsEdit ? () => onDetailsEdit(companyDetails) : undefined}
                            fieldsConfig={companyBaseDetailsFields}
                        />

                        {/* Address details */}
                        <CommonAddressDisplay
                            sectionLabel={t('subHeadings.address.text')}
                            sectionEditTooltip={onAddressEdit ? t('subHeadings.address.tooltip') : undefined}
                            sectionEditOnClick={onAddressEdit ? () => onAddressEdit(companyDetails?.company_address) : undefined}
                            addressData={companyDetails?.company_address}
                        />

                        {/*  company system details */}
                        <CommonSection
                            sectionLabel={t('subHeadings.systemStatus.text')}
                            fieldsConfig={companySystemDetailsFields}
                        />
                    </Row>

                    <OptionalButtonsWrapper>
                        <OffboardingButtons
                            companyId={companyDetails?.company_id}
                            entityName={companyDetails?.company_name}
                            entityStatus={companyDetails?.company_status}
                        />
                    </OptionalButtonsWrapper>

                    <QueryParamsMetaInfo
                        detailsContext
                        onListRefresh={onRefresh}
                        queryParams={{ companyId }}
                        queryParamsMeta={queryParamsMeta}
                    />
                </DetailsSectionsWrapper>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}

CompanyInformation.propTypes = {
    companyDetails: CompanyDetailsProps,
    onDetailsEdit: PropTypes.func,
    onAddressEdit: PropTypes.func,
    isLoading: PropTypes.bool,
    companyId: PropTypes.number,
    areDetailsReady: PropTypes.bool,
    onRefresh: PropTypes.func.isRequired,
};

export default CompanyInformation;
