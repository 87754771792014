import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { UpdateMemberStatusPayload } from 'redux/member-status/actions.types';
import { commonValidation, handleWhitespaceOnBlur } from 'utils/validation-tools';

import { Form, Input } from 'antd';
import React, { useEffect } from 'react';

import { UpdateMemberStatusModalProps } from './UpdateMemberStatusModal.types';


function UpdateMemberStatusModal({
    t,
    enhancedCurrentLocation,
    modalProps,
    dispatchSetModalProps,
}:UpdateMemberStatusModalProps) {
    const [form] = Form.useForm();
    const onFinishHandler = (data) => {
        const baseURLSegmentsCount = 2;
        const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(
            enhancedCurrentLocation?.pathname,
            baseURLSegmentsCount,
        );

        const memberId = modalProps?.memberId;
        const dispatchAction = modalProps.dispatchUpdateStatusAction;
        const comment = data?.comment;

        dispatchAction({
            queryParams: { memberId },
            requestPayload: { comment },
            locationPathname: baseUrlWithoutTabsSlugs,
        } as UpdateMemberStatusPayload);

        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });
    };

    const getComponentTranslation = (key) => t(`member:modals.updateStatus.fields.${key}`);

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);
    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinishHandler}
        >
            <Form.Item
                name="comment"
                label={getComponentTranslation('comment.label')}
                rules={[...commonValidation({ t, isRequired: true })]}
            >
                <Input
                    placeholder={getComponentTranslation('comment.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'comment', form)}
                />
            </Form.Item>
        </Form>
    );
}

export default UpdateMemberStatusModal;
