import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createFailedWebhookDetailsPath } from 'config/routes.helpers';
import { SortDirections } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import { removeUnderscores } from 'utils/string-tools';
import { sortingOptions } from 'utils/table-columns-sorters';

import { RedoOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';


export default ({
    accessControl,
    dispatchReplayFailedWebhook,
    dispatchShowViewJsonModal,
    queryParams,
    enhancedCurrentLocation,
    dispatchRequestNavigation,
    isLoadingReplayFailedWebhook,
    t,
}) => [
    {
        title: t('failedWebhooksFields.numberOfAttempts.label'),
        dataIndex: 'attemptNumber',
        align: 'right',
        render: (text) => <StandardValue value={text} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'attemptNumber',
        }),
    },
    {
        title: t('webhooksFields.uuid.label'),
        dataIndex: 'uuid',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('webhooksFields.actionType.label'),
        dataIndex: 'action',
        render: (text) => <StandardValue value={removeUnderscores(text)} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'action',
        }),
    },
    {
        title: t('webhooksFields.webhookURL.label'),
        dataIndex: 'webhookUrl',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'webhookUrl',
        }),
    },
    {
        title: t('webhooksFields.apiKey.label'),
        dataIndex: 'apiKey',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'apiKey',
        }),
    },
    {
        title: t('webhooksFields.headers.label'),
        dataIndex: 'headers',
        align: 'center',
        render: (text, record) => {
            if (record?.headers?.length > 0) {
                return viewButtonRenderer({
                    record,
                    onClick: (_, record) => dispatchShowViewJsonModal(
                        JSON.parse(record?.headers),
                        <StandardTranslationWithMarkup
                            translationKey={'modals.viewHeadersJsonModal.viewHeadersJsonModalTitle'}
                            namespace="webhooks"
                            values={{ webhookUuid: record?.uuid, actionType: record?.action }}
                            t={t}
                        />,
                    ),
                    tooltipContent: t('buttons.viewWebhookHeadersJson.text'),
                    dataTestId: 'view-headers',
                });
            }
            return '-';
        },
    },
    {
        title: t('failedWebhooksFields.errorDetails.label'),
        dataIndex: 'errorDetails',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('failedWebhooksFields.data.label'),
        dataIndex: 'data',
        align: 'center',
        render: (text, record) => {
            if (record?.data) {
                return viewButtonRenderer({
                    record,
                    onClick: (_, record) => dispatchShowViewJsonModal(
                        JSON.stringify(record?.data),
                        t('failedWebhooksFields.data.label'),
                    ),
                    tooltipContent: t('buttons.viewData.tooltip'),
                    dataTestId: 'view-data',
                });
            }
            return '-';
        },
    },
    {
        title: t('webhooksFields.createdAt.label'),
        dataIndex: 'createdAt',
        align: 'center',
        render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
        ...sortingOptions({
            queryParams,
            fieldName: 'createdAt',
        }),
        defaultSortOrder: SortDirections.ASCEND,
    },
    {
        title: t('webhooksFields.updatedAt.label'),
        dataIndex: 'updatedAt',
        align: 'center',
        render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
        ...sortingOptions({
            queryParams,
            fieldName: 'updatedAt',
        }),
    },

    {
        title: 'Actions',
        dataIndex: '',
        align: 'center',
        render: (_, record) => (
            <Space>
                {accessControl.hasPermission(PN.WEBHOOKS.REPLAY)
            && viewButtonRenderer({
                record,
                onClick: (_, record) => dispatchReplayFailedWebhook({ webhookUuid: record.uuid, clientId: record.clientId }, enhancedCurrentLocation.pathname),
                tooltipContent: t('buttons.replayFailedWebhook.tooltip'),
                icon: <RedoOutlined />,
                dataTestId: 'replay-webhook',
                isDisabled: isLoadingReplayFailedWebhook.includes(record.uuid),
                isLoading: isLoadingReplayFailedWebhook.includes(record.uuid),
            })}

                {viewButtonRenderer({
                    record,
                    onClick: (event, record) => dispatchRequestNavigation(createFailedWebhookDetailsPath(record.uuid), { state: { failedWebhook: record } }),
                    tooltipContent: t('buttons.viewDetails.tooltip'),
                    dataTestId: 'view-failed-webhook-details',
                })}
            </Space>
        ),
    },
];
