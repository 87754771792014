import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { SortDirections } from 'constants/ApplicationStateModel';
import { sortDirections, sortingOptions, sortNumbers, sortStringDates, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import PropTypes from 'prop-types';
import React from 'react';


function createApplicationsListTableColumns({
    t,
    queryParams,
}) {
    return [
        {
            title: t('ID'),
            dataIndex: 'id',
            defaultSortOrder: SortDirections.ASCEND,
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'id',
                sorter: (a, b) => sortNumbers(a, b, 'id'),
            }),
        },
        {
            title: t('UUID'),
            dataIndex: 'uuid',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'uuid',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'uuid'),
            }),
        },
        {
            title: t('Name'),
            dataIndex: 'name',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'name',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'name'),
            }),
        },
        {
            title: t('Scope'),
            dataIndex: 'scope',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            sortDirections,
            ...sortingOptions({
                queryParams,
                fieldName: 'scope',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'scope'),
            }),
        },

        {
            title: t('Created at'),
            dataIndex: 'created_at',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
            ...sortingOptions({
                queryParams,
                fieldName: 'created_at',
                sorter: (a, b) => sortStringDates(a, b, 'created_at'),
            }),
        },
    ];
}


createApplicationsListTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
};


export default createApplicationsListTableColumns;
