import { createAction } from '@reduxjs/toolkit';


export const initExchange = createAction('exchange/initExchange', (requestPayload) => ({ payload: requestPayload }));
export const initExchangeSuccess = createAction('exchange/initExchangeSuccess', (exchangeUuid) => ({ payload: exchangeUuid }));
export const initExchangeFailure = createAction('exchange/initExchangeFailure');


export const confirmExchange = createAction('exchange/confirmExchange', (exchangeUuid, requestPayload) => ({ payload: { exchangeUuid, requestPayload } }));
export const confirmExchangeSuccess = createAction('exchange/confirmExchangeSuccess', (responseData) => ({ payload: responseData }));
export const confirmExchangeFailure = createAction('exchange/confirmExchangeFailure');


export const rejectExchange = createAction('exchange/rejectExchange', (requestPayload) => ({ payload: requestPayload }));
export const rejectExchangeSuccess = createAction('exchange/rejectExchangeSuccess', (responseData) => ({ payload: responseData }));
export const rejectExchangeFailure = createAction('exchange/rejectExchangeFailure');


export const fetchExchangeRate = createAction('exchange/fetchExchangeRate', (requestPayload) => ({ payload: requestPayload }));
export const fetchExchangeRateSuccess = createAction('exchange/fetchExchangeRateSuccess', (responseData) => ({ payload: responseData }));
export const fetchExchangeRateFailure = createAction('exchange/fetchExchangeRateFailure');

