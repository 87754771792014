import { clearCurrentUser } from 'redux/current-user/actions';
import { clearResetNewTransactionFormState } from 'redux/transaction/actions';

import { createReducer } from '@reduxjs/toolkit';

import {
    confirmExchange, confirmExchangeSuccess, confirmExchangeFailure,
    fetchExchangeRate, fetchExchangeRateSuccess, fetchExchangeRateFailure,
    initExchange, initExchangeSuccess, initExchangeFailure,
    rejectExchange, rejectExchangeSuccess, rejectExchangeFailure,
} from './actions';


export const exchangeReducerName = 'exchange';

const initialState = {
    exchangeRate: undefined,
    exchangeUuid: undefined,

    isLoading: false,
    isLoadingExchangeRate: false,

    resetNewTransactionFormState: undefined,
};


const handlers = {
    [initExchange]: (state) => {
        state.isLoading = true;
    },
    [initExchangeSuccess]: (state, action) => {
        state.isLoading = false;
        state.exchangeUuid = action.payload;
    },
    [initExchangeFailure]: (state) => {
        state.isLoading = false;
    },


    [confirmExchange]: (state) => {
        state.isLoading = true;
    },
    [confirmExchangeSuccess]: (state) => {
        state.isLoading = false;
        state.exchangeUuid = undefined;
        state.exchangeRate = undefined;
        state.resetNewTransactionFormState = true;
    },
    [confirmExchangeFailure]: (state) => {
        state.isLoading = false;
    },


    [rejectExchange]: (state) => {
        state.isLoading = true;
    },
    [rejectExchangeSuccess]: (state) => {
        state.isLoading = false;
        state.exchangeUuid = undefined;
        state.exchangeRate = undefined;
    },
    [rejectExchangeFailure]: (state) => {
        state.isLoading = false;
    },


    [fetchExchangeRate]: (state) => {
        state.isLoadingExchangeRate = true;
    },
    [fetchExchangeRateSuccess]: (state, action) => {
        state.exchangeRate = action.payload;
        state.isLoadingExchangeRate = false;
    },
    [fetchExchangeRateFailure]: (state) => {
        state.isLoadingExchangeRate = false;
    },

    [clearResetNewTransactionFormState]: (state) => {
        state.resetNewTransactionFormState = undefined;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
