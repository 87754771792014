import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import Address from './Address.layout';


const mapStateToProps = (state) => ({ formData: state[beneficiaryAddReducerName].formData });


const decorate = compose(
    withTranslation('beneficiaries'),
    connect(mapStateToProps),
);

export default decorate(Address);
