import FlagAndValue from 'components/common/atoms/FlagAndValue';
import ClientRenderer from 'components/common/molecules/value-renderers/ClientRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanIconRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { FeeType } from 'constants/FeeModel';
import { enumValueToReadableString, removeUnderscores } from 'utils/string-tools';

import React from 'react';


export const createFeeDetailsFields = (feeDetails, feeTiersDetails, t) => {
    const isManualFee = feeDetails?.fee_type === FeeType.MANUAL_FEE;

    if (feeDetails?.ext_id) {
        return [
            {
                value: <StandardValue value={feeDetails.ext_id} />,
                label: t('fees:feeFields.feeUuid'),
            },
            ...(!isManualFee ? [{
                value: <StandardValue value={removeUnderscores(feeDetails.charge_type)} />,
                label: t('fees:feeFields.feeChargeType'),
            }] : []),
            {
                value: <StandardValue value={removeUnderscores(feeDetails.fee_type)} />,
                label: t('fees:feeFields.feeType'),
            },
            {
                value: <StandardValue value={feeDetails.name} />,
                label: t('fees:feeFields.feeName'),
            },
            {
                value: <StandardValue value={feeDetails.description} />,
                label: t('fees:feeFields.feeDescription'),
            },
            ...(isManualFee ? [{
                value: <FlagAndValue currencyCode={feeTiersDetails?.fee_currency} text={`${feeTiersDetails?.fee_amount_fixed} ${feeTiersDetails?.fee_currency}`} showTooltip />,
                label: t('fees:feeFields.feeAmount'),
            }] : []),
            {
                label: t('fees:feeFields.feeEnabled'),
                value: booleanIconRenderer(feeDetails, 'enabled', t),
            },
            {
                label: t('feeFields.recurrencePeriod'),
                value: enumValueToReadableString(feeDetails?.recurrence_period),
            },
        ];
    }

    return [];
};

export const createSuperAdminOnlyFeeDetailsFields = (feeDetails, t) => {
    const isManualFee = feeDetails?.fee_type === FeeType.MANUAL_FEE;

    if (feeDetails?.ext_id) {
        return [
            ...(!isManualFee && feeDetails.fee_type === FeeType.TRANSACTION_FEE ? [
                {
                    value: <StandardValue value={feeDetails.transaction_type} />,
                    label: t('Transaction type'),
                }] : []),

            ...(!isManualFee ? [
                {
                    label: t('Hidden'),
                    value: booleanIconRenderer(feeDetails, 'hidden', t),
                },
                {
                    label: t('Included in minimum'),
                    value: booleanIconRenderer(feeDetails, 'included_in_minimum', t),
                }] : []),

            ...(!isManualFee ? [{
                value: <StandardValue value={feeDetails.ext_institution_charged_id} />,
                label: t('Ext. institution charged ID'),
            }] : []),

            {
                value: <StandardValue value={feeDetails.ext_institution_id} />,
                label: t('Ext. institution ID'),
            },

            ...(!isManualFee ? [{
                value: <ClientRenderer clientId={feeDetails.charged_client_id} />,
                label: t('Charged client ID'),
            }] : []),

            {
                value: <ClientRenderer clientId={feeDetails.client_id} />,
                label: t('Client ID'),
            },
        ];
    }

    return [];
};
export default {
    createFeeDetailsFields,
    createSuperAdminOnlyFeeDetailsFields,
};
