import BusinessUserStatusTag from 'components/common/atoms/tags/BusinessUserStatusTag';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { businessUserRootRoutePath } from 'config/routes';
import { createBusinessUserDetailsPath } from 'config/routes.helpers';
import { sortingOptions } from 'utils/table-columns-sorters';

import React from 'react';


function createBusinessUsersListColumns({
    dispatchRequestNavigation,
    t,
    queryParams,
}) {
    return [
        {
            title: t('ID'),
            dataIndex: 'business_user_id',
            defaultSortOrder: 'ascend',
            align: 'right',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({ queryParams, fieldName: 'business_user_id' }),
        },
        {
            title: t('common:personalFields.firstName.label'),
            dataIndex: 'first_name',
            render: (text, record) => (
                <StandardLink
                    path={createBusinessUserDetailsPath(record.business_user_id)}
                    title={record.first_name}
                    showTooltip
                />
            ),
            ...sortingOptions({ queryParams, fieldName: 'first_name' }),
        },
        {
            title: t('common:personalFields.lastName.label'),
            dataIndex: 'last_name',
            render: (text, record) => (
                <StandardLink
                    path={`${businessUserRootRoutePath}/${record.business_user_id}`}
                    title={record.last_name}
                    showTooltip
                />
            ),
            ...sortingOptions({ queryParams, fieldName: 'last_name' }),
        },
        {
            title: 'Status',
            dataIndex: 'business_user_status',
            align: 'center',
            render: (text) => <BusinessUserStatusTag status={text} />,
        },
        {
            title: t('common:personalFields.email.label'),
            dataIndex: 'business_user_email',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({ queryParams, fieldName: 'email' }),
        },
        {
            title: t('common:personalFields.phoneNumber.label'),
            dataIndex: 'business_user_phone_number',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.PHONE} />,
            ...sortingOptions({ queryParams, fieldName: 'business_user_phone_number' }),
        },
        {
            title: 'External User UUID',
            dataIndex: 'business_user_uuid',
            render: (text) => <StandardValue value={text} />,
        },
        {
            title: 'Is Stakeholder',
            align: 'center',
            dataIndex: 'is_stakeholder',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.BOOLEAN} />,
        },
        {
            title: 'Date Created',
            dataIndex: 'created_at',
            align: 'center',
            render: (text) => <StandardValue value={text} valueType="DATE" />,
            ...sortingOptions({ queryParams, fieldName: 'created_at' }),
        },
        {
            title: t('common:table.columnNames.actions'),
            align: 'center',
            render: (_, record) => viewButtonRenderer({
                record,
                onClick: (event, record) => dispatchRequestNavigation(createBusinessUserDetailsPath(record.business_user_id)),
                tooltipContent: 'View business user details',
                dataTestIdSuffix: 'business-user',
            }),
        },
    ];
}
export default createBusinessUsersListColumns;
