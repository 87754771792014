/* eslint-disable class-methods-use-this */
import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';
import { camelToSnakeCase } from 'utils/string-tools';

import { getBusinessUserIdDetails, getBusinessUserStatusHistoryAPI, getBusinessUsersAPI, updateBusinessUserId, updateBusinessUserStatusAPI } from './index';


class BusinessUsersService {
    public getBusinessUserDetails(businessUserId): Promise<ApiResponse | ApiError> {
        return getBusinessUserIdDetails(businessUserId);
    }

    public updateBusinessUser(requestPayload): Promise<ApiResponse | ApiError> {
        const { businessUserId, ...rest } = requestPayload;

        return updateBusinessUserId(businessUserId, rest);
    }

    public getBusinessUsers(rawQueryParams): Promise<ApiResponse | ApiError> {
        const {
            clientId,
            search,
            includeInactivePartners,
            sortOrder,
            sortBy,
            ...restOfQueryParams
        } = rawQueryParams;

        const queryParams = {
            ...restOfQueryParams,
            ...(sortBy ? { order: [`${sortOrder === SortDirections.DESCEND ? '-' : ''}${camelToSnakeCase(sortBy)}`] } : {}), // TODO v2+ Inconsistencies!!!
        };


        return getBusinessUsersAPI({ params: queryParams });
    }

    public getBusinessUserStatusHistory(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { businessUserId, sortOrder, ...rest } = rawQueryParams;
        return getBusinessUserStatusHistoryAPI(businessUserId, {
            params: {
                ...rest,
                ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
            },
        });
    }

    public updateBusinessUserStatus(businessUserId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateBusinessUserStatusAPI(businessUserId, requestPayload);
    }
}

export default BusinessUsersService;

