/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';
import { sortingDirectionMapper } from 'utils/string-tools';

import { NumericId, Uuid } from '@manigo/manigo-domain-typings';


import {
    fetchAllGroupsForClientId,
    assignMemberIdToGroupId,
    createGroup as createGroupAPI,
    fetchGroupIdDetails,
    editGroupIdName,
    assignPromotionIdsToGroupId,
    removePromotionIdFromGroupId,
    fetchPromotionsForGroupId,
    deleteGroupId,
    fetchMembersNotFormGroupId,
    fetchMembersFormGroupId, fetchGroupIdFees,
} from './index';


const filterQueryParams = (rawQueryParams) => {
    const { limit, offset, sortBy, sortOrder, search } = rawQueryParams;
    return {
        limit,
        offset,
        sortBy,
        ...(search?.length > 0 && {
            query: {
                last_name: search,
                first_name: search,
                email: search,
                phone_number: search,
            },
        }),
        sortOrder: sortingDirectionMapper(sortOrder),
    };
};


class GroupsService {
    public fetchAllGroups(clientId: NumericId): Promise<ApiResponse | ApiError> {
        return fetchAllGroupsForClientId(clientId);
    }

    public fetchGroupDetails(groupId): Promise<ApiResponse | ApiError> {
        return fetchGroupIdDetails(groupId);
    }

    public reassignMemberGroup(memberId: NumericId, groupId): Promise<ApiResponse | ApiError> {
        return assignMemberIdToGroupId(memberId, groupId);
    }

    public createGroup(clientId: NumericId, groupName: string): Promise<ApiResponse | ApiError> {
        const data = {
            name: groupName,
            clientId,
        };
        return createGroupAPI(data);
    }

    public editGroupName(groupId, newGroupName: string): Promise<ApiResponse | ApiError> {
        const data = { name: newGroupName };
        return editGroupIdName(groupId, data);
    }

    public assignPromotionsToGroup(groupId, promotionUuids: Uuid[]): Promise<ApiResponse | ApiError> {
    // XXX now those are UUIDs! TODO: amend endpoint params?
        return assignPromotionIdsToGroupId(groupId, { promotionIds: [...promotionUuids] });
    }

    public removePromotionFromGroup(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { groupId, promotionUuid } = rawQueryParams;
        return removePromotionIdFromGroupId(groupId, promotionUuid);
    }

    public fetchPromotionsForGroup(groupId): Promise<ApiResponse | ApiError> {
        return fetchPromotionsForGroupId(groupId);
    }

    public deleteGroup(groupId, newGroupId): Promise<ApiResponse | ApiError> {
        const newGroupIdAsQueryParam = newGroupId ? { params: { newGroupId } } : undefined;
        return deleteGroupId(groupId, newGroupIdAsQueryParam);
    }

    public fetchMembersFormGroup(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { groupId, ...rest } = rawQueryParams;

        return fetchMembersFormGroupId(groupId, { params: filterQueryParams(rest) });
    }

    public fetchMembersNotFormGroup(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { groupId, ...rest } = rawQueryParams;

        return fetchMembersNotFormGroupId(groupId, { params: filterQueryParams(rest) });
    }

    public fetchGroupFees(groupId): Promise<ApiResponse | ApiError> {
        return fetchGroupIdFees(groupId);
    }
}

export default GroupsService;
