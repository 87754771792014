import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearGroupsList, fetchGroupsList, openCreateNewGroupModal } from 'redux/groups/actions';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import GroupsList from './GroupsList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: groupsReducerName,
        listsByLocationStoreKeyName: groupsListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),

    dispatchOpenCreateNewGroupModal: ({ clientId, locationPathname }) => dispatch(openCreateNewGroupModal({ clientId, locationPathname })),

    dispatchFetchGroupsList: (queryParams, locationPathname) => dispatch(fetchGroupsList(queryParams, locationPathname)),
    dispatchClearGroupsList: (locationPathname) => dispatch(clearGroupsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: groupsReducerName,
        fieldName: groupsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('groups'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(GroupsList);
