import MemberStatusTag from 'components/common/atoms/tags/MemberStatusTag/MemberStatusTag';
import StandardLink from 'components/common/molecules/StandardLink';
import ClientRenderer from 'components/common/molecules/value-renderers/ClientRenderer';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createMemberDetailsPath } from 'config/routes.helpers';
import { sortingOptions } from 'utils/table-columns-sorters';

import { MemberListItem, MemberStatus, MemberStatuses, SharedEntityStatuses } from '@manigo/manigo-domain-typings';
import React from 'react';

// Sortable columns
// member_id, client_id, first_name, last_name, email, phone_number, phone_country_id, status, created_at

export default ({
    queryParams,
    dispatchRequestNavigation,
    showPartnerColumn,
    t,
}) => {
    const baseColumns = [
        {
            title: t('common:table.columnNames.id'),
            dataIndex: 'memberId',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'memberId' }),
        },
        {
            title: 'First name',
            dataIndex: 'memberFirstName',
            render: (text, record) => (
                <StandardLink
                    title={text}
                    path={createMemberDetailsPath(record.memberId)}
                    filterValue={queryParams?.search}
                    showTooltip
                />
            ),
            ...sortingOptions({ queryParams, fieldName: 'memberFirstName' }),
        },
        {
            title: 'Last name',
            dataIndex: 'memberLastName',
            render: (text, record) => (
                <StandardLink
                    title={text}
                    path={createMemberDetailsPath(record.memberId)}
                    filterValue={queryParams?.search}
                    showTooltip
                />
            ),
            ...sortingOptions({ queryParams, fieldName: 'memberLastName' }),
        },
        {
            title: 'DoB',
            dataIndex: 'memberDateOfBirth',
            render: (text) => <StandardValue value={text} valueType="DATE" showTime={false} showTimeInTooltip={false} />,
        },
        {
            title: 'Phone number',
            dataIndex: 'memberPhoneNumber',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} valueType="PHONE" />,
            ...sortingOptions({ queryParams, fieldName: 'memberPhoneNumber' }),
        },
        {
            title: 'Phone country',
            dataIndex: 'memberPhoneCountryId',
            render: (text) => <CountryRenderer propertyName="id" value={text} showTooltip />,
            ...sortingOptions({ queryParams, fieldName: 'memberPhoneCountryId' }),
        },
        {
            title: t('member:memberFields.email'),
            dataIndex: 'memberEmail',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'memberEmail' }),
        },
    ];

    const partnerColumn = [{
        title: 'Member of',
        dataIndex: 'clientId',
        render: (_, record) => <ClientRenderer clientId={record.clientId} />,
        ...sortingOptions({ queryParams, fieldName: 'clientId' }),
    }];

    const statusColumn = [
        {
            title: 'Status',
            dataIndex: 'memberStatus',
            align: 'center',
            render: (memberStatus: MemberStatus) => <MemberStatusTag status={memberStatus} t={t} />,
            ...sortingOptions({ queryParams, fieldName: 'memberStatus' }),
            filters: Object.values([
                MemberStatuses.ACTIVE,
                MemberStatuses.BANNED,
                SharedEntityStatuses.CLOSED,
                MemberStatuses.DORMANT,
                MemberStatuses.INVITED,
                MemberStatuses.KYC_MANUAL_REVIEW,
                MemberStatuses.KYC_PENDING,
                MemberStatuses.KYC_REJECTED,
                MemberStatuses.KYC_UPLOADED,
                SharedEntityStatuses.PENDING_CLOSURE,
                MemberStatuses.SUSPENDED,
                MemberStatuses.UNVERIFIED,
            ]).map((status) => ({ text: t(`member:memberStatuses.${status}`), value: status })),
            filteredValue: queryParams?.memberStatus ?? undefined,
            filterMultiple: true,
        },
    ];


    const dateCreatedColumn = [{
        title: 'Date created',
        dataIndex: 'createdAt',
        render: (date) => <StandardValue value={date} valueType="DATE" />,
        ...sortingOptions({ queryParams, fieldName: 'createdAt' }),
    }];

    const actionsColumn = [{
        title: t('common:table.columnNames.actions'),
        align: 'center',
        render: (_, record: MemberListItem) => viewButtonRenderer({
            record,
            onClick: (event, record: MemberListItem) => dispatchRequestNavigation(createMemberDetailsPath(record.memberId)),
            tooltipContent: 'View member details',
            dataTestIdSuffix: 'member',
        }),
    }];


    return [
        ...baseColumns,
        ...statusColumn,
        ...dateCreatedColumn,
        ...(showPartnerColumn ? partnerColumn : []),
        ...actionsColumn,
    ];
};

