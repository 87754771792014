import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import { Integrations } from '@sentry/tracing';

import { baseApiUrl } from './config';
import packageJson from '../../package.json';


const initializeSentry = (history) => {
    if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.init({
            dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
            release: `manigo-backoffice-frontend@${packageJson.version}`,
            tracesSampleRate: 0.6,
            ignoreErrors: ['ResizeObserver loop limit exceeded'],
            integrations: [
                new Integrations.BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(history) }),
                new Replay({
                    // This sets the sample rate to be 10%. You may want this to be 100% while
                    // in development and sample at a lower rate in production
                    sessionSampleRate: 0.1,

                    // If the entire session is not sampled, use the below sample rate to sample
                    // sessions when an error occurs.
                    errorSampleRate: 1.0,

                    // Mask all text content with asterisks (*). Passes text
                    // content through to `maskTextFn` before sending to server.
                    //
                    // Defaults to true, uncomment to change
                    // maskAllText: true,

                    // Block all media elements (img, svg, video, object,
                    // picture, embed, map, audio)
                    //
                    // Defaults to true, uncomment to change
                    // blockAllMedia: true,

                    networkDetailAllowUrls: [baseApiUrl],
                }),
            ],
        });
    }
};


export default initializeSentry;
