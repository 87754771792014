import { onInputKeyDown } from 'components/common/organisms/DigitsCodeInput/DigitsCodeInput.helpers';
import noop from 'utils/function-tools';

import { Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function DigitInput({
    hasError,
    dispatchSetPasscodeDigit,
    dispatchClearAllPasscodeDigits,
    formItem,
    value,
    onPaste,
}) {
    const onKeyDown = (event) => onInputKeyDown({
        event,
        formItem,
        dispatchSetPasscodeDigit,
    });


    return (
        <Input
            key={formItem.key}
            ref={formItem.ownRef}
            autoFocus={formItem.autoFocus}
            type="text"
            value={value}
            status={hasError ? 'error' : undefined}
            data-test-id={formItem.key}
            onKeyDown={onKeyDown}
            onChange={noop}
            onPaste={onPaste}
            onFocus={() => (hasError
                ? dispatchClearAllPasscodeDigits()
                : undefined)}
        />
    );
}

DigitInput.propTypes = {
    formItem: PropTypes.shape({
        key: PropTypes.string,
        autoFocus: PropTypes.bool,
        ownRef: PropTypes.shape({}),
    }),
    dispatchSetPasscodeDigit: PropTypes.func,
    dispatchClearAllPasscodeDigits: PropTypes.func,
    onPaste: PropTypes.func,
    hasError: PropTypes.bool,
    value: PropTypes.string,
};
export default DigitInput;
