import { standardPageSize } from 'config/config';
import { companyRootRoutePath, stakeholdersRouteSegmentPath } from 'config/routes';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchStakeholdersList,
    fetchStakeholdersListSuccess,
    fetchStakeholdersListFailure, clearStakeholdersList,
} from './actions';


export const businessStakeholdersReducersName = 'businessStakeholders';
export const businessStakeholdersListsPerLocationStoreKeyName = 'businessStakeholdersListsPerLocation';


export const defaultStakeholdersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    companyId: undefined,
    ...withTotalCount,
};

const initialListState = {
    [businessStakeholdersListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState },
    //
};


const initialState = { ...initialListState };

const handlers = {
    [fetchStakeholdersList]: (state, action) => {
        state[businessStakeholdersListsPerLocationStoreKeyName] = updateStandardLists(state[businessStakeholdersListsPerLocationStoreKeyName], action);
    },
    [fetchStakeholdersListSuccess]: (state, action) => {
        state[businessStakeholdersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[businessStakeholdersListsPerLocationStoreKeyName], action);
    },
    [fetchStakeholdersListFailure]: (state, action) => {
        state[businessStakeholdersListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[businessStakeholdersListsPerLocationStoreKeyName], action);
    },
    [clearStakeholdersList]: (state, action) => {
        state[businessStakeholdersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(
            state[businessStakeholdersListsPerLocationStoreKeyName],
            action.payload.locationPathname,
        );
    },
    [preformNavigationSuccess]: (state, action) => {
        state[businessStakeholdersListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[businessStakeholdersListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [stakeholdersRouteSegmentPath],
                observedRootPaths: [companyRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === businessStakeholdersReducersName
      && action.payload?.fieldName === businessStakeholdersListsPerLocationStoreKeyName
        ) {
            state[businessStakeholdersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[businessStakeholdersListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
