/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import {
    createApplicationAPI,
    deleteApplicationId,
    fetchApplicationIdDetails,
    fetchApplicationsAPI,
    updateApplicationId,
} from './index';


class ApplicationsService {
    public fetchApplications(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, search, sortOrder, ...rest } = rawQueryParams;

        // TODO for now used only as pre condition for roles fetching
        // eventually this has to be amended to cope with regular list (pagination, sorting)
        const query = {
            params: {
                ...(clientId && clientId > 0 ? { clientId } : {}),
                // ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? 'desc' : 'asc'}` }), // TODO v2 Inconsistencies,
                ...rest,
                // ...withTotalCount
            },
        };
        return fetchApplicationsAPI(query);
    }

    public fetchApplicationDetails(applicationId): Promise<ApiResponse | ApiError> {
        return fetchApplicationIdDetails(applicationId);
    }

    public createApplication(requestPayload): Promise<ApiResponse | ApiError> {
        return createApplicationAPI(requestPayload);
    }

    public deleteApplication(applicationId): Promise<ApiResponse | ApiError> {
        return deleteApplicationId(applicationId);
    }

    public updateApplication(applicationId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateApplicationId(applicationId, requestPayload);
    }
}

export default ApplicationsService;
