import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_BUSINESS_USER_DETAILS,
    FETCH_BUSINESS_USER_DETAILS,
    FETCH_BUSINESS_USER_DETAILS_FAILURE,
    FETCH_BUSINESS_USER_DETAILS_SUCCESS,
    UPDATE_BUSINESS_USER, UPDATE_BUSINESS_USER_FAILURE,
    UPDATE_BUSINESS_USER_SUCCESS,
} from './action.types';


export const fetchBusinessUserDetails = createAction(FETCH_BUSINESS_USER_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchBusinessUserDetailsSuccess = createAction(FETCH_BUSINESS_USER_DETAILS_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchBusinessUserDetailsFailure = createAction(FETCH_BUSINESS_USER_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearBusinessUserDetails = createAction(CLEAR_BUSINESS_USER_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));

export const updateBusinessUser = createAction(UPDATE_BUSINESS_USER, (formData, listOfOriginLocationPathname) => ({ payload: { formData, listOfOriginLocationPathname } }));
export const updateBusinessUserSuccess = createAction(UPDATE_BUSINESS_USER_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const updateBusinessUserFailure = createAction(UPDATE_BUSINESS_USER_FAILURE);

