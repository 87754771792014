import images from 'assets/images/images';
import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';
import Styled from 'components/pages/authorised/CardDetails/CardDetails.styled';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps } from 'constants/ApplicationStateModel';
import { CardDetailsProps, CardType } from 'constants/CardModel';
import { ClientListItemProps } from 'constants/ClientModel';
import { PN } from 'constants/PermissionsModel';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import { createAccountDetailsFieldsConfig, createCardDetailsFieldsConfig } from './CardInfo.helpers';


function CardInfo({
    cardId,
    accessControl,
    isInReadOnlyMode,
    queryParamsMeta,
    onRefresh,
    areDetailsReady,
    isLoading,
    clients,
    accountDetailsData,
    cardDetails,
    isLoadingDeleteCard,
    dispatchDeleteCard,
    dispatchFetchAccountById,
    t,
    cardTiers,
}) {
    useEffect(() => {
        if (cardDetails?.account_id && accessControl.hasPermission(PN.ACCOUNTS.READ_BY_ID)) {
            dispatchFetchAccountById({ accountId: cardDetails?.account_id });
        }
    }, [cardDetails]);

    const clientName = useMemo(
        () => {
            const client = clients.find((client) => client.id === cardDetails?.client_id);
            return `${client?.company_dba_name} ${client?.company_business_type}`;
        },
        [clients, cardDetails],
    );

    const cardTierName = useMemo(() => {
        return cardTiers?.find(tier => tier.ext_card_tier_id === cardDetails?.card_tier_uuid)?.card_tier_name;
    }, [cardTiers, cardDetails?.card_tier_uuid]);


    const cardDetailsFieldsConfig = useMemo(
        () => createCardDetailsFieldsConfig(
            cardDetails,
            clientName,
            cardTierName,
            t,
        ),
        [cardDetails, clientName],
    );

    const accountDetailsFieldsConfig = useMemo(
        () => createAccountDetailsFieldsConfig(
            accountDetailsData,
            t,
        ),
        [accountDetailsData],
    );

    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={!areDetailsReady && isLoading}
                minHeight={'370px'}
                sectionsRowsCount={8}
                sectionsCount={3}
            >
                <Spin spinning={isLoadingDeleteCard}>
                    <Row gutter={24} size={10} direction="horizontal">
                        <Styled.CardImageWrapper>
                            <Styled.CardImage
                                isInReadOnlyMode={isInReadOnlyMode}
                                src={cardDetails ? cardDetails.image_url : images.noCardImagePlaceholder}
                                alt={''}
                            />
                        </Styled.CardImageWrapper>

                        {/* Card Details */}
                        {cardDetails?.type && (
                            <CommonSection
                                sectionLabel={t('cardDetails.tabs.general.subHeadings.cardDetails')}
                                fieldsConfig={cardDetailsFieldsConfig}
                            />
                        )}

                        {accountDetailsData?.type && (
                            <CommonSection
                                sectionLabel={t('cardDetails.tabs.general.subHeadings.accountDetails')}
                                fieldsConfig={accountDetailsFieldsConfig}
                            />
                        )}

                        {cardDetails?.type === CardType.VIRTUAL
                            && accessControl.hasPermission(PN.CARDS.DELETE)
                            && !isInReadOnlyMode
                            && (
                                <Styled.DeleteButtonWrapper>
                                    <Popconfirm
                                        title={t('buttons.deleteCard.confirmationQuestion')}
                                        onConfirm={() => dispatchDeleteCard(cardDetails?.id)}
                                    >
                                        <Button
                                            type="primary"
                                            danger
                                            icon={<DeleteOutlined />}
                                            loading={isLoadingDeleteCard}
                                        >
                                            {t('buttons.deleteCard.text')}
                                        </Button>

                                    </Popconfirm>
                                </Styled.DeleteButtonWrapper>
                            )}
                    </Row>

                    <QueryParamsMetaInfo
                        detailsContext
                        onListRefresh={onRefresh}
                        queryParams={{ cardId }}
                        queryParamsMeta={queryParamsMeta}
                    />
                </Spin>
            </EntityDetailsInfoSkeleton>
        </Col>

    );
}

CardInfo.propTypes = {
    cardId: PropTypes.bool.isRequired,
    accessControl: AccessControlProps,
    cardDetails: CardDetailsProps,
    areDetailsReady: PropTypes.bool.isRequired,
    isInReadOnlyMode: PropTypes.bool.isRequired,
    isLoadingDeleteCard: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    clients: PropTypes.arrayOf(ClientListItemProps).isRequired,
    accountDetailsData: PropTypes.shape({
        id: PropTypes.number,
        balance: PropTypes.number,
        type: PropTypes.string,
    }).isRequired,
    dispatchDeleteCard: PropTypes.func.isRequired,
    dispatchFetchAccountById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    queryParamsMeta: CommonQueryParamsMetaProps,
    onRefresh: PropTypes.func.isRequired,
};

export default CardInfo;
