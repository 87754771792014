import { timestampDiffInSeconds } from 'utils/date-time-tools';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';


import Styled from './AutoLogoutBanner.styled';


function AutoLogoutBanner({ t, dispatchClearCurrentUser }) {
    const [counter, setCounter] = useState();
    const deadline = Date.now() + 1000 * 60; // XXX 60s countdown

    function getTimeRemaining(endTime) {
        const total = endTime - Date.now();
        return timestampDiffInSeconds(total);
    }

    function updateClock(timeInterval) {
        const t = getTimeRemaining(deadline);
        setCounter(t);

        if (t <= 0) {
            clearInterval(timeInterval);
            dispatchClearCurrentUser();
        }
    }

    useEffect(() => {
        const timeInterval = setInterval(updateClock, 250);
        updateClock(timeInterval); // run function once at first to avoid delay

        return () => {
            clearInterval(timeInterval);
        };
    }, []);


    return (
        <Styled.AutoLogoutBannerWrapper>
            <Styled.AutoLogoutBanner>
                <Styled.WarningIcon />
                <Styled.Message>
                    <Trans
                        t={t}
                        i18nKey="autoLogoutBanner"
                        ns="common"
                        values={{ counter }}
                        components={{ strong: <Styled.Strong /> }}
                    />
                </Styled.Message>
            </Styled.AutoLogoutBanner>
        </Styled.AutoLogoutBannerWrapper>
    );
}


AutoLogoutBanner.propTypes = {
    t: PropTypes.func.isRequired,
    dispatchClearCurrentUser: PropTypes.func.isRequired,
};

export default AutoLogoutBanner;
