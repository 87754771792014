import { sortObjectKeysAlphabetically } from 'utils/table-columns-sorters';

import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


const { Panel } = Collapse;

function MyPermissions({
    permissions = {},
    t,
}) {
    const topLevelPermissionKeys = Object.keys(sortObjectKeysAlphabetically(permissions));

    const renderPermission = (permissionKey, index) => (

        <Panel
            header={permissionKey}
            key={index}
        >
            <pre>
                {JSON.stringify(permissions[permissionKey], null, 2)}
                {' '}
            </pre>
        </Panel>

    );

    return (
        <div>
            <h2>{t('My Permissions')}</h2>
            <Collapse>
                {topLevelPermissionKeys.map((permissionKeys, index) => renderPermission(permissionKeys, index))}
            </Collapse>
        </div>
    );
}

MyPermissions.propTypes = { t: PropTypes.func.isRequired, permissions: PropTypes.shape({}) };


export default MyPermissions;
