import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Styled from './StandardListSkeleton.styled';


function StandardListSkeleton({
    children,
    isLoading,
    columnsCount = 1,
    sectionsRowsCount = 10,
}) {
    return isLoading
        ? (
            <Styled.StandardListSkeletonWrapper>
                {[...Array(columnsCount).keys()].map((index) => (
                    <Skeleton
                        active
                        key={`standardList-skeleton-col-${index}`}
                        paragraph={{ rows: Math.min(sectionsRowsCount, 10), width: '100%' }}
                        loading
                        style={{ minHeight: '200px', height: '100%' }}
                        title={false}
                    />
                ))}
            </Styled.StandardListSkeletonWrapper>
        )
        : children;
}

StandardListSkeleton.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    columnsCount: PropTypes.number,
    sectionsRowsCount: PropTypes.number,
};
export default StandardListSkeleton;
