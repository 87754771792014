import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { SortDirections } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import noop from 'utils/function-tools';
import { removeUnderscores } from 'utils/string-tools';
import { sortingOptions, sortStringDates, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm, Space } from 'antd';
import React from 'react';


export default ({
    accessControl,
    dispatchUnregisterWebhook,
    dispatchOpenRegisterOrEditWebhookModal,
    dispatchShowViewJsonModal,
    queryParams,
    enhancedCurrentLocation,
    t,
}) => [
    {
        title: t('webhooksFields.uuid.label'),
        dataIndex: 'uuid',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'uuid',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'uuid'),
        }),
        defaultSortOrder: SortDirections.ASCEND,
    },
    {
        title: t('webhooksFields.actionType.label'),
        dataIndex: 'action',
        render: (text) => <StandardValue value={removeUnderscores(text)} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'action',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'action'),
        }),
    },
    {
        title: t('webhooksFields.webhookURL.label'),
        dataIndex: 'webhookUrl',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'webhookUrl',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'webhookUrl'),
        }),
    },
    {
        title: t('webhooksFields.apiKey.label'),
        dataIndex: 'apiKey',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'apiKey',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'apiKey'),
        }),
    },
    {
        title: t('webhooksFields.headers.label'),
        dataIndex: 'headers',
        align: 'center',
        render: (text, record) => {
            if (record?.headers?.length > 0) {
                return viewButtonRenderer({
                    record,
                    onClick: (_, record) => dispatchShowViewJsonModal(
                        JSON.parse(record?.headers),
                        <StandardTranslationWithMarkup
                            translationKey={'modals.viewHeadersJsonModal.viewHeadersJsonModalTitle'}
                            namespace="webhooks"
                            values={{ webhookUuid: record?.uuid, actionType: record?.action }}
                            t={t}
                        />,
                    ),
                    tooltipContent: t('buttons.viewWebhookHeadersJson.text'),
                    dataTestId: 'view-headers',
                });
            }
            return '-';
        },
        ...sortingOptions({
            queryParams,
            fieldName: 'headers',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'headers'),
        }),
    },
    {
        title: t('webhooksFields.createdAt.label'),
        dataIndex: 'createdAt',
        align: 'center',
        render: (text) => <StandardValue value={text} valueType="DATE" showTime />,
        ...sortingOptions({
            queryParams,
            fieldName: 'createdAt',
            sorter: (a, b) => sortStringDates(a, b, 'createdAt'),
        }),
    },

    ...(accessControl.hasOneOfPermissions([
        PN.WEBHOOKS.UNREGISTER,
        PN.WEBHOOKS.UPDATE,
    ])
        ? [
            {
                title: 'Actions',
                dataIndex: '',
                align: 'center',
                render: (_, record) => (

                    <Space>
                        {accessControl.hasPermission(PN.WEBHOOKS.UNREGISTER)
              && (
                  <Popconfirm
                      title={(
                          <StandardTranslationWithMarkup
                              translationKey={'buttons.unregisterWebhook.confirmationQuestion'}
                              namespace="webhooks"
                              values={{ webhookUuid: record?.uuid, webhookActionType: record?.action }}
                              t={t}
                          />
                      )}
                      onConfirm={() => dispatchUnregisterWebhook(record.uuid, enhancedCurrentLocation?.pathname)}
                  >
                      {viewButtonRenderer({
                          record,
                          onClick: () => noop,
                          tooltipContent: t('buttons.unregisterWebhook.text'),
                          icon: <DeleteOutlined />,
                          dataTestId: 'unregister-webhook',
                      })}
                  </Popconfirm>
              )}

                        {accessControl.hasPermission(PN.WEBHOOKS.UPDATE)
              && viewButtonRenderer({
                  record,
                  onClick: (_, record) => dispatchOpenRegisterOrEditWebhookModal({ ...record }, enhancedCurrentLocation?.pathname),
                  tooltipContent: t('buttons.editWebhook.text'),
                  icon: <EditOutlined />,
                  dataTestId: 'edit-webhook',
              })}

                    </Space>
                ),
            },
        ]
        : []
    ),
];
