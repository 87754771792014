import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const InfoIconAndButtonWrapper = styled.div`
  display: flex;
  width: max-content;
  float: right;
  justify-content: space-between;
  align-items: center;
`;

const InfoIcon = styled(InfoCircleOutlined)`
  margin-right: 10px;
  cursor: help;
`;

export default {
    InfoIconAndButtonWrapper,
    InfoIcon,
};
