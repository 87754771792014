/* eslint-disable spellcheck/spell-checker */
import PropTypes from 'prop-types';

import { AddressCommonProps } from './AddressModel';
import { CompanyTypeProp } from './CompaniesModel';


export const ClientStatuses = Object.freeze({
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
});

export const ClientStatusProp = PropTypes.oneOf(Object.keys(ClientStatuses));

export const ClientContactProps = PropTypes.shape({
    address_line_1: PropTypes.string.isRequired,
    address_line_2: PropTypes.string,
    city: PropTypes.string.isRequired,
    country: PropTypes.number.isRequired, // XXX country ISO ID, !NOT! ISO code
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    position: PropTypes.string,
});

export const AnnouncementProps = PropTypes.shape({
    button_label: PropTypes.string,
    content: PropTypes.string,
    header: PropTypes.string,
});

export const LedgerConfigurationProps = PropTypes.shape({
    ledger_configuration: PropTypes.bool,
    ext_id: PropTypes.string,
    institution_name: PropTypes.string,
    status: PropTypes.string,
});


export const ClientCommonProps = {
    id: PropTypes.number.isRequired,
    active: ClientStatusProp,
    applicant_id: PropTypes.string,
    client_uid: PropTypes.string,
    company_business_type: PropTypes.string,
    company_dba_name: PropTypes.string,
    company_registration_number: PropTypes.string,
    company_vat_registration_number: PropTypes.string,
    ext_client_id: PropTypes.string,
    descriptor: PropTypes.string,
    website: PropTypes.string,
};

export const ClientListItemProps = PropTypes.shape({
    ...ClientCommonProps,
    logo_url: PropTypes.string,
    contact: ClientContactProps,
});
export const ClientDetailsProps = PropTypes.shape({

    ...ClientCommonProps,
    announcement: AnnouncementProps,
    assign_ibans_to_base: PropTypes.number,
    assign_ibans_to_business: PropTypes.number,
    assign_ibans_to_personal: PropTypes.number,
    contact: ClientContactProps,
    client_address: AddressCommonProps,
    client_uuid: PropTypes.string,
    company_business_type: CompanyTypeProp,
    company_legal_name: PropTypes.string,
    configuration: PropTypes.shape({
        '3_ds': PropTypes.shape({}),
        client: PropTypes.shape({}),
        firebase_configs: PropTypes.shape({}),
        general_settings: PropTypes.shape({}),
        ledger: PropTypes.shape({}),
        sendgrid_config: PropTypes.shape({}),
    }),
    countries_of_operation_risk: PropTypes.number,
    countries_of_operations: PropTypes.arrayOf(PropTypes.string),
    country_of_incorporation_risk: PropTypes.number,
    created_at: PropTypes.string,
    enabled: PropTypes.number,
    industries: PropTypes.arrayOf(PropTypes.number),
    invitation_enabled: PropTypes.bool,
    kyc_kyb_alias: PropTypes.string,
    ledger_configuration: LedgerConfigurationProps,
    operating_industries_risk: PropTypes.number,
    push_notification_title: PropTypes.string,
    send_card_order: PropTypes.number,
    statement_bottom_note: PropTypes.string,
    total_risk_score: PropTypes.number,
    updated_at: PropTypes.string,
});

export default {
    ClientStatuses,
    ClientStatusProp,
    ClientContactProps,
    ClientListItemProps,
    ClientDetailsProps,
};
