import MemberStatusTag from 'components/common/atoms/tags/MemberStatusTag/MemberStatusTag';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createMemberDetailsPath } from 'config/routes.helpers';
import { MemberStatuses } from 'constants/MemberModel';
import noop from 'utils/function-tools';
import { sortingOptions } from 'utils/table-columns-sorters';

import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';


function createGroupMembersTableColumns({
    t,
    queryParams,
    dispatchAddMemberToGroup,
    idsOfMembersCurrentlyBeingAddedToGroup,
    idsOfMembersSuccessfullyAddedToTheGroup,
    groupId,
    clientId,
    dispatchRequestNavigation,
    isNonMembersVersion,
}) {
    const baseColumns = [
        {
            title: t('common:table.columnNames.id'),
            dataIndex: 'member_id',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({ queryParams, fieldName: 'member_id' }),
        },
        {
            title: t('member:memberFields.firstName'),
            dataIndex: 'first_name',
            render: (text, record) => (dispatchAddMemberToGroup
                ? <StandardValue value={text} searchQuery={queryParams?.search} />
                : (
                    <StandardLink
                        title={text}
                        path={createMemberDetailsPath(record.member_id)}
                        filterValue={queryParams?.search}
                        showTooltip
                    />
                )),
            ...sortingOptions({ queryParams, fieldName: 'first_name' }),
        },
        {
            title: t('member:memberFields.lastName'),
            dataIndex: 'last_name',
            render: (text, record) => (dispatchAddMemberToGroup ? <StandardValue value={text} searchQuery={queryParams?.search} /> : (
                <StandardLink
                    title={text}
                    path={createMemberDetailsPath(record.member_id)}
                    filterValue={queryParams?.search}
                    showTooltip
                />
            )),
            ...sortingOptions({ queryParams, fieldName: 'last_name' }),
        },
        {
            title: t('member:memberFields.phoneNumber'),
            dataIndex: 'phone_number',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} valueType="PHONE" />,
            ...sortingOptions({ queryParams, fieldName: 'phone_number' }),
        },
        {
            title: t('member:memberFields.email'),
            dataIndex: 'email',
            key: 'email',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'email' }),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (status) => <MemberStatusTag status={status} t={t} />,
            ...sortingOptions({ queryParams, fieldName: 'status' }),
        },
    ];


    const addMemberColumn = {
        title: t('common:table.columnNames.actions'),
        key: 'add_member',
        align: 'center',
        render: (record) => {
            const memberWasSuccessfullyAdded = idsOfMembersSuccessfullyAddedToTheGroup.includes(record.member_id);
            const memberIsReadOnly = [MemberStatuses.PENDING_CLOSURE, MemberStatuses.CLOSED].includes(record.status);

            return memberIsReadOnly
                ? undefined
                : viewButtonRenderer({
                    record,
                    tooltipContent: t(`groups:buttons.${memberWasSuccessfullyAdded ? 'memberAdded' : 'addMember'}.text`),
                    dataTestIdSuffix: 'member',
                    isDisabled: memberWasSuccessfullyAdded,
                    isLoading: idsOfMembersCurrentlyBeingAddedToGroup.includes(record.member_id),
                    icon: memberWasSuccessfullyAdded ? <CheckOutlined /> : <PlusOutlined />,
                    onClick: () => (memberWasSuccessfullyAdded
                        ? noop
                        : dispatchAddMemberToGroup({ memberId: record.member_id, groupId, clientId })),
                });
        },
    };

    const viewMemberColumn = {
        title: t('common:table.columnNames.actions'),
        align: 'center',
        render: (_, record) => viewButtonRenderer({
            record,
            onClick: (event, record) => dispatchRequestNavigation(createMemberDetailsPath(record.member_id)),
            tooltipContent: 'View member details',
            dataTestIdSuffix: 'member',
        }),
    };

    return isNonMembersVersion
        ? [
            ...baseColumns,
            addMemberColumn,
        ]
        : [
            ...baseColumns,
            viewMemberColumn,
        ];
}


createGroupMembersTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    searchQuery: PropTypes.string,
    dispatchAddMember: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
};


export default createGroupMembersTableColumns;
