import PropTypes from 'prop-types';


export const ChargeFeeModalType = Object.freeze({
    CUSTOM_FEE: 'CUSTOM_FEE',
    MANUAL_FEE: 'MANUAL_FEE',
});

export const FeeType = Object.freeze({
    TRANSACTION_FEE: 'TRANSACTION_FEE',
    RECURRING_FEE: 'RECURRING_FEE',
    ACTION_FEE: 'ACTION_FEE',

    MANUAL_FEE: 'MANUAL_FEE',
});

export const FeeTypeProp = PropTypes.oneOf(Object.keys(FeeType));
