import { AccessControlProps } from 'constants/AccessControlModel';
import { filterOptions } from 'utils/filterOptions';
import { createClientOptions } from 'utils/options-creators';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function ClientSelect({
    clientsData,
    allowInactivePartners,
    name = 'client_id',
    accessControl,

    currentUserClientId,
    htmlId,
    label,
    placeholder = 'Select Partner',
    isRequired,
    isRequiredMessage,
    dependencies,
    initialValue,
    value,
    isFormItem = true,
    allowClear = false,
    isDisabled,
    onChange,
    onSelect,
    style,
    rules = [],
    hidden = false,
    tooltip,

    useUuid,
    t,
}) {
    const amendedName = useUuid ? 'clientExtId' : name;

    const clientOptions = useMemo(
        () => createClientOptions(
            clientsData?.items,
            accessControl.isSuperAdmin, // XXX allowed - client filter
            currentUserClientId,
            allowInactivePartners,
            useUuid,
        ),
        [clientsData, accessControl, currentUserClientId, allowInactivePartners],
    );


    const select = (
        <Select
            className="client-select"
            id={htmlId}
            showSearch={accessControl.isSuperAdmin} // XXX allowed - client filter logic
            onChange={onChange}
            onSelect={onSelect}
            style={style || { width: '100%' }}
            placeholder={placeholder}
            disabled={!accessControl.isSuperAdmin || isDisabled} // XXX allowed - client filter logic
            value={initialValue || value}
            filterOption={filterOptions}
            loading={clientsData.isLoadingList}
            allowClear={allowClear}
        >
            {clientOptions}
        </Select>
    );

    return isFormItem ? (
        <Form.Item
            key={`${amendedName}-form-item`}
            label={label}
            name={amendedName}
            dependencies={dependencies}
            initialValue={initialValue}
            hidden={hidden}
            tooltip={tooltip}
            rules={[
                {
                    required: isRequired,
                    message: isRequiredMessage || t('common:validationMsg.fieldMandatory'),
                },
                ...rules,
            ]}
        >
            {select}
        </Form.Item>
    ) : select;
}

ClientSelect.propTypes = {
    isFormItem: PropTypes.bool,
    allowClear: PropTypes.bool,
    isDisabled: PropTypes.bool,
    name: PropTypes.string,
    currentUserClientId: PropTypes.number,
    accessControl: AccessControlProps,
    isRequiredMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    htmlId: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    useUuid: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

export default ClientSelect;
