import IntegerInput from 'components/common/molecules/form-controls/IntegerInput';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { CompanyDetailsProps } from 'constants/CompaniesModel';
import { CountriesListProps, CountryIdType } from 'constants/CountriesModel';
import { createCountriesOptions } from 'utils/options-creators';

import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


const { TextArea } = Input;

const getCountryOfIncorporation = (value, countries) => countries.find((item) => item.iso_31663 === value);

function ChangeCompanyRiskScoreModal({
    initialValues,
    enhancedCurrentLocation,
    countries,
    dispatchAddCompanyRiskDetails,
    dispatchSetModalProps,
    t,
}) {
    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);


    const initialRiskScoreValue = useMemo(
        () => initialValues.company_total_risk_score,
        [initialValues],
    );

    const country = useMemo(
        () => {
            const countryCode = initialValues?.country_of_incorporation !== null
                ? initialValues?.country_of_incorporation
                : initialValues?.company_address?.country;
            return getCountryOfIncorporation(countryCode, countries);
        },
        [initialValues, countries],
    );

    const countryOption = useMemo(
        () => createCountriesOptions([country], CountryIdType.id),
        [country],
    );

    useEffect(() => {
        if (initialRiskScoreValue) {
            form.setFieldsValue({ to: initialRiskScoreValue });
        }
    }, [initialRiskScoreValue]);

    const onFinish = (formValues) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchAddCompanyRiskDetails(initialValues.company_id, { ...formValues, to: Number(formValues.to) }, baseUrlWithoutTabsSlugs);
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
        >
            <Form.Item
                name="country_id"
                label="Country of Incorporation"
                initialValue={country?.id}
            >
                <Select
                    bordered={false}
                    disabled
                >
                    {countryOption}
                </Select>
            </Form.Item>

            <Form.Item
                name="to"
                label="New Risk Score"
                rules={[
                    { required: true, message: t('common:validationMsg.fieldMandatory') },
                    () => ({
                        validator: async (_, value) => {
                            if (value === initialValues.country_risk) {
                                throw new Error('Please enter a new risk score');
                            }
                        },
                    }),
                ]}
            >
                <IntegerInput
                    min={0}
                    max={100}
                    step={1}
                    precision={0}
                    style={{ width: '100%' }}
                    placeholder="Enter new risk score"
                />
            </Form.Item>

            <Form.Item
                name="comment"
                label="Comment"
                rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
            >
                <TextArea placeholder="Enter a comment with a reason for the change" />
            </Form.Item>
        </Form>
    );
}

ChangeCompanyRiskScoreModal.propTypes = {
    initialValues: CompanyDetailsProps.isRequired,
    countries: CountriesListProps,
    dispatchAddCompanyRiskDetails: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default ChangeCompanyRiskScoreModal;
