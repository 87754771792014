import TabSubHeading from 'components/common/atoms/TabSubHeading';
import PermissionRecordDisplay from 'components/common/organisms/forms-shared-parts/add-edit-role-form/PermissionRecordDisplay';
import RoleTemplateDetailsDisplay from 'components/common/organisms/forms-shared-parts/add-edit-role-form/RoleTemplateDetailsDisplay/RoleTemplateDetailsDisplay.layout';
import { isUniqueValue } from 'utils/validation-tools';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Input, Switch, Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Styled from './ReviewStep.styled';


function ReviewStep({
    rolesTemplatesLists = [],
    dispatchToggleSaveRoleAsRoleTemplate,
    selectedPermissions,
    saveRoleAsRoleTemplate,
    t,
}) {
    const formInstance = Form.useFormInstance();
    const details = formInstance.getFieldsValue(true);

    const existingRolesTemplatesNames = useMemo(
        () => rolesTemplatesLists.map((rolesTemplate) => rolesTemplate.details.name),
        [rolesTemplatesLists],
    );

    return (
        <div>
            <Styled.SaveAndDetailsWrapper>
                <div>
                    <TabSubHeading text={t('Save role as template')} />
                    <Styled.SaveRoleAsRoleTemplateWrapper>
                        <Switch
                            onChange={() => dispatchToggleSaveRoleAsRoleTemplate()}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
            Do tou want to store this configuration as a new template?
                    </Styled.SaveRoleAsRoleTemplateWrapper>
                    <Styled.RoleNameWrapper>
                        {saveRoleAsRoleTemplate && (
                            <Form.Item
                                name="template.name" // check which field to send here
                                label="New Role template Name"
                                rules={[
                                    {
                                        required: saveRoleAsRoleTemplate,
                                        message: 'Enter new role template name is a required field',
                                    },
                                    ...isUniqueValue({
                                        t,
                                        existingValues: existingRolesTemplatesNames,
                                    }),
                                ]}
                            >
                                <Input placeholder={t('Enter new role template name')} />
                            </Form.Item>
                        )}
                    </Styled.RoleNameWrapper>
                </div>

                <div>
                    <TabSubHeading text={t('Role Details')} />
                    <RoleTemplateDetailsDisplay details={details} t={t} />
                </div>
            </Styled.SaveAndDetailsWrapper>

            <div>
                <TabSubHeading text={t('Permissions set')} />
                <Styled.SelectedPermissionsWrapper>
                    {(selectedPermissions).map((permission) => <PermissionRecordDisplay permission={permission} key={permission.id} />)}
                </Styled.SelectedPermissionsWrapper>

            </div>

        </div>
    );
}

ReviewStep.propTypes = {
    selectedPermissions: PropTypes.arrayOf(PropTypes.shape({})),
    t: PropTypes.func,
    dispatchToggleSaveRoleAsRoleTemplate: PropTypes.func,
};

export default ReviewStep;
