import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    fetchFeeTiersListSuccess,
    fetchFeeTiersListFailure,
} from './actions';
import { FETCH_FEE_TIERS_LIST } from './actions.types';


export const onFetchFeeTiers = (action$, state$, { fees }) => action$.pipe(
    ofType(FETCH_FEE_TIERS_LIST),
    switchMap(({ payload }) => from(fees.fetchFeeTiers(payload.queryParams)).pipe(
        switchMap((response) => of(fetchFeeTiersListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchFeeTiersListFailure(payload.locationPathname))),
    )),
);

export default [onFetchFeeTiers];
