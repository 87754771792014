/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import {
    fetchCardIdSecure3dStatus,
    updateCardIdSecure3dStatus,
    fetchCardIdSecure3dActivityLog,
} from './index';


class CardSecure3dService {
    public fetchCardSecure3dStatus(cardId): Promise<ApiResponse | ApiError> {
        return fetchCardIdSecure3dStatus(cardId);
    }

    public updateCardSecure3dStatus(cardId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateCardIdSecure3dStatus(cardId, requestPayload);
    }

    public fetchCardSecure3dActivityLog(cardId): Promise<ApiResponse | ApiError> {
        return fetchCardIdSecure3dActivityLog(cardId);
    }
}

export default CardSecure3dService;
