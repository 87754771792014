import StandardTag from 'components/common/atoms/StandardTag';

import PropTypes from 'prop-types';
import React from 'react';


import { mapSecure3dActionLogToLabelAndColor } from './Secure3dActionTag.helpers';


function Secure3dActionTag({
    logRecord,
    t,
}) {
    if (logRecord) {
        const data = mapSecure3dActionLogToLabelAndColor(logRecord, t);

        return <StandardTag color={data.color} text={data.label} />;
    }

    return <>-</>;
}

Secure3dActionTag.propTypes = {
    logRecord: PropTypes.shape({
        enrolled: PropTypes.bool.isRequired,
        unenrolled_at: PropTypes.string,
        enrolled_at: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
};

export default Secure3dActionTag;
