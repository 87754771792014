import { HttpService } from 'services/http';


export const fetchApiKeysAPI = (queryParams = {}) => HttpService.get('/v2/api-keys', queryParams);

export const createApiKeyAPI = (requestPayload) => HttpService.post('/v2/api-keys', requestPayload);

export const deleteApiKeyUuid = (apiKeyUuid) => HttpService.delete(`/v2/api-keys/${apiKeyUuid}`);

export const fetchApiKeyKeValueAPI = (apiKeyUuid) => HttpService.get(`/v2/api-keys/${apiKeyUuid}/key-value`);
