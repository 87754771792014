import FlagAndValue from 'components/common/atoms/FlagAndValue';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';
import { AccountsListItemProps } from 'constants/AccountModel';

import PropTypes from 'prop-types';
import React from 'react';


function CommonAccountDetails({ t, accountDetails }) {
    return (
        <div>
            <LabelAndValueRow
                label={t('accountFields.accountCurrency.label')}
                value={(
                    <FlagAndValue
                        currencyCode={accountDetails.currency}
                        text={accountDetails.currency}
                        valueFirst
                    />
                )}
            />
            <LabelAndValueRow
                label={t('accountFields.accountType.label')}
                value={accountDetails.type}
            />
            <LabelAndValueRow
                label={t('accountFields.accountName.label')}
                value={accountDetails.name}
            />
            <LabelAndValueRow
                label={t('accountFields.availableBalance.label')}
                value={(
                    <FlagAndValue
                        currencyCode={accountDetails.currency}
                        text={(
                            <>
                                <StandardValue value={accountDetails.available_balance} valueType="AMOUNT" />
                                {' '}
                                {accountDetails.currency}
                            </>
                        )}
                        valueFirst
                    />
                )}
            />
            <LabelAndValueRow
                label={t('accountFields.blockedBalance.label')}
                value={(
                    <FlagAndValue
                        currencyCode={accountDetails.currency}
                        text={(
                            <>
                                <StandardValue value={accountDetails.blocked_balance} valueType="AMOUNT" />
                                {' '}
                                {accountDetails.currency}
                            </>
                        )}
                        valueFirst
                    />
                )}
            />
            <br />
        </div>
    );
}

CommonAccountDetails.propTypes = {
    t: PropTypes.func.isRequired,
    accountDetails: AccountsListItemProps,
};


export default CommonAccountDetails;
