import { SortDirections } from 'constants/ApplicationStateModel';
import { globalLocation } from 'constants/NavigationModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { fetchIndustriesList, fetchIndustriesListFailure, fetchIndustriesListSuccess, clearIndustriesList } from 'redux/industries/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const industriesReducerName = 'industries';
export const industriesListsPerLocationStoreKeyName = 'industriesListsPerLocation';


export const defaultIndustriesListQueryParams = {
    limit: 20,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};


const initialState = { [industriesListsPerLocationStoreKeyName]: { [globalLocation]: { queryParams: defaultIndustriesListQueryParams } } };


const handlers = {
    [fetchIndustriesList]: (state, action) => {
        state[industriesListsPerLocationStoreKeyName] = updateStandardLists(state[industriesListsPerLocationStoreKeyName], action);
    },
    [fetchIndustriesListSuccess]: (state, action) => {
        state[industriesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[industriesListsPerLocationStoreKeyName], action);
    },
    [fetchIndustriesListFailure]: (state, action) => {
        state[industriesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[industriesListsPerLocationStoreKeyName], action);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === industriesReducerName
      && action.payload?.fieldName === industriesListsPerLocationStoreKeyName
        ) {
            state[industriesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[industriesListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearIndustriesList]: (state, action) => {
        state[industriesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[industriesListsPerLocationStoreKeyName], action.payload?.locationPathname);
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
