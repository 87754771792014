import styled from 'styled-components';


const SelectPermissionsWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns:  minmax(0,1fr) minmax(0,1fr) ;
  grid-column-gap: 50px;
  white-space: nowrap;
  height: 100%;
`;


export default { SelectPermissionsWrapper };
