import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { clearGroupsList, fetchGroupsList } from 'redux/groups/actions';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { clearLimitsList, fetchLimits } from 'redux/limits/actions';
import { limitsListsPerLocationStoreKeyName, limitsReducerName } from 'redux/limits/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import LimitsList from './LimitsList.layout';
import { LimitsListDispatchProps, LimitsListStateProps } from './LimitsList.types';


const mapStateToProps = (state: RootState): LimitsListStateProps => ({
    currentClientExtId: state[currentUserReducerName].userData?.extClientUuid,

    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    clients: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    listData: getStandardListData({
        state,
        reducerName: limitsReducerName,
        listsByLocationStoreKeyName: limitsListsPerLocationStoreKeyName,
    }),

    groupsListData: getStandardListData({
        state,
        reducerName: groupsReducerName,
        listsByLocationStoreKeyName: groupsListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch:Dispatch): LimitsListDispatchProps => ({
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
    dispatchFetchLimitsList: (queryParams, locationPathname) => dispatch(fetchLimits(queryParams, locationPathname)),
    dispatchClearLimitsList: (locationPathname) => dispatch(clearLimitsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: limitsReducerName,
        fieldName: limitsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchFetchGroups: (queryParams, locationPathname) => dispatch(fetchGroupsList(queryParams, locationPathname)),
    dispatchClearGroupsList: (locationPathname) => dispatch(clearGroupsList(locationPathname)),
});

const decorate = compose<ComponentType>(
    withTranslation(),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(LimitsList);
