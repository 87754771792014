import OverdueInvoiceWarning from 'components/layouts/AuthorisedLayout/OverdueInvoiceWarning';
import ContextIndicator from 'components/layouts/AuthorisedLayout/StandardPageHeading/ClientContextIndicator';
import ReadOnlyIndicator from 'components/layouts/AuthorisedLayout/StandardPageHeading/ReadOnlyIndicator';
import UserDropdown from 'components/layouts/AuthorisedLayout/StandardPageHeading/UserDropdown';
import { WL_CONFIG } from 'config/config';
import { AccessControlProps } from 'constants/AccessControlModel';
import { ReadOnlyInfoProps } from 'constants/ApplicationStateModel';
import { capitalizeFirstLetter } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';


import AppPageHeader from './AppPageHeader';
import Styled from './StandardPageHeading.styled';


function StandardPageHeading({
    accessControl,
    clients,
    dispatchRequestNavigationBack,
    currentUserClientId,
    title,
    clientIdContext,
    showGoBack = true,
    readOnlyInfo,
    activeTabs,
    t,
}) {
    const amendName = (tabName) => ` - ${capitalizeFirstLetter(tabName)?.replace(/-/g, ' ')}`;
    const pageTitle = useMemo(
        () => (activeTabs && activeTabs?.length > 0
            ? `${WL_CONFIG.brandShortName} ${WL_CONFIG.applicationName} - ${title}${activeTabs.map((tabName) => amendName(tabName)).join('')}`
            : `${WL_CONFIG.brandShortName} ${WL_CONFIG.applicationName} - ${title}`),
        [title, activeTabs],
    );

    return (
        <>
            {/* XXX side effect -> 'document.title update */}
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <Styled.AppLayoutHeaderWrapper>
                <OverdueInvoiceWarning />
                <Styled.AppLayoutHeader>
                    <AppPageHeader
                        onBack={showGoBack ? dispatchRequestNavigationBack : undefined}
                        title={title}
                    />

                    <ReadOnlyIndicator readOnlyInfo={readOnlyInfo} t={t} />

                    {accessControl.isSuperAdmin // XXX allowed - client context related
          && clientIdContext
          && clientIdContext !== currentUserClientId
                        ? (
                            <ContextIndicator
                                clients={clients}
                                clientIdContext={clientIdContext}
                                accessControl={accessControl}
                            />
                        ) : (<span />)}

                    <UserDropdown />

                </Styled.AppLayoutHeader>
            </Styled.AppLayoutHeaderWrapper>
        </>

    );
}


StandardPageHeading.propTypes = {
    showGoBack: PropTypes.bool,
    title: PropTypes.string,
    activeTabs: PropTypes.arrayOf(PropTypes.string),
    clientIdContext: PropTypes.number,
    currentUserClientId: PropTypes.number.isRequired,
    accessControl: AccessControlProps,
    dispatchRequestNavigationBack: PropTypes.func.isRequired,
    readOnlyInfo: ReadOnlyInfoProps,
    t: PropTypes.func.isRequired,
};

export default StandardPageHeading;
