import StandardListTable from 'components/common/organisms/StandardListTable';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultGroupsListQueryParams } from 'redux/groups/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createGroupsTableColumns from './createTableColumns';


function RiskScoreHistoryList({
    // own props
    clientId,
    companyId,

    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    accessControl,
    dispatchFetchRiskScoreHistoryList,
    dispatchClearRiskScoreHistoryList,
    dispatchSetQueryParams,
    dispatchRequestNavigation,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ...(clientId > 0 ? { clientId } : {}),
        ...(companyId > 0 ? { companyId } : {}),
    };


    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };


    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultGroupsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchRiskScoreHistoryList,
        dispatchClearItemsList: dispatchClearRiskScoreHistoryList,
        dispatchSetQueryParams,
    });


    // 4a. list specific stuff - local search, local sorting is in table configuration, see 'createGroupsTableColumns'
    const filteredRiskScoreHistory = useMemo(
        () => {
            if (listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((riskScore) =>
                    searchCaseInsensitive(`${riskScore.user?.first_name} ${riskScore?.user?.last_name}`, listData?.queryParams?.search)
                    || searchCaseInsensitive(riskScore.from, listData?.queryParams?.search)
                    || searchCaseInsensitive(riskScore.to, listData?.queryParams?.search)
                    || searchCaseInsensitive(riskScore.comment, listData?.queryParams?.search));
            }
            return listData?.items || [];
        }, [listData],
    );

    // 4b. list specific stuff - action buttons


    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchRiskScoreHistoryList({ ...listData?.queryParams }, enhancedCurrentLocation.pathname)}
            items={filteredRiskScoreHistory}
            totalItemsCount={filteredRiskScoreHistory.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createGroupsTableColumns({
                accessControl,
                t,
                queryParams: listData?.queryParams,
                clientId,
                dispatchRequestNavigation,
            })}
        />
    );
}

RiskScoreHistoryList.propTypes = {
    accessControl: AccessControlProps,
    t: PropTypes.func.isRequired,
    // mapped props
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchFetchRiskScoreHistoryList: PropTypes.func.isRequired,
    dispatchClearRiskScoreHistoryList: PropTypes.func.isRequired,
    clientId: PropTypes.number,
    companyId: PropTypes.number,
    dispatchSetQueryParams: PropTypes.func.isRequired,
};


export default RiskScoreHistoryList;
