import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import styled from 'styled-components';


const BeneficiaryOptionWrapper = styled.span`
  display: inline-grid;
  grid-template-columns: minmax(0,1fr) auto;
  grid-column-gap: 10px;
  width: 100%;
  align-items: center;
  white-space: nowrap;
`;

const Left = styled.span`
  max-width: 100%;
  white-space: nowrap;
  ${commonTextEllipsisStyles};
`;

const Right = styled.span`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  justify-content: right;
  align-items: center;
`;

export default {
    BeneficiaryOptionWrapper,
    Left,
    Right,
};
