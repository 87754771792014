import AutoLogoutBanner from 'components/layouts/AuthorisedLayout/IdleTimer/AutoLogoutBanner/AutoLogoutBanner.layout';
import { clearCurrentUser } from 'redux/current-user/actions';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const mapDispatchToProps = (dispatch) => ({ dispatchClearCurrentUser: () => dispatch(clearCurrentUser()) });

const ConnectedAutoLogoutBanner = connect(null, mapDispatchToProps)(AutoLogoutBanner);

export default withTranslation('common')(ConnectedAutoLogoutBanner);
