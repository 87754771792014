import StandardTag from 'components/common/atoms/StandardTag';

import { TransactionLedgerStatus } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React, { JSX } from 'react';


import mapTransactionLedgerStatusTagToColorAndLabel from './TransactionLedgerStatusTag.helpers';


function TransactionLedgerStatusTag({ transactionLedgerStatus, t, showTooltip }: {
  transactionLedgerStatus: TransactionLedgerStatus,
  t: TFunction,
  showTooltip?: boolean
}): JSX.Element {
    const tagData = mapTransactionLedgerStatusTagToColorAndLabel(transactionLedgerStatus, t);

    if (tagData) {
        return <StandardTag color={tagData.color} text={tagData.label} showTooltip={showTooltip} />;
    }
    return <>-</>;
}

export default TransactionLedgerStatusTag;
