import StandardListTable from 'components/common/organisms/StandardListTable';
import { createAddCompanyRootPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { CompanyListItemProps } from 'constants/CompaniesModel';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultCompaniesListQueryParams } from 'redux/companies/reducer';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import tableColumns from './tableColumns';


function CompaniesList({
    accessControl,
    enforcedClientId,

    listData,
    enhancedCurrentLocation,


    dispatchFetchCompanies,
    dispatchClearCompaniesList,
    dispatchSetQueryParams,
    dispatchRequestNavigation,
    allowInactivePartners = false,
    isClientActive,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const isPartnerActive = useMemo(
        () => isClientActive(enforcedClientId),
        [enforcedClientId],
    );

    const contextEnforcedQueryParams = useMemo(
        () => ({
            ...(enforcedClientId ? { clientId: enforcedClientId } : { clientId: undefined }),
            ...(enforcedClientId && !isPartnerActive ? { includeInactivePartners: true } : { }),
        }),
        [accessControl, enforcedClientId, isPartnerActive],
    );


    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchCompanies(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultCompaniesListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchCompanies,
        dispatchClearItemsList: dispatchClearCompaniesList,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependent optional companies list buttons
    const additionalButtons = useMemo(
        () => (accessControl.hasPermission(PN.COMPANIES.CREATE)
            ? [
                {
                    type: 'primary',
                    text: t('Add company'),
                    icon: <PlusOutlined />,
                    onClick: () => dispatchRequestNavigation(createAddCompanyRootPath(enforcedClientId)),
                },
            ] : []), [enforcedClientId, accessControl],
    );

    // 5. standard render component
    return (
        <StandardListTable
            rowKey={'company_id'}
            onUpdate={fetchData}
            showClientSelect={accessControl.isSuperAdmin && !enforcedClientId} // XXX allowed - companies list client filter related
            allowAllClients={accessControl.isSuperAdmin && !enforcedClientId} // XXX allowed - companies client filter list related
            showIncludeInactivePartners
            allowInactivePartners={allowInactivePartners}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={tableColumns({
                accessControl,
                dispatchRequestNavigation,
                queryParams: listData?.queryParams,
                showPartnerColumn: !enforcedClientId && accessControl.isSuperAdmin, // XXX allowed - client filter on companies list
                t,
            })}
            filtersButtonsConfig={additionalButtons}
        />
    );
}

CompaniesList.propTypes = {
    // own props
    enforcedClientId: PropTypes.number,

    // mapped props
    accessControl: AccessControlProps,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(CompanyListItemProps),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    dispatchFetchCompanies: PropTypes.func.isRequired,
    dispatchClearCompaniesList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    allowInactivePartners: PropTypes.bool,
    isClientActive: PropTypes.func,
};


export default CompaniesList;
