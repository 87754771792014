import CardsList from 'components/common/templates/lists/CardsList';
import { cardsOrderRootNestedRoutePath } from 'config/routes';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AllEntityTypes } from 'constants/ApplicationStateModel';
import ModalName from 'constants/Modals';
import { permissionSetLinkCard, permissionSetOrderCardsTargetPreSelected, PN } from 'constants/PermissionsModel';
import { UnlinkCardViews } from 'redux/card/epic.helpers';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function CompanyCardsList({
    accessControl,
    isInReadOnlyMode,
    companyId,
    businessUserId,
    companyName = undefined,
    businessUserName = undefined,
    clientId,
    dispatchShowModal,
    dispatchRequestNavigation,
    dispatchShowUnlinkCardModal,
    t,
}) {
    const isBusinessUser = useMemo(
        () => businessUserId && businessUserId > 0,
        [businessUserId],
    );

    const linkCardModalTitle = businessUserName
        ? t('modals.linkCard.title.linkToBusinessUser', { name: businessUserName })
        : t('modals.linkCard.title.linkToCompany', { name: companyName });

    const onOrderCardsButtonClick = accessControl.hasPermissions(permissionSetOrderCardsTargetPreSelected)
    && !isInReadOnlyMode
        ? () => dispatchRequestNavigation(
            cardsOrderRootNestedRoutePath,
            { state: { clientId, companyId } },
        ) : undefined;

    const onShowLinkCardButtonClick = accessControl.hasPermissions(permissionSetLinkCard)
    && !isInReadOnlyMode
        ? () => dispatchShowModal({
            modalType: ModalName.LINK_CARD_MODAL,
            modalProps: {
                companyId,
                businessUserId,
                clientId,
                title: linkCardModalTitle,
                hideOkButton: true,
            },
        }) : undefined;

    const onShowUnLinkCardButtonClick = (event, record) => (accessControl.hasPermission(PN.CARDS.UNLINK)
    && !isInReadOnlyMode
        ? dispatchShowUnlinkCardModal(record, {
            view: isBusinessUser ? UnlinkCardViews.BUSINESS_USER : UnlinkCardViews.COMPANY,
            id: businessUserId || record.company_id,
        }) : undefined);


    return (
        <CardsList
            entityId={isBusinessUser ? Number(businessUserId) : Number(companyId)}
            entityType={isBusinessUser ? AllEntityTypes.BUSINESS_USER : AllEntityTypes.COMPANY}
            showSearchField
            onOrderCardsButtonClick={onOrderCardsButtonClick}
            onShowLinkCardButtonClick={onShowLinkCardButtonClick}
            onShowUnLinkCardButtonClick={onShowUnLinkCardButtonClick}
        />
    );
}

CompanyCardsList.propTypes = {
    accessControl: AccessControlProps,
    clientId: PropTypes.number.isRequired,
    businessUserId: PropTypes.number,
    companyId: PropTypes.number.isRequired,
    companyName: PropTypes.string,
    businessUserName: PropTypes.string,
    dispatchShowModal: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    isInReadOnlyMode: PropTypes.bool.isRequired,
    dispatchShowUnlinkCardModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default CompanyCardsList;
