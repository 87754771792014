import { AddClientStepsDefinitions } from 'constants/AddClientModel';
import { CommonFormFieldTypes, MultiStepFormStepSectionFieldsLayout, MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';
import { createCompanyTypesOptions } from 'utils/options-creators';
import { commonValidation, isValidURLRule, requiredRule } from 'utils/validation-tools';

import { BankOutlined } from '@ant-design/icons';
import React from 'react';


function createClientNamesDataFromFieldsConfig({ t }) {
    return [
        {
            name: 'company_registration_number',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Company registration number'),
            placeholder: 'Enter company registration number',
            validationRules: commonValidation({ t, isRequired: true }),
        },
        {
            name: 'company_vat_registration_number',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Company VAT/tax identification number'),
            placeholder: 'Enter company VAT/tax identification number',
            validationRules: commonValidation({ t, isRequired: true }),
        },
        {
            name: 'company_legal_name',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Company legal name'),
            placeholder: 'Enter company legal name',
            validationRules: commonValidation({ t, isRequired: true }),
        },
        {
            name: 'company_dba_name',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Company DBA/trade name'),
            placeholder: 'Enter company DBA/trade name',
            validationRules: commonValidation({ t, isRequired: true }),
        },
    ];
}


export function createClientOtherBasicDataFromFieldsConfig({ t }) {
    return [
        {
            name: 'company_business_type',
            fieldType: CommonFormFieldTypes.SELECT,
            label: t('Company business type'),
            placeholder: 'Enter business type, e.g. Ltd, LLC',
            validationRules: commonValidation({ t, isRequired: true }),
            customOptions: createCompanyTypesOptions(),
        },
        {
            fieldType: CommonFormFieldTypes.MULTI_SELECT_INDUSTRIES,
            name: 'industries',
            label: t('Operating industries'),
            placeholder: 'Select operating industries',
            validationRules: requiredRule(t),
        },
        {
            fieldType: CommonFormFieldTypes.MULTI_SELECT_EEA_COUNTRIES,
            name: 'countries_of_operations',
            label: t('Countries of operations'),
            placeholder: 'Select Countries of operations',
            validationRules: requiredRule(t),
        },
        {
            fieldType: CommonFormFieldTypes.URL,
            name: 'website',
            label: t('Company website URL'),
            placeholder: 'Enter company website URL',
            validationRules: isValidURLRule('website', t, true),
        },
    ];
}


export function createClientDetailsStepConfig({ t }) {
    return {
        stepName: AddClientStepsDefinitions.PARTNER_DETAILS,
        title: t('addClient.steps.partnerDetails'),
        icon: <BankOutlined />,
        sectionsLayout: MultiStepFormStepSectionTypes.EVEN_COLUMNS,
        stepSections: [
            {
                fieldsLayout: MultiStepFormStepSectionFieldsLayout.VERTICAL,
                title: 'IDs & Names',
                fieldsConfiguration: createClientNamesDataFromFieldsConfig({ t }),
            },
            {
                fieldsLayout: MultiStepFormStepSectionFieldsLayout.VERTICAL,
                title: 'Other info',
                fieldsConfiguration: createClientOtherBasicDataFromFieldsConfig({ t }),
            },
        ],
    };
}


export default { createClientDetailsStepConfig };
