import {
    clearLinkCardAccount,
    clearLinkCardProcess,
    setCardLinkingStep,
} from 'redux/card-linking/actions';
import { setModalProps, hideModal } from 'redux/modal/actions';

import { EMPTY, of } from 'rxjs';


export const LinkCardSteps = {
    CARD_SELECTION: 0, // must be a numbers for antd:Step component
    ACCOUNT_SELECTION: 1,
    SUMMARY: 2,
};


export const createStepChangeActions = (step, i18n, http) => {
    const handlers = [
        {
            predicate: () => step === LinkCardSteps.CARD_SELECTION,
            handler: () => of(
                setModalProps({
                    cancelText: i18n.t('common:buttons.cancel.text'),
                    hideOkButton: true,
                    onCancel: () => {
                        http.storeDispatch(clearLinkCardProcess());
                        http.storeDispatch(hideModal());
                    },
                }),
            ),
        },
        {
            predicate: () => step === LinkCardSteps.ACCOUNT_SELECTION,
            handler: () => of(
                setModalProps({
                    cancelText: i18n.t('common:buttons.back.text'),
                    hideOkButton: true,
                    onCancel: () => {
                        http.storeDispatch(clearLinkCardAccount());
                        http.storeDispatch(setCardLinkingStep(LinkCardSteps.CARD_SELECTION));
                    },
                }),
            ),
        },
        {
            predicate: () => step === LinkCardSteps.SUMMARY,
            handler: () => of(
                setModalProps({
                    cancelText: i18n.t('common:buttons.back.text'),
                    hideOkButton: false,
                    okText: i18n.t('cards:buttons.linkCard.text'),
                    onCancel: () => {
                        http.storeDispatch(clearLinkCardAccount());
                        http.storeDispatch(setCardLinkingStep(LinkCardSteps.ACCOUNT_SELECTION));
                    },
                }),
            ),
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default { LinkCardSteps, createStepChangeActions };

