import { currentStepNumberInAvailableSteps } from 'redux/multi-steps-form/epics.helpers';

import { Form, Steps } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createMultiStepsFormStepWithSubSteps } from './MultiStepFormSubStepsIndicator.helpers';
import Styled from './MultiStepFormSubStepsIndicator.styled';


function MultiStepFormSubStepsIndicator({
    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,
    stepConfig = [],
    availableSubSteps = [],
    isEditMode = false,
    dispatchSetMultiStepsFormSubStep,
}) {
    const formInstance = Form.useFormInstance();

    const onClickHandler = useMemo(
        () => {
            if (isEditMode && formInstance) {
                return (subStepName) => {
                    formInstance.validateFields()
                        .then(() => {
                            dispatchSetMultiStepsFormSubStep(currentMultiStepsFormStep, subStepName);
                        })
                        .catch(() => undefined);
                };
            }
            return undefined;
        },
        [formInstance, isEditMode],
    );

    const currentSubStepNumber = useMemo(
        () => currentStepNumberInAvailableSteps(availableSubSteps, currentMultiStepsFormSubStep),
        [currentMultiStepsFormSubStep, availableSubSteps],
    );

    return (
        <Styled.SubStepsWrapper>
            <Steps
                current={currentSubStepNumber}
                items={createMultiStepsFormStepWithSubSteps({ stepConfig, onClickHandler, currentMultiStepsFormSubStep })}
            />
        </Styled.SubStepsWrapper>
    );
}

MultiStepFormSubStepsIndicator.propTypes = {
    availableSubSteps: PropTypes.arrayOf(PropTypes.string),
    currentMultiStepsFormSubStep: PropTypes.string,
    currentMultiStepsFormStep: PropTypes.string,
    dispatchSetMultiStepsFormSubStep: PropTypes.func,
    isEditMode: PropTypes.bool,
};


export default MultiStepFormSubStepsIndicator;
