import {
    cardsRootRoutePath,
    clientRootRoutePath,
    clientsRootRoutePath,
    companiesRootRoutePath,
    dashboardRootRoutePath,
    membersRootRoutePath,
    rolesRootRoutePath,
    transactionsRootRoutePath,
    usersRootRoutePath,
    faqRootRoutePath,
    globalListsRootRoutePath,
    privacyPolicyRootRoutePath,
    limitsRootRoutePath,
    // attributionsRoutePath
} from 'config/routes';
import { PN } from 'constants/PermissionsModel';

import {
    HomeOutlined,
    ApartmentOutlined,
    CreditCardOutlined,
    ReconciliationOutlined,
    UserOutlined,
    ShopOutlined,
    UserSwitchOutlined,
    InfoCircleOutlined,
    GlobalOutlined,
    SafetyOutlined,
    DashboardOutlined,
} from '@ant-design/icons';
import { TFunction } from '@manigo/manigo-commons';
import { NumericId } from '@manigo/manigo-domain-typings';


const createSideBarMenuItems = (clientId: NumericId, t: TFunction) => ({
    1: {
        title: 'Dashboard',
        id: 'dashboard',
        to: dashboardRootRoutePath,
        Icon: HomeOutlined,
        key: '1',
        requiredSuperAdmin: true,
    },
    2: {
        title: 'Partners',
        id: 'partners',
        to: clientsRootRoutePath,
        Icon: ApartmentOutlined,
        key: '2',
        requiredSuperAdmin: true,
        requiredPermissions: [PN.CLIENTS.READ],
    },
    3: {
        title: 'Home',
        id: 'home',
        to: `${clientRootRoutePath}/${clientId}`,
        Icon: HomeOutlined,
        key: '3',
        hideForSuperAdmin: true,
        requiredPermissions: [PN.CLIENTS.READ_BY_ID],
    },
    4: {
        title: 'Members',
        id: 'members',
        to: membersRootRoutePath,
        Icon: UserOutlined,
        key: '4',
        requiredPermissions: [PN.MEMBERS.READ],
    },
    5: {
        title: 'Companies',
        id: 'companies',
        to: companiesRootRoutePath,
        Icon: ShopOutlined,
        key: '5',
        requiredPermissions: [PN.COMPANIES.READ],
    },
    6: {
        title: 'Cards',
        id: 'cards',
        to: cardsRootRoutePath,
        Icon: CreditCardOutlined,
        key: '6',
        requiredPermissions: [PN.CARDS.READ],
    },
    7: {
        title: 'Transactions',
        id: 'transactions',
        to: transactionsRootRoutePath,
        Icon: ReconciliationOutlined,
        key: '7',
        requiredPermissions: [PN.TRANSACTIONS.READ_ALL],
    },
    8: {
        title: 'Users',
        id: 'users',
        to: usersRootRoutePath,
        Icon: UserOutlined,
        key: '8',
        requiredPermissions: [PN.USERS.READ],
    },
    9: {
        title: 'Roles',
        id: 'roles',
        to: rolesRootRoutePath,
        Icon: UserSwitchOutlined,
        key: '9',
        requiredSuperAdmin: true,
        requiredPermissions: [PN.ROLES.READ],
    },
    10: {
        title: t('common:sideBar.menuItems.limits'),
        id: 'limits',
        to: limitsRootRoutePath,
        Icon: DashboardOutlined,
        requiredPermissions: [PN.RULES.READ_ALL],
        requiredSuperAdmin: true,
        key: '10',
    },
    11: {
        title: 'Global Lists',
        id: 'globalLists',
        to: globalListsRootRoutePath,
        Icon: GlobalOutlined,
        key: '11',
        requiredSuperAdmin: true,
    },
    12: {
        title: 'FAQ',
        id: 'faq',
        to: faqRootRoutePath,
        Icon: InfoCircleOutlined,
        key: '12',
    },
    13: {
        title: 'Privacy Policy',
        id: 'privacyPolicy',
        to: privacyPolicyRootRoutePath,
        Icon: SafetyOutlined,
        key: '13',
    },
    // TODO restore once PDK-2531 will be done
    // 12: {
    //   title: 'Attributions',
    //   to: attributionsRoutePath,
    //   Icon: StarOutlined,
    //   requiredSuperAdmin: true,
    //   key: '12'
    // }
});

export default createSideBarMenuItems;
