import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_FEE_TIERS_LIST,
    FETCH_FEE_TIERS_LIST,
    FETCH_FEE_TIERS_LIST_FAILURE,
    FETCH_FEE_TIERS_LIST_SUCCESS,
} from './actions.types';


export const fetchFeeTiersList = createAction(FETCH_FEE_TIERS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchFeeTiersListSuccess = createAction(FETCH_FEE_TIERS_LIST_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchFeeTiersListFailure = createAction(FETCH_FEE_TIERS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearFeeTiersList = createAction(CLEAR_FEE_TIERS_LIST, (locationPathname) => ({ payload: { locationPathname } }));
