import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { createFeeDetailsTabsConfig } from 'components/pages/authorised/FeeDetailsPage/FeeDetailsPage.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { FeeType } from 'constants/FeeModel';
import ListProps from 'constants/ListModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';
import { isValidV4Uuid } from 'utils/validation-tools';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import FeeInfo from './FeeInfo';


function FeeDetails({
    accessControl,
    clients,
    entityDetailsData,
    feeTiersListData,
    enhancedCurrentLocation,

    dispatchFetchFeeDetails,
    dispatchClearFeeDetails,
    dispatchSetQueryParams,
    dispatchFetchFeeTiersList,
    t,
}) {
    const isManualFee = entityDetailsData?.entityDetails?.fee_type === FeeType.MANUAL_FEE;
    const { feeUuid } = useParams();
    const isValidUuid = isValidV4Uuid(feeUuid);
    // XXX
    const baseURLSegmentsCount = 2; // e.g. /(1) fee /(2) FEE_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const contextEnforcedQueryParams = { feeUuid };


    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchFeeDetails,
        dispatchClearEntityDetails: dispatchClearFeeDetails,
        dispatchSetQueryParams,
    });

    const tabsConfig = useMemo(
        () => (feeUuid
    && enhancedCurrentLocation
    && !entityDetailsData?.isLoadingList
    && entityDetailsData?.entityDetails
            ? createFeeDetailsTabsConfig({
                feeUuid,
                feeType: entityDetailsData?.entityDetails?.fee_type,
                accessControl,
                clientId: entityDetailsData?.entityDetails?.client_id,
                t,
            })
            : undefined),
        [feeUuid, accessControl, entityDetailsData],
    );

    useEffect(() => {
        if (isManualFee) {
            dispatchFetchFeeTiersList({
                feeUuid,
                limit: 1,
                offset: 0,
            }, enhancedCurrentLocation?.pathname);
        }
    }, [isManualFee]);


    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('detailsScreenTitle', { feeUuid })}
            baseURLSegmentsCount={baseURLSegmentsCount}
            tabsConfig={tabsConfig}
            isValidEntity={{ type: 'feeUuid', isValid: isValidUuid }}
        >
            <FeeInfo
                t={t}
                clients={clients}
                accessControl={accessControl}
                areDetailsReady={isDetailsPageInitialStateDefined}
                isBusy={entityDetailsData?.isLoadingDetails}
                feeDetails={entityDetailsData?.entityDetails}
                feeTiersListData={feeTiersListData}
                onRefresh={() => dispatchFetchFeeDetails({ feeUuid }, baseUrlWithoutTabsSlugs)}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
            />
        </StandardEntityDetailsWithTabs>
    );
}

FeeDetails.propTypes = {
    t: PropTypes.func.isRequired,
    dispatchFetchFeeDetails: PropTypes.func.isRequired,
    dispatchClearFeeDetails: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchFetchFeeTiersList: PropTypes.func.isRequired,
    accessControl: AccessControlProps,
    entityDetailsData: PropTypes.shape({
        entityDetails: PropTypes.shape({}),
        isLoadingDetails: PropTypes.bool,
    }),
    feeTiersListData: ListProps,
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
};


export default FeeDetails;
