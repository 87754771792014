import AutoLogoutBanner from 'components/layouts/AuthorisedLayout/IdleTimer/AutoLogoutBanner';
import { timeToAutoLogoutCountDownBanner } from 'config/config';

import PropTypes from 'prop-types';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';


import Styled from './IdleTimer.styled';


function AppIdleTimer({
    children,
    isAutoLogoutBannerVisible,
    dispatchShowAutoLogoutBanner,
    dispatchUserActivity,
}) {
    const handleOnIdle = () => {
        dispatchShowAutoLogoutBanner();
    };

    const handleOnActive = () => {
        dispatchUserActivity();
    };


    // XXX
    useIdleTimer({
        timeout: timeToAutoLogoutCountDownBanner, // XXX x minutes of inactivity time shows auto logout banner with 60s countdown
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        crossTab: true,
    });

    return (
        <Styled.AppWrapper $enableOverflowHidden={isAutoLogoutBannerVisible}>
            {isAutoLogoutBannerVisible && <AutoLogoutBanner />}
            <Styled.AppBlurOverlay $activeBlur={isAutoLogoutBannerVisible}>
                {children}
            </Styled.AppBlurOverlay>
        </Styled.AppWrapper>
    );
}

AppIdleTimer.propTypes = {
    children: PropTypes.node,
    isAutoLogoutBannerVisible: PropTypes.bool.isRequired,
    dispatchShowAutoLogoutBanner: PropTypes.func.isRequired,
    dispatchUserActivity: PropTypes.func.isRequired,
};

export default AppIdleTimer;
