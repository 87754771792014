import { ClientStatuses } from 'constants/ClientModel';
import { globalLocation } from 'constants/NavigationModel';
import { ClientsListItem } from 'models/domain/clients';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { RootState } from 'redux/rootReducer';

import { NumericId } from '@manigo/manigo-domain-typings';
import React from 'react';
import { connect } from 'react-redux';


function createIsWithIsClientActive(state: RootState) {
    const clients = state[clientsReducerName][clientsListsPerLocationStoreKeyName][globalLocation]?.items;

    return {
        isClientActive: (clientId: NumericId) => {
            const matchingClient = clients.find((client: ClientsListItem) => client?.id === clientId);
            return matchingClient ? matchingClient?.active === ClientStatuses.ACTIVE : false;
        },
    };
}


export default function withIsClientActive(WrappedComponent) {
    function isClientActiveInjectorComponent(props) {
        return <WrappedComponent {...props} />;
    }

    const mapStateToProps = (state: RootState) => createIsWithIsClientActive(state);

    return connect(mapStateToProps)(isClientActiveInjectorComponent);
}
