import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { createBusinessUserDetailsTabsConfig } from 'components/pages/authorised/BusinessUserDetailsPage/BusinessUserDetailsPage.helpers';
import BusinessUserPersonalDetails from 'components/pages/authorised/BusinessUserDetailsPage/BusinessUserPersonalDetails';
import { createCompanyDetailsBusinessUsersListPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AllEntityTypes } from 'constants/ApplicationStateModel';
import { BusinessUserProps } from 'constants/BusinessUserModel';
import EntityDetailsProps, { createEntityDetailsProps } from 'constants/EntityDetailsModel';
import ModalName from 'constants/Modals';
import { PN } from 'constants/PermissionsModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';


function BusinessUserDetailsPage({
    accessControl,
    companyEntityDetailsData,

    enhancedCurrentLocation,
    entityDetailsData,

    dispatchShowModal,
    dispatchFetchBusinessUserDetails,
    dispatchClearBusinessUserDetails,
    dispatchSetQueryParams,

    dispatchFetchCompanyDetails,
    dispatchSetReadOnlyMode,
    dispatchClearReadOnlyMode,
    t,
}) {
    const { businessUserId } = useParams();
    const fixedId = Number(businessUserId);

    const companyDetails = companyEntityDetailsData?.entityDetails;

    const baseURLSegmentsCount = 2; // e.g. /(1) fee /(2) FEE_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);


    const contextEnforcedQueryParams = { businessUserId: fixedId };

    useEffect(() => () => {
        dispatchClearReadOnlyMode();
    }, []);

    useEffect(() => {
        if (!companyDetails?.company_id > 0 && entityDetailsData?.entityDetails?.company_id > 0) {
            dispatchFetchCompanyDetails({ companyId: entityDetailsData.entityDetails.company_id }, baseUrlWithoutTabsSlugs);
        }
    }, [entityDetailsData]);


    const parentEntityIsInReadOnlyMode = useMemo(
        () => accessControl.isEntityReadOnly({
            entityType: AllEntityTypes.COMPANY,
            entityStatus: companyDetails?.company_status,
        }),
        [companyDetails, accessControl],
    );

    const entityIsInReadOnlyMode = useMemo(
        () => (parentEntityIsInReadOnlyMode ? accessControl.isEntityReadOnly({
            entityType: AllEntityTypes.BUSINESS_USER,
            parentEntityIsReadOnly: parentEntityIsInReadOnlyMode,
        }) : false),
        [parentEntityIsInReadOnlyMode],
    );

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchBusinessUserDetails,
        dispatchClearEntityDetails: dispatchClearBusinessUserDetails,
        dispatchSetQueryParams,
    });


    const canEdit = useMemo(() => accessControl.hasPermission(PN.BUSINESS_USERS.UPDATE)
      && !parentEntityIsInReadOnlyMode,
    [accessControl, parentEntityIsInReadOnlyMode, entityDetailsData?.entityDetails]);

    useEffect(() => {
        if (companyDetails?.company_status && entityIsInReadOnlyMode) {
            dispatchSetReadOnlyMode({
                entityType: AllEntityTypes.BUSINESS_USER,
                entityId: fixedId,
                readOnlyStateReason: entityIsInReadOnlyMode,
                otherDetails: { parentEntityName: companyDetails?.company_name },
            });
        }
    }, [companyDetails?.company_id, parentEntityIsInReadOnlyMode]);

    useEffect(() => () => entityIsInReadOnlyMode && dispatchClearReadOnlyMode(), [entityIsInReadOnlyMode]);

    const tabsConfig = useMemo(
        () => (fixedId && companyDetails?.company_id > 0
      && enhancedCurrentLocation
            ? createBusinessUserDetailsTabsConfig({
                entityIsInReadOnlyMode,
                businessUserDetails: entityDetailsData?.entityDetails,
                companyDetails,
                fixedId,
                accessControl,
                t,
            })
            : undefined),
        [fixedId, accessControl, companyDetails, entityDetailsData, t],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('Business user details')}
            tabsConfig={tabsConfig}
            baseURLSegmentsCount={2}
            entityClientId={entityDetailsData?.entityDetails?.client_id}
        >
            <BusinessUserPersonalDetails
                areDetailsReady={isDetailsPageInitialStateDefined}
                isBusy={entityDetailsData?.isLoadingDetails}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                baseUrlWithoutTabsSlugs={baseUrlWithoutTabsSlugs}
                onRefresh={() => dispatchFetchBusinessUserDetails(contextEnforcedQueryParams, baseUrlWithoutTabsSlugs)}
                entityDetails={entityDetailsData?.entityDetails}
                t={t}
                parentEntityIsInReadOnlyMode={parentEntityIsInReadOnlyMode}
                onDetailsEdit={canEdit
                    ? () => dispatchShowModal({
                        modalType: ModalName.EDIT_BUSINESS_USER_MODAL,
                        modalProps: {
                            title: 'Edit business user',
                            initialValues: entityDetailsData?.entityDetails,
                            businessUserId: entityDetailsData?.entityDetails?.business_user_id,
                            listOfOriginLocationPathname: createCompanyDetailsBusinessUsersListPath(companyDetails?.company_id),
                        },
                    })
                    : undefined}
            />
        </StandardEntityDetailsWithTabs>
    );
}

BusinessUserDetailsPage.propTypes = {
    accessControl: AccessControlProps,
    entityDetailsData: createEntityDetailsProps(BusinessUserProps),
    companyEntityDetailsData: EntityDetailsProps,
    dispatchFetchBusinessUserDetails: PropTypes.func.isRequired,
    dispatchFetchCompanyDetails: PropTypes.func.isRequired,
    dispatchShowModal: PropTypes.func.isRequired,
    dispatchSetReadOnlyMode: PropTypes.func.isRequired,
    dispatchClearReadOnlyMode: PropTypes.func.isRequired,
    dispatchClearBusinessUserDetails: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default BusinessUserDetailsPage;

