import TabSubHeading from 'components/common/atoms/TabSubHeading';
import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';
import { AccountIdentificationType } from 'constants/AccountModel';
import { formatIBAN } from 'utils/account-tools';
import { removeUnderscores } from 'utils/string-tools';

import { Col, Skeleton, Spin } from 'antd';
import React, { JSX } from 'react';
import { withTranslation } from 'react-i18next';


import { createBeneficiaryFieldsConfig, createBeneficiaryAddressFieldsConfig } from './BeneficiaryInfoLayout.helpers';
import { BeneficiaryInfoLayoutProps } from './BeneficiaryInfoLayout.types';


function BeneficiaryInfoLayout({
    editedFieldsNames = [],

    beneficiaryDetails,
    isLoadingBeneficiary = false,

    t,
}: BeneficiaryInfoLayoutProps): JSX.Element {
    const beneficiaryFieldsConfig = createBeneficiaryFieldsConfig({
        editedFieldsNames,
        beneficiaryDetails,
        t,
    });

    const beneficiaryAddressFieldsConfig = createBeneficiaryAddressFieldsConfig({
        editedFieldsNames,
        beneficiaryDetails,
        t,
    });


    return (
        <Skeleton
            loading={(!beneficiaryDetails || isLoadingBeneficiary)}
            active
        >
            <Spin
                tip={t('actionMessages.deletingBeneficiary')}
                spinning={isLoadingBeneficiary}
            >
                <div style={{ overflow: 'auto' }}>
                    <Col>
                        <TabSubHeading text={t('beneficiaries:beneficiaryDetails.subHeadings.beneficiaryDetails', { id: beneficiaryDetails?.id })} />

                        {beneficiaryFieldsConfig.map((fieldConfig) => (
                            <LabelAndValueRow
                                key={fieldConfig.label}
                                label={fieldConfig.label}
                                value={fieldConfig.value}
                                highlightValue={fieldConfig?.highlightValue}
                                autoWidthOnValue={fieldConfig?.autoWidthOnValue}
                                showCopyToClipboard={fieldConfig?.showCopyToClipboard}
                            />
                        ))}


                        {beneficiaryDetails?.address && (
                            <>
                                <TabSubHeading text={t('beneficiaries:beneficiaryDetails.subHeadings.address')} />

                                {beneficiaryAddressFieldsConfig.map((fieldConfig) => (
                                    <LabelAndValueRow
                                        key={fieldConfig.label}
                                        label={fieldConfig.label}
                                        value={fieldConfig.value}
                                        highlightValue={fieldConfig?.highlightValue}
                                        autoWidthOnValue={fieldConfig?.autoWidthOnValue}
                                        showCopyToClipboard={fieldConfig?.showCopyToClipboard}
                                    />
                                ))}
                            </>
                        )}

                        {beneficiaryDetails?.accountIdentifications && (
                            <>
                                <TabSubHeading text={t('beneficiaries:beneficiaryDetails.subHeadings.accountIdentifiers')} />

                                {beneficiaryDetails.accountIdentifications?.[0]
                  && (
                      <>
                          <LabelAndValueRow
                              key={t('beneficiaries:beneficiaryFields.identificationCategory.label')}
                              label={t('beneficiaries:beneficiaryFields.identificationCategory.label')}
                              value={beneficiaryDetails.accountIdentifications?.[0]?.identificationCategory}
                          />

                          {beneficiaryDetails.accountIdentifications.map((identifier) => (
                              <LabelAndValueRow
                                  key={removeUnderscores(identifier.identificationType)}
                                  label={removeUnderscores(identifier.identificationType)}
                                  highlightValue={editedFieldsNames.includes(identifier.identificationType)}
                                  value={identifier.identificationType === AccountIdentificationType.IBAN
                                      ? formatIBAN(identifier.identificationValue)
                                      : identifier.identificationValue}
                                  autoWidthOnValue
                              />
                          ))}
                      </>
                  )}
                            </>
                        )}
                    </Col>
                </div>
            </Spin>
        </Skeleton>
    );
}

export default withTranslation()(BeneficiaryInfoLayout);
