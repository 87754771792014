import { Dispatch } from 'models/meta/action';
import { setClipboardContent } from 'redux/application/actions';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import StandardTooltip from './StandardTooltip.layout';
import { StandardTooltipDispatchProps, StandardTooltipOwnProps, StandardTooltipStateProps } from './StandardTooltip.types';


const mapStateToProps = (state: RootState, ownProps: StandardTooltipOwnProps): StandardTooltipStateProps => (ownProps.showCopyToClipboard
    ? { clipboardContent: state.application.clipboardContent }
    : {});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: StandardTooltipOwnProps): StandardTooltipDispatchProps => (ownProps.showCopyToClipboard
    ? { dispatchSetClipboardContent: (text) => dispatch(setClipboardContent(text)) }
    : {});


const decorate = compose<ComponentType<StandardTooltipOwnProps>>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('common'),
);

export default decorate(StandardTooltip);

