import { filterOptions } from 'utils/filterOptions';
import { countriesOfOperationsOptions, createCountriesOptions } from 'utils/options-creators';

import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';


// XXX Customized form controls can be used but controls must follow this convention:
// - It has a controlled property `value` or other name which is equal to the value of `valuePropName`.
// - It has event `onChange` or an event which name is equal to the value of `trigger`.


function CountriesSelect({
    countries,
    placeholder,
    initialValue,
    allowClear = true,
    isMultiValue = false,
    onlyEEACountries = false,
    countryIdProp,
    isDisabled,
    value,
    onChange,
    showSearch = true,
}) {
    const [localValue, setLocalValue] = useState(initialValue);

    const countriesOptions = useMemo(
        () => (onlyEEACountries ? countriesOfOperationsOptions(countries.items, countryIdProp) : createCountriesOptions(countries.items, countryIdProp)),
        [countries],
    );
    const handleOnChange = (newValue) => {
        setLocalValue(newValue);
        onChange(newValue);
    };

    return (
        <Select
            showSearch={showSearch}
            value={initialValue || value || localValue}
            mode={isMultiValue ? 'multiple' : undefined}
            allowClear={allowClear}
            className="countries-select"
            placeholder={placeholder}
            optionFilterProp="label"
            filterOption={filterOptions}
            loading={countries.isLoadingList}
            onChange={handleOnChange}
            disabled={isDisabled}
        >
            {countriesOptions}
        </Select>
    );
}

CountriesSelect.propTypes = {
    showSearch: PropTypes.bool,
    isDisabled: PropTypes.bool.isRequired,
};

export default CountriesSelect;
