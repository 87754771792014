import { HttpService } from 'services/http';


export const createMemberAPI = (requestData) => HttpService.post('/v3/members', requestData);

export const getMemberIdDetails = (memberId, config) => HttpService.get(`/v3/members/${memberId}`, config);

export const updateMemberId = (memberId, requestPayload, config) => HttpService.patch(`/v3/members/${memberId}`, requestPayload, config);

export const getMembersApi = (query:any = null) => HttpService.get('/v3/members', { ...query, convertSnakeCaseKeysToCamelCase: true });

export const getRiskDetailsAPI = (memberId) => HttpService.get(`/v2/members/${memberId}/risk-details`);

export const createRiskDetailsAPI = (memberId, requestPayload) => HttpService.post(`/v2/members/${memberId}/risk-details`, requestPayload);

export const getMemberStatusHistoryAPI = (memberId, queryParams) => HttpService.get(`/v2/members/${memberId}/status-history`, queryParams);

export const postSuspendMemberAPI = (memberId, requestPayload) => HttpService.post(`/v3/members/${memberId}/suspend`, requestPayload);

export const postUnsuspendMemberAPI = (memberId, requestPayload) => HttpService.post(`/v3/members/${memberId}/unsuspend`, requestPayload);

export const postBanMemberAPI = (memberId, requestPayload) => HttpService.post(`/v3/members/${memberId}/ban`, requestPayload);

export const postUnbanMemberAPI = (memberId, requestPayload) => HttpService.post(`/v3/members/${memberId}/unban`, requestPayload);

export const postActivateMemberAPI = (memberId, requestPayload) => HttpService.post(`/v3/members/${memberId}/activate`, requestPayload);


export const updateMemberStatus = (memberId, requestPayload) => HttpService.put(`/v4/members/${memberId}/status`, requestPayload);
