import CopyToClipboard from 'components/common/molecules/CopyToClipboard';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import JSONPretty from 'react-json-pretty';


import Styled from './ViewJsonModal.styled';


function ViewJsonModal({ dataToDisplay }) {
    return (
        <>
            <Styled.ViewJsonModalWrapper>
                <Row gutter={24}>
                    <Col>
                        <JSONPretty id="view-json-modal-json-pretty" data={dataToDisplay} />
                    </Col>
                </Row>
            </Styled.ViewJsonModalWrapper>

            <Styled.CopyToClipboardWrapper>
                <CopyToClipboard value={dataToDisplay} />
            </Styled.CopyToClipboardWrapper>
        </>

    );
}

// eslint-disable-next-line react/forbid-prop-types
ViewJsonModal.propTypes = { dataToDisplay: PropTypes.any };

export default ViewJsonModal;
