import InfoTooltip from 'components/common/atoms/InfoTooltip';
import IntegerInput from 'components/common/molecules/form-controls/IntegerInput';
import DynamicEmbossNames from 'components/pages/authorised/CardsOrderForm/steps/CardDetailsStep/DynamicEmbossNames.layout';
import { CardDeliveryMethod, CardDeliveryType, CardType } from 'constants/CardModel';
import { CardOrderSteps, maxUnitCount } from 'redux/card-order/helpers';
import noop from 'utils/function-tools';
import { createCardChallengeManagersOptions, createCardChallengeMethodsOptions, createCountriesOptions } from 'utils/options-creators';

import {
    Form, Radio, Select, Collapse, Row, Button, Col,
} from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import Styled from './CardDetailsStep.styled';

// TODO reduce complexity
// eslint-disable-next-line complexity
function CardDetailsStep({
    state,
    form,
    countries,

    isLoadingProcessCard,

    dispatchSetCardMatrix,
    dispatchSetProductType,
    dispatchSelectMatrix,
    dispatchGoToPreviousOrderStep,
    dispatchRequestNavigationBack,

    t,
}) {
    const countriesOptions = useMemo(
        () => {
            const ids = (state.selectedMatrix.matrix || []).map((matrix) => matrix.country_of_issue);
            return createCountriesOptions(countries, 'id', ids);
        },
        [countries, state.selectedMatrix],
    );


    const handleNumberOfCardsChange = (value) => {
        const numberOfCards = value && value <= maxUnitCount ? value : 1;
        const embossNamesValues = form.getFieldValue('embossNames') || [];
        const embossNamesValuesLength = embossNamesValues.length;

        if (numberOfCards < embossNamesValuesLength) {
            form.setFieldsValue({ embossNames: embossNamesValues.slice(0, numberOfCards) });
        }

        if (numberOfCards > embossNamesValuesLength) {
            const newElements = Array(numberOfCards - embossNamesValuesLength).fill(state.memberEmbossName);
            form.setFieldsValue({ embossNames: [...embossNamesValues, ...newElements] });
        }
    };


    return (
        <div>
            <Form.Item
                label="Card Type"
                name="card_type"
                rules={[
                    {
                        required: true,
                        message: t('common:validationMsg.fieldMandatory'),
                    },
                ]}
                onChange={(event) => {
                    const { value } = event.target;
                    const deliveryType = form.getFieldValue('delivery_type');
                    if (Number(value) === CardType.PHYSICAL && deliveryType === CardDeliveryType.SINGLE) {
                        form.setFieldsValue({ units: 1 });
                    }
                }}
            >
                <Radio.Group>
                    <Radio.Button value={CardType.PHYSICAL}>Physical</Radio.Button>
                    <Radio.Button value={CardType.VIRTUAL}>Virtual</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {state.data.card_type === CardType.PHYSICAL && (
                <Form.Item
                    label="Delivery Type"
                    name="delivery_type"
                    rules={[
                        {
                            required: true,
                            message: t('common:validationMsg.fieldMandatory'),
                        },
                    ]}
                >
                    <Radio.Group
                        onChange={(event) => {
                            const { value } = event.target;
                            if (value === CardDeliveryType.SINGLE) {
                                form.setFieldsValue({ units: 1 });
                            }
                        }}
                    >
                        <Radio.Button value={CardDeliveryType.SINGLE}>Single</Radio.Button>
                        <Radio.Button value={CardDeliveryType.BULK}>Bulk</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            )}

            {state.data.card_type === CardType.PHYSICAL && (
                <Form.Item
                    label="Delivery Method"
                    name="delivery_method"
                    rules={[
                        {
                            required: true,
                            message: t('common:validationMsg.fieldMandatory'),
                        },
                    ]}
                >
                    <Radio.Group
                        value={CardDeliveryMethod.STANDARD}
                    >
                        <Radio.Button value={CardDeliveryMethod.STANDARD}>Standard</Radio.Button>
                        <Radio.Button value={CardDeliveryMethod.REGISTERED_MAIL}>Registered mail</Radio.Button>
                        <Radio.Button value={CardDeliveryMethod.DIRECT_DELIVERY}>Direct delivery (courier) RFU</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            )}

            <Form.Item
                label="Number of Cards"
                name="units"
                rules={[
                    {
                        required: true,
                        message: t('common:validationMsg.fieldMandatory'),
                    },
                ]}
            >
                {/* 400 is a BE limit, 100 is because of performance issues with dynamic emboss names fields array */}
                <IntegerInput
                    min={1}
                    max={maxUnitCount}
                    step={1}
                    precision={0}
                    disabled={state.data.card_type === CardType.PHYSICAL && state.data.delivery_type === CardDeliveryType.SINGLE}
                    value={state.data.units}
                    onChange={debounce(handleNumberOfCardsChange, 1000)}
                />
            </Form.Item>

            <Styled.CustomColumns>
                <div>
                    <Form.Item
                        label="Select Product Type"
                        name="product_type"
                        rules={[
                            {
                                required: true,
                                message: t('common:validationMsg.fieldMandatory'),
                            },
                        ]}
                    >
                        <Select
                            style={{ minWidth: 300 }}
                            optionFilterProp="children"
                            placeholder="Select Product Type"
                            onChange={(productType) => {
                                form.resetFields(['card_image', 'issuing_country', 'envelope_ref', 'carrier_ref']);

                                dispatchSetProductType(productType);

                                state.cardMatrixes.map((matrixData) => {
                                    const matrix = JSON.parse(matrixData.config);
                                    return matrix.name.toLowerCase() === productType
                                        ? dispatchSelectMatrix(matrix)
                                        : noop;
                                });
                            }}
                        >
                            {state.cardMatrixes.map((cardMatrix) => {
                                const matrix = JSON.parse(cardMatrix.config);
                                return (
                                    <Select.Option value={matrix.name} key={matrix.name}>
                                        {matrix.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    {state.data.product_type && (
                        <>
                            <Form.Item
                                label="Select Card Image"
                                name="card_image"
                                rules={[
                                    {
                                        required: true,
                                        message: t('common:validationMsg.fieldMandatory'),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{ minWidth: 300 }}
                                    optionFilterProp="children"
                                    placeholder="Select Product Type"
                                >
                                    {state.selectedMatrix
                    && state.selectedMatrix.images
                    && Object.keys(state.selectedMatrix.images).map((key) => (
                        <Select.Option key={key}>
                            {state.selectedMatrix.images[key]}
                        </Select.Option>
                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Select Issuing Country"
                                name="issuing_country"
                                rules={[
                                    {
                                        required: true,
                                        message: t('common:validationMsg.fieldMandatory'),
                                    },
                                ]}
                            >
                                <Select
                                    style={{ minWidth: 300 }}
                                    placeholder="Select Issuing Country"
                                    onChange={(countryCode) => {
                                        state.selectedMatrix.matrix.map((matrix, index) => (String(matrix.country_of_issue) === String(countryCode)
                                            ? dispatchSetCardMatrix(state.selectedMatrix.matrix[index])
                                            : noop));
                                    }}
                                >
                                    {countriesOptions}
                                </Select>
                            </Form.Item>

                            {state.data.card_type === CardType.PHYSICAL && (
                                <Form.Item
                                    label="Select Envelop Design"
                                    name="envelope_ref"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('common:validationMsg.fieldMandatory'),
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ minWidth: 300 }}
                                        optionFilterProp="children"
                                        placeholder="Select Envelope Design"
                                    >
                                        {/* TODO extract to helper function */}
                                        {state.selectedMatrix && state.selectedMatrix.envelopes && Object.keys(state.selectedMatrix.envelopes).map((key, index) => (
                                            <Select.Option value={key} key={key + index}>
                                                {state.selectedMatrix.envelopes[key]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}

                            <Form.Item
                                label="Select Carrier"
                                name="carrier_ref"
                                rules={[
                                    {
                                        required: true,
                                        message: t('common:validationMsg.fieldMandatory'),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{ minWidth: 300 }}
                                    optionFilterProp="children"
                                    placeholder="Select Carrier"
                                >
                                    {/* TODO extract to helper function */}
                                    {state.selectedMatrix && state.selectedMatrix.carriers && Object.keys(state.selectedMatrix.carriers).map((key, index) => (
                                        <Select.Option value={key} key={key + index}>
                                            {state.selectedMatrix.carriers[key]}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </>
                    )}
                </div>

                <div>
                    {state.data.product_type && (
                        <Collapse
                            style={{ marginTop: '90px' }}
                            defaultActiveKey={['1', '2']}
                        >
                            <Collapse.Panel
                                header={<InfoTooltip content={<span>Emboss Names</span>} tooltipContent="Optional. May be left blank." />}
                                key="1"
                            >
                                <DynamicEmbossNames t={t} />
                            </Collapse.Panel>
                            <Collapse.Panel
                                header={(
                                    <InfoTooltip
                                        content={<span>{t('modals.linkCard.summaryStep.subHeadings.secure3dSetup')}</span>}
                                        tooltipContent={t('modals.linkCard.summaryStep.hint')}
                                    />
                                )}
                                key="2"
                            >
                                <Col style={{ maxWidth: '400px' }}>
                                    <div style={{ marginBottom: 10 }}>
                                        <Form.Item
                                            label={t('modals.linkCard.fields.challengeMethod.label')}
                                            name="acsAuthMethod"
                                        >
                                            <Select placeholder={t('modals.linkCard.fields.challengeMethod.placeholder')}>
                                                {createCardChallengeMethodsOptions()}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div style={{ marginBottom: 10 }}>
                                        <Form.Item
                                            label={t('modals.linkCard.fields.challengeManager.label')}
                                            name="acsAuthManager"
                                        >
                                            <Select placeholder={t('modals.linkCard.fields.challengeManager.placeholder')}>
                                                {createCardChallengeManagersOptions()}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Collapse.Panel>
                        </Collapse>
                    )}
                </div>
            </Styled.CustomColumns>

            <Row style={{ marginTop: 20 }}>
                <Form.Item>
                    {state.showSelectTargetStep
                        ? (
                            <Button onClick={() => dispatchGoToPreviousOrderStep(CardOrderSteps.CARD_DETAILS)} disabled={isLoadingProcessCard}>
                                {t('common:buttons.back.text')}
                            </Button>
                        )
                        : (
                            <Button onClick={() => dispatchRequestNavigationBack()} disabled={isLoadingProcessCard}>
                                {t('common:buttons.cancel.text')}
                            </Button>
                        )}
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        type="primary"
                        style={{ marginLeft: 10 }}
                        loading={isLoadingProcessCard}
                        disabled={isLoadingProcessCard}
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </div>
    );
}


CardDetailsStep.propTypes = {
    state: PropTypes.shape({}).isRequired,
    form: PropTypes.shape({}).isRequired,

    isLoadingProcessCard: PropTypes.bool.isRequired,

    dispatchSetProductType: PropTypes.func.isRequired,
    dispatchSetCardMatrix: PropTypes.func.isRequired,
    dispatchSelectMatrix: PropTypes.func.isRequired,
    dispatchGoToPreviousOrderStep: PropTypes.func.isRequired,
    dispatchRequestNavigationBack: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default CardDetailsStep;
