import StandardTag from 'components/common/atoms/StandardTag';

import PropTypes from 'prop-types';
import React from 'react';


const createHandlers = (invitationEnabled) => {
    const handlers = [
        {
            predicate: () => invitationEnabled === 1 || invitationEnabled === true,
            handler: () => ({ color: 'green', text: 'ACTIVE' }),
        },
        {
            predicate: () => invitationEnabled === 0 || invitationEnabled === false,
            handler: () => ({ color: 'red', text: 'INACTIVE' }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: 'Unknown' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function ClientInvitationEnabledTag({ invitationEnabled, showTooltip = false }) {
    const tagData = createHandlers(invitationEnabled);
    return <StandardTag color={tagData.color} text={tagData.text} showTooltip={showTooltip} />;
}

ClientInvitationEnabledTag.propTypes = { invitationEnabled: PropTypes.bool };

export default ClientInvitationEnabledTag;
