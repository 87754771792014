import Table from 'components/common/molecules/Table';
import createSecure3dActivityLogsTableColumns from 'components/pages/authorised/CardDetails/Tabs/Secure3d/ActivityLogTable/createTableConfig';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function ActivityLogTable({
    cardId,
    logs,
    isLoading,

    t,
    dispatchShowViewJsonModal,
    dispatchFetch3DSecureActivityLogs,
}) {
    useEffect(() => {
        if (cardId) {
            dispatchFetch3DSecureActivityLogs(cardId);
        }
    }, []);


    return (
        <div>
            <Table
                loadable
                isLoading={isLoading}
                isTableLoading={isLoading}
                rowKey={'id'}
                // TODO
                pagination={false}
                columns={createSecure3dActivityLogsTableColumns(t, dispatchShowViewJsonModal)}
                dataSource={logs}
            />
        </div>
    );
}

ActivityLogTable.propTypes = {
    cardId: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    dispatchFetch3DSecureActivityLogs: PropTypes.func.isRequired,
    dispatchShowViewJsonModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};


export default ActivityLogTable;
