import styled from 'styled-components';


const IconDecoration = styled.img`
  width: 200px;
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 30px;
`;


export default { IconDecoration };
