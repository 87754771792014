import { createMigrationReportTypesOptions } from 'utils/options-creators';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function DownloadUserMigrationReportModal({
    // own (modal) props
    clientId,
    locationPathname,

    // mapped props
    dispatchSetModalProps,
    dispatchDownloadMigrationReport,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);

    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: false,
            cancelButtonProps: { disabled: false },
        });

        dispatchDownloadMigrationReport(
            {
                clientId,
                reportType: values.status,
            },
            locationPathname,
        );
    };
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            name="csv-migration-report-status"
        >
            <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: 'Status is a required field' }]}
            >
                <Select
                    placeholder="Please select migration report type"
                >
                    {createMigrationReportTypesOptions()}
                </Select>
            </Form.Item>
        </Form>
    );
}


DownloadUserMigrationReportModal.propTypes = {
    clientId: PropTypes.number.isRequired,
    locationPathname: PropTypes.string.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchDownloadMigrationReport: PropTypes.func.isRequired,
};

export default DownloadUserMigrationReportModal;
