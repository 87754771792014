import StandardValue from 'components/common/molecules/value-renderers/StandardValue';

import React, { JSX } from 'react';

import Styled from './StandardLink.styled';
import { StandardLinkProps } from './StandardLink.types';


function StandardLink({
    title,
    path,
    dispatchRequestNavigation,
    navigationState,
    children,
    dataTestId,
    showTooltip,
    filterValue,
    onClick,
    isDisabled,
    isLoading,
    icon,
}: StandardLinkProps): JSX.Element {
    return (
        <Styled.LinkStyleButton
            data-test-id={dataTestId || 'standard-link'}
            type="link"
            disabled={isDisabled}
            loading={isLoading}
            icon={icon}
            onClick={() => (onClick ? onClick() : dispatchRequestNavigation(`${path}`, navigationState))}
        >
            {children || (
                <StandardValue
                    showTooltip={showTooltip}
                    value={title}
                    searchQuery={filterValue}
                    showCopyToClipboard={false}
                />
            )}
        </Styled.LinkStyleButton>
    );
}


export default StandardLink;
