import AddPromotion from 'components/pages/authorised/AddPromotion/index';

import React from 'react';
import { useParams } from 'react-router-dom';


function AddPromotionWrapper() {
    const { clientId, selectedGroup } = useParams();

    const fixedClientId = Number(clientId);
    const preSelectedGroup = Number(selectedGroup);

    return (
        <AddPromotion
            fixedClientId={fixedClientId}
            preSelectedGroup={preSelectedGroup}
        />
    );
}

export default AddPromotionWrapper;
