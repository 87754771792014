import StandardListTable from 'components/common/organisms/StandardListTable';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { BusinessUserProps } from 'constants/BusinessUserModel';
import ModalName from 'constants/Modals';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultBusinessUsersListQueryParams } from 'redux/business-users/reducer';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import createBusinessUsersListColumns from './tableColumns';


function BusinessUsersList({
    // own props
    companyId,
    clientId,

    isInReadOnlyMode,


    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    accessControl,
    dispatchShowModal,
    dispatchRequestNavigation,
    dispatchFetchBusinessUsersList,
    dispatchClearBusinessUsersList,
    dispatchSetQueryParams,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ...(companyId > 0 ? { companyId } : {}),
    //
    };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchBusinessUsersList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultBusinessUsersListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchBusinessUsersList,
        dispatchClearItemsList: dispatchClearBusinessUsersList,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependent optional account list filters & buttons
    const additionalButtons = useMemo(
        () => (accessControl.hasPermission(PN.COMPANIES.CREATE_EMPLOYEE)
    && !isInReadOnlyMode
            ? [
                {
                    type: 'primary',
                    text: 'Add business user',
                    icon: <PlusOutlined />,
                    onClick: () => {
                        dispatchShowModal({
                            modalType: ModalName.ADD_BUSINESS_USER_MODAL,
                            modalProps: {
                                title: 'Add business user',
                                locationPathname: enhancedCurrentLocation?.pathname,
                                initialValues: {
                                    clientId,
                                    companyId,
                                },
                            },
                        });
                    },
                },
            ] : []), [accessControl, companyId],
    );

    // 5. standard render component
    return (
        <StandardListTable
            onUpdate={fetchData}
            rowKey={'business_user_id'}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams || {}}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createBusinessUsersListColumns({
                dispatchRequestNavigation,
                queryParams: listData?.queryParams,
                enhancedCurrentLocation,
                t,
            })}
            filtersButtonsConfig={additionalButtons}
        />
    );
}

BusinessUsersList.propTypes = {
    accessControl: AccessControlProps,
    clientId: PropTypes.number,
    companyId: PropTypes.number.isRequired,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(BusinessUserProps),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),

    dispatchFetchBusinessUsersList: PropTypes.func.isRequired,
    dispatchClearBusinessUsersList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchShowModal: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    isInReadOnlyMode: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

export default BusinessUsersList;
