import ResetKycKybButton from 'components/common/molecules/ResetKycKybButton';
import AccountsList from 'components/common/templates/lists/AccountsList';
import BeneficiariesList from 'components/common/templates/lists/BeneficiariesList';
import KycKybAudit from 'components/common/templates/lists/ComplianceTab/KycKybAuditList';
import KycKybLevelSubTab from 'components/common/templates/lists/ComplianceTab/KycKybLevel';
import CompanyRiskDetails from 'components/common/templates/lists/ComplianceTab/RiskDetailsSubTab/CompanyRiskDetails';
import OutgoingTransfer from 'components/common/templates/lists/OutgoingTransfer';
import RiskScoreHistoryList from 'components/common/templates/lists/RiskScoreHistoryList';
import StatusHistoryList from 'components/common/templates/lists/StatusHistoryList';
import TransactionsList from 'components/common/templates/lists/TransactionsList';
import {
    accountsRouteSegmentPath,
    beneficiariesRouteSegmentPath,
    cardsRouteSegmentPath,
    changeStatusRouteSegmentPath,
    complianceRouteSegmentPath,
    dueDiligenceKybRouteSegmentPath,
    businessUsersRouteSegmentPath,
    kybLevelRouteSegmentPath,
    pendingTransactionsRouteSegmentPath,
    riskScoreRouteSegmentPath,
    statusHistoryRouteSegmentPath,
    statusManagementRouteSegmentPath,
    stakeholdersRouteSegmentPath,
    transactionsRouteSegmentPath,
    riskScoreHistoryRouteSegmentPath,
    withdrawRouteSegmentPath,
} from 'config/routes';
import { AccountOwnerTypes } from 'constants/AccountModel';
import { CompanyStatuses } from 'constants/CompaniesModel';
import { KycKybEntityTypes } from 'constants/KycKybModel';
import { PN } from 'constants/PermissionsModel';
import { ProductTypes } from 'constants/ProductModel';
import { clearCompanyStatusHistoryList, fetchCompanyStatusHistoryList } from 'redux/company-status/actions';
import { companyStatusHistoryListsPerLocationStoreKeyName, companyStatusReducerName } from 'redux/company-status/reducer';

import {
    CheckCircleOutlined,
    CreditCardOutlined,
    DollarOutlined,
    TransactionOutlined,
    UserOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import React from 'react';


import BusinessUsersList from './Tabs/BusinessUsersList';
import CompanyCardsList from './Tabs/CompanyCardsList';
import StakeholdersList from './Tabs/StakeholdersList';
import createCompanyStatusHistoryListColumns from './Tabs/StatusHistory/tableColumns';
import UpdateCompanyStatusForm from './Tabs/UpdateCompanyStatusForm';


const createStatusManagementSubTabsConfig = ({
    accessControl,
    companyDetails,
    companyId,
    parentActiveMainTabSegmentURL,
    t,
}) => [
    ...(accessControl.hasPermission(PN.COMPANIES.UPDATE_STATUS)
        ? [{
            label: t('Change Status'),
            isDefault: true,
            activeSubTabSegmentURL: changeStatusRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: <UpdateCompanyStatusForm
                companyId={companyId}
                entityData={companyDetails}
            />,
        }]
        : []
    ),

    ...(accessControl.hasPermission(PN.COMPANIES.READ_COMPANY_STATUS_HISTORY)
        ? [{
            label: t('Status History'),
            activeSubTabSegmentURL: statusHistoryRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: <StatusHistoryList
                reducerName={companyStatusReducerName}
                listsByLocationStoreKeyName={companyStatusHistoryListsPerLocationStoreKeyName}
                fetcherListAction={fetchCompanyStatusHistoryList}
                clearListAction={clearCompanyStatusHistoryList}
                contextEnforcedQueryParams={{ companyId }}
                entityData={companyDetails}
                createTableColumns={createCompanyStatusHistoryListColumns}
            />,
        }]
        : []),
];

const createCompanyComplianceSubTabsConfig = ({
    accessControl,
    isInReadOnlyMode,
    companyDetails,
    companyId,
    parentActiveMainTabSegmentURL,
    baseUrlWithoutTabsSlugs,
    t,
}) => {
    const kybKycType = 'KYB';

    return [
        {
            label: t('Risk score'),
            isDefault: true,
            activeSubTabSegmentURL: riskScoreRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: (
                <CompanyRiskDetails
                    entityId={companyId}
                    entityData={companyDetails}
                    entityType={KycKybEntityTypes.COMPANY}
                    isInReadOnlyMode={isInReadOnlyMode}
                />),
        },

        ...(accessControl.hasPermission(PN.COMPANIES.READ_RISK_DETAILS)
            ? [{
                label: t('Risk score history'),
                isDefault: true,
                activeSubTabSegmentURL: riskScoreHistoryRouteSegmentPath,
                parentActiveMainTabSegmentURL,
                content: <RiskScoreHistoryList companyId={companyId} />,
            }]
            : []),

        ...(accessControl.hasPermission(PN.KYCKYB.GET_LEVEL) && accessControl.isKycKybApprovalEnabled()
            ? [{
                label: t('common:tabsNames.kycKybManagement', { kybKycType, entityType: 'Companies' }),
                activeSubTabSegmentURL: kybLevelRouteSegmentPath,
                parentActiveMainTabSegmentURL,
                content:
        <KycKybLevelSubTab
            entityType={KycKybEntityTypes.COMPANY}
            entityData={companyDetails}
            entityId={companyId}
            isInReadOnlyMode={isInReadOnlyMode}
        >
            <ResetKycKybButton
                companyId={companyDetails?.company_id}
                entityName={companyDetails?.company_name}
                isInReadOnlyMode={[
                    CompanyStatuses.CLOSED,
                    CompanyStatuses.PENDING_CLOSURE,
                    CompanyStatuses.BANNED,
                    CompanyStatuses.UNVERIFIED,
                    CompanyStatuses.INVITED,
                    CompanyStatuses.KYB_PENDING,
                ].includes(companyDetails?.company_status)}
                baseUrlWithoutTabsSlugs={baseUrlWithoutTabsSlugs}
                gutterTop
            />
        </KycKybLevelSubTab>,
            }]
            : []),

        ...(accessControl.hasPermission(PN.KYCKYB.GET_LOGS) && accessControl.isKycKybApprovalEnabled()
            ? [
                {
                    label: t('common:tabsNames.kycKybHistory', { kybKycType, entityType: 'Companies' }),
                    activeSubTabSegmentURL: dueDiligenceKybRouteSegmentPath,
                    parentActiveMainTabSegmentURL,
                    content: <KycKybAudit companyId={companyId} />,
                }]
            : []),

        ...(accessControl.hasPermission(PN.TRANSACTIONS.AUDIT_PAYMENT)
            ? [{
                label: 'Pending transactions',
                hasList: true,
                activeSubTabSegmentURL: pendingTransactionsRouteSegmentPath,
                parentActiveMainTabSegmentURL,
                content: (
                    <TransactionsList
                        key={`${KycKybEntityTypes.COMPANY}-pending`}
                        companyId={companyId}
                        enforcePendingOnly
                    />
                ),
            }]
            : []),
    ];
};

// TODO reduce complexity
// eslint-disable-next-line complexity
export const createCompanyDetailsTabsConfig = ({
    fixedId,
    accessControl,
    isInReadOnlyMode,
    companyDetails,
    dispatchOpenAddAccountModal,
    returnPath,
    currentLocation,
    baseUrlWithoutTabsSlugs,
    t,
}) => {
    const fixedClientId = Number(companyDetails.client_id);

    return [
        ...(accessControl.hasPermission(PN.ACCOUNTS.READ) ? [
            {
                icon: <WalletOutlined />,
                label: t('Accounts'),
                activeMainTabSegmentURL: accountsRouteSegmentPath,
                isDefault: true,
                hasList: true,
                content: (
                    <AccountsList
                        ownerType={AccountOwnerTypes.COMPANY}
                        ownerId={fixedId}
                        isInReadOnlyMode={isInReadOnlyMode}
                        onAddAccount={accessControl.hasPermission(PN.ACCOUNTS.CREATE)
              && !isInReadOnlyMode
                            ? () => dispatchOpenAddAccountModal({
                                title: t('accounts:modals.addAccount.titles.company'),
                                clientId: fixedClientId,
                                companyId: fixedId,
                                allowedAccountTypes: [ProductTypes.Business],
                                setName: true,
                            }, currentLocation)
                            : undefined}
                    />),
            },
        ] : []),

        ...(accessControl.hasPermission(PN.CARDS.READ_BY_COMPANY) ? [
            {
                icon: <CreditCardOutlined />,
                label: t('Cards'),
                activeMainTabSegmentURL: cardsRouteSegmentPath,
                hasList: true,
                content: (
                    <CompanyCardsList
                        companyId={fixedId}
                        companyName={companyDetails?.name}
                        clientId={companyDetails ? fixedClientId : 0}
                        isInReadOnlyMode={isInReadOnlyMode}
                    />),
            },
        ] : []),

        ...(accessControl.hasPermission(PN.TRANSACTIONS.READ_ALL) ? [
            {
                icon: <TransactionOutlined />,
                label: t('Transactions'),
                activeMainTabSegmentURL: transactionsRouteSegmentPath,
                hasList: true,
                content: <TransactionsList companyId={fixedId} />,
            },
        ] : []),

        ...(accessControl.hasPermission(PN.BUSINESS_USERS.READ) ? [
            {
                icon: <UserOutlined />,
                label: t('Business users'),
                activeMainTabSegmentURL: businessUsersRouteSegmentPath,
                hasList: true,
                content: (
                    <BusinessUsersList
                        companyId={fixedId}
                        t={t}
                        clientId={fixedClientId}
                        isInReadOnlyMode={isInReadOnlyMode}
                    />
                ),
            },
        ] : []),

        ...(accessControl.hasPermission(PN.BUSINESS_SHAREHOLDERS.READ_ALL) ? [
            {
                icon: <UserOutlined />,
                label: t('stakeholders:tabName.label'),
                activeMainTabSegmentURL: stakeholdersRouteSegmentPath,
                hasList: true,
                content: <StakeholdersList
                    companyId={fixedId}
                    isInReadOnlyMode={isInReadOnlyMode}
                />,
            },
        ] : []),

        ...(accessControl.clientConfig?.generalSettings?.beneficiariesEnabled
    && accessControl.hasPermission(PN.BENEFICIARIES.SEARCH)
            ? [{
                icon: <UserOutlined />,
                label: t('Beneficiaries'),
                activeMainTabSegmentURL: beneficiariesRouteSegmentPath,
                hasList: true,
                content: (
                    <BeneficiariesList ownerContext={{
                        companyId: fixedId,
                        isInReadOnlyMode,
                        ownerName: `${companyDetails.company_name} (company)`,
                        returnPath,
                    }}
                    />
                ),
            }] : []),
        ...(accessControl.hasOneOfPermissions([
            PN.TRANSACTIONS.MAKE_PAYMENT,
            PN.ACCOUNTS.READ,
            PN.BENEFICIARIES.GET_ALL,
        ])
            ? [
                {
                    icon: <DollarOutlined />,
                    label: t('common:tabsNames.withdraw'),
                    activeMainTabSegmentURL: withdrawRouteSegmentPath,
                    content: <OutgoingTransfer companyId={fixedId} />,
                },
            ]
            : []),

        ...(accessControl.hasOneOfPermissions([
            PN.COMPANIES.READ_RISK_DETAILS,
            PN.KYCKYB.GET_LOGS,
            PN.KYCKYB.GET_LEVEL,
            PN.TRANSACTIONS.AUDIT_PAYMENT,
        ])
            ? [{
                icon: <CheckCircleOutlined />,
                label: 'Compliance',
                activeMainTabSegmentURL: complianceRouteSegmentPath,
                // XXX no 'content' but 'subTabsConfig'
                subTabsConfig: createCompanyComplianceSubTabsConfig({
                    companyDetails,
                    isInReadOnlyMode,
                    accessControl,
                    companyId: fixedId,
                    parentActiveMainTabSegmentURL: complianceRouteSegmentPath,
                    baseUrlWithoutTabsSlugs,
                    t,
                }),
            }]
            : []),

        ...(accessControl.hasOneOfPermissions([
            PN.COMPANIES.UPDATE_STATUS,
            PN.COMPANIES.READ_COMPANY_STATUS_HISTORY,
        ])
            ? [{
                icon: <CheckCircleOutlined />,
                label: 'Status Management',
                activeMainTabSegmentURL: statusManagementRouteSegmentPath,
                subTabsConfig: createStatusManagementSubTabsConfig({
                    companyDetails,
                    accessControl,
                    companyId: fixedId,
                    parentActiveMainTabSegmentURL: statusManagementRouteSegmentPath,
                    t,
                }),
            }]
            : []
        ),
    ];
};


export default { createCompanyDetailsTabsConfig };
