import { createAction } from '@reduxjs/toolkit';

import {
    ADD_ACCOUNT,
    ADD_ACCOUNT_FAILURE,
    ADD_ACCOUNT_SUCCESS,
    CLEAR_ACCOUNTS_LIST, FETCH_ACCOUNTS_LIST, FETCH_ACCOUNTS_LIST_FAILURE, FETCH_ACCOUNTS_LIST_SUCCESS,
    OPEN_ADD_ACCOUNT_MODAL,
} from './action.types';


export const fetchAccountsList = createAction(FETCH_ACCOUNTS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchAccountsListSuccess = createAction(
    FETCH_ACCOUNTS_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchAccountsListFailure = createAction(FETCH_ACCOUNTS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearAccountsList = createAction(CLEAR_ACCOUNTS_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export const openAddAccountModal = createAction(OPEN_ADD_ACCOUNT_MODAL, (ownerType, configData, locationPathname) => ({ payload: { ownerType, configData, locationPathname } }));
export const addAccount = createAction(ADD_ACCOUNT, (ownerType, requestPayload, locationPathname) => ({ payload: { ownerType, requestPayload, locationPathname } }));
export const addAccountSuccess = createAction(ADD_ACCOUNT_SUCCESS, (ownerType, responseData, locationPathname) => ({ payload: { ownerType, responseData, locationPathname } }));
export const addAccountFailure = createAction(ADD_ACCOUNT_FAILURE);
