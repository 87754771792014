import { createReviewStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/ReviewStep/reviewStep.helpers';
import { createSelectPermissionsStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/SelectPermissionsStep/selectPermissionsStep.helpers';
import { createRoleDetailsStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/roleDetailsStep.helpers';
import { createSuccessStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/successStep.helpers';
import { createSelectRoleTemplateStepConfig } from 'components/pages/authorised/AddRole/AddRoleForm/SelectRoleTempalteStep/reviewStep.helpers';


export function createAddRoleStepsConfiguration({
    t,
    selectedRoleTemplate,
    locationPathname,
    operationsLists,
    rolesTemplatesLists,
}) {
    // XXX order DOES matter
    return [
        createSelectRoleTemplateStepConfig({ rolesTemplatesLists, t }),

        createRoleDetailsStepConfig({ t, selectedRoleTemplate }),

        createSelectPermissionsStepConfig({ operationsLists, t }),

        createReviewStepConfig({ t, locationPathname }),

        createSuccessStepConfig({ t }),
    ];
}


export default { createAddRoleStepsConfiguration };
