import BusinessUserStatusTag from 'components/common/atoms/tags/BusinessUserStatusTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';

import React from 'react';


export const createBusinessUserBasicDetailsFields = ({ entityDetails, t }) => {
    if (entityDetails?.business_user_id) {
        return [

            {
                label: 'Name',
                value: `${entityDetails?.first_name} ${entityDetails?.last_name}`,
            },
            {
                label: 'Phone Number',
                value: <StandardValue value={entityDetails?.business_user_phone_number} valueType={ValueTypes.PHONE} />,
            },
            {
                label: 'E-Mail',
                showEmpty: true,
                value: entityDetails?.business_user_email,
            },
            {
                label: t('Status'),
                value: <BusinessUserStatusTag status={entityDetails.business_user_status} />,
            },

        ];
    }

    return [];
};


export const createBusinessUserSystemDetailsFields = ({ entityDetails, t }) => {
    if (entityDetails?.business_user_id) {
        return [
            {
                label: t('Created at'),
                value: <StandardValue value={entityDetails.created_at} valueType={ValueTypes.DATE} />,
            },
            {
                label: t('Updated at'),
                value: <StandardValue value={entityDetails.updated_at} valueType={ValueTypes.DATE} />,
            },
            {
                label: 'Is Stakeholder',
                value: <StandardValue value={entityDetails.is_stakeholder} valueType={ValueTypes.BOOLEAN} />,
            },
            {
                label: 'Business user UUID',
                value: <StandardValue value={entityDetails.business_user_uuid} showCopyToClipboard />,
            },
            {
                label: 'Business user role',
                value: <StandardValue value={entityDetails.business_user_role} showCopyToClipboard />,
            },

            ...(entityDetails.applicant_id
                ? [{
                    label: 'Applicant ID',
                    value: <StandardValue value={entityDetails.applicant_id} showCopyToClipboard />,
                }]
                : []),
        ];
    }

    return [];
};

export default {
    createBusinessUserBasicDetailsFields,
    createBusinessUserSystemDetailsFields,
};
