import withAccessControl from 'hocs/withAccessControl';
import { requestNavigation } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import Cards from './Cards.layout';


const mapDispatchToProps = (dispatch) => ({ dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)) });


const decorate = compose(
    withTranslation('cards'),
    withAccessControl,
    connect(null, mapDispatchToProps),
);

export default decorate(Cards);
