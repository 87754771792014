import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import {
    ADD_CLIENT_PRODUCT,
    ADD_CLIENT_PRODUCT_SUCCESS,
    FETCH_CLIENT_PRODUCTS,
    OPEN_ADD_CLIENT_PRODUCT_MODAL,
} from 'redux/client-products/actions.types';
import { clientProductsListsPerLocationStoreKeyName, clientProductsReducerName } from 'redux/client-products/reducer';
import { setModalProps, showModal, hideModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    fetchClientProducts,
    fetchClientProductsSuccess,
    fetchClientProductsFailure,
    addProductSuccess,
    addProductFailure,
} from './actions';


export const onFetchClientProducts = (action$, state$, { clients }) => action$.pipe(
    ofType(FETCH_CLIENT_PRODUCTS),
    switchMap(({ payload }) => from(clients.getClientProducts(payload.queryParams))
        .pipe(
            switchMap((response) => of(fetchClientProductsSuccess(
                response.data,
                response.count,
                payload.locationPathname,
            ))),
            catchError(() => of(fetchClientProductsFailure(payload.locationPathname))),
        )),
);


export const onOpenAddProductModal = (action$, _, { i18n }) => action$.pipe(
    ofType(OPEN_ADD_CLIENT_PRODUCT_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.ADD_PRODUCT_MODAL,
            modalProps: {
                title: i18n.t('products:modals.addProduct.title'),
                clientId: payload.clientId,
                locationPathname: payload.locationPathname,
            },
        }),
    )),
);


export const onAddProduct = (action$, state$, { clients }) => action$.pipe(
    ofType(ADD_CLIENT_PRODUCT),
    switchMap(({ payload }) => from(clients.createClientProduct(payload.requestPayload))
        .pipe(
            switchMap((response) => of(addProductSuccess(response.data, payload.locationPathname))),
            catchError(() => of(
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
                addProductFailure(),
            )),
        )),
);

export const onAddProductSuccess = (action$, state$, { i18n }) => action$.pipe(
    ofType(ADD_CLIENT_PRODUCT_SUCCESS),
    switchMap(({ payload }) => {
        const currentQueryParams = state$.value[clientProductsReducerName][clientProductsListsPerLocationStoreKeyName][payload.locationPathname]?.queryParams;
        return of(
            fetchClientProducts(currentQueryParams, payload.locationPathname),
            showSuccessToast(i18n.t('products:actionMessages.addNewSuccess', { type: payload.responseData.account_type, currency: payload.responseData.currency_code })),
            hideModal(),
        );
    }),
);


export default [
    onFetchClientProducts,
    onOpenAddProductModal,
    onAddProduct,
    onAddProductSuccess,
];
