import {
    clearAllPasscodeDigits, clearBlockSendConfirmationCode,
    confirmLogin,
    sendConfirmationCode,
    setPasscodeDigit,
} from 'redux/authorisation/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { RootState } from 'redux/rootReducer';

import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import PasscodeConfirmContainer from './PasscodeConfirm.layout';
import { PasscodeConfirmDispatchProps, PasscodeConfirmStateProps } from './PasscodeConfirm.types';


const mapStateToProps = (state: RootState): PasscodeConfirmStateProps => ({
    loginRequestId: state.authorisation.loginRequestId,
    submittedUserNameOrEmail: state.authorisation.submittedUserNameOrEmail,
    loginConfirmFormError: state.authorisation.loginConfirmFormError,
    isLoadingLoginConfirmationForm: state.authorisation.isLoadingLoginConfirmationForm,
    loginConfirmationValues: state.authorisation.loginConfirmationValues,
    loginToken: state.authorisation.accessToken,
    isLoadingSendConfirmationCode: state.authorisation.isLoadingSendConfirmationCode,
    sendConfirmationCodeBlockUntil: state.authorisation.sendConfirmationCodeBlockUntil,
});

const mapDispatchToProps = (dispatch: Dispatch): PasscodeConfirmDispatchProps => ({
    dispatchConfirmLogin: (data) => dispatch(confirmLogin(data)),
    dispatchSetPasscodeDigit: (key, value) => dispatch(setPasscodeDigit(key, value)),
    dispatchClearAllPasscodeDigits: () => dispatch(clearAllPasscodeDigits()),
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
    dispatchSendConfirmationCode: (data) => dispatch(sendConfirmationCode(data)),
    dispatchClearBlockSendConfirmationCode: () => dispatch(clearBlockSendConfirmationCode()),
});


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(PasscodeConfirmContainer);
