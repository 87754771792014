import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';

import PropTypes from 'prop-types';
import React from 'react';


import Styled from './RoleTemplateDetailsDisplay.styled';


function RoleTemplateDetailsDisplay({
    details,
    t,
}) {
    return (
        <Styled.RoleTemplateDetailsDisplayWrapper>
            <div>
                <h4>Labels</h4>
                <p>{`Name: ${details.name}`}</p>
                <p>{`Display name: ${details.display_name}`}</p>
                <p>{`Description: ${details.description}`}</p>
                {details?.client_id > 0 && (
                    <p>{`Client ID: ${details.client_id}`}</p>
                )}

            </div>

            <div>
                <h4>Scope</h4>
                <LabelAndValueRow
                    label={t('All')}
                    value={booleanAsIconsRenderer(details, 'all', t)}
                />
                <LabelAndValueRow
                    label={t('Client')}
                    value={booleanAsIconsRenderer(details, 'client', t)}
                />
                <LabelAndValueRow
                    label={t('Company')}
                    value={booleanAsIconsRenderer(details, 'company', t)}
                />
                <LabelAndValueRow
                    label={t('Own')}
                    value={booleanAsIconsRenderer(details, 'own', t)}
                />
                <LabelAndValueRow
                    label={t('Is reserved?')}
                    value={booleanAsIconsRenderer(details, 'reserved', t)}
                />
            </div>
        </Styled.RoleTemplateDetailsDisplayWrapper>
    );
}

RoleTemplateDetailsDisplay.propTypes = {
    details: PropTypes.shape({}),
    t: PropTypes.func,
};

export default RoleTemplateDetailsDisplay;
