import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AllEntityTypes } from 'constants/ApplicationStateModel';
import { CompanyDetailsProps } from 'constants/CompaniesModel';
import { createEntityDetailsProps } from 'constants/EntityDetailsModel';
import ModalName from 'constants/Modals';
import { PN } from 'constants/PermissionsModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';


import { createCompanyDetailsTabsConfig } from './CompanyDetails.helpers';
import CompanyInformation from './CompanyInformation';


function CompanyDetails({
    accessControl,
    entityDetailsData,
    dispatchFetchCompanyDetails,
    dispatchOpenAddAccountModal,
    dispatchShowModal,
    dispatchClearCompanyDetails,
    dispatchSetReadOnlyMode,
    dispatchClearReadOnlyMode,
    dispatchSetQueryParams,
    enhancedCurrentLocation,
    t,
}) {
    const entityDetails = entityDetailsData?.entityDetails;

    const { companyId } = useParams();
    const fixedId = Number(companyId);

    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(
        enhancedCurrentLocation?.pathname,
        baseURLSegmentsCount,
    );

    const contextEnforcedQueryParams = { companyId: fixedId };

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchCompanyDetails,
        dispatchClearEntityDetails: dispatchClearCompanyDetails,
        dispatchSetQueryParams,
    });

    const isInReadOnlyMode = useMemo(
        () => accessControl.isEntityReadOnly({
            entityType: AllEntityTypes.COMPANY,
            entityStatus: entityDetails?.company_status,
        }),
        [entityDetails?.entityDetails?.company_id, accessControl],
    );

    useEffect(() => {
        if (entityDetails?.company_status && isInReadOnlyMode) {
            dispatchSetReadOnlyMode({
                entityType: AllEntityTypes.COMPANY,
                entityId: entityDetails.company_id,
                entityStatus: entityDetails.company_status,
                readOnlyStateReason: isInReadOnlyMode,
                otherDetails: { entityName: entityDetails?.company_name },
            });
        }
    }, [entityDetails?.entityDetails?.company_id, isInReadOnlyMode]);
    useEffect(() => () => isInReadOnlyMode && dispatchClearReadOnlyMode(), [isInReadOnlyMode]);

    const tabsConfig = useMemo(
        () => (fixedId && entityDetails?.company_id > 0 && enhancedCurrentLocation
            ? createCompanyDetailsTabsConfig({
                fixedId,
                accessControl,
                isInReadOnlyMode,
                companyDetails: entityDetails,
                dispatchOpenAddAccountModal,
                returnPath: enhancedCurrentLocation?.pathname,
                currentLocation: enhancedCurrentLocation?.pathname,
                baseUrlWithoutTabsSlugs,
                t,
            })
            : undefined),
        [fixedId, accessControl, baseUrlWithoutTabsSlugs, entityDetails, dispatchOpenAddAccountModal, t],
    );

    const onDetailsEdit = accessControl.hasPermission(PN.COMPANIES.UPDATE) && !isInReadOnlyMode
        ? (data) => dispatchShowModal({
            modalType: ModalName.EDIT_COMPANY_MODAL,
            modalProps: {
                title: 'Edit Company',
                initialValues: data,
            },
        })
        : undefined;

    const onAddressEdit = accessControl.hasPermission(PN.COMPANIES.UPDATE_ADDRESS)
    && !isInReadOnlyMode
    && !accessControl.isKycKybApprovalEnabled()
        ? (data) => dispatchShowModal({
            modalType: ModalName.EDIT_COMPANY_ADDRESS,
            modalProps: {
                title: 'Edit Company Address',
                initialValues: data,
            },
        })
        : undefined;

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t(`Company #${fixedId} Details`)}
            tabsConfig={tabsConfig}
            baseURLSegmentsCount={baseURLSegmentsCount}
            entityClientId={entityDetails?.client_id}
        >
            <CompanyInformation
                areDetailsReady={isDetailsPageInitialStateDefined}
                isLoading={entityDetailsData?.isLoadingDetails}
                isInReadOnlyMode={isInReadOnlyMode}
                onRefresh={() => dispatchFetchCompanyDetails(
                    contextEnforcedQueryParams,
                    baseUrlWithoutTabsSlugs,
                )}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                companyDetails={entityDetails}
                onDetailsEdit={onDetailsEdit}
                onAddressEdit={onAddressEdit}
                companyId={fixedId}
            />
        </StandardEntityDetailsWithTabs>
    );
}

CompanyDetails.propTypes = {
    accessControl: AccessControlProps,
    entityDetailsData: createEntityDetailsProps(CompanyDetailsProps),
    dispatchFetchCompanyDetails: PropTypes.func.isRequired,
    dispatchOpenAddAccountModal: PropTypes.func.isRequired,
    dispatchShowModal: PropTypes.func.isRequired,
    dispatchClearCompanyDetails: PropTypes.func.isRequired,
    dispatchSetReadOnlyMode: PropTypes.func.isRequired,
    dispatchClearReadOnlyMode: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default CompanyDetails;
