import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { SortDirections } from 'constants/ApplicationStateModel';
import { sortingOptions, sortNumbers, sortStringDates, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import PropTypes from 'prop-types';
import React from 'react';


function createIndustriesListTableColumns({
    t,
    queryParams,
}) {
    return [
        {
            title: t('common:table.columnNames.id'),
            dataIndex: 'id',
            align: 'right',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            defaultSortOrder: SortDirections.ASCEND,
            ...sortingOptions({
                queryParams,
                fieldName: 'id',
                sorter: (a, b) => sortNumbers(a, b, 'id'),
            }),
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            align: 'center',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
            ...sortingOptions({
                queryParams,
                fieldName: 'created_at',
                sorter: (a, b) => sortStringDates(a, b, 'created_at'),
            }),
        },
        {
            title: t('Name'),
            dataIndex: 'name',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'name',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'name'),
            }),
        },
        {
            title: t('Risk score'),
            dataIndex: 'risk_score',
            align: 'right',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.COUNTER} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'risk_score',
                sorter: (a, b) => sortNumbers(a, b, 'risk_score'),
            }),
        },
        {
            title: t('Deleted'),
            dataIndex: 'deleted',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(record, 'deleted', t),
            ...sortingOptions({
                queryParams,
                fieldName: 'deleted',
                sorter: (a, b) => sortNumbers(a, b, 'deleted'),
            }),
        },
        {
            title: t('Deleted at'),
            dataIndex: 'deleted_at',
            align: 'center',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
            ...sortingOptions({
                queryParams,
                fieldName: 'deleted_at',
                sorter: (a, b) => sortNumbers(a, b, 'deleted_at'),
            }),
        },
        {
            title: 'Updated at',
            dataIndex: 'updated_at',
            align: 'center',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
            ...sortingOptions({
                queryParams,
                fieldName: 'updated_at',
                sorter: (a, b) => sortStringDates(a, b, 'updated_at'),
            }),
        },
    ];
}


createIndustriesListTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({ search: PropTypes.string }),
};


export default createIndustriesListTableColumns;
