import styled from 'styled-components';


const TimelineWrapper = styled.div<{ gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
`;


export default { TimelineWrapper };
