import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';

import {
    fetchOperationsList, fetchOperationsListFailure, fetchOperationsListSuccess, clearOperationsList,
    fetchRolesTemplatesListSuccess, fetchRolesTemplatesListFailure, fetchRolesTemplatesList, clearRolesTemplatesList,
} from './actions';


export const operationsReducerName = 'operations';
export const operationsListsPerLocationStoreKeyName = 'operationsListsPerLocation';
export const roleTemplatesListsPerLocationStoreKeyName = 'rolesTemplatesListsPerLocation';

const initialRolesListState = {
    [operationsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    [roleTemplatesListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    // ?
};


const initialState = { ...initialRolesListState };

const handlers = {
    [fetchOperationsList]: (state, action) => {
        state[operationsListsPerLocationStoreKeyName] = updateStandardLists(state[operationsListsPerLocationStoreKeyName], action);
    },
    [fetchOperationsListSuccess]: (state, action) => {
        state[operationsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[operationsListsPerLocationStoreKeyName], action);
    },
    [fetchOperationsListFailure]: (state, action) => {
        state[operationsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[operationsListsPerLocationStoreKeyName], action);
    },
    [clearOperationsList]: (state, action) => {
        state[operationsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[operationsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === operationsReducerName
      && action.payload?.fieldName === operationsListsPerLocationStoreKeyName
        ) {
            state[operationsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[operationsListsPerLocationStoreKeyName] }, action);
        }
    },

    [fetchRolesTemplatesList]: (state, action) => {
        state[roleTemplatesListsPerLocationStoreKeyName] = updateStandardLists(state[roleTemplatesListsPerLocationStoreKeyName], action);
    },
    [fetchRolesTemplatesListSuccess]: (state, action) => {
        state[roleTemplatesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[roleTemplatesListsPerLocationStoreKeyName], action);
    },
    [fetchRolesTemplatesListFailure]: (state, action) => {
        state[roleTemplatesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[roleTemplatesListsPerLocationStoreKeyName], action);
    },
    [clearRolesTemplatesList]: (state, action) => {
        state[roleTemplatesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[roleTemplatesListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === operationsReducerName
      && action.payload?.fieldName === roleTemplatesListsPerLocationStoreKeyName
        ) {
            state[roleTemplatesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[roleTemplatesListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
