import { goToPreviousOrderStep } from 'redux/card-order/actions';
import { cardsOrderReducerName } from 'redux/card-order/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import DeliveryDetailsStep from './DeliveryDetailsStep.layout';


const mapStateToProps = (state) => ({
    isLoadingProcessCard: state[cardsOrderReducerName].isLoadingProcessCard,
    isLoadingDeliveryAddress: state[cardsOrderReducerName].isLoadingDeliveryAddress,
    data: state[cardsOrderReducerName].data,
});

const mapDispatchToProps = (dispatch) => ({ dispatchGoToPreviousOrderStep: (currentStep) => dispatch(goToPreviousOrderStep(currentStep)) });

const decorate = compose(
    withTranslation('cards'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(DeliveryDetailsStep);
