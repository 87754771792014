import CardCategoryTag from 'components/common/atoms/tags/CardCategoryTag';
import CardTypeTag from 'components/common/atoms/tags/CardTypeTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';

import React from 'react';


export const createTableColumns = (t) => [
    {
        title: 'Emboss Name',
        dataIndex: 'emboss_name',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: 'Card Type',
        dataIndex: 'card_type',
        render: (card_type) => <CardTypeTag cardType={card_type} t={t} showTooltip />,
    },
    {
        title: 'Card category',
        dataIndex: 'product_type',
        render: (product_type) => <CardCategoryTag cardCategory={product_type} t={t} showTooltip />,
    },
    {
        title: 'Card Image',
        dataIndex: 'card_image',
        render: (text) => <StandardValue value={text} />,
    },
];
