import styled from 'styled-components';


const AppVersionInfoWrapper = styled.div`
  margin-top: 20px;

  .ant-btn {
      color: var(--sidebar-menu-item-color);
      appearance: none;
      
      &:hover {
        background: transparent;
        color: var(--sidebar-menu-item-color);
        outline: none;
      }
    }
`;

const AppVersionInfoPopupWrapper = styled.div`
    max-width: 700px
`;

export default { AppVersionInfoWrapper, AppVersionInfoPopupWrapper };
