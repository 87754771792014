import TabSubHeading from 'components/common/atoms/TabSubHeading';
import PermissionRecordDisplay from 'components/common/organisms/forms-shared-parts/add-edit-role-form/PermissionRecordDisplay';
import RoleTemplateDetailsDisplay from 'components/common/organisms/forms-shared-parts/add-edit-role-form/RoleTemplateDetailsDisplay/RoleTemplateDetailsDisplay.layout';
import { filterOptions } from 'utils/filterOptions';
import { createRolesTemplatesOptions } from 'utils/options-creators';
import { sortStringsAlphabetically } from 'utils/table-columns-sorters';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import Styled from './SelectRoleTemplateStep.styled';


function SelectRoleTemplateStep({
    selectedRoleTemplate,
    rolesTemplatesLists,
    dispatchSelectRoleTemplate,
    dispatchClearRoleTemplate,
    dispatchAddPermissionsToRole,
    dispatchRemoveAllPermissionsFromRole,
    t,
}) {
    const formInstance = Form.useFormInstance();

    const templateOptions = useMemo(
        () => createRolesTemplatesOptions(rolesTemplatesLists),
        [rolesTemplatesLists],
    );

    const sortedPermissions = useMemo(
        () => {
            if (selectedRoleTemplate?.permissions?.length > 0) {
                return [...selectedRoleTemplate.permissions].sort((a, b) => sortStringsAlphabetically(a, b, 'name'));
            }
            return [];
        },
        [selectedRoleTemplate],
    );


    useEffect(() => {
        if (selectedRoleTemplate) {
            formInstance.setFieldsValue({
                all: Boolean(Number(selectedRoleTemplate?.details.all)),
                client: Boolean(Number(selectedRoleTemplate?.details.client)),
                company: Boolean(Number(selectedRoleTemplate?.details.company)),
                own: Boolean(Number(selectedRoleTemplate?.details.own)),
                reserved: Boolean(Number(selectedRoleTemplate?.details.reserved)),
                description: selectedRoleTemplate?.details.description,
                display_name: selectedRoleTemplate?.details.display_name,
                name: selectedRoleTemplate?.details.name,
                ...(selectedRoleTemplate?.details?.client_id > 0 ? { client_id: Number(selectedRoleTemplate?.details.client_id) } : {}),
            });
            dispatchRemoveAllPermissionsFromRole();
            dispatchAddPermissionsToRole([...selectedRoleTemplate.permissions.map((permission) => ({ id: Number(permission.operation_id), name: permission.name }))]);
        }

        if (!selectedRoleTemplate) {
            formInstance.resetFields();
        }
    }, [formInstance, selectedRoleTemplate]);


    return (
        <Styled.SelectRoleTemplateOuterWrapper>
            <Styled.SelectRoleTemplateWrapper>
                <div>
                    <h3>{t('Select existing role template or go next step to select all operations manually')}</h3>
                    <Select
                        style={{ width: '400px' }}
                        showSearch
                        allowClear
                        value={JSON.stringify(selectedRoleTemplate)}
                        filterOption={filterOptions}
                        placeholder={'Select role template'}
                        onSelect={(selectedRole) => dispatchSelectRoleTemplate(JSON.parse(selectedRole))}
                        onClear={() => {
                            dispatchClearRoleTemplate();
                            dispatchRemoveAllPermissionsFromRole();
                        }}
                    >
                        {templateOptions}
                    </Select>
                </div>

                <div>
                    {selectedRoleTemplate && (
                        <>
                            <TabSubHeading text={t('Template Details')} />
                            <RoleTemplateDetailsDisplay details={selectedRoleTemplate?.details} t={t} />
                        </>
                    )}
                </div>
            </Styled.SelectRoleTemplateWrapper>


            <Styled.PermissionsWrapper>
                {selectedRoleTemplate
                    ? (
                        <div>
                            <TabSubHeading text={t('Permissions set')} />
                            <Styled.PreloadedPermissionsWrapper>
                                {(sortedPermissions).map((permission) => <PermissionRecordDisplay permission={permission} key={permission.operation_id} />)}
                            </Styled.PreloadedPermissionsWrapper>
                        </div>
                    )
                    : (
                        <Styled.Placeholder>
                            <div>
                                <h2>Select role template to preview it`s details & permissions set OR skip this step and setup everything from scratch.</h2>
                                <p>Re-selection (or clearing) of role template overrides (or clears) pre-loaded permission set.</p>
                                {/* eslint-disable-next-line max-len */}
                                <p>Selected role template re-selection (or clearing of it) will clear any selected permissions that might have been set up in `Define permission` set step.</p>
                            </div>

                        </Styled.Placeholder>
                    )}

            </Styled.PermissionsWrapper>

        </Styled.SelectRoleTemplateOuterWrapper>
    );
}

SelectRoleTemplateStep.propTypes = {
    // mapped props
    selectedRoleTemplate: PropTypes.shape({}),
    rolesTemplatesLists: PropTypes.arrayOf(PropTypes.shape({})),
    dispatchSelectRoleTemplate: PropTypes.func,
    dispatchAddPermissionsToRole: PropTypes.func,
    dispatchClearRoleTemplate: PropTypes.func,
};

export default SelectRoleTemplateStep;
