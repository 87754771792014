import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import CardInfo from 'components/pages/authorised/CardDetails/CardInfo';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AllEntityTypes } from 'constants/ApplicationStateModel';
import { ClientListItemProps } from 'constants/ClientModel';
import EntityDetailsProps from 'constants/EntityDetailsModel';
import { PN } from 'constants/PermissionsModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { createTabsConfig } from './CardDetails.helpers';


function CardDetails({
    enhancedCurrentLocation,
    accessControl,
    entityDetailsData,
    isLoadingUpdateCard,
    clients,

    dispatchFetchCardDetails,
    dispatchClearCardDetails,
    dispatchSetQueryParams,

    dispatchUpdateCard,
    dispatchSetReadOnlyMode,
    dispatchClearReadOnlyMode,
    t,
}) {
    const { cardId } = useParams();
    const fixedId = Number(cardId);

    const entityDetails = entityDetailsData?.entityDetails;

    const baseURLSegmentsCount = 2; // e.g. /(1) card /(2) CARD_ID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);
    const contextEnforcedQueryParams = { cardId: fixedId };

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchCardDetails,
        dispatchClearEntityDetails: dispatchClearCardDetails,
        dispatchSetQueryParams,
    });

    const isLoadingDetails = entityDetailsData?.isLoadingDetails;


    useEffect(() => {
        const entityStatus = entityDetails?.card_status;
        const entityId = entityDetails?.id;

        const isInReadOnlyMode = accessControl.isEntityReadOnly({
            entityStatus,
            entityType: AllEntityTypes.CARD,
        });

        if (entityDetails?.card_status && isInReadOnlyMode) {
            dispatchSetReadOnlyMode({
                entityId,
                entityStatus,
                entityType: AllEntityTypes.CARD,
                readOnlyStateReason: isInReadOnlyMode,
            });
        }
    }, [entityDetails]);

    const isInReadOnlyMode = useMemo(
        () => accessControl.isEntityReadOnly({
            entityType: AllEntityTypes.CARD,
            entityStatus: entityDetails?.card_status,
        }),
        [entityDetails, accessControl],
    );


    useEffect(() => {
        if (entityDetails?.card_status && isInReadOnlyMode) {
            dispatchSetReadOnlyMode({
                entityType: AllEntityTypes.CARD,
                entityId: entityDetails.id,
                entityStatus: entityDetails.card_status,
                readOnlyStateReason: isInReadOnlyMode,
            });
        }
    }, [entityDetails, isInReadOnlyMode]);

    useEffect(() => () => isInReadOnlyMode && dispatchClearReadOnlyMode(), [isInReadOnlyMode]);

    const onCardUpdate = useMemo(
        () => (accessControl.hasPermission(PN.CARDS.TOGGLING) && !isInReadOnlyMode
            ? (updatedData) => dispatchUpdateCard(fixedId, { ...updatedData })
            : undefined),
        [entityDetails, accessControl, fixedId],
    );


    const tabsConfig = useMemo(
        () => createTabsConfig({
            locationPathname: baseUrlWithoutTabsSlugs,
            accessControl,
            isInReadOnlyMode,
            fixedId,
            entityDetails,
            onCardUpdate,
            isLoadingUpdateCard,
            isLoadingDetails,
            t,
        }),
        [fixedId, entityDetails, accessControl, onCardUpdate, t, isLoadingDetails, isLoadingUpdateCard],
    );


    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('detailsScreenTitle', { cardId })}
            tabsConfig={tabsConfig}
            baseURLSegmentsCount={baseURLSegmentsCount}
            entityClientId={entityDetails?.client_id}
        >
            <CardInfo
                clients={clients}
                accessControl={accessControl}
                isInReadOnlyMode={isInReadOnlyMode}
                cardDetails={entityDetails}
                areDetailsReady={isDetailsPageInitialStateDefined}
                isLoading={entityDetailsData?.isLoadingDetails}
                cardId={fixedId}
                baseUrlWithoutTabsSlugs={baseUrlWithoutTabsSlugs}
                onRefresh={() => dispatchFetchCardDetails(contextEnforcedQueryParams, baseUrlWithoutTabsSlugs)}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
            />
        </StandardEntityDetailsWithTabs>
    );
}

CardDetails.propTypes = {
    accessControl: AccessControlProps,
    clients: PropTypes.arrayOf(ClientListItemProps).isRequired,
    account: PropTypes.shape({ id: PropTypes.number }),
    t: PropTypes.func.isRequired,

    entityDetailsData: EntityDetailsProps,

    isLoadingUpdateCard: PropTypes.bool.isRequired,
    dispatchUpdateCard: PropTypes.func.isRequired,
    dispatchFetchCardDetails: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchClearCardDetails: PropTypes.func.isRequired,
    dispatchSetReadOnlyMode: PropTypes.func.isRequired,
    dispatchClearReadOnlyMode: PropTypes.func.isRequired,
};


export default CardDetails;
