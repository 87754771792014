export const CommonMimeTypes = Object.freeze({
    PDF: 'application/pdf',
    CSV: 'text/csv',
    CSS: 'text/css',
    DOC: 'application/msword',
    JSON: 'application/json',
    TXT: 'text/plain',
    ZIP: 'application/zip',
});


export default { CommonMimeTypes };
