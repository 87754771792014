import CountriesList from 'components/common/templates/lists/CountriesList';
import CurrenciesList from 'components/common/templates/lists/CurrenciesList';
import IndustriesList from 'components/common/templates/lists/IndustriesList';
import { countriesRouteSegmentPath, currenciesRouteSegmentPath, industriesRouteSegmentPath } from 'config/routes';

import { FlagOutlined, DollarCircleOutlined, ToolOutlined } from '@ant-design/icons';
import React from 'react';


export const createGlobalListPageTabsConfig = ({ t }) => [
    {
        icon: <DollarCircleOutlined />,
        label: t('Currencies'),
        isDefault: true,
        hasList: true,
        activeMainTabSegmentURL: currenciesRouteSegmentPath,
        content: <CurrenciesList />,
    },
    {
        icon: <FlagOutlined />,
        label: t('Countries'),
        hasList: true,
        activeMainTabSegmentURL: countriesRouteSegmentPath,
        content: <CountriesList />,
    },
    {
        icon: <ToolOutlined />,
        label: t('Industries'),
        hasList: true,
        activeMainTabSegmentURL: industriesRouteSegmentPath,
        content: <IndustriesList />,
    },

];

export default { createGlobalListPageTabsConfig };
