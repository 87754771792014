import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';


export const TranslationInlineWrap = styled.div`
  display: inline-block;
  color: inherit;
`;

export const hyperlinkStyles = css`
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
`;

export const Paragraph = styled.p`
  margin: 0;
  padding-bottom: 15px;
  font-weight: 400;
`;

export const Emphasis = styled.em`
  font-weight: 700;
`;

export const Underline = styled.u`
  text-decoration: underline;
`;

export const Quotation = styled.q`
  margin: 0;
  font-style: italic;
`;

export const Code = styled.code`
  font-family: monospace;
  font-size: 12px;
`;

export const Anchor = styled.a`
  ${hyperlinkStyles};
`;

export const InternalLink = styled(Link)`
  ${hyperlinkStyles};
`;

export const UnorderedList = styled.ul`
  margin: 0;
  padding: 0 0 15px 20px;
  list-style: disc;
`;

export const OrderedList = styled.ol`
  margin: 0;
  padding: 0 0 15px 20px;
  list-style: decimal;
`;

export const ListItem = styled.li`
  margin-bottom: 3px;
  list-style: inherit;
`;

export default {
    TranslationInlineWrap,
    Paragraph,
    Underline,
    Emphasis,
    Quotation,
    Code,
    Anchor,
    InternalLink,
    UnorderedList,
    OrderedList,
    ListItem,
};

