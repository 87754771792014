import styled from 'styled-components';


const PageWrapper = styled.div`
  padding: 16px;
  background: #fff;
  
  p {
    font-size: 16px;
  }
  
  h2 {
    text-decoration: underline;
  }
`;

const AttributionsWrapper = styled.div`
    padding: 30px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    
    img {
      width: 40%;
      min-width: 200px;
      height: auto;
    }
`;

const AttributionWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 30px;
`;

const CopyAndLinkWrapper = styled.div`
    display: grid;
    align-content: center;
    justify-items: left;
    font-family: monospace;
    font-weight: 400;
    font-size: 12px;
`;

const Name = styled.div`
  display: block;
  font-weight: 500;
  font-size: 14px;
`;

const ImageWrapper = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;

    img {
        width: 100%;
        max-width: 180px;
        height: auto;
      }
`;

const SvgConversionNote = styled.p`
  font-size: 12px !important;
`;


export default { PageWrapper, AttributionsWrapper, AttributionWrapper, CopyAndLinkWrapper, ImageWrapper, Name, SvgConversionNote };
