import { CompanyStatuses, ManigoKybStatuses } from 'constants/CompaniesModel';
import { enumValueToReadableString } from 'utils/string-tools';


export const getCompanyStatusOptions = (status, accessControl) => {
    const isManigoKyb = accessControl.isKycKybApprovalEnabled();

    const handlers = [
        ...(
            !isManigoKyb
                ? [
                    {
                        predicate: () => status === CompanyStatuses.INVITED,
                        handler: () => [CompanyStatuses.UNVERIFIED],
                    },
                    {
                        predicate: () => status === CompanyStatuses.UNVERIFIED,
                        handler: () => [CompanyStatuses.KYB_PENDING],
                    },
                    {
                        predicate: () => status === CompanyStatuses.KYB_PENDING,
                        handler: () => [CompanyStatuses.KYB_UPLOADED],
                    },
                    {
                        predicate: () => status === CompanyStatuses.KYB_UPLOADED,
                        handler: () => [CompanyStatuses.ACTIVE, CompanyStatuses.KYB_MANUAL_REVIEW, CompanyStatuses.KYB_REJECTED],
                    },
                    {
                        predicate: () => status === CompanyStatuses.KYB_REJECTED,
                        handler: () => [CompanyStatuses.KYB_PENDING, CompanyStatuses.KYB_UPLOADED],
                    },
                ] : []
        ),
        {
            predicate: () => status === CompanyStatuses.KYB_MANUAL_REVIEW,
            handler: () => [CompanyStatuses.KYB_PENDING, CompanyStatuses.KYB_REJECTED, CompanyStatuses.ACTIVE],
        },
        {
            predicate: () => status === CompanyStatuses.SUSPENDED,
            handler: () => [CompanyStatuses.ACTIVE],
        },
        {
            predicate: () => status === CompanyStatuses.DORMANT,
            handler: () => [CompanyStatuses.ACTIVE, CompanyStatuses.SUSPENDED],
        },
        {
            predicate: () => status === CompanyStatuses.ACTIVE || status === null,
            handler: () => [CompanyStatuses.KYB_PENDING, CompanyStatuses.SUSPENDED],
        },
    ];

    const options = handlers.find(({ predicate }) => predicate())?.handler().map((status) => ({
        value: status,
        label: enumValueToReadableString(status),
    }));
    if (!options) return [];
    const amendedOptions = [...options, { value: CompanyStatuses.BANNED, label: enumValueToReadableString(CompanyStatuses.BANNED) }];

    return isManigoKyb ? amendedOptions.filter((option) => Object.values(ManigoKybStatuses).includes(option.value)) : amendedOptions;
};

export const getStatusHint = (status) => {
    const handlers = [
        {
            predicate: () => status === CompanyStatuses.SUSPENDED,
            handler: () => ({
                color: 'warning',
                text: CompanyStatuses.SUSPENDED,
            }),
        },
        {
            predicate: () => status === CompanyStatuses.ACTIVE,
            handler: () => ({
                color: 'success',
                text: CompanyStatuses.ACTIVE,
            }),
        },
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];
    return handlers.find((handler) => handler.predicate(status)).handler();
};
