import styled from 'styled-components';


const Columns = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 50px;
  
  .ant-tabs {
    background: transparent;
  }
`;

const Column = styled.div`
  position: relative;
`;

// eslint-disable-next-line spellcheck/spell-checker
const DecorIcon = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  
  .anticon {
    box-sizing: border-box;
    display: grid;
    padding: 50px;
    height: 100%;
    align-items: center;
    
    svg {
      width: 100%;
      height: auto;
      opacity: .06;
      color: #397dcc;
    }
  }
`;

const ColumnContent = styled.div`
  position: relative;
  z-index: 3;
`;


const TransferDetails = styled.div`
  font-size: 16px;
`;

const ColumnHeading = styled.h2`
  border-bottom: 2px solid #f0f0f0;
`;

export default {
    Columns,
    Column,
    ColumnHeading,
    DecorIcon,
    TransferDetails,
    ColumnContent,
};
