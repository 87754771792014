import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import { createClientDetailsGroupsListPath, createGroupDetailsMembersListPath, createGroupDetailsPath } from 'config/routes.helpers';
import { showSuccessToast } from 'redux/application/actions';
import { fetchGroupDetails, reassignMemberGroupSuccess } from 'redux/group/actions';
import { groupDetailsPerLocationStoreKeyName, groupReducerName } from 'redux/group/reducer';
import { fetchGroupMembers } from 'redux/group-members/actions';
import { groupMembersListsPerLocationStoreKeyName, groupMembersReducerName } from 'redux/group-members/reducer';
import { fetchGroupsList } from 'redux/groups/actions';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { fetchMemberDetails } from 'redux/member/actions';
import { hideModal } from 'redux/modal/actions';

import React from 'react';


export const createDeleteGroupSuccessMessage = (t, values, newGroupId) => (
    <StandardTranslationWithMarkup
        translationKey={`actionMessages.${newGroupId ? 'deleteGroupAndReassignMembersSuccess' : 'deleteGroupSuccess'}`}
        namespace="groups"
        values={values}
        t={t}
    />
);

export const createOnSuccessReassignMemberGroupActions = ({ payload, state$, i18n }) => {
    const groupDetailsPath = createGroupDetailsPath(payload?.requestPayload?.clientId, payload?.requestPayload?.oldGroupId);
    const groupDetailsMembersListPath = createGroupDetailsMembersListPath(payload?.requestPayload?.clientId, payload?.requestPayload?.oldGroupId);
    const clientGroupsLocation = createClientDetailsGroupsListPath(payload?.requestPayload?.clientId);

    const currentGroupsListQueryParams = state$.value[groupsReducerName][groupsListsPerLocationStoreKeyName]?.[clientGroupsLocation]?.queryParams;
    const currentGroupMemberListQueryParams = state$.value[groupMembersReducerName][groupMembersListsPerLocationStoreKeyName]?.[groupDetailsMembersListPath]?.queryParams;
    const currentGroupDetailsQueryParams = state$.value[groupReducerName][groupDetailsPerLocationStoreKeyName]?.[groupDetailsPath]?.queryParams;

    const commonActions = [
        reassignMemberGroupSuccess(payload.locationPathname),
        hideModal(),
        showSuccessToast(i18n.t('groups:actionMessages.resignMemberGroupSuccess')),
        fetchMemberDetails({ memberId: payload?.requestPayload?.memberId }, payload.locationPathname),
    ];


    const handlers = [
        {
            predicate: () => currentGroupsListQueryParams,
            handler: () => [fetchGroupsList(currentGroupsListQueryParams, clientGroupsLocation)],
        },
        {
            predicate: () => currentGroupMemberListQueryParams,
            handler: () => [fetchGroupMembers(currentGroupMemberListQueryParams, groupDetailsMembersListPath)],
        },
        {
            predicate: () => currentGroupDetailsQueryParams,
            handler: () => [fetchGroupDetails(currentGroupDetailsQueryParams, groupDetailsPath)],
        },
    ];

    const additionalActions = handlers.filter(({ predicate }) => predicate()).flatMap(((item) => item.handler()));

    return [
        ...commonActions,
        ...additionalActions,
    ];
};

export default { createDeleteGroupSuccessMessage, createOnSuccessReassignMemberGroupActions };

