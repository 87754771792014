import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { clearUserDetails, fetchUserDetails } from 'redux/user/actions';
import { userDetailsPerLocationStoreKeyName, userReducerName } from 'redux/user/reducer';
import { openAddEditUserModal } from 'redux/users/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UserProfile from './UserProfile.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: userReducerName,
        fieldName: userDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 1,
    }),
    currentUser: state[currentUserReducerName],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchUserDetails: (queryParams, locationPathname) => dispatch(fetchUserDetails(queryParams, locationPathname)),
    dispatchClearUserDetails: (locationPathname) => dispatch(clearUserDetails(locationPathname)),
    dispatchOpenAddEditUserModal: (configData) => dispatch(openAddEditUserModal(configData)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: userReducerName,
        fieldName: userDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('users'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UserProfile);
