import AccountsDropdown from 'components/common/molecules/custom-dropdowns/AccountsDropdown';
import AccountInfoLayout from 'components/common/organisms/AccountInfoLayout';

import { Button, Form, Row, Col } from 'antd';
import React, { useEffect, useMemo } from 'react';


import { AccountSelectionProps } from './AccountSelection.types';
import BackButton from '../../BackButton';


function AccountSelection({
    t,
    dispatchFetchAllAccounts,
    dispatchSetSelectedAccountDetails,
    dispatchSetSelectedBeneficiaryDetails,
    accountsList,
    accountDetails,
    isLoadingAccounts,
    beneficiaryDetails,
}: AccountSelectionProps) {
    const formInstance = Form.useFormInstance();

    useEffect(() => {
        if (accountsList.length === 0) {
            dispatchFetchAllAccounts();
        }
    }, []);


    const filteredAccountsList = useMemo(
        () => accountsList.filter((account) => account.accountIdentifications?.length > 0),
        [accountsList],
    );


    const handleSelection = (value) => {
        const selectedAccount = accountsList.find((account) => account.id === value);
        dispatchSetSelectedAccountDetails(selectedAccount);
        if (beneficiaryDetails && beneficiaryDetails.currency !== selectedAccount?.currency) {
            formInstance.resetFields(['beneficiaryId']);
            dispatchSetSelectedBeneficiaryDetails();
        }
    };

    // XXX minHeight: "350px" - magic number, space for dropdown
    return (
        <Col>
            <div style={{ maxWidth: '500px', minHeight: '350px' }}>
                <AccountsDropdown
                    name="accountId"
                    label={t('transactions:outgoingTransfer.steps.accountSelection.fields.account.text')}
                    disableZeroBalances
                    isRequired
                    onChange={handleSelection}
                    items={filteredAccountsList}
                    loading={isLoadingAccounts}
                />

                {accountDetails && (
                    <AccountInfoLayout
                        accountDetails={accountDetails}
                    />
                )}
            </div>

            <Row key="form buttons" style={{ marginTop: 20 }}>
                <BackButton />
                <Form.Item>
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </Col>
    );
}

export default AccountSelection;
