import IntegerInput from 'components/common/molecules/form-controls/IntegerInput';
import {
    getCountriesOfOperation,
    getCountryOfIncorporation,
    getCurrentRiskValues,
    getOperatingIndustries,
} from 'components/common/templates/modals/ChangeClientRiskScoreModal/ChangeClientRiskScoreModal.helpers';
import { CountriesListProps, CountryIdType } from 'constants/CountriesModel';
import { IndustriesListProps } from 'constants/IndustriesModel';
import { ClientRiscScoreTypes } from 'constants/RiskScoreModel';
import { createCountriesOptions, createIndustryOptions } from 'utils/options-creators';

import { Form, Input, Radio, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';


const { TextArea } = Input;

function ChangeRiskScoreModal({
    initialValues,
    locationPathname,
    countries,
    industries,
    dispatchAddClientRiskDetails,
    dispatchSetModalProps,
    t,
}) {
    const [form] = Form.useForm();
    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);

    const [riskType, setRiskType] = useState(ClientRiscScoreTypes.COUNTRY_OF_INCORPORATION);
    const { country_of_incorporation_risk, riskDetails } = initialValues;

    const countryOfIncorporation = useMemo(
        () => getCountryOfIncorporation(initialValues?.client_address?.country, countries),
        [initialValues, countries],
    );

    const countriesOfOperation = useMemo(
        () => getCountriesOfOperation(initialValues, countries),
        [initialValues, countries],
    );

    const operatingIndustries = useMemo(
        () => getOperatingIndustries(initialValues, industries),
        [initialValues, industries],
    );

    const currentRiskValues = useMemo(
        () => getCurrentRiskValues(riskDetails),
        [initialValues],
    );

    const countriesOfOperationOptions = useMemo(
        () => createCountriesOptions(countriesOfOperation, CountryIdType.id),
        [countriesOfOperation],
    );

    const countryOfIncorporationOption = useMemo(
        () => createCountriesOptions(countryOfIncorporation ? [countryOfIncorporation] : [], CountryIdType.id),
        [countryOfIncorporation],
    );

    const industryOptions = useMemo(
        () => createIndustryOptions(operatingIndustries),
        [operatingIndustries],
    );


    useEffect(() => {
    // XXX 1. no need for risk score changes history search, this single value is always up-to-date
        form.setFieldsValue({ to: country_of_incorporation_risk });
    }, [country_of_incorporation_risk]);

    const onRadioChangeHandler = (e) => {
        setRiskType(e.target.value);
        form.resetFields();
        if (e.target.value === ClientRiscScoreTypes.COUNTRY_OF_INCORPORATION) {
            // XXX 1.1 same as above
            form.setFieldsValue({ to: country_of_incorporation_risk });
        }
    };

    const onSelectCountryOfOperationHandler = (val) => {
        const countryId = Number(val);
        const historyEntry = currentRiskValues.countries_of_operations[countryId];

        if (historyEntry) {
            form.setFieldsValue({ to: historyEntry.value });
        } else {
            const country = countries.find((country) => country.id === countryId);
            form.setFieldsValue({ to: country.risk_clients });
        }
    };

    const onSelectOperatingIndustryHandler = (val) => {
        const industryId = Number(val);
        const historyEntry = currentRiskValues.operating_industries[industryId];

        if (historyEntry) {
            form.setFieldsValue({ to: historyEntry.value });
        } else {
            const industry = industries.find((industry) => industry.id === industryId);
            form.setFieldsValue({ to: industry.risk_score });
        }
    };

    const onFinish = (formValues) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchAddClientRiskDetails({
            ...formValues,
            clientId: initialValues.id,
            to: Number(formValues.to),
        }, locationPathname);
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
        >
            <Form.Item>
                <Radio.Group
                    name="risk_type"
                    onChange={onRadioChangeHandler}
                    value={riskType}
                >
                    <Radio value={ClientRiscScoreTypes.COUNTRY_OF_INCORPORATION}>
            Country Of Incorporation
                    </Radio>
                    <Radio
                        value={ClientRiscScoreTypes.COUNTRIES_OF_OPERATIONS}
                        disabled={countriesOfOperation.length === 0}
                    >
            Countries Of Operations
                    </Radio>
                    <Radio
                        value={ClientRiscScoreTypes.INDUSTRIES}
                        disabled={operatingIndustries.length === 0}
                    >
            Operating Industries
                    </Radio>
                </Radio.Group>
            </Form.Item>

            {riskType === ClientRiscScoreTypes.COUNTRY_OF_INCORPORATION && (
                <Form.Item
                    name="country_id"
                    label={t('Country of Incorporation')}
                    initialValue={countryOfIncorporation?.id}
                >
                    <Select
                        placeholder="Country Of Incorporation"
                        bordered={false}
                        disabled
                    >
                        {countryOfIncorporationOption}
                    </Select>
                </Form.Item>
            )}

            {riskType === ClientRiscScoreTypes.COUNTRIES_OF_OPERATIONS && (
                <Form.Item
                    name="operation_country_id"
                    label="Countries Of Operations"
                    rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                >
                    <Select
                        placeholder="Select Country Of Operations"
                        onChange={onSelectCountryOfOperationHandler}
                    >
                        {countriesOfOperationOptions}
                    </Select>
                </Form.Item>
            )}

            {riskType === ClientRiscScoreTypes.INDUSTRIES && (
                <Form.Item
                    name="industry_id"
                    label="Operating Industries"
                    rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                >
                    <Select
                        placeholder="Select Industry"
                        onChange={onSelectOperatingIndustryHandler}
                    >
                        {industryOptions}
                    </Select>
                </Form.Item>
            )}

            <Form.Item
                name="to"
                label="New Risk score"
                rules={[
                    { required: true, message: t('common:validationMsg.fieldMandatory') },
                    () => ({
                        validator: async (_, value) => {
                            if (
                                (riskType === ClientRiscScoreTypes.COUNTRY_OF_INCORPORATION && value === country_of_incorporation_risk)
                || (riskType === ClientRiscScoreTypes.COUNTRIES_OF_OPERATIONS && value === initialValues.countries_of_operation_risk)
                || (riskType === ClientRiscScoreTypes.INDUSTRIES && value === initialValues.operating_industries_risk)
                            ) {
                                throw new Error('Please enter a new risk score');
                            }
                        },
                    }),
                ]}
            >
                <IntegerInput
                    min={1}
                    max={100}
                    step={1}
                    precision={0}
                    style={{ width: '100%' }}
                    placeholder="Enter new risk score"
                />
            </Form.Item>

            <Form.Item
                name="comment"
                label="Comment"
                rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
            >
                <TextArea placeholder="Enter a comment with a reason for the change" />
            </Form.Item>
        </Form>
    );
}

ChangeRiskScoreModal.propTypes = {
    industries: IndustriesListProps,
    countries: CountriesListProps,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchAddClientRiskDetails: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default ChangeRiskScoreModal;
