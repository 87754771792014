import { DatePicker } from 'antd';
import styled from 'styled-components';


const FormContent = styled.div`
  width: 100%;
  
  & .ant-picker.ant-picker-range {
    width: 100%;
  }
`;


const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: right;
  
  .ant-btn + .ant-btn { margin-left: 10px;}
`;


export default {
    FormContent,
    FullWidthDatePicker,
    ButtonsWrapper,
};
