import { standardPageSize } from 'config/config';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    clearBusinessUserStatusHistoryList,
    clearResetForm,
    fetchBusinessUserStatusHistoryList,
    fetchBusinessUserStatusHistoryListFailure,
    fetchBusinessUserStatusHistoryListSuccess,
    updateBusinessUserStatus,
    updateBusinessUserStatusFailure,
    updateBusinessUserStatusSuccess,
} from './actions';


export const businessUserStatusReducerName = 'businessUserStatuses';
export const businessUserStatusHistoryListsPerLocationStoreKeyName = 'businessUserStatusHistoryListsPerLocationStoreKey';

export const defaultBusinessUserStatusHistoryListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.DESCEND,
    ...withTotalCount,
};

const initialState = {
    [businessUserStatusHistoryListsPerLocationStoreKeyName]: {},
    isLoadingStatusUpdate: false,
};

const handlers = {
    [fetchBusinessUserStatusHistoryList]: (state, action) => {
        state[businessUserStatusHistoryListsPerLocationStoreKeyName] = updateStandardLists(state[businessUserStatusHistoryListsPerLocationStoreKeyName], action);
    },
    [fetchBusinessUserStatusHistoryListSuccess]: (state, action) => {
        state[businessUserStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[businessUserStatusHistoryListsPerLocationStoreKeyName], action);
    },
    [fetchBusinessUserStatusHistoryListFailure]: (state, action) => {
        state[businessUserStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[businessUserStatusHistoryListsPerLocationStoreKeyName], action);
    },
    [clearBusinessUserStatusHistoryList]: (state, action) => {
        state[businessUserStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[businessUserStatusHistoryListsPerLocationStoreKeyName],
            action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === businessUserStatusReducerName
        && action.payload?.fieldName === businessUserStatusHistoryListsPerLocationStoreKeyName
        ) {
            state[businessUserStatusHistoryListsPerLocationStoreKeyName] = updateStandardListDataQueryParams(
                { ...state?.[businessUserStatusHistoryListsPerLocationStoreKeyName] },
                action,
            );
        }
    },

    [updateBusinessUserStatus]: (state) => {
        state.isLoadingStatusUpdate = true;
    },
    [updateBusinessUserStatusSuccess]: (state) => {
        state.isLoadingStatusUpdate = false;
        state.shouldResetForm = true;
    },
    [updateBusinessUserStatusFailure]: (state) => {
        state.isLoadingStatusUpdate = false;
    },
    [clearResetForm]: (state) => {
        state.shouldResetForm = false;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
