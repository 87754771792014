import { confirmUnlinkCard } from 'redux/card/actions';
import { setModalProps } from 'redux/modal/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UnlinkCardModal from './UnlinkCardModal.layout';


const mapDispatchToProps = (dispatch) => ({
    dispatchConfirmUnlinkCard: (cardId, meta) => dispatch(confirmUnlinkCard(cardId, meta)),
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
});

const decorate = compose(
    withTranslation('cards'),
    connect(null, mapDispatchToProps),
);

export default decorate(UnlinkCardModal);
