import KycKybReviewAnswerTag from 'components/common/atoms/tags/KycKybReviewAnswerTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { sortingOptions } from 'utils/table-columns-sorters';

import React from 'react';


export const createKycKybAuditListColumns = ({ queryParams, t }) => [
    {
        title: t('ID'),
        dataIndex: 'id',
        defaultSortOrder: 'ascend',
        render: (text) => <StandardValue value={text} />,
        ...sortingOptions({ queryParams, fieldName: 'id' }),
    },
    {
        title: 'Applicant ID',
        dataIndex: 'applicant_id',
        render: (text) => <StandardValue value={text} />,
        ...sortingOptions({ queryParams, fieldName: 'applicant_id' }),
    },
    {
        title: 'Applicant type',
        dataIndex: 'applicant_type',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: 'Webhook type',
        dataIndex: 'type',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: 'Review Status',
        dataIndex: 'review_status',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: 'Review answer',
        dataIndex: 'review_answer',
        render: (text) => <KycKybReviewAnswerTag value={text} />,
    },
    {
        title: 'Reject Labels',
        dataIndex: 'reject_labels',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: 'Moderation Comment',
        dataIndex: 'moderation_comment',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: 'Partner Comment',
        dataIndex: 'client_comment',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: 'Created',
        dataIndex: 'created_at',
        render: (text) => <StandardValue value={text} valueType="DATE" />,
        ...sortingOptions({ queryParams, fieldName: 'created_at' }),
    },

];

export default { createKycKybAuditListColumns };
