import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { clearTransactionDetails, fetchTransactionDetails, refundTopUp } from 'redux/transaction/actions';
import { transactionDetailsPerLocationStoreKeyName, transactionReducerName } from 'redux/transaction/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import TransactionDetailsPage from './TransactionDetailsPage.layout';
import { TransactionDetailsPageDispatchProps, TransactionDetailsPageStateProps } from './TransactionDetailsPage.types';


const mapStateToProps = (state: RootState): TransactionDetailsPageStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,

    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: transactionReducerName,
        fieldName: transactionDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),

    isLoadingRefundTopUp: state?.[transactionReducerName]?.isLoadingRefundTopUp,
    isLoadingUnblockTransaction: state?.[transactionReducerName]?.isLoadingUnblockTransaction,
    isUnblockFormVisible: state?.[transactionReducerName]?.isUnblockFormVisible,
});


const mapDispatchToProps = (dispatch: Dispatch): TransactionDetailsPageDispatchProps => ({
    dispatchFetchTransactionDetails: (queryParams, locationPathname) => dispatch(fetchTransactionDetails(queryParams, locationPathname)),
    dispatchClearTransactionDetails: (locationPathname) => dispatch(clearTransactionDetails(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: transactionReducerName,
        fieldName: transactionDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchRefundTopUp: (transactionUuid) => dispatch(refundTopUp(transactionUuid)),
    dispatchRequestNavigation: (location, meta) => dispatch(requestNavigation(location, meta)),
});

const decorate = compose(
    withTranslation('transactions'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(TransactionDetailsPage);

