import StandardTag from 'components/common/atoms/StandardTag';

import { TransactionDetails, TransactionListItem } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React, { JSX } from 'react';


import mapTransactionScopeToColorAndLabel from './TransactionScopeTag.helpers';


function TransactionScopeTag({ transactionRecord, t, showTooltip }: {
  transactionRecord: TransactionListItem | TransactionDetails,
  t: TFunction,
  showTooltip?: boolean
}): JSX.Element {
    const tagData = mapTransactionScopeToColorAndLabel(transactionRecord, t);

    if (tagData) {
        return <StandardTag color={tagData.color} text={tagData.label} showTooltip={showTooltip} />;
    }
    return <>-</>;
}


export default TransactionScopeTag;
