import { AddEditRoleStepDefinitions } from 'constants/AddEditRoleModel';
import { MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';

import { EyeOutlined } from '@ant-design/icons';
import React from 'react';


import ReviewStep from './index';


export function createReviewStepConfig({ t, locationPathname }) {
    return {
        stepName: AddEditRoleStepDefinitions.SUMMARY,
        title: t('Review'),
        icon: <EyeOutlined />,
        sectionsLayout: MultiStepFormStepSectionTypes.EVEN_COLUMNS,
        stepSections: [
            {
                title: 'Review',
                children: <ReviewStep locationPathname={locationPathname} />,
            },
        ],
    };
}


export default { createReviewStepConfig };
