import { createTransactionDetailsPath } from 'config/routes.helpers';
import { showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    fetchTransactionDetailsFailure,
    fetchTransactionDetailsSuccess,
    createP2PTransactionSuccess,
    createP2PTransactionFailure,
    refundTopUpSuccess,
    refundTopUpFailure,
    unblockTransactionSuccess,
    unblockTransactionFailure,
    auditPendingTransactionSuccess,
    auditPendingTransactionFailure,
    fetchTransactionDetails,
} from './actions';
import { AUDIT_PENDING_TRANSACTION, FETCH_TRANSACTION_DETAILS, UNBLOCK_TRANSACTION } from './actions.types';


export const onFetchTransactionDetails = (action$, state$, { transactions }) => action$.pipe(
    ofType(FETCH_TRANSACTION_DETAILS),
    switchMap(({ payload: { queryParams, locationPathname } }) => from(transactions.getTransactionDetails(queryParams?.transactionUuid)).pipe(
        switchMap((response) => of(fetchTransactionDetailsSuccess(response?.data, locationPathname))),
        catchError(() => of(fetchTransactionDetailsFailure(locationPathname))),
    )),
);

export const onCreateP2PTransaction = (action$, state$, { transactions, i18n }) => action$.pipe(
    ofType('transaction/createP2PTransaction'),
    switchMap(({ payload }) => from(transactions.createNewPayment(payload)).pipe(
        switchMap((response) => of(
            createP2PTransactionSuccess(response.data),
            showSuccessToast(i18n.t('transactions:actionMessages.createNewPaymentSuccess')),
        )),
        catchError(() => of(createP2PTransactionFailure())),
    )),
);

export const onRefundTopUp = (action$, state$, { transactions, i18n }) => action$.pipe(
    ofType('transaction/refundTopUp'),
    switchMap(({ payload }) => from(transactions.refundTopUp(payload)).pipe(
        switchMap((response) => of(
            refundTopUpSuccess(response.data),
            fetchTransactionDetails({ transactionUuid: payload }, createTransactionDetailsPath(payload)),
            showSuccessToast(i18n.t('transactions:actionMessages.refundSuccess')),
        )),
        catchError(() => of(refundTopUpFailure())),
    )),
);


export const onUnblockTransaction = (action$, state$, { transactions, i18n }) => action$.pipe(
    ofType(UNBLOCK_TRANSACTION),
    switchMap(({ payload }) => from(transactions.unblockTransaction(payload.queryParams)).pipe(
        switchMap((response) => of(
            hideModal(),
            unblockTransactionSuccess(response.data, payload.locationPathname),
            showSuccessToast(i18n.t('transactions:actionMessages.unblockSuccess')),
        )),
        catchError(() => of(
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
            unblockTransactionFailure(),
        )),
    )),
);

export const onAuditPendingTransaction = (action$, state$, { transactions, i18n }) => action$.pipe(
    ofType(AUDIT_PENDING_TRANSACTION),
    switchMap(({ payload }) => from(transactions.auditPendingTransaction(payload.requestPayload)).pipe(
        switchMap((response) => {
            const resolveActionLabel = (action) => {
                if (action === 'accept') {
                    return i18n.t('transactions:actionMessages.auditPendingApproveSuccess');
                }
                return i18n.t('transactions:actionMessages.auditPendingRejectedSuccess');
            };

            return of(
                hideModal(),
                fetchTransactionDetails({ transactionUuid: payload.requestPayload?.transactionUuid }, payload.locationPathname),
                auditPendingTransactionSuccess(response.data),
                showSuccessToast(resolveActionLabel(payload.requestPayload?.action)),
            );
        }),
        catchError(() => of(
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
            auditPendingTransactionFailure(payload.locationPathname),
        )),
    )),
);


export default [
    onFetchTransactionDetails,
    onCreateP2PTransaction,
    onRefundTopUp,
    onUnblockTransaction,
    onAuditPendingTransaction,
];
