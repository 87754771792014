import withAccessControl from 'hocs/withAccessControl';
import { unlockUser } from 'redux/authorisation/actions';
import { authorisationReducerName } from 'redux/authorisation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UnlockLogin from './UnlockLogin.layout';


const mapStateToProps = (state) => ({ isLoadingUnlockLogin: state[authorisationReducerName].isLoadingUnlockLogin });

const mapDispatchToProps = (dispatch) => ({ dispatchUnlockUser: ({ extUserUuid, context }) => dispatch(unlockUser({ extUserUuid, context })) });


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UnlockLogin);
