import StandardListTable from 'components/common/organisms/StandardListTable';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { StakeholderListProp } from 'constants/StakeholderModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultStakeholdersListQueryParams } from 'redux/business-stakeholders/reducer';

import PropTypes from 'prop-types';
import React from 'react';


import tableColumns from './tableColumns';


function StakeholdersList({
    // ownProps
    companyId,
    dispatchShowModal,
    isInReadOnlyMode,
    // XXX  list state data
    listData,
    enhancedCurrentLocation,
    accessControl,

    // other mapped props
    dispatchFetchStakeholdersList,
    dispatchClearStakeholdersList,
    dispatchSetQueryParams,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { companyId };


    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchStakeholdersList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultStakeholdersListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchStakeholdersList,
        dispatchClearItemsList: dispatchClearStakeholdersList,
        dispatchSetQueryParams,
    });


    return (
        <StandardListTable
            rowKey={'stakeholder_user_uuid'}
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={tableColumns({
                dispatchShowModal,
                accessControl,
                isInReadOnlyMode,
                enhancedCurrentLocation,
                companyId,
                t,
            })}
        />
    );
}

StakeholdersList.propTypes = {
    accessControl: AccessControlProps,
    // mapped props
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: StakeholderListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    companyId: PropTypes.number,

    dispatchShowModal: PropTypes.func.isRequired,
    dispatchFetchStakeholdersList: PropTypes.func.isRequired,
    dispatchClearStakeholdersList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isInReadOnlyMode: PropTypes.bool,
};

export default StakeholdersList;
