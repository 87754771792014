/* eslint-disable no-unused-vars */
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { memberRootRoutePath } from 'config/routes';

import PropTypes from 'prop-types';
import React from 'react';


function createManualFeesListTableColumns({ t, memberId }) {
    return [
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.id'),
            dataIndex: 'id',
            render: (value) => <StandardValue value={value} />,
        },
        ...(!memberId ? [{
            title: t('marketingPreferences:marketingPreferencesList.fields.member_id'),
            dataIndex: 'member_id',
            render: (value) => (
                <StandardLink
                    path={`${memberRootRoutePath}/${value}`}
                    title={t(`Member #${value}`)}
                />
            ),
        }] : []),
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.marketing_email'),
            dataIndex: 'marketing_email',
            align: 'center',
            render: (_, record) => booleanAsIconsRenderer(record, 'marketing_email', t),
        },
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.marketing_push'),
            dataIndex: 'marketing_push',
            align: 'center',
            render: (_, record) => booleanAsIconsRenderer(record, 'marketing_push', t),
        },
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.marketing_phone'),
            dataIndex: 'marketing_phone',
            align: 'center',
            render: (_, record) => booleanAsIconsRenderer(record, 'marketing_phone', t),

        },
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.marketing_post'),
            dataIndex: 'marketing_post',
            align: 'center',
            render: (_, record) => booleanAsIconsRenderer(record, 'marketing_post', t),
        },
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.marketing_partners_email'),
            dataIndex: 'marketing_partners_email',
            align: 'center',
            render: (_, record) => booleanAsIconsRenderer(record, 'marketing_partners_email', t),
        },
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.marketing_partners_push'),
            dataIndex: 'marketing_partners_push',
            align: 'center',
            render: (_, record) => booleanAsIconsRenderer(record, 'marketing_partners_push', t),
        },
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.marketing_partners_phone'),
            dataIndex: 'marketing_partners_phone',
            align: 'center',
            render: (_, record) => booleanAsIconsRenderer(record, 'marketing_partners_phone', t),
        },
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.marketing_partners_post'),
            dataIndex: 'marketing_partners_post',
            align: 'center',
            render: (_, record) => booleanAsIconsRenderer(record, 'marketing_partners_post', t),
        },
        {
            title: t('marketingPreferences:marketingPreferencesList.fields.updated_at'),
            dataIndex: 'updated_at',
            render: (value) => <StandardValue value={value} valueType={ValueTypes.DATE} />,
        },
    ];
}


createManualFeesListTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    memberId: PropTypes.number,
};


export default createManualFeesListTableColumns;
