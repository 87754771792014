import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import React from 'react';
import { useParams } from 'react-router-dom';

import { LimitDetailsProps } from './LimitDetails.types';
import LimitInformation from './LimitInformation';


function LimitDetails({
    t,

    entityDetailsData,
    enhancedCurrentLocation,

    dispatchFetchLimitDetails,
    dispatchClearLimitDetails,
    dispatchSetQueryParams,
}: LimitDetailsProps) {
    const entityDetails = entityDetailsData?.entityDetails;

    const { limitExtId } = useParams();

    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(
        enhancedCurrentLocation?.pathname,
        baseURLSegmentsCount,
    );

    const contextEnforcedQueryParams = { limitExtId };

    useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchLimitDetails,
        dispatchClearEntityDetails: dispatchClearLimitDetails,
        dispatchSetQueryParams,
    });

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('limits:limitDetailsPage.screenTitle', { limitExtId })}
            baseURLSegmentsCount={2}
            tabsConfig={[]}
        >
            <LimitInformation
                t={t}
                isLoading={entityDetailsData?.isLoadingDetails}
                onRefresh={() => dispatchFetchLimitDetails(
                    contextEnforcedQueryParams,
                    baseUrlWithoutTabsSlugs,
                )}
                queryParams={entityDetailsData?.queryParams}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                limitDetails={entityDetails}
            />
        </StandardEntityDetailsWithTabs>
    );
}

export default LimitDetails;
