import StandardListTable from 'components/common/organisms/StandardListTable';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { RoleProps } from 'constants/RolesModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultRolesListQueryParams } from 'redux/roles/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
// import { createAddRolePath } from 'config/routes.helpers';

import createColumns from './tableColumns';
// import { PN } from 'constants/PermissionsModel';

// TODO restore add role button ASAP - BE fix & FE error with form in prod build :face_palm:

function RolesList({
    accessControl,

    enhancedCurrentLocation,
    listData,

    dispatchFetchRolesList,
    dispatchClearRolesList,
    dispatchRequestNavigation,
    dispatchSetQueryParams,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { };

    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation.pathname);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultRolesListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchRolesList,
        dispatchClearItemsList: dispatchClearRolesList,
        dispatchSetQueryParams,
    });


    // 4. list specific stuff - local search, local sorting is in table configuration, see 'generateColumns'
    const filteredRoles = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((role) => searchCaseInsensitive(role.display_name, listData?.queryParams?.search)
          || searchCaseInsensitive(role.description, listData?.queryParams?.search)
          || searchCaseInsensitive(`${role.id}`, listData?.queryParams?.search));
            }
            return listData?.items || [];
        }, [listData],
    );

    // // 4. context & permission dependent optional account list filters & buttons
    // const additionalButtons = useMemo(
    //   () => (
    //     [
    //       ...(accessControl.hasPermission(PN.ROLES.CREATE)
    //         && accessControl.isSuperAdmin
    //         ? [{
    //           text: t('Add role'),
    //           type: 'primary',
    //           icon: <PlusOutlined />,
    //           onClick: () => dispatchRequestNavigation(createAddRolePath())
    //         }]
    //         : [])
    //     ]), [accessControl]
    // );


    return (
        <>
            <StandardPageHeading title={t('rolesList.screenTitle')} />

            <StandardPageWrapper>
                <StandardListTable
                    onUpdate={fetchData}
                    onListRefresh={() => dispatchFetchRolesList({ ...listData?.queryParams }, enhancedCurrentLocation?.pathname)}
                    items={filteredRoles}
                    totalItemsCount={filteredRoles?.length}
                    queryParams={listData?.queryParams}
                    queryParamsMeta={listData?.queryParamsMeta}
                    isTableLoading={listData?.isLoadingList}
                    isLoadingPlaceholder={!isListInitialStateDefined}
                    columnsConfig={createColumns({
                        accessControl,
                        t,
                        queryParams: listData?.queryParams,
                        dispatchRequestNavigation,
                    })}
                    // filtersButtonsConfig={additionalButtons}
                />
            </StandardPageWrapper>
        </>
    );
}


RolesList.propTypes = {
    accessControl: AccessControlProps,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(RoleProps),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchFetchRolesList: PropTypes.func.isRequired,
    dispatchClearRolesList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default RolesList;
