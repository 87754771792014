import { filterOptions } from 'utils/filterOptions';
import { createGroupsOptions } from 'utils/options-creators';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';


import Styled from './DeleteGroup.styled';


function DeleteGroup({
    initialValues, // XXX group details
    clientGroupsListData,

    t,
    dispatchFetchGroups,
    dispatchDeleteGroup,
    dispatchSetModalProps,
}) {
    const [form] = Form.useForm();


    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);

    useEffect(() => {
        if (!clientGroupsListData?.items) {
            dispatchFetchGroups({ clientId: initialValues.client_id });
        }
    }, []);

    const onFinish = (values) => {
        const newGroupId = values?.newGroupId;

        const meta = {
            clientId: initialValues.client_id,
            groupName: initialValues?.name,
            count: initialValues?.customer_count,
            newGroupName: (clientGroupsListData?.items || []).find((group) => group.id === newGroupId)?.name,
        };

        dispatchDeleteGroup(initialValues.id, values?.newGroupId, meta);
    };


    const otherGroupsOptions = useMemo(
        () => {
            if (clientGroupsListData?.items) {
                const filteredGroups = clientGroupsListData.items.filter((group) => group.id !== initialValues.id);
                return createGroupsOptions(filteredGroups);
            }
            return createGroupsOptions([]);
        }, [clientGroupsListData, initialValues],
    );

    const groupContainsMembers = useMemo(
        () => initialValues?.customer_count > 0,
        [initialValues],
    );

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
        >
            {groupContainsMembers ? (
                <>
                    <Styled.GroupHasMembersWarning>
                        <Styled.WarningIcon />
                        <div>
                            <Trans
                                t={t}
                                i18nKey="modals.deleteGroup.groupHasMembersWarning"
                                ns="groups"
                                values={{ groupName: initialValues?.name, count: initialValues?.customer_count }}
                                components={{ strong: <Styled.Strong /> }}
                            />
                        </div>
                    </Styled.GroupHasMembersWarning>

                    <Form.Item
                        name="newGroupId"
                        label={t('modals.deleteGroup.fields.newGroupId.label')}
                        rules={[{ required: true, message: t('modals.deleteGroup.fields.newGroupId.validationRules.required') }]}
                    >
                        <Select
                            loading={clientGroupsListData?.isLoadingList}
                            disabled={clientGroupsListData?.isLoadingList}
                            showSearch
                            filterOption={filterOptions}
                            placeholder={t('modals.deleteGroup.fields.newGroupId.label')}
                        >
                            {otherGroupsOptions}
                        </Select>
                    </Form.Item>
                </>
            ) : (
                <Trans
                    t={t}
                    i18nKey="modals.deleteGroup.groupHasNoMembersWarning"
                    ns="groups"
                    values={{ groupName: initialValues.name }}
                    components={{ strong: <Styled.Strong /> }}
                />
            )}
        </Form>
    );
}


DeleteGroup.propTypes = {
    initialValues: PropTypes.shape({
        id: PropTypes.number.isRequired,
        client_id: PropTypes.number.isRequired,
        customer_count: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }),

    t: PropTypes.func.isRequired,
    dispatchFetchGroups: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchDeleteGroup: PropTypes.func.isRequired,
};


export default DeleteGroup;

