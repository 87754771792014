import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setModalProps } from 'redux/modal/actions';
import { modalReducerName } from 'redux/modal/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UpdateMemberStatusModal from './UpdateMemberStatusModal.layout';
import { UpdateMemberStatusModalDispatchProps, UpdateMemberStatusModalStateProps } from './UpdateMemberStatusModal.types';


const mapStateToProps = (state: RootState): UpdateMemberStatusModalStateProps => ({
    modalProps: state[modalReducerName]?.modalProps,
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
});

const mapDispatchToProps = (dispatch: Dispatch): UpdateMemberStatusModalDispatchProps => ({ dispatchSetModalProps: (payload) => dispatch(setModalProps(payload)) });

const decorate = compose(
    withTranslation(),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);
export default decorate(UpdateMemberStatusModal);
