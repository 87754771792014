import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import styled, { css } from 'styled-components';


const FlagAndValueWrapper = styled.div<{ $inlineMode?: boolean; $valueFirst?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-column-gap: 1ch;
  align-items: center;

  ${({ $inlineMode = false }) => $inlineMode && css`
    display: inline-grid;
    width: auto;
    max-width: 100%;
  `};

  ${({ $valueFirst = false }) => $valueFirst && css`
    grid-template-columns: minmax(0, 1fr) auto;
  `};
`;

const Value = styled.div`
  width: 100%;
  ${commonTextEllipsisStyles};
`;

export default {
    FlagAndValueWrapper,
    Value,
};
