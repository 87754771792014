export const FETCH_MEMBER_DETAILS = 'FETCH_MEMBER_DETAILS';
export const FETCH_MEMBER_DETAILS_SUCCESS = 'FETCH_MEMBER_DETAILS_SUCCESS';
export const FETCH_MEMBER_DETAILS_FAILURE = 'FETCH_MEMBER_DETAILS_FAILURE';

export const CLEAR_MEMBER_DETAILS = 'CLEAR_MEMBER_DETAILS';

export const OPEN_UPDATE_MEMBER_MODAL = 'OPEN_UPDATE_MEMBER_MODAL';

export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';
