import CommonAccountDetails from 'components/common/organisms/CommonAccountDetails';
import { WL_CONFIG } from 'config/config';
import { AccountsListItemProps } from 'constants/AccountModel';
import { commonValidation, handleWhitespaceOnBlur } from 'utils/validation-tools';

import { Form, Input, Alert } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function WithdrawAccountBalanceModal({
    initialValues,
    dispatchWithdrawBalance,
    dispatchSetModalProps,
    enhancedCurrentLocation,
    t,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);


    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchWithdrawBalance(values, enhancedCurrentLocation?.pathname);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ accountId: initialValues.id }}
            onFinish={onFinish}
        >
            <CommonAccountDetails t={t} accountDetails={initialValues} />
            <Alert
                description={t('modals.withdrawAccountBalance.warningInfo', { brandShortName: WL_CONFIG.brandShortName })}
                type="warning"
                showIcon
            />
            <br />

            <Form.Item
                name="accountId"
                hidden
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="reference"
                label={t('accountFields.transactionReference.label')}
                rules={[...commonValidation({ t, isRequired: true })]}
            >
                <Input
                    placeholder={t('accountFields.transactionReference.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'reference', form)}
                />
            </Form.Item>

            <Form.Item
                name="reason"
                label={t('accountFields.transactionReason.label')}
                rules={[...commonValidation({ t })]}
            >
                <Input
                    placeholder={t('accountFields.transactionReason.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'reason', form)}
                />
            </Form.Item>
        </Form>
    );
}


WithdrawAccountBalanceModal.propTypes = {
    t: PropTypes.func.isRequired,
    initialValues: AccountsListItemProps,
    dispatchWithdrawBalance: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
};

export default WithdrawAccountBalanceModal;

