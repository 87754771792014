import { LocationChangeActionScopeTypes, NavigationDirection } from 'constants/NavigationModel';

import { useEffect, useMemo, useState, useRef } from 'react';

import { EnhancedLocation, LocationPathname } from '../models/app/navigation';


function useStandardListCachingLogic({
    useGlobalLocation = false,
    customLocation = undefined,
    omitDataFetching = false,

    contextEnforcedQueryParams,
    defaultItemsListQueryParams,

    enhancedCurrentLocation,
    listData,

    dispatchFetchItemsList,
    dispatchClearItemsList,
    dispatchSetQueryParams,
}: {
  useGlobalLocation?: boolean,
  omitDataFetching?: boolean,
  customLocation?: LocationPathname,
  contextEnforcedQueryParams: any,
  defaultItemsListQueryParams: any,
  enhancedCurrentLocation?: EnhancedLocation,
  listData: any,
  dispatchFetchItemsList: (queryParams, locationPathname) => void,
  dispatchSetQueryParams: (queryParams, locationPathname) => void,
  dispatchClearItemsList: (locationPathname) => void,

}): boolean {
    const locationOnMount = useRef(enhancedCurrentLocation?.pathname);
    const areInitialQueryParamsSet = useRef(false);
    const doesListRequiresCleanup = useRef(false);

    const [isListReadyForDisplay, setIsListReadyForDisplay] = useState(false);

    // InitialQuery === default query params + query params enforced by context/props
    const initialQueryParams = {
        ...defaultItemsListQueryParams,
        ...contextEnforcedQueryParams,
    };

    // helper variables for ease of reading
    const currentPathname = useMemo(
        () => enhancedCurrentLocation?.pathname,
        [enhancedCurrentLocation],
    );

    const isValidLocation = useMemo(
        () => enhancedCurrentLocation?.pathname === locationOnMount.current,
        [enhancedCurrentLocation],
    );

    const isListPristineEmpty = useMemo(
        () => isValidLocation && listData?.queryParamsMeta?.location?.pathname !== locationOnMount.current,
        [isValidLocation, listData],
    );


    // XXX very, very important
    const onLocationChangeHandlers = [
    // no location (or current location doesn't match location form list data)-> do nothing
        {
            predicate: () => !isValidLocation,
            handler: () => undefined,
        },
        // 1st time on given location, set queryParams and fetch data, spinner ON
        {
            predicate: () => isValidLocation
        && isListPristineEmpty
        && !areInitialQueryParamsSet.current
        && !doesListRequiresCleanup.current,
            handler: () => {
                areInitialQueryParamsSet.current = true;
                dispatchSetQueryParams(initialQueryParams, useGlobalLocation ? undefined : customLocation || currentPathname);
                if (!omitDataFetching || !useGlobalLocation) {
                    dispatchFetchItemsList(initialQueryParams, useGlobalLocation ? undefined : customLocation || currentPathname);
                }
            },
        },

        // returning to on given location via forward navigation (except TAB_CHANGE)!!
        // & clear existing query params and list results (except global lists) for given location and fetch new with initialQueryParams, spinner ON
        {
            predicate: () => isValidLocation
        && !isListPristineEmpty
        && !doesListRequiresCleanup.current
        && enhancedCurrentLocation?.direction === NavigationDirection.FORWARD
        && enhancedCurrentLocation?.state?.type !== LocationChangeActionScopeTypes.TAB_CHANGE,

            handler: () => {
                areInitialQueryParamsSet.current = true;
                if (omitDataFetching || useGlobalLocation) {
                    areInitialQueryParamsSet.current = true;
                    doesListRequiresCleanup.current = false;
                    dispatchSetQueryParams(initialQueryParams, useGlobalLocation ? undefined : customLocation || currentPathname);
                    setIsListReadyForDisplay(true);
                } else {
                    doesListRequiresCleanup.current = true;
                    dispatchClearItemsList(currentPathname);
                    dispatchSetQueryParams(initialQueryParams, useGlobalLocation ? undefined : customLocation || currentPathname);
                    dispatchFetchItemsList(initialQueryParams, useGlobalLocation ? undefined : customLocation || currentPathname);
                    setIsListReadyForDisplay(false);
                }
            },
        },

        // returning to on given location via TAB_CHANGE or go back, just display what is already there, spinner OFF
        {
            predicate: () => isValidLocation
        && !isListPristineEmpty
        && !areInitialQueryParamsSet.current
        && !doesListRequiresCleanup.current
        && (enhancedCurrentLocation?.direction !== NavigationDirection.FORWARD
          || (enhancedCurrentLocation?.direction === NavigationDirection.FORWARD
            && enhancedCurrentLocation?.state?.type === LocationChangeActionScopeTypes.TAB_CHANGE)
        ),
            handler: () => {
                areInitialQueryParamsSet.current = true;
                doesListRequiresCleanup.current = false;
                setIsListReadyForDisplay(true);
            },
        },

        // default fallback
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];


    const onListDataChangeHandlers = [
    // 1st time on given location - PART TWO, data was fetched, spinner OFF
        {
            predicate: () => isValidLocation
        && !isListPristineEmpty
        && !listData?.isLoadingList
        && areInitialQueryParamsSet.current
        && !doesListRequiresCleanup.current
        && !isListReadyForDisplay,
            handler: () => {
                setIsListReadyForDisplay(true);
            },
        },
        // returning to on given location via forward navigation - PART II (after clearing, new data has arrived), spinner OFF
        {
            predicate: () => isValidLocation
        && !isListPristineEmpty
        && areInitialQueryParamsSet.current
        && doesListRequiresCleanup.current
        && !listData?.isLoadingList, // XXX important
            handler: () => {
                // setDoesListRequiresCleanup(false);
                doesListRequiresCleanup.current = false;
                setIsListReadyForDisplay(true);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];


    useEffect(() => onLocationChangeHandlers.filter(({ predicate }) => predicate())[0].handler(), [enhancedCurrentLocation]);

    useEffect(() => onListDataChangeHandlers.filter(({ predicate }) => predicate())[0].handler(), [listData]);

    return isListReadyForDisplay;
}

export default useStandardListCachingLogic;
