import FlagAndValue from 'components/common/atoms/FlagAndValue';
import { filterOptions } from 'utils/filterOptions';
import { requiredRule } from 'utils/validation-tools';

import { Select, Form } from 'antd';
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';


import Styled from './BeneficiariesDropdown.styled';
import { BeneficiariesDropdownProps } from './BeneficiariesDropdown.types';


function BeneficiariesDropdown({
    beneficiariesList = [],
    isLoadingBeneficiaries = false,
    placeholder = 'Select beneficiary',
    value = undefined,
    onChange,
    onSelect,
    fieldName,
    fieldLabel,
    isRequired,
    t,
}: BeneficiariesDropdownProps) {
    const beneficiariesOptions = useMemo(
        () => beneficiariesList.map(({
            id,
            firstName,
            middleName,
            lastName,
            companyName,
            currency,
            country,
        }) => (
            <Select.Option
                key={id}
                value={id}
                label={`${companyName}  ${currency} ${country} ${firstName} ${middleName} ${lastName}`}
            >
                <Styled.BeneficiaryOptionWrapper>
                    <Styled.Left>
                        {companyName || `${firstName}${middleName ? ` ${middleName}` : ''} ${lastName}`}
                    </Styled.Left>
                    <Styled.Right>
                        <FlagAndValue inlineMode countryCode={country} text={country} useCountryISOAlpha3Code />
                        <FlagAndValue inlineMode currencyCode={currency} text={currency} />
                    </Styled.Right>
                </Styled.BeneficiaryOptionWrapper>
            </Select.Option>
        )), [beneficiariesList],
    );


    const select = (
        <Select
            showSearch
            allowClear
            filterOption={filterOptions}
            loading={isLoadingBeneficiaries}
            notFoundContent={isLoadingBeneficiaries ? t('common:select.loadingText') : t('common:select.noDataText')}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onSelect={onSelect}
        >
            {beneficiariesOptions}
        </Select>
    );


    return fieldName ? (
        <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={isRequired ? requiredRule(t) : []}
        >
            {select}
        </Form.Item>
    ) : select;
}

export default withTranslation()(BeneficiariesDropdown);
