import ErrorMessage from 'components/common/atoms/ErrorMessage/ErrorMessage';
import Countdown from 'components/common/molecules/Countdown';
import StandardLink from 'components/common/molecules/StandardLink';
import DigitsCodeInput from 'components/common/organisms/DigitsCodeInput';
import { useCreateDigitsCodeInputReferences } from 'components/common/organisms/DigitsCodeInput/DigitsCodeInput.helpers';
import { UnauthorisedLinkWrapper, UnauthorisedScreenTitle } from 'components/layouts/PublicLayout/PublicLayout.styled';
import { loginRootRoutePath } from 'config/routes';

import { RedoOutlined } from '@ant-design/icons';
import { Form, Button } from 'antd';
import React, { useEffect, JSX } from 'react';

import { PasscodeConfirmProps } from './PasscodeConfirm.types';


function PasscodeConfirmContainer({
    loginRequestId,
    submittedUserNameOrEmail,
    loginConfirmFormError,
    isLoadingLoginConfirmationForm,
    dispatchConfirmLogin,
    dispatchRequestNavigation,
    loginConfirmationValues,
    dispatchSendConfirmationCode,
    loginToken,
    isLoadingSendConfirmationCode,
    sendConfirmationCodeBlockUntil,
    dispatchClearBlockSendConfirmationCode,
    t,
}: PasscodeConfirmProps): JSX.Element {
    const references = useCreateDigitsCodeInputReferences();

    useEffect(() => {
        if (!loginRequestId) {
            dispatchRequestNavigation(loginRootRoutePath);
        }
    }, []);

    const parseValues = (values) => Object.values(values).join('');

    const onFinish = () => {
        dispatchConfirmLogin({
            passcode: parseValues(loginConfirmationValues),
            emailOrUsername: `${submittedUserNameOrEmail}`,
        });
    };

    const onResendConfirmationCode = () => {
        dispatchSendConfirmationCode({ token: `${loginToken}` });
    };


    return (
        <>
            <UnauthorisedScreenTitle>
                {t('unauthorised:screenTitles.passcodeConfirmation')}
            </UnauthorisedScreenTitle>

            <Form onFinish={onFinish}>
                <DigitsCodeInput
                    references={references}
                    loginConfirmationValues={loginConfirmationValues}
                    loginConfirmFormError={loginConfirmFormError}
                />

                <Form.Item style={{ paddingTop: 10 }}>
                    <Button
                        block
                        ref={references.refButtonSubmit}
                        disabled={parseValues(loginConfirmationValues)?.length < 6 || loginConfirmFormError}
                        type="primary"
                        data-test-id="submit-passcode-button"
                        loading={isLoadingLoginConfirmationForm}
                        htmlType={'submit'}
                    >
                        {t('unauthorised:formsElements.validatePasscode.text')}
                    </Button>
                </Form.Item>
            </Form>

            {loginConfirmFormError && <ErrorMessage text={t('unauthorised:validationMsg.invalidConfirmationCode')} />}

            <Form.Item>
                {sendConfirmationCodeBlockUntil ? (
                    <>
                        {t('unauthorised:formsElements.sendConfirmationCode.disabledCountdown')}
                        <Countdown
                            date={sendConfirmationCodeBlockUntil}
                            onCountdownEnd={dispatchClearBlockSendConfirmationCode}
                            withSuffix
                        />
                    </>
                ) : (
                    <UnauthorisedLinkWrapper>
                        <StandardLink
                            icon={<RedoOutlined />}
                            isLoading={isLoadingSendConfirmationCode}
                            isDisabled={isLoadingSendConfirmationCode || !!sendConfirmationCodeBlockUntil}
                            onClick={() => onResendConfirmationCode()}
                            title={t('unauthorised:formsElements.sendConfirmationCode.text')}
                            dataTestId="forgot-password-link"
                        />
                    </UnauthorisedLinkWrapper>
                )}
            </Form.Item>
        </>
    );
}


export default PasscodeConfirmContainer;
