import { standardPageSize } from 'config/config';
import { accountRootRoutePath, cardsRouteSegmentPath, companyRootRoutePath, businessUserRootRoutePath, memberRootRoutePath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists, updateStandardListsOnFailure, updateStandardListsOnSuccess,
    updateStandardListsOnCleanup,
    clearStandardListDataQueryParamsOnNavigation,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import { clearCardsList, fetchAllCards, fetchAllCardsFailure, fetchAllCardsSuccess } from './actions';


export const cardsReducerName = 'cards';
export const cardsListsPerLocationStoreKeyName = 'cardsListsPerLocation';

export const defaultCardListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    card_status: undefined,
    search: '',
    clientId: undefined,
    companyId: undefined,
    memberId: undefined,
    businessUserId: undefined,
    accountId: undefined,
    ...withTotalCount,
};


const initialState = { [cardsListsPerLocationStoreKeyName]: {} };

const handlers = {

    [fetchAllCards]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = updateStandardLists(state[cardsListsPerLocationStoreKeyName], action);
    },
    [fetchAllCardsSuccess]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[cardsListsPerLocationStoreKeyName], action);
    },
    [fetchAllCardsFailure]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[cardsListsPerLocationStoreKeyName], action);
    },
    [clearCardsList]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[cardsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === cardsReducerName
        && action.payload?.fieldName === cardsListsPerLocationStoreKeyName
        ) {
            state[cardsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[cardsListsPerLocationStoreKeyName] }, action);
        }
    },
    [preformNavigationSuccess]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[cardsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [cardsRouteSegmentPath],
                observedRootPaths: [accountRootRoutePath, companyRootRoutePath, businessUserRootRoutePath, memberRootRoutePath],
            },
        );
    },
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
