import SelectPermissionsStep from 'components/common/organisms/forms-shared-parts/add-edit-role-form/SelectPermissionsStep/index';
import { AddEditRoleStepDefinitions } from 'constants/AddEditRoleModel';
import { MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';

import { KeyOutlined } from '@ant-design/icons';
import React from 'react';


export function createSelectPermissionsStepConfig({ t, operationsLists }) {
    return {
        stepName: AddEditRoleStepDefinitions.OPERATIONS_LIST,
        title: t('Define permissions set'),
        icon: <KeyOutlined />,
        sectionsLayout: MultiStepFormStepSectionTypes.EVEN_COLUMNS,
        stepSections: [
            {
                title: 'Add or remove additional operations',
                children: <SelectPermissionsStep operationsLists={operationsLists} />,
            },
        ],
    };
}


export default { createSelectPermissionsStepConfig };
