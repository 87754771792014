import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import StepsIndicator from './StepsIndicator.layout';


const mapStateToProps = (state) => ({
    currentStep: state[beneficiaryAddReducerName].currentStep,
    isEditMode: state[beneficiaryAddReducerName].contextData?.beneficiaryId?.length > 0,
});

const decorate = compose(
    withTranslation('beneficiaries'),
    connect(mapStateToProps),
);

export default decorate(StepsIndicator);
