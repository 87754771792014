import withAccessControl from 'hocs/withAccessControl';
import {
    openBalanceAdjustmentModal,
    openWithdrawAccountBalanceModal,
} from 'redux/account/actions';
import {
    clearAccountsList,
    fetchAccountsList,
} from 'redux/accounts/actions';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'redux/accounts/reducer';
import { setQueryParams } from 'redux/application/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AccountsList from './AccountsList.layout';


const mapStateToProps = (state) => ({
    currentUserClientId: state[currentUserReducerName].userData?.clientId,
    clientConfig: state[currentUserReducerName].clientConfig,

    currencies: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: accountsReducerName,
        listsByLocationStoreKeyName: accountsListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch) => ({
    dispatchFetchAccountsList: (queryParams, locationPathname) => dispatch(fetchAccountsList(queryParams, locationPathname)),
    dispatchClearAccountsList: (locationPathname) => dispatch(clearAccountsList(locationPathname)),
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: accountsReducerName,
        fieldName: accountsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchOpenWithdrawAccountBalanceModal: (accountDetails, locationPathname) => dispatch(openWithdrawAccountBalanceModal(accountDetails, locationPathname)),
    dispatchOpenBalanceAdjustmentModal: (accountDetails, locationPathname) => dispatch(openBalanceAdjustmentModal(accountDetails, locationPathname)),
});

const decorate = compose(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AccountsList);
