import CountryOrCurrencyFlag from 'components/common/atoms/CountryOrCurrencyFlag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { sortBooleansByKey, sortingOptions, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import PropTypes from 'prop-types';
import React from 'react';


function createCurrenciesListTableColumns({
    t,
    queryParams,
}) {
    return [
        {
            title: t('ISO code'),
            dataIndex: 'short',
            align: 'center',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'short',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'short'),
            }),
        },
        {
            title: t('Icon'),
            dataIndex: 'icon',
            align: 'center',
            render: (text, record) => <CountryOrCurrencyFlag currencyCode={record.short} size={20} />,
        },
        {
            title: t('Full name'),
            dataIndex: 'full_name',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'full_name',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'full_name'),
            }),
        },
        {
            title: t('Symbol'),
            dataIndex: 'symbol',
            align: 'center',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'symbol',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'symbol'),
            }),
        },
        {
            title: t('Beneficiary'),
            dataIndex: 'beneficiary',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(record, 'beneficiary', t),
            ...sortingOptions({
                queryParams,
                fieldName: 'beneficiary',
                sorter: (a, b) => sortBooleansByKey(a, b, 'beneficiary'),
            }),
        },
        {
            title: t('Business account'),
            dataIndex: 'business_account_enabled',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(record, 'business_account_enabled', t),
            ...sortingOptions({
                queryParams,
                fieldName: 'business_account_enabled',
                sorter: (a, b) => sortBooleansByKey(a, b, 'business_account_enabled'),
            }),
        },
        {
            title: t('Wallet'),
            dataIndex: 'wallet',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(record, 'wallet', t),
            ...sortingOptions({
                queryParams,
                fieldName: 'wallet',
                sorter: (a, b) => sortBooleansByKey(a, b, 'wallet'),
            }),
        },
    ];
}


createCurrenciesListTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({ search: PropTypes.string }),
};


export default createCurrenciesListTableColumns;
