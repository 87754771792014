import ClientRiskTag, { ClientRiskTagContent } from 'components/common/atoms/tags/ClientRiskTag';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { ClientDetailsProps } from 'constants/ClientModel';
import { PN } from 'constants/PermissionsModel';

import { Button, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from '../RiskDetailsSubTab.styled';


function ClientRiskDetails({
    t,
    enhancedCurrentLocation,
    clientId,
    clientDetails,
    accessControl,
    isLoadingRiskDetails,
    dispatchOpenAddClientRiskDetailsModal,
}) {
    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    return (
        <Skeleton loading={isLoadingRiskDetails} active>
            <Styled.RiskDetailsWrapper>
                <div>
                    <h2>{t('riskScore:riskScoreDetails.heading')}</h2>
                    <Styled.CurrentRiskDetailsWrapper>
                        <div>
                            <ClientRiskTagContent client={clientDetails} t={t} />
                        </div>

                        <ClientRiskTag
                            score={clientDetails?.total_risk_score}
                            showTooltip={false}
                            isTextEllipsisDisabled
                            t={t}
                        />
                    </Styled.CurrentRiskDetailsWrapper>

                    <Styled.ChangeRiskScoreWrapper>
                        {accessControl.hasPermission(PN.CLIENTS.CREATE_RISK_DETAIL) && (
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => dispatchOpenAddClientRiskDetailsModal(
                                    {
                                        clientId,
                                        ...clientDetails,
                                    },
                                    baseUrlWithoutTabsSlugs,
                                )}
                            >
                                {t('riskScore:buttons.changeRiskScore.text')}
                            </Button>
                        )}
                    </Styled.ChangeRiskScoreWrapper>
                </div>
            </Styled.RiskDetailsWrapper>
        </Skeleton>
    );
}


ClientRiskDetails.propTypes = {
    clientId: PropTypes.number,
    t: PropTypes.func.isRequired,
    dispatchOpenAddClientRiskDetailsModal: PropTypes.func.isRequired,
    clientDetails: ClientDetailsProps,
    isLoadingRiskDetails: PropTypes.bool,
};


export default ClientRiskDetails;
