import { StandardFilterTypes } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import { AccessControlContent } from 'models/domain/accessControl';
import { RuleScope, RuleTransactionType, RuleType } from 'models/domain/rules';
import { removeUnderscores } from 'utils/string-tools';

import { TFunction } from 'i18next';


export const createTransactionTypeOptions = () => Object.keys(RuleTransactionType).map((key) => ({
    value: RuleTransactionType[key],
    text: removeUnderscores(RuleTransactionType[key]),
}));

// TODO: PDK-6061 NL filter issue
export const createRuleTypeOptions = (t: TFunction) => Object.keys(RuleType).filter((key) => ![RuleType.MCC_CHECK,
    RuleType.COUNTRY_CHECK].includes(key as RuleType)).map((key) => ({
    value: RuleType[key],
    text: t(`limits:ruleTypes.${RuleType[key]}`),
}));

const createRuleScopeOptions = (t: TFunction, isSuperAdmin: boolean) => Object.keys(RuleScope)
    .map((key) => ({ value: RuleScope[key], label: t(`limits:ruleScopes.${RuleScope[key]}`) }))
    .filter((option) => isSuperAdmin || option.value !== RuleScope.GLOBAL);

export const createAdditionalFilterConfig = ({
    t,
    accessControl,
    isSuperAdmin,

    enhancedCurrentLocation,
    queryParams,
    groupsListData,

    fetchData,
    dispatchClearGroupsList,
}:{
  t:TFunction,
  accessControl: AccessControlContent,
  isSuperAdmin: AccessControlContent['isSuperAdmin'],

  enhancedCurrentLocation: any,
  queryParams: any,
  groupsListData: any,

  fetchData: (newQueryParams: any) => void,
  dispatchClearGroupsList: (pathname: string) => void,

}) => {
    const createFetchDataPayload = (newQueryParams, clearOffset = true) => ({
        ...queryParams,
        ...(clearOffset ? { offset: 0 } : {}),
        ...newQueryParams,
    });
    return [
        { type: StandardFilterTypes.SEARCH, placeholder: t('limits:limitsList.filters.search.placeholder') },
        {
            type: StandardFilterTypes.DROPDOWN,
            allowClear: true,
            options: createRuleScopeOptions(t, isSuperAdmin),
            value: queryParams?.ruleScope,
            placeholder: t('limits:limitsList.filters.ruleScope.placeholder'),
            onChange: (value) => fetchData(
                createFetchDataPayload({
                    ruleScope: value,
                    ...(isSuperAdmin ? { clientExtId: undefined } : {}),
                    groupExtId: undefined,
                }),
            ),
        },
        ...(
            isSuperAdmin
&& (queryParams?.ruleScope === RuleScope.INSTITUTION
        || queryParams?.ruleScope === RuleScope.GROUP)
                ? [{
                    type: StandardFilterTypes.CLIENT_SELECT,
                    value: queryParams?.clientExtId,
                    placeholder: queryParams?.ruleScope === RuleScope.GROUP
                        ? t('limits:limitsList.filters.clientSelectForGroup.placeholder')
                        : t('limits:limitsList.filters.clientSelect.placeholder'),
                    allowClear: true,
                    onChange: (value) => {
                        fetchData({
                            clientExtId: value,
                            groupExtId: undefined,
                        });
                        dispatchClearGroupsList(enhancedCurrentLocation?.pathname);
                    },
                    handleClientSelectWithUuid: true,
                }] : []),
        ...(
            accessControl.hasPermission(PN.GROUPS.READ_LIST)
      && queryParams?.ruleScope === RuleScope.GROUP
      && queryParams?.clientExtId
                ? [
                    {
                        type: StandardFilterTypes.DROPDOWN,
                        allowClear: true,
                        placeholder: t('limits:limitsList.filters.group.placeholder'),
                        value: queryParams?.groupExtId,
                        loading: groupsListData?.isLoadingList,
                        options: groupsListData?.isLoadingList ? [] : groupsListData?.items?.map((group) => ({ value: group?.ext_group_id, label: group?.name })),
                        onChange: (value) => fetchData(createFetchDataPayload({ groupExtId: value })),
                    },
                ] : []),
    ];
};
