import { addProduct } from 'redux/client-products/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { setModalProps } from 'redux/modal/actions';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddProductModal from './AddProductModal.layout';


const mapStateToProps = (state) => ({
    currenciesList: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAddProduct: (requestPayload, locationPathname) => dispatch(addProduct(requestPayload, locationPathname)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});


const decorate = compose(
    withTranslation('products'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddProductModal);

