import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearStakeholdersList, fetchStakeholdersList } from 'redux/business-stakeholders/actions';
import { businessStakeholdersListsPerLocationStoreKeyName, businessStakeholdersReducersName } from 'redux/business-stakeholders/reducer';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import StakeholdersList from './StakeholdersList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: businessStakeholdersReducersName,
        listsByLocationStoreKeyName: businessStakeholdersListsPerLocationStoreKeyName,
    }),

});

const mapDispatchToProps = (dispatch) => ({
    dispatchShowModal: (data) => dispatch(showModal(data)),
    dispatchHideModal: () => dispatch(hideModal()),
    dispatchSetModalProps: (data) => dispatch(setModalProps(data)),

    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),

    dispatchFetchStakeholdersList: (queryParams, locationPathname) => dispatch(fetchStakeholdersList(queryParams, locationPathname)),
    dispatchClearStakeholdersList: (locationPathname) => dispatch(clearStakeholdersList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: businessStakeholdersReducersName,
        fieldName: businessStakeholdersListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('stakeholders'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(StakeholdersList);
