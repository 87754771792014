import OverdueInvoiceWarning from 'components/layouts/AuthorisedLayout/OverdueInvoiceWarning/OverdueInvoiceWarning.layout';
import withAccessControl from 'hocs/withAccessControl';
import { navigationReducerName } from 'redux/navigation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const mapStateToProps = (state) => ({ enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation });
const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps),
);

export default decorate(OverdueInvoiceWarning);
