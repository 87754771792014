import { showSuccessToast } from 'redux/application/actions';
import { fetchPromotionsList } from 'redux/promotions/actions';
import { promotionsListsPerLocationStoreKeyName, promotionsReducerName } from 'redux/promotions/reducer';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    fetchPromotionDetailsSuccess, fetchPromotionDetailsFailure,
    removePromotionFailure, removePromotionSuccess,
    enablePromotionFailure, enablePromotionSuccess,
    disablePromotionFailure, disablePromotionSuccess,
} from './actions';
import { DISABLE_PROMOTION, ENABLE_PROMOTION, FETCH_PROMOTION_DETAILS, REMOVE_PROMOTION } from './actions.types';


export const onFetchPromotionDetails = (action$, state$, { promotions }) => action$.pipe(
    ofType(FETCH_PROMOTION_DETAILS),
    switchMap(({ payload }) => from(promotions.fetchPromotionDetails(payload?.queryParams?.promotionUuid)).pipe(
        switchMap((response) => of(fetchPromotionDetailsSuccess(response.data, payload.locationPathname))),
        catchError(() => of(fetchPromotionDetailsFailure(payload.locationPathname))),
    )),
);

export const onRemovePromotion = (action$, state$, { promotions, i18n }) => action$.pipe(
    ofType(REMOVE_PROMOTION),
    switchMap(({ payload }) => from(promotions.removePromotion(payload?.requestPayload?.promotionUuid)).pipe(
        switchMap(() => {
            const currentQueryParams = state$.value[promotionsReducerName][promotionsListsPerLocationStoreKeyName]?.[payload?.locationPathname]?.queryParams;
            return of(
                showSuccessToast(i18n.t('promotions:actionMessages.removePromotionSuccess')),
                fetchPromotionsList(currentQueryParams, payload.locationPathname),
                removePromotionSuccess(payload.locationPathname),
            );
        }),
        catchError(() => of(removePromotionFailure(payload.locationPathname))),
    )),
);


export const onEnablePromotion = (action$, state$, { promotions, i18n }) => action$.pipe(
    ofType(ENABLE_PROMOTION),
    switchMap(({ payload }) => from(promotions.enablePromotion(payload.promotionUuid)).pipe(
        switchMap(() => of(
            showSuccessToast(i18n.t('promotions:actionMessages.enablePromotionSuccess')),
            enablePromotionSuccess(payload.promotionUuid, payload.locationPathname),
        )),
        catchError(() => of(enablePromotionFailure(payload.locationPathname))),
    )),
);


export const onDisablePromotion = (action$, state$, { promotions, i18n }) => action$.pipe(
    ofType(DISABLE_PROMOTION),
    switchMap(({ payload }) => from(promotions.disablePromotion(payload.promotionUuid)).pipe(
        switchMap(() => of(
            showSuccessToast(i18n.t('promotions:actionMessages.disablePromotionSuccess')),
            disablePromotionSuccess(payload.promotionUuid, payload.locationPathname),
        )),
        catchError(() => of(disablePromotionFailure(payload.locationPathname))),
    )),
);

export default [
    onFetchPromotionDetails,
    onRemovePromotion,
    onEnablePromotion,
    onDisablePromotion,
];
