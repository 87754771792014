/* eslint-disable react/jsx-props-no-spreading */
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';

// eslint-disable-next-line react/prop-types,no-unused-vars
function RemoteSearch({ fetchOptions, debounceTimeout = 800, options, setOptions, ...props }) {
    const [fetching, setFetching] = useState(false);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;

            const fetchId = fetchRef.current;

            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, setOptions, debounceTimeout]);

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            showSearch
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            {...props}
        />
    );
}

export default RemoteSearch;
