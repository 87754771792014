// TODO refactor to use standard-multi-steps-form

export const INITIALISE_ADD_EDIT_BENEFICIARY_FORM = 'INITIALISE_ADD_EDIT_BENEFICIARY_FORM';
export const INITIALISE_ADD_EDIT_BENEFICIARY_FORM_SUCCESS = 'INITIALISE_ADD_EDIT_BENEFICIARY_FORM_SUCCESS';
export const GO_TO_PREVIOUS_ADD_EDIT_BENEFICIARY_FORM_STEP = 'GO_TO_PREVIOUS_ADD_EDIT_BENEFICIARY_FORM_STEP';
export const SUBMIT_ADD_EDIT_BENEFICIARY_FORM_STEP = 'SUBMIT_ADD_EDIT_BENEFICIARY_FORM_STEP';
export const SET_ADD_EDIT_BENEFICIARY_FORM_STEP = 'SET_ADD_EDIT_BENEFICIARY_FORM_STEP';
export const CLEAR_ADD_EDIT_BENEFICIARY_FORM = 'CLEAR_ADD_EDIT_BENEFICIARY_FORM';
export const SET_BENEFICIARY_IDENTIFICATION_CATEGORY = 'SET_BENEFICIARY_IDENTIFICATION_CATEGORY';

export const FETCH_BENEFICIARY_REQUIREMENTS = 'FETCH_BENEFICIARY_REQUIREMENTS';
export const FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS = 'FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS';
export const FETCH_BENEFICIARY_REQUIREMENTS_FAILURE = 'FETCH_BENEFICIARY_REQUIREMENTS_FAILURE';
export const CLEAR_BENEFICIARY_REQUIREMENTS = 'CLEAR_BENEFICIARY_REQUIREMENTS';

export const CREATE_BENEFICIARY = 'CREATE_BENEFICIARY';
export const CREATE_BENEFICIARY_SUCCESS = 'UPDATE_BENEFICIARY_SUCCESS';
export const CREATE_BENEFICIARY_FAILURE = 'UPDATE_BENEFICIARY_FAILURE';
