import PropTypes from 'prop-types';


export const ProductTypes = Object.freeze({
    Base: 'Base',
    Business: 'Business',
    Personal: 'Personal',
});

export const ProductProps = PropTypes.shape({
    account_type: PropTypes.oneOf(Object.keys(ProductTypes)).isRequired,
    banking_details_available: PropTypes.bool.isRequired,
    client_id: PropTypes.number.isRequired,
    currency_code: PropTypes.string.isRequired, // CAD
    description: PropTypes.string.isRequired, // "Wari CAD B Product"
    id: PropTypes.number.isRequired, // "Wari CAD B Product"
    name: PropTypes.string.isRequired, // "WARIUK|B-CAD"
});
export const ProductListProp = PropTypes.arrayOf(ProductProps);

export default {
    ProductTypes,
    ProductProps,
    ProductListProp,
};
