import { CommonMimeTypes } from 'constants/MimeTypes';
import ModalName from 'constants/Modals';
import { CurrentUser } from 'models/api/auth';
import { Epic } from 'models/meta/epic';
import {
    BALANCE_ADJUSTMENT,
    DELETE_ACCOUNT, DOWNLOAD_ACCOUNT_STATEMENT_PDF,
    FETCH_ACCOUNT_DETAILS,
    OPEN_BALANCE_ADJUSTMENT_MODAL,
    OPEN_WITHDRAW_ACCOUNT_BALANCE_MODAL, SET_AS_PRIMARY_ACCOUNT,
    WITHDRAW_BALANCE,
} from 'redux/account/action.types';
import { createAccountsListsUpdatesActions } from 'redux/account/epics.helpers';
import { accountDetailsPerLocationStoreKeyName, accountReducerName } from 'redux/account/reducer';
import { fetchAccountsList } from 'redux/accounts/actions';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'redux/accounts/reducer';
import { saveFile, showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';
import { requestNavigationBack } from 'redux/navigation/actions';
import { formatDate } from 'utils/date-time-tools';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    fetchAccountById,
    fetchAccountByIdFailure,
    fetchAccountByIdSuccess,
    deleteAccountSuccess,
    deleteAccountFailure,

    withdrawBalanceFailure,
    withdrawBalanceSuccess,
    downloadAccountStatementPdfSuccess,
    downloadAccountStatementPdfFailure,
    hideDownloadPdfStatementForm, setAsPrimaryAccountSuccess, setAsPrimaryAccountFailure,
    balanceAdjustmentSuccess,
    balanceAdjustmentFailure,
} from './actions';


export const onFetchAccountById: Epic = (action$, state$, { accounts }) => action$.pipe(
    ofType(FETCH_ACCOUNT_DETAILS),
    switchMap(({ payload }) => from(accounts.getAccountDetails(payload?.queryParams?.accountId))
        .pipe(
            switchMap((response) => of(fetchAccountByIdSuccess(response.data, payload.locationPathname))),
            catchError(() => of(fetchAccountByIdFailure(payload.locationPathname))),
        )),
);


export const onDeleteAccount: Epic = (action$, state$, { accounts, i18n }) => action$.pipe(
    ofType(DELETE_ACCOUNT),
    switchMap(({ payload }) => from(accounts.deleteAccount(payload.accountDetails.id))
        .pipe(
            switchMap((response) => {
                const accountsListUpdatesActions = createAccountsListsUpdatesActions({ accountDetails: payload.accountDetails, state$ });

                return of(
                    ...accountsListUpdatesActions,
                    deleteAccountSuccess(response),
                    showSuccessToast(i18n.t('accounts:actionMessages.deleteAccountSuccess')),
                    requestNavigationBack(),
                );
            }),
            catchError(() => of(deleteAccountFailure())),
        )),
);

export const onOpenWithdrawAccountBalanceModal: Epic = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_WITHDRAW_ACCOUNT_BALANCE_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.WITHDRAW_ACCOUNT_BALANCE_MODAL,
            modalProps: {
                title: i18n.t('accounts:modals.withdrawAccountBalance.title', { accountId: payload.accountData?.id }),
                initialValues: payload?.accountData,
            },
        }),
    )),
);

export const onWithdrawBalance: Epic = (action$, state$, { transactions, i18n }) => action$.pipe(
    ofType(WITHDRAW_BALANCE),
    switchMap(({ payload }) => from(transactions.withdrawBalance(payload.requestPayload)).pipe(
        switchMap((response) => {
            const pathname = payload.locationPathname;
            const currentAccountListQueryParams = state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

            return of(
                withdrawBalanceSuccess(response.data),
                showSuccessToast(i18n.t('accounts:actionMessages.withdrawAccountBalanceSuccess')),
                hideModal(),
                fetchAccountsList({ ...currentAccountListQueryParams }, pathname),
            );
        }),
        catchError(() => of(
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
            withdrawBalanceFailure(),
        )),
    )),
);


export const onOpenBalanceAdjustmentModal: Epic = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_BALANCE_ADJUSTMENT_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.BALANCE_ADJUSTMENT_MODAL,
            modalProps: {
                title: i18n.t('accounts:modals.balanceAdjustment.title', { accountId: payload.accountData?.id }),
                initialValues: payload?.accountData,
            },
        }),
    )),
);

export const onBalanceAdjustment: Epic = (action$, state$, { transactions, i18n }) => action$.pipe(
    ofType(BALANCE_ADJUSTMENT),
    switchMap(({ payload }) => from(transactions.balanceAdjustment(payload.requestPayload)).pipe(
        switchMap((response) => {
            const pathname = payload.locationPathname;
            const currentAccountListQueryParams = state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

            return of(
                balanceAdjustmentSuccess(response.data),
                showSuccessToast(i18n.t('accounts:actionMessages.balanceAdjustmentSuccess')),
                fetchAccountsList({ ...currentAccountListQueryParams }, pathname),
                hideModal(),
            );
        }),
        catchError(() => of(
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
            balanceAdjustmentFailure(),
        )),
    )),
);


export const onDownloadAccountStatementPdf: Epic = (action$, state$, { reports }) => action$.pipe(
    ofType(DOWNLOAD_ACCOUNT_STATEMENT_PDF),
    switchMap(({ payload }) => from(reports.downloadTransactionStatement(payload))
        .pipe(
            switchMap((response) => {
                const { userPreferences: { dateFormat } } = state$.value.currentUser.userData as CurrentUser;
                const formattedDateFrom = formatDate({
                    date: payload.dateFrom,
                    dateFormat,
                    convertFromUTC: false,
                })?.replace(/\D/g, '_');

                const formattedDateTo = formatDate({
                    date: payload.dateTo,
                    dateFormat,
                    convertFromUTC: false,
                })?.replace(/\D/g, '_');

                return of(
                    saveFile({
                        blob: response,
                        fileName: `Statement_${payload.currency}_account_#${payload.accountId}_${formattedDateFrom}-${formattedDateTo}`,
                        mimeType: CommonMimeTypes.PDF,
                    }),
                    downloadAccountStatementPdfSuccess(payload.accountId),
                    hideDownloadPdfStatementForm(payload.accountId),
                );
            }),
            catchError(() => of(downloadAccountStatementPdfFailure(payload.accountId))),
        )),
);

export const onSetAsPrimaryAccount: Epic = (action$, state$, { accounts, i18n }) => action$.pipe(
    ofType(SET_AS_PRIMARY_ACCOUNT),
    switchMap(({ payload }) => from(accounts.updateExistingAccount({ ...payload.accountDetails, isPrimary: true }))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentAccountDetailsQueryParams = state$.value[accountReducerName][accountDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                const accountsListUpdatesActions = createAccountsListsUpdatesActions({ accountDetails: payload.accountDetails, state$ });

                return of(
                    ...accountsListUpdatesActions,
                    setAsPrimaryAccountSuccess(response),
                    showSuccessToast(i18n.t('accounts:actionMessages.setAccountAsPrimarySuccess')),
                    fetchAccountById(currentAccountDetailsQueryParams, pathname),
                );
            }),
            catchError(() => of(
                setAsPrimaryAccountFailure(),
            )),
        )),
);
export default [
    onFetchAccountById,
    onDeleteAccount,
    onOpenWithdrawAccountBalanceModal,
    onWithdrawBalance,
    onOpenBalanceAdjustmentModal,
    onBalanceAdjustment,
    onDownloadAccountStatementPdf,
    onSetAsPrimaryAccount,
];

