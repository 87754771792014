import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { clientRootRoutePath } from 'config/routes';

import React, { useMemo } from 'react';


import { ClientRendererProps } from './ClientRenderer.types';


function ClientRenderer({
    // Own Props
    clientId,
    clientExtId,
    useLink = true,

    // Store Props
    clients,
}:ClientRendererProps) {
    const client = useMemo(() => {
        if (clientId || clientExtId) {
            return clients.find((client) => (clientId ? client.id === clientId : client.ext_client_id === clientExtId));
        }

        return undefined;
    }, [clientId, clientExtId, clients]);

    const content = useMemo(() => {
        if (client) {
            const label = client?.company_dba_name ? `${client?.id} - ${client.company_dba_name} ${client.company_business_type}` : client?.id;

            return label;
        }

        return undefined;
    }, [client]);

    return (
        <>
            {useLink && (<StandardLink title={content} path={`${clientRootRoutePath}/${client?.id}`} />)}

            {!useLink && <StandardValue value={content} />}
        </>
    );
}

export default ClientRenderer;
