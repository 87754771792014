import { fetchAccountById } from 'redux/account/actions';
import { accountDetailsPerLocationStoreKeyName, accountReducerName } from 'redux/account/reducer';
import { deleteCard } from 'redux/card/actions';
import { cardReducerName } from 'redux/card/reducer';
import { cardTiersListsPerLocationStoreKeyName, cardTiersReducerName } from 'redux/card-tiers/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CardInfo from './CardInfo.layout';


const mapStateToProps = (state, ownProps) => ({
    accountDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: accountReducerName,
        fieldName: accountDetailsPerLocationStoreKeyName,
        customLocation: ownProps?.baseUrlWithoutTabsSlugs,
    })?.entityDetails,

    isLoadingDeleteCard: state[cardReducerName].isLoadingDeleteCard,
    queryParamsMeta: state[cardReducerName].cardDetailsQueryParamsMeta,
    cardTiers: getStandardListData({
        state,
        reducerName: cardTiersReducerName,
        listsByLocationStoreKeyName: cardTiersListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchDeleteCard: (cardId) => dispatch(deleteCard(cardId)),
    dispatchFetchAccountById: (queryParams) => dispatch(fetchAccountById(queryParams, ownProps.baseUrlWithoutTabsSlugs)),
});

const decorate = compose(
    withTranslation('cards'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CardInfo);
