import DefaultRedirection from 'components/layouts/DefaultRedirection';
import { publicRoutes } from 'config/routes';
import { RedirectionToDefaultPathTypes } from 'constants/NavigationModel';

import React from 'react';
import { Route, Routes } from 'react-router-dom';


export function hasAccessRights({
    routeConfiguration,
    accessControl,
}) {
    const isPublic = publicRoutes.includes(routeConfiguration.path);

    const handlers = [
    // route-config-driven access control checks, if predicate is valid then redirection
        {
            predicate: () => isPublic === true && accessControl.isAuthorised,
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.isAuthorisedOnly === true && !accessControl.isAuthorised,
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.isSuperAdminOnly === true && !accessControl.isSuperAdmin, // XXX allowed - route access check
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.hasPermission && !accessControl.hasPermission(routeConfiguration.hasPermission),
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.hasPermissions && !accessControl.hasPermissions(routeConfiguration.hasPermissions),
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.hasOneOfPermissions && !accessControl.hasOneOfPermissions(routeConfiguration.hasOneOfPermissions),
            handler: () => false,
        },
        {
            // default happy-path scenario, render component
            predicate: () => true,
            handler: () => true,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export function renderApplicationContent({
    Layout,
    routesConfiguration,
    defaultRedirectionPath,
}) {
    return (
        <Layout>
            <Routes>
                {routesConfiguration}

                <Route
                    path="*"
                    key="not-found"
                    element={(
                        <DefaultRedirection
                            defaultRedirectionPath={defaultRedirectionPath}
                            navigationState={{
                                replace: true,
                                type: RedirectionToDefaultPathTypes.ROUTER_REDIRECTION_TO_DEFAULT_PATH,
                                trigger: 'not_found_redirection_to_default_path',
                                defaultRedirectionPath,
                            }}
                        />
                    )}
                />
            </Routes>
        </Layout>
    );
}

export function renderRouteElement({ routeConfiguration }) {
    const Component = routeConfiguration.component;

    return (
        <Route
            path={routeConfiguration.path}
            key={routeConfiguration.path}
            element={routeConfiguration?.isIndex ? undefined : <Component />}
        >
            {routeConfiguration?.isIndex && (
                <Route
                    index
                    key={`${routeConfiguration.path}-index`}
                    element={<Component />}
                />
            )}

            {routeConfiguration?.childrenRoutes?.length > 0
        && routeConfiguration?.childrenRoutes.map((routeConfiguration) => renderRouteElement({ routeConfiguration }))}
        </Route>
    );
}

export function renderRoutes({ routesConfiguration }) {
    return routesConfiguration.map((routeConfiguration) => renderRouteElement({ routeConfiguration }));
}

export default {
    hasAccessRights,
    renderApplicationContent,
    renderRouteElement,
    renderRoutes,
};
