import { createCommonAddressFieldsConfig } from 'components/common/organisms/addressFields.helpers';
import { AddClientAddressesAvailableStepsDefinitions, AddClientStepsDefinitions } from 'constants/AddClientModel';
import { AddressTypes } from 'constants/AddressModel';
import { MultiStepFormStepSectionFieldsLayout, MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';

import { GlobalOutlined } from '@ant-design/icons';
import React from 'react';


import AddressSameAsOtherAddress from '../steps/Addressess/AddressSameAsOtherAddress';


export function createClientAddressSubSectionFieldsConfig({
    t,
    fieldsNamePrefix,
    isDisabled,
}) {
    return [
        ...createCommonAddressFieldsConfig({
            isDisabled,
            fieldsNamePrefix,
            t,
            useStateField: true,
            usePhoneField: true,
        }),
    ];
}


export function createClientAddressSubStepsConfig({
    shippingAddressSameAsCompanyAddress,
    billingAddressSameAsCompanyAddress,
    t,
}) {
    return {
        stepName: AddClientStepsDefinitions.ADDRESSES,
        title: t('addClient.steps.addresses'),
        icon: <GlobalOutlined />,
        subStepsConfiguration: [
            {
                parentStepName: AddClientStepsDefinitions.ADDRESSES,
                subStepName: AddClientAddressesAvailableStepsDefinitions.COMPANY_ADDRESS,
                title: t('Company address'),
                sectionsLayout: MultiStepFormStepSectionTypes.AUTO_ROWS,
                stepSections: [
                    {
                        fieldsLayout: MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL,
                        title: t('Address data'),
                        fieldsConfiguration: createClientAddressSubSectionFieldsConfig({
                            t,
                            fieldsNamePrefix: AddressTypes.CLIENT_ADDRESS,
                        }),
                    },
                ],
            },
            {
                parentStepName: AddClientStepsDefinitions.ADDRESSES,
                subStepName: AddClientAddressesAvailableStepsDefinitions.SHIPPING_ADDRESS,
                title: t('Shipping address'),
                sectionsLayout: MultiStepFormStepSectionTypes.AUTO_ROWS,
                stepSections: [
                    {
                        title: t('Copy address'),
                        children: (
                            <AddressSameAsOtherAddress
                                storeFieldName="shippingAddressSameAsCompanyAddress"
                                label={'Shipping address the same as company address'}
                            />
                        ),
                    },
                    {
                        fieldsLayout: MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL,
                        title: t('Address data'),
                        fieldsConfiguration: createClientAddressSubSectionFieldsConfig({
                            t,
                            fieldsNamePrefix: AddressTypes.SHIPPING_ADDRESS,
                            isDisabled: !!shippingAddressSameAsCompanyAddress,
                        }),
                    },
                ],
            },
            {
                parentStepName: AddClientStepsDefinitions.ADDRESSES,
                subStepName: AddClientAddressesAvailableStepsDefinitions.BILLING_ADDRESS,
                title: t('Billing address'),
                sectionsLayout: MultiStepFormStepSectionTypes.AUTO_ROWS,
                stepSections: [
                    {
                        title: t('Copy address'),
                        children: (
                            <AddressSameAsOtherAddress
                                storeFieldName="billingAddressSameAsCompanyAddress"
                                label={'Billing address the same as company address'}
                            />
                        ),
                    },
                    {
                        fieldsLayout: MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL,
                        title: t('Address data'),
                        fieldsConfiguration: createClientAddressSubSectionFieldsConfig({
                            t,
                            fieldsNamePrefix: AddressTypes.BILLING_ADDRESS,
                            isDisabled: !!billingAddressSameAsCompanyAddress,
                        }),
                    },
                ],
            },
        ],
    };
}

export default { createClientAddressSubSectionFieldsConfig };
