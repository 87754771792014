import StandardTooltip from 'components/common/molecules/StandardTooltip';
import TextWithHighlightedSearch from 'components/common/molecules/value-renderers/TextWithHighlightedSearch';

import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from './PermissionFormRecord.styled';


function PermissionFormRecord({
    searchQuery,
    permission,
    dispatchRemovePermissionsFromRole,
    t,
}) {
    const handleOnRemove = () => {
        dispatchRemovePermissionsFromRole([permission]);
    };

    return (
        <Styled.PermissionItemWrapper>
            <Button
                size="small"
                icon={<DeleteOutlined />}
                danger
                onClick={handleOnRemove}
            >
                {t('Remove')}
            </Button>

            <Styled.PermissionName>
                <TextWithHighlightedSearch filterValue={searchQuery} rawValue={permission.name} />
                {permission.description?.length > 0 && (
                    <StandardTooltip title={permission.description}>
                        <InfoCircleOutlined />
                    </StandardTooltip>
                )}
            </Styled.PermissionName>
        </Styled.PermissionItemWrapper>
    );
}

PermissionFormRecord.propTypes = {
    searchQuery: PropTypes.string,
    permission: PropTypes.shape({}),
    dispatchRemovePermissionsFromRole: PropTypes.func,
    t: PropTypes.func,
};

export default PermissionFormRecord;
