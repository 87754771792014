import ClientSelect from 'components/common/molecules/form-controls/ClientSelect';
import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import { defaultLocale, isoDateFormat, standardDateFormat, superAdminRoleTemplateName, WL_CONFIG } from 'config/config';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { ApplicationsListProp } from 'constants/ApplicationsModel';
import { CountriesListProps } from 'constants/CountriesModel';
import { RoleProps } from 'constants/RolesModel';
import { mapSeparatorsToNumbersFormat, UserPreferencesDecimalSeparators, UserPreferencesThousandsSeparators } from 'constants/UserModel';
import { findProperApplicationByScope } from 'redux/applications/helpers';
import calcIs18YearsOld from 'utils/calcIs18YearsOld';
import { filterOptions } from 'utils/filterOptions';
import {
    createCountriesOptions,
    createUserPreferencesDateFormatOptions, createUserPreferencesNumbersFormatOptions,
    createUserPreferencesTimeFormatOptions, createRolesOptions, createUserStatusesOptions,
} from 'utils/options-creators';
import { commonValidation, isValidFirstNameRule, isValidLastNameRule, isValidBrandDomainEmail } from 'utils/validation-tools';

import { DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import Styled from './AddEditUserModal.styled';


function AddEditUserModal({
    enhancedCurrentLocation,
    currentUserClientId,
    currentUserId,

    rolesListData,
    applicationsListData,

    accessControl,
    countries,
    dispatchFetchRolesList,
    dispatchSetModalProps,
    dispatchFetchApplicationsList,
    dispatchEditUser,
    dispatchAddUser,
    config,
    userPreferences,
    t,
}) {
    const [form] = Form.useForm();

    const [selectedRoleIsSuperAdmin, setSelectedRoleIsSuperAdmin] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(config?.initialValues?.client_id);

    const { initialValues } = config;

    const applicationForSelectedClientId = useMemo(
        () => findProperApplicationByScope(applicationsListData?.items),
        [applicationsListData, selectedClientId],
    );

    const countriesOptions = useMemo(
        () => createCountriesOptions(countries, 'id'),
        [countries],
    );

    const isEditMode = useMemo(
        () => !!initialValues?.id,
        [config?.initialValues],
    );

    const isCurrentUserSelfEditMode = useMemo(
        () => isEditMode && initialValues?.id === currentUserId,
        [isEditMode],
    );


    useEffect(() => {
    // EDIT user use case
        if (!isCurrentUserSelfEditMode
      && selectedClientId > 0
      && !applicationsListData?.items
        ) {
            dispatchFetchApplicationsList({ clientId: selectedClientId }, enhancedCurrentLocation.pathname);
        }
    }, []);


    useEffect(() => {
        if (applicationForSelectedClientId?.uuid) {
            dispatchFetchRolesList(
                {
                    clientId: selectedClientId,
                    applicationUuid: applicationForSelectedClientId?.uuid,
                },
                enhancedCurrentLocation.pathname,
            );
        }
    }, [applicationForSelectedClientId]);

    useEffect(() => {
    // ADD new user by WL admin use case
        if (!isEditMode
      && !isCurrentUserSelfEditMode
      && !accessControl.isSuperAdmin // XXX allowed - client select in add/edit BOP user
        ) {
            setSelectedClientId(currentUserClientId);

            if (!applicationsListData?.items) {
                dispatchFetchApplicationsList({ clientId: currentUserClientId, limit: 100 }, enhancedCurrentLocation.pathname);
            }
        }
    }, [isEditMode, isCurrentUserSelfEditMode]);


    const createMomentBirthday = () => {
        if (initialValues?.birthday && initialValues?.birthday !== 'Invalid date') {
            return dayjs(initialValues?.birthday);
        }
        return dayjs().subtract(18, 'years');
    };

    const rolesOptions = useMemo(
        () => createRolesOptions(rolesListData?.items || []),
        [rolesListData],
    );

    const format = useMemo(
        () => userPreferences?.dateFormat || standardDateFormat,
        [userPreferences],
    );

    const emailValidationRules = useMemo(
        () => (selectedRoleIsSuperAdmin && [...isValidBrandDomainEmail({
            t,
            brandBaseDomain: WL_CONFIG.brandBaseDomain,
        })]),
        [selectedRoleIsSuperAdmin],
    );

    const userStatusesOptions = useMemo(
        () => createUserStatusesOptions(),
        [],
    );

    const onPartnerChange = (value) => {
        setSelectedClientId(value);
        dispatchFetchApplicationsList({ clientId: value }, enhancedCurrentLocation.pathname);
        form.setFieldsValue({ role_id: undefined });
    };

    // Find the role in the roles list the compare is it Admin or not
    const onRoleChange = (value) => {
        const populatedRole = rolesListData?.items?.find(
            (role) => role.id === value,
        );
        setSelectedRoleIsSuperAdmin(populatedRole.name === superAdminRoleTemplateName);
    };

    const onFinish = (values) => {
        const { birthday, date_format, time_format, numbers_format, language, ...rest } = values;
        const [thousandsSeparator, decimalSeparator] = numbers_format.split('__');
        const requestPayload = {
            ...rest,
            birthday: dayjs(birthday).format(isoDateFormat),
            user_preferences: {
                date_format,
                time_format,
                thousands_separator: UserPreferencesThousandsSeparators[thousandsSeparator],
                decimal_separator: UserPreferencesDecimalSeparators[decimalSeparator],
                language: defaultLocale,
            },
        };

        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        if (isEditMode) {
            dispatchEditUser({ ...requestPayload, userId: config?.initialValues?.id }, enhancedCurrentLocation.pathname, isCurrentUserSelfEditMode);
        } else {
            const finalRequestPayload = {
                ...requestPayload,
                ...(!accessControl.isSuperAdmin ? { clientId: currentUserClientId } : {}), // XXX allowed - no partner dropdown for WL admins
            };

            dispatchAddUser(finalRequestPayload);
        }
    };
    const formInitialValues = useMemo(() => (initialValues?.id ? {
        ...initialValues,
        birthday: createMomentBirthday(),
        date_format: initialValues?.user_preferences?.date_format,
        time_format: initialValues?.user_preferences?.time_format,
        numbers_format: mapSeparatorsToNumbersFormat(initialValues?.user_preferences),
    } : undefined), [initialValues]);

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);


    return (

        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={formInitialValues}
            onFinish={onFinish}
        >
            {accessControl.isSuperAdmin && !isEditMode && !isCurrentUserSelfEditMode && ( // XXX allowed - client select in add edit BOP user
                <ClientSelect
                    onChange={onPartnerChange}
                    initialValue={config?.initialValues?.client_id}
                    isRequired
                    label={t('userFields.partner.label')}
                    placeholder={t('userFields.partner.placeholder')}
                    name="client_id"
                    isRequiredMessage={t('common:validationMsg.fieldMandatory')}
                />
            )}

            {isEditMode && !isCurrentUserSelfEditMode && (
                <Styled.TwoColumns>
                    <Form.Item
                        name="status"
                        label={t('userFields.status.label')}
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Select placeholder={t('userFields.status.placeholder')}>
                            {userStatusesOptions}
                        </Select>
                    </Form.Item>
                </Styled.TwoColumns>
            )}

            <Styled.TwoColumns>
                <div>
                    {!isCurrentUserSelfEditMode && (
                        <Form.Item
                            name="role_id"
                            label={t('userFields.role.label')}
                            rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                        >
                            <Select
                                loading={rolesListData?.isLoadingList || applicationsListData?.isLoadingList}
                                disabled={!selectedClientId}
                                placeholder={t(`userFields.role.${selectedClientId ? 'placeholder' : 'placeholderDisabled'}`)}
                                onChange={onRoleChange}
                            >
                                {rolesOptions}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item
                        name="first_name"
                        label={t('userFields.firstName.label')}
                        rules={[
                            ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                            ...isValidFirstNameRule(t),
                        ]}
                    >
                        <Input placeholder={t('userFields.firstName.placeholder')} />
                    </Form.Item>

                    <EmailInput
                        t={t}
                        placeholder={t('userFields.email.placeholder')}
                        isRequired
                        label={t('userFields.email.label')}
                        additionalValidationRules={emailValidationRules}
                    />

                    <Form.Item
                        name="birthday"
                        label={t('userFields.birthday.label')}
                        rules={[
                            { type: 'object', required: true, message: t('common:validationMsg.fieldMandatory') },
                            {
                                validator: async (_, value) => {
                                    if (!calcIs18YearsOld(value)) throw new Error(t('userFields.birthday.validationMin18Yrs'));
                                },
                            },
                        ]}
                    >
                        <DatePicker
                            format={format}
                            style={{ width: '100%' }}
                            disabledDate={(today) => !calcIs18YearsOld(today)}
                            defaultPickerValue={createMomentBirthday()}
                        />
                    </Form.Item>
                </div>


                <div>
                    <Form.Item
                        name="username"
                        label={t('userFields.username.label')}
                        rules={[{ required: true, whitespace: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Input placeholder={t('userFields.username.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        name="last_name"
                        label={t('userFields.lastName.label')}
                        rules={[
                            ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                            ...isValidLastNameRule(t),
                        ]}
                    >
                        <Input placeholder={t('userFields.lastName.placeholder')} />
                    </Form.Item>

                    <PhoneInput name="phone" t={t} />

                    <Form.Item
                        name="country_id"
                        label={t('userFields.country.label')}
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Select
                            showSearch
                            placeholder={t('userFields.country.placeholder')}
                            filterOption={filterOptions}
                        >
                            {countriesOptions}
                        </Select>
                    </Form.Item>
                </div>
            </Styled.TwoColumns>

            <Styled.TwoColumns>
                <div>
                    <Form.Item
                        name="date_format"
                        label={t('userFields.userPreferences.dateFormat.label')}
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Select placeholder={t('userFields.userPreferences.dateFormat.placeholder')}>
                            {createUserPreferencesDateFormatOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="numbers_format"
                        label={t('userFields.userPreferences.numbersFormat.label')}
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Select placeholder={t('userFields.userPreferences.numbersFormat.placeholder')}>
                            {createUserPreferencesNumbersFormatOptions()}
                        </Select>
                    </Form.Item>
                </div>

                <div>
                    <Form.Item
                        name="time_format"
                        label={t('userFields.userPreferences.timeFormat.label')}
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Select placeholder={t('userFields.userPreferences.timeFormat.placeholder')}>
                            {createUserPreferencesTimeFormatOptions()}
                        </Select>
                    </Form.Item>
                </div>
            </Styled.TwoColumns>
        </Form>

    );
}


AddEditUserModal.propTypes = {
    applicationsListData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: ApplicationsListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),

    config: PropTypes.shape({
        initialValues: PropTypes.shape({
            id: PropTypes.number,
            clientId: PropTypes.number,
        }),
    }),
    currentUserClientId: PropTypes.number,
    currentUserId: PropTypes.number,
    countries: CountriesListProps,
    form: PropTypes.shape({ getFieldValue: PropTypes.func.isRequired }).isRequired,
    rolesListData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(RoleProps),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    accessControl: AccessControlProps,
    dispatchFetchRolesList: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchEditUser: PropTypes.func.isRequired,
    dispatchAddUser: PropTypes.func.isRequired,
    dispatchFetchApplicationsList: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default AddEditUserModal;
