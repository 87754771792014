import RiskAlert from 'components/common/molecules/RiskAlert';
import RiskAssessmentList from 'components/common/templates/lists/RiskAssesmentList';
import { KycKybEntityTypes } from 'constants/KycKybModel';

import { Alert } from 'antd';
import React from 'react';


import { MemberRiskDetailsProps } from './MemberRiskDetails.types';
import Styled from '../RiskDetailsSubTabCommon.styled';


function MemberRiskDetails({
    entityData,
    t,
}: MemberRiskDetailsProps) {
    return (

        <Styled.RiskDetailsWrapper>
            <div>
                <h2>{t('member:memberDetails.tabs.compliance.subHeadings.finalRisk')}</h2>
                <RiskAlert
                    riskAlertConfigs={[
                        { label: 'finalScore', value: entityData?.memberTotalRiskScore },
                        { label: 'finalRating', value: entityData?.memberRiskRating },
                    ]}
                    riskRating={entityData?.memberRiskRating}
                />
            </div>

            <div>
                <h2 style={{ marginTop: '8px' }}>{t('member:memberDetails.tabs.compliance.subHeadings.riskAssessments')}</h2>
                <Alert
                    description={t('member:memberDetails.tabs.compliance.infoTexts.noAssessment')}
                    type="warning"
                    banner
                />
                <RiskAssessmentList
                    entityType={KycKybEntityTypes.MEMBER}
                    contextEnforcedQueryParams={
                        { ext_member_id: entityData?.memberUuid }
                    }
                />
            </div>
        </Styled.RiskDetailsWrapper>

    );
}


export default MemberRiskDetails;
