import { createClientDetailsGroupsListPath } from 'config/routes.helpers';
import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import { getProperGetGroupsEndpoint } from 'redux/groups/epic.helpers';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    createNewGroupFailure, createNewGroupSuccess,
    fetchGroupsList, fetchGroupsListFailure, fetchGroupsListSuccess,
} from './actions';
import { CREATE_NEW_GROUP, FETCH_GROUPS_LIST, OPEN_CREATE_NEW_GROUP_MODAL } from './actions.types';


export const onFetchGroupsList = (action$, state$, { groups, fees, promotions }) => action$.pipe(
    ofType(FETCH_GROUPS_LIST),
    switchMap(({ payload }) => from(getProperGetGroupsEndpoint({ groups, fees, promotions, payload })).pipe(
        switchMap((response) => of(fetchGroupsListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchGroupsListFailure(payload.locationPathname))),
    )),
);


export const onOpenCreateNewGroupModal = (action$, _, { i18n }) => action$.pipe(
    ofType(OPEN_CREATE_NEW_GROUP_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.CREATE_NEW_GROUP,
            modalProps: {
                title: i18n.t('groups:modals.createEditGroup.titleCreate'),
                initialValues: {
                    clientId: payload?.clientId,
                    locationPathname: payload?.locationPathname,
                },
            },
        }),
    )),
);


export const onCreateNewGroup = (action$, state$, { groups, i18n }) => action$.pipe(
    ofType(CREATE_NEW_GROUP),
    switchMap(({ payload }) => from(groups.createGroup(payload.clientId, payload.groupName)).pipe(
        switchMap(() => {
            const clientGroupsLocation = createClientDetailsGroupsListPath(payload.clientId);
            const currentQueryParams = state$.value[groupsReducerName][groupsListsPerLocationStoreKeyName]?.[clientGroupsLocation]?.queryParams;

            return of(
                createNewGroupSuccess(payload.locationPathname),
                fetchGroupsList(currentQueryParams, clientGroupsLocation),
                showSuccessToast(i18n.t('groups:actionMessages.createNewGroupSuccess')),
                hideModal(),
            );
        }),
        catchError(() => of(
            createNewGroupFailure(payload.locationPathname),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);


export default [
    onFetchGroupsList,
    onOpenCreateNewGroupModal,
    onCreateNewGroup,
];
