import { CountryIdType, CountryIdTypeProp } from 'constants/CountriesModel';
import { filterOptions } from 'utils/filterOptions';
import { createCountriesOptions } from 'utils/options-creators';
import { commonValidation, handleWhitespaceOnBlur } from 'utils/validation-tools';

import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function CommonAddressFrom({
    form,
    countries,
    countryIdType = CountryIdType.iso_31663,
    fieldNamePrefix,
    usePremiseField = false,
    useStateField = false,
    stateFieldName = 'state',

    // Remove this usage after the migration in all components
    isCamelCase = false,
    t,
}) {
    const countriesOptions = useMemo(
        () => createCountriesOptions(countries, countryIdType),
        [countries],
    );

    const fieldName = (rawName) => (fieldNamePrefix ? [`${fieldNamePrefix}`, `${rawName}`] : rawName);


    return (
        <>
            <Form.Item
                name={fieldName(isCamelCase ? 'addressLine1' : 'address_line_1')}
                label={t('addressFields.addressLine1.label')}
                rules={commonValidation({
                    t,
                    isRequired: true,
                })}
            >
                <Input
                    placeholder={t('addressFields.addressLine1.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, fieldName('address_line_1'), form)}
                />
            </Form.Item>

            <Form.Item
                name={fieldName(isCamelCase ? 'addressLine2' : 'address_line_2')}
                label={t('addressFields.addressLine2.label')}
                rules={commonValidation({ t })}
            >
                <Input
                    placeholder={t('addressFields.addressLine2.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, fieldName(isCamelCase ? 'addressLine2' : 'address_line_2'), form)}
                />
            </Form.Item>

            {usePremiseField && (
                <Form.Item
                    name={fieldName('premise')}
                    label={t('addressFields.premise.label')}
                    rules={commonValidation({ t })}
                >
                    <Input
                        onBlur={(e) => handleWhitespaceOnBlur(e, fieldName('premise'), form)}
                        placeholder={t('addressFields.premise.placeholder')}
                    />
                </Form.Item>
            )}

            <Form.Item
                name={fieldName('city')}
                label={t('common:addressFields.city.label')}
                rules={commonValidation({
                    t,
                    isRequired: true,
                })}
            >
                <Input
                    placeholder={t('addressFields.city.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, fieldName('city'), form)}
                />
            </Form.Item>

            <Form.Item
                name={fieldName(isCamelCase ? 'zipCode' : 'zip_code')}
                label={t('addressFields.postCode.label')}
                rules={commonValidation({
                    t,
                    isRequired: true,
                })}
            >
                <Input
                    onBlur={(e) => handleWhitespaceOnBlur(e, fieldName(isCamelCase ? 'zipCode' : 'zip_code'), form)}
                    placeholder={t('addressFields.postCode.placeholder')}
                />
            </Form.Item>

            <Form.Item
                name={fieldName('country')}
                label={t('addressFields.country.label')}
                rules={commonValidation({
                    t,
                    isRequired: true,
                    maxLength: 3,
                })}
            >
                <Select
                    showSearch
                    filterOption={filterOptions}
                    placeholder={t('addressFields.country.placeholder')}
                >
                    {countriesOptions}
                </Select>
            </Form.Item>


            {useStateField && (
                <Form.Item
                    name={fieldName(stateFieldName)}
                    label={t('addressFields.state.label')}
                    rules={commonValidation({ t })}
                >
                    <Input
                        onBlur={(e) => handleWhitespaceOnBlur(e, fieldName(stateFieldName), form)}
                        placeholder={t('addressFields.state.placeholder')}
                    />
                </Form.Item>
            )}
        </>
    );
}

CommonAddressFrom.propTypes = {
    form: PropTypes.shape({ getFieldValue: PropTypes.func.isRequired }).isRequired,
    countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    fieldNamePrefix: PropTypes.string,
    usePremiseField: PropTypes.bool,
    useStateField: PropTypes.bool,
    countryIdType: CountryIdTypeProp,
    stateFieldName: PropTypes.string,
    t: PropTypes.func.isRequired,
};


export default CommonAddressFrom;
