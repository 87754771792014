import { showSuccessToast } from 'redux/application/actions';
import { fetchCompanyDetails } from 'redux/company/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { fetchCompanyStatusHistoryListFailure, fetchCompanyStatusHistoryListSuccess, updateCompanyStatusFailure, updateCompanyStatusSuccess } from './actions';
import { FETCH_COMPANY_STATUS_HISTORY_LIST, UPDATE_COMPANY_STATUS } from './actions.types';


export const onFetchCompanyStatusHistory = (action$, state$, { companies }) => action$.pipe(
    ofType(FETCH_COMPANY_STATUS_HISTORY_LIST),
    switchMap(({ payload }) => from(companies.getCompanyStatusHistory({ ...payload.queryParams }))
        .pipe(
            switchMap((response) => of(fetchCompanyStatusHistoryListSuccess(
                response.data.rows,
                response?.data?.count,
                payload.locationPathname,
            ))),
            catchError(() => of(fetchCompanyStatusHistoryListFailure())),
        )),
);

export const onUpdateCompanyStatus = (action$, state$, { i18n, companies }) => action$.pipe(
    ofType(UPDATE_COMPANY_STATUS),
    switchMap(({ payload }) => from(companies.updateCompanyStatus(payload.companyId, payload.requestPayload))
        .pipe(
            switchMap(() => of(
                fetchCompanyDetails({ companyId: payload.companyId }, payload.locationPathname),
                updateCompanyStatusSuccess(),
                showSuccessToast(i18n.t('companies:actionMessages.updateCompanyStatusSuccess')),
            )),
            catchError(() => of(updateCompanyStatusFailure())),
        )),
);


export default [
    onFetchCompanyStatusHistory,
    onUpdateCompanyStatus,
];
