import { createListActions } from 'redux/standardLists.helpers';

import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_MEMBER_STATUS_HISTORY_LIST,
    FETCH_MEMBER_STATUS_HISTORY_LIST_FAILURE,
    FETCH_MEMBER_STATUS_HISTORY_LIST_SUCCESS,

    CLEAR_MEMBER_STATUS_HISTORY_LIST,

    UpdateMemberStatusPayload,
    SUSPEND_MEMBER,

    UNSUSPEND_MEMBER,
    BAN_MEMBER,
    UNBAN_MEMBER,
    ACTIVATE_MEMBER,
    EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS,
    UpdateExternalKycKybMemberStatusPayload,
    CLEAR_RESET_UPDATE_MEMBER_STATUS_FORM,
} from './actions.types';


const listActions = createListActions(
    {
        base: FETCH_MEMBER_STATUS_HISTORY_LIST,
        success: FETCH_MEMBER_STATUS_HISTORY_LIST_SUCCESS,
        failure: FETCH_MEMBER_STATUS_HISTORY_LIST_FAILURE,
        clear: CLEAR_MEMBER_STATUS_HISTORY_LIST,
    },
);

export const fetchMemberStatusHistoryList = listActions.fetchList;
export const fetchMemberStatusHistoryListSuccess = listActions.fetchListSuccess;
export const fetchMemberStatusHistoryListFailure = listActions.fetchListFailure;
export const clearMemberStatusHistoryList = listActions.clearList;

export const commonUpdateStatusFunction = ({
    queryParams,
    requestPayload,
    locationPathname,
}: UpdateMemberStatusPayload | UpdateExternalKycKybMemberStatusPayload) => ({ payload: { queryParams, requestPayload, locationPathname } });


export const suspendMember = createAction(SUSPEND_MEMBER, commonUpdateStatusFunction);
export const unsuspendMember = createAction(UNSUSPEND_MEMBER, commonUpdateStatusFunction);
export const banMember = createAction(BAN_MEMBER, commonUpdateStatusFunction);
export const unbanMember = createAction(UNBAN_MEMBER, commonUpdateStatusFunction);
export const activateMember = createAction(ACTIVATE_MEMBER, commonUpdateStatusFunction);
export const externalKycKybUpdateMemberStatus = createAction(EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS, commonUpdateStatusFunction);

export const clearResetUpdateMemberStatusForm = createAction(CLEAR_RESET_UPDATE_MEMBER_STATUS_FORM);
