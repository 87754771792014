import { createClientDetailsPath, createClientDetailsRiskScoreHistoryListPath } from 'config/routes.helpers';
import ModalName from 'constants/Modals';
import { globalLocation } from 'constants/NavigationModel';
import { showSuccessToast } from 'redux/application/actions';
import {
    ADD_CLIENT_RISK_DETAILS, FETCH_CLIENT_3DS_SETTINGS,
    FETCH_CLIENT_ADDRESSES,
    FETCH_CLIENT_DETAILS,
    OPEN_ADD_CLIENT_RISK_DETAILS_MODAL,
    OPEN_EDIT_CLIENT_PROFILE_MODAL, UPDATE_CLIENT_ANNOUNCEMENT,
    UPDATE_CLIENT_PROFILE, UPDATE_CLIENT_SETTINGS,
} from 'redux/client/actions.types';
import { fetchClientsList } from 'redux/clients/actions';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { setModalProps, showModal, hideModal } from 'redux/modal/actions';
import { requestNavigation, unblockNavigation } from 'redux/navigation/actions';
import { fetchRiskScoreHistoryList } from 'redux/risk-score-history/actions';
import { riskScoreHistoryListsPerLocationStoreKeyName, riskScoreHistoryReducerName } from 'redux/risk-score-history/reducer';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    fetchClient3DsSettingsSuccess,
    fetchClient3DsSettingsFailure,
    fetchClientAddressesSuccess,
    fetchClientAddressesFailure,
    updateClientProfileSuccess,
    updateClientProfileFailure,
    updateClientSettingsSuccess,
    updateClientSettingsFailure,
    updateClientAnnouncementSuccess,
    updateClientAnnouncementFailure,
    fetchClientDetailsSuccess,
    fetchClientDetailsFailure,
    addClientRiskDetailsSuccess,
    addClientRiskDetailsFailure,
    fetchClientDetails,
} from './actions';


export const onFetchClientDetails = (action$, state$, { clients }) => action$.pipe(
    ofType(FETCH_CLIENT_DETAILS),
    switchMap(({ payload }) => from(clients.getClientDetails(payload?.queryParams?.clientId))
        .pipe(
            switchMap((response) => of(fetchClientDetailsSuccess(response.data, payload.locationPathname))),
            catchError(() => of(fetchClientDetailsFailure(payload.locationPathname))),
        )),
);

export const onFetchClient3DsSettings = (action$, state$, { clients }) => action$.pipe(
    ofType(FETCH_CLIENT_3DS_SETTINGS),
    switchMap(({ payload }) => from(clients.getClientDetails(payload.clientId))
        .pipe(
            switchMap((response) => of(fetchClient3DsSettingsSuccess(
                response.data.configuration?.['3_ds'] || null,
                payload.reducerName,
            ))),
            catchError(() => of(fetchClient3DsSettingsFailure())),
        )),
);

export const onFetchClientAddresses = (action$, state$, { addresses }) => action$.pipe(
    ofType(FETCH_CLIENT_ADDRESSES),
    switchMap(({ payload }) => from(addresses.getAddresses({ clientId: payload?.queryParams?.clientId, type: ['billing_address', 'shipping_address'] }))
        .pipe(
            switchMap((response) => {
                const shippingAddress = response.data.find((item) => item.type === 'shipping_address');
                const billingAddress = response.data.find((item) => item.type === 'billing_address');

                return of(fetchClientAddressesSuccess({ billingAddress, shippingAddress }, payload.locationPathname));
            }),
            catchError(() => of(fetchClientAddressesFailure(payload.locationPathname))),
        )),
);


export const onOpenEditClientProfileModal = (action$, _, { i18n }) => action$.pipe(
    ofType(OPEN_EDIT_CLIENT_PROFILE_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.EDIT_CLIENT_MODAL,
            modalProps: {
                title: i18n.t('clients:modals.editClient.title'),
                initialValues: payload,
                width: '50%',
            },
        }),
    )),
);

export const onUpdateClientProfile = (action$, state$, { clients, i18n }) => action$.pipe(
    ofType(UPDATE_CLIENT_PROFILE),
    switchMap(({ payload }) => from(clients.updateClient(payload.clientId, payload.formData))
        .pipe(
            switchMap((response) => {
                const currentClientListQueryParams = state$.value[clientsReducerName][clientsListsPerLocationStoreKeyName]?.[globalLocation]?.queryParams;
                return of(
                    updateClientProfileSuccess(response.data, createClientDetailsPath(payload.clientId)),
                    showSuccessToast(i18n.t('clients:actionMessages.clientProfileUpdateSuccess')),
                    hideModal(),
                    fetchClientsList(currentClientListQueryParams),
                );
            }),
            catchError(() => of(
                updateClientProfileFailure(),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);

export const onUpdateClientSettings = (action$, state$, { clients, i18n }) => action$.pipe(
    ofType(UPDATE_CLIENT_SETTINGS),
    switchMap(({ payload }) => from(clients.updateClient(payload.clientId, payload.formData))
        .pipe(
            switchMap((response) => {
                const commonActions = [
                    updateClientSettingsSuccess(response.data, createClientDetailsPath(payload.clientId)),
                    showSuccessToast(i18n.t('clients:actionMessages.clientSettingsUpdateSuccess')),
                ];

                return of(
                    ...commonActions,
                    ...(!payload?.isAnnouncementsEnabledUpdateOnly ? [
                        unblockNavigation(),
                        requestNavigation(createClientDetailsPath(response.data?.id, { replace: true })),
                    ] : []),
                );
            }),
            catchError(() => of(updateClientSettingsFailure())),
        )),
);

export const onUpdateClientAnnouncement = (action$, state$, { clients, i18n }) => action$.pipe(
    ofType(UPDATE_CLIENT_ANNOUNCEMENT),
    switchMap(({ payload }) => from(clients.updateClientAnnouncement(payload.clientId, payload.formData))
        .pipe(
            switchMap((response) => of(
                // XXX notice responsePayload = { announcement: response.data }
                updateClientAnnouncementSuccess({ announcement: response.data }, createClientDetailsPath(payload.clientId)),
                showSuccessToast(i18n.t('clients:actionMessages.clientAnnouncementUpdateSuccess')),
            )),
            catchError(() => of(updateClientAnnouncementFailure())),
        )),
);


export const onOpenAddClientRiskDetailsModal = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_ADD_CLIENT_RISK_DETAILS_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.CHANGE_CLIENT_RISK_SCORE_MODAL,
            modalProps: {
                title: i18n.t('riskScore:buttons.changeRiskScore.text'),
                initialValues: payload.initialValues,
                locationPathname: payload.locationPathname,
            },
        }),
    )),
);

export const onAddClientRiskDetails = (action$, state$, { clients, i18n }) => action$.pipe(
    ofType(ADD_CLIENT_RISK_DETAILS),
    switchMap(({ payload }) => from(clients.createClientRiskDetails(payload.formData))
        .pipe(
            switchMap(() => {
                const currentClientListQueryParams = state$.value[clientsReducerName][clientsListsPerLocationStoreKeyName]?.[globalLocation]?.queryParams;
                const { clientId } = payload.formData;
                const pathname = createClientDetailsRiskScoreHistoryListPath(clientId);
                // eslint-disable-next-line max-len
                const currentRiskScoreHistoryQueryPrams = state$.value[riskScoreHistoryReducerName][riskScoreHistoryListsPerLocationStoreKeyName]?.[pathname]?.entityDetails?.queryParams;

                return of(
                    ...(currentRiskScoreHistoryQueryPrams
                        ? [fetchRiskScoreHistoryList({ clientId }, pathname)]
                        : []),
                    addClientRiskDetailsSuccess(),
                    showSuccessToast(i18n.t('clients:actionMessages.clientRiskScoreUpdateSuccess')),
                    fetchClientDetails({ clientId }, createClientDetailsPath(clientId)),
                    fetchClientsList(currentClientListQueryParams),
                    hideModal(),
                );
            }),
            catchError(() => of(
                addClientRiskDetailsFailure(),
                setModalProps({ confirmLoading: false, cancelButtonProps: { disabled: false } }),
            )),
        )),
);


export default [
    onFetchClientDetails,
    onFetchClient3DsSettings,
    onFetchClientAddresses,
    onOpenEditClientProfileModal,
    onUpdateClientProfile,
    onUpdateClientSettings,
    onUpdateClientAnnouncement,
    onOpenAddClientRiskDetailsModal,
    onAddClientRiskDetails,
];
