import StandardListTable from 'components/common/organisms/StandardListTable';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import { UserListItemProps } from 'constants/UserModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultUserListQueryParams } from 'redux/users/reducer';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import createUsersColumns from './tableColumns';


function Users({
    accessControl,

    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    dispatchFetchUsers,
    dispatchClearUsersList,
    dispatchSetQueryParams,
    dispatchOpenAddEditUserModal,
    dispatchRequestNavigation,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    // TODO clientId if not super admin?
    const contextEnforcedQueryParams = {};

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newRequestedData) => {
        dispatchSetQueryParams(newRequestedData, enhancedCurrentLocation?.pathname);
        dispatchFetchUsers(newRequestedData, enhancedCurrentLocation?.pathname);
    };


    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultUserListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchUsers,
        dispatchClearItemsList: dispatchClearUsersList,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependent optional account list filters & buttons
    const additionalButtons = useMemo(
        () => (accessControl.hasPermissions([PN.USERS.WRITE, PN.ROLES.READ])
            ? [
                {
                    text: t('buttons.addUser'),
                    type: 'primary',
                    icon: <PlusOutlined />,
                    onClick: () => dispatchOpenAddEditUserModal({ title: t('modals.addEditUserModal.addTitle') }),
                },
            ] : []), [accessControl, dispatchOpenAddEditUserModal],
    );

    // 5. standard render component
    return (
        <>
            <StandardPageHeading title={t('usersList.screenTitle')} />

            <StandardPageWrapper>
                <StandardListTable
                    onUpdate={fetchData}
                    showClientSelect={accessControl.isSuperAdmin} // XXX allowed - client filter on global users list
                    allowAllClients={accessControl.isSuperAdmin} // XXX allowed - client filter on global users list
                    showIncludeInactivePartners
                    allowInactivePartners
                    items={listData?.items}
                    totalItemsCount={listData?.totalCount}
                    queryParams={listData?.queryParams}
                    queryParamsMeta={listData?.queryParamsMeta}
                    isTableLoading={listData?.isLoadingList}
                    isLoadingPlaceholder={!isListInitialStateDefined}
                    columnsConfig={createUsersColumns({
                        accessControl,
                        t,
                        queryParams: listData?.queryParams,
                        dispatchRequestNavigation,
                    })}
                    filtersButtonsConfig={additionalButtons}
                />
            </StandardPageWrapper>
        </>
    );
}


Users.propTypes = {
    accessControl: AccessControlProps,

    // mapped props
    listData: PropTypes.shape({
        isLoading: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(UserListItemProps),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),

    dispatchFetchUsers: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchOpenAddEditUserModal: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchClearUsersList: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default Users;
