import DownloadAccountStatement from 'components/common/templates/lists/AccountsList/DownlaodAccountStatemnet/DownlaodAccountStatemnet.layout';
import { downloadAccountStatementPdf, hideDownloadPdfStatementForm, showDownloadPdfStatementForm } from 'redux/account/actions';
import { accountReducerName } from 'redux/account/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const mapStateToProps = (state, ownProps) => ({
    userPreferences: state[currentUserReducerName]?.userPreferences,
    downloadPdfStatementData: state[accountReducerName].downloadPdfStatement[ownProps?.accountId] || { isFormVisible: false, isDownloading: false },
});


const mapDispatchToProps = (dispatch) => ({
    dispatchDownloadAccountStatementPdf: (queryParams) => dispatch(downloadAccountStatementPdf(queryParams)),
    dispatchShowDownloadPdfStatementForm: (accountId) => dispatch(showDownloadPdfStatementForm(accountId)),
    dispatchHideDownloadPdfStatementForm: (accountId) => dispatch(hideDownloadPdfStatementForm(accountId)),


});

const decorate = compose(
    withTranslation('accounts'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(DownloadAccountStatement);
