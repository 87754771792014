import FAQ from 'components/common/molecules/FAQ';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';

import React from 'react';


function Faq() {
    return (
        <>
            <StandardPageHeading title="Frequently asked questions" />

            <StandardPageWrapper>
                <FAQ
                    showHeading={false}
                    showDecorationImage
                />
            </StandardPageWrapper>
        </>
    );
}

export default Faq;
