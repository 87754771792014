import { createClientSettingsSubStepsConfig } from 'components/common/organisms/forms-shared-parts/add-edit-client-form/common-steps-configurations/settingsSubSteps.helpers';
import { createSuccessStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-client-form/common-steps-configurations/successStep.helpers';
import { DefaultAccountsCurrenciesSet, NewAccountTypes } from 'constants/AccountModel';
import { AddressTypes } from 'constants/AddressModel';
import { flattenObject } from 'utils/object-tools';


import { createClientAccountProductsSubStepsConfig } from './steps-configurations/accountsProductsSubSteps.helpers';
import { createClientAddressSubStepsConfig } from './steps-configurations/addressSubSteps.helpers';
import { createClientDetailsStepConfig } from './steps-configurations/partnerDetailsStep.helpers';


export function createAddClientStepsConfiguration({
    t,
    parsedSettingsConfig,
    shippingAddressSameAsCompanyAddress,
    billingAddressSameAsCompanyAddress,
}) {
    // XXX order DOES matter
    return [
    // BASE DATA
        createClientDetailsStepConfig({ t }),

        // ADDRESS DATA
        createClientAddressSubStepsConfig({
            shippingAddressSameAsCompanyAddress,
            billingAddressSameAsCompanyAddress,
            t,
        }),

        // API DRIVEN DYNAMIC SETTINGS
        createClientSettingsSubStepsConfig({
            parsedSettingsConfig,
            t,
        }),

        // API DRIVEN ACCOUNTS PRODUCTS
        createClientAccountProductsSubStepsConfig({ t }),
        // STATIC (except partner ID for 'View new partner' link)
        createSuccessStepConfig({ t }),
    ];
}


function createCopiedAddressPayload(
    values,
    outputAddressName,
    sourceAddressName = AddressTypes.CLIENT_ADDRESS,
) {
    return Object.keys(values).reduce((acc, fieldName) => {
        if (fieldName.includes(sourceAddressName)) {
            return {
                ...acc,
                [fieldName.replace(sourceAddressName, outputAddressName)]: values[fieldName],
            };
        }

        return acc;
    }, {});
}

export function handleShippingAndBillingAddressCopying({
    values,
    form,
    shippingAddressSameAsCompanyAddress,
    billingAddressSameAsCompanyAddress,
}) {
    if (shippingAddressSameAsCompanyAddress) {
        form.setFieldsValue(createCopiedAddressPayload(
            values,
            AddressTypes.SHIPPING_ADDRESS,
        ));
    }

    if (billingAddressSameAsCompanyAddress) {
        form.setFieldsValue(createCopiedAddressPayload(
            values,
            AddressTypes.BILLING_ADDRESS,
        ));
    }
}


export function extractCurrentAddress(form) {
    return form.getFieldsValue([
        `${AddressTypes.CLIENT_ADDRESS}.address_line_1`,
        `${AddressTypes.CLIENT_ADDRESS}.address_line_2`,
        `${AddressTypes.CLIENT_ADDRESS}.city`,
        `${AddressTypes.CLIENT_ADDRESS}.zip_code`,
        `${AddressTypes.CLIENT_ADDRESS}.country`,
        `${AddressTypes.CLIENT_ADDRESS}.phone_number`,
        `${AddressTypes.CLIENT_ADDRESS}.state`,
    ]);
}
export function createCompanyAddressInitialFormData() {
    return flattenObject({
        client_address: {
            address_line_1: '',
            address_line_2: '',
            city: '',
            zip_code: '',
            country: '',
            phone_number: '',
            state: '',
        },
    });
}

export function createAccountsProductsInitialFormData() {
    const createDefaultValuesForAccountType = (
        accountType,
        defaultCurrencies = DefaultAccountsCurrenciesSet,
    ) => defaultCurrencies.map((currency) => ({
        account_type: accountType,
        currency,
        bps_product_id: undefined,
    }));

    return flattenObject({
        account_products: ([
            ...createDefaultValuesForAccountType(NewAccountTypes.BASE),
            ...createDefaultValuesForAccountType(NewAccountTypes.BUSINESS),
            ...createDefaultValuesForAccountType(NewAccountTypes.PERSONAL),
        ]),
    });
}

export default {
    createCompanyAddressInitialFormData,
    createAccountsProductsInitialFormData,
    handleShippingAndBillingAddressCopying,
    extractCurrentAddress,
};
