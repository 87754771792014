import { TransactionType } from 'constants/TransactionModel';
import { PromotionType } from 'constants/promotions';
import { removeUnderscores } from 'utils/string-tools';

import { Select } from 'antd';
import React from 'react';


const createTransactionTypesForFeeDiscount = () => {
    const {
        WITHDRAW,
        WITHDRAW_INTERNATIONAL,
        POS,
        POS_INTERNATIONAL,
        TRANSACTION_EXCHANGE,
        EXCHANGE,
        TOP_UP,
        TOP_UP_INTERNATIONAL,
        P2P,
        LOCAL_TRANSFER,
        SWIFT_TRANSFER,
    } = TransactionType;

    return {
        WITHDRAW,
        WITHDRAW_INTERNATIONAL,
        POS,
        POS_INTERNATIONAL,
        TRANSACTION_EXCHANGE,
        EXCHANGE,
        TOP_UP,
        TOP_UP_INTERNATIONAL,
        P2P,
        LOCAL_TRANSFER,
        SWIFT_TRANSFER,
    };
};

const createTransactionTypesForCashIncentive = () => {
    const { TOP_UP, TOP_UP_INTERNATIONAL } = TransactionType;
    return { TOP_UP, TOP_UP_INTERNATIONAL };
};


export const handleSubmit = ({
    values,
    clientId,
    dispatchCreatePromotion,
    locationPathname,
}) => {
    const {
        allowExchange,
        isPercentage,
        timeRange,
        triggerAmount,
        ...rest
    } = values;


    dispatchCreatePromotion({
        ...rest,
        allowExchange: allowExchange || false,
        isPercentage: isPercentage || false,
        activeFrom: timeRange[0],
        activeUntil: timeRange[1],
        clientId,
        ...(triggerAmount && triggerAmount > 0 && { triggerAmount }),
    }, locationPathname);
};

export const createTransactionTypeOptions = (promotionType) => {
    if (promotionType) {
        const availableTypes = promotionType === PromotionType.FEE_DISCOUNT
            ? createTransactionTypesForFeeDiscount()
            : createTransactionTypesForCashIncentive();

        return Object.keys(availableTypes).map((type) => (
            <Select.Option key={type} value={type}>
                {removeUnderscores(type)}
            </Select.Option>
        ));
    }
    return null;
};


export default {
    handleSubmit,
    createTransactionTypeOptions,
};
