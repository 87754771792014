import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import MultiStepFormStepsIndicator from './MultiStepFormStepsIndicator.layout';


const decorate = compose(
    withTranslation('common'),
);

export default decorate(MultiStepFormStepsIndicator);

