import { createAction } from '@reduxjs/toolkit';

import {
    CREATE_PROMOTION, CREATE_PROMOTION_FAILURE, CREATE_PROMOTION_SUCCESS,
    FETCH_PROMOTIONS_LIST, CLEAR_PROMOTIONS_LIST, FETCH_PROMOTIONS_LIST_FAILURE, FETCH_PROMOTIONS_LIST_SUCCESS,
} from './actions.types';


export const fetchPromotionsList = createAction(FETCH_PROMOTIONS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchPromotionsListSuccess = createAction(
    FETCH_PROMOTIONS_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchPromotionsListFailure = createAction(FETCH_PROMOTIONS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearPromotionsList = createAction(CLEAR_PROMOTIONS_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export const createPromotion = createAction(CREATE_PROMOTION, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const createPromotionSuccess = createAction(CREATE_PROMOTION_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const createPromotionFailure = createAction(CREATE_PROMOTION_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
