import StandardTag from 'components/common/atoms/StandardTag';
import { BusinessUserStatus } from 'constants/BusinessUserModel';

import PropTypes from 'prop-types';
import React from 'react';


const createHandlers = (status) => {
    const handlers = [
        {
            predicate: () => status === BusinessUserStatus.KYC_PENDING,
            handler: () => ({
                color: 'orange',
                label: 'KYC PENDING',
            }),
        },
        {
            predicate: () => status === BusinessUserStatus.UNVERIFIED,
            handler: () => ({
                color: 'orange',
                label: 'UNVERIFIED',
            }),
        },
        {
            predicate: () => status === BusinessUserStatus.ACTIVE,
            handler: () => ({
                color: 'green',
                label: 'ACTIVE',
            }),
        },
        {
            predicate: () => status === BusinessUserStatus.KYC_UPLOADED,
            handler: () => ({
                color: 'blue',
                label: 'KYC UPLOADED',
            }),
        },
        {
            predicate: () => status === BusinessUserStatus.SUSPENDED,
            handler: () => ({
                color: 'blue',
                label: 'SUSPENDED',
            }),
        },


        {
            predicate: () => status === BusinessUserStatus.KYC_REJECTED,
            handler: () => ({
                color: 'red',
                label: 'KYC REJECTED',
            }),
        },
        {
            predicate: () => status === BusinessUserStatus.CLOSED,
            handler: () => ({
                color: 'red',
                label: 'CLOSED',
            }),
        },

        {
            predicate: () => status === BusinessUserStatus.BANNED,
            handler: () => ({
                color: 'red',
                label: 'BANNED',
            }),
        },
        {
            predicate: () => true,
            handler: () => ({ label: 'UNKNOWN' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function BusinessUserStatusTag({ status, showTooltip = false }) {
    const tagData = createHandlers(status);

    return <StandardTag color={tagData.color} text={tagData.label} showTooltip={showTooltip} />;
}

BusinessUserStatusTag.propTypes = {
    status: PropTypes.oneOf([...Object.values(BusinessUserStatus)]),
    showTooltip: PropTypes.bool,
};

export default BusinessUserStatusTag;
