import { CommonFormFieldTypes } from 'constants/MultiStepsFormModel';
import { commonValidation, isValidPhoneNumberRule, requiredRule } from 'utils/validation-tools';


export const flatFieldName = (rawName, flatFieldNamePrefix) => (flatFieldNamePrefix ? `${flatFieldNamePrefix}.${rawName}` : rawName);

export function createCommonAddressFieldsConfig({
    t,
    useStateField = false,
    usePhoneField = false,
    usePremiseField = false,
    fieldsNamePrefix,
    isDisabled,
}) {
    return [
        {
            name: flatFieldName('address_line_1', fieldsNamePrefix),
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('common:addressFields.addressLine1.label'),
            placeholder: t('common:addressFields.addressLine1.placeholder'),
            validationRules: commonValidation({
                t,
                isRequired: true,
            }),
            isDisabled,
        },
        {
            name: flatFieldName('address_line_2', fieldsNamePrefix),
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('common:addressFields.addressLine2.label'),
            placeholder: t('common:addressFields.addressLine2.placeholder'),
            validationRules: commonValidation({ t }),
            isDisabled,
        },

        ...(usePremiseField ? [{
            name: flatFieldName('premise', fieldsNamePrefix),
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('common:addressFields.premise.label'),
            placeholder: t('common:addressFields.premise.placeholder'),
            validationRules: commonValidation({
                t,
                isRequired: false,
            }),
            isDisabled,
        }] : []),

        {
            name: flatFieldName('city', fieldsNamePrefix),
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('common:addressFields.city.label'),
            placeholder: t('common:addressFields.city.placeholder'),
            validationRules: commonValidation({
                t,
                isRequired: true,
            }),
            isDisabled,
        },
        {
            name: flatFieldName('zip_code', fieldsNamePrefix),
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('common:addressFields.postCode.label'),
            placeholder: t('common:addressFields.postCode.placeholder'),
            validationRules: commonValidation({
                t,
                isRequired: true,
            }),
            isDisabled,
        },
        {
            name: flatFieldName('country', fieldsNamePrefix),
            fieldType: CommonFormFieldTypes.SELECT_COUNTRY,
            label: t('common:addressFields.country.label'),
            placeholder: t('common:addressFields.country.placeholder'),
            validationRules: requiredRule(t),
            isDisabled,
        },


        ...(usePhoneField ? [{
            name: flatFieldName('phone_number', fieldsNamePrefix),
            label: t('common:personalFields.phoneNumber.label'),
            placeholder: t('common:personalFields.phoneNumber.placeholder'),
            fieldType: CommonFormFieldTypes.PHONE_NUMBER,
            validationRules: [
                ...requiredRule(t),
                ...isValidPhoneNumberRule(t),
            ],
            isDisabled,
        }] : []),


        ...(useStateField ? [{
            name: flatFieldName('state', fieldsNamePrefix),
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('common:addressFields.state.label'),
            placeholder: t('common:addressFields.state.placeholder'),
            validationRules: commonValidation({ t }),
            isDisabled,
        }] : []),
    ];
}
export default { flatFieldName, createCommonAddressFieldsConfig };
