import StandardTooltip from 'components/common/molecules/StandardTooltip';
import { AccessControlProps } from 'constants/AccessControlModel';
import { PN } from 'constants/PermissionsModel';

import { UnlockOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import Styled from './UnlockLogin.styled';


function UnlockLogin({
    isLocked,
    extUserUuid,
    context,
    accessControl,
    isLoadingUnlockLogin,
    dispatchUnlockUser,
    t,
}) {
    const isActionable = useMemo(
        () => accessControl.hasPermission(PN.AUTH.UNLOCK)
      && extUserUuid
      && isLocked,
        [accessControl, isLocked, extUserUuid],
    );

    return isActionable ? (
        <Styled.UnlockLoginWrapper>
            <StandardTooltip title={t('buttons.unlockLogin.title')}>
                <Button
                    type="primary"
                    icon={<UnlockOutlined />}
                    disabled={isLoadingUnlockLogin}
                    loading={isLoadingUnlockLogin}
                    onClick={() => dispatchUnlockUser({ extUserUuid, context })}
                >
                    {t('buttons.unlockLogin.text')}
                </Button>
            </StandardTooltip>
        </Styled.UnlockLoginWrapper>
    ) : null;
}

UnlockLogin.propTypes = {
    // own props
    extUserUuid: PropTypes.string,
    isLocked: PropTypes.bool,
    context: PropTypes.shape({
        memberId: PropTypes.number,
        userId: PropTypes.number,
        businessUserId: PropTypes.number,
        locationPathname: PropTypes.string,
    }),
    // mapped props
    accessControl: AccessControlProps,
    isLoadingUnlockLogin: PropTypes.bool,
    dispatchUnlockUser: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default UnlockLogin;
