import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from './SwitchWithInfoTooltip.styled';


function SwitchWithInfoTooltip({
    onChange,
    checked,
    disabled,
    tooltipContent,
    reverseOrder = false,
}) {
    const toggle = (
        <Switch
            onClick={onChange}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checked}
            disabled={disabled}
        />
    );


    return tooltipContent
        ? (
            <Tooltip title={tooltipContent}>
                <Styled.InfoIconAndSwitchWrapper>
                    {reverseOrder ? (
                        <>
                            {toggle}
                            <Styled.InfoIcon />
                        </>
                    ) : (
                        <>
                            <Styled.InfoIcon />
                            {toggle}
                        </>
                    )}
                </Styled.InfoIconAndSwitchWrapper>
            </Tooltip>
        )
        : toggle;
}


SwitchWithInfoTooltip.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltipContent: PropTypes.string,
    reverseOrder: PropTypes.bool,
};

export default SwitchWithInfoTooltip;
