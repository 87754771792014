import StandardLink from 'components/common/molecules/StandardLink';

import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';


import {
    TranslationInlineWrap,

    Anchor,
    Code,
    Emphasis,
    ListItem,
    OrderedList,
    Paragraph,
    Quotation,
    Underline,
    UnorderedList,
} from './StandardTranslationWithMarkup.styled';


const defaultComponents = {
    p: <Paragraph />,
    strong: <Emphasis />,
    u: <Underline />,
    q: <Quotation />,
    code: <Code />,
    ol: <OrderedList />,
    ul: <UnorderedList />,
    li: <ListItem />,
    a: <Anchor target="_blank" />,
    ref: <StandardLink />,
};

function StandardTranslationWithMarkup({
    translationKey,
    components = undefined,
    values,
    namespace,
    t,
    parent,
    color,
}) {
    return (
        <TranslationInlineWrap color={color}>
            <Trans
                i18nKey={translationKey}
                ns={namespace}
                t={t}
                values={{ ...values }}
                components={{ ...defaultComponents, ...components }}
                parent={parent}
            />
        </TranslationInlineWrap>
    );
}


StandardTranslationWithMarkup.propTypes = {
    t: PropTypes.func.isRequired,
    translationKey: PropTypes.string.isRequired,
    namespace: PropTypes.string.isRequired,
    values: PropTypes.shape({}),
    color: PropTypes.string,
    parent: PropTypes.string,
    components: PropTypes.node,
};
export default StandardTranslationWithMarkup;
