import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearMarketingPreferencesList, fetchMarketingPreferences, showMarketingPreferencesModal } from 'redux/marketing-preferences/actions';
import { marketingPreferencesListsPerLocationStoreKeyName, marketingPreferencesReducerName } from 'redux/marketing-preferences/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import MarketingPreferencesList from './MarketingPreferencesList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: marketingPreferencesReducerName,
        listsByLocationStoreKeyName: marketingPreferencesListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchMarketingPreferencesList: (queryParams, locationPathname) => dispatch(fetchMarketingPreferences(queryParams, locationPathname)),
    dispatchClearMarketingPreferencesList: (locationPathname) => dispatch(clearMarketingPreferencesList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: marketingPreferencesReducerName,
        fieldName: marketingPreferencesListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchShowRequestMarketingPreferencesModal: (modalProps) => dispatch(showMarketingPreferencesModal(modalProps)),
});

const decorate = compose(
    withTranslation(),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(MarketingPreferencesList);
