import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
} from 'redux/standardEntityDetails.helpers';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';

import {
    fetchPromotionDetails, fetchPromotionDetailsFailure, fetchPromotionDetailsSuccess,
    enablePromotion, enablePromotionFailure, enablePromotionSuccess,
    disablePromotion, disablePromotionFailure, disablePromotionSuccess, clearPromotionDetails,
} from './actions';


export const promotionReducerName = 'promotion';
export const promotionDetailsPerLocationStoreKeyName = 'promotionDetailsPerLocation';
export const promotionGroupsListPerLocationStoreKeyName = 'promotionGroupsListPerLocation';

const initialDetailsState = {
    [promotionDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    [promotionGroupsListPerLocationStoreKeyName]: {},
    isLoadingToggleEnableDisable: false,
};

const initialState = { ...initialDetailsState };


const handlers = {
    [fetchPromotionDetails]: (state, action) => {
        state[promotionDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[promotionDetailsPerLocationStoreKeyName], action);
        // XXX non-standard binding of the list to entity details
        state[promotionGroupsListPerLocationStoreKeyName] = updateStandardLists(state[promotionGroupsListPerLocationStoreKeyName], action);
    },
    [fetchPromotionDetailsSuccess]: (state, action) => {
        state[promotionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[promotionDetailsPerLocationStoreKeyName], action);
        // XXX non-standard binding of the list to entity details
        state[promotionGroupsListPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[promotionGroupsListPerLocationStoreKeyName], {
            payload: {
                items: action.payload.responsePayload?.groups,
                totalCount: action.payload.responsePayload?.groups?.length,
                locationPathname: action.payload.locationPathname,
            },
        });
    },
    [fetchPromotionDetailsFailure]: (state, action) => {
        state[promotionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[promotionDetailsPerLocationStoreKeyName], action);
        // XXX non-standard binding of the list to entity details
        state[promotionGroupsListPerLocationStoreKeyName] = updateStandardListsOnFailure(state[promotionGroupsListPerLocationStoreKeyName], {
            locationPathname: action.locationPathname,
            //
        });
    },
    [clearPromotionDetails]: (state, action) => {
        state[promotionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[promotionDetailsPerLocationStoreKeyName], action.payload.locationPathname);
        // XXX non-standard binding of the list to entity details
        state[promotionGroupsListPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[promotionDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === promotionReducerName
      && action.payload?.fieldName === promotionDetailsPerLocationStoreKeyName
        ) {
            state[promotionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[promotionDetailsPerLocationStoreKeyName] }, action);
        }

        if (action.payload?.reducerName === promotionReducerName
      && action.payload?.fieldName === promotionGroupsListPerLocationStoreKeyName
        ) {
            state[promotionGroupsListPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[promotionGroupsListPerLocationStoreKeyName] }, action);
        }
    },

    [enablePromotion]: (state) => {
        state.isLoadingToggleEnableDisable = true;
    },
    [enablePromotionFailure]: (state) => {
        state.isLoadingToggleEnableDisable = false;
    },
    [enablePromotionSuccess]: (state, action) => {
        state[promotionDetailsPerLocationStoreKeyName] = {
            ...state[promotionDetailsPerLocationStoreKeyName],
            [action.payload.locationPathname]: {
                ...state[promotionDetailsPerLocationStoreKeyName][action.payload.locationPathname],
                entityDetails: {
                    ...state[promotionDetailsPerLocationStoreKeyName][action.payload.locationPathname].entityDetails,
                    is_enabled: true,
                },
            },
        };
        state.isLoadingToggleEnableDisable = false;
    },


    [disablePromotion]: (state) => {
        state.isLoadingToggleEnableDisable = true;
    },
    [disablePromotionFailure]: (state) => {
        state.isLoadingToggleEnableDisable = false;
    },
    [disablePromotionSuccess]: (state, action) => {
        state[promotionDetailsPerLocationStoreKeyName] = {
            ...state[promotionDetailsPerLocationStoreKeyName],
            [action.payload.locationPathname]: {
                ...state[promotionDetailsPerLocationStoreKeyName][action.payload.locationPathname],
                entityDetails: {
                    ...state[promotionDetailsPerLocationStoreKeyName][action.payload.locationPathname].entityDetails,
                    is_enabled: false,
                },

            },
        };
        state.isLoadingToggleEnableDisable = false;
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
