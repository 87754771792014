import withAccessControl from 'hocs/withAccessControl';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import Companies from './Companies.layout';


const mapStateToProps = (state) => ({ currentUserClientId: state.currentUser.userData?.clientId });

const decorate = compose(
    withTranslation('companies'),
    withAccessControl,
    connect(mapStateToProps),
);

export default decorate(Companies);
