import FeeTiersList from 'components/common/templates/lists/FeeTiersList';
import GroupsTab from 'components/common/templates/lists/GroupsList';
import { groupsRouteSegmentPath, tiersRouteSegmentPath } from 'config/routes';
import { FeeType } from 'constants/FeeModel';
import { PN } from 'constants/PermissionsModel';

import { FolderOutlined } from '@ant-design/icons';
import React from 'react';


export const createFeeDetailsTabsConfig = ({
    feeUuid,
    feeType,
    accessControl,
    clientId,
    t,
}) => {
    const isManualFee = feeType === FeeType.MANUAL_FEE;
    return !isManualFee ? [
        ...(accessControl.hasPermission(PN.FEE_TIERS.READ_BY_FEE_ID) ? [{
            icon: <FolderOutlined />,
            label: t('common:tabsNames.feeTiers'),
            isDefault: true,
            activeMainTabSegmentURL: tiersRouteSegmentPath,
            content: <FeeTiersList feeUuid={feeUuid} feeType={feeType} />,
        }] : []),

        ...(accessControl.hasPermission(PN.GROUPS.READ_FOR_FEE) ? [{
            icon: <FolderOutlined />,
            label: t('common:tabsNames.groups'),
            activeMainTabSegmentURL: groupsRouteSegmentPath,
            content: <GroupsTab feeUuid={feeUuid} clientId={clientId} />,
        }] : []),
    ] : [];
};

export default { createFeeDetailsTabsConfig };
