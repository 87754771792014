import styled from 'styled-components';


const PermissionItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0,1fr) minmax(0, auto) minmax(0, auto);
  grid-column-gap: 10px;
  white-space: nowrap;
  align-items: center;
  
  & + & {
    margin-top: 10px;
  }
`;

const PermissionName = styled.div`
  font-family: monospace;
  font-weight: bold;
  letter-spacing: 1px;
  display: grid;
  grid-template-columns: min-content minmax(0, min-content);
  grid-column-gap: 10px;
`;

const OperationId = styled.div`
  font-family: monospace;
`;


// eslint-disable-next-line spellcheck/spell-checker
const AddPermission = styled.button`
  background: none;
  border: none;
  outline: none;
  color: #CACACA;
  font-size: 24px;
  cursor: pointer;
  
  :hover {
    color: #397dcc;
  }
`;

export default { PermissionItemWrapper, PermissionName, OperationId, AddPermission };
