import { addCompanyRiskDetails } from 'redux/company/actions';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ChangeCompanyRiskScoreModal from './ChangeCompanyRiskScoreModal.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAddCompanyRiskDetails: (companyId, formData, locationPathname) => dispatch(addCompanyRiskDetails(companyId, formData, locationPathname)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});


const decorate = compose(
    withTranslation('company'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ChangeCompanyRiskScoreModal);

