import { standardPageSize } from 'config/config';
import { beneficiariesRouteSegmentPath, clientRootRoutePath, companyRootRoutePath, memberRootRoutePath } from 'config/routes';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchBeneficiariesList,
    fetchBeneficiariesListSuccess,
    fetchBeneficiariesListFailure,
    clearBeneficiariesList,
} from './actions';


export const beneficiariesReducerName = 'beneficiaries';
export const beneficiariesListsPerLocationStoreKeyName = 'beneficiariesListsPerLocation';

export const beneficiaryListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    clientId: undefined,
    memberId: undefined,
    companyId: undefined,
};

const initialState = {
    // List
    [beneficiariesListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...}
};


const handlers = {
    [fetchBeneficiariesList]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = updateStandardLists(state[beneficiariesListsPerLocationStoreKeyName], action);
    },
    [fetchBeneficiariesListSuccess]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[beneficiariesListsPerLocationStoreKeyName], action);
    },
    [fetchBeneficiariesListFailure]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[beneficiariesListsPerLocationStoreKeyName], action);
    },
    [clearBeneficiariesList]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[beneficiariesListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[beneficiariesListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [beneficiariesRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath, companyRootRoutePath, memberRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === beneficiariesReducerName
      && action.payload?.fieldName === beneficiariesListsPerLocationStoreKeyName
        ) {
            state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[beneficiariesListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
