import PropTypes from 'prop-types';


export const ClientRiscScoreTypes = Object.freeze({
    COUNTRY_OF_INCORPORATION: 1,
    COUNTRIES_OF_OPERATIONS: 2,
    INDUSTRIES: 3,
});
const RiskScoreCountryProps = PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    name: PropTypes.string,
    iso_31662: PropTypes.string,
    iso_31663: PropTypes.string,
});

const RiskScoreHistoryCommonProps = {

    comment: PropTypes.string,
    country: RiskScoreCountryProps,
    country_id: PropTypes.number,
    created_at: PropTypes.string,
    from: PropTypes.number,
    id: PropTypes.number,
    to: PropTypes.number,
    user: PropTypes.shape({
        first_name: PropTypes.string,
        id: PropTypes.number,
        last_name: PropTypes.string,
    }),
    user_id: PropTypes.number,
};


export const ClientRiskScoreHistoryItemProps = PropTypes.shape({
    ...RiskScoreHistoryCommonProps,
    client_id: PropTypes.number,
    industry_id: PropTypes.number,
    operation_country: RiskScoreCountryProps,
    operation_country_id: PropTypes.number,
    industry: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
});

export const CompanyRiskScoreHistoryItemProps = PropTypes.shape({
    ...RiskScoreHistoryCommonProps,
    company_id: PropTypes.number,
});

export default {
    ClientRiscScoreTypes,
    ClientRiskScoreHistoryItemProps,
    CompanyRiskScoreHistoryItemProps,
};
