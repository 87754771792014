import dayjs from 'dayjs';


export const markActivatedPromotions = (rawPromotions = []) => {
    const now = dayjs();
    return rawPromotions.map((rawPromotion) => {
        const isAlreadyActivated = dayjs.utc(rawPromotion.active_from).local() <= now;
        return {
            ...rawPromotion,
            isActivated: isAlreadyActivated,
        };
    });
};


export default { markActivatedPromotions };
