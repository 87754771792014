import StandardListTable from 'components/common/organisms/StandardListTable';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { KycKybAuditLogsDataProp } from 'constants/KycKybModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultKycKybAuditsListQueryParams } from 'redux/kyc-kyb/reducer';

import PropTypes from 'prop-types';
import React from 'react';


import { createKycKybAuditListColumns } from './tableColumns';


function KycKybAuditList({
    // own props
    memberId,
    companyId,
    businessUserId,

    // list data
    enhancedCurrentLocation,
    listData,

    // other mapped props
    dispatchFetchKycKybLogsList,
    dispatchClearKycKybLogsList,
    dispatchSetQueryParams,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ...(memberId && memberId > 0 ? { memberId } : {}),
        ...(companyId && companyId > 0 ? { companyId } : {}),
        ...(businessUserId && businessUserId > 0 ? { businessUserId } : {}),
    };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchKycKybLogsList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultKycKybAuditsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchKycKybLogsList,
        dispatchClearItemsList: dispatchClearKycKybLogsList,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependant
    // no actions

    // 5. standard render component
    return (
        <StandardListTable
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createKycKybAuditListColumns({ t, queryParams: listData?.queryParams })}
        />
    );
}

KycKybAuditList.propTypes = {
    // own props
    memberId: PropTypes.number,
    companyId: PropTypes.number,
    businessUserId: PropTypes.number,

    // mapped props
    dispatchFetchKycKybLogsList: PropTypes.func.isRequired,
    dispatchClearKycKybLogsList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: KycKybAuditLogsDataProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
};

export default KycKybAuditList;
