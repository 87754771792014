import FlagAndValue from 'components/common/atoms/FlagAndValue';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { AccountIdentificationType } from 'constants/AccountModel';
import { CountryIdType } from 'constants/CountriesModel';
import { formatIBAN } from 'utils/account-tools';
import { removeUnderscores } from 'utils/string-tools';

import React from 'react';


export const createBeneficiaryDetailsFields = (beneficiaryDetails, t) => {
    if (beneficiaryDetails?.id) {
        return [
            ...(beneficiaryDetails?.company_name ? [{
                value: <StandardValue value={beneficiaryDetails.company_name} />,
                label: t('beneficiaryFields.companyName.label'),
            }] : []),

            ...(beneficiaryDetails?.first_name ? [{

                value: <StandardValue value={beneficiaryDetails.first_name} />,
                label: t('beneficiaryFields.firstName.label'),
            }] : []),

            ...(beneficiaryDetails?.middle_name ? [{
                value: <StandardValue value={beneficiaryDetails.middle_name} />,
                label: t('beneficiaryFields.middleName.label'),
            }] : []),

            ...(beneficiaryDetails?.last_name ? [{
                value: <StandardValue value={beneficiaryDetails.last_name} />,
                label: t('beneficiaryFields.lastName.label'),
            }] : []),

            {
                value: <StandardValue value={beneficiaryDetails.id} />,
                label: t('beneficiaryFields.uuid'),
            },
            {
                value: <StandardValue value={beneficiaryDetails.created_at} valueType="DATE" showTime />,
                label: t('beneficiaryFields.createdAt'),
            },
            {
                value: <StandardValue value={beneficiaryDetails.updated_at} valueType="DATE" showTime />,
                label: t('beneficiaryFields.updatedAt'),
            },
        ];
    }

    return [];
};

export const createBeneficiaryAddressFields = (beneficiaryDetails, t) => {
    if (beneficiaryDetails?.address) {
        return [
            {
                value: <StandardValue value={beneficiaryDetails.address?.address_line_1} />,
                label: t('common:addressFields.addressLine1.label'),
            },

            ...(beneficiaryDetails?.address.address_line_2 ? [{
                value: <StandardValue value={beneficiaryDetails.address?.address_line_2} />,
                label: t('common:addressFields.addressLine2.label'),
            }] : []),

            {
                value: <StandardValue value={beneficiaryDetails.address?.city} />,
                label: t('common:addressFields.city.label'),
            },
            {
                value: <StandardValue value={beneficiaryDetails.address?.zip_code} />,
                label: t('common:addressFields.postCode.label'),
            },
            {
                value: <CountryRenderer propertyName={CountryIdType.iso_31663} value={beneficiaryDetails.address?.country} />,
                label: t('common:addressFields.country.label'),
            },
            {
                value: <StandardValue value={beneficiaryDetails.address?.state_province} />,
                label: t('common:addressFields.state.label'),
            },
        ];
    }

    return [];
};

export const createBeneficiaryAccountIdentificationsFields = (beneficiaryDetails, t) => {
    if (beneficiaryDetails?.account_identifications?.[0]) {
        return [
            {
                value: <StandardValue value={beneficiaryDetails.beneficiary_type} />,
                label: t('beneficiaryFields.beneficiaryType.label'),
            },
            {
                value: <StandardValue value={beneficiaryDetails.account_identifications?.[0]?.identification_category} />,
                label: t('beneficiaryFields.identificationCategory.label'),
            },
            {
                value: <CountryRenderer propertyName={CountryIdType.iso_31663} value={beneficiaryDetails?.country} />,
                label: t('beneficiaryFields.country.label'),
            },
            {
                value: <FlagAndValue currencyCode={beneficiaryDetails?.currency} text={beneficiaryDetails?.currency} />,
                label: t('beneficiaryFields.currency.label'),
            },
            ...((beneficiaryDetails?.account_identifications || []).map((identifier) => ({
                value: <StandardValue
                    value={identifier.identification_type === AccountIdentificationType.IBAN
                        ? formatIBAN(identifier.identification_value)
                        : identifier.identification_value}
                />,
                label: removeUnderscores(identifier.identification_type),
            }))),
        ];
    }

    return [];
};


export default {
    createBeneficiaryDetailsFields,
    createBeneficiaryAddressFields,
    createBeneficiaryAccountIdentificationsFields,
};
