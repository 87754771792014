import AddNewProductTile from 'components/pages/authorised/AddClient/AddClientForm/steps/InitialAccountsProducts/AddNewProductTile';
import { CurrenciesListProp } from 'constants/CurrencyModel';

import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from './InitailAccountsProducts.styled';
import ProductItemTile from './ProductItemTile';


function InitialAccountsProducts({
    bpsProductsList,
    currencies,
    sectionAccountType,
    t,
}) {
    const fieldName = 'account_products';

    return (
        <Form.List name={fieldName}>
            {(fields, { add, remove }) => (
                <Styled.InitialAccountsProductsWrapper>
                    <AddNewProductTile
                        t={t}
                        parentListFieldName={fieldName}
                        onAdd={add}
                        currencies={currencies}
                        sectionAccountType={sectionAccountType}
                    />

                    {fields.map((field, index) => (
                        <ProductItemTile
                            key={`account_products_field_${field.name}`}
                            t={t}
                            parentListFieldName={fieldName}
                            field={field}
                            index={index}
                            bpsProductsList={bpsProductsList}
                            currencies={currencies}
                            sectionAccountType={sectionAccountType}
                            onRemove={remove}
                        />
                    ))}
                </Styled.InitialAccountsProductsWrapper>
            )}
        </Form.List>
    );
}

InitialAccountsProducts.propTypes = {
    formInstance: PropTypes.shape({}),
    bpsProductsList: PropTypes.arrayOf(PropTypes.shape({})),
    currencies: CurrenciesListProp,
    sectionAccountType: PropTypes.string.isRequired,
    t: PropTypes.func,
};

export default InitialAccountsProducts;
