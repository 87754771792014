import { LockOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const ReadOnlyIndicatorWrapper = styled.div`
  width: min-content;
  margin: 0 auto;
`;

const Content = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
`;

const TooltipContent = styled.div`
  text-align: left;
  font-weight: 400;
`;

const TooltipHeadingCopy = styled.div`
  text-align: center;
  font-weight: 700;
`;

const TooltipContentValue = styled.td`
  font-weight: 700;
  padding-left: 10px;
  max-width: 250px;
`;

const TooltipContentDetailsTable = styled.table`
  text-align: left;
`;

const DecorIcon = styled(LockOutlined)`
  color: red;
  cursor: help;
  font-size: 40px;
`;

const WarnCopy = styled.div`
  white-space: nowrap;
  word-spacing: 0px;
  color: red;
  font-size: 24px;
  letter-spacing: 1px;
  font-family: monospace;
`;

const InfoIcon = styled(InfoCircleOutlined)`
  color: red;
  cursor: help;
  font-size: 14px;
`;


export default {
    ReadOnlyIndicatorWrapper,
    DecorIcon,
    WarnCopy,
    Content,
    TooltipContentDetailsTable,
    InfoIcon,
    TooltipContent,
    TooltipHeadingCopy,
    TooltipContentValue,
};

