import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import { Tag } from 'antd';
import styled, { css } from 'styled-components';


export const StandardTagWrapper = styled.div`
  display: inline-grid;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  ${({ $enableTextEllipsis }) => $enableTextEllipsis && css`
    .standard-tag {
      ${commonTextEllipsisStyles};
    }
  `};
`;

export const StandardTag = styled(Tag)`
  max-width: 100%;
`;


export default { StandardTagWrapper, StandardTag };
