import { HttpService } from 'services/http';


export const getKycKybFlowsApi = (queryParams) => HttpService.get('/v2/verification/level', queryParams);

export const setKycKybFlowApi = (requestPayload) => HttpService.post('/v2/verification/level', requestPayload);

export const triggerKycKybFlowApi = (requestPayload) => HttpService.post('/v2/verification/restart', requestPayload);

export const getKycKybLogsApi = (queryParams) => HttpService.get('/v2/kyckyb-logs', queryParams);

export default {
    getKycKybFlowsApi,
    setKycKybFlowApi,
    triggerKycKybFlowApi,
    getKycKybLogsApi,
};
