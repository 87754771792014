import { optionalTransactionsScopeFilters } from 'constants/TransactionModel';
import { QueryParams } from 'models/app/common';

import { TFunction } from 'i18next';


export const createScopeFilters = (t: TFunction, transactionFilters, queryParams: QueryParams) => {
    const scopeFilters = (transactionFilters || []).reduce((acc, filterOption) => {
        if (filterOption
      && optionalTransactionsScopeFilters.includes(filterOption.name)
      && !queryParams?.showCardVerification
        ) {
            return acc;
        }

        if (filterOption) {
            return [
                ...acc,
                {
                    text: t(`transactionScopes.${filterOption.transactionScopeNew.toLowerCase()}.text`) || filterOption.label,
                    value: filterOption.name,
                },
            ];
        }

        return acc;
    }, []);

    return scopeFilters || undefined;
};


export const mapTransactionScopeFilterValueToQuery = (selectedType, transactionsFilters) => {
    if (selectedType) {
        const selectedOption = transactionsFilters.find((filterOption) => (filterOption.name === selectedType));
        if (selectedOption) {
            return {
                transactionType: selectedOption.transactionTypeNew,
                transactionScope: selectedOption.transactionScopeNew,
            };
        }
    }

    return {};
};


export default { createScopeFilters, mapTransactionScopeFilterValueToQuery };

