import withAccessControl from 'hocs/withAccessControl';
import { openAddCompanyRiskDetailsModal } from 'redux/company/actions';
import { companyDetailsPerLocationsStoreKeyName, companyReducerName } from 'redux/company/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CompanyRiskDetails from './CompanyRiskDetails.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    riskDetails: state.company.riskDetails,
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    companyEntityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: companyReducerName,
        fieldName: companyDetailsPerLocationsStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchOpenAddCompanyRiskDetailsModal: (companyData) => dispatch(openAddCompanyRiskDetailsModal(companyData)),
    //
});

const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CompanyRiskDetails);

