import styled from 'styled-components';


const InitialAccountsProductsWrapper = styled.div`
  display: block;
  padding: 16px 0;
  
  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export default { InitialAccountsProductsWrapper };
