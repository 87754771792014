import { goToPreviousAddEditBeneficiaryFormStep } from 'redux/beneficiary-add/actions';
import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';
import { requestNavigation } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import BackButton from './BackButton.layout';


const mapStateToProps = (state) => ({
    currentStep: state[beneficiaryAddReducerName].currentStep,
    isEditMode: state[beneficiaryAddReducerName].contextData?.isEditMode === true,
    returnPath: state[beneficiaryAddReducerName].contextData?.returnPath,
});


const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
    dispatchGoToPreviousAddEditBeneficiaryFormStep: (currentStep) => dispatch(goToPreviousAddEditBeneficiaryFormStep(currentStep)),
});

const decorate = compose(
    withTranslation('beneficiaries'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BackButton);

