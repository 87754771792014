export const FETCH_CLIENT_MIGRATIONS_LIST = 'FETCH_CLIENT_MIGRATIONS_LIST';
export const FETCH_CLIENT_MIGRATIONS_LIST_SUCCESS = 'FETCH_CLIENT_MIGRATIONS_LIST_SUCCESS';
export const FETCH_CLIENT_MIGRATIONS_LIST_FAILURE = 'FETCH_CLIENT_MIGRATIONS_LIST_FAILURE';
export const CLEAR_CLIENT_MIGRATIONS_LIST = 'CLEAR_CLIENT_MIGRATIONS_LIST';

export const DOWNLOAD_MIGRATION_RESULT = 'DOWNLOAD_MIGRATION_RESULT';
export const DOWNLOAD_MIGRATION_RESULT_SUCCESS = 'DOWNLOAD_MIGRATION_RESULT_SUCCESS';
export const DOWNLOAD_MIGRATION_RESULT_FAILURE = 'DOWNLOAD_MIGRATION_RESULT_FAILURE';

export const DOWNLOAD_MIGRATION_REPORT = 'DOWNLOAD_MIGRATION_REPORT';
export const DOWNLOAD_MIGRATION_REPORT_SUCCESS = 'DOWNLOAD_MIGRATION_REPORT_SUCCESS';
export const DOWNLOAD_MIGRATION_REPORT_FAILURE = 'DOWNLOAD_MIGRATION_REPORT_FAILURE';

export const UPLOAD_CLIENT_MIGRATION_FILE = 'UPLOAD_CLIENT_MIGRATION_FILE';
export const UPLOAD_CLIENT_MIGRATION_FILE_SUCCESS = 'UPLOAD_CLIENT_MIGRATION_FILE_SUCCESS';
export const UPLOAD_CLIENT_MIGRATION_FILE_FAILURE = 'UPLOAD_CLIENT_MIGRATION_FILE_FAILURE';

