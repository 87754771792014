import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearBeneficiariesList, fetchBeneficiariesList } from 'redux/beneficiaries/actions';
import { beneficiariesListsPerLocationStoreKeyName, beneficiariesReducerName } from 'redux/beneficiaries/reducer';
import { deleteBeneficiary } from 'redux/beneficiary/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import BeneficiariesList from './BeneficiariesList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: beneficiariesReducerName,
        listsByLocationStoreKeyName: beneficiariesListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch) => ({
    dispatchDeleteBeneficiary: (beneficiaryUuid, locationPathname) => dispatch(deleteBeneficiary(beneficiaryUuid, locationPathname)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),

    dispatchFetchBeneficiariesList: (queryParams, locationPathname) => dispatch(fetchBeneficiariesList(queryParams, locationPathname)),
    dispatchClearBeneficiariesList: (queryParams, locationPathname) => dispatch(clearBeneficiariesList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: beneficiariesReducerName,
        fieldName: beneficiariesListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('beneficiaries'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BeneficiariesList);
