import StandardTooltip from 'components/common/molecules/StandardTooltip';
import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import ClientSelect from 'components/common/molecules/form-controls/ClientSelect';
import CountriesSelect from 'components/common/molecules/form-controls/CountriesSelect';
import IndustriesSelect from 'components/common/molecules/form-controls/IndustriesSelect';
import IntegerInput from 'components/common/molecules/form-controls/IntegerInput';
import { CommonFormFieldTypes } from 'constants/MultiStepsFormModel';
import { removeUnderscores } from 'utils/string-tools';

import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Input, Select, Switch } from 'antd';
import React from 'react';


import Styled from './StandardFormItem.styled';


const selectRenderers = ({
    fieldConfig,
    isDisabled,
    placeholder,
}) => {
    const renderEnumValues = (enumValues = []) => enumValues.map((item) => (
        <Select.Option key={item?.value || item} value={item?.value || item}>
            {item?.label || removeUnderscores(item)}
        </Select.Option>
    ));

    return [
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.MULTI_SELECT_INDUSTRIES,
            handler: () => (
                <IndustriesSelect
                    placeholder={placeholder}
                    allowClear
                    isMultiValue
                    isDisabled={isDisabled}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.MULTI_SELECT_EEA_COUNTRIES,
            handler: () => (
                <CountriesSelect
                    placeholder={placeholder}
                    onlyEEACountries
                    allowClear
                    isMultiValue
                    isDisabled={isDisabled}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.SELECT_COUNTRY,
            handler: () => (
                <CountriesSelect
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    allowClear
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.SELECT_CLIENT,
            handler: () => (
                <ClientSelect
                    isFormItem={false}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.MULTI_SELECT,
            handler: () => (
                <Select
                    placeholder={placeholder}
                    mode="multiple"
                    allowClear
                    disabled={isDisabled}
                    showSearch={(fieldConfig?.metadata?.enum_values?.length || fieldConfig?.customOptions?.length) > 7}
                >
                    {fieldConfig?.customOptions || renderEnumValues(fieldConfig?.metadata?.enum_values)}
                </Select>
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.SELECT,
            handler: () => (
                <Select
                    placeholder={placeholder}
                    allowClear
                    disabled={isDisabled}
                    showSearch={(fieldConfig?.metadata?.enum_values?.length || fieldConfig?.customOptions?.length) > 7}
                >
                    {fieldConfig?.customOptions || renderEnumValues(fieldConfig?.metadata?.enum_values)}
                </Select>
            ),
        },

        // XXX fancy select with input and tags as control -> array of string values as output
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.TEXT_MULTI_VALUES,
            handler: () => (
                <Select
                    mode="tags"
                    allowClear
                    disabled={isDisabled}
                    placeholder={placeholder}
                    notFoundContent={'Enter new value to see it on the list'}
                />
            ),
        },
    ];
};

const numbersRenderers = ({
    fieldConfig,
    placeholder,
    isDisabled,
}) => [
    {
        predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.NUMBER,
        handler: () => (
            <IntegerInput
                placeholder={placeholder}
                disabled={isDisabled}
            />
        ),
    },
    {
        predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.POSITIVE_INTEGER,
        handler: () => (
            <IntegerInput
                min={0}
                step={1}
                precision={0}
                placeholder={placeholder}
                disabled={isDisabled}
            />
        ),
    },
    {
        predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.AMOUNT,
        handler: () => (
            <IntegerInput
                min={0}
                step={1}
                precision={2}
                placeholder={placeholder}
                disabled={isDisabled}
            />
        ),
    },
];


export function renderStandardFormControlFromConfig({
    fieldConfig,
    placeholder,
    t,
}) {
    const isDisabled = !!fieldConfig?.isDisabled;

    const handlers = [
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.BOOLEAN,
            handler: () => (
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={fieldConfig?.default_value}
                    disabled={isDisabled}
                />
            ),
        },

        ...selectRenderers({ fieldConfig, placeholder, isDisabled }),
        ...numbersRenderers({ fieldConfig, placeholder, isDisabled }),

        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.PASSWORD,
            handler: () => (
                <Input.Password
                    placeholder={placeholder}
                    disabled={isDisabled}
                />
            ),
        },

        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.URL,
            handler: () => (
                <Input
                    placeholder={placeholder}
                    // type="url"
                    disabled={isDisabled}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.EMAIL,
            handler: () => (
                <Input
                    placeholder={placeholder}
                    // type="email"
                    disabled={isDisabled}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.TEXT_MULTI_LINE,
            handler: () => (
                <Input.TextArea
                    placeholder={placeholder}
                    disabled={isDisabled}
                />
            ),
        },


        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.TEXT,
            handler: () => (
                <Input
                    placeholder={placeholder}
                    disabled={isDisabled}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.COLOR,
            handler: () => (
                <input
                    placeholder={placeholder}
                    type="color"
                    disabled={isDisabled}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.JSON,
            handler: () => (
                <Input.TextArea
                    placeholder={placeholder}
                    rows={10}
                    disabled={isDisabled}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.PHONE_NUMBER,
            handler: () => (
                <Input
                    disabled={isDisabled}
                    placeholder={placeholder || t('common:personalFields.phoneNumber.placeholder')}
                />
            ),
        },
        {
            predicate: () => fieldConfig?.fieldType === CommonFormFieldTypes.HIDDEN,
            handler: () => (
                <Input
                    type="hidden"
                    disabled
                />
            ),
        },
        {
            predicate: () => true,
            handler: () => (<span />),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export function renderStandardFormControlFromItemLabel({ fieldConfig, t }) {
    return (
        <Styled.StandardFormItemLabelWrapper>
            <Styled.StandardFormItemLabelText>
                {fieldConfig.label}
            </Styled.StandardFormItemLabelText>

            {fieldConfig.infoTooltip?.length > 0
        // TMP fix - hide test data
        && (
            fieldConfig.infoTooltip !== 'XYZ'
          && fieldConfig.infoTooltip !== 'TODO'
        )
                ? (
                    <StandardTooltip
                        overlayStyle={{ maxWidth: '350px' }}
                        placement={'right'}
                        title={(
                            <StandardTranslationWithMarkup
                                translationKey={fieldConfig.infoTooltip}
                                namespace="common"
                                t={t}
                            />
                        )}
                    >
                        <InfoCircleOutlined style={{ color: 'blue', cursor: 'help' }} />
                    </StandardTooltip>
                )
                : null}
        </Styled.StandardFormItemLabelWrapper>
    );
}


export default {
    renderStandardFormControlFromConfig,
    renderStandardFormControlFromItemLabel,
};
