import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import FetchAndViewValueButton from './FetchAndViewValueButton.layout';


const mapStateToProps = (state, ownProps) => ({ data: state[ownProps?.reducerName]?.[ownProps?.stateFieldName]?.[ownProps?.itemId] });

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(FetchAndViewValueButton);
