import StandardListTable from 'components/common/organisms/StandardListTable';
import amendTableColumnFilterValue from 'components/common/organisms/StandardListTable/StandardListTable.helpers';
import { createAddMemberPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultMembersListQueryParams } from 'redux/members/reducer';

import { PlusOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';


import { MembersListProps } from './MembersList.types';
import createTableColumns from './tableColumns';


function MembersList({
    allowInactivePartners = false,
    enforcedClientId,
    accessControl,
    isClientActive,

    listData,
    enhancedCurrentLocation,

    dispatchFetchMembers,
    dispatchClearMembersList,
    dispatchSetQueryParams,
    dispatchRequestNavigation,
    t,
}: MembersListProps) {
    // 1. context-specific / ownProps-driven list configuration
    const isPartnerActive = useMemo(
        () => isClientActive(enforcedClientId),
        [enforcedClientId],
    );

    const contextEnforcedQueryParams = useMemo(
        () => ({
            ...(enforcedClientId ? { clientId: enforcedClientId } : { clientId: undefined }),
            ...(enforcedClientId && !isPartnerActive ? { includeInactivePartners: true } : { }),
            ...(accessControl.clientConfig?.generalSettings?.membersMigrationEnabled ? { withMigrationStatus: true } : { withMigrationStatus: false }),
        }),
        [accessControl, enforcedClientId, isPartnerActive],
    );

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const { filters, ...rest } = newQueryParams;

            // list specific table filters
            const newRequestedData = {
                ...rest,
                ...amendTableColumnFilterValue('memberStatus', filters),
            };

            const prams = {
                ...listData?.queryParams,
                ...newRequestedData,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchMembers(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultMembersListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchMembers,
        dispatchClearItemsList: dispatchClearMembersList,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependent optional members list buttons
    const additionalButtons = useMemo(
        () => (accessControl.hasPermission(PN.MEMBERS.CREATE) ? [
            {
                text: 'Add Member',
                type: 'primary',
                icon: <PlusOutlined />,
                onClick: () => dispatchRequestNavigation(createAddMemberPath(enforcedClientId)),
            },
        ] : []), [dispatchRequestNavigation, accessControl],
    );

    return (
        <StandardListTable
            onUpdate={fetchData}
            allowInactivePartners={allowInactivePartners}
            rowKey="memberId"
            showClientSelect={accessControl.isSuperAdmin && !enforcedClientId}
            allowAllClients={accessControl.isSuperAdmin && !enforcedClientId}
            showIncludeInactivePartners
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createTableColumns({
                dispatchRequestNavigation,
                queryParams: listData?.queryParams,
                showPartnerColumn: !enforcedClientId,
                t,
            })}
            filtersButtonsConfig={additionalButtons}
        />
    );
}

export default MembersList;
