import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_ADD_EDIT_BENEFICIARY_FORM, CLEAR_BENEFICIARY_REQUIREMENTS,
    CREATE_BENEFICIARY,
    CREATE_BENEFICIARY_FAILURE,
    CREATE_BENEFICIARY_SUCCESS,
    FETCH_BENEFICIARY_REQUIREMENTS, FETCH_BENEFICIARY_REQUIREMENTS_FAILURE,
    FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS,
    GO_TO_PREVIOUS_ADD_EDIT_BENEFICIARY_FORM_STEP,
    INITIALISE_ADD_EDIT_BENEFICIARY_FORM,
    INITIALISE_ADD_EDIT_BENEFICIARY_FORM_SUCCESS, SET_ADD_EDIT_BENEFICIARY_FORM_STEP, SET_BENEFICIARY_IDENTIFICATION_CATEGORY, SUBMIT_ADD_EDIT_BENEFICIARY_FORM_STEP,
} from './actions.types';


export const initialiseAddEditBeneficiaryForm = createAction(
    INITIALISE_ADD_EDIT_BENEFICIARY_FORM,
    (ownerContext, formData) => ({ payload: { ownerContext, formData } }),
);

export const initialiseAddEditBeneficiaryFormSuccess = createAction(
    INITIALISE_ADD_EDIT_BENEFICIARY_FORM_SUCCESS,
    (contextData, formData) => ({ payload: { contextData, formData } }),
);

export const submitAddEditBeneficiaryFormStep = createAction(SUBMIT_ADD_EDIT_BENEFICIARY_FORM_STEP, (step, formData) => ({ payload: { step, formData } }));
export const setAddEditBeneficiaryFormStep = createAction(SET_ADD_EDIT_BENEFICIARY_FORM_STEP, (step, formData) => ({ payload: { step, formData } }));

export const goToPreviousAddEditBeneficiaryFormStep = createAction(GO_TO_PREVIOUS_ADD_EDIT_BENEFICIARY_FORM_STEP, (currentStep) => ({ payload: currentStep }));

export const fetchBeneficiaryRequirements = createAction(FETCH_BENEFICIARY_REQUIREMENTS, (queryParams) => ({ payload: queryParams }));
export const fetchBeneficiaryRequirementsSuccess = createAction(FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS, (responseData) => ({ payload: responseData }));
export const fetchBeneficiaryRequirementsFailure = createAction(FETCH_BENEFICIARY_REQUIREMENTS_FAILURE);
export const setBeneficiaryIdentificationCategory = createAction(SET_BENEFICIARY_IDENTIFICATION_CATEGORY, (category) => ({ payload: category }));
export const clearBeneficiaryRequirements = createAction(CLEAR_BENEFICIARY_REQUIREMENTS);


export const createBeneficiary = createAction(CREATE_BENEFICIARY, (formData) => ({ payload: formData }));
export const createBeneficiarySuccess = createAction(CREATE_BENEFICIARY_SUCCESS, (responseData) => ({ payload: responseData }));
export const createBeneficiaryFailure = createAction(CREATE_BENEFICIARY_FAILURE);

export const clearAddEditBeneficiaryForm = createAction(CLEAR_ADD_EDIT_BENEFICIARY_FORM);
