import PropTypes from 'prop-types';

import { StatusChangesChannelProp } from './CommonModel';
import { UserPreferencesProps } from './UserModel';


export const BusinessUserStatusChangeEntryProps = PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    company_id: PropTypes.number,
    business_user_id: PropTypes.number,
    business_user_status: PropTypes.string,
    internal_comment: PropTypes.string.isRequired,
    channel: StatusChangesChannelProp.isRequired,
    bo_user_id: PropTypes.number,
    api_user_id: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
});

export const BusinessUserStatus = Object.freeze({
    BANNED: 'BANNED',
    UNVERIFIED: 'UNVERIFIED',
    KYC_PENDING: 'KYC_PENDING',
    KYC_UPLOADED: 'KYC_UPLOADED',
    KYC_REJECTED: 'KYC_REJECTED',
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',
    CLOSED: 'CLOSED',
});

export const BusinessUserRole = Object.freeze({
    BUSINESS_USER_ADMIN: 'BUSINESS_USER_ADMIN',
    BUSINESS_USER_WRITE_ACCESS: 'BUSINESS_USER_WRITE_ACCESS',
    // BUSINESS_USER_READ_ONLY: 'BUSINESS_USER_READ_ONLY', // TMP disabled
});

export const BusinessUserProps = PropTypes.shape({
    applicant_id: PropTypes.string.isRequired,
    client_id: PropTypes.number,
    client_uuid: PropTypes.string,
    company_id: PropTypes.number.isRequired,
    company_uuid: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    business_user_status: PropTypes.oneOf(Object.values(BusinessUserStatus)),
    business_user_email: PropTypes.string.isRequired,
    business_user_uuid: PropTypes.string.isRequired,
    is_stakeholder: PropTypes.bool,
    is_permanently_locked: PropTypes.bool,
    first_name: PropTypes.string.isRequired,
    business_user_id: PropTypes.number.isRequired,
    last_name: PropTypes.string.isRequired,
    business_user_phone_number: PropTypes.string.isRequired,
    business_user_role: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    user_preferences: UserPreferencesProps,
});


export default { BusinessUserProps };
