import { FETCH_PERMISSIONS_FOR_ROLE, fetchPermissionsForRoleFailure, fetchPermissionsForRoleSuccess } from 'redux/permissions/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const onFetchPermissionsForRole = (action$, state$, { permissions }) => action$.pipe(
    ofType(FETCH_PERMISSIONS_FOR_ROLE),
    switchMap(({ payload }) => from(permissions.getPermissionsByRole(payload)).pipe(
        switchMap((response) => of(fetchPermissionsForRoleSuccess(payload, response.data))),
        catchError(() => of(fetchPermissionsForRoleFailure())),
    )),
);

export default [
    onFetchPermissionsForRole,
];
