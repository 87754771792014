import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { clearRiskScoreHistoryList, fetchRiskScoreHistoryList } from 'redux/risk-score-history/actions';
import { riskScoreHistoryListsPerLocationStoreKeyName, riskScoreHistoryReducerName } from 'redux/risk-score-history/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import RiskScoreHistoryList from './RiskScoreHistoryList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: riskScoreHistoryReducerName,
        listsByLocationStoreKeyName: riskScoreHistoryListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
    dispatchFetchRiskScoreHistoryList: (queryParams, locationPathname) => dispatch(fetchRiskScoreHistoryList(queryParams, locationPathname)),
    dispatchClearRiskScoreHistoryList: (locationPathname) => dispatch(clearRiskScoreHistoryList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: riskScoreHistoryReducerName,
        fieldName: riskScoreHistoryListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('risk-score'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(RiskScoreHistoryList);
