import LinkCardModal from 'components/common/templates/modals/LinkCardModal/LinkCardModal.layout';
import withAccessControl from 'hocs/withAccessControl';
import { fetchAccountsList } from 'redux/accounts/actions';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'redux/accounts/reducer';
import {
    fetchNotLinkedCards,
    linkCardToBusinessUser, linkCardToMember, linkCardToCompany,
    selectCardAccountForLink,
    selectCardForLink,
    setCardLinkingStep, setCardsSearchQuery, setWithPartnerCards, clearLinkCardProcess,
} from 'redux/card-linking/actions';
import { cardLinkingReducerName } from 'redux/card-linking/reducer';
import { fetchClient3DsSettings } from 'redux/client/actions';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const mapStateToProps = (state) => ({
    cardLinkingStep: state[cardLinkingReducerName].cardLinkingStep,
    notLinkedCards: state[cardLinkingReducerName].notLinkedCards,
    selectedAccount: state[cardLinkingReducerName].accountForLink,
    selectedCard: state[cardLinkingReducerName].cardForLink,
    cardSearchQuery: state[cardLinkingReducerName].cardSearchQuery,
    withPartnerCards: state[cardLinkingReducerName].withPartnerCards,
    client3DsSettings: state[cardLinkingReducerName].client3DsSettings,

    isLoadingNotLinkedCards: state[cardLinkingReducerName].isLoadingNotLinkedCards,
    isBusyLinkingCard: state[cardLinkingReducerName].isBusyLinkingCard,
    listData: getStandardListData({
        state,
        reducerName: accountsReducerName,
        listsByLocationStoreKeyName: accountsListsPerLocationStoreKeyName,
    }),
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchNotLinkedCards: (query) => dispatch(fetchNotLinkedCards(query)),
    dispatchSelectCardAccountForLink: (data) => dispatch(selectCardAccountForLink(data)),
    dispatchSelectCardForLink: (data) => dispatch(selectCardForLink(data)),
    dispatchFetchAccountsList: (queryParams, locationPathname) => dispatch(fetchAccountsList(queryParams, locationPathname)),
    dispatchSetCardLinkingStep: (step) => dispatch(setCardLinkingStep(step)),
    dispatchSetCardsSearchQuery: (search) => dispatch(setCardsSearchQuery(search)),
    dispatchSetWithPartnerCards: (value) => dispatch(setWithPartnerCards(value)),
    dispatchFetchClient3DsSettings: (clientId) => dispatch(fetchClient3DsSettings(clientId, cardLinkingReducerName)),
    dispatchSetModalProps: (data) => dispatch(setModalProps(data)),
    dispatchLinkCardToMember: (cardId, data) => dispatch(linkCardToMember(cardId, data)),
    dispatchLinkCardToBusinessUser: (cardId, data) => dispatch(linkCardToBusinessUser(cardId, data)),
    dispatchLinkCardToCompany: (cardId, data) => dispatch(linkCardToCompany(cardId, data)),
    dispatchClearLinkCardProcess: () => dispatch(clearLinkCardProcess()),
});


const decorate = compose(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(LinkCardModal);
