import { AddEditBeneficiarySteps } from 'constants/BeneficiariesModel';
import { mapCurrentStepToNumber } from 'redux/beneficiary-add/helpers';

import { Col, Steps } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


export function StepsIndicatorLayout({
    currentStep,
    isEditMode = false,
    t,
}) {
    const currentStepNumber = useMemo(
        () => mapCurrentStepToNumber(currentStep, isEditMode),
        [currentStep, isEditMode],
    );
    const stepItems = useMemo(() => [
        ...(isEditMode ? [{ title: t('Basic data') }] : []),
        { title: t('Beneficiary details') },
        { title: t('Account identifiers') },
        { title: t('Review') },
    ], [isEditMode, t]);

    return (
        <Col style={{ background: 'white', padding: 20, paddingBottom: 30 }}>
            <Steps current={currentStepNumber} items={stepItems} />
        </Col>
    );
}


StepsIndicatorLayout.propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    currentStep: PropTypes.oneOf(Object.keys(AddEditBeneficiarySteps)).isRequired,
    t: PropTypes.func,
};


export default StepsIndicatorLayout;
