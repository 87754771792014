import { Counter, QueryParams } from 'models/app/common';
import { LocationPathname } from 'models/app/navigation';

import { WebhookAction, WebhookListItem } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_WEBHOOKS_ACTION_TYPES,
    FETCH_WEBHOOKS_ACTION_TYPES_FAILURE,
    FETCH_WEBHOOKS_ACTION_TYPES_SUCCESS,
    FETCH_WEBHOOKS_LIST,
    FETCH_WEBHOOKS_LIST_FAILURE,
    FETCH_WEBHOOKS_LIST_SUCCESS,
    OPEN_REGISTER_OR_EDIT_WEBHOOK_MODAL,
    REGISTER_WEBHOOK,
    REGISTER_WEBHOOK_FAILURE,
    REGISTER_WEBHOOK_SUCCESS,
    UNREGISTER_WEBHOOK,
    UNREGISTER_WEBHOOK_FAILURE,
    UNREGISTER_WEBHOOK_SUCCESS,
    UPDATE_WEBHOOK,
    UPDATE_WEBHOOK_FAILURE,
    UPDATE_WEBHOOK_SUCCESS,
} from './actions.types';


export const fetchWebhooksList = createAction(FETCH_WEBHOOKS_LIST,
    (queryParams: QueryParams, locationPathname: LocationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchWebhooksListSuccess = createAction(FETCH_WEBHOOKS_LIST_SUCCESS,
    (items: WebhookListItem, totalCount: Counter, locationPathname: LocationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchWebhooksListFailure = createAction(FETCH_WEBHOOKS_LIST_FAILURE, (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));
export const clearWebhooksList = createAction(FETCH_WEBHOOKS_LIST_FAILURE, (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));


export const fetchWebhooksActionTypes = createAction(FETCH_WEBHOOKS_ACTION_TYPES);
export const fetchWebhooksActionTypesSuccess = createAction(FETCH_WEBHOOKS_ACTION_TYPES_SUCCESS, (responsePayload: WebhookAction[]) => ({ payload: responsePayload }));
export const fetchWebhooksActionTypesFailure = createAction(FETCH_WEBHOOKS_ACTION_TYPES_FAILURE);


export const openRegisterOrEditWebhookModal = createAction(OPEN_REGISTER_OR_EDIT_WEBHOOK_MODAL,
    (initialData, locationPathname) => ({ payload: { initialData, locationPathname } }));
export const registerWebhook = createAction(REGISTER_WEBHOOK, (formData, locationPathname) => ({ payload: { formData, locationPathname } }));
export const registerWebhookSuccess = createAction(REGISTER_WEBHOOK_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const registerWebhookFailure = createAction(REGISTER_WEBHOOK_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const unregisterWebhook = createAction(UNREGISTER_WEBHOOK, (webhookUuid, locationPathname) => ({ payload: { webhookUuid, locationPathname } }));
export const unregisterWebhookSuccess = createAction(UNREGISTER_WEBHOOK_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const unregisterWebhookFailure = createAction(UNREGISTER_WEBHOOK_FAILURE, (webhookUuid, locationPathname) => ({ payload: { webhookUuid, locationPathname } }));


export const updateWebhook = createAction(UPDATE_WEBHOOK, ({ webhookUuid, formData, locationPathname }) => ({ payload: { webhookUuid, formData, locationPathname } }));
export const updateWebhookSuccess = createAction(UPDATE_WEBHOOK_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const updateWebhookFailure = createAction(UPDATE_WEBHOOK_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
