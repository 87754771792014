import withAccessControl from 'hocs/withAccessControl';
import withIsClientActive from 'hocs/withIsClientActive';
import { setQueryParams } from 'redux/application/actions';
import { clearCompaniesList, fetchCompanies } from 'redux/companies/actions';
import { companiesListsPerLocationStoreKeyName, companiesReducerName } from 'redux/companies/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import CompaniesList from './CompaniesList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,

    listData: getStandardListData({
        state,
        reducerName: companiesReducerName,
        listsByLocationStoreKeyName: companiesListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
    dispatchFetchCompanies: (queryParams, locationPathname) => dispatch(fetchCompanies(queryParams, locationPathname)),
    dispatchClearCompaniesList: (locationPathname) => dispatch(clearCompaniesList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: companiesReducerName,
        fieldName: companiesListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('companies'),
    withIsClientActive,
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CompaniesList);
