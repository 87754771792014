import StandardTooltip from 'components/common/molecules/StandardTooltip';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import BalanceMigrationResult from 'constants/BalanceMigrationResult';
import MembersMigrationStatus from 'constants/MembersMigrationStatus';
import MembersMigrationType from 'constants/MembersMigrationType';
import ModalName from 'constants/Modals';
import { capitalizeFirstLetter } from 'utils/string-tools';

import { DownloadOutlined, DollarCircleOutlined, TeamOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MemberMigrationStatus } from '@manigo/manigo-domain-typings';
import { Button } from 'antd';
import React from 'react';


export const CSVUploadModal = ({
    enhancedCurrentLocation,
    dispatchShowModal,
    clientId,
    clientMigrationType,
}) => dispatchShowModal({
    modalType: ModalName.ADD_MIGRATION_MODAL,
    modalProps: {
        migrationType: clientMigrationType,
        title: 'Import migration file',
        okButtonProps: { style: { display: 'none' } },
        clientId,
        locationPathname: enhancedCurrentLocation.pathname,
    },
});

export const CSVDownloadModal = ({
    clientId,
    dispatchShowModal,
    enhancedCurrentLocation,
}) => dispatchShowModal({
    modalType: ModalName.DOWNLOAD_MIGRATION_REPORT_MODAL,
    modalProps: {
        title: 'Generate report',
        cancelButtonProps: { style: { display: 'none' } },
        clientId,
        locationPathname: enhancedCurrentLocation.pathname,
    },
});

export const migrationTypeRenderer = (type) => (
    <StandardTooltip title={capitalizeFirstLetter(type)}>
        {type === MembersMigrationType.BALANCES && <DollarCircleOutlined style={{ fontSize: '20px' }} /> }
        {type === MembersMigrationType.MEMBERS && <TeamOutlined style={{ fontSize: '20px' }} />}
    </StandardTooltip>
);


export const infoRenderer = (record) => (
    <>
        <StandardValue value={record.entity_failed_count} valueType={ValueTypes.COUNTER} />
        {record.migration_result && (
            <StandardTooltip title={<div key="migration-comment">{`Issue: ${record.migration_result}`}</div>}>
                <InfoCircleOutlined style={{ color: '#397dcc', fontSize: '20px' }} />
            </StandardTooltip>
        )}
    </>
);


export const downloadResultsRenderer = ({
    record,
    clientId,
    dispatchDownloadMigrationResult,
    fieldName,
    enhancedCurrentLocation,
}) => {
    const successResultStatus = record.type === MembersMigrationType.BALANCES ? BalanceMigrationResult.MIGRATED : MemberMigrationStatus.CREATED;

    return record.status !== MembersMigrationStatus.IN_PROGRESS && (
        <>
            {record?.[fieldName] > 0
        && fieldName === 'entity_failed_count'
        && (
            <StandardTooltip title="Download result CSV file containing failed records">
                <Button
                    key={`download-${MemberMigrationStatus.FAILED}-results`}
                    data-test-id={`download-${MemberMigrationStatus.FAILED}-results`}
                    shape="circle"
                    icon={<DownloadOutlined style={{ color: 'red' }} />}
                    onClick={() => dispatchDownloadMigrationResult({
                        clientId,
                        migrationId: record.id,
                        migrationStatus: MemberMigrationStatus.FAILED,
                        migrationType: record.type,
                    }, enhancedCurrentLocation.pathname)}
                />
            </StandardTooltip>
        )}

            {record?.[fieldName] > 0
        && fieldName === 'entity_created_count'
        && (
            <StandardTooltip title="Download result CSV file containing successfully processed records">
                <Button
                    key={`download-${successResultStatus}-results`}
                    data-test-id={`download-${successResultStatus}-results`}
                    shape="circle"
                    icon={<DownloadOutlined style={{ color: 'green' }} />}
                    onClick={() => dispatchDownloadMigrationResult({
                        clientId,
                        migrationId: record.id,
                        migrationStatus: successResultStatus,
                        migrationType: record.type,
                    })}
                />
            </StandardTooltip>
        )}
        </>
    );
};


export default {
    CSVUploadModal,
    CSVDownloadModal,
    migrationTypeRenderer,
    downloadResultsRenderer,
};
