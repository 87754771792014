import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';


import { showDrawer, hideDrawer } from './actions';


export const drawerReducerName = 'drawer';

const initialState = {
    visible: false,
    drawerType: null,
    drawerProps: null,
};

const handlers = {
    [showDrawer]: (state, action) => {
        state.visible = true;
        state.drawerType = action.payload.drawerType;
        state.drawerProps = action.payload.drawerProps;
    },
    [hideDrawer]: (state) => {
        state.visible = false;
        state.drawerType = null;
        state.drawerProps = null;
    },
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
