import { addClientRiskDetails } from 'redux/client/actions';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { industriesListsPerLocationStoreKeyName, industriesReducerName } from 'redux/industries/reducer';
import { setModalProps } from 'redux/modal/actions';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ChangeRiskScoreModal from './ChangeClientRiskScoreModal.layout';


const mapStateToProps = (state) => ({
    // add risk details

    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    industries: getStandardListData({
        state,
        reducerName: industriesReducerName,
        listsByLocationStoreKeyName: industriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});


const mapDispatchToProps = (dispatch) => ({
    dispatchAddClientRiskDetails: (clientId, formData) => dispatch(addClientRiskDetails(clientId, formData)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});


const decorate = compose(
    withTranslation('clients'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ChangeRiskScoreModal);

