import { standardPageSize } from 'config/config';
import { SortDirections } from 'constants/ApplicationStateModel';
import { globalLocation } from 'constants/NavigationModel';
import { setQueryParams } from 'redux/application/actions';
import {
    fetchClientsList,
    fetchClientsListSuccess,
    fetchClientsListFailure, clearClientsList,
} from 'redux/clients/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withSkipImages, withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const clientsReducerName = 'clients';
export const clientsListsPerLocationStoreKeyName = 'clientsListsPerLocation';

export const defaultClientsListQueryParams = {
    limit: standardPageSize,
    enforcedLimit: 2000, // XXX for API
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
    ...withSkipImages,
};

const defaultListState = { [clientsListsPerLocationStoreKeyName]: { [globalLocation]: { queryParams: defaultClientsListQueryParams } } };

const initialState = { ...defaultListState };

const handlers = {

    [fetchClientsList]: (state, action) => {
        state[clientsListsPerLocationStoreKeyName] = updateStandardLists(state[clientsListsPerLocationStoreKeyName], action);
    },
    [fetchClientsListSuccess]: (state, action) => {
        state[clientsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[clientsListsPerLocationStoreKeyName], action);
    },
    [fetchClientsListFailure]: (state, action) => {
        state[clientsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[clientsListsPerLocationStoreKeyName], action);
    },
    [clearClientsList]: (state, action) => {
        state[clientsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[clientsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === clientsReducerName
      && action.payload?.fieldName === clientsListsPerLocationStoreKeyName
        ) {
            state[clientsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[clientsListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
