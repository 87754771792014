import StandardTabsConnectedToUrl from 'components/common/organisms/StandardTabsConnectedToUrl';
import { extractActiveTabKeysFromParams } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { StandardTabConfigProps } from 'constants/ApplicationStateModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { handleURLSegmentsAndActiveTab } from './StandardEntityDetailsWithTabs.helpers';


function StandardEntityDetailsWithTabs({
    accessControl,

    currentUserClientId,
    entityClientId,
    // clientIdContext,

    pageHeadingTitle,
    children,
    tabsConfig,
    dispatchRequestNavigation,
    enhancedCurrentLocation,
    baseURLSegmentsCount = 2, // e.g. /client/1 - > ['client', '1'] -> 2,
    dispatchSetClientIdContext,
    dispatchClearClientIdContext,
}) {
    // XXX Super admin client context ON / OFF
    const checkContext = () => {
        if (accessControl.isSuperAdmin // XXX allowed - client-context related
      && entityClientId > 0
      && currentUserClientId > 0
      && entityClientId !== currentUserClientId
        ) {
            dispatchSetClientIdContext(entityClientId);
        }
    };

    useEffect(() => {
        checkContext();

        return () => {
            dispatchClearClientIdContext();
        };
    }, []);

    useEffect(() => {
        checkContext();
    }, [entityClientId, currentUserClientId]);


    const [isScreenReady, setIsScreenReady] = useState(false);

    const requestedTabKeyParts = useMemo(
        () => extractActiveTabKeysFromParams(enhancedCurrentLocation, baseURLSegmentsCount),
        [enhancedCurrentLocation],
    );

    useEffect(() => {
        handleURLSegmentsAndActiveTab({
            enhancedCurrentLocation,
            requestedTabKeyParts,
            tabsConfig,
            dispatchRequestNavigation,
            baseURLSegmentsCount,
            isScreenReady,
            setIsScreenReady,
        });
    }, [tabsConfig, isScreenReady, requestedTabKeyParts]);

    return (
        <>
            <StandardPageHeading title={pageHeadingTitle} activeTabs={requestedTabKeyParts} />

            <StandardPageWrapper>
                <div className="standardEntityDetailsWithTabs-headingAndStandardTabsConnectedToUrl-wrapper">
                    {children}

                    <StandardTabsConnectedToUrl
                        isSubTab={false}
                        destroyInactiveTabPane
                        baseURLSegmentsCount={baseURLSegmentsCount}
                        tabsConfig={tabsConfig}
                        activeMainTab={requestedTabKeyParts?.[0]}
                        activeSubTab={requestedTabKeyParts?.[1]}
                        enhancedCurrentLocation={enhancedCurrentLocation}
                        dispatchRequestNavigation={dispatchRequestNavigation}
                    />

                </div>
            </StandardPageWrapper>
        </>
    );
}

StandardEntityDetailsWithTabs.propTypes = {
    tabsConfig: StandardTabConfigProps,
    baseURLSegmentsCount: PropTypes.number,
    currentUserClientId: PropTypes.number,
    entityClientId: PropTypes.number,
    // clientIdContext: PropTypes.number,
    pageHeadingTitle: PropTypes.string,
    isValidEntity: PropTypes.shape({
        type: PropTypes.string, // feeUuid, beneficiaryUuid, promotionUuid, clientId, groupId, member ect
        isValid: PropTypes.bool,
    }),
    children: PropTypes.node,
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchSetClientIdContext: PropTypes.func.isRequired,
    dispatchClearClientIdContext: PropTypes.func.isRequired,
};

export default StandardEntityDetailsWithTabs;
