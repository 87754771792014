import withAccessControl from 'hocs/withAccessControl';
import { clearClientIdContext, setClientIdContext } from 'redux/client-context/actions';
import { addCompany } from 'redux/companies/actions';
import { companiesReducerName } from 'redux/companies/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { requestSetMultiStepsFormStep } from 'redux/multi-steps-form/actions';
import { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddCompanyForm from './AddCompanyForm.layout';


const mapStateToProps = (state, ownProps) => {
    const { locationPathname } = ownProps;
    const currentMultiStepsFormStep = state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormStep;

    return {
        currentUserClientId: state[currentUserReducerName].userData?.clientId,
        isLoadingAddCompany: state[companiesReducerName]?.isLoadingAddCompany,
        // standard multi step form related
        availableSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSteps,
        currentMultiStepsFormStep,
        newlyCreatedCompanyData: state[companiesReducerName]?.newlyCreatedCompanyData,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchSetClientIdContext: (clientId) => dispatch(setClientIdContext(clientId)),
    dispatchClearClientIdContext: () => dispatch(clearClientIdContext()),
    dispatchAddCompany: (requestData, locationPathname) => dispatch(addCompany(requestData, locationPathname)),
    // standard multi step form related
    dispatchRequestSetMultiStepsFormStep: (currentStep, direction) => dispatch(requestSetMultiStepsFormStep(
        currentStep,
        direction,
        ownProps?.locationPathname,
    )),
});

const decorate = compose(
    withTranslation('roles'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddCompanyForm);
