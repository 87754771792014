import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';

import { Col, Row } from 'antd';
import React from 'react';

import { createLimitBaseFieldsConfig, createLimitBlockedFieldsConfig, createLimitDetailsFieldsConfig } from './LimitInformation.helpers';
import { LimitInformationProps } from './LimitInformation.types';


function LimitInformation({
    t,

    isLoading,
    onRefresh,
    queryParams,
    queryParamsMeta,
    limitDetails,
}: LimitInformationProps) {
    return (
        <Col
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={isLoading}
                minHeight={'280px'}
                sectionsRowsCount={8}
                sectionsCount={3}
            >
                <DetailsSectionsWrapper>
                    <Row gutter={24}>

                        <CommonSection
                            sectionLabel={'System information'}
                            fieldsConfig={createLimitBaseFieldsConfig({ t, limitDetails })}
                        />

                        <CommonSection
                            sectionLabel={'Limit details'}
                            fieldsConfig={createLimitDetailsFieldsConfig({ t, limitDetails })}
                        />

                        <CommonSection
                            sectionLabel={'Limit restrictions'}
                            fieldsConfig={createLimitBlockedFieldsConfig({ t, limitDetails })}
                        />
                    </Row>


                    <QueryParamsMetaInfo
                        detailsContext
                        onListRefresh={onRefresh}
                        queryParams={queryParams}
                        queryParamsMeta={queryParamsMeta}
                    />
                </DetailsSectionsWrapper>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}

export default LimitInformation;
