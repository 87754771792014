import withAccessControl from 'hocs/withAccessControl';
import withIsClientActive from 'hocs/withIsClientActive';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { clearMembersList, fetchMembers } from 'redux/members/actions';
import { membersListsPerLocationStoreKeyName, membersReducerName } from 'redux/members/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import MembersList from './MembersList.layout';
import { MembersListDispatchProps, MembersListOwnProps, MembersListStateProps } from './MembersList.types';


const mapStateToProps = (state: RootState): MembersListStateProps => ({
    clients: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: membersReducerName,
        listsByLocationStoreKeyName: membersListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch: Dispatch): MembersListDispatchProps => ({
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchFetchMembers: (queryParams, locationPathname) => dispatch(fetchMembers(queryParams, locationPathname)),
    dispatchClearMembersList: (locationPathname) => dispatch(clearMembersList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: membersReducerName,
        fieldName: membersListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose<ComponentType<MembersListOwnProps>>(
    withTranslation('member'),
    withAccessControl,
    withIsClientActive,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(MembersList);
