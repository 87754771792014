import { CardOrderTarget } from 'constants/CardModel';
import noop from 'utils/function-tools';


export const cardDetailsFields = [
    'card_type',
    'delivery_type',
    'delivery_method',
    'product_type',
    'carrier_ref',
    'envelope_ref',
    'card_image',
    'issuing_country',
    'units',
    'embossNames',
    'acsAuthMethod',
    'acsAuthManager',
];

export const addressFields = [
    'address_line_1',
    'address_line_2',
    'zip_code',
    'first_name',
    'last_name',
    'email',
    'city',
    'country',
];


export const createTargetChangeFormActions = (targetType, formInstance) => {
    const handlers = [
        {
            predicate: () => targetType === CardOrderTarget.MEMBERS,
            handler: () => formInstance.resetFields(['company_id', ...cardDetailsFields, ...addressFields]),
        },
        {
            predicate: () => targetType === CardOrderTarget.COMPANIES,
            handler: () => formInstance.resetFields(['member_id', ...cardDetailsFields, ...addressFields]),
        },
        {
            predicate: () => targetType === CardOrderTarget.CLIENTS,
            handler: () => formInstance.resetFields(['member_id', 'company_id', ...cardDetailsFields, ...addressFields]),
        },
        {
            predicate: () => true,
            handler: () => noop,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
