import { Epic } from 'models/meta/epic';
import { sortStringDates } from 'utils/table-columns-sorters';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { FETCH_TRANSACTION_STATUS_HISTORY } from './action.types';
import { fetchTransactionStatusHistoryFailure, fetchTransactionStatusHistorySuccess } from './actions';


export const onFetchTransactionStatusHistory: Epic = (action$, state$, { transactions }) => action$.pipe(
    ofType(FETCH_TRANSACTION_STATUS_HISTORY),
    switchMap(({ payload }) => from(transactions.getTransactionStatusHistory(payload.queryParams.transactionUuid)).pipe(
        switchMap((response) => of(fetchTransactionStatusHistorySuccess(
            (response?.data?.rows || []).sort((a, b) => sortStringDates(a, b, 'createdAt')),
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchTransactionStatusHistoryFailure(payload.locationPathname))),
    )),
);


export default [
    onFetchTransactionStatusHistory,
];
