import CopyrightNotice from 'components/common/molecules/CopyrightNotice';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import AppVersionInfo from 'components/layouts/AuthorisedLayout/SideBar/AppVersionInfo';
import { getApplicationEnvironment, WL_CONFIG } from 'config/config';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';


import Styled from './SideBar.styled';
import { SideBarProps } from './SideBar.types';
import createSideBarMenuItems from './sidebarData';


function SideBar({
    accessControl,
    dispatchRequestNavigation,
    clientId,
    isSidebarCollapsed,
    dispatchToggleSidebar,
    t,
}: SideBarProps) {
    const { pathname } = useLocation();

    const menuData = useMemo(
        () => createSideBarMenuItems(clientId, t),
        [clientId],
    );
    const sidebarCollapseProps = {
        className: 'trigger',
        onClick: () => dispatchToggleSidebar(),
    };

    const currentlySelectedKey = useMemo(
        () => {
            const tmp = Object.values(menuData).find((menuItem) => {
                const urlPath = pathname.split('/')[1];
                const urlPathWithoutPlural = urlPath.slice(0, -1);

                return menuItem.to.includes(urlPath) || menuItem.to.includes(urlPathWithoutPlural);
            });
            return tmp?.key || '1';
        },
        [pathname, menuData],
    );

    const environmentName = getApplicationEnvironment();

    const onMenuItemClick = (key) => dispatchRequestNavigation(`${menuData[key].to}`);


    const createMenuItems = (accessControl) => {
        const createItem = (item) => ({
            label: (
                <>
                    <item.Icon />
                    <Styled.MenuItemText>{item.title}</Styled.MenuItemText>
                </>
            ),
            key: item.key,
        });

        const createItemRenderer = (item) => {
            const handlers = [
                {
                    predicate: () => item.hideForSuperAdmin && accessControl.isSuperAdmin, // XXX allowed - side barn menu item display
                    handler: () => null,
                },
                {
                    predicate: () => item.requiredSuperAdmin && !accessControl.isSuperAdmin, // XXX allowed - side barn menu item display
                    handler: () => null,
                },
                {
                    predicate: () => item.requiredPermissions && !accessControl.hasPermissions(item.requiredPermissions),
                    handler: () => null,
                },
                {
                    predicate: () => true,
                    handler: () => createItem(item),
                },
            ];

            return handlers.filter(({ predicate }) => predicate())[0].handler();
        };

        return Object.keys(menuData).map((key) => {
            const item = menuData[key];
            return createItemRenderer(item);
        });
    };

    return (
        <Styled.SideBarWrapper $collapsed={isSidebarCollapsed}>
            <Styled.CollapseSidebarTriggerWrapper>
                <StandardTooltip
                    placement="bottomRight"
                    title={`Click to ${isSidebarCollapsed ? 'show' : 'hide'} sidebar`}
                >
                    {isSidebarCollapsed ? (
                        <MenuUnfoldOutlined {...sidebarCollapseProps} />
                    ) : (
                        <MenuFoldOutlined {...sidebarCollapseProps} />
                    )}
                </StandardTooltip>
            </Styled.CollapseSidebarTriggerWrapper>

            <Styled.SideBarContent className={isSidebarCollapsed ? 'collapsed' : undefined}>
                <div className="app-logo-header">
                    {WL_CONFIG?.brandSignetLogoUrl
                        ? (
                            <Styled.SideBarLogo
                                src={WL_CONFIG?.brandSignetLogoUrl}
                                alt={`${WL_CONFIG?.brandFullName} signet logo`}
                                title={`${WL_CONFIG?.brandFullName} signet logo`}
                            />
                        )
                        : <Styled.AppName>{WL_CONFIG?.applicationName}</Styled.AppName>}

                    <AppVersionInfo t={t} environmentName={environmentName} />
                </div>

                <Menu
                    defaultSelectedKeys={[currentlySelectedKey]}
                    selectedKeys={[currentlySelectedKey]}
                    onClick={(data) => onMenuItemClick(data.key)}
                    items={createMenuItems(accessControl)}
                />

                <Styled.SideBarFooter>
                    <CopyrightNotice />
                </Styled.SideBarFooter>

            </Styled.SideBarContent>
        </Styled.SideBarWrapper>
    );
}

export default SideBar;
