import { standardPageSize } from 'config/config';
import { clientRootRoutePath, memberRootRoutePath, topupCardsRouteSegmentPath } from 'config/routes';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchTopupListSuccess,
    fetchTopupListFailure,
    fetchTopupList,
    clearTopupList,
} from './actions';


export const topupReducerName = 'topup';
export const topupListsPerLocationStoreKeyName = 'topupListsPerLocation';


export const defaultTopupListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: undefined,
    sortOrder: undefined,

    memberId: undefined,
    clientId: undefined,
    businessUserId: undefined,
};


const initialState = {
    [topupListsPerLocationStoreKeyName]: {},
    // XXX 'pathname': {...standard }
};


const handlers = {
    [fetchTopupList]: (state, action) => {
        state[topupListsPerLocationStoreKeyName] = updateStandardLists(state[topupListsPerLocationStoreKeyName], action);
    },
    [fetchTopupListSuccess]: (state, action) => {
        state[topupListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[topupListsPerLocationStoreKeyName], action);
    },
    [fetchTopupListFailure]: (state, action) => {
        state[topupListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[topupListsPerLocationStoreKeyName], action);
    },
    [clearTopupList]: (state, action) => {
        state[topupListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[topupListsPerLocationStoreKeyName], action.payload.locationPathname);
    },

    [preformNavigationSuccess]: (state, action) => {
        state[topupListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[topupListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [topupCardsRouteSegmentPath],
                observedRootPaths: [memberRootRoutePath, clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === topupReducerName) {
            if (action.payload?.fieldName === topupListsPerLocationStoreKeyName) {
                state[topupListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[topupListsPerLocationStoreKeyName] }, action);
            }
        }
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
