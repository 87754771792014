import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_BUSINESS_USER_STATUS_HISTORY_LIST,
    FETCH_BUSINESS_USER_STATUS_HISTORY_LIST_FAILURE,
    FETCH_BUSINESS_USER_STATUS_HISTORY_LIST_SUCCESS,
    CLEAR_BUSINESS_USER_STATUS_LIST_HISTORY,
    UPDATE_BUSINESS_USER_STATUS,
    UPDATE_BUSINESS_USER_STATUS_SUCCESS,
    UPDATE_BUSINESS_USER_STATUS_FAILURE,
    CLEAR_RESET_FORM,
} from './actions.types';


export const fetchBusinessUserStatusHistoryList = createAction(FETCH_BUSINESS_USER_STATUS_HISTORY_LIST,
    (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchBusinessUserStatusHistoryListSuccess = createAction(FETCH_BUSINESS_USER_STATUS_HISTORY_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchBusinessUserStatusHistoryListFailure = createAction(FETCH_BUSINESS_USER_STATUS_HISTORY_LIST_FAILURE);
export const clearBusinessUserStatusHistoryList = createAction(CLEAR_BUSINESS_USER_STATUS_LIST_HISTORY, (locationPathname) => ({ payload: { locationPathname } }));

export const updateBusinessUserStatus = createAction(UPDATE_BUSINESS_USER_STATUS,
    (businessUserId, requestPayload, locationPathname) => ({ payload: { businessUserId, requestPayload, locationPathname } }));
export const updateBusinessUserStatusSuccess = createAction(UPDATE_BUSINESS_USER_STATUS_SUCCESS);
export const updateBusinessUserStatusFailure = createAction(UPDATE_BUSINESS_USER_STATUS_FAILURE);

export const clearResetForm = createAction(CLEAR_RESET_FORM);
