import { addMembersRouteSegmentPath, clientRouteSegmentPath, groupRootRoutePath, membersRouteSegmentPath } from 'config/routes';
import { createClientDetailsGroupsListPath, createGroupDetailsMembersListPath, createGroupDetailsPath } from 'config/routes.helpers';
import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import {
    ADD_MEMBER_TO_GROUP,
    ASSIGN_PROMOTIONS_TO_GROUP, CLEANUP_ADD_MEMBER_TO_GROUP_METADATA, DELETE_GROUP,
    FETCH_GROUP_DETAILS,
    OPEN_ASSIGN_PROMOTIONS_TO_GROUP_MODAL, OPEN_DELETE_GROUP_MODAL,
    OPEN_EDIT_GROUP_NAME_MODAL, OPEN_REASSIGN_MEMBER_GROUP,
    REASSIGN_MEMBER_GROUP,
    UPDATE_GROUP_NAME,
} from 'redux/group/actions.types';
import { groupDetailsPerLocationStoreKeyName, groupReducerName } from 'redux/group/reducer';
import { fetchGroupMembers } from 'redux/group-members/actions';
import { groupMembersListsPerLocationStoreKeyName, groupMembersReducerName } from 'redux/group-members/reducer';
import { fetchGroupsList } from 'redux/groups/actions';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';
import { PREFORM_NAVIGATION_BACK_SUCCESS, REQUEST_NAVIGATION, REQUEST_NAVIGATION_BACK, requestNavigationBack, unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { removePromotionFailure, removePromotionSuccess } from 'redux/promotion/actions';
import { REMOVE_PROMOTION_FROM_GROUP } from 'redux/promotion/actions.types';
import { fetchPromotionsList } from 'redux/promotions/actions';
import { promotionsListsPerLocationStoreKeyName, promotionsReducerName } from 'redux/promotions/reducer';

import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import {
    reassignMemberGroupFailure,
    fetchGroupDetailsSuccess, fetchGroupDetailsFailure,
    fetchGroupDetails,
    assignPromotionsSuccess, assignPromotionsFailure,
    deleteGroupFailure, deleteGroupSuccess,
    addMemberToGroupSuccess, addMemberToGroupFailure,
    updateGroupNameSuccess, updateGroupNameFailure, cleanupAddMemberToGroupMetadata,
} from './actions';
import { createDeleteGroupSuccessMessage, createOnSuccessReassignMemberGroupActions } from './epic.helpers';


export const onFetchGroupDetails = (action$, state$, { groups }) => action$.pipe(
    ofType(FETCH_GROUP_DETAILS),
    switchMap(({ payload }) => from(groups.fetchGroupDetails(payload?.queryParams?.groupId)).pipe(
        switchMap((response) => of(fetchGroupDetailsSuccess(response.data, payload.locationPathname))),
        catchError(() => of(fetchGroupDetailsFailure(payload.locationPathname))),
    )),
);

export const onOpenReassignMemberGroupModal = (action$, _, { i18n }) => action$.pipe(
    ofType(OPEN_REASSIGN_MEMBER_GROUP),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.REASSIGN_MEMBER_GROUP,
            modalProps: {
                title: i18n.t('member:modals.reassign.heading'),
                currentGroup: payload?.initialData?.currentGroup,
                memberClientId: payload?.initialData?.memberClientId,
                memberId: payload.initialData?.memberId,
                locationPathname: payload?.locationPathname,
            },
        }),
    )),
);


export const onReassignMemberGroup = (action$, state$, { groups, i18n }) => action$.pipe(
    ofType(REASSIGN_MEMBER_GROUP),
    switchMap(({ payload }) => from(groups.reassignMemberGroup(payload?.requestPayload?.memberId, payload?.requestPayload?.groupId)).pipe(
        switchMap(() => of(...createOnSuccessReassignMemberGroupActions({ i18n, state$, payload }))),
        catchError(() => of(
            reassignMemberGroupFailure(payload.locationPathname),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);


export const onOpenEditGroupNameModal = (action$, _, { i18n }) => action$.pipe(
    ofType(OPEN_EDIT_GROUP_NAME_MODAL),
    switchMap(({ payload: { clientId, groupId, locationPathname } }) => of(
        showModal({
            modalType: ModalName.EDIT_GROUP_NAME,
            modalProps: {
                title: i18n.t('groups:modals.createEditGroup.titleEdit'),
                isEditMode: true,
                initialValues: {
                    groupId,
                    clientId,
                    locationPathname,
                },
            },
        }),
    )),
);


export const onUpdateGroupName = (action$, state$, { groups, i18n }) => action$.pipe(
    ofType(UPDATE_GROUP_NAME),
    switchMap(({ payload }) => from(groups.editGroupName(payload.groupId, payload.newGroupName)).pipe(
        switchMap(() => {
            const clientGroupsLocation = createClientDetailsGroupsListPath(payload.clientId);
            const currentQueryParams = state$.value[groupsReducerName][groupsListsPerLocationStoreKeyName]?.[clientGroupsLocation]?.queryParams;
            return of(
                updateGroupNameSuccess(payload.locationPathname),
                fetchGroupDetails({ groupId: payload.groupId }, createGroupDetailsPath(payload.clientId, payload.groupId)),
                fetchGroupsList(currentQueryParams, clientGroupsLocation),
                showSuccessToast(i18n.t('groups:actionMessages.editGroupNameSuccess')),
                hideModal(),
            );
        }),
        catchError(() => of(
            updateGroupNameFailure(payload.locationPathname),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);


export const onOpenAssignPromotionsToGroupModal = (action$, _, { i18n }) => action$.pipe(
    ofType(OPEN_ASSIGN_PROMOTIONS_TO_GROUP_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.ADD_PROMOTION_TO_GROUP,
            modalProps: {
                title: i18n.t('groups:modals.addPromotionsToGroup.title'),
                clientId: payload?.initialData?.clientId,
                groupId: payload?.initialData?.groupId,
                existingPromotionsUuids: payload?.initialData?.existingPromotionsUuids,
                locationPathname: payload?.locationPathname,
            },
        }),
    )),
);


export const onAssignPromotionToGroup = (action$, state$, { groups, i18n }) => action$.pipe(
    ofType(ASSIGN_PROMOTIONS_TO_GROUP),
    switchMap(({ payload }) => from(groups.assignPromotionsToGroup(payload?.requestPayload?.groupId, payload?.requestPayload?.promotionUuids)).pipe(
        switchMap(() => {
            const currentQueryParams = state$.value[promotionsReducerName][promotionsListsPerLocationStoreKeyName]?.[payload?.locationPathname]?.queryParams;
            const groupDetailsPath = createGroupDetailsPath(payload?.requestPayload?.clientId, payload?.requestPayload?.groupId);
            const currentGroupDetailsQueryParams = state$.value[groupReducerName][groupDetailsPerLocationStoreKeyName]?.[groupDetailsPath]?.queryParams;

            return of(
                assignPromotionsSuccess(payload.locationPathname),
                fetchGroupDetails(currentGroupDetailsQueryParams, groupDetailsPath),
                fetchPromotionsList(currentQueryParams, payload.locationPathname),
                showSuccessToast(i18n.t('groups:actionMessages.addPromotionsToGroupSuccess')),
                hideModal(),
            );
        }),
        catchError(() => of(
            assignPromotionsFailure(payload.locationPathname),
            setModalProps({ confirmLoading: false, cancelButtonProps: { disabled: false } }),
        )),
    )),
);


export const onRemovePromotionFromGroup = (action$, state$, { groups, i18n }) => action$.pipe(
    ofType(REMOVE_PROMOTION_FROM_GROUP),
    switchMap(({ payload }) => from(groups.removePromotionFromGroup(payload.queryParams)).pipe(
        switchMap(() => {
            const currentQueryParams = state$.value[promotionsReducerName][promotionsListsPerLocationStoreKeyName]?.[payload?.locationPathname]?.queryParams;
            return of(
                showSuccessToast(i18n.t('groups:actionMessages.removePromotionFromGroupSuccess')),
                fetchPromotionsList(currentQueryParams, payload.locationPathname),
                removePromotionSuccess(payload.locationPathname),
            );
        }),
        catchError(() => of(removePromotionFailure(payload.locationPathname))),
    )),
);


export const onDeleteGroupModal = (action$, _, { i18n }) => action$.pipe(
    ofType(OPEN_DELETE_GROUP_MODAL),
    switchMap(({ payload: { groupDetails } }) => of(
        showModal({
            modalType: ModalName.DELETE_GROUP,
            modalProps: {
                title: i18n.t('groups:modals.deleteGroup.title'),
                initialValues: groupDetails,
            },
        }),
    )),
);


export const onDeleteGroup = (action$, state$, { i18n, groups }) => action$.pipe(
    ofType(DELETE_GROUP),
    switchMap(({ payload, meta }) => from(groups.deleteGroup(payload.groupId, payload.newGroupId)).pipe(
        switchMap(() => {
            // TODO warp this in service
            const t = i18n.t.bind(i18n);
            const clientGroupsLocation = createClientDetailsGroupsListPath(meta.clientId);
            const currentQueryParams = state$.value[groupsReducerName][groupsListsPerLocationStoreKeyName]?.[clientGroupsLocation]?.queryParams;

            return of(
                fetchGroupsList(currentQueryParams, clientGroupsLocation),
                hideModal(),
                deleteGroupSuccess(),
                unblockNavigation(),
                requestNavigationBack(),
                showSuccessToast(createDeleteGroupSuccessMessage(t, meta, payload.newGroupId)),
            );
        }),
        catchError(() => of(
            deleteGroupFailure(),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);


export const onAddMemberToGroup = (action$, state$, { groups, i18n }) => action$.pipe(
    ofType(ADD_MEMBER_TO_GROUP),
    mergeMap(({ payload: { memberId, groupId, clientId } }) => from(groups.reassignMemberGroup(memberId, groupId)).pipe(
        switchMap(() => of(
            addMemberToGroupSuccess({ memberId, groupId, clientId }),
            showSuccessToast(i18n.t('groups:actionMessages.addMemberToGroupSuccess')),
        )),
        catchError(() => of(addMemberToGroupFailure(memberId))),
    )),
);

export const onLeavingAddMemberToGroupPage = (action$, state$) => action$.pipe(
    ofType(REQUEST_NAVIGATION, REQUEST_NAVIGATION_BACK, PREFORM_NAVIGATION_BACK_SUCCESS),
    mergeMap(({ payload, type }) => {
        const currentLocation = state$.value[navigationReducerName]?.enhancedCurrentLocation;
        const membersAdded = state$.value[groupReducerName].idsOfMembersSuccessfullyAddedToTheGroup;
        const groupId = state$.value[groupReducerName].idOfGroupToWhichMembersAreBeingAdded;
        const clientId = state$.value[groupReducerName].idOfClientWhoOwnsGroupToWhichMembersAreBeingAdded;
        const regularExpression = type === PREFORM_NAVIGATION_BACK_SUCCESS
            ? new RegExp(`^${groupRootRoutePath}/.*/${clientRouteSegmentPath}/.*/${membersRouteSegmentPath}$`, 'g')
            : new RegExp(`^${groupRootRoutePath}/.*/${addMembersRouteSegmentPath}$`, 'g');

        if (!membersAdded?.length || !currentLocation?.pathname.match(regularExpression)) {
            return EMPTY;
        }

        // XXX any navigation AWAY FROM 'add members to group page' but not when entering it
        if (type === PREFORM_NAVIGATION_BACK_SUCCESS || !(payload || '').match(regularExpression)) {
            return of(cleanupAddMemberToGroupMetadata({ groupId, clientId, membersAdded }));
        }

        return EMPTY;
    }),
);

export const onCleanupAddMemberToGroupMetadata = (action$, state$) => action$.pipe(
    ofType(CLEANUP_ADD_MEMBER_TO_GROUP_METADATA),
    switchMap(({ payload: { groupId, clientId, membersAdded } }) => {
        if (membersAdded && membersAdded.length > 0) {
            // eslint-disable-next-line max-len
            const currentGroupMembersListQueryParams = state$.value[groupMembersReducerName][groupMembersListsPerLocationStoreKeyName]?.[createGroupDetailsMembersListPath(clientId, groupId)]?.queryParams;
            // eslint-disable-next-line max-len
            const currentClientGroupsListQueryParams = state$.value[groupsReducerName][groupsListsPerLocationStoreKeyName]?.[createClientDetailsGroupsListPath(clientId)]?.queryParams;

            return of(
                fetchGroupDetails({ groupId }, createGroupDetailsPath(clientId, groupId)),
                fetchGroupMembers({ ...currentGroupMembersListQueryParams, groupId }, createGroupDetailsMembersListPath(clientId, groupId)),
                fetchGroupsList({ ...currentClientGroupsListQueryParams, clientId }, createClientDetailsGroupsListPath(clientId)),
            );
        }
        return EMPTY;
    }),
    catchError(() => of(addMemberToGroupFailure())),
);


export default [
    onOpenReassignMemberGroupModal,
    onReassignMemberGroup,
    onOpenEditGroupNameModal,
    onUpdateGroupName,
    onFetchGroupDetails,
    onOpenAssignPromotionsToGroupModal,
    onAssignPromotionToGroup,
    onRemovePromotionFromGroup,
    onDeleteGroupModal,
    onDeleteGroup,
    onAddMemberToGroup,
    onCleanupAddMemberToGroupMetadata,
];
