import styled, { css } from 'styled-components';


const CardImageWrapper = styled.div`
  text-align: center;
  min-height: 251px;
`;

const CardImage = styled.img`
  border-radius: 15px;
  width: 400px;
  
  ${({ isInReadOnlyMode = false }) => isInReadOnlyMode && css`
    filter: grayscale(1);
  `};
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export default {
    CardImageWrapper,
    CardImage,
    DeleteButtonWrapper,
};
