import { standardPageSize } from 'config/config';
import { clientRootRoutePath, membersRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    fetchMembers, fetchMembersFailure, fetchMembersSuccess,
    createMember, createMemberFailure, createMemberSuccess, setCreateMemberFormStep, setCreateMemberFormData, clearMembersList,
} from 'redux/members/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists, updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const membersReducerName = 'members';
export const membersListsPerLocationStoreKeyName = 'membersListsPerLocationStoreKey';


export const defaultMembersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    clientId: undefined,
    sortBy: 'memberId', // backend validation doesn't allow camelCase
    status: undefined,
    includeInactivePartners: false,
    sortOrder: SortDirections.DESCEND,
    ...withTotalCount,
};

const initialState = {
    [membersListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }

    createMemberFormStep: 0,
    isLoadingCreateMember: false,
    createMemberFormData: undefined,
};

const handlers = {
    [fetchMembers]: (state, action) => {
        state[membersListsPerLocationStoreKeyName] = updateStandardLists(state[membersListsPerLocationStoreKeyName], action);
    },
    [fetchMembersSuccess]: (state, action) => {
        state[membersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[membersListsPerLocationStoreKeyName], action);
    },
    [fetchMembersFailure]: (state, action) => {
        state[membersListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[membersListsPerLocationStoreKeyName], action);
    },
    [clearMembersList]: (state, action) => {
        state[membersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[membersListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[membersListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[membersListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [membersRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === membersReducerName
      && action.payload?.fieldName === membersListsPerLocationStoreKeyName
        ) {
            state[membersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[membersListsPerLocationStoreKeyName] }, action);
        }
    },

    // Add Member
    [createMember]: (state) => {
        state.isLoadingCreateMember = true;
    },

    [createMemberSuccess]: (state) => {
        state.isLoadingCreateMember = false;
    },

    [createMemberFailure]: (state) => {
        state.isLoadingCreateMember = false;
    },

    [setCreateMemberFormStep]: (state, action) => {
        state.createMemberFormStep = action.payload;
    },
    [setCreateMemberFormData]: (state, action) => {
        state.createMemberFormData = action.payload;
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
