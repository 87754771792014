import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { isoDateFormat, standardDateFormat } from 'config/config';
import { isoDateOnlyStringToDate } from 'utils/date-time-tools';
import { formatPhoneNumber } from 'utils/phone-number-tools';
import { commonValidation, isValidFirstNameRule, isValidLastNameRule } from 'utils/validation-tools';

import { DatePicker, Form, Input, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';


import { EditMemberModalProps } from './EditMemberModal.types';


function EditMemberModal({
    accessControl,
    dispatchSetModalProps,
    dispatchUpdateMember,
    enhancedCurrentLocation,
    initialValues,
    data,
    userPreferences,
    t,
}: EditMemberModalProps) {
    const [form] = Form.useForm();

    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);

    const format = useMemo(
        () => userPreferences?.dateFormat || standardDateFormat,
        [userPreferences],
    );

    const date = isoDateOnlyStringToDate(data ? data?.memberDateOfBirth : initialValues?.memberDateOfBirth);

    const onFinish = (formValues) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        const { memberFirstName, memberLastName, memberPhoneNumber, memberStatus, memberDateOfBirth, ...restOfFormValues } = formValues;
        const request = {
            ...restOfFormValues,
            ...(!accessControl.isKycKybApprovalEnabled() ? { memberFirstName, memberLastName } : {}),
            memberPhoneNumber: formatPhoneNumber(memberPhoneNumber, 'E.164'),
            ...(accessControl.isKycKybApprovalEnabled() ? { } : { memberDateOfBirth: `${formValues.memberDateOfBirth.format(isoDateFormat)}` }),
        };
        dispatchUpdateMember(initialValues.memberId, request, baseUrlWithoutTabsSlugs);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            initialValues={{
                memberFirstName: data ? data?.memberFirstName : initialValues.memberFirstName,
                memberLastName: data ? data?.memberLastName : initialValues.memberLastName,
                memberPhoneNumber: formatPhoneNumber(data ? data?.memberPhoneNumber : initialValues.memberPhoneNumber),
                memberEmail: data ? data?.memberEmail : initialValues.memberEmail,
                memberDateOfBirth: date,
                memberStatus: t(`member:memberStatuses.${data ? data?.status : initialValues.memberStatus}`),
            }}
        >
            <>
                <Form.Item
                    name="memberFirstName"
                    label={t('memberFields.firstName')}
                    rules={[
                        ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                        ...isValidFirstNameRule(t),
                    ]}
                >
                    <Input disabled={accessControl.isKycKybApprovalEnabled()} />
                </Form.Item>

                <Form.Item
                    name="memberLastName"
                    label={t('memberFields.lastName')}
                    rules={[
                        ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                        ...isValidLastNameRule(t),
                    ]}
                >
                    <Input disabled={accessControl.isKycKybApprovalEnabled()} />
                </Form.Item>

                <Form.Item
                    name="memberDateOfBirth"
                    label={t('memberFields.dateOfBirth')}
                    rules={[{ type: 'object', required: true, message: 'Required' }]}
                >
                    <DatePicker format={format} disabled={accessControl.isKycKybApprovalEnabled()} />
                </Form.Item>
            </>

            <PhoneInput
                t={t}
                name="memberPhoneNumber"
            />

            <EmailInput t={t} label={t('memberFields.email')} name="memberEmail" isRequired />

            <Form.Item
                name="memberStatus"
                label={t('memberFields.status')}
                rules={[...commonValidation({ t, isRequired: true })]}
            >
                <Select placeholder="Select Status" disabled />
            </Form.Item>
        </Form>
    );
}

export default EditMemberModal;

