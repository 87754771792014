import { fetchCompanyDetails } from 'redux/company/actions';
import { companyDetailsPerLocationsStoreKeyName, companyReducerName } from 'redux/company/reducer';
import { fetchMemberDetails } from 'redux/member/actions';


const commonRefreshActions = (payload, state) => {
    const { requestPayload, locationPathname } = payload;
    const pathname = payload.locationPathname;
    const currentCompanyDetailsQueryParams = state[companyReducerName][companyDetailsPerLocationsStoreKeyName]?.[pathname]?.queryParams
      ?? { companyId: requestPayload.companyId };

    const refreshAction = requestPayload.companyId > 0
        ? [fetchCompanyDetails(currentCompanyDetailsQueryParams, locationPathname)]
        : [fetchMemberDetails({ memberId: requestPayload.memberId }, locationPathname)];

    return refreshAction;
};

export default commonRefreshActions;
