import { HttpService } from 'services/http';


export const getClientIdRiskDetails = (clientId) => HttpService.get(`/v2/clients/${clientId}/risk-details`);

export const createClientIdRiskDetails = (clientId, requestPayload) => HttpService.post(`/v2/clients/${clientId}/risk-details`, requestPayload);

export const getClientIdCards = (clientId, queryParams) => HttpService.get(`/v2/clients/${clientId}/cards`, queryParams);

export const updateClientId = (clientId, requestPayload) => HttpService.patch(`/v2/clients/${clientId}`, requestPayload);

export const getClientIdCardMatrixes = (clientId) => HttpService.get(`/v2/clients/${clientId}/card-matrixes`);

export const getClientsApi = (queryParams) => HttpService.get('/v2/clients', queryParams);

export const getClientIdDetails = (clientId, queryParams) => HttpService.get(`/v2/clients/${clientId}`, queryParams);

export const updateClientIdAnnouncement = (clientId, requestPayload) => HttpService.put(`/v2/clients/${clientId}/announcements`, requestPayload);

export const getClientIdProducts = (clientId, queryParams) => HttpService.get(`/v2/clients/${clientId}/products`, queryParams);

export const createClientIdProduct = (clientId, requestPayload) => HttpService.post(`/v2/clients/${clientId}/products`, requestPayload);

export const addNewClientApi = (requestPayload) => HttpService.post('/v2/clients', requestPayload);

export const getClientIdMigrations = (clientId, queryParams) => HttpService.get(`v2/clients/${clientId}/migrations`, { ...queryParams });

export const getClientIdMigrationIdResult = ({
    clientId,
    migrationType,
    migrationId,
    queryParams,
}) => HttpService.get(
    `v2/clients/${clientId}/migrations/${migrationType}/${migrationId}/csv`,
    {
        ...queryParams,
        download: 'blob',
        raw: true,
    },
);

export const getClientIdMigrationReport = (
    clientId,
    queryParams,
) => HttpService.get(
    `v2/clients/${clientId}/migrations/report/csv`,
    {
        ...queryParams,
        download: 'blob',
        raw: true,
    },
);

export const uploadClientIdMigrationCsv = ({
    clientId,
    migrationType,
    blob,
}) => HttpService.post(
    `v2/clients/${clientId}/migrations/${migrationType}`,
    blob,
    {
        headers: { 'Content-Type': blob.type },
        noErrorHandling: true,
    },
);

