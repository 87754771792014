import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { FETCH_ALL_CARDS } from './action.types';
import { fetchAllCardsFailure, fetchAllCardsSuccess } from './actions';


export const onFetchAllCards = (action$, state$, { cards }) => action$.pipe(
    ofType(FETCH_ALL_CARDS),
    switchMap(({ payload }) => from(cards.fetchAllCards(payload.queryParams)).pipe(
        switchMap((response) => of(fetchAllCardsSuccess(
            response?.data?.rows,
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchAllCardsFailure(payload.locationPathname))),
    )),
);

export default [
    onFetchAllCards,
];
