import { createAction } from '@reduxjs/toolkit';

import {
    CHARGE_CUSTOM_FEE,
    CHARGE_CUSTOM_FEE_FAILURE,
    CHARGE_CUSTOM_FEE_SUCCESS,
    CLEAR_FEES_LIST,
    FETCH_FEES_LIST,
    FETCH_FEES_LIST_FAILURE,
    FETCH_FEES_LIST_SUCCESS,
    OPEN_CHARGE_CUSTOM_FEE_MODAL,
} from './actions.types';


export const fetchFeesList = createAction(FETCH_FEES_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchFeesListSuccess = createAction(FETCH_FEES_LIST_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchFeesListFailure = createAction(FETCH_FEES_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearFeesList = createAction(CLEAR_FEES_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export const openChargeCustomFeeModal = createAction(OPEN_CHARGE_CUSTOM_FEE_MODAL);

export const chargeCustomFee = createAction(CHARGE_CUSTOM_FEE, (requestPayload) => ({ payload: { queryParams: { requestPayload } } }));
export const chargeCustomFeeSuccess = createAction(CHARGE_CUSTOM_FEE_SUCCESS);
export const chargeCustomFeeFailure = createAction(CHARGE_CUSTOM_FEE_FAILURE);
