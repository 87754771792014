import { CompanyRiskTag } from 'components/common/atoms/tags/CompanyRiskTag';
import CompanyStatusTag from 'components/common/atoms/tags/CompanyStatusTag';
import StandardLink from 'components/common/molecules/StandardLink';
import ClientRenderer from 'components/common/molecules/value-renderers/ClientRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createCompanyDetailsPath, createGroupDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { enumValueToReadableString } from 'utils/string-tools';
import { sortingOptions } from 'utils/table-columns-sorters';

import React from 'react';


export default ({
    accessControl,
    queryParams,
    showPartnerColumn,
    dispatchRequestNavigation,
    t,
}) => {
    const baseColumns = [
        {
            title: t('ID'),
            dataIndex: 'company_id',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'company_id' }),
        },
        {
            title: t('Status'),
            align: 'center',
            dataIndex: 'company_status',
            render: (text) => <CompanyStatusTag status={text} t={t} />,
            ...sortingOptions({ queryParams, fieldName: 'company_status' }),
        },
        {
            title: 'Name',
            dataIndex: 'company_name',
            render: (text, record) => (accessControl.hasPermission(PN.COMPANIES.READ_BY_ID)
                ? (
                    <StandardLink
                        title={text}
                        path={createCompanyDetailsPath(record.company_id)}
                        filterValue={queryParams?.search}
                        showTooltip
                    />
                ) : <StandardValue value={text} searchQuery={queryParams?.search} />),
            ...sortingOptions({ queryParams, fieldName: 'company_name' }),
        },
        {
            title: 'Registration number',
            dataIndex: 'company_registration_number',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'company_registration_number' }),
        },
        {
            title: 'Company type',
            dataIndex: 'company_type',
            render: (text) => <StandardValue value={enumValueToReadableString(text)} />,
            ...sortingOptions({ queryParams, fieldName: 'company_type' }),
        },
        {
            title: 'Email',
            dataIndex: 'company_email',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'company_email' }),
        },
        {
            title: 'Phone number',
            dataIndex: 'company_phone_number',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.PHONE} />,
            ...sortingOptions({ queryParams, fieldName: 'company_phone_number' }),
        },
        {
            title: 'Risk score',
            dataIndex: 'company_total_risk_score',
            align: 'center',
            render: (riskScore) => <CompanyRiskTag score={riskScore} showTooltip />,
            ...sortingOptions({ queryParams, fieldName: 'company_total_risk_score' }),
        },

        {
            title: 'Group',
            dataIndex: 'group',
            render: (text, record) => (accessControl.hasPermission(PN.GROUPS.READ_BY_ID)
      && record.group?.group_id
                ? (
                    <StandardLink
                        title={record.group.group_name}
                        path={createGroupDetailsPath(record.client_id, record.group?.group_id)}
                    />
                ) : <StandardValue value={record.group.group_name} />),
        },


        {
            title: 'Date created',
            dataIndex: 'created_at',
            render: (text) => <StandardValue value={text} valueType="DATE" />,
            ...sortingOptions({ queryParams, fieldName: 'created_at' }),
        },
    ];

    const partnerColumn = [{
        title: 'Member of',
        dataIndex: 'client_id',
        render: (text, record) => <ClientRenderer clientId={record.client_id} />,
        ...sortingOptions({ queryParams, fieldName: 'client_id' }),
    }];

    const actionsColumn = [{
        title: t('common:table.columnNames.actions'),
        align: 'center',
        render: (_, record) => viewButtonRenderer({
            record,
            onClick: (event, record) => dispatchRequestNavigation(createCompanyDetailsPath(record.company_id)),
            tooltipContent: 'View company details',
            dataTestIdSuffix: 'company',
        }),
    }];


    return [
        ...baseColumns,
        ...(showPartnerColumn ? partnerColumn : []),
        ...(accessControl.hasPermission(PN.COMPANIES.READ_BY_ID) ? actionsColumn : []),
    ];
};
