import FeesTab from 'components/common/templates/lists/FeesList';
import GroupMembersList from 'components/common/templates/lists/GroupMembersList';
import PromotionsList from 'components/common/templates/lists/PromotionsList';
import { feesRouteSegmentPath, membersRouteSegmentPath, promotionsRouteSegmentPath } from 'config/routes';
import { createAddMembersToGroupPath, createAddPromotionPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';

import { FolderOutlined, PercentageOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';


export const createGroupsDetailsTabsConfig = ({
    fixedGroupId,
    fixedClientId,
    accessControl,
    enhancedCurrentLocation,
    dispatchOpenAssignPromotionsToGroupModal,
    dispatchRequestNavigation,
    existingPromotionsUuids,
    t,
}) => [
    ...(accessControl?.hasPermission(PN.PROMOTIONS.READ_FOR_GROUP) ? [{
        icon: <FolderOutlined />,
        label: t('common:tabsNames.promotions'),
        isDefault: true,
        activeMainTabSegmentURL: promotionsRouteSegmentPath,
        content: (
            <PromotionsList
                clientId={fixedClientId}
                groupId={fixedGroupId}
                enhancedCurrentLocation={enhancedCurrentLocation}
                buttonsConfig={[
                    {
                        type: 'primary',
                        text: t('groups:buttons.addPromotion.text'),
                        icon: <PlusOutlined />,
                        dataTestId: 'add-existing-promotion-button',
                        onClick: () => dispatchOpenAssignPromotionsToGroupModal(
                            {
                                clientId: fixedClientId,
                                groupId: fixedGroupId,
                                existingPromotionsUuids,
                            },
                            enhancedCurrentLocation?.pathname,
                        ),
                    },
                    ...(accessControl.hasPermission(PN.PROMOTIONS.CREATE) ? [
                        {
                            type: 'primary',
                            text: t('groups:buttons.createPromotion.text'),
                            dataTestId: 'add-promotion-button',
                            icon: <PlusOutlined />,
                            onClick: () => dispatchRequestNavigation(createAddPromotionPath({
                                selectedGroup: fixedGroupId,
                                clientId: fixedClientId,
                            })),
                        },
                    ] : []),
                ]}
            />
        ),
    }] : []),

    ...(accessControl?.hasPermission(PN.FEES.READ_FOR_GROUP) ? [{
        icon: <PercentageOutlined />,
        label: t('common:tabsNames.fees'),
        activeMainTabSegmentURL: feesRouteSegmentPath,
        content: (
            <FeesTab
                clientId={fixedClientId}
                groupId={fixedGroupId}
            />
        ),
    }] : []),

    ...(accessControl?.hasPermission(PN.MEMBERS.READ_FOR_GROUP) ? [{
        icon: <UserOutlined />,
        label: t('common:tabsNames.members'),
        activeMainTabSegmentURL: membersRouteSegmentPath,
        content: (
            <GroupMembersList
                clientId={fixedClientId}
                groupId={fixedGroupId}
                buttonsConfig={[
                    {
                        type: 'primary',
                        text: t('groups:buttons.addMembers.text'),
                        icon: <PlusOutlined />,
                        dataTestId: 'add-existing-promotion-button',
                        onClick: () => dispatchRequestNavigation(createAddMembersToGroupPath(fixedClientId, fixedGroupId)),
                    },
                ]}
            />
        ),
    }] : []),

];

export default { createGroupsDetailsTabsConfig };
