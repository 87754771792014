export const createAddCompanyErrorMessage = (t, error) => {
    const handlers = [
        {
            predicate: () => error?.messageCode === 'USER_EMAIL_NOT_UNIQUE',
            handler: () => (t('businessUsers:validationMsg.userEmailNotUnique')),
        },
        {
            predicate: () => error.message.startsWith('Company with registration number'),
            handler: () => (t('companies:actionMessages.registrationNumberNotUnique')),
        },
        {
            predicate: () => true,
            handler: () => (t('common:genericApiError')),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default { createAddCompanyErrorMessage };
