import svgCountries from 'assets/images/svgCountries';
import svgCurrencies from 'assets/images/svgCurrencies';
import countryISO3ToISO2Mapping from 'utils/country-iso-3-to-2';

import PropTypes from 'prop-types';
import React from 'react';


import Styled from './CountryOrCurrencyFlag.styled';


function CountryOrCurrencyFlag({
    currencyCode = '',
    countryCode = '',
    size = 40,
    useCountryISOAlpha3Code = false,
}) {
    const handlers = [
        {
            predicate: () => currencyCode !== '',
            handler: () => ({
                flagSrc: svgCurrencies[currencyCode?.toLowerCase()],
                imgAlt: currencyCode,
            }),
        },
        {
            predicate: () => countryCode !== '' && useCountryISOAlpha3Code,
            handler: () => ({
                flagSrc: svgCountries[countryISO3ToISO2Mapping[countryCode].toLowerCase()],
                imgAlt: countryCode,
            }),
        },
        {
            predicate: () => countryCode !== '' && !useCountryISOAlpha3Code,
            handler: () => ({
                flagSrc: svgCountries[countryCode?.toLowerCase()],
                imgAlt: countryCode,
            }),
        },
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];

    const flagData = handlers.filter(({ predicate }) => predicate())[0].handler();

    return flagData?.flagSrc ? (
        <img
            width={`${size}px`}
            height={`${size}px`}
            src={flagData?.flagSrc}
            alt={flagData?.imgAlt}
        />
    ) : (
        <Styled.MissingFlagPlaceholder size={`${size}px`} />
    );
}


CountryOrCurrencyFlag.propTypes = {
    currencyCode: PropTypes.string,
    countryCode: PropTypes.string,
    size: PropTypes.number,
};


export default CountryOrCurrencyFlag;
