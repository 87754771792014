import CardsOrderForm from 'components/pages/authorised/CardsOrderForm/CardsOrderForm.layout';
import {
    setData,
    resetDataToInitState,
    submitOrderStep, startCardsOrdering,
} from 'redux/card-order/actions';
import { cardsOrderReducerName } from 'redux/card-order/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { blockNavigation, showNavigationConfirmation, unblockNavigation } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const mapStateToProps = (state) => ({
    initialSetupFinished: state[cardsOrderReducerName].initialSetupFinished,
    currentStep: state[cardsOrderReducerName].currentStep,

    currentUserClientId: state[currentUserReducerName].userData?.clientId,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetData: (data) => dispatch(setData(data)),
    dispatchResetDataToInitState: () => dispatch(resetDataToInitState()),
    dispatchSubmitOrderStep: (step) => dispatch(submitOrderStep(step)),
    dispatchStartCardsOrdering: (data) => dispatch(startCardsOrdering(data)),
    dispatchBlockNavigation: (reason) => dispatch(blockNavigation(showNavigationConfirmation(), reason)),
    dispatchUnblockNavigation: () => dispatch(unblockNavigation()),
});

const decorate = compose(
    withTranslation('cards'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CardsOrderForm);
