import { requestNavigation } from 'redux/navigation/actions';

import { connect } from 'react-redux';


import DefaultRedirection from './DefaultRedirection.layout';


const mapDispatchToProps = (dispatch) => ({ dispatchRequestNavigation: (pathname, state) => dispatch(requestNavigation(pathname, state)) });

export default connect(null, mapDispatchToProps)(DefaultRedirection);

