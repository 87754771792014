import { updateAddressFailure, updateAddressSuccess } from 'redux/address/actions';
import { showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { OPEN_EDIT_ADDRESS_MODAL, UPDATE_ADDRESS } from './actions.types';


export const onOpenEditAddressModal = (action$) => action$.pipe(
    ofType(OPEN_EDIT_ADDRESS_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: payload.modalType,
            modalProps: {
                initialValues: payload.initialValues,
                title: payload.modalTitle,
                addressId: payload.addressId,
                addressType: { ...payload.addressType },
            },
        }),
    )),
);


export const onUpdateAddress = (action$, state$, { addresses, i18n }) => action$.pipe(
    ofType(UPDATE_ADDRESS),
    switchMap(({ payload }) => from(addresses.updateAddress(payload.addressId, payload.requestPayload)).pipe(
        switchMap((response) => of(
            updateAddressSuccess(response.data, payload.addressType, payload?.locationPathname),
            showSuccessToast(i18n.t('common:actionMsg.updateAddressSuccess')),
            hideModal(),
        )),
        catchError(() => of(
            updateAddressFailure(payload.addressType),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);


export default [
    onOpenEditAddressModal,
    onUpdateAddress,
];
