import StandardFormSection from 'components/common/molecules/StandardFormSection';
import MultiStepFormSubStepsIndicator from 'components/common/organisms/StandardMultiStepForm/MultiStepFormSubStepsIndicator';
import { MultiStepFormResetScrollElements } from 'constants/MultiStepsFormModel';

import { Tabs } from 'antd';
import React from 'react';


import Styled from './FormStepsRenderer.styled';


export const renderFormSections = (
    stepConfig = { },
    t,
) => (
    <Styled.StandardFormSectionWrapper
        $colCount={stepConfig?.stepSections?.length || 1}
        $sectionsLayout={stepConfig?.sectionsLayout}
    >
        {stepConfig.stepSections.map((stepSection, index) => (
            <StandardFormSection
                key={`StandardFormSection-${index}`}
                formSectionConfig={stepSection}
                index={index}
                t={t}
            />
        ))}
    </Styled.StandardFormSectionWrapper>
);


export const createMultiStepsFormStep = ({
    isSubStep = false,
    stepConfig = {},
    t,
}) => {
    const { stepName, children, stepSections } = stepConfig;
    const renderContent = () => (stepSections?.length > 0
        ? renderFormSections(stepConfig, t)
        : children);

    return {
        key: stepName,
        children: isSubStep
            ? renderContent()
            : (
                <Styled.StepWithoutSubStepsOuterWrapper className={MultiStepFormResetScrollElements.mainStepsClassName}>
                    <Styled.StepWithoutSubStepsWrapper>
                        <Styled.StepWithoutSubStepsInnerWrapper>
                            {renderContent()}
                        </Styled.StepWithoutSubStepsInnerWrapper>
                    </Styled.StepWithoutSubStepsWrapper>
                </Styled.StepWithoutSubStepsOuterWrapper>
            ),
    };
};


export const renderMultiStepsFormStepWithSubSteps = ({
    stepConfig = {},
    currentMultiStepsFormSubStep,
    currentMultiStepsFormStep,
    availableSubSteps,
    isEditMode,
    locationPathname,
    t,
}) => ({
    key: stepConfig.stepName,
    children: (
        <Styled.StepWithSubStepsSubStepIndicatorAndContentWrapper className="step-with-subSteps">
            <MultiStepFormSubStepsIndicator
                locationPathname={locationPathname}
                availableSubSteps={availableSubSteps}
                currentMultiStepsFormSubStep={currentMultiStepsFormSubStep}
                currentMultiStepsFormStep={currentMultiStepsFormStep}
                stepConfig={stepConfig}
                isEditMode={isEditMode}
            />
            <Styled.StepWithSubStepsOuterWrapper>
                <Styled.StepWithSubStepsWrapper className={MultiStepFormResetScrollElements.subStepsClassName}>
                    <Styled.StepWithSubStepsInnerWrapper>
                        <Tabs
                            animated
                            destroyInactiveTabPane
                            activeKey={currentMultiStepsFormSubStep}
                            tabPosition={'top'}
                            renderTabBar={() => <Styled.DummyTabsBar />}
                            items={stepConfig?.subStepsConfiguration.map((subStepConfig) => createMultiStepsFormStep({
                                isSubStep: true,
                                stepConfig: {
                                    ...subStepConfig,
                                    stepName: subStepConfig.subStepName,
                                },
                                t,
                            }))}
                        />
                    </Styled.StepWithSubStepsInnerWrapper>
                </Styled.StepWithSubStepsWrapper>
            </Styled.StepWithSubStepsOuterWrapper>
        </Styled.StepWithSubStepsSubStepIndicatorAndContentWrapper>
    ),
});

export default {
    renderMultiStepsFormStep: createMultiStepsFormStep,
    renderMultiStepsFormStepWithSubSteps,
};
