import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import { WL_CONFIG } from 'config/config';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';


function CopyrightNotice({ t }) {
    const currentYear = dayjs().year();
    return (
        <StandardTranslationWithMarkup
            translationKey={'copyrightNotice'}
            namespace="common"
            values={{
                brandLandingPageUrl: WL_CONFIG.brandLandingPageUrl,
                brandShortName: WL_CONFIG.brandShortName,
                brandFullName: WL_CONFIG.brandFullName,
                currentYear,
            }}
            t={t}
        />

    );
}


CopyrightNotice.propTypes = { t: PropTypes.func };


export default CopyrightNotice;
