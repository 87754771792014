import { StandardTagConfigItem } from 'models/app/standardTag';
import { capitalizeFirstLetter } from 'utils/string-tools';

import { TransactionStatus } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';


const mapTransactionStatusToColorAndLabel = (status: TransactionStatus, t: TFunction): StandardTagConfigItem => {
    const normalisedStatus = capitalizeFirstLetter(status); // FIXING API output

    const handlers = [
        {
            predicate: () => normalisedStatus === TransactionStatus.Pending,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionStatuses.pending.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Rejected,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.rejected.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Approved,
            handler: () => ({
                color: 'green',
                label: t('transactions:transactionStatuses.approved.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Completed,
            handler: () => ({
                color: 'green',
                label: t('transactions:transactionStatuses.completed.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Reversed,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.reversed.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Failed,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.failed.text'),
            }),
        },
        {
            predicate: () => true,
            handler: () => ({ label: t('transactions:transactionStatuses.unknown') }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default mapTransactionStatusToColorAndLabel;
