import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';
import { clearUsersList, fetchUsers, openAddEditUserModal } from 'redux/users/actions';
import { usersReducerName, usersListsPerLocationStoreKeyName } from 'redux/users/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UsersList from './Users.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: usersReducerName,
        listsByLocationStoreKeyName: usersListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchUsers: (requestData, locationPathname) => dispatch(fetchUsers(requestData, locationPathname)),
    dispatchClearUsersList: (locationPathname) => dispatch(clearUsersList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: usersReducerName,
        fieldName: usersListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
    dispatchOpenAddEditUserModal: (configData) => dispatch(openAddEditUserModal(configData)),
});

const decorate = compose(
    withTranslation('users'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UsersList);
