import StandardLink from 'components/common/molecules/StandardLink';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createGroupDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { sortBooleansByKey, sortNumbers, sortStringsAlphabetically, sortingOptions } from 'utils/table-columns-sorters';

import { StarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';


function createGroupsTableColumns({
    accessControl,
    t,
    queryParams,
    clientId,
    dispatchRequestNavigation,
}) {
    const canViewGroupDetails = accessControl.hasPermission(PN.GROUPS.READ_BY_ID);


    return [
        {
            title: t('common:table.columnNames.id'),
            dataIndex: 'id',
            align: 'right',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'id',
                sorter: (a, b) => sortNumbers(a, b, 'id'),
            }),
        },
        {
            title: t('common:table.columnNames.groupName'),
            dataIndex: 'name',
            render: (text, record) => (canViewGroupDetails
                ? (
                    <StandardLink
                        path={createGroupDetailsPath(clientId, record.id)}
                        title={text}
                        filterValue={queryParams?.search}
                        showTooltip
                    />
                ) : <StandardValue value={text} searchQuery={queryParams?.search} />),
            ...sortingOptions({
                queryParams,
                fieldName: 'name',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'name'),
            }),
        },
        {
            title: t('common:table.columnNames.default'),
            dataIndex: 'is_default',
            align: 'center',
            render: (text, record) => record.is_default && (
                <StandardTooltip title={'Default group'}>
                    <StarOutlined style={{ fontSize: '20px', color: 'gold' }} />
                </StandardTooltip>
            ),
            ...sortingOptions({
                queryParams,
                fieldName: 'is_default',
                sorter: (a, b) => sortBooleansByKey(a, b, 'is_default'),
            }),
        },
        {
            title: t('groupsFields.consumerCount'),
            dataIndex: 'consumer_count',
            align: 'right',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.COUNTER} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'consumer_count',
                sorter: (a, b) => sortNumbers(a, b, 'consumer_count'),
            }),
        },
        {
            title: t('common:table.columnNames.promotionsCount'),
            dataIndex: 'promotion_count',
            align: 'right',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.COUNTER} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'promotion_count',
                sorter: (a, b) => sortNumbers(a, b, 'promotion_count'),
            }),
        },
        {
            title: t('common:table.columnNames.feesCount'),
            dataIndex: 'fee_count',
            align: 'right',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.COUNTER} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'fee_count',
                sorter: (a, b) => sortNumbers(a, b, 'fee_count'),
            }),
        },
        ...(canViewGroupDetails ? [{
            title: 'Actions',
            dataIndex: '',
            align: 'center',
            render: (_, record) => viewButtonRenderer({
                record,
                onClick: (event, record) => dispatchRequestNavigation(createGroupDetailsPath(clientId, record.id)),
                tooltipContent: "View Group's details",
                dataTestId: 'view-group-details',
            }),
        }] : []),
    ];
}


createGroupsTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    filterValue: PropTypes.string,
    clientId: PropTypes.number.isRequired,
};


export default createGroupsTableColumns;
