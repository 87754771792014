import StandardListTable from 'components/common/organisms/StandardListTable';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { CurrenciesListProp } from 'constants/CurrencyModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultCurrenciesListQueryParams } from 'redux/currencies/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createCurrenciesListTableColumns from './createTableColumns';


function CurrenciesList({
    enhancedCurrentLocation,
    listData,

    dispatchSetQueryParams,
    dispatchFetchCurrencies,
    dispatchClearCurrencies,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { };


    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        useGlobalLocation: true,
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultCurrenciesListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchCurrencies,
        dispatchClearItemsList: dispatchClearCurrencies,
        dispatchSetQueryParams,
    });

    // 4a. list specific stuff - local search, local sorting is in table configuration, see 'createCurrenciesListTableColumns'
    const filteredIndustries = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((currency) => searchCaseInsensitive(currency?.short, listData?.queryParams?.search)
          || searchCaseInsensitive(currency?.full_name, listData?.queryParams?.search)
          || searchCaseInsensitive(currency?.symbol, listData?.queryParams?.search));
            }
            return listData?.items || [];
        }, [listData],
    );

    return (
        <StandardListTable
            rowKey="short"
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchCurrencies(contextEnforcedQueryParams, enhancedCurrentLocation.pathname)}
            items={filteredIndustries}
            totalItemsCount={filteredIndustries.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createCurrenciesListTableColumns({
                queryParams: listData?.queryParams || {},
                t,
            })}
        />
    );
}

CurrenciesList.propTypes = {
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: CurrenciesListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    dispatchFetchCurrencies: PropTypes.func.isRequired,
    dispatchClearCurrencies: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default CurrenciesList;
