import TransactionsList from 'components/common/templates/lists/TransactionsList';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';

import React, { JSX } from 'react';


function Transactions(): JSX.Element {
    return (
        <>
            <StandardPageHeading title={'Transactions'} />

            <StandardPageWrapper>
                <TransactionsList showPartnerSelect allowInactivePartners />
            </StandardPageWrapper>
        </>
    );
}


export default Transactions;
