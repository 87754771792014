import { clearLoginBlock, getRememberedUsernameOrEmail, initialiseLogin } from 'redux/authorisation/actions';
import { authorisationReducerName } from 'redux/authorisation/reducer';
import { RootState } from 'redux/rootReducer';

import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import Login from './Login.layout';
import { LoginDispatchProps, LoginStateProps } from './Login.types';


const mapStateToProps = (state: RootState): LoginStateProps => ({
    fieldLoginAttempts: state.authorisation.fieldLoginAttempts,
    loginFormErrorMessage: state[authorisationReducerName].loginFormErrorMessage,
    isLoadingLoginForm: state.authorisation.isLoadingLoginForm,
    emailOrUsername: state.authorisation.loginFormRememberedUserNameOrEmail,
    isLoadingLoginConfirmationForm: state.authorisation.isLoadingLoginConfirmationForm,

    loginBlockUntil: state[authorisationReducerName].loginBlockUntil,
    loginBlockPermanent: state[authorisationReducerName].loginBlockPermanent,
    submittedUserNameOrEmail: state[authorisationReducerName].submittedUserNameOrEmail,
});

const mapDispatchToProps = (dispatch: Dispatch): LoginDispatchProps => ({
    dispatchInitialiseLogin: (data) => dispatch(initialiseLogin(data)),
    dispatchGetRememberedUsernameOrEmail: () => dispatch(getRememberedUsernameOrEmail()),
    dispatchClearLoginBlock: () => dispatch(clearLoginBlock()),
});


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(Login);
