import withAccessControl from 'hocs/withAccessControl';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { fetchFeeTiersList } from 'redux/fee-tiers/actions';
import { feeTiersListPerLocationStoreKeyName, feeTiersReducerName } from 'redux/fee-tiers/reducer';
import { chargeCustomFee } from 'redux/fees/actions';
import { triggerManualFee } from 'redux/manual-fees/actions';
import { setModalProps } from 'redux/modal/actions';
import { modalReducerName } from 'redux/modal/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ChargeFeeModal from './ChargeFeeModal';


const mapStateToProps = (state) => ({
    clientsData: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
    feeTiersListData: getStandardListData({
        state,
        reducerName: feeTiersReducerName,
        listsByLocationStoreKeyName: feeTiersListPerLocationStoreKeyName,
    }),
    userClientId: state[currentUserReducerName].userData?.clientId,
    modalProps: state[modalReducerName]?.modalProps,
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchChargeCustomFee: (payload) => dispatch(chargeCustomFee(payload)),
    dispatchTriggerManualFee: (payload) => dispatch(triggerManualFee(payload)),
    dispatchFetchFeeTiersList: (queryParams, locationPathname) => dispatch(fetchFeeTiersList(queryParams, locationPathname)),
});

const decorate = compose(
    withTranslation('fees'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);
export default decorate(ChargeFeeModal);
