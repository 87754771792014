import StandardListTable from 'components/common/organisms/StandardListTable';
import { createAccountTypeFilterConfig, createCurrencyFilterConfig } from 'components/common/organisms/StandardListTable/FilterSection/FiltersSection.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CurrenciesListProp } from 'constants/CurrencyModel';
import { PN } from 'constants/PermissionsModel';
import { ProductTypes } from 'constants/ProductModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { clientProductsListQueryParams } from 'redux/client-products/reducer';
import { createAccountTypeOptions, createCurrencyOptions } from 'utils/options-creators';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import tableColumns from './tableColumns';


function ProductsList({
    // Context enforced query param
    clientId,

    enhancedCurrentLocation,
    listData,

    // other mapped props
    accessControl,
    currencies,
    dispatchOpenAddProductModal,
    dispatchFetchClientProducts,
    dispatchClearClientProducts,
    dispatchSetQueryParams,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { clientId };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchClientProducts(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: clientProductsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchClientProducts,
        dispatchClearItemsList: dispatchClearClientProducts,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependent optional products list filters & buttons
    const currencyOptions = useMemo(
        () => createCurrencyOptions(currencies, t),
        [currencies],
    );

    const accountTypeOptions = createAccountTypeOptions(Object.keys(ProductTypes));

    const filters = [
        createCurrencyFilterConfig({
            currencyOptions,
            value: listData?.queryParams?.currency,
            t,
        }),
        createAccountTypeFilterConfig({
            accountTypeOptions,
            value: listData?.queryParams?.type,
            t,
        }),
    ];

    const buttons = accessControl.hasPermission(PN.CLIENTS.CREATE_PRODUCT)
        ? [{
            type: 'primary',
            text: t('buttons.addProduct.text'),
            icon: <PlusOutlined />,
            onClick: () => dispatchOpenAddProductModal(enhancedCurrentLocation?.pathname),
        }] : [];

    // 5. standard render component
    return (
        <StandardListTable
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={tableColumns({ t })}
            additionalFiltersConfig={filters}
            filtersButtonsConfig={buttons}
        />

    );
}

ProductsList.propTypes = {
    clientId: PropTypes.number.isRequired, // XXX used in mapDispatchToProps

    // mapped props
    accessControl: AccessControlProps,
    currencies: CurrenciesListProp,

    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchOpenAddProductModal: PropTypes.func.isRequired,
    dispatchFetchClientProducts: PropTypes.func.isRequired,
    dispatchClearClientProducts: PropTypes.func.isRequired,
};

export default ProductsList;
