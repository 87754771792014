import { addClientReducerName } from 'redux/client-add/reducer';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import InitialAccountsProducts from './InitialAccountsProducts.layout';


const mapStateToProps = (state) => ({
    bpsProductsList: state[addClientReducerName].bpsProductsList,
    isLoadingBpsProductsList: state[addClientReducerName].isLoadingBpsProductsList,
    currencies: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});

const decorate = compose(
    withTranslation('clients'),
    connect(mapStateToProps),
);

export default decorate(InitialAccountsProducts);
