import { ofType } from 'redux-observable';
import { catchError, from, of, switchMap } from 'rxjs';

import { fetchLimitDetailsFailure, fetchLimitDetailsSuccess } from './actions';
import { FETCH_LIMIT_DETAILS } from './actions.types';


const onFetchLimitDetails = (action$, _, { rules }) => action$.pipe(
    ofType(FETCH_LIMIT_DETAILS),
    switchMap(({ payload: { queryParams, locationPathname } }) => from(rules.getRule(queryParams))
        .pipe(
            switchMap((response) => of(fetchLimitDetailsSuccess(response.data, locationPathname))),
            catchError(() => of(fetchLimitDetailsFailure(locationPathname))),
        )),
);

export default [onFetchLimitDetails];
