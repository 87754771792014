import {
    accountRootRoutePath,
    addMembersRouteSegmentPath,
    addPromotionRouteSegmentPath,
    beneficiariesRouteSegmentPath,
    beneficiaryRootRoutePath,
    cardRootRoutePath,
    cardsRouteSegmentPath,
    clientRootRoutePath,
    clientRouteSegmentPath,
    clientsRootRoutePath, companiesRootRoutePath, companiesRouteSegmentPath,
    companyRootRoutePath,
    editBeneficiaryRootRoutePath,
    editRoleRootRouteSegmentPath,
    editSettingsRouteSegmentPath,
    businessUserRootRoutePath,
    businessUsersRouteSegmentPath,
    feeRootRoutePath,
    feesRouteSegmentPath,
    genericAddRouteSegmentPath,
    groupRootRoutePath,
    groupsRouteSegmentPath,
    memberRootRoutePath,
    membersRootRoutePath,
    membersRouteSegmentPath,
    promotionRootRoutePath,
    promotionsRouteSegmentPath,
    roleRootRoutePath,
    rolesRootRoutePath,
    transactionRootRoutePath,
    transactionsRouteSegmentPath,
    userRootRoutePath, riskScoreHistoryRouteSegmentPath, failedWebhookRootRoutePath, errorsHistoryRouteSegmentPath,
    webhooksFailedRouteSegmentPath, webhooksRouteSegmentPath,
    limitRootPath,
} from 'config/routes';


export const createGroupDetailsPath = (clientId, groupId) => `${groupRootRoutePath}/${groupId}/${clientRouteSegmentPath}/${clientId}`;
export const createGroupDetailsMembersListPath = (clientId, groupId) => `${createGroupDetailsPath(clientId, groupId)}/${membersRouteSegmentPath}`;
export const createAddMembersToGroupPath = (clientId, groupId) => `${createGroupDetailsPath(clientId, groupId)}/${addMembersRouteSegmentPath}`;


export const createFeeDetailsPath = (feeUuid) => `${feeRootRoutePath}/${feeUuid}`;

export const createPromotionDetailsPath = (promotionUuid) => `${promotionRootRoutePath}/${promotionUuid}`;

export const createAddPromotionPath = (params) => {
    if (params?.selectedGroup) {
        return `/${addPromotionRouteSegmentPath}/${params.clientId}/${params.selectedGroup}`;
    }
    return `/${addPromotionRouteSegmentPath}/${params.clientId}`;
};

export const createUserDetailsPath = (userId) => `${userRootRoutePath}/${userId}`;
export const createClientsListPath = () => `${clientsRootRoutePath}`;
export const createAddClientPath = () => `${clientsRootRoutePath}/${genericAddRouteSegmentPath}`;

export const createClientDetailsPath = (clientId) => `${clientRootRoutePath}/${clientId}`;
export const createClientDetailsPromotionsListPath = (clientId) => `${createClientDetailsPath(clientId)}/${promotionsRouteSegmentPath}`;
export const createClientDetailsCompaniesListPath = (clientId) => `${createClientDetailsPath(clientId)}/${companiesRouteSegmentPath}`;
export const createClientDetailsGroupsListPath = (clientId) => `${createClientDetailsPath(clientId)}/${groupsRouteSegmentPath}`;
export const createClientDetailsFeesListPath = (clientId) => `${createClientDetailsPath(clientId)}/${feesRouteSegmentPath}`;
export const createClientDetailsFailedWebhooksListPath = (clientId) => `${createClientDetailsPath(clientId)}/${webhooksRouteSegmentPath}/${webhooksFailedRouteSegmentPath}`;
export const createClientDetailsBeneficiariesListPath = (clientId) => `${createClientDetailsPath(clientId)}/${beneficiariesRouteSegmentPath}`;
export const createEditClientSettingsPath = (clientId) => `${createClientDetailsPath(clientId)}/${editSettingsRouteSegmentPath}`;
export const createClientDetailsRiskScoreHistoryListPath = (clientId) => `${createClientDetailsPath(clientId)}/${companiesRouteSegmentPath}/${riskScoreHistoryRouteSegmentPath}`;
export const createCompanyDetailsPath = (companyId) => `${companyRootRoutePath}/${companyId}`;
export const createAddCompanyRootPath = (clientId) => `${companiesRootRoutePath}/${genericAddRouteSegmentPath}${clientId > 0 ? `/${clientId}` : ''}`;
export const createCompanyDetailsBusinessUsersListPath = (companyId) => `${createCompanyDetailsPath(companyId)}/${businessUsersRouteSegmentPath}`;
export const createCompanyDetailsBeneficiariesListPath = (companyId) => `${createCompanyDetailsPath(companyId)}/${beneficiariesRouteSegmentPath}`;

// directly to ATM one and only tab - transactions
export const createBeneficiaryDetailsPath = (beneficiaryUuid) => `${beneficiaryRootRoutePath}/${beneficiaryUuid}`;
export const createBeneficiaryDetailsTransactionsListPath = (beneficiaryUuid) => `${createBeneficiaryDetailsPath(beneficiaryUuid)}/${transactionsRouteSegmentPath}`;
export const createAddBeneficiaryPath = () => `${beneficiaryRootRoutePath}/${genericAddRouteSegmentPath}`;
export const createEditBeneficiaryPath = (beneficiaryUuid) => `${editBeneficiaryRootRoutePath}/${beneficiaryUuid}`;

export const createAccountDetailsPath = (accountId) => `${accountRootRoutePath}/${accountId}`;
export const createAccountCardsPath = (accountId) => `${accountRootRoutePath}/${accountId}/${cardsRouteSegmentPath}`;

export const createAccountTransactionsPath = (accountId) => `${accountRootRoutePath}/${accountId}/${transactionsRouteSegmentPath}`;

export const createMemberDetailsPath = (memberId) => `${memberRootRoutePath}/${memberId}`;
export const createAddMemberPath = (clientId) => `${membersRootRoutePath}/${genericAddRouteSegmentPath}${clientId > 0 ? `/${clientId}` : ''}`;
export const createClientDetailsMembersListPath = (clientId) => `${createClientDetailsPath(clientId)}/${membersRouteSegmentPath}`;
export const createMemberDetailsBeneficiariesListPath = (memberId) => `${createMemberDetailsPath(memberId)}/${beneficiariesRouteSegmentPath}`;

export const createBusinessUserDetailsPath = (businessUserId) => `${businessUserRootRoutePath}/${businessUserId}`;
export const createFailedWebhookDetailsPath = (failedWebhookUuid) => `${failedWebhookRootRoutePath}/${failedWebhookUuid}`;
export const createFailedWebhookDetailsErrorsHistoryPath = (failedWebhookUuid) => `${failedWebhookRootRoutePath}/${failedWebhookUuid}/${errorsHistoryRouteSegmentPath}`;

export const createRoleDetailsPath = (roleId) => `${roleRootRoutePath}/${roleId}`;
export const createEditRolePath = (roleId) => `${editRoleRootRouteSegmentPath}/${roleId}`;
export const createAddRolePath = () => `${rolesRootRoutePath}/${genericAddRouteSegmentPath}`;
export const createRolesListPath = () => `${rolesRootRoutePath}`;
export const createCardDetailsPath = (cardId) => `${cardRootRoutePath}/${cardId}`;
export const createTransactionDetailsPath = (transactionUuid) => `${transactionRootRoutePath}/${transactionUuid}`;
export const createLimitDetailsPath = (limitUuid) => `${limitRootPath}/${limitUuid}`;

export default {
    createGroupDetailsPath,
    createAddMembersToGroupPath,

    createFeeDetailsPath,

    createAddPromotionPath,

    createPromotionDetailsPath,

    createUserDetailsPath,

    createAddClientPath,
    createClientDetailsPath,
    createClientDetailsCompaniesListPath,
    createEditClientSettingsPath,

    createCompanyDetailsPath,

    createAddBeneficiaryPath,
    createBeneficiaryDetailsPath,
    createBeneficiaryDetailsTransactionsListPath,
    createEditBeneficiaryPath,

    createAccountDetailsPath,
    createAccountCardsPath,
    createAccountTransactionsPath,

    createAddMemberPath,
    createMemberDetailsPath,

    createBusinessUserDetailsPath,

    createRoleDetailsPath,

    createCardDetailsPath,
    createClientsListPath,
    createTransactionDetailsPath,
};
