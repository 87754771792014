import { scrollBar } from 'assets/styles/scrollbar';

import styled from 'styled-components';


const ViewJsonModalWrapper = styled.div`
  overflow: auto;
  max-height: 400px;
  ${scrollBar};
  
  pre {
    ${scrollBar};
  }
`;

const CopyToClipboardWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 16px;
`;

export default { ViewJsonModalWrapper, CopyToClipboardWrapper };

