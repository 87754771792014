import FlagAndValue from 'components/common/atoms/FlagAndValue';
import StandardMultiValue from 'components/common/molecules/value-renderers/StandardMultiValue';
import { CountriesListProps } from 'constants/CountriesModel';

import PropTypes from 'prop-types';
import React from 'react';


import Styled from './CountriesOfOperations.styled';


const getCountriesOfOperations = (countries_of_operations, countries = []) => {
    const countriesList = [];
    if (countries_of_operations) {
        (countries_of_operations || []).forEach((iso) => {
            const country = countries.find((country) => country.iso_31663 === iso);
            if (country) {
                countriesList.push(country);
            }
        });
    }

    return countriesList;
};

const countryInlineRenderer = (country) => (
    <FlagAndValue
        key={country.name}
        countryCode={country.iso_31662}
        text={country.name}
        showTooltip
    />
);

const countryTooltipRenderer = (country) => (
    <FlagAndValue
        key={country.name}
        countryCode={country.iso_31662}
        text={country.name}
        showTooltip
    />
);

function CountriesOfOperations({ countriesOfOperations, countries, itemsToBeShownInline = 3 }) {
    const data = getCountriesOfOperations(countriesOfOperations, countries);

    return (
        <Styled.CountriesOfOperationsWrapper>
            <StandardMultiValue
                items={data}
                itemsToBeShownInline={itemsToBeShownInline}
                inlineItemRenderer={countryInlineRenderer}
                tooltipItemRenderer={countryTooltipRenderer}
            />
        </Styled.CountriesOfOperationsWrapper>
    );
}


CountriesOfOperations.propTypes = {
    countriesOfOperations: PropTypes.arrayOf(PropTypes.string),
    countries: CountriesListProps,
    itemsToBeShownInline: PropTypes.number,
};


export default CountriesOfOperations;
