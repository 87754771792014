import { hideDrawer } from 'redux/drawer/actions';

import { Drawer } from 'antd';
import React from 'react';
import { connect } from 'react-redux';


const DRAWER_COMPONENTS = {};

function DrawerRoot({
    state,
    hideDrawer,
}) {
    if (!state.drawerType) {
        return null;
    }

    const SpecificDrawer = DRAWER_COMPONENTS[state.drawerType];
    const { width, ...restOfDrawerProps } = state?.drawerProps || {};

    return (
        <Drawer
            width={width || 300}
            placement="right"
            closable={false}
            onClose={() => {
                hideDrawer();
            }}
            open={state.visible}
            getContainer={'#modals-wrapper'}
        >
            <SpecificDrawer {...restOfDrawerProps} />
        </Drawer>
    );
}

const mapStateToProps = (state) => ({ state: state.drawer });

const mapDispatchToProps = (dispatch) => ({ hideDrawer: () => dispatch(hideDrawer()) });

export default connect(mapStateToProps, mapDispatchToProps)(DrawerRoot);
