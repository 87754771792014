import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import CommonAddressFrom from 'components/common/organisms/CommonAddressForm';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';

import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function EditClientAddressModal({
    initialValues,
    enhancedCurrentLocation,
    dispatchSetModalProps,
    dispatchUpdateAddress,
    addressId,
    addressType,
    t,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);


    const onFinish = (values) => {
        const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, 2);

        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchUpdateAddress(addressId, values, addressType, baseUrlWithoutTabsSlugs);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <CommonAddressFrom
                form={form}
                useStateField
            />

            <PhoneInput
                name="phone_number"
                t={t}
            />
        </Form>
    );
}

EditClientAddressModal.propTypes = {
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
    initialValues: PropTypes.shape({}),
    t: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchUpdateAddress: PropTypes.func.isRequired,
};

export default EditClientAddressModal;

