import { currentUserReducerName } from 'redux/current-user/reducer';


// Function to add partner UUID to query parameters
export function addPartnerUuidToQueryParams({ state$, queryParams }) {
    const { isSuperAdmin, userData } = state$.value[currentUserReducerName];
    const partnerUuid = !isSuperAdmin ? userData?.extClientUuid : null;

    return partnerUuid
        ? { ...queryParams, partnerUuid }
        : { ...queryParams };
}
