import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import {
    fetchGroupDetails,
    openAssignPromotionsToGroupModal,
    openEditGroupNameModal,
    openDeleteGroupModal, clearGroupDetails,
} from 'redux/group/actions';
import { groupDetailsPerLocationStoreKeyName, groupReducerName } from 'redux/group/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { removePromotionFromGroup } from 'redux/promotion/actions';
import { promotionsListsPerLocationStoreKeyName, promotionsReducerName } from 'redux/promotions/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import GroupDetailsPage from './GroupDetailsPage.layout';


const mapStateToProps = (state) => ({
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: groupReducerName,
        fieldName: groupDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 4,
    }),

    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    assignedPromotionsListData: getStandardListData({
        state,
        reducerName: promotionsReducerName,
        listsByLocationStoreKeyName: promotionsListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (url, state) => dispatch(requestNavigation(url, state)),

    dispatchFetchGroupDetails: (queryParams, locationPathname) => dispatch(fetchGroupDetails(queryParams, locationPathname)),
    dispatchClearGroupDetails: (locationPathname) => dispatch(clearGroupDetails(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: groupReducerName,
        fieldName: groupDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchOpenEditGroupNameModal: ({ clientId, groupId, locationPathname }) => dispatch(openEditGroupNameModal({ clientId, groupId, locationPathname })),
    dispatchOpenAssignPromotionsToGroupModal: (initialData, locationPathname) => dispatch(openAssignPromotionsToGroupModal(initialData, locationPathname)),
    dispatchRemovePromotionFromGroup: (queryParams, locationPathname) => dispatch(removePromotionFromGroup(queryParams, locationPathname)),
    dispatchOpenDeleteGroupModal: (groupDetails, locationPathname) => dispatch(openDeleteGroupModal(groupDetails, locationPathname)),
});

const decorate = compose(
    withAccessControl,
    withTranslation('groups'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(GroupDetailsPage);

