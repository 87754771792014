import ClientSelect from 'components/common/molecules/form-controls/ClientSelect';

import PropTypes from 'prop-types';
import React from 'react';


function PartnerSelection({
    currentUserClientId,
    accessControl,
    dispatchClearClientIdContext,
    dispatchSetClientIdContext,
    t,
}) {
    const checkContext = (clientId) => {
        if (accessControl?.isSuperAdmin && clientId !== currentUserClientId) {
            dispatchSetClientIdContext(clientId);
        } else {
            dispatchClearClientIdContext();
        }
    };


    return (
        <ClientSelect
            label={'Partner'}
            onChange={checkContext}
            isRequired
            name="client_id"
            isRequiredMessage={t('common:validationMsg.fieldMandatory')}
        />
    );
}

PartnerSelection.propTypes = {
    t: PropTypes.func.isRequired,
    currentUserClientId: PropTypes.number,
    dispatchSetClientIdContext: PropTypes.func,
    dispatchClearClientIdContext: PropTypes.func,
};

export default PartnerSelection;
