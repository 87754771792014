import { standardPageSize } from 'config/config';
import { clientRootRoutePath, webhooksManagementRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clientProductsListsPerLocationStoreKeyName } from 'redux/client-products/reducer';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchWebhooksActionTypes,
    fetchWebhooksActionTypesSuccess,
    fetchWebhooksActionTypesFailure,
    registerWebhook,
    registerWebhookSuccess,
    registerWebhookFailure,
    updateWebhook,
    updateWebhookSuccess,
    updateWebhookFailure,
    unregisterWebhook,
    unregisterWebhookSuccess,
    unregisterWebhookFailure,
    fetchWebhooksList,
    fetchWebhooksListSuccess,
    fetchWebhooksListFailure,
    clearWebhooksList,
} from './actions';


export const webhooksReducerName = 'webhooks';
export const webhooksListsPerLocationStoreKeyName = 'webhooksListsPerLocation';


export const defaultWebhooksListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    clientId: undefined,
};


// webhooks CRUD
const initialWebhooksManagementState = {
    [webhooksListsPerLocationStoreKeyName]: {},

    webhookActionTypes: [],
    isLoadingWebhookActionTypes: false,
    isLoadingRegisterWebhook: false,
    isLoadingUpdateWebhook: false,

    isLoadingUnregisterWebhook: false,
};


const initialState = { ...initialWebhooksManagementState };

const handlers = {
    // webhooks CRUD
    [fetchWebhooksList]: (state, action) => {
        state[webhooksListsPerLocationStoreKeyName] = updateStandardLists(state[webhooksListsPerLocationStoreKeyName], action);
    },
    [fetchWebhooksListSuccess]: (state, action) => {
        state[webhooksListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[webhooksListsPerLocationStoreKeyName], action);
    },
    [fetchWebhooksListFailure]: (state, action) => {
        state[webhooksListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[clientProductsListsPerLocationStoreKeyName], action);
    },
    [clearWebhooksList]: (state, action) => {
        state[webhooksListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[webhooksListsPerLocationStoreKeyName], action.payload?.locationPathname);
    },


    [fetchWebhooksActionTypes]: (state) => {
        state.isLoadingWebhookActionTypes = true;
    },
    [fetchWebhooksActionTypesSuccess]: (state, action) => {
        state.isLoadingWebhookActionTypes = false;
        state.webhookActionTypes = action.payload;
    },
    [fetchWebhooksActionTypesFailure]: (state) => {
        state.isLoadingWebhookActionTypes = false;
        state.webhookActionTypes = undefined;
    },


    [registerWebhook]: (state) => {
        state.isLoadingRegisterWebhook = true;
    },
    [registerWebhookSuccess]: (state) => {
        state.isLoadingRegisterWebhook = false;
    },
    [registerWebhookFailure]: (state) => {
        state.isLoadingRegisterWebhook = false;
    },


    [updateWebhook]: (state) => {
        state.isLoadingUpdateWebhook = true;
    },
    [updateWebhookSuccess]: (state) => {
    // TODO inject it form the response to the store or extra list refresh??
        state.isLoadingUpdateWebhook = false;
    },
    [updateWebhookFailure]: (state) => {
        state.isLoadingUpdateWebhook = false;
    },


    [unregisterWebhook]: (state) => {
        state.isLoadingUnregisterWebhook = true;
    },
    [unregisterWebhookSuccess]: (state) => {
        state.isLoadingUnregisterWebhook = false;
    },
    [unregisterWebhookFailure]: (state) => {
        state.isLoadingUnregisterWebhook = false;
    },


    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === webhooksReducerName
      && action.payload?.fieldName === webhooksListsPerLocationStoreKeyName
        ) {
            state[webhooksListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[webhooksListsPerLocationStoreKeyName] }, action);
        }
    },

    [preformNavigationSuccess]: (state, action) => {
        state[webhooksListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[webhooksListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [webhooksManagementRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
