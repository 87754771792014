import { createAction } from '@reduxjs/toolkit';

import {
    ADD_MEMBER_TO_GROUP, ADD_MEMBER_TO_GROUP_FAILURE, ADD_MEMBER_TO_GROUP_SUCCESS,
    ASSIGN_PROMOTIONS_TO_GROUP, ASSIGN_PROMOTIONS_TO_GROUP_FAILURE, ASSIGN_PROMOTIONS_TO_GROUP_SUCCESS, CLEANUP_ADD_MEMBER_TO_GROUP_METADATA,
    CLEAR_GROUP_DETAILS,
    DELETE_GROUP, DELETE_GROUP_FAILURE, DELETE_GROUP_SUCCESS,
    FETCH_GROUP_DETAILS, FETCH_GROUP_DETAILS_FAILURE, FETCH_GROUP_DETAILS_SUCCESS,
    OPEN_ASSIGN_PROMOTIONS_TO_GROUP_MODAL, OPEN_DELETE_GROUP_MODAL, OPEN_DELETE_GROUP_WITH_MEMBERS_RE_ASSIGMENT_MODAL,
    OPEN_EDIT_GROUP_NAME_MODAL, OPEN_REASSIGN_MEMBER_GROUP,
    REASSIGN_MEMBER_GROUP, REASSIGN_MEMBER_GROUP_FAILURE, REASSIGN_MEMBER_GROUP_SUCCESS,
    UPDATE_GROUP_NAME, UPDATE_GROUP_NAME_FAILURE, UPDATE_GROUP_NAME_SUCCESS,
} from './actions.types';


export const fetchGroupDetails = createAction(FETCH_GROUP_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchGroupDetailsSuccess = createAction(FETCH_GROUP_DETAILS_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchGroupDetailsFailure = createAction(FETCH_GROUP_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearGroupDetails = createAction(CLEAR_GROUP_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));


export const openEditGroupNameModal = createAction(OPEN_EDIT_GROUP_NAME_MODAL, ({ clientId, groupId, locationPathname }) => ({ payload: { clientId, groupId, locationPathname } }));
export const updateGroupName = createAction(UPDATE_GROUP_NAME, ({ clientId, groupId, newGroupName, locationPathname }) => ({
    payload: {
        clientId,
        groupId,
        newGroupName,
        locationPathname,
    },
}));
export const updateGroupNameSuccess = createAction(UPDATE_GROUP_NAME_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const updateGroupNameFailure = createAction(UPDATE_GROUP_NAME_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const reassignMemberGroup = createAction(REASSIGN_MEMBER_GROUP, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const reassignMemberGroupSuccess = createAction(REASSIGN_MEMBER_GROUP_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const reassignMemberGroupFailure = createAction(REASSIGN_MEMBER_GROUP_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const openReassignMemberGroupModal = createAction(
    OPEN_REASSIGN_MEMBER_GROUP,
    (initialData, locationPathname) => ({ payload: { initialData, locationPathname } }),
);

export const openAssignPromotionsToGroupModal = createAction(
    OPEN_ASSIGN_PROMOTIONS_TO_GROUP_MODAL,
    (initialData, locationPathname) => ({ payload: { initialData, locationPathname } }),
);
export const assignPromotions = createAction(ASSIGN_PROMOTIONS_TO_GROUP, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const assignPromotionsSuccess = createAction(ASSIGN_PROMOTIONS_TO_GROUP_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const assignPromotionsFailure = createAction(ASSIGN_PROMOTIONS_TO_GROUP_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const openDeleteGroupModal = createAction(OPEN_DELETE_GROUP_MODAL, (groupDetails) => ({ payload: { groupDetails } }));


export const openDeleteGroupWithMembersReAssignmentConfirmation = createAction(OPEN_DELETE_GROUP_WITH_MEMBERS_RE_ASSIGMENT_MODAL, (groupId, newGroupId, meta) => ({
    payload: { groupId, newGroupId },
    meta,
}));

export const deleteGroup = createAction(DELETE_GROUP, (groupId, newGroupId, meta) => ({
    payload: { groupId, newGroupId },
    meta,
}));
export const deleteGroupSuccess = createAction(DELETE_GROUP_SUCCESS);
export const deleteGroupFailure = createAction(DELETE_GROUP_FAILURE);


export const addMemberToGroup = createAction(ADD_MEMBER_TO_GROUP, ({ memberId, groupId, clientId }) => ({
    payload: {
        memberId,
        groupId,
        clientId,
    },
}));
export const addMemberToGroupSuccess = createAction(ADD_MEMBER_TO_GROUP_SUCCESS, ({ memberId, groupId, clientId }) => ({ payload: { memberId, groupId, clientId } }));
export const addMemberToGroupFailure = createAction(ADD_MEMBER_TO_GROUP_FAILURE, (memberId) => ({ payload: { memberId } }));
export const cleanupAddMemberToGroupMetadata = createAction(CLEANUP_ADD_MEMBER_TO_GROUP_METADATA,
    ({ groupId, clientId, membersAdded }) => ({ payload: { groupId, clientId, membersAdded } }));
