import { setMultiStepsFormSubStep } from 'redux/multi-steps-form/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import MultiStepFormSubStepsIndicator from './MultiStepFormSubStepsIndicator.layout';


const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchSetMultiStepsFormSubStep: (parentStep, newCurrentSubStep) => dispatch(setMultiStepsFormSubStep(
        parentStep,
        newCurrentSubStep,
        ownProps?.locationPathname,
    )),
});


const decorate = compose(
    withTranslation('common'),
    connect(null, mapDispatchToProps),
);

export default decorate(MultiStepFormSubStepsIndicator);

