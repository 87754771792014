import { HttpService } from 'services/http';


export const getAllCardsApi = (queryParams) => HttpService.get('/v3/cards', queryParams);

export const getCardIdDetails = (cardId) => HttpService.get(`/v3/cards/${cardId}`);

export const updateCardIdDetails = (cardId, requestPayload) => HttpService.put(`/v3/cards/${cardId}`, requestPayload);

export const updateCardIdLimits = (cardId, requestPayload) => HttpService.put(`/v2/cards/${cardId}/limits`, requestPayload);

export const bulkCardOrderApiRequest = (requestPayload) => HttpService.post('/v2/cards/order-bo', requestPayload);

export const linkCardId = (cardId, requestPayload) => HttpService.post(`/v2/cards/${cardId}/link`, requestPayload);

export const unlinkCardId = (cardId) => HttpService.post(`/v2/cards/${cardId}/unlink`, {});

export const fetchCardProducts = (requestPayload) => HttpService.get('/v2/cards/products', requestPayload);

export const processCardsOrderApi = (requestPayload, queryParams) => HttpService.post('/v2/cards/process-orders', requestPayload, queryParams);

export const getCardIdPin = (cardId) => HttpService.get(`/v2/cards/${cardId}/pin`);

export const unblockCardPinByCardToken = (requestPayload) => HttpService.post('/v2/cards/unblock-pin', requestPayload);

export const getCardIdImage = (cardId) => HttpService.get(`/v2/cards/${cardId}/image`);

export const getNotLinkedCardsApi = (queryParams) => HttpService.get('/v3/cards/not-linked', queryParams);

export const deleteCardId = (cardId) => HttpService.delete(`/v2/cards/${cardId}`);

export const getCardIdStatusHistory = (cardId, queryParams) => HttpService.get(`/v2/cards/${cardId}/status-history`, queryParams);
