import { requestNavigationBack } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import SuccessStep from './SuccessStep.layout';


const mapDispatchToProps = (dispatch) => ({ dispatchRequestNavigationBack: () => dispatch(requestNavigationBack()) });

const decorate = compose(
    withTranslation('cards'),
    connect(null, mapDispatchToProps),
);

export default decorate(SuccessStep);
