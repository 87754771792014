import withAccessControl from 'hocs/withAccessControl';
import { cardPinUnblock } from 'redux/card/actions';
import { cardReducerName } from 'redux/card/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import Settings from './Settings.layout';


const mapStateToProps = (state) => ({ isLoadingUnblockPin: state[cardReducerName].isLoadingUnblockPin });

const mapDispatchToProps = (dispatch) => ({ dispatchCardPinUnblock: (cardId, locationPathname) => dispatch(cardPinUnblock(cardId, locationPathname)) });


const decorate = compose(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(Settings);
