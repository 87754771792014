import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { clearRiskAssessmentsList, fetchRiskAssessments } from 'redux/risk-assessments/actions';
import { riskAssessmentsListsPerLocationStoreKeyName, riskAssessmentsReducerName } from 'redux/risk-assessments/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import RiskAssessmentList from './RiskAssessmentList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: riskAssessmentsReducerName,
        listsByLocationStoreKeyName: riskAssessmentsListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchRiskAssessments: (queryParams, locationPathname) => dispatch(fetchRiskAssessments(queryParams, locationPathname)),
    dispatchClearRiskAssessmentsList: (queryParams, locationPathname) => dispatch(clearRiskAssessmentsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: riskAssessmentsReducerName,
        fieldName: riskAssessmentsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation(),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(RiskAssessmentList);
