import { Counter, IdQueryParams } from 'models/app/common';
import { LocationPathname } from 'models/app/navigation';

import { FailedWebhookListItem } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_FAILED_WEBHOOKS_LIST,
    FETCH_FAILED_WEBHOOKS_LIST,
    FETCH_FAILED_WEBHOOKS_LIST_FAILURE,
    FETCH_FAILED_WEBHOOKS_LIST_SUCCESS,
    REPLAY_ALL_FAILED_WEBHOOKS,
    REPLAY_ALL_FAILED_WEBHOOKS_FAILURE,
    REPLAY_ALL_FAILED_WEBHOOKS_SUCCESS,
} from './actions.types';


export const fetchFailedWebhooksList = createAction(FETCH_FAILED_WEBHOOKS_LIST,
    (queryParams: IdQueryParams, locationPathname: LocationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchFailedWebhooksListSuccess = createAction(FETCH_FAILED_WEBHOOKS_LIST_SUCCESS,
    (items: FailedWebhookListItem, totalCount: Counter, locationPathname: LocationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchFailedWebhooksListFailure = createAction(FETCH_FAILED_WEBHOOKS_LIST_FAILURE, (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));
export const clearFailedWebhooksList = createAction(CLEAR_FAILED_WEBHOOKS_LIST, (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));

export const replayAllFieldWebhooks = createAction(REPLAY_ALL_FAILED_WEBHOOKS, (locationPathname: LocationPathname) => ({ payload: locationPathname }));
export const replayAllFieldWebhooksSuccess = createAction(REPLAY_ALL_FAILED_WEBHOOKS_SUCCESS,
    (responsePayload, locationPathname: LocationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const replayAllFieldWebhooksFailure = createAction(REPLAY_ALL_FAILED_WEBHOOKS_FAILURE, (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));

