/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';
import { cleanNilValues } from 'utils/dataMappers';

import { getCurrenciesAPI } from './index';


class CurrenciesService {
    public fetchCurrencies(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { skipImages } = rawQueryParams;

        return getCurrenciesAPI({ params: { ...cleanNilValues({ skipImages }) } });
    }
}

export default CurrenciesService;
