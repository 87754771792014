import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import UnlockLogin from 'components/common/molecules/UnlockLogin';
import CommonAddressDisplay from 'components/common/organisms/CommonAddressDisplay';
import CommonSection from 'components/common/organisms/CommonSection';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AllEntityTypes } from 'constants/ApplicationStateModel';
import ModalName from 'constants/Modals';
import { PN } from 'constants/PermissionsModel';

import { Col, Row } from 'antd';
import React, { useMemo } from 'react';


import { createMemberPersonalDetailsFields, createMemberSystemDetailsFields } from './PersonDetails.helpers';
import { PersonalDetailsProps } from './PersonalDetails.types';


function PersonalDetails({
    // hoc props
    t,
    accessControl,

    // own props
    isInReadOnlyMode,
    isLoadingMemberDetails,
    queryParamsMeta,
    memberId,
    memberDetails,
    dispatchFetchMemberDetails,

    // state props
    enhancedCurrentLocation,

    dispatchOpenUpdateMemberModal,
    dispatchOpenReassignMemberGroupModal,
    dispatchOpenEditAddressModal,
}:PersonalDetailsProps) {
    const canEditProfile = useMemo(() => accessControl.hasPermission(PN.MEMBERS.UPDATE_PROFILE)
      && !isInReadOnlyMode, [accessControl, isInReadOnlyMode]);

    const canEditAddress = useMemo(() => accessControl.hasPermission(PN.MEMBERS.UPDATE_ADDRESS)
      && !accessControl.isKycKybApprovalEnabled()
      && !isInReadOnlyMode, [accessControl, isInReadOnlyMode]);

    const memberPersonalDetailsFields = useMemo(
        () => createMemberPersonalDetailsFields({
            memberDetails,
            t,
        }),
        [memberDetails],
    );
    const memberSystemDetailsFields = useMemo(
        () => createMemberSystemDetailsFields({
            memberDetails,
            t,
            accessControl,
            dispatchOpenReassignMemberGroupModal,
        }),
        [memberDetails],
    );

    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, 2);

    return (
        <Col
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={isLoadingMemberDetails}
                minHeight={'280px'}
                sectionsRowsCount={8}
                sectionsCount={3}
            >
                <DetailsSectionsWrapper>
                    <Row
                        gutter={24}
                    >
                        <CommonSection
                            sectionLabel={t('member:memberDetails.tabs.general.subHeadings.personalDetails')}
                            sectionEditTooltip={canEditProfile ? t('member:buttons.editPersonalDetails.tooltip') : undefined}
                            fieldsConfig={memberPersonalDetailsFields}
                            sectionEditOnClick={canEditProfile
                                ? () => dispatchOpenUpdateMemberModal(memberDetails)
                                : undefined}
                        />

                        {/* System/status Details */}
                        <CommonSection
                            sectionLabel={t('member:memberDetails.tabs.general.subHeadings.systemDetails')}
                            fieldsConfig={memberSystemDetailsFields}
                        />

                        {/* Address Details */}
                        <CommonAddressDisplay
                            sectionLabel={t('member:memberDetails.tabs.general.subHeadings.address')}
                            sectionEditTooltip={canEditAddress ? t('member:buttons.editAddress.tooltip') : undefined}
                            addressData={memberDetails?.memberAddress}
                            sectionEditOnClick={canEditAddress
                                ? () => dispatchOpenEditAddressModal({
                                    modalType: ModalName.EDIT_MEMBER_ADDRESS_MODAL,
                                    modalTitle: t('member:modals.editAddress.heading'),
                                    addressId: memberDetails?.memberAddress?.id,
                                    initialValues: memberDetails?.memberAddress,
                                    addressType: {
                                        addressType: memberDetails?.memberAddress?.type,
                                        entityId: memberId,
                                        entityType: AllEntityTypes.MEMBER,
                                    },
                                })
                                : undefined}
                            isCamelCase
                        />
                    </Row>

                    <OptionalButtonsWrapper>
                        {!isInReadOnlyMode && (
                            <UnlockLogin
                                extUserUuid={memberDetails?.memberUuid}
                                isLocked={memberDetails?.isPermanentlyLocked}
                                context={{ memberId }}
                            />
                        )}
                    </OptionalButtonsWrapper>

                    <QueryParamsMetaInfo
                        detailsContext
                        onListRefresh={() => dispatchFetchMemberDetails({ memberId }, baseUrlWithoutTabsSlugs)}
                        queryParams={{ memberId }}
                        queryParamsMeta={queryParamsMeta}
                    />
                </DetailsSectionsWrapper>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}


export default PersonalDetails;
