export const FETCH_FEES_LIST = 'FETCH_FEES_LIST';
export const FETCH_FEES_LIST_SUCCESS = 'FETCH_FEES_LIST_SUCCESS';
export const FETCH_FEES_LIST_FAILURE = 'FETCH_FEES_LIST_FAILURE';
export const CLEAR_FEES_LIST = 'CLEAR_FEES_LIST';

export const OPEN_CHARGE_CUSTOM_FEE_MODAL = 'OPEN_CHARGE_CUSTOM_FEE_MODAL';

export const CHARGE_CUSTOM_FEE = 'CHARGE_CUSTOM_FEE';
export const CHARGE_CUSTOM_FEE_SUCCESS = 'CHARGE_CUSTOM_FEE_SUCCESS';
export const CHARGE_CUSTOM_FEE_FAILURE = 'CHARGE_CUSTOM_FEE_FAILURE';
