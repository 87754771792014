import withAccessControl from 'hocs/withAccessControl';
import { openAddClientRiskDetailsModal } from 'redux/client/actions';
import { clientDetailsPerLocationStoreKeyName, clientReducerName } from 'redux/client/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ClientRiskDetails from './ClientRiskDetails.layout';


const mapStateToProps = (state) => {
    const entityDetailsData = getStandardEntityDetailsData({
        state,
        reducerName: clientReducerName,
        fieldName: clientDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    });

    return {
        enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
        countries: getStandardListData({
            state,
            reducerName: countriesReducerName,
            listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
            useGlobalLocation: true,
        })?.items,

        clientDetails: entityDetailsData?.entityDetails,
        riskDetails: state.client.riskDetails,

    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchOpenAddClientRiskDetailsModal: (initialValues, locationPathname) => dispatch(openAddClientRiskDetailsModal(initialValues, locationPathname)),
    //
});


const decorate = compose(
    withTranslation(['common', 'client']),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ClientRiskDetails);
