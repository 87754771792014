import styled from 'styled-components';


const AppLayoutHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: fit-content;
  z-index: 30;
`;

const AppLayoutHeader = styled.div`
  padding: 5px 16px 5px 70px;
  height: 50px;
  background-color: #fff;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto auto auto;
  grid-column-gap: 20px;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0,0,0, .15);
`;


const AppPageHeader = styled.div`
  padding: 0;
 `;

export default {
    AppLayoutHeader,
    AppLayoutHeaderWrapper,
    AppPageHeader,
};

