import StandardTag from 'components/common/atoms/StandardTag';
import getRiskAlertTagProps from 'utils/risk-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function RiskRatingTag({ riskRating, children }) {
    const tagData = useMemo(() => getRiskAlertTagProps(riskRating).filter(({ predicate }) => predicate())[0].handler(), [riskRating]);
    return (
        <StandardTag
            color={tagData.color}
            text={tagData.text}
            showTooltip={false}
        >
            {children}
        </StandardTag>
    );
}

export default RiskRatingTag;

RiskRatingTag.propTypes = {
    riskRating: PropTypes.string,
    children: PropTypes.node,
};
