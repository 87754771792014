export const OPEN_ADD_COMPANY_RISK_DETAILS_MODAL = 'OPEN_ADD_COMPANY_RISK_DETAILS_MODAL';
export const ADD_COMPANY_RISK_DETAILS = 'ADD_COMPANY_RISK_DETAILS';
export const ADD_COMPANY_RISK_DETAILS_SUCCESS = 'ADD_COMPANY_RISK_DETAILS_SUCCESS';
export const ADD_COMPANY_RISK_DETAILS_FAILURE = 'ADD_COMPANY_RISK_DETAILS_FAILURE';
export const FETCH_COMPANY_DETAILS = 'FETCH_COMPANY_DETAILS';
export const FETCH_COMPANY_DETAILS_SUCCESS = 'FETCH_COMPANY_DETAILS_SUCCESS';
export const FETCH_COMPANY_DETAILS_FAILURE = 'FETCH_COMPANY_DETAILS_FAILURE';
export const CLEAR_COMPANY_DETAILS = 'CLEAR_COMPANY_DETAILS';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';
