import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import CreateModalProps from 'constants/ModalModel';

import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function RequestMarketingPreferencesModal({
    t,
    modalProps,
    emailCurrentUser,
    dispatchRequestMarketingPreferences,
    dispatchSetModalProps,
}) {
    const [form] = Form.useForm();
    const { submit } = form;

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => submit() });
    }, []);

    const onFinish = (values) => {
        const { clientId, memberId } = modalProps;

        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchRequestMarketingPreferences({
            format: 'csv',
            ...values,
            ...clientId ? { clientId } : {},
            ...memberId ? { memberId } : {},
        });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ notification_email: emailCurrentUser }}
            onFinish={onFinish}
        >
            <EmailInput
                t={t}
                name="notification_email"
                placeholder={t('marketingPreferences:modals.requestMarketingPreferences.fields.email.placeholder')}
                isRequired
                label={t('marketingPreferences:modals.requestMarketingPreferences.fields.email.label')}
            />
        </Form>
    );
}

export default RequestMarketingPreferencesModal;

RequestMarketingPreferencesModal.propTypes = {
    t: PropTypes.func.isRequired,
    modalProps: PropTypes.shape(CreateModalProps({ clientId: PropTypes.number })),
    emailCurrentUser: PropTypes.string.isRequired,
    dispatchRequestMarketingPreferences: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
};
