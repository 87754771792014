import PropTypes from 'prop-types';

import { UserPreferencesProps } from './UserModel';


const BackofficeUserCommonProps = {
    address: PropTypes.shape({}),
    avatar: PropTypes.string,
    backoffice: PropTypes.bool,
    business: PropTypes.bool,
    client_id: PropTypes.number,
    country_id: PropTypes.number,
    created_at: PropTypes.string,
    email: PropTypes.string,
    ext_user_uuid: PropTypes.string,
    first_name: PropTypes.string,
    id: PropTypes.number,
    is_permanently_locked: PropTypes.bool,
    last_login: PropTypes.string,
    last_name: PropTypes.string,
    locked: PropTypes.bool,
    phone: PropTypes.string,
    role: PropTypes.shape({
        display_name: PropTypes.string,
        description: PropTypes.string,
    }),
    role_id: PropTypes.number,
    status: PropTypes.string,
    two_factor_country_code: PropTypes.string,
    two_factor_phone: PropTypes.string,
    updated_at: PropTypes.string,
    user_preferences: UserPreferencesProps,
    username: PropTypes.string,
};

export const BackofficeUserListItemProps = PropTypes.shape({
    ...BackofficeUserCommonProps,
    //
});

export const BackofficeUserDetailsProps = PropTypes.shape({
    ...BackofficeUserCommonProps,
    //
});


export default { BackofficeUserDetailsProps, BackofficeUserListItemProps };
