import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_TOPUP_LIST,
    FETCH_TOPUP_LIST_SUCCESS,
    FETCH_TOPUP_LIST_FAILURE,
    CLEAR_TOPUP_LIST,
} from './actions.types';


export const fetchTopupList = createAction(FETCH_TOPUP_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchTopupListSuccess = createAction(FETCH_TOPUP_LIST_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchTopupListFailure = createAction(FETCH_TOPUP_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearTopupList = createAction(CLEAR_TOPUP_LIST, (locationPathname) => ({ payload: { locationPathname } }));
