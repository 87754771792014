import { standardPageSize } from 'config/config';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { clearRolesList, fetchRolesList, fetchRolesListFailure, fetchRolesListSuccess } from 'redux/roles/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const rolesReducerName = 'roles';
export const rolesListsPerLocationStoreKeyName = 'rolesListsPerLocation';

const initialRolesListState = {
    [rolesListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    // ?
};


export const defaultRolesListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};


const initialState = { ...initialRolesListState };

const handlers = {
    [fetchRolesList]: (state, action) => {
        state[rolesListsPerLocationStoreKeyName] = updateStandardLists(state[rolesListsPerLocationStoreKeyName], action);
    },
    [fetchRolesListSuccess]: (state, action) => {
        state[rolesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[rolesListsPerLocationStoreKeyName], action);
    },
    [fetchRolesListFailure]: (state, action) => {
        state[rolesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[rolesListsPerLocationStoreKeyName], action);
    },
    [clearRolesList]: (state, action) => {
        state[rolesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[rolesListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === rolesReducerName
      && action.payload?.fieldName === rolesListsPerLocationStoreKeyName
        ) {
            state[rolesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[rolesListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
