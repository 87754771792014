export const FETCH_ALL_BENEFICIARIES = 'FETCH_ALL_BENEFICIARIES';
export const FETCH_ALL_BENEFICIARIES_SUCCESS = 'FETCH_ALL_BENEFICIARIES_SUCCESS';
export const FETCH_ALL_BENEFICIARIES_FAILURE = 'FETCH_ALL_BENEFICIARIES_FAILURE';

export const FETCH_ALL_ACCOUNTS = 'FETCH_ALL_ACCOUNTS';
export const FETCH_ALL_ACCOUNTS_SUCCESS = 'FETCH_ALL_ACCOUNTS_SUCCESS';
export const FETCH_ALL_ACCOUNTS_FAILURE = 'FETCH_ALL_ACCOUNTS_FAILURE';

export const INITIALISE_OUTGOING_TRANSFER_FORM = 'INITIALISE_OUTGOING_TRANSFER_FORM';

export const SET_SELECTED_BENEFICIARY_DETAILS = 'SET_SELECTED_BENEFICIARY_DETAILS';
export const SET_SELECTED_ACCOUNT_DETAILS = 'SET_SELECTED_ACCOUNT_DETAILS';

export const SUBMIT_OUTGOING_TRANSFER_FORM_STEP = 'SUBMIT_OUTGOING_TRANSFER_FORM_STEP';
export const SET_OUTGOING_TRANSFER_FORM_STEP = 'SET_OUTGOING_TRANSFER_FORM_STEP';

export const GO_TO_PREVIOUS_OUTGOING_TRANSFER_FORM_STEP = 'GO_TO_PREVIOUS_OUTGOING_TRANSFER_FORM_STEP';

export const CREATE_OUTGOING_TRANSFER = 'CREATE_OUTGOING_TRANSFER';
export const CREATE_OUTGOING_TRANSFER_SUCCESS = 'CREATE_OUTGOING_TRANSFER_SUCCESS';
export const CREATE_OUTGOING_TRANSFER_FAILURE = 'CREATE_OUTGOING_TRANSFER_FAILURE';

export const CLEAR_OUTGOING_TRANSFER_DATA = 'CLEAR_OUTGOING_TRANSFER_DATA';

export const REQUEST_RESET_FORM = 'REQUEST_RESET_FORM';
