import { hideNavigationConfirmation, navigationCanceled, requestNavigation, requestNavigationBack, unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import NavigationGuard from './NavigationGuard.layout';


const mapStateToProps = (state) => ({
    isNavigationConfirmationVisible: state[navigationReducerName].isNavigationConfirmationVisible,
    requestedNextLocation: state[navigationReducerName].requestedNextLocation,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigationCanceled: () => dispatch(navigationCanceled()),
    dispatchUnblockNavigation: () => dispatch(unblockNavigation()),
    dispatchRequestNavigation: (location, meta) => dispatch(requestNavigation(location, meta)),
    dispatchRequestNavigationBack: () => dispatch(requestNavigationBack()),
    dispatchHideNavigationConfirmation: () => dispatch(hideNavigationConfirmation()),
});


const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(NavigationGuard);

