import svgIcons from 'assets/images/icons/svgIcons';
import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { WL_CONFIG } from 'config/config';

import React from 'react';

import Styled from './PrivacyPolicy.styled';


function PrivacyPolicy({ t }) {
    return (
        <>
            <StandardPageHeading title={t('screenTitle')} />

            <StandardPageWrapper>
                <Styled.PrivacyPolicyWrapper>
                    <div>
                        <h2>
                            {t('sections.intro.heading')}
                        </h2>
                        <p>
                            <StandardTranslationWithMarkup
                                translationKey={'sections.intro.explanation'}
                                namespace="privacyPolicy"
                                values={{
                                    brandFullName: WL_CONFIG.brandFullName,
                                    applicationName: WL_CONFIG.applicationName,
                                }}
                                t={t}
                            />
                        </p>
                    </div>

                    <div>
                        <h2>
                            {t('sections.cookies.heading')}
                        </h2>
                        <p>
                            <StandardTranslationWithMarkup
                                translationKey={'sections.cookies.explanation'}
                                namespace="privacyPolicy"
                                values={{
                                    brandFullName: WL_CONFIG.brandFullName,
                                    applicationName: WL_CONFIG.applicationName,
                                }}
                                t={t}
                            />
                            <Styled.IconDecoration
                                src={svgIcons.cookiesIcon}
                                alt="Decoration illustration picturing classic cookie"
                            />
                        </p>

                    </div>

                    <div>
                        <h2>
                            {t('sections.securitySessionStorage.heading')}
                        </h2>
                        <p>
                            <StandardTranslationWithMarkup
                                translationKey={'sections.securitySessionStorage.explanation'}
                                namespace="privacyPolicy"
                                values={{ applicationName: WL_CONFIG.applicationName }}
                                t={t}
                            />
                            <Styled.IconDecoration
                                src={svgIcons.shieldLock}
                                alt="Decoration illustration picturing classic shield and lock"
                            />
                        </p>

                    </div>
                    <div>
                        <h2>
                            {t('sections.securityHttps.heading')}
                        </h2>
                        <p>
                            <StandardTranslationWithMarkup
                                translationKey={'sections.securityHttps.explanation'}
                                namespace="privacyPolicy"
                                values={{ brandFullName: WL_CONFIG.brandFullName }}
                                t={t}
                            />

                            <Styled.IconDecoration
                                src={svgIcons.encryption}
                                alt="Decoration illustration picturing circuit board"
                            />
                        </p>
                    </div>
                    <div>
                        <h2>
                            {t('sections.summary.heading')}
                        </h2>
                        <p>
                            <StandardTranslationWithMarkup
                                translationKey={'sections.summary.explanation'}
                                namespace="privacyPolicy"
                                values={{ brandFullName: WL_CONFIG.brandFullName }}
                                t={t}
                            />
                            <Styled.IconDecoration
                                src={svgIcons.authentication}
                                alt="Decoration illustration picturing user inside a shield shape"
                            />
                        </p>
                    </div>
                </Styled.PrivacyPolicyWrapper>
            </StandardPageWrapper>
        </>
    );
}

export default PrivacyPolicy;
