import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import StatusHistory from './StatusHistoryList.layout';
import {
    StatusHistoryListDispatchProps,
    StatusHistoryListOwnProps,
    StatusHistoryListStateProps,
} from './StatusTimeline.types';


const mapStateToProps = (state: RootState, ownProps: StatusHistoryListOwnProps): StatusHistoryListStateProps => {
    const { reducerName, listsByLocationStoreKeyName } = ownProps;
    return {
        enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
        listData: getStandardListData({
            state,
            reducerName,
            listsByLocationStoreKeyName,
        }),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: StatusHistoryListOwnProps): StatusHistoryListDispatchProps => {
    const { reducerName, listsByLocationStoreKeyName, fetcherListAction, clearListAction } = ownProps;
    return {
        dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
            reducerName,
            fieldName: listsByLocationStoreKeyName,
            locationPathname,
            queryParams,
        })),
        dispatchFetcherListAction: (queryParams, locationPathname) => dispatch(fetcherListAction(queryParams, locationPathname)),
        dispatchClearListAction: (locationPathname) => dispatch(clearListAction(locationPathname)),
    };
};

const decorate = compose<ComponentType<StatusHistoryListOwnProps>>(
    withTranslation(),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(StatusHistory);
