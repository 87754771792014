import svgIcons from 'assets/images/icons/svgIcons';
import ErrorMessage from 'components/common/atoms/ErrorMessage/ErrorMessage';
import StandardLink from 'components/common/molecules/StandardLink';
import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import { UnauthorisedScreenTitle, UnauthorisedLinkWrapper } from 'components/layouts/PublicLayout/PublicLayout.styled';
import { loginRootRoutePath } from 'config/routes';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Button } from 'antd';
import React, { useEffect, useState } from 'react';


import Styled from './ForgotPassword.styled';
import { ForgotPasswordProps } from './ForgotPassword.types';


function ForgotPassword({
    isLoadingForgetPassword,
    dispatchRequestPasswordReset,
    dispatchClearRequestPasswordReset,
    passwordResetLinkSent,
    t,
}:ForgotPasswordProps) {
    const [message] = useState('');
    const [error] = useState(null);


    const onFormFinish = (values) => {
        dispatchRequestPasswordReset(values);
    };

    useEffect(() => dispatchClearRequestPasswordReset, [dispatchClearRequestPasswordReset]);

    return passwordResetLinkSent
        ? (
            <div>
                <UnauthorisedScreenTitle>
                    {t('unauthorised:actionMessages.requestPasswordResetSuccess')}
                </UnauthorisedScreenTitle>

                <Styled.IconDecoration
                    src={svgIcons.emailSent}
                    alt="Decoration illustration picturing envelope in movement"
                />
            </div>
        ) : (
            <>
                <UnauthorisedScreenTitle>
                    {t('unauthorised:screenTitles.forgotPassword')}
                </UnauthorisedScreenTitle>

                <Form onFinish={onFormFinish}>
                    <EmailInput
                        t={t}
                        name="email"
                        isRequired
                        hideLabel
                    />

                    <Form.Item style={{ paddingTop: 10 }}>
                        <Button
                            block
                            htmlType="submit"
                            type="primary"
                            data-test-id="submit-reset-password-button"
                            loading={isLoadingForgetPassword}
                        >
                            {t('unauthorised:formsElements.resetPassword.text')}
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <UnauthorisedLinkWrapper>
                            <StandardLink
                                icon={<ArrowLeftOutlined />}
                                path={loginRootRoutePath}
                                title={t('unauthorised:formsElements.backToLoginLink.text')}
                                dataTestId="back-to-login-link"
                            />
                        </UnauthorisedLinkWrapper>
                    </Form.Item>

                    {error && <ErrorMessage text={message} dataTestIdPrefix="reset-password" />}
                </Form>
            </>
        );
}

export default ForgotPassword;
