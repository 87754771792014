import { createCommonAddressFieldsConfig } from 'components/common/organisms/addressFields.helpers';
import PartnerSelection from 'components/pages/authorised/AddCompanyPage/AddCompanyForm/PartnerSelection';
import FirstBusinessUser from 'components/pages/authorised/AddCompanyPage/AddCompanyForm/steps/FirstBusinessUser';
import { AddCompanyStepsDefinitions } from 'constants/CompaniesModel';
import { CommonFormFieldTypes, MultiStepFormStepSectionFieldsLayout, MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';
import { createCompanyTypesOptions } from 'utils/options-creators';
import { commonValidation, isValidEmail, isValidPhoneNumberRule, requiredRule } from 'utils/validation-tools';

import { TagOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';


function createCompanyBasicDataFromFieldsConfig({ t }) {
    return [
        {
            name: 'company_name',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Company name'),
            placeholder: 'Enter company name',
            validationRules: [
                ...commonValidation({
                    t,
                    isRequired: true,
                }),
            ],
        },

        {
            name: 'company_registration_number',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Company registration number'),
            placeholder: 'Enter company registration number',
            validationRules: commonValidation({
                t,
                fieldName: 'company_registration_number',
                isRequired: true,
            }),
        },
        {
            name: 'company_type',
            fieldType: CommonFormFieldTypes.SELECT,
            label: t('Company type'),
            placeholder: 'Select company type',
            validationRules: requiredRule(t),
            customOptions: createCompanyTypesOptions(),
        },
        {
            name: 'company_email',
            fieldType: CommonFormFieldTypes.EMAIL,
            label: t('Company email'),
            placeholder: 'Enter company email',
            validationRules: [
                ...commonValidation({
                    t,
                    isRequired: true,
                }),
                ...isValidEmail(t)],
        },
        {
            name: 'companyPhoneNumber',
            fieldType: CommonFormFieldTypes.PHONE_NUMBER,
            label: t('common:personalFields.phoneNumber.label'),
            placeholder: t('common:personalFields.phoneNumber.placeholder'),
            validationRules: [
                ...requiredRule(t),
                ...isValidPhoneNumberRule(t),
            ],
        },

    ];
}

export function createCompanyDetailsFormStepConfig({ t, clientId }) {
    return {
        stepName: AddCompanyStepsDefinitions.COMPANY_DETAILS,
        title: t('Basic Details'),
        icon: <TagOutlined />,
        sectionsLayout: MultiStepFormStepSectionTypes.AUTO_ROWS,
        stepSections: [
            ...(!clientId > 0 ? [
                {
                    fieldsLayout: MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL,
                    title: 'Partner',
                    children: <PartnerSelection />,
                },
            ] : []),
            {
                fieldsLayout: MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL,
                title: 'Details',
                fieldsConfiguration: createCompanyBasicDataFromFieldsConfig({ t }),
            },
        ],
    };
}

export function createFirstBusinessUserFormStepConfig({ t }) {
    return {
        stepName: AddCompanyStepsDefinitions.FIRST_BUSINESS_USER,
        title: t('Business user'),
        icon: <UserOutlined />,
        children: <FirstBusinessUser />,
    };
}


export function createCompanyAddressFormStepConfig({ t }) {
    return {
        stepName: AddCompanyStepsDefinitions.ADDRESSES,
        title: t('Company address'),
        icon: <TagOutlined />,
        sectionsLayout: MultiStepFormStepSectionTypes.AUTO_ROWS,
        stepSections: [
            {
                fieldsLayout: MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL,
                title: 'Address details',
                fieldsConfiguration: [
                    ...createCommonAddressFieldsConfig({
                        isDisabled: false,
                        fieldsNamePrefix: 'company_address',
                        t,
                        useStateField: false,
                        usePhoneField: false,
                        usePremiseField: false,
                    }),
                ],
            },
        ],
    };
}


export default { createCompanyDetailsFormStepConfig, createCompanyAddressFormStepConfig, createFirstBusinessUserFormStepConfig };
