import GroupMembersTab from 'components/common/templates/lists/GroupMembersList';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';

import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';


function AddMembersToGroup({ t }) {
    const { clientId, groupId } = useParams();

    return (
        <>
            <StandardPageHeading title={t('groups:addMembersToGroupScreenTitle')} />
            <StandardPageWrapper>
                <GroupMembersTab
                    isNonMembersVersion
                    clientId={Number(clientId)}
                    groupId={Number(groupId)}
                />
            </StandardPageWrapper>
        </>
    );
}


AddMembersToGroup.propTypes = { t: PropTypes.func.isRequired };

export default AddMembersToGroup;
