import StandardTooltip from 'components/common/molecules/StandardTooltip';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';


const booleanAsIconsRenderer = (
    record,
    key,
    t,
    yesTooltip,
    noTooltip,
    inverseValue = false,
) => {
    const value = inverseValue ? !record?.[key] : record?.[key];

    if (value === false || Boolean(value) === false || Number(value) === 0) {
        return (
            <StandardTooltip
                title={noTooltip || t('common:no')}
                showCopyToClipboard={false}
                isTextEllipsisDisabled
            >
                <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
            </StandardTooltip>
        );
    }
    if (value === true || Boolean(value) === true || Number(value) === 1) {
        return (
            <StandardTooltip
                title={yesTooltip || t('common:yes')}
                showCopyToClipboard={false}
                isTextEllipsisDisabled
            >
                <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
            </StandardTooltip>
        );
    }

    return null;
};


export default booleanAsIconsRenderer;
