/* eslint-disable import/no-unresolved */
import /* webpackPreload: true */ /* webpackChunkName: "maintenance_cogs" */ maintenanceCogs
    from 'assets/images/maintenance-cogs.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "no_card_image_placeholder" */ noCardImagePlaceholder
    from 'assets/images/no-card-iamge-placeholder.png';


const images = {
    noCardImagePlaceholder,
    maintenanceCogs,
};


export default images;
