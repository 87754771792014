export const FETCH_COMPANY_STATUS_HISTORY_LIST = 'FETCH_COMPANY_STATUS_HISTORY_LIST';
export const FETCH_COMPANY_STATUS_HISTORY_LIST_SUCCESS = 'FETCH_COMPANY_STATUS_HISTORY_LIST_SUCCESS';
export const FETCH_COMPANY_STATUS_HISTORY_LIST_FAILURE = 'FETCH_COMPANY_STATUS_HISTORY_LIST_FAILURE';

export const CLEAR_COMPANY_STATUS_LIST_HISTORY = 'CLEAR_COMPANY_STATUS_LIST_HISTORY';

export const UPDATE_COMPANY_STATUS = 'UPDATE_COMPANY_STATUS';
export const UPDATE_COMPANY_STATUS_SUCCESS = 'UPDATE_COMPANY_STATUS_SUCCESS';
export const UPDATE_COMPANY_STATUS_FAILURE = 'UPDATE_COMPANY_STATUS_FAILURE';

export const CLEAR_RESET_FORM = 'CLEAR_RESET_FORM';
