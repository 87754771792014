import StandardTooltip from 'components/common/molecules/StandardTooltip';
import { isoDateFormat, standardDateFormat } from 'config/config';
import { getDateRangeForPreviousMonth, preventDatesFormFuture } from 'utils/date-time-tools';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Form, Popover } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import Styled from './DownlaodAccountStatemnet.styled';


function DownloadAccountStatement({
    // ownProps
    accountId,
    currency,

    // mapped props,
    downloadPdfStatementData,
    t,
    userPreferences,
    dispatchShowDownloadPdfStatementForm,
    dispatchHideDownloadPdfStatementForm,
    dispatchDownloadAccountStatementPdf,

}) {
    const [form] = Form.useForm();

    const dateFormat = useMemo(
        () => userPreferences?.dateFormat || standardDateFormat,
        [userPreferences],
    );

    const defaultDates = getDateRangeForPreviousMonth();

    const onFinish = (values) => {
        dispatchDownloadAccountStatementPdf({
            accountId,
            currency,
            dateFrom: values?.timeRange?.[0].format(isoDateFormat),
            dateTo: values?.timeRange?.[1].format(isoDateFormat),
            // TODO BE error prevents this now but this is how it should be:
            // dateFrom: values?.timeRange?.[0].toISOString(),
            // dateTo: values?.timeRange?.[1].toISOString(),
        });
    };

    const popupContent = () => (
        <Styled.FormContent>
            <h4>
                {`Download PDF statement for account #${accountId}`}
            </h4>
            <hr />
            <Form
                form={form}
                layout="vertical"
                initialValues={defaultDates}
                onFinish={onFinish}
            >
                <Form.Item
                    name="timeRange"
                    required
                    label={t('Selected time range')}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (value && value[0] && value[1]) { return Promise.resolve(); }
                                return Promise.reject(new Error(t('common:validationMsg.fieldMandatory')));
                            },
                        }),
                    ]}
                >
                    <Styled.FullWidthDatePicker.RangePicker
                        placeholder={[t('Select date form'), t('Select date to')]}
                        format={dateFormat}
                        allowClear={false}
                        disabledDate={preventDatesFormFuture}
                    />
                </Form.Item>

                <Styled.ButtonsWrapper>
                    <Button
                        htmlType="reset"
                        onClick={() => dispatchHideDownloadPdfStatementForm(accountId)}
                        disabled={downloadPdfStatementData.isDownloading}
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={downloadPdfStatementData.isDownloading}
                        loading={downloadPdfStatementData.isDownloading}
                        icon={<DownloadOutlined />}
                    >
                        {t('Download')}
                    </Button>

                </Styled.ButtonsWrapper>
            </Form>
        </Styled.FormContent>
    );


    return (
        <StandardTooltip
            title={t('Click download account statement PDF')}
        >
            <Popover
                okText={t('Download')}
                trigger="click"
                placement="left"
                open={downloadPdfStatementData.isFormVisible}
                getPopupContainer={(trigger) => trigger.parentElement?.parentElement}
                content={popupContent}
            >
                <Button
                    htmlType="button"
                    shape="circle"
                    loading={downloadPdfStatementData.isDownloading}
                    disabled={downloadPdfStatementData.isDownloading}
                    icon={<DownloadOutlined />}
                    onClick={() => dispatchShowDownloadPdfStatementForm(accountId)}
                />

            </Popover>
        </StandardTooltip>
    );
}

DownloadAccountStatement.propTypes = {
    // own props
    accountId: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    // mapped props
    dispatchDownloadAccountStatementPdf: PropTypes.func.isRequired,
    dispatchShowDownloadPdfStatementForm: PropTypes.func.isRequired,
    dispatchHideDownloadPdfStatementForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    downloadPdfStatementData: PropTypes.shape({
        isFormVisible: PropTypes.bool,
        isDownloading: PropTypes.bool,
    }),
};


export default DownloadAccountStatement;
