import { AllEntityTypes } from 'constants/ApplicationStateModel';
import { updateAddress, updateAddressFailure, updateAddressSuccess } from 'redux/address/actions';
import { setQueryParams } from 'redux/application/actions';
import {
    CLEAR_COMPANY_DETAILS, FETCH_COMPANY_DETAILS,
    FETCH_COMPANY_DETAILS_FAILURE,
    FETCH_COMPANY_DETAILS_SUCCESS,
    UPDATE_COMPANY,
    UPDATE_COMPANY_FAILURE,
    UPDATE_COMPANY_SUCCESS,
} from 'redux/company/action.types';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';


import { addCompanyRiskDetails, addCompanyRiskDetailsSuccess, addCompanyRiskDetailsFailure } from './actions';


export const companyReducerName = 'company';
export const companyDetailsPerLocationsStoreKeyName = 'companyDetailsPerLocations';

const initialState = {
    [companyDetailsPerLocationsStoreKeyName]: {}, // XXX 'pathname': {...standard }

    isLoadingCompanyUpdate: false,
    isLoadingCompanyAddressUpdate: false,
    isLoadingCompanyDelete: false,
    isLoadingAddRiskDetails: false,
};

const handlers = {
    [FETCH_COMPANY_DETAILS]: (state, action) => {
        state[companyDetailsPerLocationsStoreKeyName] = updateStandardEntityDetails(state[companyDetailsPerLocationsStoreKeyName], action);
    },
    [FETCH_COMPANY_DETAILS_SUCCESS]: (state, action) => {
        state[companyDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[companyDetailsPerLocationsStoreKeyName], action);
    },
    [FETCH_COMPANY_DETAILS_FAILURE]: (state, action) => {
        state[companyDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnFailure(state[companyDetailsPerLocationsStoreKeyName], action);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === companyReducerName
      && action.payload?.fieldName === companyDetailsPerLocationsStoreKeyName
        ) {
            state[companyDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[companyDetailsPerLocationsStoreKeyName] }, action);
        }
    },

    [CLEAR_COMPANY_DETAILS]: () => initialState,

    [UPDATE_COMPANY]: (state) => {
        state.isLoadingCompanyUpdate = true;
    },
    [UPDATE_COMPANY_SUCCESS]: (state, action) => {
        state.isLoadingCompanyUpdate = false;
        state[companyDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[companyDetailsPerLocationsStoreKeyName],
            { payload: action.payload });
    },
    [UPDATE_COMPANY_FAILURE]: (state) => {
        state.isLoadingCompanyUpdate = false;
    },

    [updateAddress]: (state, action) => {
        if (action.payload.addressType === AllEntityTypes.COMPANY) {
            state.isLoadingCompanyAddressUpdate = true;
        }
    },
    [updateAddressSuccess]: (state, action) => {
        if (action.payload.addressType === AllEntityTypes.COMPANY) {
            const { locationPathname } = action.payload;
            const entityDetails = state[companyDetailsPerLocationsStoreKeyName]?.[locationPathname]?.entityDetails;

            const mockAction = {
                payload: {
                    locationPathname,
                    responsePayload: {
                        ...entityDetails,
                        company_address: {
                            ...action.payload.responseData,
                            address_id: action.payload.responseData?.id,
                        },
                    },
                },
            };
            state.isLoadingCompanyAddressUpdate = false;
            state[companyDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[companyDetailsPerLocationsStoreKeyName], mockAction);
        }
    },
    [updateAddressFailure]: (state, action) => {
        if (action.payload.addressType === AllEntityTypes.COMPANY) {
            state.isLoadingCompanyAddressUpdate = false;
        }
    },


    [addCompanyRiskDetails]: (state) => {
        state.isLoadingAddRiskDetails = true;
    },
    [addCompanyRiskDetailsSuccess]: (state) => {
        state.isLoadingAddRiskDetails = false;
    },
    [addCompanyRiskDetailsFailure]: (state) => {
        state.isLoadingAddRiskDetails = false;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
