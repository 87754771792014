/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import {
    initAPI,
    confirmAPI,
    rejectAPI,
    fetchRateAPI,
} from './index';


class ExchangeService {
    public init(requestPayload): Promise<ApiResponse | ApiError> {
        return initAPI(requestPayload);
    }

    public confirm(exchangeId): Promise<ApiResponse | ApiError> {
        return confirmAPI(exchangeId);
    }

    public reject(exchangeId): Promise<ApiResponse | ApiError> {
        return rejectAPI(exchangeId);
    }

    public fetchRate(from, to): Promise<ApiResponse | ApiError> {
        return fetchRateAPI(from, to);
    }
}

export default ExchangeService;
