import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import UnlockLogin from 'components/common/molecules/UnlockLogin';
import CommonSection from 'components/common/organisms/CommonSection';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps } from 'constants/ApplicationStateModel';
import { BackofficeUserDetailsProps } from 'constants/BackofficeUserModel';
import { createEntityDetailsProps } from 'constants/EntityDetailsModel';
import { PN } from 'constants/PermissionsModel';
import { UserStatuses } from 'constants/UserModel';

import { MailOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';


import { createUserFieldsConfigCol1, createUserFieldsConfigCol2 } from './UserInformation.helpers';


function UserInformation({
    t,
    accessControl,
    enhancedCurrentLocation,

    entityDetailsData,
    isLoadingUserDetails,
    queryParamsMeta,

    dispatchFetchUserDetails,
    dispatchDeleteUser,
    dispatchResendConfirmationEmail,
    dispatchOpenAddEditUserModal,
    dispatchBanUser,
}) {
    const { userId } = useParams();
    const fixedId = Number(userId);

    const entityDetails = entityDetailsData?.entityDetails;
    const isLoadingDetails = entityDetailsData?.isLoadingDetails && isLoadingUserDetails;


    const onEditClickHandler = () => dispatchOpenAddEditUserModal({
        title: t('modals.addEditUserModal.editTitle'),
        initialValues: entityDetails,
        okButtonProps: { disabled: false },
    });

    const commonQueryParams = { userId };
    const onDeleteClickHandler = () => dispatchDeleteUser({ userId: fixedId });

    const onBanClickHandler = () => dispatchBanUser(commonQueryParams, enhancedCurrentLocation.pathname);

    const onResendClickHandler = () => dispatchResendConfirmationEmail(commonQueryParams);

    const onRefreshClickHandler = () => dispatchFetchUserDetails(commonQueryParams, enhancedCurrentLocation?.pathname);

    const fieldsConfigCol1 = useMemo(
        () => createUserFieldsConfigCol1({ userDetails: entityDetails, t }),
        [entityDetails],
    );

    const fieldsConfigCol2 = useMemo(
        () => createUserFieldsConfigCol2({ userDetails: entityDetails, t, accessControl }),
        [entityDetails],
    );

    const canWrite = useMemo(() => (accessControl.hasPermission(PN.USERS.WRITE)), [accessControl]);

    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={isLoadingDetails}
                minHeight={'150px'}
                sectionsRowsCount={5}
                sectionsCount={2}
            >
                <DetailsSectionsWrapper>
                    <Row gutter={24} size={10} direction="horizontal">
                        <CommonSection
                            sectionLabel={t('userDetails.sectionLabel')}
                            sectionEditTooltip={t('userDetails.sectionEditTooltip')}
                            fieldsConfig={fieldsConfigCol1}
                            isLoading={isLoadingDetails}
                            sectionEditOnClick={canWrite ? onEditClickHandler : undefined}
                            sectionDeleteOnClick={canWrite ? onDeleteClickHandler : undefined}
                            sectionDeleteTooltip={t('userDetails.sectionDeleteTooltip')}
                            sectionDeleteConfirmationText={t('userDetails.sectionDeleteConfirmationText')}
                            sectionAdditionalButtons={canWrite && (
                                <>
                                    {UserStatuses.BANNED !== entityDetails?.status && (
                                        <StandardTooltip title={t('userDetails.banUser')}>
                                            <Popconfirm title={t('userDetails.banUserConfirmationText')} onConfirm={onBanClickHandler}>
                                                <Button shape="circle" icon={<StopOutlined />} />
                                            </Popconfirm>
                                        </StandardTooltip>
                                    )}

                                    {UserStatuses.UNCONFIRMED === entityDetails?.status && (
                                        <StandardTooltip title={t('userDetails.resendEmail')}>
                                            <Popconfirm title={t('userDetails.resendEmailConfirmationText')} onConfirm={onResendClickHandler}>
                                                <Button shape="circle" icon={<MailOutlined />} />
                                            </Popconfirm>
                                        </StandardTooltip>
                                    )}
                                </>
                            )}
                        />
                        <CommonSection
                            sectionLabel={'System info'}
                            fieldsConfig={fieldsConfigCol2}
                            isLoading={isLoadingDetails}
                        />
                    </Row>

                    <OptionalButtonsWrapper>
                        <UnlockLogin
                            extUserUuid={entityDetails?.ext_user_uuid}
                            isLocked={entityDetails?.is_permanently_locked}
                            context={{ userId: fixedId, locationPathname: enhancedCurrentLocation?.pathname }}
                        />
                    </OptionalButtonsWrapper>

                    <QueryParamsMetaInfo
                        detailsContext
                        onListRefresh={onRefreshClickHandler}
                        queryParams={{ userId }}
                        queryParamsMeta={queryParamsMeta}
                    />
                </DetailsSectionsWrapper>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}

UserInformation.propTypes = {
    accessControl: AccessControlProps,
    t: PropTypes.func.isRequired,
    queryParamsMeta: CommonQueryParamsMetaProps,

    isLoadingUserDetails: PropTypes.bool.isRequired,
    entityDetailsData: createEntityDetailsProps(BackofficeUserDetailsProps),
    dispatchFetchUserDetails: PropTypes.func.isRequired,
    dispatchDeleteUser: PropTypes.func.isRequired,
    dispatchBanUser: PropTypes.func.isRequired,
    dispatchResendConfirmationEmail: PropTypes.func.isRequired,
    dispatchOpenAddEditUserModal: PropTypes.func.isRequired,
};
export default UserInformation;
