import StandardListTable from 'components/common/organisms/StandardListTable';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { TopupCardsListProp } from 'constants/TopupModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultTopupListQueryParams } from 'redux/topup/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { createTopupCardsListColumns } from './tableColumns';


function TopupCardsList({
    // ownProps
    memberId,
    clientId,
    businessUserId,
    showOwnerIdsColumns = false,

    // XXX  list state data
    enhancedCurrentLocation,
    listData,

    dispatchFetchTopupList,
    dispatchClearTopupList,
    dispatchSetQueryParams,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ...(memberId > 0 ? { memberId } : {}),
        ...(businessUserId > 0 ? { businessUserId } : {}),
        ...(clientId > 0 ? { clientId } : {}),
    };

    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultTopupListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchTopupList,
        dispatchClearItemsList: dispatchClearTopupList,
        dispatchSetQueryParams,
    });

    // 4. list specific stuff - local search, local sorting is in table configuration
    const filteredTopups = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((topup) => searchCaseInsensitive(topup?.card_holder, listData?.queryParams?.search)
          || searchCaseInsensitive(topup?.expiry, listData?.queryParams?.search)
          || searchCaseInsensitive(topup?.pan_masked, listData?.queryParams?.search)
          || searchCaseInsensitive(topup?.scheme, listData?.queryParams?.search)
          || searchCaseInsensitive(`${topup?.account_id}`, listData?.queryParams?.search)
          || searchCaseInsensitive(`${topup?.member_id}`, listData?.queryParams?.search)
          || searchCaseInsensitive(`${topup?.business_user_id}`, listData?.queryParams?.search)
          || searchCaseInsensitive(`${topup?.id}`, listData?.queryParams?.search));
            }

            return listData?.items || [];
        }, [listData],
    );


    // 5. standard render component
    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchTopupList({ ...listData?.queryParams }, enhancedCurrentLocation.pathname)}
            items={filteredTopups}
            totalItemsCount={filteredTopups.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createTopupCardsListColumns({
                t,
                queryParams: listData?.queryParams,
                showOwnerIdsColumns,
            })}
        />
    );
}


TopupCardsList.propTypes = {
    dispatchClearTopupList: PropTypes.func.isRequired,
    showOwnerIdsColumns: PropTypes.bool,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchFetchTopupList: PropTypes.func.isRequired,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: TopupCardsListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    t: PropTypes.func.isRequired,
};

export default TopupCardsList;

