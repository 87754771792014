import { SortDirections } from 'constants/ApplicationStateModel';
import { CardOrderTarget, CardType } from 'constants/CardModel';
import {
    fetchCardMatrix,
    fetchDeliveryAddress,
    processCardsOrder,
    setOrderStep,
    getTargetListSuccess,
    getTargetListFailure, fetchDeliveryAddressSuccess, orderCards,
} from 'redux/card-order/actions';

import { EMPTY, from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const CardOrderSteps = Object.freeze({
    TARGET_SELECTION: 'TARGET_SELECTION',
    CARD_DETAILS: 'CARD_DETAILS',
    DELIVERY_DETAILS: 'DELIVERY_DETAILS',
    REVIEW: 'REVIEW',
    SUCCESS: 'SUCCESS',
});

export const maxUnitCount = 100;

export const mapCurrentStepToNumber = (
    currentStep,
    cardType,
    showTargetSelectionStep,
) => {
    const handlers = [
        {
            predicate: () => currentStep === CardOrderSteps.TARGET_SELECTION,
            handler: () => 0,
        },
        {
            predicate: () => currentStep === CardOrderSteps.CARD_DETAILS && !showTargetSelectionStep,
            handler: () => 0,
        },
        {
            predicate: () => currentStep === CardOrderSteps.CARD_DETAILS && showTargetSelectionStep,
            handler: () => 1,
        },
        {
            predicate: () => currentStep === CardOrderSteps.DELIVERY_DETAILS && showTargetSelectionStep && cardType === CardType.PHYSICAL,
            handler: () => 2,
        },
        {
            predicate: () => currentStep === CardOrderSteps.DELIVERY_DETAILS && !showTargetSelectionStep && cardType === CardType.PHYSICAL,
            handler: () => 1,
        },
        {
            predicate: () => currentStep === CardOrderSteps.REVIEW && showTargetSelectionStep && cardType === CardType.PHYSICAL,
            handler: () => 3,
        },
        {
            predicate: () => currentStep === CardOrderSteps.REVIEW && !showTargetSelectionStep && cardType === CardType.PHYSICAL,
            handler: () => 2,
        },
        {
            predicate: () => currentStep === CardOrderSteps.REVIEW && showTargetSelectionStep && cardType === CardType.VIRTUAL,
            handler: () => 2,
        },
        {
            predicate: () => currentStep === CardOrderSteps.REVIEW && !showTargetSelectionStep && cardType === CardType.VIRTUAL,
            handler: () => 1,
        },
        {
            predicate: () => currentStep === CardOrderSteps.SUCCESS && showTargetSelectionStep && cardType === CardType.PHYSICAL,
            handler: () => 4,
        },
        {
            predicate: () => currentStep === CardOrderSteps.SUCCESS && !showTargetSelectionStep && cardType === CardType.PHYSICAL,
            handler: () => 3,
        },
        {
            predicate: () => currentStep === CardOrderSteps.SUCCESS && showTargetSelectionStep && cardType === CardType.VIRTUAL,
            handler: () => 3,
        },
        {
            predicate: () => currentStep === CardOrderSteps.SUCCESS && !showTargetSelectionStep && cardType === CardType.VIRTUAL,
            handler: () => 2,
        },
        {
            predicate: () => true,
            handler: () => -1,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

const createProcessCardsOrderPayload = (state, includeAddress = false) => {
    const commonPayload = {
        card_image: state.data.card_image,
        card_matrix: state.data.card_matrix,
        member_id: state.data.member_id,
        business_user_id: state.data.business_user_id,
        company_id: state.data.company_id,
        target: state.data.target,
        product_type: state.data.product_type,
        issuing_country: String(state.data.issuing_country),
        card_type: state.data.card_type,
        units: state.data.units,
        client_id: state.data.client_id,
    };

    if (!includeAddress) {
        return commonPayload;
    }

    const deliveryData = {
        delivery_type: state.data.delivery_type,
        delivery_method: state.data.delivery_method,
        carrier_ref: state.data.carrier_ref,
        envelope_ref: state.data.envelope_ref,
    };

    const addressData = {
        address_line_1: state.data.address_line_1,
        address_line_2: state.data.address_line_2,
        zip_code: state.data.zip_code,
        city: state.data.city,
        country: state.data.country,
        first_name: state.data.first_name,
        last_name: state.data.last_name,
        phone_number: state.data.phone_number,
        email: state.data.email,
    };

    return {
        address: addressData,
        ...commonPayload,
        ...deliveryData,
    };
};


export const mapSelectedTargetToPayload = (state) => {
    const handlers = [
        {
            predicate: () => state.data.target === CardOrderTarget.MEMBERS,
            handler: () => state.data.member_id,
        },
        {
            predicate: () => state.data.target === CardOrderTarget.CLIENTS,
            handler: () => state.data.client_id,
        },
        {
            predicate: () => state.data.target === CardOrderTarget.COMPANIES,
            handler: () => state.data.company_id,
        },
        {
            predicate: () => true,
            handler: () => ({ targetType: null, id: 0 }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createOnSubmitOrderStepActions = (
    currentStep,
    state,
) => {
    const handlers = [
        {
            predicate: () => currentStep === CardOrderSteps.TARGET_SELECTION,
            handler: () => of(
                fetchCardMatrix(state.data.client_id),
                setOrderStep(CardOrderSteps.CARD_DETAILS),
            ),
        },
        {
            predicate: () => currentStep === CardOrderSteps.CARD_DETAILS && state.data.card_type === CardType.PHYSICAL,
            handler: () => of(
                fetchDeliveryAddress(state.data.target, mapSelectedTargetToPayload(state)),
                setOrderStep(CardOrderSteps.DELIVERY_DETAILS),
            ),
        },
        {
            predicate: () => currentStep === CardOrderSteps.CARD_DETAILS && state.data.card_type === CardType.VIRTUAL,
            handler: () => of(processCardsOrder(createProcessCardsOrderPayload(state))),
        },
        {
            predicate: () => currentStep === CardOrderSteps.DELIVERY_DETAILS,
            handler: () => of(processCardsOrder(createProcessCardsOrderPayload(state, true))),
        },
        {
            predicate: () => currentStep === CardOrderSteps.REVIEW,
            handler: () => of(orderCards(state.cardOrderReview)),
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createOnGoToPreviousOrderStepActions = (
    currentStep,
    state,
) => {
    const handlers = [
        {
            predicate: () => currentStep === CardOrderSteps.CARD_DETAILS,
            handler: () => of(setOrderStep(CardOrderSteps.TARGET_SELECTION)),
        },
        {
            predicate: () => currentStep === CardOrderSteps.DELIVERY_DETAILS,
            handler: () => of(
                setOrderStep(CardOrderSteps.CARD_DETAILS),
            ),
        },
        {
            predicate: () => currentStep === CardOrderSteps.REVIEW && state.data.card_type === CardType.PHYSICAL,
            handler: () => of(setOrderStep(CardOrderSteps.DELIVERY_DETAILS)),
        },
        {
            predicate: () => currentStep === CardOrderSteps.REVIEW && state.data.card_type === CardType.VIRTUAL,
            handler: () => of(setOrderStep(CardOrderSteps.CARD_DETAILS)),
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export const createOnGetTargetListActions = ({
    targetType,
    id,
    search,
    members,
    companies,
}) => {
    const handlers = [
        {
            predicate: () => targetType === CardOrderTarget.MEMBERS,
            handler: () => from(members.getMembers(id, { search, sortBy: 'member_id', sortOrder: SortDirections.DESCEND, limit: 100, offset: 0 }))
                .pipe(
                    switchMap((response) => of(getTargetListSuccess(targetType, response.data))),
                    catchError(() => of(getTargetListFailure(targetType))),
                ),
        },
        {
            predicate: () => targetType === CardOrderTarget.COMPANIES,
            handler: () => from(companies.getCompanies({ search, clientId: id, sortBy: 'id', sortOrder: SortDirections.DESCEND, limit: 100, offset: 0 }))
                .pipe(
                    switchMap((response) => of(getTargetListSuccess(targetType, response.data))),
                    catchError(() => of(getTargetListFailure(targetType))),
                ),
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export const createAmendedDeliveryAddress = ({
    entityType,
    entityId,
    rawDeliveryAddress,
    state$,
}) => {
    const handlers = [
        {
            predicate: () => entityType === CardOrderTarget.CLIENTS,
            handler: () => {
                const client = state$.value.clients.list.find((c) => c.id === entityId);
                return ({
                    ...rawDeliveryAddress,
                    first_name: client?.contact?.first_name,
                    last_name: client?.contact?.last_name,
                });
            },
        },
        {
            predicate: () => entityType === CardOrderTarget.COMPANIES,
            handler: () => {
                const company = state$.value.cardOrder.companies.find((c) => c.id === entityId);
                return ({
                    ...rawDeliveryAddress,
                    email: company?.email,
                    phone_number: company?.phone_number,
                });
            },
        },
        {
            predicate: () => true,
            handler: () => rawDeliveryAddress,
        },
    ];

    const amendedDeliveryAddress = handlers.filter(({ predicate }) => predicate())[0].handler();

    return of(fetchDeliveryAddressSuccess(amendedDeliveryAddress));
};


export default {
    CardOrderSteps,
    mapCurrentStepToNumber,
    createOnSubmitOrderStepActions,
    createOnGoToPreviousOrderStepActions,
    createOnGetTargetListActions,
    maxUnitCount,
    createAmendedDeliveryAddress,
};
