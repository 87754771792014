import withAccessControl from 'hocs/withAccessControl';
import { createApiKey } from 'redux/api-keys/actions';
import { fetchApplicationsList } from 'redux/applications/actions';
import { applicationsListsPerLocationStoreKeyName, applicationsReducerName } from 'redux/applications/reducer';
import { setModalProps } from 'redux/modal/actions';
import { blockNavigation, showNavigationConfirmation, unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { fetchRolesList } from 'redux/roles/actions';
import { rolesListsPerLocationStoreKeyName, rolesReducerName } from 'redux/roles/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddApiKeyModal from './AddApiKeyModal.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    isBlockNavigationReasonSet: state[navigationReducerName].blockNavigationReason,
    rolesListData: getStandardListData({
        state,
        reducerName: rolesReducerName,
        listsByLocationStoreKeyName: rolesListsPerLocationStoreKeyName,
    }),

    applicationsListData: getStandardListData({
        state,
        reducerName: applicationsReducerName,
        listsByLocationStoreKeyName: applicationsListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch) => ({
    dispatchFetchRolesList: (queryParams, locationPathname) => dispatch(fetchRolesList(queryParams, locationPathname)),

    dispatchFetchApplicationsList: (queryParams, locationPathname) => dispatch(fetchApplicationsList(queryParams, locationPathname)),

    dispatchCreateApiKey: (formData, locationPathname) => dispatch(createApiKey(formData, locationPathname)),
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),

    dispatchBlockNavigation: (reason) => dispatch(blockNavigation(showNavigationConfirmation(), reason)),
    dispatchUnblockNavigation: () => dispatch(unblockNavigation()),
});

const decorate = compose(
    withTranslation('apiKeys'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddApiKeyModal);
