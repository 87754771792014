import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchTopupListFailure, fetchTopupListSuccess } from './actions';
import { FETCH_TOPUP_LIST } from './actions.types';


export const onFetchTopupList = (action$, state$, { topup }) => action$.pipe(
    ofType(FETCH_TOPUP_LIST),
    switchMap(({ payload }) => from(topup.getTopupCards(payload.queryParams)).pipe(
        switchMap((response) => of(fetchTopupListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchTopupListFailure(payload.locationPathname))),
    )),
);


export default [
    onFetchTopupList,
];
