import SwitchWithInfoTooltip from 'components/common/atoms/SwitchWithInfoTooltip';
import CardItem from 'components/common/templates/modals/LinkCardModal/components/CardtItem';
import { LinkCardSteps } from 'redux/card-linking/helpers';

import { Button, List, Skeleton, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function CardSelectionStep({
    initLoading,
    cardSearchQuery,
    withPartnerCards,
    isLoadingNotLinkedCards,
    notLinkedCards,

    t,
    fetchNotLinkedCards,
    dispatchSelectCardForLink,
    dispatchSetCardLinkingStep,
    dispatchSetCardsSearchQuery,
    dispatchSetWithPartnerCards,
}) {
    const onWithPartnerCardsChange = (checked) => {
        dispatchSetWithPartnerCards(checked);
        return fetchNotLinkedCards({ search: cardSearchQuery });
    };

    return (
        <div>
            <Input.Search
                placeholder={t('modals.linkCard.fields.search.placeholder')}
                value={cardSearchQuery}
                enterButton
                onChange={(event) => dispatchSetCardsSearchQuery(event.target.value)}
                onSearch={() => fetchNotLinkedCards({ search: cardSearchQuery })}
                style={{ width: '100%', marginBottom: 10 }}
            />
            <div style={{ width: 'max-content' }}>
                <SwitchWithInfoTooltip
                    onChange={onWithPartnerCardsChange}
                    checked={withPartnerCards}
                    tooltipContent={withPartnerCards ? 'All partner cards' : 'Only assigned cards'}
                />
            </div>

            <div style={{ overflowY: 'auto' }}>
                <Skeleton
                    loading={isLoadingNotLinkedCards && initLoading}
                    active
                >
                    <List
                        style={{ maxHeight: 350 }}
                        loading={isLoadingNotLinkedCards}
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={notLinkedCards}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <Button
                                        key={'select-account'}
                                        onClick={() => {
                                            dispatchSelectCardForLink(item);
                                            dispatchSetCardLinkingStep(LinkCardSteps.ACCOUNT_SELECTION);
                                        }}
                                    >
                                        {t('common:buttons.select.text')}
                                    </Button>,
                                ]}
                            >
                                <Skeleton avatar title={false} loading={isLoadingNotLinkedCards} active>
                                    <CardItem card={item} t={t} />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </Skeleton>
            </div>
        </div>
    );
}


CardSelectionStep.propTypes = {
    isLoadingNotLinkedCards: PropTypes.bool.isRequired,
    notLinkedCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    cardSearchQuery: PropTypes.string.isRequired,
    withPartnerCards: PropTypes.bool.isRequired,

    t: PropTypes.func.isRequired,
    dispatchSelectCardForLink: PropTypes.func.isRequired,
    dispatchSetCardsSearchQuery: PropTypes.func.isRequired,
    dispatchSetWithPartnerCards: PropTypes.func.isRequired,
};

export default CardSelectionStep;

