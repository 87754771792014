import { HttpService } from 'services/http';
import { HttpRequestConfig } from 'services/http/httpService.types';

import { Uuid } from '@manigo/manigo-domain-typings';


export const getAllTransactionsAPI = (requestConfig: HttpRequestConfig) => HttpService.get('/v4/transactions', requestConfig);

export const getTransactionUuidDetails = (transactionUuid: Uuid) => HttpService.get(`/v4/transactions/${transactionUuid}`, { convertSnakeCaseKeysToCamelCase: true });

export const createNewPaymentAPI = (requestPayload) => HttpService.post('/v4/payments/create', requestPayload);

export const getTransactionsFiltersAPI = (requestConfig: HttpRequestConfig) => HttpService.post('/v3/transactions/filters', undefined, requestConfig);

export const unblockTransactionAPI = (requestConfig: HttpRequestConfig) => HttpService.post('/v4/transactions/unblock', requestConfig);

export const withdrawBalanceAPI = (requestConfig: HttpRequestConfig) => HttpService.post('/v2/transactions/withdraw-balance', requestConfig);

export const balanceAdjustmentAPI = (requestConfig: HttpRequestConfig) => HttpService.post('/v4/payments/balance-adjustment', requestConfig);

export const auditPendingTransactionUuid = (transactionUuid: Uuid, requestPayload) => HttpService.put(`/v3/payments/pending-transactions/${transactionUuid}`, requestPayload);


export const refundTopUpUuid = (transactionUuid: Uuid) => HttpService.post(`/v3/bop/top-up/refund/${transactionUuid}`);


export const getTransactionUuidStatusHistory = (transactionUuid: Uuid) => HttpService.get(`/v3/transactions/${transactionUuid}/history`,
    { convertSnakeCaseKeysToCamelCase: true });

