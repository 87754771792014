export const FETCH_MARKETING_PREFERENCES = 'FETCH_MARKETING_PREFERENCES';
export const FETCH_MARKETING_PREFERENCES_SUCCESS = 'FETCH_MARKETING_PREFERENCES_SUCCESS';
export const FETCH_MARKETING_PREFERENCES_FAILURE = 'FETCH_MARKETING_PREFERENCES_FAILURE';

export const CLEAR_MARKETING_PREFERENCES_LIST = 'CLEAR_MARKETING_PREFERENCES_LIST';

export const REQUEST_MARKETING_PREFERENCES = 'REQUEST_MARKETING_PREFERENCES';
export const REQUEST_MARKETING_PREFERENCES_SUCCESS = 'REQUEST_MARKETING_PREFERENCES_SUCCESS';
export const REQUEST_MARKETING_PREFERENCES_FAILURE = 'REQUEST_MARKETING_PREFERENCES_FAILURE';

export const SHOW_REQUEST_MARKETING_PREFERENCES_MODAL = 'SHOW_REQUEST_MARKETING_PREFERENCES_MODAL';
