import {
    ADD_COMPANY,
    ADD_COMPANY_FAILURE,
    ADD_COMPANY_SUCCESS, CLEAR_COMPANIES_LIST,
    CLEAR_COMPANIES_STATE,
    FETCH_COMPANIES,
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_SUCCESS,
} from 'redux/companies/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchCompanies = createAction(FETCH_COMPANIES, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchCompaniesSuccess = createAction(FETCH_COMPANIES_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchCompaniesFailure = createAction(FETCH_COMPANIES_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const clearCompaniesList = createAction(CLEAR_COMPANIES_LIST, (locationPathname) => ({ payload: { locationPathname } }));

export const addCompany = createAction(ADD_COMPANY, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const addCompanySuccess = createAction(ADD_COMPANY_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const addCompanyFailure = createAction(ADD_COMPANY_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearCompaniesState = createAction(CLEAR_COMPANIES_STATE);
