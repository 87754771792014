import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { companyRootRoutePath, businessUserRootRoutePath, memberRootRoutePath, userRootRoutePath } from 'config/routes';
import { StatusChangeChannels, StatusChangesChannelProp } from 'constants/CommonModel';

import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';


const createHandlers = ({
    t,
    channel,
    bo_user_id,
    api_user_id,
    member_id,
    company_id,
    business_user_id,
}) => {
    const handlers = [
        {
            predicate: () => channel === StatusChangeChannels.BACKOFFICE && bo_user_id > 0,
            handler: () => (
                <StandardLink
                    path={`${userRootRoutePath}/${bo_user_id}`}
                    title={t('statusChangeActors.backofficeUser', { id: bo_user_id })}
                />
            ),
        },
        {
            predicate: () => channel === StatusChangeChannels.API_USER && api_user_id > 0,
            handler: () => <StandardValue value={t('statusChangeActors.apiUser', { id: api_user_id })} />,
        },
        {
            predicate: () => channel === StatusChangeChannels.PERSONAL_MOBILE_APP && member_id > 0,
            handler: () => (
                <StandardLink
                    path={`${memberRootRoutePath}/${member_id}`}
                    title={t('statusChangeActors.member', { id: member_id })}
                />
            ),
        },
        {
            predicate: () => channel === StatusChangeChannels.BUSINESS_WEB_APP && !company_id > 0 && !business_user_id > 0,
            handler: () => (
                <StandardLink
                    path={`${companyRootRoutePath}/${company_id}`}
                    title={t('statusChangeActors.company', { id: company_id })}
                />
            ),
        },
        {
            predicate: () => channel === StatusChangeChannels.BUSINESS_WEB_APP && business_user_id > 0,
            handler: () => (
                <StandardLink
                    path={`${businessUserRootRoutePath}/${business_user_id}`}
                    title={t('statusChangeActors.businessUser', { id: business_user_id })}
                />
            ),
        },
        {
            predicate: () => channel === StatusChangeChannels.SCHEDULER,
            handler: () => <StandardValue value={t('statusChangeActors.scheduler')} />,
        },
        {
            predicate: () => channel === StatusChangeChannels.SYSTEM,
            handler: () => <StandardValue value={t('statusChangeActors.system')} />,
        },
        {
            predicate: () => channel === 'Admin initial input',
            handler: () => t(t('statusChangeActors.init')),
        },
        {
            predicate: () => true,
            handler: () => t('statusChangeActors.unknown'),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function StatusChangeActor(props) {
    return createHandlers(props);
}

StatusChangeActor.propTypes = {
    channel: StatusChangesChannelProp,
    bo_user_id: PropTypes.number,
    api_user_id: PropTypes.number,
    member_id: PropTypes.number,
    company_id: PropTypes.number,
    business_user_id: PropTypes.number,
    t: PropTypes.string.isRequired,
};

export default withTranslation('common')(StatusChangeActor);
