import withAccessControl from 'hocs/withAccessControl';
import { clearApiKeysList, deleteApiKey, fetchApiKeysList, fetchApiKeyValue, openCreateApiKeyModal } from 'redux/api-keys/actions';
import { apiKeysListsPerLocationStoreKeyName, apiKeysReducerName } from 'redux/api-keys/reducer';
import { setQueryParams } from 'redux/application/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ApiKeysList from './ApiKeysList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: apiKeysReducerName,
        listsByLocationStoreKeyName: apiKeysListsPerLocationStoreKeyName,
    }),

    isLoadingDeleteApiKey: state[apiKeysReducerName].isLoadingDeleteApiKey,
    isLoadingCreateApiKey: state[apiKeysReducerName].isLoadingCreateApiKey,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchApiKeysList: (queryParams, locationPathname) => dispatch(fetchApiKeysList(queryParams, locationPathname)),
    dispatchClearApiKeysList: (locationPathname) => dispatch(clearApiKeysList(locationPathname)),
    dispatchOpenCreateApiKeyModal: (clientId) => dispatch(openCreateApiKeyModal(clientId)),
    dispatchDeleteApiKey: (apiKeyUuid, locationPathname) => dispatch(deleteApiKey(apiKeyUuid, locationPathname)),
    dispatchFetchApiKeyValue: (apiKeyUuid) => dispatch(fetchApiKeyValue(apiKeyUuid)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: apiKeysReducerName,
        fieldName: apiKeysListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('apiKeys'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ApiKeysList);
