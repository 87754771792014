import { standardPageSize } from 'config/config';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';

import {
    clearManualFeesList,
    fetchManualFeesList,
    fetchManualFeesListFailure,
    fetchManualFeesListSuccess,
} from './actions';


export const manualFeesReducerName = 'manualFees';
export const manualFeesListsPerLocationStoreKeyName = 'manualFeesListsPerLocation';

export const defaultManualFeesListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: undefined,
    sortOrder: undefined,
};


const initialState = { [manualFeesListsPerLocationStoreKeyName]: {} };

const handlers = {
    [fetchManualFeesList]: (state, action) => {
        state[manualFeesListsPerLocationStoreKeyName] = updateStandardLists(state[manualFeesListsPerLocationStoreKeyName], action);
    },
    [fetchManualFeesListSuccess]: (state, action) => {
        state[manualFeesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[manualFeesListsPerLocationStoreKeyName], action);
    },
    [fetchManualFeesListFailure]: (state, action) => {
        state[manualFeesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[manualFeesListsPerLocationStoreKeyName], action);
    },
    [clearManualFeesList]: (state, action) => {
        state[manualFeesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[manualFeesListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === manualFeesReducerName
        && action.payload?.fieldName === manualFeesListsPerLocationStoreKeyName
        ) {
            state[manualFeesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[manualFeesListsPerLocationStoreKeyName] }, action);
        }
    },
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
