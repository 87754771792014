import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearClientProducts, fetchClientProducts, openAddProductModal } from 'redux/client-products/actions';
import { clientProductsReducerName, clientProductsListsPerLocationStoreKeyName } from 'redux/client-products/reducer';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ProductsList from './ProductsList.layout';


const mapStateToProps = (state) => ({
    currencies: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: clientProductsReducerName,
        listsByLocationStoreKeyName: clientProductsListsPerLocationStoreKeyName,
    }),

});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchFetchClientProducts: (queryParams, locationPathname) => dispatch(fetchClientProducts(queryParams, locationPathname)),
    dispatchClearClientProducts: (locationPathname) => dispatch(clearClientProducts(locationPathname)),

    dispatchOpenAddProductModal: (locationPathname) => dispatch(openAddProductModal(ownProps.clientId, locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: clientProductsReducerName,
        fieldName: clientProductsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('products'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ProductsList);
