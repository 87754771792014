import { RiskRating } from 'constants/RiskAssessmentModel';


const getRiskAlertTagProps = (riskRating) => [
    {
        predicate: () => riskRating === RiskRating.LOW,
        handler: () => ({
            color: 'green',
            type: 'success',
            text: 'LOW',
        }),
    },
    {
        predicate: () => riskRating === RiskRating.MEDIUM,
        handler: () => ({
            color: 'yellow',
            type: 'warning',
            text: 'MEDIUM',
        }),
    },
    {
        predicate: () => riskRating === RiskRating.HIGH,
        handler: () => ({
            color: 'red',
            type: 'error',
            text: 'HIGH',
        }),
    },
    {
        predicate: () => true,
        handler: () => ({
            color: 'red',
            type: 'error',
            text: 'UNDEFINED',
        }),
    },
];

export default getRiskAlertTagProps;
