import { Counter, QueryParams } from 'models/app/common';
import { LocationPathname } from 'models/app/navigation';

import { FailedWebhookListItem } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_FAILED_WEBHOOK_ERRORS_LIST,
    FETCH_FAILED_WEBHOOK_ERRORS_LIST_FAILURE,
    FETCH_FAILED_WEBHOOK_ERRORS_LIST_SUCCESS,
    CLEAR_FAILED_WEBHOOK_ERRORS_LIST,
} from './actions.types';


export const fetchFailedWebhookErrorsList = createAction(FETCH_FAILED_WEBHOOK_ERRORS_LIST,
    (queryParams: QueryParams, locationPathname: LocationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchFailedWebhookErrorsListSuccess = createAction(FETCH_FAILED_WEBHOOK_ERRORS_LIST_SUCCESS,
    (items: FailedWebhookListItem[], totalCount: Counter, locationPathname: LocationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchFailedWebhookErrorsListFailure = createAction(FETCH_FAILED_WEBHOOK_ERRORS_LIST_FAILURE,
    (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));
export const clearFailedWebhookErrorsList = createAction(CLEAR_FAILED_WEBHOOK_ERRORS_LIST, (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));
