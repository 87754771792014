import TabSubHeading from 'components/common/atoms/TabSubHeading';
import Common from 'components/common/atoms/styled/UpdateStatus.styled';
import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import { CardType, PermanentCardStatusesList, PhysicalCardsAvailableStatusesMapping, VirtualCardsAvailableStatusesMapping } from 'constants/CardModel';
import noop from 'utils/function-tools';
import { commonValidation, handleWhitespaceOnBlur } from 'utils/validation-tools';

import { FormOutlined } from '@ant-design/icons';
import {
    Button, Form, Input, Popconfirm, Select, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';


function UpdateCardStatusForm({
    locationPathname,
    canUpdateCard,
    entityDetailsData,
    dispatchUpdateCardStatus,
    isChangeStatusFormVisible,
    isLoadingChangeStatus,
    dispatchToggleChangeCardStatusFormVisibility,
    t,
}) {
    const entityDetails = entityDetailsData?.entityDetails;

    useEffect(() => () => {
        dispatchToggleChangeCardStatusFormVisibility(false);
    }, []);

    const [form] = Form.useForm();
    const [selectedNewCardStatus, setSelectedNewCardStatus] = useState(undefined);
    const [showPermanentStatusConfirmation, setShowPermanentStatusConfirmation] = useState(false);

    const isPermanentStatus = useMemo(() => PermanentCardStatusesList.includes(selectedNewCardStatus), [selectedNewCardStatus]);

    const onConfirmPermanentStatusUpdateHandler = () => {
        const cardStatusComment = form.getFieldValue('cardStatusComment');
        const cardStatus = form.getFieldValue('cardStatus');
        dispatchUpdateCardStatus(
            {
                cardId: entityDetails?.id,
                cardStatusComment,
                cardStatus,
            },
            locationPathname,
        );
    };

    const onNonPermanentStatusUpdateHandler = (values) => {
        if (!showPermanentStatusConfirmation) {
            dispatchUpdateCardStatus(
                {
                    ...values,
                    cardId: entityDetails?.id,
                },
                locationPathname,
            );
        }
    };

    const onFieldsChange = (changedFields, allFields) => {
        const formIsValid = allFields.reduce((acc, formItem) => {
            if (formItem.errors.length === 0 && formItem.value) {
                return true;
            }
            return false;
        }, false);

        if (isPermanentStatus && formIsValid) {
            setShowPermanentStatusConfirmation(true);
        } else {
            setShowPermanentStatusConfirmation(false);
        }
    };

    const availableCardStatuses = useMemo(() => {
        if (entityDetails?.type && entityDetails?.card_status) {
            const allAvailableOptions = entityDetails.type === CardType.PHYSICAL ? PhysicalCardsAvailableStatusesMapping : VirtualCardsAvailableStatusesMapping;
            return allAvailableOptions[entityDetails?.card_status] || [];
        }

        return [];
    }, [entityDetails]);

    const onShowStatusUpdateForm = () => {
        form.setFieldsValue({ cardStatusComment: undefined, cardStatus: undefined });
        setSelectedNewCardStatus(undefined);
        dispatchToggleChangeCardStatusFormVisibility(true);
    };

    return canUpdateCard ? (
        <div style={{ width: '100%' }}>
            <TabSubHeading text={t('cardDetails.tabs.settings.subHeadings.cardStatus')} />
            <div>
                {isChangeStatusFormVisible ? (
                    <Spin tip="Updating card status..." spinning={isLoadingChangeStatus}>
                        <Form
                            form={form}
                            layout="vertical"
                            name="card_status_form"
                            initialValues={{}}
                            style={{ width: '100%' }}
                            onFinish={showPermanentStatusConfirmation ? noop : onNonPermanentStatusUpdateHandler}
                            onFieldsChange={onFieldsChange}
                        >
                            <Form.Item
                                name="cardStatus"
                                label="New card status"
                                rules={[...commonValidation({ t, isRequired: true })]}
                            >
                                <Select
                                    placeholder={t('cardDetails.tabs.settings.fields.cardStatus.submitButtonLabel')}
                                    style={{ width: '120px;' }}
                                    onChange={(value) => setSelectedNewCardStatus(value)}
                                >
                                    {availableCardStatuses.map((status) => (
                                        <Select.Option value={status} key={status}>
                                            {status}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>


                            <Common.HintAlert
                                message={selectedNewCardStatus
                                    ? t(`cardDetails.tabs.settings.fields.cardStatus.cardStatusesDescriptions.${selectedNewCardStatus}`)
                                    : t('cardDetails.tabs.settings.fields.cardStatus.cardStatusesDescriptionsPlaceholder')}
                                type={selectedNewCardStatus && isPermanentStatus ? 'warning' : 'info'}
                                showIcon
                            />


                            <Form.Item
                                name="cardStatusComment"
                                label="Internal comment"
                                rules={[...commonValidation({ t, isRequired: true })]}
                            >
                                <Input
                                    placeholder="Enter internal comment"
                                    onBlur={(e) => handleWhitespaceOnBlur(e, 'cardStatusComment', form)}
                                />
                            </Form.Item>

                            <Common.ButtonsWrapper>
                                <Button type="default" htmlType="reset" onClick={() => dispatchToggleChangeCardStatusFormVisibility(false)}>
                                    {t('common:buttons.cancel.text')}
                                </Button>

                                <Popconfirm
                                    disabled={!showPermanentStatusConfirmation}
                                    onConfirm={onConfirmPermanentStatusUpdateHandler}
                                    title={(
                                        <StandardTranslationWithMarkup
                                            translationKey={'cardDetails.tabs.settings.fields.cardStatus.permanentStatusSelectedConfirmationQuestion'}
                                            namespace="cards"
                                            t={t}
                                        />
                                    )}
                                >
                                    <Button type="primary" htmlType="submit">
                                        {t('cardDetails.tabs.settings.fields.cardStatus.submitButtonLabel')}
                                    </Button>
                                </Popconfirm>
                            </Common.ButtonsWrapper>
                        </Form>
                    </Spin>
                ) : (
                    <Button
                        type="primary"
                        icon={<FormOutlined />}
                        onClick={onShowStatusUpdateForm}
                        disabled={isLoadingChangeStatus}
                    >
                        {t('cardDetails.tabs.settings.fields.cardStatus.showFormButtonLabel')}
                    </Button>
                )}
            </div>
        </div>
    ) : null;
}

UpdateCardStatusForm.propTypes = {
    canUpdateCard: PropTypes.bool.isRequired,
    locationPathname: PropTypes.string.isRequired,
    dispatchUpdateCardStatus: PropTypes.func.isRequired,
    dispatchToggleChangeCardStatusFormVisibility: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default UpdateCardStatusForm;
