import { developmentMode } from 'config/config';
import {
    onDeleteAccount,
    onFetchAccountById,
    onOpenWithdrawAccountBalanceModal,
    onWithdrawBalance,
    onOpenBalanceAdjustmentModal,
    onBalanceAdjustment,
    onDownloadAccountStatementPdf,
    onSetAsPrimaryAccount,
} from 'redux/account/epics';
import {
    onAddAccount,
    onAddAccountSuccess,

    onFetchAccountsList,
    onOpenAddAccountModal,
} from 'redux/accounts/epics';
import { onOpenEditAddressModal, onUpdateAddress } from 'redux/address/epics';
import {
    onCreateApiKey, onDeleteApiKey, onFetchApiKeys, onFetchApiKeyValue, onOpenCreateApiKeyModal, onRefreshApiKeysList,
} from 'redux/api-keys/epics';
import {
    onInitApplication, onInitApplicationUnauthorisedFlow, onInitApplicationAuthorisedFlow,
    onInitI18next,
    onGetJwtTokenFormStorage, onRemoveJwtTokenFromStorage,
    onSetClipboardContent, onShowSuccessToast, onShowErrorToast, onShowWarningToast, onShowInfoToast, onSaveFile, onAuthorisedGlobalDataFetchFailure, onFetchGlobalData,
} from 'redux/application/epics';
import { onFetchApplicationsList } from 'redux/applications/epics';
import {
    onInitialiseLogin,
    onConfirmLogin,
    onSetNewPassword,
    onRequestPasswordReset,
    onSetNewPasswordSuccess,
    onGetRememberedUsernameOrEmail,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
    onUnlockUser,
    onRefreshToken, onSendConfirmationCode,
} from 'redux/authorisation/epics';
import { onFetchBeneficiariesList } from 'redux/beneficiaries/epics';
import { onDeleteBeneficiary, onFetchBeneficiaryDetails, onUpdateBeneficiary } from 'redux/beneficiary/epics';
import {
    onCreateBeneficiary, onFetchBeneficiaryRequirements, onGoToPreviousAddEditBeneficiaryFormStep,
    onInitialiseAddEditBeneficiaryForm, onSubmitAddEditBeneficiaryFormStep,
} from 'redux/beneficiary-add/epics';
import { onFetchStakeholdersList } from 'redux/business-stakeholders/epics';
import {
    onUpdateBusinessUser,
    onFetchBusinessUserDetails,
} from 'redux/business-user/epics';
import {
    onFetchBusinessUsersList,
    onAddBusinessUser,
} from 'redux/business-users/epics';
import {
    onFetchCardDetails,
    onUpdateCard,
    onUpdateCardLimits,
    onUnlinkCard,
    onShowUnlinkCardModal,
    onConfirmUnlinkCard,
    onUnlinkCardSuccess,
    onDeleteCard,
    onDeleteCardSuccess,
    onCardPinUnblock,
} from 'redux/card/epic';
import {
    onFetchNotLinkedCards,
    onLinkCardToBusinessUser,
    onLinkCardToBusinessUserSuccess,
    onLinkCardToMember,
    onLinkCardToMemberSuccess,
    onSetCardLinkingStep,
    onLinkCardFailure,
    onLinkCardToCompany,
    onLinkCardToCompanySuccess,
} from 'redux/card-linking/epics';
import {
    onStartCardsOrdering,
    onSubmitOrderStep,
    onGoToPreviousOrderStep,
    onFetchCardMatrix,
    onGetTargetList,
    onFetchDeliveryAddress,
    onProcessCardsOrder,
    onOrderCards, onOrderCardsSuccess,
    onSetData,
} from 'redux/card-order/epics';
import {
    onFetchCardSecure3dData,
    onFetchCardSecure3dActivityLogs,
    onOpenEditCardSecure3dPhoneNumberModal,
    onSubmitCardSecure3dPhoneNumberModal,
    onUpdateCardSecure3dSettings,
} from 'redux/card-secure3d/epic';
import { onFetchCardStatusHistory, onUpdateCardStatus } from 'redux/card-status-history/epic';
import { onFetchCardTiers } from 'redux/card-tiers/epics';
import { onFetchAllCards } from 'redux/cards/epics';
import {
    onFetchClient3DsSettings,
    onFetchClientAddresses,
    onOpenEditClientProfileModal,
    onUpdateClientProfile,
    onUpdateClientSettings,
    onUpdateClientAnnouncement,
    onFetchClientDetails,
    onAddClientRiskDetails,
    onOpenAddClientRiskDetailsModal,
} from 'redux/client/epics';
import {
    onAddClient,
    onFetchBpsProducts,
    onFetchDefaultClientConfiguration,
} from 'redux/client-add/epics';
import { onFetchEntityDetails, onSetClientIdContext } from 'redux/client-context/epics';
import {
    onDownloadMigrationReport,
    onDownloadMigrationResult,
    onFetchClientMigrationsList,
    onUploadClientMigrationFile,
} from 'redux/client-migrations/epics';
import { onAddProduct, onAddProductSuccess, onFetchClientProducts, onOpenAddProductModal } from 'redux/client-products/epics';
import { onFetchClientsList } from 'redux/clients/epics';
import {
    onAddCompany,
    onFetchCompanies,
} from 'redux/companies/epics';
import {
    onOpenAddCompanyRiskDetailsModal,
    onAddCompanyRiskDetails,
    onFetchCompanyDetails,
    onUpdateCompany,
} from 'redux/company/epics';
import { onFetchCountriesList } from 'redux/countries/epics';
import { onFetchCurrencies } from 'redux/currencies/epics';
import { onFetchSupportedCurrencies } from 'redux/currencies-supported/epics';
import {
    onSetCurrentUserSuccess,
    onSetCurrentUserFailure,
    onSetCurrentUser,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity, onDocumentVisibilityChange, onCheckTokens,
} from 'redux/current-user/epic';
import {
    onInitExchange,
    onConfirmExchange,
    onRejectExchange,
    onFetchExchangeRate,
} from 'redux/exchange/epics';
import {
    onFetchFailedWebhooks,
    onReplayAllFieldWebhooks,

} from 'redux/failed-webhooks/epics';
import { onFetchFeeDetails } from 'redux/fee/epics';
import { onFetchFeeTiers } from 'redux/fee-tiers/epics';
import {
    onFetchFeesList,
    onChargeCustomFee,
    onChargeCustomFeeModal,
} from 'redux/fees/epics';
import {
    onOpenReassignMemberGroupModal,
    onReassignMemberGroup,
    onFetchGroupDetails,
    onOpenEditGroupNameModal,
    onOpenAssignPromotionsToGroupModal,
    onAssignPromotionToGroup,
    onRemovePromotionFromGroup,
    onDeleteGroupModal,
    onDeleteGroup,
    onAddMemberToGroup,
    onUpdateGroupName,
    onCleanupAddMemberToGroupMetadata,
    onLeavingAddMemberToGroupPage,
} from 'redux/group/epic';
import {
    onFetchGroupMembers,
    onFetchGroupNonMembers,
} from 'redux/group-members/epic';
import {
    onFetchGroupsList,
    onOpenCreateNewGroupModal,
    onCreateNewGroup,
} from 'redux/groups/epic';
import { onFetchIndustriesList } from 'redux/industries/epics';
import {
    onFetchCurrentKycKybFlow,
    onSetKycKybFlow,
    onTriggerKycKybFlow,
    onFetchKycKybLogsList,
} from 'redux/kyc-kyb/epics';
import limitEpics from 'redux/limit/epics';
import limitsEpics from 'redux/limits/epics';
import {
    onFetchManualFeesList,
    onTriggerManualFee,
    onTriggerManualFeeModal,
} from 'redux/manual-fees/epics';
import { onRequestMarketingPreferences, onFetchMembersMarketPreferences, onShowRequestMarketingPreferencesModal } from 'redux/marketing-preferences/epics';
import {
    onFetchMemberDetails,
    onOpenUpdateMemberModal,
    onUpdateMember,
} from 'redux/member/epics';
import memberStatusEpics from 'redux/member-status/epics';
import { onCreateMember, onFetchMembers } from 'redux/members/epics';
import { onShowViewJsonModal } from 'redux/modal/epics';
import { onMultiStepsFormStepOrSubSepChange, onRequestSetMultiStepsFormStep, onRequestSetMultiStepsFormSubStep } from 'redux/multi-steps-form/epics';
import {
    onNavigationRequested,
    onNavigationBackRequested,
    onBlockNavigation,
    onUnblockNavigation,
} from 'redux/navigation/epic';
import { onFinaliseOffboarding, onStartOffboarding, onStopOffboarding } from 'redux/offboarding/epics';
import { onFetchOperationsList, onFetchRolesTemplatesList } from 'redux/operations/epics';
import {
    onCreateOutgoingTransfer,
    onFetchAllBeneficiaries,
    onFetchAllAccounts,
    onGoToPreviousOutgoingTransferFormStep,
    onSubmitOutgoingTransferFormStep,
} from 'redux/outgoing-transfer/epics';
import { onFetchPermissionsForRole } from 'redux/permissions/epics';
import {
    onFetchPromotionDetails,
    onRemovePromotion,
    onEnablePromotion,
    onDisablePromotion,
} from 'redux/promotion/epic';
import {
    onFetchClientPromotionsList,
    onCreatePromotion,
} from 'redux/promotions/epic';
import { onFetchRiskAssessments } from 'redux/risk-assessments/epics';
import { onFetchRiskScoreHistoryList } from 'redux/risk-score-history/epic';
import {
    onFetchRoleDetails,
    onUpdateRole,
    onCreateRole,
    onDeleteRole,
} from 'redux/role/epics';
import { onFetchRolesList } from 'redux/roles/epics';
import { onFetchTopupList } from 'redux/topup/epic';
import {
    onFetchTransactionDetails,
    onCreateP2PTransaction,
    onRefundTopUp,
    onUnblockTransaction,
    onAuditPendingTransaction,
} from 'redux/transaction/epics';
import transactionStatusEpics from 'redux/transaction-status-history/epics';
import {
    onGetTransactions,
    onGetTransactionsFilters,
    onRefreshTransactions,
} from 'redux/transactions/epics';
import { onFetchUserDetails, onEditUser, onBanUser, onDeleteUser, onResendConfirmationEmail } from 'redux/user/epics';
import { onFetchUsers, onAddUser, onOpenAddEditUserModal } from 'redux/users/epics';
import {
    onFetchWebhooks,
    onFetchWebhooksActionTypes,
    onOpenRegisterOrEditWebhookModal,
    onRegisterWebhook,
    onUnregisterWebhook,
    onUpdateWebhook,
} from 'redux/webhooks/epics';

import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';

import { onFetchBusinessStatusHistory, onUpdateBusinessUserStatus } from './business-user-status/epics';
import { onFetchCompanyStatusHistory, onUpdateCompanyStatus } from './company-status/epics';
import failedWebhookEpics from './failed-webhook/epics';
import failedWebhookHistoryEpics from './failed-webhook-history/epics';


const accountEpics = [
    onFetchAccountById,
    onDeleteAccount,
    onOpenWithdrawAccountBalanceModal,
    onWithdrawBalance,
    onOpenBalanceAdjustmentModal,
    onBalanceAdjustment,
    onDownloadAccountStatementPdf,
    onSetAsPrimaryAccount,
];

const accountsEpics = [
    onOpenAddAccountModal,
    onAddAccount,
    onAddAccountSuccess,
    onFetchAccountsList,
];

const addressEpics = [
    onOpenEditAddressModal,
    onUpdateAddress,
];

const apiKeysEpics = [
    onFetchApiKeys,
    onOpenCreateApiKeyModal,
    onCreateApiKey,
    onDeleteApiKey,
    onFetchApiKeyValue,
    onRefreshApiKeysList,
];

const applicationsEpics = [
    onFetchApplicationsList,
];

const applicationEpics = [
    onInitApplication, onInitApplicationUnauthorisedFlow, onInitApplicationAuthorisedFlow,
    onAuthorisedGlobalDataFetchFailure,
    onInitI18next,
    onGetJwtTokenFormStorage, onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowSuccessToast,
    onShowErrorToast,
    onShowInfoToast,
    onShowWarningToast,
    onSaveFile,
    onFetchGlobalData,
];

const authorisation = [
    onInitialiseLogin,
    onConfirmLogin,
    onSetNewPassword,
    onRequestPasswordReset,
    onSetNewPasswordSuccess,
    onGetRememberedUsernameOrEmail,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
    onUnlockUser,
    onRefreshToken,
    onSendConfirmationCode,
];


const beneficiariesEpics = [
    onFetchBeneficiariesList,
];

const beneficiaryEpics = [
    onFetchBeneficiaryDetails,
    onUpdateBeneficiary,
    onDeleteBeneficiary,
];
const beneficiaryAddEpics = [
    onCreateBeneficiary,
    onFetchBeneficiaryRequirements,
    onInitialiseAddEditBeneficiaryForm,
    onSubmitAddEditBeneficiaryFormStep,
    onGoToPreviousAddEditBeneficiaryFormStep,
];

const cardEpics = [
    onFetchCardDetails,
    onUpdateCard,
    onUpdateCardLimits,
    onUnlinkCard,
    onShowUnlinkCardModal,
    onConfirmUnlinkCard,
    onUnlinkCardSuccess,
    onDeleteCard,
    onDeleteCardSuccess,
    onCardPinUnblock,
];

const cardsEpics = [
    onFetchAllCards,
];

const cardStatusEpics = [
    onFetchCardStatusHistory,
    onUpdateCardStatus,
];


const card3dSecureEpics = [
    onFetchCardSecure3dData,
    onFetchCardSecure3dActivityLogs,
    onOpenEditCardSecure3dPhoneNumberModal,
    onSubmitCardSecure3dPhoneNumberModal,
    onUpdateCardSecure3dSettings,
];

const cardLinkingEpics = [
    onLinkCardToBusinessUser,
    onLinkCardToBusinessUserSuccess,
    onLinkCardToMember,
    onLinkCardToMemberSuccess,
    onSetCardLinkingStep,
    onLinkCardFailure,
    onFetchNotLinkedCards,
    onLinkCardToCompany,
    onLinkCardToCompanySuccess,
];

const cardOrderEpics = [
    onStartCardsOrdering,
    onSubmitOrderStep,
    onGoToPreviousOrderStep,
    onFetchCardMatrix,
    onGetTargetList,
    onFetchDeliveryAddress,
    onProcessCardsOrder,
    onOrderCards,
    onOrderCardsSuccess,
    onSetData,
];

const clientEpics = [
    onFetchClient3DsSettings,
    onFetchClientAddresses,
    onFetchClientDetails,
    onOpenEditClientProfileModal,
    onUpdateClientProfile,
    onUpdateClientSettings,
    onUpdateClientAnnouncement,
    onOpenAddClientRiskDetailsModal,
    onAddClientRiskDetails,
];

const clientAddEpics = [
    onFetchDefaultClientConfiguration,
    onFetchBpsProducts,
    onAddClient,
];

const clientProductsEpics = [
    onFetchClientProducts,
    onOpenAddProductModal,
    onAddProduct,
    onAddProductSuccess,
];

const clientMigrationsEpics = [
    onFetchClientMigrationsList,
    onDownloadMigrationResult,
    onDownloadMigrationReport,
    onUploadClientMigrationFile,
];

const clientsEpics = [
    onFetchClientsList,
];


const clientContextEpics = [
    onFetchEntityDetails,
    onSetClientIdContext,
];

const companiesEpics = [
    onFetchCompanies,
    onAddCompany,
];


const businessUserEpics = [
    onUpdateBusinessUser,
    onFetchBusinessUserDetails,
];
const businessUsersEpics = [
    onAddBusinessUser,
    onFetchBusinessUsersList,
];

const businessStakeholdersEpics = [
    onFetchStakeholdersList,
];

const businessUserStatusEpics = [
    onFetchBusinessStatusHistory,
    onUpdateBusinessUserStatus,
];

const companyEpics = [
    onFetchCompanyDetails,
    onUpdateCompany,
    onOpenAddCompanyRiskDetailsModal,
    onAddCompanyRiskDetails,
];

const companyStatusEpics = [
    onFetchCompanyStatusHistory,
    onUpdateCompanyStatus,
];

const countriesEpics = [
    onFetchCountriesList,
];

const currenciesEpics = [
    onFetchCurrencies,
];
const cardTiersEpics = [
    onFetchCardTiers,
];
const supportedCurrenciesEpics = [
    onFetchSupportedCurrencies,
];


const currentUserEpics = [
    onSetCurrentUser,
    onSetCurrentUserSuccess,
    onSetCurrentUserFailure,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity,
    onCheckTokens,
    onDocumentVisibilityChange,
];

const exchangeEpics = [
    onInitExchange,
    onConfirmExchange,
    onRejectExchange,
    onFetchExchangeRate,
];


const feeEpics = [
    onFetchFeeDetails,
];
const feeTiersEpics = [
    onFetchFeeTiers,
];

const feesEpics = [
    onFetchFeesList,
    onChargeCustomFee,
    onChargeCustomFeeModal,
];
const manualFeesEpics = [
    onFetchManualFeesList,
    onTriggerManualFee,
    onTriggerManualFeeModal,
];
const groupEpics = [
    onOpenReassignMemberGroupModal,
    onReassignMemberGroup,
    onFetchGroupDetails,
    onOpenEditGroupNameModal,
    onUpdateGroupName,
    onOpenAssignPromotionsToGroupModal,
    onAssignPromotionToGroup,
    onRemovePromotionFromGroup,
    onDeleteGroupModal,
    onDeleteGroup,
    onAddMemberToGroup,
    onLeavingAddMemberToGroupPage,
    onCleanupAddMemberToGroupMetadata,
];
const groupMembersEpics = [
    onFetchGroupMembers,
    onFetchGroupNonMembers,
];

const groupsEpics = [
    onFetchGroupsList,
    onOpenCreateNewGroupModal,
    onCreateNewGroup,
];

const industriesEpics = [
    onFetchIndustriesList,
];

const kycKybEpics = [
    onFetchCurrentKycKybFlow,
    onSetKycKybFlow,
    onTriggerKycKybFlow,
    onFetchKycKybLogsList,
];

const marketingPreferencesEpics = [
    onFetchMembersMarketPreferences,
    onRequestMarketingPreferences,
    onShowRequestMarketingPreferencesModal,
];

const memberEpics = [
    onFetchMemberDetails,
    onOpenUpdateMemberModal,
    onUpdateMember,
];

const membersEpics = [
    onFetchMembers,
    onCreateMember,
];

const multiStepsFormsEpics = [
    onRequestSetMultiStepsFormStep,
    onRequestSetMultiStepsFormSubStep,
    onMultiStepsFormStepOrSubSepChange,
];

const modalEpics = [
    onShowViewJsonModal,
];

const navigationEpics = [
    onNavigationRequested,
    onNavigationBackRequested,
    onBlockNavigation,
    onUnblockNavigation,
];

const offboardingEpics = [
    onStartOffboarding,
    onStopOffboarding,
    onFinaliseOffboarding,
];
const operationsEpics = [
    onFetchOperationsList,
    onFetchRolesTemplatesList,
];


const outgoingTransferEpics = [
    onFetchAllBeneficiaries,
    onFetchAllAccounts,
    onSubmitOutgoingTransferFormStep,
    onGoToPreviousOutgoingTransferFormStep,
    onCreateOutgoingTransfer,
];

const permissionsEpics = [
    onFetchPermissionsForRole,
];

const promotionEpics = [
    onFetchPromotionDetails,
    onRemovePromotion,
    onEnablePromotion,
    onDisablePromotion,
];

const promotionsEpics = [
    onFetchClientPromotionsList,
    onCreatePromotion,
];

const riskAssessmentsEpics = [
    onFetchRiskAssessments,
];

const riskScoreHistoryEpics = [
    onFetchRiskScoreHistoryList,
];

const rolesEpics = [
    onFetchRolesList,
];

const roleEpics = [
    onFetchRoleDetails,
    onUpdateRole,
    onCreateRole,
    onDeleteRole,
];

const topupEpics = [
    onFetchTopupList,
];


const transactionEpics = [
    onFetchTransactionDetails,
    onCreateP2PTransaction,
    onRefundTopUp,
    onUnblockTransaction,
    onAuditPendingTransaction,
];

const transactionsEpics = [
    onGetTransactions,
    onGetTransactionsFilters,
    onRefreshTransactions,
];


const userEpics = [
    onFetchUserDetails,
    onEditUser,
    onBanUser,
    onDeleteUser,
    onResendConfirmationEmail,
    onOpenAddEditUserModal,
];

const usersEpics = [
    onFetchUsers,
    onAddUser,
];

const webhooksEpics = [
    onFetchWebhooks,
    onFetchWebhooksActionTypes,
    onOpenRegisterOrEditWebhookModal,
    onRegisterWebhook,
    onUpdateWebhook,
    onUnregisterWebhook,
];

const failedWebhooksEpics = [
    onFetchFailedWebhooks,
    onReplayAllFieldWebhooks,
];

const epics = [
    ...accountEpics,
    ...accountsEpics,
    ...addressEpics,
    ...apiKeysEpics,
    ...applicationEpics,
    ...applicationsEpics,
    ...authorisation,
    ...beneficiariesEpics,
    ...beneficiaryEpics,
    ...beneficiaryAddEpics,
    ...businessStakeholdersEpics,
    ...businessUserEpics,
    ...businessUsersEpics,
    ...businessUserStatusEpics,
    ...cardEpics,
    ...cardsEpics,
    ...card3dSecureEpics,
    ...cardLinkingEpics,
    ...cardOrderEpics,
    ...cardStatusEpics,
    ...clientEpics,
    ...clientProductsEpics,
    ...clientMigrationsEpics,
    ...clientAddEpics,
    ...clientContextEpics,
    ...clientsEpics,
    ...navigationEpics,
    ...companyEpics,
    ...companyStatusEpics,
    ...companiesEpics,
    ...countriesEpics,
    ...currenciesEpics,
    ...cardTiersEpics,
    ...supportedCurrenciesEpics,
    ...currentUserEpics,
    ...exchangeEpics,
    ...failedWebhooksEpics,
    ...failedWebhookEpics,
    ...failedWebhookHistoryEpics,
    ...feeEpics,
    ...feesEpics,
    ...manualFeesEpics,
    ...feeTiersEpics,
    ...groupEpics,
    ...groupMembersEpics,
    ...groupsEpics,
    ...industriesEpics,
    ...kycKybEpics,
    ...limitEpics,
    ...limitsEpics,
    ...marketingPreferencesEpics,
    ...memberEpics,
    ...membersEpics,
    ...memberStatusEpics,
    ...multiStepsFormsEpics,
    ...modalEpics,
    ...offboardingEpics,
    ...operationsEpics,
    ...outgoingTransferEpics,
    ...permissionsEpics,
    ...promotionEpics,
    ...promotionsEpics,
    ...riskAssessmentsEpics,
    ...riskScoreHistoryEpics,
    ...roleEpics,
    ...rolesEpics,
    ...topupEpics,
    ...transactionEpics,
    ...transactionsEpics,
    ...transactionStatusEpics,
    ...userEpics,
    ...usersEpics,
    ...webhooksEpics,
];


export const areEpicsDoubled = Array.from(new Set(epics)).length !== epics.length;

if (areEpicsDoubled) {
    throw Error(`Doubled epics! ${Array.from(new Set(epics)).length} vs ${epics.length}`);
}

const onAnyEpic = combineEpics(...epics);

function errorHandler(error, source) {
    if (developmentMode) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return source;
}

const onEpic = (action$, store$, dependencies) => onAnyEpic(action$, store$, dependencies).pipe(catchError(errorHandler));


export default onEpic;
