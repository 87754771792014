import { LocationPathname } from '@manigo/manigo-commons';
import { NumericId, RawJwtToken, SessionUuid, Uuid } from '@manigo/manigo-domain-typings';


export const INITIALISE_LOGIN = 'INITIALISE_LOGIN';
export const INITIALISE_LOGIN_SUCCESS = 'INITIALISE_LOGIN_SUCCESS';
export const INITIALISE_LOGIN_FAILURE = 'INITIALISE_LOGIN_FAILURE';

export const CONFIRM_LOGIN = 'CONFIRM_LOGIN';
export const CONFIRM_LOGIN_SUCCESS = 'CONFIRM_LOGIN_SUCCESS';
export const CONFIRM_LOGIN_FAILURE = 'CONFIRM_LOGIN_FAILURE';

export const UNLOCK_LOGIN = 'UNLOCK_LOGIN';
export const UNLOCK_LOGIN_SUCCESS = 'UNLOCK_LOGIN_SUCCESS';
export const UNLOCK_LOGIN_FAILURE = 'UNLOCK_LOGIN_FAILURE';

export const CLEAR_LOGIN_BLOCK = 'CLEAR_LOGIN_BLOCK';

export const LOGIN_COUNTDOWN_TIME = 180000; // 3 minutes
export const RESEND_CONFIRMATION_CODE_COUNTDOWN_TIME = 60000; // 1 min

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const SET_PASSCODE_DIGIT = 'SET_PASSCODE_DIGIT';
export const CLEAR_ALL_PASSCODE_DIGITS = 'CLEAR_ALL_PASSCODE_DIGITS';

export const CLEAR_REQUEST_PASSWORD_RESET = 'CLEAR_REQUEST_PASSWORD_RESET';

export const SEND_CONFIRMATION_CODE = 'SEND_CONFIRMATION_CODE';
export const SEND_CONFIRMATION_CODE_SUCCESS = 'SEND_CONFIRMATION_CODE_SUCCESS';
export const SEND_CONFIRMATION_CODE_FAILURE = 'SEND_CONFIRMATION_CODE_FAILURE';
export const CLEAR_BLOCK_SEND_CONFIRMATION_CODE = 'CLEAR_BLOCK_SEND_CONFIRMATION_CODE';

export const GET_REMEMBERED_USERNAME_OR_EMAIL = 'GET_REMEMBERED_USERNAME_OR_EMAIL';
export const GET_REMEMBERED_USERNAME_OR_EMAIL_SUCCESS = 'GET_REMEMBERED_USERNAME_OR_EMAIL_SUCCESS';
export const GET_REMEMBERED_USERNAME_OR_EMAIL_FAILURE = 'GET_REMEMBERED_USERNAME_OR_EMAIL_FAILURE';

export const SET_FAILED_LOGIN_ATTEMPTS = 'SET_FAILED_LOGIN_ATTEMPTS';

export const TOGGLE_REMEMBER_ME = 'TOGGLE_REMEMBER_ME';

export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';

export const GET_TOKENS_FOR_DUPLICATED_TAB = 'GET_TOKENS_FOR_DUPLICATED_TAB';


export interface SendConfirmationCodePayload {
  token: SessionUuid,
}

export interface ConfirmLoginPayload {
  passcode: string,
  emailOrUsername: string,
}

export interface InitialiseLoginPayload {
  emailOrUsername: string,
  password: string,
  remember: boolean,
}
export interface InitialiseLoginResponse {
  id: number,
  session_uuid: SessionUuid,
  token: RawJwtToken,
}


export interface SetNewPasswordPayload {
  token: string,
  confirmationCode: string,
  password: string,
  confirmPassword: string,
}

export interface RequestPasswordResetPayload {
  email: string,
}

export interface UnlockUserPayload {
  extUserUuid: Uuid,
  context: {
    memberId?: NumericId,
    userId?: NumericId,
    businessUserId?: NumericId,
    locationPathname: LocationPathname,
  }
}
export interface GetTokensForDuplicatedTabPayload {
  sessionUuid: SessionUuid,
  accessToken: RawJwtToken,
}

