import { extractSegmentsFromPathname } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { standardPageSize } from 'config/config';
import { defaultQueryParamsMeta, updateQueryParamsMeta } from 'constants/ApplicationStateModel';
import { globalLocation, LocationChangeActionScopeTypes, NavigationDirection, RedirectionToDefaultPathTypes } from 'constants/NavigationModel';
import { navigationReducerName } from 'redux/navigation/reducer';

import { createAction } from '@reduxjs/toolkit';


export const standardListStateDataPlaceholder = {
    items: undefined,
    totalCount: null,
    isLoadingList: false,
    queryParams: {
        limit: standardPageSize,
        offset: 0,
        sortBy: undefined,
        sortOrder: undefined,
    },
    queryParamsMeta: defaultQueryParamsMeta,
};


export function updateStandardLists(stateListsKey = {}, action) {
    const locationKey = action.payload?.locationPathname;
    const queryParamsMeta = updateQueryParamsMeta();
    return {
        ...stateListsKey,
        [locationKey]: {
            ...stateListsKey[locationKey],
            // XXX suspicious fix!
            // if there is now query params and fetch request has them, add them
            ...(!stateListsKey[locationKey]?.queryParams && action?.payload?.queryParams ? { queryParams: action.payload.queryParams } : {}),
            queryParamsMeta,
            isLoadingList: true,
        },
    };
}

export function updateStandardListsOnSuccess(
    stateListsKey = {},
    action,
) {
    const queryParamsMeta = updateQueryParamsMeta();
    const locationKey = action.payload?.locationPathname;

    if (locationKey) {
        return {
            ...stateListsKey,
            [locationKey]: {
                ...stateListsKey[locationKey],
                queryParamsMeta,
                isLoadingList: false,
                items: action.payload?.items,
                totalCount: action.payload?.totalCount,
            },
        };
    }
    return stateListsKey;
}

export function updateStandardListsOnFailure(stateListsKey = {}, action) {
    const queryParamsMeta = updateQueryParamsMeta(true);
    const locationKey = action.payload?.locationPathname;

    return {
        ...stateListsKey,
        [locationKey]: {
            ...stateListsKey[locationKey],
            queryParamsMeta,
            isLoadingList: false,
            items: undefined,
            totalCount: null,
        },
    };
}


export function getStandardListData({
    state,
    reducerName,
    listsByLocationStoreKeyName,
    useGlobalLocation,
    locationSuffix,
    customLocation,
}:{
  state: object,
  reducerName: string,
  listsByLocationStoreKeyName: string,
  useGlobalLocation?: boolean,
  locationSuffix?: string,
  customLocation?: string
}) {
    const finalDataLocation = useGlobalLocation
        ? globalLocation
        : customLocation || `${state?.[navigationReducerName]?.enhancedCurrentLocation?.pathname}${locationSuffix || ''}`;

    const listData = state?.[reducerName]?.[listsByLocationStoreKeyName]?.[finalDataLocation];


    return listData;
}

export function getCurrentPathname(
    store = {},
) {
    return store?.[navigationReducerName]?.enhancedCurrentLocation?.pathname;
}

export function updateStandardListDataQueryParams(
    listsByLocationStoreData = {},
    action,
) {
    const locationPathname = action?.payload?.locationPathname;
    const listCurrentData = listsByLocationStoreData[action?.payload?.locationPathname];

    if (locationPathname) {
        const currentData = listCurrentData || standardListStateDataPlaceholder;

        const resultData = {
            ...listsByLocationStoreData,
            [locationPathname]: {
                ...currentData,
                queryParams: {
                    ...currentData?.queryParams,
                    ...action.payload?.queryParams,
                },
            },
        };
        return resultData;
    }

    return listsByLocationStoreData;
}


export function clearStandardListData(
    state,
    listsByLocationStoreKeyName: string,
    action: any,
) {
    const { [action.payload?.locationPathname]: thisLocation, ...rest } = state[listsByLocationStoreKeyName];
    return { ...rest };
}

export function updateStandardListsOnCleanup(state, locationKey: string) {
    const existingData = state?.[locationKey];
    if (existingData) {
        const { ...rest } = state;
        return rest;
    }

    return state;
}

export const clearStandardListDataQueryParamsOnNavigation = (
    state,
    {
        action,
        listRouteSegments,
        observedRootPaths,
    },
) => {
    const direction = action?.payload?.direction;
    const navigationState = action?.payload?.state;
    // XXX only interested in forward navigation
    // with the exception for tab changes and
    // STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT witch amends raw navigation to entity details page
    // witch is a navigation correction that occur just after navigation to raw (without active tab segment) entity details path
    if (
        direction === NavigationDirection.FORWARD
    && navigationState?.type !== LocationChangeActionScopeTypes.TAB_CHANGE
    && navigationState?.type !== RedirectionToDefaultPathTypes.STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT
    ) {
        const [rootSegment, idOrUuidSegment] = extractSegmentsFromPathname(action?.payload);
        const currentlyStoredLists = Object.keys(state);

        // XXX only interested in detail screens  e.g. '/member/1' -> `/member/1/${listRouteSegment}`
        // and only when there is sth to be potentially cleaned
        if (observedRootPaths?.includes(`/${rootSegment}`)
      && idOrUuidSegment
      && currentlyStoredLists?.length > 0
        ) {
            if (listRouteSegments?.length > 0) {
                const matchingLists = currentlyStoredLists.filter((listKey) => {
                    const regularExpression = new RegExp(`^/${rootSegment}/${idOrUuidSegment}(/([a-zA-Z-])*)?/(${listRouteSegments.join('|')})$`, 'g');
                    return listKey.match(regularExpression) ? listKey : false;
                });

                if (listRouteSegments?.length > 0) {
                    return matchingLists.reduce((acc, item) => updateStandardListsOnCleanup(acc, item), state);
                }

                return state;
            }

            return state;
        }
        return state;
    }
    return state;
};

export const createListActions = ({
    base,
    success,
    failure,
    clear,
}:{
  base: string,
  success: string,
  failure: string,
  clear: string
}) => ({
    fetchList: createAction(base, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } })),
    fetchListSuccess: createAction(success, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } })),
    fetchListFailure: createAction(failure, (locationPathname) => ({ payload: { locationPathname } })),
    clearList: createAction(clear, (locationPathname) => ({ payload: { locationPathname } })),
});

export default {
    standardListStateDataPlaceholder,
    // map state to props store getter
    getStandardListData,
    getCurrentPathname,

    // standard 3 actions for API call + clear
    updateStandardLists,
    updateStandardListsOnSuccess,
    updateStandardListsOnFailure,
    updateStandardListsOnCleanup,

    // standard query params & navigation-triggered updates
    updateStandardListDataQueryParams,
    clearStandardListData,
    clearStandardListDataQueryParamsOnNavigation,
};
