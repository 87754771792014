import MemberSettings from 'components/pages/authorised/MemberDetails/Tabs/Settings/Settings.layout';
import { createClientDetailsPath } from 'config/routes.helpers';
import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { fetchClientDetails } from 'redux/client/actions';
import { clientDetailsPerLocationStoreKeyName, clientReducerName } from 'redux/client/reducer';
import { updateMember } from 'redux/member/actions';
import { memberDetailsPerLocationsStoreKeyName, memberReducerName } from 'redux/member/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import { SettingsDispatchProps, SettingsOwnProps, SettingsStateProps } from './Settings.types';


const mapStateToProps = (state:RootState, ownProps: SettingsOwnProps):SettingsStateProps => {
    const entityDetailsData = getStandardEntityDetailsData({
        state,
        reducerName: clientReducerName,
        fieldName: clientDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
        customLocation: createClientDetailsPath(ownProps?.clientId),
    });

    return ({
        enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
        entityDetailsData: getStandardEntityDetailsData({
            state,
            reducerName: memberReducerName,
            fieldName: memberDetailsPerLocationsStoreKeyName,
            baseURLSegmentsCount: 2,
        }),
        isLoadingUpdateMember: state.member.isLoadingUpdateMember,
        clientDetails: entityDetailsData?.entityDetails,
    });
};

const mapDispatchToProps = (dispatch:Dispatch):SettingsDispatchProps => ({
    dispatchUpdateMember: (memberId, formData, locationPathname) => dispatch(updateMember(memberId, formData, locationPathname)),
    dispatchFetchClientDetails: (queryParams, locationPathname) => dispatch(fetchClientDetails(queryParams, locationPathname)),
});

const decorate = compose<ComponentType<SettingsOwnProps>>(
    withTranslation('member'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(MemberSettings);
