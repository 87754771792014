import styled from 'styled-components';


const TabSubHeading = styled.div`
  display: grid;
  margin: 0 0 20px 0;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-column-gap: 10px;
  align-items: center;
  border-bottom: 1px solid #F0F0F0;
`;

const TabSubHeadingText = styled.div`
  padding-bottom: 7px;
  padding-top: 15px;
  color: #CACACA;
  text-transform: uppercase;
  text-align: left;
`;

const TabSubHeadingOptionalContent = styled.div`
  justify-self: right;
`;

export default {
    TabSubHeading,
    TabSubHeadingText,
    TabSubHeadingOptionalContent,
};
