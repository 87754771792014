export const rootRoutePath = '/';
export const loginRootRoutePath = '/login';
export const passcodeConfirmRootRoutePath = '/confirm';
export const forgotPasswordRootRoutePath = '/forgot-password';
export const setPasswordRootRoutePath = '/set-password';

export const accountRootRoutePath = '/account';

export const clientRouteSegmentPath = 'partner';
export const clientRootRoutePath = `/${clientRouteSegmentPath}`;

export const globalListsRootRoutePath = '/global-lists';

export const clientsRootRoutePath = '/partners';


export const addPromotionRouteSegmentPath = 'add-promotion';
export const addMembersRouteSegmentPath = 'add-members';
export const newTransactionRouteSegmentPath = 'new-transaction';
export const genericAddRouteSegmentPath = 'add';
export const genericEditRouteSegmentPath = 'edit';
export const genericHistoryRouteSegmentPath = 'history';
export const genericDataRouteSegmentPath = 'data';
export const genericDetailsRouteSegmentPath = 'details';
export const editSettingsRouteSegmentPath = `${genericEditRouteSegmentPath}-settings`;
export const editRoleRootRouteSegmentPath = `/${genericEditRouteSegmentPath}-role`;
export const cardsOrderRouteSegmentPath = 'order';
export const feeRouteSegmentPath = 'fee';
export const tiersRouteSegmentPath = 'tiers';
export const countriesRouteSegmentPath = 'countries';
export const currenciesRouteSegmentPath = 'currencies';
export const industriesRouteSegmentPath = 'industries';
export const promotionRouteSegmentPath = 'promotion';
export const withdrawRouteSegmentPath = 'withdraw';
export const announcementRouteSegmentPath = 'announcement';
export const settingsRouteSegmentPath = 'settings';
export const limitRouteSegmentPath = 'limit';
export const limitsRouteSegmentPath = 'limits';
export const secure3DsRouteSegmentPath = 'secure-3ds';
export const riskScoreRouteSegmentPath = 'risk-score';
export const riskAssessmentsRouteSegmentPath = 'risk-assessments';
export const riskScoreHistoryRouteSegmentPath = `risk-score-${genericHistoryRouteSegmentPath}`;
export const requestRouteSegmentPath = 'request';
export const responseRouteSegmentPath = 'response';
export const statusHistoryRouteSegmentPath = `status-${genericHistoryRouteSegmentPath}`;
export const errorsHistoryRouteSegmentPath = `errors-${genericHistoryRouteSegmentPath}`;
export const errorDetailsRouteSegmentPath = `error-${genericDetailsRouteSegmentPath}`;
export const dataDetailsRouteSegmentPath = `data-${genericDetailsRouteSegmentPath}`;
export const headersDetailsRouteSegmentPath = `headers-${genericDetailsRouteSegmentPath}`;

export const changeStatusRouteSegmentPath = 'change-status';
export const kycLevelRouteSegmentPath = 'kyc-level';
export const kybLevelRouteSegmentPath = 'kyb-level';
export const dueDiligenceKycRouteSegmentPath = 'due-diligence-kyc';
export const dueDiligenceKybRouteSegmentPath = 'due-diligence-kyb';


export const accountsRouteSegmentPath = 'accounts';
export const apiKeysRouteSegmentPath = 'api-keys';
export const beneficiariesRouteSegmentPath = 'beneficiaries';
export const applicationsRouteSegmentPath = 'applications';
export const cardsRouteSegmentPath = 'cards';
export const companiesRouteSegmentPath = 'companies';
export const complianceRouteSegmentPath = 'compliance';
export const statusManagementRouteSegmentPath = 'status-management';
export const businessUsersRouteSegmentPath = 'business-users';

export const feesRouteSegmentPath = 'fees';
export const manualFeesRouteSegmentPath = 'manual-fees';
export const automaticFeesRouteSegmentPath = 'automatic-fees';

export const groupsRouteSegmentPath = 'groups';
export const importMembersRouteSegmentPath = 'import-members';
export const membersRouteSegmentPath = 'members';
export const topupCardsRouteSegmentPath = 'topup-cards';
export const pendingTransactionsRouteSegmentPath = 'pending-transactions';
export const promotionsRouteSegmentPath = 'promotions';
export const productsRouteSegmentPath = 'products';
export const stakeholdersRouteSegmentPath = 'stakeholders';
export const transactionsRouteSegmentPath = 'transactions';
export const marketingPreferencesRouteSegmentPath = 'marketing-preferences';
export const transactionRouteSegmentPath = 'transaction';

export const generalRouteSegmentPath = 'general';
export const localBankDetailsRouteSegmentPath = 'local-bank-details';
export const globalBankDetailsRouteSegmentPath = 'global-bank-details';

export const webhooksRouteSegmentPath = 'webhooks';
export const webhooksManagementRouteSegmentPath = 'management';
export const webhooksFailedRouteSegmentPath = 'failed';
export const failedWebhookRouteSegmentPath = 'failed-webhook';

export const firstLevelTabsSlugName = 'activeMainTabSegmentURL';
export const secondLevelTabsSlugName = 'activeSubTabSegmentURL';


export const groupRootRoutePath = '/group';
export const cardRootRoutePath = '/card';
export const cardsRootRoutePath = `/${cardsRouteSegmentPath}`;
export const cardsOrderRootNestedRoutePath = `${cardsRootRoutePath}/${cardsOrderRouteSegmentPath}`;

export const feeRootRoutePath = `/${feeRouteSegmentPath}`;
export const promotionRootRoutePath = `/${promotionRouteSegmentPath}`;

export const memberRootRoutePath = '/member';
export const membersRootRoutePath = `/${membersRouteSegmentPath}`;
export const addMemberRootNestedRoutePath = `${membersRootRoutePath}/${genericAddRouteSegmentPath}`;

export const companyRouteSegmentPath = 'company';
export const companyRootRoutePath = `/${companyRouteSegmentPath}`;
export const companiesRootRoutePath = `/${companiesRouteSegmentPath}`;

export const dashboardRootRoutePath = '/dashboard';
export const faqRootRoutePath = '/faq';
export const privacyPolicyRootRoutePath = '/privacy-policy';
export const attributionsRoutePath = '/attributions';
export const currentUserProfileRootRoutePath = '/my-profile';

export const transactionsRootRoutePath = `/${transactionsRouteSegmentPath}`;
export const transactionRootRoutePath = `/${transactionRouteSegmentPath}`;

export const userRootRoutePath = '/backoffice-user';
export const usersRootRoutePath = '/backoffice-users';

export const roleRootRoutePath = '/role';
export const rolesRootRoutePath = '/roles';

export const businessUserRootRoutePath = '/business-user';

export const beneficiaryRootRoutePath = '/beneficiary';
export const editBeneficiaryRootRoutePath = `${beneficiaryRootRoutePath}/${genericEditRouteSegmentPath}`; // TODO turn it into nested

export const failedWebhookRootRoutePath = `/${failedWebhookRouteSegmentPath}`;

export const limitRootPath = `/${limitRouteSegmentPath}`;
export const limitsRootRoutePath = `/${limitsRouteSegmentPath}`;

export const publicRoutes = [
    loginRootRoutePath,
    passcodeConfirmRootRoutePath,
    forgotPasswordRootRoutePath,
    setPasswordRootRoutePath,
];

export const authorisedRoutesWithoutClientContext = [
    addMemberRootNestedRoutePath,
    cardsRootRoutePath,
    clientsRootRoutePath,
    cardsOrderRootNestedRoutePath,
    companiesRootRoutePath,
    dashboardRootRoutePath,
    membersRootRoutePath,
    transactionsRootRoutePath,
    usersRootRoutePath,
    firstLevelTabsSlugName,
    secondLevelTabsSlugName,
    attributionsRoutePath,
];

