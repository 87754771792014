import StandardListTable from 'components/common/organisms/StandardListTable';
import { AccessControlProps } from 'constants/AccessControlModel';
import { ApiKeysListProp } from 'constants/ApiKeysModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultApiKeysListQueryParams } from 'redux/api-keys/reducer';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import tableColumns from './tableColumns';


function ApiKeysList({
    // own prop
    clientId,

    enhancedCurrentLocation,
    listData,
    accessControl,


    // isLoadingDeleteApiKey,
    isLoadingCreateApiKey,

    dispatchFetchApiKeysList,
    dispatchClearApiKeysList,

    dispatchFetchApiKeyValue,
    dispatchOpenCreateApiKeyModal,
    dispatchSetQueryParams,
    dispatchDeleteApiKey,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { clientId };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchApiKeysList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultApiKeysListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchApiKeysList,
        dispatchClearItemsList: dispatchClearApiKeysList,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependent optional members list buttons
    const additionalButtons = useMemo(
        () => (accessControl.hasPermission(PN.API_KEY.CREATE)
            ? [{
                type: 'primary',
                text: t('buttons.addApiKey.text'),
                icon: <PlusOutlined />,
                isLoading: isLoadingCreateApiKey,
                onClick: () => dispatchOpenCreateApiKeyModal(clientId),
            }] : []), [accessControl],
    );


    return (
        <StandardListTable
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={tableColumns({
                accessControl,
                dispatchDeleteApiKey,
                queryParams: listData?.queryParams,
                dispatchFetchApiKeyValue,
                enhancedCurrentLocation,
                t,
            })}
            filtersButtonsConfig={additionalButtons}
        />
    );
}

ApiKeysList.propTypes = {
    // ownProps
    clientId: PropTypes.number.isRequired, // XXX used in mapDispatchToProps

    // mapped props
    accessControl: AccessControlProps,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: ApiKeysListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    // isLoadingDeleteApiKey: PropTypes.bool.isRequired,
    isLoadingCreateApiKey: PropTypes.bool.isRequired,

    dispatchFetchApiKeysList: PropTypes.func.isRequired,
    dispatchClearApiKeysList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchOpenCreateApiKeyModal: PropTypes.func.isRequired,
    dispatchDeleteApiKey: PropTypes.func.isRequired,
    dispatchFetchApiKeyValue: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default ApiKeysList;
