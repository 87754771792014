import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const TooltipWrapper = styled.div`
  width: 100%;
`;

const InfoIconAndValueWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto minmax(0, 1fr);
  cursor: help;
  max-width: 100%;
  align-items: center;
  justify-content: flex-start;
  ${commonTextEllipsisStyles}
`;

const InfoIcon = styled(InfoCircleOutlined)`
  margin-right: 1ch;
`;

const ValueWrapper = styled.div`
  ${commonTextEllipsisStyles}
`;

export default {
    TooltipWrapper,
    InfoIconAndValueWrapper,
    InfoIcon,
    ValueWrapper,
};
