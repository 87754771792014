import CompanyRiskTag from 'components/common/atoms/tags/CompanyRiskTag';
import CompanyStatusTag from 'components/common/atoms/tags/CompanyStatusTag';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { CountryIdType } from 'constants/CountriesModel';
import { enumValueToReadableString } from 'utils/string-tools';

import React from 'react';


export const createCompanyBaseDetailsFields = ({
    companyDetails,
    t,
}) => {
    if (companyDetails?.company_id) {
        return [
            {
                label: t('companyFields.createdAt'),
                value: <StandardValue value={companyDetails.created_at} valueType="DATE" />,
            },
            {
                label: t('companyFields.name'),
                showEmpty: true,
                value: <StandardValue value={companyDetails?.company_name} showCopyToClipboard />,
            },
            {
                label: t('companyFields.phoneNumber'),
                value: <StandardValue value={companyDetails?.company_phone_number} valueType="PHONE" />,
            },
            {
                label: t('member:memberFields.email'),
                value: <StandardValue value={companyDetails?.company_email} showCopyToClipboard />,
            },
            {
                label: t('companyFields.registrationNumber'),
                value: <StandardValue value={companyDetails?.company_registration_number} />,
            },
            {
                label: t('companyFields.type'),
                value: <StandardValue value={enumValueToReadableString(companyDetails.company_type)} />,
            },
            ...(companyDetails.country_of_incorporation ? [{
                label: t('companyFields.countryOfIncorporation'),
                value: <CountryRenderer propertyName={CountryIdType.iso_31663} value={companyDetails.country_of_incorporation} />,
            }] : []),
        ];
    }

    return [];
};

export const createCompanySystemDetailsFields = ({
    companyDetails,
    t,
}) => {
    if (companyDetails?.company_id) {
        return [
            {
                label: t('companyFields.externalUserId'),
                value: <StandardValue value={companyDetails.external_user_id} showCopyToClipboard />,
            },
            {
                label: t('companyFields.status'),
                value: <CompanyStatusTag status={companyDetails?.company_status} t={t} />,
            },
            {
                label: 'Risk Score',
                value: <CompanyRiskTag score={companyDetails?.company_total_risk_score} company={companyDetails} />,
            },
            ...(companyDetails?.closure_start_date
                ? [{
                    label: t('companyFields.closureStartDate'),
                    value: <StandardValue value={companyDetails?.closure_start_date} valueType="DATE" />,
                }]
                : []
            ),
            ...(companyDetails?.closure_end_date
                ? [{
                    label: t('companyFields.closureEndDate'),
                    value: <StandardValue value={companyDetails?.closure_end_date} valueType="DATE" />,
                }]
                : []
            ),
        ];
    }

    return [];
};


export default {
    createCompanyBaseDetailsFields,
    createCompanySystemDetailsFields,
};
