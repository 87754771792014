/* eslint-disable class-methods-use-this */

import { AccountOwnerTypes } from 'constants/AccountModel';
import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';
import { cleanNilValues } from 'utils/dataMappers';

import { AccountDetails } from '@manigo/manigo-domain-typings';


import {
    getAccountIdDetails,
    createNewAccountApi,
    deleteAccountId,
    getAccountsAPI, updateExistingAccountApi,
} from './index';


class AccountsService {
    public getAccountDetails(accountId: AccountDetails['id']): Promise<ApiResponse | ApiError> {
        return getAccountIdDetails(accountId, { convertSnakeCaseKeysToCamelCase: true });
    }

    public getAccountsList(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { ownerType, sortOrder, sortBy = 'id', ownerId, ...standardQueryParams } = rawQueryParams;

        const handlers = [
            {
                predicate: () => ownerType === AccountOwnerTypes.CLIENT,
                handler: () => ({ clientId: ownerId }),
            },
            {
                predicate: () => ownerType === AccountOwnerTypes.COMPANY,
                handler: () => ({ companyId: ownerId }),
            },
            {
                predicate: () => ownerType === AccountOwnerTypes.MEMBER,
                handler: () => ({ memberId: ownerId }),
            },
            {
                predicate: () => true,
                handler: () => ({}),
            },
        ];


        const queryParams = {
            params: cleanNilValues({
                ...standardQueryParams,
                sortBy,
                ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
                ...(handlers.filter(({ predicate }) => predicate())[0].handler()),
            }),
        };
        return getAccountsAPI(queryParams);
    }


    public createNewAccount(requestPayload): Promise<ApiResponse | ApiError> {
        return createNewAccountApi(requestPayload);
    }

    public deleteAccount(accountId: AccountDetails['id']): Promise<ApiResponse | ApiError> {
        return deleteAccountId(accountId);
    }

    public updateExistingAccount(accountDetails: AccountDetails): Promise<ApiResponse | ApiError> {
    // Extract forbidden fields
        const {
            id,
            memberId,
            clientId,
            companyId,
            businessUserId,
            extAccountId,
            currency,
            type,
            balance,
            availableBalance,
            blockedBalance,
            currencyDetails,
            accountDeletable,
            createdAt,
            accountIdentifications,
            ...rest
        } = accountDetails;

        return updateExistingAccountApi(id, rest, { convertSnakeCaseKeysToCamelCase: true });
    }
}

export default AccountsService;

