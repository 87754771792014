import { clearCurrentUser } from 'redux/current-user/actions';
import { requestNavigation } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UserDropdown from './UserDropdown.layout';


const mapStateToProps = (state) => ({ currentUser: state.currentUser });

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (location) => dispatch(requestNavigation(location)),
    dispatchClearCurrentUser: () => dispatch(clearCurrentUser()),
});


const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UserDropdown);
