import PropTypes from 'prop-types';


export const AllCardStatuses = Object.freeze({
    // editable statuses
    NOT_ACTIVATED: 'NOT_ACTIVATED',
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',

    // final(permanent) statuses, card is in a read-only mode
    NOT_DELIVERED: 'NOT_DELIVERED',
    LOST: 'LOST',
    STOLEN: 'STOLEN',
    FRAUD: 'FRAUD',
    EXPIRED: 'EXPIRED',
    VOIDED: 'VOIDED',
});

export const CardStatusProp = PropTypes.oneOf(Object.keys(AllCardStatuses));

export const VirtualCardsAvailableStatusesMapping = Object.freeze({
    ACTIVE: [AllCardStatuses.SUSPENDED, AllCardStatuses.FRAUD],
    SUSPENDED: [AllCardStatuses.ACTIVE, AllCardStatuses.FRAUD],
});

export const PhysicalCardsAvailableStatusesMapping = Object.freeze({
    NOT_ACTIVATED: [AllCardStatuses.NOT_DELIVERED, AllCardStatuses.LOST, AllCardStatuses.STOLEN, AllCardStatuses.FRAUD],
    ACTIVE: [AllCardStatuses.SUSPENDED, AllCardStatuses.LOST, AllCardStatuses.STOLEN, AllCardStatuses.FRAUD],
    SUSPENDED: [AllCardStatuses.ACTIVE, AllCardStatuses.LOST, AllCardStatuses.STOLEN, AllCardStatuses.FRAUD],
});

export const VirtualCardsStatusesList = Object.freeze([
    AllCardStatuses.ACTIVE,
    AllCardStatuses.SUSPENDED,

    AllCardStatuses.VOIDED,
    AllCardStatuses.FRAUD,
    AllCardStatuses.EXPIRED,
]);


export const PhysicalCardsStatusesList = Object.freeze([
    AllCardStatuses.NOT_ACTIVATED,
    AllCardStatuses.ACTIVE,
    AllCardStatuses.SUSPENDED,

    AllCardStatuses.NOT_DELIVERED,
    AllCardStatuses.LOST,
    AllCardStatuses.STOLEN,
    AllCardStatuses.FRAUD,
    AllCardStatuses.EXPIRED,
]);


export const EditableCardStatusesList = Object.freeze([
    AllCardStatuses.NOT_ACTIVATED,
    AllCardStatuses.ACTIVE,
    AllCardStatuses.SUSPENDED,
]);

export const PermanentCardStatusesList = Object.freeze([
    AllCardStatuses.NOT_DELIVERED,
    AllCardStatuses.LOST,
    AllCardStatuses.STOLEN,
    AllCardStatuses.FRAUD,
    AllCardStatuses.EXPIRED,
    AllCardStatuses.VOIDED,
]);


export const CardType = Object.freeze({
    PHYSICAL: 1,
    VIRTUAL: 2,
});

export const CardOrderTarget = Object.freeze({
    BUSINESS_USERS: 'business_users',
    COMPANIES: 'companies',
    CLIENTS: 'clients',
    MEMBERS: 'members',
});

export const CardDeliveryType = Object.freeze({
    SINGLE: 'standard',
    BULK: 'bulk',
});

export const CardDeliveryMethod = Object.freeze({
    STANDARD: 0,
    REGISTERED_MAIL: 1,
    DIRECT_DELIVERY: 2,
});


export const CardChallengeMethods = Object.freeze({
    IN_APP_AUTH: 'IN_APP',
    SMS_OTC: 'SMS_OTC',
});

export const CardChallengeManagers = {
    MANIGO: 'MANIGO',
    PARTNER: 'PARTNER',
};

export const CardLimitsProps = PropTypes.shape({
    spent_monthly: PropTypes.number,
    limit_monthly: PropTypes.number,
});

export const CardDetailsProps = PropTypes.shape({
    id: PropTypes.number.isRequired,
    cvv: PropTypes.string,
    type: PropTypes.number,
    token: PropTypes.string.isRequired,
    card_token: PropTypes.string.isRequired,
    card_type: PropTypes.string,
    card_status: CardStatusProp,
    card_status_comment: PropTypes.string,
    card_limit: PropTypes.number,
    card_spent: PropTypes.number,

    expiry: PropTypes.string,
    emboss_name: PropTypes.string,
    pan_mask: PropTypes.string,

    created_at: PropTypes.string,
    updated_at: PropTypes.string,

    enrolled: PropTypes.bool.isRequired,
    is_linked: PropTypes.bool.isRequired,
    has_limit: PropTypes.bool.isRequired,

    contactless_payment_enabled: PropTypes.bool.isRequired,
    online_payment_enabled: PropTypes.bool.isRequired,
    magstripe_enabled: PropTypes.bool.isRequired,
    atm_withdrawal_enabled: PropTypes.bool.isRequired,

    limits: CardLimitsProps.isRequired,

    image_url: PropTypes.string,
    card_image: PropTypes.string,

    account_id: PropTypes.number,
    member_id: PropTypes.number,
    client_id: PropTypes.number,
    company_id: PropTypes.number,
    business_user_id: PropTypes.number,

    request: PropTypes.string,
    response: PropTypes.string,

    account: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        currency: PropTypes.string,
        balance: PropTypes.string,
    }),
});


export const CardStatusChangesChannels = Object.freeze({
    BACKOFFICE: 'BACKOFFICE',
    PERSONAL_MOBILE_APP: 'PERSONAL_MOBILE_APP',
    BUSINESS_WEB_APP: 'BUSINESS_WEB_APP',
    API_USER: 'API_USER',
    SCHEDULER: 'SCHEDULER',
});

export const CardStatusChangesChannelProp = PropTypes.oneOf(Object.keys(CardStatusChangesChannels));


export const CardStatusChangeEntryProps = PropTypes.shape({
    api_user_id: PropTypes.number,
    bo_user_id: PropTypes.number,
    business_user_id: PropTypes.number,
    card_id: PropTypes.number.isRequired,
    card_status: CardStatusProp.isRequired,
    channel: CardStatusChangesChannelProp.isRequired,
    client_id: PropTypes.number.isRequired,
    company_id: PropTypes.number,
    created_at: PropTypes.string.isRequired, // "2022-08-15T08:07:26.000Z"
    id: PropTypes.number.isRequired,
    internal_comment: PropTypes.string.isRequired,
    member_id: PropTypes.number.isRequired,
    updated_at: PropTypes.string.isRequired, // "2022-08-15T08:07:26.000Z"
});


export const cardMaxLimitValue = 9999999.99;

export default {
    AllCardStatuses,
    CardStatusProp,
    VirtualCardsAvailableStatusesMapping,
    PhysicalCardsAvailableStatusesMapping,
    VirtualCardsStatusesList,
    PhysicalCardsStatusesList,
    EditableCardStatusesList,
    PermanentCardStatusesList,
    CardType,
    CardOrderTarget,
    CardChallengeMethods,
    CardChallengeManagers,
    CardDeliveryType,
    CardDeliveryMethod,
    CardStatusChangesChannels,
    CardStatusChangeEntryProps,
    cardMaxLimitValue,
};
