import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import getRiskAlertTagProps from 'utils/risk-tools';

import { Alert } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';


function RiskAlert({ t, riskAlertConfigs, riskRating }) {
    const alertData = useMemo(() => getRiskAlertTagProps(riskRating).filter(({ predicate }) => predicate())[0].handler(), [riskRating]);
    return (
        <Alert
            type={alertData.type}
            message={(

                <div style={{ display: 'flex', gap: '8px' }}>
                    {riskAlertConfigs?.map((config) => (
                        <StandardTranslationWithMarkup
                            translationKey={`risk.${config.label}`}
                            namespace="common"
                            values={{ value: config?.value }}
                            t={t}
                            key={config.label}
                        />

                    ))}
                </div>
            )}
            style={{ width: 'fit-content' }}
        />
    );
}

export default withTranslation()(RiskAlert);
RiskAlert.propTypes = {
    t: PropTypes.func.isRequired,
    riskAlertConfigs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    riskRating: PropTypes.string,
};

