import { balanceAdjustment } from 'redux/account/actions';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';

import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import BalanceAdjustmentModal from './BalanceAdjustmentModal.layout';
import { BalanceAdjustmentModalDispatchProps, BalanceAdjustmentModalStateProps } from './BalanceAdjustmentModal.types';


const mapStateToProps = (state: RootState): BalanceAdjustmentModalStateProps => ({ enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation });

const mapDispatchToProps = (dispatch:Dispatch):BalanceAdjustmentModalDispatchProps => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchBalanceAdjustment: (formData, locationPathname) => dispatch(balanceAdjustment(formData, locationPathname)),
});


const decorate = compose<ComponentType>(
    withTranslation('accounts'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BalanceAdjustmentModal);

