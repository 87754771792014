import {
    getSubTabFormTabConfig,
    getTabFormTabConfig,
    tabHasSubTabs,
    updateNavigationPath,
} from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { LocationChangeActionTypes, RedirectionToDefaultPathTypes } from 'constants/NavigationModel';


export const handleURLSegmentsAndActiveTab = ({
    baseURLSegmentsCount,
    requestedTabKeyParts,
    enhancedCurrentLocation,
    dispatchRequestNavigation,

    tabsConfig,
    setIsScreenReady,
}) => {
    if (
        tabsConfig?.length > 0
    && !requestedTabKeyParts
    ) {
        const mainTab = getTabFormTabConfig({ activeTab: requestedTabKeyParts?.[0], tabsConfig });
        const mainTabHasSubTabs = tabHasSubTabs(mainTab);
        const defaultSubTab = mainTabHasSubTabs ? getSubTabFormTabConfig({ tabConfig: mainTab, activeTab: requestedTabKeyParts?.[1] }) : undefined;

        const handlers = [
            {
                // pristine redirection (REPLACE!) on enter 'raw' path
                // correct path and set default/first activeTab e.g. /member/1 & activeTab = undefined --> /member/1 -> /member/1/accounts & activeTab = accounts
                predicate: () => !requestedTabKeyParts,
                handler: () => {
                    const newPath = updateNavigationPath({
                        baseURLSegmentsCount,
                        enhancedCurrentLocation,
                        requestedTabKeyParts: [
                            mainTab.activeMainTabSegmentURL,
                            ...(mainTabHasSubTabs && defaultSubTab ? [defaultSubTab?.activeSubTabSegmentURL] : []),
                        ],
                    });

                    setIsScreenReady(true);
                    dispatchRequestNavigation(newPath, {
                        replace: true,
                        state: {
                            ...enhancedCurrentLocation?.state,
                            type: RedirectionToDefaultPathTypes.STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT,
                        },
                    });
                },
            },
            {
                predicate: () => true,
                handler: () => undefined,
            },
        ];

        return handlers.filter(({ predicate }) => predicate())[0].handler();
    }

    return undefined;
};

export const onBrowserPOPNavigationEvents = ({
    dispatchSetActiveTab,
    currentLocation,
    activeTab,
    params,
}) => {
    if (currentLocation?.action === LocationChangeActionTypes.POP) {
        const { activeMainTabSegmentURL, activeSubTabSegmentURL } = params;
        const handlers = [
            {
                predicate: () => !!activeMainTabSegmentURL && !activeSubTabSegmentURL,
                handler: () => {
                    const segment = currentLocation?.pathname?.split('/')?.slice(-1)?.[0];
                    if (segment === activeMainTabSegmentURL && activeTab !== segment) {
                        dispatchSetActiveTab(segment);
                    }
                },
            },
            {
                predicate: () => !!activeMainTabSegmentURL && !!activeSubTabSegmentURL,
                handler: () => {
                    const segment = currentLocation?.pathname?.split('/')?.slice(-2)?.[0];
                    if (segment === activeMainTabSegmentURL && activeTab !== segment) {
                        dispatchSetActiveTab(segment);
                    }
                },
            },
            {
                predicate: () => activeMainTabSegmentURL && activeSubTabSegmentURL,
                handler: () => {
                    const segment = currentLocation?.pathname?.split('/')
                        ?.slice(-1)?.[0];
                    if (activeSubTabSegmentURL === segment && activeTab !== segment) {
                        dispatchSetActiveTab(segment);
                    }
                },
            },
            {
                predicate: () => true,
                handler: () => undefined,
            },
        ];

        return handlers.filter(({ predicate }) => predicate())[0].handler();
    }

    return undefined;
};

export default {
    handleURLSegmentsAndActiveTab,
    onBrowserPOPNavigationEvents,
};
