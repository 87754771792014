import TabSubHeading from 'components/common/atoms/TabSubHeading';
import CommonStyled from 'components/common/atoms/styled/UpdateStatus.styled';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { BusinessUserProps } from 'constants/BusinessUserModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import { commonValidation, handleWhitespaceOnBlur } from 'utils/validation-tools';

import { Button, Form, Input, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { getBusinessUserStatusOptions, getStatusHint, getHintAlertProps } from './UpdateBusinessUserStatusForm.helpers';


const { useWatch } = Form;

function UpdateBusinessUserStatusForm({
    t,
    enhancedCurrentLocation,
    accessControl,
    entityData,
    businessUserId,
    isLoadingStatusUpdate,
    shouldResetForm,
    dispatchUpdateBusinessUserStatus,
    dispatchClearResetForm,
}) {
    const [form] = Form.useForm();

    const newStatus = useWatch('businessUserStatus', form);

    const onFinishHandler = (payload) => {
        const baseURLSegmentsCount = 2;
        const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);
        dispatchUpdateBusinessUserStatus(businessUserId, payload, baseUrlWithoutTabsSlugs);
    };

    const statusOptions = useMemo(() => getBusinessUserStatusOptions(entityData?.business_user_status, accessControl),
        [entityData?.business_user_status, accessControl]);

    const statusOptionsHasItems = !!statusOptions.length;

    const alertObject = useMemo(() => getStatusHint(newStatus), [newStatus]);
    const getTranslation = (key) => t(`businessUserDetails.tabs.statusManagement.changeStatus.${key}`);

    useEffect(() => {
        if (shouldResetForm) {
            form.resetFields();
            dispatchClearResetForm();
        }
    }, [shouldResetForm]);

    return (
        <CommonStyled.FormCol>
            <TabSubHeading text={getTranslation('title')} />
            <div>
                <Spin spinning={isLoadingStatusUpdate}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="updateBusinessUserStatusForm"
                        initialValues={{}}
                        style={{ maxWidth: '500px' }}
                        onFinish={onFinishHandler}
                    >
                        {statusOptionsHasItems && (
                            <Form.Item
                                name="businessUserStatus"
                                label={t(getTranslation('fields.businessUserStatus.label'))}
                            >
                                <Select
                                    placeholder={t(getTranslation('fields.businessUserStatus.placeholder'))}
                                    options={statusOptions}
                                />
                            </Form.Item>
                        )}

                        <CommonStyled.HintAlert
                            {...getHintAlertProps({ alertObject, statusOptionsHasItems, getTranslation })}
                            showIcon
                        />

                        {statusOptionsHasItems && (
                            <>
                                <Form.Item
                                    name="businessUserStatusComment"
                                    label={t(getTranslation('fields.businessUserStatusComment.label'))}
                                    rules={[...commonValidation({ fieldName: 'businessUserStatusComment', t, isRequired: true })]}
                                >
                                    <Input
                                        placeholder={t(getTranslation('fields.businessUserStatusComment.placeholder'))}
                                        onBlur={(e) => handleWhitespaceOnBlur(e, 'businessUserStatusComment', form)}
                                    />
                                </Form.Item>

                                <CommonStyled.ButtonsWrapper>
                                    <Button type="primary" htmlType="submit">
                                        {t(getTranslation('submitButtonLabel'))}
                                    </Button>
                                </CommonStyled.ButtonsWrapper>
                            </>
                        )}
                    </Form>
                </Spin>
            </div>
        </CommonStyled.FormCol>
    );
}
UpdateBusinessUserStatusForm.propTypes = {
    t: PropTypes.func.isRequired,
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
    accessControl: AccessControlProps,
    entityData: BusinessUserProps,
    businessUserId: PropTypes.number.isRequired,
    isLoadingStatusUpdate: PropTypes.bool.isRequired,
    shouldResetForm: PropTypes.bool.isRequired,
    dispatchUpdateBusinessUserStatus: PropTypes.func.isRequired,
    dispatchClearResetForm: PropTypes.func.isRequired,
};

export default UpdateBusinessUserStatusForm;
