import styled from 'styled-components';


const SubStepsWrapper = styled.div`
  /* padding: 16px 16px 5px 16px; //progressDot version specific */
  padding: 8px 16px;
  background-color: #fff;
  user-select: none;
  
  .ant-steps-item-title {
    white-space: nowrap;
    text-transform: capitalize;
  }
  
  .ant-steps-item-active .ant-steps-item-title {
    color: var(--primary-color) !important;
  }
  
  .ant-steps-item-wait .ant-steps-item-title {
    color: #000 !important;
  }
`;

const SubStepLabel = styled.div`
  display: grid;
  padding: 0 5px;
  grid-template-columns: auto minmax(0, 1fr);
  grid-column-gap: 5px;
  justify-content: right;
  align-items: center;
  text-transform: capitalize;
  
  button {
    line-height: 32px;
    background: none;
    border: none;
    font-size: 16px;
    padding: 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
`;

const SubStepLabelButton = styled.button`
  margin: 0;
  border: 0;
  cursor: pointer;
  background: none;
  appearance: none !important;
  text-transform: capitalize;
  
  :hover {
    color: var(--btn-primary-bg);
  }
`;

export default { SubStepLabel, SubStepsWrapper, SubStepLabelButton };
