import { createAction } from '@reduxjs/toolkit';


export const FETCH_PERMISSIONS_FOR_ROLE = 'FETCH_PERMISSIONS_FOR_ROLE';
export const FETCH_PERMISSIONS_FOR_ROLE_SUCCESS = 'FETCH_PERMISSIONS_FOR_ROLE_SUCCESS';
export const FETCH_PERMISSIONS_FOR_ROLE_FAILURE = 'FETCH_PERMISSIONS_FOR_ROLE_FAILURE';

export const fetchPermissionsForRole = createAction(FETCH_PERMISSIONS_FOR_ROLE, (roleId) => ({ payload: roleId }));

export const fetchPermissionsForRoleSuccess = createAction(FETCH_PERMISSIONS_FOR_ROLE_SUCCESS, (roleId, responseData) => ({ payload: { roleId, responseData } }));
export const fetchPermissionsForRoleFailure = createAction(FETCH_PERMISSIONS_FOR_ROLE_FAILURE);

