import React from 'react';

import Styled from './NewStar.styled';


function NewStar() {
    return (
        <Styled.NewStarCssShape>
            <Styled.Copy>
        New!
            </Styled.Copy>
        </Styled.NewStarCssShape>
    );
}


export default NewStar;
