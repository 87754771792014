import { Col } from 'antd';
import styled from 'styled-components';


const AddressTabsWrapper = styled(Col)`
  padding-top: 16px;
  
  .ant-tabs-tab-btn {
    font-size: 12px;
  }
  
  .commonSection-heading {
    padding: 0;
    min-height: 0; /* XXX important */
  }
  .commonSection-heading-text {
    display: none;
  }
  
  .commonSection-heading-buttonsWrapper {
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .commonSection-contentWrapper {
    padding-right: 20px
  }
`;

export default { AddressTabsWrapper };
