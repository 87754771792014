import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import styled from 'styled-components';


const TogglerAndLabelWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-column-gap: 10px;
`;

const Label = styled.span`
  
  ${commonTextEllipsisStyles}
`;
export default {
    TogglerAndLabelWrapper,
    Label,
};
