import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import Attributions from './Attributions.layout';


const decorate = compose(
    withTranslation('common'),
);

export default decorate(Attributions);
