import { ChargeFeeModalType } from 'constants/FeeModel';
import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import {
    fetchFeesListSuccess,
    fetchFeesListFailure,
    chargeCustomFeeSuccess,
    chargeCustomFeeFailure,
} from 'redux/fees/actions';
import {
    CHARGE_CUSTOM_FEE,
    FETCH_FEES_LIST,
    OPEN_CHARGE_CUSTOM_FEE_MODAL,
} from 'redux/fees/actions.types';
import {
    hideModal,
    setModalProps,
    showModal,
} from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const onFetchFeesList = (action$, state$, { fees }) => action$.pipe(
    ofType(FETCH_FEES_LIST),
    switchMap(({ payload }) => from(fees.fetchFees(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchFeesListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchFeesListFailure(payload.locationPathname))),
    )),
);

export const onChargeCustomFeeModal = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_CHARGE_CUSTOM_FEE_MODAL),
    switchMap(() => of(
        showModal({
            modalType: ModalName.CHARGE_FEE_MODAL,
            modalProps: {
                title: i18n.t('fees:modals.chargeCustomFee.title'),
                okButtonProps: { disabled: false },
                feeType: ChargeFeeModalType.CUSTOM_FEE,
            },
        }),
    )),
);
export const onChargeCustomFee = (action$, state$, { fees, i18n }) => action$.pipe(
    ofType(CHARGE_CUSTOM_FEE),
    switchMap(({ payload }) => from(fees.createCustomFee(payload?.queryParams?.requestPayload)).pipe(
        switchMap(() => of(
            chargeCustomFeeSuccess(),
            showSuccessToast(i18n.t('fees:actionMessages.chargeCustomFeeSuccessFull')),
            hideModal(),
        )),
        catchError(() => of(
            chargeCustomFeeFailure(),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);

export default [
    onFetchFeesList,
    onChargeCustomFeeModal,
    onChargeCustomFee,
];
