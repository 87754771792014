import withAccessControl from 'hocs/withAccessControl';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';


import AddCompanyPage from './AddCompanyPage.layout';


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
);
export default decorate(AddCompanyPage);
