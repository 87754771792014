import PropTypes from 'prop-types';


export const AddressTypes = Object.freeze({
    CLIENT_ADDRESS: 'client_address',
    SHIPPING_ADDRESS: 'shipping_address',
    BILLING_ADDRESS: 'billing_address',
});

export const AddressCommonProps = PropTypes.shape({
    id: PropTypes.number,
    address_line_1: PropTypes.string.isRequired,
    address_line_2: PropTypes.string,
    city: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    state: PropTypes.string,
    premise: PropTypes.string,
    additionals: PropTypes.string,
});


export default { AddressCommonProps, AddressTypes };
