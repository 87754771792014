import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';

import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function EditPhoneNumberModal({
    t,
    phoneNumber,
    cardId,
    dispatchSetModalProps,
    dispatchSubmitCardSecure3dPhoneNumberModal,
}) {
    const [form] = useForm();

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);

    const onFinish = (values) => {
        dispatchSubmitCardSecure3dPhoneNumberModal(cardId, values?.newPhoneNumber);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ newPhoneNumber: phoneNumber }}
            onFinish={onFinish}
        >
            <PhoneInput
                t={t}
                placeholder={t('modals.editSecure3DPhoneNumber.fields.newPhoneNumber.placeholder')}
                name="newPhoneNumber"
                label={t('modals.editSecure3DPhoneNumber.fields.newPhoneNumber.label')}
                isRequiredMessage={t('modals.editSecure3DPhoneNumber.fields.newPhoneNumber.validationRules.required')}
                additionalValidationRules={[{
                    validator: async (_, value) => {
                        if (value === phoneNumber) {
                            throw new Error(t('modals.editSecure3DPhoneNumber.fields.newPhoneNumber.validationRules.noChange'));
                        }
                    },
                }]}
            />

        </Form>
    );
}


EditPhoneNumberModal.propTypes = {
    cardId: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    dispatchSubmitCardSecure3dPhoneNumberModal: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string,
};


export default EditPhoneNumberModal;

