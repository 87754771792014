import styled from 'styled-components';

// XXX - \00a0 - non braking space, must be as unicode
const IndustriesWrapper = styled.div`
  .multiple-inline-values > div:not(:last-child) > span:after {
    content: ",\\00a0"; 
  }
`;

export default { IndustriesWrapper };
