import { requestSetMultiStepsFormStep } from 'redux/multi-steps-form/actions';
import { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';
import { fetchOperationsList } from 'redux/operations/actions';
import { operationsListsPerLocationStoreKeyName, operationsReducerName } from 'redux/operations/reducer';
import { fetchRoleDetails, updateRole } from 'redux/role/actions';
import { roleReducerName } from 'redux/role/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import EditRoleForm from './EditRoleForm.layout';


const mapStateToProps = (state, ownProps) => {
    const { locationPathname } = ownProps;
    const currentMultiStepsFormStep = state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormStep;
    const roleDetails = state[roleReducerName]?.clientDetails;
    const operationsLists = state[operationsReducerName]?.[operationsListsPerLocationStoreKeyName]?.[locationPathname];

    return {
        operationsLists,
        roleDetails,
        isLoadingRoleDetails: state[roleReducerName]?.isLoadingClientDetails,
        isLoadingUpdateRole: state[roleReducerName]?.isLoadingClientConfigUpdate,

        // standard multi step form related
        availableSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSteps,
        currentMultiStepsFormStep,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    // edit client settings form specific
    dispatchFetchRoleDetails: (roleId) => dispatch(fetchRoleDetails(roleId)),
    dispatchUpdateRole: (requestData) => dispatch(updateRole(requestData)),
    dispatchFetchOperationsList: (requestData, locationPathname) => dispatch(fetchOperationsList(requestData, locationPathname)),

    // standard multi step form related
    dispatchRequestSetMultiStepsFormStep: (currentStep, direction) => dispatch(requestSetMultiStepsFormStep(
        currentStep,
        direction,
        ownProps?.locationPathname,
    )),
});

const decorate = compose(
    withTranslation('roles'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(EditRoleForm);
