import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    fetchGroupDetails,
    fetchGroupDetailsFailure,
    fetchGroupDetailsSuccess,
    addMemberToGroup,
    addMemberToGroupSuccess,
    addMemberToGroupFailure,
    clearGroupDetails, cleanupAddMemberToGroupMetadata,
} from 'redux/group/actions';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const groupReducerName = 'group';
export const groupDetailsPerLocationStoreKeyName = 'groupDetailsPerLocation';


const initialState = {
    [groupDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }

    idsOfMembersCurrentlyBeingAddedToGroup: [],
    idsOfMembersSuccessfullyAddedToTheGroup: [],
    idOfGroupToWhichMembersAreBeingAdded: undefined,
    idOfClientWhoOwnsGroupToWhichMembersAreBeingAdded: undefined,
};


const handlers = {
    [fetchGroupDetails]: (state, action) => {
        state[groupDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[groupDetailsPerLocationStoreKeyName], action);
    },
    [fetchGroupDetailsSuccess]: (state, action) => {
        state[groupDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[groupDetailsPerLocationStoreKeyName], action);
    },
    [fetchGroupDetailsFailure]: (state, action) => {
        state[groupDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[groupDetailsPerLocationStoreKeyName], action);
    },
    [clearGroupDetails]: (state, action) => {
        state[groupDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[groupDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === groupReducerName
      && action.payload?.fieldName === groupDetailsPerLocationStoreKeyName
        ) {
            state[groupDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[groupDetailsPerLocationStoreKeyName] }, action);
        }
    },


    [addMemberToGroup]: (state, action) => {
        const idsSet = new Set([...state.idsOfMembersCurrentlyBeingAddedToGroup, action.payload.memberId]);
        state.idsOfMembersCurrentlyBeingAddedToGroup = Array.from(idsSet);
    },

    [addMemberToGroupSuccess]: (state, action) => {
        const idsSet = new Set([...state.idsOfMembersCurrentlyBeingAddedToGroup]);
        idsSet.delete(action.payload.memberId);
        state.idsOfMembersCurrentlyBeingAddedToGroup = Array.from(idsSet);
        state.idsOfMembersSuccessfullyAddedToTheGroup = Array.from(new Set([...state.idsOfMembersSuccessfullyAddedToTheGroup, action.payload.memberId]));
        state.idOfGroupToWhichMembersAreBeingAdded = action.payload?.groupId;
        state.idOfClientWhoOwnsGroupToWhichMembersAreBeingAdded = action.payload?.clientId;
    },

    [addMemberToGroupFailure]: (state, action) => {
        const idsSet = new Set([...state.idsOfMembersCurrentlyBeingAddedToGroup]);
        idsSet.delete(action.payload.memberId);
        state.idsOfMembersCurrentlyBeingAddedToGroup = Array.from(idsSet);
    },

    [cleanupAddMemberToGroupMetadata]: (state) => {
        state.idsOfMembersCurrentlyBeingAddedToGroup = [];
        state.idsOfMembersSuccessfullyAddedToTheGroup = [];
        state.idOfGroupToWhichMembersAreBeingAdded = undefined;
        state.idOfClientWhoOwnsGroupToWhichMembersAreBeingAdded = undefined;
    },

    [clearCurrentUser]: () => initialState,


};

export default createReducer(initialState, handlers);
