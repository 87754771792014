import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';


import { createFailedWebhookDetailsTabsConfig } from './FailedWebhookDetailsPage.helpers';
import { FailedWebhookDetailsPageProps } from './FailedWebhookDetailsPage.types';
import FailedWebhookInfo from './FailedWebhookInfo';


function FailedWebhookDetailsPage({
    accessControl,
    enhancedCurrentLocation,
    t,
    entityDetailsData,
    dispatchSetQueryParams,
    dispatchFetchFailedWebhookDetails,
    dispatchClearFailedWebhookDetails,

}: FailedWebhookDetailsPageProps) {
    const { failedWebhookUuid } = useParams();

    const contextEnforcedQueryParams = { failedWebhookUuid };
    const baseURLSegmentsCount = 2; // e.g. /(1) fee /(2) FEE_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);


    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchFailedWebhookDetails,
        dispatchClearEntityDetails: dispatchClearFailedWebhookDetails,
        dispatchSetQueryParams,
    });

    const tabsConfig = useMemo(
        () => (
            entityDetailsData?.entityDetails?.uuid
                ? createFailedWebhookDetailsTabsConfig({ t, accessControl, failedWebhook: entityDetailsData?.entityDetails })
                : undefined),
        [entityDetailsData],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('webhooks:failedWebhookDetails.screenTitle')}
            tabsConfig={tabsConfig}
            baseURLSegmentsCount={baseURLSegmentsCount}
        >
            <FailedWebhookInfo
                entityDetailsData={entityDetailsData}
                locationPathname={baseUrlWithoutTabsSlugs}
                queryParams={contextEnforcedQueryParams}
                areDetailsReady={isDetailsPageInitialStateDefined}
                accessControl={accessControl}
                onRefresh={() => dispatchFetchFailedWebhookDetails(contextEnforcedQueryParams, baseUrlWithoutTabsSlugs)}
            />

        </StandardEntityDetailsWithTabs>
    );
}

export default FailedWebhookDetailsPage;
