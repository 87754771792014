import TabSubHeading from 'components/common/atoms/TabSubHeading';
import CommonStyled from 'components/common/atoms/styled/UpdateStatus.styled';
import OffboardingButtons from 'components/common/molecules/OffboardingButtons';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { PN } from 'constants/PermissionsModel';
import useMemberActionChecks from 'hooks/useMemberActionChecks';
import { UpdateExternalKycKybMemberStatusPayload } from 'redux/member-status/actions.types';
import { commonValidation, handleWhitespaceOnBlur } from 'utils/validation-tools';

import { MemberStatuses } from '@manigo/manigo-domain-typings';
import {
    Button, Form, Input, Select, Spin, Row,
} from 'antd';
import React, { useEffect, useMemo } from 'react';

import { createButtonConfigs, getExternalKycKybCompanyStatusOptions } from './UpdateMemberStatus.helpers';
import Styled from './UpdateMemberStatus.styled';
import { UpdateMemberStatusProps } from './UpdateMemberStatus.types';


function UpdateMemberStatusForm({
    isLoadingDetails,
    isLoadingUpdateStatus,
    memberDetails,
    accessControl,
    isInReadOnlyMode,
    enhancedCurrentLocation,
    shouldResetForm,

    dispatchSuspendMember,
    dispatchActivateMember,
    dispatchBanMember,
    dispatchUnbanMember,
    dispatchUnsuspendMember,
    dispatchExternalKycKybUpdateMemberStatus,
    dispatchShowModal,
    t,
}: UpdateMemberStatusProps) {
    const [form] = Form.useForm();

    useEffect(() => {
        if (shouldResetForm) {
            form?.resetFields();
        }
    }, [shouldResetForm]);

    const getComponentTranslation = (key) => t(`member:memberDetails.tabs.statusManagement.tabs.changeStatus.${key}`);

    const { canSuspend, canUnSuspend, canBan, canUnBan, canActivate } = useMemberActionChecks({ memberDetails, accessControl });

    const buttonConfigs = useMemo(() => createButtonConfigs({
        t,
        canActivate,
        canBan,
        canSuspend,
        canUnBan,
        canUnSuspend,
        dispatchActivateMember,
        dispatchBanMember,
        dispatchSuspendMember,
        dispatchUnbanMember,
        dispatchUnsuspendMember,
        memberId: memberDetails.memberId,
    }), [
        canActivate, canBan, canSuspend, canUnBan, canUnSuspend,
        dispatchActivateMember, dispatchBanMember, dispatchSuspendMember, dispatchUnbanMember, dispatchUnsuspendMember,
        memberDetails.memberId,
    ]);

    const showExternalKycKybExtraStatusOptions = useMemo(() =>
        !accessControl.isKycKybApprovalEnabled()
        && accessControl.hasPermission(PN.MEMBERS.UPDATE_STATUS)
            && memberDetails?.memberStatus
            && [MemberStatuses.KYC_PENDING,
                MemberStatuses.KYC_UPLOADED,
                MemberStatuses.KYC_MANUAL_REVIEW,
                MemberStatuses.KYC_REJECTED,
                MemberStatuses.ACTIVE,
                MemberStatuses.UNVERIFIED,
            ].includes(memberDetails?.memberStatus),
    [memberDetails?.memberStatus, accessControl]);

    const externalKycKybStatusOptions = useMemo(() =>
        showExternalKycKybExtraStatusOptions ? getExternalKycKybCompanyStatusOptions(memberDetails?.memberStatus) : [],
    [memberDetails?.memberStatus, showExternalKycKybExtraStatusOptions]);

    const entityName = useMemo(() => `${memberDetails.memberFirstName} ${memberDetails.memberLastName}`, [memberDetails?.memberFirstName, memberDetails?.memberLastName]);

    const showAdditionalOptions = useMemo(() => showExternalKycKybExtraStatusOptions && externalKycKybStatusOptions?.length && externalKycKybStatusOptions?.length > 0,
        [showExternalKycKybExtraStatusOptions, externalKycKybStatusOptions]);


    const onFinishHandler = ({ memberStatusComment, memberStatus }) => {
        const baseURLSegmentsCount = 2;
        const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(
            enhancedCurrentLocation?.pathname,
            baseURLSegmentsCount,
        );

        dispatchExternalKycKybUpdateMemberStatus({
            queryParams: { memberId: memberDetails.memberId },
            requestPayload: { memberStatus, memberStatusComment },
            locationPathname: baseUrlWithoutTabsSlugs,
        } as UpdateExternalKycKybMemberStatusPayload);
    };

    return (
        <Row>
            <CommonStyled.FormCol style={{ width: showAdditionalOptions ? '50%' : '100%' }}>
                <TabSubHeading text={getComponentTranslation('title')} />
                <Styled.UpdateStatusButtonsWrapper>
                    <div>
                        {buttonConfigs.map((config) => config.predicate() && (
                            <Button
                                key={config.text}
                                type={config.type}
                                onClick={() => dispatchShowModal(config.action)}
                                icon={config.icon}
                                disabled={isLoadingDetails}
                            >
                                {config.text}
                            </Button>
                        ))}
                    </div>

                    {(!isInReadOnlyMode || ![MemberStatuses.SUSPENDED, MemberStatuses.BANNED].includes(memberDetails.memberStatus)) && (
                        <div>
                            <OffboardingButtons
                                memberId={memberDetails.memberId}
                                entityName={entityName}
                                entityStatus={memberDetails.memberStatus}
                                isEntityLoading={isLoadingDetails}
                            />
                        </div>
                    )}
                </Styled.UpdateStatusButtonsWrapper>
            </CommonStyled.FormCol>

            {showAdditionalOptions
                ? (
                    <CommonStyled.FormCol style={{ width: '40%' }}>
                        <TabSubHeading text={getComponentTranslation('optionalExtraOptionsTitle')} />
                        <Spin spinning={isLoadingUpdateStatus}>
                            <Form
                                form={form}
                                layout="vertical"
                                name="updateMemberStatusForm"
                                initialValues={{ memberStatusComment: '' }}
                                style={{ maxWidth: '500px' }}
                                onFinish={onFinishHandler}
                            >
                                <Form.Item
                                    name="memberStatus"
                                    rules={[...commonValidation({ t, isRequired: true })]}
                                    label={t(getComponentTranslation('fields.memberStatus.label'))}
                                >
                                    <Select
                                        placeholder={t(getComponentTranslation('fields.memberStatus.placeholder'))}
                                        options={externalKycKybStatusOptions}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="memberStatusComment"
                                    label={t(getComponentTranslation('fields.memberStatusComment.label'))}
                                    rules={[...commonValidation({ t, isRequired: true })]}
                                >
                                    <Input
                                        placeholder={t(getComponentTranslation('fields.memberStatusComment.placeholder'))}
                                        onBlur={(e) => handleWhitespaceOnBlur(e, 'memberStatusComment', form)}
                                    />
                                </Form.Item>
                                <CommonStyled.ButtonsWrapper>
                                    <Button type="primary" htmlType="submit" disabled={isLoadingUpdateStatus}>
                                        {t(getComponentTranslation('submitButtonLabel'))}
                                    </Button>
                                </CommonStyled.ButtonsWrapper>
                            </Form>
                        </Spin>
                    </CommonStyled.FormCol>
                )
                : <></>}
        </Row>
    );
}

export default UpdateMemberStatusForm;
