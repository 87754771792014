import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { sortingOptions, sortStringDates } from 'utils/table-columns-sorters';

import React from 'react';


export default ({ t, queryParams, dispatchShowViewJsonModal }) => [
    {
        title: t('webhooksFields.uuid.label'),
        dataIndex: 'id',
        render: (text) => <StandardValue value={text} disableTextEllipsis />,
    },
    {
        title: t('failedWebhooksFields.errorDetails.label'),
        dataIndex: 'error',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('failedWebhooksFields.data.label'),
        dataIndex: 'payload',
        align: 'center',
        render: (text, record) => (record?.payload
            ? viewButtonRenderer({
                record,
                onClick: (event, record) => dispatchShowViewJsonModal(JSON.stringify(record?.payload, null, 4), t('Webhook payload')),
                tooltipContent: t('buttons.viewData.tooltip'),
                dataTestId: 'view-payload-data',
            })
            : '-'),
    },
    {
        title: t('webhooksFields.createdAt.label'),
        dataIndex: 'createdAt',
        align: 'center',
        render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime disableTextEllipsis />,
        ...sortingOptions({
            queryParams,
            fieldName: 'createdAt',
            sorter: (a, b) => sortStringDates(a, b, 'createdAt'),
        }),
    },

];
