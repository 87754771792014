import {
    CARD_PIN_UNBLOCK, CARD_PIN_UNBLOCK_FAILURE, CARD_PIN_UNBLOCK_SUCCESS,

    UNLINK_CARD, UNLINK_CARD_FAILURE, UNLINK_CARD_SUCCESS,

    FETCH_CARD_DETAILS, FETCH_CARD_DETAILS_FAILURE, FETCH_CARD_DETAILS_SUCCESS,
    CLEAR_CARD_DETAILS,

    UPDATE_CARD, UPDATE_CARD_FAILURE, UPDATE_CARD_SUCCESS,

    UPDATE_CARD_LIMITS, UPDATE_CARD_LIMITS_FAILURE, UPDATE_CARD_LIMITS_SUCCESS,

    TOGGLE_CHANGE_CARD_LIMIT_FORM_VISIBILITY,

    SET_CHANGE_CARD_LIMIT_FORM_HAS_LIMIT_VALUE,

    SHOW_UNLINK_CARD_MODAL,

    CONFIRM_UNLINK_CARD,

    DELETE_CARD, DELETE_CARD_FAILURE, DELETE_CARD_SUCCESS,
} from 'redux/card/action.types';

import { createAction } from '@reduxjs/toolkit';


export const cardPinUnblock = createAction(CARD_PIN_UNBLOCK, (cardToken, locationPathname) => ({ payload: { cardToken, locationPathname } }));
export const cardPinUnblockSuccess = createAction(CARD_PIN_UNBLOCK_SUCCESS, (responseData) => ({ payload: responseData }));
export const cardPinUnblockFailure = createAction(CARD_PIN_UNBLOCK_FAILURE);


export const unlinkCard = createAction(UNLINK_CARD, (cardId, meta) => ({ payload: cardId, meta }));
export const unlinkCardSuccess = createAction(UNLINK_CARD_SUCCESS, (response, meta) => ({ payload: response, meta }));
export const unlinkCardFailure = createAction(UNLINK_CARD_FAILURE);


export const fetchCardDetails = createAction(FETCH_CARD_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchCardDetailsSuccess = createAction(FETCH_CARD_DETAILS_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchCardDetailsFailure = createAction(FETCH_CARD_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearCardDetails = createAction(CLEAR_CARD_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));

export const updateCard = createAction(UPDATE_CARD, (cardId, requestPayload) => ({ payload: { cardId, requestPayload } }));
export const updateCardSuccess = createAction(UPDATE_CARD_SUCCESS, ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const updateCardFailure = createAction(UPDATE_CARD_FAILURE);


export const updateCardLimits = createAction(UPDATE_CARD_LIMITS, (cardId, requestPayload) => ({ payload: { cardId, requestPayload } }));
export const updateCardLimitsSuccess = createAction(UPDATE_CARD_LIMITS_SUCCESS, ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const updateCardLimitsFailure = createAction(UPDATE_CARD_LIMITS_FAILURE);

export const toggleChangeCardLimitFormVisibility = createAction(TOGGLE_CHANGE_CARD_LIMIT_FORM_VISIBILITY, (value) => ({ payload: value }));

export const setChangeCardLimitFormHasLimitValue = createAction(SET_CHANGE_CARD_LIMIT_FORM_HAS_LIMIT_VALUE, (value) => ({ payload: value }));


export const showUnlinkCardModal = createAction(SHOW_UNLINK_CARD_MODAL, (cardRecord, meta) => ({ payload: cardRecord, meta }));
export const confirmUnlinkCard = createAction(CONFIRM_UNLINK_CARD, (cardId, meta) => ({ payload: cardId, meta }));

export const deleteCard = createAction(DELETE_CARD, (cardId) => ({ payload: cardId }));
export const deleteCardSuccess = createAction(DELETE_CARD_SUCCESS, (response) => ({ payload: response }));
export const deleteCardFailure = createAction(DELETE_CARD_FAILURE);
