/* eslint-disable import/no-named-as-default-member */
import withAccessControl from 'hocs/withAccessControl';
import { clearResetForm, updateBusinessUserStatus } from 'redux/business-user-status/actions';
import { businessUserStatusReducerName } from 'redux/business-user-status/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UpdateBusinessUserStatusForm from './UpdateBusinessUserStatusForm.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state[navigationReducerName]?.enhancedCurrentLocation,
    isLoadingStatusUpdate: state[businessUserStatusReducerName].isLoadingStatusUpdate,
    shouldResetForm: state[businessUserStatusReducerName].shouldResetForm,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateBusinessUserStatus: (businessUserId, requestPayload, locationPathname) => dispatch(updateBusinessUserStatus(businessUserId, requestPayload, locationPathname)),
    dispatchClearResetForm: () => dispatch(clearResetForm()),
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('businessUsers'),
    withAccessControl,
);
export default decorate(UpdateBusinessUserStatusForm);
