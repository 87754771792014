import withAccessControl from 'hocs/withAccessControl';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';


import MemberRiskDetails from './MemberRiskDetails.layout';
import { MemberRiskDetailsOwnProps } from './MemberRiskDetails.types';


const decorate = compose<ComponentType<MemberRiskDetailsOwnProps>>(
    withTranslation(['common', 'member']),
    withAccessControl,
);

export default decorate(MemberRiskDetails);
