import { Space } from 'antd';
import styled from 'styled-components';


export const StatusWrapper = styled.div`
    display: grid;
    padding: 3rem 1rem;
    text-align: center;
    position: relative ;
    
    .ant-tag {
        font-size: large;
        padding: 0.25rem 1rem;
    }
`;


export const FinalBalanceWrapper = styled(Space)`
    position: absolute;
    top: 1rem;
    white-space: nowrap;
`;

export default { StatusWrapper, FinalBalanceWrapper };
