/* eslint-disable class-methods-use-this */
import { normaliseSortOrderValue } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';

import {
    fetchApiKeysAPI,
    createApiKeyAPI,
    deleteApiKeyUuid,
    fetchApiKeyKeValueAPI,
} from './index';


class ApiKeysService {
    public fetchApiKeys(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, sortOrder, ...rest } = rawQueryParams;

        const query = {
            params: {
                ...(clientId && clientId > 0 ? { clientId } : {}),
                ...({ sortOrder: normaliseSortOrderValue(sortOrder) }),
                ...rest,
            },
        };
        return fetchApiKeysAPI(query);
    }

    public createApiKey(requestPayload): Promise<ApiResponse | ApiError> {
        return createApiKeyAPI(requestPayload);
    }

    public deleteApiKey(apiKeyId): Promise<ApiResponse | ApiError> {
        return deleteApiKeyUuid(apiKeyId);
    }

    public fetchApiKeyKeValue(apiKeyId): Promise<ApiResponse | ApiError> {
        return fetchApiKeyKeValueAPI(apiKeyId);
    }
}

export default ApiKeysService;

