import { standardPageSize } from 'config/config';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchBeneficiaryDetails,
    fetchBeneficiaryDetailsSuccess,
    fetchBeneficiaryDetailsFailure,
    clearBeneficiaryDetails,
    updateBeneficiary,
    updateBeneficiarySuccess,
    updateBeneficiaryFailure,
    deleteBeneficiary,
    deleteBeneficiarySuccess,
    deleteBeneficiaryFailure,
} from './actions';


export const beneficiaryReducerName = 'beneficiary';
export const beneficiaryDetailsPerLocationStoreKeyName = 'beneficiaryDetailsPerLocation';

export const beneficiaryListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    clientId: null,
    memberId: null,
    companyId: null,
};

const initialBeneficiaryDetailsState = {
    [beneficiaryDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    //
};


const initialState = {
    // details
    ...initialBeneficiaryDetailsState,

    isLoadingDeleteBeneficiary: false,
    isLoadingUpdateBeneficiary: false,
};


const handlers = {
    [fetchBeneficiaryDetails]: (state, action) => {
        state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[beneficiaryDetailsPerLocationStoreKeyName], action);
    },
    [fetchBeneficiaryDetailsSuccess]: (state, action) => {
        state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[beneficiaryDetailsPerLocationStoreKeyName], action);
    },
    [fetchBeneficiaryDetailsFailure]: (state, action) => {
        state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[beneficiaryDetailsPerLocationStoreKeyName], action);
    },
    [clearBeneficiaryDetails]: (state, action) => {
        state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[beneficiaryDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === beneficiaryReducerName
      && action.payload?.fieldName === beneficiaryDetailsPerLocationStoreKeyName
        ) {
            state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[beneficiaryDetailsPerLocationStoreKeyName] }, action);
        }
    },

    [updateBeneficiary]: (state, action) => {
        state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[beneficiaryDetailsPerLocationStoreKeyName], action);
        state.isLoadingUpdateBeneficiary = true;
    },
    [updateBeneficiarySuccess]: (state, action) => {
        state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[beneficiaryDetailsPerLocationStoreKeyName], action);
        state.isLoadingUpdateBeneficiary = false;
    },
    [updateBeneficiaryFailure]: (state, action) => {
        state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[beneficiaryDetailsPerLocationStoreKeyName], action);
        state.isLoadingUpdateBeneficiary = false;
    },

    [deleteBeneficiary]: (state) => {
        state.isLoadingDeleteBeneficiary = true;
    },
    [deleteBeneficiarySuccess]: (state) => {
        state.isLoadingDeleteBeneficiary = false;
    },
    [deleteBeneficiaryFailure]: (state) => {
        state.isLoadingDeleteBeneficiary = false;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
