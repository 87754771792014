import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_ROLE_DETAILS,
    FETCH_ROLE_DETAILS_SUCCESS,
    FETCH_ROLE_DETAILS_FAILURE,
    CLEAR_ROLE_DETAILS,
    CREATE_ROLE,
    CREATE_ROLE_SUCCESS,
    CREATE_ROLE_FAILURE,
    UPDATE_ROLE,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAILURE,
    DELETE_ROLE,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE,
    SELECT_ROLE_TEMPLATE,
    CLEAR_ROLE_TEMPLATE,
    ADD_PERMISSIONS_TO_ROLE,
    REMOVE_PERMISSIONS_FROM_ROLE,
    TOGGLE_SAVE_ROLE_AS_ROLE_TEMPLATE, CLEAR_ADD_EDIT_ROLE_FORM_STATE, REMOVE_ALL_PERMISSIONS_FROM_ROLE,
} from './actions.types';


export const fetchRoleDetails = createAction(FETCH_ROLE_DETAILS,
    (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchRoleDetailsSuccess = createAction(FETCH_ROLE_DETAILS_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchRoleDetailsFailure = createAction(FETCH_ROLE_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearRoleDetails = createAction(CLEAR_ROLE_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));


export const selectRoleTemplate = createAction(SELECT_ROLE_TEMPLATE, (selectedRole) => ({ payload: selectedRole }));
export const clearRoleTemplate = createAction(CLEAR_ROLE_TEMPLATE);
export const clearAddEditRoleFormState = createAction(CLEAR_ADD_EDIT_ROLE_FORM_STATE);

export const addPermissionsToRole = createAction(ADD_PERMISSIONS_TO_ROLE, (selectedPermissions = []) => ({ payload: selectedPermissions }));
export const removePermissionsFromRole = createAction(REMOVE_PERMISSIONS_FROM_ROLE, (selectedPermissions = []) => ({ payload: selectedPermissions }));
export const removeAllPermissionsFromRole = createAction(REMOVE_ALL_PERMISSIONS_FROM_ROLE);
export const toggleSaveRoleAsRoleTemplate = createAction(TOGGLE_SAVE_ROLE_AS_ROLE_TEMPLATE);

export const createRole = createAction(CREATE_ROLE, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const createRoleSuccess = createAction(CREATE_ROLE_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const createRoleFailure = createAction(CREATE_ROLE_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const updateRole = createAction(UPDATE_ROLE, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const updateRoleSuccess = createAction(UPDATE_ROLE_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const updateRoleFailure = createAction(UPDATE_ROLE_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const deleteRole = createAction(DELETE_ROLE, (roleId, ownerContext) => ({ payload: { roleId, ownerContext } }));
export const deleteRoleSuccess = createAction(DELETE_ROLE_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const deleteRoleFailure = createAction(DELETE_ROLE_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

