import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { fetchMarketingPreferencesFailure, fetchMarketingPreferencesSuccess, requestMarketingPreferencesFailure, requestMarketingPreferencesSuccess } from './actions';
import { REQUEST_MARKETING_PREFERENCES, FETCH_MARKETING_PREFERENCES, SHOW_REQUEST_MARKETING_PREFERENCES_MODAL } from './actions.types';


export const onFetchMembersMarketPreferences = (action$, _, { config }) => action$.pipe(
    ofType(FETCH_MARKETING_PREFERENCES),
    switchMap(({ payload }) => from(config.fetchMembersMarketPreferences(payload.queryParams)).pipe(
        switchMap((response) => of(fetchMarketingPreferencesSuccess(
            response.data.rows,
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchMarketingPreferencesFailure(payload.locationPathname))),
    )),
);

export const onRequestMarketingPreferences = (action$, _, { i18n, config }) => action$.pipe(
    ofType(REQUEST_MARKETING_PREFERENCES),
    switchMap(({ payload }) => from(config.exportMembersMarketingPreferences(payload.queryParams))
        .pipe(
            switchMap(() => of(
                requestMarketingPreferencesSuccess(),
                showSuccessToast(i18n.t('marketingPreferences:actionMessages.requestMarketingPreferencesSuccess')),
                hideModal(),
            )),
            catchError(() => of(
                requestMarketingPreferencesFailure(),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);

export const onShowRequestMarketingPreferencesModal = (action$, _, { i18n }) => action$.pipe(
    ofType(SHOW_REQUEST_MARKETING_PREFERENCES_MODAL),
    switchMap(({ payload: { modalProps } }) => of(showModal({
        modalType: ModalName.REQUEST_MARKETING_PREFERENCES_MODAL,
        modalProps: {
            title: i18n.t('marketingPreferences:modals.requestMarketingPreferences.title'),
            okButtonProps: { disabled: false },
            ...(modalProps || {}),
        },
    }))),
);

export default [
    onFetchMembersMarketPreferences,
    onRequestMarketingPreferences,
    onShowRequestMarketingPreferencesModal,
];
