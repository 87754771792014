import ClientSelect from 'components/common/molecules/form-controls/ClientSelect';
import { addressFields, cardDetailsFields, createTargetChangeFormActions } from 'components/pages/authorised/CardsOrderForm/steps/SelectTargetStep/SelectTargetStep.helpers';
import { CardOrderTarget } from 'constants/CardModel';
import { filterOptions } from 'utils/filterOptions';
import noop from 'utils/function-tools';
import { createCompaniesOptions, createMembersOptions } from 'utils/options-creators';
import { requiredRule } from 'utils/validation-tools';

import {
    Button, Form, Radio, Row, Select, Col, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function SelectTargetStep({
    form,
    data,
    dispatchGetTargetList,
    t,
    dispatchRequestNavigationBack,
    dispatchClearMembers,
    dispatchClearCompanies,
    dispatchSetMemberEmbossName,
    dispatchClearCardsDetails,
    dispatchClearAddress,

    members,
    companies,
    isLoadingMembers,
    isLoadingCompanies,

}) {
    const membersOptions = useMemo(
        () => createMembersOptions(members),
        [members],
    );

    const companiesOptions = useMemo(
        () => createCompaniesOptions(companies),
        [companies],
    );

    const searchHandler = (query) => (query && query.length > 0 ? dispatchGetTargetList(data.target, data.client_id, query) : noop);

    const isClientSelected = data.client_id && data.client_id > 0;

    const handleTargetChange = (event) => {
        const value = event?.target?.value;
        dispatchClearMembers();
        dispatchClearCompanies();
        dispatchClearCardsDetails();
        dispatchClearAddress();

        if ([CardOrderTarget.COMPANIES, CardOrderTarget.MEMBERS].includes(value)) {
            dispatchGetTargetList(value, data.client_id);
        }
        return createTargetChangeFormActions(value, form);
    };

    const handlePartnerChange = (value) => {
        form.resetFields(['member_id', 'company_id', ...cardDetailsFields]);
        dispatchClearMembers();
        dispatchClearCompanies();
        dispatchClearAddress();
        dispatchClearCardsDetails();

        if ([CardOrderTarget.COMPANIES, CardOrderTarget.MEMBERS].includes(data.target)) {
            dispatchGetTargetList(data.target, value);
        }
    };

    const handleMemberSelection = (event, data) => {
        const parts = data.label.split(' - ');
        dispatchSetMemberEmbossName(parts[1]);
        dispatchClearCardsDetails();
        dispatchClearAddress();
        form.resetFields([...cardDetailsFields, ...addressFields]);
        form.setFieldsValue({ embossNames: [parts[1]] });
    };

    const handleCompanySelection = () => {
        dispatchClearCardsDetails();
        dispatchClearAddress();
        form.resetFields([...cardDetailsFields, ...addressFields]);
    };

    const getSelectLabel = (isClientSelected, isLoading) => {
        if (isLoading) return t('Loading data...');
        if (isClientSelected) {
            return 'Select partner first';
        }
        return 'Select partner first';
    };

    return (
        <Col style={{ maxWidth: '400px' }}>
            <Form.Item
                key="target-form-item"
                label="Select Target"
                name="target"
            >
                <Radio.Group onChange={handleTargetChange}>
                    <Radio.Button value={CardOrderTarget.CLIENTS}>Partner</Radio.Button>
                    <Radio.Button value={CardOrderTarget.MEMBERS}>Member</Radio.Button>
                    <Radio.Button value={CardOrderTarget.COMPANIES}>Company</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <ClientSelect onChange={handlePartnerChange} />


            {data.target === CardOrderTarget.MEMBERS && (
                <Form.Item
                    key="member_id-form-item"
                    label="Select Member"
                    name="member_id"
                    rules={requiredRule(t)}
                >
                    <Select
                        onChange={handleMemberSelection}
                        showSearch
                        placeholder={getSelectLabel(isClientSelected, isLoadingMembers)}
                        onSearch={searchHandler}
                        disabled={!isClientSelected}
                        filterOption={filterOptions}
                        loading={isLoadingMembers}
                        notFoundContent={isLoadingMembers ? <Spin size="small" /> : 'No Results found'}
                    >
                        {membersOptions}
                    </Select>
                </Form.Item>
            )}

            {data.target === CardOrderTarget.COMPANIES && (
                <Form.Item
                    key="company_id-form-item"
                    label="Select Company"
                    name="company_id"
                    rules={requiredRule(t)}
                >
                    <Select
                        showSearch
                        onChange={handleCompanySelection}
                        placeholder={getSelectLabel(isClientSelected, isLoadingCompanies)}
                        onSearch={searchHandler}
                        disabled={!isClientSelected}
                        filterOption={filterOptions}
                        loading={isLoadingCompanies}
                        notFoundContent={isLoadingCompanies ? <Spin size="small" /> : 'No Results found'}
                    >
                        {companiesOptions}
                    </Select>
                </Form.Item>
            )}


            <Row key="form buttons" style={{ marginTop: 20 }}>
                <Form.Item>
                    <Button onClick={() => dispatchRequestNavigationBack()}>
                        {t('common:buttons.cancel.text')}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ marginLeft: 10 }}
                        type="primary"
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </Col>
    );
}

SelectTargetStep.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isLoadingMembers: PropTypes.bool.isRequired,
    isLoadingCompanies: PropTypes.bool.isRequired,

    data: PropTypes.shape({
        client_id: PropTypes.number.isRequired,
        member_id: PropTypes.number,
        target: PropTypes.string.isRequired,
    }).isRequired,
    dispatchGetTargetList: PropTypes.func.isRequired,
    dispatchRequestNavigationBack: PropTypes.func.isRequired,
    dispatchClearMembers: PropTypes.func.isRequired,
    dispatchClearCompanies: PropTypes.func.isRequired,
    dispatchSetMemberEmbossName: PropTypes.func.isRequired,
    dispatchClearCardsDetails: PropTypes.func.isRequired,
    dispatchClearAddress: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default SelectTargetStep;
