import RemoteSearch from 'components/common/molecules/RemoteSearch';
import AccountsDropdown from 'components/common/molecules/custom-dropdowns/AccountsDropdown';
import ClientSelect from 'components/common/molecules/form-controls/ClientSelect';
import { EXCHANGE_RATE_DECIMALS, listCompanies, listMembers } from 'components/pages/authorised/ClientDetails/Tabs/NewTransaction/NewTransaction.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AccountTypes } from 'constants/AccountModel';
import { getAccountsAPI } from 'services/accounts';
import { cleanNilValues } from 'utils/dataMappers';
import { divideBig, multiplyBig } from 'utils/math-tools';

import {
    Row, Col, Form, Input, Divider, Button, Radio, InputNumber, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

// TODO refactor !
// eslint-disable-next-line complexity
function NewTransaction({
    accessControl,
    resetFormState,

    exchangeRate,
    userClientId,
    isLoading,
    isLoadingExchangeRate,
    isLoadingExchange,

    dispatchFetchExchangeRate,
    dispatchInitExchange,
    dispatchCreateP2PTransaction,
    dispatchClearResetNewTransactionFormState,
}) {
    const [form] = Form.useForm();


    const [senderType, setSenderType] = useState(1);
    const [recipientType, setRecipientType] = useState(() => (accessControl.isSuperAdmin ? 1 : 2)); // XXX allowed - client select in new transaction
    const [senderMembers, setSenderMembers] = useState([]);
    const [recipientMembers, setRecipientMembers] = useState([]);
    const [senderCompanies, setSenderCompanies] = useState([]);
    const [recipientCompanies, setRecipientCompanies] = useState([]);
    const [senderAccounts, setSenderAccounts] = useState([]);
    const [recipientAccounts, setRecipientAccounts] = useState([]);

    const [senderSelectedClient, setSenderSelectedClient] = useState(null);
    const [recipientSelectedClient, setRecipientSelectedClient] = useState(null);
    const [senderSelectedMember, setSenderSelectedMember] = useState(null);
    const [recipientSelectedMember, setRecipientSelectedMember] = useState(null);
    const [senderSelectedCompany, setSenderSelectedCompany] = useState(null);
    const [recipientSelectedCompany, setRecipientSelectedCompany] = useState(null);

    const [isExchange, setIsExchange] = useState(false);
    const [senderCurrency, setSenderCurrency] = useState(null);
    const [recipientCurrency, setRecipientCurrency] = useState(null);
    const [isLoadingSenderAccounts, setIsLoadingSenderAccounts] = useState(false);
    const [isLoadingRecipientAccounts, setIsLoadingRecipientAccounts] = useState(false);

    const isSenderClient = senderType === 1;
    const isSenderMember = senderType === 2;
    const isSenderCompany = senderType === 3;
    const isRecipientClient = recipientType === 1;
    const isRecipientMember = recipientType === 2;
    const isRecipientCompany = recipientType === 3;

    const inverseExchangeRate = useMemo(
        () => divideBig({ x: 1, y: exchangeRate, precision: 6 }),
        [exchangeRate],
    );

    useEffect(() => {
        const fetchMembers = async () => {
            const data = await listMembers({ clientId: userClientId });
            setSenderMembers(data);
            setRecipientMembers(data);
        };

        const fetchAccounts = async () => {
            setIsLoadingSenderAccounts(true);
            const response = await getAccountsAPI({
                params: {
                    clientId: userClientId,
                    type: [AccountTypes.Base, AccountTypes.Technical],
                },
            });

            setSenderAccounts(response.data);
            setRecipientAccounts(response.data);
            setIsLoadingSenderAccounts(false);
        };

        if (!accessControl.isSuperAdmin) { // XXX allowed - client select in new transaction
            setSenderSelectedClient(userClientId);
            setRecipientSelectedClient(userClientId);
            fetchMembers();
            fetchAccounts();
        }

        form.setFieldsValue({
            sender_member: { value: null, label: null },
            recipient_member: { value: null, label: null },
            sender_company: { value: null, label: null },
            recipient_company: { value: null, label: null },
        });
    }, [userClientId]);

    useEffect(() => {
        if (resetFormState === true) {
            form.resetFields();
            form.setFieldsValue({
                sender_member: { value: null, label: null },
                recipient_member: { value: null, label: null },
                sender_company: { value: null, label: null },
                recipient_company: { value: null, label: null },
            });

            if (accessControl.isSuperAdmin) { // XXX allowed - client select in new transaction
                setSenderSelectedClient(null);
                setRecipientSelectedClient(null);
            }

            setSenderSelectedMember(null);
            setRecipientSelectedMember(null);
            setSenderSelectedCompany(null);
            setRecipientSelectedCompany(null);
            setIsExchange(false);
            dispatchClearResetNewTransactionFormState();
        }
    }, [resetFormState]);


    const onRadioChangeHandler = (e) => {
        const { value, name } = e.target;

        if (name === 'sender') {
            setSenderType(value);

            // Reset sender state on change
            setSenderSelectedClient(null);
            setSenderSelectedMember(null);
            form.setFieldsValue({
                sender_client_id: null,
                sender_member: { value: null, label: null },
                sender_account_id: null,
            });
        } else if (name === 'recipient') {
            setRecipientType(value);
            setRecipientSelectedMember(null);
            form.setFieldsValue({
                recipient_member: { value: null, label: null },
                recipient_account_id: null,
            });
            if (accessControl.isSuperAdmin) { // XXX allowed - client select in new transaction
                // Reset recipient state on change
                setRecipientSelectedClient(null);
                form.setFieldsValue({ recipient_client_id: null });
            } else {
                setRecipientAccounts(senderAccounts);
                setRecipientSelectedClient(userClientId);
            }
        }
    };

    const onSenderClientSelectHandler = async (clientId) => {
        setIsLoadingSenderAccounts(true);
        const accountsResponse = await getAccountsAPI({
            params: {
                clientId,
                type: [AccountTypes.Base, AccountTypes.Technical],
            },
        });
        setSenderAccounts(accountsResponse.data);
        setIsLoadingSenderAccounts(false);

        if (isSenderMember) {
            const members = await listMembers({ clientId });
            setSenderMembers(members);
        } else if (isSenderCompany) {
            const companies = await listCompanies({ clientId });
            setSenderCompanies(companies);
        }

        form.setFieldsValue({
            sender_member: { value: null, label: null },
            sender_company: { value: null, label: null },
            sender_account_id: null,
        });

        setSenderSelectedClient(clientId);
    };

    const onRecipientClientSelectHandler = async (clientId) => {
        setIsLoadingRecipientAccounts(true);
        const accountsResponse = await getAccountsAPI({
            params: {
                clientId,
                type: [AccountTypes.Base, AccountTypes.Technical],
            },
        });
        setRecipientAccounts(accountsResponse.data);
        setIsLoadingRecipientAccounts(false);

        if (isRecipientMember) {
            const members = await listMembers({ clientId });
            setRecipientMembers(members);
        } else if (isRecipientCompany) {
            const companies = await listCompanies({ clientId });
            setRecipientCompanies(companies);
        }

        form.setFieldsValue({
            recipient_member: { value: null, label: null },
            recipient_company: { value: null, label: null },
            recipient_account_id: null,
        });

        setRecipientSelectedClient(clientId);
    };

    const onSenderMemberSelectHandler = async ({ value: memberId }) => {
        setIsLoadingSenderAccounts(true);
        const accountsResponse = await getAccountsAPI({
            params: {
                memberId,
                type: [AccountTypes.Personal, AccountTypes.Technical],
            },
        });

        setSenderAccounts(accountsResponse.data);
        setIsLoadingSenderAccounts(false);

        form.setFieldsValue({ sender_account_id: null });

        setSenderSelectedMember(memberId);
    };

    const onSenderCompanySelectHandler = async ({ value: companyId }) => {
        setIsLoadingSenderAccounts(true);
        const accountsResponse = await getAccountsAPI({
            params: {
                companyId,
                type: [AccountTypes.Business, AccountTypes.Technical],
            },
        });
        setSenderAccounts(accountsResponse.data);
        setIsLoadingSenderAccounts(false);

        form.setFieldsValue({ sender_account_id: null });

        setSenderSelectedCompany(companyId);
    };

    const onRecipientMemberSelectHandler = async ({ value: memberId }) => {
        setIsLoadingRecipientAccounts(true);
        const accountsResponse = await getAccountsAPI({
            params: {
                memberId,
                type: [AccountTypes.Personal, AccountTypes.Technical],
            },
        });
        setRecipientAccounts(accountsResponse.data);
        setIsLoadingRecipientAccounts(false);

        form.setFieldsValue({ recipient_account_id: null });

        setRecipientSelectedMember(memberId);
    };

    const onRecipientCompanySelectHandler = async ({ value: companyId }) => {
        setIsLoadingRecipientAccounts(true);
        const accountsResponse = await getAccountsAPI({
            params: {
                companyId,
                type: [AccountTypes.Business, AccountTypes.Technical],
            },
        });
        setRecipientAccounts(accountsResponse.data);
        setIsLoadingRecipientAccounts(false);

        form.setFieldsValue({ recipient_account_id: null });

        setRecipientSelectedCompany(companyId);
    };

    const onAccountSelectHandler = async () => {
        let senderCurrency = null;
        let recipientCurrency = null;

        const senderAccId = form.getFieldValue('sender_account_id');

        if (senderAccId) {
            const senderAcc = senderAccounts.find((acc) => acc.id === senderAccId);
            senderCurrency = senderAcc?.currency;
        }

        const recipientAccId = form.getFieldValue('recipient_account_id');

        if (recipientAccId) {
            const recipientAcc = recipientAccounts.find((acc) => acc.id === recipientAccId);
            recipientCurrency = recipientAcc?.currency;
        }

        const isExchange = senderCurrency && recipientCurrency && senderCurrency !== recipientCurrency;

        if (isExchange) {
            form.setFieldsValue({ buy_amount: null, sell_amount: null });
            dispatchFetchExchangeRate({ from: senderCurrency, to: recipientCurrency });
        }

        setIsExchange(isExchange);
        setSenderCurrency(senderCurrency);
        setRecipientCurrency(recipientCurrency);
    };


    const onAmountChangeHandler = (inputType, value) => {
        if (inputType === 'sell') {
            const buyAmount = multiplyBig({ x: value, y: exchangeRate, precision: 2, roundingMode: 0 });
            form.setFieldsValue({ buy_amount: buyAmount });
        }

        if (inputType === 'buy') {
            const sellAmount = multiplyBig({ x: value, y: inverseExchangeRate, precision: 2, roundingMode: 0 });
            form.setFieldsValue({ sell_amount: sellAmount });
        }
    };

    const doExchange = (formData) => {
        const { sell_amount, sender_member, sender_account_id, recipient_account_id } = formData;

        const initExchangeData = sender_member ? {
            sendCurrency: senderCurrency,
            receiveCurrency: recipientCurrency,
            sendAmount: sell_amount,
            sender_member,
        } : {
            sendAmount: sell_amount,
            fromAccount: sender_account_id,
            toAccount: recipient_account_id,
        };

        dispatchInitExchange(initExchangeData);
    };

    const transformFormData = (formData) => {
        const {
            sender_member,
            recipient_member,
            sender_company,
            recipient_company,
            ...data
        } = formData;

        data.sender_member_id = sender_member?.value;
        data.recipient_member_id = recipient_member?.value;
        data.sender_company_id = sender_company?.value;
        data.recipient_company_id = recipient_company?.value;

        return cleanNilValues(data);
    };


    const createMakeV2PaymentPayload = (formData) => {
        const data = {
            amount: Number.parseFloat(formData?.amount).toFixed(2),
            reference: formData?.reference,
            account_id: formData?.sender_account_id,
            currency: senderCurrency,
            receiver: { account_id: formData?.recipient_account_id },

            reason: formData?.reason,
        };

        return cleanNilValues(data);
    };

    const onFinishHandler = async (formData) => {
        if (isExchange) {
            const data = transformFormData(formData);
            doExchange(data);
        } else {
            dispatchCreateP2PTransaction(createMakeV2PaymentPayload(formData));
        }
    };


    return (
        <div style={{ padding: '15px' }}>
            <Form form={form} onFinish={onFinishHandler}>
                <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 40 }}>
                    <Col span={12}>
                        <Divider orientation="left">From</Divider>

                        {accessControl.isSuperAdmin && ( // XXX allowed - client select in new transaction
                            <Form.Item>
                                <Radio.Group name="sender" onChange={onRadioChangeHandler} value={senderType}>
                                    <Radio value={1}>Partner</Radio>
                                    <Radio value={2}>Member</Radio>
                                    <Radio value={3} disabled={isRecipientMember}>
                    Company
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        )}

                        <ClientSelect
                            name="sender_client_id"
                            disabled={!accessControl.isSuperAdmin} // XXX allowed - client select - disable client change
                            placeholder="Select Partner"
                            onSelect={onSenderClientSelectHandler}
                            initialValue={!accessControl.isSuperAdmin ? userClientId : null} // XXX allowed - client select in new transaction
                            dependencies={['recipient_client_id']}
                        />

                        {isSenderMember && accessControl.isSuperAdmin && (
                            <Form.Item
                                name="sender_member"
                                dependencies={['recipient_member']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator: async (_, sender_member) => {
                                            const recipient_member = getFieldValue('recipient_member');

                                            if (sender_member.value === recipient_member.value) throw new Error('Sender member cannot be the same as recipient member');
                                        },
                                    }),
                                ]}
                            >
                                <RemoteSearch
                                    placeholder="Select Member"
                                    fetchOptions={(search) => listMembers({ clientId: senderSelectedClient, search })}
                                    onSelect={onSenderMemberSelectHandler}
                                    showSearch
                                    disabled={!senderSelectedClient}
                                    options={senderMembers}
                                    setOptions={setSenderMembers}
                                />
                            </Form.Item>
                        )}

                        {isSenderCompany && accessControl.isSuperAdmin && (
                            <Form.Item
                                name="sender_company"
                                dependencies={['recipient_company']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator: async (_, sender_company) => {
                                            const recipient_company = getFieldValue('recipient_company');

                                            if (sender_company.value === recipient_company.value) throw new Error('Sender company cannot be the same as recipient company');
                                        },
                                    }),
                                ]}
                            >
                                <RemoteSearch
                                    placeholder="Search Company"
                                    fetchOptions={(search) => listCompanies({ clientId: senderSelectedClient, search })}
                                    onSelect={onSenderCompanySelectHandler}
                                    showSearch
                                    disabled={!senderSelectedClient}
                                    options={senderCompanies}
                                    setOptions={setSenderCompanies}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="sender_account_id"
                            dependencies={['recipient_account_id']}
                            rules={[
                                { required: true, message: 'Account is a required field' },
                                ({ getFieldValue }) => ({
                                    // eslint-disable-next-line consistent-return
                                    validator: async () => {
                                        const recipient_account_id = getFieldValue('recipient_account_id');

                                        if (!recipient_account_id) return true;

                                        const recipientAcc = recipientAccounts.find(
                                            (acc) => acc?.id === recipient_account_id,
                                        );

                                        const hasCurrency = senderAccounts.find(
                                            (acc) => acc?.currency === recipientAcc?.currency,
                                        );

                                        if (!hasCurrency) {
                                            throw new Error(
                                                `Exchange not possible. Sender does not have an account with following currency: ${recipientAcc.currency}`,
                                            );
                                        }
                                    },
                                }),
                            ]}
                        >
                            <AccountsDropdown
                                placeholder="Select Account"
                                loading={isLoadingSenderAccounts}
                                disabled={
                                    !senderSelectedClient
                  // TODO  remove !accessControl.isWhiteLabelAdmin (last use case), why it is even here?
                  || (isSenderMember && !accessControl.isWhiteLabelAdmin && !senderSelectedMember)
                  || (isSenderCompany && !senderSelectedCompany)
                                }
                                onSelect={onAccountSelectHandler}
                                items={senderAccounts}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Divider orientation="left">To</Divider>

                        <Form.Item>
                            <Radio.Group name="recipient" onChange={onRadioChangeHandler} value={recipientType}>
                                <Radio value={1}>Partner</Radio>
                                <Radio value={2}>Member</Radio>
                                <Radio value={3} disabled={isSenderMember}>Company</Radio>
                            </Radio.Group>
                        </Form.Item>


                        <ClientSelect
                            name="recipient_client_id"
                            disabled={!accessControl.isSuperAdmin} // XXX allowed - client filter in new transaction/ disable change
                            placeholder="Select Partner"
                            onSelect={onRecipientClientSelectHandler}
                            dependencies={['sender_client_id']}
                        />

                        {isRecipientMember && (
                            <Form.Item
                                name="recipient_member"
                                dependencies={['sender_member']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator: async (_, recipient_member) => {
                                            const sender_member = getFieldValue('sender_member');

                                            if (recipient_member.value === sender_member.value) throw new Error('Recipient Member cannot be the same as Sender Member ');
                                        },
                                    }),
                                ]}
                            >
                                <RemoteSearch
                                    placeholder="Select Member"
                                    fetchOptions={(search) => listMembers({ clientId: recipientSelectedClient, search })}
                                    onSelect={onRecipientMemberSelectHandler}
                                    showSearch
                                    disabled={!recipientSelectedClient}
                                    options={recipientMembers}
                                    setOptions={setRecipientMembers}
                                />
                            </Form.Item>
                        )}

                        {isRecipientCompany && (
                            <Form.Item
                                name="recipient_company"
                                dependencies={['sender_company']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator: async (_, recipient_company) => {
                                            const sender_company = getFieldValue('sender_company');

                                            if (recipient_company.value === sender_company.value) throw new Error('Recipient Company cannot be the same as Sender Company ');
                                        },
                                    }),
                                ]}
                            >
                                <RemoteSearch
                                    placeholder="Search Company"
                                    fetchOptions={(search) => listCompanies({ clientId: recipientSelectedClient, search })}
                                    onSelect={onRecipientCompanySelectHandler}
                                    showSearch
                                    disabled={!recipientSelectedClient}
                                    options={recipientCompanies}
                                    setOptions={setRecipientCompanies}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="recipient_account_id"
                            dependencies={['sender_account_id']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Account is a required field',
                                },
                                ({ getFieldValue }) => ({
                                    // TODO refactor & reduce complexity
                                    // eslint-disable-next-line complexity
                                    validator: async (_, recipient_account_id) => {
                                        const sender_account_id = getFieldValue('sender_account_id');

                                        const isBothPartner = isSenderClient && isRecipientClient;
                                        const isBothMember = isSenderMember && isRecipientMember;
                                        const isBothCompany = isSenderCompany && isRecipientCompany;

                                        if (
                                            (isBothPartner || isBothMember || isBothCompany)
                      && recipient_account_id === sender_account_id
                                        ) {
                                            throw new Error('Recipient account cannot be the same as sender account');
                                        }

                                        const senderAcc = senderAccounts.find((acc) => acc.id === sender_account_id);
                                        const recipientAcc = recipientAccounts.find((acc) => acc.id === recipient_account_id);

                                        const isExchange = senderAcc?.currency !== recipientAcc?.currency;
                                        const isBothBaseAccounts = senderAcc?.type === AccountTypes.Base
                      && recipientAcc?.type === AccountTypes.Base;

                                        if (
                                            recipient_account_id
                      && sender_account_id
                      && isExchange
                      && !isBothBaseAccounts
                                        ) {
                                            throw new Error('Exchange is allowed only between Base accounts');
                                        }
                                    },
                                }),
                            ]}
                        >
                            <AccountsDropdown
                                placeholder="Select Account"
                                loading={isLoadingRecipientAccounts}
                                disabled={
                                    !recipientSelectedClient
                  || (isRecipientMember && !recipientSelectedMember)
                  || (isRecipientCompany && !recipientSelectedCompany)
                                }
                                onSelect={onAccountSelectHandler}
                                items={recipientAccounts}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 40 }}>
                    <Col span={24}>
                        <Divider orientation="center">Transaction Details</Divider>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="reference"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Transaction Reference is a required field',
                                },
                            ]}
                        >
                            <Input placeholder="Transaction Reference" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="reason">
                            <Input placeholder="Transaction Reason" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 40 }}>
                    <Col span={24}>
                        <Divider orientation="center">Amount and confirm</Divider>
                    </Col>

                    {isExchange ? (
                        <>
                            <Col span={12}>
                                <Form.Item
                                    name="sell_amount"
                                    rules={[
                                        {
                                            type: 'number',
                                            required: true,
                                            whitespace: true,
                                            message: 'Sell amount is a required field',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator: async () => {
                                                const sell_amount = getFieldValue('sell_amount');

                                                if (sell_amount <= 0) throw new Error('Sell amount must be greater than 0');
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber
                                        placeholder="Sell amount"
                                        style={{ width: '100%' }}
                                        onChange={(value) => onAmountChangeHandler('sell', value)}
                                    />
                                </Form.Item>
                                {isLoadingExchangeRate ? (
                                    <Spin />
                                ) : exchangeRate && (
                                    <span style={{ fontWeight: 'bold' }}>
                                        {`${senderCurrency} 1 = ${recipientCurrency} ${exchangeRate}`}
                                    </span>
                                )}
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="buy_amount"
                                    rules={[
                                        {
                                            type: 'number',
                                            required: true,
                                            whitespace: true,
                                            message: 'Buy amount is a required field',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        precision={EXCHANGE_RATE_DECIMALS}
                                        placeholder="Buy amount"
                                        style={{ width: '100%' }}
                                        onChange={(value) => onAmountChangeHandler('buy', value)}
                                    />
                                </Form.Item>
                                {isLoadingExchangeRate ? (
                                    <Spin />
                                ) : inverseExchangeRate && (
                                    <span style={{ fontWeight: 'bold' }}>
                                        {`${recipientCurrency} 1 = ${senderCurrency} ${inverseExchangeRate}`}
                                    </span>

                                )}
                            </Col>
                        </>
                    ) : (
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                rules={[
                                    {
                                        type: 'number',
                                        required: true,
                                        whitespace: true,
                                        message: 'Amount is a required field',
                                    },
                                ]}
                            >
                                <InputNumber
                                    placeholder="Amount"
                                    precision={EXCHANGE_RATE_DECIMALS}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    <Col span={isExchange ? 24 : 12}>
                        <Form.Item>
                            <Button
                                style={{ display: 'block', marginLeft: 'auto' }}
                                type="primary"
                                htmlType="submit"
                                loading={isLoading || isLoadingExchange}
                            >
                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

NewTransaction.propTypes = {
    accessControl: AccessControlProps,
    userClientId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingExchangeRate: PropTypes.bool.isRequired,
    isLoadingExchange: PropTypes.bool.isRequired,
    exchangeRate: PropTypes.number,
    dispatchFetchExchangeRate: PropTypes.func.isRequired,
    dispatchInitExchange: PropTypes.func.isRequired,
    dispatchCreateP2PTransaction: PropTypes.func.isRequired,
    dispatchClearResetNewTransactionFormState: PropTypes.func.isRequired,
};

export default NewTransaction;
