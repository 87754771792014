/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { fetchBPSAccountProductsAPI, fetchDefaultClientConfigurationAPI } from './index';


class ConfigurationService {
    public fetchDefaultClientConfiguration(): Promise<ApiResponse | ApiError> {
        return fetchDefaultClientConfigurationAPI();
    }

    public fetchBPSAccountProducts(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { tpp_code, accountType, currency } = rawQueryParams;

        const query = {
            params: {
                ...(tpp_code ? { tpp_code } : {}),
                ...(accountType ? { accountType } : {}),
                ...(currency ? { currency } : {}),
            },
        };
        return fetchBPSAccountProductsAPI(query);
    }
}

export default ConfigurationService;

