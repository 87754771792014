import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_CURRENT_USER, SET_CURRENT_USER, SET_CURRENT_USER_FAILURE, SET_CURRENT_USER_SUCCESS,
    EXTEND_TOKEN_VALIDITY, EXTEND_TOKEN_VALIDITY_FAILURE, EXTEND_TOKEN_VALIDITY_SUCCESS,
    USER_ACTIVITY, CHECK_TOKENS, EXPIRE_TOKEN,
} from './actions.types';


export const setCurrentUser = createAction(SET_CURRENT_USER, (responsePayload, variant) => ({ payload: { responsePayload, variant } }));
export const setCurrentUserSuccess = createAction(SET_CURRENT_USER_SUCCESS, (loginResponseData) => ({ payload: loginResponseData }));
export const setCurrentUserFailure = createAction(SET_CURRENT_USER_FAILURE);

export const clearCurrentUser = createAction(CLEAR_CURRENT_USER);

export const extendTokenValidity = createAction(EXTEND_TOKEN_VALIDITY);
export const extendTokenValiditySuccess = createAction(EXTEND_TOKEN_VALIDITY_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const extendTokenValidityFailure = createAction(EXTEND_TOKEN_VALIDITY_FAILURE);

export const userActivity = createAction(USER_ACTIVITY);

export const checkTokens = createAction(CHECK_TOKENS, (eventName) => ({ payload: eventName }));

export const expireToken = createAction(EXPIRE_TOKEN, (requestPayload) => ({ payload: requestPayload }));
