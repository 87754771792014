import StandardListTable from 'components/common/organisms/StandardListTable';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultFailedWebhooksListQueryParams } from 'redux/failed-webhooks/reducer';

import { RedoOutlined } from '@ant-design/icons';
import React from 'react';


import { FailedWebhooksListProps } from './FailedWebhooks.types';
import tableColumns from './tableColumns';


function FailedWebhooksList({
    // own props
    clientId,

    // mapped props
    accessControl,

    enhancedCurrentLocation,
    listData,

    isLoadingReplayFailedWebhook,
    isLoadingReplayAllFailedWebhooks,

    dispatchSetQueryParams,
    dispatchFetchFailedWebhooksList,
    dispatchClearFailedWebhooksList,

    dispatchReplayFailedWebhook,
    dispatchReplayAllFieldWebhooks,
    dispatchShowViewJsonModal,
    dispatchRequestNavigation,
    t,
}: FailedWebhooksListProps) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { clientId };

    // 2.  BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchFailedWebhooksList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultFailedWebhooksListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchFailedWebhooksList,
        dispatchClearItemsList: dispatchClearFailedWebhooksList,
        dispatchSetQueryParams,
    });

    // 4a. list specific stuff

    // 5. Standard render
    return (
        <StandardListTable
            rowKey="uuid"
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList || isLoadingReplayAllFailedWebhooks}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={tableColumns({
                accessControl,
                queryParams: listData?.queryParams,
                dispatchReplayFailedWebhook,
                dispatchShowViewJsonModal,
                enhancedCurrentLocation,
                dispatchRequestNavigation,
                isLoadingReplayFailedWebhook,
                t,
            })}
            filtersButtonsConfig={accessControl.hasPermission(PN.WEBHOOKS.REPLAY_ALL_FAILED)
                ? [{
                    type: 'primary',
                    text: t('buttons.replayAllFailedWebhooks.text'),
                    icon: <RedoOutlined />,
                    isLoading: isLoadingReplayAllFailedWebhooks,
                    onClick: () => dispatchReplayAllFieldWebhooks(enhancedCurrentLocation?.pathname),
                }]
                : []}
        />
    );
}

export default FailedWebhooksList;
