/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { exportMembersMarketingPreferencesAPI, fetchMembersMarketPreferencesAPI, fetchMembersMarketPreferencesHistoryAPI } from './index';


class ConfigService {
    public fetchMembersMarketPreferences(queryParams): Promise<ApiResponse | ApiError> {
        return fetchMembersMarketPreferencesAPI({ params: queryParams });
    }

    public fetchMembersMarketPreferencesHistory(queryParams): Promise<ApiResponse | ApiError> {
        return fetchMembersMarketPreferencesHistoryAPI(queryParams);
    }

    public exportMembersMarketingPreferences(queryParams): Promise<ApiResponse | ApiError> {
        return exportMembersMarketingPreferencesAPI({ params: queryParams });
    }
}

export default ConfigService;
