import { SortDirections } from 'constants/ApplicationStateModel';
import { globalLocation } from 'constants/NavigationModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withSkipImages } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import { fetchCurrencies, fetchCurrenciesFailure, fetchCurrenciesSuccess, clearCurrencies } from './actions';


export const currenciesReducerName = 'currencies';
export const currenciesListsPerLocationStoreKeyName = 'currenciesListsPerLocation';


export const defaultCurrenciesListQueryParams = {
    limit: 20,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withSkipImages,
};


const initialState = { [currenciesListsPerLocationStoreKeyName]: { [globalLocation]: { queryParams: defaultCurrenciesListQueryParams } } };

const handlers = {
    [fetchCurrencies]: (state, action) => {
        state[currenciesListsPerLocationStoreKeyName] = updateStandardLists(state[currenciesListsPerLocationStoreKeyName], action);
    },
    [fetchCurrenciesSuccess]: (state, action) => {
        state[currenciesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[currenciesListsPerLocationStoreKeyName], action);
    },
    [fetchCurrenciesFailure]: (state, action) => {
        state[currenciesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[currenciesListsPerLocationStoreKeyName], action);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === currenciesReducerName
      && action.payload?.fieldName === currenciesListsPerLocationStoreKeyName
        ) {
            state[currenciesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[currenciesListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCurrencies]: (state, action) => {
        state[currenciesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[currenciesListsPerLocationStoreKeyName], action.payload?.locationPathname);
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
