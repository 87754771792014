import { SortDirections } from 'constants/ApplicationStateModel';

import dotObject from 'dot-object';


import noop from './function-tools';
import { QueryParams, SorterFunction, SorterResult } from '../models/app/common';


export const sortDirections = [SortDirections.ASCEND, SortDirections.DESCEND, SortDirections.ASCEND];

export const sortingOptionsLEGACY = {
    sorter: () => noop,
    // XXX to remove unsorted option
    // see https://ant.design/components/table/#components-table-demo-head
    sortDirections,
};

export const sortingOptions = ({
    fieldName,
    queryParams,
    sorter,
}: {
  fieldName: string,
  queryParams: QueryParams,
  sorter?: SorterFunction
}) => ({
    sorter: sorter || noop,
    // XXX to remove unsorted option
    // see https://ant.design/components/table/#components-table-demo-head
    sortDirections,
    defaultSortOrder: fieldName === queryParams?.sortBy ? queryParams.sortOrder : undefined,
});


export const sortStringsAlphabetically = (a: string, b: string, key: string): SorterResult => {
    const A = key ? dotObject.pick(key, a) : a;
    const B = key ? dotObject.pick(key, b) : b;

    if (A && B) {
        return A.localeCompare(B);
    }

    if (A && !B) {
        return 1;
    }

    if (!A && B) {
        return -1;
    }

    return 0;
};

export const sortNumbers = (a: number, b: number, key: string) => {
    const A = key ? dotObject.pick(key, a) : a;
    const B = key ? dotObject.pick(key, b) : b;

    if (A && B) {
        return A - B;
    }

    if (A && !B) {
        return 1;
    }

    if (!A && B) {
        return -1;
    }

    return 0;
};


export const sortBooleansByKey = (a: boolean, b: boolean, key: string): SorterResult => {
    if (a[key] === b[key]) {
        return 0;
    }
    return a[key] ? -1 : 1;
};

export const sortStringDates = (a: string, b: string, key: string): SorterResult => {
    const dateA = new Date(a[key]);
    const dateB = new Date(b[key]);

    if (dateA === dateB) {
        return 0;
    }

    return dateA > dateB ? 1 : -1;
};

export const sortObjectKeysAlphabetically = (rawObject = {}) => Object.keys(rawObject)
    .sort()
    .reduce((acc, currentKey) => ({
        ...acc,
        [currentKey]: rawObject[currentKey],
    }), {});


export default {
    sortDirections,
    sortingOptions: sortingOptionsLEGACY,
    sortStringsAlphabetically,
    sortNumbers,
    sortBooleansByKey,
    sortStringDates,
    sortObjectKeysAlphabetically,
};

