import { CLEAR_KYC_KYB_LOGS_LIST, FETCH_KYC_KYB_LOGS_LIST, FETCH_KYC_KYB_LOGS_LIST_FAILURE, FETCH_KYC_KYB_LOGS_LIST_SUCCESS } from 'redux/kyc-kyb/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchCurrentKycKybFlow = createAction('kycKyb/fetchCurrentKycKybFlow', (queryParam) => ({ payload: queryParam }));
export const fetchCurrentKycKybFlowSuccess = createAction('kycKyb/fetchCurrentKycKybFlowSuccess', (responsePayload) => ({ payload: responsePayload }));
export const fetchCurrentKycKybFlowFailure = createAction('kycKyb/fetchCurrentKycKybFlowFailure');


export const setKycKybFlow = createAction('kycKyb/setKycKybFlow', (requestPayload) => ({ payload: requestPayload }));
export const setKycKybFlowSuccess = createAction('kycKyb/setKycKybFlowSuccess', (responsePayload) => ({ payload: responsePayload }));
export const setKycKybFlowFailure = createAction('kycKyb/setKycKybFlowFailure');


export const triggerKycKybFlow = createAction('kycKyb/triggerKycKybFlow', (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const triggerKycKybFlowSuccess = createAction('kycKyb/triggerKycKybFlowSuccess', (responsePayload) => ({ payload: responsePayload }));
export const triggerKycKybFlowFailure = createAction('kycKyb/triggerKycKybFlowFailure');

export const setIsInEditLevelMode = createAction('kycKyb/setInEditLevelMode', (value) => ({ payload: value }));


export const fetchKycKybLogsList = createAction(FETCH_KYC_KYB_LOGS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchKycKybLogsListSuccess = createAction(FETCH_KYC_KYB_LOGS_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchKycKybLogsListFailure = createAction(FETCH_KYC_KYB_LOGS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearKycKybLogsList = createAction(CLEAR_KYC_KYB_LOGS_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export default {
    fetchCurrentKycKybFlow,
    fetchCurrentKycKybFlowSuccess,
    fetchCurrentKycKybFlowFailure,
    setKycKybFlow,
    setKycKybFlowSuccess,
    setKycKybFlowFailure,
    triggerKycKybFlow,
    triggerKycKybFlowSuccess,
    triggerKycKybFlowFailure,
    setIsInEditLevelMode,
    fetchKycKybLogsList,
    fetchKycKybLogsListSuccess,
    fetchKycKybLogsListFailure,
    clearKycKybLogsList,
};
