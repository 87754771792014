import { requestSetMultiStepsFormStep, requestSetMultiStepsFormSubStep } from 'redux/multi-steps-form/actions';
import { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';
import { requestNavigation } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import MultiStepFormButtons from './MultiStepFormButtons.layout';


const mapStateToProps = (state, ownProps) => {
    const { locationPathname } = ownProps;
    const { currentMultiStepsFormStep } = state[multiStepsFormReducerName][locationPathname];
    return {
        availableSteps: state[multiStepsFormReducerName][locationPathname].availableSteps,
        availableSubSteps: state[multiStepsFormReducerName][locationPathname].availableSubSteps?.[currentMultiStepsFormStep],
        currentMultiStepsFormStep,
        currentMultiStepsFormSubStep: state[multiStepsFormReducerName][locationPathname]?.currentMultiStepsFormSubStep?.[currentMultiStepsFormStep],
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { locationPathname } = ownProps;
    return {
        dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
        dispatchRequestSetMultiStepsFormStep: (currentStep, direction) => dispatch(requestSetMultiStepsFormStep(
            currentStep,
            direction,
            locationPathname,
        )),
        dispatchRequestSetMultiStepsFormSubStep: (parentStep, currentSubStep, direction) => dispatch(requestSetMultiStepsFormSubStep(
            {
                parentStep,
                currentSubStep,
                direction,
            },
            locationPathname,
        )),
    };
};

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(MultiStepFormButtons);
