import { createClientDetailsPath } from 'config/routes.helpers';
import { accessControlForEpics } from 'hocs/withAccessControl';
import { FETCH_ACCOUNT_DETAILS_SUCCESS } from 'redux/account/action.types';
import { FETCH_BENEFICIARY_DETAILS_SUCCESS } from 'redux/beneficiary/actions.types';
import { FETCH_BUSINESS_USER_DETAILS_SUCCESS } from 'redux/business-user/action.types';
import { FETCH_CARD_DETAILS_SUCCESS } from 'redux/card/action.types';
import { fetchClientDetails } from 'redux/client/actions';
import { FETCH_CLIENT_DETAILS_SUCCESS } from 'redux/client/actions.types';
import { SET_CLIENT_CONTEXT } from 'redux/client-context/actions.types';
import { clientContextReducerName } from 'redux/client-context/reducer';
import { FETCH_COMPANY_DETAILS_SUCCESS } from 'redux/company/action.types';
import { FETCH_GROUP_DETAILS_SUCCESS } from 'redux/group/actions.types';
import { FETCH_MEMBER_DETAILS_SUCCESS } from 'redux/member/actions.types';

import { ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


export const onFetchEntityDetails = (action$, state$) => action$.pipe(
    ofType(
        FETCH_CLIENT_DETAILS_SUCCESS,
        'user/fetchUserDetailsSuccess',
        FETCH_CARD_DETAILS_SUCCESS,
        FETCH_ACCOUNT_DETAILS_SUCCESS,
        FETCH_MEMBER_DETAILS_SUCCESS,
        FETCH_COMPANY_DETAILS_SUCCESS,
        FETCH_BUSINESS_USER_DETAILS_SUCCESS,
        FETCH_BENEFICIARY_DETAILS_SUCCESS,
        FETCH_GROUP_DETAILS_SUCCESS,
    ),
    switchMap(({ payload, type }) => {
        const accessControl = accessControlForEpics(state$.value);
        if (accessControl.isSuperAdmin) { // XXX allowed - client context
            const clientId = type === FETCH_CLIENT_DETAILS_SUCCESS
                ? payload?.responsePayload?.id
                : (payload.client_id
          || payload?.clientId
          || payload?.responsePayload?.clientId
          || payload?.responsePayload?.client_id
          || payload?.responseData?.client_id
          || payload?.responseData?.clientId
                );

            if (!state$.value[clientContextReducerName]?.clientsConfigs?.[clientId]) {
                return of(fetchClientDetails({ clientId }, createClientDetailsPath(clientId)));
            }
        }

        return EMPTY;
    }),
);

export const onSetClientIdContext = (action$, state$) => action$.pipe(
    ofType(SET_CLIENT_CONTEXT),
    switchMap(({ payload }) => {
        const accessControl = accessControlForEpics(state$.value);
        if (accessControl.isSuperAdmin) { // XXX allowed - client-context
            if (!state$.value[clientContextReducerName]?.clientsConfigs?.[payload]) {
                return of(fetchClientDetails({ clientId: payload }, createClientDetailsPath(payload)));
            }
        }

        return EMPTY;
    }),
);

export default [
    onFetchEntityDetails,
    onSetClientIdContext,
];
