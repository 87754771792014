import { membersRootRoutePath } from 'config/routes';
import { createClientDetailsMembersListPath } from 'config/routes.helpers';
import { showSuccessToast } from 'redux/application/actions';
import {
    createMemberFailure, createMemberSuccess, fetchMembers, fetchMembersFailure, fetchMembersSuccess, setCreateMemberFormStep,
} from 'redux/members/actions';
import { CREATE_MEMBER, FETCH_MEMBERS } from 'redux/members/actions.types';
import { membersListsPerLocationStoreKeyName, membersReducerName } from 'redux/members/reducer';
import { unblockNavigation } from 'redux/navigation/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const onFetchMembers = (action$, state$, { members }) => action$.pipe(
    ofType(FETCH_MEMBERS),
    switchMap(({ payload }) => from(members.getMembers(undefined, { ...payload?.queryParams })).pipe(
        switchMap((response) => of(fetchMembersSuccess(
            response.data?.rows,
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchMembersFailure(payload.locationPathname))),
    )),
);

export const onCreateMember = (action$, state$, { members, i18n }) => action$.pipe(
    ofType(CREATE_MEMBER),
    switchMap(({
        payload: {
            requestPayload: {
                isClientContext,
                ...restPayload
            },
        },
    }) => from(members.createMember(restPayload)).pipe(
        switchMap((response) => {
            const commonActions = [
                createMemberSuccess(response),
                setCreateMemberFormStep(state$.value.members.createMemberFormStep + 1),
                unblockNavigation(),
                showSuccessToast(i18n.t('member:actionMessages.createMemberSuccess')),
            ];
            const membersListPathname = isClientContext
                ? createClientDetailsMembersListPath(restPayload?.clientId)
                : membersRootRoutePath;

            const currentMemberListQueryParams = state$.value[membersReducerName][membersListsPerLocationStoreKeyName]?.[membersListPathname]?.queryParams;
            if (currentMemberListQueryParams) {
                return of(
                    ...commonActions,
                    fetchMembers(currentMemberListQueryParams, membersListPathname),
                );
            }

            return of(...commonActions);
        }),
        catchError((error) => of(createMemberFailure(error))),
    )),
);


export default [
    onFetchMembers,
    onCreateMember,
];
