import { decorateValue, emptyValue, formatValue, StandardValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';

import React from 'react';


import Styled from './StandardValue.styled';
import { StandardValueProps } from './StandardValue.types';


function StandardValue({
    value,
    searchQuery,
    valueType = StandardValueTypes.TEXT,
    dateFormat,
    timeFormat,
    showTime = false,
    showTimeInTooltip = true,
    disableTextEllipsis = false,
    showCopyToClipboard = true,
    showTooltip,
    useTooltipRootContainer = false,
    userPreferences,
    t,
    decimalPrecision = 2,
    className,
}: StandardValueProps) {
    const isTextEllipsisDisabled = disableTextEllipsis || valueType === StandardValueTypes.AMOUNT;
    const formattedValue = formatValue({ userPreferences, valueType, value, dateFormat, showTime, timeFormat, t, decimalPrecision });
    const isAmount = valueType === StandardValueTypes.AMOUNT;
    const isEmpty = formattedValue === emptyValue;

    const decoratedValue = decorateValue({
        userPreferences,
        timeFormat,
        dateFormat,
        valueType,
        value,
        formattedValue,
        searchQuery,
        showCopyToClipboard: valueType === StandardValueTypes.BOOLEAN ? false : showCopyToClipboard,
        isTextEllipsisDisabled,
        isEmpty,
        showTimeInTooltip,
        isAmount,
        showTooltip: valueType === StandardValueTypes.BOOLEAN ? false : showTooltip,
        useTooltipRootContainer,
        t,
    });

    return (
        <Styled.StandardValueWrapper
            $enableTextEllipsis={!isTextEllipsisDisabled}
            $isAmount={isAmount}
            className={className}
        >
            {decoratedValue}
        </Styled.StandardValueWrapper>
    );
}

export default StandardValue;
