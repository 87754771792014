import PropTypes from 'prop-types';


export const ApplicationScopes = Object.freeze({
    MOBILE: 'mobile',
    BACKOFFICE_FRONTEND: 'backoffice',
    SINGLE_API: 'single_api',
});


export const ApplicationsRolesTemplate = {
    WL: 'WL_ADMIN',
    RO: 'Read Only User',
    MEMBER: 'Member',
    API_USER: 'Api User',
};

const ApplicationsCommonProps = {
    id: PropTypes.number,
    name: PropTypes.string,
    scope: PropTypes.oneOf(Object.values(ApplicationScopes)),
    client_id: PropTypes.number,
    created_at: PropTypes.string,
    uuid: PropTypes.string,
};

export const ApplicationProps = PropTypes.shape({ ...ApplicationsCommonProps });
export const ApplicationsListProp = PropTypes.arrayOf(ApplicationProps);

export function getDefaultApplicationRoleName(applicationsRolesTemplateToMatchAgainst) {
    return `Default ${applicationsRolesTemplateToMatchAgainst} application`;
}
