import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const ContentIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  
  & > span {
    display: flex;
  }
`;

const TooltipContent = styled.div`
  white-space: nowrap;
`;

const ConfigJson = styled.pre`
  text-align: left;
`;

const InfoIcon = styled(WarningOutlined)`
  color: orange;
  cursor: help;
  font-size: 25px;
`;


export default {
    ContentIndicatorWrapper,
    TooltipContent,
    ConfigJson,
    InfoIcon,
};

