import { standardPageSize } from 'config/config';
import { clientRootRoutePath, webhooksFailedRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import { PREFORM_NAVIGATION_SUCCESS } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    CLEAR_FAILED_WEBHOOKS_LIST,
    FETCH_FAILED_WEBHOOKS_LIST,
    FETCH_FAILED_WEBHOOKS_LIST_FAILURE,
    FETCH_FAILED_WEBHOOKS_LIST_SUCCESS,
    REPLAY_ALL_FAILED_WEBHOOKS,
    REPLAY_ALL_FAILED_WEBHOOKS_FAILURE,
    REPLAY_ALL_FAILED_WEBHOOKS_SUCCESS,
} from './actions.types';


export const failedWebhooksReducerName = 'failedWebhooks';
export const failedWebhooksListPerLocationStoreKeyName = 'failedWebhooksListsPerLocation';


export const defaultFailedWebhooksListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'created_at',
    sortOrder: SortDirections.ASCEND,
    clientId: undefined,
    ...withTotalCount,
};


// field webhooks & replay webhook(s)
const initialFailedWebhooksState = {
    [failedWebhooksListPerLocationStoreKeyName]: {},

    isLoadingReplayFailedWebhook: false,
    isLoadingReplayAllFailedWebhooks: false,
};

const initialState = { ...initialFailedWebhooksState };

const handlers = {
    // field webhooks & replay
    [FETCH_FAILED_WEBHOOKS_LIST]: (state, action) => {
        state[failedWebhooksListPerLocationStoreKeyName] = updateStandardLists(state[failedWebhooksListPerLocationStoreKeyName], action);
    },
    [FETCH_FAILED_WEBHOOKS_LIST_SUCCESS]: (state, action) => {
        state[failedWebhooksListPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[failedWebhooksListPerLocationStoreKeyName], action);
    },
    [FETCH_FAILED_WEBHOOKS_LIST_FAILURE]: (state, action) => {
        state[failedWebhooksListPerLocationStoreKeyName] = updateStandardListsOnFailure(state[failedWebhooksListPerLocationStoreKeyName], action);
    },
    [CLEAR_FAILED_WEBHOOKS_LIST]: (state, action) => {
        state[failedWebhooksListPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[failedWebhooksListPerLocationStoreKeyName], action.payload?.locationPathname);
    },

    [REPLAY_ALL_FAILED_WEBHOOKS]: (state) => {
        state.isLoadingReplayAllFailedWebhooks = true;
    },
    [REPLAY_ALL_FAILED_WEBHOOKS_SUCCESS]: (state) => {
        state.isLoadingReplayAllFailedWebhooks = false;
    },
    [REPLAY_ALL_FAILED_WEBHOOKS_FAILURE]: (state) => {
        state.isLoadingReplayAllFailedWebhooks = false;
    },


    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === failedWebhooksReducerName) {
            if (action.payload?.fieldName === failedWebhooksListPerLocationStoreKeyName) {
                state[failedWebhooksListPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[failedWebhooksListPerLocationStoreKeyName] }, action);
            }
        }
    },
    [PREFORM_NAVIGATION_SUCCESS]: (state, action) => {
        state[failedWebhooksListPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[failedWebhooksListPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [webhooksFailedRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },


    [CLEAR_CURRENT_USER]: () => initialState,
};

export default createReducer(initialState, handlers);
