import { getProperGetRiskScoreHistoryEndpoint } from 'redux/risk-score-history/epic.helpers';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { fetchRiskScoreHistoryListFailure, fetchRiskScoreHistoryListSuccess } from './actions';
import { FETCH_RISK_SCORE_HISTORY_LIST } from './actions.types';


export const onFetchRiskScoreHistoryList = (action$, state$, { clients, companies }) => action$.pipe(
    ofType(FETCH_RISK_SCORE_HISTORY_LIST),
    switchMap(({ payload }) => from(getProperGetRiskScoreHistoryEndpoint({ clients, companies, payload })).pipe(
        switchMap((response) => of(fetchRiskScoreHistoryListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchRiskScoreHistoryListFailure(payload.locationPathname))),
    )),
);

export default [
    onFetchRiskScoreHistoryList,
];
