import RiskRatingTag from 'components/common/atoms/tags/RiskRatingTag';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { CountryIdType } from 'constants/CountriesModel';
import { KycKybEntityTypes } from 'constants/KycKybModel';
import { RiskCriteriaName } from 'constants/RiskAssessmentModel';
import { enumValueToReadableString } from 'utils/string-tools';
import { sortingOptions } from 'utils/table-columns-sorters';

import React from 'react';


const createTableColumns = ({ t, entityType, queryParams }) => {
    const assessedValueTitle = { [KycKybEntityTypes.MEMBER]: t('member:memberDetails.tabs.compliance.fields.assessedValue') };
    return [
        {
            title: t('common:table.columnNames.id'),
            dataIndex: 'id',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({ queryParams, fieldName: 'id' }),
        },

        {
            title: t('common:table.columnNames.criteriaName'),
            dataIndex: 'criteria_name',
            render: (text) => <StandardValue value={enumValueToReadableString(text)} />,
        },

        {
            title: assessedValueTitle[entityType],
            dataIndex: 'assessed_value',
            render: (text, record) => {
                if ([RiskCriteriaName.NATIONALITY, RiskCriteriaName.RESIDENCY]
                    .includes(record?.criteria_name)) return <CountryRenderer value={text} propertyName={CountryIdType.iso_31663} />;

                return <StandardValue value={enumValueToReadableString(text)} />;
            },
        },

        {
            title: t('common:table.columnNames.answerScore'),
            dataIndex: 'calculated_risk_score',
            align: 'center',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.COUNTER} />,
        },

        {
            title: t('common:table.columnNames.answerRating'),
            dataIndex: 'calculated_risk_rating',
            align: 'center',
            render: (text) => <RiskRatingTag riskRating={text} />,
            ...sortingOptions({ queryParams, fieldName: 'calculated_risk_rating' }),
        },

        {
            title: t('common:table.columnNames.date'),
            align: 'center',
            render: (_, record) => <StandardValue value={record?.updated_at ? record?.updated_at : record?.created_at} valueType={ValueTypes.DATE} />,
        },
    ];
};
export default createTableColumns;
