import { TabContentWrapper } from 'components/layouts/AuthorisedLayout/StandardPageWrapper/StandardPageWrapper.styled';
import { tabKeySubTabKeySeparator } from 'constants/ApplicationStateModel';
import { LocationChangeActionScopeTypes } from 'constants/NavigationModel';

import React from 'react';


import Styled from './StandardTabsConnectedToUrl.styled';


export const tabHasSubTabs = (tabConfig = {}) => tabConfig?.subTabsConfig?.length > 0;

export const splitActiveTabKey = (tabKey) => {
    if (tabKey) {
        return tabKey.split(tabKeySubTabKeySeparator);
    }
    return undefined;
};

export const getTabFormTabConfig = ({ tabsConfig = [], activeTabSegment }) => {
    if (activeTabSegment) {
        return tabsConfig.find((tabConfig) => tabConfig?.activeMainTabSegmentURL === activeTabSegment);
    }
    const defaultTab = tabsConfig.find((tabConfig) => tabConfig?.isDefault);

    return defaultTab || tabsConfig?.[0] || undefined;
};


export const getSubTabFormTabConfig = ({ tabConfig = {}, activeTabSegment }) => {
    if (tabHasSubTabs(tabConfig)) {
        if (activeTabSegment) {
            return tabConfig.subTabsConfig.find((subTabConfig) => subTabConfig?.activeSubTabSegmentURL === activeTabSegment);
        }
        const defaultTab = tabConfig.subTabsConfig.find((subTabConfig) => subTabConfig?.isDefault);

        return defaultTab || tabConfig.subTabsConfig?.[0] || undefined;
    }

    return undefined;
};

export const createTabKey = ({ tabConfig, index }) => {
    if (tabConfig?.parentActiveMainTabSegmentURL && tabConfig?.activeSubTabSegmentURL) {
        return `${tabConfig.parentActiveMainTabSegmentURL}${tabKeySubTabKeySeparator}${tabConfig.activeSubTabSegmentURL}`;
    }

    if (tabConfig?.activeMainTabSegmentURL) {
        return tabConfig.activeMainTabSegmentURL;
    }

    // XXX no connection to URL
    return `warring-tab-key-${index}`;
};


export const createTabKeyFromParams = (params = {}) => {
    const tmp = `${params?.activeMainTabSegmentURL || ''}${params?.activeSubTabSegmentURL ? `${tabKeySubTabKeySeparator}${params?.activeSubTabSegmentURL}` : ''}`;
    return tmp?.length > 0 ? tmp : undefined;
};

export const extractActiveTabKeysFromParams = (enhancedCurrentLocation = {}, baseURLSegmentsCount = 2) => {
    const tmp = enhancedCurrentLocation?.pathname?.split('/')?.slice(baseURLSegmentsCount + 1);
    return tmp?.length > 0 ? tmp : undefined;
};

export const extractBaseURLWithoutActiveTab = (locationPathname = '', baseURLSegmentsCount = 2) => locationPathname.split('/')?.slice(0, baseURLSegmentsCount + 1).join('/');


export const extractSegmentsFromPathname = (enhancedCurrentLocation = {}) => enhancedCurrentLocation?.pathname?.split('/')?.slice(1);

export const updateNavigationPath = ({
    baseURLSegmentsCount,
    enhancedCurrentLocation,
    requestedTabKeyParts = [],
}) => {
    const newTabUrlSlugs = requestedTabKeyParts.join('/');
    const currentPathSegments = enhancedCurrentLocation?.pathname?.split('/')?.slice(1);


    // XXX root details' path eg `/member/1` -> just add new ones
    if (baseURLSegmentsCount === currentPathSegments?.length) {
        return `${enhancedCurrentLocation?.pathname}/${newTabUrlSlugs}`;
    }
    // active tab(s) URL slugs must exist -> trim to base path and add new ones
    if (currentPathSegments?.length > baseURLSegmentsCount) {
        const diff = currentPathSegments.length - baseURLSegmentsCount;
        const tmp = `${enhancedCurrentLocation?.pathname?.split('/').slice(0, -1 * diff).join('/')}/${newTabUrlSlugs}`;
        return tmp;
    }
    // Fallback
    return enhancedCurrentLocation?.pathname;
};


export const onTabCLickHandler = ({
    currentTabKey,

    tabsConfig,
    baseURLSegmentsCount,
    enhancedCurrentLocation,
    dispatchRequestNavigation,
}) => {
    const requestedTabKeyParts = splitActiveTabKey(currentTabKey);
    const mainTab = getTabFormTabConfig({ tabsConfig, activeTabSegment: requestedTabKeyParts?.[0] });

    const subTab = !mainTab ? getTabFormTabConfig({ tabsConfig, activeTabSegment: requestedTabKeyParts?.[1] }) : getSubTabFormTabConfig({ tabConfig: mainTab });
    const correctedRequestedTabKeyParts = requestedTabKeyParts?.length === 1 && subTab
        ? [requestedTabKeyParts?.[0], subTab?.activeSubTabSegmentURL]
        : requestedTabKeyParts;

    const newPath = updateNavigationPath({
        enhancedCurrentLocation,
        requestedTabKeyParts: correctedRequestedTabKeyParts,
        baseURLSegmentsCount,
    });

    dispatchRequestNavigation(newPath, {
        state: {
            ...enhancedCurrentLocation?.state,
            type: LocationChangeActionScopeTypes.TAB_CHANGE,
            activeMainTab: requestedTabKeyParts?.[0],
            activeSubTab: requestedTabKeyParts?.[1],
        },
    });
};


// TODO refactor to items config, this old tabs config style becomes deprecated in antd@4.23.2
export const renderTabContentOrSubTabs = ({
    tabConfig,
    index,
    activeSubKey,
    params,
    dispatchSetActiveTab,
    enhancedCurrentLocation,
    dispatchRequestNavigation,
    destroyInactiveTabPane,
}) => {
    // XXX very important
    const tabKey = createTabKey({ tabConfig, index });

    const tabLabel = (
        <span>
            {tabConfig.icon}
            {tabConfig.label}
        </span>
    );

    return {
        key: tabKey,
        label: tabLabel,
        children: tabConfig?.subTabsConfig?.length > 0
            ? (
                <Styled.StandardTabs
                    activeTab={activeSubKey}
                    params={params}
                    tabsConfig={tabConfig?.subTabsConfig}
                    dispatchSetActiveTab={dispatchSetActiveTab}
                    dispatchRequestNavigation={dispatchRequestNavigation}
                    enhancedCurrentLocation={enhancedCurrentLocation}
                    destroyInactiveTabPane={destroyInactiveTabPane}
                />
            )
            : (
                <TabContentWrapper>
                    {tabConfig.content}
                </TabContentWrapper>
            ),
    };
};

export default {
    createTabKey,
    splitActiveTabKey,
    tabHasSubTabs,
    getSubTabFormTabConfig,
    updateNavigationPath,
    onTabCLickHandler,
    renderTabContentOrSubTabs,
    extractSegmentsFromPathname,
};

