import styled from 'styled-components';


export const StandardTwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  align-items: center;
  grid-column-gap: 20px;
  height: 30px;
`;

export const StandardTwoColumnsLabel = styled.div`
  font-weight: 700;
  align-items: center;
  min-height: 28px;
  display: flex;
`;

export const StandardTwoColumnsValueWrapper = styled.div`
  min-height: 28px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .ant-btn-link {
    height: 28px;
  }
`;

export default {
    StandardTwoColumns,
    StandardTwoColumnsLabel,
    StandardTwoColumnsValueWrapper,
};
