import { AllEntityTypes, ReasonsForEntityIsReadOnly } from 'constants/ApplicationStateModel';
import { PermanentCardStatusesList } from 'constants/CardModel';
import { CompanyStatuses } from 'constants/CompaniesModel';
import { kycKybApproval } from 'constants/KycKybModel';
import { SharedOffboardingStatuses } from 'constants/StatusModel';
import { clientContextReducerName } from 'redux/client-context/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';

import { ReadOnlyMemberStatus } from '@manigo/manigo-domain-typings';
import dotObject from 'dot-object';
import React from 'react';
import { connect } from 'react-redux';


const mapPermissionNameToObjectPath = (permissionName: string) => permissionName.replace(/:/g, '.');


function isEntityReadOnly({
    entityType,
    entityStatus,
    parentEntityIsReadOnly = false,
}) {
    const handlers = [
        {
            predicate: () => entityType === AllEntityTypes.CARD && PermanentCardStatusesList.includes(entityStatus),
            handler: () => ReasonsForEntityIsReadOnly.CARD_PERMANENT_STATUS,
        },
        {
            predicate: () => entityStatus === SharedOffboardingStatuses.PENDING_CLOSURE,
            handler: () => ReasonsForEntityIsReadOnly.ENTITY_IS_OFFBOARDING,
        },
        {
            predicate: () => entityStatus && entityStatus === SharedOffboardingStatuses.CLOSED,
            handler: () => ReasonsForEntityIsReadOnly.ENTITY_IS_OFFBOARDED,
        },
        {
            predicate: () => entityType === AllEntityTypes.COMPANY
        && [CompanyStatuses.INVITED,
            CompanyStatuses.UNVERIFIED,
            CompanyStatuses.KYB_PENDING,
            CompanyStatuses.KYB_UPLOADED,
            CompanyStatuses.KYB_MANUAL_REVIEW,
            CompanyStatuses.KYB_REJECTED,
            CompanyStatuses.SUSPENDED,
            CompanyStatuses.BANNED,
            SharedOffboardingStatuses.PENDING_CLOSURE,
            SharedOffboardingStatuses.CLOSED,
        ].includes(entityStatus),
            handler: () => ReasonsForEntityIsReadOnly.COMPANY_NOT_ACTIVE,
        },
        {
            predicate: () => entityType === AllEntityTypes.BUSINESS_USER && parentEntityIsReadOnly,
            handler: () => ReasonsForEntityIsReadOnly.PARENT_ENTITY_IS_READ_ONLY,
        },
        {
            predicate: () => entityType === AllEntityTypes.MEMBER && Object.values(ReadOnlyMemberStatus).includes(entityStatus),
            handler: () => ReasonsForEntityIsReadOnly.MEMBER_IS_READ_ONLY_STATUS,
        },
        {
            predicate: () => true, // default
            handler: () => false, // isEntityReadOnly -> false
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

function createAccessControlObject(state) {
    const isSuperAdmin = state[currentUserReducerName]?.isSuperAdmin;
    const permissions = state[currentUserReducerName].permissions || { };

    // XXX client context is connected & consumed here! (SuperAdmins only)
    // if applicable then `accessControl.clientConfig.${setting_name}`-based conditions refer to
    // currently viewed client and his companies, members... whatever has clientId context
    // otherwise clientConfig of currently logged-in user will be used. This is default.
    const { clientsConfigs, clientIdContext } = state[clientContextReducerName];

    const clientConfig = isSuperAdmin && clientIdContext && clientsConfigs[clientIdContext]
        ? clientsConfigs[clientIdContext].configuration
        : state[currentUserReducerName].clientConfig;


    return {
        accessControl: {
            isAuthorised: !!state[currentUserReducerName].jwtToken,
            isSuperAdmin,
            clientConfig,

            hasPermission: (permissionName = '') => {
                const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                const tmp = dotObject.pick(permissionNameAsPath, permissions);
                return tmp === true;
            },
            hasPermissions: (permissionNames = []) => {
                return permissionNames.every((permissionName = '') => {
                    const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                    const tmp = dotObject.pick(permissionNameAsPath, permissions);
                    return tmp === true;
                });
            },
            hasOneOfPermissions: (permissionNames = []) => {
                return permissionNames.some((permissionName = '') => {
                    const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                    const tmp = dotObject.pick(permissionNameAsPath, permissions);
                    return tmp === true;
                });
            },

            isKycKybApprovalEnabled: () => clientConfig?.generalSettings?.kyckybApproval === kycKybApproval.MANIGO,

            isEntityReadOnly,
        },
    };
}

export function accessControlForEpics(state) {
    const { accessControl } = createAccessControlObject(state);
    return accessControl;
}

export default function withAccessControl(WrappedComponent) {
    function AccessControlInjectorComponent(props) {
        return <WrappedComponent {...props} />;
    }

    const mapStateToProps = (state) => createAccessControlObject(state);

    return connect(mapStateToProps)(AccessControlInjectorComponent);
}
