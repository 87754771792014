import { ApplicationScopes, ApplicationsRolesTemplate, getDefaultApplicationRoleName } from 'constants/ApplicationsModel';


export function findProperApplicationByScope(applications = [], applicationScopeToMatchAgainst = ApplicationScopes.BACKOFFICE_FRONTEND) {
    return applications.find((application) => application.scope === applicationScopeToMatchAgainst);
}


export function findProperApplicationByName(applications = [], applicationsRolesTemplateToMatchAgainst = ApplicationsRolesTemplate.API_USER) {
    return applications.find((application) => application.name.includes(getDefaultApplicationRoleName(applicationsRolesTemplateToMatchAgainst)));
}
