import { accountsRouteSegmentPath } from 'config/routes';
import { createClientDetailsPath, createCompanyDetailsPath, createMemberDetailsPath } from 'config/routes.helpers';
import { fetchAccountsList } from 'redux/accounts/actions';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'redux/accounts/reducer';

import { AccountDetails } from '@manigo/manigo-domain-typings';
import { Action } from '@reduxjs/toolkit';


const createOwnerAccountListPaths = (accountDetails: AccountDetails) => {
    const handlers = [
        {
            predicate: () => accountDetails.memberId > 0,
            handler: () => `${createMemberDetailsPath(accountDetails.memberId)}/${accountsRouteSegmentPath}`,
        },
        {
            predicate: () => accountDetails.companyId > 0,
            handler: () => `${createCompanyDetailsPath(accountDetails.companyId)}/${accountsRouteSegmentPath}`,
        },
        {
            predicate: () => true,
            handler: () => `${createClientDetailsPath(accountDetails.clientId)}/${accountsRouteSegmentPath}`,
        },
    ];

    return handlers.filter(({ predicate }) => predicate()).map(((item) => item.handler()));
};


export const createAccountsListsUpdatesActions = ({ accountDetails, state$ }) => {
    const ownerAccountListPaths = createOwnerAccountListPaths(accountDetails);

    if (ownerAccountListPaths?.length > 0) {
        const accountsListUpdatesActions = ownerAccountListPaths.reduce((acc: Action[], path: string) => {
            const currentAccountListQueryParams = state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[path]?.queryParams;

            if (currentAccountListQueryParams) {
                return [
                    ...acc,
                    fetchAccountsList({ ...currentAccountListQueryParams }, path),
                ];
            }
            return acc;
        }, []);

        return accountsListUpdatesActions;
    }

    return [];
};


export default { createAccountsListsUpdatesActions };
