import { usersRootRoutePath } from 'config/routes';
import { fetchBusinessUserDetails } from 'redux/business-user/actions';
import { fetchMemberDetails } from 'redux/member/actions';
import { fetchUserDetails } from 'redux/user/actions';
import { fetchUsers } from 'redux/users/actions';
import { usersListsPerLocationStoreKeyName, usersReducerName } from 'redux/users/reducer';


export function createRefreshActions({ context, state$ }) {
    const handlers = [
        {
            predicate: () => context?.memberId > 0,
            handler: () => [fetchMemberDetails({ memberId: context?.memberId }, context?.locationPathname)],
        },
        {
            predicate: () => context?.userId > 0,
            handler: () => [
                fetchUserDetails({ userId: context?.userId }, context?.locationPathname),

                ...(state$.value[usersReducerName][usersListsPerLocationStoreKeyName]?.[usersRootRoutePath]?.queryParams
                    ? [
                        fetchUsers(
                            state$.value[usersReducerName][usersListsPerLocationStoreKeyName]?.[usersRootRoutePath]?.queryParams,
                            usersRootRoutePath,
                        ),
                    ] : []),
            ],
        },
        {
            predicate: () => context?.businessUserId > 0 && context?.locationPathname,
            handler: () => [
                fetchBusinessUserDetails({ businessUserId: context?.businessUserId }, context?.locationPathname),
            ],
        },
        {
            predicate: () => true,
            handler: () => [],
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export default { createRefreshActions };
