import RiskAssessmentModel from 'constants/RiskAssessmentModel';

import React from 'react';

import RiskRatingTag from './RiskRatingTag';


export function MemberRiskTag({ riskRating }) {
    return (
        <RiskRatingTag riskRating={riskRating}>
            {riskRating}
        </RiskRatingTag>
    );
}

MemberRiskTag.propTypes = { riskRating: RiskAssessmentModel.calculated_risk_rating };


export default MemberRiskTag;

