import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { removeUnderscores } from 'utils/string-tools';

import { TransactionDetails } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React from 'react';


export const createTransactionDescriptionFields = (transactionDetails: TransactionDetails, t: TFunction) => [
    {
        value: <StandardValue value={transactionDetails.systemDescription} />,
        label: t('transactions:transactionFields.systemDescription.text'),
    },

    {
        value: <StandardValue value={transactionDetails.customDescription} />,
        label: t('transactions:transactionFields.customDescription.text'),
    },

    {
        value: <StandardValue value={transactionDetails.reference} />,
        label: t('transactions:transactionFields.reference.text'),
    },

    {
        value: <StandardValue value={removeUnderscores(transactionDetails.reason)} />,
        label: t('transactions:transactionFields.reason.text'),
    },

    {
        value: <StandardValue value={transactionDetails.transactionReference} />,
        label: t('transactions:transactionFields.transactionReference.text'),
    },

    {
        value: <StandardValue value={removeUnderscores((transactionDetails.transactionDeclineReasons).join(', '))} />,
        label: t('transactions:transactionFields.transactionDeclineReasons.text'),
    },

    {
        value: <StandardValue value={removeUnderscores((transactionDetails.transactionInternalComments).join(', '))} />,
        label: t('transactions:transactionFields.transactionInternalComments.text'),
    },
];


export default { createTransactionDescriptionFields };
