import StatusChangeActor from 'components/common/atoms/StatusChangeActor';
import CardStatusTag from 'components/common/atoms/tags/CardStatusTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { sortingOptions } from 'utils/table-columns-sorters';

import React from 'react';


function createCardStatusColumns({
    t,
    queryParams,
}) {
    return [
        {
            title: t('common:table.columnNames.id'),
            dataIndex: 'id',
            defaultSortOrder: 'ascend',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({ queryParams, fieldName: 'id' }),
        },
        {
            title: t('common:table.columnNames.status'),
            dataIndex: 'card_status',
            align: 'center',
            render: (cardStatus) => <CardStatusTag cardStatus={cardStatus} t={t} showTooltip />,
            ...sortingOptions({ queryParams, fieldName: 'card_status' }),
        },
        {
            title: t('common:table.columnNames.channel'),
            dataIndex: 'channel',
            render: (channel) => <StandardValue value={channel} />,
            ...sortingOptions({ queryParams, fieldName: 'channel' }),
        },
        {
            title: t('common:table.columnNames.comment'),
            dataIndex: 'internal_comment',
            render: (cardStatusComment) => <StandardValue value={cardStatusComment} />,
        },
        {
            title: t('common:table.columnNames.actor'),
            align: 'center',
            render: (_, record) => (
                <StatusChangeActor
                    channel={record?.channel}
                    api_user_id={record?.api_user_id}
                    bo_user_id={record?.bo_user_id}
                    business_user_id={record?.business_user_id}
                    company_id={record?.company_id}
                    member_id={record?.member_id}
                />
            ),
        },
        {
            title: t('common:table.columnNames.updatedAt'),
            dataIndex: 'updated_at',
            align: 'center',
            render: (date) => <StandardValue value={date} valueType="DATE" showTime />,
        },
    ];
}

export default createCardStatusColumns;
