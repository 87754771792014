import {
    createClientDetailsFailedWebhooksListPath,
    createFailedWebhookDetailsErrorsHistoryPath,
    createFailedWebhookDetailsPath,
} from 'config/routes.helpers';
import { fetchFailedWebhookErrorsList } from 'redux/failed-webhook-history/actions';
import {
    failedWebhookErrorsListPerLocationStoreKeyName,
    failedWebhookErrorsListReducerName,
} from 'redux/failed-webhook-history/reducer';
import { fetchFailedWebhooksList } from 'redux/failed-webhooks/actions';
import { failedWebhooksListPerLocationStoreKeyName, failedWebhooksReducerName } from 'redux/failed-webhooks/reducer';

import { fetchFailedWebhookDetails } from './actions';


export const createOnReplayFailedWebhookRefreshActions = (payload, state$) => {
    const detailsPath = createFailedWebhookDetailsPath(payload?.queryParams.webhookUuid);
    const failedWebhooksListPath = payload?.queryParams?.clientId ? createClientDetailsFailedWebhooksListPath(payload?.queryParams?.clientId) : '';
    const failedWebhookHistoryListPath = createFailedWebhookDetailsErrorsHistoryPath(payload?.queryParams.webhookUuid);

    const isDetailsPageLocation = payload.locationPathname.includes(detailsPath);
    const currentFailedWebhookListQP = state$.value[failedWebhooksReducerName][failedWebhooksListPerLocationStoreKeyName]?.[failedWebhooksListPath]?.queryParams;
    // eslint-disable-next-line max-len
    const currentFailedWebhookErrorsListQP = state$.value[failedWebhookErrorsListReducerName][failedWebhookErrorsListPerLocationStoreKeyName]?.[failedWebhookHistoryListPath]?.queryParams;

    return [
        ...(currentFailedWebhookListQP ? [fetchFailedWebhooksList({ ...currentFailedWebhookListQP }, failedWebhooksListPath)] : []),
        ...(currentFailedWebhookErrorsListQP ? [fetchFailedWebhookErrorsList({ ...currentFailedWebhookErrorsListQP }, failedWebhookHistoryListPath)] : []),
        ...(isDetailsPageLocation ? [fetchFailedWebhookDetails({ failedWebhookUuid: payload?.queryParams?.webhookUuid }, detailsPath)] : []),
    ];
};


export default { createOnReplayFailedWebhookRefreshActions };
