import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import Styled from './AppLoadingPlaceholder.styled';


export const renderLoadingInfoItem = (isLoaded, itemLabel) => (
    <Styled.LoadingProgressInfo $isLoaded={isLoaded}>
        <Styled.Label>{itemLabel}</Styled.Label>
        <Styled.LoadedLabelWrapper>
            {isLoaded ? <CheckOutlined /> : <LoadingOutlined />}
        </Styled.LoadedLabelWrapper>

    </Styled.LoadingProgressInfo>
);

export const renderLoadingDetails = ({
    t,
    accessControl,

    isCurrenciesLoaded,
    isCountriesLoaded,
    isIndustriesLoaded,
    isTransactionsFiltersLoaded,
    isClientsListLoaded,
    isRefreshingSession,

    shouldFetchGlobalData,
}) => accessControl.isAuthorised && (
    <Styled.LoadingProgressInfoWrapper>
        {isRefreshingSession && !shouldFetchGlobalData && renderLoadingInfoItem(!isRefreshingSession, 'Refreshing session')}
        {!isRefreshingSession && !shouldFetchGlobalData && (
            <>
                {renderLoadingInfoItem(isCurrenciesLoaded, t('Currencies list'))}
                {renderLoadingInfoItem(isCountriesLoaded, t('Countries list'))}
                {renderLoadingInfoItem(isIndustriesLoaded, t('Industries list'))}
                {renderLoadingInfoItem(isClientsListLoaded, t('Partner data'))}
                {renderLoadingInfoItem(isTransactionsFiltersLoaded, t('Transactions filters'))}
            </>
        )}
    </Styled.LoadingProgressInfoWrapper>
);

export const renderRestartApplication = ({
    t,
    shouldFetchGlobalData,
    dispatchReFetchGlobalData,
}) => (
    shouldFetchGlobalData && (
        <Styled.RestartApplicationInfoWrapper>
            <div>
                {t('Application is not available at the moment.')}
            </div>
            <Button type="primary" onClick={dispatchReFetchGlobalData}>
                {t('Restart application')}
            </Button>
        </Styled.RestartApplicationInfoWrapper>
    )
);

