import { globalLocation } from 'constants/NavigationModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCountriesList, fetchCountriesList } from 'redux/countries/actions';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CountriesList from './CountriesList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchCountriesList: (queryParams) => dispatch(fetchCountriesList(queryParams)),
    dispatchClearCountriesList: () => dispatch(clearCountriesList()),
    dispatchSetQueryParams: (queryParams) => dispatch(setQueryParams({
        reducerName: countriesReducerName,
        fieldName: countriesListsPerLocationStoreKeyName,
        locationPathname: globalLocation,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CountriesList);
