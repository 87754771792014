import withAccessControl from 'hocs/withAccessControl';
import { showUnlinkCardModal } from 'redux/card/actions';
import { showModal } from 'redux/modal/actions';
import { requestNavigation } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import MemberCards from './MemberCards.layout';
import { MemberCardsDispatchProps, MemberCardsOwnProps } from './MemberCards.types';


const mapDispatchToProps = (dispatch): MemberCardsDispatchProps => ({
    dispatchShowModal: (data) => dispatch(showModal(data)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchShowUnlinkCardModal: (cardRecord, meta) => dispatch(showUnlinkCardModal(cardRecord, meta)),
});

const decorate = compose<ComponentType<MemberCardsOwnProps>>(
    withTranslation('cards'),
    withAccessControl,
    connect(null, mapDispatchToProps),
);

export default decorate(MemberCards);
