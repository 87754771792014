import CountryOptionContent from 'components/common/molecules/custom-dropdowns/CountryOptionContent';
import CurrencyOptionContent from 'components/common/molecules/custom-dropdowns/CurrencyOptionContent';
import { PartnerOption, PartnerOptionLabelWrapper, PartnerOptionLabel, PartnerStatus } from 'components/common/molecules/form-controls/ClientSelect/ClientSelect.styled';
import { EEA } from 'config/config';
import { AccountTypes } from 'constants/AccountModel';
import { BeneficiaryType } from 'constants/BeneficiariesModel';
import { CardChallengeManagers, CardChallengeMethods } from 'constants/CardModel';
import { ClientStatuses } from 'constants/ClientModel';
import { CompanyStatuses, CompanyType, StakeholderPosition, StakeholderType } from 'constants/CompaniesModel';
import { CountryIdType } from 'constants/CountriesModel';
import { EnabledAcquirerOptions } from 'constants/EnabledAcquirer';
import { MemberStatuses } from 'constants/MemberModel';
import MigrationReportTypes from 'constants/MigrationReportTypes';
import { ProductTypes } from 'constants/ProductModel';
import { UserPreferencesDateFormat, UserPreferencesNumbersFormat, UserPreferencesTimeFormat, UserStatuses } from 'constants/UserModel';
import { PromotionTriggerEntity, PromotionType } from 'constants/promotions';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Select, Tag } from 'antd';
import { sortBy } from 'lodash';
import React from 'react';

import { capitalizeFirstLetter, enumValueToReadableString, removeUnderscores } from './string-tools';
import { BusinessUserRole } from '../constants/BusinessUserModel';


export const createCountriesOptions = (countries, prop = CountryIdType.iso_31663, filterArray = undefined) => {
    const baseCountries = filterArray
        ? countries.filter((country) => filterArray.includes(country[prop]))
        : countries;

    return sortBy(baseCountries, ['name']).map((country) => (
        <Select.Option
            key={country[prop]}
            value={country[prop]}
            label={country.name}
        >
            <CountryOptionContent country={country} />
        </Select.Option>
    ));
};

export const countriesOfOperationsOptions = (countries, prop = CountryIdType.iso_31663) => {
    // if EEA countries are defined create list off all countries and add EEA option,
    // otherwise use default country Options
    if (EEA?.length) {
        countries = [
            ...countries,
            {
                [prop]: 'EEA',
                name: 'EEA - European Economic Area',
            },
        ];
    }

    return createCountriesOptions(countries, prop);
};

export const createIndustryOptions = (industries) => industries.map((industry) => (
    <Select.Option
        key={`${industry.id} - ${industry.name}`}
        value={industry.id}
        label={`${industry.id} - ${industry.name}`}
    >
        {industry.name}
    </Select.Option>
));

export const createCurrencyOptions = (
    currencies,
    t,
    showBankingDetails = false,
) => {
    const renderCurrencies = (currenciesList) => (
        <>
            {(currenciesList || []).map((currency) => (
                <Select.Option
                    key={currency.short}
                    value={currency.short}
                    label={`${currency.short} - ${currency.full_name}`}
                >
                    <CurrencyOptionContent t={t} currency={currency} showBankingDetails={showBankingDetails} />
                </Select.Option>
            ))}
        </>
    );

    return renderCurrencies(currencies);
};

export const createAccountTypeOptions = (
    accountTypes = [],
    hideTechnical = false,
) => {
    const renderAccountItem = (accountType, index) => (
        <Select.Option
            key={index}
            value={accountType}
            label={accountType}
        >
            {accountType}
        </Select.Option>
    );

    const allowedAccountTypes = hideTechnical ? accountTypes.filter((accountType) => accountType !== AccountTypes.Technical) : accountTypes;
    const accountTypesOptions = allowedAccountTypes.map((accountType, index) => renderAccountItem(accountType, index));

    return accountTypesOptions;
};

export const createProductTypeOptions = () => Object.keys(ProductTypes).map((type) => (
    <Select.Option key={type} value={type}>
        {type}
    </Select.Option>
));

export const createGroupsOptions = (groups = []) => groups.map((group) => (
    <Select.Option
        key={`${group.id}-${group.name}`}
        value={group.id}
        label={group.name}
    >
        {group.name}
    </Select.Option>
));

export const createRolesOptions = (roles = []) => roles.map((role) => (
    <Select.Option
        key={role.id}
        value={role.id}
    >
        {role.display_name}
    </Select.Option>
));

export const createPromotionTypeOptions = () => Object.keys(PromotionType).map((type) => (
    <Select.Option key={type} value={type}>
        {removeUnderscores(type)}
    </Select.Option>
));

export const createPromotionTriggerEntityOptions = () => Object.keys(PromotionTriggerEntity).map((type) => (
    <Select.Option key={type} value={type}>
        {removeUnderscores(type)}
    </Select.Option>
));

export const createPromotionTriggerTypeOptions = (options = []) => options.map((type) => (
    <Select.Option key={type} value={type}>
        {removeUnderscores(type)}
    </Select.Option>
));


export const createFeesOptions = (fees = []) => fees.map((fee) => (
    <Select.Option
        key={fee.ext_id}
        value={fee.ext_id}
        label={fee.name}
    >
        {fee.name}
    </Select.Option>
));

export const createPromotionOptions = (promotions = []) => promotions.map((promotion) => (
    <Select.Option
        key={promotion.ext_id}
        value={promotion.ext_id}
        label={promotion.name}
    >
        {promotion.name}
    </Select.Option>
));

export const createCardChallengeMethodsOptions = (defaultChallengeMethod) => Object.keys(CardChallengeMethods)
    .map((type) => (
        <Select.Option key={type} value={CardChallengeMethods[type]}>
            {`${removeUnderscores(CardChallengeMethods[type])} ${CardChallengeMethods[type] === defaultChallengeMethod ? '(Default client config)' : ''}`}
        </Select.Option>
    ));

export const createCardChallengeManagersOptions = (defaultChallengeManager) => Object.keys(CardChallengeManagers)
    .map((type) => (
        <Select.Option key={type} value={CardChallengeManagers[type]}>
            {`${removeUnderscores(CardChallengeManagers[type])} ${CardChallengeManagers[type] === defaultChallengeManager ? '(Default client config)' : ''}`}
        </Select.Option>
    ));

export const createEnabledAcquirerOptions = () => Object.keys(EnabledAcquirerOptions)
    .map((type) => (
        <Select.Option key={type} value={EnabledAcquirerOptions[type]}>
            {removeUnderscores(EnabledAcquirerOptions[type])}
        </Select.Option>
    ));


export const createUserStatusesOptions = () => Object.values(UserStatuses)
    .map((status) => (
        <Select.Option key={status} value={status}>
            {status}
        </Select.Option>
    ));


export const createClientOptions = (
    clients = [],
    isSuperAdmin = false,
    currentUserClientId = -1,
    allowInactivePartners = false,
    useUuid = false,
) => {
    const filteredClients = clients.filter((client) => (allowInactivePartners
        ? (isSuperAdmin || client.id === currentUserClientId)
        : (isSuperAdmin || client.id === currentUserClientId) && client.active === ClientStatuses.ACTIVE
    ));

    return filteredClients.map((client) => {
        const label = (client.id > 0
            ? `${isSuperAdmin ? `${client.id} - ` : ''}${client.company_dba_name} ${enumValueToReadableString(client.company_business_type)}`
            : '');

        return (
            <PartnerOption
                key={client.id}
                value={useUuid ? client.ext_client_id : client.id}
                label={label}
            >
                <PartnerOptionLabelWrapper>
                    <PartnerOptionLabel>
                        {`${label}`}
                    </PartnerOptionLabel>

                    {client.active === ClientStatuses.INACTIVE
                        ? (
                            <PartnerStatus>
                                <Tag color="orange">{client.active}</Tag>
                            </PartnerStatus>
                        )
                        : null}
                </PartnerOptionLabelWrapper>
            </PartnerOption>
        );
    });
};


export const createMembersOptions = (
    members = [],
) => members.reduce((acc, member) => {
    const label = `${member.member_id} - ${member.full_name}`;
    if (member.status !== MemberStatuses.PENDING_CLOSURE) {
        return [
            ...acc,
            (
                <Select.Option
                    key={member.member_id}
                    value={member.member_id}
                    label={label}
                >
                    {label}
                </Select.Option>
            ),
        ];
    }
    return acc;
}, []);

export const createCompaniesOptions = (
    companies = [],
) => companies.reduce((acc, company) => {
    const label = `${company.company_id} - ${company.company_name}`;
    if (company.status !== CompanyStatuses.PENDING_CLOSURE) {
        return [
            ...acc,
            (
                <Select.Option
                    key={company.company_id}
                    value={company.company_id}
                    label={label}
                >
                    {label}
                </Select.Option>
            ),
        ];
    }
    return acc;
}, []);

export const createBeneficiaryTypeOptions = () => Object.keys(BeneficiaryType).map((type) => (
    <Select.Option key={type} value={type}>
        {capitalizeFirstLetter(type)}
    </Select.Option>
));

export const createIdentificationCategoryOptions = (beneficiariesRequirements = {}) => Object.keys(beneficiariesRequirements).map((category) => (
    <Select.Option key={category} value={category}>
        {category}
    </Select.Option>
));

export const createUserPreferencesNumbersFormatOptions = () => Object.keys(UserPreferencesNumbersFormat).map((formatName) => (
    <Select.Option key={formatName} value={formatName}>
        {UserPreferencesNumbersFormat[formatName]}
    </Select.Option>
));

export const createUserPreferencesDateFormatOptions = () => Object.values(UserPreferencesDateFormat).map((format) => (
    <Select.Option key={format} value={format}>
        {format}
    </Select.Option>
));
export const createUserPreferencesTimeFormatOptions = () => Object.values(UserPreferencesTimeFormat).map((format) => (
    <Select.Option key={format} value={format}>
        {format}
    </Select.Option>
));

export const createMigrationReportTypesOptions = () => Object.keys(MigrationReportTypes).map((item) => {
    const status = MigrationReportTypes[item];
    return (
        <Select.Option key={status} value={status}>
            {capitalizeFirstLetter(status).replace(/_/g, ' ')}
        </Select.Option>
    );
});


export const createKycKybLevelOptions = (list = []) => list.map((level) => (
    <Select.Option key={level} value={level}>
        {level}
    </Select.Option>
));

export const createWebhookActionTypeOptions = (list = []) => list.map((actionType, index) => (
    <Select.Option
        key={`${index}_${actionType}`}
        value={actionType}
    >
        {removeUnderscores(actionType)}
    </Select.Option>
));


export const createCompanyTypesOptions = () => Object.keys(CompanyType).map((type) => (
    <Select.Option key={type} value={type}>
        {enumValueToReadableString(type)}
    </Select.Option>
));

export const createRolesTemplatesOptions = (list = []) => list.map((roleTemplate, index) => (
    <Select.Option
        key={`${index}_${roleTemplate.details.display_name}`}
        value={JSON.stringify(roleTemplate)}
    >
        {roleTemplate.details.name}
    </Select.Option>
));

export const createOperationOptionsOptions = (list = []) => list.map((operation, index) => (
    <Select.Option
        key={`${index}_${operation.id}`}
        value={JSON.stringify(operation)}
    >
        {operation.name}
    </Select.Option>
));

export const createShareHolderOptions = (stakeholders = []) => stakeholders.map((stakeholder) => (
    <Select.Option
        key={stakeholder.stakeholder_user_uuid}
        value={stakeholder.stakeholder_user_uuid}
        label={`${stakeholder.first_name} ${stakeholder.last_name}`}
    >
        {`${stakeholder.first_name} ${stakeholder.last_name}`}
    </Select.Option>
));
export const createBusinessUserRoleOptions = (t) => Object.keys(BusinessUserRole).map((role) => (
    <Select.Option
        key={role}
        value={role}
        label={t(`businessUsers:businessUserFields.role.values.${role}`)}
    >
        {t(`businessUsers:businessUserFields.role.values.${role}`)}
    </Select.Option>
));

export const createBusinessUserPositionOptions = ({ t }) => Object.keys(StakeholderPosition).map((position) => (
    <Select.Option
        key={position}
        value={position}
    >
        {t(`businessUsers:businessUserFields.businessUserPosition.values.${position}`)}
    </Select.Option>
));


export const createBusinessUserTypeOptions = ({ t }) => Object.keys(StakeholderType).map((type) => (
    <Select.Option
        key={type}
        value={type}
    >
        {t(`businessUsers:businessUserFields.businessUserType.values.${type}`)}
    </Select.Option>
));
export const createTransactionScreeningResolutionActionsOptions = () => (
    <>
        <Select.Option key={'accept'} value={'accept'}>
            <CheckOutlined />
            {' '}
      Accept
        </Select.Option>
        <Select.Option key={'reject'} value={'reject'}>
            <CloseOutlined />
            {' '}
      Reject
        </Select.Option>
    </>
);
