export const FETCH_ACCOUNTS_LIST = 'FETCH_ACCOUNTS_LIST';
export const FETCH_ACCOUNTS_LIST_SUCCESS = 'FETCH_ACCOUNTS_LIST_SUCCESS';
export const FETCH_ACCOUNTS_LIST_FAILURE = 'FETCH_ACCOUNTS_LIST_FAILURE';
export const CLEAR_ACCOUNTS_LIST = 'CLEAR_ACCOUNTS_LIST';


export const OPEN_ADD_ACCOUNT_MODAL = 'OPEN_ADD_ACCOUNT_MODAL';

export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_FAILURE = 'ADD_ACCOUNT_FAILURE';
