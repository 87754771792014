import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { createTableColumns } from 'components/pages/authorised/CardsOrderForm/steps/ReviewStep/createTableColumns';
import { AddressCommonProps } from 'constants/AddressModel';
import { CountryIdType } from 'constants/CountriesModel';
import { CardOrderSteps } from 'redux/card-order/helpers';

import { Button, Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from './ReviewStep.styled';


function ReviewStep({
    isPhysicalCard,
    isLoadingOrderCards,
    cardOrderReview,

    dispatchGoToPreviousOrderStep,
    t,
}) {
    return (
        <Col style={{ width: '100%' }}>

            {isPhysicalCard && (
                <Row style={{ paddingBottom: 20 }}>
                    <Col>
                        <Styled.Heading>Delivery Address Details</Styled.Heading>
                        <Row gutter={24}>
                            <Col>
                                <div>
                                    <strong>Full Name: </strong>
                                </div>
                                <div>
                                    <strong>Phone Number: </strong>
                                </div>
                                <div>
                                    <strong>Address Line 1: </strong>
                                </div>
                                <div>
                                    <strong>Address Line 2: </strong>
                                </div>
                                <div>
                                    <strong>Post Code: </strong>
                                </div>
                                <div>
                                    <strong>City: </strong>
                                </div>
                                <div>
                                    <strong>Country: </strong>
                                </div>
                            </Col>
                            <Col>
                                {cardOrderReview && cardOrderReview.address && (
                                    <>
                                        <div>
                                            {cardOrderReview.address.first_name}
                                            {' '}
                                            {cardOrderReview.address.last_name}
                                        </div>
                                        <div><StandardValue value={cardOrderReview.address.phone_number} valueType="PHONE" /></div>
                                        <div><StandardValue value={cardOrderReview.address.address_line_1} /></div>
                                        <div><StandardValue value={cardOrderReview.address.address_line_2} /></div>
                                        <div><StandardValue value={cardOrderReview.address.zip_code} /></div>
                                        <div><StandardValue value={cardOrderReview.address.city} /></div>
                                        <div><CountryRenderer propertyName={CountryIdType.iso_31663} value={cardOrderReview.address.country} showTooltip={false} /></div>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}

            <Styled.Heading>Cards Review</Styled.Heading>


            <Styled.ReviewTable
                pagination={false}
                rowKey="req_id"
                columns={createTableColumns(t)}
                dataSource={cardOrderReview ? cardOrderReview.cards : []}
            />

            <Row style={{ marginTop: 20 }}>
                <Form.Item>
                    <Button onClick={() => dispatchGoToPreviousOrderStep(CardOrderSteps.REVIEW)} disabled={isLoadingOrderCards}>
                        {t('common:buttons.back.text')}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ marginLeft: 10 }}
                        type="primary"
                        loading={isLoadingOrderCards}
                        disabled={isLoadingOrderCards}
                    >
                        {t('cards:buttons.orderCard.text')}
                    </Button>
                </Form.Item>
            </Row>
        </Col>
    );
}


ReviewStep.propTypes = {
    cardOrderReview: PropTypes.shape({
        cards: PropTypes.arrayOf(PropTypes.shape({ })),
        address: AddressCommonProps,
    }).isRequired,
    isLoadingOrderCards: PropTypes.bool.isRequired,
    isPhysicalCard: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    dispatchGoToPreviousOrderStep: PropTypes.func.isRequired,
};

export default ReviewStep;

