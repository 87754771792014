import { clearCurrentUser } from 'redux/current-user/actions';
import {
    clearMultiStepsFormMetaData, setMultiStepsFormMetaData,
    setMultiStepsFormStep, setMultiStepsFormSubStep,
} from 'redux/multi-steps-form/actions';

import { createReducer } from '@reduxjs/toolkit';


export const multiStepsFormReducerName = 'multiStepsForm';


// eslint-disable-next-line object-curly-newline
const initialState = {
// XXX: e.g.
//   '/partner/add': {
//    stepsConfiguration: ????, // raw input data to parse

//     extracted metadata
//     availableSteps: ['PARTNER_DETAILS', 'ADDRESSES', 'SUCCESS'],
//     availableSubSteps: {
//       'ADDRESSES': ['COMPANY_ADDRESS', 'SHIPPING_ADDRESS', 'BILLING_ADDRESS']
//     },
//     currentMultiStepsFormStep: 'PARTNER_DETAILS',
//     currentMultiStepsFormSubStep: {
//         'ADDRESSES': 'COMPANY_ADDRESS'
//     },
// eslint-disable-next-line object-curly-newline
};

const initialExtractedMetaState = {
    availableSteps: [],
    availableSubSteps: {},
    currentMultiStepsFormStep: undefined,
    currentMultiStepsFormSubStep: {},
    allStepsValues: {},
};


const parseStepsConfiguration = (formMetaData) => {
    const stepsConfiguration = formMetaData?.stepsConfiguration;

    if (stepsConfiguration && stepsConfiguration.length > 0) {
    // reduce stepsConfiguration to available steps & sub-steps lists
        const parsedData = stepsConfiguration.reduce((acc, stepConfig) => ({
            availableSteps: [...acc.availableSteps, stepConfig.stepName],
            availableSubSteps: {
                ...acc.availableSubSteps,
                ...(stepConfig?.subStepsConfiguration?.length > 0
                    ? { [stepConfig.stepName]: stepConfig.subStepsConfiguration.map((subStepConfig) => subStepConfig.subStepName) }
                    : {}),
            },
        }), { ...initialExtractedMetaState });

        // return original stepsConfiguration, reduced lists ...
        return {
            stepsConfiguration,
            ...parsedData,
            currentMultiStepsFormStep: parsedData.availableSteps?.[0],
            // ...and further reduce availableSteps/subSteps to find default screens...
            currentMultiStepsFormSubStep: Object.keys(parsedData?.availableSubSteps).reduce((acc, parentStepName) => ({
                ...acc,
                [parentStepName]: parsedData.availableSubSteps[parentStepName]?.[0],
            }), {}),
        };
    }

    // for debug, spinner will stay
    return {
        stepsConfiguration,
        ...initialExtractedMetaState,
    };
};


const handlers = {
    [setMultiStepsFormMetaData]: (state, action) => ({
        ...state,
        [action.payload.locationPathname]: { ...parseStepsConfiguration(action.payload.formMetaData) },
    }),

    [clearMultiStepsFormMetaData]: (state, action) => {
        const { locationPathname } = action.payload;
        const { [locationPathname]: omittedOne, ...rest } = state;
        return { ...rest };
    },

    [setMultiStepsFormStep]: (state, action) => {
        const { locationPathname, newCurrentStep } = action.payload;
        return {
            ...state,
            [locationPathname]: {
                ...state[locationPathname],
                currentMultiStepsFormStep: newCurrentStep,
            },
        };
    },

    [setMultiStepsFormSubStep]: (state, action) => {
        const { locationPathname, newCurrentSubStep, parentStep } = action.payload;

        return {
            ...state,
            [locationPathname]: {
                ...state[locationPathname],
                currentMultiStepsFormSubStep: {
                    ...state[locationPathname].currentMultiStepsFormSubStep,
                    [parentStep]: newCurrentSubStep,
                },
            },
        };
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
