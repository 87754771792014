import { standardPageSize } from 'config/config';
import { updateQueryParamsMeta } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';


import { fetchPermissionsForRole, fetchPermissionsForRoleSuccess, fetchPermissionsForRoleFailure } from './actions';


export const permissionsReducerName = 'permissions';
export const permissionsListQueryParamsName = 'permissionsListQueryParams';

export const defaultPermissionsListQueryParams = {
    roleId: undefined,
    limit: standardPageSize,
    offset: 0,
    search: '',
};

const initialState = {
    permissionsByRole: {},
    isLoadingPermissions: false,
};

const handlers = {
    [fetchPermissionsForRole]: (state) => {
        state.isLoadingPermissions = true;
    },

    [fetchPermissionsForRoleSuccess]: (state, action) => {
        state.isLoadingPermissions = false;
        state.permissionsByRole = {
            ...state.permissionsByRole,
            [action.payload.roleId]: {
                permissions: action.payload.responseData,
                permissionsTotalCount: action.payload.responseData,
                [permissionsListQueryParamsName]: { ...defaultPermissionsListQueryParams, roleId: action.payload.roleId },
                queryParamsMeta: updateQueryParamsMeta(),
            },
        };
    },
    [fetchPermissionsForRoleFailure]: (state) => {
        state.isLoadingPermissions = false;
    },


    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === permissionsReducerName) {
            if (action.payload?.fieldName === permissionsListQueryParamsName) {
                const roleId = action.payload?.queryParams?.roleId;
                if (roleId) {
                    state.permissionsByRole = {
                        ...state.permissionsByRole,
                        [roleId]: {
                            ...state.permissionsByRole[roleId],
                            [permissionsListQueryParamsName]: {
                                ...state.queryParams,
                                ...action.payload?.queryParams,
                            },
                            queryParamsMeta: updateQueryParamsMeta(),
                        },
                    };
                }
            }
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
