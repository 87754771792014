import svgIllustrations from 'assets/images/illustrations/svgIllustrations';
import { AddClientStepsDefinitions } from 'constants/AddClientModel';
import { MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';

import { TrophyOutlined } from '@ant-design/icons';
import React from 'react';


export function createSuccessStepConfig({ t }) {
    return {
        stepName: AddClientStepsDefinitions.SUCCESS,
        title: t('addClient.steps.finished'),
        icon: <TrophyOutlined />,
        sectionsLayout: MultiStepFormStepSectionTypes.EVEN_COLUMNS,
        stepSections: [{
            title: 'Congratulations! A new partner was successfully created!',
            children: (
                <div style={{ textAlign: 'center' }}>
                    <img
                        style={{ maxWidth: '460px', marginTop: '50px' }}
                        src={svgIllustrations.successWoman}
                        alt="Decoration illustration picturing success woman"
                    />
                </div>
            ),
        }],
    };
}


export default { createSuccessStepConfig };
