import FlagAndValue from 'components/common/atoms/FlagAndValue';
import AccountTypeTag from 'components/common/atoms/tags/AccountTypeTag';
import StandardLink from 'components/common/molecules/StandardLink';
import ClientRenderer from 'components/common/molecules/value-renderers/ClientRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanIconRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { companyRootRoutePath, businessUserRootRoutePath, memberRootRoutePath } from 'config/routes';
import { amountFormatter } from 'utils/number-tools';

import { AccountDetails } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React from 'react';


export const createAccountDetailsSystemFieldsConfig = (
    account: AccountDetails,
    t: TFunction,
) => {
    if (account?.id) {
        return [
            {
                label: t('accounts:accountFields.extAccountId.label'),
                value: <StandardValue value={account.extAccountId} />,
            },
            ...(!account.createdAt
                ? []
                : [{
                    label: 'Created at',
                    value: <StandardValue value={account.createdAt} valueType="DATE" showTime />,
                }]),
            {
                label: 'Client ID',
                value: <ClientRenderer clientId={account.clientId} />,
            },

            ...(account.companyId > 0 ? [{
                label: 'Company ID',
                value: <StandardLink
                    title={account.companyId}
                    path={`${companyRootRoutePath}/${account.companyId}`}
                />,
            }] : []),

            ...(account.memberId > 0 ? [
                {
                    label: 'Member ID',
                    value: <StandardLink
                        title={account.memberId}
                        path={`${memberRootRoutePath}/${account.memberId}`}
                    />,

                }] : []),

            ...(account.businessUserId > 0 ? [
                {
                    label: 'Business user ID',
                    value: <StandardLink
                        title={account.businessUserId}
                        path={`${businessUserRootRoutePath}/${account.businessUserId}`}
                    />,

                }] : []),
        ];
    }

    return [];
};

export const createAccountDetailsPersonalFieldsConfig = (
    account: AccountDetails,
    t: TFunction,
) => {
    if (account?.id) {
        return [
            {
                label: 'Name',
                value: <StandardValue value={account.name} />,
            },
            {
                label: 'Type',
                value: <AccountTypeTag accountType={account.type} />,
            },
            {
                label: 'Is primary?',
                value: booleanIconRenderer(account, 'isPrimary', t),
            },
            {
                label: 'Currency',
                value: <FlagAndValue currencyCode={account.currency} text={account.currency} />,
            },
        ];
    }
    return [];
};


export const createAccountDetailsAmountFieldsConfig = (
    account: AccountDetails,
    t: TFunction,
) => {
    if (account?.id) {
        return [
            {
                label: t('Balance'),
                value: <FlagAndValue currencyCode={account.currency} text={`${amountFormatter({ value: account.balance })} ${account.currency}`} valueFirst />,
            },
            {
                label: t('Available balance'),
                value: <FlagAndValue currencyCode={account.currency} text={`${amountFormatter({ value: account.availableBalance })} ${account.currency}`} valueFirst />,
            },
            {
                label: t('Blocked balance'),
                value: <FlagAndValue currencyCode={account.currency} text={`${amountFormatter({ value: account.blockedBalance })} ${account.currency}`} valueFirst />,
            },
        ];
    }
    return [];
};
export default { createAccountDetailsSystemFieldsConfig, createAccountDetailsAmountFieldsConfig };
