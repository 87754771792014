export const getProperGetGroupsEndpoint = ({
    payload,
    groups,
    fees,
    promotions,
}) => {
    const handlers = [
        {
            predicate: () => payload?.queryParams?.feeUuid?.length > 0,
            handler: () => fees.fetchFeeGroups(payload.queryParams.feeUuid),
        },
        {
            predicate: () => payload?.queryParams?.promotionUuid?.length > 0,
            handler: () => promotions.fetchPromotionGroups(payload.queryParams.promotionUuid),
        },
        {
            predicate: () => true,
            handler: () => groups.fetchAllGroups(payload.queryParams?.clientId),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export default { getProperGetGroupsEndpoint };
