import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { fetchRolesList, clearRolesList } from 'redux/roles/actions';
import { rolesListsPerLocationStoreKeyName, rolesReducerName } from 'redux/roles/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import RolesList from './Roles.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: rolesReducerName,
        listsByLocationStoreKeyName: rolesListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchRolesList: (queryParams, locationPathname) => dispatch(fetchRolesList(queryParams, locationPathname)),
    dispatchClearRolesList: (locationPathname) => dispatch(clearRolesList(locationPathname)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: rolesReducerName,
        fieldName: rolesListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('roles'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(RolesList);
