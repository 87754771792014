import PropTypes from 'prop-types';

import { MultiStepFormStepChangeDirections } from './MultiStepsFormModel';


export const defaultClipboardContent = '';
export const tabKeySubTabKeySeparator = '🖤';

export const APPLICATION_STATE = Object.freeze({
    APPLICATION_STARTED: 'APPLICATION_STARTED',
    UNAUTHORISED_READY: 'UNAUTHORISED_READY',
    INIT_AUTHORISED_GLOBAL_DATA_FETCHING: 'INIT_AUTHORISED_GLOBAL_DATA_FETCHING',

    AUTHORISED_GLOBAL_DATA_FETCHING: 'AUTHORISED_GLOBAL_DATA_FETCHING',
    AUTHORISED_READY: 'AUTHORISED_READY',
});

export const ApplicationStateProps = PropTypes.oneOf(Object.keys(APPLICATION_STATE));

export const SortDirections = Object.freeze({
    DESCEND: 'descend',
    ASCEND: 'ascend',
});

export const DefaultSortByValue = 'id';

export const SortDirectionsAPI = Object.freeze({
    DESC: 'desc',
    ASC: 'asc',
});

export const emptyString = '';


export const CommonQueryParamsProps = PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    search: PropTypes.string,
    clientId: PropTypes.number,
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,

    filters: PropTypes.shape({}),
    includeInactivePartners: PropTypes.bool,
});


export const DetailsScreensNames = Object.freeze({
    cardDetails: 'cardDetails',
    clientDetails: 'clientDetails',
    companyDetails: 'companyDetails',
    beneficiaryDetails: 'beneficiaryDetails',
    businessUserDetails: 'businessUserDetails',
    feeDetails: 'feeDetails',
    groupDetails: 'groupDetails',
    memberDetails: 'memberDetails',
    promotionDetails: 'promotionDetails',
    userDetails: 'userDetails',
    roleDetails: 'roleDetails',
    myProfile: 'myProfile',
});

export const StandardTabConfigItemProps = PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.node, // normal tabs
    icon: PropTypes.node,
    hasList: PropTypes.bool,
    isDefault: PropTypes.bool,

    tabKey: PropTypes.string, // for tabs not connected to URL

    activeMainTabSegmentURL: PropTypes.string, // for 1 level tabs connected to URL
    subTabsConfig: PropTypes.arrayOf(PropTypes.shape({})), // tab with sub tabs -> 2 level
    parentActiveMainTabSegmentURL: PropTypes.string, // should be same as  parent tab `activeMainTabSegmentURL` value
    activeSubTabSegmentURL: PropTypes.string, // for 2 level tabs connected to URL
});


export const AllEntityTypes = Object.freeze({
    ACCOUNT: 'ACCOUNT',
    BENEFICIARY: 'BENEFICIARY',
    BUSINESS_USER: 'BUSINESS_USER',
    BUSINESS_STAKEHOLDER: 'BUSINESS_STAKEHOLDER',
    CARD: 'CARD',
    CLIENT: 'CLIENT',
    COMPANY: 'COMPANY',
    FEE: 'FEE',
    GROUP: 'GROUP',
    MEMBER: 'MEMBER',
    TRANSACTION: 'TRANSACTION',
    USER: 'USER',
    PROMOTION: 'PROMOTION',
    ROLE: 'ROLE',
    PERMISSION: 'PERMISSION',
});

export const StandardFilterTypes = Object.freeze({
    SEARCH: 'search',
    DROPDOWN: 'dropdown',
    CHECKBOX: 'checkbox',
    CLIENT_SELECT: 'client-select',
});

export const ReasonsForEntityIsReadOnly = Object.freeze({
    CARD_PERMANENT_STATUS: 'CARD_PERMANENT_STATUS',
    ENTITY_IS_OFFBOARDING: 'ENTITY_IS_OFFBOARDING',
    ENTITY_IS_OFFBOARDED: 'ENTITY_IS_OFFBOARDED',
    PARENT_ENTITY_IS_READ_ONLY: 'PARENT_ENTITY_IS_READ_ONLY',
    COMPANY_NOT_ACTIVE: 'COMPANY_NOT_ACTIVE',
    // MEMBER_IS_BANNED: 'MEMBER_IS_BANNED', // removed form read-only states in PDK-1695
    MEMBER_IS_READ_ONLY_STATUS: 'MEMBER_IS_READ_ONLY_STATUS',
});

export const AllEntityTypesProps = PropTypes.oneOf(Object.keys(AllEntityTypes));

export const ReasonsForEntityIsReadOnlyProps = PropTypes.oneOf(Object.keys(ReasonsForEntityIsReadOnly));

export const StandardTabConfigProps = PropTypes.arrayOf(StandardTabConfigItemProps);

export const ReadOnlyInfoProps = PropTypes.shape({
    entityType: AllEntityTypesProps,
    entityId: PropTypes.number,
    entityStatus: PropTypes.string,
    readOnlyStateReason: ReasonsForEntityIsReadOnlyProps,
    otherDetails: PropTypes.shape({
        parentEntityName: PropTypes.string,
        entityName: PropTypes.string,
    }),
});

export const ReasonsForBlockedNavigation = Object.freeze({
    // forms with own routes
    NOT_PRISTINE_ADD_MEMBER_FORM: 'NOT_PRISTINE_ADD_MEMBER_FORM',
    NOT_PRISTINE_ADD_PROMOTION_FORM: 'NOT_PRISTINE_ADD_PROMOTION_FORM',
    NOT_PRISTINE_ORDER_CARDS_FORM: 'NOT_PRISTINE_ORDER_CARDS_FORM',
    NOT_PRISTINE_ADD_CLIENT_FORM: 'NOT_PRISTINE_ADD_CLIENT_FORM',
    NOT_PRISTINE_ADD_COMPANY_FORM: 'NOT_PRISTINE_ADD_COMPANY_FORM',
    NOT_PRISTINE_EDIT_CLIENT_SETTINGS_FORM: 'NOT_PRISTINE_EDIT_CLIENT_SETTINGS_FORM',
    NOT_PRISTINE_ADD_ROLE_FORM: 'NOT_PRISTINE_ADD_ROLE_FORM',
    NOT_PRISTINE_EDIT_ROLE_FORM: 'NOT_PRISTINE_EDIT_ROLE_FORM',
    // forms inside a modal
    NOT_PRISTINE_ADD_EDIT_WEBHOOK_FORM: 'NOT_PRISTINE_ADD_EDIT_WEBHOOK_FORM',
    NOT_PRISTINE_ADD_API_KEY_FORM: 'NOT_PRISTINE_ADD_API_KEY_FORM',
    NOT_PRISTINE_REASSIGN_MEMBER_GROUP_FORM: 'NOT_PRISTINE_REASSIGN_MEMBER_GROUP_FORM',
});

export const ReasonsForBlockedNavigationProps = PropTypes.oneOf(Object.keys(ReasonsForBlockedNavigation));

export const CommonQueryParamsMetaProps = PropTypes.shape({
    timestamp: PropTypes.number,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.shape({ replace: PropTypes.bool }),
    }),
});

export const defaultQueryParamsMeta = {
    location: undefined,
    timestamp: undefined,
};

export function updateQueryParamsMeta(
    clearTimestamp = false,
    clearLocation = false,
) {
    return {
        location: clearLocation ? undefined : window.location,
        timestamp: clearTimestamp ? undefined : Date.now(),
    };
}

export function normaliseSortOrderValue(
    sortOrder = '',
) {
    return sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC;
}


export default {
    APPLICATION_STATE,
    StandardFilterTypes,
    ApplicationStateProps,
    SortDirections,
    DetailsScreensNames,
    CommonQueryParamsProps,
    StandardTabConfigItemProps,
    StandardTabConfigProps,
    AllEntityTypes,
    ReadOnlyInfoProps,
    ReasonsForBlockedNavigation,
    ReasonsForBlockedNavigationProps,
    defaultQueryParamsMeta,
    updateQueryParamsMeta,
    normaliseSortOrderValue,
    CommonQueryParamsMetaProps,
    defaultClipboardContent,
    tabKeySubTabKeySeparator,
    FormStepsChangeDirections: MultiStepFormStepChangeDirections,
};
