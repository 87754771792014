import { scrollBar } from 'assets/styles/scrollbar';

import styled, { css } from 'styled-components';


const SideBarWrapper = styled.div<{ $collapsed?: boolean }>`
  position: sticky;
  z-index: 9;
  left: 0;
  top: 0;
  height: 100vh;
  flex-direction: row;
  width: 200px;
  color: var(--sidebar-text-color);
  background-color: var(--sidebar-background-color);
  transform: translateX(0);
  transition: 300ms;

  ${({ $collapsed = false }) => $collapsed && css`
    transform: translateX(-100%);
  `};
`;

const CollapseSidebarTriggerWrapper = styled.div`
  width: 54px;
  height: 50px;
  position: absolute;
  z-index: 9;
  top: 0;
  right: -54px;
  font-size: 25px;
  background: var(--sidebar-background-color);
  border-radius: 0 100% 100% 0;
  display: grid;
  justify-content: center;
  padding-right: 4px;
  align-items: center;

  svg path {
    transition: fill .25s ease;
    fill: var(--sidebar-link-color);
  }
  
  svg:hover path {
    fill: var(--primary-color);
  }
`;

const SideBarContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0,1fr) auto;
  
  a {
    color: var(--sidebar-link-color);
  }
    
  .ant-list-item-meta-title {
    color: var(--sidebar-text-color);
  }

  .ant-menu {
    background-color: var(--sidebar-menu-background-color);
    color: var(--sidebar-menu-item-color);
    border-right: 0;
    overflow: auto;
    ${scrollBar};
  }
  
  .ant-menu-item {
    width: 100%;
    margin: 4px 0;
    border-radius: 0;

    &:hover {
      background-color: var(--sidebar-menu-item-active-color) !important;
      color: var(--sidebar-menu-item-color) !important;
    }
  }

  .ant-menu-item-active {
    background-color: var(--sidebar-menu-item-active-color);
    color: var(--sidebar-menu-item-color);
  }

  .ant-menu-item-selected {
    background-color: var(--sidebar-menu-item-active-color);
    color: var(--sidebar-menu-item-color);
  }

  .app-logo-header {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
    
  }
    
  .app-environment {
    padding-right: 1ch;
  }
`;

const AppName = styled.div`
  color: var(--sidebar-menu-item-color);
  padding-bottom: 10px;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
`;

const SideBarLogo = styled.img`
  width: 60px;
`;

const SideBarFooter = styled.div`
  width: 100%;
  padding: 5px;
  color: var(--sidebar-text-color);
  font-size: 12px;
`;

const MenuItemText = styled.span`
  margin-left: 10px;
`;


export default {
    SideBarWrapper,
    CollapseSidebarTriggerWrapper,
    SideBarContent,
    SideBarLogo,
    MenuItemText,
    SideBarFooter,
    AppName,
};
