import StandardListTable from 'components/common/organisms/StandardListTable';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultCardStatusHistoryQueryParams } from 'redux/card-status-history/reducer';

import React from 'react';


import { StatusHistoryListProps } from './StatusTimeline.types';


function StatusHistoryList({
    contextEnforcedQueryParams,
    enhancedCurrentLocation,
    listData,

    createTableColumns,

    dispatchFetcherListAction,
    dispatchClearListAction,
    dispatchSetQueryParams,
    filtersButtonsConfig,
    t,
    defaultItemsListQueryParams = defaultCardStatusHistoryQueryParams,
    createCustomHeadingContent,
    removeFilterSection,
}: StatusHistoryListProps) {
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetcherListAction(prams, enhancedCurrentLocation?.pathname);
        }
    };

    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetcherListAction,
        dispatchClearItemsList: dispatchClearListAction,
        dispatchSetQueryParams,
    });


    return (
        <StandardListTable
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createTableColumns({
                t,
                queryParams: listData?.queryParams,
            })}
            filtersButtonsConfig={filtersButtonsConfig}
            removeFilterSection={removeFilterSection}
            customHeadingContent={createCustomHeadingContent ? createCustomHeadingContent(listData) : undefined}
        />
    );
}

export default StatusHistoryList;
