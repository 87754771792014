import {
    ADD_CLIENT_PRODUCT,
    ADD_CLIENT_PRODUCT_FAILURE,
    ADD_CLIENT_PRODUCT_SUCCESS,
    CLEAR_CLIENT_PRODUCTS,
    FETCH_CLIENT_PRODUCTS,
    FETCH_CLIENT_PRODUCTS_FAILURE,
    FETCH_CLIENT_PRODUCTS_SUCCESS,
    OPEN_ADD_CLIENT_PRODUCT_MODAL,
} from 'redux/client-products/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchClientProducts = createAction(FETCH_CLIENT_PRODUCTS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchClientProductsSuccess = createAction(
    FETCH_CLIENT_PRODUCTS_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchClientProductsFailure = createAction(FETCH_CLIENT_PRODUCTS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearClientProducts = createAction(CLEAR_CLIENT_PRODUCTS, (locationPathname) => ({ payload: { locationPathname } }));


export const openAddProductModal = createAction(OPEN_ADD_CLIENT_PRODUCT_MODAL, (clientId, locationPathname) => ({ payload: { clientId, locationPathname } }));

export const addProduct = createAction(ADD_CLIENT_PRODUCT, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const addProductSuccess = createAction(ADD_CLIENT_PRODUCT_SUCCESS, (responseData, locationPathname) => ({ payload: { responseData, locationPathname } }));
export const addProductFailure = createAction(ADD_CLIENT_PRODUCT_FAILURE);
