import { MultiStepFormStepChangeDirections } from 'constants/MultiStepsFormModel';
import { nestifyObject } from 'utils/object-tools';


export function createActionsForStepSuccessfulValidation({
    locationPathname,
    currentMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormStep,
    dispatchFinalApiCall,
    values,
    lastStepExceptSuccess,
}) {
    // XXX remove FE helper fields from payload
    const { businessUser, ...rest } = nestifyObject(values);
    const { isAStakeholder, createBusinessUser, ...other } = businessUser || {};

    const amendedPayload = {
        ...rest,
        ...(createBusinessUser && other?.firstName
            ? { businessUser: { ...other } }
            : {}),
    };


    const handlers = [
        {
            // last step or last sub step in last step (last expect SUCCESS step)
            predicate: () => (currentMultiStepsFormStep === lastStepExceptSuccess),
            handler: () => dispatchFinalApiCall(amendedPayload, locationPathname),
        },

        {
            // regular case -> advance step
            predicate: () => true,
            handler: () => dispatchRequestSetMultiStepsFormStep(
                currentMultiStepsFormStep,
                MultiStepFormStepChangeDirections.FORWARD,
                locationPathname,
            ),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export default { createActionsForStepSuccessfulValidation };
