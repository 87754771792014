import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CompanyDetailsProps } from 'constants/CompaniesModel';
import { createCompanyTypesOptions } from 'utils/options-creators';
import { commonValidation } from 'utils/validation-tools';

import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function EditCompanyModal({
    accessControl,
    initialValues,
    dispatchUpdateCompany,
    enhancedCurrentLocation,
    dispatchSetModalProps,
    t,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);


    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        const newPayload = {
            ...(!accessControl.isKycKybApprovalEnabled() ? {
                company_type: values.company_type,
                company_name: values.company_name,
                company_registration_number: values.company_registration_number,
            } : {}),

            company_phone_number: values.company_phone_number,
            company_email: values.company_email,
        };

        const baseURLSegmentsCount = 2;
        const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);
        dispatchUpdateCompany(initialValues.company_id, newPayload, baseUrlWithoutTabsSlugs);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={initialValues}
            onFinish={onFinish}
        >
            <Form.Item
                name="company_name"
                label="Name"
                rules={commonValidation({ t, isRequired: true })}
            >
                <Input disabled={accessControl.isKycKybApprovalEnabled()} />
            </Form.Item>

            <Form.Item
                name="company_type"
                label="Company Type"
                rules={[
                    {
                        required: true,
                        message: t('common:validationMsg.fieldMandatory'),
                    },
                ]}
            >
                <Select
                    disabled={accessControl.isKycKybApprovalEnabled()}
                    placeholder={t('Select company type')}
                >
                    {createCompanyTypesOptions()}
                </Select>
            </Form.Item>

            <Form.Item
                name="company_registration_number"
                label="Registration Number"
                rules={commonValidation({ t, isRequired: true })}
            >
                <Input disabled={accessControl.isKycKybApprovalEnabled()} />
            </Form.Item>

            <PhoneInput
                name="company_phone_number"
                t={t}
            />

            <EmailInput
                name="company_email"
                t={t}
                label="eMail"
            />
        </Form>
    );
}


EditCompanyModal.propTypes = {
    accessControl: AccessControlProps,
    initialValues: CompanyDetailsProps,
    enhancedCurrentLocation: PropTypes.string,
    dispatchUpdateCompany: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default EditCompanyModal;

