import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const GroupHasMembersWarning = styled.div`
  display: grid;
  grid-template-columns: minmax(0,auto) minmax(0, 1fr);
  grid-column-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;

const WarningIcon = styled(WarningOutlined)`
  font-size: 50px;
  margin-right: 20px;
  
  path {
    fill: orange;
  }
`;


const Strong = styled.strong`
  display: inline-block;
  text-align: center;
  font-weight: 700;
  color: orange;
`;

export default {
    GroupHasMembersWarning,
    WarningIcon,
    Strong,
};
