import StandardListTable from 'components/common/organisms/StandardListTable';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultFailedWebhooksListQueryParams } from 'redux/failed-webhooks/reducer';

import React from 'react';

import { FailedWebhookHistoryListProps } from './FailedWebhookHistoryList.types';
import tableColumns from './tableColumns';


function FailedWebhookHistoryList({
    // own props
    failedWebhookUuid,

    enhancedCurrentLocation,
    listData,

    dispatchSetQueryParams,
    dispatchFetchFailedWebhookErrorsList,
    dispatchClearFailedWebhookErrorsList,
    dispatchShowViewJsonModal,
    t,
}: FailedWebhookHistoryListProps) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { failedWebhookUuid };

    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultFailedWebhooksListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchFailedWebhookErrorsList,
        dispatchClearItemsList: dispatchClearFailedWebhookErrorsList,
        dispatchSetQueryParams,
    });

    // 4a. list specific stuff

    // 5. Standard render
    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchFailedWebhookErrorsList({ ...contextEnforcedQueryParams }, enhancedCurrentLocation?.pathname)}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={tableColumns({
                t,
                queryParams: listData?.queryParams,
                dispatchShowViewJsonModal,
            })}
        />
    );
}


export default FailedWebhookHistoryList;
