import React from 'react';

import Styled from './MultiStepFormSubStepsIndicator.styled';

// TODO add new prop 'isEditMode' -> sub-steps should on click allow direct step change to given step

const createMultiStepsFormSubStep = ({ subStepConfig, onClickHandler, currentMultiStepsFormSubStep }) => ({
    key: subStepConfig.subStepName,
    title: (
        <Styled.SubStepLabel>
            {onClickHandler && currentMultiStepsFormSubStep !== subStepConfig.subStepName
                ? (
                    <Styled.SubStepLabelButton
                        type="button"
                        onClick={() => onClickHandler(subStepConfig.subStepName)}
                    >
                        {subStepConfig.title}
                    </Styled.SubStepLabelButton>
                ) : subStepConfig.title}
        </Styled.SubStepLabel>
    ),
});


export const createMultiStepsFormStepWithSubSteps = ({ stepConfig, onClickHandler, currentMultiStepsFormSubStep }) => (
    stepConfig?.subStepsConfiguration || []
).map((subStepConfig) => createMultiStepsFormSubStep({ subStepConfig, onClickHandler, currentMultiStepsFormSubStep }));


export default {
    createMultiStepsFormSubStep,
    createMultiStepsFormStepWithSubSteps,
};
