import { clientConfigKey, permissionsKey } from 'config/config';
import { loginRootRoutePath, rootRoutePath } from 'config/routes';
import { fetchGlobalData, hideAutoLogoutBanner, removeJwtTokenFromStorage } from 'redux/application/actions';
import { refreshToken } from 'redux/authorisation/actions';
import { authorisationReducerName } from 'redux/authorisation/reducer';
import {
    setCurrentUserFailure, setCurrentUserSuccess,
    expireToken,
    extendTokenValidityFailure, extendTokenValiditySuccess, extendTokenValidity, clearCurrentUser, checkTokens,
} from 'redux/current-user/actions';
import { requestNavigation, unblockNavigation } from 'redux/navigation/actions';
import { setSessionStorageObjectItem } from 'utils/browser-storage';
import { calculateTimeToSilentTokenRefresh, getJwt, isTokenNotExpired, saveJwt } from 'utils/jwtToken';

import { ofType } from 'redux-observable';
import { EMPTY, from, fromEvent, of, timer } from 'rxjs';
import {
    catchError, switchMap, tap, map, mergeMap, takeUntil,
} from 'rxjs/operators';


import {
    CHECK_TOKENS,
    CLEAR_CURRENT_USER,
    EXPIRE_TOKEN,
    EXTEND_TOKEN_VALIDITY,
    EXTEND_TOKEN_VALIDITY_SUCCESS,
    SET_CURRENT_USER,
    SET_CURRENT_USER_FAILURE,
    SET_CURRENT_USER_SUCCESS, USER_ACTIVITY,
} from './actions.types';
import SET_CURRENT_USER_VARIANT from './epics.helpers';
import { currentUserReducerName } from './reducer';


export const onSetCurrentUser = (action$, _, { http }) => action$.pipe(
    ofType(SET_CURRENT_USER),
    tap(({ payload: { responsePayload: { permissions, configuration, ...rest } } }) => {
        http.setUserToken(rest.accessToken);
        setSessionStorageObjectItem(permissionsKey, permissions);
        setSessionStorageObjectItem(clientConfigKey, configuration);
        saveJwt(rest);
    }),
    mergeMap(({ payload }) => {
        if (payload?.responsePayload?.accessToken) {
            return of(setCurrentUserSuccess(payload?.responsePayload));
        }
        return of(setCurrentUserFailure());
    }),
);

export const onSetCurrentUserSuccess = (action$, state$) => action$.pipe(
    ofType(SET_CURRENT_USER_SUCCESS),
    mergeMap(() => {
        const shouldMakeFetch = state$.value[currentUserReducerName].variant !== SET_CURRENT_USER_VARIANT.REFRESH_SESSION;
        return of(
            unblockNavigation(),
            ...(shouldMakeFetch ? [fetchGlobalData(true)] : []),
        );
    }),
);

export const onSetCurrentUserFailure = (action$) => action$.pipe(
    ofType(SET_CURRENT_USER_FAILURE),
    switchMap(() => of(requestNavigation(rootRoutePath))),
);


export const onClearCurrentUser = (action$) => action$.pipe(
    ofType(CLEAR_CURRENT_USER),
    switchMap(() => of(
        expireToken(),
        removeJwtTokenFromStorage(),
        unblockNavigation(),
        requestNavigation(loginRootRoutePath, { replace: true }),
    )),
);


export const onExtendTokenValidity = (action$, state$, { authorisation }) => action$.pipe(
    ofType(EXTEND_TOKEN_VALIDITY),
    switchMap(() => {
        const jwtTokens = getJwt();
        if (jwtTokens?.refreshToken) {
            return from(authorisation.extendTokenValidity(jwtTokens.refreshToken)).pipe(
                switchMap((response) => of(extendTokenValiditySuccess(response.data))),
                catchError(() => extendTokenValidityFailure()),
            );
        }
        return of(extendTokenValidityFailure());
    }),
);

export const onExtendTokenValiditySuccess = (action$, state$, { http }) => action$.pipe(
    ofType(EXTEND_TOKEN_VALIDITY_SUCCESS),
    tap(({ payload }) => {
        http.setUserToken(payload.accessToken);
        saveJwt(payload);
    }),
    switchMap(() => EMPTY),
);

export const onExpireToken = (action$, state$, { authorisation }) => action$.pipe(
    ofType(EXPIRE_TOKEN),
    switchMap(() => from(authorisation.expireToken()).pipe(
        switchMap(() => EMPTY),
        catchError(() => EMPTY),
    )),
);

export const onReceivingToken = (action$) => action$.pipe(
    ofType(
        SET_CURRENT_USER,
        EXTEND_TOKEN_VALIDITY_SUCCESS,
    ),
    switchMap(({ payload }) => {
        const delay = calculateTimeToSilentTokenRefresh(payload?.expirationTimeOfAccessToken || payload?.responsePayload?.expirationTimeOfAccessToken);

        return timer(delay).pipe(
            takeUntil(action$.pipe(ofType(CLEAR_CURRENT_USER))),
            map(() => extendTokenValidity()),
        );
    }),
);

const checkTokensValidity = (state) => {
    const jwtToken = getJwt();
    const { isAutoLogoutBannerVisible } = state.application;

    if (isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)) {
        return isAutoLogoutBannerVisible === true ? of(hideAutoLogoutBanner()) : EMPTY;
    }

    if (jwtToken
    && !isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)
    && isTokenNotExpired(jwtToken.expirationTimeOfRefreshToken)
    ) {
        const { isRefreshingSession } = state[authorisationReducerName];
        return of(hideAutoLogoutBanner(), ...(isRefreshingSession ? [] : [refreshToken(jwtToken)]));
    }
    return of(clearCurrentUser());
};


export const onUserActivity = (action$) => action$.pipe(
    ofType(USER_ACTIVITY),
    switchMap(() => of(checkTokens('userActivityAfterIDLE'))),
);


export const onDocumentVisibilityChange = (action$) => action$.pipe(
    ofType(SET_CURRENT_USER),
    switchMap(() => fromEvent(document, 'visibilitychange').pipe(
        takeUntil(action$.pipe(ofType(CLEAR_CURRENT_USER))),
        switchMap(() => of(checkTokens("document, 'visibilitychange'"))),
    )),
);

export const onCheckTokens = (action$, state$, { authorisation }) => action$.pipe(
    ofType(CHECK_TOKENS),
    switchMap(({ payload }) => checkTokensValidity(state$.value, authorisation, payload)),
);

export default [
    onSetCurrentUser,
    onSetCurrentUserSuccess,
    onSetCurrentUserFailure,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity,
    onCheckTokens,
    onDocumentVisibilityChange,
];

