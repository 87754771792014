import { goToPreviousOrderStep, setProductType, setCardMatrix, selectMatrix } from 'redux/card-order/actions';
import { cardsOrderReducerName } from 'redux/card-order/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { requestNavigationBack } from 'redux/navigation/actions';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CardDetailsStep from './CardDetailsStep.layout';


const mapStateToProps = (state) => ({
    state: state[cardsOrderReducerName],

    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    isLoadingProcessCard: state[cardsOrderReducerName].isLoadingProcessCard,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSelectMatrix: (data) => dispatch(selectMatrix(data)),
    dispatchSetProductType: (type) => dispatch(setProductType(type)),
    dispatchSetCardMatrix: (matrix) => dispatch(setCardMatrix(matrix)),
    dispatchGoToPreviousOrderStep: (currentStep) => dispatch(goToPreviousOrderStep(currentStep)),
    dispatchRequestNavigationBack: () => dispatch(requestNavigationBack()),
});

const decorate = compose(
    withTranslation('cards'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CardDetailsStep);
