import styled from 'styled-components';


const ChangeFlowLevelFormWrapper = styled.div`
  max-width: 200px;
`;

const ChangeFlowLevelFormButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  justify-content: right;
  align-items: center;
`;


export default { ChangeFlowLevelFormWrapper, ChangeFlowLevelFormButtonsWrapper };
