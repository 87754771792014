import withAccessControl from 'hocs/withAccessControl';
import { updateCompany } from 'redux/company/actions';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { industriesListsPerLocationStoreKeyName, industriesReducerName } from 'redux/industries/reducer';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import EditCompanyModal from './EditCompanyModal.layout';


const mapStateToProps = (state) => ({
    industries: getStandardListData({
        state,
        reducerName: industriesReducerName,
        listsByLocationStoreKeyName: industriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchUpdateCompany: (companyId, formData, locationPathname) => dispatch(updateCompany(companyId, formData, locationPathname)),
});


const decorate = compose(
    withAccessControl,
    withTranslation('companies'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(EditCompanyModal);

