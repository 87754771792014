import { AccountDetailsProps } from 'constants/AccountModel';

import { StarOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import { SetAsPrimaryButtonProps } from './SetAsPrimaryButton.types';


function SetAsPrimaryButton({
    accountDetails,
    t,
    locationPathname,
    isLoadingSetAsPrimaryAccount,
    dispatchSetAsPrimaryAccount,
}: SetAsPrimaryButtonProps) {
    return (
        <Popconfirm
            placement="bottom"
            title={t('buttons.setAsPrimary.confirmationQuestion')}
            okText={t('buttons.setAsPrimary.text')}
            onConfirm={() => dispatchSetAsPrimaryAccount(accountDetails, locationPathname)}
        >
            <Button
                type="primary"
                icon={<StarOutlined />}
                loading={isLoadingSetAsPrimaryAccount}
                disabled={isLoadingSetAsPrimaryAccount}
            >
                {t('buttons.setAsPrimary.text')}
            </Button>
        </Popconfirm>

    );
}

SetAsPrimaryButton.propTypes = {
    isLoadingSetAsPrimaryAccount: PropTypes.bool,
    accountDetails: AccountDetailsProps,
    dispatchSetAsPrimaryAccount: PropTypes.func,
    t: PropTypes.func.isRequired,
};

export default SetAsPrimaryButton;
