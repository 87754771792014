import styled from 'styled-components';


export const PageWrapper = styled.div`
  position: relative;
`;

export const AccordionsWrapper = styled.div`
  max-width: 900px;
`;


export const HeaderWrapper = styled.div`
  position: relative;
  padding-right: 50px;
`;

export const NewIndicatorWrapper = styled.div`
  position: absolute;
  right: -9px;
  top: -4px;
  transform: rotate(-30deg);
`;

export const FaqIconDecoration = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  height: auto;
`;


export default { HeaderWrapper, NewIndicatorWrapper, AccordionsWrapper, PageWrapper, FaqIconDecoration };

