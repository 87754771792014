import { standardPageSize } from 'config/config';
import {
    accountRootRoutePath,
    beneficiaryRootRoutePath, cardRootRoutePath,
    clientRootRoutePath,
    companyRootRoutePath,
    businessUserRootRoutePath,
    memberRootRoutePath,
    pendingTransactionsRouteSegmentPath,
    transactionsRouteSegmentPath,
} from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    getTransactions, getTransactionsSuccess, getTransactionsFailure,
    getTransactionsFilters, getTransactionsFiltersSuccess, getTransactionsFiltersFailure,
    clearTransactionsList,
} from './actions';


export const transactionsReducerName = 'transactions';
export const transactionsListsPerLocationStoreKeyName = 'transactionsListsPerLocation';


export const defaultTransactionsQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    status: undefined,
    clientId: undefined,
    memberId: undefined,
    companyId: undefined,
    accountId: undefined,
    cardId: undefined,
    beneficiaryUuid: undefined,
    businessUserId: undefined,
    originalCurrency: undefined,
    sortBy: 'createdAt',
    sortOrder: SortDirections.DESCEND,
    showCardVerification: true,
    showOutgoingRecall: true,
    amlReview: undefined,
};

const defaultTransactionsListState = { [transactionsListsPerLocationStoreKeyName]: [] };

const initialState = {
    filters: [],
    isLoadingTransactionsFilters: false,

    ...defaultTransactionsListState,
};

const handlers = {
    [getTransactions]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = updateStandardLists(state[transactionsListsPerLocationStoreKeyName], action);
    },
    [getTransactionsSuccess]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[transactionsListsPerLocationStoreKeyName], action);
    },
    [getTransactionsFailure]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[transactionsListsPerLocationStoreKeyName], action);
    },
    [clearTransactionsList]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[transactionsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[transactionsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [transactionsRouteSegmentPath, pendingTransactionsRouteSegmentPath],
                observedRootPaths: [
                    accountRootRoutePath,
                    beneficiaryRootRoutePath,
                    companyRootRoutePath,
                    cardRootRoutePath,
                    businessUserRootRoutePath,
                    memberRootRoutePath,
                    clientRootRoutePath,
                    companyRootRoutePath,
                    memberRootRoutePath,
                ],
            },
        );
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === transactionsReducerName
      && action.payload?.fieldName === transactionsListsPerLocationStoreKeyName
        ) {
            state[transactionsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[transactionsListsPerLocationStoreKeyName] }, action);
        }
    },

    [getTransactionsFilters]: (state) => {
        state.isLoadingTransactionsFilters = true;
    },
    [getTransactionsFiltersFailure]: (state) => {
        state.isLoadingTransactionsFilters = false;
    },
    [getTransactionsFiltersSuccess]: (state, action) => {
        state.isLoadingTransactionsFilters = false;
        state.filters = action.payload;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
