import PropTypes from 'prop-types';


export const kycKybApproval = Object.freeze({
    MANIGO: 'MANIGO',
    EXTERNAL: 'EXTERNAL',
});

export const KycKybEntityTypes = Object.freeze({
    MEMBER: 'member',
    COMPANY: 'company',
    BUSINESS_USER: 'businessUser',

    CLIENT: 'client', // XXX clients aren't applicable to KYC/KYB, added to unify compliance tab logic
});


export const KycKybEntityTypeProp = PropTypes.oneOf(Object.values(KycKybEntityTypes));

export const GetKycKybFlowsQueryParamsProps = PropTypes.shape({
    entityType: KycKybEntityTypeProp,
    memberId: PropTypes.number,
    companyId: PropTypes.number,
    businessUserId: PropTypes.number,
});

export const KycKybAuditReviewAnswerTypes = Object.freeze({
    GREEN: 'GREEN', // Approved
    RED: 'RED', // Rejected
    YELLOW: 'YELLOW', // Manual review
});

export const KycKybAuditItemProps = PropTypes.shape({
    applicant_id: PropTypes.string,
    applicant_member_of: PropTypes.string,
    applicant_type: PropTypes.string,
    client_comment: PropTypes.string,
    correlation_id: PropTypes.string,
    created_at: PropTypes.string, // "2021-05-18T10:28:06.000Z"
    deleted_at: PropTypes.string,
    entity_id: PropTypes.number,
    entity_type: PropTypes.string,
    external_user_id: PropTypes.string,
    id: PropTypes.number,
    inspection_id: PropTypes.string,
    invoked: PropTypes.string, // ?
    invoked_info: PropTypes.string,
    moderation_comment: PropTypes.string,
    reject_labels: PropTypes.string,
    review_answer: PropTypes.string,
    review_reject_type: PropTypes.string,
    review_result: PropTypes.string,
    review_status: PropTypes.string,
    type: PropTypes.string,
    updated_at: PropTypes.string, // "2021-05-18T10:28:07.000Z"
    whole_request: PropTypes.string, // JSON
});

export const KycKybAuditLogsDataProp = PropTypes.arrayOf(KycKybAuditItemProps);


export default {
    kycKybApproval,
    KycKybEntityTypes,
    KycKybEntityTypeProp,
    GetKycKybFlowsQueryParamsProps,
    KycKybAuditReviewAnswerTypes,
    KycKybAuditItemProps,
    KycKybAuditLogsDataProp,
};
