import StandardMultiValue from 'components/common/molecules/value-renderers/StandardMultiValue';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';

import PropTypes from 'prop-types';
import React from 'react';

import Styled from './IndustriesRenderer.styled';


export const getIndustries = (industries = [], stateIndustries) => {
    const result = [];
    if (industries && stateIndustries?.length > 0) {
        (industries).forEach((id) => {
            const industry = stateIndustries?.find((industry) => parseInt(industry.id, 10) === parseInt(id, 10));
            if (industry && industry.id !== 31) {
                result.push(industry.name);
            }
        });
    }
    return result;
};

const industryInlineRenderer = (item) => (
    <StandardValue
        key={`industryInlineRenderer-${item}`}
        value={item}
        showTooltip
        showCopyToClipboard={false}
    />
);

const industryTooltipRenderer = (item) => (
    <div>
        <StandardValue
            key={`industryTooltipRenderer-${item}`}
            value={item}
            showTooltip={false}
        />
    </div>
);

function IndustriesRenderer({ clientIndustries, industries, itemsToBeShownInline = 3 }) {
    const industriesList = getIndustries(clientIndustries, industries);

    return (
        <Styled.IndustriesWrapper>
            <StandardMultiValue
                itemsToBeShownInline={itemsToBeShownInline}
                items={industriesList}
                inlineItemRenderer={industryInlineRenderer}
                tooltipItemRenderer={industryTooltipRenderer}
            />
        </Styled.IndustriesWrapper>

    );
}


IndustriesRenderer.propTypes = {
    clientIndustries: PropTypes.arrayOf(PropTypes.number),
    itemsToBeShownInline: PropTypes.number,
};


export default IndustriesRenderer;
