/* eslint-disable import/no-named-as-default-member */
import withAccessControl from 'hocs/withAccessControl';
import { clearResetForm, updateCompanyStatus } from 'redux/company-status/actions';
import { companyStatusReducerName } from 'redux/company-status/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UpdateCompanyStatusForm from './UpdateCompanyStatusForm.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state[navigationReducerName]?.enhancedCurrentLocation,
    isLoadingStatusUpdate: state[companyStatusReducerName].isLoadingStatusUpdate,
    shouldResetForm: state[companyStatusReducerName].shouldResetForm,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateCompanyStatus: (companyId, requestPayload, locationPathname) => dispatch(updateCompanyStatus(companyId, requestPayload, locationPathname)),
    dispatchClearResetForm: () => dispatch(clearResetForm()),
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('companies'),
    withAccessControl,
);
export default decorate(UpdateCompanyStatusForm);
