import styled from 'styled-components';


const TwoColumns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
`;


export default { TwoColumns };
