import withAccessControl from 'hocs/withAccessControl';
import { authorisationReducerName } from 'redux/authorisation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AppRoutes from './AppRoutes.layout';


const mapStateToProps = (state) => ({
    applicationState: state.application.applicationState,
    clientId: state.currentUser.userData?.clientId,
    isRefreshingSession: state[authorisationReducerName]?.isRefreshingSession,
});


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps),
);

export default decorate(AppRoutes);
