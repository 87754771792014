import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { settingsRouteSegmentPath } from 'config/routes';
import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import {
    updateMemberSuccess, updateMemberFailure,
    fetchMemberDetailsSuccess, fetchMemberDetailsFailure,
} from 'redux/member/actions';
import {

    OPEN_UPDATE_MEMBER_MODAL,
    UPDATE_MEMBER,
    FETCH_MEMBER_DETAILS,
} from 'redux/member/actions.types';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const onFetchMemberDetails = (action$, state$, { members }) => action$.pipe(
    ofType(FETCH_MEMBER_DETAILS),
    switchMap(({ payload: { queryParams, locationPathname } }) => from(members.getMemberDetails(queryParams))
        .pipe(
            switchMap((response) => of(fetchMemberDetailsSuccess(response.data, locationPathname))),
            catchError(() => of(fetchMemberDetailsFailure(locationPathname))),
        )),
);

export const onOpenUpdateMemberModal = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_UPDATE_MEMBER_MODAL),
    switchMap(({ payload: { initialValues } }) => of(
        showModal({
            modalType: ModalName.EDIT_MEMBER_MODAL,
            modalProps: {
                title: i18n.t('member:modals.updateMember.heading'),
                initialValues,
            },
        }),
    )),
);

export const onUpdateMember = (action$, state$, { members, i18n }) => action$.pipe(
    ofType(UPDATE_MEMBER),
    switchMap(({ payload: { locationPathname, memberId, requestPayload } }) => from(members.updateMember(memberId, requestPayload)).pipe(
        switchMap((response) => {
            if (locationPathname?.includes(settingsRouteSegmentPath)) {
                return of(
                    updateMemberSuccess(response.data, extractBaseURLWithoutActiveTab(locationPathname, 2)),
                    showSuccessToast(i18n.t('member:actionMessages.updateMemberSettingsSuccess')),
                );
            }
            return of(
                updateMemberSuccess(response.data, extractBaseURLWithoutActiveTab(locationPathname, 2)),
                showSuccessToast(i18n.t('member:actionMessages.updateMemberSuccess')),
                hideModal(),
            );
        }),
        catchError(() => {
            if (locationPathname?.includes(settingsRouteSegmentPath)) {
                return of(
                    updateMemberFailure(),
                );
            }
            return of(
                updateMemberFailure(),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            );
        }),
    )),
);


export default [
    onFetchMemberDetails,
    onUpdateMember,
    onOpenUpdateMemberModal,
];
