import { createCompanyAddressFormStepConfig, createCompanyDetailsFormStepConfig, createFirstBusinessUserFormStepConfig } from './steps/companyDetailsStep.helpers';
import { createSuccessStepConfig } from './steps/successStep.helpers';


export function createAddCompanyFormStepsConfiguration({ t, clientId }) {
    // XXX order DOES matter
    return [
        createCompanyDetailsFormStepConfig({ t, clientId }),
        createCompanyAddressFormStepConfig({ t }),
        createFirstBusinessUserFormStepConfig({ t }),
        createSuccessStepConfig({ t }),
    ];
}


export default { createAddCompanyFormStepsConfiguration };
