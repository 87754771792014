import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import AccountItem from 'components/common/templates/modals/LinkCardModal/components/AccountItem';
import CardItem from 'components/common/templates/modals/LinkCardModal/components/CardtItem';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CardChallengeMethods } from 'constants/CardModel';
import {
    createCardChallengeManagersOptions,
    createCardChallengeMethodsOptions,
} from 'utils/options-creators';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';


function FinalLinkStep({
    accessControl,

    card,
    account,
    companyId,
    businessUserId,
    client3DsSettings,
    form,
    t,
}) {
    const [challengeMethod, setChallengeMethod] = useState(form.getFieldValue('challengeMethod'));

    const defaultChallengeMethod = useMemo(
        () => {
            if (businessUserId > 0) {
                return client3DsSettings?.business_user?.method;
            }
            if (companyId > 0) {
                return client3DsSettings?.company?.method;
            }
            return client3DsSettings?.member?.method;
        },
        [companyId, businessUserId, client3DsSettings],
    );

    const defaultChallengeManager = useMemo(
        () => {
            if (businessUserId > 0) {
                return client3DsSettings?.business_user?.manager;
            }
            if (companyId > 0) {
                return client3DsSettings?.company?.manager;
            }
            return client3DsSettings?.member?.manager;
        },
        [companyId, businessUserId, client3DsSettings],
    );


    const showPhoneNumberField = useMemo(
        () => {
            if (
            // company case and...
                (companyId > 0 && !businessUserId > 0)
          && (
                // challengeMethod not picked yet but client default is SMS
              (challengeMethod !== CardChallengeMethods.IN_APP_AUTH && defaultChallengeMethod === CardChallengeMethods.SMS_OTC)
            // challengeMethod was picked and it is SMS
            || (challengeMethod === CardChallengeMethods.SMS_OTC)
          )
            ) {
                return true;
            }
            return undefined;
        },
        [companyId, businessUserId, challengeMethod, client3DsSettings],
    );

    const showPhoneNumberRequiredMsgKey = challengeMethod === CardChallengeMethods.SMS_OTC ? 'required' : 'requiredDefaultClientSetting';

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <h2 className="ant-list-item-meta-title">
                    {t('modals.linkCard.summaryStep.subHeadings.selectedCard')}
                </h2>
                <div className="ant-list-item-meta">
                    <CardItem card={card} t={t} />
                </div>
            </div>

            <div style={{ marginBottom: 20 }}>
                <h2 className="ant-list-item-meta-title">
                    {t('modals.linkCard.summaryStep.subHeadings.selectedAccount')}
                </h2>
                <div className="ant-list-item-meta">
                    <AccountItem account={account} companyId={companyId} />
                </div>
            </div>

            {(accessControl.isSuperAdmin || showPhoneNumberField) && ( // XXX allowed - display conditional logic
                <div style={{ marginBottom: 20 }}>
                    <h2 className="ant-list-item-meta-title">
                        {t('modals.linkCard.summaryStep.subHeadings.secure3dSetup')}
                    </h2>

                    {showPhoneNumberField && (
                        <div style={{ marginBottom: 20 }}>
                            <PhoneInput
                                name="phoneNumber"
                                label="Phone Number"
                                isRequiredMessage={t(`modals.linkCard.fields.phoneNumber.${showPhoneNumberRequiredMsgKey}`)}
                                isRequired={showPhoneNumberField}
                                t={t}
                            />
                        </div>
                    )}

                    {accessControl.isSuperAdmin && ( // XXX allowed - only super admin can override
                        <>
                            <div style={{ marginBottom: 10 }}>
                                <Form.Item
                                    name="challengeMethod"
                                    label="Challenge Method"
                                    initialValue={defaultChallengeMethod}
                                >
                                    <Select
                                        onChange={((value) => setChallengeMethod(value))}
                                        placeholder={t('modals.linkCard.fields.challengeMethod.placeholder')}
                                    >
                                        {createCardChallengeMethodsOptions(defaultChallengeMethod)}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div style={{ marginBottom: 10 }}>
                                <Form.Item
                                    name="challengeManager"
                                    label="Challenge Manager"
                                    initialValue={defaultChallengeManager}
                                >
                                    <Select placeholder={t('modals.linkCard.fields.challengeManager.placeholder')}>
                                        {createCardChallengeManagersOptions(defaultChallengeManager)}
                                    </Select>
                                </Form.Item>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}


FinalLinkStep.propTypes = {
    accessControl: AccessControlProps,
    companyId: PropTypes.number.isRequired,
    businessUserId: PropTypes.number.isRequired,
    account: PropTypes.shape({}),
    card: PropTypes.shape({}),
    client3DsSettings: PropTypes.string,
    t: PropTypes.func.isRequired,
};
export default FinalLinkStep;
