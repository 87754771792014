import ClientRenderer from 'components/common/molecules/value-renderers/ClientRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { removeUnderscores } from 'utils/string-tools';

import { FailedWebhookListItem } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React from 'react';


export function createFailedWebhookDetailsCol1FieldsConfig({
    failedWebhook,
    t,
}: {
  t: TFunction,
  failedWebhook: FailedWebhookListItem
}) {
    if (failedWebhook?.uuid) {
        return [
            {
                label: t('webhooks:webhooksFields.actionType.label'),
                showEmpty: true,
                value: <StandardValue value={removeUnderscores(failedWebhook.action)} />,
            },
            {
                label: t('webhooks:webhooksFields.webhookURL.label'),
                showEmpty: true,
                value: <StandardValue value={failedWebhook.webhookUrl} />,
            },
            {
                label: t('webhooks:webhooksFields.apiKey.label'),
                showEmpty: true,
                value: <StandardValue value={failedWebhook.apiKey} />,
            },
        ];
    }

    return [];
}

export function createFailedWebhookDetailsCol2FieldsConfig({
    failedWebhook,
    t,
}: {
  t: TFunction,
  failedWebhook: FailedWebhookListItem,
}) {
    if (failedWebhook?.uuid) {
        return [
            {
                label: t('webhooks:webhooksFields.uuid.label'),
                value: <StandardValue value={failedWebhook.uuid} />,
            },

            {
                label: t('webhooks:webhooksFields.createdAt.label'),
                showEmpty: true,
                value: <StandardValue value={failedWebhook.createdAt} valueType={ValueTypes.DATE} showTime />,
            },
            {
                label: t('webhooks:webhooksFields.updatedAt.label'),
                showEmpty: true,
                value: <StandardValue value={failedWebhook.updatedAt} valueType={ValueTypes.DATE} showTime />,
            },

            {
                label: t('common:partner'),
                showEmpty: true,
                value: <ClientRenderer clientId={failedWebhook.clientId} />,
            },
        ];
    }

    return [];
}

export default {
    createFailedWebhookDetailsCol1FieldsConfig,
    createFailedWebhookDetailsCol2FieldsConfig,
};
