import styled from 'styled-components';


const LayoutOuterWrapper = styled.div`
  display: block;
  height: 100dvh;
`;

// eslint-disable-next-line spellcheck/spell-checker
const LayoutUiWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  transition: 300ms;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;

  &:has(.collapsed) {
    grid-template-columns: 0 minmax(0, 1fr);
  }
`;

const LayoutSpecialElementsWrapper = styled.div`
  display: block;
  height: 0;
  margin: 0;
  border: 0;
  padding: 0;
`;

const LayoutRouteContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  background: #F0F2F5;
  grid-template-rows: minmax(0, auto) minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  overflow: hidden;
  height: 100%;
`;

export default {
    LayoutOuterWrapper,
    LayoutUiWrapper,
    LayoutSpecialElementsWrapper,
    LayoutRouteContentWrapper,
};

