import FlagAndValue from 'components/common/atoms/FlagAndValue';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import booleanIconRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';

import React from 'react';


export const createGroupDetailsFieldsConfig = ({
    groupDetails,
    t,
}) => {
    if (groupDetails?.id) {
        return [
            {
                value: <StandardValue value={groupDetails?.id} valueType={ValueTypes.COUNTER} />,
                label: t('groups:groupsFields.groupId'),
            },
            {
                value: <StandardValue value={groupDetails?.ext_group_id} />,
                label: t('groups:groupsFields.extGroupId'),
            },
            {
                label: t('groups:groupsFields.groupName'),
                value: <StandardValue value={groupDetails?.name} />,
            },
            {
                label: t('groups:groupsFields.defaultGroup'),
                value: booleanIconRenderer(groupDetails, 'is_default', t),
            },
        ];
    }

    return [];
};

export const createGroupDetailsCountersFieldsConfig = ({
    groupDetails,
    t,
}) => {
    if (groupDetails?.id) {
        return [
            {
                value: <StandardValue value={groupDetails?.customer_count} valueType={ValueTypes.COUNTER} />,
                label: t('groups:groupsFields.consumerCount'),
            },

            {
                value: <StandardValue value={groupDetails?.promotion_count} valueType={ValueTypes.COUNTER} />,
                label: t('groups:groupsFields.promotionsCount'),
            },

            {
                value: <StandardValue value={groupDetails?.fee_count} valueType={ValueTypes.COUNTER} />,
                label: t('groups:groupsFields.feesCount'),
            },
            {
                value: <FlagAndValue currencyCode={groupDetails.local_currency} text={groupDetails.local_currency} />,
                label: t('groups:groupsFields.localCurrency'),
            },


        ];
    }

    return [];
};

export default { createGroupDetailsFieldsConfig, createGroupDetailsCountersFieldsConfig };
