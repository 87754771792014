import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import ModalName from 'constants/Modals';
import { showModal } from 'redux/modal/actions';

import React from 'react';


export function createOnOpenRegisterOrEditWebhookAction(payload, i18n) {
    return showModal({
        modalType: ModalName.ADD_EDIT_WEBHOOK_MODAL,
        modalProps: {
            title: payload?.initialData?.uuid
                ? (
                    <StandardTranslationWithMarkup
                        translationKey="modals.addEditWebhookModal.editWebhookModalTitle"
                        namespace="webhooks"
                        values={{ webhookUuid: payload?.initialData?.uuid, actionType: payload?.initialData?.action }}
                    />
                )
                : i18n.t('webhooks:modals.addEditWebhookModal.registerWebhookModalTitle'),
            width: '50%',
            okButtonProps: { disabled: false },
            initialValues: { ...payload.initialData },
            locationPathname: payload.locationPathname,
        },
    });
}


export default { createOnOpenRegisterOrEditWebhookAction };
