import { scrollBar } from 'assets/styles/scrollbar';

import styled from 'styled-components';


const AppLayoutContent = styled.div`
  padding: 16px 16px 0 16px;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  ${scrollBar}

`;

// eslint-disable-next-line spellcheck/spell-checker
const MainContentWrapper = styled.div`
  margin: 0;
  display: grid;
  height: 100%;
`;

const MainContentInnerWrapper = styled.div`
  margin: 0;
  height: 100%;
  
  .standardEntityDetailsWithTabs-headingAndStandardTabsConnectedToUrl-wrapper,
  .standardListTable-headingAndStandardTable-wrapper {
    margin: 0;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: minmax(100%, 1fr);
    grid-row-gap: 16px;
    height: 100%;
  }

  .ant-table-tbody.ant-table-body  {
    white-space: nowrap;
  }
  
  .ant-table-wrapper {
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: 100%;
  }
  
  & > .ant-table-wrapper table {
    min-width: 600px;
  }
  
  .ant-table-container {
    position: relative;
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    height: 100%;
    width: 100%;

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 16px 10px;
      min-width: 60px;
      overflow-wrap: normal;
    }

    .ant-table-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: -6px;
      width: 100%;
      max-height: 100%;
      overflow: auto !important;
      ${scrollBar};
    }

    table {
      width: 100% !important;
      background: #fff;
      
      .ant-table-tbody .ant-table-cell {
        white-space: nowrap;
      }
      
      .ant-table-thead {
        position: sticky;
        top: 0;
        z-index: 20;

        .ant-table-cell {
          white-space: normal;

          /* XXX - hammer to prevent transparency on hover and thus visibility issues on table scroll */
          &:hover {
            background: rgb(250, 250, 250);
          }
        }
      }
    }
  }
  
  .ant-tabs {
    padding: 0 10px;
    background: #fff;
    max-width: 100%;

    .ant-tabs-content {
      height: 100%;
    }
    
    .ant-tabs-tabpane {
      height: 100% !important;
    }
    
    .ant-table-wrapper {
      height: 100%;
    }

    .ant-table-container  {
      min-height: 300px;
    }
  }
  
  .standardListTable-standardTable-wrapper {
    position: relative;
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
    grid-template-columns: minmax(100%, 1fr);
    
    .standardTable--footerWrapper {
      padding: 1rem 0;
      display: flex;
      flex-wrap: wrap;
      gap: 20px 10px;
      justify-content: space-between;
      position: relative;
      
      &.isLoading {
        pointer-events: none;
        user-select: none;
        
        &:after {
          content: "";
          display: block;
          z-index: 10;
          position: absolute;
          background: #fff;
          opacity: 0.5;
          top: 0;
          bottom: 0;
          width: 100%;
        }
      }
      
      .ant-pagination {
        margin: 0 0 0 auto;
      }
    }
  }
  
  .ant-spin-nested-loading {
    height: 100%;
    max-height: 100%;


    .ant-spin {
      max-height: 100% !important;
      
      .ant-spin-dot {
        font-size: 200px !important;
        margin-top: -100px !important;
        margin-left: -100px !important;
      }
      
      .ant-spin-text {
        transform: translate(0, 140px);
      }
    }
  }
  
  .ant-spin-container {
    height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(100%, 1fr);

    /* XXX we are hiding default table pagination purposely */
    .ant-table-pagination {
      display: none !important;
    }
  }
`;

export const TabContentWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(100%, 1fr);
  height: 100%;
`;

export default {
    AppLayoutContent,
    MainContentWrapper,
    MainContentInnerWrapper,
    TabContentWrapper,
};

