import { HttpService } from 'services/http';


export const getRolesAPI = (query) => HttpService.get('/v2/roles', query);
export const getRoleById = (roleId) => HttpService.get(`/v2/roles/${roleId}`);
export const createRoleAPI = (requestPayload) => HttpService.post('/v2/roles', requestPayload);
export const updateRoleAPI = (roleId, requestPayload) => HttpService.put(`/v2/roles/${roleId}`, requestPayload);
export const deleteRoleAPI = (roleId) => HttpService.delete(`/v2/roles/${roleId}`);

export default { getRolesAPI, createRoleAPI, updateRoleAPI, deleteRoleAPI };

