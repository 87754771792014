import { HttpService } from 'services/http';


export const fetchUsersApi = (queryParams = {}) => HttpService.get('/v2/users/', queryParams);

export const fetchUserIdDetails = (userId) => HttpService.get(`/v2/users/${userId}`);

export const createUserApi = (requestPayload) => HttpService.post('/v2/users/', requestPayload);

export const updateUserApi = (userId, requestPayload) => HttpService.put(`/v2/users/${userId}`, requestPayload);

export const deleteUserApi = (userId) => HttpService.delete(`/v2/users/${userId}`);

export const resendConfirmationEmailApi = (userId) => HttpService.post(`/v2/users/resend-email/${userId}`);
