import StandardTooltip from 'components/common/molecules/StandardTooltip';

import PropTypes from 'prop-types';
import React from 'react';

import Styled from './StandardMultiValue.styled';


function StandardMultiValue({
    items = [],
    inlineItemRenderer,
    tooltipItemRenderer,
    itemsToBeShownInline = 3,
}) {
    const visibleItems = items.slice(0, itemsToBeShownInline);
    const itemsInTooltip = items.slice(itemsToBeShownInline);

    return (
        <Styled.MultiValueWrapper>
            <Styled.InlineValues className="multiple-inline-values">
                {visibleItems?.map(inlineItemRenderer)}
            </Styled.InlineValues>

            {itemsInTooltip?.length > 0 && (
                <StandardTooltip title={(
                    <Styled.TooltipValues>
                        {itemsInTooltip?.map(tooltipItemRenderer)}
                    </Styled.TooltipValues>
                )}
                >
                    <Styled.SeeMoreBadge>
                        {`+${itemsInTooltip?.length} more`}
                    </Styled.SeeMoreBadge>
                </StandardTooltip>
            )}

        </Styled.MultiValueWrapper>
    );
}


StandardMultiValue.propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
    inlineItemRenderer: PropTypes.func.isRequired,
    tooltipItemRenderer: PropTypes.func.isRequired,
    itemsToBeShownInline: PropTypes.number,
};

export default StandardMultiValue;
