import { LinkCardSteps } from 'redux/card-linking/helpers';
import { fetchClient3DsSettings, fetchClient3DsSettingsFailure, fetchClient3DsSettingsSuccess } from 'redux/client/actions';
import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';

import {
    clearLinkCard,
    clearLinkCardAccount,
    clearLinkCardProcess,
    selectCardAccountForLink,
    selectCardForLink,
    fetchNotLinkedCards, fetchNotLinkedCardsSuccess, fetchNotLinkedCardsFailure,
    setCardLinkingStep,
    linkCardToMember, linkCardToMemberSuccess, linkCardToMemberFailure,
    linkCardToBusinessUser, linkCardToBusinessUserSuccess, linkCardToBusinessUserFailure,
    linkCardToCompany, linkCardToCompanyFailure, linkCardToCompanySuccess,
    setWithPartnerCards,
    setCardsSearchQuery,
} from './actions';


export const cardLinkingReducerName = 'cardLinking';

const initialState = {
    accountForLink: null,
    cardForLink: null,
    notLinkedCards: [],
    notLinkedCardsCount: null,

    withPartnerCards: false,
    cardSearchQuery: '',

    cardLinkingStep: LinkCardSteps.CARD_SELECTION,
    isLoadingNotLinkedCards: false,
    isBusyLinkingCard: false,
    client3DsSettings: null,
    isLoadingClient3DsSettings: false,
};


const handlers = {
    [selectCardAccountForLink]: (state, action) => {
        state.accountForLink = action.payload;
    },

    [selectCardForLink]: (state, action) => {
        state.cardForLink = action.payload;
    },

    [setWithPartnerCards]: (state, action) => {
        state.withPartnerCards = action.payload;
    },

    [setCardsSearchQuery]: (state, action) => {
        state.cardSearchQuery = action.payload;
    },

    [clearLinkCard]: (state) => {
        state.cardForLink = null;
    },

    [clearLinkCardAccount]: (state) => {
        state.accountForLink = null;
    },

    [setCardLinkingStep]: (state, action) => {
        state.cardLinkingStep = action.payload;
    },

    [linkCardToCompany]: (state) => {
        state.isBusyLinkingCard = true;
    },
    [linkCardToCompanySuccess]: (state) => {
        state.isBusyLinkingCard = false;
    },
    [linkCardToCompanyFailure]: (state) => {
        state.isBusyLinkingCard = false;
    },


    [linkCardToBusinessUser]: (state) => {
        state.isBusyLinkingCard = true;
    },
    [linkCardToBusinessUserSuccess]: (state) => {
        state.isBusyLinkingCard = false;
    },
    [linkCardToBusinessUserFailure]: (state) => {
        state.isBusyLinkingCard = false;
    },

    [linkCardToMember]: (state) => {
        state.isBusyLinkingCard = true;
    },
    [linkCardToMemberSuccess]: (state) => {
        state.isBusyLinkingCard = false;
    },
    [linkCardToMemberFailure]: (state) => {
        state.isBusyLinkingCard = false;
    },
    [fetchNotLinkedCards]: (state) => {
        state.isLoadingNotLinkedCards = true;
    },
    [fetchNotLinkedCardsSuccess]: (state, action) => {
        state.isLoadingNotLinkedCards = false;
        state.notLinkedCards = action.payload.rows;
        state.notLinkedCardsCount = action.payload.count;
    },
    [fetchNotLinkedCardsFailure]: (state) => {
        state.isLoadingNotLinkedCards = false;
    },

    [fetchClient3DsSettings]: (state, action) => {
        if (action.payload.reducerName === cardLinkingReducerName) {
            state.isLoadingClient3DsSettings = true;
        }
    },
    [fetchClient3DsSettingsSuccess]: (state, action) => {
        if (action.payload.reducerName === cardLinkingReducerName) {
            state.isLoadingClient3DsSettings = false;
            state.client3DsSettings = action.payload.data;
        }
    },
    [fetchClient3DsSettingsFailure]: (state, action) => {
        if (action.payload.reducerName === cardLinkingReducerName) {
            state.isLoadingClient3DsSettings = false;
        }
    },

    [clearLinkCardProcess]: () => initialState,
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
