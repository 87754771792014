import { kycKybApproval } from 'constants/KycKybModel';
import { OutstandingDebtTypes } from 'constants/OutstandingDebtModel';

import PropTypes from 'prop-types';


export const AccessControlProps = PropTypes.shape({
    // 0. isAuthorised helper flag
    isAuthorised: PropTypes.bool,

    // 1. most important requirement for ecosystem-wide-access
    // (or in other words how not to be clientId-scope-only)
    isSuperAdmin: PropTypes.bool,


    // 2. client config/settings based flags/feature-toggles
    // if applicable then they are more important than related permissions
    clientConfig: PropTypes.shape({
        generalSettings: PropTypes.shape({
            kyckybApproval: PropTypes.oneOf(Object.keys(kycKybApproval)),
            allowOnlinePayments: PropTypes.bool,
            bwpAppClient: PropTypes.bool,
            apiKeyManagement: PropTypes.bool,
            allowOutgoingPaymentsWithoutBpsAccount: PropTypes.bool,
            allowPhysicalCards: PropTypes.bool,
            allowTechnicalAccounts: PropTypes.bool,
            allowTopup: PropTypes.bool,
            allowVirtualCards: PropTypes.bool,
            alwaysEnforce3DsForTopups: PropTypes.bool,
            autoAcceptTermsAndConditions: PropTypes.bool,
            externalCardVerificationEnabled: PropTypes.bool,
            beneficiariesEnabled: PropTypes.bool,
            externalKyc: PropTypes.bool,
            groupsEnabled: PropTypes.bool,
            isAnnouncementEnabled: PropTypes.bool,
            membersMigrationEnabled: PropTypes.bool,
            searchPhoneAcrossAllClients: PropTypes.bool,
            unlockPhysicalCardsAfterActivated: PropTypes.bool,
            virtualCardsActivation: PropTypes.bool,
            outstandingDebt: PropTypes.oneOf(Object.keys(OutstandingDebtTypes)),
            // TODO update this list and leave only flags used in BOP
        }),
    }),

    // 3. lowest layer - permissions,
    // In BOP only simple actions map to one required permission (eg unlink card button -> cards:unlink)
    // more common are sets of required permissions  (eg link card button -> cards:link + accounts:read + cards:cards:read-by-client-not-linked)
    // and sth sets differ depending on context (eg cards:cards:read-by-client-not-linked vs cards:read-by-company-not-linked)
    hasPermission: PropTypes.func.isRequired, // hasPermission('permission:name')
    hasPermissions: PropTypes.func.isRequired, // hasPermissions(['permissionA:name', ..., 'permissionX:name'])
    hasOneOfPermissions: PropTypes.func.isRequired, // hasOneOfPermissions(['permissionA:name', ..., 'permissionX:name']),
    isKycKybApprovalEnabled: PropTypes.func.isRequired, // returns boolean
    isEntityReadOnly: PropTypes.func.isRequired, // returns STRING (one of ReasonsForEntityIsReadOnly) if true or false otherwise
});

export default { AccessControlProps };

