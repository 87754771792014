import { updateClientProfile } from 'redux/client/actions';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { industriesListsPerLocationStoreKeyName, industriesReducerName } from 'redux/industries/reducer';
import { setModalProps } from 'redux/modal/actions';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import EditClientModal from './EditClientModal.layout';


const mapStateToProps = (state) => ({
    industries: getStandardListData({
        state,
        reducerName: industriesReducerName,
        listsByLocationStoreKeyName: industriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchUpdateClientProfile: (clientId, clientData) => dispatch(updateClientProfile(clientId, clientData)),
});


const decorate = compose(
    withTranslation('clients'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(EditClientModal);

