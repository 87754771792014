/* eslint-disable spellcheck/spell-checker */
import { scrollBar } from 'assets/styles/scrollbar';
import { defaultTheme } from 'config/theme';

import { createGlobalStyle, css } from 'styled-components';


const createSidebarStyles = () => css`
  * {
    --sidebar-background-color: ${defaultTheme.token.sidebarBackgroundColor};
    --sidebar-text-color: ${defaultTheme.token.sidebarTextColor};
    --sidebar-link-color: ${defaultTheme.token.sidebarTextColor};
    --sidebar-menu-background-color: transparent;
    --sidebar-menu-item-active-color: ${defaultTheme.token.sidebarActiveTextColor};
  }
`;

const GlobalCommonElements = createGlobalStyle`
  ${createSidebarStyles()}

  * {
    --primary-color: ${defaultTheme.token.colorPrimary};
    --btn-primary-bg: ${defaultTheme.token.colorPrimary};
  }
  
  
  html {
    height: 100%;
  }
  
  body {
    min-height: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
    font-family: ${defaultTheme.token.fontFamily}; 
    
    ${scrollBar};

    &.ant-scrolling-effect {
      width: 100% !important;
    }

    &.no-scroll {
      overflow: hidden !important;
    }
  }
  
  #root {
    height: 100%;
  }
  
  .width-100 {
    width: 100px;
  }

  .ant-divider-inner-text {
    font-size: 12px;
  }

  .ant-tabs-dropdown {
    z-index: 99999999999999 !important;
  }

  .ant-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .ant-card-cover {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .site-page-header {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .site-layout-background {
    padding-top: 0px !important;
  }

  .appContent {
    flex: 1 1 0%;
    flex-direction: row;
    padding-left: 250px;
    padding-right: 0px;
  }

  .modal-pdf > .ant-modal {
    width: 100% !important;
    max-width: 100% !important;
    top: 0;
    height: 100%;
    padding: 0;
  }

  .modal-pdf > .ant-modal .ant-modal-content {
    height: 100%;
  }

  .modal-pdf > .ant-modal .ant-modal-content .ant-modal-body {
    height: calc(100% - 55px);
    padding: 0;
  }

  .ant-table {
    table-layout: auto;
  }
  

  .ant-table .ant-table-cell > .multiline-ellipsis {
    word-break: break-word;
    display: -webkit-box;
    /* csslint ignore:start */
    -webkit-line-clamp: 3;
    /* csslint ignore:end */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }


  .text-align-center {
    text-align: center;
  }
  
 .ant-table-filter-dropdown .ant-dropdown-menu-item {
   display: grid;
   grid-template-columns: auto minmax(0, 1fr);
   align-items: center;
   
   span {
     display: flex;
     align-items: center;
   }
 }


 .ant-table-tbody .ant-table-cell.mono-font {
   font-family: monospace;
 }

  textarea.ant-input{
    overflow: auto;
    ${scrollBar};
  }
  
 .rc-virtual-list-holder {
   overflow: auto !important;
   overflow-anchor: none;
   ${scrollBar};
 }

  .rc-virtual-list-scrollbar {
    display: none;
  }
  
  .ant-tooltip {
    max-width: none;
  } 
  
  
  .ant-tabs-dropdown .ant-tabs-dropdown-menu-item svg {
    margin-right: 12px;
  }

  .ant-drawer-body {
    overflow: auto !important;
    ${scrollBar};
  }

  .ant-select .anticon-spin {
    color: ${defaultTheme.token.colorPrimary};
  }
`;


export default GlobalCommonElements;
