import { clientRootRoutePath, importMembersRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    downloadMigrationReport,
    downloadMigrationReportSuccess,
    downloadMigrationReportFailure,
    uploadClientMigrationFile,
    uploadClientMigrationFileSuccess,
    uploadClientMigrationFileFailure, clearClientMigrationsList, fetchClientMigrationsListFailure, fetchClientMigrationsList, fetchClientMigrationsListSuccess,
} from './actions';


export const clientMigrationsReducerName = 'clientMigrations';
export const clientMigrationsListsPerLocationStoreKeyName = 'clientMigrationsListsPerLocation';

// no API support for pagination
export const clientMigrationsQueryParams = {
    limit: 10,
    offset: 0,
    sortBy: 'created_at',
    sortOrder: SortDirections.DESCEND,
    clientId: undefined,
};

const initialState = {
    [clientMigrationsListsPerLocationStoreKeyName]: {},
    isLoadingClientMigrationReport: false,
    isUploadingClientMigrationReport: false,
};

const handlers = {
    [downloadMigrationReport]: (state) => {
        state.isLoadingClientMigrationReport = true;
    },
    [downloadMigrationReportSuccess]: (state) => {
        state.isLoadingClientMigrationReport = false;
    },
    [downloadMigrationReportFailure]: (state) => {
        state.isLoadingClientMigrationReport = false;
    },

    [uploadClientMigrationFile]: (state) => {
        state.isUploadingClientMigrationReport = true;
    },
    [uploadClientMigrationFileSuccess]: (state) => {
        state.isUploadingClientMigrationReport = false;
    },
    [uploadClientMigrationFileFailure]: (state) => {
        state.isUploadingClientMigrationReport = false;
    },


    [fetchClientMigrationsList]: (state, action) => {
        state[clientMigrationsListsPerLocationStoreKeyName] = updateStandardLists(state[clientMigrationsListsPerLocationStoreKeyName], action);
    },
    [fetchClientMigrationsListSuccess]: (state, action) => {
        state[clientMigrationsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[clientMigrationsListsPerLocationStoreKeyName], action);
    },
    [fetchClientMigrationsListFailure]: (state, action) => {
        state[clientMigrationsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[clientMigrationsListsPerLocationStoreKeyName], action);
    },
    [clearClientMigrationsList]: (state, action) => {
        state[clientMigrationsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[clientMigrationsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[clientMigrationsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[clientMigrationsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [importMembersRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === clientMigrationsReducerName
      && action.payload?.fieldName === clientMigrationsListsPerLocationStoreKeyName
        ) {
            state[clientMigrationsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[clientMigrationsListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
