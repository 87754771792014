import withAccessControl from 'hocs/withAccessControl';
import { navigationReducerName } from 'redux/navigation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import GlobalListsPage from './GlobalListsPage.layout';


const mapStateToProps = (state) => ({ enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation });

const decorate = compose(
    withAccessControl,
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(GlobalListsPage);

