import { requestNavigation } from 'redux/navigation/actions';

import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import StandardLink from './StandardLink.layout';
import { StandardLinkDispatchProps, StandardLinkOwnProps } from './StandardLink.types';


const mapDispatchToProps = (dispatch: Dispatch): StandardLinkDispatchProps => ({
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    //
});

const decorate = compose<ComponentType<StandardLinkOwnProps>>(
    withTranslation('common'),
    connect(null, mapDispatchToProps),
);

export default decorate(StandardLink);
