import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Styled from './CopyToClipboard.styled';


function CopyToClipboard({
    value,
    clipboardContent,
    dispatchSetClipboardContent,
    t,
}) {
    const isInClipboard = useMemo(
        () => clipboardContent === value,
        [clipboardContent, value],
    );

    return (
        <div>
            <Styled.CopyButton
                type="link"
                data-test-id={`copy-to-clipboard-${value}`}
                onClick={() => dispatchSetClipboardContent(value)}
                isInClipboard={isInClipboard}
            >
                <Tooltip title={t(`${isInClipboard ? 'Copied!' : 'Copy to Clipboard'}`)}>
                    <CopyOutlined />
                </Tooltip>
            </Styled.CopyButton>
        </div>
    );
}

CopyToClipboard.propTypes = {
    clipboardContent: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    dispatchSetClipboardContent: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default CopyToClipboard;
