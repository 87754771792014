import { operationsReducerName, roleTemplatesListsPerLocationStoreKeyName } from 'redux/operations/reducer';
import { toggleSaveRoleAsRoleTemplate } from 'redux/role/actions';
import { roleReducerName } from 'redux/role/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ReviewStep from './ReviewStep.layout';


const mapStateToProps = (state, ownProps) => {
    const { locationPathname } = ownProps;
    return {
        selectedPermissions: state[roleReducerName]?.selectedPermissions,
        saveRoleAsRoleTemplate: state[roleReducerName]?.saveRoleAsRoleTemplate,
        rolesTemplatesLists: state[operationsReducerName]?.[roleTemplatesListsPerLocationStoreKeyName]?.[locationPathname]?.items,
    };
};

const mapDispatchToProps = (dispatch) => ({ dispatchToggleSaveRoleAsRoleTemplate: () => dispatch(toggleSaveRoleAsRoleTemplate()) });

const decorate = compose(
    withTranslation('roles'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ReviewStep);
