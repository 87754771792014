import CountryOrCurrencyFlag from 'components/common/atoms/CountryOrCurrencyFlag';

import { Avatar, List } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function AccountItem({ account, companyId }) {
    return (
        <List.Item.Meta
            avatar={(
                <Avatar
                    size={64}
                    shape="circle"
                    icon={<CountryOrCurrencyFlag currencyCode={account.currency.iso || account.currency} />}
                />
            )}
            title={`${companyId > 0 ? account.name : account.type} - ${account.currency}`}
            description={`${parseFloat(account.available_balance).toFixed(2)}`}
        />
    );
}


AccountItem.propTypes = {
    account: PropTypes.shape({}),
    companyId: PropTypes.number,
};


export default AccountItem;
