import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_OPERATIONS_LIST,
    FETCH_OPERATIONS_LIST,
    FETCH_OPERATIONS_LIST_FAILURE,
    FETCH_OPERATIONS_LIST_SUCCESS,
    CLEAR_ROLES_TEMPLATES_LIST,
    FETCH_ROLES_TEMPLATES_LIST,
    FETCH_ROLES_TEMPLATES_LIST_SUCCESS,
    FETCH_ROLES_TEMPLATES_LIST_FAILURE,
} from './actions.types';


export const fetchOperationsList = createAction(FETCH_OPERATIONS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchOperationsListSuccess = createAction(FETCH_OPERATIONS_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchOperationsListFailure = createAction(FETCH_OPERATIONS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearOperationsList = createAction(CLEAR_OPERATIONS_LIST, (locationPathname) => ({ payload: { locationPathname } }));

export const fetchRolesTemplatesList = createAction(FETCH_ROLES_TEMPLATES_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchRolesTemplatesListSuccess = createAction(FETCH_ROLES_TEMPLATES_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchRolesTemplatesListFailure = createAction(FETCH_ROLES_TEMPLATES_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearRolesTemplatesList = createAction(CLEAR_ROLES_TEMPLATES_LIST, (locationPathname) => ({ payload: { locationPathname } }));
