import { LocationChangeActionScopeTypes, NavigationDirection } from 'constants/NavigationModel';
import { EnhancedLocation, LocationPathname } from 'models/app/navigation';
import {
    EntityDetailsQueryParams,
    StandardEntityDetailsData,
} from 'models/app/standardEntityDetails';

import { useEffect, useMemo, useState } from 'react';


export function useStandardDetailsPageCachingLogic<ItemDefinition = any>({
    enhancedCurrentLocation,
    baseUrlWithoutTabsSlugs,

    contextEnforcedQueryParams,
    entityDetailsData,

    dispatchFetchEntityDetails,
    dispatchClearEntityDetails,
    dispatchSetQueryParams,
}: {
  enhancedCurrentLocation?: EnhancedLocation,
  baseUrlWithoutTabsSlugs: LocationPathname,
  contextEnforcedQueryParams: EntityDetailsQueryParams,
  entityDetailsData: StandardEntityDetailsData<ItemDefinition>,
  dispatchFetchEntityDetails: (queryParams: EntityDetailsQueryParams, locationPathname: LocationPathname) => void,
  dispatchSetQueryParams: (queryParams: EntityDetailsQueryParams, locationPathname: LocationPathname) => void,
  dispatchClearEntityDetails: (locationPathname: LocationPathname) => void,
}): boolean {
    const [areInitialQueryParamsSet, setAreInitialQueryParamsSet] = useState(false);
    const [doesDetailsPageRequiresCleanup, setDoesDetailsPageRequiresCleanup] = useState(false);
    const [isDetailsPageReadyForDisplay, setIsDetailsPageReadyForDisplay] = useState(false);


    // InitialQuery === default query params + query params enforced by context/props
    const initialQueryParams = { ...contextEnforcedQueryParams };

    const hasValidLocation = useMemo(
        () => enhancedCurrentLocation?.pathname && enhancedCurrentLocation?.pathname?.length > 0 && enhancedCurrentLocation?.pathname.includes(baseUrlWithoutTabsSlugs),
        [enhancedCurrentLocation, baseUrlWithoutTabsSlugs],
    );

    const isDetailsPagePristineEmpty = useMemo(
        () => hasValidLocation && !entityDetailsData?.queryParamsMeta?.location?.pathname?.includes(baseUrlWithoutTabsSlugs),
        [hasValidLocation, entityDetailsData],
    );


    const onLocationChangeHandlers = [
    // no location === no details fetching because at least ID/UUID is mandatory (possibly with other optional query params)
    // and it comes form URL params
        {
            predicate: () => !hasValidLocation,
            handler: () => undefined,
        },
        // returning to on given location via forward navigation
        // & clear existing data for given location and fetch new with initialQueryParams, spinner ON
        {
            predicate: () => hasValidLocation
        && !isDetailsPagePristineEmpty
        && !doesDetailsPageRequiresCleanup
        && enhancedCurrentLocation?.direction === NavigationDirection.FORWARD
        && enhancedCurrentLocation?.state?.type !== LocationChangeActionScopeTypes.TAB_CHANGE,
            handler: () => {
                dispatchClearEntityDetails(baseUrlWithoutTabsSlugs);
                setDoesDetailsPageRequiresCleanup(true);
                setAreInitialQueryParamsSet(true);
                dispatchSetQueryParams(initialQueryParams, baseUrlWithoutTabsSlugs);
                dispatchFetchEntityDetails(initialQueryParams, baseUrlWithoutTabsSlugs);
            },
        },
        // returning to on given location via go back navigation, just display what is already there, spinner OFF
        {
            predicate: () => hasValidLocation
        && !isDetailsPagePristineEmpty
        && enhancedCurrentLocation?.direction === NavigationDirection.BACKWARD,

            handler: () => {
                setIsDetailsPageReadyForDisplay(true);
            },
        },

        // 1st time on given location, set queryParams and fetch data, spinner ON
        {
            predicate: () => hasValidLocation
        && isDetailsPagePristineEmpty
        && !areInitialQueryParamsSet
        && !doesDetailsPageRequiresCleanup,
            handler: () => {
                dispatchSetQueryParams(initialQueryParams, baseUrlWithoutTabsSlugs);
                setAreInitialQueryParamsSet(true);
                dispatchFetchEntityDetails(initialQueryParams, baseUrlWithoutTabsSlugs);
            },
        },
        // 1st time on given location but view linked transaction case
        {
            predicate: () => hasValidLocation
        && isDetailsPagePristineEmpty
        && areInitialQueryParamsSet
        && isDetailsPageReadyForDisplay
        && !doesDetailsPageRequiresCleanup,
            handler: () => {
                setIsDetailsPageReadyForDisplay(false);
                dispatchSetQueryParams(initialQueryParams, baseUrlWithoutTabsSlugs);
                setAreInitialQueryParamsSet(true);
                dispatchFetchEntityDetails(initialQueryParams, baseUrlWithoutTabsSlugs);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];

    const onEntityDetailsDataChangeHandlers = [
    // 1st time on given location - PART TWO, data was fetched, spinner OFF
        {
            predicate: () => hasValidLocation
        && !isDetailsPagePristineEmpty
        && areInitialQueryParamsSet
        && !doesDetailsPageRequiresCleanup
        && !entityDetailsData?.isLoadingDetails, // XXX important
            handler: () => {
                setIsDetailsPageReadyForDisplay(true);
            },
        },
        // returning to on given location via forward navigation - PART II (after clearing, new data has arrived), spinner OFF
        {
            predicate: () => hasValidLocation
        && !isDetailsPagePristineEmpty
        && areInitialQueryParamsSet
        && doesDetailsPageRequiresCleanup
        && !entityDetailsData?.isLoadingDetails, // XXX important
            handler: () => {
                setDoesDetailsPageRequiresCleanup(false);
                setIsDetailsPageReadyForDisplay(true);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];


    useEffect(() => {
        onLocationChangeHandlers.filter(({ predicate }) => predicate())[0].handler();
    }, [baseUrlWithoutTabsSlugs]);

    useEffect(() => onEntityDetailsDataChangeHandlers.filter(({ predicate }) => predicate())[0].handler(), [entityDetailsData]);

    return isDetailsPageReadyForDisplay;
}

export default useStandardDetailsPageCachingLogic;
