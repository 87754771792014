import { globalLocation } from 'constants/NavigationModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrencies, fetchCurrencies } from 'redux/currencies/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CurrenciesList from './CurrenciesList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchCurrencies: (queryParams) => dispatch(fetchCurrencies(queryParams)),
    dispatchClearCurrencies: () => dispatch(clearCurrencies()),
    dispatchSetQueryParams: (queryParams) => dispatch(setQueryParams({
        reducerName: currenciesReducerName,
        fieldName: currenciesListsPerLocationStoreKeyName,
        locationPathname: globalLocation,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CurrenciesList);
