import { statusHistoryRouteSegmentPath } from 'config/routes';
import { showSuccessToast } from 'redux/application/actions';
import { fetchCardDetails } from 'redux/card/actions';
import { cardDetailsPerLocationStoreKeyName, cardReducerName } from 'redux/card/reducer';
import { cardStatusHistoryListPerLocationStoreKeyName, cardStatusHistoryReducerName } from 'redux/card-status-history/reducer';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { FETCH_CARD_STATUS_HISTORY, UPDATE_CARD_STATUS } from './action.types';
import { fetchCardStatusHistory, fetchCardStatusHistoryFailure, fetchCardStatusHistorySuccess, updateCardStatusFailure, updateCardStatusSuccess } from './actions';


export const onFetchCardStatusHistory = (action$, state$, { cards }) => action$.pipe(
    ofType(FETCH_CARD_STATUS_HISTORY),
    switchMap(({ payload }) => from(cards.getCardStatusHistory(payload.queryParams)).pipe(
        switchMap((response) => of(fetchCardStatusHistorySuccess(
            response?.data?.rows,
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchCardStatusHistoryFailure(payload.locationPathname))),
    )),
);

export const onUpdateCardStatus = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(UPDATE_CARD_STATUS),
    switchMap(({ payload: { requestPayload: { cardId, ...rest }, locationPathname } }) => from(cards.updateCardDetails(cardId, rest)).pipe(
        switchMap((response) => {
            const amendedLocation = `${locationPathname}/${statusHistoryRouteSegmentPath}`;
            const currentListData = state$.value[cardStatusHistoryReducerName][cardStatusHistoryListPerLocationStoreKeyName]?.[amendedLocation];

            const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName][locationPathname]?.queryParams;
            const commonActions = [
                updateCardStatusSuccess(response.data),
                fetchCardDetails(currentCardDetailsQueryParams, locationPathname),
                showSuccessToast(i18n.t('cards:actionMessages.changeCardStatus')),
            ];

            if (currentListData) {
                // update the list if it exists
                return of(
                    ...commonActions,
                    fetchCardStatusHistory(currentListData?.queryParams, amendedLocation),
                );
            }

            return of(...commonActions);
        }),
        catchError(() => of(updateCardStatusFailure())),
    )),
);

export default [
    onFetchCardStatusHistory,
    onUpdateCardStatus,
];
