import styled from 'styled-components';


const minHeight = 50;

const OverdueInvoiceWarningWrapper = styled.div`
    display: grid;
    align-items: center;
    min-height: ${minHeight}px;
  
    .ant-alert {
      width: 100%;
      min-height: ${minHeight}px;
      padding: 4px 20px 4px 70px;
      border-top: none;
      border-right: none;
      display: grid;
      border-left: none;
      grid-template-columns: min-content minmax(0, auto);
      align-items: center;
      justify-content: center;
    }
`;

export default { OverdueInvoiceWarningWrapper };
