import { clearAllPasscodeDigits, setPasscodeDigit } from 'redux/authorisation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DigitsCodeInput from './DigitsCodeInput.layout';


const mapDispatchToProps = (dispatch) => ({
    dispatchSetPasscodeDigit: (key, value) => dispatch(setPasscodeDigit(key, value)),
    dispatchClearAllPasscodeDigits: () => dispatch(clearAllPasscodeDigits()),
});

const decorate = compose(
    withTranslation('unauthorised'),
    connect(null, mapDispatchToProps),
);

export default decorate(DigitsCodeInput);
