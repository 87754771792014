import FlagAndValue from 'components/common/atoms/FlagAndValue';
import AccountInfoLayout from 'components/common/organisms/AccountInfoLayout';
import BeneficiaryInfoLayout from 'components/common/organisms/BeneficiaryInfoLayout';
import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';
import { amountFormatter } from 'utils/number-tools';

import { UserOutlined, BankOutlined, DollarOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Row, Col, Spin } from 'antd';
import React, { useMemo } from 'react';

import Styled from './Review.styled';
import { ReviewStepProps } from './Review.types';
import BackButton from '../../BackButton';


const loadingIcon = <LoadingOutlined spin />;

function ReviewStep({
    t,
    formData,
    accountDetails,
    beneficiaryDetails,
    isLoadingMakeOutgoingTransfer,
}: ReviewStepProps) {
    const createTransferDetailsFieldsConfig = (accountDetails, formData, t) => {
        if (accountDetails.id) {
            return [
                {
                    label: t('transactions:outgoingTransfer.steps.transferDetails.fields.amount.text'),
                    value: <FlagAndValue currencyCode={accountDetails.currency} text={`${amountFormatter({ value: formData?.amount })} ${accountDetails.currency}`} valueFirst />,
                },
                {
                    label: t('transactions:outgoingTransfer.steps.transferDetails.fields.reference.text'),
                    value: formData.reference,
                },
                ...(formData.reason
                    ? [{
                        label: t('transactions:outgoingTransfer.steps.transferDetails.fields.reason.text'),
                        value: formData.reason,
                    }]
                    : []
                ),
            ];
        }
        return [];
    };

    const fieldsConfig = useMemo(
        () => createTransferDetailsFieldsConfig(accountDetails, formData, t),
        [accountDetails, formData, t],
    );

    return (
        <Spin
            tip="Creating transfer..."
            spinning={isLoadingMakeOutgoingTransfer}
            indicator={loadingIcon}
        >
            <Col>
                <Styled.Columns>
                    {accountDetails && (
                        <Styled.Column>
                            <Styled.ColumnContent>
                                <Styled.ColumnHeading>
                                    {t('transactions:outgoingTransfer.steps.review.fields.selectedAccount.text')}
                                </Styled.ColumnHeading>
                                <AccountInfoLayout
                                    accountDetails={accountDetails}
                                />
                            </Styled.ColumnContent>
                            <Styled.DecorIcon><BankOutlined /></Styled.DecorIcon>
                        </Styled.Column>
                    )}

                    {beneficiaryDetails && (
                        <Styled.Column>
                            <Styled.ColumnContent>
                                <Styled.ColumnHeading>{t('transactions:outgoingTransfer.steps.review.fields.selectedBeneficiary.text')}</Styled.ColumnHeading>
                                <BeneficiaryInfoLayout
                                    beneficiaryDetails={convertSnakeCaseKeysToCamelCase(beneficiaryDetails)}
                                />
                            </Styled.ColumnContent>
                            <Styled.DecorIcon><UserOutlined /></Styled.DecorIcon>
                        </Styled.Column>
                    )}

                    <Styled.Column>
                        <Styled.ColumnContent>
                            <Styled.ColumnHeading>
                                {t('transactions:outgoingTransfer.steps.review.fields.transferDetails.text')}
                            </Styled.ColumnHeading>
                            <Styled.TransferDetails>
                                {fieldsConfig.map((item, index) => (!item.value ? null : (
                                    <LabelAndValueRow
                                        key={`${index}-${item.label}`}
                                        label={item.label}
                                        value={item.value}
                                    />
                                )))}
                            </Styled.TransferDetails>
                        </Styled.ColumnContent>
                        <Styled.DecorIcon><DollarOutlined /></Styled.DecorIcon>
                    </Styled.Column>

                </Styled.Columns>

                <Row key="form buttons" style={{ marginTop: 20 }}>
                    <BackButton />

                    <Form.Item>
                        <Button
                            htmlType="submit"
                            disabled={isLoadingMakeOutgoingTransfer}
                            type="primary"
                        >
                            {t('common:buttons.submit.text')}
                        </Button>
                    </Form.Item>
                </Row>
            </Col>
        </Spin>
    );
}

export default ReviewStep;
