import { standardPageSize } from 'config/config';
import { clientRootRoutePath, companyRootRoutePath, riskScoreHistoryRouteSegmentPath } from 'config/routes';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { clearCurrentUser } from 'redux/current-user/actions';
import { PREFORM_NAVIGATION_SUCCESS } from 'redux/navigation/actions';
import {
    CLEAR_RISK_SCORE_HISTORY_LIST,
    FETCH_RISK_SCORE_HISTORY_LIST,
    FETCH_RISK_SCORE_HISTORY_LIST_FAILURE,
    FETCH_RISK_SCORE_HISTORY_LIST_SUCCESS,
} from 'redux/risk-score-history/actions.types';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const riskScoreHistoryReducerName = 'riskScoreHistory';
export const riskScoreHistoryListsPerLocationStoreKeyName = 'riskScoreHistoryListsPerLocation';


export const defaultRiskScoreHistoryQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: undefined,
    sortOrder: undefined,
};


const initialState = {
    [riskScoreHistoryListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    // ?
};


const handlers = {
    [FETCH_RISK_SCORE_HISTORY_LIST]: (state, action) => {
        state[riskScoreHistoryListsPerLocationStoreKeyName] = updateStandardLists(state[riskScoreHistoryListsPerLocationStoreKeyName], action);
    },
    [FETCH_RISK_SCORE_HISTORY_LIST_SUCCESS]: (state, action) => {
        state[riskScoreHistoryListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[riskScoreHistoryListsPerLocationStoreKeyName], action);
    },
    [FETCH_RISK_SCORE_HISTORY_LIST_FAILURE]: (state, action) => {
        state[riskScoreHistoryListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[riskScoreHistoryListsPerLocationStoreKeyName], action);
    },
    [CLEAR_RISK_SCORE_HISTORY_LIST]: (state, action) => {
        state[riskScoreHistoryListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[riskScoreHistoryListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [PREFORM_NAVIGATION_SUCCESS]: (state, action) => {
        state[riskScoreHistoryListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[riskScoreHistoryListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [riskScoreHistoryRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath, companyRootRoutePath],
            },
        );
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === riskScoreHistoryReducerName
        && action.payload?.fieldName === riskScoreHistoryListsPerLocationStoreKeyName
        ) {
            state[riskScoreHistoryListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[riskScoreHistoryListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
