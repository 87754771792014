import { PN } from 'constants/PermissionsModel';
import { AccessControlContent } from 'models/domain/accessControl';

import { MemberDetails, MemberStatuses, SharedEntityStatuses } from '@manigo/manigo-domain-typings';
import { useMemo } from 'react';


const useMemberActionChecks = ({ memberDetails, accessControl }:{
  memberDetails: MemberDetails,
  accessControl: AccessControlContent,
}) => {
    const canSuspend = useMemo(() => accessControl.hasPermission(PN.MEMBERS.SUSPEND)
    && ![MemberStatuses.SUSPENDED,
        SharedEntityStatuses.PENDING_CLOSURE,
        SharedEntityStatuses.CLOSED,
        MemberStatuses.BANNED,
    ].includes(memberDetails?.memberStatus),
    [accessControl, memberDetails?.memberStatus]);

    const canUnSuspend = useMemo(() => accessControl.hasPermission(PN.MEMBERS.UNSUSPEND)
    && memberDetails?.memberStatus === MemberStatuses.SUSPENDED, [accessControl, memberDetails?.memberStatus]);

    const canBan = useMemo(() => accessControl.hasPermission(PN.MEMBERS.BAN)
    && ![MemberStatuses.SUSPENDED,
        SharedEntityStatuses.PENDING_CLOSURE,
        SharedEntityStatuses.CLOSED,
        MemberStatuses.BANNED].includes(memberDetails?.memberStatus), [accessControl, memberDetails?.memberStatus]);

    const canUnBan = useMemo(() => accessControl.hasPermission(PN.MEMBERS.UNBAN)
    && [MemberStatuses.BANNED].includes(memberDetails?.memberStatus), [accessControl, memberDetails?.memberStatus]);

    const canActivate = useMemo(() => accessControl.hasPermission(PN.MEMBERS.ACTIVATE)
    && accessControl.isKycKybApprovalEnabled()
    && [MemberStatuses.UNVERIFIED].includes(memberDetails?.memberStatus), [accessControl, memberDetails?.memberStatus]);

    const canStartOffboarding = useMemo(() => [
        SharedEntityStatuses.PENDING_CLOSURE,
        SharedEntityStatuses.CLOSED,
    ].includes(memberDetails?.memberStatus) && accessControl.hasPermission(PN.OFFBOARDING.SET_PENDING_CLOSURE), [memberDetails?.memberStatus]);

    const canStopOffboarding = useMemo(() => [SharedEntityStatuses.PENDING_CLOSURE].includes(memberDetails?.memberStatus)
   && accessControl.hasPermission(PN.OFFBOARDING.REINSTATE_PENDING_CLOSURE), [memberDetails?.memberStatus]);

    const canFinaliseOffboarding = useMemo(() => [SharedEntityStatuses.PENDING_CLOSURE]
   && accessControl.hasPermission(PN.OFFBOARDING.FINALISE_CLOSED), [memberDetails?.memberStatus]);

    return {
        canSuspend,
        canUnSuspend,
        canBan,
        canUnBan,
        canActivate,
        canStartOffboarding,
        canStopOffboarding,
        canFinaliseOffboarding,
    };
};

export interface MemberActionChecks {
  canSuspend: boolean,
  canUnSuspend: boolean,
  canBan: boolean,
  canUnBan: boolean,
  canActivate: boolean,
  canStartOffboarding: boolean,
  canStopOffboarding: boolean,
  canFinaliseOffboarding: boolean
}

export default useMemberActionChecks;
