import { CountriesListProps } from 'constants/CountriesModel';
import { IndustriesListProps } from 'constants/IndustriesModel';
import { countriesOfOperationsOptions, createCompanyTypesOptions, createIndustryOptions } from 'utils/options-creators';

import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import Styled from './EditClientModal.styled';


function EditClientModal({
    industries,
    countries,
    initialValues,
    dispatchSetModalProps,
    dispatchUpdateClientProfile,
    t,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);

    const values = { ...initialValues };

    const industryOptions = useMemo(
        () => createIndustryOptions(industries),
        [industries],
    );

    const countryOptions = useMemo(
        () => countriesOfOperationsOptions(countries),
        [countries],
    );

    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });
        dispatchUpdateClientProfile(initialValues.id, values);
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            initialValues={values}
        >
            <Styled.TwoColumns>
                <div>
                    <Form.Item
                        name="company_registration_number"
                        label="Company Registration Number"
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="company_legal_name"
                        label={t('Company Legal Name')}
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="countries_of_operations"
                        label="Countries Of Operations"
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Select mode="multiple" allowClear placeholder="Select Countries" optionFilterProp="label">
                            {countryOptions}
                        </Select>
                    </Form.Item>


                    <Form.Item
                        name="company_business_type"
                        label="Business Type"
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Select placeholder={t('Select business type')}>
                            {createCompanyTypesOptions()}
                        </Select>
                    </Form.Item>
                </div>

                <div>
                    <Form.Item name="company_vat_registration_number" label="VAT / Tax Identification number">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="company_dba_name"
                        label="Company DBA/Trade Name"
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item name="industries" label="Industries">
                        <Select mode="multiple" allowClear placeholder="Select Industry" optionFilterProp="label">
                            {industryOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item name="website" label="Website">
                        <Input />
                    </Form.Item>
                </div>
            </Styled.TwoColumns>
        </Form>
    );
}

EditClientModal.propTypes = {
    industries: IndustriesListProps,
    countries: CountriesListProps,
    dispatchUpdateClientProfile: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default EditClientModal;
