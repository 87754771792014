import styled from 'styled-components';

// eslint-disable-next-line spellcheck/spell-checker
const ProductItemTileWrapper = styled.div`
  position: relative;
  float: left;
  width: 280px;
  height: 280px;
  border: 1px solid #F0F0F0;
  margin: 10px;
  transition: border 200ms ease;

  &:hover {
    border: 1px solid #397dcc;
  }
  
  &:focus-within {
    border: 1px solid #FF00FF;
  }
`;

const ProductItemTileDecorWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;

  img {
    top: 0;
    bottom: 0;
    right: -50%;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.08;
  }
`;

const ProductItemContentWrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 2;
  padding: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const RemoveProductWrapper = styled.div`
  top: 2px;
  right: 2px;
  position: absolute;
  z-index: 3;
  
  button {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    font-size: 26px;
    line-height: 0;
    color: #000;
    cursor: pointer;
    
    &:hover {
      color: red;
    }
  }
`;

const CurrencySymbol = styled.h2`
  font-family: monospace;
  font-size: 30px;
  font-weight: 700;
  margin-top: 0;
`;

const CurrencyName = styled.h3`
  outline: none;
`;

const HiddenFieldsWrapper = styled.h3`
  overflow: hidden;
  height: 0;
  margin: 0;
  padding: 0;
`;


export default {
    ProductItemTileWrapper,
    ProductItemContentWrapper,
    RemoveProductWrapper,
    ProductItemTileDecorWrapper,
    CurrencyName,
    CurrencySymbol,
    HiddenFieldsWrapper,
};
