import PropTypes from 'prop-types';


export const TemplateModel = Object.freeze({
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    entity_type: PropTypes.string.isRequired,
    criteria_name: PropTypes.string.isRequired,
    questionnaire_id: PropTypes.string.isRequired,
    ext_item_id: PropTypes.string.isRequired,
    include_in_final_score: PropTypes.bool.isRequired,
    risk_rating: PropTypes.string.isRequired,
    risk_score: PropTypes.number.isRequired,
    criteria_value: PropTypes.string.isRequired,
});

export const RiskAssessmentModel = Object.freeze({
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    ext_client_id: PropTypes.string.isRequired,
    ext_company_id: PropTypes.string,
    ext_business_user_id: PropTypes.string,
    ext_member_id: PropTypes.string,
    risk_assessment_scoring_template_id: PropTypes.string.isRequired,
    assessed_value: PropTypes.string.isRequired,
    calculated_risk_rating: PropTypes.string,
    calculated_risk_score: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string,
    template: PropTypes.shape(TemplateModel),
    ...TemplateModel,
});

export const RiskCriteriaName = Object.freeze({
    RESIDENCY: 'RESIDENCY',
    NATIONALITY: 'NATIONALITY',
    INDUSTRY: 'INDUSTRY',
    ANNUAL_CARD_SPEND: 'ANNUAL_CARD_SPEND',
    AGE: 'AGE',
    EMPLOYMENT_STATUS: 'EMPLOYMENT_STATUS',
});

export const RiskRating = Object.freeze({
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
});

export default RiskAssessmentModel;
