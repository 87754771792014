import { TabContentWrapper } from 'components/layouts/AuthorisedLayout/StandardPageWrapper/StandardPageWrapper.styled';
import { StandardTabConfigProps, tabKeySubTabKeySeparator } from 'constants/ApplicationStateModel';
import { isUndefined } from 'utils/object-tools';

import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createTabKey, onTabCLickHandler } from './StandardTabsConnectedToUrl.helpers';
import Styled from './StandardTabsConnectedToUrl.styled';


function StandardTabsConnectedToUrl({
    tabPosition = 'top',
    destroyInactiveTabPane = false,
    isSubTab,
    tabsConfig,

    activeMainTab,
    activeSubTab,
    enhancedCurrentLocation,
    baseURLSegmentsCount,
    dispatchRequestNavigation,
}) {
    const activeKey = useMemo(
        () => (isSubTab ? `${activeMainTab}${tabKeySubTabKeySeparator}${activeSubTab}` : activeMainTab),
        [enhancedCurrentLocation, activeMainTab, isSubTab, activeSubTab],
    );
    const createTabItemConfig = (tabConfig, index, activeSubTab, parentActiveMainTabSegmentURL) => {
        // XXX very important
        const tabKey = createTabKey({ tabConfig, index });


        const tabLabel = (
            <span>
                {tabConfig.icon}
                {tabConfig.label}
            </span>
        );

        return {
            label: tabLabel,
            key: tabKey,
            children: tabConfig?.subTabsConfig?.length > 0
                ? (
                    <StandardTabsConnectedToUrl
                        isSubTab
                        activeMainTab={activeMainTab}
                        activeSubTab={activeSubTab}
                        baseURLSegmentsCount={baseURLSegmentsCount}
                        tabPosition={'left'}
                        parentActiveMainTabSegmentURL={parentActiveMainTabSegmentURL}
                        tabsConfig={tabConfig?.subTabsConfig}
                        dispatchRequestNavigation={dispatchRequestNavigation}
                        enhancedCurrentLocation={enhancedCurrentLocation}
                    />
                )
                : (
                    <TabContentWrapper>
                        {tabConfig.content}
                    </TabContentWrapper>
                ),
        };
    };

    const getLoadingContent = () => isUndefined(tabsConfig)
        ? (
            <Styled.StyledStandardTabsSkeletonWrapper>
                <Skeleton
                    active
                    loading
                    paragraph={{ rows: 14, width: '100%' }}
                    title={false}
                />
            </Styled.StyledStandardTabsSkeletonWrapper>

        ) : null;

    return tabsConfig?.length > 0 && ((!isSubTab && activeMainTab) || (isSubTab && activeSubTab))
        ? (
            <Styled.StyledStandardTabsWrapper>
                <Styled.StyledStandardTabs
                    activeKey={activeKey}
                    destroyInactiveTabPane={destroyInactiveTabPane}
                    tabPosition={tabPosition}
                    items={tabsConfig.map((tabConfig, index) => createTabItemConfig(tabConfig, index, activeSubTab, activeMainTab))}
                    onTabClick={(currentTabKey) => onTabCLickHandler({
                        currentTabKey,
                        enhancedCurrentLocation,
                        dispatchRequestNavigation,
                        tabsConfig,
                        baseURLSegmentsCount,
                    })}
                />
            </Styled.StyledStandardTabsWrapper>
        ) : getLoadingContent();
}


StandardTabsConnectedToUrl.propTypes = {
    destroyInactiveTabPane: PropTypes.bool,
    tabsConfig: StandardTabConfigProps,
    tabPosition: PropTypes.string,
    baseURLSegmentsCount: PropTypes.number,
    activeSubTab: PropTypes.string,
    isSubTab: PropTypes.bool,
    activeMainTab: PropTypes.string,
    dispatchRequestNavigation: PropTypes.func,
};

export default StandardTabsConnectedToUrl;
