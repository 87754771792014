import ClientInfo from 'components/pages/authorised/ClientDetails/ClientInfo/ClientInfo.layout';
import withAccessControl from 'hocs/withAccessControl';
import { openEditAddressModal } from 'redux/address/actions';
import { openEditClientProfileModal } from 'redux/client/actions';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { industriesListsPerLocationStoreKeyName, industriesReducerName } from 'redux/industries/reducer';
import { hideModal, showModal } from 'redux/modal/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const mapStateToProps = (state) => ({
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    industries: getStandardListData({
        state,
        reducerName: industriesReducerName,
        listsByLocationStoreKeyName: industriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});

const mapDispatchToProps = (dispatch) => ({
    showModal: (data) => dispatch(showModal(data)),
    hideModal: () => dispatch(hideModal()),
    dispatchOpenEditAddressModal: ({ modalType, modalTitle, addressId, initialValues, addressType }) => dispatch(
        openEditAddressModal({ modalType, modalTitle, addressId, initialValues, addressType }),
    ),
    dispatchOpenEditClientProfileModal: (clientDetails) => dispatch(openEditClientProfileModal(clientDetails)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
});


const decorate = compose(
    withTranslation('clients'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ClientInfo);
