import { currentUserProfileRootRoutePath } from 'config/routes';

import { UserOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Popover, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from './UserDropdown.styled';


function UserDropdown({
    currentUser,
    dispatchRequestNavigation,
    dispatchClearCurrentUser,
    t,
}) {
    const createDropdownMenu = ({ t, dispatchRequestNavigation, dispatchClearCurrentUser }) => (
        <>
            <Styled.UserDropdownOption>
                <Button
                    type="link"
                    onClick={() => dispatchRequestNavigation(currentUserProfileRootRoutePath)}
                >
                    <UserOutlined />
                    {t('View user profile')}
                </Button>
            </Styled.UserDropdownOption>

            <Styled.UserDropdownOption>
                <Button
                    type="link"
                    onClick={() => dispatchClearCurrentUser()}
                >
                    <PoweroffOutlined />
                    {t('Log out')}
                </Button>
            </Styled.UserDropdownOption>
        </>
    );

    return (
        <Styled.UserDropdownWrapper>
            <Popover
                showCancel={false}
                okText={t('Close')}
                trigger="click"
                placement="bottom"
                getPopupContainer={(trigger) => trigger.parentElement}
                content={createDropdownMenu({ t, dispatchRequestNavigation, dispatchClearCurrentUser })}
            >
                <Styled.UserDropdownContent>
                    <Styled.UserDropdownNameWrapper>
                        <Styled.UserDropdownFirstName>
                            {currentUser.userData.firstName}
                        </Styled.UserDropdownFirstName>
                        <Styled.UserDropdownLastName>
                            {currentUser.userData.lastName}
                        </Styled.UserDropdownLastName>
                    </Styled.UserDropdownNameWrapper>
                    <Styled.UserDropdownUserIcon />
                    <Styled.CaretOutlined />
                </Styled.UserDropdownContent>
            </Popover>
        </Styled.UserDropdownWrapper>
    );
}

UserDropdown.propTypes = {
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchClearCurrentUser: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
};


export default UserDropdown;
