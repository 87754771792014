import StandardListTable from 'components/common/organisms/StandardListTable';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import MembersMigrationType from 'constants/MembersMigrationType';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { clientMigrationsQueryParams } from 'redux/client-migrations/reducer';

import { UsergroupAddOutlined, DownloadOutlined, DollarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { CSVDownloadModal, CSVUploadModal } from './MembersMigration.helpers';
import createClientMigrationsListColumnsConfig from './tableColumns';


function MembersMigration({
    // own props
    clientId,

    // XXX  list state data
    enhancedCurrentLocation,
    listData,

    // other mapped props
    accessControl,
    isLoadingClientMigrationReport,
    isUploadingClientMigrationReport,
    dispatchShowModal,
    dispatchHideModal,
    dispatchFetchClientMigrationsList,
    dispatchClearClientMigrationsList,

    dispatchDownloadMigrationResult,
    dispatchDownloadMigrationReport,
    dispatchSetQueryParams,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { clientId };

    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: clientMigrationsQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchClientMigrationsList,
        dispatchClearItemsList: dispatchClearClientMigrationsList,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependent optional B. list  button
    const buttons = useMemo(
        () => ([
            ...(accessControl.hasPermission(PN.CLIENTS.MIGRATIONS.REPORT.GET.CSV) ? [{
                type: 'secondary',
                text: 'Generate report',
                dataTestId: 'generate-report',
                icon: <DownloadOutlined />,
                isLoading: isLoadingClientMigrationReport,
                onClick: () => CSVDownloadModal({
                    clientId,
                    dispatchShowModal,
                    dispatchHideModal,
                    dispatchDownloadMigrationReport,
                    enhancedCurrentLocation,
                }),
            }] : []),

            ...(accessControl.hasPermission(PN.CLIENTS.MIGRATIONS.BALANCES.CREATE) ? [{
                type: 'primary',
                text: 'Migrate balances',
                dataTestId: 'upload-balances-migration-file',
                icon: <DollarOutlined />,
                isLoading: isUploadingClientMigrationReport,
                onClick: () => CSVUploadModal({
                    enhancedCurrentLocation,
                    dispatchShowModal,
                    clientId,
                    clientMigrationType: MembersMigrationType.BALANCES,
                }),
            }] : []),

            ...(accessControl.hasPermission(PN.CLIENTS.MIGRATIONS.MEMBERS.CREATE) ? [{
                type: 'primary',
                text: 'Migrate members',
                dataTestId: 'upload-members-migration-file',
                icon: <UsergroupAddOutlined />,
                isLoading: isUploadingClientMigrationReport,
                onClick: () => CSVUploadModal({
                    enhancedCurrentLocation,
                    dispatchShowModal,
                    clientId,
                    clientMigrationType: MembersMigrationType.MEMBERS,
                }),
            }] : []),

        ]), [accessControl, clientId],
    );
    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchClientMigrationsList({ ...contextEnforcedQueryParams }, enhancedCurrentLocation.pathname)}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createClientMigrationsListColumnsConfig({
                clientId,
                dispatchDownloadMigrationResult,
                queryParams: listData?.queryParams,
                enhancedCurrentLocation,
            })}
            filtersButtonsConfig={buttons}
        />
    );
}


MembersMigration.propTypes = {
    // mapped props
    accessControl: AccessControlProps,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    isLoadingClientMigrationReport: PropTypes.bool.isRequired,
    isUploadingClientMigrationReport: PropTypes.bool.isRequired,
    dispatchShowModal: PropTypes.func.isRequired,
    dispatchHideModal: PropTypes.func.isRequired,
    dispatchDownloadMigrationResult: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    dispatchFetchClientMigrationsList: PropTypes.func.isRequired,
    dispatchClearClientMigrationsList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchDownloadMigrationReport: PropTypes.func.isRequired,
};


export default MembersMigration;
