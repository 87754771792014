import { createClientDetailsPath } from 'config/routes.helpers';
import { CurrenciesListProp } from 'constants/CurrencyModel';
import { ProductListProp } from 'constants/ProductModel';
import { filterOptions } from 'utils/filterOptions';
import { createAccountTypeOptions, createCurrencyOptions } from 'utils/options-creators';

import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { parseProductsList } from './AddAccountModal.helpers';


function AddAccountModal({
    currenciesListsData,

    enhancedCurrentLocation,
    listData,

    clientConfiguration,
    config,
    dispatchFetchClientById,
    dispatchFetchClientProducts,
    dispatchAddAccount,
    dispatchSetModalProps,
    t,
}) {
    const [form] = Form.useForm();
    const {
        clientId,
        setName = true,
        ownerType,
        memberId,
        companyId,
        allowedAccountTypes,
    } = config;


    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });

        if (!clientConfiguration) {
            dispatchFetchClientById({ clientId }, createClientDetailsPath(clientId));
        }

        if (!listData?.items) {
            dispatchFetchClientProducts({ clientId }, enhancedCurrentLocation.pathname); // XXX  full list, no pagination
        }
    }, [config]);


    const [allowedCombinations, setAllowedCombinations] = useState({});
    const [availableAccountTypes, setAvailableAccountTypes] = useState([]);
    const [availableCurrencyOptions, setAvailableCurrencyOptions] = useState([]);
    const [selectedAccountType, setSelectedAccountType] = useState(null);

    const allowTechnical = useMemo(
        () => clientConfiguration?.allow_technical_accounts,
        [clientConfiguration],
    );


    useEffect(() => {
        if (listData?.items?.length > 0) {
            const data = parseProductsList({ allProducts: listData.items, allowTechnical, allowedAccountTypes, currencies: currenciesListsData.items });
            setAllowedCombinations(data.combinations);
            setAvailableAccountTypes(data.availableAccountTypes);
            // if there is only one option auto-select it
            if (data.availableAccountTypes.length === 1) {
                const type = data.availableAccountTypes[0];
                form.setFieldsValue({ type });
                setSelectedAccountType(type);
                setAvailableCurrencyOptions(data.combinations[type]);
                // if there is only one option auto-select it
                if (data.combinations[type].length === 1) {
                    form.setFieldsValue({ currency: data.combinations[type][0].short });
                }
            }
        }
    }, [config, listData, allowTechnical, allowedAccountTypes, currenciesListsData]);


    const accountTypeOptions = useMemo(
        () => createAccountTypeOptions(availableAccountTypes),
        [availableAccountTypes],
    );

    const currencyOptions = useMemo(
        () => createCurrencyOptions(availableCurrencyOptions, t, true),
        [availableCurrencyOptions, selectedAccountType],
    );


    const updateCurrencyList = (accountType) => {
        form.resetFields(['currency']);
        setSelectedAccountType(accountType);
        const availableCurrencies = allowedCombinations[`${accountType}`];

        // // update product options
        setAvailableCurrencyOptions(availableCurrencies);
        // if there is only one option auto-select it
        if (availableCurrencies.length === 1) {
            form.setFieldsValue({ currency: availableCurrencies[0].short });
        }
    };

    const onFinish = (values) => {
        const requestPayload = {
            ...values,
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(!memberId > 0 && !companyId > 0 ? { clientId } : {}),
            ...(!setName ? { name: `${values.currency} ${values.type}` } : {}),
        };

        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });
        dispatchAddAccount(ownerType, requestPayload, enhancedCurrentLocation.pathname);
    };

    return (
        <div data-test-id="add-account-modal">
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onFinish}
            >
                <Form.Item
                    name="type"
                    label="Account Type"
                    rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                >
                    <Select
                        placeholder={'Select account type'}
                        loading={listData?.isLoadingList}
                        onChange={updateCurrencyList}
                        data-test-id="account-type-select"
                    >
                        {accountTypeOptions}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="currency"
                    label="Currency"
                    rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                >
                    <Select
                        showSearch
                        filterOption={filterOptions}
                        loading={listData?.isLoadingList || currenciesListsData?.isLoadingList}
                        disabled={!selectedAccountType}
                        placeholder={!selectedAccountType ? 'Select account type first' : 'Select currency'}
                        data-test-id="currency-select"
                    >
                        {currencyOptions}
                    </Select>
                </Form.Item>

                {setName && (
                    <Form.Item
                        name="name"
                        label="Account Name"
                        rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                    >
                        <Input placeholder={t('Enter account name')} />
                    </Form.Item>
                )}
            </Form>
        </div>
    );
}

AddAccountModal.propTypes = {
    clientConfiguration: PropTypes.shape({ allow_technical_accounts: PropTypes.bool.isRequired }).isRequired,
    currenciesListsData: PropTypes.shape({ items: CurrenciesListProp, isLoadingList: PropTypes.bool }),
    listData: PropTypes.shape({ items: ProductListProp, isLoadingList: PropTypes.bool }),
    config: PropTypes.shape({
        clientId: PropTypes.number.isRequired,
        setName: PropTypes.bool,
        ownerType: PropTypes.string,
        memberId: PropTypes.number,
        companyId: PropTypes.number,
        allowedAccountTypes: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    dispatchFetchClientById: PropTypes.func.isRequired,
    dispatchFetchClientProducts: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchAddAccount: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,

};

export default AddAccountModal;
