import StandardFormSection from 'components/common/molecules/StandardFormSection';
import Styled from 'components/common/organisms/StandardMultiStepForm/FormStepsRenderer/FormStepsRenderer.styled';
import { AccessControlProps } from 'constants/AccessControlModel';
import { MultiStepFormStepSectionFieldsLayout } from 'constants/MultiStepsFormModel';

import { Switch, Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createFirstBusinessUserFromFieldsConfig, createFirstBusinessUserFromOptionalFieldsConfig } from './FirstBusinessUser.helpers';


export function FirstBusinessUser({
    accessControl,
    t,
}) {
    const formInstance = Form.useFormInstance();


    const createBusinessUser = Form.useWatch('businessUser.createBusinessUser', formInstance);
    const isAStakeholder = Form.useWatch('businessUser.isAStakeholder', formInstance);
    const sendInvitation = Form.useWatch('businessUser.sendInvitation', formInstance);

    const userFields = useMemo(
        () => createFirstBusinessUserFromFieldsConfig({ t }),
        [],
    );

    const userOptionalFields = useMemo(
        () => createFirstBusinessUserFromOptionalFieldsConfig({ t, isAStakeholder }),
        [isAStakeholder],
    );


    return (
        <div>
            <Styled.StandardFormSectionWrapper colCount={1}>
                <StandardFormSection
                    key={`StandardFormSection-${0}`}
                    index={0}
                    t={t}
                    formSectionConfig={{
                        title: 'Create optional Business User',
                        children: (
                            <Form.Item name="businessUser.createBusinessUser">
                                <Switch checked={createBusinessUser} />
                            </Form.Item>
                        ),
                    }}
                />

                {createBusinessUser ? (
                    <>
                        <StandardFormSection
                            key={`StandardFormSection-${1}`}
                            formSectionConfig={{
                                fieldsLayout: MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL,
                                title: 'Business user data',
                                fieldsConfiguration: userFields,
                            }}
                            index={1}
                            t={t}
                        />

                        <StandardFormSection
                            key={`StandardFormSection-${2}`}
                            index={2}
                            t={t}
                            formSectionConfig={{
                                title: t('businessUsers:businessUserFields.stakeholder.placeholder'),
                                children: (
                                    <Form.Item
                                        name="businessUser.isAStakeholder"
                                        label="Business user is also a stakeholder"
                                    >
                                        <Switch checked={isAStakeholder} />
                                    </Form.Item>
                                ),
                            }}
                        />


                        {isAStakeholder ? (
                            <StandardFormSection
                                key={`StandardFormSection-${3}`}
                                index={3}
                                t={t}
                                formSectionConfig={{
                                    fieldsLayout: MultiStepFormStepSectionFieldsLayout.AUTO_HORIZONTAL,
                                    title: '',
                                    fieldsConfiguration: userOptionalFields,
                                }}
                            />
                        ) : null}

                        <Form.Item
                            name="businessUser.sendInvitation"
                            label="Send invitation email with activation link leading to BWP"
                        >
                            <Switch
                                checked={sendInvitation}
                                disabled={!accessControl.clientConfig?.generalSettings?.bwpAppClient}
                            />
                        </Form.Item>
                    </>
                ) : null}

            </Styled.StandardFormSectionWrapper>
        </div>
    );
}

FirstBusinessUser.propTypes = {
    accessControl: AccessControlProps,
    t: PropTypes.func,
};

export default FirstBusinessUser;

