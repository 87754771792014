import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { clearFailedWebhookDetails, fetchFailedWebhookDetails } from 'redux/failed-webhook/actions';
import { failedWebhookDetailsPerLocationsStoreKeyName, failedWebhookReducerName } from 'redux/failed-webhook/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import FailedWebhookDetailsPage from './FailedWebhookDetailsPage.layout';
import {
    FailedWebhookDetailsPageDispatchProps,
    FailedWebhookDetailsPageStateProps,
} from './FailedWebhookDetailsPage.types';


const mapStateToProps = (state: RootState): FailedWebhookDetailsPageStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: failedWebhookReducerName,
        fieldName: failedWebhookDetailsPerLocationsStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
});

const mapDispatchToProps = (dispatch: Dispatch): FailedWebhookDetailsPageDispatchProps => ({
    dispatchFetchFailedWebhookDetails: (queryParams, locationPathname) => dispatch(fetchFailedWebhookDetails(queryParams, locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: failedWebhookReducerName,
        fieldName: failedWebhookDetailsPerLocationsStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchClearFailedWebhookDetails: (locationPathname) => dispatch(clearFailedWebhookDetails(locationPathname)),
});

const decorate = compose(
    withTranslation('webhooks'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(FailedWebhookDetailsPage);
