import withAccessControl from 'hocs/withAccessControl';
import withIsClientActive from 'hocs/withIsClientActive';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';


import StandardListTable from './StandardListTable.layout';


const decorate = compose(
    withTranslation('common'),
    withIsClientActive,
    withAccessControl,
);

export default decorate(StandardListTable);
