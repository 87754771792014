import HttpStatus from 'constants/HttpStatus';
import { showErrorToast } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';

import { ApiError } from '../../models/api/http';


export const withTotalCount = { withTotal: true };
export const withSkipImages = { skipImages: true };

export const createErrorActions = (error) => {
    const handlers = [
        {
            predicate: () => error.unauthorized,
            handler: () => [clearCurrentUser()],
        },
        {
            predicate: () => true,
            handler: () => [showErrorToast(error.message || 'Something went wrong.')],
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createApiError = async (axiosError): Promise<ApiError> => {
    const { config } = axiosError;
    const data = await axiosError.request.responseType === 'blob'
  && axiosError.response.data instanceof Blob
        ? JSON.parse(await axiosError.response.data.text())
        : axiosError.response?.data;

    const status = axiosError.response?.status || HttpStatus.IM_A_TEAPOT;

    const error = new Error(data?.message || data || '') as ApiError;
    error.messageCode = data?.message_code || data?.code;
    error.handled = !config.noErrorHandling;
    error.forbidden = status === HttpStatus.FORBIDDEN;
    error.unauthorized = status === HttpStatus.UNAUTHORIZED || (status === HttpStatus.INTERNAL_SERVER_ERROR && axiosError.message === 'jwt expired');
    error.offline = status === HttpStatus.SERVICE_UNAVAILABLE || status === HttpStatus.GATEWAY_TIMEOUT;
    error.status = status;
    error.data = data;
    error.actions = createErrorActions(error);

    return error;
};
