import React from 'react';

import Styled from './ReadOnlyIndicator.styled';


export function createReadOnlyIndicatorTooltipContent({ readOnlyInfo, t }) {
    const fieldsConfig = [
        {
            value: readOnlyInfo.entityId,
            label: t('readOnlyInfo.readOnlyNavbarTooltip.entityId'),
        },
        {
            value: readOnlyInfo.entityType,
            label: t('readOnlyInfo.readOnlyNavbarTooltip.entityType'),
        },
        ...(readOnlyInfo.entityStatus ? [{
            value: readOnlyInfo.entityStatus,
            label: t('readOnlyInfo.readOnlyNavbarTooltip.entityStatus'),
        }] : []),
        {
            value: t(`readOnlyInfo.readOnlyNavbarTooltip.reasonForReadOnly.values.${readOnlyInfo.readOnlyStateReason}`, { ...readOnlyInfo?.otherDetails }),
            label: t('readOnlyInfo.readOnlyNavbarTooltip.reasonForReadOnly.label'),
        },
    ];


    return (
        <Styled.TooltipContent>
            <Styled.TooltipHeadingCopy>
                {t('readOnlyInfo.readOnlyNavbarTooltip.headingCopy')}
            </Styled.TooltipHeadingCopy>

            <hr />

            <Styled.TooltipContentDetailsTable>
                <tbody>
                    {fieldsConfig.map((field) => (
                        <tr key={field.label}>
                            <td>
                                {field.label}
                :
                            </td>
                            <Styled.TooltipContentValue>
                                {field.value}
                            </Styled.TooltipContentValue>
                        </tr>
                    ))}
                </tbody>
            </Styled.TooltipContentDetailsTable>
        </Styled.TooltipContent>
    );
}

export default { createReadOnlyIndicatorTooltipContent };

