import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';

import { Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createPromotionFieldsCol1, createPromotionFieldsCol2 } from './PromotionInfo.helpers';


function PromotionInfo({
    t,
    promotionDetails,
    areDetailsReady,
    isBusy,
    isLoadingToggleEnableDisable,
    dispatchEnablePromotion,
    dispatchDisablePromotion,
    locationPathname,
    onRefresh,
    queryParamsMeta,
}) {
    const fieldsCol1 = useMemo(
        () => createPromotionFieldsCol1({
            promotionDetails,
            isLoadingToggleEnableDisable,
            dispatchEnablePromotion,
            dispatchDisablePromotion,
            locationPathname,
            t,
        }),
        [promotionDetails],
    );

    const fieldsCol2 = useMemo(
        () => createPromotionFieldsCol2(promotionDetails, t),
        [promotionDetails],
    );

    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={!areDetailsReady && isBusy}
                minHeight={'300px'}
                sectionsRowsCount={8}
                sectionsCount={2}
            >
                <Spin
                    tip={t('common:actionMsg.genericSpinnerText')}
                    spinning={isLoadingToggleEnableDisable}
                >
                    <DetailsSectionsWrapper>
                        <Row gutter={24} size={10} direction="horizontal">
                            <CommonSection fieldsConfig={fieldsCol1} />

                            <CommonSection fieldsConfig={fieldsCol2} />
                        </Row>

                        <QueryParamsMetaInfo
                            detailsContext
                            isLoading={isBusy}
                            queryParams={{ promotionUuid: promotionDetails?.ext_id }}
                            queryParamsMeta={queryParamsMeta}
                            onListRefresh={onRefresh}
                        />
                    </DetailsSectionsWrapper>
                </Spin>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}

PromotionInfo.propTypes = {
    t: PropTypes.func.isRequired,
    areDetailsReady: PropTypes.bool,
    isBusy: PropTypes.bool,
    isLoadingToggleEnableDisable: PropTypes.bool.isRequired,
    dispatchEnablePromotion: PropTypes.func.isRequired,
    dispatchDisablePromotion: PropTypes.func.isRequired,
    onRefresh: PropTypes.func,
    locationPathname: PropTypes.string,
};

export default PromotionInfo;
