import { MultiStepFormCommonStepsDefinitions } from './MultiStepsFormModel';


export const AddEditRoleStepDefinitions = Object.freeze({
    ROLE_DETAILS: 'ROLE_DETAILS',
    TEMPLATE_SELECTION: 'TEMPLATE_SELECTION',
    OPERATIONS_LIST: 'SETTINGS',
    SUMMARY: 'SUMMARY',
    SUCCESS: MultiStepFormCommonStepsDefinitions.SUCCESS,
});

// XXX order DOES matter
export const AddRoleSteps = Object.freeze([
    AddEditRoleStepDefinitions.ROLE_DETAILS,
    AddEditRoleStepDefinitions.TEMPLATE_SELECTION,
    AddEditRoleStepDefinitions.OPERATIONS_LIST,
    AddEditRoleStepDefinitions.SUMMARY,
    AddEditRoleStepDefinitions.SUCCESS,
]);

export const EditRoleSteps = Object.freeze([
    AddEditRoleStepDefinitions.ROLE_DETAILS,
    AddEditRoleStepDefinitions.OPERATIONS_LIST,
    AddEditRoleStepDefinitions.SUMMARY,
    AddEditRoleStepDefinitions.SUCCESS,
]);

// XXX order DOES matter


export default {
    AddEditRoleStepDefinitions,
    AddRoleSteps,
    EditRoleSteps,
};
