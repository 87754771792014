import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { WL_CONFIG } from 'config/config';

import PropTypes from 'prop-types';
import React from 'react';

import Styled from './Attributions.styled';
import { attributionsList } from './assetsList';


function Attributions({ t }) {
    return (
        <>
            <StandardPageHeading title={t('Attributions')} />

            <StandardPageWrapper>
                <Styled.PageWrapper>

                    <p>As a startup, whenever it is possible, we like to use high quality free graphic assets & software libraries.</p>
                    <p>...but, some valuable assets use Attribution License.</p>
                    <p>Bellow is the full list of such assets used in this web application.</p>

                    <h2>{`${WL_CONFIG.brandShortName} always plays fair!`}</h2>

                    <Styled.AttributionsWrapper>
                        {attributionsList.map((item, index) => (
                            <Styled.AttributionWrapper key={index}>
                                <Styled.ImageWrapper>
                                    <img
                                        src={item.assetSrc}
                                        alt={item.name}
                                    />
                                </Styled.ImageWrapper>

                                <Styled.CopyAndLinkWrapper>
                                    <Styled.Name>
                                        {item.name}
                                    </Styled.Name>
                                    <a
                                        href={item.attributionURL}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {item.attributionURL}
                                    </a>
                                </Styled.CopyAndLinkWrapper>
                            </Styled.AttributionWrapper>
                        ))}
                    </Styled.AttributionsWrapper>

                    <Styled.SvgConversionNote>
                        {/* eslint-disable-next-line max-len */}
            If SVG file was not provided in download set then SVG version was obtained by conversion (usually form .ai file format) using free online tool (cloudconvert.com) & optimized (svgoptimizer.com).
                    </Styled.SvgConversionNote>
                </Styled.PageWrapper>
            </StandardPageWrapper>
        </>
    );
}


Attributions.propTypes = { t: PropTypes.func.isRequired };

export default Attributions;
