/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { getAllOperationsAPI, getAllRolesTemplatesAPI } from './index';


class OperationsService {
    public getAllOperations(): Promise<ApiResponse | ApiError> {
        return getAllOperationsAPI();
    }

    public getAllRolesTemplates(): Promise<ApiResponse | ApiError> {
        return getAllRolesTemplatesAPI();
    }
}

export default OperationsService;
