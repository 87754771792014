import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchOperationsListSuccess, fetchOperationsListFailure, fetchRolesTemplatesListFailure, fetchRolesTemplatesListSuccess } from './actions';
import { FETCH_OPERATIONS_LIST, FETCH_ROLES_TEMPLATES_LIST } from './actions.types';


export const onFetchOperationsList = (action$, state$, { operations }) => action$.pipe(
    ofType(FETCH_OPERATIONS_LIST),
    switchMap(({ payload }) => from(operations.getAllOperations()).pipe(
        switchMap((response) => of(fetchOperationsListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchOperationsListFailure(payload.locationPathname))),
    )),
);

export const onFetchRolesTemplatesList = (action$, state$, { operations }) => action$.pipe(
    ofType(FETCH_ROLES_TEMPLATES_LIST),
    switchMap(({ payload }) => from(operations.getAllRolesTemplates()).pipe(
        switchMap((response) => of(fetchRolesTemplatesListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchRolesTemplatesListFailure(payload.locationPathname))),
    )),
);
export default [
    onFetchOperationsList,
    onFetchRolesTemplatesList,
];
