import { NumericId } from '@manigo/manigo-domain-typings';


export enum OutgoingTransferStep {
  ACCOUNT_SELECTION = 'ACCOUNT_SELECTION',
  BENEFICIARY_SELECTION = 'BENEFICIARY_SELECTION',
  TRANSFER_DETAILS = 'TRANSFER_DETAILS',
  REVIEW = 'REVIEW',
}

export const OutgoingTransferSteps = Object.values(OutgoingTransferStep);

export interface AccountSelectionStepFormData {
  accountId: NumericId,
}

export interface BeneficiarySelectionStepFormData {
  beneficiaryId: NumericId,
}

export interface TransferDetailsStepFormData {
  amount: number,
  reference: string,
  reason?: string,
}

export interface OutgoingTransferFormData extends AccountSelectionStepFormData, BeneficiarySelectionStepFormData, TransferDetailsStepFormData {}

export type OutgoingTransferForms = AccountSelectionStepFormData | BeneficiarySelectionStepFormData | TransferDetailsStepFormData;
