/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';
import { getPermissionsByRoleId } from 'services/permissions/index';


class PermissionsService {
    public getPermissionsByRole(roleId): Promise<ApiResponse | ApiError> {
        return getPermissionsByRoleId(roleId);
    }
}

export default PermissionsService;
