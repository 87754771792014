import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_ROLES_LIST,
    FETCH_ROLES_LIST,
    FETCH_ROLES_LIST_FAILURE,
    FETCH_ROLES_LIST_SUCCESS,
} from './actions.types';


export const fetchRolesList = createAction(FETCH_ROLES_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchRolesListSuccess = createAction(FETCH_ROLES_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchRolesListFailure = createAction(FETCH_ROLES_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearRolesList = createAction(CLEAR_ROLES_LIST, (locationPathname) => ({ payload: { locationPathname } }));

