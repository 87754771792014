import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import { Button } from 'antd';
import styled from 'styled-components';


const LinkStyleButton = styled(Button)`
  padding: 0;
  max-width: 100%;
  ${commonTextEllipsisStyles};
  cursor: pointer;
  
  &:disabled {
    cursor: not-allowed !important;
    color: var(--ant-color-text-disabled) !important;
  }
  
  .anticon {
    margin-right: 1ch;
  }
  
  * {
    cursor: inherit !important;  
  }
`;


export default { LinkStyleButton };
