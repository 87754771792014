import withAccessControl from 'hocs/withAccessControl';
import { industriesListsPerLocationStoreKeyName, industriesReducerName } from 'redux/industries/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import IndustriesSelect from './IndustriesSelect.layout';


const mapStateToProps = (state) => ({
    listData: getStandardListData({
        state,
        reducerName: industriesReducerName,
        listsByLocationStoreKeyName: industriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
});

const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps),
);

export default decorate(IndustriesSelect);
