export const FETCH_CLIENT_DETAILS = 'FETCH_CLIENT_DETAILS';
export const FETCH_CLIENT_DETAILS_SUCCESS = 'FETCH_CLIENT_DETAILS_SUCCESS';
export const FETCH_CLIENT_DETAILS_FAILURE = 'FETCH_CLIENT_DETAILS_FAILURE';
export const CLEAR_CLIENT_DETAILS = 'CLEAR_CLIENT_DETAILS';

export const FETCH_CLIENT_ADDRESSES = 'FETCH_CLIENT_ADDRESSES';
export const FETCH_CLIENT_ADDRESSES_SUCCESS = 'FETCH_CLIENT_ADDRESSES_SUCCESS';
export const FETCH_CLIENT_ADDRESSES_FAILURE = 'FETCH_CLIENT_ADDRESSES_FAILURE';

export const OPEN_ADD_CLIENT_RISK_DETAILS_MODAL = 'OPEN_ADD_CLIENT_RISK_DETAILS_MODAL';

export const ADD_CLIENT_RISK_DETAILS = 'ADD_CLIENT_RISK_DETAILS';
export const ADD_CLIENT_RISK_DETAILS_SUCCESS = 'ADD_CLIENT_RISK_DETAILS_SUCCESS';
export const ADD_CLIENT_RISK_DETAILS_FAILURE = 'ADD_CLIENT_RISK_DETAILS_SUCCESS';


export const OPEN_EDIT_CLIENT_PROFILE_MODAL = 'OPEN_EDIT_CLIENT_PROFILE_MODAL';
export const UPDATE_CLIENT_PROFILE = 'UPDATE_CLIENT_PROFILE';
export const UPDATE_CLIENT_PROFILE_SUCCESS = 'UPDATE_CLIENT_PROFILE_SUCCESS';
export const UPDATE_CLIENT_PROFILE_FAILURE = 'UPDATE_CLIENT_PROFILE_FAILURE';

export const UPDATE_CLIENT_SETTINGS = 'UPDATE_CLIENT_SETTINGS';
export const UPDATE_CLIENT_SETTINGS_SUCCESS = 'UPDATE_CLIENT_SETTINGS_SUCCESS';
export const UPDATE_CLIENT_SETTINGS_FAILURE = 'UPDATE_CLIENT_SETTINGS_FAILURE';

export const FETCH_CLIENT_3DS_SETTINGS = 'FETCH_CLIENT_3DS_SETTINGS';
export const FETCH_CLIENT_3DS_SETTINGS_SUCCESS = 'FETCH_CLIENT_3DS_SETTINGS_SUCCESS';
export const FETCH_CLIENT_3DS_SETTINGS_FAILURE = 'FETCH_CLIENT_3DS_SETTINGS_FAILURE';

export const UPDATE_CLIENT_ANNOUNCEMENT = 'UPDATE_CLIENT_ANNOUNCEMENT';
export const UPDATE_CLIENT_ANNOUNCEMENT_SUCCESS = 'UPDATE_CLIENT_ANNOUNCEMENT_SUCCESS';
export const UPDATE_CLIENT_ANNOUNCEMENT_FAILURE = 'UPDATE_CLIENT_ANNOUNCEMENT_FAILURE';
