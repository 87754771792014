import ActivityLogTable from 'components/pages/authorised/CardDetails/Tabs/Secure3d/ActivityLogTable/ActivityLogTable.layout';
import LoadingStatus from 'constants/LoadingStatus';
import { fetchCardSecure3dActivityLogs } from 'redux/card-secure3d/actions';
import { card3dSecureReducerName } from 'redux/card-secure3d/reducer';
import { showViewJsonModal } from 'redux/modal/actions';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const mapStateToProps = (state) => ({
    logs: state[card3dSecureReducerName].logs,
    isLoading: state[card3dSecureReducerName].isLoadingActivityLogs === LoadingStatus.PENDING,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetch3DSecureActivityLogs: (cardId) => dispatch(fetchCardSecure3dActivityLogs(cardId)),
    dispatchShowViewJsonModal: (dataToDisplay, modalTitle) => dispatch(showViewJsonModal(dataToDisplay, modalTitle)),
});


const connectedActivityLogTable = connect(mapStateToProps, mapDispatchToProps)(ActivityLogTable);

export default withTranslation('cards')(connectedActivityLogTable);
