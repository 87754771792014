import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Review from './Review.layout';
import { ReviewStepStateProps } from './Review.types';


const mapStateToProps = (state: RootState): ReviewStepStateProps => ({
    formData: state.outgoingTransfer.formData,
    beneficiaryDetails: state.outgoingTransfer.beneficiaryDetails,
    accountDetails: state.outgoingTransfer.accountDetails,
    isLoadingMakeOutgoingTransfer: state.outgoingTransfer.isLoadingMakeOutgoingTransfer,
});

const decorate = compose<ComponentType>(
    connect(mapStateToProps),
    withTranslation('beneficiaries'),
);

export default decorate(Review);
