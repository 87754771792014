import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearFeeTiersList, fetchFeeTiersList } from 'redux/fee-tiers/actions';
import { feeTiersListPerLocationStoreKeyName, feeTiersReducerName } from 'redux/fee-tiers/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import FeeTiersList from './FeeTiersList.layout';


const mapStateToProps = (state) => ({
    listData: getStandardListData({
        state,
        reducerName: feeTiersReducerName,
        listsByLocationStoreKeyName: feeTiersListPerLocationStoreKeyName,
    }),
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchFeeTiersList: (queryParams, locationPathname) => dispatch(fetchFeeTiersList(queryParams, locationPathname)),
    dispatchClearFeeTiersList: (locationPathname) => dispatch(clearFeeTiersList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: feeTiersReducerName,
        fieldName: feeTiersListPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('fees'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(FeeTiersList);
