import { createAction } from '@reduxjs/toolkit';

import { ORDER_CARDS, ORDER_CARDS_FAILURE, ORDER_CARDS_SUCCESS } from './actions.types';


export const startCardsOrdering = createAction('card-order/startCardsOrdering', (configData) => ({ payload: configData }));

export const setData = createAction('card-order/set-data', (data) => ({ payload: data }));

export const resetDataToInitState = createAction('card-order/reset-data');

export const setProductType = createAction('card-order/selectProductType', (productType) => ({ payload: productType }));

export const setCardMatrix = createAction('card-order/set-card-matrix', (matrix) => ({ payload: matrix }));

export const setCardType = createAction('card-order/setCardType', (cardType) => ({ payload: { card_type: cardType } }));

export const clearAddress = createAction('card-order/clearAddress');
export const clearMatrix = createAction('card-order/clearMatrix');
export const clearMembers = createAction('card-order/clearMembers');
export const clearCompanies = createAction('card-order/clearCompanies');
export const clearCardsDetails = createAction('card-order/clearCardsDetails');

export const selectMatrix = createAction('card-order/selectMatrix', (matrix) => ({ payload: matrix }));

export const submitOrderStep = createAction('card-order/submitOrderStep', (currentStep) => ({ payload: currentStep }));

export const goToPreviousOrderStep = createAction('card-order/goToPreviousOrderStep', (currentStep) => ({ payload: currentStep }));

export const setOrderStep = createAction('card-order/setOrderStep', (step) => ({ payload: step }));

export const processCardsOrder = createAction('card-order/processCardsOrder', (requestPayload) => ({ payload: requestPayload }));
export const processCardsOrderSuccess = createAction('card-order/processCardsOrderSuccess', (responseData) => ({ payload: responseData }));
export const processCardsOrderFailure = createAction('card-order/processCardsOrderFailure');

export const getTargetList = createAction('card-order/getTargetList', (targetType, id, search) => ({ payload: { targetType, id, search } }));
export const getTargetListSuccess = createAction('card-order/getTargetListSuccess', (targetType, responseData) => ({ payload: { targetType, responseData } }));
export const getTargetListFailure = createAction('card-order/getTargetListFailure', (targetType) => ({ payload: targetType }));

export const getCardProducts = createAction('card-order/getCardProducts', (targetType, id) => ({ payload: { targetType, id } }));
export const getCardProductsSuccess = createAction('card-order/getCardProductsSuccess', (responseData) => ({ payload: responseData }));
export const getCardProductsFailure = createAction('card-order/getCardProductsFailure');


export const fetchCardMatrix = createAction('card-order/fetchCardMatrix', (clientId) => ({ payload: clientId }));
export const fetchCardMatrixSuccess = createAction('card-order/fetchCardMatrixSuccess', (responseData) => ({ payload: responseData }));
export const fetchCardMatrixFailure = createAction('card-order/fetchCardMatrixFailure');


export const fetchDeliveryAddress = createAction('card-order/fetchDeliveryAddress', (targetType, id) => ({ payload: { targetType, id } }));
export const fetchDeliveryAddressSuccess = createAction('card-order/fetchDeliveryAddressSuccess', (responseData) => ({ payload: responseData }));
export const fetchDeliveryAddressFailure = createAction('card-order/fetchDeliveryAddressFailure');


export const orderCards = createAction(ORDER_CARDS, (requestPayload) => ({ payload: requestPayload }));
export const orderCardsSuccess = createAction(ORDER_CARDS_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const orderCardsFailure = createAction(ORDER_CARDS_FAILURE);

export const setMemberEmbossName = createAction('card-order/setMemberEmbossName', (embossName) => ({ payload: embossName }));
