import withAccessControl from 'hocs/withAccessControl';
import { navigationReducerName } from 'redux/navigation/reducer';
import { startOffboarding, stopOffboarding, finaliseOffboarding } from 'redux/offboarding/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import OffboardingButtons from './OffboardingButtons.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state[navigationReducerName]?.enhancedCurrentLocation,
    isLoadingStartOffboarding: state.offboarding.isLoadingStartOffboarding,
    isLoadingStopOffboarding: state.offboarding.isLoadingStopOffboarding,
    isLoadingFinalizeOffboarding: state.offboarding.isLoadingFinalizeOffboarding,

});

const mapDispatchToProps = (dispatch) => ({
    dispatchStartOffboarding: (requestPayload, locationPathname) => dispatch(startOffboarding(requestPayload, locationPathname)),
    dispatchStopOffboarding: (requestPayload, locationPathname) => dispatch(stopOffboarding(requestPayload, locationPathname)),
    dispatchFinaliseOffboarding: (requestPayload, locationPathname) => dispatch(finaliseOffboarding(requestPayload, locationPathname)),
});


const decorate = compose(
    withTranslation('offboarding'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(OffboardingButtons);
