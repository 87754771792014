import { globalLocation } from 'constants/NavigationModel';

import { createAction } from '@reduxjs/toolkit';

import { FETCH_CARD_TIERS, FETCH_CARD_TIERS_SUCCESS, FETCH_CARD_TIERS_FAILURE, CLEAR_CARD_TIERS } from './actions.types';


export const fetchCardTiers = createAction(FETCH_CARD_TIERS, (queryParams = {}, locationPathname = globalLocation) => ({ payload: { queryParams, locationPathname } }));
export const fetchCardTiersSuccess = createAction(FETCH_CARD_TIERS_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchCardTiersFailure = createAction(FETCH_CARD_TIERS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearCardTiers = createAction(CLEAR_CARD_TIERS, (locationPathname) => ({ payload: { locationPathname } }));
