import PropTypes from 'prop-types';

import {
    CommonQueryParamsMetaProps,
    CommonQueryParamsProps,
} from './ApplicationStateModel';


const ListProps = PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    isLoadingList: PropTypes.bool,
    queryParams: CommonQueryParamsProps,
    queryParamsMeta: CommonQueryParamsMetaProps,
    totalCount: PropTypes.number,
});

export const createListProps = (ItemModel) => PropTypes.shape({
    items: ItemModel,
    isLoadingList: PropTypes.bool,
    queryParams: CommonQueryParamsProps,
    queryParamsMeta: CommonQueryParamsMetaProps,
    totalCount: PropTypes.number,
});

export default ListProps;
