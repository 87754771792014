import {
    ADD_COMPANY_RISK_DETAILS,
    ADD_COMPANY_RISK_DETAILS_FAILURE,
    ADD_COMPANY_RISK_DETAILS_SUCCESS,
    CLEAR_COMPANY_DETAILS,
    FETCH_COMPANY_DETAILS,
    FETCH_COMPANY_DETAILS_FAILURE,
    FETCH_COMPANY_DETAILS_SUCCESS,
    OPEN_ADD_COMPANY_RISK_DETAILS_MODAL,
    UPDATE_COMPANY,
    UPDATE_COMPANY_FAILURE,
    UPDATE_COMPANY_SUCCESS,
} from 'redux/company/action.types';

import { createAction } from '@reduxjs/toolkit';


export const openAddCompanyRiskDetailsModal = createAction(OPEN_ADD_COMPANY_RISK_DETAILS_MODAL, (locationPathname) => ({ payload: { locationPathname } }));

export const addCompanyRiskDetails = createAction(ADD_COMPANY_RISK_DETAILS, (companyId, formData, locationPathname) => ({ payload: { companyId, formData, locationPathname } }));
export const addCompanyRiskDetailsSuccess = createAction(ADD_COMPANY_RISK_DETAILS_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const addCompanyRiskDetailsFailure = createAction(ADD_COMPANY_RISK_DETAILS_FAILURE);

export const fetchCompanyDetails = createAction(FETCH_COMPANY_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchCompanyDetailsSuccess = createAction(FETCH_COMPANY_DETAILS_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchCompanyDetailsFailure = createAction(FETCH_COMPANY_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const clearCompanyDetails = createAction(CLEAR_COMPANY_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));

export const updateCompany = createAction(UPDATE_COMPANY, (companyId, formData, locationPathname) => ({ payload: { companyId, formData, locationPathname } }));
export const updateCompanySuccess = createAction(UPDATE_COMPANY_SUCCESS, (responsePayload, locationPathname) => ({
    payload: {
        responsePayload,
        locationPathname,
    },
}));
export const updateCompanyFailure = createAction(UPDATE_COMPANY_FAILURE);
