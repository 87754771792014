export const getProperGetPromotionsEndpoint = ({ payload, promotions, fees, groups }) => {
    const handlers = [
        {
            predicate: () => payload?.queryParams?.feeUuid?.length > 0,
            handler: () => fees.fetchFeePromotions(payload.queryParams.feeUuid),
        },
        {
            predicate: () => payload?.queryParams?.groupId > 0,
            handler: () => groups.fetchPromotionsForGroup(payload.queryParams.groupId),
        },
        {
            predicate: () => true,
            handler: () => promotions.fetchClientPromotions(payload?.queryParams?.clientId),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export default { getProperGetPromotionsEndpoint };
