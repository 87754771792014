import { LINK_CARD_TO_BUSINESS_USER, LINK_CARD_TO_BUSINESS_USER_FAILURE, LINK_CARD_TO_BUSINESS_USER_SUCCESS } from 'redux/card-linking/action.types';

import { createAction } from '@reduxjs/toolkit';


export const selectCardForLink = createAction('card-linking/selectCardForLink', (cardForLink) => ({ payload: cardForLink }));

export const selectCardAccountForLink = createAction('card-linking/selectCardAccountForLink', (accountForLink) => ({ payload: accountForLink }));

export const clearLinkCardProcess = createAction('card-linking/clearLinkCardProcess');

export const clearLinkCard = createAction('card-linking/clearLinkCard');

export const clearLinkCardAccount = createAction('card-linking/clearLinkCardAccount');

export const setCardLinkingStep = createAction('card-linking/setCardLinkingStep', (currentStep) => ({ payload: currentStep }));

export const setWithPartnerCards = createAction('card-linking/setWithPartnerCards', (booleanValue) => ({ payload: booleanValue }));

export const setCardsSearchQuery = createAction('card-linking/setCardsSearchQuery', (search) => ({ payload: search }));

export const linkCardToMember = createAction('card-linking/linkCardToMember', (cardId, requestPayload) => ({ payload: { cardId, requestPayload } }));
export const linkCardToMemberSuccess = createAction('card-linking/linkCardToMemberSuccess', (memberId, response) => ({ payload: { memberId, response } }));
export const linkCardToMemberFailure = createAction('card-linking/linkCardToMemberFailure');


export const linkCardToBusinessUser = createAction(LINK_CARD_TO_BUSINESS_USER, (cardId, requestPayload) => ({ payload: { cardId, requestPayload } }));
export const linkCardToBusinessUserSuccess = createAction(LINK_CARD_TO_BUSINESS_USER_SUCCESS, (businessUserId, response) => ({ payload: { businessUserId, response } }));
export const linkCardToBusinessUserFailure = createAction(LINK_CARD_TO_BUSINESS_USER_FAILURE);


export const linkCardToCompany = createAction('card-linking/linkCardToCompany', (cardId, requestPayload) => ({ payload: { cardId, requestPayload } }));
export const linkCardToCompanySuccess = createAction('card-linking/linkCardToCompanySuccess', (companyId, response) => ({ payload: { companyId, response } }));
export const linkCardToCompanyFailure = createAction('card-linking/linkCardToCompanyFailure');


export const fetchNotLinkedCards = createAction('card-linking/fetchNotLinkedCards', (query) => ({ payload: query }));
export const fetchNotLinkedCardsSuccess = createAction('card-linking/fetchNotLinkedCardsSuccess', (responseData) => ({ payload: responseData }));
export const fetchNotLinkedCardsFailure = createAction('card-linking/fetchNotLinkedCardsFailure');
