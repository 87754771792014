import { CardDetailsProps } from 'constants/CardModel';

import React from 'react';
import JSONPretty from 'react-json-pretty';


function ResponseTab({ cardDetails }) {
    return (
        <div>
            <JSONPretty id="json-pretty-card-response" data={cardDetails?.response} />
        </div>
    );
}

ResponseTab.propTypes = { cardDetails: CardDetailsProps };

export default ResponseTab;
