/* eslint-disable class-methods-use-this */

import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';
import { withTotalCount } from 'services/http/http.helpers';

import { NumericId } from '@manigo/manigo-domain-typings';


import {
    fetchUsersApi,
    fetchUserIdDetails,
    createUserApi,
    updateUserApi,
    deleteUserApi,
    resendConfirmationEmailApi,
} from './index';


class UsersService {
    public fetchUsers(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, search, sortOrder, includeInactivePartners, ...rest } = rawQueryParams;

        const query = {
            params: {
                ...rest,
                ...(clientId && clientId > 0 ? { clientId } : {}),
                ...(search && search?.length > 0 ? { search } : {}),
                ...(includeInactivePartners === true ? { includeInactivePartners } : {}),
                ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
                ...withTotalCount,
            },
        };
        return fetchUsersApi(query);
    }

    public fetchUserDetails(userId: NumericId): Promise<ApiResponse | ApiError> {
        return fetchUserIdDetails(userId);
    }

    public createUser(requestPayload): Promise<ApiResponse | ApiError> {
        return createUserApi(requestPayload);
    }

    public updateUser(queryParams): Promise<ApiResponse | ApiError> {
        const { userId } = queryParams;
        const payload = Object.keys(queryParams).reduce((acc, key) => {
            if (key !== 'userId') {
                acc[key] = queryParams[key];
            }
            return acc;
        }, {});

        return updateUserApi(userId, payload);
    }

    public deleteUser(userId: NumericId): Promise<ApiResponse | ApiError> {
        return deleteUserApi(userId);
    }

    public resendConfirmationEmail(userId: NumericId): Promise<ApiResponse | ApiError> {
        return resendConfirmationEmailApi(userId);
    }
}

export default UsersService;
