import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import Review from './Review.layout';


const mapStateToProps = (state) => ({
    formData: state[beneficiaryAddReducerName].formData,
    initialValues: state[beneficiaryAddReducerName].initialValues,
    isEditMode: state[beneficiaryAddReducerName].contextData?.isEditMode === true,
});

const decorate = compose(
    connect(mapStateToProps),
    withTranslation('beneficiaries'),
);

export default decorate(Review);
