import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import StepsIndicator from './StepsIndicator.layout';
import { StepsIndicatorStateProps } from './StepsIndicator.types';


const mapStateToProps = (state: RootState): StepsIndicatorStateProps => ({ currentStep: state.outgoingTransfer.currentStep });

const decorate = compose<ComponentType>(
    connect(mapStateToProps),
    withTranslation('beneficiaries'),
);

export default decorate(StepsIndicator);
