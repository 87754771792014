import { standardPageSize } from 'config/config';
import { applicationsRouteSegmentPath, clientRootRoutePath } from 'config/routes';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    clearApplicationsList,
    fetchApplicationsList,
    fetchApplicationsListFailure,
    fetchApplicationsListSuccess,
} from './actions';


export const applicationsReducerName = 'applications';
export const applicationsListsPerLocationStoreKeyName = 'applicationsListsPerLocation';

export const defaultApplicationsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: undefined,
    sortOrder: undefined,
    clientId: undefined,
};

const initialState = {
    // List
    [applicationsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...}
};


const handlers = {
    [fetchApplicationsList]: (state, action) => {
        state[applicationsListsPerLocationStoreKeyName] = updateStandardLists(state[applicationsListsPerLocationStoreKeyName], action);
    },
    [fetchApplicationsListSuccess]: (state, action) => {
        state[applicationsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[applicationsListsPerLocationStoreKeyName], action);
    },
    [fetchApplicationsListFailure]: (state, action) => {
        state[applicationsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[applicationsListsPerLocationStoreKeyName], action);
    },
    [clearApplicationsList]: (state, action) => {
        state[applicationsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[applicationsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[applicationsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[applicationsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [applicationsRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === applicationsReducerName
      && action.payload?.fieldName === applicationsListsPerLocationStoreKeyName
        ) {
            state[applicationsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[applicationsListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
