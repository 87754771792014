import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import DigitInput from './DigitInput';
import Styled from './DigitsCodeInput.styled';


function DigitsCodeInput({
    loginConfirmationValues,
    references,
    dispatchSetPasscodeDigit,
    dispatchClearAllPasscodeDigits,
    loginConfirmFormError,
}) {
    const createFieldKey = (counter) => `digit${counter}Value`;

    const fieldsConfigs = useMemo(
        () => ([
            { key: createFieldKey(1), ownRef: references.refInputDigit1, prevRef: references.refInputDigit1, nextRef: references.refInputDigit2, autoFocus: true },
            { key: createFieldKey(2), ownRef: references.refInputDigit2, prevRef: references.refInputDigit1, nextRef: references.refInputDigit3 },
            { key: createFieldKey(3), ownRef: references.refInputDigit3, prevRef: references.refInputDigit2, nextRef: references.refInputDigit4 },
            { key: createFieldKey(4), ownRef: references.refInputDigit4, prevRef: references.refInputDigit3, nextRef: references.refInputDigit5 },
            { key: createFieldKey(5), ownRef: references.refInputDigit5, prevRef: references.refInputDigit4, nextRef: references.refInputDigit6 },
            { key: createFieldKey(6), ownRef: references.refInputDigit6, prevRef: references.refInputDigit5, nextRef: references.refButtonSubmit },
        ]),
        [references],
    );

    return (
        <Styled.DigitsCodeInputWrapper>
            {fieldsConfigs.map((fieldConfig, index) => (
                <DigitInput
                    key={fieldConfig.key}
                    value={loginConfirmationValues[fieldConfig.key]}
                    hasError={loginConfirmFormError}
                    formItem={fieldConfig}
                    index={index}
                    onPaste={(event) => {
                        const value = event.clipboardData.getData('text');
                        // validate pasted value
                        if (/^\d{6}$/.test(value)) {
                            value.split('').forEach((digit, index) => {
                                dispatchSetPasscodeDigit(createFieldKey(index + 1), digit);
                                setTimeout(() => {
                                    if (references.refButtonSubmit?.current) references.refButtonSubmit.current.focus();
                                });
                            });
                        }
                    }}
                    dispatchSetPasscodeDigit={dispatchSetPasscodeDigit}
                    dispatchClearAllPasscodeDigits={dispatchClearAllPasscodeDigits}
                />
            ))}
        </Styled.DigitsCodeInputWrapper>
    );
}


DigitsCodeInput.propTypes = {
    dispatchSetPasscodeDigit: PropTypes.func,
    dispatchClearAllPasscodeDigits: PropTypes.func,
    loginConfirmationValues: PropTypes.shape({
        digit1Value: PropTypes.string,
        digit2Value: PropTypes.string,
        digit3Value: PropTypes.string,
        digit4Value: PropTypes.string,
        digit5Value: PropTypes.string,
        digit6Value: PropTypes.string,
    }),
    references: PropTypes.shape({
        refInputDigit1: PropTypes.shape({}),
        refInputDigit2: PropTypes.shape({}),
        refInputDigit3: PropTypes.shape({}),
        refInputDigit4: PropTypes.shape({}),
        refInputDigit5: PropTypes.shape({}),
        refInputDigit6: PropTypes.shape({}),
        refButtonSubmit: PropTypes.shape({}),
    }),
    loginConfirmFormError: PropTypes.bool,
};

export default DigitsCodeInput;
