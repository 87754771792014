import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';

import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { GeneralAccountDataProps } from './GeneralAccountData.types';
import { createAccountDetailsSystemFieldsConfig, createAccountDetailsAmountFieldsConfig, createAccountDetailsPersonalFieldsConfig } from './GeneralAccountsData.helpers';


function GeneralAccountData({ accountDetails, t }: GeneralAccountDataProps) {
    const fieldsConfig = useMemo(
        () => [
            ...createAccountDetailsPersonalFieldsConfig(accountDetails, t),
            ...createAccountDetailsAmountFieldsConfig(accountDetails, t),
            ...createAccountDetailsSystemFieldsConfig(accountDetails, t),
        ],
        [accountDetails.id],
    );

    return (
        <div>
            {fieldsConfig.map((item, index) => (!item.value ? null : (
                <LabelAndValueRow
                    key={`${index}-${item.label}`}
                    label={item.label}
                    value={item.value}
                />
            )))}
        </div>
    );
}
export default withTranslation()(GeneralAccountData);
