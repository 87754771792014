import { ofType } from 'redux-observable';
import { catchError, from, of, switchMap } from 'rxjs';

import { fetchLimitsFailure, fetchLimitsSuccess } from './actions';
import { FETCH_LIMITS } from './actions.types';


export const onFetchLimits = (action$, _, { rules }) => action$.pipe(
    ofType(FETCH_LIMITS),
    switchMap(({ payload }) => from(rules.getRules({ ...payload.queryParams }))
        .pipe(
            switchMap((response) => of(fetchLimitsSuccess(
                response.data,
                response?.data?.length,
                payload.locationPathname,
            ))),
            catchError(() => of(fetchLimitsFailure(payload.locationPathname))),
        )),
);

export default [
    onFetchLimits,
];
