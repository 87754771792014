import styled from 'styled-components';


const AppHasInvalidConfigPlaceholderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  padding: 50px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  grid-column-gap: 50px;
  color: firebrick;
`;

const InvalidConfigErrorsInfoWrapper = styled.div`
  display: block;
  max-width: 100%;
`;

const InvalidConfigErrorInfoWrapper = styled.div`
  font-family: monospace;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-column-gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  color: red;
  font-size: 25px;
  
  .anticon {
    font-size: 60px;
  }
  
  & + & {
    margin-top: 20px;
  }
`;

const InjuredManIconDecoration = styled.img`
  width: 90%;
  max-width: 600px;
  height: auto;
`;

export default {
    AppHasInvalidConfigPlaceholderWrapper,
    InvalidConfigErrorsInfoWrapper,
    InvalidConfigErrorInfoWrapper,
    InjuredManIconDecoration,
};

