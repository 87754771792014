import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_FEE_DETAILS,
    FETCH_FEE_DETAILS,
    FETCH_FEE_DETAILS_FAILURE,
    FETCH_FEE_DETAILS_SUCCESS,
} from './actions.types';


export const fetchFeeDetails = createAction(FETCH_FEE_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchFeeDetailsSuccess = createAction(FETCH_FEE_DETAILS_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchFeeDetailsFailure = createAction(FETCH_FEE_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearFeeDetails = createAction(CLEAR_FEE_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));
