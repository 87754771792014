import { embossedNameRegex, isMatchingGivenRegexRule, maxLengthRule } from 'utils/validation-tools';

import { Form, Col, Row, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


export function DynamicEmbossNames({ t }) {
    return (
        <Form.List name="embossNames">
            {(fields) => (
                <Row gutter={10}>
                    {fields.map((field, index) => (
                        <Col span={6} key={`embossNamesField-${index}`}>
                            <Form.Item
                                {...field}
                                label={t('cards:cardFields.dynamicEmbossNames.text', { index: index + 1 })}
                                rules={[
                                    ...maxLengthRule(22, t),
                                    ...isMatchingGivenRegexRule({
                                        t,
                                        regexPattern: embossedNameRegex,
                                        messageError: t('common:validationMsg.invalidCharactersForCardEmbossName'),
                                    }),
                                ]}
                            >
                                <Input placeholder={t('cards:cardFields.dynamicEmbossNames.placeholder', { index: index + 1 })} />
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            )}
        </Form.List>

    );
}

DynamicEmbossNames.propTypes = { t: PropTypes.func.isRequired };


export default DynamicEmbossNames;
