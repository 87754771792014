import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import PermissionsList from './PermissionsList.layout';


const decorate = compose(
    withTranslation('roles'),
);

export default decorate(PermissionsList);

