import { clientConfigKey, debugMode, defaultLocale, permissionsKey, userPreferencesKey } from 'config/config';
import { i18nFormat, namespaces, resources } from 'config/i18next.helpers';
import {
    applicationGlobalDataFetchingAuthorisedUser,
    applicationInitGlobalDataFetchingAuthorisedUser,
    applicationReadyAuthorisedUser,
    applicationReadyUnauthorisedUser,
    fetchGlobalDataFailure,
    initI18nextFailure,
    initI18nextSuccess,
    removeJwtTokenFromStorage,
} from 'redux/application/actions';
import { SAVE_FILE, SHOW_ERROR_TOAST, SHOW_INFO_TOAST, SHOW_SUCCESS_TOAST, SHOW_WARNING_TOAST } from 'redux/application/actions.types';
import { commonAuthorisedInitApplicationFetches, createAppInitActionsAndSideEffects } from 'redux/application/epics.helpers';
import { applicationUIReducerName } from 'redux/application/reducer';
import { getTokensForDuplicatedTab, refreshToken } from 'redux/authorisation/actions';
import { authorisationReducerName } from 'redux/authorisation/reducer';
import { FETCH_CLIENTS_LIST_FAILURE, FETCH_CLIENTS_LIST_SUCCESS } from 'redux/clients/actions.types';
import { FETCH_COUNTRIES_LIST_FAILURE, FETCH_COUNTRIES_LIST_SUCCESS } from 'redux/countries/actions.types';
import { FETCH_CURRENCIES_FAILURE, FETCH_CURRENCIES_SUCCESS } from 'redux/currencies/actions.types';
import { setCurrentUser } from 'redux/current-user/actions';
import { SET_CURRENT_USER_SUCCESS } from 'redux/current-user/actions.types';
import SET_CURRENT_USER_VARIANT from 'redux/current-user/epics.helpers';
import { FETCH_INDUSTRIES_LIST_FAILURE, FETCH_INDUSTRIES_LIST_SUCCESS } from 'redux/industries/actions.types';
import { getSessionStorageObjectItem, removeSessionStorageObjectItem } from 'utils/browser-storage';
import copyToClipboard from 'utils/copy-to-clipboard';
import { clearJwt, getJwt, isTokenNotExpired } from 'utils/jwtToken';

import { notification } from 'antd';
import { initReactI18next } from 'react-i18next';
import { ofType } from 'redux-observable';
import { combineLatest, EMPTY, from, map, of } from 'rxjs';
import {
    catchError, mergeMap, switchMap, take, takeUntil, tap,
} from 'rxjs/operators';


export const onInitApplication = (action$, _, { http, history }) => action$.pipe(
    ofType('application/initApplication'),
    mergeMap(() => createAppInitActionsAndSideEffects(http, history)),
);

export const onInitI18next = (action$, _, { i18n }) => action$.pipe(
    ofType('application/initI18next'),
    switchMap(() => from(
        i18n.use(initReactI18next)
            .init({
                // debug: debugMode,
                debug: false,
                lng: defaultLocale,
                // TODO simplest way to have this working, eventually should be loaded by http backend plugin
                // https://github.com/i18next/i18next-http-backend
                resources,
                ns: namespaces,
                interpolation: {
                    format: i18nFormat,
                    escapeValue: false,
                },
                load: 'currentOnly',
                fallbackLng: defaultLocale,
                react: { useSuspense: false },
                saveMissing: debugMode,
                // XXX this is an external lib, this one has to be ignored by eslintcc
                // eslint-disable-next-line max-params, no-unused-vars
                missingKeyHandler: () => {
                    if (debugMode) {
                        // eslint-disable-next-line no-console
                        // TODO restore one day in BOP-goes-full-i18n epic
                        // console.warn(`Missing ‘${lngs.join('’, ’')}’ translation key\n  key: ‘${ns}:${key}’\n  fallback: ‘${fallbackValue}’\n`);
                    }
                },
            }),
    ).pipe(
        switchMap(() => of(initI18nextSuccess())),
        catchError(() => of(initI18nextFailure())),
    )),
);


export const onGetJwtTokenFormStorage = (action$) => action$.pipe(
    ofType('application/getJwtTokenFormStorage'),
    mergeMap(({ payload }) => {
        const jwtToken = getJwt();
        const permissions = getSessionStorageObjectItem(permissionsKey);
        const configuration = getSessionStorageObjectItem(clientConfigKey);
        const userPreferences = getSessionStorageObjectItem(userPreferencesKey);

        // XXX special case - payload is present
        if (payload && jwtToken) {
            return of(
                getTokensForDuplicatedTab({
                    sessionUuid: payload,
                    accessToken: jwtToken.accessToken,
                },
                { permissions, configuration, userPreferences }),
            );
        }

        if (jwtToken && isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)) {
            return of(setCurrentUser({ ...jwtToken, permissions, configuration, userPreferences }, SET_CURRENT_USER_VARIANT.GET_JWT_FROM_STORAGE));
        }

        if (jwtToken
        && !isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)
        && isTokenNotExpired(jwtToken.expirationTimeOfRefreshToken)
        ) {
            return of(refreshToken(jwtToken));
        }

        if (jwtToken) {
            return of(removeJwtTokenFromStorage());
        }

        return EMPTY;
    }),
);


export const onRemoveJwtTokenFromStorage = (action$, _, { http }) => action$.pipe(
    ofType('application/removeJwtTokenFromStorage'),
    tap(
        () => {
            clearJwt();
            removeSessionStorageObjectItem(permissionsKey);
            removeSessionStorageObjectItem(clientConfigKey);
            removeSessionStorageObjectItem(userPreferencesKey);
            http.setUserToken();
        },
    ),
    switchMap(() => EMPTY),
);

export const onSetClipboardContent = (action$) => action$.pipe(
    ofType('application/setClipboardContent'),
    tap(({ payload }) => copyToClipboard(payload)),
    switchMap(() => EMPTY),
);

export const onInitApplicationUnauthorisedFlow = (action$, state$) => combineLatest([
    action$.pipe(ofType('application/initApplication')),
    action$.pipe(ofType('application/setSessionUuid')),
    action$.pipe(ofType('application/initI18nextSuccess')),
]).pipe(
    take(1),
    takeUntil(action$.pipe(ofType(SET_CURRENT_USER_SUCCESS))),
    map(() => {
        const jwtToken = getJwt();
        // XXX user is logged-in -> skip applicationReadyUnauthorisedUser to avoid routes ability changes and possible unwanted redirection
        if ((jwtToken && isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)) || state$.value[authorisationReducerName]?.isRefreshingSession) {
            return EMPTY();
        }
        return applicationReadyUnauthorisedUser();
    }),
    catchError(() => EMPTY),
);

export const onInitApplicationAuthorisedFlow = (action$) => action$.pipe(
    ofType('application/fetchGlobalData'),
    switchMap(() => combineLatest([
    // afterLogin initial data fetching
        action$.pipe(ofType(FETCH_CLIENTS_LIST_SUCCESS)),
        action$.pipe(ofType(FETCH_COUNTRIES_LIST_SUCCESS)),
        action$.pipe(ofType(FETCH_CURRENCIES_SUCCESS)),
        action$.pipe(ofType(FETCH_INDUSTRIES_LIST_SUCCESS)),

        action$.pipe(ofType('transactions/getTransactionsFiltersSuccess')),
    ]).pipe(
        takeUntil(action$.pipe(ofType('application/applicationReadyAuthorisedUser'))),
        map(applicationReadyAuthorisedUser),
        catchError(() => EMPTY),
    )),
);


export const onAuthorisedGlobalDataFetchFailure = (action$, state$) => action$.pipe(
    ofType(
        FETCH_CLIENTS_LIST_FAILURE,
        FETCH_COUNTRIES_LIST_FAILURE,
        FETCH_CURRENCIES_FAILURE,
        FETCH_INDUSTRIES_LIST_FAILURE,
        'transactions/getTransactionsFiltersFailure',
    ),
    switchMap(() => {
        if (state$.value[applicationUIReducerName]?.shouldFetchGlobalData) {
            return EMPTY;
        }
        return of(
            fetchGlobalDataFailure(),
        );
    }),
    catchError(() => EMPTY),
);

export const onFetchGlobalData = (action$) => action$.pipe(
    ofType('application/fetchGlobalData'),
    switchMap(({ payload: { isInitialRun } }) => of(
        ...(isInitialRun ? [applicationInitGlobalDataFetchingAuthorisedUser()] : [applicationGlobalDataFetchingAuthorisedUser()]),
        ...commonAuthorisedInitApplicationFetches,
    )),
    catchError(() => EMPTY),
);

export const onShowSuccessToast = (action$) => action$.pipe(
    ofType(SHOW_SUCCESS_TOAST),
    switchMap(({
        payload: {
            message,
            description,
            options,
        },
    }) => {
        notification.success({
            message,
            description,
            ...options,
        });
        return EMPTY;
    }),
);

export const onShowInfoToast = (action$) => action$.pipe(
    ofType(SHOW_INFO_TOAST),
    switchMap(({
        payload: {
            message,
            description,
            options,
        },
    }) => {
        notification.info({
            message,
            description,
            ...options,
        });
        return EMPTY;
    }),
);

export const onShowWarningToast = (action$) => action$.pipe(
    ofType(SHOW_WARNING_TOAST),
    switchMap(({
        payload: {
            message,
            description,
            options,
        },
    }) => {
        notification.warning({
            message,
            description,
            ...options,
        });
        return EMPTY;
    }),
);

export const onShowErrorToast = (action$) => action$.pipe(
    ofType(SHOW_ERROR_TOAST),
    switchMap(({
        payload: {
            message,
            description,
            options,
        },
    }) => {
        notification.error({
            message,
            description,
            ...options,
        });
        return EMPTY;
    }),
);

export const onSaveFile = (action$, state$, { document, file }) => action$.pipe(
    ofType(SAVE_FILE),
    tap(({ payload: { blob, fileName, mimeType } }) => {
        file.saveBlobFile({ document, blob, fileName, mimeType });
    }),
    switchMap(() => EMPTY),
);

export default [
    onInitApplication,
    onInitApplicationUnauthorisedFlow,
    onInitApplicationAuthorisedFlow,
    onAuthorisedGlobalDataFetchFailure,
    onInitI18next,
    onGetJwtTokenFormStorage,
    onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowSuccessToast,
    onShowErrorToast,
    onShowInfoToast,
    onShowWarningToast,
    onSaveFile,
    onFetchGlobalData,
];
