import AccountItem from 'components/common/templates/modals/LinkCardModal/components/AccountItem';
import { LinkCardSteps } from 'redux/card-linking/helpers';

import { Button, List, Skeleton } from 'antd';
import React from 'react';


function AccountSelectionStep({
    accountsList,
    isLoadingAccounts,
    companyId,
    dispatchSelectCardAccountForLink,
    dispatchSetCardLinkingStep,
    t,
}) {
    return (
        <div style={{ overflowY: 'auto' }}>
            <Skeleton
                loading={isLoadingAccounts}
                active
            >
                <List
                    style={{ maxHeight: 350 }}
                    loading={isLoadingAccounts}
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={accountsList}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button
                                    key={'select-card'}
                                    onClick={() => {
                                        dispatchSelectCardAccountForLink(item);
                                        dispatchSetCardLinkingStep(LinkCardSteps.SUMMARY);
                                    }}
                                >
                                    {t('common:buttons.select.text')}
                                </Button>,
                            ]}
                        >
                            <Skeleton avatar title={false} loading={isLoadingAccounts} active>
                                <AccountItem account={item} companyId={companyId} />
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </Skeleton>
        </div>
    );
}


export default AccountSelectionStep;
