import styled from 'styled-components';


const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr)  minmax(0, 1fr);
  grid-column-gap: 30px;
`;

export default { TwoColumns };

