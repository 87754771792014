const getSuffix = (hour, minute, t) => {
    if (hour) return t('common:timeUnits.hours');
    if (minute > 1) return t('common:timeUnits.minutes');
    return t('common:timeUnits.seconds');
};

export const renderSeconds = (withSuffix, t, seconds = 0) => {
    const getTwoDigits = (value) => (value < 10 ? `0${value}` : value);

    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds - hour * 3600) / 60);
    const second = Math.floor(seconds - hour * 3600 - minute * 60);
    const suffix = withSuffix ? getSuffix(hour, minute, t) : '';

    if (!hour) {
        return `${getTwoDigits(minute)}:${getTwoDigits(second)} ${suffix}`;
    }
    return `${getTwoDigits(hour)}:${getTwoDigits(minute)}:${getTwoDigits(second)} ${suffix}`;
};

export const calculateSecondsLeft = (date) => {
    const now = new Date();
    return (date.getTime() - now.getTime()) / 1000;
};


export default { renderSeconds, calculateSecondsLeft };
