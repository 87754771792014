import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { createAccountDetailsPath, createBusinessUserDetailsPath, createMemberDetailsPath } from 'config/routes.helpers';
import { SortDirections } from 'constants/ApplicationStateModel';
import { sortBooleansByKey, sortingOptions, sortNumbers, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import React from 'react';


export const createTopupCardsListColumns = ({
    t,
    showOwnerIdsColumns = false,
    queryParams,
}) => [
    {
        title: t('ID'),
        dataIndex: 'id',
        align: 'right',
        render: (id) => <StandardValue value={id} searchQuery={queryParams?.search} />,
        defaultSortOrder: SortDirections.ASCEND,
        ...sortingOptions({
            queryParams,
            fieldName: 'id',
            sorter: (a, b) => sortNumbers(a, b, 'id'),
        }),
    },
    {
        title: t('Is verified?'),
        dataIndex: 'is_verified',
        align: 'center',
        render: (text, record) => booleanAsIconsRenderer(record, 'is_verified', t),
        ...sortingOptions({
            queryParams,
            fieldName: 'is_verified',
            sorter: (a, b) => sortBooleansByKey(a, b, 'is_verified'),
        }),
    },
    {
        title: t('Verification pending?'),
        dataIndex: 'verification_pending',
        align: 'center',
        render: (text, record) => booleanAsIconsRenderer(record, 'verification_pending', t),
        ...sortingOptions({
            queryParams,
            fieldName: 'verification_pending',
            sorter: (a, b) => sortBooleansByKey(a, b, 'verification_pending'),
        }),
    },
    {
        title: t('Account ID'),
        dataIndex: 'account_id',
        align: 'right',
        render: (text) => (
            <StandardLink
                path={createAccountDetailsPath(text)}
                title={text}
                showTooltip
                filterValue={queryParams?.search}
            />
        ),
        ...sortingOptions({
            queryParams,
            fieldName: 'account_id',
            sorter: (a, b) => sortNumbers(a, b, 'account_id'),
        }),
    },
    ...(showOwnerIdsColumns ? [
        {
            title: t('Member ID'),
            dataIndex: 'member_id',
            align: 'right',
            render: (text) => (
                <StandardLink
                    path={createMemberDetailsPath(text)}
                    title={text}
                    showTooltip
                    filterValue={queryParams?.search}
                />
            ),
            ...sortingOptions({
                queryParams,
                fieldName: 'member_id',
                sorter: (a, b) => sortNumbers(a, b, 'member_id'),
            }),
        },
        {
            title: t('Business user ID'),
            dataIndex: 'business_user_id',
            align: 'right',
            render: (text) => (
                <StandardLink
                    path={createBusinessUserDetailsPath(text)}
                    title={text}
                    showTooltip
                    filterValue={queryParams?.search}
                />
            ),
            ...sortingOptions({
                queryParams,
                fieldName: 'business_user_id',
                sorter: (a, b) => sortNumbers(a, b, 'business_user_id'),
            }),
        },
    ] : []),

    {
        title: t('Card holder'),
        dataIndex: 'card_holder',
        align: 'center',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'card_holder',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'card_holder'),
        }),
    },
    {
        title: t('Expiry'),
        dataIndex: 'expiry',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'expiry',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'expiry'),
        }),
    },
    {
        title: t('PAN'),
        dataIndex: 'pan_masked',
        align: 'center',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'pan_masked',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'pan_masked'),
        }),
    },
    {
        title: t('Scheme'),
        dataIndex: 'scheme',
        align: 'center',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'scheme',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'scheme'),
        }),
    },


];


export default { createTopupCardsListColumns };
