import ModalName from 'constants/Modals';
import { ADD_ACCOUNT, ADD_ACCOUNT_SUCCESS, FETCH_ACCOUNTS_LIST, OPEN_ADD_ACCOUNT_MODAL } from 'redux/accounts/action.types';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'redux/accounts/reducer';
import { showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    addAccountFailure,
    addAccountSuccess,
    fetchAccountsListSuccess,
    fetchAccountsListFailure,
    fetchAccountsList,
} from './actions';


export const onOpenAddAccountModal = (action$) => action$.pipe(
    ofType(OPEN_ADD_ACCOUNT_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.ADD_ACCOUNT_MODAL,
            modalProps: {
                title: payload.configData.title,
                okButtonProps: { disabled: false, 'data-test-id': 'add-account-modal-ok-button' },
                config: {
                    ...payload.configData,
                    ownerType: payload.ownerType,
                },
            },
        }),
    )),
);


export const onAddAccount = (action$, state$, { accounts }) => action$.pipe(
    ofType(ADD_ACCOUNT),
    switchMap(({ payload }) => from(accounts.createNewAccount(payload.requestPayload))
        .pipe(
            switchMap((response) => of(
                addAccountSuccess(
                    payload.ownerType,
                    response.data,
                    payload.locationPathname,
                ),
            )),
            catchError(() => of(
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
                addAccountFailure(),
            )),
        )),
);

export const onAddAccountSuccess = (action$, state$, { i18n }) => action$.pipe(
    ofType(ADD_ACCOUNT_SUCCESS),
    switchMap(({ payload }) => {
        const pathname = payload.locationPathname;
        const currentAccountListQueryParams = state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

        return of(
            fetchAccountsList({ ...currentAccountListQueryParams }, pathname),
            showSuccessToast(i18n.t('accounts:actionMessages.addNewSuccess', {
                type: payload.responseData.type,
                currency: payload.responseData.currency,
            })),
            hideModal(),
        );
    }),
);

export const onFetchAccountsList = (action$, state$, { accounts }) => action$.pipe(
    ofType(FETCH_ACCOUNTS_LIST),
    switchMap(({ payload }) => from(accounts.getAccountsList(payload.queryParams))
        .pipe(
            switchMap((response) => (payload?.queryParams?.withTotal && response?.data?.count >= 0
                ? of(fetchAccountsListSuccess(
                    response.data.rows,
                    response?.data.count,
                    payload.locationPathname,
                ))
                : of(fetchAccountsListSuccess(
                    response.data,
                    response?.data?.length,
                    payload.locationPathname,
                )))),
            catchError(() => of(fetchAccountsListFailure(payload.locationPathname))),
        )),
);

export default [
    onOpenAddAccountModal,
    onAddAccount,
    onAddAccountSuccess,
    onFetchAccountsList,
];

