import { standardPageSize } from 'config/config';
import {
    errorsHistoryRouteSegmentPath,
    failedWebhookRootRoutePath,
} from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import { PREFORM_NAVIGATION_SUCCESS } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    FETCH_FAILED_WEBHOOK_ERRORS_LIST,
    FETCH_FAILED_WEBHOOK_ERRORS_LIST_SUCCESS,
    FETCH_FAILED_WEBHOOK_ERRORS_LIST_FAILURE,
    CLEAR_FAILED_WEBHOOK_ERRORS_LIST,
} from './actions.types';


export const failedWebhookErrorsListReducerName = 'failedWebhookErrorsList';
export const failedWebhookErrorsListPerLocationStoreKeyName = 'failedWebhookErrorsListsPerLocation';


export const defaultFailedWebhookErrorsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'created_at',
    sortOrder: SortDirections.ASCEND,
    clientId: undefined,
    ...withTotalCount,
};


// field webhooks & replay webhook(s)
const initialFailedWebhooksState = {
    [failedWebhookErrorsListPerLocationStoreKeyName]: {},
    //
};

const initialState = { ...initialFailedWebhooksState };

const handlers = {
    // field webhooks & replay
    [FETCH_FAILED_WEBHOOK_ERRORS_LIST]: (state, action) => {
        state[failedWebhookErrorsListPerLocationStoreKeyName] = updateStandardLists(state[failedWebhookErrorsListPerLocationStoreKeyName], action);
    },
    [FETCH_FAILED_WEBHOOK_ERRORS_LIST_SUCCESS]: (state, action) => {
        state[failedWebhookErrorsListPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[failedWebhookErrorsListPerLocationStoreKeyName], action);
    },
    [FETCH_FAILED_WEBHOOK_ERRORS_LIST_FAILURE]: (state, action) => {
        state[failedWebhookErrorsListPerLocationStoreKeyName] = updateStandardListsOnFailure(state[failedWebhookErrorsListPerLocationStoreKeyName], action);
    },
    [CLEAR_FAILED_WEBHOOK_ERRORS_LIST]: (state, action) => {
        state[failedWebhookErrorsListPerLocationStoreKeyName] = updateStandardListsOnCleanup(
            state[failedWebhookErrorsListPerLocationStoreKeyName],
            action.payload?.locationPathname,
        );
    },

    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === failedWebhookErrorsListReducerName) {
            if (action.payload?.fieldName === failedWebhookErrorsListPerLocationStoreKeyName) {
                state[failedWebhookErrorsListPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[failedWebhookErrorsListPerLocationStoreKeyName] }, action);
            }
        }
    },
    [PREFORM_NAVIGATION_SUCCESS]: (state, action) => {
        state[failedWebhookErrorsListPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[failedWebhookErrorsListPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [errorsHistoryRouteSegmentPath],
                observedRootPaths: [failedWebhookRootRoutePath],
            },
        );
    },


    [CLEAR_CURRENT_USER]: () => initialState,
};

export default createReducer(initialState, handlers);
