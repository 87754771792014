import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const RoleWrapper = styled.div`
  position: relative;
  display: inline-grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-column-gap: 1ch;
  text-align: center;
  cursor: help;
  align-items: center;
`;

const RoleName = styled.span`
  position: relative;
  z-index: 2;
  ${commonTextEllipsisStyles};
`;

const InfoIcon = styled(InfoCircleOutlined)`
    font-size: 10px;
`;

export default {
    RoleWrapper,
    RoleName,
    InfoIcon,
};

