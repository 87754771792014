import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createUploaderProps from './AddUserMigrationModal.helpers';


const { Dragger } = Upload;

function AddUserMigrationModal({
    clientId,
    locationPathname,
    migrationType,
    dispatchUploadClientMigrationFile,
}) {
    const uploaderProps = useMemo(
        () => createUploaderProps({
            clientId,
            locationPathname,
            migrationType,
            dispatchUploadClientMigrationFile,
        }),
        [],
    );


    return (
        <div>
            <Dragger
                {...uploaderProps}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
          Support for a single CSV upload.
                </p>
            </Dragger>
        </div>
    );
}


AddUserMigrationModal.propTypes = {
    migrationType: PropTypes.string.isRequired,
    clientId: PropTypes.number.isRequired,
    dispatchUploadClientMigrationFile: PropTypes.func.isRequired,
};


export default AddUserMigrationModal;
