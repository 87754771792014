import { baseApiUrl } from 'config/config';
import { ApiError, HttpMethod } from 'models/api/http';
import { Action, Dispatch } from 'models/meta/action';
import { createApiError } from 'services/http/http.helpers';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';

import Axios from 'axios';
import Qs from 'qs';
// eslint-disable-next-line no-restricted-imports
import { v4 as uuidv4 } from 'uuid';


import { HttpRequestConfig } from './httpService.types';


class HttpServiceInstance {
    private readonly baseUrl;

    private userJwtToken;

    dispatch?: Dispatch;

    axios;

    constructor(baseUrl = baseApiUrl) {
        this.baseUrl = baseUrl;
        this.reset();
    }


    reset() {
        const headers = { 'Content-Type': 'application/json' };

        this.axios = Axios.create({
            baseURL: this.baseUrl || '/',
            headers,
        });

        this.axios.interceptors.response.use((response) => response, async (error) => this.handleResponseError(await createApiError(error)));
        this.axios.interceptors.request.use((config) => {
            config.headers['request-uuid'] = uuidv4();
            return config;
        },
        async (error) => this.handleResponseError(await createApiError(error)));
    }


    configure(dispatch: Dispatch) {
        this.reset();
        this.dispatch = dispatch;
        return this;
    }

    request({ method, url, data, config = {} }: {
    method: HttpMethod,
    url: string,
    data?: any,
    config?: HttpRequestConfig
  }) {
        const { download, ...axiosConfig } = config;
        const responseType = download ? 'blob' : 'json';
        const responseMapper = (response) => {
            const responseData = config?.convertSnakeCaseKeysToCamelCase ? convertSnakeCaseKeysToCamelCase(response?.data, config?.ignoreConvertKeys) : response?.data;
            if (config?.raw) {
                return ({ data: responseData, status: response?.status, headers: response?.headers });
            }

            return responseData;
        };

        const paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets' }); // XXX to match singleAPI format

        const configOptions = {
            ...axiosConfig,
            paramsSerializer,
        };


        return this.axios.request({ ...configOptions, responseType, method, url, data }).then(responseMapper);
    }

    handleResponseError(error: ApiError) {
        if (error.handled) {
            error.actions.forEach((action) => (this?.dispatch ? this?.dispatch(action) : undefined));
        }

        return Promise.reject(error);
    }


    // public methods
    get(url: string, config?: HttpRequestConfig) {
    // method, url, data, config
        return this.request({ method: 'get', url, data: undefined, config });
    }

    delete(url: string, data?: any, config?: HttpRequestConfig) {
        return this.request({ method: 'delete', url, data, config });
    }

    put(url: string, data?: any, config?: HttpRequestConfig) {
        return this.request({ method: 'put', url, data, config });
    }

    post(url: string, data?: any, config?: HttpRequestConfig) {
        return this.request({ method: 'post', url, data, config });
    }

    patch(url: string, data?: any, config?: HttpRequestConfig) {
        return this.request({ method: 'patch', url, data, config });
    }

    setUserToken(userJwtToken: string) {
        this.userJwtToken = userJwtToken;
        this.axios.defaults.headers.common.Authorization = userJwtToken ? `Bearer ${userJwtToken}` : '';
    }

    setSessionUuid(sessionUuid: string) {
        this.axios.defaults.headers.common['session-uuid'] = sessionUuid;
    }

    storeDispatch(action: Action) {
        return this.dispatch ? this.dispatch(action) : undefined;
    }
}


export const HttpService = new HttpServiceInstance();

export interface HttpServiceStore {
  setUserToken: HttpServiceInstance['setUserToken']
  setSessionUuid: HttpServiceInstance['setSessionUuid']
  storeDispatch: HttpServiceInstance['storeDispatch']
}

export const createHttpService = () => ({
    setUserToken: HttpService.setUserToken.bind(HttpService),
    setSessionUuid: HttpService.setSessionUuid.bind(HttpService),
    storeDispatch: HttpService.storeDispatch.bind(HttpService),
});
