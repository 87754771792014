export const FETCH_MANUAL_FEES_LIST = 'FETCH_MANUAL_FEES_LIST';
export const FETCH_MANUAL_FEES_LIST_SUCCESS = 'FETCH_MANUAL_FEES_LIST_SUCCESS';
export const FETCH_MANUAL_FEES_LIST_FAILURE = 'FETCH_MANUAL_FEES_LIST_FAILURE';
export const CLEAR_MANUAL_FEES_LIST = 'CLEAR_MANUAL_FEES_LIST';

export const OPEN_TRIGGER_MANUAL_FEE_MODAL = 'OPEN_TRIGGER_MANUAL_FEE_MODAL';

export const TRIGGER_MANUAL_FEE = 'TRIGGER_MANUAL_FEE';
export const TRIGGER_MANUAL_FEE_SUCCESS = 'TRIGGER_MANUAL_FEE_SUCCESS';
export const TRIGGER_MANUAL_FEE_FAILURE = 'TRIGGER_MANUAL_FEE_FAILURE';
