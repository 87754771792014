import { createAction } from '@reduxjs/toolkit';

/**
 *
 * @param {*} payload { type: 'Client-Edit-Drawer' }
 * Action which shows drawer
 */
export const showDrawer = createAction('drawer/show', ({ drawerType, drawerProps }) => ({
    payload: {
        drawerType,
        drawerProps,
    },
}));

/**
 * Action which hides modal
 */
export const hideDrawer = createAction('drawer/hide');
