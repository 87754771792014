import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createPromotionDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { removeUnderscores } from 'utils/string-tools';
import {
    sortBooleansByKey,
    sortStringDates,
    sortStringsAlphabetically, sortingOptions,
} from 'utils/table-columns-sorters';

import { DeleteOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function createPromotionsTableColumns({
    accessControl,
    enhancedCurrentLocation,
    t,
    queryParams,
    clientId,
    groupId,
    dispatchRemovePromotion,
    dispatchRemovePromotionFromGroup,
    dispatchRequestNavigation,
}) {
    const canRemovePromotion = accessControl.hasPermission(PN.PROMOTIONS.DELETE);
    const canRemovePromotionFromGroup = accessControl.hasPermission(PN.PROMOTIONS.REMOVE_FROM_GROUP);
    const canViewPromotionDetails = accessControl.hasPermission(PN.PROMOTIONS.READ_BY_ID);

    return [
        {
            title: t('promotions:promotionsFields.promotionUuid'),
            dataIndex: 'ext_id',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'ext_id',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'ext_id'),
            }),
        },
        {
            title: t('promotions:promotionsFields.promotionName'),
            dataIndex: 'name',
            render: (text, record) => (canViewPromotionDetails ? (
                <StandardLink
                    path={createPromotionDetailsPath(record.ext_id)}
                    title={text}
                    filterValue={queryParams?.search}
                    showTooltip
                />
            ) : <StandardValue value={text} searchQuery={queryParams?.search} />),
            ...sortingOptions({
                queryParams,
                fieldName: 'name',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'name'),
            }),
        },
        {
            title: t('promotions:promotionsFields.enabled'),
            dataIndex: 'is_enabled',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(record, 'is_enabled', t),
            ...sortingOptions({
                queryParams,
                fieldName: 'is_enabled',
                sorter: (a, b) => sortBooleansByKey(a, b, 'is_enabled'),
            }),
        },
        {
            title: t('promotions:promotionsFields.promotionType'),
            dataIndex: 'type',
            align: 'center',
            render: (type) => <StandardValue value={removeUnderscores(type)} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'type',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'type'),
            }),
        },
        {
            title: t('promotions:promotionsFields.activeFrom'),
            dataIndex: 'active_from',
            align: 'center',
            render: (date) => <StandardValue value={date} valueType="DATE" />,
            ...sortingOptions({
                queryParams,
                fieldName: 'active_from',
                sorter: (a, b) => sortStringDates(a, b, 'active_from'),
            }),
        },
        {
            title: t('promotions:promotionsFields.activeUntil'),
            dataIndex: 'active_until',
            align: 'center',
            render: (date) => <StandardValue value={date} valueType="DATE" />,
            ...sortingOptions({
                queryParams,
                fieldName: 'active_until',
                sorter: (a, b) => sortStringDates(a, b, 'active_until'),
            }),
        },
        ...(canRemovePromotion || canRemovePromotionFromGroup || canViewPromotionDetails ? [{
            title: t('common:table.columnNames.actions'),
            align: 'center',
            dataIndex: '',
            render: (record) => (
                <Space>
                    {clientId && !groupId
              && record.isActivated !== true
              && canRemovePromotion
              && viewButtonRenderer({
                  record,
                  onClick: (event, record) => dispatchRemovePromotion(
                      {
                          clientId,
                          promotionUuid: record.ext_id,
                      }, enhancedCurrentLocation.pathname,
                  ),
                  tooltipContent: t('common:buttons.remove.text'),
                  dataTestId: 'remove-promotion',
                  icon: <DeleteOutlined />,
              })}

                    { groupId && groupId > 0
            && record.isActivated !== true
            && canRemovePromotionFromGroup
            && viewButtonRenderer({
                record,
                onClick: (event, record) => dispatchRemovePromotionFromGroup(
                    {
                        groupId,
                        promotionUuid: record.ext_id,
                    }, enhancedCurrentLocation.pathname,
                ),
                tooltipContent: t('common:buttons.removeFromGroup.text'),
                dataTestId: 'remove-promotion-from-group',
                icon: <DeleteOutlined />,
            })}

                    {canViewPromotionDetails && viewButtonRenderer({
                        record,
                        onClick: (event, record) => dispatchRequestNavigation(createPromotionDetailsPath(record.ext_id)),
                        tooltipContent: "View Promotion's details",
                        dataTestId: 'view-promotion-details',
                    })}
                </Space>
            ),
        },
        ] : []),

    ];
}


createPromotionsTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    filterValue: PropTypes.string,
    clientId: PropTypes.number.isRequired,
    groupId: PropTypes.number.isRequired,
    dispatchRemovePromotion: PropTypes.func.isRequired,
};


export default createPromotionsTableColumns;
