import { InputNumber, DatePicker } from 'antd';
import styled from 'styled-components';


const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  & .ant-picker.ant-picker-range {
    width: 100%;
  }
`;

const FullWidthDatePicker = styled(DatePicker)`
  width: 100%;
  
  & .ant-picker.ant-picker-range {
    width: 100%;
  }
`;

const SubmitButtonWrapper = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px;
`;


export default {
    DatePickerWrapper,
    FullWidthInputNumber,
    FullWidthDatePicker,
    SubmitButtonWrapper,
};
