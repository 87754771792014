import {
    BLOCK_NAVIGATION, UNBLOCK_NAVIGATION,
    REQUEST_NAVIGATION, REQUEST_NAVIGATION_BACK,
} from 'redux/navigation/actions';

import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';


export const onBlockNavigation = (action$, _, { location }) => action$.pipe(
    ofType(BLOCK_NAVIGATION),
    tap(({ payload }) => location.lock(payload)),
    switchMap(() => EMPTY),
);

export const onUnblockNavigation = (action$, _, { location }) => action$.pipe(
    ofType(UNBLOCK_NAVIGATION),
    tap(() => location.unlock()),
    switchMap(() => EMPTY),
);


export const onNavigationRequested = (action$, _, { location }) => action$.pipe(
    ofType(REQUEST_NAVIGATION),
    tap(({ payload, meta }) => location.navigate(payload, meta)),
    switchMap(() => EMPTY),
);
export const onNavigationBackRequested = (action$, _, { location }) => action$.pipe(
    ofType(REQUEST_NAVIGATION_BACK),
    tap(() => location.goBack()),
    switchMap(() => EMPTY),
);


export default [
    onNavigationRequested,
    onNavigationBackRequested,
    onBlockNavigation,
    onUnblockNavigation,
];
