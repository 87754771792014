import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { updateMember } from 'redux/member/actions';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import EditMemberModal from './EditMemberModal.layout';
import { EditMemberModalDispatchProps, EditMemberModalStateProps } from './EditMemberModal.types';


const mapStateToProps = (state: RootState): EditMemberModalStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    userPreferences: state.currentUser?.userPreferences,
});

const mapDispatchToProps = (dispatch: Dispatch): EditMemberModalDispatchProps => ({
    dispatchUpdateMember: (memberId, formData, locationPathname) => dispatch(updateMember(memberId, formData, locationPathname)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});


const decorate = compose(
    withTranslation('member'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(EditMemberModal);
