import TabSubHeading from 'components/common/atoms/TabSubHeading';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import PermissionRecordDisplay from 'components/common/organisms/forms-shared-parts/add-edit-role-form/PermissionRecordDisplay';
import { searchCaseInsensitive } from 'utils/string-tools';
import { sortStringsAlphabetically } from 'utils/table-columns-sorters';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import Styled from './AvailablePermissions.styled';


function AvailablePermissions({
    operationsLists,
    selectedPermissions,
    dispatchAddPermissionsToRole,
    t,
}) {
    const [searchQuery, setSearchQuery] = useState('');

    const onSearchInputChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };

    const remainingPermissions = useMemo(
        () => {
            if (selectedPermissions && selectedPermissions?.length > 0) {
                return operationsLists
                    .filter((operation) => !selectedPermissions.some((existingOperation) => operation.id === existingOperation.id))
                    .sort((a, b) => sortStringsAlphabetically(a, b, 'name'));
            }
            return [...operationsLists].sort((a, b) => sortStringsAlphabetically(a, b, 'name'));
        },
        [operationsLists, selectedPermissions],
    );

    const filteredRemainingPermissions = useMemo(
        () => {
            if (searchQuery && searchQuery?.length > 0) {
                return (remainingPermissions || []).filter((permission) => searchCaseInsensitive(permission?.name, searchQuery));
            }
            return remainingPermissions;
        }, [remainingPermissions, searchQuery],
    );

    const handleOnAdd = (newPermission) => {
        dispatchAddPermissionsToRole([newPermission]);
    };


    return (
        <Styled.AvailablePermissionsWrapper>
            <TabSubHeading
                text={t('AVAILABLE PERMISSIONS')}
                optionalContent={searchQuery?.length > 0 ? (
                    <StandardTooltip
                        placement="top"
                        title={'Search filter is active. Not all records are shown. Clear search filter to see the full list.'}
                    >
                        <Styled.InfoIcon />
                    </StandardTooltip>
                ) : null}
            />
            <Styled.AvailablePermissionsSearchWrapper>
                <Input
                    onChange={onSearchInputChange}
                    placeholder={t('Type to filter available permissions')}
                    value={searchQuery}
                    allowClear
                />
                <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    disabled={!filteredRemainingPermissions.length > 0}
                    onClick={() => dispatchAddPermissionsToRole(filteredRemainingPermissions)}
                >
                    {t('Add all matching permissions')}
                </Button>
            </Styled.AvailablePermissionsSearchWrapper>

            <Styled.AvailablePermissionsListOuterWrapper>
                <Styled.AvailablePermissionsListWrapper>
                    {filteredRemainingPermissions.map((remainingPermission) => (
                        <PermissionRecordDisplay
                            t={t}
                            searchQuery={searchQuery}
                            permission={remainingPermission}
                            key={`${remainingPermission.name}_${remainingPermission.id}`}
                            onAddPermission={handleOnAdd}
                        />
                    ))}
                </Styled.AvailablePermissionsListWrapper>

            </Styled.AvailablePermissionsListOuterWrapper>

        </Styled.AvailablePermissionsWrapper>
    );
}

AvailablePermissions.propTypes = {
    operationsLists: PropTypes.arrayOf(PropTypes.shape({})),
    selectedPermissions: PropTypes.arrayOf(PropTypes.shape({})),
    dispatchAddPermissionsToRole: PropTypes.func,
    t: PropTypes.func,
};

export default AvailablePermissions;
