import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { createMember, setCreateMemberFormStep, setCreateMemberFormData } from 'redux/members/actions';
import { blockNavigation, requestNavigationBack, showNavigationConfirmation, unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddMember from './AddMember.layout';
import { AddMemberDispatchProps, AddMemberStateProps } from './AddMember.types';


const mapStateToProps = (state: RootState): AddMemberStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    isBlockNavigationReasonSet: state[navigationReducerName].blockNavigationReason,
    clientsData: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

    currentUserClientId: state.currentUser.userData?.clientId,
    isLoadingCreateMember: state.members.isLoadingCreateMember,
    currentStep: state.members.createMemberFormStep,
    createMemberFormData: state.members.createMemberFormData,
    userPreferences: state.currentUser?.userPreferences,
});

const mapDispatchToProps = (dispatch: Dispatch): AddMemberDispatchProps => ({
    dispatchCreateMember: (requestData) => dispatch(createMember(requestData)),
    dispatchSetCreateMemberFormStep: (step) => dispatch(setCreateMemberFormStep(step)),
    dispatchSetCreateMemberFormData: (step) => dispatch(setCreateMemberFormData(step)),
    dispatchRequestNavigationBack: () => dispatch(requestNavigationBack()),
    dispatchBlockNavigation: (reason) => dispatch(blockNavigation(showNavigationConfirmation(), reason)),
    dispatchUnblockNavigation: () => dispatch(unblockNavigation()),
});

const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);
export default decorate(AddMember);
