import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import { AccessControlProps } from 'constants/AccessControlModel';
import { PN } from 'constants/PermissionsModel';

import { ReloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import Styled from './ResetKycKybButton.styled';


function ResetKycKybButton({
    accessControl,
    entityName,
    t,
    isLoadingTriggerKycKyb,
    isEntityLoading,
    dispatchTriggerKycKybFlow,
    baseUrlWithoutTabsSlugs,
    memberId,
    companyId,
    businessUserId,
    isInReadOnlyMode,
    parentEntityIsInReadOnlyMode,
    gutterTop = false,
}) {
    const type = useMemo(
        () => (companyId > 0 ? 'KYB' : 'KYC'),
        [companyId],
    );

    const isActionable = useMemo(
        () => accessControl.isKycKybApprovalEnabled()
      && accessControl.hasPermission(PN.KYCKYB.RESTART)
      && !isInReadOnlyMode
      && !parentEntityIsInReadOnlyMode,
        [accessControl, isInReadOnlyMode],
    );

    return isActionable ? (
        <Styled.ResetKycKybButtonWrapper $gutterTop={gutterTop}>
            <Popconfirm
                title={(
                    <StandardTranslationWithMarkup
                        translationKey={'buttons.requestKycKyb.confirmationQuestion'}
                        namespace="kycKyb"
                        values={{ type, entityName }}
                        t={t}
                    />
                )}
                onConfirm={() => dispatchTriggerKycKybFlow({ memberId, companyId, businessUserId }, baseUrlWithoutTabsSlugs)}
            >
                <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    disabled={isLoadingTriggerKycKyb || isEntityLoading}
                    loading={isLoadingTriggerKycKyb}
                >
                    {t('buttons.requestKycKyb.text', { type })}
                </Button>

            </Popconfirm>
        </Styled.ResetKycKybButtonWrapper>
    ) : null;
}

ResetKycKybButton.propTypes = {
    // own props
    entityName: PropTypes.string,
    memberId: PropTypes.number,
    companyId: PropTypes.number,
    businessUserId: PropTypes.number,
    isInReadOnlyMode: PropTypes.bool,
    isEntityLoading: PropTypes.bool,
    parentEntityIsInReadOnlyMode: PropTypes.bool,
    baseUrlWithoutTabsSlugs: PropTypes.string,
    gutterTop: PropTypes.bool,

    // mapped props
    accessControl: AccessControlProps,
    isLoadingTriggerKycKyb: PropTypes.bool.isRequired,
    dispatchTriggerKycKybFlow: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default ResetKycKybButton;
