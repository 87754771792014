import { standardPageSize } from 'config/config';
import { cardRootRoutePath, statusHistoryRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists, updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchCardStatusHistory, fetchCardStatusHistorySuccess, fetchCardStatusHistoryFailure, clearCardStatusHistory, toggleChangeCardStatusFormVisibility,
    updateCardStatus, updateCardStatusSuccess, updateCardStatusFailure,
} from './actions';


export const cardStatusHistoryReducerName = 'cardStatusHistory';
export const cardStatusHistoryListPerLocationStoreKeyName = 'cardStatusHistoryListPerLocation';


export const defaultCardStatusHistoryQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
    // context
    cardId: undefined,
};

const defaultCardStatusHistoryValues = { [cardStatusHistoryListPerLocationStoreKeyName]: {} };


const initialState = {
    isChangeStatusFormVisible: false,
    isLoadingChangeStatus: false,
    ...defaultCardStatusHistoryValues,
};

const handlers = {
    [toggleChangeCardStatusFormVisibility]: (state, action) => {
        state.isChangeStatusFormVisible = action.payload;
    },

    [fetchCardStatusHistory]: (state, action) => {
        state[cardStatusHistoryListPerLocationStoreKeyName] = updateStandardLists(state[cardStatusHistoryListPerLocationStoreKeyName], action);
    },
    [fetchCardStatusHistorySuccess]: (state, action) => {
        state[cardStatusHistoryListPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[cardStatusHistoryListPerLocationStoreKeyName], action);
    },
    [fetchCardStatusHistoryFailure]: (state, action) => {
        state[cardStatusHistoryListPerLocationStoreKeyName] = updateStandardListsOnFailure(state[cardStatusHistoryListPerLocationStoreKeyName], action);
    },
    [clearCardStatusHistory]: (state, action) => {
        state[cardStatusHistoryListPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[cardStatusHistoryListPerLocationStoreKeyName], action.payload.locationPathname);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === cardStatusHistoryReducerName
      && action.payload?.fieldName === cardStatusHistoryListPerLocationStoreKeyName
        ) {
            state[cardStatusHistoryListPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[cardStatusHistoryListPerLocationStoreKeyName] }, action);
        }
    },

    [preformNavigationSuccess]: (state, action) => {
        state[cardStatusHistoryListPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[cardStatusHistoryListPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [statusHistoryRouteSegmentPath],
                observedRootPaths: [cardRootRoutePath],
            },
        );
    },


    [updateCardStatus]: (state) => {
        state.isLoadingChangeStatus = true;
    },
    [updateCardStatusSuccess]: (state) => {
        state.isLoadingChangeStatus = false;
    },
    [updateCardStatusFailure]: (state) => {
        state.isLoadingChangeStatus = false;
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
