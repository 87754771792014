import { AddClientStepsDefinitions } from 'constants/AddClientModel';
import { MultiStepFormStepChangeDirections } from 'constants/MultiStepsFormModel';

import { Button, Form } from 'antd';
import React from 'react';


export const renderNonPrimaryButton = ({
    dataTestId,
    text,
    onClick,
}) => (
    <Button
        data-test-id={dataTestId}
        onClick={onClick}
    >
        {text}
    </Button>
);

export const renderSubmitButton = ({
    dataTestId,
    text,
    isLoading,
}) => (
    <Form.Item>
        <Button
            data-test-id={dataTestId}
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
        >
            {text}
        </Button>
    </Form.Item>
);

export const renderTrinaryButton = ({
    currentStepNumber,
    currentSubStepNumber,
    currentMultiStepsFormStep,
    dispatchRequestNavigation,
    goBackPathname,
    t,
}) => {
    const isSuccessStep = currentMultiStepsFormStep === AddClientStepsDefinitions.SUCCESS;


    const handlers = [
        {
            predicate: () => !isSuccessStep && (currentStepNumber > 0 || (currentStepNumber === 0 && currentSubStepNumber > 0)),
            handler: () => renderNonPrimaryButton({
                dataTestId: 'leave-form-by-cancel',
                text: t('common:buttons.cancel.text'),
                // onClick: () => dispatchRequestNavigationBack()
                onClick: () => dispatchRequestNavigation(goBackPathname, { replace: true }),
            }),
        },


        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const renderSecondaryButton = ({
    currentStepNumber,
    currentSubStepNumber,

    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,

    dispatchRequestNavigation,
    dispatchRequestSetMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormSubStep,
    goBackPathname,
    onSuccessGoBackLabel,
    t,
}) => {
    const isSuccessStep = currentMultiStepsFormStep === AddClientStepsDefinitions.SUCCESS;


    const handlers = [
        {
            predicate: () => isSuccessStep,
            handler: () => renderNonPrimaryButton({
                dataTestId: 'leave-form-by-finish-and-go-back',
                text: onSuccessGoBackLabel,
                onClick: () => dispatchRequestNavigation(goBackPathname, { replace: true }),
            }),
        },
        {
            predicate: () => currentMultiStepsFormSubStep && currentSubStepNumber > 0,
            handler: () => renderNonPrimaryButton({
                dataTestId: 'go-to-previous-form-sub-step',
                text: t('common:buttons.back.text'),
                onClick: () => dispatchRequestSetMultiStepsFormSubStep(
                    currentMultiStepsFormStep,
                    currentMultiStepsFormSubStep,
                    MultiStepFormStepChangeDirections.BACK,
                ),
            }),
        },
        {
            predicate: () => currentStepNumber === 0,
            handler: () => renderNonPrimaryButton({
                dataTestId: 'leave-form-by-cancel',
                text: t('common:buttons.cancel.text'),
                // onClick: () => dispatchRequestNavigationBack()
                onClick: () => dispatchRequestNavigation(goBackPathname, { replace: true }),
            }),
        },


        {
            predicate: () => true,
            handler: () => renderNonPrimaryButton({
                dataTestId: 'go-to-previous-form-step',
                text: t('common:buttons.back.text'),
                onClick: () => dispatchRequestSetMultiStepsFormStep(
                    currentMultiStepsFormStep,
                    MultiStepFormStepChangeDirections.BACK,
                ),
            }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const renderPrimaryButton = ({
    isLoading,
    lastStepExceptSuccess,
    currentMultiStepsFormStep,
    currentSubStepNumber,
    availableSubSteps,
    dispatchRequestNavigation,
    onSuccessNewLocationPathname,
    onSuccessNewLocationLabel,
    t,
}) => {
    const isSuccessStep = currentMultiStepsFormStep === AddClientStepsDefinitions.SUCCESS;


    const handlers = [
        {
            predicate: () => isSuccessStep,
            handler: () => (
                <Button
                    type="primary"
                    data-test-id="view-new-entity"
                    onClick={() => dispatchRequestNavigation(onSuccessNewLocationPathname, { replace: true })}
                >
                    {onSuccessNewLocationLabel}
                </Button>
            ),
        },
        {
            // last non-success step without sub steps or last sub-step of last non-success step
            predicate: () => (!isSuccessStep
          && currentMultiStepsFormStep === lastStepExceptSuccess
          && !availableSubSteps?.length > 0
            )
      || (!isSuccessStep
          && currentMultiStepsFormStep === lastStepExceptSuccess
          && currentSubStepNumber + 1 === availableSubSteps?.length
      ),
            handler: () => renderSubmitButton({
                isLoading,
                dataTestId: 'submit-form',
                text: t('common:buttons.submit.text'),
            }),
        },

        {
            predicate: () => !isSuccessStep,
            handler: () => renderSubmitButton({
                isLoading,
                dataTestId: 'submit-form-step',
                text: t('common:buttons.next.text'),
            }),
        },
        {
            predicate: () => true,
            handler: () => <span />,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export default {
    renderSubmitButton,
    renderSecondaryButton,
    renderPrimaryButton,
};
