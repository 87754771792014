import {
    extractRelevantClientConfig,
    isMemberValueSet,
    createColumns,
} from 'components/pages/authorised/MemberDetails/Tabs/Settings/Settings.helpers';
import { createClientDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { AllEntityTypes } from 'models/app/applicationState';
import { isObject } from 'utils/object-tools';

import {
    Space, Switch, Table, InputNumber, Button, Spin, Tooltip, Skeleton,
} from 'antd';
import { merge } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';


import Styled from './Settings.styled';
import { SettingsProps } from './Settings.types';


function MemberSettings({
    accessControl,
    enhancedCurrentLocation,
    entityDetailsData,
    memberId,
    clientDetails,
    isLoadingUpdateMember,
    isInReadOnlyMode,

    dispatchUpdateMember,
    dispatchFetchClientDetails,
    t,
}:SettingsProps) {
    const entityDetails = entityDetailsData?.entityDetails;

    useEffect(() => {
    // Fetch partner config
        if (entityDetails?.clientId > 0 && clientDetails?.id !== entityDetails?.clientId) {
            dispatchFetchClientDetails(
                { clientId: entityDetails.clientId },
                createClientDetailsPath(entityDetails.clientId),
            );
        }
    }, [entityDetails, clientDetails]);


    const canUpdate = useMemo(
        () => accessControl.hasPermission(PN.MEMBERS.UPDATE)
      && !accessControl.isEntityReadOnly({ entityStatus: entityDetails?.memberStatus, entityType: AllEntityTypes.MEMBER })
      && !isInReadOnlyMode,
        [accessControl, isInReadOnlyMode],
    );

    const [physicalCardsLimit, setPhysicalCardsLimit] = useState<number | null>(null);
    const [virtualCardsLimit, setVirtualCardsLimit] = useState<number | null>(null);

    const clientConfig = useMemo(
        () => (clientDetails?.configuration ? extractRelevantClientConfig(clientDetails.configuration) : undefined),
        [clientDetails],
    );

    const memberConfig = useMemo(
        () => (isObject(entityDetails?.memberConfig) ? entityDetails.memberConfig : {}),
        [entityDetails],
    );

    const finalConfig = useMemo(
        () => {
            const final = merge({}, clientConfig, memberConfig);
            setVirtualCardsLimit(final.virtualCardsLimit);
            setPhysicalCardsLimit(final.physicalCardsLimit);
            return final;
        },
        [memberConfig, clientConfig],
    );


    const updateMember = (newConfig) => {
        dispatchUpdateMember(
            memberId,
            {
                memberPhoneNumber: entityDetails?.memberPhoneNumber,
                memberConfig: newConfig,
            },
            enhancedCurrentLocation?.pathname,
        );
    };

    const changeConfig = (key, value) => {
        const newConfig = {
            ...memberConfig,
            [key]: value,
        };

        updateMember(newConfig);
    };

    const resetConfig = (key) => {
        const newConfig = { ...memberConfig };
        delete newConfig[key];

        updateMember(newConfig);
    };

    const renderResetButton = (key) => {
        const Container = !isInReadOnlyMode ? {
            Component: Tooltip,
            props: { title: "Remove member's setting -> partner-level setting will be applied." },
        } : {
            Component: Fragment,
            props: {},
        };
        if (isMemberValueSet(memberConfig, key)) {
            return (
                <Container.Component {...Container.props}>
                    <Styled.InfoIconAndButtonWrapper>
                        {!isInReadOnlyMode && <Styled.InfoIcon />}
                        <Button
                            type="primary"
                            onClick={canUpdate ? () => resetConfig(key) : undefined}
                            disabled={!canUpdate}
                        >
                            {t('common:buttons.reset.text')}
                        </Button>
                    </Styled.InfoIconAndButtonWrapper>
                </Container.Component>
            );
        }
        return null;
    };

    const renderSwitch = (key) => (
        <Switch
            disabled={!canUpdate}
            checked={finalConfig[key]}
            onChange={canUpdate ? (value) => changeConfig(key, value) : undefined}
        />
    );


    const data = [
        {
            name: 'Allow Physical Cards',
            value: renderSwitch('allowPhysicalCards'),
            reset: renderResetButton('allowPhysicalCards'),
        },
        {
            name: 'Allow Virtual Cards',
            value: renderSwitch('allowVirtualCards'),
            reset: renderResetButton('allowVirtualCards'),
        },
        {
            name: 'Allow Online Payments',
            value: renderSwitch('allowOnlinePayments'),
            reset: renderResetButton('allowOnlinePayments'),
        },
        {
            name: 'Allow TopUp',
            value: renderSwitch('allowTopup'),
            reset: renderResetButton('allowTopup'),
        },
        {
            name: 'Enable Chat',
            value: renderSwitch('enableChat'),
            reset: renderResetButton('enableChat'),
        },
        {
            name: 'Physical Cards Limit',
            value: (
                <Space>
                    <InputNumber
                        min={0}
                        disabled={!canUpdate}
                        value={physicalCardsLimit}
                        onChange={setPhysicalCardsLimit}
                    />
                    {physicalCardsLimit !== finalConfig.physicalCardsLimit && canUpdate ? (
                        <Button
                            type="primary"
                            onClick={() => changeConfig('physicalCardsLimit', physicalCardsLimit)}
                        >
              Update value
                        </Button>
                    ) : null}
                </Space>
            ),
            reset: renderResetButton('physicalCardsLimit'),
        },
        {
            name: 'Virtual Cards Limit',
            value: (
                <Space>
                    <InputNumber
                        disabled={!canUpdate}
                        min={0}
                        value={virtualCardsLimit}
                        onChange={setVirtualCardsLimit}
                    />
                    {virtualCardsLimit !== finalConfig.virtualCardsLimit && canUpdate ? (
                        <Button
                            type="primary"
                            onClick={() => changeConfig('virtualCardsLimit', virtualCardsLimit)}
                        >
              Update value
                        </Button>
                    ) : null}
                </Space>
            ),
            reset: renderResetButton('virtualCardsLimit'),
        },

    ];


    return (

        <Skeleton
            loading={!clientConfig && !(entityDetails?.clientId > 0)}
            active
        >
            <Spin
                tip="Updating..."
                spinning={isLoadingUpdateMember}
            >
                <Table
                    columns={createColumns(t)}
                    dataSource={data}
                    pagination={false}
                />
            </Spin>
        </Skeleton>
    );
}

export default MemberSettings;

