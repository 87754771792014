import MemberRiskTag from 'components/common/atoms/tags/MemberRiskTag';
import MemberStatusTag from 'components/common/atoms/tags/MemberStatusTag/MemberStatusTag';
import StandardLink from 'components/common/molecules/StandardLink';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { createGroupDetailsPath, createMemberDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { AccessControlContent } from 'models/domain/accessControl';

import { MemberDetails } from '@manigo/manigo-domain-typings';
import { Button, Space } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';


export const createMemberPersonalDetailsFields = ({
    memberDetails,
    t,
}: {
  memberDetails: MemberDetails,
  t: TFunction
}) => {
    if (memberDetails?.memberId) {
        return [
            {
                label: t('member:memberFields.name'),
                value: <StandardValue value={`${memberDetails?.memberFirstName} ${memberDetails?.memberLastName}`} />,
            },
            {
                label: t('member:memberFields.phoneNumber'),
                value: <StandardValue value={memberDetails?.memberPhoneNumber} valueType="PHONE" />,
            },
            {
                label: 'Phone Country',
                value: <CountryRenderer propertyName="id" value={memberDetails?.memberPhoneCountryId} showTooltip={false} />,
            },
            {
                label: t('member:memberFields.email'),
                value: <StandardValue value={memberDetails?.memberEmail} showCopyToClipboard />,
            },
            {
                label: t('member:memberFields.dateOfBirth'),
                value: <StandardValue value={memberDetails?.memberDateOfBirth} valueType="DATE" showTimeInTooltip={false} />,
            },
        ];
    }

    return [];
};

export const createMemberSystemDetailsFields = ({
    memberDetails,
    t,
    accessControl,
    dispatchOpenReassignMemberGroupModal,
}: {
  memberDetails: MemberDetails,
  t: TFunction,
  accessControl: AccessControlContent,
  dispatchOpenReassignMemberGroupModal: (args: any, path: string) => void
}) => {
    if (memberDetails?.memberId) {
        const baseFields = [
            {
                label: t('member:memberFields.externalUserId'),
                value: <StandardValue value={memberDetails?.memberUuid} showCopyToClipboard />,
            },
            {
                label: t('member:memberFields.status'),
                value: <MemberStatusTag status={memberDetails?.memberStatus} t={t} />,
            },

            ...(memberDetails?.closureStartDate
                ? [{
                    label: t('member:memberFields.closureStartDate'),
                    value: <StandardValue value={memberDetails?.closureStartDate} valueType="DATE" />,
                }]
                : []
            ),

            ...(memberDetails?.closureEndDate
                ? [{
                    label: t('member:memberFields.closureEndDate'),
                    value: <StandardValue value={memberDetails?.closureEndDate} valueType="DATE" />,
                }]
                : []
            ),
        ];


        const riskScoreField = [{
            label: t('member:memberFields.riskScore'),
            value: <MemberRiskTag
                riskRating={memberDetails?.memberRiskRating}
            />,
        }];

        const groupField = [{
            label: t('member:memberFields.group'),
            value: (
                <Space>
                    <StandardLink
                        title={memberDetails?.group?.groupName}
                        path={createGroupDetailsPath(memberDetails?.clientId, memberDetails?.group?.groupId)}
                    />

                    {accessControl.hasPermission(PN.MEMBERS.UPDATE) && (
                        <Button
                            type="primary"
                            size="small"
                            title={t('member:buttons.reassign.tooltip')}
                            onClick={() => dispatchOpenReassignMemberGroupModal({
                                memberId: memberDetails?.memberId,
                                memberClientId: memberDetails?.clientId,
                                currentGroup: memberDetails?.group,
                            },
                            createMemberDetailsPath(memberDetails?.memberId))}
                        >
                            {t('member:buttons.reassign.text')}
                        </Button>
                    )}
                </Space>
            ),
        }];

        return [
            ...baseFields,
            ...riskScoreField,
            ...(accessControl.clientConfig?.generalSettings?.groupsEnabled ? groupField : []),
        ];
    }

    return [];
};


export default {
    createMemberPersonalDetailsFields,
    createMemberSystemDetailsFields,
};
