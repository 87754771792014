import { standardPageSize } from 'config/config';
import { accountsRouteSegmentPath, clientRootRoutePath, companyRootRoutePath, memberRootRoutePath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import { CLEAR_ACCOUNTS_LIST, FETCH_ACCOUNTS_LIST, FETCH_ACCOUNTS_LIST_FAILURE, FETCH_ACCOUNTS_LIST_SUCCESS, OPEN_ADD_ACCOUNT_MODAL } from './action.types';


export const accountsReducerName = 'accounts';
export const accountsListsPerLocationStoreKeyName = 'accountsListsPerLocation';


export const accountListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    type: undefined,
    currency: undefined,
    ownerId: undefined,
    ownerType: undefined,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
};


const initialState = {
    [accountsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    //
};

const handlers = {
    [OPEN_ADD_ACCOUNT_MODAL]: (state, action) => {
        state.accountOwnerType = action.payload.ownerType;
    },


    [FETCH_ACCOUNTS_LIST]: (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = updateStandardLists(state[accountsListsPerLocationStoreKeyName], action);
    },
    [FETCH_ACCOUNTS_LIST_SUCCESS]: (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[accountsListsPerLocationStoreKeyName], action);
    },
    [FETCH_ACCOUNTS_LIST_FAILURE]: (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[accountsListsPerLocationStoreKeyName], action);
    },
    [CLEAR_ACCOUNTS_LIST]: (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[accountsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[accountsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [accountsRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath, companyRootRoutePath, memberRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === accountsReducerName
      && action.payload?.fieldName === accountsListsPerLocationStoreKeyName
        ) {
            state[accountsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[accountsListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCurrentUser]: () => initialState,
};


export default createReducer(initialState, handlers);
