import CommonAccountDetails from 'components/common/organisms/CommonAccountDetails';
import usePrepareFormModal from 'hooks/usePrepareFormModal';
import { cleanNilValues } from 'utils/dataMappers';
import { commonValidation, handleWhitespaceOnBlur, numberGreaterThan, numberRule, numberSmallerThan } from 'utils/validation-tools';

import { LoadUnloadMoneyAdjustmentType } from '@manigo/manigo-domain-typings';
import {
    Form, Input, Alert, InputNumber, Radio, Space,
} from 'antd';
import React, { useEffect, useMemo } from 'react';


import { BalanceAdjustmentModalFormData, BalanceAdjustmentModalProps } from './BalanceAdjustmentModal.types';


const { useWatch } = Form;


function BalanceAdjustmentModal({
    enhancedCurrentLocation,
    initialValues,
    dispatchSetModalProps,
    dispatchBalanceAdjustment,
    t,
}: BalanceAdjustmentModalProps) {
    const [form] = Form.useForm();
    const adjustmentType = useWatch('adjustmentType', form);

    usePrepareFormModal({
        form,
        dispatchSetModalProps,
    });


    const amountValidation = useMemo(
        () => ([
            ...numberRule({ t }),
            ...numberGreaterThan('amount', t, 0),
            ...numberSmallerThan({
                t,
                fieldName: 'amount',
                maxValue: adjustmentType === LoadUnloadMoneyAdjustmentType.CREDIT ? 20000 : 50000,
                allowEquality: true,
            }),
        ]),
        [initialValues, adjustmentType, t],
    );


    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchBalanceAdjustment(cleanNilValues(values) as BalanceAdjustmentModalFormData, enhancedCurrentLocation?.pathname);
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
                accountId: initialValues.id,
                adjustmentType: LoadUnloadMoneyAdjustmentType.DEBIT,
            }}
            onFinish={onFinish}
        >
            <CommonAccountDetails t={t} accountDetails={initialValues as any} />

            <Form.Item
                name="accountId"
                hidden
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="adjustmentType"
                label={t('modals.balanceAdjustment.fields.adjustmentType.label')}
            >
                <Radio.Group>
                    <Space direction="horizontal">
                        {
                            Object.values(LoadUnloadMoneyAdjustmentType).map((value) => (
                                <Radio key={value} value={value}>
                                    {t(`modals.balanceAdjustment.fields.adjustmentType.values.${value}`)}
                                </Radio>
                            ))
                        }
                    </Space>
                </Radio.Group>
            </Form.Item>

            <Alert
                description={adjustmentType === LoadUnloadMoneyAdjustmentType.CREDIT
                    ? t('modals.balanceAdjustment.creditWarringInfo')
                    : t('modals.balanceAdjustment.debitWarringInfo')}
                type="warning"
                showIcon
            />

            <br />

            <Form.Item
                name="amount"
                label={t('accountFields.amount.label')}
                rules={amountValidation}
            >
                <InputNumber
                    placeholder={t('accountFields.amount.placeholder')}
                    style={{ width: '100%' }}
                />
            </Form.Item>

            <Form.Item
                name="reference"
                label={t('accountFields.transactionReference.label')}
                rules={[...commonValidation({ t })]}
            >
                <Input
                    placeholder={t('accountFields.transactionReference.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'reference', form)}
                />
            </Form.Item>

            <Form.Item
                name="reason"
                label={t('accountFields.transactionReason.label')}
                rules={[...commonValidation({ t })]}
            >
                <Input
                    placeholder={t('accountFields.transactionReason.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'reason', form)}
                />
            </Form.Item>

            <Form.Item
                name="description"
                label={t('accountFields.transactionDescription.label')}
                rules={[...commonValidation({ t })]}
            >
                <Input
                    placeholder={t('accountFields.transactionDescription.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'description', form)}
                />
            </Form.Item>
        </Form>
    );
}

export default BalanceAdjustmentModal;

