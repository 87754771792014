import { addPartnerUuidToQueryParams } from 'redux/common.helpers';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchCardTiersFailure, fetchCardTiersSuccess } from './actions';
import { FETCH_CARD_TIERS } from './actions.types';


export const onFetchCardTiers = (action$, state$, { cardTiers }) =>
    action$.pipe(
        ofType(FETCH_CARD_TIERS),
        switchMap(({ payload }) =>
            from(
                cardTiers.fetchCardTiers(
                    addPartnerUuidToQueryParams({
                        state$,
                        queryParams: payload?.queryParams,
                    }),
                ),
            ).pipe(
                switchMap((response) =>
                    of(
                        fetchCardTiersSuccess(
                            response.data,
                            response?.data?.length,
                            payload?.locationPathname,
                        ),
                    ),
                ),
                catchError(() =>
                    of(fetchCardTiersFailure(payload?.locationPathname)),
                ),
            ),
        ),
    );


export default [
    onFetchCardTiers,
];
