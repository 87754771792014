/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';
import { cleanNilValues } from 'utils/dataMappers';

import { getCardTiersAPI } from './index';


class CardTiersService {
    public fetchCardTiers(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { partnerUuid } = rawQueryParams;

        return getCardTiersAPI({ params: { ...cleanNilValues({ partnerUuid }) } });
    }
}

export default CardTiersService;
