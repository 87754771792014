import { RawJwtToken } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_ALL_PASSCODE_DIGITS,
    CLEAR_LOGIN_BLOCK,
    CLEAR_REQUEST_PASSWORD_RESET,
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_FAILURE,
    CONFIRM_LOGIN_SUCCESS,
    GET_REMEMBERED_USERNAME_OR_EMAIL_FAILURE,
    GET_REMEMBERED_USERNAME_OR_EMAIL_SUCCESS,
    INITIALISE_LOGIN,
    INITIALISE_LOGIN_FAILURE,
    INITIALISE_LOGIN_SUCCESS,
    REFRESH_TOKEN,
    REFRESH_TOKEN_FAILURE,
    REFRESH_TOKEN_SUCCESS,
    SEND_CONFIRMATION_CODE,
    SEND_CONFIRMATION_CODE_FAILURE,
    SEND_CONFIRMATION_CODE_SUCCESS,
    SET_PASSCODE_DIGIT,
    TOGGLE_REMEMBER_ME,
    UNLOCK_LOGIN,
    UNLOCK_LOGIN_FAILURE,
    UNLOCK_LOGIN_SUCCESS,
    GET_REMEMBERED_USERNAME_OR_EMAIL,
    SET_FAILED_LOGIN_ATTEMPTS,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_SUCCESS,
    SET_NEW_PASSWORD_FAILURE,
    REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_SUCCESS,
    REQUEST_PASSWORD_RESET_FAILURE,
    GET_TOKENS_FOR_DUPLICATED_TAB,
    ConfirmLoginPayload,
    SendConfirmationCodePayload,
    InitialiseLoginPayload,
    SetNewPasswordPayload,
    RequestPasswordResetPayload, UnlockUserPayload, GetTokensForDuplicatedTabPayload, CLEAR_BLOCK_SEND_CONFIRMATION_CODE,
} from './actions.types';


export const initialiseLogin = createAction(INITIALISE_LOGIN, (requestPayload: InitialiseLoginPayload) => ({ payload: requestPayload }));
export const initialiseLoginSuccess = createAction(INITIALISE_LOGIN_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const initialiseLoginFailure = createAction(INITIALISE_LOGIN_FAILURE, (apiError) => ({ payload: apiError }));

export const clearLoginBlock = createAction(CLEAR_LOGIN_BLOCK);

export const getRememberedUsernameOrEmail = createAction(GET_REMEMBERED_USERNAME_OR_EMAIL);
export const getRememberedUsernameOrEmailSuccess = createAction(GET_REMEMBERED_USERNAME_OR_EMAIL_SUCCESS, (userNameOrEmail) => ({ payload: userNameOrEmail }));
export const getRememberedUsernameOrEmailFailure = createAction(GET_REMEMBERED_USERNAME_OR_EMAIL_FAILURE);

export const setFailedLoginAttempts = createAction(SET_FAILED_LOGIN_ATTEMPTS, (count) => ({ payload: count }));

export const toggleRememberMe = createAction(TOGGLE_REMEMBER_ME);

export const confirmLogin = createAction(CONFIRM_LOGIN, (requestPayload: ConfirmLoginPayload) => ({ payload: requestPayload }));
export const confirmLoginSuccess = createAction(CONFIRM_LOGIN_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const confirmLoginFailure = createAction(CONFIRM_LOGIN_FAILURE, (apiError) => ({ payload: apiError }));


export const setNewPassword = createAction(SET_NEW_PASSWORD, (requestPayload: SetNewPasswordPayload) => ({ payload: requestPayload }));
export const setNewPasswordSuccess = createAction(SET_NEW_PASSWORD_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const setNewPasswordFailure = createAction(SET_NEW_PASSWORD_FAILURE);


export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET, (requestPayload: RequestPasswordResetPayload) => ({ payload: requestPayload }));
export const requestPasswordResetSuccess = createAction(REQUEST_PASSWORD_RESET_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const requestPasswordResetFailure = createAction(REQUEST_PASSWORD_RESET_FAILURE);

export const clearRequestPasswordReset = createAction(CLEAR_REQUEST_PASSWORD_RESET);

export const getTokensForDuplicatedTab = createAction(
    GET_TOKENS_FOR_DUPLICATED_TAB,
    (requestPayload: GetTokensForDuplicatedTabPayload, sessionStorageData) => ({ payload: { requestPayload, sessionStorageData } }),
);


export const unlockUser = createAction(UNLOCK_LOGIN, ({ extUserUuid, context }: UnlockUserPayload) => ({ payload: { extUserUuid, context } }));
export const unlockUserSuccess = createAction(UNLOCK_LOGIN_SUCCESS);
export const unlockUserFailure = createAction(UNLOCK_LOGIN_FAILURE);

export const refreshToken = createAction(REFRESH_TOKEN, (jwtToken: RawJwtToken) => ({ payload: { jwtToken } }));
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS);
export const refreshTokenFailure = createAction(REFRESH_TOKEN_FAILURE);

export const setPasscodeDigit = createAction(SET_PASSCODE_DIGIT, (key: string, value: string) => ({ payload: { key, value } }));
export const clearAllPasscodeDigits = createAction(CLEAR_ALL_PASSCODE_DIGITS);


export const sendConfirmationCode = createAction(SEND_CONFIRMATION_CODE, (responsePayload: SendConfirmationCodePayload) => ({ payload: responsePayload }));
export const sendConfirmationCodeSuccess = createAction(SEND_CONFIRMATION_CODE_SUCCESS, (requestPayload: SendConfirmationCodePayload) => ({ payload: requestPayload }));
export const sendConfirmationCodeFailure = createAction(SEND_CONFIRMATION_CODE_FAILURE);
export const clearBlockSendConfirmationCode = createAction(CLEAR_BLOCK_SEND_CONFIRMATION_CODE);
