import FlagAndValue from 'components/common/atoms/FlagAndValue';
import AccountTypeTag from 'components/common/atoms/tags/AccountTypeTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';

import React from 'react';


export default ({ t }) => [
    {
        title: t('productColumns.id'),
        dataIndex: 'id',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('productColumns.name'),
        dataIndex: 'name',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('productColumns.currency'),
        dataIndex: 'currency_code',
        render: (currency_code) => <FlagAndValue currencyCode={currency_code} text={currency_code} showTooltip />,
    },
    {
        title: t('productColumns.accountType'),
        dataIndex: 'account_type',
        align: 'center',
        render: (type) => <AccountTypeTag accountType={type} showTooltip />,
    },
    {
        title: t('productColumns.bankingDetailsAvailable'),
        dataIndex: 'banking_details_available',
        align: 'center',
        render: (text, record) => booleanAsIconsRenderer(record, 'banking_details_available', t),
    },
    {
        title: t('productColumns.description'),
        dataIndex: 'description',
        render: (text) => <StandardValue value={text} />,
    },
];
