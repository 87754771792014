import { HttpService } from 'services/http';


export const startOffboardingAPI = (requestPayload) => HttpService.post('/v2/offboarding', requestPayload);

export const stopOffboardingAPI = (requestPayload) => HttpService.post('/v2/offboarding/restore', requestPayload);

export const finaliseClosureAPI = (requestPayload) => HttpService.post('/v2/offboarding/end', requestPayload);

export default {
    startOffboardingAPI,
    stopOffboardingAPI,
    finaliseClosureAPI,
};
