import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const AutoLogoutBannerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const AutoLogoutBanner = styled.div`
  background-color: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 25px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, .2);
`;

const Message = styled.div`
  font-size: 20px;
`;

const WarningIcon = styled(WarningOutlined)`
  font-size: 50px;
  margin-right: 40px;
  
  path {
    fill: orange;
  }
`;

const Strong = styled.strong`
  display: inline-block;
  width: 27px; /* Fixed width for 2 digits to avoid text movement */
  text-align: center;
  font-weight: 700;
  color: orange;
`;

export default {
    AutoLogoutBannerWrapper,
    AutoLogoutBanner,
    Message,
    WarningIcon,
    Strong,
};
