import { HttpService } from 'services/http';


export const getBusinessUserIdDetails = (businessUserId) => HttpService.get(`/v3/business-users/${businessUserId}`);

export const updateBusinessUserId = (businessUserId, requestPayload) => HttpService.patch(`/v3/business-users/${businessUserId}`, requestPayload, { noErrorHandling: true });
export const getBusinessUsersAPI = (queryParams) => HttpService.get('/v3/business-users/', queryParams);

export const getBusinessUserStatusHistoryAPI = (businessUserId, queryParams) => HttpService.get(`/v3/business-users/${businessUserId}/status-history`, queryParams);

export const updateBusinessUserStatusAPI = (businessUserId, requestPayload) => HttpService.put(`/v3/business-users/${businessUserId}/status`, requestPayload);
