import TabSubHeading from 'components/common/atoms/TabSubHeading';
import CommonStyled from 'components/common/atoms/styled/UpdateStatus.styled';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CompanyListItemProps } from 'constants/CompaniesModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import { commonValidation, handleWhitespaceOnBlur } from 'utils/validation-tools';

import { Button, Form, Input, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { getCompanyStatusOptions, getStatusHint } from './UpdateCompanyStatusForm.helpers';


const { useWatch } = Form;

function UpdateCompanyStatusForm({
    t,
    enhancedCurrentLocation,
    accessControl,
    entityData,
    companyId,
    isLoadingStatusUpdate,
    shouldResetForm,
    dispatchUpdateCompanyStatus,
    dispatchClearResetForm,
}) {
    const [form] = Form.useForm();

    const newStatus = useWatch('companyStatus', form);

    const onFinishHandler = (values) => {
        const baseURLSegmentsCount = 2;
        const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);
        dispatchUpdateCompanyStatus(companyId, values, baseUrlWithoutTabsSlugs);
    };

    const statusOptions = useMemo(() => getCompanyStatusOptions(entityData?.company_status, accessControl), [entityData?.company_status]);
    const statusOptionsHasItems = !!statusOptions.length;

    const alertObject = useMemo(() => getStatusHint(newStatus), [newStatus]);
    const getTranslation = (key) => t(`companyDetails.tabs.statusManagement.changeStatus.${key}`);

    const getHintAlertProps = () => {
        const handlers = [
            {
                predicate: () => alertObject?.text,
                handler: () => ({
                    message: getTranslation(`fields.companyStatus.companyStatusesDescriptions.${alertObject?.text}`),
                    type: alertObject.color,
                }),
            },
            {
                predicate: () => !statusOptionsHasItems,
                handler: () => ({
                    message: getTranslation('fields.companyStatus.transitionNotAvailableHint'),
                    type: 'warning',
                }),
            },
            {
                predicate: () => true,
                handler: () => ({
                    message: getTranslation('fields.companyStatus.companyStatusesDescriptionsPlaceholder'),
                    type: 'info',
                }),
            },
        ];
        return handlers.find(({ predicate }) => predicate()).handler();
    };

    useEffect(() => {
        if (shouldResetForm) {
            form.resetFields();
            dispatchClearResetForm();
        }
    }, [shouldResetForm]);

    return (
        <CommonStyled.FormCol>
            <TabSubHeading text={t('Update Company Status')} />
            <div>
                <Spin spinning={isLoadingStatusUpdate}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="updateCompanyStatusForm"
                        initialValues={{}}
                        style={{ maxWidth: '500px' }}
                        onFinish={onFinishHandler}
                    >
                        {
                            statusOptionsHasItems && (
                                <Form.Item
                                    name="companyStatus"
                                    label={t(getTranslation('fields.companyStatus.label'))}
                                >
                                    <Select
                                        placeholder={t(getTranslation('fields.companyStatus.placeholder'))}
                                        options={statusOptions}
                                    />
                                </Form.Item>
                            )
                        }
                        <CommonStyled.HintAlert
                            {...getHintAlertProps()}
                            showIcon
                        />
                        {
                            statusOptionsHasItems && (
                                <>
                                    <Form.Item
                                        name="companyStatusComment"
                                        label={t(getTranslation('fields.companyStatusComment.label'))}
                                        rules={[...commonValidation({ fieldName: 'companyStatusComment', t, isRequired: true })]}
                                    >
                                        <Input
                                            placeholder={t(getTranslation('fields.companyStatusComment.placeholder'))}
                                            onBlur={(e) => handleWhitespaceOnBlur(e, 'companyStatusComment', form)}
                                        />
                                    </Form.Item>
                                    <CommonStyled.ButtonsWrapper>
                                        <Button type="primary" htmlType="submit">
                                            {t(getTranslation('submitButtonLabel'))}
                                        </Button>
                                    </CommonStyled.ButtonsWrapper>
                                </>
                            )
                        }
                    </Form>
                </Spin>
            </div>
        </CommonStyled.FormCol>
    );
}
UpdateCompanyStatusForm.propTypes = {
    t: PropTypes.func.isRequired,
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
    accessControl: AccessControlProps,
    entityData: CompanyListItemProps,
    companyId: PropTypes.number.isRequired,
    isLoadingStatusUpdate: PropTypes.bool.isRequired,
    shouldResetForm: PropTypes.bool.isRequired,
    dispatchUpdateCompanyStatus: PropTypes.func.isRequired,
    dispatchClearResetForm: PropTypes.func.isRequired,
};

export default UpdateCompanyStatusForm;
