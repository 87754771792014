import StandardMultiStepForm from 'components/common/organisms/StandardMultiStepForm';
import {
    parseDefaultClientConfigurationToInitialFormData,
    parseDefaultClientConfigurationToSubStepsConfig,
    createActionsForStepSuccessfulValidation,
} from 'components/common/organisms/forms-shared-parts/add-edit-client-form/common.helpers';
import { createClientDetailsPath, createClientsListPath } from 'config/routes.helpers';
import { AddClientAddressesAvailableStepsDefinitions, AddClientFormVariants, AddClientStepsDefinitions } from 'constants/AddClientModel';
import { ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { currentStepNumberInAvailableSteps, getLastStepExceptSuccessFromAvailableSteps } from 'redux/multi-steps-form/epics.helpers';

import { Form, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import {

    createAddClientStepsConfiguration,
    createAccountsProductsInitialFormData,
    handleShippingAndBillingAddressCopying,
    extractCurrentAddress, createCompanyAddressInitialFormData,
} from './AddClientForm.helpers';


function AddClientForm({
    locationPathname,
    formVariant,
    isLoadingAddClient,
    shippingAddressSameAsCompanyAddress,
    billingAddressSameAsCompanyAddress,

    defaultNewClientConfiguration,
    bpsProductsList,

    dispatchFetchBpsProducts,
    dispatchFetchDefaultClientConfiguration,
    dispatchAddClient,


    availableSteps,
    availableSubSteps,
    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,

    dispatchRequestSetMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormSubStep,
    dispatchClearAddClientStateData,

    newlyCreatedPartnerData,

    t,
}) {
    const [form] = Form.useForm();

    const lastStepExceptSuccess = useMemo(
        () => getLastStepExceptSuccessFromAvailableSteps(availableSteps),
        [availableSteps],
    );

    const currentSubStepNumber = useMemo(
        () => {
            if (availableSubSteps && availableSubSteps?.length > 0 && currentMultiStepsFormSubStep) {
                return currentStepNumberInAvailableSteps(availableSubSteps, currentMultiStepsFormSubStep);
            }
            return undefined;
        },
        [currentMultiStepsFormSubStep, availableSubSteps],
    );

    const onSuccessViewNewEntityPathname = useMemo(
        () => {
            if (newlyCreatedPartnerData && newlyCreatedPartnerData?.id > 0) {
                return createClientDetailsPath(newlyCreatedPartnerData?.id);
            }
            return undefined;
        },
        [newlyCreatedPartnerData],
    );

    useEffect(
        () => {
            // semi-static data it seems there is no n eed to re-fetch it
            if (formVariant === AddClientFormVariants.ADD_PARTNER) {
                if (!defaultNewClientConfiguration) {
                    dispatchFetchDefaultClientConfiguration();
                }
                // semi-static data it seems there is no n eed to re-fetch it
                if (!bpsProductsList) {
                    dispatchFetchBpsProducts();
                }
            }

            return () => {
                dispatchClearAddClientStateData();
            };
        }, [],
    );

    const finalInitialFormData = useMemo(
        () => {
            if (defaultNewClientConfiguration?.configuration) {
                return {
                    ...parseDefaultClientConfigurationToInitialFormData(defaultNewClientConfiguration.configuration),
                    ...createAccountsProductsInitialFormData(),

                    ...createCompanyAddressInitialFormData(),
                };
            }

            return undefined;
        },
        [defaultNewClientConfiguration],
    );

    const stepsConfiguration = useMemo(
        () => {
            if (
                finalInitialFormData
        && bpsProductsList
            ) {
                return createAddClientStepsConfiguration({
                    t,
                    parsedSettingsConfig: parseDefaultClientConfigurationToSubStepsConfig(defaultNewClientConfiguration.configuration, t),
                    initialFormData: finalInitialFormData,
                    shippingAddressSameAsCompanyAddress,
                    billingAddressSameAsCompanyAddress,
                });
            }

            return undefined;
        },
        [
            bpsProductsList,
            finalInitialFormData,
            shippingAddressSameAsCompanyAddress,
            billingAddressSameAsCompanyAddress,
        ],
    );

    useEffect(() => {
    // XXX we want to react only to later changes, not on init
        if (finalInitialFormData && stepsConfiguration) {
            handleShippingAndBillingAddressCopying({
                values: extractCurrentAddress(form),
                form,
                shippingAddressSameAsCompanyAddress,
                billingAddressSameAsCompanyAddress,
            });
        }
    }, [shippingAddressSameAsCompanyAddress, billingAddressSameAsCompanyAddress, finalInitialFormData, stepsConfiguration]);


    const onFinishHandler = (values) => {
        if (currentMultiStepsFormStep === AddClientStepsDefinitions.ADDRESSES
      && currentMultiStepsFormSubStep === AddClientAddressesAvailableStepsDefinitions.COMPANY_ADDRESS
        ) {
            handleShippingAndBillingAddressCopying({
                values,
                form,
                shippingAddressSameAsCompanyAddress,
                billingAddressSameAsCompanyAddress,
            });
        }

        createActionsForStepSuccessfulValidation({
            values,

            locationPathname,
            currentMultiStepsFormStep,
            currentMultiStepsFormSubStep,
            availableSubSteps,
            dispatchFinalApiCall: dispatchAddClient,
            dispatchRequestSetMultiStepsFormStep,
            dispatchRequestSetMultiStepsFormSubStep,

            currentSubStepNumber,
            lastStepExceptSuccess,
        });
    };


    return finalInitialFormData && stepsConfiguration
        ? (
            <StandardMultiStepForm
                formName="addClient"
                form={form}
                isLoading={isLoadingAddClient}
                locationPathname={locationPathname}
                stepsConfiguration={stepsConfiguration}
                initialFormData={finalInitialFormData}
                reasonForBlockedNavigation={ReasonsForBlockedNavigation.NOT_PRISTINE_ADD_CLIENT_FORM}
                onFinishHandler={onFinishHandler}
                onSuccessNewLocationPathname={onSuccessViewNewEntityPathname}
                onSuccessNewLocationLabel="View new partner"
                onSuccessGoBackLabel="View partners list"
                goBackPathname={createClientsListPath()}
                onSubmitSpinnerCopy={'Submitting new partner...'}
            />
        )
        : (<Skeleton loading active />);
}

AddClientForm.propTypes = {
    // mapped props
    isLoadingAddClient: PropTypes.bool,
    shippingAddressSameAsCompanyAddress: PropTypes.bool,
    billingAddressSameAsCompanyAddress: PropTypes.bool,


    dispatchFetchBpsProducts: PropTypes.func,
    dispatchFetchDefaultClientConfiguration: PropTypes.func,
    dispatchAddClient: PropTypes.func,
    dispatchRequestSetMultiStepsFormStep: PropTypes.func,
    dispatchRequestSetMultiStepsFormSubStep: PropTypes.func,
    dispatchClearAddClientStateData: PropTypes.func,
};


export default AddClientForm;
