import { AddEditBeneficiaryFormCurrentStep, AddEditBeneficiarySteps } from 'constants/BeneficiariesModel';

import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function BackButton({
    isEditMode,
    currentStep,
    returnPath,
    dispatchRequestNavigation,
    dispatchGoToPreviousAddEditBeneficiaryFormStep,
    t,
}) {
    const data = useMemo(
        () => {
            const handlers = [
                {
                    predicate: () => currentStep === AddEditBeneficiarySteps.BASIC_DATA || (currentStep === AddEditBeneficiarySteps.ADDRESS && isEditMode),
                    handler: () => ({
                        onClick: () => dispatchRequestNavigation(returnPath),
                        text: t('common:buttons.cancel.text'),
                        dataTestId: 'cancel-button',
                    }),
                },
                {
                    predicate: () => true,
                    handler: () => ({
                        onClick: () => dispatchGoToPreviousAddEditBeneficiaryFormStep(currentStep),
                        text: t('common:buttons.back.text'),
                        dataTestId: 'back-button',
                    }),
                },
            ];

            return handlers.filter(({ predicate }) => predicate())[0].handler();
        },
        [currentStep, isEditMode],
    );

    return (
        <Button
            onClick={data.onClick}
            data-test-id={data.dataTestId}
        >
            {data.text}
        </Button>
    );
}

BackButton.propTypes = {
    dispatchGoToPreviousAddEditBeneficiaryFormStep: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    currentStep: AddEditBeneficiaryFormCurrentStep,
    isEditMode: PropTypes.bool,
    returnPath: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

export default BackButton;
