import LimitsList from 'components/common/templates/lists/LimitsList';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';

import { TFunction } from 'i18next';
import React from 'react';


function Limits({ t }: { t: TFunction }) {
    return (
        <>
            <StandardPageHeading title={t('limits:limitsPage.screenTitle')} />

            <StandardPageWrapper>
                <LimitsList />
            </StandardPageWrapper>
        </>
    );
}

export default Limits;
