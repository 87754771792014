import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setModalProps } from 'redux/modal/actions';
import { blockNavigation, showNavigationConfirmation, unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { fetchWebhooksActionTypes, registerWebhook, updateWebhook } from 'redux/webhooks/actions';
import { webhooksReducerName } from 'redux/webhooks/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddEditWebhookModal from './AddEditWebhookModal.layout';


const mapStateToProps = (state:RootState) => ({
    webhookActionTypes: state[webhooksReducerName].webhookActionTypes,
    isLoadingWebhookActionTypes: state[webhooksReducerName].isLoadingWebhookActionTypes,
    isBlockNavigationReasonSet: state[navigationReducerName].blockNavigationReason,
});


const mapDispatchToProps = (dispatch:Dispatch) => ({
    dispatchFetchWebhooksActionTypes: () => dispatch(fetchWebhooksActionTypes()),
    dispatchUpdateWebhook: ({ webhookUuid, formData, locationPathname }) => dispatch(updateWebhook({ webhookUuid, formData, locationPathname })),
    dispatchRegisterWebhook: (formData, locationPathname) => dispatch(registerWebhook(formData, locationPathname)),
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchBlockNavigation: (reason) => dispatch(blockNavigation(showNavigationConfirmation(), reason)),
    dispatchUnblockNavigation: () => dispatch(unblockNavigation()),
});

const decorate = compose(
    withTranslation('webhooks'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddEditWebhookModal);
