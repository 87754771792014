import { WL_CONFIG } from 'config/config';

import { Tooltip } from 'antd';
import styled, { css } from 'styled-components';


const StandardTooltipWithOverriddenStyles = styled(Tooltip)`
    outline: 1px solid transparent;
` as typeof Tooltip;

const CopyTransparentOverlay = styled.span`
  cursor: pointer;
  color: inherit;
`;

const StandardOverlay = styled.span`
  cursor: help;

  overflow: hidden;
`;

const TooltipCopy2Clipboard = styled.div`
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid white;
  text-align: center;
  font-size: 11px;
`;

const TooltipValue = styled.div<{ $isAmount?: boolean }>`
  text-align: center;
  font-weight: 600;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-all;
  white-space: pre-wrap; 
  
  ${({ $isAmount }) => $isAmount && css`
    ${WL_CONFIG.themeVariables?.monoFontFamily};
  `};
`;

export default {
    StandardTooltipWithOverriddenStyles,
    CopyTransparentOverlay,
    StandardOverlay,
    TooltipCopy2Clipboard,
    TooltipValue,
};
