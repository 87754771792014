import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import { Select } from 'antd';
import styled from 'styled-components';


const BpsProductsSelectComponent = styled(Select)`
  .ant-select-selection-item {
    ${commonTextEllipsisStyles};
  }
`;


const BpsOptionWrapper = styled.span`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-row-gap: 1ch;
`;


const OptionSubValueWrapper = styled.span`
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 1ch;
  
`;

const BpsOptionLabel = styled.span`
  max-width: 100%;
  margin-left: 10px;

`;
const BpsOptionValue = styled.code`
  font-family: monospace;
  ${commonTextEllipsisStyles};
`;

export default {
    BpsProductsSelectComponent,
    BpsOptionWrapper,
    OptionSubValueWrapper,
    BpsOptionLabel,
    BpsOptionValue,
};
