export const FETCH_TRANSACTION_DETAILS = 'FETCH_TRANSACTION_DETAILS';
export const FETCH_TRANSACTION_DETAILS_SUCCESS = 'FETCH_TRANSACTION_DETAILS_SUCCESS';
export const FETCH_TRANSACTION_DETAILS_FAILURE = 'FETCH_TRANSACTION_DETAILS_FAILURE';
export const CLEAR_TRANSACTION_DETAILS = 'CLEAR_TRANSACTION_DETAILS';


export const UNBLOCK_TRANSACTION = 'UNBLOCK_TRANSACTION';
export const UNBLOCK_TRANSACTION_SUCCESS = 'UNBLOCK_TRANSACTION_SUCCESS';
export const UNBLOCK_TRANSACTION_FAILURE = 'UNBLOCK_TRANSACTION_FAILURE';

export const AUDIT_PENDING_TRANSACTION = 'AUDIT_PENDING_TRANSACTION';
export const AUDIT_PENDING_TRANSACTION_SUCCESS = 'AUDIT_PENDING_TRANSACTION_SUCCESS';
export const AUDIT_PENDING_TRANSACTION_FAILURE = 'AUDIT_PENDING_TRANSACTION_FAILURE';
