import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_MARKETING_PREFERENCES,
    FETCH_MARKETING_PREFERENCES_FAILURE,
    FETCH_MARKETING_PREFERENCES_SUCCESS,

    CLEAR_MARKETING_PREFERENCES_LIST,

    REQUEST_MARKETING_PREFERENCES,
    REQUEST_MARKETING_PREFERENCES_SUCCESS,
    REQUEST_MARKETING_PREFERENCES_FAILURE,

    SHOW_REQUEST_MARKETING_PREFERENCES_MODAL,
} from './actions.types';


export const fetchMarketingPreferences = createAction(FETCH_MARKETING_PREFERENCES, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));

export const fetchMarketingPreferencesSuccess = createAction(FETCH_MARKETING_PREFERENCES_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));

export const fetchMarketingPreferencesFailure = createAction(FETCH_MARKETING_PREFERENCES_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearMarketingPreferencesList = createAction(CLEAR_MARKETING_PREFERENCES_LIST, (locationPathname) => ({ payload: { locationPathname } }));

export const requestMarketingPreferences = createAction(REQUEST_MARKETING_PREFERENCES, (queryParams) => ({ payload: { queryParams } }));
export const requestMarketingPreferencesSuccess = createAction(REQUEST_MARKETING_PREFERENCES_SUCCESS);
export const requestMarketingPreferencesFailure = createAction(REQUEST_MARKETING_PREFERENCES_FAILURE);

export const showMarketingPreferencesModal = createAction(SHOW_REQUEST_MARKETING_PREFERENCES_MODAL, (modalProps) => ({ payload: { modalProps } }));

