import { scrollBar } from 'assets/styles/scrollbar';

import styled from 'styled-components';


const StandardMultiStepFormLayout = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 16px;
  height: 100%;
`;
const StandardMultiStepFormWrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;  
  
  form {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    height: 100%;
  }
`;

const StandardMultiStepFormContentAndButtonsWrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 16px;
  height: 100%;
`;

const DummyDivToMatchGlobalLayoutStyles = styled.div`
  pointer-events: none;
`;

const StandardMultiStepFormContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
`;

const StandardMultiStepFormTabContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 16px;
  height: 100%;
  position: relative;
`;

const StandardMultiStepFormTabContentSubStepsWrapper = styled.div`
  display: grid;
`;

const StandardMultiStepFormTabContentSubStepContentWrapper = styled.div`
  display: block;
  overflow: auto;
  position: absolute;
  top: 0;
  
  
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  ${scrollBar};
  background: #fff;
`;

const StandardMultiStepFormTabContentSubStepContent = styled.div`
  display: block;
  position: relative;
`;


export default {
    StandardMultiStepFormLayout,
    StandardMultiStepFormWrapper,
    StandardMultiStepFormContentAndButtonsWrapper,
    DummyDivToMatchGlobalLayoutStyles,
    StandardMultiStepFormContent,
    StandardMultiStepFormTabContentWrapper,
    StandardMultiStepFormTabContentSubStepsWrapper,
    StandardMultiStepFormTabContentSubStepContentWrapper,
    StandardMultiStepFormTabContentSubStepContent,
};
