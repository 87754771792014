import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { CountryIdType } from 'constants/CountriesModel';

import dayjs from 'dayjs';
import React from 'react';


export const extractRelevantDataFromIsoMessage = (data) => {
    if (!data) {
        return undefined;
    }

    return {
        merchantName: `${data['43']}`.slice(0, 22).trim(),
        country: `${data['43']}`.slice(37).trim(),
        city: `${data['43']}`.slice(23, 35).trim(),
        // eslint-disable-next-line spellcheck/spell-checker
        date: dayjs(data['13'], 'MMDD'),
        // eslint-disable-next-line spellcheck/spell-checker
        time: dayjs(data['12'], 'HHmmss'),

        ...(data['42'] ? { merchantId: data['42'] } : {}),
        ...(data['37'] ? { retrievalReferenceNumber: data['37'] } : {}),
        ...(data['18'] ? { mccNumber: data['18'] } : {}),
        ...(data['32'] ? { acquirerId: data['32'] } : {}),
        ...(data['41'] ? { terminalId: data['41'] } : {}),
    };
};

export const createCardTransactionCol1Fields = (parsedData, t) => {
    if (parsedData) {
        return [
            {
                value: <StandardValue value={parsedData.date} valueType={ValueTypes.DATE_WITHOUT_YEAR} />,
                label: t('Date'),
            },
            {
                value: <StandardValue value={parsedData.time} valueType={ValueTypes.TIME} />,
                label: t('Time'),
            },
            {
                value: <StandardValue value={parsedData.city} />,
                label: t('common:addressFields.city.label'),
            },
            {
                value: <CountryRenderer
                    propertyName={parsedData.country.length === 2 ? CountryIdType.iso_31662 : CountryIdType.iso_31663}
                    value={parsedData.country}
                />,
                label: t('common:addressFields.country.label'),
            },

        ];
    }

    return [];
};
export const createCardTransactionCol2Fields = (parsedData, t) => {
    if (parsedData) {
        return [
            {
                value: <StandardValue value={parsedData.merchantName} />,
                label: t('Merchant name'),
            },
            {
                value: <StandardValue value={parsedData.retrievalReferenceNumber} />,
                label: t('Retrieval reference number'),
            },
            {
                value: <StandardValue value={parsedData.merchantId} />,
                label: t('Merchant ID'),
            },
            {
                value: <StandardValue value={parsedData.terminalId} />,
                label: t('Terminal ID'),
            },

        ];
    }

    return [];
};

export const createCardTransactionTmpFields = (cardTransactionData, t) => {
    if (cardTransactionData) {
        return [
            {
                value: <StandardValue value={cardTransactionData.cardToken} />,
                label: t('Card token'),
            },

            {
                value: <StandardValue value={cardTransactionData.mcc} />,
                label: t('MCC'),
            },
        ];
    }

    return [];
};

export default {
    createCardTransactionTmpFields,
    extractRelevantDataFromIsoMessage,
    createCardTransactionCol1Fields,
    createCardTransactionCol2Fields,
};
