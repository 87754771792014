import StandardTag from 'components/common/atoms/StandardTag';
import { StakeholderStatus } from 'constants/StakeholderModel';

import PropTypes from 'prop-types';
import React from 'react';


const createHandlers = (status) => {
    const handlers = [
        {
            predicate: () => status === StakeholderStatus.KYC_PENDING,
            handler: () => ({
                color: 'orange',
                label: 'KYC pending',
            }),
        },
        {
            predicate: () => status === StakeholderStatus.VERIFIED,
            handler: () => ({
                color: 'green',
                label: 'Verified',
            }),
        },
        {
            predicate: () => status === StakeholderStatus.KYC_UPLOADED,
            handler: () => ({
                color: 'blue',
                label: 'KYC uploaded',
            }),
        },
        {
            predicate: () => status === StakeholderStatus.KYC_REJECTED,
            handler: () => ({
                color: 'red',
                label: 'KYC rejected',
            }),
        },
        {
            predicate: () => true,
            handler: () => ({ label: 'Unknown', color: 'red' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function StakeholderStatusTag({ status, showTooltip = false }) {
    const tagData = createHandlers(status);

    return <StandardTag color={tagData.color} text={tagData.label} showTooltip={showTooltip} />;
}

StakeholderStatusTag.propTypes = {
    status: PropTypes.oneOf([...Object.values(StakeholderStatus)]),
    showTooltip: PropTypes.bool,
};

export default StakeholderStatusTag;
