import PropTypes from 'prop-types';
import React from 'react';

import Styled from './StandardPageWrapper.styled';


function StandardPageWrapper({ children }) {
    return (
        <Styled.AppLayoutContent>
            <Styled.MainContentWrapper>
                <Styled.MainContentInnerWrapper>
                    {children}
                </Styled.MainContentInnerWrapper>
            </Styled.MainContentWrapper>
        </Styled.AppLayoutContent>
    );
}

StandardPageWrapper.propTypes = { children: PropTypes.node };

export default StandardPageWrapper;
