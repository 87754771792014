import { convertHexToRGB } from 'utils/css-tools';

import { UserOutlined, CaretDownOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const UserDropdownWrapper = styled.div`
  cursor: pointer;
  
  .ant-popover-inner-content {
    padding: 0 !important;
    margin: 0;
  }
  
  &:hover {
    color: var(--btn-primary-bg);
    
    svg {
      fill: var(--btn-primary-bg);
    } 
  }
  
  .anticon-caret-down {
    transition: transform 200ms ease;
  }
  
 .ant-popover-open {
   .anticon-caret-down {
     color: var(--btn-primary-bg);
     transform: rotate(180deg);
   }
   
   svg {
     fill: var(--btn-primary-bg);
   }
 }
`;

const UserDropdownContent = styled.span`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 5px;
  align-items: center;
`;

const UserDropdownUserIcon = styled(UserOutlined)`
  font-size: 20px;
  margin-left: 5px;
`;

const CaretOutlined = styled(CaretDownOutlined)`
  font-size: 10px;
`;

const UserDropdownNameWrapper = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16px;
`;

const UserDropdownFirstName = styled.span`
  text-align: right;
  display: block;
  justify-items: center;
`;

const UserDropdownLastName = styled.span`
  text-align: right;
  display: block;
  justify-items: center;
`;

const UserDropdownOption = styled.span`
  display: block;
  transition: color 200ms ease;

  button {
   width: 100%;
   text-align: left;
   padding: 10px;
   grid-template-columns: auto 1fr;
   display: grid;
   align-items: center;
   height: auto;

  &:hover {
      background: rgba(${convertHexToRGB('#397dcc', true)}, 0.12);
      color: var(--primary-color);
      
        svg {
          fill: var(--primary-color);
        }
    }
  }
`;


export default {
    UserDropdownWrapper,
    UserDropdownContent,
    UserDropdownUserIcon,
    UserDropdownNameWrapper,
    UserDropdownFirstName,
    UserDropdownLastName,
    CaretOutlined,
    UserDropdownOption,
};

