import UserRoleRenderer from 'components/common/atoms/tags/UserRoleRenderer';
import UserStatusTag from 'components/common/atoms/tags/UserStatusTag';
import ClientRenderer from 'components/common/molecules/value-renderers/ClientRenderer';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { formatPhoneNumber } from 'utils/phone-number-tools';

import React from 'react';


export function createCurrentUserFieldsConfig({
    userDetails,
    t,
}) {
    if (userDetails?.id) {
        return [
            {
                label: t('userFields.partner.label'),
                value: <ClientRenderer clientId={userDetails.client_id} />,
            },
            {
                label: t('userFields.status.label'),
                value: <UserStatusTag userStatus={userDetails.status} t={t} />,
            },
            {
                label: t('userFields.role.label'),
                value: <UserRoleRenderer roleObject={userDetails?.role} />,
            },
            {
                label: t('userFields.lastLogin.label'),
                showEmpty: true,
                value: <StandardValue value={userDetails.last_login} valueType="DATE" showTimeInTooltip />,
            },
            {
                label: t('userFields.firstName.label'),
                showEmpty: true,
                value: userDetails.first_name,
            },
            {
                label: t('userFields.lastName.label'),
                showEmpty: true,
                value: userDetails.last_name,
            },
            {
                label: t('userFields.username.label'),
                showEmpty: true,
                value: userDetails.username,
            },
            {
                label: t('userFields.email.label'),
                showEmpty: true,
                value: userDetails.email,
            },
            {
                label: t('userFields.phone.label'),
                value: formatPhoneNumber(userDetails.phone || ''),
            },
            {
                label: t('userFields.birthday.label'),
                value: <StandardValue value={userDetails.birthday} valueType="DATE" />,
            },
            {
                label: t('userFields.country.label'),
                showEmpty: true,
                value: <CountryRenderer propertyName="id" value={userDetails.country_id} />,
            },
            {
                label: t('userFields.createdAt.label'),
                value: <StandardValue value={userDetails.created_at} valueType="DATE" showTimeInTooltip />,
            },
            {
                label: t('userFields.updatedAt.label'),
                value: <StandardValue value={userDetails.updated_at} valueType="DATE" showTimeInTooltip />,
            },
        ];
    }

    return [];
}


export default { createCurrentUserFieldsConfig };
