import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_CARD_STATUS_HISTORY,
    FETCH_CARD_STATUS_HISTORY,
    FETCH_CARD_STATUS_HISTORY_FAILURE,
    FETCH_CARD_STATUS_HISTORY_SUCCESS,
    TOGGLE_CHANGE_CARD_STATUS_FORM_VISIBILITY, UPDATE_CARD_STATUS, UPDATE_CARD_STATUS_FAILURE, UPDATE_CARD_STATUS_SUCCESS,
} from './action.types';


export const fetchCardStatusHistory = createAction(FETCH_CARD_STATUS_HISTORY, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchCardStatusHistorySuccess = createAction(
    FETCH_CARD_STATUS_HISTORY_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchCardStatusHistoryFailure = createAction(FETCH_CARD_STATUS_HISTORY_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearCardStatusHistory = createAction(CLEAR_CARD_STATUS_HISTORY, (locationPathname) => ({ payload: { locationPathname } }));

export const toggleChangeCardStatusFormVisibility = createAction(TOGGLE_CHANGE_CARD_STATUS_FORM_VISIBILITY, (value) => ({ payload: value }));


export const updateCardStatus = createAction(UPDATE_CARD_STATUS, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const updateCardStatusSuccess = createAction(UPDATE_CARD_STATUS_SUCCESS, (response) => ({ payload: response }));
export const updateCardStatusFailure = createAction(UPDATE_CARD_STATUS_FAILURE);

