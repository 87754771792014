import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function EntityDetailsInfoSkeleton({
    children,
    isLoading,
    minHeight,
    sectionsCount = 1,
    sectionsRowsCount = 5,
}) {
    return isLoading
        ? (
            <div style={{ background: '#fff', display: 'flex', gap: '40px', padding: '16px', marginBottom: '16px' }}>
                {[...Array(sectionsCount).keys()].map((index) => (
                    <Skeleton
                        active
                        loading={isLoading}
                        key={`entityDetails-infoSkeleton-${index}`}
                        paragraph={{ rows: sectionsRowsCount, width: '100%' }}
                        style={{ minHeight }}
                    />
                ))}
            </div>
        )
        : children;
}

EntityDetailsInfoSkeleton.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    minHeight: PropTypes.string,
    sectionsCount: PropTypes.number,
    sectionsRowsCount: PropTypes.number,
};
export default EntityDetailsInfoSkeleton;
