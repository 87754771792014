// XXX has to be old not form MTD!
import FlagAndValue from 'components/common/atoms/FlagAndValue';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { CountryIdType } from 'constants/CountriesModel';

import { Beneficiary, BeneficiaryType } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React from 'react';

import { BeneficiaryInfoFieldConfig } from './BeneficiaryInfoLayout.types';


export function createBeneficiaryFieldsConfig({
    t,
    beneficiaryDetails,
    editedFieldsNames,
}: {
  editedFieldsNames?: string[],
  beneficiaryDetails?: Beneficiary,
  t: TFunction
}): BeneficiaryInfoFieldConfig[] {
    return [
        {
            label: t('beneficiaries:beneficiaryFields.beneficiaryType.label'),
            value: beneficiaryDetails?.beneficiaryType,
        },

        ...(beneficiaryDetails?.companyName
            ? [{
                label: t('beneficiaries:beneficiaryFields.companyName.label'),
                highlightValue: editedFieldsNames?.includes('companyName'),
                value: beneficiaryDetails?.companyName,
            }]
            : []),

        ...(beneficiaryDetails?.firstName
            ? [{
                label: t('beneficiaries:beneficiaryFields.firstName.label'),
                highlightValue: editedFieldsNames?.includes('firstName'),
                value: beneficiaryDetails?.firstName,
            }]
            : []),

        ...((beneficiaryDetails?.middleName || (beneficiaryDetails?.middleName === BeneficiaryType.INDIVIDUAL))
            ? [{
                label: t('beneficiaries:beneficiaryFields.middleName.label'),
                highlightValue: editedFieldsNames?.includes('middleName'),
                value: beneficiaryDetails?.middleName,
            }]
            : []),

        ...(beneficiaryDetails?.lastName
            ? [{
                label: t('beneficiaries:beneficiaryFields.lastName.label'),
                highlightValue: editedFieldsNames?.includes('lastName'),
                value: beneficiaryDetails?.lastName,
            }]
            : []),

        {
            label: t('beneficiaries:beneficiaryFields.country.label'),
            value: <CountryRenderer propertyName={CountryIdType.iso_31663} value={beneficiaryDetails?.country} />,
        },
        {
            label: t('beneficiaries:beneficiaryFields.currency.label'),
            value: <FlagAndValue currencyCode={beneficiaryDetails?.currency} text={beneficiaryDetails?.currency} />,
        },

        ...(beneficiaryDetails?.id
            ? [{
                label: t('beneficiaries:beneficiaryFields.uuid'),
                value: beneficiaryDetails?.id,
                showCopyToClipboard: true,
                autoWidthOnValue: true,
            }]
            : []),

        ...(beneficiaryDetails?.createdAt
            ? [{
                label: t('beneficiaries:beneficiaryFields.createdAt'),
                value: <StandardValue value={beneficiaryDetails.createdAt} valueType={ValueTypes.DATE} showTime />,
            }]
            : []),

        ...(beneficiaryDetails?.createdAt
            ? [{
                label: t('beneficiaries:beneficiaryFields.updatedAt'),
                value: <StandardValue value={beneficiaryDetails.updatedAt} valueType={ValueTypes.DATE} showTime />,
            }]
            : []),
    ];
}


export function createBeneficiaryAddressFieldsConfig({
    t,
    beneficiaryDetails,
    editedFieldsNames,
}: {
  editedFieldsNames?: string[],
  beneficiaryDetails?: Beneficiary,
  t: TFunction
}): BeneficiaryInfoFieldConfig[] {
    return [
        {
            label: t('common:addressFields.addressLine1.label'),
            highlightValue: editedFieldsNames?.includes('address.addressLine1'),
            value: beneficiaryDetails?.address.addressLine1,
        },

        ...(beneficiaryDetails?.address?.addressLine2
            ? [{
                label: t('common:addressFields.addressLine2.label'),
                highlightValue: editedFieldsNames?.includes('address.addressLine2'),
                value: beneficiaryDetails?.address.addressLine2,
            }]
            : []),

        {
            label: t('common:addressFields.city.label'),
            highlightValue: editedFieldsNames?.includes('address.city'),
            value: beneficiaryDetails?.address.city,
        },
        {
            label: t('common:addressFields.postCode.label'),
            highlightValue: editedFieldsNames?.includes('address.zipCode'),
            value: beneficiaryDetails?.address.zipCode,
        },
        {
            label: t('common:addressFields.country.label'),
            highlightValue: editedFieldsNames?.includes('address.country'),
            value: <CountryRenderer propertyName={CountryIdType.iso_31663} value={beneficiaryDetails?.address.country} />,
        },


        ...(beneficiaryDetails?.address?.stateProvince
            ? [{
                label: t('common:addressFields.state.label'),
                highlightValue: editedFieldsNames?.includes('address.stateProvince'),
                value: beneficiaryDetails?.address.stateProvince,
            }]
            : []),
    ];
}


export default { createBeneficiaryFieldsConfig, createBeneficiaryAddressFieldsConfig };

