import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import ChangeLog from './ChangeLog.layout';


const decorate = compose(
    withTranslation('changeLog'),
);

export default decorate(ChangeLog);

