import FlagAndValue from 'components/common/atoms/FlagAndValue';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import {
    createFeeDetailsPath,
    createTransactionDetailsPath,
} from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';

import { Space } from 'antd';
import React from 'react';


function createAppliedFeesListTableColumns({
    t,
    accessControl,
    dispatchRequestNavigation,
}) {
    return [
        {
            title: t('fees:feeFields.feeUuid'),
            dataIndex: 'feeUuid',
            align: 'center',
            render: (text) => (
                accessControl.hasPermission(PN.FEES.READ_BY_ID)
                    ? (
                        <StandardLink
                            title={text}
                            path={createFeeDetailsPath(text)}
                        />
                    ) : <StandardValue value={text} />
            ),
        },
        {
            title: t('fees:feeFields.feeName'),
            dataIndex: 'appliedFeeConfig.appliedFeeName',
            align: 'center',
            render: (text, record) => <StandardValue value={record?.appliedFeeConfig.appliedFeeName} />,
        },
        {
            title: 'Fee fixed amount',
            dataIndex: 'appliedFeeConfig.appliedFeeFixedAmount',
            align: 'right',
            render: (amount, record) => <StandardValue value={record?.appliedFeeConfig?.appliedFeeFixedAmount} valueType={ValueTypes.AMOUNT} />,
        },
        {
            title: 'Fee percentage amount',
            dataIndex: 'appliedFeeConfig.appliedFeePercentageAmount',
            align: 'right',
            render: (amount, record) => <StandardValue value={record?.appliedFeeConfig?.appliedFeePercentageAmount} valueType={ValueTypes.PERCENTAGE} />,
        },
        {
            title: 'Fee minimum amount',
            dataIndex: 'appliedFeeConfig.appliedMinimumFeeAmount',
            align: 'right',
            render: (amount, record) => <StandardValue value={record?.appliedFeeConfig?.appliedMinimumFeeAmount} valueType={ValueTypes.AMOUNT} />,
        },
        {
            title: t('transactionFields.debitedAmount.text'),
            dataIndex: 'debitedAmount',
            align: 'right',
            render: (amount) => <StandardValue value={amount} valueType={ValueTypes.AMOUNT} />,
        },
        {
            title: 'Debited currency',
            dataIndex: 'debitedCurrency',
            render: (text) => <FlagAndValue currencyCode={text} text={text} showTooltip />,
        },

        {
            title: t('transactionFields.creditedAmount.text'),
            dataIndex: 'creditedAmount',
            align: 'right',
            render: (amount) => <StandardValue value={amount} valueType={ValueTypes.AMOUNT} />,
        },
        {
            title: 'Credited currency',
            dataIndex: 'creditedCurrency',
            render: (text) => <FlagAndValue currencyCode={text} text={text} showTooltip />,
        },
        {
            title: t('common:table.columnNames.actions'),
            dataIndex: '',
            align: 'center',
            render: (_, record) => (
                <Space>
                    {accessControl.hasPermission(PN.TRANSACTIONS.READ_BY_ID)
              && record?.debitTransactionUuid
                        ? viewButtonRenderer({
                            record,
                            onClick: (event, record) => dispatchRequestNavigation(createTransactionDetailsPath(record?.debitTransactionUuid)),
                            tooltipContent: t('View fee transaction'),
                            dataTestId: 'view-debit-transaction-details',
                        }) : undefined}
                </Space>
            ),
        },
    ];
}


export default createAppliedFeesListTableColumns;
