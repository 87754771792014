import { StandardTagConfigItem } from 'models/app/standardTag';

import { TransactionType } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';


const mapTransactionTypeToColorAndLabel = (
    text: TransactionType,
    t: TFunction,
): StandardTagConfigItem => {
    const handlers = [
        {
            predicate: () => text === TransactionType.EXT,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionTypes.ext.text'),
            }),
        },
        {
            predicate: () => text === TransactionType.CT,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionTypes.ct.text'),
            }),
        },
        {
            predicate: () => text === TransactionType.INT,
            handler: () => ({
                color: 'green',
                label: t('transactions:transactionTypes.int.text'),
            }),
        },
        {
            predicate: () => text === TransactionType.FEE,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionTypes.fee.text'),
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionTypes.unknown'),
            }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default mapTransactionTypeToColorAndLabel;
