import InfoTooltip from 'components/common/atoms/InfoTooltip';
import TransactionLedgerStatusTag from 'components/common/atoms/tags/TransactionLedgerStatusTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { SortDirections } from 'constants/ApplicationStateModel';

import { TransactionStatusHistoryListItem } from '@manigo/manigo-domain-typings';
import { Space } from 'antd';
import React from 'react';


function createTransactionStatusColumns({ t }) {
    return [
        {
            title: t('transactions:transactionStatusFields.updatedAt.text'),
            dataIndex: 'updatedAt',
            defaultSortOrder: SortDirections.ASCEND,
            render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
        },
        {
            title: (
                <Space size={0} align={'center'}>
                    {t('transactions:transactionStatusFields.status.text')}
                    <InfoTooltip tooltipContent={t('transactions:transactionStatusFields.status.tooltip')} />
                </Space>
            ),
            dataIndex: 'status',
            align: 'center',
            render: (transactionStatus) => <TransactionLedgerStatusTag transactionLedgerStatus={transactionStatus} showTooltip />,
        },
        {
            title: t('transactions:transactionStatusFields.balanceChange.text'),
            align: 'right',
            render: (text, record) => (
                <Space size={0}>
                    {record.balanceBeforeTransaction < record.balanceAfterTransaction ? '+' : ''}
                    <StandardValue value={-(record.balanceBeforeTransaction - record.balanceAfterTransaction)} valueType={ValueTypes.AMOUNT} />
                    {` ${record.processedCurrency}`}
                </Space>
            ),
        },
        {
            title: t('transactions:transactionStatusFields.transactionFlags.text'),
            dataIndex: 'newFlags',
            align: 'right',
            render: (text, record: TransactionStatusHistoryListItem) => (<StandardValue value={[...record.newFlags].join('; ')} />),
        },
        {
            title: (
                <Space size={0} align={'center'}>
                    {t('transactions:transactionStatusFields.comments.text')}
                    <InfoTooltip tooltipContent={t('transactions:transactionStatusFields.comments.tooltip')} />
                </Space>
            ),
            align: 'right',
            render: (text, record: TransactionStatusHistoryListItem) => (
                <StandardValue value={[...new Set([
                    ...record.internalComments,
                    ...record.declineReasons,
                    ...(record.reason ? [record.reason] : []),
                    ...(record.description ? [record.description] : []),
                ].filter((val) => val !== ''))].join('; ')}
                />
            ),
        },
    ];
}

export default createTransactionStatusColumns;
