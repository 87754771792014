// XXX - legacy - only for promotions
export const TransactionType = Object.freeze({
    WITHDRAW: 'WITHDRAW', // -> ATM_WITHDRAWAL
    WITHDRAW_INTERNATIONAL: 'WITHDRAW_INTERNATIONAL', // sme as above => ATM_WITHDRAWAL
    POS: 'POS', // POS_ONLINE
    POS_INTERNATIONAL: 'POS_INTERNATIONAL', // same as above -> POS_ONLINE
    TRANSACTION_EXCHANGE: 'TRANSACTION_EXCHANGE', // ???
    EXCHANGE: 'EXCHANGE', // -> EXCHANGE_TO
    TOP_UP: 'TOP_UP', // -> CT (+ EXT)
    TOP_UP_INTERNATIONAL: 'TOP_UP_INTERNATIONAL', // -> CT (+ EXT)
    ADMIN_TOP_UP: 'ADMIN_TOP_UP',
    P2P: 'P2P',
    LOCAL_TRANSFER: 'LOCAL_TRANSFER',
    SWIFT_TRANSFER: 'SWIFT_TRANSFER',
    APM: 'APM',
    FUNDING: 'FUNDING',
});

export const optionalTransactionsScopeFilters = ['TOPUP_CARD_VERIFICATION', 'TOPUP_CARD_VERIFICATION_REFUND'];

export const AvailableReplayTransactionTypes = Object.freeze({ CARD_AUTH: 'card_auth' });


export default {
    TransactionType,
    optionalTransactionsScopeFilters,
    AvailableReplayTransactionTypes,
};
