import styled from 'styled-components';


export const TransactionRequestWrapper = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

    @media (max-width: 768px) {
        grid-template-columns: minmax(0, 1fr);
    }
`;

export default { TransactionRequestWrapper };
