import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { clearPromotionDetails, fetchPromotionDetails } from 'redux/promotion/actions';
import { promotionGroupsListPerLocationStoreKeyName, promotionReducerName } from 'redux/promotion/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';


import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import PromotionGroupsList from './PromotionGroupsList.layout';


const mapStateToProps = (state, ownProps) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: promotionReducerName,
        listsByLocationStoreKeyName: promotionGroupsListPerLocationStoreKeyName,
        customLocation: ownProps.locationPathname,
    }),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),

    dispatchFetchPromotionDetails: (queryParams) => dispatch(fetchPromotionDetails(queryParams, ownProps.locationPathname)),
    dispatchClearPromotionDetails: () => dispatch(clearPromotionDetails(ownProps.locationPathname)),

    dispatchSetQueryParams: (queryParams) => dispatch(setQueryParams({
        reducerName: promotionReducerName,
        fieldName: promotionGroupsListPerLocationStoreKeyName,
        locationPathname: ownProps.locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('groups'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(PromotionGroupsList);
