import { showSuccessToast } from 'redux/application/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    initExchangeSuccess, initExchangeFailure,
    confirmExchange, confirmExchangeSuccess, confirmExchangeFailure,
    rejectExchange, rejectExchangeSuccess, rejectExchangeFailure,
    fetchExchangeRateSuccess, fetchExchangeRateFailure,
} from './actions';


export const onInitExchange = (action$, state$, { exchange }) => action$.pipe(
    ofType('exchange/initExchange'),
    switchMap(({ payload }) => from(exchange.init(payload)).pipe(
        switchMap((response) => of(
            initExchangeSuccess(response.data.ext_id),
            confirmExchange(response.data.ext_id, { sender_client_id: payload.sender_client_id, sender_member: payload.sender_member }),
        )),
        catchError(() => of(initExchangeFailure())),
    )),
);


export const onConfirmExchange = (action$, state$, { exchange }) => action$.pipe(
    ofType('exchange/confirmExchange'),
    switchMap(({ payload }) => from(exchange.confirm(payload.exchangeUuid)).pipe(
        switchMap((response) => of(
            confirmExchangeSuccess(response.data),
            showSuccessToast('Your conversion has been processed successfully'),
        )),
        catchError(() => of(
            confirmExchangeFailure(),
            rejectExchange(payload.exchangeUuid),
        )),
    )),
);

export const onRejectExchange = (action$, state$, { exchange }) => action$.pipe(
    ofType('exchange/rejectExchange'),
    switchMap(({ payload }) => from(exchange.reject(payload)).pipe(
        switchMap((response) => of(rejectExchangeSuccess(response.data))),
        catchError(() => of(rejectExchangeFailure())),
    )),
);

export const onFetchExchangeRate = (action$, state$, { exchange }) => action$.pipe(
    ofType('exchange/fetchExchangeRate'),
    switchMap(({ payload }) => from(exchange.fetchRate(payload.from, payload.to)).pipe(
        switchMap((response) => of(fetchExchangeRateSuccess(response.data))),
        catchError(() => of(fetchExchangeRateFailure())),
    )),
);


export default [
    onInitExchange,
    onConfirmExchange,
    onRejectExchange,
    onFetchExchangeRate,
];
