import PropTypes from 'prop-types';
import React from 'react';

import Styled from './TabSubHeading.styled';


function TabSubHeading({ text, optionalContent }) {
    return (
        <Styled.TabSubHeading>
            <Styled.TabSubHeadingText>{text}</Styled.TabSubHeadingText>
            <Styled.TabSubHeadingOptionalContent>{optionalContent}</Styled.TabSubHeadingOptionalContent>
        </Styled.TabSubHeading>
    );
}

TabSubHeading.propTypes = {
    text: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    optionalContent: PropTypes.any,
};

export default TabSubHeading;
