import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { supportedCurrenciesListsPerLocationStoreKeyName, supportedCurrenciesReducerName } from 'redux/currencies-supported/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import BasicDataStep from './BasicData.layout';


const mapStateToProps = (state) => ({
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    supportedCurrencies: getStandardListData({
        state,
        reducerName: supportedCurrenciesReducerName,
        listsByLocationStoreKeyName: supportedCurrenciesListsPerLocationStoreKeyName,
    }),

    formData: state[beneficiaryAddReducerName].formData,
});


const decorate = compose(
    withTranslation('beneficiaries'),
    connect(mapStateToProps),
);

export default decorate(BasicDataStep);
