function convertSnakeCaseKeysToCamelCase(obj: any, ignoreKeys: string[] = []): any {
    if (Array.isArray(obj)) {
        return obj.map((item) => convertSnakeCaseKeysToCamelCase(item, ignoreKeys));
    } if (obj !== null && obj?.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => {
            if (ignoreKeys.includes(key)) {
                result[key] = obj[key];
                return result;
            }
            const camelCaseKey = key.replace(/_([a-zA-Z0-9])/g, (match, group1) => group1.toUpperCase());
            result[camelCaseKey] = convertSnakeCaseKeysToCamelCase(obj[key], ignoreKeys);
            return result;
        }, {});
    }
    return obj;
}

export default convertSnakeCaseKeysToCamelCase;
