import {
    CLEAR_ACCOUNT_DETAILS,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_FAILURE,
    DELETE_ACCOUNT_SUCCESS, DOWNLOAD_ACCOUNT_STATEMENT_PDF, DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE, DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS,
    FETCH_ACCOUNT_DETAILS,
    FETCH_ACCOUNT_DETAILS_FAILURE,
    FETCH_ACCOUNT_DETAILS_SUCCESS, HIDE_DOWNLOAD_PDF_STATEMENT_FORM,
    BALANCE_ADJUSTMENT, BALANCE_ADJUSTMENT_FAILURE, BALANCE_ADJUSTMENT_SUCCESS,
    SET_AS_PRIMARY_ACCOUNT, SET_AS_PRIMARY_ACCOUNT_FAILURE, SET_AS_PRIMARY_ACCOUNT_SUCCESS, SHOW_DOWNLOAD_PDF_STATEMENT_FORM,
    WITHDRAW_BALANCE,
    WITHDRAW_BALANCE_FAILURE,
    WITHDRAW_BALANCE_SUCCESS,
} from 'redux/account/action.types';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const accountReducerName = 'account';
export const accountDetailsPerLocationStoreKeyName = 'accountDetailsPerLocation';


const initialState = {
    [accountDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }

    isLoadingWithdrawBalance: false,
    isLoadingBalanceAdjustment: false,
    isLoadingDeleteAccount: false,
    isLoadingSetAsPrimaryAccount: false,
    downloadPdfStatement: { },
};

const handlers = {
    [FETCH_ACCOUNT_DETAILS]: (state, action) => {
        state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[accountDetailsPerLocationStoreKeyName], action);
    },
    [FETCH_ACCOUNT_DETAILS_SUCCESS]: (state, action) => {
        state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[accountDetailsPerLocationStoreKeyName], action);
    },
    [FETCH_ACCOUNT_DETAILS_FAILURE]: (state, action) => {
        state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[accountDetailsPerLocationStoreKeyName], action);
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === accountReducerName
      && action.payload?.fieldName === accountDetailsPerLocationStoreKeyName
        ) {
            state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[accountDetailsPerLocationStoreKeyName] }, action);
        }
    },

    [CLEAR_ACCOUNT_DETAILS]: (state, action) => {
        state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[accountDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },

    [DELETE_ACCOUNT]: (state) => {
        state.isLoadingDeleteAccount = true;
    },
    [DELETE_ACCOUNT_SUCCESS]: (state) => {
        state.isLoadingDeleteAccount = false;
        state.accountDetails = {};
    },
    [DELETE_ACCOUNT_FAILURE]: (state) => {
        state.isLoadingDeleteAccount = false;
    },


    [WITHDRAW_BALANCE]: (state) => {
        state.isLoadingWithdrawBalance = true;
    },
    [WITHDRAW_BALANCE_SUCCESS]: (state) => {
        state.isLoadingWithdrawBalance = false;
    },
    [WITHDRAW_BALANCE_FAILURE]: (state) => {
        state.isLoadingWithdrawBalance = false;
    },

    [BALANCE_ADJUSTMENT]: (state) => {
        state.isLoadingBalanceAdjustment = true;
    },
    [BALANCE_ADJUSTMENT_SUCCESS]: (state) => {
        state.isLoadingBalanceAdjustment = false;
    },
    [BALANCE_ADJUSTMENT_FAILURE]: (state) => {
        state.isLoadingBalanceAdjustment = false;
    },


    [SHOW_DOWNLOAD_PDF_STATEMENT_FORM]: (state, action) => {
        const updatedAccountStatements = Object.keys(state.downloadPdfStatement).reduce((acc, accountId) => {
            acc[accountId] = {
                ...state.downloadPdfStatement[accountId],
                isFormVisible: false,
            };
            return acc;
        }, {});

        const accountId = action.payload;
        state.downloadPdfStatement = {
            ...updatedAccountStatements,
            [accountId]: {
                ...state.downloadPdfStatement[accountId],
                isFormVisible: true,
                isDownloading: false,
            },
        };
    },

    [HIDE_DOWNLOAD_PDF_STATEMENT_FORM]: (state, action) => {
        const accountId = action.payload;
        state.downloadPdfStatement = {
            ...state.downloadPdfStatement,
            [accountId]: {
                ...state.downloadPdfStatement[accountId],
                isFormVisible: false,
            },
        };
    },

    [DOWNLOAD_ACCOUNT_STATEMENT_PDF]: (state, action) => {
        state.downloadPdfStatement = {
            ...state.downloadPdfStatement,
            [action.payload.accountId]: {
                ...state.downloadPdfStatement[action.payload.accountId],
                isDownloading: true,
            },
        };
    },

    [DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS]: (state, action) => {
        state.downloadPdfStatement = {
            ...state.downloadPdfStatement,
            [action.payload]: {
                ...state.downloadPdfStatement[action.payload],
                isDownloading: false,
            },
        };
    },
    [DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE]: (state, action) => {
        state.downloadPdfStatement = {
            ...state.downloadPdfStatement,
            [action.payload]: {
                ...state.downloadPdfStatement[action.payload],
                isDownloading: false,
            },
        };
    },

    [SET_AS_PRIMARY_ACCOUNT]: (state) => {
        state.isLoadingSetAsPrimaryAccount = true;
    },
    [SET_AS_PRIMARY_ACCOUNT_SUCCESS]: (state) => {
        state.isLoadingSetAsPrimaryAccount = false;
    },
    [SET_AS_PRIMARY_ACCOUNT_FAILURE]: (state) => {
        state.isLoadingSetAsPrimaryAccount = false;
    },

    [CLEAR_CURRENT_USER]: () => initialState,
};


export default createReducer(initialState, handlers);
