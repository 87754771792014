import { globalLocation } from 'constants/NavigationModel';
import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearClientsList, fetchClientsList } from 'redux/clients/actions';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ClientsList from './ClientsList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchFetchClientsList: (queryParams) => dispatch(fetchClientsList(queryParams)),
    dispatchClearClientsList: () => dispatch(clearClientsList()),
    dispatchSetQueryParams: (queryParams) => dispatch(setQueryParams({
        reducerName: clientsReducerName,
        fieldName: clientsListsPerLocationStoreKeyName,
        locationPathname: globalLocation,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('clients'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ClientsList);
