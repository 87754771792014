import { HttpService } from 'services/http';


export const fetchFeeUuidDetails = (feeUuid) => HttpService.get(`/v2/fees/${feeUuid}`);

export const fetchFeeUuidTiers = (feeUuid) => HttpService.get(`/v3/fees/${feeUuid}/fee-tiers`);

export const fetchFeeTierDetails = (feUuid) => HttpService.get(`/v2/fees/tiers/${feUuid}`);

export const fetchClientIdFees = (clientId) => HttpService.get(`/v2/clients/${clientId}/fees`);

export const fetchGroupIdFees = (groupId) => HttpService.get(`/v2/groups/${groupId}/fees`);

// XXX new one that should replace 2 above but ID vs UUID  issues prevent usage ATM
export const fetchFeesAPI = (queryParams) => HttpService.get('/v3/fees/', queryParams);

export const fetchManualFeesAPI = (queryParams) => HttpService.get('/v3/fees/manual', queryParams);

export const fetchFeeUuidGroups = (feeUuid) => HttpService.get(`/v2/fees/${feeUuid}/groups`);

export const fetchFeeUuidPromotions = (feeUuid) => HttpService.get(`/v2/fees/${feeUuid}/promotions`);

export const createCustomFeeAPI = (rawQueryParams) => HttpService.post('/v4/fees/custom', rawQueryParams);

export const triggerManualFeeAPI = ({ feeUuid, ...payload }) => HttpService.post(`/v4/fees/${feeUuid}/trigger`, payload);

export default {
    fetchFeeUuidDetails,

    fetchClientIdFees,
    fetchGroupIdFees,
    fetchFeesAPI,

    fetchFeeUuidTiers,

    fetchFeeUuidGroups,
    fetchFeeUuidPromotions,

    createCustomFeeAPI,
    triggerManualFeeAPI,
};
