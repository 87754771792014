import { APPLICATION_STATE, defaultClipboardContent } from 'constants/ApplicationStateModel';
import {
    applicationGlobalDataFetchingAuthorisedUser,
    applicationInitGlobalDataFetchingAuthorisedUser,
    applicationReadyAuthorisedUser,
    applicationReadyUnauthorisedUser,
    clearReadOnlyMode,
    fetchGlobalData,
    fetchGlobalDataFailure,
    hideAutoLogoutBanner,
    initApplication,
    initI18nextSuccess,
    setClipboardContent,
    setReadOnlyMode,
    setSessionUuid,
    showAutoLogoutBanner,
    toggleSidebar,
} from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';


export const applicationUIReducerName = 'application';


const defaultReadOnlyInfo = {
    entityType: undefined,
    entityId: undefined,
    entityStatus: undefined,
    readOnlyStateReason: undefined,
    otherDetails: { }, // eg parentEntityName, entityName
};


const currentUserDependantDefaultState = {
    isAutoLogoutBannerVisible: false,
    clipboardContent: defaultClipboardContent,
    isSidebarCollapsed: false,
    readOnlyInfo: defaultReadOnlyInfo,
};

const initialState = {
    sessionUuid: undefined,
    isI18nReady: false,
    applicationState: undefined,
    shouldFetchGlobalData: false,

    ...currentUserDependantDefaultState,
};

const handlers = {
    [initApplication]: (state) => {
        state.applicationState = APPLICATION_STATE.APPLICATION_STARTED;
    },
    [applicationReadyAuthorisedUser]: (state) => {
        state.applicationState = APPLICATION_STATE.AUTHORISED_READY;
    },
    [applicationInitGlobalDataFetchingAuthorisedUser]: (state) => {
        state.applicationState = APPLICATION_STATE.INIT_AUTHORISED_GLOBAL_DATA_FETCHING;
    },
    [applicationGlobalDataFetchingAuthorisedUser]: (state) => {
        state.applicationState = APPLICATION_STATE.AUTHORISED_GLOBAL_DATA_FETCHING;
    },
    [applicationReadyUnauthorisedUser]: (state) => {
        state.applicationState = APPLICATION_STATE.UNAUTHORISED_READY;
    },
    [setSessionUuid]: (state, action) => {
        state.sessionUuid = action.payload;
    },
    [initI18nextSuccess]: (state) => {
        state.isI18nReady = true;
    },
    [showAutoLogoutBanner]: (state) => {
        state.isAutoLogoutBannerVisible = true;
    },
    [hideAutoLogoutBanner]: (state) => {
        state.isAutoLogoutBannerVisible = false;
    },
    [toggleSidebar]: (state) => {
        state.isSidebarCollapsed = !state.isSidebarCollapsed;
    },
    [setClipboardContent]: (state, action) => {
        state.clipboardContent = action.payload;
    },
    [clearCurrentUser]: (state) => ({
        ...state,
        ...currentUserDependantDefaultState,
        applicationState: APPLICATION_STATE.UNAUTHORISED_READY,
    }),

    [setReadOnlyMode]: (state, action) => {
        state.readOnlyInfo = action.payload;
    },
    [clearReadOnlyMode]: (state) => {
        state.readOnlyInfo = defaultReadOnlyInfo;
    },

    [fetchGlobalDataFailure]: (state) => {
        state.shouldFetchGlobalData = true;
    },
    [fetchGlobalData]: (state) => {
        state.shouldFetchGlobalData = false;
    },
};

export default createReducer(initialState, handlers);
