import StandardTooltip from 'components/common/molecules/StandardTooltip';

import PropTypes from 'prop-types';
import React from 'react';


import Styled from './UserRoleRenderer.styled';


function UserRoleRenderer({ roleObject = {} }) {
    return (
        <StandardTooltip title={roleObject?.description}>
            <Styled.RoleWrapper>
                <Styled.RoleName>
                    {roleObject?.display_name}
                </Styled.RoleName>
                <Styled.InfoIcon />
            </Styled.RoleWrapper>
        </StandardTooltip>
    );
}

UserRoleRenderer.propTypes = {
    roleObject: PropTypes.shape({
        display_name: PropTypes.string,
        description: PropTypes.string,
    }),
};

export default UserRoleRenderer;
