import withAccessControl from 'hocs/withAccessControl';
import {
    activateMember,
    banMember,
    externalKycKybUpdateMemberStatus,
    suspendMember,
    unbanMember,
    unsuspendMember,
} from 'redux/member-status/actions';
import { memberStatusReducerName } from 'redux/member-status/reducer';
import { showModal } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';

import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UpdateMemberStatus from './UpdateMemberStatus.layout';
import {
    UpdateMemberStatusDispatchProps,
    UpdateMemberStatusOwnProps,
    UpdateMemberStatusStateProps,
} from './UpdateMemberStatus.types';


const mapStateToProps = (state: RootState): UpdateMemberStatusStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].enhancedCurrentLocation,
    shouldResetForm: state[memberStatusReducerName].shouldResetForm,
    isLoadingUpdateStatus: state[memberStatusReducerName].isLoadingUpdateStatus,
    //
});

const mapDispatchToProps = (dispatch: Dispatch): UpdateMemberStatusDispatchProps => ({
    dispatchSuspendMember: (payload) => dispatch(suspendMember(payload)),
    dispatchUnsuspendMember: (payload) => dispatch(unsuspendMember(payload)),
    dispatchActivateMember: (payload) => dispatch(activateMember(payload)),
    dispatchBanMember: (payload) => dispatch(banMember(payload)),
    dispatchUnbanMember: (payload) => dispatch(unbanMember(payload)),
    dispatchExternalKycKybUpdateMemberStatus: (payload) => dispatch(externalKycKybUpdateMemberStatus(payload)),

    dispatchShowModal: (data) => dispatch(showModal(data)),
});

const decorate = compose<ComponentType<UpdateMemberStatusOwnProps>>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('member'),
    withAccessControl,
);
export default decorate(UpdateMemberStatus);
