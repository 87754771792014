import styled from 'styled-components';


const InputNumberNoDefaultsWrap = styled.div`
  .ant-input-number {
    width: 100%;
  }
  
  .ant-input-number-handler-wrap {
    display: none;
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    margin: 0;
  }

  /* Firefox */
  input {
    -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
  } 
`;


export default { InputNumberNoDefaultsWrap };
