import FlagAndValue from 'components/common/atoms/FlagAndValue';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { FeeType } from 'constants/FeeModel';
import { sortBooleansByKey, sortingOptions, sortNumbers, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import PropTypes from 'prop-types';
import React from 'react';


function createFeeTiersTableColumns({
    t,
    queryParams,
    feeType,
}) {
    return [
        {
            title: t('fees:feeTierFields.uuid'),
            dataIndex: 'ext_id',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'ext_id',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'ext_id'),
            }),
        },
        {
            title: t('fees:feeTierFields.extFeeUuid'),
            dataIndex: 'ext_fee_id',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'ext_fee_id',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'ext_fee_id'),
            }),
        },
        ...(feeType === FeeType.TRANSACTION_FEE ? [
            {
                title: t('fees:feeTierFields.entityType'),
                dataIndex: 'entity_type',
                render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
                ...sortingOptions({
                    queryParams,
                    fieldName: 'entity_type',
                    sorter: (a, b) => sortBooleansByKey(a, b, 'entity_type'),
                }),
            },

            {
                title: t('fees:feeTierFields.conditionType'),
                dataIndex: 'condition_type',
                render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
                ...sortingOptions({
                    queryParams,
                    fieldName: 'condition_type',
                    sorter: (a, b) => sortStringsAlphabetically(a, b, 'condition_type'),
                }),
            },
            {
                title: t('fees:feeTierFields.conditionOccurrence'),
                dataIndex: 'condition_occurrence',
                render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
                ...sortingOptions({
                    queryParams,
                    fieldName: 'condition_occurrence',
                    sorter: (a, b) => sortStringsAlphabetically(a, b, 'condition_occurrence'),
                }),
            },
            {
                title: t('fees:feeTierFields.conditionTriggerValue'),
                dataIndex: 'condition_trigger_value',
                align: 'right',
                render: (text) => <StandardValue value={text} valueType={Number(text) ? 'AMOUNT' : 'TEXT'} />,
                ...sortingOptions({
                    queryParams,
                    fieldName: 'condition_trigger_value',
                    sorter: (a, b) => sortNumbers(a, b, 'condition_trigger_value'),
                }),
            },
        ] : []),

        {
            title: t('fees:feeTierFields.feeAmountFixed'),
            dataIndex: 'fee_amount_fixed',
            align: 'right',
            render: (value) => <StandardValue value={value} valueType="AMOUNT" />,
            ...sortingOptions({
                queryParams,
                fieldName: 'fee_amount_fixed',
                sorter: (a, b) => sortNumbers(a, b, 'fee_amount_fixed'),
            }),
        },

        ...(feeType === FeeType.TRANSACTION_FEE ? [
            {
                title: t('fees:feeTierFields.feeAmountMinimum'),
                dataIndex: 'fee_amount_minimum',
                align: 'right',
                render: (value) => <StandardValue value={value} valueType="AMOUNT" />,
                ...sortingOptions({
                    queryParams,
                    fieldName: 'fee_amount_minimum',
                    sorter: (a, b) => sortNumbers(a, b, 'fee_amount_minimum'),
                }),
            },
            {
                title: t('fees:feeTierFields.feeAmountPercentage'),
                dataIndex: 'fee_amount_percentage',
                align: 'right',
                render: (value) => <StandardValue value={value} valueType="AMOUNT" />,
                ...sortingOptions({
                    queryParams,
                    fieldName: 'fee_amount_percentage',
                    sorter: (a, b) => sortNumbers(a, b, 'fee_amount_percentage'),
                }),
            },
        ] : []),

        {
            title: t('fees:feeTierFields.feeCurrency'),
            dataIndex: 'fee_currency',
            render: (currencyCode) => <FlagAndValue currencyCode={currencyCode} text={currencyCode} showTooltip />,
            ...sortingOptions({
                queryParams,
                fieldName: 'fee_currency',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'fee_currency'),
            }),
        },

    ];
}


createFeeTiersTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    searchQuery: PropTypes.string,
};


export default createFeeTiersTableColumns;
