import CommonSection from 'components/common/organisms/CommonSection';

import { TransactionDetails } from '@manigo/manigo-domain-typings';
import { Row } from 'antd';
import { TFunction } from 'i18next';
import React, { useMemo, JSX } from 'react';


import { createTransactionDescriptionFields } from './TransactionDescription.helpers';


function TransactionDescription({
    transactionDetails,
    t,
}: { transactionDetails: TransactionDetails, t: TFunction }): JSX.Element {
    const transactionDescriptionFields = useMemo(
        () => createTransactionDescriptionFields(transactionDetails, t),
        [transactionDetails],
    );


    return (
        <div>
            <Row gutter={24}>
                <CommonSection
                    sectionLabel={t('')}
                    fieldsConfig={transactionDescriptionFields}
                />
            </Row>
        </div>
    );
}

export default TransactionDescription;
