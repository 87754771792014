import UserRoleRenderer from 'components/common/atoms/tags/UserRoleRenderer';
import UserStatusTag from 'components/common/atoms/tags/UserStatusTag';
import ClientRenderer from 'components/common/molecules/value-renderers/ClientRenderer';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createUserDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { sortingOptions } from 'utils/table-columns-sorters';

import React from 'react';


function createUsersColumns({
    accessControl,
    queryParams,
    dispatchRequestNavigation,
    t,
}) {
    const canViewUserDetails = accessControl.hasPermission(PN.USERS.READ_BY_ID);


    const partnersColumnConfig = [{
        title: t('userFields.partner.label'),
        dataIndex: 'client_id',
        render: (_, record) => <ClientRenderer clientId={record.client_id} />,
        ...sortingOptions({ queryParams, fieldName: 'client_id' }),
    }];

    const actionsColumn = [{
        title: t('userFields.actions.columnName'),
        align: 'center',
        render: (_, record) => viewButtonRenderer({
            record,
            onClick: (event, record) => dispatchRequestNavigation(createUserDetailsPath(record.id)),
            tooltipContent: t('userFields.actions.viewDetails'),
            dataTestIdSuffix: 'user',
        }),
    }];

    const baseColumns = [
        {
            title: t('userFields.id.label'),
            dataIndex: 'id',
            align: 'right',
            defaultSortOrder: 'ascend',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({ queryParams, fieldName: 'id' }),
        },
        {
            title: t('userFields.firstName.label'),
            dataIndex: 'first_name',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({ queryParams, fieldName: 'first_name' }),
        },
        {
            title: t('userFields.lastName.label'),
            dataIndex: 'last_name',
            render: (text) => <StandardValue value={text} />,
            ...sortingOptions({ queryParams, fieldName: 'last_name' }),
        },
        {
            title: t('userFields.email.label'),
            dataIndex: 'email',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'email' }),
        },
        {
            title: t('userFields.username.label'),
            dataIndex: 'username',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'username' }),
        },
        {
            title: t('userFields.country.label'),
            dataIndex: 'country_id',
            render: (text) => <CountryRenderer propertyName="id" value={text} showTooltip />,
            ...sortingOptions({ queryParams, fieldName: 'country_id' }),
        },
        {
            title: t('userFields.status.label'),
            dataIndex: 'status',
            align: 'center',
            render: (status) => <UserStatusTag userStatus={status} t={t} showTooltip />,
            ...sortingOptions({ queryParams, fieldName: 'status' }),
        },
        {
            title: t('userFields.role.label'),
            dataIndex: 'role_id',
            align: 'center',
            render: (text, { role }) => <UserRoleRenderer roleObject={role} />,
            ...sortingOptions({ queryParams, fieldName: 'role_id' }),
        },
        {
            title: t('userFields.isPermanentlyLocked.label'),
            dataIndex: 'is_permanently_locked',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(
                record,
                'is_permanently_locked',
                t,
                undefined,
                t('userFields.isPermanentlyLocked.noTooltip'),
                true,
            ),
            ...sortingOptions({ queryParams, fieldName: 'is_permanently_locked' }),
        },

        {
            title: t('userFields.lastLogin.label'),
            dataIndex: 'last_login',
            render: (date) => <StandardValue value={date} valueType="DATE" />,
            ...sortingOptions({ queryParams, fieldName: 'last_login' }),
        },
    ];


    return [
        ...baseColumns,
        ...(accessControl.isSuperAdmin ? partnersColumnConfig : []), // XXX allowed - client filter on global users list
        ...(canViewUserDetails ? actionsColumn : []),
    ];
}


export default createUsersColumns;

