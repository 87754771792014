import { setQueryParams } from 'redux/application/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';
import { clearTopupList, fetchTopupList } from 'redux/topup/actions';
import { topupListsPerLocationStoreKeyName, topupReducerName } from 'redux/topup/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TopupCardsList from './TopupCardsList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: topupReducerName,
        listsByLocationStoreKeyName: topupListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch) => ({
    dispatchFetchTopupList: (queryParams, locationPathname) => dispatch(fetchTopupList(queryParams, locationPathname)),
    dispatchClearTopupList: (queryParams, locationPathname) => dispatch(clearTopupList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: topupReducerName,
        fieldName: topupListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(TopupCardsList);
