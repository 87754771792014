import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { showModal } from 'redux/modal/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { refundTopUp } from 'redux/transaction/actions';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import TransactionInfo from './TransactionInfo.layout';
import { TransactionInfoDispatchProps, TransactionInfoOwnProps } from './TransactionInfo.types';


const mapDispatchToProps = (dispatch: Dispatch): TransactionInfoDispatchProps => ({
    dispatchRefundTopUp: (transactionUuid) => dispatch(refundTopUp(transactionUuid)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchShowModal: (data) => dispatch(showModal(data)),
});

const decorate = compose<ComponentType<TransactionInfoOwnProps>>(
    withTranslation('transactions'),
    withAccessControl,
    connect(null, mapDispatchToProps),
);

export default decorate(TransactionInfo);

