export const mapSecure3dActionLogToLabelAndColor = (logRecord, t) => {
    const handlers = [
        {
            predicate: () => logRecord.enrolled === true && logRecord.enrolled_at,
            handler: () => ({
                color: 'green',
                label: t('cards:cardDetails.tabs.3dSecure.table.filedValues.action.enrolled.text'),
            }),
        },
        {
            predicate: () => logRecord.enrolled === false && logRecord.unenrolled_at,
            handler: () => ({
                color: 'red',
                label: t('cards:cardDetails.tabs.3dSecure.table.filedValues.action.disenrolled.text'),
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                color: 'transparent',
                label: 'Unknown',
            }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
