/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { finaliseClosureAPI, startOffboardingAPI, stopOffboardingAPI } from './index';


class OffboardingService {
    public startOffboarding(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { memberId, companyId } = rawRequestPayload;
        const requestPayload = {
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),

        };
        return startOffboardingAPI(requestPayload);
    }


    public stopOffboarding(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { memberId, companyId } = rawRequestPayload;
        const requestPayload = {
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),

        };
        return stopOffboardingAPI(requestPayload);
    }

    public finaliseClosure(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { memberId, companyId } = rawRequestPayload;
        const requestPayload = {
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),

        };
        return finaliseClosureAPI(requestPayload);
    }
}

export default OffboardingService;
