import { CompanyStatuses } from 'constants/CompaniesModel';
import { getAllCompanies } from 'services/companies';
import { getMembersApi } from 'services/members';

import { NumericId, MemberListItem, ReadOnlyMemberStatus } from '@manigo/manigo-domain-typings';


export const EXCHANGE_RATE_DECIMALS = 2;

export const listMembers = async ({ clientId, search }: { clientId: NumericId, search: string }) => {
    const requestData = {
        params: {
            clientId,
            ...(search?.length > 0 ? { search } : {}),
            order: ['-member_id'],
            limit: 100,
            offset: 0,
        },
    };

    if (search) requestData.params.search = search;

    const response: { data: MemberListItem[] } = await getMembersApi(requestData);
    return (response?.data || []).reduce((acc: {
    label: string;
    value: MemberListItem['memberId'];
  }[],
    member) => {
        if (!Object.values(ReadOnlyMemberStatus).includes(member.memberStatus)) {
            return [
                ...acc,
                {
                    label: `${member.memberId} - ${member.memberFirstName} ${member.memberLastName}`,
                    value: member.memberId,
                },
            ];
        }
        return acc;
    }, []);
};

export const listCompanies = async ({ clientId, search }: { clientId: NumericId, search: string }) => {
    const requestData = {
        clientId,
        ...(search?.length > 0 ? { search } : {}),
        order: ['-company_id'],
        limit: 100,
        offset: 0,
    };

    const response = await getAllCompanies(requestData);

    return (response?.data || []).reduce((acc, company) => {
        if (company.status !== CompanyStatuses.PENDING_CLOSURE) {
            return [
                ...acc,
                {
                    label: `${company.company_id} - ${company.company_name}`,
                    value: company.company_id,
                },
            ];
        }
        return acc;
    }, []);
};


export default {
    EXCHANGE_RATE_DECIMALS,
    listMembers,
    listCompanies,
};
