/* eslint-disable spellcheck/spell-checker */

import OpenSansBoldLatinExt from 'assets/fonts/OpenSans/OpenSans-Bold-latin-ext.woff2';
import OpenSansBoldLatin from 'assets/fonts/OpenSans/OpenSans-Bold-latin.woff2';
import OpenSansRegularLatinExt from 'assets/fonts/OpenSans/OpenSans-Regular-latin-ext.woff2';
import OpenSansRegularLatin from 'assets/fonts/OpenSans/OpenSans-Regular-latin.woff2';
import OpenSansSemiBoldLatinExt from 'assets/fonts/OpenSans/OpenSans-SemiBold-latin-ext.woff2';
import OpenSansSemiBoldLatin from 'assets/fonts/OpenSans/OpenSans-SemiBold-latin.woff2';

import { createGlobalStyle } from 'styled-components';


export const GlobalFonts = createGlobalStyle`

/* Regular latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url(${OpenSansRegularLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Regular latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url(${OpenSansRegularLatinExt}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* SemiBold latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans SemiBold'),
    local('OpenSans-SemiBold'),
    url(${OpenSansSemiBoldLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* SemiBold latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Open Sans SemiBold'),
    local('OpenSans-SemiBold'),
    url(${OpenSansSemiBoldLatinExt}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Bold latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url(${OpenSansBoldLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}        

/* Bold latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url(${OpenSansBoldLatinExt}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
`;
export default GlobalFonts;
