import PropTypes from 'prop-types';


export const ApiKeyCommonProps = {
    id: PropTypes.number.isRequired,
    label: PropTypes.string,
    client_id: PropTypes.number.isRequired,
    role_id: PropTypes.number.isRequired,
};

export const ApiKeyProps = PropTypes.shape({ ...ApiKeyCommonProps });
export const ApiKeysListProp = PropTypes.arrayOf(ApiKeyProps);

export default {
    ApiKeyProps,
    ApiKeysListProp,
};
