import StandardTag from 'components/common/atoms/StandardTag';
import { AccountTypes } from 'constants/AccountModel';

import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';


const createHandlers = (accountType) => {
    const normalisedAccountType = capitalize(accountType);

    const handlers = [
        {
            predicate: () => normalisedAccountType === AccountTypes.Personal,
            handler: () => ({ color: 'green', text: accountType }),
        },
        {
            predicate: () => normalisedAccountType === AccountTypes.Business,
            handler: () => ({ color: 'geekblue', text: accountType }),
        },
        {
            predicate: () => normalisedAccountType === AccountTypes.Technical,
            handler: () => ({ color: 'cyan', text: accountType }),
        },
        {
            predicate: () => normalisedAccountType === AccountTypes.Base,
            handler: () => ({ color: 'orange', text: accountType }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: 'Unknown' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function AccountTypeTag({ accountType, showTooltip = false }) {
    const tagData = createHandlers(accountType);

    return <StandardTag color={tagData.color} text={tagData.text} showTooltip={showTooltip} />;
}

AccountTypeTag.propTypes = {
    accountType: PropTypes.oneOf(Object.keys(AccountTypes)),
    showTooltip: PropTypes.bool,
};

export default AccountTypeTag;

