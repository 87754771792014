import { isoDateFormat, standardDateFormat, standardTimeFormat, usTimeFormat } from 'config/config';
import { UserPreferencesTimeFormat } from 'constants/UserModel';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as utc from 'dayjs/plugin/utc';


dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export const timestampDiffInSeconds = (timestamp) => Math.floor((timestamp / 1000) % 60);

export const utcToLocalTime = (date) => dayjs.utc(date).local();

export const isoDateOnlyStringToDate = (date) => dayjs(date);

export const mapTimeFormat = (userPreference) => {
    if (userPreference === UserPreferencesTimeFormat.HOURS_12) {
        return usTimeFormat;
    }
    return standardTimeFormat;
};

export function formatDate({
    date,
    dateFormat = standardDateFormat,
    convertFromUTC = true,
}) {
    const noDate = !date || date.length === 0;
    const resultDate = convertFromUTC ? utcToLocalTime(date) : date;

    if (noDate) {
        return date;
    }
    return dayjs(resultDate).format(dateFormat);
}
export function removeYearFormDateFormat(dateFormat) {
    const separator = dateFormat.match(/\W/g);
    return dateFormat.replace(`YYYY${separator?.[0]}`, '').replace(`${separator?.[0]}YYYY`, '');
}

export function formatTime({
    date,
    timeFormat = standardTimeFormat,
    convertFromUTC = false,
}) {
    const noDate = !date || date.length === 0;
    const resultDate = convertFromUTC ? utcToLocalTime(date) : date;

    if (noDate) {
        return date;
    }
    return dayjs(resultDate).format(timeFormat);
}

export const getDateRangeForXDaysFromToday = (
    x = 30,
    dateFormat = isoDateFormat,
) => ({
    dateFrom: dayjs().subtract(x, 'days').format(dateFormat),
    dateTo: dayjs().format(dateFormat),
});

export const getDateRangeForPreviousMonth = () => ({
    timeRange: [
        dayjs().subtract(1, 'months').startOf('month'),
        dayjs().subtract(1, 'months').endOf('month'),
    ],
});

export const getRelativeTimeFromNowString = (date) => dayjs(date).fromNow();

export const preventDatesFormFuture = (currentDate) => currentDate && currentDate > dayjs().endOf('day'); // allow time to be grater than now() as long as it isn't next day


export default {
    timestampDiffInSeconds,
    utcToLocalTime,
    isoDateOnlyStringToDate,
    formatDate,
    mapTimeFormat,
    getRelativeTimeFromNowString,
    getDateRangeForXDaysFromToday,
    preventDatesFormFuture,
};
