/* eslint-disable class-methods-use-this */
import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';
import {
    ACTIVATE_MEMBER,
    BAN_MEMBER, EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS, FetchMemberStatusHistoryQueryParams,
    SUSPEND_MEMBER,
    UNBAN_MEMBER,
    UNSUSPEND_MEMBER,
} from 'redux/member-status/actions.types';
import { camelToSnakeCase } from 'utils/string-tools';

import { NumericId } from '@manigo/manigo-domain-typings';


import { FetchStandardListPayload } from '../../models/app/standardList';

import {
    getMembersApi,
    getRiskDetailsAPI,
    createRiskDetailsAPI,
    updateMemberId,
    getMemberIdDetails,
    createMemberAPI,
    getMemberStatusHistoryAPI,
    postBanMemberAPI,
    postUnsuspendMemberAPI,
    postSuspendMemberAPI,
    postUnbanMemberAPI,
    postActivateMemberAPI, updateMemberStatus,
} from './index';


class MembersService {
    public getMembers(enforcedClientId: NumericId, rawQuery:any = {}): Promise<ApiResponse | ApiError> {
        const { clientId, search, sortOrder, sortBy = 'first_name', includeInactivePartners, memberStatus, ...restOfQueryParams } = rawQuery;

        const query = {
            ...restOfQueryParams,
            ...(includeInactivePartners === true ? { includeInactivePartners } : {}),
            ...(search?.length > 0 ? { search } : {}),
            ...(enforcedClientId && enforcedClientId !== 0 ? { clientId: enforcedClientId } : {}),
            ...({ order: [`${sortOrder === SortDirections.DESCEND ? `-${camelToSnakeCase(sortBy)}` : `${camelToSnakeCase(sortBy)}`}`] }), // Backend expects snake_case for order
            ...(clientId && clientId !== 0 ? { clientId } : {}),
            ...(memberStatus?.length > 0 ? { status: memberStatus } : {}),
        };


        return getMembersApi({ params: query });
    }

    public getMemberDetails({ memberId }: { memberId: NumericId }): Promise<ApiResponse | ApiError> {
        return getMemberIdDetails(memberId, { convertSnakeCaseKeysToCamelCase: true });
    }

    public getMemberRiskDetails(memberId: NumericId): Promise<ApiResponse | ApiError> {
        return getRiskDetailsAPI(memberId);
    }

    public createMemberRiskDetail(memberId: NumericId, rawRequestPayload): Promise<ApiResponse | ApiError> {
        return createRiskDetailsAPI(memberId, rawRequestPayload);
    }

    public createMember(rawRequestPayload): Promise<ApiResponse | ApiError> {
        return createMemberAPI(rawRequestPayload);
    }

    public updateMember(memberId: NumericId, rawRequestPayload): Promise<ApiResponse | ApiError> {
        return updateMemberId(memberId, rawRequestPayload, { convertSnakeCaseKeysToCamelCase: true });
    }

    public getMemberStatusHistory(rawQueryParams): Promise<ApiResponse<FetchStandardListPayload<FetchMemberStatusHistoryQueryParams>> | ApiError> {
        const { memberId, sortOrder, ...rest } = rawQueryParams;
        return getMemberStatusHistoryAPI(memberId, {
            params: {
                ...rest,
                ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
            },
        });
    }

    public updateMemberStatus({ memberId, actionType }, requestPayload): Promise<ApiResponse | ApiError> {
        const args = [memberId, requestPayload] as [number, {
      comment: string;
    }];
        const statusUpdateHandlers = [
            {
                predicate: () => actionType === SUSPEND_MEMBER,
                handler: () => postSuspendMemberAPI(...args),
            },
            {
                predicate: () => actionType === UNSUSPEND_MEMBER,
                handler: () => postUnsuspendMemberAPI(...args),
            },
            {
                predicate: () => actionType === BAN_MEMBER,
                handler: () => postBanMemberAPI(...args),
            },
            {
                predicate: () => actionType === UNBAN_MEMBER,
                handler: () => postUnbanMemberAPI(...args),
            },
            {
                predicate: () => actionType === ACTIVATE_MEMBER,
                handler: () => postActivateMemberAPI(...args),
            },
            {
                predicate: () => actionType === EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS,
                handler: () => updateMemberStatus(...args),
            },
            {
                predicate: () => true,
                handler: () => undefined,
            },
        ];
        return statusUpdateHandlers.find(({ predicate }) => predicate())?.handler();
    }
}

export default MembersService;
