import StandardListTable from 'components/common/organisms/StandardListTable';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultManualFeesListQueryParams } from 'redux/manual-fees/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import createManualFeesListTableColumns from './createTableColumns';


function ManualFeesList({
    t,

    clientExtId,

    listData,
    enhancedCurrentLocation,

    accessControl,
    dispatchFetchManualFeesList,
    dispatchClearManualFeesList,
    dispatchSetQueryParams,
    dispatchRequestNavigation,

    dispatchOpenChargeCustomFeeModal,
    dispatchOpenTriggerManualFeeModal,
}) {
    const contextEnforcedQueryParams = { ...(clientExtId ? { clientExtId } : {}) };

    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname && clientExtId) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
        }
    };

    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultManualFeesListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchManualFeesList,
        dispatchClearItemsList: dispatchClearManualFeesList,
        dispatchSetQueryParams,
    });

    const filteredManualFees = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((fee) => searchCaseInsensitive(fee.name, listData?.queryParams?.search)
          || searchCaseInsensitive(fee.description, listData?.queryParams?.search));
            }

            return listData?.items || [];
        }, [listData],
    );


    const additionalButtons = useMemo(
        () => (dispatchOpenChargeCustomFeeModal
            ? [
                {
                    type: 'primary',
                    text: t('buttons.chargeCustomFee.text'),
                    icon: null,
                    onClick: dispatchOpenChargeCustomFeeModal,
                    tooltipContent: t('buttons.chargeCustomFee.tooltip'),
                },
            ]
            : []), [dispatchOpenChargeCustomFeeModal],
    );


    return (
        <StandardListTable
            filtersButtonsConfig={additionalButtons}
            rowKey="ext_id"
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchManualFeesList({ ...contextEnforcedQueryParams }, enhancedCurrentLocation.pathname)}
            items={filteredManualFees}
            totalItemsCount={filteredManualFees.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList || !clientExtId}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createManualFeesListTableColumns({
                t,
                queryParams: listData?.queryParams,
                dispatchRequestNavigation,
                accessControl,
                dispatchOpenTriggerManualFeeModal,
            })}

        />
    );
}
ManualFeesList.propTypes = {
    t: PropTypes.func.isRequired,

    clientExtId: PropTypes.string,

    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    enhancedCurrentLocation: EnhancedCurrentLocationProps,

    accessControl: AccessControlProps,

    dispatchFetchManualFeesList: PropTypes.func.isRequired,
    dispatchClearManualFeesList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,

    dispatchOpenTriggerManualFeeModal: PropTypes.func.isRequired,
    dispatchOpenChargeCustomFeeModal: PropTypes.func,
};

export default ManualFeesList;
