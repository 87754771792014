import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import CardDetailsStep from 'components/pages/authorised/CardsOrderForm/steps/CardDetailsStep';
import AddressStep from 'components/pages/authorised/CardsOrderForm/steps/DeliveryDetailsStep';
import ReviewStep from 'components/pages/authorised/CardsOrderForm/steps/ReviewStep';
import SelectTargetStep from 'components/pages/authorised/CardsOrderForm/steps/SelectTargetStep';
import StepsIndicatorLayout from 'components/pages/authorised/CardsOrderForm/steps/StepsIndicator';
import SuccessStep from 'components/pages/authorised/CardsOrderForm/steps/SuccessStep';
import { ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { CardOrderSteps } from 'redux/card-order/helpers';
import { initialFormState } from 'redux/card-order/reducer';
import { isFormPristine } from 'utils/forms-tools';

import { Col, Form, Row, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function CardsOrderForm({
    initialSetupFinished,
    currentStep,
    currentUserClientId,
    dispatchSetData,
    dispatchResetDataToInitState,
    dispatchSubmitOrderStep,
    dispatchStartCardsOrdering,
    dispatchBlockNavigation,
    dispatchUnblockNavigation,
    t,
}) {
    const [form] = Form.useForm();
    const location = useLocation();

    const clientIdFromNavigation = location?.state?.clientId;
    const memberEmbossName = location?.state?.memberEmbossName;
    const clientId = clientIdFromNavigation || currentUserClientId;

    useEffect(() => {
        dispatchStartCardsOrdering({ ...location.state, clientId });

        return () => {
            dispatchResetDataToInitState();
            dispatchUnblockNavigation();
        };
    }, []);

    const getInitialValues = (clientId, memberEmbossName) => ({
        ...initialFormState,
        embossNames: memberEmbossName ? [memberEmbossName] : [undefined],
        client_id: clientId,
    });

    const initialValues = getInitialValues(clientId, memberEmbossName);

    const handleFormValuesChanges = (changedValues, allValues) => {
        dispatchSetData(changedValues);

        const isPristine = isFormPristine({ ...allValues }, initialValues);
        if (!isPristine) {
            dispatchBlockNavigation(ReasonsForBlockedNavigation.NOT_PRISTINE_ORDER_CARDS_FORM);
        } else {
            dispatchUnblockNavigation();
        }
    };

    return (
        <>
            <StandardPageHeading title={t('orderCardsScreenTitle')} />

            <StandardPageWrapper>

                <Skeleton loading={!initialSetupFinished} active>
                    <Row style={{ width: '100%' }}>
                        <Col style={{ width: '100%', minWidth: 650 }}>
                            <StepsIndicatorLayout />

                            <Col style={{ background: 'white', paddingBottom: 20 }}>
                                <Row justify="center">
                                    <Form
                                        form={form}
                                        style={{ width: '100%', padding: 20 }}
                                        layout="vertical"
                                        initialValues={initialValues}
                                        onValuesChange={handleFormValuesChanges}
                                        onFinish={(values) => {
                                            dispatchSetData(values);
                                            dispatchSubmitOrderStep(currentStep);
                                        }}
                                    >
                                        {currentStep === CardOrderSteps.TARGET_SELECTION && (
                                            <SelectTargetStep form={form} />
                                        )}

                                        {currentStep === CardOrderSteps.CARD_DETAILS && (
                                            <CardDetailsStep form={form} />
                                        )}

                                        {currentStep === CardOrderSteps.DELIVERY_DETAILS && (
                                            <AddressStep form={form} />
                                        )}

                                        {currentStep === CardOrderSteps.REVIEW && (
                                            <ReviewStep />
                                        )}

                                        {currentStep === CardOrderSteps.SUCCESS && (
                                            <SuccessStep />
                                        )}
                                    </Form>
                                </Row>
                            </Col>
                        </Col>
                    </Row>
                </Skeleton>
            </StandardPageWrapper>
        </>

    );
}


CardsOrderForm.propTypes = {
    currentUserClientId: PropTypes.number.isRequired,
    initialSetupFinished: PropTypes.bool.isRequired,
    currentStep: PropTypes.string.isRequired,
    dispatchSubmitOrderStep: PropTypes.func.isRequired,
    dispatchStartCardsOrdering: PropTypes.func.isRequired,
    dispatchSetData: PropTypes.func.isRequired,
    dispatchResetDataToInitState: PropTypes.func.isRequired,
    dispatchUnblockNavigation: PropTypes.func.isRequired,
    dispatchBlockNavigation: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired,
};

export default CardsOrderForm;

