import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';
import AddressTabs from 'components/pages/authorised/ClientDetails/AddressTabs';
import { createFieldsConfig, createSystemFieldsConfig } from 'components/pages/authorised/ClientDetails/ClientInfo/ClientInfo.helpers';
import { createEditClientSettingsPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AddressCommonProps } from 'constants/AddressModel';
import { CountriesListProps } from 'constants/CountriesModel';
import { IndustriesListProps } from 'constants/IndustriesModel';
import { PN } from 'constants/PermissionsModel';

import { SettingOutlined } from '@ant-design/icons';
import { Row, Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function ClientInfo({
    accessControl,
    clientId,
    isLoadingAddress,
    isLoading,
    countries,
    industries,
    client,
    queryParamsMeta,
    shippingAddress,
    billingAddress,
    dispatchOpenEditAddressModal,
    dispatchOpenEditClientProfileModal,
    dispatchRequestNavigation,
    onRefresh,
    t,
}) {
    const fieldsConfig = useMemo(
        () => createFieldsConfig({
            client,
            countries,
            industries,
            t,
        }),
        [client, countries, industries],
    );

    const systemFieldsConfig = useMemo(
        () => createSystemFieldsConfig({ client, t }),
        [client],
    );

    const canUpdateClient = useMemo(
        () => accessControl.hasPermission(PN.CLIENTS.UPDATE),
        [accessControl],
    );

    return (
        <EntityDetailsInfoSkeleton
            isLoading={isLoading}
            minHeight={'280px'}
            sectionsRowsCount={8}
            sectionsCount={3}
        >
            <Row
                gutter={24}
                size={10}
                direction="horizontal"
                style={{ background: 'white', position: 'relative', padding: '0 140px 10px 0' }}
            >
                <CommonSection
                    sectionLabel={t('clients:clientDetails.sections.baseData.sectionLabel')}
                    sectionEditTooltip={t('clients:clientDetails.sections.baseData.sectionEditTooltip')}
                    fieldsConfig={fieldsConfig}
                    sectionEditOnClick={canUpdateClient ? () => dispatchOpenEditClientProfileModal(client) : undefined}
                />

                <CommonSection
                    sectionLabel={t('clients:clientDetails.sections.systemInfo.sectionLabel')}
                    fieldsConfig={systemFieldsConfig}
                />

                <AddressTabs
                    t={t}
                    clientId={clientId}
                    client={client}
                    canUpdateClient={canUpdateClient}
                    isLoadingAddress={isLoadingAddress}
                    shippingAddress={shippingAddress}
                    billingAddress={billingAddress}
                    dispatchOpenEditAddressModal={dispatchOpenEditAddressModal}
                />

                <div style={{
                    marginTop: '-12px',
                    position: 'absolute',
                    bottom: '12px',
                    right: '12px',
                }}
                >
                    <QueryParamsMetaInfo
                        detailsContext
                        onListRefresh={onRefresh}
                        queryParams={{ clientId }}
                        queryParamsMeta={queryParamsMeta}
                    />
                </div>

                {accessControl.isSuperAdmin && ( // XXX allowed - this is problematic, shares permission with client info update
                    <Button
                        style={{ position: 'absolute', top: '16px', right: '16px', display: 'block' }}
                        icon={<SettingOutlined />}
                        danger
                        onClick={() => dispatchRequestNavigation(createEditClientSettingsPath(client.id), {
                            state: {
                                clientId: client.id,
                                clientName: client.company_dba_name,
                            },
                        })}
                    >
                        {t('clients:clientDetails.buttons.editClientSettings.text')}
                    </Button>
                )}
            </Row>
        </EntityDetailsInfoSkeleton>
    );
}

ClientInfo.propTypes = {
    accessControl: AccessControlProps,
    clientId: PropTypes.number.isRequired,
    countries: CountriesListProps,
    industries: IndustriesListProps,
    shippingAddress: AddressCommonProps,
    billingAddress: AddressCommonProps,
    dispatchOpenEditAddressModal: PropTypes.func.isRequired,
    dispatchOpenEditClientProfileModal: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isLoadingAddress: PropTypes.bool,
    t: PropTypes.func,
};

export default ClientInfo;
