import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TransferDetails from './TransferDetails.layout';
import { TransferDetailsOwnProps, TransferDetailsStateProps } from './TransferDetails.types';


const mapStateToProps = (state: RootState): TransferDetailsStateProps => ({ accountDetails: state.outgoingTransfer.accountDetails });


const decorate = compose<ComponentType<TransferDetailsOwnProps>>(
    connect(mapStateToProps),
    withTranslation('beneficiaries'),
);

export default decorate(TransferDetails);
