/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';
import { cleanNilValues } from 'utils/dataMappers';

import { getTopupCardsAPI } from './index';


class TopupService {
    public getTopupCards(rawRequestPayload): Promise<ApiResponse | ApiError> {
    // XXX  dumb GET list endpoint, do NOT use limit & offset
        const { memberId, clientId, businessUserId } = rawRequestPayload;
        const queryParams = { params: cleanNilValues({ memberId, clientId, businessUserId }) };

        return getTopupCardsAPI(queryParams);
    }
}

export default TopupService;
