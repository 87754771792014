import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { replayFailedWebhook } from 'redux/failed-webhook/actions';
import { failedWebhookReducerName } from 'redux/failed-webhook/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import FailedWebhookInfo from './FailedWebhookInfo.layout';
import {
    FailedWebhookInfoDispatchProps,
    FailedWebhookInfoOwnProps,
    FailedWebhookInfoStateProps,
} from './FailedWebhookInfo.types';


const mapStateToProps = (state: RootState): FailedWebhookInfoStateProps => ({
    isLoadingReplayFailedWebhook: state[failedWebhookReducerName].isLoadingReplayFailedWebhook,
    //
});

const mapDispatchToProps = (dispatch: Dispatch): FailedWebhookInfoDispatchProps => ({
    dispatchReplayFailedWebhook: (queryParams, locationPathname) => dispatch(replayFailedWebhook(queryParams, locationPathname)),
//
});

const decorate = compose<ComponentType<FailedWebhookInfoOwnProps>>(
    withTranslation('webhooks'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(FailedWebhookInfo);
