import { showSuccessToast } from 'redux/application/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { fetchFailedWebhooksList, fetchFailedWebhooksListFailure, fetchFailedWebhooksListSuccess, replayAllFieldWebhooksFailure, replayAllFieldWebhooksSuccess } from './actions';
import { FETCH_FAILED_WEBHOOKS_LIST, REPLAY_ALL_FAILED_WEBHOOKS } from './actions.types';
import { failedWebhooksListPerLocationStoreKeyName, failedWebhooksReducerName } from './reducer';


export const onFetchFailedWebhooks = (action$, state$, { webhooks }) => action$.pipe(
    ofType(FETCH_FAILED_WEBHOOKS_LIST),
    switchMap(({ payload }) => from(webhooks.fetchFailedWebhooks(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchFailedWebhooksListSuccess(
            response.data.rows,
            response?.data.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchFailedWebhooksListFailure(payload.locationPathname))),
    )),
);

export const onReplayAllFieldWebhooks = (action$, state$, { webhooks, i18n }) => action$.pipe(
    ofType(REPLAY_ALL_FAILED_WEBHOOKS),
    switchMap(({ payload }) => from(webhooks.replayAllFailedWebhooks()).pipe(
        switchMap((response) => {
            const currentFailedWebhookListQueryParams = state$.value[failedWebhooksReducerName][failedWebhooksListPerLocationStoreKeyName]?.[payload]?.queryParams;

            return of(
                replayAllFieldWebhooksSuccess(response.data, payload),
                showSuccessToast(i18n.t('webhooks:actionMessages.replayAllWebhooksSuccess')),
                fetchFailedWebhooksList({ ...currentFailedWebhookListQueryParams }, payload),
            );
        }),
        catchError(() => {
            const currentFailedWebhookListQueryParams = state$.value[failedWebhooksReducerName][failedWebhooksListPerLocationStoreKeyName]?.[payload]?.queryParams;

            return of(
                replayAllFieldWebhooksFailure(payload),
                fetchFailedWebhooksList({ ...currentFailedWebhookListQueryParams }, payload),
            );
        }),
    )),
);


export default [
    onFetchFailedWebhooks,
    onReplayAllFieldWebhooks,
];
