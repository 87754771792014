import styled from 'styled-components';


export const ArrowWrapper = styled.div`
    grid-template-columns: min-content 29px;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    position: relative;
`;

export const ArrowHead = styled.div`
    border-top: 29px solid transparent;
    border-bottom: 29px solid transparent;
    border-left: 29px solid #ccc;
    position: relative;
    
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: -24px;
        right: 3px;
        border-top: 24px solid transparent;
        border-bottom: 24px solid transparent;
        border-left: 24px solid #fff;
        pointer-events: none;
    }
`;


export const ArrowShaft = styled.div`
    height: 24px;
    min-width: 70px;
    padding: 0 0 0 0.5rem;
    background: #ccc;
    display: block;
    text-align: center;
    position: relative;
    
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 2px;
        right: -3px;
        background: #fff;
        z-index: 2;
        pointer-events: none;
    }
`;

export const ArrowShaftContent = styled.span`
    position: relative;
    z-index: 3;
    font-size: smaller;
    font-weight: 600;
`;


export default { ArrowWrapper, ArrowHead, ArrowShaft, ArrowShaftContent };
