import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import PromotionGroupsList from 'components/pages/authorised/PromotionDetails/PromotionGroupsList';
import { groupsRouteSegmentPath } from 'config/routes';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import { FolderOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import PromotionInfo from './PromotionInfo';


function PromotionDetails({
    enhancedCurrentLocation,
    entityDetailsData,

    dispatchFetchPromotionDetails,
    dispatchClearPromotionDetails,
    dispatchSetQueryParams,

    dispatchEnablePromotion,
    dispatchDisablePromotion,
    isLoadingToggleEnableDisable,

    t,
}) {
    const { promotionUuid } = useParams();

    const contextEnforcedQueryParams = { promotionUuid };

    // XXX
    const baseURLSegmentsCount = 2; // e.g. /(1) promotion /(2) PROMOTION_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        baseUrlWithoutTabsSlugs,
        enhancedCurrentLocation,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchPromotionDetails,
        dispatchClearEntityDetails: dispatchClearPromotionDetails,
        dispatchSetQueryParams,
    });


    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('detailsScreenTitle', { promotionUuid })}
            baseURLSegmentsCount={baseURLSegmentsCount}
            tabsConfig={[
                {
                    icon: <FolderOutlined />,
                    label: t('common:tabsNames.groups'),
                    hasList: true,
                    isDefault: true,
                    activeMainTabSegmentURL: groupsRouteSegmentPath,
                    content: (
                        <PromotionGroupsList
                            promotionUuid={promotionUuid}
                            locationPathname={baseUrlWithoutTabsSlugs}
                            clientId={entityDetailsData?.entityDetails?.institution_id}
                        />
                    ),
                },
            ]}
        >
            <PromotionInfo
                t={t}
                locationPathname={baseUrlWithoutTabsSlugs}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                queryParams={{ promotionUuid }}
                onRefresh={() => dispatchFetchPromotionDetails({ promotionUuid }, baseUrlWithoutTabsSlugs)}
                promotionDetails={entityDetailsData?.entityDetails}
                areDetailsReady={isDetailsPageInitialStateDefined}
                isBusy={entityDetailsData?.isLoadingDetails}
                dispatchEnablePromotion={dispatchEnablePromotion}
                dispatchDisablePromotion={dispatchDisablePromotion}
                isLoadingToggleEnableDisable={isLoadingToggleEnableDisable}
            />
        </StandardEntityDetailsWithTabs>
    );
}

PromotionDetails.propTypes = {
    t: PropTypes.func.isRequired,
    isLoadingToggleEnableDisable: PropTypes.bool.isRequired,
    dispatchFetchPromotionDetails: PropTypes.func.isRequired,
    dispatchEnablePromotion: PropTypes.func.isRequired,
    dispatchDisablePromotion: PropTypes.func.isRequired,
    dispatchClearPromotionDetails: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    entityDetailsData: PropTypes.shape({
        entityDetails: PropTypes.shape({}),
        isLoadingDetails: PropTypes.bool,
    }),
};

export default PromotionDetails;

