import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { clearFeeDetails, fetchFeeDetails } from 'redux/fee/actions';
import { feeDetailsPerLocationStoreKeyName, feeReducerName } from 'redux/fee/reducer';
import { fetchFeeTiersList } from 'redux/fee-tiers/actions';
import { feeTiersListPerLocationStoreKeyName, feeTiersReducerName } from 'redux/fee-tiers/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import FeeDetailsPage from './FeeDetailsPage.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    clients: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: feeReducerName,
        fieldName: feeDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
    feeTiersListData: getStandardListData({
        state,
        reducerName: feeTiersReducerName,
        listsByLocationStoreKeyName: feeTiersListPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch) => ({
    dispatchFetchFeeDetails: (queryParams, locationPathname) => dispatch(fetchFeeDetails(queryParams, locationPathname)),
    dispatchFetchFeeTiersList: (queryParams, locationPathname) => dispatch(fetchFeeTiersList(queryParams, locationPathname)),
    dispatchClearFeeDetails: (locationPathname) => dispatch(clearFeeDetails(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: feeReducerName,
        fieldName: feeDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withAccessControl,
    withTranslation('fees'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(FeeDetailsPage);

