import RemoteSearch from 'components/common/molecules/RemoteSearch';
import ClientSelect from 'components/common/molecules/form-controls/ClientSelect';
import { listCompanies, listMembers } from 'components/pages/authorised/ClientDetails/Tabs/NewTransaction/NewTransaction.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AccountTypes } from 'constants/AccountModel';
import { ChargeFeeModalType } from 'constants/FeeModel';
import ListProps from 'constants/ListModel';
import { getAccountsAPI } from 'services/accounts';
import { commonValidation, isValidV4Uuid, minLengthRule, numberGreaterThan, requiredRule } from 'utils/validation-tools';

import {
    Select, Form, Radio, InputNumber, Input, Skeleton, Descriptions,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


const { Option } = Select;
const { useWatch } = Form;
const { TextArea } = Input;

// TODO reduce complexity
// eslint-disable-next-line complexity
function ChargeFeeModal({
    t,
    accessControl,
    enhancedCurrentLocation,

    clientsData,
    feeTiersListData,
    userClientId,
    modalProps,

    dispatchSetModalProps,
    dispatchChargeCustomFee,
    dispatchTriggerManualFee,
    dispatchFetchFeeTiersList,
}) {
    // state variables
    const isTriggerFeeModal = modalProps?.feeType === ChargeFeeModalType.MANUAL_FEE;
    const feeUuid = modalProps?.feeUuid;
    const isLoading = feeTiersListData?.isLoadingList;

    const isSuperAdmin = accessControl?.isSuperAdmin;

    // helper
    const translationPlaceholder = 'modals.chargeCustomFee.fields.';
    const translate = (key) => t(`${translationPlaceholder}${key}`);

    const [form] = Form.useForm();
    const { setFieldValue, setFieldsValue, submit } = form;

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => submit() });
    }, []);

    const [members, setMembers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
    const [isLoadingMembers, setIsLoadingMembers] = useState(false);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

    const type = useWatch('type', form);
    const selectedClient = useWatch('client', form);
    const creditedClient = useWatch('creditedExtClient', form);
    const selectedMember = useWatch('member', form);
    const selectedCompany = useWatch('company', form);

    const isPartner = type === 1;
    const isMember = type === 2;
    const isCompany = type === 3;

    const fetchMembers = async (clientId) => {
        setIsLoadingMembers(true);

        const data = await listMembers({ clientId });

        setMembers(data);
        setIsLoadingMembers(false);
    };

    const fetchCompanies = async (clientId) => {
        setIsLoadingCompanies(true);

        const data = await listCompanies({ clientId });

        setCompanies(data);
        setIsLoadingCompanies(false);
    };

    const fetchAccounts = async ({ key, value, type }) => {
        setIsLoadingAccounts(true);

        const response = await getAccountsAPI({
            params: {
                [key]: value,
                type,
            },
        });

        setAccounts(response.data);
        setIsLoadingAccounts(false);
    };

    useEffect(() => {
        if (!isSuperAdmin) {
            setFieldsValue({ type: 2, creditedExtClient: userClientId });
            fetchMembers(userClientId);
            fetchCompanies(userClientId);
        } else {
            setFieldsValue({
                type: 1,
                member: null,
                company: null,
            });
        }
    }, [userClientId]);


    const onRadioChangeHandler = async () => {
        if (isSuperAdmin) {
            setFieldsValue({
                member: null,
                client: null,
                company: null,
                debitedExtAccount: null,
            });
        } else {
            setFieldsValue({
                member: null,
                company: null,
                debitedExtAccount: null,
            });
        }
    };
    const onCreditedClientSelectHandler = async (clientId) => {
        if (!isPartner) {
            setFieldsValue({
                creditedExtClient: clientId,
                member: null,
                company: null,
                debitedExtAccount: null,
            });
        }

        fetchMembers(clientId);
        fetchCompanies(clientId);
    };

    const onClientSelectHandler = async (clientId) => {
        if (isPartner) {
            fetchAccounts({ key: 'clientId', value: clientId, type: [AccountTypes.Base, AccountTypes.Technical] });
        }
        setFieldsValue({
            member: null,
            company: null,
            debitedExtAccount: null,
        });
    };
    const onMemberSelectHandler = async ({ value: memberId }) => {
        fetchAccounts({ key: 'memberId', value: memberId, type: [AccountTypes.Personal, AccountTypes.Technical] });
        setFieldsValue({ debitedExtAccount: null });

        setFieldValue({ member: memberId });
    };

    const onCompanySelectHandler = async ({ value: companyId }) => {
        fetchAccounts({ key: 'companyId', value: companyId, type: [AccountTypes.Business, AccountTypes.Technical] });
        setFieldsValue({ debitedExtAccount: null });
    };

    const onFinishHandler = async (formData) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        const getAccountId = () => (isValidV4Uuid(formData?.debitedExtAccount)
            ? { debitedExtAccountId: formData?.debitedExtAccount }
            : { debitedAccountId: formData?.debitedExtAccount });

        const payload = isTriggerFeeModal ? {
            feeUuid,
            description: formData?.description,
            ...getAccountId(),
        } : {
            description: formData?.description,
            feeAmount: formData?.feeAmount,
            ...getAccountId(),
            ...(
                isSuperAdmin ? { creditedExtClientId: clientsData?.items?.find((client) => client.id === creditedClient)?.ext_client_id } : {}),
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isTriggerFeeModal ? dispatchTriggerManualFee(payload) : dispatchChargeCustomFee(payload);
    };

    useEffect(() => {
        if (isTriggerFeeModal && modalProps?.clientId) {
            onCreditedClientSelectHandler(modalProps?.clientId);
            dispatchFetchFeeTiersList({
                feeUuid,
                limit: 1,
                offset: 0,
            }, enhancedCurrentLocation?.pathname);
        }
    }, []);

    const getTriggerFeeModalTitle = () => {
        if (!isTriggerFeeModal || !feeTiersListData?.items) return null;
        const feeTier = feeTiersListData?.items[0];

        return (
            <Descriptions column={1} style={{ marginTop: '24px', marginBottom: '14px' }}>
                <Descriptions.Item label={translate('feeName.label')}>{modalProps?.name}</Descriptions.Item>
                <Descriptions.Item label={translate('feeAmount.label')}>{`${feeTier?.fee_amount_fixed} ${feeTier?.fee_currency}`}</Descriptions.Item>
            </Descriptions>
        );
    };
    return isLoading ? (
        <>
            <Skeleton active />
            <Skeleton active />
        </>
    ) : (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinishHandler}
        >
            {getTriggerFeeModalTitle()}
            <ClientSelect
                name="creditedExtClient"
                placeholder={translate('creditedClient.placeholder')}
                label={translate('creditedClient.label')}
                tooltip={translate('creditedClient.tooltip')}
                onSelect={onCreditedClientSelectHandler}
                isRequired
                hidden={!isSuperAdmin || isTriggerFeeModal}
            />

            <Form.Item name="type" label={translate('debitedClient.label')} required>
                <Radio.Group onChange={onRadioChangeHandler}>
                    {isSuperAdmin && (
                        <Radio value={1}>Partner</Radio>
                    )}
                    <Radio value={2}>Member</Radio>
                    <Radio value={3}>
            Company
                    </Radio>
                </Radio.Group>
            </Form.Item>

            <ClientSelect
                name="client"
                placeholder={translate('debitedClient.placeholder')}
                onSelect={onClientSelectHandler}
                initialValue={!isSuperAdmin ? userClientId : null}
                isRequired={isPartner && isSuperAdmin}
                dependencies={['creditedExtClient']}
                rules={[
                    () => ({
                        validator: async () => {
                            if (isSuperAdmin && isPartner && (creditedClient === selectedClient)) {
                                throw new Error('Partner and Credited Partner cannot be the same');
                            }
                        },
                    }),
                ]}
                hidden={!isSuperAdmin || !isPartner}
            />
            {isMember && (
                <Form.Item
                    name="member"
                    rules={[
                        ...requiredRule(t),
                    ]}
                >
                    <RemoteSearch
                        placeholder={translate('member.placeholder')}
                        fetchOptions={(search) => listMembers({ clientId: creditedClient, search })}
                        onSelect={onMemberSelectHandler}
                        showSearch
                        disabled={!creditedClient || isLoadingMembers}
                        options={members}
                        setOptions={setMembers}
                        loading={isLoadingMembers}
                    />
                </Form.Item>
            )}

            {isCompany && (
                <Form.Item
                    name="company"
                    rules={[
                        ...requiredRule(t),
                    ]}
                >
                    <RemoteSearch
                        placeholder={translate('company.placeholder')}
                        fetchOptions={(search) => listCompanies({ clientId: creditedClient, search })}
                        onSelect={onCompanySelectHandler}
                        showSearch
                        disabled={!creditedClient || isLoadingCompanies}
                        options={companies}
                        setOptions={setCompanies}
                        loading={isLoadingCompanies}
                    />
                </Form.Item>
            )}

            <Form.Item
                name="debitedExtAccount"
                label={translate('debitedAccount.label')}
                tooltip={translate('debitedAccount.tooltip')}
                rules={[
                    ...requiredRule(t),
                ]}
            >
                <Select
                    placeholder={translate('debitedAccount.placeholder')}
                    loading={isLoadingAccounts}
                    disabled={
                        (isPartner && !selectedClient)
              || (isMember && !selectedMember)
              || (isCompany && !selectedCompany)
              || isLoadingAccounts
                    }
                >
                    {accounts.map(({ id, ext_account_id, currency, type, available_balance }) => (
                        <Option key={id} value={isValidV4Uuid(ext_account_id) ? ext_account_id : id}>
                            {`${type} ${currency}, Balance: ${available_balance}`}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            {!isTriggerFeeModal && (
                <Form.Item
                    name="feeAmount"
                    label={translate('feeAmount.label')}
                    tooltip={translate('feeAmount.tooltip')}
                    rules={[
                        { type: 'number' },
                        ...requiredRule(t),
                        ...numberGreaterThan('feeAmount', t, 0),
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder={translate('feeAmount.placeholder')}
                    />
                </Form.Item>
            )}
            <Form.Item
                label={translate('description.label')}
                tooltip={translate('description.tooltip')}
                name="description"
                rules={[
                    ...commonValidation({ t, isRequired: true }),
                    ...minLengthRule(10, t),
                ]}
            >
                <TextArea placeholder={translate('description.placeholder')} />
            </Form.Item>
        </Form>
    );
}
ChargeFeeModal.propTypes = {
    accessControl: AccessControlProps,

    userClientId: PropTypes.number.isRequired,
    clientsData: ListProps,
    modalProps: PropTypes.shape({
        feeType: PropTypes.string,
        feeUuid: PropTypes.string,
        clientId: PropTypes.number,
        name: PropTypes.string,
    }).isRequired,
    feeTiersListData: ListProps,

    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchChargeCustomFee: PropTypes.func.isRequired,
    dispatchTriggerManualFee: PropTypes.func.isRequired,
    dispatchFetchFeeTiersList: PropTypes.func.isRequired,
};
export default ChargeFeeModal;
