import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';

import { DeleteAccountButtonProps } from './DeleteAccountButton.types';


function DeleteAccountButton({
    accountDetails,
    t,
    locationPathname,
    isLoadingDeleteAccount,
    dispatchDeleteAccount,
    isDeletable,
    isPrimaryAccount,
}: DeleteAccountButtonProps) {
    const deleteButton = (
        <Button
            danger
            icon={<DeleteOutlined />}
            loading={isLoadingDeleteAccount}
            disabled={!isDeletable || isPrimaryAccount || isLoadingDeleteAccount}
        >
            {t('buttons.deleteAccount.text')}
        </Button>
    );

    return isDeletable && !isPrimaryAccount && locationPathname
        ? (
            <Popconfirm
                placement="bottom"
                title={t('buttons.deleteAccount.confirmationQuestion')}
                okText={t('buttons.deleteAccount.text')}
                onConfirm={() => dispatchDeleteAccount(accountDetails, locationPathname)}
            >
                {deleteButton}
            </Popconfirm>
        )
        : (
            <Tooltip
                title={t(`buttons.deleteAccount.${isPrimaryAccount ? 'disabledTooltipIsPrimary' : 'disabledTooltip'}`)}
                placement="bottom"
            >
                {deleteButton}
            </Tooltip>

        );
}
export default DeleteAccountButton;
