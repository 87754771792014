import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';
import { createEditBeneficiaryPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { PN } from 'constants/PermissionsModel';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import {
    createBeneficiaryDetailsFields,
    createBeneficiaryAddressFields,
    createBeneficiaryAccountIdentificationsFields,
} from './BeneficiaryInfo.helpers';


function BeneficiaryInfo({
    accessControl,
    locationPathname,
    t,
    beneficiaryDetails,
    queryParamsMeta,
    onRefresh,
    areDetailsReady,
    isBusy,
    isLoadingDeleteBeneficiary,

    dispatchDeleteBeneficiary,
    dispatchRequestNavigation,
}) {
    const beneficiaryDetailsFields = useMemo(
        () => createBeneficiaryDetailsFields(beneficiaryDetails, t),
        [beneficiaryDetails],
    );

    const beneficiaryAddressFields = useMemo(
        () => createBeneficiaryAddressFields(beneficiaryDetails, t),
        [beneficiaryDetails],
    );

    const beneficiaryAccountIdentificationsFields = useMemo(
        () => createBeneficiaryAccountIdentificationsFields(beneficiaryDetails, t),
        [beneficiaryDetails],
    );

    const canDelete = useMemo(() => accessControl.hasPermission(PN.BENEFICIARIES.DELETE), [accessControl]);
    const canEdit = useMemo(() => accessControl.hasPermissions([PN.BENEFICIARIES.UPDATE, PN.BENEFICIARIES.REQUIRED_DETAILS]), [accessControl]);

    const beneficiaryName = useMemo(() => beneficiaryDetails?.company_name
    // eslint-disable-next-line max-len
    || `${beneficiaryDetails?.first_name}${beneficiaryDetails?.middle_name ? ` ${beneficiaryDetails?.middle_name}` : ''} ${beneficiaryDetails?.last_name}`, [beneficiaryDetails]);


    const applicableOwnerId = useMemo(() => ({
        ...(beneficiaryDetails?.company_id > 0 ? { companyId: beneficiaryDetails?.company_id } : {}),
        ...(beneficiaryDetails?.member_id > 0 ? { memberId: beneficiaryDetails?.member_id } : {}),
        ...(beneficiaryDetails?.client_id > 0 && !beneficiaryDetails?.companyId > 0 && !beneficiaryDetails?.member_id > 0
            ? { clientId: beneficiaryDetails?.client_id } : {}),
    }), [beneficiaryDetails]);


    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={!areDetailsReady && isBusy}
                minHeight={'250px'}
                sectionsRowsCount={6}
                sectionsCount={3}
            >
                <Spin
                    tip="Processing..."
                    spinning={isBusy || isLoadingDeleteBeneficiary}
                >
                    <DetailsSectionsWrapper>
                        <Row gutter={24} size={10} direction="horizontal">
                            <CommonSection
                                sectionLabel={t('Beneficiary details')}
                                fieldsConfig={beneficiaryDetailsFields}
                            />

                            <CommonSection
                                sectionLabel={t('Account identifications')}
                                fieldsConfig={beneficiaryAccountIdentificationsFields}
                            />

                            <CommonSection
                                sectionLabel={t('Beneficiary address')}
                                fieldsConfig={beneficiaryAddressFields}
                            />
                        </Row>

                        <OptionalButtonsWrapper>
                            {canEdit && (
                                <Button
                                    type="primary"
                                    icon={<EditOutlined />}
                                    onClick={() => dispatchRequestNavigation(createEditBeneficiaryPath(beneficiaryDetails?.id), {
                                        state: {
                                            ownerContext: {
                                                beneficiaryId: beneficiaryDetails?.id,
                                                beneficiaryName,
                                                returnPath: locationPathname,
                                                ...applicableOwnerId,
                                            },
                                            formData: beneficiaryDetails,
                                        },
                                    })}
                                >
                                    {t('buttons.updateBeneficiary.text')}
                                </Button>
                            )}

                            {canDelete && (
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => dispatchDeleteBeneficiary(beneficiaryDetails?.id, { ...applicableOwnerId })}
                                >
                                    <Button
                                        danger
                                        icon={<DeleteOutlined />}
                                        loading={isLoadingDeleteBeneficiary}
                                        disabled={isLoadingDeleteBeneficiary}
                                    >
                                        {t('buttons.deleteBeneficiary.text')}
                                    </Button>
                                </Popconfirm>
                            )}
                        </OptionalButtonsWrapper>

                        <QueryParamsMetaInfo
                            detailsContext
                            onListRefresh={onRefresh}
                            queryParams={{ beneficiaryUuid: beneficiaryDetails?.id }}
                            queryParamsMeta={queryParamsMeta}
                        />
                    </DetailsSectionsWrapper>
                </Spin>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}

BeneficiaryInfo.propTypes = {
    t: PropTypes.func.isRequired,
    accessControl: AccessControlProps,
    onRefresh: PropTypes.func,
    queryParamsMeta: PropTypes.shape({}),
    beneficiaryDetails: PropTypes.shape({}),
    areDetailsReady: PropTypes.bool,
    isBusy: PropTypes.bool,
    dispatchDeleteBeneficiary: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    locationPathname: PropTypes.string,
    isLoadingDeleteBeneficiary: PropTypes.bool,
};


export default BeneficiaryInfo;
