import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import AddCompanyForm from 'components/pages/authorised/AddCompanyPage/AddCompanyForm';

import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';


function AddCompanyPage({ t }) {
    const { clientId } = useParams();
    const location = useLocation();
    const fixedClientId = Number(clientId);

    return (
        <>
            <StandardPageHeading
                title={t('Add new company')}
            />

            <StandardPageWrapper>
                {location?.pathname
                    ? (
                        <AddCompanyForm
                            clientId={fixedClientId}
                            locationPathname={location.pathname}
                        />
                    )
                    : (<Skeleton loading active />)}

            </StandardPageWrapper>
        </>
    );
}


AddCompanyPage.propTypes = { t: PropTypes.func.isRequired };


export default AddCompanyPage;
