import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { fetchStakeholdersListFailure, fetchStakeholdersListSuccess } from './actions';
import { FETCH_STAKEHOLDERS_LIST } from './actions.types';


export const onFetchStakeholdersList = (action$, state$, { companies }) => action$.pipe(
    ofType(FETCH_STAKEHOLDERS_LIST),
    switchMap(({ payload }) => from(companies.getStakeholdersByCompany(payload.queryParams))
        .pipe(
            switchMap((response) => of(fetchStakeholdersListSuccess(
                response.data.rows,
                response.data?.count,
                payload.locationPathname,
            ))),
            catchError(() => of(fetchStakeholdersListFailure(payload.locationPathname))),
        )),
);


export default [
    onFetchStakeholdersList,
];
