import { showErrorToast, showSuccessToast } from 'redux/application/actions';
import { addBusinessUserFailure, addBusinessUserSuccess, fetchBusinessUsersList, fetchBusinessUsersListFailure, fetchBusinessUsersListSuccess } from 'redux/business-users/actions';
import { createCreateBusinessUserErrorMessage } from 'redux/business-users/epics.helpers';
import { hideModal, setModalProps } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ADD_BUSINESS_USER, FETCH_BUSINESS_USERS_LIST } from './action.types';
import { businessUsersReducerName, businessUsersListsPerLocationStoreKeyName } from './reducer';


export const onFetchBusinessUsersList = (action$, state$, { businessUsers }) => action$.pipe(
    ofType(FETCH_BUSINESS_USERS_LIST),
    switchMap(({ payload }) => from(businessUsers.getBusinessUsers(payload.queryParams)).pipe(
        switchMap((response) => of(fetchBusinessUsersListSuccess(
            response.data?.rows,
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchBusinessUsersListFailure(payload.locationPathname))),
    )),
);


export const onAddBusinessUser = (action$, state$, { companies, i18n }) => action$.pipe(
    ofType(ADD_BUSINESS_USER),
    switchMap(({ payload }) => from(companies.createBusinessUserForCompany(payload.formData))
        .pipe(
            switchMap((response) => {
                // eslint-disable-next-line max-len
                const currentBusinessUsersListQueryParams = state$.value[businessUsersReducerName][businessUsersListsPerLocationStoreKeyName]?.[payload.locationPathname]?.queryParams;

                return of(
                    ...(currentBusinessUsersListQueryParams ? [fetchBusinessUsersList(currentBusinessUsersListQueryParams, payload.locationPathname)] : []),
                    addBusinessUserSuccess(response.data, payload.locationPathname),
                    showSuccessToast(i18n.t('companies:actionMessages.addBusinessUserSuccess')),
                    hideModal(),
                );
            }),
            catchError((error) => of(
                showErrorToast(createCreateBusinessUserErrorMessage(i18n.t, error)),
                addBusinessUserFailure(payload.locationPathname),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);


export default [
    onAddBusinessUser,
    onFetchBusinessUsersList,
];
