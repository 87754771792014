import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { removePromotion, removePromotionFromGroup } from 'redux/promotion/actions';
import { clearPromotionsList, fetchPromotionsList } from 'redux/promotions/actions';
import { promotionsListsPerLocationStoreKeyName, promotionsReducerName } from 'redux/promotions/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import PromotionsList from './PromotionsList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: promotionsReducerName,
        listsByLocationStoreKeyName: promotionsListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchRemovePromotion: (requestPayload, locationPathname) => dispatch(removePromotion(requestPayload, locationPathname)),
    dispatchRemovePromotionFromGroup: (queryParams, locationPathname) => dispatch(removePromotionFromGroup(queryParams, locationPathname)),

    dispatchFetchPromotionsList: (queryParams, locationPathname) => dispatch(fetchPromotionsList(queryParams, locationPathname)),
    dispatchClearPromotionsList: (queryParams, locationPathname) => dispatch(clearPromotionsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: promotionsReducerName,
        fieldName: promotionsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('promotions'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);


export default decorate(PromotionsList);
