import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';

import { TransactionStatusHistoryListItem } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React, { JSX, useMemo } from 'react';

import Styled from './TransactionStatusTimelineArrow.styled';
import { TransactionStatusTimelineArrowProps } from './TransactionStatusTimelineItem.types';


function createArrowShaftContent(afterItem: TransactionStatusHistoryListItem, t: TFunction) {
    const handlers = [
        {
            predicate: () => afterItem.balanceBeforeTransaction === afterItem.balanceAfterTransaction,
            handler: () => t('common:noChange'),
        },
        {
            predicate: () => true,
            handler: () => (
                <>
                    {afterItem.balanceBeforeTransaction < afterItem.balanceAfterTransaction ? '+' : ''}
                    <StandardValue value={-(afterItem.balanceBeforeTransaction - afterItem.balanceAfterTransaction)} valueType={ValueTypes.AMOUNT} />
                    {` ${afterItem.currency}`}
                </>
            ),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

function TransactionStatusTimelineArrow({ afterItem, t }: TransactionStatusTimelineArrowProps): JSX.Element {
    const arrowShaftContent = useMemo(() => createArrowShaftContent(afterItem, t), [afterItem]);

    return (
        <Styled.ArrowWrapper>
            <Styled.ArrowShaft>
                <Styled.ArrowShaftContent>
                    {arrowShaftContent}
                </Styled.ArrowShaftContent>
            </Styled.ArrowShaft>
            <Styled.ArrowHead />
        </Styled.ArrowWrapper>

    );
}

export default TransactionStatusTimelineArrow;
