import { IdQueryParams } from 'models/app/common';
import { LocationPathname } from 'models/app/navigation';

import { FailedWebhook } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_FAILED_WEBHOOK_DETAILS, FETCH_FAILED_WEBHOOK_DETAILS_SUCCESS, FETCH_FAILED_WEBHOOK_DETAILS_FAILURE,
    CLEAR_FAILED_WEBHOOK_DETAILS,
    REPLAY_FAILED_WEBHOOK,
    REPLAY_FAILED_WEBHOOK_FAILURE,
    REPLAY_FAILED_WEBHOOK_SUCCESS,
} from './actions.types';


export const fetchFailedWebhookDetails = createAction(FETCH_FAILED_WEBHOOK_DETAILS,
    (queryParams: IdQueryParams, locationPathname: LocationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchFailedWebhookDetailsSuccess = createAction(FETCH_FAILED_WEBHOOK_DETAILS_SUCCESS,
    (responsePayload: FailedWebhook, locationPathname: LocationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchFailedWebhookDetailsFailure = createAction(FETCH_FAILED_WEBHOOK_DETAILS_FAILURE,
    (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));

export const clearFailedWebhookDetails = createAction(CLEAR_FAILED_WEBHOOK_DETAILS,
    (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));


export const replayFailedWebhook = createAction(REPLAY_FAILED_WEBHOOK, (queryParams: IdQueryParams, locationPathname: LocationPathname) => ({
    payload: {
        queryParams,
        locationPathname,
    },
}));
export const replayFailedWebhookSuccess = createAction(REPLAY_FAILED_WEBHOOK_SUCCESS, (responsePayload, locationPathname) => ({
    payload: {
        responsePayload,
        locationPathname,
    },
}));
export const replayFailedWebhookFailure = createAction(REPLAY_FAILED_WEBHOOK_FAILURE, (webhookUuid, locationPathname) => ({
    payload: {
        webhookUuid,
        locationPathname,
    },
}));
