import { LocationPathname } from 'models/app/navigation';
import { FetchStandardListPayload, FetchStandardListWithTotalCountResponsePayload } from 'models/app/standardList';

import { MemberCommonData } from '@manigo/manigo-domain-typings';


export interface UpdateMemberStatusPayload {
  queryParams: {
    memberId: MemberCommonData['memberId'];
  },
  requestPayload: {
    comment: string;
  }
  locationPathname: LocationPathname;
}

export interface UpdateExternalKycKybMemberStatusPayload {
  queryParams: {
    memberId: MemberCommonData['memberId'];
  },
  requestPayload: {
    memberStatusComment: string;
    memberStatus: MemberCommonData['memberStatus']
  }
  locationPathname: LocationPathname;
}


export const SUSPEND_MEMBER = 'SUSPEND_MEMBER';
export const UNSUSPEND_MEMBER = 'UNSUSPEND_MEMBER';
export const BAN_MEMBER = 'BAN_MEMBER';
export const UNBAN_MEMBER = 'UNBAN_MEMBER';
export const ACTIVATE_MEMBER = 'ACTIVATE_MEMBER';
export const EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS = 'EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS';

export const CLEAR_RESET_UPDATE_MEMBER_STATUS_FORM = 'CLEAR_RESET_UPDATE_MEMBER_STATUS_FORM';

export interface FetchMemberStatusHistoryQueryParams {
  memberId: MemberCommonData['memberId'];
}

export type FetchMemberStatusHistoryPayload = FetchStandardListPayload<FetchMemberStatusHistoryQueryParams>;
export type FetchMemberStatusHistoryResponsePayload = FetchStandardListWithTotalCountResponsePayload<any>;

export const FETCH_MEMBER_STATUS_HISTORY_LIST = 'FETCH_MEMBER_STATUS_HISTORY_LIST';
export const FETCH_MEMBER_STATUS_HISTORY_LIST_SUCCESS = 'FETCH_MEMBER_STATUS_HISTORY_LIST_SUCCESS';
export const FETCH_MEMBER_STATUS_HISTORY_LIST_FAILURE = 'FETCH_MEMBER_STATUS_HISTORY_LIST_FAILURE';

export const CLEAR_MEMBER_STATUS_HISTORY_LIST = 'CLEAR_MEMBER_STATUS_HISTORY_LIST';
