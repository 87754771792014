import { promotionsNotAssignedToGroupListSuffix } from 'constants/promotions';
import { assignPromotions } from 'redux/group/actions';
import { setModalProps } from 'redux/modal/actions';
import { fetchPromotionsList } from 'redux/promotions/actions';
import { promotionsListsPerLocationStoreKeyName, promotionsReducerName } from 'redux/promotions/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddPromotionToGroupModal from './AddPromotionToGroupModal.layout';


const mapStateToProps = (state) => ({
    promotionsListData: getStandardListData({
        state,
        reducerName: promotionsReducerName,
        listsByLocationStoreKeyName: promotionsListsPerLocationStoreKeyName,
        locationSuffix: promotionsNotAssignedToGroupListSuffix,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchPromotionsList: (queryParams, locationPathname) => dispatch(fetchPromotionsList(queryParams, locationPathname)),
    dispatchAssignPromotions: (requestPayload, locationPathname) => dispatch(assignPromotions(requestPayload, locationPathname)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});


const decorate = compose(
    withTranslation('groups'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddPromotionToGroupModal);
