import { Counter } from 'models/app/common';
import { OutgoingTransferForms, OutgoingTransferStep } from 'models/app/outgoingTransfer';
import { Action } from 'models/meta/action';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';

import { AccountListItem, Beneficiary } from '@manigo/manigo-domain-typings';
import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import {
    CLEAR_OUTGOING_TRANSFER_DATA,
    CREATE_OUTGOING_TRANSFER,
    CREATE_OUTGOING_TRANSFER_FAILURE,
    CREATE_OUTGOING_TRANSFER_SUCCESS,
    FETCH_ALL_ACCOUNTS,
    FETCH_ALL_ACCOUNTS_FAILURE,
    FETCH_ALL_ACCOUNTS_SUCCESS,
    FETCH_ALL_BENEFICIARIES,
    FETCH_ALL_BENEFICIARIES_FAILURE,
    FETCH_ALL_BENEFICIARIES_SUCCESS,
    INITIALISE_OUTGOING_TRANSFER_FORM,
    REQUEST_RESET_FORM, SET_OUTGOING_TRANSFER_FORM_STEP,
    SET_SELECTED_ACCOUNT_DETAILS,
    SET_SELECTED_BENEFICIARY_DETAILS,
} from './actions.types';
import { OutgoingTransferState } from './reducer.types';


export const outgoingTransferReducerName = 'outgoingTransfer';


const initialState:OutgoingTransferState = {
    beneficiariesList: [],
    beneficiariesTotalCount: null,
    isLoadingBeneficiaries: false,

    accountsList: [],
    isLoadingAccounts: false,

    // details
    beneficiaryDetails: null,
    accountDetails: null,
    currentStep: null,
    formData: null,
    contextData: null,
    isLoadingMakeOutgoingTransfer: false,
    requestResetForm: false,
};

const createOutgoingTransferBuilderCases = (builder:ActionReducerMapBuilder<OutgoingTransferState>) => {
    builder
        .addCase(INITIALISE_OUTGOING_TRANSFER_FORM, (state, action: Action<any>) => {
            state.contextData = { ...action.payload };
            state.currentStep = OutgoingTransferStep.ACCOUNT_SELECTION;
        })
        .addCase(FETCH_ALL_ACCOUNTS, (state) => {
            state.isLoadingAccounts = true;
        })
        .addCase(FETCH_ALL_ACCOUNTS_SUCCESS, (state, action: Action<AccountListItem[]>) => {
            state.isLoadingAccounts = false;
            state.accountsList = action.payload;
        })
        .addCase(FETCH_ALL_ACCOUNTS_FAILURE, (state) => {
            state.isLoadingAccounts = false;
        })
        .addCase(FETCH_ALL_BENEFICIARIES, (state) => {
            state.isLoadingBeneficiaries = true;
        })
        .addCase(FETCH_ALL_BENEFICIARIES_SUCCESS, (state, action: Action<{ rows: Beneficiary[], count: Counter }>) => {
            state.isLoadingBeneficiaries = false;
            state.beneficiariesList = action.payload.rows;
            state.beneficiariesTotalCount = action.payload.count;
        })
        .addCase(FETCH_ALL_BENEFICIARIES_FAILURE, (state) => {
            state.isLoadingBeneficiaries = false;
        })
        .addCase(SET_SELECTED_BENEFICIARY_DETAILS, (state, action: Action<Beneficiary>) => {
            state.beneficiaryDetails = action.payload;
        })
        .addCase(REQUEST_RESET_FORM, (state, action: Action<boolean>) => {
            state.requestResetForm = action.payload; // test it it was action.value why
        })
        .addCase(SET_SELECTED_ACCOUNT_DETAILS, (state, action: Action<AccountListItem>) => {
            state.accountDetails = action.payload;
        })
        .addCase(SET_OUTGOING_TRANSFER_FORM_STEP, (state, action: Action<{ step: OutgoingTransferStep, formData: OutgoingTransferForms }>) => {
            state.currentStep = action.payload.step;
            if (action.payload) {
                state.formData = {
                    ...state.formData,
                    ...action.payload.formData,
                };
            }
        })
        .addCase(CREATE_OUTGOING_TRANSFER, (state) => {
            state.isLoadingMakeOutgoingTransfer = true;
        })
        .addCase(CREATE_OUTGOING_TRANSFER_SUCCESS, (state) => {
            state.isLoadingMakeOutgoingTransfer = false;
            state.currentStep = OutgoingTransferStep.ACCOUNT_SELECTION;
            state.formData = null;
            state.beneficiaryDetails = null;
            state.accountDetails = null;
            state.requestResetForm = true;
        })
        .addCase(CREATE_OUTGOING_TRANSFER_FAILURE, (state) => {
            state.isLoadingMakeOutgoingTransfer = false;
        })
        .addCase(CLEAR_OUTGOING_TRANSFER_DATA, () => initialState)
        .addCase(CLEAR_CURRENT_USER, () => initialState);
};

export default createReducer(initialState, createOutgoingTransferBuilderCases);
