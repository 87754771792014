import Styled from 'components/common/molecules/OffboardingButtons/OffboardingButtons.styled';
import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';

import { Button, Popconfirm } from 'antd';
import React from 'react';


function renderOffboardingButton({
    config,
    entityType,
    entityName,
    t,
    index,
    isEntityLoading,
}) {
    return config.dispatchAction
        ? (
            <Styled.OffboardingButtonWrapper key={`offboardingButtonWrapper_${index}`}>
                <Popconfirm
                    title={(
                        <StandardTranslationWithMarkup
                            translationKey={config.confirmationQuestionKey}
                            namespace="offboarding"
                            values={{ entityName }}
                            t={t}
                        />
                    )}
                    onConfirm={config.dispatchAction}
                >
                    <Button
                        type="primary"
                        danger={config.isDanger}
                        icon={config.icon}
                        disabled={config.isLoading || isEntityLoading}
                        loading={config.isLoading}
                    >
                        {t(config.buttonLabelKey, { entityType })}
                    </Button>

                </Popconfirm>
            </Styled.OffboardingButtonWrapper>
        )
        : null;
}


export function renderContent({
    isEntityLoading,
    isApplicableForStartOffboarding,
    isApplicableForStopOffboarding,
    isApplicableForFinaliseOffboarding,
    createButtonsConfigs,
    t,
    entityType,
    entityName,
}) {
    const handlers = [
        {
            predicate: () => isApplicableForStartOffboarding,
            handler: (index) => renderOffboardingButton({
                config: createButtonsConfigs.startOffboarding,
                t,
                entityType,
                entityName,
                index,
                isEntityLoading,
            }),
        },
        {
            predicate: () => isApplicableForStopOffboarding,
            handler: (index) => renderOffboardingButton({
                config: createButtonsConfigs.stopOffboarding,
                t,
                entityType,
                entityName,
                index,
                isEntityLoading,
            }),
        },
        {
            predicate: () => isApplicableForFinaliseOffboarding,
            handler: (index) => renderOffboardingButton({
                config: createButtonsConfigs.finaliseOffboarding,
                t,
                entityType,
                entityName,
                index,
                isEntityLoading,
            }),
        },
        {
            predicate: () => true,
            handler: () => null,
        },
    ];

    return handlers.filter(({ predicate }) => predicate()).map(((item, index) => item.handler(index)));
}


export default { renderContent };
