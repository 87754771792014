import styled from 'styled-components';


const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  
  > div + div  {
    margin-left: 40px;
    padding-left: 40px;
    border-left: 1px solid #F0F0F0;
  }
`;


const ButtonsWrapper = styled.div`
  padding: 25px 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  justify-content: right;
  align-items: center;
`;


export default {
    TwoColumns,
    ButtonsWrapper,
};
