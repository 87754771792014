import { standardPageSize } from 'config/config';
import { feeRootRoutePath, tiersRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import { fetchFeeTiersList, fetchFeeTiersListSuccess, fetchFeeTiersListFailure, clearFeeTiersList } from './actions';


export const feeTiersReducerName = 'feeTiers';
export const feeTiersListPerLocationStoreKeyName = 'feeTiersListPerLocation';

export const defaultFeeTiersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};


const initialState = {
    [feeTiersListPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    //
};


const handlers = {
    [fetchFeeTiersList]: (state, action) => {
        state[feeTiersListPerLocationStoreKeyName] = updateStandardLists(state[feeTiersListPerLocationStoreKeyName], action);
    },
    [fetchFeeTiersListSuccess]: (state, action) => {
        state[feeTiersListPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[feeTiersListPerLocationStoreKeyName], action);
    },
    [fetchFeeTiersListFailure]: (state, action) => {
        state[feeTiersListPerLocationStoreKeyName] = updateStandardListsOnFailure(state[feeTiersListPerLocationStoreKeyName], action);
    },
    [clearFeeTiersList]: (state, action) => {
        state[feeTiersListPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[feeTiersListPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[feeTiersListPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[feeTiersListPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [tiersRouteSegmentPath],
                observedRootPaths: [feeRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === feeTiersReducerName
      && action.payload?.fieldName === feeTiersListPerLocationStoreKeyName
        ) {
            state[feeTiersListPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[feeTiersListPerLocationStoreKeyName] }, action);
        }
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
