import { standardPageSize } from 'config/config';
import { clientRootRoutePath, feeRootRoutePath, groupsRouteSegmentPath } from 'config/routes';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { clearGroupsList, fetchGroupsList, fetchGroupsListFailure, fetchGroupsListSuccess } from 'redux/groups/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const groupsReducerName = 'groups';
export const groupsListsPerLocationStoreKeyName = 'groupsListsPerLocation';


export const defaultGroupsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: undefined,
    sortOrder: undefined,
};


const initialState = {
    [groupsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    // ?
};


const handlers = {
    [fetchGroupsList]: (state, action) => {
    // state.clientId = action.payload ( .clientId ?) ;
        state[groupsListsPerLocationStoreKeyName] = updateStandardLists(state[groupsListsPerLocationStoreKeyName], action);
    },
    [fetchGroupsListSuccess]: (state, action) => {
        state[groupsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[groupsListsPerLocationStoreKeyName], action);
    },
    [fetchGroupsListFailure]: (state, action) => {
        state[groupsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[groupsListsPerLocationStoreKeyName], action);
    },
    [clearGroupsList]: (state, action) => {
        state[groupsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[groupsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[groupsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[groupsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [groupsRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath, feeRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === groupsReducerName
        && action.payload?.fieldName === groupsListsPerLocationStoreKeyName
        ) {
            state[groupsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[groupsListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
