import { setQueryParams } from 'redux/application/actions';
import { clearKycKybLogsList, fetchKycKybLogsList } from 'redux/kyc-kyb/actions';
import { kycKybAuditsListPerLocationStoreKeyName, kycKybReducerName } from 'redux/kyc-kyb/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import KycKybAuditList from './KycKybAuditList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: kycKybReducerName,
        listsByLocationStoreKeyName: kycKybAuditsListPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchKycKybLogsList: (queryParams, locationPathname) => dispatch(fetchKycKybLogsList(queryParams, locationPathname)),
    dispatchClearKycKybLogsList: (locationPathname) => dispatch(clearKycKybLogsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: kycKybReducerName,
        fieldName: kycKybAuditsListPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(KycKybAuditList);
