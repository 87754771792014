/**
 * Extending the Window interface to add a new method to the window object
 * @example window.applicationVersionData() - to get the application version data
 */

import { Store } from '@reduxjs/toolkit';


export interface WindowModel extends Window {
  applicationVersionData: () => void;
  Cypress?: any,
  store?: Store
}

export const browserWindow = window as unknown as WindowModel;
