import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { showUnlinkCardModal } from 'redux/card/actions';
import { clearCardsList, fetchAllCards } from 'redux/cards/actions';
import { cardsListsPerLocationStoreKeyName, cardsReducerName } from 'redux/cards/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import CardsList from './CardList.layout';


const mapStateToProps = (state) => ({
    currentUserClientId: state[currentUserReducerName].userData?.clientId,
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,

    listData: getStandardListData({
        state,
        reducerName: cardsReducerName,
        listsByLocationStoreKeyName: cardsListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchAllCards: (queryParams, locationPathname) => dispatch(fetchAllCards(queryParams, locationPathname)),
    dispatchClearCardsList: (locationPathname) => dispatch(clearCardsList(locationPathname)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchShowUnlinkCardModal: (cardRecord, meta) => dispatch(showUnlinkCardModal(cardRecord, meta)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: cardsReducerName,
        fieldName: cardsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);


export default decorate(CardsList);
