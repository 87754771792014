import { defaultTheme } from 'config/theme';

import { css } from 'styled-components';


export const scrollBar = css`
    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: ${defaultTheme.token.colorPrimary};
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }
`;
