import styled from 'styled-components';


const DashboardHeading = styled.h1`
    padding: 10px 0;
`;

const DashboardColumns = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 50px;
`;


export default { DashboardHeading, DashboardColumns };
