export const FETCH_CARD_STATUS_HISTORY = 'FETCH_CARD_STATUS_HISTORY';
export const FETCH_CARD_STATUS_HISTORY_SUCCESS = 'FETCH_CARD_STATUS_HISTORY_SUCCESS';
export const FETCH_CARD_STATUS_HISTORY_FAILURE = 'FETCH_CARD_STATUS_HISTORY_FAILURE';
export const CLEAR_CARD_STATUS_HISTORY = 'CLEAR_CARD_STATUS_HISTORY';

export const TOGGLE_CHANGE_CARD_STATUS_FORM_VISIBILITY = 'TOGGLE_CHANGE_CARD_STATUS_FORM_VISIBILITY';

export const UPDATE_CARD_STATUS = 'UPDATE_CARD_STATUS';
export const UPDATE_CARD_STATUS_SUCCESS = 'UPDATE_CARD_STATUS_SUCCESS';
export const UPDATE_CARD_STATUS_FAILURE = 'UPDATE_CARD_STATUS_FAILURE';

