import { globalLocation } from 'constants/NavigationModel';
import { setQueryParams } from 'redux/application/actions';
import { clearIndustriesList, fetchIndustriesList } from 'redux/industries/actions';
import { industriesListsPerLocationStoreKeyName, industriesReducerName } from 'redux/industries/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';


import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import IndustriesList from './IndustriesList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: industriesReducerName,
        listsByLocationStoreKeyName: industriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchIndustriesList: (queryParams) => dispatch(fetchIndustriesList(queryParams)),
    dispatchClearIndustriesList: () => dispatch(clearIndustriesList()),
    dispatchSetQueryParams: (queryParams) => dispatch(setQueryParams({
        reducerName: industriesReducerName,
        fieldName: industriesListsPerLocationStoreKeyName,
        locationPathname: globalLocation,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(IndustriesList);
