import StandardTag from 'components/common/atoms/StandardTag';

import { TransactionStatus } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React, { JSX } from 'react';


import mapTransactionStatusToColorAndLabel from './TransactionStatusTag.helpers';


function TransactionStatusTag({ transactionStatus, t, showTooltip }: {
  transactionStatus: TransactionStatus,
  t: TFunction,
  showTooltip?: boolean
}): JSX.Element {
    const tagData = mapTransactionStatusToColorAndLabel(transactionStatus, t);

    if (tagData) {
        return <StandardTag color={tagData.color} text={tagData.label} showTooltip={showTooltip} />;
    }
    return <>-</>;
}

export default TransactionStatusTag;
