import { isValidPhoneNumber } from 'utils/phone-number-tools';

import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function PhoneInput({
    id,
    name = 'phoneNumber',
    label,
    placeholder,
    isRequired,
    isRequiredMessage,
    additionalValidationRules = [],
    t,
}) {
    const defaultValidationRules = [
        {
            required: isRequired || true,
            message: isRequiredMessage || t('common:validationMsg.fieldMandatory'),
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value || isValidPhoneNumber(getFieldValue(name)) === true) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error(t('common:validationMsg.phoneInvalidFormat')));
            },
        }),
    ];

    const validationRules = [
        ...defaultValidationRules,
        ...(additionalValidationRules?.length > 0 ? additionalValidationRules : []),
    ];

    return (
        <div style={{ marginBottom: 10 }}>
            <Form.Item
                name={name}
                label={label || t('common:personalFields.phoneNumber.label')}
                rules={validationRules}
            >
                <Input
                    id={id}
                    placeholder={placeholder || t('common:personalFields.phoneNumber.placeholder')}
                />
            </Form.Item>
        </div>
    );
}

PhoneInput.propTypes = {
    t: PropTypes.func.isRequired,
    isRequiredMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    additionalValidationRules: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PhoneInput;
