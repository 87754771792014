/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { fetchIndustriesApi } from './index';


class IndustriesService {
    public fetchIndustries(): Promise<ApiResponse | ApiError> {
        return fetchIndustriesApi({ params: { limit: 1000, offset: 0 } });
    }
}

export default IndustriesService;
