import { Dispatch } from 'models/meta/action';
import { fetchAllAccounts, setSelectedAccountDetails, setSelectedBeneficiaryDetails } from 'redux/outgoing-transfer/actions';
import { outgoingTransferReducerName } from 'redux/outgoing-transfer/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AccountSelection from './AccountSelection.layout';
import { AccountSelectionDispatchProps, AccountSelectionStateProps } from './AccountSelection.types';


const mapStateToProps = (state: RootState): AccountSelectionStateProps => ({
    accountsList: state[outgoingTransferReducerName].accountsList,
    isLoadingAccounts: state[outgoingTransferReducerName].isLoadingAccounts,
    accountDetails: state[outgoingTransferReducerName].accountDetails,
    beneficiaryDetails: state[outgoingTransferReducerName].beneficiaryDetails,
});

const mapDispatchToProps = (dispatch: Dispatch): AccountSelectionDispatchProps => ({
    dispatchFetchAllAccounts: () => dispatch(fetchAllAccounts()),
    dispatchSetSelectedAccountDetails: (accountDetails) => dispatch(setSelectedAccountDetails(accountDetails)),
    dispatchSetSelectedBeneficiaryDetails: (beneficiaryDetails) => dispatch(setSelectedBeneficiaryDetails(beneficiaryDetails)),
});


const decorate = compose<ComponentType>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('beneficiaries'),
);

export default decorate(AccountSelection);

