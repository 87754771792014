import styled from 'styled-components';


export const StandardListSkeletonWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  gap: 20px;
  padding: 1rem;
  box-sizing: border-box;
  
  .ant-skeleton {
    height: 100%;
  }
  
  .ant-skeleton-content {
    height: 100%;
  }
  
  .ant-skeleton-paragraph {
    margin: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    li {
      height: auto !important;
      max-height: 30px;
      min-height: 10px;
      display: flex;
      flex: 1 1 100%;
    }
  }
`;


export default { StandardListSkeletonWrapper };
