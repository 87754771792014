import styled from 'styled-components';


const RoleTemplateDetailsDisplayWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0,1fr) minmax(0,1fr);
  grid-column-gap: 50px;
  width: 100%;
  white-space: nowrap;
`;


export default { RoleTemplateDetailsDisplayWrapper };
