import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { sortDirections, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import React from 'react';


function createPermissionsListColumns({
    searchQuery,
    t,
}) {
    const baseColumns = [
        {
            title: 'Permission Name',
            dataIndex: 'name',
            render: (text) => <StandardValue value={text} searchQuery={searchQuery} />,
            sortDirections,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'name'),
        },
        {
            title: 'Operation enabled',
            dataIndex: 'enabled',
            render: (text, record) => booleanAsIconsRenderer(record, 'enabled', t),
        },
    ];

    return [
        ...baseColumns,
    ];
}


export default createPermissionsListColumns;

