import FlagAndValue from 'components/common/atoms/FlagAndValue';
import TransactionScopeTag from 'components/common/atoms/tags/TransactionScopeTag';
import TransactionStatusTag from 'components/common/atoms/tags/TransactionStatusTag';
import TransactionTypeTag from 'components/common/atoms/tags/TransactionTypeTag';
import ClientRenderer from 'components/common/molecules/value-renderers/ClientRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import TransactionPartyRenderer from 'components/common/molecules/value-renderers/TransactionPartyRenderer';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createTransactionDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { QueryParams } from 'models/app/common';
import { LocationPathname, NavigationMeta } from 'models/app/navigation';
import { AccessControlContent } from 'models/domain/accessControl';
import { sortingOptions } from 'utils/table-columns-sorters';
import { isValidV4Uuid } from 'utils/validation-tools';

import { CurrencyListItem, TransactionListItem, TransactionStatus } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React from 'react';

import { createScopeFilters } from './TransactionsList.helpers';


const statusFilters = Object.keys(TransactionStatus).map((key) => ({ text: key, value: TransactionStatus[key] }));


const makeCurrencyFilters = (currencies?: CurrencyListItem[]) => (currencies || []).map((currency) => ({
    text: <FlagAndValue currencyCode={currency.short} text={currency.short} inlineMode />,
    value: currency.short,
}));

export const createTransactionsTableColumns = ({
    accessControl,
    hidePartnerColumn,
    enforcePendingOnly,
    hideCurrencyFilter,
    dispatchRequestNavigation,
    queryParams,
    currencies,
    t,
    transactionsFilters,
}: {
  accessControl: AccessControlContent,
  hidePartnerColumn?: boolean,
  enforcePendingOnly?: boolean,
  hideCurrencyFilter?: boolean,
  dispatchRequestNavigation: (locationPathname: LocationPathname, meta?: NavigationMeta) => void,
  queryParams: QueryParams,
  currencies?: CurrencyListItem[],
  t: TFunction,
  transactionsFilters: any,
}) => {
    const canViewTransactionDetails = accessControl.hasPermission(PN.TRANSACTIONS.READ_BY_ID);

    const idColumns = [
        {
            title: t('transactions:transactionFields.transactionReference.text'),
            align: 'center',
            dataIndex: 'transactionReference',
            render: (text) => (<StandardValue value={text} />),
        },
        {
            title: 'UUID',
            align: 'center',
            dataIndex: 'transactionUuid',
            render: (text) => (<StandardValue value={text} searchQuery={queryParams?.search} />),
        },
    ];

    const standardColumns = [
        {
            title: t('transactions:transactionFields.type.text'),
            dataIndex: 'transactionType',
            align: 'center',
            render: (text) => <TransactionTypeTag transactionType={text} showTooltip />,
            ...sortingOptions({ queryParams, fieldName: 'transactionType' }),
        },
        {
            title: t('transactions:transactionFields.scope.text'),
            dataIndex: 'transactionScope',
            align: 'center',
            render: (text, record: TransactionListItem) => <TransactionScopeTag transactionRecord={record} showTooltip />,
            filters: createScopeFilters(t, transactionsFilters, queryParams),
            filteredValue: queryParams?.transactionScope ?? undefined,
            filterMultiple: false, // currently, not supported on BE,
            ...sortingOptions({ queryParams, fieldName: 'transactionScope' }),
        },
        {
            title: 'Original amount',
            dataIndex: 'originalAmount',
            align: 'right',
            render: (amount) => <StandardValue value={amount} valueType={ValueTypes.AMOUNT} />,
        },
        {
            title: 'Original currency',
            dataIndex: 'originalCurrency',
            render: (text) => <FlagAndValue currencyCode={text} text={text} showTooltip />,
            filters: !hideCurrencyFilter ? makeCurrencyFilters(currencies) : undefined,
            filteredValue: queryParams?.originalCurrency ?? undefined,
            filterMultiple: false, // currently, not supported on BE,
            ...sortingOptions({ queryParams, fieldName: 'originalCurrency' }),
        },
        {
            title: 'Processed Amount',
            dataIndex: 'processedAmount',
            align: 'right',
            render: (amount) => <StandardValue value={amount} valueType={ValueTypes.AMOUNT} />,
        },
        {
            title: 'Processed Currency',
            dataIndex: 'processedCurrency',
            render: (text) => <FlagAndValue currencyCode={text} text={text} showTooltip />,
        },
    ];

    const senderColumn = [{
        title: 'Sender',
        align: 'center',
        dataIndex: '',
        render: (text, record: TransactionListItem) => <TransactionPartyRenderer transaction={record} t={t} />,
    }];

    const receiverColumn = [{
        title: 'Receiver',
        align: 'center',
        dataIndex: '',
        render: (text, record: TransactionListItem) => <TransactionPartyRenderer transaction={record} t={t} side="receiver" />,
    }];

    const partnerColumn = [{
        title: 'Partner',
        dataIndex: 'clientId',
        align: 'center',
        render: (text, record: TransactionListItem) => <ClientRenderer clientId={record.clientId} />,
        ...sortingOptions({ queryParams, fieldName: 'clientId' }),
    }];

    const statusColumn = [{
        title: t('transactions:transactionFields.status.text'),
        dataIndex: 'status',
        align: 'center',
        render: (status) => <TransactionStatusTag transactionStatus={status} showTooltip />,
        filters: statusFilters,
        filteredValue: queryParams?.status === 'All' ? undefined : queryParams?.status,
        filterMultiple: false, // currently, not supported on BE
        ...sortingOptions({ queryParams, fieldName: 'status' }),
    }];

    const createdAtColumn = [{
        title: t('transactions:transactionFields.createdAt.text'),
        align: 'center',
        dataIndex: 'createdAt',
        render: (date) => <StandardValue value={date} valueType={ValueTypes.DATE} />,
        ...sortingOptions({ queryParams, fieldName: 'createdAt' }),
    }];

    const actionsColumn = [{
        title: t('common:table.columnNames.actions'),
        dataIndex: '',
        align: 'center',
        render: (_, record: TransactionListItem) => {
            if (isValidV4Uuid(record.transactionUuid)) {
                return viewButtonRenderer({
                    record,
                    onClick: (event, record) => dispatchRequestNavigation(createTransactionDetailsPath(record.transactionUuid)),
                    tooltipContent: 'View transaction details',
                    dataTestIdSuffix: 'transaction',
                });
            }

            return null;
        },
    }];


    return [
        ...idColumns,
        ...senderColumn,
        ...receiverColumn,
        ...accessControl.isSuperAdmin && !hidePartnerColumn ? partnerColumn : [], // XXX allowed - client filter on global transactions list
        ...standardColumns,
        ...enforcePendingOnly ? [] : statusColumn,
        ...createdAtColumn,
        ...canViewTransactionDetails ? actionsColumn : [],
    ];
};


export default { createTransactionsTableColumns };

