import CommonAddressFrom from 'components/common/organisms/CommonAddressForm';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AddressCommonProps } from 'constants/AddressModel';
import { AllEntityTypes } from 'constants/ApplicationStateModel';

import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function EditCompanyAddressModal({
    initialValues,
    enhancedCurrentLocation,
    dispatchSetModalProps,
    dispatchUpdateAddress,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);

    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchUpdateAddress(
            initialValues?.address_id,
            values,
            AllEntityTypes.COMPANY,
            baseUrlWithoutTabsSlugs,
        );
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
                address_line_1: initialValues.address_line_1,
                address_line_2: initialValues.address_line_2,
                zip_code: initialValues.zip_code,
                city: initialValues.city,
                country: initialValues.country,
            }}
        >
            <CommonAddressFrom form={form} />
        </Form>
    );
}


EditCompanyAddressModal.propTypes = {
    initialValues: AddressCommonProps,
    enhancedCurrentLocation: PropTypes.string.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchUpdateAddress: PropTypes.func.isRequired,
};


export default EditCompanyAddressModal;

