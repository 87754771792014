import StandardListTable from 'components/common/organisms/StandardListTable';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultGroupMembersListQueryParams, defaultGroupNonMembersQueryParams } from 'redux/group-members/reducer';

import PropTypes from 'prop-types';
import React from 'react';


import createGroupMembersTableColumns from './createTableColumns';


function GroupMembersList({
    // OwnProps
    groupId,
    clientId,
    isNonMembersVersion,
    buttonsConfig,

    // mapped props
    dispatchFetchItemsList,
    dispatchClearItemsList,
    idsOfMembersCurrentlyBeingAddedToGroup,
    idsOfMembersSuccessfullyAddedToTheGroup,


    enhancedCurrentLocation,
    listData,

    dispatchSetQueryParams,
    t,

    dispatchAddMemberToGroup,
    dispatchRequestNavigation,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ...(clientId > 0 ? { clientId } : {}),
        ...(groupId > 0 ? { groupId } : {}),
    };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchItemsList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: isNonMembersVersion ? defaultGroupMembersListQueryParams : defaultGroupNonMembersQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList,
        dispatchClearItemsList,
        dispatchSetQueryParams,
    });


    // 4. context & permission dependent optional list filters & buttons
    const buttons = buttonsConfig || undefined;

    const columnsConfig = createGroupMembersTableColumns({
        t,
        idsOfMembersCurrentlyBeingAddedToGroup,
        idsOfMembersSuccessfullyAddedToTheGroup,
        groupId,
        isNonMembersVersion,
        clientId,
        queryParams: listData?.queryParams,
        dispatchAddMemberToGroup,
        dispatchRequestNavigation,
    });


    // 5. standard render component
    return (
        <StandardListTable
            rowKey="member_id"
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={columnsConfig}
            filtersButtonsConfig={buttons}
        />
    );
}

GroupMembersList.propTypes = {
    t: PropTypes.func.isRequired,
    dispatchFetchItemsList: PropTypes.func.isRequired,
    dispatchClearItemsList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchAddMemberToGroup: PropTypes.func,
    groupId: PropTypes.number.isRequired,
    idsOfMembersCurrentlyBeingAddedToGroup: PropTypes.arrayOf(PropTypes.number).isRequired,
    idsOfMembersSuccessfullyAddedToTheGroup: PropTypes.arrayOf(PropTypes.number).isRequired,

    // eslint-disable-next-line react/no-unused-prop-types
    isNonMembersVersion: PropTypes.bool, // XXX used in mapStateToProps
};


export default GroupMembersList;
