import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';


import AddMembersToGroup from './AddMembersToGroup.layout';


const decorate = compose(
    withTranslation('members'),
);

export default decorate(AddMembersToGroup);

