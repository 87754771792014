import TabSubHeading from 'components/common/atoms/TabSubHeading';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import PermissionFormRecord from 'components/common/organisms/forms-shared-parts/add-edit-role-form/SelectPermissionsStep/PermissionFormRecord';
import { searchCaseInsensitive } from 'utils/string-tools';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import Styled from './SelecctedPermissions.styled';


function SelectedPermissions({
    selectedPermissions,
    dispatchRemoveAllPermissionsFromRole,
    dispatchRemovePermissionsFromRole,
    t,
}) {
    const [searchQuery, setSearchQuery] = useState('');

    const onSearchInputChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };


    const filteredSelectedPermissions = useMemo(
        () => {
            if (searchQuery && searchQuery?.length > 0) {
                return (selectedPermissions || []).filter((permission) => searchCaseInsensitive(permission?.name, searchQuery));
            }
            return selectedPermissions;
        }, [selectedPermissions, searchQuery],
    );


    return (
        <Styled.SelectedPermissionsWrapper>
            <TabSubHeading
                text={t('SELECTED PERMISSIONS')}
                optionalContent={searchQuery?.length > 0 ? (
                    <StandardTooltip
                        placement="top"
                        title={'Search filter is active. Not all records are shown. Clear search filter to see the full list.'}
                    >
                        <Styled.InfoIcon />
                    </StandardTooltip>
                ) : null}
            />
            <Styled.SelectedPermissionsSearchWrapper>
                <Input
                    onChange={onSearchInputChange}
                    placeholder={t('Type to filter selected permissions')}
                    value={searchQuery}
                    allowClear
                />
                <Button
                    icon={<DeleteOutlined />}
                    danger
                    disabled={!filteredSelectedPermissions.length > 0}
                    onClick={() => (searchQuery?.length > 0
                        ? dispatchRemovePermissionsFromRole(filteredSelectedPermissions)
                        : dispatchRemoveAllPermissionsFromRole())}
                >
                    {
                        searchQuery?.length > 0
                            ? t('Remove all matching permissions')
                            : t('Remove all permissions')
                    }
                </Button>
            </Styled.SelectedPermissionsSearchWrapper>

            <Styled.SelectedPermissionsListOuterWrapper>
                <Styled.SelectedPermissionsListWrapper>
                    {filteredSelectedPermissions.map((permission) => (
                        <PermissionFormRecord
                            key={`permissions_${permission.name}`}
                            t={t}
                            searchQuery={searchQuery}
                            permission={permission}
                            dispatchRemovePermissionsFromRole={dispatchRemovePermissionsFromRole}
                        />
                    ))}
                </Styled.SelectedPermissionsListWrapper>
            </Styled.SelectedPermissionsListOuterWrapper>

        </Styled.SelectedPermissionsWrapper>
    );
}

SelectedPermissions.propTypes = {
    selectedPermissions: PropTypes.arrayOf(PropTypes.shape({})),
    dispatchRemoveAllPermissionsFromRole: PropTypes.func,
    dispatchRemovePermissionsFromRole: PropTypes.func,
    t: PropTypes.func,
};

export default SelectedPermissions;

