import FlagAndValue from 'components/common/atoms/FlagAndValue';
import AccountTypeTag from 'components/common/atoms/tags/AccountTypeTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';
import { filterOptions } from 'utils/filterOptions';
import { isUndefined } from 'utils/object-tools';
import { requiredRule } from 'utils/validation-tools';

import { AccountListItem } from '@manigo/manigo-domain-typings';
import { Select, Form } from 'antd';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import Styled from './AccountsDropdown.styled';
import { AccountsDropdownProps } from './AccountsDropdown.types';


function AccountsDropdown({
    name,
    value,
    items = [],
    isRequired,
    disabled,
    loading,
    label,
    placeholder = 'Select Account',

    disableZeroBalances = false,

    sortBy = 'availableBalance',
    sortDirection = 'desc',

    onChange,
    onSelect,

    t,
}:AccountsDropdownProps) {
    // TODO: Remove convert after related places are refactored
    const accountsOptions = useMemo(
        () => (orderBy(convertSnakeCaseKeysToCamelCase(items), [sortBy], [sortDirection])).map(({ id, currency, type, name, availableBalance }: AccountListItem) => (
            <Select.Option
                key={id}
                value={id}
                disabled={disableZeroBalances && availableBalance === 0}
                label={`${name?.trim().length > 0 ? name : type} ${currency} ${availableBalance}`}
            >
                <Styled.AccountOptionWrapper>
                    <div>
                        <Styled.Left>
                            <StandardValue value={name} useTooltipRootContainer />
                        </Styled.Left>

                        <AccountTypeTag accountType={type} />

                        <Styled.Right>
                            <StandardValue value={availableBalance} valueType={ValueTypes.AMOUNT} showTooltip useTooltipRootContainer />
                            <FlagAndValue inlineMode valueFirst currencyCode={currency} text={currency} />
                        </Styled.Right>
                    </div>


                </Styled.AccountOptionWrapper>
            </Select.Option>
        )),
        [items],
    );

    const select = (
        <Select
            showSearch={isUndefined(value)}
            allowClear
            filterOption={filterOptions}
            loading={loading}
            notFoundContent={loading ? t('common:select.loadingText') : t('common:select.noDataText')}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onSelect={onSelect}
            disabled={disabled}
        >
            {accountsOptions}
        </Select>
    );


    return name ? (
        <Form.Item
            name={name}
            label={label}
            rules={isRequired ? requiredRule(t) : []}
        >
            {select}
        </Form.Item>
    ) : select;
}


export default withTranslation()(AccountsDropdown);
