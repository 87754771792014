import StandardTag from 'components/common/atoms/StandardTag';
import { ClientStatuses, ClientStatusProp } from 'constants/ClientModel';

import PropTypes from 'prop-types';
import React from 'react';


const createHandlers = (active) => {
    const handlers = [
        {
            predicate: () => active === ClientStatuses.ACTIVE,
            handler: () => ({ color: 'green', text: ClientStatuses.ACTIVE }),
        },
        {
            predicate: () => active === ClientStatuses.INACTIVE,
            handler: () => ({ color: 'red', text: ClientStatuses.INACTIVE }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: 'Unknown' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function ClientStatusTag({ active, showTooltip = false }) {
    const tagData = createHandlers(active);

    return (
        <StandardTag
            color={tagData.color}
            text={tagData.text}
            showTooltip={showTooltip}
        />
    );
}

ClientStatusTag.propTypes = {
    active: ClientStatusProp,
    showTooltip: PropTypes.bool,
};

export default ClientStatusTag;

