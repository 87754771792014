import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_MANUAL_FEES_LIST,
    FETCH_MANUAL_FEES_LIST,
    FETCH_MANUAL_FEES_LIST_FAILURE,
    FETCH_MANUAL_FEES_LIST_SUCCESS,
    OPEN_TRIGGER_MANUAL_FEE_MODAL,
    TRIGGER_MANUAL_FEE,
    TRIGGER_MANUAL_FEE_FAILURE,
    TRIGGER_MANUAL_FEE_SUCCESS,
} from './actions.types';


export const fetchManualFeesList = createAction(FETCH_MANUAL_FEES_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchManualFeesListSuccess = createAction(FETCH_MANUAL_FEES_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchManualFeesListFailure = createAction(FETCH_MANUAL_FEES_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const clearManualFeesList = createAction(CLEAR_MANUAL_FEES_LIST, (locationPathname) => ({ payload: { locationPathname } }));

export const openTriggerManualFeeModal = createAction(OPEN_TRIGGER_MANUAL_FEE_MODAL, (queryParams) => ({ payload: { queryParams } }));

export const triggerManualFee = createAction(TRIGGER_MANUAL_FEE, (requestPayload) => ({ payload: { queryParams: requestPayload } }));
export const triggerManualFeeSuccess = createAction(TRIGGER_MANUAL_FEE_SUCCESS);
export const triggerManualFeeFailure = createAction(TRIGGER_MANUAL_FEE_FAILURE);
