import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { createGroupsDetailsTabsConfig } from 'components/pages/authorised/GroupDetailsPage/GroupDetailsPage.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps } from 'constants/ApplicationStateModel';
import { GroupListItemProps } from 'constants/GroupModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';


import GroupInfo from './GroupInfo';


function GroupDetails({
    entityDetailsData,
    enhancedCurrentLocation,
    accessControl,
    assignedPromotionsListData,

    t,
    dispatchRequestNavigation,

    dispatchFetchGroupDetails,
    dispatchClearGroupDetails,
    dispatchSetQueryParams,

    dispatchOpenEditGroupNameModal,
    dispatchOpenAssignPromotionsToGroupModal,
    dispatchOpenDeleteGroupModal,
}) {
    const { groupId, clientId } = useParams();
    const fixedClientId = Number(clientId);
    const fixedGroupId = Number(groupId);

    // XXX
    const baseURLSegmentsCount = 4; // e.g. /(1) group/(2) GROUP_ID /(3) client/ (4) CLIENT_ID/(5) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const contextEnforcedQueryParams = { groupId: fixedGroupId };

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,
        baseUrlWithoutTabsSlugs,
        enhancedCurrentLocation,

        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchGroupDetails,
        dispatchClearEntityDetails: dispatchClearGroupDetails,
        dispatchSetQueryParams,
    });

    const existingPromotionsUuids = useMemo(
        () => (assignedPromotionsListData?.items || []).map((promotion) => promotion.ext_id),
        [assignedPromotionsListData],
    );


    const tabsConfig = useMemo(
        () => (fixedGroupId > 0
    && fixedClientId > 0
    && enhancedCurrentLocation
            ? createGroupsDetailsTabsConfig({
                fixedGroupId,
                fixedClientId,
                accessControl,
                enhancedCurrentLocation,
                dispatchOpenAssignPromotionsToGroupModal,
                dispatchRequestNavigation,
                existingPromotionsUuids,
                t,
            })
            : undefined),
        [fixedGroupId, fixedClientId, enhancedCurrentLocation, assignedPromotionsListData],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('groups:detailsScreenTitle', { groupId })}
            baseURLSegmentsCount={baseURLSegmentsCount}
            tabsConfig={tabsConfig}
            entityClientId={fixedClientId}
        >
            <GroupInfo
                t={t}
                enhancedCurrentLocation={enhancedCurrentLocation}
                areDetailsReady={isDetailsPageInitialStateDefined}
                isBusy={entityDetailsData?.isLoadingDetails}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                onRefresh={() => dispatchFetchGroupDetails({ ...contextEnforcedQueryParams }, baseUrlWithoutTabsSlugs)}
                dispatchOpenEditGroupNameModal={dispatchOpenEditGroupNameModal}
                dispatchOpenDeleteGroupModal={dispatchOpenDeleteGroupModal}
                groupDetails={entityDetailsData?.entityDetails}
                clientId={fixedClientId}
                groupId={fixedGroupId}
            />

        </StandardEntityDetailsWithTabs>
    );
}

GroupDetails.propTypes = {
    accessControl: AccessControlProps,
    assignedPromotionsListData: PropTypes.shape({}),
    entityDetailsData: PropTypes.shape({
        entityDetails: GroupListItemProps,
        isLoadingDetails: PropTypes.bool,
        queryParams: PropTypes.shape({}),
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),

    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchFetchGroupDetails: PropTypes.func.isRequired,
    dispatchOpenEditGroupNameModal: PropTypes.func.isRequired,
    dispatchOpenAssignPromotionsToGroupModal: PropTypes.func.isRequired,
    dispatchOpenDeleteGroupModal: PropTypes.func.isRequired,
    dispatchClearGroupDetails: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default GroupDetails;
