import AccountsList from 'components/common/templates/lists/AccountsList';
import ApplicationsList from 'components/common/templates/lists/ApplicationsList';
import BeneficiariesList from 'components/common/templates/lists/BeneficiariesList';
import CompaniesList from 'components/common/templates/lists/CompaniesList';
import ClientRiskDetails from 'components/common/templates/lists/ComplianceTab/RiskDetailsSubTab/ClientRiskDetails';
import FailedWebhooks from 'components/common/templates/lists/FailedWebhooks';
import FeesTab from 'components/common/templates/lists/FeesList';
import GroupsTab from 'components/common/templates/lists/GroupsList';
import ManualFeesList from 'components/common/templates/lists/ManualFeesList';
import MarketingPreferencesList from 'components/common/templates/lists/MarketingPreferencesList';
import MembersList from 'components/common/templates/lists/MembersList';
import OutgoingTransfer from 'components/common/templates/lists/OutgoingTransfer';
import PromotionsList from 'components/common/templates/lists/PromotionsList';
import RiskScoreHistoryList from 'components/common/templates/lists/RiskScoreHistoryList';
import TopupCardsList from 'components/common/templates/lists/TopupCardsList';
import TransactionsList from 'components/common/templates/lists/TransactionsList';
import Webhooks from 'components/common/templates/lists/Webhooks';
import {
    accountsRouteSegmentPath,
    announcementRouteSegmentPath,
    apiKeysRouteSegmentPath, applicationsRouteSegmentPath,
    automaticFeesRouteSegmentPath,
    beneficiariesRouteSegmentPath,
    companiesRouteSegmentPath,
    complianceRouteSegmentPath,
    feesRouteSegmentPath,
    groupsRouteSegmentPath,
    importMembersRouteSegmentPath,
    manualFeesRouteSegmentPath,
    marketingPreferencesRouteSegmentPath,
    membersRouteSegmentPath,
    newTransactionRouteSegmentPath, pendingTransactionsRouteSegmentPath,
    productsRouteSegmentPath,
    promotionsRouteSegmentPath, riskScoreHistoryRouteSegmentPath,
    riskScoreRouteSegmentPath, topupCardsRouteSegmentPath,
    webhooksFailedRouteSegmentPath, webhooksManagementRouteSegmentPath,
    webhooksRouteSegmentPath,
    withdrawRouteSegmentPath,
} from 'config/routes';
import { AccountOwnerTypes } from 'constants/AccountModel';
import { KycKybEntityTypes } from 'constants/KycKybModel';
import { permissionSetNewTransaction, PN } from 'constants/PermissionsModel';
import { ProductTypes } from 'constants/ProductModel';
import { enumValueToReadableString } from 'utils/string-tools';

import {
    BankOutlined,
    BulbOutlined,
    DatabaseOutlined,
    FolderOutlined,
    PercentageOutlined,
    ShopOutlined,
    StarOutlined,
    TeamOutlined,
    TransactionOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    DollarOutlined,
    CheckCircleOutlined,
    RadarChartOutlined,
    KeyOutlined, FireOutlined,
    ApartmentOutlined, CreditCardOutlined,
} from '@ant-design/icons';
import React from 'react';

import Announcement from './Tabs/Announcement';
import ApiKeysList from './Tabs/ApiKeys';
import MembersMigration from './Tabs/MembersMigration';
import NewTransaction from './Tabs/NewTransaction';
import Products from './Tabs/Products';


const createWebhooksSubTabsConfig = ({
    t,
    accessControl,
    clientId,
    parentActiveMainTabSegmentURL,
}) => [
    ...(accessControl.hasPermission(PN.WEBHOOKS.READ_ALL)
        ? [{
            icon: <RadarChartOutlined />,
            label: t('webhooks:subTabNames.management'),
            hasList: true,
            isDefault: true,
            parentActiveMainTabSegmentURL,
            activeSubTabSegmentURL: webhooksManagementRouteSegmentPath,
            content: <Webhooks clientId={clientId} />,
        }]
        : []),

    ...(accessControl.hasPermission(PN.WEBHOOKS.READ_ALL_FAILED)
        ? [{
            icon: <FireOutlined />,
            label: t('webhooks:subTabNames.failed'),
            hasList: true,
            parentActiveMainTabSegmentURL,
            activeSubTabSegmentURL: webhooksFailedRouteSegmentPath,
            content: <FailedWebhooks clientId={clientId} />,
        }]
        : []),
];


const createClientComplianceSubTabsConfig = ({
    t,
    accessControl,
    clientId,
    parentActiveMainTabSegmentURL,
}) => [
    {
        label: t('common:tabsNames.riskScore'),
        isDefault: true,
        activeSubTabSegmentURL: riskScoreRouteSegmentPath,
        parentActiveMainTabSegmentURL,
        content: <ClientRiskDetails clientId={clientId} />,
    },
    ...(accessControl.hasPermission(PN.CLIENTS.READ_RISK_DETAILS)
        ? [
            {
                label: t('common:tabsNames.riskScoreHistory'),
                isDefault: true,
                activeSubTabSegmentURL: riskScoreHistoryRouteSegmentPath,
                parentActiveMainTabSegmentURL,
                content: <RiskScoreHistoryList clientId={clientId} />,
            }]
        : []),

    ...(accessControl.hasPermission(PN.TRANSACTIONS.AUDIT_PAYMENT)
        ? [{
            label: t('common:tabsNames.pendingTransactions'),
            hasList: true,
            activeSubTabSegmentURL: pendingTransactionsRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: (
                <TransactionsList
                    key={`${KycKybEntityTypes.CLIENT}-pending`}
                    clientId={clientId}
                    enforcePendingOnly
                />
            ),
        }]
        : []),
];
const createFeesSubTabsConfig = ({
    accessControl,
    t,
    clientId,
    clientExtId,
    parentActiveMainTabSegmentURL,
    dispatchOpenChargeCustomFeeModal,
    dispatchOpenTriggerManualFeeModal,
}) => [
    ...(accessControl.hasPermission(PN.FEES.READ_LIST)
        ? [{
            label: t('common:tabsNames.automaticFees'),
            hasList: true,
            isDefault: true,
            activeSubTabSegmentURL: automaticFeesRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: (
                <FeesTab
                    clientId={clientId}
                />
            ),
        }] : []),

    ...(accessControl.hasPermission(PN.FEES.READ_MANUAL_FEES) && clientExtId
        ? [{
            label: t('common:tabsNames.manualFees'),
            hasList: true,
            activeSubTabSegmentURL: manualFeesRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: (
                <ManualFeesList
                    clientExtId={clientExtId}
                    dispatchOpenChargeCustomFeeModal={accessControl.hasPermission(PN.FEES.CHARGE_CUSTOM) ? dispatchOpenChargeCustomFeeModal : undefined}
                    dispatchOpenTriggerManualFeeModal={accessControl.hasPermission(PN.FEES.TRIGGER_MANUAL_FEE) ? dispatchOpenTriggerManualFeeModal : undefined}
                />
            ),
        }] : []),
];

// TODO reduce complexity
// eslint-disable-next-line complexity
export const createClientDetailsTabsConfig = ({
    clientId,
    entityDetails,
    dispatchOpenAddAccountModal,
    dispatchOpenChargeCustomFeeModal,
    dispatchOpenTriggerManualFeeModal,
    accessControl,
    returnPath,
    currentLocation,
    t,
}) => {
    const getClientName = (clientDetails) => `${clientDetails?.company_dba_name
  || clientDetails?.company_legal_name} ${enumValueToReadableString(clientDetails?.company_business_type)}`;

    const clientName = getClientName(entityDetails);

    return [
        ...(accessControl.hasPermission(PN.ACCOUNTS.READ)
            ? [
                {
                    icon: <BankOutlined />,
                    label: t('common:tabsNames.accounts'),
                    hasList: true,
                    activeMainTabSegmentURL: accountsRouteSegmentPath,
                    isDefault: true,
                    content: (
                        <AccountsList
                            ownerId={clientId}
                            isInReadOnlyMode={false}
                            ownerType={AccountOwnerTypes.CLIENT}
                            onAddAccount={accessControl.hasPermission(PN.ACCOUNTS.CREATE)
                                ? () => dispatchOpenAddAccountModal({
                                    title: t('accounts:modals.addAccount.titles.client'),
                                    clientId,
                                    allowedAccountTypes: [ProductTypes.Base],
                                    setName: false,
                                }, currentLocation)
                                : undefined}
                        />),
                },
            ] : []),

        ...(accessControl.hasPermission(PN.MEMBERS.READ)
            ? [
                {
                    icon: <TeamOutlined />,
                    label: t('common:tabsNames.members'),
                    activeMainTabSegmentURL: membersRouteSegmentPath,
                    hasList: true,
                    content: <MembersList enforcedClientId={clientId} />,
                },
            ] : []),

        ...(accessControl.hasPermission(PN.COMPANIES.READ_BY_CLIENT)
            ? [
                {
                    icon: <ShopOutlined />,
                    label: t('common:tabsNames.companies'),
                    activeMainTabSegmentURL: companiesRouteSegmentPath,
                    hasList: true,
                    content: <CompaniesList enforcedClientId={clientId} />,
                },
            ] : []),

        {
            icon: <BulbOutlined />,
            label: t('common:tabsNames.announcement'),
            activeMainTabSegmentURL: announcementRouteSegmentPath,
            content: <Announcement t={t} enforcedClientId={clientId} />,
        },

        ...(accessControl.clientConfig?.generalSettings?.beneficiariesEnabled
    && accessControl.hasPermission(PN.BENEFICIARIES.SEARCH)
            ? [{
                icon: <UserOutlined />,
                label: t('common:tabsNames.beneficiaries'),
                hasList: true,
                activeMainTabSegmentURL: beneficiariesRouteSegmentPath,
                content: (
                    <BeneficiariesList ownerContext={{
                        clientId,
                        ownerName: `${clientName} (Partner)`,
                        returnPath,
                        isInReadOnlyMode: false,
                    }}
                    />),
            }] : []),

        ...(accessControl.clientConfig?.generalSettings?.beneficiariesEnabled
      && accessControl.hasPermission(PN.TRANSACTIONS.MAKE_PAYMENT)
            ? [{
                icon: <DollarOutlined />,
                label: t('common:tabsNames.withdraw'),
                activeMainTabSegmentURL: withdrawRouteSegmentPath,
                content: <OutgoingTransfer clientId={clientId} />,
            }] : []),

        ...(accessControl.hasPermission(PN.CLIENTS.READ_PRODUCTS)
            ? [{
                icon: <DatabaseOutlined />,
                hasList: true,
                activeMainTabSegmentURL: productsRouteSegmentPath,
                label: t('common:tabsNames.products'),
                content: <Products clientId={clientId} />,
            }] : []),

        ...(accessControl.hasPermissions(permissionSetNewTransaction)
            ? [{
                icon: <TransactionOutlined />,
                label: t('common:tabsNames.newTransaction'),
                activeMainTabSegmentURL: newTransactionRouteSegmentPath,
                content: <NewTransaction clientId={clientId} />,
            }] : []),

        ...(accessControl.clientConfig?.generalSettings?.membersMigrationEnabled
    && accessControl.hasPermission(PN.CLIENTS.MIGRATIONS.GET)
            ? [{
                icon: <UsergroupAddOutlined />,
                label: t('common:tabsNames.importMembers'),
                activeMainTabSegmentURL: importMembersRouteSegmentPath,
                hasList: true,
                content: <MembersMigration clientId={clientId} />,
            }] : []),

        ...(accessControl.hasOneOfPermissions([
            PN.CLIENTS.READ_RISK_DETAILS,
            PN.TRANSACTIONS.AUDIT_PAYMENT,
        ])
            ? [{
                icon: <CheckCircleOutlined />,
                label: t('common:tabsNames.compliance'),
                activeMainTabSegmentURL: complianceRouteSegmentPath,
                // XXX no 'content' but 'subTabsConfig'
                subTabsConfig: createClientComplianceSubTabsConfig({
                    accessControl,
                    clientId,
                    parentActiveMainTabSegmentURL: complianceRouteSegmentPath,
                    t,
                }),
            }]
            : []),

        ...(accessControl.hasOneOfPermissions([
            PN.WEBHOOKS.READ_ALL,
            PN.WEBHOOKS.READ_ALL_FAILED,
        ])
            ? [{
                icon: <RadarChartOutlined />,
                label: t('common:tabsNames.webhooks'),
                activeMainTabSegmentURL: webhooksRouteSegmentPath,
                // XXX no 'content' but 'subTabsConfig'
                subTabsConfig: createWebhooksSubTabsConfig({
                    accessControl,
                    t,
                    clientId,
                    parentActiveMainTabSegmentURL: webhooksRouteSegmentPath,
                }),
            }]
            : []),

        ...(accessControl.clientConfig?.generalSettings?.apiKeyManagement
    && accessControl.hasPermission(PN.API_KEY.READ_ALL)
            ? [{
                icon: <KeyOutlined />,
                hasList: true,
                activeMainTabSegmentURL: apiKeysRouteSegmentPath,
                label: t('apiKeys:tabName.text'),
                content: <ApiKeysList clientId={clientId} />,
            }] : []),


        ...(accessControl.clientConfig?.generalSettings?.groupsEnabled ? [
            ...(accessControl.hasPermission(PN.GROUPS.READ_LIST) ? [
                {
                    icon: <FolderOutlined />,
                    label: t('common:tabsNames.groups'),
                    activeMainTabSegmentURL: groupsRouteSegmentPath,
                    hasList: true,
                    content: (
                        <GroupsTab
                            clientId={clientId}
                            showAddButton
                        />
                    ),
                }] : []),

            ...(accessControl.hasPermission(PN.PROMOTIONS.READ_LIST) ? [{
                icon: <StarOutlined />,
                label: t('common:tabsNames.promotions'),
                activeMainTabSegmentURL: promotionsRouteSegmentPath,
                hasList: true,
                content: <PromotionsList clientId={clientId} />,
            }] : []),

            ...(accessControl.hasPermission(PN.FEES.READ_LIST) ? [{
                icon: <PercentageOutlined />,
                label: t('common:tabsNames.fees'),
                activeMainTabSegmentURL: feesRouteSegmentPath,
                subTabsConfig: createFeesSubTabsConfig({
                    t,
                    accessControl,
                    clientId,
                    clientExtId: entityDetails?.ext_client_id,
                    parentActiveMainTabSegmentURL: feesRouteSegmentPath,
                    dispatchOpenChargeCustomFeeModal,
                    dispatchOpenTriggerManualFeeModal,
                }),
            }] : []),
        ] : []),

        ...(accessControl.isSuperAdmin ? [{ // XXX allowed - only for super admins
            icon: <ApartmentOutlined />,
            label: t('common:tabsNames.applications'),
            activeMainTabSegmentURL: applicationsRouteSegmentPath,
            hasList: true,
            content: <ApplicationsList clientId={clientId} />,
        }] : []),

        ...(accessControl.hasPermission(PN.TOPUP.GET_ALL_CARDS)
            ? [{
                icon: <CreditCardOutlined />,
                label: t('common:tabsNames.topUpCards'),
                hasList: true,
                activeMainTabSegmentURL: topupCardsRouteSegmentPath,
                content: (
                    <TopupCardsList
                        clientId={clientId}
                        showOwnerIdsColumns
                    />
                ),
            }] : []),
        ...(accessControl.hasPermission(PN.CONFIG.MEMBER_MARKETING.GET_ALL)
            ? [
                {
                    icon: <ShopOutlined />,
                    label: t('common:tabsNames.marketingPreferences'),
                    activeMainTabSegmentURL: marketingPreferencesRouteSegmentPath,
                    hasList: true,
                    content: <MarketingPreferencesList clientId={clientId} />,
                },
            ] : []),
    ];
};


export default { createClientDetailsTabsConfig };
