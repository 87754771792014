/* eslint-disable import/no-unresolved */
// xxx origin: https://uxwing.com/faqs-icon/
// License: All icons are free to use any personal and commercial projects without any attribution or credit.
import /* webpackPreload: true */ /* webpackChunkName: "authentication-icon" */ authentication
    from 'assets/images/icons/authentication-icon.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "cookies-icon" */ cookiesIcon
    from 'assets/images/icons/cookies-icon.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "email-sent-icon" */ emailSent
    from 'assets/images/icons/email-mail-sent-icon.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "encryption-icon" */ encryption
    from 'assets/images/icons/encryption-icon.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "faq-bubble-icon" */ faqIcon
    from 'assets/images/icons/faq-icon.svg?url';
// xxx https://uxwing.com/injury-icon
// License: All icons are free to use any personal and commercial projects without any attribution or credit.
import /* webpackPreload: true */ /* webpackChunkName: "injury-icon" */ injuredManIcon
    from 'assets/images/icons/injury-icon.svg?url';
// xxx https://uxwing.com/cookies-icon
// License: All icons are free to use any personal and commercial projects without any attribution or credit.
// https://uxwing.com/shield-lock-black-icon
// License: All icons are free to use any personal and commercial projects without any attribution or credit.
import /* webpackPreload: true */ /* webpackChunkName: "shield-lock-icon" */ shieldLock
    from 'assets/images/icons/shield-lock-icon.svg?url';
// https://uxwing.com/encryption-icon
// License: All icons are free to use any personal and commercial projects without any attribution or credit.


// https://uxwing.com/authentication-icon
// License: All icons are free to use any personal and commercial projects without any attribution or credit.

// https://uxwing.com/email-mail-sent-icon/
// License: All icons are free to use any personal and commercial projects without any attribution or credit.


const svgIcons = {
    faqIcon,
    injuredManIcon,
    cookiesIcon,
    shieldLock,
    encryption,
    authentication,
    emailSent,
};


export default svgIcons;
