import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { createMemberDetailsTabsConfig } from 'components/pages/authorised/MemberDetails/MemberDetails.helpers';
import useMemberActionChecks from 'hooks/useMemberActionChecks';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';
import { AllEntityTypes } from 'models/app/applicationState';

import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';


import { MemberDetailsProps } from './MemberDetails.types';
import PersonalDetails from './PersonalDetails';


function MemberDetails({
    accessControl,

    entityDetailsData,
    enhancedCurrentLocation,

    dispatchFetchMemberDetails,
    dispatchSetQueryParams,
    dispatchClearMemberDetails,
    dispatchOpenAddAccountModal,
    dispatchSetReadOnlyMode,
    dispatchClearReadOnlyMode,

    t,
}:MemberDetailsProps) {
    const { memberId } = useParams();
    const fixedId = Number(memberId);

    const entityDetails = entityDetailsData?.entityDetails;
    const memberStatusActionChecks = useMemberActionChecks({ memberDetails: entityDetails, accessControl });

    useEffect(() => () => {
        dispatchClearReadOnlyMode();
    }, []);

    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const contextEnforcedQueryParams = { memberId: fixedId };

    useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchMemberDetails,
        dispatchClearEntityDetails: dispatchClearMemberDetails,
        dispatchSetQueryParams,
    });

    const isInReadOnlyMode = useMemo(
        () => accessControl.isEntityReadOnly({
            entityType: AllEntityTypes.MEMBER,
            entityStatus: entityDetails?.memberStatus,
        }),
        [entityDetails, accessControl],
    );


    useEffect(() => {
        if (entityDetails?.memberStatus) {
            if (isInReadOnlyMode) {
                dispatchSetReadOnlyMode({
                    entityType: AllEntityTypes.MEMBER,
                    entityId: fixedId,
                    entityStatus: entityDetails.memberStatus,
                    readOnlyStateReason: isInReadOnlyMode,
                    otherDetails: { entityName: `${entityDetails?.memberFirstName} ${entityDetails?.memberLastName}` },
                });
            } else {
                dispatchClearReadOnlyMode();
            }
        }
    }, [entityDetails, isInReadOnlyMode]);


    const tabsConfig = useMemo(
        () => (fixedId
    && entityDetails?.clientId > 0
    && enhancedCurrentLocation
            ? createMemberDetailsTabsConfig({
                fixedId,
                isInReadOnlyMode,
                isLoadingDetails: entityDetailsData?.isLoadingDetails,
                entityDetails,
                accessControl,
                dispatchOpenAddAccountModal,
                returnPath: enhancedCurrentLocation?.pathname,
                currentLocation: enhancedCurrentLocation?.pathname,
                baseUrlWithoutTabsSlugs,
                memberStatusActionChecks,
                t,
            }) : undefined),
        [fixedId, accessControl, entityDetailsData?.entityDetails, dispatchOpenAddAccountModal, t],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t(`Member #${fixedId} Details`)}
            tabsConfig={tabsConfig}
            baseURLSegmentsCount={2}
            entityClientId={entityDetails?.clientId}
        >
            <PersonalDetails
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                isInReadOnlyMode={isInReadOnlyMode}
                memberId={fixedId}
                memberDetails={entityDetails}
                isLoadingMemberDetails={entityDetailsData?.isLoadingDetails}
                dispatchFetchMemberDetails={dispatchFetchMemberDetails}
            />
        </StandardEntityDetailsWithTabs>
    );
}

export default MemberDetails;
