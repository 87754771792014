import { showSuccessToast } from 'redux/application/actions';
import {
    START_OFFBOARDING, startOffboardingSuccess, startOffboardingFailure,
    STOP_OFFBOARDING, stopOffboardingSuccess, stopOffboardingFailure, FINALISE_OFFBOARDING, finaliseOffboardingSuccess,
} from 'redux/offboarding/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import commonRefreshActions from './epics.helpers';


export const onStartOffboarding = (action$, state$, { offboarding, i18n }) => action$.pipe(
    ofType(START_OFFBOARDING),
    switchMap(({ payload }) => from(offboarding.startOffboarding(payload.requestPayload)).pipe(
        switchMap((response) => of(
            ...commonRefreshActions(payload, state$.value),
            showSuccessToast(i18n.t('offboarding:actionMessages.startOffboardingSuccess')),
            startOffboardingSuccess(response.data),
        )),
        catchError(() => of(startOffboardingFailure())),
    )),
);

export const onStopOffboarding = (action$, state$, { offboarding, i18n }) => action$.pipe(
    ofType(STOP_OFFBOARDING),
    switchMap(({ payload }) => from(offboarding.stopOffboarding(payload.requestPayload)).pipe(
        switchMap((response) => of(
            ...commonRefreshActions(payload, state$.value),
            showSuccessToast(i18n.t('offboarding:actionMessages.stopOffboardingSuccess')),
            stopOffboardingSuccess(response.data),
        )),
        catchError(() => of(stopOffboardingFailure())),
    )),
);

export const onFinaliseOffboarding = (action$, state$, { offboarding, i18n }) => action$.pipe(
    ofType(FINALISE_OFFBOARDING),
    switchMap(({ payload }) => from(offboarding.finaliseClosure(payload.requestPayload)).pipe(
        switchMap((response) => of(
            ...commonRefreshActions(payload, state$.value),
            showSuccessToast(i18n.t('offboarding:actionMessages.finaliseOffboardingSuccess')),
            finaliseOffboardingSuccess(response.data),
        )),
        catchError(() => of(finaliseOffboardingSuccess())),
    )),
);
export default [
    onStartOffboarding,
    onStopOffboarding,
    onFinaliseOffboarding,
];
