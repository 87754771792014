import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import StandardLink from './StandardMultiValue.layout';


const decorate = compose(
    withTranslation('common'),
);

export default decorate(StandardLink);
