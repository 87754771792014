import FailedWebhookHistoryList from 'components/common/templates/lists/FailedWebhookHistoryList';
import {
    dataDetailsRouteSegmentPath,
    errorDetailsRouteSegmentPath,
    errorsHistoryRouteSegmentPath,
    headersDetailsRouteSegmentPath,
} from 'config/routes';
import { PN } from 'constants/PermissionsModel';
import { AccessControlContent } from 'models/domain/accessControl';

import { HistoryOutlined, BugOutlined, DatabaseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { FailedWebhook } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React from 'react';
import JSONPretty from 'react-json-pretty';


export const createFailedWebhookDetailsTabsConfig = ({
    accessControl,
    failedWebhook,
    t,
}: {
  t: TFunction,
  accessControl: AccessControlContent,
  failedWebhook?: FailedWebhook
}) => [
    {
        icon: <BugOutlined />,
        label: t('webhooks:failedWebhookDetails.tabs.errorDetails'),
        activeMainTabSegmentURL: errorDetailsRouteSegmentPath,
        content: (
            <div>
                <JSONPretty
                    keyStyle={'font-weight: bold;'}
                    mainStyle={'white-space: pre-wrap'}
                    id="json-pretty-failed-webhook-error-details"
                    data={failedWebhook?.errorDetails}
                />
            </div>
        ),
    },
    {
        icon: <InfoCircleOutlined />,
        label: t('webhooks:failedWebhookDetails.tabs.webhookData'),
        activeMainTabSegmentURL: dataDetailsRouteSegmentPath,
        content: (
            <div>
                <JSONPretty
                    keyStyle={'font-weight: bold;'}
                    id="json-pretty-failed-webhook-error-data"
                    data={failedWebhook?.data}
                />
            </div>
        ),
    },

    ...(failedWebhook?.headers ? [
        {
            icon: <DatabaseOutlined />,
            label: t('webhooks:failedWebhookDetails.tabs.headers'),
            activeMainTabSegmentURL: headersDetailsRouteSegmentPath,
            content: (
                <div>
                    <JSONPretty
                        keyStyle={'font-weight: bold;'}
                        id="json-pretty-failed-webhook-error-headers"
                        data={failedWebhook?.headers}
                    />
                </div>
            ),
        },
    ] : []),

    ...(accessControl.hasPermission(PN.WEBHOOKS.FAILED.HISTORY) ? [{
        icon: <HistoryOutlined />,
        label: t('webhooks:failedWebhookDetails.tabs.errorsHistory'),
        hasList: true,
        activeMainTabSegmentURL: errorsHistoryRouteSegmentPath,
        content: (
            <FailedWebhookHistoryList failedWebhookUuid={failedWebhook?.uuid} />
        ),
    }] : []),

];


export default { createFailedWebhookDetailsTabsConfig };
