import CommonSection from 'components/common/organisms/CommonSection';

import { TransactionDetails } from '@manigo/manigo-domain-typings';
import { Row } from 'antd';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';


import {
    createCardTransactionCol1Fields,
    createCardTransactionCol2Fields,
    createCardTransactionTmpFields,
    extractRelevantDataFromIsoMessage,
} from './CardTransactionInfo.helpers';


function CardTransactionInfo({
    transactionDetails,
    t,
}: { transactionDetails: TransactionDetails, t: TFunction }) {
    const parsedData = useMemo(
        () => extractRelevantDataFromIsoMessage(transactionDetails?.cardTransactionData?.request?.isoMsg),
        [transactionDetails],
    );

    const cardTransactionTmpFields = useMemo(
        () => createCardTransactionTmpFields(transactionDetails?.cardTransactionData, t),
        [transactionDetails],
    );

    const cardTransactionCol1Fields = useMemo(
        () => createCardTransactionCol1Fields(parsedData, t),
        [parsedData],
    );


    const cardTransactionCol2Fields = useMemo(
        () => createCardTransactionCol2Fields(parsedData, t),
        [parsedData],
    );

    return (
        <div>
            <Row gutter={24}>
                <CommonSection
                    sectionLabel={t('')}
                    fieldsConfig={cardTransactionTmpFields}
                />

                {cardTransactionCol1Fields?.length > 0 ? (
                    <CommonSection
                        sectionLabel={t('')}
                        fieldsConfig={cardTransactionCol1Fields}
                    />
                ) : undefined}

                {cardTransactionCol2Fields?.length > 0 ? (
                    <CommonSection
                        sectionLabel={t('')}
                        fieldsConfig={cardTransactionCol2Fields}
                    />
                ) : undefined}
            </Row>
        </div>
    );
}

export default CardTransactionInfo;
