import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import styled from 'styled-components';


const CurrencyOptionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const CurrencyOptionText = styled.span`
  max-width: 100%;
  margin-left: 10px;
  ${commonTextEllipsisStyles};
`;

export default {
    CurrencyOptionWrapper,
    CurrencyOptionText,
};
