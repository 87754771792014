import styled from 'styled-components';


// eslint-disable-next-line spellcheck/spell-checker
const AddProductItemTileWrapper = styled.div`
  width: 280px;
  height: 280px;
  border: 1px solid #F0F0F0;
  margin: 10px;
  position: relative;
  overflow: hidden;
  float: right;
  transition: border 200ms ease;

  &:hover {
    border: 1px solid #397dcc;
  }
  
  
  &:focus-within {
    border: 1px solid #FF00FF;
  }
`;


const AddProductItemTileAddPlaceholderWrapper = styled.div`
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;


const AddProductItemTileFromWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-row-gap: 10px;
  padding: 10px;
`;

const FormHeadingWrapper = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  text-align: center;
  font-weight: 600;
`;


const MultiCurrencyFieldWrapper = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
`;


const FormButtonsWrapper = styled.div`
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 100px;
`;


export default {
    AddProductItemTileWrapper,
    AddProductItemTileFromWrapper,
    AddProductItemTileAddPlaceholderWrapper,
    FormHeadingWrapper,
    MultiCurrencyFieldWrapper,
    FormButtonsWrapper,
};
