import { CardType } from 'constants/CardModel';
import { mapCurrentStepToNumber } from 'redux/card-order/helpers';

import { Col, Steps } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


export function StepsIndicatorLayout({
    currentStep,
    cardType,
    showSelectTargetStep,
    t,
}) {
    const currentStepNumber = useMemo(
        () => mapCurrentStepToNumber(currentStep, cardType, showSelectTargetStep),
        [currentStep, cardType, showSelectTargetStep],
    );

    const stepItems = useMemo(() => [
        ...(showSelectTargetStep ? [{ title: t('Start Order') }] : []),
        { title: t('Card Details') },
        ...(cardType === CardType.PHYSICAL ? [{ title: t('Delivery Details') }] : []),
        { title: t('Review') },
        { title: t('Finish') },
    ], [t, showSelectTargetStep, cardType]);
    return (
        <Col style={{ background: 'white', padding: 20, paddingBottom: 30 }}>
            <Steps current={currentStepNumber} items={stepItems} />
        </Col>
    );
}

StepsIndicatorLayout.propTypes = {
    currentStep: PropTypes.string.isRequired,
    showSelectTargetStep: PropTypes.bool.isRequired,
    cardType: PropTypes.oneOf([CardType.PHYSICAL, CardType.VIRTUAL]).isRequired,
    t: PropTypes.func,
};


export default StepsIndicatorLayout;
