import { useMemo, useRef } from 'react';


export const useCreateDigitsCodeInputReferences = () => {
    const refInputDigit1 = useRef(null);
    const refInputDigit2 = useRef(null);
    const refInputDigit3 = useRef(null);
    const refInputDigit4 = useRef(null);
    const refInputDigit5 = useRef(null);
    const refInputDigit6 = useRef(null);
    const refButtonSubmit = useRef(null);

    return useMemo(() => (
        {
            refInputDigit1,
            refInputDigit2,
            refInputDigit3,
            refInputDigit4,
            refInputDigit5,
            refInputDigit6,
            refButtonSubmit,
        }
    ), []);
};

export const onInputKeyDown = ({
    event,
    formItem,
    dispatchSetPasscodeDigit,
}) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
    const { key } = event;
    const isBackspace = (key) => key === 'Backspace';

    if (allowedKeys.includes(key)) {
        dispatchSetPasscodeDigit(formItem.key, isBackspace(key) ? '' : key);
        const targetElement = isBackspace(key) ? formItem.prevRef?.current : formItem.nextRef?.current;

        setTimeout(() => {
            if (targetElement) targetElement.focus();
        });
    }
};
