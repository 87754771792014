import { ofType } from 'redux-observable';
import { catchError, from, of, switchMap } from 'rxjs';

import { fetchRiskAssessmentsFailure, fetchRiskAssessmentsSuccess } from './actions';
import { FETCH_RISK_ASSESMENTS } from './actions.types';


export const onFetchRiskAssessments = (action$, _, { risks }) => action$.pipe(
    ofType(FETCH_RISK_ASSESMENTS),
    switchMap(({ payload: { queryParams, locationPathname } }) => from(risks.getRiskAssessment(queryParams))
        .pipe(
            switchMap((response) => of(fetchRiskAssessmentsSuccess(response.data?.rows?.map((row) => ({
                ...(row?.template || {}),
                ...row,
            })), response.data?.count, locationPathname))),

            catchError(() => of(fetchRiskAssessmentsFailure())),
        )),
);


export default [
    onFetchRiskAssessments,
];
