import AddAccountModal from 'components/common/templates/modals/AddAccountModal';
import AddApiKeyModal from 'components/common/templates/modals/AddApiKeyModal';
import AddBusinessUserModal from 'components/common/templates/modals/AddBusinessUserModal';
import AddEditUserModal from 'components/common/templates/modals/AddEditUserModal';
import AddEditWebhookModal from 'components/common/templates/modals/AddEditWebhookModal';
import AddProductModal from 'components/common/templates/modals/AddProductModal';
import AddPromotionToGroupModal from 'components/common/templates/modals/AddPromotionToGroupModal';
import AddUserMigrationModal from 'components/common/templates/modals/AddUserMigrationModal';
import BalanceAdjustmentModal from 'components/common/templates/modals/BalanceAdjustmentModal';
import ChangeClientRiskScoreModal from 'components/common/templates/modals/ChangeClientRiskScoreModal';
import ChangeCompanyRiskScoreModal from 'components/common/templates/modals/ChangeCompanyRiskScoreModal';
import ChargeFeeModal from 'components/common/templates/modals/ChargeFeeModal';
import CreateEditGroup from 'components/common/templates/modals/CreateEditGroup';
import DeleteGroup from 'components/common/templates/modals/DeleteGroup';
import DownloadUserMigrationReportModal from 'components/common/templates/modals/DownloadUserMigrationReportModal';
import EditBusinessUserModal from 'components/common/templates/modals/EditBusinessUserModal';
import EditClientAddressModal from 'components/common/templates/modals/EditClientAddressModal';
import EditClientModal from 'components/common/templates/modals/EditClientModal';
import EditCompanyAddressModal from 'components/common/templates/modals/EditCompanyAddressModal';
import EditCompanyModal from 'components/common/templates/modals/EditCompanyModal';
import EditMemberAddressModal from 'components/common/templates/modals/EditMemberAddressModal';
import EditMemberModal from 'components/common/templates/modals/EditMemberModal';
import EditPhoneNumberModal from 'components/common/templates/modals/EditPhoneNumberModal';
import LinkCardModal from 'components/common/templates/modals/LinkCardModal';
import ReassignMemberGroup from 'components/common/templates/modals/ReassignMemberGroup';
import RequestMarketingPreferencesModal from 'components/common/templates/modals/RequestMarketingPreferencesModal';
import TransactionScreeningResolutionModal from 'components/common/templates/modals/TransactionScreeningResolutionModal';
import UnblockTransactionModal from 'components/common/templates/modals/UnblockTransactionModal';
import UnlinkCardModal from 'components/common/templates/modals/UnlinkCardModal';
import UpdateMemberStatusModal from 'components/common/templates/modals/UpdateMemberStatusModal';
import ViewJsonModal from 'components/common/templates/modals/ViewJsonModal';
import WithdrawAccountBalanceModal from 'components/common/templates/modals/WithdrawAccountBalanceModal';
import ModalName from 'constants/Modals';
import { hideModal } from 'redux/modal/actions';

import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';


const MODAL_COMPONENTS = {
    [ModalName.ADD_ACCOUNT_MODAL]: AddAccountModal,
    [ModalName.ADD_API_KEY_MODAL]: AddApiKeyModal,
    [ModalName.ADD_EDIT_USER_MODAL]: AddEditUserModal,
    [ModalName.ADD_BUSINESS_USER_MODAL]: AddBusinessUserModal,
    [ModalName.ADD_MIGRATION_MODAL]: AddUserMigrationModal,
    [ModalName.ADD_PRODUCT_MODAL]: AddProductModal,
    [ModalName.ADD_PROMOTION_TO_GROUP]: AddPromotionToGroupModal,
    [ModalName.ADD_EDIT_WEBHOOK_MODAL]: AddEditWebhookModal,
    [ModalName.LINK_CARD_MODAL]: LinkCardModal,
    [ModalName.EDIT_MEMBER_MODAL]: EditMemberModal,
    [ModalName.EDIT_MEMBER_ADDRESS_MODAL]: EditMemberAddressModal,
    [ModalName.EDIT_CLIENT_MODAL]: EditClientModal,
    [ModalName.EDIT_CLIENT_ADDRESS_MODAL]: EditClientAddressModal,
    [ModalName.EDIT_COMPANY_MODAL]: EditCompanyModal,
    [ModalName.EDIT_COMPANY_ADDRESS]: EditCompanyAddressModal,
    [ModalName.EDIT_BUSINESS_USER_MODAL]: EditBusinessUserModal,
    [ModalName.CHANGE_CLIENT_RISK_SCORE_MODAL]: ChangeClientRiskScoreModal,
    [ModalName.CHANGE_COMPANY_RISK_SCORE_MODAL]: ChangeCompanyRiskScoreModal,
    [ModalName.DOWNLOAD_MIGRATION_REPORT_MODAL]: DownloadUserMigrationReportModal,
    [ModalName.REASSIGN_MEMBER_GROUP]: ReassignMemberGroup,
    [ModalName.CREATE_NEW_GROUP]: CreateEditGroup,
    [ModalName.EDIT_GROUP_NAME]: CreateEditGroup,
    [ModalName.DELETE_GROUP]: DeleteGroup,
    [ModalName.UNLINK_CARD_MODAL]: UnlinkCardModal,
    [ModalName.EDIT_PHONE_NUMBER]: EditPhoneNumberModal,
    [ModalName.VIEW_JSON_MODAL]: ViewJsonModal,
    [ModalName.WITHDRAW_ACCOUNT_BALANCE_MODAL]: WithdrawAccountBalanceModal,
    [ModalName.BALANCE_ADJUSTMENT_MODAL]: BalanceAdjustmentModal,
    [ModalName.CHARGE_FEE_MODAL]: ChargeFeeModal,
    [ModalName.UNBLOCK_TRANSACTION]: UnblockTransactionModal,
    [ModalName.TRANSACTION_SCREENING_RESOLUTION_MODAL]: TransactionScreeningResolutionModal,
    [ModalName.REQUEST_MARKETING_PREFERENCES_MODAL]: RequestMarketingPreferencesModal,
    [ModalName.UPDATE_MEMBER_STATUS_MODAL]: UpdateMemberStatusModal,
};

function ModalRoot({
    dispatch,
    state,
    ...restProps
}) {
    if (!state.modalType) {
        return null;
    }

    const SpecificModal = MODAL_COMPONENTS[state.modalType];

    const defaultCancelHandle = () => {
        dispatch(hideModal());
    };

    const defaultOkHandle = () => {
        dispatch(hideModal());
    };

    const getFooter = () => [
        ...(state.modalProps.hideCancelButton
            ? []
            : [
                <Button
                    key={'cancel'}
                    type="default"
                    onClick={state.modalProps.onCancel
                        ? state.modalProps.onCancel
                        : defaultCancelHandle}
                    disabled={state.modalProps?.confirmLoading}
                    {...(state.modalProps.cancelButtonProps ?? {})}
                >
                    {state.modalProps.cancelText || 'Cancel'}
                </Button>,
            ]),
        ...(state.modalProps.hideOkButton
            ? []
            : [
                <Button
                    key={'okText'}
                    type="primary"
                    onClick={state.modalProps?.onOk ? state.modalProps?.onOk : defaultOkHandle}
                    loading={state.modalProps?.confirmLoading}
                    {...(state.modalProps.okButtonProps ?? {})}
                >
                    {state.modalProps.okText || 'OK'}
                </Button>,
            ]),
    ];

    return (
        <Modal
            key={state.modalType}
            title={state.modalProps.title || 'Modal'}
            open={state.open}
            onCancel={defaultCancelHandle}
            confirmLoading={state.modalProps.confirmLoading}
            destroyOnClose={state.modalProps.destroyOnClose}
            maskClosable={state.modalProps.maskClosable || false}
            width={state.modalProps.width}
            footer={state.modalProps.footer || getFooter()}
            wrapClassName={state.modalProps.wrapClassName}
            {...restProps}
        >
            <SpecificModal {...state.modalProps} />
        </Modal>
    );
}

const mapStateToProps = (state) => ({ state: state.modal });

ModalRoot.propTypes = {
    state: PropTypes.shape({
        open: PropTypes.bool.isRequired,
        modalType: PropTypes.string,
        modalProps: PropTypes.shape({}),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ModalRoot);
