import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearFeesList, fetchFeesList } from 'redux/fees/actions';
import { feesListsPerLocationStoreKeyName, feesReducerName } from 'redux/fees/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import FeesList from './FeesList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: feesReducerName,
        listsByLocationStoreKeyName: feesListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
    dispatchFetchFeesList: (queryParams, locationPathname) => dispatch(fetchFeesList(queryParams, locationPathname)),
    dispatchClearFeesList: (locationPathname) => dispatch(clearFeesList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: feesReducerName,
        fieldName: feesListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('fees'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(FeesList);
