import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchIndustriesListFailure, fetchIndustriesListSuccess } from './actions';
import { FETCH_INDUSTRIES_LIST } from './actions.types';


export const onFetchIndustriesList = (action$, state$, { industries }) => action$.pipe(
    ofType(FETCH_INDUSTRIES_LIST),
    switchMap(({ payload }) => from(industries.fetchIndustries(payload.queryParams)).pipe(
        switchMap((response) => of(fetchIndustriesListSuccess(
            response.data,
            response?.data?.length,
            payload?.locationPathname,
        ))),
        catchError(() => of(fetchIndustriesListFailure())),
    )),
);

export default [
    onFetchIndustriesList,
];
