import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_ACCOUNT_DETAILS,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_FAILURE,
    DELETE_ACCOUNT_SUCCESS,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS,
    FETCH_ACCOUNT_DETAILS,
    FETCH_ACCOUNT_DETAILS_FAILURE,
    FETCH_ACCOUNT_DETAILS_SUCCESS,
    HIDE_DOWNLOAD_PDF_STATEMENT_FORM,
    BALANCE_ADJUSTMENT,
    BALANCE_ADJUSTMENT_FAILURE,
    BALANCE_ADJUSTMENT_SUCCESS,
    OPEN_BALANCE_ADJUSTMENT_MODAL,
    OPEN_WITHDRAW_ACCOUNT_BALANCE_MODAL,
    SET_AS_PRIMARY_ACCOUNT, SET_AS_PRIMARY_ACCOUNT_FAILURE, SET_AS_PRIMARY_ACCOUNT_SUCCESS,
    SHOW_DOWNLOAD_PDF_STATEMENT_FORM,
    WITHDRAW_BALANCE,
    WITHDRAW_BALANCE_FAILURE,
    WITHDRAW_BALANCE_SUCCESS,
} from './action.types';

// account details
export const fetchAccountById = createAction(FETCH_ACCOUNT_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchAccountByIdSuccess = createAction(FETCH_ACCOUNT_DETAILS_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchAccountByIdFailure = createAction(FETCH_ACCOUNT_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const clearAccountDetails = createAction(CLEAR_ACCOUNT_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));

// delete account
export const deleteAccount = createAction(DELETE_ACCOUNT, (accountDetails, locationPathname) => ({ payload: { accountDetails, locationPathname } }));
export const deleteAccountSuccess = createAction(DELETE_ACCOUNT_SUCCESS, (responseData) => ({ payload: responseData }));
export const deleteAccountFailure = createAction(DELETE_ACCOUNT_FAILURE);

// WithdrawAccountBalance flow

export const openWithdrawAccountBalanceModal = createAction(
    OPEN_WITHDRAW_ACCOUNT_BALANCE_MODAL,
    (accountData, locationPathname) => ({ payload: { accountData, locationPathname } }),
);


export const withdrawBalance = createAction(WITHDRAW_BALANCE, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const withdrawBalanceSuccess = createAction(WITHDRAW_BALANCE_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const withdrawBalanceFailure = createAction(WITHDRAW_BALANCE_FAILURE);


// XXX money making flow (Carmen Queasy ;))

export const openBalanceAdjustmentModal = createAction(OPEN_BALANCE_ADJUSTMENT_MODAL, (accountData, locationPathname) => ({ payload: { accountData, locationPathname } }));

export const balanceAdjustment = createAction(BALANCE_ADJUSTMENT, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const balanceAdjustmentSuccess = createAction(BALANCE_ADJUSTMENT_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const balanceAdjustmentFailure = createAction(BALANCE_ADJUSTMENT_FAILURE);


// PDF account's statements downloads

export const showDownloadPdfStatementForm = createAction(SHOW_DOWNLOAD_PDF_STATEMENT_FORM, (accountId) => ({ payload: accountId }));
export const hideDownloadPdfStatementForm = createAction(HIDE_DOWNLOAD_PDF_STATEMENT_FORM, (accountId) => ({ payload: accountId }));

export const downloadAccountStatementPdf = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF, (queryParams) => ({ payload: queryParams }));
export const downloadAccountStatementPdfSuccess = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS, (queryParams) => ({ payload: queryParams }));
export const downloadAccountStatementPdfFailure = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE, (queryParams) => ({ payload: queryParams }));


export const setAsPrimaryAccount = createAction(SET_AS_PRIMARY_ACCOUNT, (accountDetails, locationPathname) => ({ payload: { accountDetails, locationPathname } }));
export const setAsPrimaryAccountSuccess = createAction(SET_AS_PRIMARY_ACCOUNT_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const setAsPrimaryAccountFailure = createAction(SET_AS_PRIMARY_ACCOUNT_FAILURE);
