import { AddCompanyStepsDefinitions } from 'constants/CompaniesModel';
import { showErrorToast } from 'redux/application/actions';
import {
    addCompanySuccess, addCompanyFailure,
    fetchCompaniesSuccess, fetchCompaniesFailure,
} from 'redux/companies/actions';
import { ADD_COMPANY, FETCH_COMPANIES } from 'redux/companies/actions.types';
import { createAddCompanyErrorMessage } from 'redux/companies/epics.helpers';
import { setMultiStepsFormStep } from 'redux/multi-steps-form/actions';
import { unblockNavigation } from 'redux/navigation/actions';


import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const onFetchCompanies = (action$, state$, { companies }) => action$.pipe(
    ofType(FETCH_COMPANIES),
    switchMap(({ payload }) => from(companies.getCompanies({ ...payload.queryParams })).pipe(
        switchMap((response) => of(fetchCompaniesSuccess(
            response.data?.rows,
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchCompaniesFailure(payload.locationPathname))),
    )),
);


export const onAddCompany = (action$, state$, { companies, i18n }) => action$.pipe(
    ofType(ADD_COMPANY),
    switchMap(({ payload }) => from(companies.createCompany(payload.requestPayload))
        .pipe(
            switchMap((response) => of(
                unblockNavigation(),
                addCompanySuccess(response.data, payload?.locationPathname),
                setMultiStepsFormStep(AddCompanyStepsDefinitions.SUCCESS, payload.locationPathname),

            )),
            catchError((error) => of(
                showErrorToast(createAddCompanyErrorMessage(i18n.t, error)),
                addCompanyFailure(payload?.locationPathname),
            )),
        )),
);


export default [
    onFetchCompanies,
    onAddCompany,
];
