import withAccessControl from 'hocs/withAccessControl';
import { fetchApplicationsList } from 'redux/applications/actions';
import { applicationsListsPerLocationStoreKeyName, applicationsReducerName } from 'redux/applications/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { fetchRolesList } from 'redux/roles/actions';
import { rolesListsPerLocationStoreKeyName, rolesReducerName } from 'redux/roles/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';
import { editUser } from 'redux/user/actions';
import { addUser } from 'redux/users/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddEditUserModal from './AddEditUserModal.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    rolesListData: getStandardListData({
        state,
        reducerName: rolesReducerName,
        listsByLocationStoreKeyName: rolesListsPerLocationStoreKeyName,
    }),
    applicationsListData: getStandardListData({
        state,
        reducerName: applicationsReducerName,
        listsByLocationStoreKeyName: applicationsListsPerLocationStoreKeyName,
    }),

    currentUserClientId: state[currentUserReducerName].userData.clientId,
    currentUserId: state[currentUserReducerName].userData.id,
    userPreferences: state[currentUserReducerName]?.userPreferences,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchRolesList: (queryParams, locationPathname) => dispatch(fetchRolesList(queryParams, locationPathname)),
    dispatchFetchApplicationsList: (queryParams, locationPathname) => dispatch(fetchApplicationsList(queryParams, locationPathname)),
    dispatchEditUser:
    (queryParams, locationPathname, isCurrentUserSelfEditMode) => dispatch(editUser(queryParams, locationPathname, isCurrentUserSelfEditMode)),
    dispatchAddUser: (requestPayload) => dispatch(addUser(requestPayload)),
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
});

const decorate = compose(
    withTranslation('users'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddEditUserModal);
