import StandardLink from 'components/common/molecules/StandardLink';
import TextWithHighlightedSearch from 'components/common/molecules/value-renderers/TextWithHighlightedSearch';
import { companyRootRoutePath, memberRootRoutePath } from 'config/routes';
import { createClientDetailsPath, createUserDetailsPath } from 'config/routes.helpers';

import { Tooltip } from 'antd';
import React, { JSX } from 'react';


import Styled from './TransactionPartyRenderer.styled';
import { TransactionPartyRendererProps } from './TransactionPartyRenderer.types';


function TransactionPartyRenderer({
    transaction,
    t,
    side = 'sender',
    search,
}: TransactionPartyRendererProps): JSX.Element {
    const partyData = transaction[side] || {};

    const handlers = [
        {
            predicate: () => Number(partyData.memberId) > 0,
            handler: () => ({
                path: `${memberRootRoutePath}/${partyData.memberId}`,
                label: partyData.name || `${t('transactionFields.transactionParty.values.member', { id: partyData.memberId })}`,
            }),
        },
        {
            predicate: () => Number(partyData.companyId) > 0,
            handler: () => ({
                path: `${companyRootRoutePath}/${partyData.companyId}`,
                label: partyData.name || `${t('transactionFields.transactionParty.values.company', { id: partyData.companyId })}`,
            }),
        },
        {
            predicate: () => Number(partyData.clientId) > 0,
            handler: () => ({
                path: createClientDetailsPath(partyData.clientId),
                label: partyData.name || `${t('transactionFields.transactionParty.values.client', { id: partyData.clientId })}`,
            }),
        },
        {
            predicate: () => Number(partyData.userId) > 0,
            handler: () => ({
                path: createUserDetailsPath(partyData.userId),
                label: partyData.name || `${t('transactionFields.transactionParty.values.user', { id: partyData.userId })}`,
            }),
        },
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];

    if (partyData.type === 'internal') {
        const partyRenderData = handlers.filter(({ predicate }) => predicate())[0].handler();

        if (partyRenderData) {
            return (
                <StandardLink
                    path={partyRenderData.path}
                    title={partyRenderData.label}
                    filterValue={search}
                />
            );
        }
    }

    return (
        <Styled.TooltipWrapper>
            <Tooltip
                title={<pre>{JSON.stringify(partyData, null, 2)}</pre>}
                overlayStyle={{ maxWidth: '600px' }}
            >
                <Styled.InfoIconAndValueWrapper>
                    <Styled.InfoIcon />
                    <Styled.ValueWrapper>
                        {partyData.name ? (<TextWithHighlightedSearch filterValue={search} rawValue={partyData.name} />) : partyData.type}
                    </Styled.ValueWrapper>
                </Styled.InfoIconAndValueWrapper>
            </Tooltip>
        </Styled.TooltipWrapper>
    );
}

export default TransactionPartyRenderer;

