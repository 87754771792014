import StandardLink from 'components/common/molecules/StandardLink';
import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import FetchAndViewValueButton from 'components/common/organisms/FetchAndViewValueButton';
import { createRoleDetailsPath } from 'config/routes.helpers';
import { SortDirections } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import { apiKeysReducerName } from 'redux/api-keys/reducer';
import noop from 'utils/function-tools';
import { sortingOptions } from 'utils/table-columns-sorters';

import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Space } from 'antd';
import React from 'react';


export default ({
    accessControl,
    dispatchDeleteApiKey,
    dispatchFetchApiKeyValue,
    queryParams,
    enhancedCurrentLocation,
    t,
}) => [
    {
        title: t('apiKeysFields.id.label'),
        dataIndex: 'id',
        defaultSortOrder: SortDirections.ASCEND,
        render: (text) => <StandardValue value={text} />,
        ...sortingOptions({ queryParams, fieldName: 'id' }),
    },
    {
        title: t('apiKeysFields.roleId.label'),
        dataIndex: 'role_id',
        render: (text) => <StandardLink path={createRoleDetailsPath(text)} title={text} />,
        ...sortingOptions({ queryParams, fieldName: 'role_id' }),
    },
    {
        title: t('apiKeysFields.label.label'),
        dataIndex: 'label',
        render: (text) => <StandardValue value={text} />,
        ...sortingOptions({ queryParams, fieldName: 'label' }),
    },
    ...(accessControl.hasOneOfPermissions([PN.API_KEY.DELETE, PN.API_KEY.READ_KEY_VALUE_BY_ID])
        ? [
            {
                title: 'Actions',
                dataIndex: '',
                align: 'center',
                render: (_, record) => (
                    <Space>
                        {accessControl.hasPermission(PN.API_KEY.DELETE) && (
                            <Popconfirm
                                onConfirm={() => dispatchDeleteApiKey(record.id, enhancedCurrentLocation.pathname)}
                                title={(
                                    <StandardTranslationWithMarkup
                                        translationKey={'buttons.deleteApiKey.confirmationQuestion'}
                                        namespace="apiKeys"
                                        values={{ apiKeyId: record?.id, roleId: record?.role_id }}
                                        t={t}
                                    />
                                )}
                            >
                                {viewButtonRenderer({
                                    record,
                                    onClick: () => noop,
                                    tooltipContent: t('buttons.deleteApiKey.tooltip'),
                                    icon: <DeleteOutlined />,
                                    dataTestId: 'delete-api-key',
                                })}
                            </Popconfirm>
                        )}

                        {accessControl.hasPermission(PN.API_KEY.READ_KEY_VALUE_BY_ID) && (
                            <FetchAndViewValueButton
                                itemId={record.id}
                                reducerName={apiKeysReducerName}
                                stateFieldName="apiKeyValuePerApiKeyId"
                                title={'API key value'}
                                dispatchFetchValue={dispatchFetchApiKeyValue}
                            />
                        )}
                    </Space>
                ),
            },
        ] : []
    ),
];
