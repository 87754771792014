import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import {
    hideModal,
    showModal,
} from 'redux/modal/actions';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import CompanyInformation from './CompanyInformation.layout';


const mapStateToProps = (state) => ({
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchShowModal: (data) => dispatch(showModal(data)),
    dispatchHideModal: () => dispatch(hideModal()),
});

const decorate = compose(
    withTranslation('companies'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CompanyInformation);

