import PropTypes from 'prop-types';

import { SharedOffboardingStatuses } from './StatusModel';


export const MemberStatuses = Object.freeze({
    INVITED: 'INVITED',
    UNVERIFIED: 'UNVERIFIED',
    KYC_PENDING: 'KYC_PENDING',
    KYC_UPLOADED: 'KYC_UPLOADED',
    KYC_MANUAL_REVIEW: 'KYC_MANUAL_REVIEW',
    KYC_REJECTED: 'KYC_REJECTED',
    ACTIVE: 'ACTIVE',
    DORMANT: 'DORMANT',
    SUSPENDED: 'SUSPENDED',
    ...SharedOffboardingStatuses,
    BANNED: 'BANNED',
});

export const MemberCommonProps = Object.freeze({
    member_id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    member_uid: PropTypes.string,
    ext_member_id: PropTypes.string,
    role_id: PropTypes.number,
    contact_id: PropTypes.number,

    first_name: PropTypes.string.isRequired,
    middle_name: PropTypes.string,
    last_name: PropTypes.string.isRequired,
    full_name: PropTypes.string,

    phone_number: PropTypes.string,
    phone_country_id: PropTypes.number,

    email: PropTypes.string.isRequired,
    date_of_birth: PropTypes.string,
    gender: PropTypes.string,

    avatar: PropTypes.string,
    status: PropTypes.oneOf(Object.values(MemberStatuses)),
    refresh_token: PropTypes.string,

    removable: PropTypes.number,
    enabled: PropTypes.number,
    deleted: PropTypes.number,
    is_permanently_locked: PropTypes.bool,
    transfer_is_allowed: PropTypes.bool,

    document_checked: PropTypes.number,
    topped_up: PropTypes.number,
    contacts_synced: PropTypes.number,
    documents_verification_status: PropTypes.number,
    accepted_terms_and_conditions: PropTypes.bool,

    // eslint-disable-next-line spellcheck/spell-checker
    ibans_status: PropTypes.number,

    risk_rating: PropTypes.string,
    pep_risk_score: PropTypes.number,
    kyc_risk_score: PropTypes.number,

    pep_sanctioned_watchlists_match: PropTypes.number,
    country_of_registration_risk: PropTypes.number,
    nationality_risk: PropTypes.number,
    country_of_residence_risk: PropTypes.number,

    title: PropTypes.string,
    card_usage_information_confirmed: PropTypes.bool,

    closure_start_date: PropTypes.string,
    closure_end_date: PropTypes.string,

    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    deleted_at: PropTypes.string,

    enforce_passcode_setup: PropTypes.bool,
    config: PropTypes.oneOfType([PropTypes.shape({
        allow_online_payments: PropTypes.bool,
        allow_physical_cards: PropTypes.bool,
        allow_topup: PropTypes.bool,
        allow_virtual_cards: PropTypes.bool,
        enable_chat: PropTypes.bool,
        physical_cards_limit: PropTypes.number,
        virtual_cards_limit: PropTypes.number,
    }), PropTypes.string]),
});

export const MemberDetailsProps = PropTypes.shape({
    ...MemberCommonProps,

    firebase_uid: PropTypes.string,
    fin_customer_id: PropTypes.string,
    external_user_id: PropTypes.string,

    country_nationality_iso_3166_3: PropTypes.string,
    country_of_residence_iso_3166_3: PropTypes.string,
    total_risk_score: PropTypes.number,

    migration_status: PropTypes.arrayOf(PropTypes.string),
    pass_code: PropTypes.string,

    // Group Model
    group: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }),


    // Address Model
    member_address: PropTypes.shape(
        {
            id: PropTypes.number,
            client_id: PropTypes.number,
            company_id: PropTypes.number,
            member_id: PropTypes.number,
            business_user_id: PropTypes.number,
            business_member_id: PropTypes.number,
            type: PropTypes.string,
            city: PropTypes.string,
            registered_address: PropTypes.string,
            secondary_address: PropTypes.string,
            house_number: PropTypes.string,
            fax_number: PropTypes.string,
            state: PropTypes.string,
            premise: PropTypes.string,
            zip_code: PropTypes.string,
            country: PropTypes.string,
            phone_number: PropTypes.string,
            street: PropTypes.string,
            street_number: PropTypes.string,
            additionals: PropTypes.string,
            address_line_1: PropTypes.string,
            address_line_2: PropTypes.string,
            confirmed: PropTypes.bool,
            deleted: PropTypes.bool,
            created_at: PropTypes.string,
            updated_at: PropTypes.string,
            deleted_at: PropTypes.string,

            // Country Model
            country_details: PropTypes.shape({
                id: PropTypes.number,
                capital: PropTypes.string,
                citizenship: PropTypes.string,
                country_code: PropTypes.string,
                currency: PropTypes.string,
                currency_code: PropTypes.string,
                currency_sub_unit: PropTypes.string,
                currency_symbol: PropTypes.string,
                full_name: PropTypes.string,
                iso_3166_2: PropTypes.string,
                iso_3166_3: PropTypes.string,
                name: PropTypes.string,
                region_code: PropTypes.string,
                sub_region_code: PropTypes.string,
                eea: PropTypes.number,
                calling_code: PropTypes.string,
                flag: PropTypes.string,
                enabled: PropTypes.number,
                document: PropTypes.number,
                risk_consumer: PropTypes.number,
                risk_clients: PropTypes.number,
                created_at: PropTypes.string,
                updated_at: PropTypes.string,
                deleted_at: PropTypes.string,
            }),
        },
    ),
    remember_token: PropTypes.string,
});

export const MemberListProps = PropTypes.shape({
    ...MemberCommonProps,

    country_nationality_iso_31663: PropTypes.string,
    country_of_residence_iso_31663: PropTypes.string,

    final_risk_score: PropTypes.number,
    final_risk_rating: PropTypes.string,
});
export default { MemberStatuses, MemberDetailsProps, MemberListProps };
