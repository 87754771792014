import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchGroupMembersFailure, fetchGroupMembersSuccess, fetchMembersNotInGroupFailure, fetchMembersNotInGroupSuccess } from './actions';
import { FETCH_GROUP_MEMBERS, FETCH_MEMBERS_NOT_IN_GROUP } from './actions.types';


export const onFetchGroupMembers = (action$, state$, { groups }) => action$.pipe(
    ofType(FETCH_GROUP_MEMBERS),
    switchMap(({ payload }) => from(groups.fetchMembersFormGroup(payload.queryParams)).pipe(
        switchMap((response) => of(fetchGroupMembersSuccess(
            response.data.rows,
            response.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchGroupMembersFailure(payload.locationPathname))),
    )),
);

export const onFetchGroupNonMembers = (action$, state$, { groups }) => action$.pipe(
    ofType(FETCH_MEMBERS_NOT_IN_GROUP),
    switchMap(({ payload }) => from(groups.fetchMembersNotFormGroup(payload.queryParams)).pipe(
        switchMap((response) => of(fetchMembersNotInGroupSuccess(
            response.data.rows,
            response.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchMembersNotInGroupFailure(payload.locationPathname))),
    )),
);


export default [
    onFetchGroupMembers,
    onFetchGroupNonMembers,
];
