import { CommonMimeTypes } from 'constants/MimeTypes';
import { saveFile, showSuccessToast } from 'redux/application/actions';
import { setModalProps, hideModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    downloadMigrationReportFailure,
    downloadMigrationReportSuccess,
    downloadMigrationResultFailure,
    downloadMigrationResultSuccess,
    fetchClientMigrationsList,
    fetchClientMigrationsListFailure,
    fetchClientMigrationsListSuccess,
    uploadClientMigrationFileFailure,
    uploadClientMigrationFileSuccess,
} from './actions';
import { DOWNLOAD_MIGRATION_REPORT, DOWNLOAD_MIGRATION_RESULT, FETCH_CLIENT_MIGRATIONS_LIST, UPLOAD_CLIENT_MIGRATION_FILE } from './actions.types';
import { clientMigrationsListsPerLocationStoreKeyName, clientMigrationsReducerName } from './reducer';


export const onFetchClientMigrationsList = (action$, state$, { clients }) => action$.pipe(
    ofType(FETCH_CLIENT_MIGRATIONS_LIST),
    switchMap(({ payload }) => from(clients.getClientMigrations(payload.queryParams))
        .pipe(
            switchMap((response) => of(
                fetchClientMigrationsListSuccess(
                    response?.data,
                    response?.data?.length,
                    payload.locationPathname,
                ),
            )),
            catchError(() => of(fetchClientMigrationsListFailure(payload.locationPathname))),
        )),
);


export const onDownloadMigrationResult = (action$, state$, { clients }) => action$.pipe(
    ofType(DOWNLOAD_MIGRATION_RESULT),
    switchMap(({ payload }) => from(clients.getClientMigrationResult(payload.queryParams))
        .pipe(
            switchMap((response) => of(
                downloadMigrationResultSuccess(response, payload.locationPathname),
                saveFile({
                    blob: response.data,
                    fileName: `${payload?.queryParams.migrationType}_result_${payload?.queryParams.migrationId}_${payload?.queryParams.migrationStatus}.csv`,
                    mimeType: CommonMimeTypes.CSV,
                }),
                hideModal(),
            )),
            catchError(() => of(
                downloadMigrationResultFailure(payload.locationPathname),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);


export const onDownloadMigrationReport = (action$, state$, { clients, i18n }) => action$.pipe(
    ofType(DOWNLOAD_MIGRATION_REPORT),
    switchMap(({ payload }) => from(clients.getClientMigrationReport(payload.queryParams))
        .pipe(
            switchMap((response) => of(
                hideModal(),
                downloadMigrationReportSuccess(response, payload.locationPathname),

                ...(response?.size === 0 ? [
                    showSuccessToast(i18n.t('Empty migration result file')),
                ] : [
                    saveFile({
                        blob: response.data,
                        fileName: `migration_report_${payload.queryParams.clientId}_${payload.queryParams.reportType}.csv`,
                        mimeType: CommonMimeTypes.CSV,
                    }),
                ]),
            )),
            catchError(() => of(
                downloadMigrationReportFailure(payload.locationPathname),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);

export const onUploadClientMigrationFile = (action$, state$, { clients, i18n }) => action$.pipe(
    ofType(UPLOAD_CLIENT_MIGRATION_FILE),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(clients.uploadClientMigrationCsv(queryParams))
        .pipe(
            switchMap((response) => {
                // eslint-disable-next-line max-len
                const currentClientMigrationsListQueryParams = state$.value[clientMigrationsReducerName][clientMigrationsListsPerLocationStoreKeyName]?.[locationPathname]?.queryParams;
                return of(
                    uploadClientMigrationFileSuccess(response.data, locationPathname),
                    showSuccessToast(i18n.t('clients:actionMessages.clientRiskScoreUpdateSuccess')),
                    fetchClientMigrationsList(currentClientMigrationsListQueryParams, locationPathname),
                    hideModal(),
                );
            }),
            catchError(() => of(
                uploadClientMigrationFileFailure(locationPathname),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);


export default [
    onFetchClientMigrationsList,
    onDownloadMigrationResult,
    onDownloadMigrationReport,
    onUploadClientMigrationFile,
];
