import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { showViewJsonModal } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';
import { clearWebhooksList, fetchWebhooksList, openRegisterOrEditWebhookModal, unregisterWebhook } from 'redux/webhooks/actions';
import { webhooksListsPerLocationStoreKeyName, webhooksReducerName } from 'redux/webhooks/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import WebhooksList from './WebhooksList.layout';
import { WebhooksListDispatchProps, WebhooksListStateProps } from './WebhooksList.types';


const mapStateToProps = (state: RootState): WebhooksListStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: webhooksReducerName,
        listsByLocationStoreKeyName: webhooksListsPerLocationStoreKeyName,
    }),

    isLoadingUnregisterWebhook: state[webhooksReducerName].isLoadingUnregisterWebhook,
    isLoadingRegisterWebhook: state[webhooksReducerName].isLoadingRegisterWebhook,
    isLoadingUpdateWebhook: state[webhooksReducerName].isLoadingUnregisterWebhook,
});

const mapDispatchToProps = (dispatch: Dispatch): WebhooksListDispatchProps => ({
    dispatchFetchWebhooksList: (queryParams, locationPathname) => dispatch(fetchWebhooksList(queryParams, locationPathname)),
    dispatchClearWebhooksList: (locationPathname) => dispatch(clearWebhooksList(locationPathname)),
    dispatchOpenRegisterOrEditWebhookModal: (initialData, locationPathname) => dispatch(openRegisterOrEditWebhookModal(initialData, locationPathname)),
    dispatchUnregisterWebhook: (webhookUuid, locationPathname) => dispatch(unregisterWebhook(webhookUuid, locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: webhooksReducerName,
        fieldName: webhooksListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchShowViewJsonModal: (dataToDisplay, modalTitle) => dispatch(showViewJsonModal(dataToDisplay, modalTitle)),
});

const decorate = compose(
    withTranslation('webhooks'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(WebhooksList);
