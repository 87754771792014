import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_RAED_ONLY_MODE,
    SAVE_FILE,
    SET_QUERY_PARAMS,
    SET_RAED_ONLY_MODE,
    SHOW_ERROR_TOAST,
    SHOW_INFO_TOAST,
    SHOW_SUCCESS_TOAST,
    SHOW_WARNING_TOAST,
} from './actions.types';

// TODO create TYPES and replace app-wide
export const initApplication = createAction('application/initApplication');

export const applicationReadyUnauthorisedUser = createAction('application/applicationReadyUnauthorisedUser');
export const applicationGlobalDataFetchingAuthorisedUser = createAction('application/applicationGlobalDataFetchingAuthorisedUser');
export const applicationInitGlobalDataFetchingAuthorisedUser = createAction('application/applicationInitGlobalDataFetchingAuthorisedUser');
export const applicationReadyAuthorisedUser = createAction('application/applicationReadyAuthorisedUser');

export const fetchGlobalDataFailure = createAction('application/fetchGlobalDataFailure');
export const fetchGlobalData = createAction('application/fetchGlobalData', (isInitialRun) => ({ payload: { isInitialRun } }));

// TODO
// eslint-disable-next-line @typescript-eslint/no-shadow
export const setSessionUuid = createAction('application/setSessionUuid', (setSessionUuid) => ({ payload: setSessionUuid }));
export const initI18next = createAction('application/initI18next');
export const initI18nextSuccess = createAction('application/initI18nextSuccess');
export const initI18nextFailure = createAction('application/initI18nextFailure');

export const getJwtTokenFormStorage = createAction('application/getJwtTokenFormStorage', (originalSessionUuid) => ({ payload: originalSessionUuid }));
export const removeJwtTokenFromStorage = createAction('application/removeJwtTokenFromStorage');

export const showAutoLogoutBanner = createAction('application/showAutoLogoutBanner');
export const hideAutoLogoutBanner = createAction('application/hideAutoLogoutBanner');
export const toggleSidebar = createAction('application/toggleSidebar');

export const setClipboardContent = createAction('application/setClipboardContent', (text) => ({ payload: text }));

export const setReadOnlyMode = createAction(SET_RAED_ONLY_MODE, (readOnlyInfo) => ({ payload: readOnlyInfo }));
export const clearReadOnlyMode = createAction(CLEAR_RAED_ONLY_MODE);

export const showSuccessToast = createAction(SHOW_SUCCESS_TOAST, (message, description, options) => ({
    payload: {
        message,
        description,
        options,
    },
}));

export const showErrorToast = createAction(SHOW_ERROR_TOAST, (message, description, options) => ({
    payload: {
        message,
        description,
        options,
    },
}));

export const showInfoToast = createAction(SHOW_INFO_TOAST, (message, description, options) => ({
    payload: {
        message,
        description,
        options,
    },
}));

export const showWarningToast = createAction(SHOW_WARNING_TOAST, (message, description, options) => ({
    payload: {
        message,
        description,
        options,
    },
}));

// TODO cleanup isInitialRun
export const setQueryParams = createAction(SET_QUERY_PARAMS, ({ reducerName, fieldName, locationPathname, queryParams }) => ({
    payload: {
        reducerName,
        fieldName,
        locationPathname,
        queryParams,
    },
}));

export const saveFile = createAction(SAVE_FILE, ({ blob, fileName, mimeType }) => ({ payload: { blob, fileName, mimeType } }));

export default {
    initApplication,
    applicationReadyUnauthorisedUser,
    applicationReadyAuthorisedUser,
    initI18next,
    initI18nextSuccess,
    setSessionUuid,
    getJwtTokenFormStorage,
    removeJwtTokenFromStorage,
    showAutoLogoutBanner,
    hideAutoLogoutBanner,
    setClipboardContent,
    setReadOnlyMode,
    clearReadOnlyMode,
    setQueryParams,
    // TODO consider replacing SHOW_<*>_TOAST with single SHOW_TOAST + 'type' prop
    showSuccessToast,
    showErrorToast,
    showWarningToast,
    showInfoToast,
    saveFile,
};
