import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';
import { sortStringsAlphabetically } from 'utils/table-columns-sorters';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchRoleDetails, fetchRoleDetailsSuccess, fetchRoleDetailsFailure, clearRoleDetails,
    updateRole, updateRoleFailure, updateRoleSuccess,
    deleteRole, deleteRoleFailure, deleteRoleSuccess,
    createRole, createRoleFailure, createRoleSuccess,
    selectRoleTemplate,
    clearRoleTemplate,
    addPermissionsToRole,
    removePermissionsFromRole, toggleSaveRoleAsRoleTemplate, clearAddEditRoleFormState, removeAllPermissionsFromRole,
} from './actions';


export const roleReducerName = 'role';
export const roleDetailsPerLocationStoreKeyName = 'roleDetailsPerLocation';

const initialAddEditRoleFormState = {
    selectedRoleTemplate: undefined,
    selectedPermissions: [],
    saveRoleAsRoleTemplate: false,
    isLoadingCreateRole: false,
    isLoadingUpdateRole: false,

    newlyCreatedRoleData: undefined,
};


const initialRoleDetailsState = {
    [roleDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }


    isLoadingDeleteRole: false,
};

const initialState = {
    ...initialRoleDetailsState,
    ...initialAddEditRoleFormState,
};

const handlers = {
    [fetchRoleDetails]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[roleDetailsPerLocationStoreKeyName], action);
    },
    [fetchRoleDetailsSuccess]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[roleDetailsPerLocationStoreKeyName], action);
    },
    [fetchRoleDetailsFailure]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[roleDetailsPerLocationStoreKeyName], action);
    },
    [clearRoleDetails]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[roleDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === roleReducerName
      && action.payload?.fieldName === roleDetailsPerLocationStoreKeyName
        ) {
            state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[roleDetailsPerLocationStoreKeyName] }, action);
        }
    },
    [selectRoleTemplate]: (state, action) => {
        state.selectedRoleTemplate = action.payload;
    },
    [clearRoleTemplate]: (state) => {
        state.selectedRoleTemplate = undefined;
    },

    [addPermissionsToRole]: (state, action) => {
        state.selectedPermissions = [
            ...state.selectedPermissions,
            ...action.payload,
        ].sort((a, b) => sortStringsAlphabetically(a, b, 'name'));
    },
    [removePermissionsFromRole]: (state, action) => {
        state.selectedPermissions = state.selectedPermissions
            .filter((selectedPermissions) => !action.payload.some((permissionToBeRemoved) => selectedPermissions.id === permissionToBeRemoved.id));
    },


    [removeAllPermissionsFromRole]: (state) => {
        state.selectedPermissions = [];
    },


    [toggleSaveRoleAsRoleTemplate]: (state) => {
        state.saveRoleAsRoleTemplate = !state.saveRoleAsRoleTemplate;
    },

    [clearAddEditRoleFormState]: (state) => ({
        ...state,
        ...initialAddEditRoleFormState,
    }),


    [updateRole]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[roleDetailsPerLocationStoreKeyName], action);
    },
    [updateRoleSuccess]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[roleDetailsPerLocationStoreKeyName], action);
    },
    [updateRoleFailure]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[roleDetailsPerLocationStoreKeyName], action);
    },


    [createRole]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[roleDetailsPerLocationStoreKeyName], action);
        state.newlyCreatedRoleData = undefined;
    },
    [createRoleSuccess]: (state, action) => {
        state.newlyCreatedRoleData = action.payload.responsePayload.role;
    },
    [createRoleFailure]: (state, action) => {
        state[roleDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[roleDetailsPerLocationStoreKeyName], action);
        state.newlyCreatedRoleData = undefined;
    },

    [deleteRole]: (state) => {
        state.isLoadingDeleteRole = true;
    },
    [deleteRoleSuccess]: (state) => {
        state.isLoadingDeleteRole = false;
    },
    [deleteRoleFailure]: (state) => {
        state.isLoadingDeleteRole = false;
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
