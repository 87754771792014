import { createClientDetailsPromotionsListPath } from 'config/routes.helpers';
import { showSuccessToast } from 'redux/application/actions';
import { requestNavigationBack, unblockNavigation } from 'redux/navigation/actions';
import { CREATE_PROMOTION, FETCH_PROMOTIONS_LIST } from 'redux/promotions/actions.types';
import { getProperGetPromotionsEndpoint } from 'redux/promotions/epic.helpers';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { createPromotionFailure, createPromotionSuccess, fetchPromotionsList, fetchPromotionsListFailure, fetchPromotionsListSuccess } from './actions';
import { markActivatedPromotions } from './helpers';
import { promotionsListsPerLocationStoreKeyName, promotionsReducerName } from './reducer';


export const onFetchClientPromotionsList = (action$, state$, { promotions, fees, groups }) => action$.pipe(
    ofType(FETCH_PROMOTIONS_LIST),
    switchMap(({ payload }) => from(getProperGetPromotionsEndpoint({ payload, promotions, fees, groups })).pipe(
        switchMap((response) => of(
            fetchPromotionsListSuccess(
                markActivatedPromotions(response.data),
                response.data?.length,
                payload?.locationPathname,
            ),
        )),
        catchError(() => of(fetchPromotionsListFailure(payload?.locationPathname))),
    )),
);


export const onCreatePromotion = (action$, state$, { promotions, i18n }) => action$.pipe(
    ofType(CREATE_PROMOTION),
    switchMap(({ payload }) => from(promotions.createPromotion(payload.requestPayload)).pipe(
        switchMap(() => {
            const { clientId } = payload.requestPayload;
            const properListLocationPathname = createClientDetailsPromotionsListPath(clientId);

            const commonActions = [
                unblockNavigation(),
                createPromotionSuccess(properListLocationPathname),
                showSuccessToast(i18n.t('promotions:actionMessages.createPromotionSuccess')),
                requestNavigationBack(),
            ];

            // XXX standard flow -> user was on promotions list, re-fetch it
            if (state$.value[promotionsReducerName][promotionsListsPerLocationStoreKeyName]?.[properListLocationPathname]?.items) {
                // eslint-disable-next-line max-len
                const currentProperPromotionsListQueryParams = state$.value[promotionsReducerName][promotionsListsPerLocationStoreKeyName]?.[properListLocationPathname]?.queryParams;
                return of(
                    fetchPromotionsList(currentProperPromotionsListQueryParams, properListLocationPathname),
                    ...commonActions,
                );
            }
            // XXX rather edge case -> user reloaded add promotion form page, there is no list state to update
            return of(...commonActions);
        }),
        catchError(() => of(createPromotionFailure(payload.locationPathname))),
    )),
);


export default [
    onFetchClientPromotionsList,
    onCreatePromotion,
];
