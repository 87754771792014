import { cardsOrderReducerName } from 'redux/card-order/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import StepsIndicator from './StepsIndicator.layout';


const mapStateToProps = (state) => ({
    currentStep: state[cardsOrderReducerName].currentStep,
    cardType: state[cardsOrderReducerName].data.card_type,
    showSelectTargetStep: state[cardsOrderReducerName].showSelectTargetStep,
});

const decorate = compose(
    withTranslation('cards'),
    connect(mapStateToProps),
);

export default decorate(StepsIndicator);

