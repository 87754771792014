import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_LIMIT_DETAILS, FETCH_LIMIT_DETAILS, FETCH_LIMIT_DETAILS_FAILURE, FETCH_LIMIT_DETAILS_SUCCESS } from './actions.types';


export const limitReducerName = 'limit';
export const limitDetailsPerLocationsStoreKeyName = 'limitDetailsPerLocationsStoreKeyName';

export const initialState = { [limitDetailsPerLocationsStoreKeyName]: {} };

export default createReducer(initialState, (builder) => {
    builder.addCase(FETCH_LIMIT_DETAILS, (state, action) => {
        state[limitDetailsPerLocationsStoreKeyName] = updateStandardEntityDetails(state[limitDetailsPerLocationsStoreKeyName], action);
    })
        .addCase(FETCH_LIMIT_DETAILS_SUCCESS, (state, action) => {
            state[limitDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[limitDetailsPerLocationsStoreKeyName], action);
        })
        .addCase(FETCH_LIMIT_DETAILS_FAILURE, (state, action) => {
            state[limitDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnFailure(state[limitDetailsPerLocationsStoreKeyName], action);
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            state[limitDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsDataQueryParams(state[limitDetailsPerLocationsStoreKeyName], action);
        })
        .addCase(CLEAR_LIMIT_DETAILS, () => initialState);
});
