import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import { WL_CONFIG } from 'config/config';
import { ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { isFormPristine } from 'utils/forms-tools';
import { createWebhookActionTypeOptions } from 'utils/options-creators';
import { commonValidation, handleWhitespaceOnBlur, isValidJsonStringRule, isValidURLRule } from 'utils/validation-tools';

import { Alert, Form, Input, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';


import { AddEditWebhookModalProps } from './AddEditWebhookModal.types';


const { TextArea } = Input;


function AddEditWebhookModal({
    initialValues,
    locationPathname,
    isBlockNavigationReasonSet,
    webhookActionTypes,
    isLoadingWebhookActionTypes,
    dispatchSetModalProps,
    dispatchRegisterWebhook,
    dispatchUpdateWebhook,
    dispatchFetchWebhooksActionTypes,
    dispatchUnblockNavigation,
    dispatchBlockNavigation,
    t,
}:AddEditWebhookModalProps) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchFetchWebhooksActionTypes();
        dispatchSetModalProps({ onOk: () => form.submit() });

        return () => {
            dispatchUnblockNavigation();
        };
    }, []);


    const isEditMode = useMemo(
        () => !!initialValues?.uuid && !!initialValues?.action,
        [initialValues],
    );

    const webhookActionTypesOptions = useMemo(
        () => createWebhookActionTypeOptions(webhookActionTypes),
        [webhookActionTypes],
    );


    const onFinish = (values) => {
        const { action, headers, webhookUrl, apiKey } = values;
        const commonPayload = {
            webhookUrl,
            ...(headers?.length > 0 ? { headers } : undefined),
            ...(apiKey?.length > 0 ? { apiKey } : undefined),
        };

        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        if (isEditMode) {
            dispatchUpdateWebhook({
                webhookUuid: initialValues?.uuid,
                formData: commonPayload,
                locationPathname,
            });
        } else {
            const finalRequestPayload = {
                ...commonPayload,
                clientId: initialValues.clientId,
                action,
            };

            dispatchRegisterWebhook(finalRequestPayload, locationPathname);
        }
    };


    const handleFormValuesChanges = (changedValues, allValues) => {
        const isPristine = isFormPristine({ ...allValues });
        if (!isPristine && !isBlockNavigationReasonSet) {
            dispatchBlockNavigation(ReasonsForBlockedNavigation.NOT_PRISTINE_ADD_EDIT_WEBHOOK_FORM);
        }

        if (isBlockNavigationReasonSet && isPristine) {
            dispatchUnblockNavigation();
        }
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={initialValues?.uuid ? initialValues : undefined}
            onFinish={onFinish}
            onValuesChange={handleFormValuesChanges}
        >
            <Form.Item
                name="action"
                label={t('webhooksFields.actionType.label')}
                rules={[...commonValidation({ t, isRequired: true })]}
            >
                <Select
                    placeholder={t('webhooksFields.actionType.placeholder')}
                    disabled={isEditMode}
                    loading={isLoadingWebhookActionTypes}
                >
                    {webhookActionTypesOptions}
                </Select>
            </Form.Item>
            <p>
                <Alert
                    showIcon
                    type="info"
                    description={(
                        <StandardTranslationWithMarkup
                            translationKey={'webhooks:webhooksFields.actionType.infoAlert'}
                            t={t}
                            namespace="webhooks"
                            values={{ webhooksPublicApiDocumentationUrl: WL_CONFIG.externalLinks.webhooksPublicApiDocumentationUrl }}
                        />
                    )}
                />
            </p>


            <Form.Item
                name="webhookUrl"
                label={t('webhooksFields.webhookURL.label')}
                rules={[
                    ...commonValidation({ t, isRequired: true }),
                    ...isValidURLRule('webhookUrl', t),
                ]}
            >
                <Input
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'webhookUrl', form)}
                    placeholder={t('webhooksFields.webhookURL.placeholder')}
                />
            </Form.Item>

            <Form.Item
                name="apiKey"
                label={t('webhooksFields.apiKey.label')}
                rules={[...commonValidation({ t })]}
            >
                <Input
                    placeholder={t('webhooksFields.apiKey.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'apiKey', form)}
                />
            </Form.Item>

            <Form.Item
                name="headers"
                label={t('webhooksFields.headers.label')}
                rules={[
                    ...commonValidation({ t }),
                    ...isValidJsonStringRule('headers', t),
                ]}
            >
                <TextArea
                    placeholder={t('webhooksFields.headers.placeholder')}
                    rows={4}
                />
            </Form.Item>
        </Form>
    );
}


export default AddEditWebhookModal;
