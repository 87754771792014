import { AccessControlProps } from 'constants/AccessControlModel';
import { KycKybEntityTypeProp, KycKybEntityTypes } from 'constants/KycKybModel';
import { PN } from 'constants/PermissionsModel';
import { createKycKybLevelOptions } from 'utils/options-creators';

import { Button, Form, Select, Skeleton, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import Styled from './KycKybLevel.styled';


function KycKybLevelSubTab({
    accessControl,
    isInReadOnlyMode,
    t,
    currentFlow = {},
    entityId,
    entityType,
    isLoadingCurrentKycKybFlow,
    isLoadingSetKycKyb,
    dispatchSetKycKybFlow,
    isInEditLevelMode,
    dispatchSetIsInEditLevelMode,
    dispatchFetchCurrentKycKybFlow,
    children,
}) {
    const [form] = Form.useForm();

    const type = useMemo(
        () => (entityType === KycKybEntityTypes.COMPANY ? 'KYB' : 'KYC'),
        [entityType],
    );

    const commonPayload = useMemo(
        () => (
            {
                ...(entityType === KycKybEntityTypes.MEMBER ? { memberId: entityId } : {}), // TODO should be just id
                ...(entityType === KycKybEntityTypes.COMPANY ? { companyId: entityId } : {}),
                ...(entityType === KycKybEntityTypes.BUSINESS_USER ? { businessUserId: entityId } : {}),
            }
        ),
        [entityType, entityId],
    );


    useEffect(() => {
        if (entityId > 0) {
            dispatchFetchCurrentKycKybFlow({ ...commonPayload });
        }
    }, []);


    const levelOptions = useMemo(
        () => {
            if (currentFlow) {
                const { base_level, additional_levels = [] } = currentFlow;
                return createKycKybLevelOptions([base_level, ...additional_levels]);
            }
            return [];
        },
        [currentFlow],
    );

    const onFinishHandler = (values) => {
        dispatchSetKycKybFlow({
            ...commonPayload,
            flowType: values.flowType,
        });
    };

    return (
        <Skeleton loading={isLoadingCurrentKycKybFlow} active>
            <Spin spinning={isLoadingSetKycKyb} tip={`Updating ${type} level...`}>
                <div>
                    <p>
                        {t(`Current ${type} level: `)}
                        <strong>{currentFlow?.base_level}</strong>
                    </p>
                    <Styled.ChangeFlowLevelFormWrapper>
                        {isInEditLevelMode
                            ? (
                                <Form
                                    form={form}
                                    layout="vertical"
                                    name="form_in_modal"
                                    initialValues={{ flowType: currentFlow?.base_level }}
                                    onFinish={onFinishHandler}
                                >
                                    <Form.Item
                                        name="flowType"
                                        label={t(`Select new ${type} flow level`)}
                                        rules={[
                                            { required: true, message: t('common:validationMsg.fieldMandatory') },
                                            () => ({
                                                validator: async (_, value) => {
                                                    if (value === currentFlow?.base_level) {
                                                        throw new Error('Please select different level');
                                                    }
                                                },
                                            }),
                                        ]}
                                    >
                                        <Select>
                                            {levelOptions}
                                        </Select>
                                    </Form.Item>

                                    <Styled.ChangeFlowLevelFormButtonsWrapper>
                                        <Button type="default" htmlType="reset" onClick={() => dispatchSetIsInEditLevelMode(false)}>
                      Cancel
                                        </Button>

                                        <Button type="primary" htmlType="submit">
                      Update value
                                        </Button>
                                    </Styled.ChangeFlowLevelFormButtonsWrapper>
                                </Form>
                            )
                            : accessControl.hasPermission(PN.KYCKYB.SET_APPLICANT_LEVEL)
              && !isInReadOnlyMode
              && (
                  <p>
                      <Button type="primary" onClick={() => dispatchSetIsInEditLevelMode(true)}>
                    Change level
                      </Button>
                  </p>
              )}
                        {children}
                    </Styled.ChangeFlowLevelFormWrapper>

                </div>
            </Spin>
        </Skeleton>
    );
}

KycKybLevelSubTab.propTypes = {
    accessControl: AccessControlProps,
    isInEditLevelMode: PropTypes.bool.isRequired,
    isLoadingCurrentKycKybFlow: PropTypes.bool.isRequired,
    isInReadOnlyMode: PropTypes.bool.isRequired,
    isLoadingSetKycKyb: PropTypes.bool.isRequired,
    entityType: KycKybEntityTypeProp,
    entityId: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    dispatchFetchCurrentKycKybFlow: PropTypes.func.isRequired,
    dispatchSetIsInEditLevelMode: PropTypes.func.isRequired,
    children: PropTypes.node,
};


export default KycKybLevelSubTab;
