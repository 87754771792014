import { createAction } from '@reduxjs/toolkit';

import { CLEAR_RISK_ASSESMENTS_LIST, FETCH_RISK_ASSESMENTS, FETCH_RISK_ASSESMENTS_FAILURE, FETCH_RISK_ASSESMENTS_SUCCESS } from './actions.types';


export const fetchRiskAssessments = createAction(FETCH_RISK_ASSESMENTS,
    (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchRiskAssessmentsSuccess = createAction(FETCH_RISK_ASSESMENTS_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchRiskAssessmentsFailure = createAction(FETCH_RISK_ASSESMENTS_FAILURE);

export const clearRiskAssessmentsList = createAction(CLEAR_RISK_ASSESMENTS_LIST, (locationPathname) => ({ payload: { locationPathname } }));
