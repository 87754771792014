import CardCategoryTag from 'components/common/atoms/tags/CardCategoryTag';
import CardStatusTag from 'components/common/atoms/tags/CardStatusTag';
import CardTypeTag from 'components/common/atoms/tags/CardTypeTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import unlinkButtonRenderer from 'components/common/molecules/value-renderers/unlinkButtonRendener';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createCardDetailsPath } from 'config/routes.helpers';
import { AllCardStatuses, PermanentCardStatusesList } from 'constants/CardModel';
import { PN } from 'constants/PermissionsModel';
import { removeUnderscores } from 'utils/string-tools';
import { sortingOptions } from 'utils/table-columns-sorters';

import { Space } from 'antd';
import React from 'react';


const createCardStatusFilters = () => Object.keys(AllCardStatuses).map((cardStatus) => ({
    text: removeUnderscores(cardStatus),
    value: cardStatus,
}));

function createColumns({
    accessControl,
    queryParams,
    dispatchRequestNavigation,
    onShowUnLinkCardButtonClick,
    t,
}) {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            defaultSortOrder: 'ascend',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'id' }),
        },
        {
            title: t('cards:cardFields.type.text'),
            dataIndex: 'type',
            align: 'center',
            render: (type) => <CardTypeTag cardType={type} t={t} showTooltip />,
            ...sortingOptions({ queryParams, fieldName: 'type' }),
        },
        {
            title: t('cards:cardFields.category.text'),
            dataIndex: 'card_type',
            align: 'center',
            render: (card_type) => <CardCategoryTag cardCategory={card_type} t={t} showTooltip />,
        },
        {
            title: t('cards:cardFields.cardStatus.text'),
            dataIndex: 'card_status',
            align: 'center',
            render: (card_status) => <CardStatusTag cardStatus={card_status} t={t} showTooltip />,
            ...sortingOptions({ queryParams, fieldName: 'card_status' }),
            filters: createCardStatusFilters(),
            filteredValue: queryParams?.card_status ?? undefined,
            filterMultiple: true,
        },
        {
            title: t('cards:cardFields.embossName.text'),
            dataIndex: 'emboss_name',
            render: (name) => <StandardValue value={name} searchQuery={queryParams?.search} />,
        },
        {
            title: t('cards:cardFields.maskedPan.text'),
            dataIndex: 'pan_mask',
            align: 'center',
            render: (maskedPan) => <StandardValue value={maskedPan} searchQuery={queryParams?.search} />,
        },
        {
            title: t('cards:cardFields.cardToken.text'),
            dataIndex: 'token',
            align: 'center',
            render: (token) => <StandardValue value={token} searchQuery={queryParams?.search} />,
            ...sortingOptions({ queryParams, fieldName: 'token' }),
        },
        // XXX accessControl.hasPermission check is preformed over onShowUnLinkCardButtonClick, can be undefined
        ...(onShowUnLinkCardButtonClick || accessControl.hasPermission(PN.CARDS.READ_BY_ID) ? [{
            title: 'Actions',
            align: 'center',
            render: (balance, record) => (
                <Space>
                    {onShowUnLinkCardButtonClick
              && !PermanentCardStatusesList.includes(record.card_status)
              && unlinkButtonRenderer(
                  record,
                  onShowUnLinkCardButtonClick,
                  t('cards:buttons.unlinkCard.tooltip'),
              )}

                    {accessControl.hasPermission(PN.CARDS.READ_BY_ID) && viewButtonRenderer({
                        record,
                        onClick: (event, record) => dispatchRequestNavigation(createCardDetailsPath(record.id)),
                        tooltipContent: t('cards:buttons.viewCard.tooltip'),
                        dataTestIdSuffix: 'card',
                    })}
                </Space>
            ),
        }] : []),
    ];
}

export default createColumns;
