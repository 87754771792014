import AuthorisedLayout from 'components/layouts/AuthorisedLayout/AuthorisedLayout.layout';
import { applicationUIReducerName } from 'redux/application/reducer';
import { authorisationReducerName } from 'redux/authorisation/reducer';
import { clearCurrentUser } from 'redux/current-user/actions';

import { connect } from 'react-redux';


const mapStateToProps = (state) => ({
    applicationState: state[applicationUIReducerName].applicationState,
    isRefreshingSession: state[authorisationReducerName]?.isRefreshingSession,
    shouldFetchGlobalData: state[applicationUIReducerName]?.shouldFetchGlobalData,
});

const mapDispatchToProps = (dispatch) => ({ dispatchClearCurrentUser: () => dispatch(clearCurrentUser()) });

export default connect(mapStateToProps, mapDispatchToProps)(AuthorisedLayout);

