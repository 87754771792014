import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import StandardValue from './StandardValue.layout';
import { StandardValueOwnProps, StandardValueStateProps } from './StandardValue.types';


const mapStateToProps = (state: RootState): StandardValueStateProps => ({ userPreferences: state.currentUser?.userPreferences });


const decorate = compose<ComponentType<StandardValueOwnProps>>(
    connect(mapStateToProps),
    withTranslation('common'),
);

export default decorate(StandardValue);
