import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createLimitDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { AccessControlContent } from 'models/domain/accessControl';
import { RuleListItem, RuleScope } from 'models/domain/rules';
import { enumValueToReadableString, removeUnderscores } from 'utils/string-tools';
import { sortStringsAlphabetically, sortingOptions } from 'utils/table-columns-sorters';

import { TFunction } from 'i18next';
import React from 'react';

import { createRuleTypeOptions, createTransactionTypeOptions } from './LimitsList.helpers';


export const getRuleScope = (record:RuleListItem) => {
    const handlers = [{
        predicate: () => record?.extGroupId,
        handler: () => RuleScope.GROUP,
    },
    {
        predicate: () => record?.extInstitutionId,
        handler: () => RuleScope.INSTITUTION,
    },
    {
        predicate: () => true,
        handler: () => RuleScope.GLOBAL,
    }];
    return handlers.find((handler) => handler.predicate())?.handler();
};


function createTableColumns({
    t,
    queryParams,
    accessControl,
    dispatchRequestNavigation,
}: {
  t:TFunction,
  accessControl: AccessControlContent,
  queryParams: any,
  dispatchRequestNavigation: (payload:any) =>void,
}) {
    const filterValue = queryParams?.search;
    return [
        {
            title: t('common:table.columnNames.uuid'),
            dataIndex: 'extId',
            render: (text) => <StandardValue value={text} searchQuery={filterValue} />,
            width: 120,
        },
        {
            title: t('limits:limitFields.name'),
            dataIndex: 'ruleName',
            render: (text) => <StandardValue value={text} searchQuery={filterValue} />,
            width: 90,
        },
        {
            title: t('limits:limitFields.extInstitutionId'),
            dataIndex: 'extInstitutionId',
            render: (text) => <StandardValue value={text} searchQuery={filterValue} />,
            width: 90,
        },
        {
            title: t('limits:limitFields.extGroupId'),
            dataIndex: 'extGroupId',
            render: (text) => <StandardValue value={text} searchQuery={filterValue} />,
            width: 90,
        },
        {
            title: t('limits:limitFields.ruleScope'),
            dataIndex: 'ruleScope',
            render: (_, record) => <StandardValue value={t(`limits:ruleScopes.${getRuleScope(record)}`)} />,
            width: 70,
        },
        {
            title: t('limits:limitFields.ruleType'),
            dataIndex: 'ruleType',
            filters: createRuleTypeOptions(t),
            filteredValue: queryParams?.ruleType ?? undefined,
            filterMultiple: false,
            render: (text) => <StandardValue value={enumValueToReadableString(text)} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'ruleType',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'ruleType'),
            }),
            width: 70,
        },
        {
            title: t('limits:limitFields.ruleTransactionType'),
            dataIndex: 'ruleTransactionType',
            filters: createTransactionTypeOptions(),
            filteredValue: queryParams?.ruleTransactionType ?? undefined,
            filterMultiple: false,
            render: (text) => <StandardValue value={removeUnderscores(text)} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'ruleTransactionType',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'ruleTransactionType'),
            }),
            width: 70,
        },
        {
            title: t('limits:limitFields.limitOccurrence'),
            dataIndex: 'limitOccurrence',
            render: (text) => <StandardValue value={enumValueToReadableString(text)} />,
            width: 70,
        },
        {
            title: t('limits:limitFields.limitType'),
            dataIndex: 'limitType',
            render: (text) => <StandardValue value={enumValueToReadableString(text)} />,
            width: 90,
        },
        {
            title: t('limits:limitFields.triggerAmount'),
            dataIndex: 'ruleTriggerAmount',
            align: 'center',
            render: (text) => <StandardValue value={text} />,
            width: 90,
        },
        {
            title: t('limits:limitFields.ruleActions'),
            dataIndex: 'ruleActions',
            render: (actions) => <StandardValue value={`${actions.map((action) => enumValueToReadableString(action)).join(', ')}`} />,
            width: 90,
        },
        {
            title: t('common:table.columnNames.actions'),
            align: 'center',
            render: (_, record) => (
                accessControl.hasPermission(PN.RULES.READ_BY_UUID) && viewButtonRenderer({
                    record,
                    onClick: (_, record) => dispatchRequestNavigation(createLimitDetailsPath(record.extId)),
                    tooltipContent: t('limits:buttons.viewLimitDetails'),
                    dataTestId: 'view-limit-details',
                })
            ),
            width: 90,
        },
    ];
}

export default createTableColumns;
