import styled from 'styled-components';


const MultiStepFormButtonsWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, min-content) minmax(auto, min-content) minmax(auto, min-content);
  grid-column-gap: 16px;
  justify-content: center;
  background: #fff;
  padding: 16px;
  
  .ant-form-item {
    margin: 0;
  }
`;

const EditModeInfoWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  bottom: 16px;
  max-width: 270px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  font-size: 24px;
  align-items: center;
`;

export default {
    MultiStepFormButtonsWrapper,
    EditModeInfoWrapper,
};
