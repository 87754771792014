import StandardTag from 'components/common/atoms/StandardTag';
import { KycKybAuditReviewAnswerTypes } from 'constants/KycKybModel';

import PropTypes from 'prop-types';
import React from 'react';


const mapReviewAnswerToLabelAndColor = (value) => {
    const handlers = [
        {
            predicate: () => value === KycKybAuditReviewAnswerTypes.GREEN,
            handler: () => ({
                color: 'green',
                label: 'Approved',
            }),
        },
        {
            predicate: () => value === KycKybAuditReviewAnswerTypes.RED,
            handler: () => ({
                color: 'red',
                label: 'Rejected',
            }),
        },
        {
            predicate: () => value === KycKybAuditReviewAnswerTypes.YELLOW,
            handler: () => ({
                color: 'yellow',
                label: 'Manual review',
            }),
        },
        {
            predicate: () => true,
            handler: () => ({ label: 'UNKNOWN' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

function KycKybReviewAnswerTag({ value, showTooltip = false }) {
    if (value) {
        const renderData = mapReviewAnswerToLabelAndColor(value);

        return (
            <StandardTag
                color={renderData.color}
                text={renderData.label}
                showTooltip={showTooltip}
            />
        );
    }

    return '-';
}


KycKybReviewAnswerTag.propTypes = {
    value: PropTypes.oneOf(Object.values(KycKybAuditReviewAnswerTypes)).isRequired,
    showTooltip: PropTypes.bool,
};

export default KycKybReviewAnswerTag;
