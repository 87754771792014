import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchBeneficiariesListFailure, fetchBeneficiariesListSuccess } from './actions';
import { FETCH_BENEFICIARIES_LIST } from './actions.types';


export const onFetchBeneficiariesList = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_BENEFICIARIES_LIST),
    switchMap(({ payload }) => from(beneficiaries.fetchBeneficiaries(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchBeneficiariesListSuccess(response.data.rows, response.data.count, payload.locationPathname))),
        catchError(() => of(fetchBeneficiariesListFailure(payload.locationPathname))),
    )),
);


export default [
    onFetchBeneficiariesList,
];

