import { withdrawBalance } from 'redux/account/actions';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import WithdrawAccountBalanceModal from './WithdrawAccountBalanceModal.layout';


const mapStateToProps = (state) => ({ enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation });

const mapDispatchToProps = (dispatch) => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchWithdrawBalance: (formData, locationPathname) => dispatch(withdrawBalance(formData, locationPathname)),
});


const decorate = compose(
    withTranslation('accounts'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(WithdrawAccountBalanceModal);

