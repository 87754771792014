import { createAction } from '@reduxjs/toolkit';


export const REQUEST_NAVIGATION = 'REQUEST_NAVIGATION';
export const requestNavigation = createAction(REQUEST_NAVIGATION, (pathname, meta) => ({
    payload: pathname,
    meta,
}));

export const BLOCK_NAVIGATION = 'BLOCK_NAVIGATION';
export const blockNavigation = createAction(BLOCK_NAVIGATION, (onStop, reason) => ({ payload: onStop, meta: reason }));
export const UNBLOCK_NAVIGATION = 'UNBLOCK_NAVIGATION';
export const unblockNavigation = createAction(UNBLOCK_NAVIGATION);

export const NAVIGATION_STOPPED = 'NAVIGATION_STOPPED';
export const navigationStopped = createAction(NAVIGATION_STOPPED, (location, meta) => ({
    payload: location,
    meta,
}));

export const SHOW_NAVIGATION_CONFIRMATION = 'SHOW_NAVIGATION_CONFIRMATION';
export const showNavigationConfirmation = createAction(SHOW_NAVIGATION_CONFIRMATION);
export const HIDE_NAVIGATION_CONFIRMATION = 'HIDE_NAVIGATION_CONFIRMATION';
export const hideNavigationConfirmation = createAction(HIDE_NAVIGATION_CONFIRMATION);

export const NAVIGATION_CANCELED = 'NAVIGATION_CANCELED';
export const navigationCanceled = createAction(NAVIGATION_CANCELED);


export const PREFORM_NAVIGATION_SUCCESS = 'PREFORM_NAVIGATION_SUCCESS';
export const preformNavigationSuccess = createAction(PREFORM_NAVIGATION_SUCCESS, (location) => ({ payload: location }));


export const REQUEST_NAVIGATION_BACK = 'REQUEST_NAVIGATION_BACK';
export const requestNavigationBack = createAction(REQUEST_NAVIGATION_BACK, (location) => ({ payload: location }));

export const PREFORM_NAVIGATION_BACK_SUCCESS = 'PREFORM_NAVIGATION_BACK_SUCCESS';
export const preformNavigationBackSuccess = createAction(PREFORM_NAVIGATION_BACK_SUCCESS, (location) => ({ payload: location }));

export const REDIRECTION_TO_DEFAULT_PATH = 'REDIRECTION_TO_DEFAULT_PATH';
export const redirectionToDefaultPath = createAction(REDIRECTION_TO_DEFAULT_PATH, (location) => ({ payload: location }));


export default {
    REQUEST_NAVIGATION,
    requestNavigation,
    BLOCK_NAVIGATION,
    blockNavigation,
    UNBLOCK_NAVIGATION,
    unblockNavigation,
    NAVIGATION_CANCELED,
    navigationCanceled,
    PREFORM_NAVIGATION_SUCCESS,
    preformNavigationSuccess,
    REQUEST_NAVIGATION_BACK,
    requestNavigationBack,
    PREFORM_NAVIGATION_BACK_SUCCESS,
    preformNavigationBackSuccess,
    REDIRECTION_TO_DEFAULT_PATH,
    redirectionToDefaultPath,
};
