import { TableComponentProps } from 'components/common/organisms/StandardListTable/StandardTable/propTypes';
import { standardPaginationOptions } from 'config/config';

import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';


import Styled from './StandardTable.styled';


const antIcon = <LoadingOutlined spin />;

function RawTable({
    rowKey,
    totalItemsCount,
    onChange,
    columnsConfig,
    dataSource,
    isTableLoading,
    queryParams,
    showTotal = true,
}) {
    return (
        <Styled.StandardListTable
            tableLayout="auto"
            rowKey={rowKey}
            scroll={{ x: true }}
            loading={isTableLoading
                ? {
                    indicator: antIcon,
                    size: 'large',
                    spinning: true,
                } : false}
            pagination={{
                responsive: true,
                hideOnSinglePage: false,
                showSizeChanger: true,
                total: totalItemsCount,
                current: queryParams.offset / queryParams.limit + 1,
                pageSize: queryParams?.limit,
                showTotal: showTotal
                    ? (total, range) => `Viewing ${range[0]}-${range[1]} of ${total} items`
                    : undefined,
                pageSizeOptions: standardPaginationOptions,
            }}
            onChange={onChange}
            columns={columnsConfig}
            dataSource={dataSource}
        />
    );
}

RawTable.propTypes = TableComponentProps.props;
RawTable.defaultProps = TableComponentProps.default;


export default RawTable;
