export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
export const CLEAR_COMPANIES_LIST = 'CLEAR_COMPANIES_LIST';


export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE';

export const OPEN_ADD_COMPANY_MODAL = 'OPEN_ADD_COMPANY_MODAL';

export const CLEAR_COMPANIES_STATE = 'CLEAR_COMPANIES_STATE';
