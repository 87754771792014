import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import ClientInfo from 'components/pages/authorised/ClientDetails/ClientInfo';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AddressCommonProps } from 'constants/AddressModel';
import { CommonQueryParamsMetaProps } from 'constants/ApplicationStateModel';
import { ClientDetailsProps } from 'constants/ClientModel';
import { createEntityDetailsProps } from 'constants/EntityDetailsModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';


import { createClientDetailsTabsConfig } from './ClientDetails.helpers';


function ClientDetails({
    accessControl,

    enhancedCurrentLocation,
    entityDetailsData,
    dispatchFetchClientGroups,
    dispatchOpenAddAccountModal,
    dispatchOpenChargeCustomFeeModal,
    dispatchOpenTriggerManualFeeModal,
    dispatchFetchClientDetails,
    dispatchClearClientDetails,
    dispatchFetchClientAddress,
    dispatchSetQueryParams,
    addressData,
    t,
}) {
    const { clientId } = useParams();
    const fixedId = Number(clientId);
    const entityDetails = entityDetailsData?.entityDetails;

    const baseURLSegmentsCount = 2; // e.g. /(1) card /(2) CARD_ID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);
    const contextEnforcedQueryParams = { clientId: fixedId };

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: (queryParams, locationPathname) => {
            dispatchFetchClientDetails(queryParams, locationPathname);
            dispatchFetchClientAddress(queryParams, locationPathname);
        },
        dispatchClearEntityDetails: (locationPathname) => dispatchClearClientDetails(locationPathname),
        dispatchSetQueryParams,
    });

    const tabsConfig = useMemo(
        () => (fixedId
    && accessControl
    && enhancedCurrentLocation
    && entityDetails?.id > 0
            ? createClientDetailsTabsConfig({
                accessControl,
                clientId: fixedId,
                entityDetails,
                dispatchFetchClientGroups,
                dispatchOpenAddAccountModal,
                dispatchOpenChargeCustomFeeModal,
                dispatchOpenTriggerManualFeeModal,
                returnPath: enhancedCurrentLocation?.pathname,
                currentLocation: enhancedCurrentLocation?.pathname,
                t,
            })
            : undefined),
        [fixedId, accessControl, entityDetails, t],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('clients:clientDetails.screenTitle', { clientId: fixedId })}
            tabsConfig={tabsConfig}
            baseURLSegmentsCount={2}
            entityClientId={fixedId}
        >
            <ClientInfo
                clientId={fixedId}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                client={entityDetails}
                areDetailsReay={isDetailsPageInitialStateDefined}
                isLoading={entityDetailsData?.isLoadingDetails}
                shippingAddress={addressData?.entityDetails?.shippingAddress}
                billingAddress={addressData?.entityDetails?.billingAddress}
                isLoadingAddress={addressData?.isLoadingDetails}
                onRefresh={() => {
                    dispatchFetchClientDetails(contextEnforcedQueryParams, baseUrlWithoutTabsSlugs);
                    dispatchFetchClientAddress(contextEnforcedQueryParams, baseUrlWithoutTabsSlugs);
                }}
            />
        </StandardEntityDetailsWithTabs>
    );
}

ClientDetails.propTypes = {
    accessControl: AccessControlProps,
    dispatchOpenAddAccountModal: PropTypes.func.isRequired,
    dispatchOpenChargeCustomFeeModal: PropTypes.func.isRequired,
    dispatchOpenTriggerManualFeeModal: PropTypes.func.isRequired,
    dispatchFetchClientGroups: PropTypes.func.isRequired,
    entityDetailsData: createEntityDetailsProps(ClientDetailsProps),
    addressData: PropTypes.shape({
        entityDetails: PropTypes.shape({
            shippingAddress: AddressCommonProps,
            billingAddress: AddressCommonProps,
        }),
        isLoadingDetails: PropTypes.bool,
        queryParams: PropTypes.shape({}),
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    t: PropTypes.func.isRequired,
    dispatchFetchClientDetails: PropTypes.func.isRequired,
    dispatchFetchClientAddress: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchClearClientDetails: PropTypes.func.isRequired,
};

export default ClientDetails;
