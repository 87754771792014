import CardsList from 'components/common/templates/lists/CardsList';
import { cardsOrderRootNestedRoutePath } from 'config/routes';
import { AllEntityTypes } from 'constants/ApplicationStateModel';
import ModalName from 'constants/Modals';
import {
    permissionSetLinkCard,
    permissionSetOrderCardsTargetPreSelected,
    PN,
} from 'constants/PermissionsModel';
import { UnlinkCardViews } from 'redux/card/epic.helpers';

import React from 'react';


import { MemberCardProps } from './MemberCards.types';


function MemberCards({
    accessControl,

    memberId,
    memberFirstName,
    memberLastName,
    clientId,

    isInReadOnlyMode = true,

    dispatchShowModal,
    dispatchShowUnlinkCardModal,
    dispatchRequestNavigation,

    t,
}:MemberCardProps) {
    const memberFullName = `${memberFirstName} ${memberLastName}`;

    const onOrderCardsButtonClick = accessControl.hasPermissions(permissionSetOrderCardsTargetPreSelected)
    && !isInReadOnlyMode
        ? () => dispatchRequestNavigation(cardsOrderRootNestedRoutePath, {
            state: {
                clientId,
                memberId,
                memberEmbossName: memberFullName,
            },
        })
        : undefined;

    const onShowLinkCardButtonClick = accessControl.hasPermissions(permissionSetLinkCard) && !isInReadOnlyMode
        ? () => dispatchShowModal({
            modalType: ModalName.LINK_CARD_MODAL,
            modalProps: {
                clientId,
                memberId,
                title: t('modals.linkCard.title.linkToMember', { name: memberFullName }),
                hideOkButton: true,
            },
        })
        : undefined;

    const onShowUnLinkCardButtonClick = accessControl.hasPermission(PN.CARDS.UNLINK) && !isInReadOnlyMode
        ? (event, record) => dispatchShowUnlinkCardModal(record, {
            view: UnlinkCardViews.MEMBER,
            id: record.member_id,
        })
        : undefined;

    return (
        <CardsList
            entityId={Number(memberId)}
            entityType={AllEntityTypes.MEMBER}
            showSearchField
            onOrderCardsButtonClick={onOrderCardsButtonClick}
            onShowLinkCardButtonClick={onShowLinkCardButtonClick}
            onShowUnLinkCardButtonClick={onShowUnLinkCardButtonClick}
        />
    );
}

export default MemberCards;
