import styled from 'styled-components';


export const HeaderWrapper = styled.div`
  position: relative;
  padding-right: 50px;
`;

export const NewIndicatorWrapper = styled.div`
  position: absolute;
  right: -9px;
  top: -4px;
  transform: rotate(-30deg);
`;


export const ChangeLogEntryWrapper = styled.div`
   ul {
    list-style-type: circle;
     font-weight: 700;
     
    
    & > li > ul {
      list-style-type: disc;
      font-weight: 400;
    }
  }
`;

export const ChangeLogStatusInfoWrapper = styled.div`
  text-align: left;
  padding-bottom: 20px;
  
  & > div {
    grid-template-columns: minmax(0px, min-content) minmax(0px, min-content) !important;
    column-gap: 0;
    font-weight: 700;
    
    div + div {
      justify-self: left;
      font-weight: 400;
    }
  }
`;


export default { ChangeLogEntryWrapper, HeaderWrapper, NewIndicatorWrapper, ChangeLogStatusInfoWrapper };
