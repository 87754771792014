import { createClientDetailsFeesListPath, createClientDetailsGroupsListPath } from 'config/routes.helpers';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { fetchFeesList } from 'redux/fees/actions';
import { feesListsPerLocationStoreKeyName, feesReducerName } from 'redux/fees/reducer';
import { fetchGroupsList } from 'redux/groups/actions';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { blockNavigation, showNavigationConfirmation, unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { createPromotion } from 'redux/promotions/actions';
import { promotionsReducerName } from 'redux/promotions/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddPromotion from './AddPromotion.layout';


const mapStateToProps = (state, ownProps) => ({
    userPreferences: state[currentUserReducerName]?.userPreferences,
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    isBlockNavigationReasonSet: state[navigationReducerName].blockNavigationReason,

    feesListData: getStandardListData({
        state,
        reducerName: feesReducerName,
        listsByLocationStoreKeyName: feesListsPerLocationStoreKeyName,
        customLocation: createClientDetailsFeesListPath(ownProps.fixedClientId),
    }),
    groupsListData: getStandardListData({
        state,
        reducerName: groupsReducerName,
        listsByLocationStoreKeyName: groupsListsPerLocationStoreKeyName,
        customLocation: createClientDetailsGroupsListPath(ownProps.fixedClientId),
    }),
    currenciesList: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

    isLoading: state[promotionsReducerName]?.isLoadingAddPromotion,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchFetchFees: (queryParams) => dispatch(fetchFeesList(queryParams, createClientDetailsFeesListPath(ownProps.fixedClientId))),
    dispatchFetchGroups: (queryParams) => dispatch(fetchGroupsList(queryParams, createClientDetailsGroupsListPath(ownProps.fixedClientId))),
    dispatchCreatePromotion: (requestPayload, locationPathname) => dispatch(createPromotion(requestPayload, locationPathname)),

    dispatchBlockNavigation: (reason) => dispatch(blockNavigation(showNavigationConfirmation(), reason)),
    dispatchUnblockNavigation: () => dispatch(unblockNavigation()),
});


const decorate = compose(
    withTranslation('promotions'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddPromotion);

