import { AccountOwnerTypes } from 'constants/AccountModel';
import withAccessControl from 'hocs/withAccessControl';
import { openAddAccountModal } from 'redux/accounts/actions';
import { clearReadOnlyMode, setQueryParams, setReadOnlyMode } from 'redux/application/actions';
import { clearCompanyDetails, fetchCompanyDetails } from 'redux/company/actions';
import { companyDetailsPerLocationsStoreKeyName, companyReducerName } from 'redux/company/reducer';
import { showModal } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import CompanyDetails from './CompanyDetails.layout';


const mapStateToProps = (state) => ({
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: companyReducerName,
        fieldName: companyDetailsPerLocationsStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
    enhancedCurrentLocation: state[navigationReducerName]?.enhancedCurrentLocation,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchCompanyDetails: (queryParams, locationPathname) => dispatch(fetchCompanyDetails(queryParams, locationPathname)),
    dispatchOpenAddAccountModal: (configData, locationPathname) => dispatch(openAddAccountModal(AccountOwnerTypes.COMPANY, configData, locationPathname)),
    dispatchShowModal: (configData) => dispatch(showModal(configData)),
    dispatchClearCompanyDetails: () => dispatch(clearCompanyDetails()),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: companyReducerName,
        fieldName: companyDetailsPerLocationsStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchSetReadOnlyMode: (readOnlyInfo) => dispatch(setReadOnlyMode(readOnlyInfo)),
    dispatchClearReadOnlyMode: () => dispatch(clearReadOnlyMode()),
});


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CompanyDetails);

