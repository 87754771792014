import { createClientSettingsSubStepsConfig } from 'components/common/organisms/forms-shared-parts/add-edit-client-form/common-steps-configurations/settingsSubSteps.helpers';
import { createSuccessStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-client-form/common-steps-configurations/successStep.helpers';


export function createEditClientSettingsStepsConfiguration({
    t,
    parsedSettingsConfig,
}) {
    // XXX order DOES matter
    return [
    // API DRIVEN DYNAMIC SETTINGS
        createClientSettingsSubStepsConfig({
            parsedSettingsConfig,
            t,
        }),

        // STATIC (except partner ID for 'View new partner' link)
        createSuccessStepConfig({ t }),
    ];
}

export default { createEditClientSettingsStepsConfiguration };
