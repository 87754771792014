import { baseApiUrl, developmentMode, nodeEnv, WL_CONFIG } from 'config/config';
import { publicIpv4NoFtpWebURLRegex, trimValue } from 'utils/validation-tools';

import { WarningOutlined } from '@ant-design/icons';
import React from 'react';


// eslint-disable-next-line spellcheck/spell-checker

import Styled from './AppHasInvalidConfigPlaceholder.styled';


export const getInstanceConfigurationErrors = () => {
    const handlers = [
        {
            predicate: () => !['production', 'development'].includes(nodeEnv),
            handler: () => 'Invalid NODE_ENV! Only "production" & "development" are allowed as values.',
        },

        ...(!developmentMode ? [{
            // eslint-disable-next-line spellcheck/spell-checker
            predicate: () => !(baseApiUrl || '').match(publicIpv4NoFtpWebURLRegex),
            handler: () => 'Invalid REACT_APP_BASE_API_URL! Only public, IPv4 & https-only addresses are allowed.',
        }] : []),

        {
            predicate: () => trimValue(WL_CONFIG.brandShortName || '').length < 1,
            handler: () => 'Invalid REACT_APP_BRAND_SHORT_NAME! At least one non-whitespace character is required.',
        },
        {
            predicate: () => trimValue(WL_CONFIG.brandFullName || '').length < 3,
            handler: () => 'Invalid REACT_APP_BRAND_FULL_NAME! At least 3 non-whitespace characters are required.',
        },
        {
            predicate: () => trimValue(WL_CONFIG.applicationName || '').length < 3,
            handler: () => 'Invalid REACT_APP_APPLICATION_NAME! At least 3 non-whitespace characters are required.',
        },
    ];

    return handlers.filter(({ predicate }) => predicate()).map(((item, index) => item.handler(index)));
};

export const renderInstanceConfigErrorInfo = (errorMessage) => (
    <Styled.InvalidConfigErrorInfoWrapper key={errorMessage}>
        <WarningOutlined />
        <span>{errorMessage}</span>
    </Styled.InvalidConfigErrorInfoWrapper>
);

export const renderInstanceConfigErrors = ({ instanceConfigurationErrors }) => (
    <Styled.InvalidConfigErrorsInfoWrapper>
        {instanceConfigurationErrors.map((errorMessage) => renderInstanceConfigErrorInfo(errorMessage))}
    </Styled.InvalidConfigErrorsInfoWrapper>
);
