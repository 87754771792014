import CountryOrCurrencyFlag from 'components/common/atoms/CountryOrCurrencyFlag';
import StandardTooltip from 'components/common/molecules/StandardTooltip';

import React from 'react';

import Styled from './FlagAndValue.styled';
import { FlagAndValueProps } from './FlagAndValue.types';


function FlagAndValue({
    currencyCode = undefined,
    countryCode = undefined,
    size = 20,
    text = '-',
    inlineMode = false,
    valueFirst = false,
    isTextEllipsisDisabled = false,
    showTooltip = false,
    showCopyToClipboard = false,
    useCountryISOAlpha3Code = false,
}: FlagAndValueProps) {
    const flag = (
        <CountryOrCurrencyFlag
            currencyCode={currencyCode}
            countryCode={countryCode}
            useCountryISOAlpha3Code={useCountryISOAlpha3Code}
            size={size}
        />
    );

    const value = isTextEllipsisDisabled ? text : (
        <Styled.Value>
            {text}
        </Styled.Value>
    );

    const flagAndValue = (
        <Styled.FlagAndValueWrapper
            $valueFirst={valueFirst}
            $inlineMode={inlineMode}
        >
            {valueFirst ? (
                <>
                    {value}
                    {flag}
                </>
            ) : (
                <>
                    {flag}
                    {value}
                </>
            )}
        </Styled.FlagAndValueWrapper>
    );

    return showTooltip ? (
        <StandardTooltip
            title={text}
            showCopyToClipboard={showCopyToClipboard}
            isTextEllipsisDisabled={isTextEllipsisDisabled}
        >
            {flagAndValue}
        </StandardTooltip>
    ) : flagAndValue;
}

export default FlagAndValue;
