import { CommonFormFieldTypes, OpenFormFieldTypes } from 'constants/MultiStepsFormModel';

import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import { renderStandardFormControlFromConfig, renderStandardFormControlFromItemLabel } from './StandardFormItem.helpers';
import Styled from './StandardFormItem.styled';


function StandardFormItem({ fieldConfig, t }) {
    const formInstance = Form.useFormInstance();

    const createFieldPlaceholder = (fieldConfig) => {
        if (OpenFormFieldTypes.includes(fieldConfig.fieldType)) {
            return `Enter ${fieldConfig.label?.toLowerCase()}`;
        }
        return `Select ${fieldConfig.label?.toLowerCase()}`;
    };


    return fieldConfig?.name?.length > 0
        ? (
            <Styled.StandardFormItemWrapper key={`form-field-${fieldConfig.name}`}>
                <Form.Item
                    name={fieldConfig.name}
                    label={renderStandardFormControlFromItemLabel({ fieldConfig, t })}
                    valuePropName={fieldConfig.fieldType === CommonFormFieldTypes.BOOLEAN ? 'checked' : undefined}
                    rules={fieldConfig.validationRules}
                    hidden={fieldConfig?.metadata?.is_hidden}
                >
                    {renderStandardFormControlFromConfig({
                        fieldConfig,
                        formInstance,
                        placeholder: createFieldPlaceholder(fieldConfig),
                        t,
                    })}
                </Form.Item>
            </Styled.StandardFormItemWrapper>
        )
        : null;
}

StandardFormItem.propTypes = {
    filedConfig: PropTypes.shape({}),
    formInstance: PropTypes.shape({}),
    t: PropTypes.func,
};

export default StandardFormItem;
