import { showSuccessToast } from 'redux/application/actions';
import { FETCH_KYC_KYB_LOGS_LIST } from 'redux/kyc-kyb/actions.types';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    setKycKybFlowSuccess,
    setKycKybFlowFailure,
    triggerKycKybFlowSuccess,
    triggerKycKybFlowFailure,
    fetchCurrentKycKybFlowSuccess,
    fetchCurrentKycKybFlowFailure,
    setIsInEditLevelMode,
    fetchKycKybLogsListSuccess,
    fetchKycKybLogsListFailure, fetchCurrentKycKybFlow,
} from './actions';
import createResetKycKybRefreshActions from './epics.helpers';


export const onFetchCurrentKycKybFlow = (action$, state$, { kycKyb }) => action$.pipe(
    ofType('kycKyb/fetchCurrentKycKybFlow'),
    switchMap(({ payload }) => from(kycKyb.getKycKybFlows(payload)).pipe(
        switchMap((response) => of(fetchCurrentKycKybFlowSuccess(response.data))),
        catchError(() => of(fetchCurrentKycKybFlowFailure())),
    )),
);

export const onSetKycKybFlow = (action$, state$, { kycKyb, i18n }) => action$.pipe(
    ofType('kycKyb/setKycKybFlow'),
    switchMap(({ payload: { flowType, ...entityIds } }) => from(kycKyb.setKycKybFlow({ flowType, ...entityIds })).pipe(
        switchMap((response) => of(
            setKycKybFlowSuccess(response.data),
            showSuccessToast(i18n.t('Level has been successfully updated.')),
            setIsInEditLevelMode(false),
            fetchCurrentKycKybFlow({ ...entityIds }),
        )),
        catchError(() => of(setKycKybFlowFailure())),
    )),
);

export const onTriggerKycKybFlow = (action$, state$, { kycKyb, i18n }) => action$.pipe(
    ofType('kycKyb/triggerKycKybFlow'),
    switchMap(({ payload }) => from(kycKyb.triggerKycKybFlow(payload?.requestPayload)).pipe(
        switchMap((response) => of(
            triggerKycKybFlowSuccess(response.data),
            showSuccessToast(i18n.t('Verification process has been successfully restarted.')),
            ...createResetKycKybRefreshActions(payload),
        )),
        catchError(() => of(triggerKycKybFlowFailure())),
    )),
);


export const onFetchKycKybLogsList = (action$, state$, { kycKyb }) => action$.pipe(
    ofType(FETCH_KYC_KYB_LOGS_LIST),
    switchMap(({ payload }) => from(kycKyb.getKycKybLogs(payload.queryParams)).pipe(
        switchMap((response) => of(fetchKycKybLogsListSuccess(
            response.data?.rows,
            response.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchKycKybLogsListFailure(payload.locationPathname))),
    )),
);

export default [
    onFetchCurrentKycKybFlow,
    onSetKycKybFlow,
    onTriggerKycKybFlow,
    onFetchKycKybLogsList,
];
