import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchCurrenciesFailure, fetchCurrenciesSuccess } from './actions';
import { FETCH_CURRENCIES } from './actions.types';


export const onFetchCurrencies = (action$, state$, { currencies }) => action$.pipe(
    ofType(FETCH_CURRENCIES),
    switchMap(({ payload }) => from(currencies.fetchCurrencies(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchCurrenciesSuccess(
            response.data,
            response?.data?.length,
            payload?.locationPathname,
        ))),
        catchError(() => of(fetchCurrenciesFailure(payload?.locationPathname))),
    )),
);


export default [
    onFetchCurrencies,
];
