import { showSuccessToast } from 'redux/application/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { fetchFailedWebhookDetailsFailure, fetchFailedWebhookDetailsSuccess, replayFailedWebhookFailure, replayFailedWebhookSuccess } from './actions';
import { FETCH_FAILED_WEBHOOK_DETAILS, REPLAY_FAILED_WEBHOOK } from './actions.types';
import { createOnReplayFailedWebhookRefreshActions } from './epics.hepers';


export const onFetchFailedWebhookDetails = (action$, state$, { webhooks }) => action$.pipe(
    ofType(FETCH_FAILED_WEBHOOK_DETAILS),
    switchMap(({ payload: { queryParams, locationPathname } }) => from(webhooks.fetchFailedWebhookDetails(queryParams))
        .pipe(
            switchMap((response) => of(fetchFailedWebhookDetailsSuccess(response.data, locationPathname))),
            catchError(() => of(fetchFailedWebhookDetailsFailure(locationPathname))),
        )),
);

export const onReplayFailedWebhook = (action$, state$, {
    webhooks,
    i18n,
}) => action$.pipe(
    ofType(REPLAY_FAILED_WEBHOOK),
    switchMap(({ payload }) => from(webhooks.replayFailedWebhook(payload?.queryParams?.webhookUuid))
        .pipe(
            switchMap((response) => of(
                replayFailedWebhookSuccess({ webhookUuid: payload?.queryParams.webhookUuid, ...response.data }, payload.locationPathname),
                showSuccessToast(i18n.t('webhooks:actionMessages.replayWebhookSuccess')),
                ...createOnReplayFailedWebhookRefreshActions(payload, state$),
            )),
            catchError(() => of(
                replayFailedWebhookFailure(payload?.queryParams.webhookUuid, payload.locationPathname),
                ...createOnReplayFailedWebhookRefreshActions(payload, state$),
            )),
        )),
);

export default [
    onFetchFailedWebhookDetails,
    onReplayFailedWebhook,
];

