import withAccessControl from 'hocs/withAccessControl';
import withIsClientActive from 'hocs/withIsClientActive';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';
import { clearTransactionsList, getTransactions } from 'redux/transactions/actions';
import { transactionsListsPerLocationStoreKeyName, transactionsReducerName } from 'redux/transactions/reducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import TransactionsList from './TransactionsList.layout';
import {
    TransactionsListDispatchProps,
    TransactionsListOwnProps,
    TransactionsListStateProps,
} from './TransactionsList.types';


const mapStateToProps = (state: RootState): TransactionsListStateProps => ({
    currentUserClientId: state[currentUserReducerName].userData?.clientId,
    transactionsFilters: state[transactionsReducerName].filters,
    currencies: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: transactionsReducerName,
        listsByLocationStoreKeyName: transactionsListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch: Dispatch): TransactionsListDispatchProps => ({
    dispatchRequestNavigation: (locationPathname, meta) => dispatch(requestNavigation(locationPathname, meta)),
    dispatchGetTransactions: (queryParams, locationPathname) => dispatch(getTransactions(queryParams, locationPathname)),
    dispatchClearTransactionsList: (locationPathname) => dispatch(clearTransactionsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: transactionsReducerName,
        fieldName: transactionsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose<ComponentType<TransactionsListOwnProps>>(
    withTranslation('transactions'),
    withIsClientActive,
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(TransactionsList);
