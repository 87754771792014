import { companyRootRoutePath, businessUserRootRoutePath, memberRootRoutePath } from 'config/routes';
import { showSuccessToast } from 'redux/application/actions';
import { getCardsListPathname } from 'redux/card/epic.helpers';
import { LINK_CARD_TO_BUSINESS_USER, LINK_CARD_TO_BUSINESS_USER_FAILURE, LINK_CARD_TO_BUSINESS_USER_SUCCESS } from 'redux/card-linking/action.types';
import { createStepChangeActions } from 'redux/card-linking/helpers';
import { fetchAllCards } from 'redux/cards/actions';
import { cardsListsPerLocationStoreKeyName, cardsReducerName } from 'redux/cards/reducer';
import { hideModal, setModalProps } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    linkCardToBusinessUserSuccess,
    linkCardToBusinessUserFailure,
    linkCardToMemberFailure,
    linkCardToMemberSuccess,
    clearLinkCardProcess,
    fetchNotLinkedCardsSuccess,
    fetchNotLinkedCardsFailure,
    linkCardToCompanyFailure, linkCardToCompanySuccess,
} from './actions';


export const onLinkCardToBusinessUser = (action$, state$, { cards }) => action$.pipe(
    ofType(LINK_CARD_TO_BUSINESS_USER),
    switchMap(({ payload }) => from(cards.linkCard(payload.cardId, payload.requestPayload)).pipe(
        switchMap((response) => of(linkCardToBusinessUserSuccess(payload.requestPayload.businessUserId, response.data))),
        catchError(() => of(linkCardToBusinessUserFailure())),
    )),
);

export const onLinkCardToBusinessUserSuccess = (action$, state$, { i18n }) => action$.pipe(
    ofType(LINK_CARD_TO_BUSINESS_USER_SUCCESS),
    switchMap(({ payload }) => {
        const pathname = getCardsListPathname(businessUserRootRoutePath, payload.businessUserId);
        const currentCardsListQueryParams = state$.value[cardsReducerName][cardsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;
        return of(
            hideModal(),
            clearLinkCardProcess(),
            fetchAllCards(currentCardsListQueryParams, pathname),
            showSuccessToast(i18n.t('cards:actionMessages.linkCardSuccess')),
        );
    }),
);


export const onLinkCardToCompany = (action$, state$, { cards }) => action$.pipe(
    ofType('card-linking/linkCardToCompany'),
    switchMap(({ payload }) => from(cards.linkCard(payload.cardId, payload.requestPayload)).pipe(
        switchMap((response) => of(linkCardToCompanySuccess(payload.requestPayload.companyId, response.data))),
        catchError(() => of(linkCardToCompanyFailure())),
    )),
);

export const onLinkCardToCompanySuccess = (action$, state$, { i18n }) => action$.pipe(
    ofType('card-linking/linkCardToCompanySuccess'),
    switchMap(({ payload }) => {
        const pathname = getCardsListPathname(companyRootRoutePath, payload.companyId);
        const currentCardsListQueryParams = state$.value[cardsReducerName][cardsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;
        return of(
            hideModal(),
            clearLinkCardProcess(),
            fetchAllCards(currentCardsListQueryParams, pathname),
            showSuccessToast(i18n.t('cards:actionMessages.linkCardSuccess')),
        );
    }),
);


export const onLinkCardToMember = (action$, state$, { cards }) => action$.pipe(
    ofType('card-linking/linkCardToMember'),
    switchMap(({ payload }) => from(cards.linkCard(payload.cardId, payload.requestPayload)).pipe(
        switchMap((response) => of(linkCardToMemberSuccess(payload.requestPayload.memberId, response.data))),
        catchError(() => of(linkCardToMemberFailure())),
    )),
);


export const onLinkCardToMemberSuccess = (action$, state$, { i18n }) => action$.pipe(
    ofType('card-linking/linkCardToMemberSuccess'),
    switchMap(({ payload }) => {
        const pathname = getCardsListPathname(memberRootRoutePath, payload.memberId);
        const currentCardsListQueryParams = state$.value[cardsReducerName][cardsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

        return of(
            hideModal(),
            clearLinkCardProcess(),
            fetchAllCards(currentCardsListQueryParams, pathname),
            showSuccessToast(i18n.t('cards:actionMessages.linkCardSuccess')),
        );
    }),
);
export const onLinkCardFailure = (action$) => action$.pipe(
    ofType('card-linking/linkCardToCompanyFailure', 'card-linking/linkCardToMemberFailure', LINK_CARD_TO_BUSINESS_USER_FAILURE),
    switchMap(() => of(
        setModalProps({
            confirmLoading: false,
            cancelButtonProps: { disabled: false },
        }),
    )),
);

export const onSetCardLinkingStep = (action$, _, { i18n, http }) => action$.pipe(
    ofType('card-linking/setCardLinkingStep'),
    switchMap(({ payload }) => createStepChangeActions(payload, i18n, http)),
);

export const onFetchNotLinkedCards = (action$, state$, { cards }) => action$.pipe(
    ofType('card-linking/fetchNotLinkedCards'),
    switchMap(({ payload }) => {
        const resultPayload = {
            ...payload,
            ...(state$.value.cardLinking.withPartnerCards && { withPartnerCards: state$.value.cardLinking.withPartnerCards }),
        };

        return from(cards.getNotLinkedCards(resultPayload))
            .pipe(
                switchMap((response) => of(fetchNotLinkedCardsSuccess(response.data))),
                catchError(() => of(fetchNotLinkedCardsFailure())),
            );
    }),
);


export default [
    onLinkCardToCompany,
    onLinkCardToCompanySuccess,
    onLinkCardToBusinessUser,
    onLinkCardToBusinessUserSuccess,
    onLinkCardToMemberSuccess,
    onLinkCardToMember,
    onSetCardLinkingStep,
    onLinkCardFailure,
    onFetchNotLinkedCards,
];
