import styled, { css } from 'styled-components';


const size = 30;

const commonPseudoElementStyles = css`
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  height: ${size}px;
  width: ${size}px;
`;

export const NewStarCssShape = styled.div`
  background: var(--primary-color);
  width: ${size}px;
  height: ${size}px;
  position: relative;
  text-align: center;

  &::after {
    ${commonPseudoElementStyles};
    transform: rotate(30deg);
    background: var(--primary-color);
  }
  
  &::before {
    ${commonPseudoElementStyles};
    transform: rotate(60deg);
    background: var(--primary-color);
  }
`;

export const Copy = styled.div`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-shadow:  3px 4px rgb(0 0 0 / 76%)
`;

export default { NewStarCssShape, Copy };
