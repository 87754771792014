import { commonValidation } from 'utils/validation-tools';

import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function PasswordInput({
    id,
    name = 'password',
    label,
    placeholder,
    isRequiredMessage,
    additionalValidationRules = [],
    dependencies,
    dataTestIdPrefix,
    dataTestId = 'password-input',
    t,
}) {
    const placeholderText = placeholder || t('unauthorised:formsElements.password.placeholder');

    const validationRules = [
        ...commonValidation({
            isRequired: true,
            t,
            isRequiredMessage,
        }),
        ...(additionalValidationRules?.length > 0 ? additionalValidationRules : []),
    ];

    return (
        <div style={{ marginBottom: 10 }}>
            <Form.Item
                name={name}
                label={label}
                rules={validationRules}
                dependencies={dependencies}
            >
                <Input
                    id={id}
                    data-test-id={`${dataTestIdPrefix ? `${dataTestIdPrefix}-` : ''}${dataTestId}`}
                    type="password"
                    placeholder={placeholderText}
                />
            </Form.Item>
        </div>
    );
}


PasswordInput.propTypes = {
    t: PropTypes.func.isRequired,
    isRequiredMessage: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    dataTestId: PropTypes.string,
    dataTestIdPrefix: PropTypes.string,
    additionalValidationRules: PropTypes.arrayOf(PropTypes.func),
    dependencies: PropTypes.arrayOf(PropTypes.string),
};

export default PasswordInput;
