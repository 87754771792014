import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';
import { unblockTransaction, unblockTransactionFailure, unblockTransactionSuccess } from 'redux/transaction/actions';
import { CLEAR_TRANSACTION_DETAILS, FETCH_TRANSACTION_DETAILS, FETCH_TRANSACTION_DETAILS_FAILURE, FETCH_TRANSACTION_DETAILS_SUCCESS } from 'redux/transaction/actions.types';

import { createReducer } from '@reduxjs/toolkit';

import {
    clearResetNewTransactionFormState,
    createP2PTransaction, createP2PTransactionFailure, createP2PTransactionSuccess,
    refundTopUp, refundTopUpSuccess, refundTopUpFailure,
    auditPendingTransaction, auditPendingTransactionSuccess, auditPendingTransactionFailure,
} from './actions';


export const transactionReducerName = 'transaction';
export const transactionDetailsPerLocationStoreKeyName = 'transactionDetailsPerLocation';

const initialTransactionDetailsState = {
    [transactionDetailsPerLocationStoreKeyName]: {}, // TODO undefined

    isLoadingRefundTopUp: false,
    isLoadingUnblockTransaction: false,
    isLoadingAuditPendingTransaction: false,
    isBusyCreatingNewTransaction: false,
    resetNewTransactionFormState: undefined,
};


const handlers = {

    [FETCH_TRANSACTION_DETAILS]: (state, action) => {
        state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[transactionDetailsPerLocationStoreKeyName], action);
    },
    [FETCH_TRANSACTION_DETAILS_SUCCESS]: (state, action) => {
        state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[transactionDetailsPerLocationStoreKeyName], action);
    },
    [FETCH_TRANSACTION_DETAILS_FAILURE]: (state, action) => {
        state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[transactionDetailsPerLocationStoreKeyName], action);
    },
    [CLEAR_TRANSACTION_DETAILS]: (state, action) => {
        state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[transactionDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === transactionReducerName
      && action.payload?.fieldName === transactionDetailsPerLocationStoreKeyName
        ) {
            state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[transactionDetailsPerLocationStoreKeyName] }, action);
        }
    },

    [refundTopUp]: (state) => {
        state.isLoadingRefundTopUp = true;
    },
    [refundTopUpSuccess]: (state) => {
        state.isLoadingRefundTopUp = false;
    },
    [refundTopUpFailure]: (state) => {
        state.isLoadingRefundTopUp = false;
    },

    [createP2PTransaction]: (state) => {
        state.isBusyCreatingNewTransaction = true;
    },
    [createP2PTransactionSuccess]: (state) => {
        state.isBusyCreatingNewTransaction = false;
        state.resetNewTransactionFormState = true;
    },
    [createP2PTransactionFailure]: (state) => {
        state.isBusyCreatingNewTransaction = false;
    },


    [clearResetNewTransactionFormState]: (state) => {
        state.resetNewTransactionFormState = undefined;
    },


    [unblockTransaction]: (state) => {
        state.isLoadingUnblockTransaction = true;
    },
    [unblockTransactionSuccess]: (state, action) => {
        state.isLoadingUnblockTransaction = false;
        state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[transactionDetailsPerLocationStoreKeyName], action);
    },
    [unblockTransactionFailure]: (state) => {
        state.isLoadingUnblockTransaction = false;
    },

    [auditPendingTransaction]: (state) => {
        state.isLoadingAuditPendingTransaction = true;
    },
    [auditPendingTransactionSuccess]: (state) => {
        state.isLoadingAuditPendingTransaction = false;
    },
    [auditPendingTransactionFailure]: (state) => {
        state.isLoadingAuditPendingTransaction = false;
    },


    [clearCurrentUser]: () => initialTransactionDetailsState,
};

export default createReducer(initialTransactionDetailsState, handlers);
