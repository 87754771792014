import styled from 'styled-components';


const ControlWithOptionalTooltip = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const AccountIdentificationsPlaceholder = styled.div`
    font-size: 12px;
`;


export default { ControlWithOptionalTooltip, AccountIdentificationsPlaceholder };
