import { AddEditBeneficiarySteps } from 'constants/BeneficiariesModel';
import { OutgoingTransferStep } from 'models/app/outgoingTransfer';
import { isNull } from 'utils/object-tools';

import { Form, Skeleton } from 'antd';
import dotObject from 'dot-object';
import React, { useEffect } from 'react';

import { OutgoingTransferProps } from './OutgoingTransfer.types';
import AccountSelection from './Steps/AccountSelection';
import BeneficiarySelection from './Steps/BeneficiarySelection';
import Review from './Steps/Review';
import TransferDetails from './Steps/TransferDetails';
import StepsIndicator from './StepsIndicator';


function OutgoingTransfer({
    clientId,
    memberId,
    companyId,
    contextData,
    requestResetForm,
    currentStep,
    dispatchClearOutgoingTransferData,
    dispatchSubmitOutgoingTransferFormStep,
    dispatchInitialiseOutgoingTransferForm,
    dispatchRequestResetForm,
}: OutgoingTransferProps) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchInitialiseOutgoingTransferForm({ clientId, memberId, companyId });

        return () => {
            dispatchClearOutgoingTransferData();
        };
    }, []);

    useEffect(() => {
        if (requestResetForm === true) {
            form.resetFields();
            dispatchRequestResetForm();
        }
    }, [requestResetForm]);

    return (
        <Skeleton active loading={contextData === null}>
            <div style={{ padding: 10, background: 'white' }}>
                <StepsIndicator />

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        const dataToSend = dotObject.object({ ...values });
                        if (!isNull(currentStep)) dispatchSubmitOutgoingTransferFormStep(currentStep, dataToSend);
                    }}
                >
                    {currentStep === OutgoingTransferStep.ACCOUNT_SELECTION && (
                        <AccountSelection />
                    )}

                    {currentStep === OutgoingTransferStep.BENEFICIARY_SELECTION && (
                        <BeneficiarySelection />
                    )}

                    {currentStep === OutgoingTransferStep.TRANSFER_DETAILS && (
                        <TransferDetails form={form} />
                    )}

                    {currentStep === AddEditBeneficiarySteps.REVIEW && <Review />}
                </Form>
            </div>
        </Skeleton>
    );
}

export default OutgoingTransfer;
