import { setBeneficiaryIdentificationCategory } from 'redux/beneficiary-add/actions';
import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AccountDetailsStep from './AccountDetails.layout';


const mapStateToProps = (state) => ({
    formData: state[beneficiaryAddReducerName].formData,
    isEditMode: state[beneficiaryAddReducerName].contextData?.isEditMode === true,
    beneficiariesRequirements: state[beneficiaryAddReducerName].beneficiariesRequirements,
    selectedIdentificationCategory: state[beneficiaryAddReducerName].selectedIdentificationCategory,
});

const mapDispatchToProps = (dispatch) => ({ dispatchSetBeneficiaryIdentificationCategory: (category) => dispatch(setBeneficiaryIdentificationCategory(category)) });


const decorate = compose(
    withTranslation('beneficiaries'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AccountDetailsStep);
