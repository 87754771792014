import PropTypes from 'prop-types';

import { CurrencyObjectProps } from './CurrencyModel';


export const AccountTypes = Object.freeze({
    Base: 'Base',
    Business: 'Business',
    Personal: 'Personal',
    Technical: 'Technical',
});

export const NewAccountTypes = Object.freeze({
    BASE: 'BASE',
    BUSINESS: 'BUSINESS',
    PERSONAL: 'PERSONAL',
});

export const DefaultRequiredCurrency = 'GBP';

export const DefaultAccountsCurrenciesSet = Object.freeze([DefaultRequiredCurrency, 'USD', 'EUR']);

export const AccountOwnerTypes = Object.freeze({
    CLIENT: 'CLIENT',
    MEMBER: 'MEMBER',
    COMPANY: 'COMPANY',
});


export const AccountIdentificationCategory = Object.freeze({
    LOCAL: 'LOCAL',
    GLOBAL: 'GLOBAL',
});

export const AccountIdentificationCategoryProp = PropTypes.oneOf(Object.keys(AccountIdentificationCategory));

export const AccountIdentificationType = Object.freeze({
    ABA_ACH: 'ABA_ACH', // bank-routing-number-validator ???,  9 digits long
    ABA_WIRE: 'ABA_WIRE',
    ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
    ACH_CODE: 'ACH_CODE',
    BANK_ACCOUNT_TYPE: 'BANK_ACCOUNT_TYPE',
    BANK_ADDRESS: 'BANK_ADDRESS',
    BANK_CODE: 'BANK_CODE',
    BANK_NAME: 'BANK_NAME',
    BIC: 'BIC', // import { isValidBIC } from 'ibantools';
    BRANCH_CODE: 'BRANCH_CODE',
    BRANCH_NAME: 'BRANCH_NAME',
    BSB_CODE: 'BSB_CODE',
    CNAPS: 'CNAPS', // The CNAPS code is 12 digits long and may start with the code word C/N that identifies the Recipient Bank in China
    IBAN: 'IBAN', // import { isValidIBAN } from 'ibantools';
    IFSC: 'IFSC', // ^[A-Z]{4}[0][A-Z0-9]{6}$ ???
    LOCATION_ID: 'LOCATION_ID',
    SORT_CODE: 'SORT_CODE', // ^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/ ???
    SWIFT: 'SWIFT', // ^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$ ???
    SWIFT_CODE: 'SWIFT_CODE',
    TRANSIT_NUMBER: 'TRANSIT_NUMBER',
    WALLET: 'WALLET',
});

export const AccountIdentificationTypeProp = PropTypes.oneOf(Object.keys(AccountIdentificationType));


export const AccountIdentifierProps = PropTypes.shape({
    identification_type: AccountIdentificationTypeProp,
    identification_value: PropTypes.string.isRequired,
    identification_category: PropTypes.oneOf(Object.keys(AccountIdentificationCategory)).isRequired,
    identification_regex: PropTypes.string,
});

export const AllAccountsOptionValue = 'ALL';

export const AccountTypeProp = PropTypes.oneOf(Object.keys(AccountTypes));


export const AccountsCommonProps = {
    available_balance: PropTypes.number,
    balance: PropTypes.number,
    blocked_balance: PropTypes.number,
    business_user_id: PropTypes.number,
    client_id: PropTypes.number,
    // created_at: PropTypes.string.isRequired,
    company_id: PropTypes.number,
    currency: PropTypes.string.isRequired,
    currency_details: CurrencyObjectProps,
    id: PropTypes.number.isRequired,
    is_primary: PropTypes.bool.isRequired,
    member_id: PropTypes.number,
    name: PropTypes.string.isRequired,
    type: AccountTypeProp,
};

export const AccountsListItemProps = PropTypes.shape({ ...AccountsCommonProps });
export const AccountsListProp = PropTypes.arrayOf(AccountsListItemProps);


export const AccountDetailsProps = PropTypes.shape({
    ...AccountsCommonProps,
    account_identifications: PropTypes.arrayOf(AccountIdentifierProps),
});

export default {
    AccountTypes,
    AccountOwnerTypes,
    AccountIdentificationCategory,
    AccountsListItemProps,
    AccountsListProp,
    AccountDetailsProps,
    NewAccountTypes,
    DefaultAccountsCurrenciesSet,
};
