import { ChargeFeeModalType } from 'constants/FeeModel';
import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchManualFeesListFailure, fetchManualFeesListSuccess, triggerManualFeeFailure, triggerManualFeeSuccess } from './actions';
import { FETCH_MANUAL_FEES_LIST, OPEN_TRIGGER_MANUAL_FEE_MODAL, TRIGGER_MANUAL_FEE } from './actions.types';


export const onFetchManualFeesList = (action$, state$, { fees }) => action$.pipe(
    ofType(FETCH_MANUAL_FEES_LIST),
    switchMap(({ payload }) => from(fees.fetchManualFees(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchManualFeesListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchManualFeesListFailure(payload.locationPathname))),
    )),
);

export const onTriggerManualFeeModal = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_TRIGGER_MANUAL_FEE_MODAL),
    switchMap(({ payload: { queryParams: { feeUuid, name, clientId } } }) => of(
        showModal({
            modalType: ModalName.CHARGE_FEE_MODAL,
            modalProps: {
                title: i18n.t('fees:modals.chargeCustomFee.triggerManualFeeTitle'),
                okButtonProps: { disabled: false },
                feeType: ChargeFeeModalType.MANUAL_FEE,
                feeUuid,
                name,
                clientId,
            },
        }),
    )),
);

export const onTriggerManualFee = (action$, state$, { fees, i18n }) => action$.pipe(
    ofType(TRIGGER_MANUAL_FEE),
    switchMap(({ payload }) => from(fees.triggerManualFee(payload?.queryParams)).pipe(
        switchMap(() => of(
            triggerManualFeeSuccess(),
            showSuccessToast(i18n.t('fees:actionMessages.chargeCustomFeeSuccessFull')),
            hideModal(),
        )),
        catchError(() => of(
            triggerManualFeeFailure(),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);

export default [
    onFetchManualFeesList,
    onTriggerManualFeeModal,
    onTriggerManualFee,
];
