import withAccessControl from 'hocs/withAccessControl';
import { clearClientIdContext, setClientIdContext } from 'redux/client-context/actions';
import { currentUserReducerName } from 'redux/current-user/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import PartnerSelection from './PartnerSelection.layout';


const mapStateToProps = (state) => ({
    currentUserClientId: state[currentUserReducerName].userData?.clientId,
    //
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetClientIdContext: (clientId) => dispatch(setClientIdContext(clientId)),
    dispatchClearClientIdContext: () => dispatch(clearClientIdContext()),
});

const decorate = compose(
    withTranslation('companies'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(PartnerSelection);
