import PropTypes from 'prop-types';


export const RoleProps = PropTypes.shape({
    all: PropTypes.bool,
    client: PropTypes.bool,
    company: PropTypes.bool,
    description: PropTypes.string,
    display_name: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string, // XXX same as display_name?
    own: PropTypes.bool,
    reserved: PropTypes.bool.isRequired,
});
