import { rolesRootRoutePath } from 'config/routes';
import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { fetchPermissionsForRole } from 'redux/permissions/actions';
import { permissionsListQueryParamsName, permissionsReducerName } from 'redux/permissions/reducer';
import { fetchRoleDetails } from 'redux/role/actions';
import { fetchRolesList } from 'redux/roles/actions';
import { rolesListsPerLocationStoreKeyName, rolesReducerName } from 'redux/roles/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import RoleDetails from './RoleDetails.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    rolesListData: getStandardListData({
        state,
        reducerName: rolesReducerName,
        listsByLocationStoreKeyName: rolesListsPerLocationStoreKeyName,
        customLocation: rolesRootRoutePath, // XXX same as main list, because there is no fetch role details endpoint yet
    }),

    isLoadingPermissions: state[permissionsReducerName].isLoadingPermissions,
    permissionsListStates: state[permissionsReducerName].permissionsByRole,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchPermissionsForRole: (roleId) => dispatch(fetchPermissionsForRole(roleId)),
    dispatchFetchRoleDetails: (queryParams, locationPathname) => dispatch(fetchRoleDetails(queryParams, locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: permissionsReducerName,
        fieldName: permissionsListQueryParamsName,
        locationPathname,
        queryParams,
    })),
    dispatchFetchRolesList: (queryParams, locationPathname) => dispatch(fetchRolesList(queryParams, locationPathname)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
});


const decorate = compose(
    withTranslation('roles'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(RoleDetails);
