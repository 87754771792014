import TransactionsList from 'components/common/templates/lists/TransactionsList';
import { transactionsRouteSegmentPath } from 'config/routes';
import { PN } from 'constants/PermissionsModel';

import { TransactionOutlined } from '@ant-design/icons';
import React from 'react';


export const createBeneficiaryDetailsTabsConfig = ({
    beneficiaryUuid,
    accessControl,
    t,
}) => [
    ...(accessControl.hasPermission(PN.TRANSACTIONS.READ_ALL) ? [
        {
            icon: <TransactionOutlined />,
            label: 'Transactions',
            hasList: true,
            activeMainTabSegmentURL: transactionsRouteSegmentPath,
            content: (
                <TransactionsList
                    beneficiaryId={beneficiaryUuid}
                    pageTitle={t(`Beneficiary ${beneficiaryUuid} transactions`)}
                    hidePartnerColumn
                    hideCurrencyFilter
                />
            ),
        },
    ] : []),
];


export default { createBeneficiaryDetailsTabsConfig };
