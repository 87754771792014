import PropTypes from 'prop-types';


export const TableComponentProps = {
    props: {
        loading: PropTypes.bool,
        rowKey: PropTypes.string.isRequired,
        scroll: PropTypes.shape({
            x: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool,
                PropTypes.number,
            ]),
            y: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool,
                PropTypes.number,
            ]),
        }),
        pagination: PropTypes.shape({
            total: PropTypes.number,
            pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
            showSizeChanger: PropTypes.bool,
            defaultCurrent: PropTypes.number,
            current: PropTypes.number,
            pageSize: PropTypes.number,
        }),
        onChange: PropTypes.func,
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func,
                ]),
                dataIndex: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func,
                ]),
                render: PropTypes.func,
                width: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        ),
        dataSource: PropTypes.arrayOf(PropTypes.shape({})),
    },
    default: {
        rowKey: 'id',
        scroll: {
            x: true,
            y: 'calc(100vh - 440px)',
        },
    },
};

export const LoadWrapperProps = {
    props: {
        isLoading: PropTypes.bool,
        active: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
        ]).isRequired,
    },
    default: { isLoading: false, active: false },
};

export const CustomTableProps = {
    props: {
        ...TableComponentProps.props,
        loadable: PropTypes.bool,
        isTableLoading: PropTypes.bool,
    },
    default: {
        ...TableComponentProps.default,
        loadable: false,
        isTableLoading: false,
    },
};
