import { Uuid } from '@manigo/manigo-domain-typings';


export enum RuleTransactionType {
  P2P = 'P2P',
  TOP_UP = 'TOP_UP',
  POS = 'POS',
  POS_CONTACTLESS = 'POS_CONTACTLESS',
  SWIFT_BANK_TRANSFER = 'SWIFT_BANK_TRANSFER',
  LOCAL_BANK_TRANSFER = 'LOCAL_BANK_TRANSFER',
  SWIFT_BANK_TRANSFER_FUNDING = 'SWIFT_BANK_TRANSFER_FUNDING',
  LOCAL_BANK_TRANSFER_FUNDING = 'LOCAL_BANK_TRANSFER_FUNDING',
  WITHDRAWAL = 'WITHDRAWAL',
  CNP = 'CNP',
  ALL = 'ALL',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  CARD_TRANSACTION_REFUND = 'CARD_TRANSACTION_REFUND',
}

export enum RuleType {
  COUNTRY_CHECK = 'COUNTRY_CHECK',
  HIGH_VALUE = 'HIGH_VALUE',
  HIGH_VELOCITY = 'HIGH_VELOCITY',
  LIMIT = 'LIMIT',
  MCC_CHECK = 'MCC_CHECK',
}

export enum RuleScope {
  GLOBAL = 'GLOBAL',
  GROUP = 'GROUP',
  INSTITUTION = 'INSTITUTION',
}

export interface RuleListItem {
  extId: Uuid;
  ruleName: string;
  ruleDescription: string;
  ruleTriggerAmount: number;
  ruleTransactionType: RuleTransactionType;
  ruleType: RuleType;
  limitType: string;
  entityType: string;
  limitOccurrence: string;
  extInstitutionId: Uuid;
  extGroupId: Uuid;
  ruleActions: string[];
  countries: string[];
  mcc: string[];
}
export type RuleDetails = RuleListItem;
