import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Styled from './IntegerInput.stylerd';


function IntegerInput({
    min,
    max,
    precision = 0,
    step,
    value,
    defaultValue,
    disabled,
    onChange,
    onClick,
    onFocus,
    onBlur,
    placeholder,
}) {
    const onInputKeyDown = (event) => {
        const { key } = event;

        const baseKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
        const allowedKeys = precision === 0 ? baseKeys : [...baseKeys, '.'];

        if (!allowedKeys.includes(key)) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        <Styled.InputNumberNoDefaultsWrap>
            <InputNumber
                min={min}
                max={max}
                step={step}
                precision={precision}
                disabled={disabled}
                value={value}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onBlur}
                onClick={onClick}
                onFocus={onFocus}
                onKeyDown={onInputKeyDown}
            />
        </Styled.InputNumberNoDefaultsWrap>
    );
}


IntegerInput.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    precision: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
};

export default IntegerInput;
