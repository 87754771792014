import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';
import { GroupListItemProps } from 'constants/GroupModel';

import { Button, Row, Spin, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createGroupDetailsCountersFieldsConfig, createGroupDetailsFieldsConfig } from './GroupInfo.helpers';


function GroupInfo({
    enhancedCurrentLocation,
    groupDetails,
    clientId,
    groupId,
    queryParamsMeta,
    t,
    dispatchOpenEditGroupNameModal,
    dispatchOpenDeleteGroupModal,
    onRefresh,
    areDetailsReady,
    isBusy,
}) {
    const fieldsConfig = useMemo(
        () => createGroupDetailsFieldsConfig({
            groupDetails,
            t,
        }),
        [groupDetails],
    );

    const countersFieldsConfig = useMemo(
        () => createGroupDetailsCountersFieldsConfig({
            groupDetails,
            t,
        }),
        [groupDetails],
    );


    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={!areDetailsReady && isBusy}
                minHeight={'150px'}
                sectionsRowsCount={3}
                sectionsCount={2}
            >
                <Spin tip="Processing..." spinning={isBusy}>
                    <DetailsSectionsWrapper>
                        <Row gutter={24} size={10} direction="horizontal">
                            <CommonSection fieldsConfig={fieldsConfig} />
                            <CommonSection fieldsConfig={countersFieldsConfig} />
                        </Row>

                        <OptionalButtonsWrapper>
                            <Button
                                key="editGroupName"
                                type="primary"
                                onClick={() => dispatchOpenEditGroupNameModal({ clientId, groupId, locationPathname: enhancedCurrentLocation?.pathname })}
                            >
                                {t('groups:buttons.editGroupName.text')}
                            </Button>

                            <Button
                                key="delete-group"
                                danger
                                onClick={() => dispatchOpenDeleteGroupModal(groupDetails)}
                            >
                                {t('common:buttons.delete.text')}
                            </Button>
                        </OptionalButtonsWrapper>

                        <QueryParamsMetaInfo
                            detailsContext
                            onListRefresh={onRefresh}
                            queryParams={{ groupId }}
                            queryParamsMeta={queryParamsMeta}
                        />
                    </DetailsSectionsWrapper>
                </Spin>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}

GroupInfo.propTypes = {
    t: PropTypes.func.isRequired,
    dispatchOpenEditGroupNameModal: PropTypes.func.isRequired,
    dispatchOpenDeleteGroupModal: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    areDetailsReady: PropTypes.bool,
    isBusy: PropTypes.bool,
    groupDetails: GroupListItemProps,
    groupId: PropTypes.number,
    clientId: PropTypes.number,
};

export default GroupInfo;
