import { StandardTagConfigItem } from 'models/app/standardTag';

import {
    TransactionDetails,
    TransactionType,
    TransactionScope,
    TransactionListItem,
} from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';


const mapTransactionScopeToColorAndLabel = (
    transactionRecord: TransactionListItem | TransactionDetails,
    t: TFunction,
): StandardTagConfigItem => {
    const { transactionScope, transactionType } = transactionRecord;

    const handlers = [
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.IBT,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.ibt.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.OBT,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.obt.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.CT,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.ct.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.APM,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.apm.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.CV,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.cv.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.CVR,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.cvr.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.INT && transactionScope === TransactionScope.IP2P,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.ip2p.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.INT && transactionScope === TransactionScope.OP2P,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.op2p.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.INT && transactionScope === TransactionScope.AC,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.ac.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.INT && transactionScope === TransactionScope.AD,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.ad.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.INT && transactionScope === TransactionScope.EXF,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.exf.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.INT && transactionScope === TransactionScope.EXT,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.ext.text'),
            }),
        },

        {
            predicate: () => transactionType === TransactionType.CT && transactionScope === TransactionScope.ATM,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.atm.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.CT && transactionScope === TransactionScope.POS,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.pos.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.CT && transactionScope === TransactionScope.CR,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.cr.text'),
            }),
        },

        {
            predicate: () => transactionType === TransactionType.FEE && transactionScope === TransactionScope.C,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.c.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.FEE && transactionScope === TransactionScope.D,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.d.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.INT && transactionScope === TransactionScope.BWC,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.bwc.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.INT && transactionScope === TransactionScope.BWD,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.bwd.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.DD,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.dd.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.DC,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.dc.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.CT && transactionScope === TransactionScope.CTR,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.ctr.text'),
            }),
        },
        {
            predicate: () => transactionType === TransactionType.EXT && transactionScope === TransactionScope.OR,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionScopes.or.text'),
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionScopes.unknown'),
            }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default mapTransactionScopeToColorAndLabel;
