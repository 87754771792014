import StandardListTable from 'components/common/organisms/StandardListTable';
import { createAccountTypeFilterConfig, createCurrencyFilterConfig } from 'components/common/organisms/StandardListTable/FilterSection/FiltersSection.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AccountOwnerTypes, AccountsListProp, AccountTypes } from 'constants/AccountModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { CurrenciesListProp } from 'constants/CurrencyModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { accountListQueryParams } from 'redux/accounts/reducer';
import { createAccountTypeOptions, createCurrencyOptions } from 'utils/options-creators';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { createAccountsListColumns } from './tableColumns';


function AccountsList({
    // own props
    isInReadOnlyMode = false,
    ownerId,
    ownerType,
    hideFilters = false,
    showLinkToAccountTransactions = false,

    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    accessControl,
    currentUserClientId,
    clientConfig,
    currencies,
    dispatchFetchAccountsList,
    dispatchRequestNavigation,
    dispatchClearAccountsList,
    dispatchSetQueryParams,
    dispatchOpenWithdrawAccountBalanceModal,
    dispatchOpenBalanceAdjustmentModal,
    onAddAccount,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ownerId,
        ownerType,
    };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchAccountsList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: accountListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchAccountsList,
        dispatchClearItemsList: dispatchClearAccountsList,
        dispatchSetQueryParams,
    });


    // 4. context & permission dependent optional account list filters & buttons
    const currencyOptions = useMemo(
        () => createCurrencyOptions(currencies, t),
        [currencies],
    );

    const accountTypeOptions = useMemo(
        () => createAccountTypeOptions(
            Object.keys(AccountTypes),
            !accessControl.clientConfig?.generalSettings?.allowTechnicalAccounts,
        ),
        [accessControl],
    );

    const additionalFilters = useMemo(
        () => (hideFilters
            ? []
            : [
                createCurrencyFilterConfig({
                    currencyOptions,
                    value: listData?.queryParams?.currency,
                    t,
                }),
                createAccountTypeFilterConfig({
                    accountTypeOptions,
                    value: listData?.queryParams?.type,
                    t,
                }),
            ]), [hideFilters, listData, currencyOptions, accountTypeOptions],
    );

    const additionalButtons = useMemo(
        () => (onAddAccount
            ? [
                {
                    type: 'primary',
                    text: t('buttons.addAccount.text'),
                    icon: null,
                    onClick: onAddAccount,
                    dataTestId: 'add-account-button',
                },
            ]
            : []), [hideFilters, listData, currencyOptions, accountTypeOptions],
    );


    // 5. standard render component
    return (
        <StandardListTable
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createAccountsListColumns({
                accessControl,
                queryParams: listData?.queryParams,
                currentUserClientId,
                clientConfig,
                isInReadOnlyMode,
                enhancedCurrentLocation,
                showLinkToAccountTransactions,
                dispatchRequestNavigation,
                dispatchOpenWithdrawAccountBalanceModal,
                dispatchOpenBalanceAdjustmentModal,
                t,
            })}
            additionalFiltersConfig={additionalFilters}
            filtersButtonsConfig={additionalButtons}
        />
    );
}


AccountsList.propTypes = {
    // own props
    ownerId: PropTypes.number.isRequired,
    // locationPathname: PropTypes.string,
    clientConfig: PropTypes.shape({}),
    currentUserClientId: PropTypes.number,
    isInReadOnlyMode: PropTypes.bool,
    ownerType: PropTypes.oneOf(Object.keys(AccountOwnerTypes)).isRequired,
    onAddAccount: PropTypes.func,
    hideFilters: PropTypes.bool,
    accessControl: AccessControlProps,
    showLinkToAccountTransactions: PropTypes.bool,

    // mapped props
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: AccountsListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    currencies: CurrenciesListProp,
    dispatchFetchAccountsList: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchOpenWithdrawAccountBalanceModal: PropTypes.func.isRequired,
    dispatchClearAccountsList: PropTypes.func.isRequired,
    dispatchOpenBalanceAdjustmentModal: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default AccountsList;

