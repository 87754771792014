import styled from 'styled-components';


export const DetailsSectionsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, auto);
  grid-column-gap: 10px;
  position: relative;
  width: 100%;
  padding-bottom: 32px;
  background: #fff;
`;

const OptionalButtonsAbsoluteWrapper = styled.div`
  button {
    display: block;
    width: max-content;
    margin-left: auto;
    margin-right: 0;
  }
  
   > *:not(:first-child){ 
    margin-top: 16px;
  }
`;

export default { OptionalButtonsAbsoluteWrapper, DetailsSectionsWrapper };
