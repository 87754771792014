import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { createAccountDetailsTabsConfig } from 'components/pages/authorised/AccountDetailsPage/AccountDetailsPage.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';
import { EntityDetailsQueryParams } from 'models/app/standardEntityDetails';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';


import { AccountDetailsPageButtonProps } from './AccountDetailsPage.types';
import AccountInfo from './AccountInfo';


function AccountDetailsPage({
    enhancedCurrentLocation,
    entityDetailsData,
    accessControl,
    dispatchFetchAccountById,
    dispatchClearAccountDetails,
    dispatchSetQueryParams,
    t,
}: AccountDetailsPageButtonProps) {
    const { accountId } = useParams();
    const fixedAccountId = Number(accountId);

    const baseURLSegmentsCount = 2; // e.g. /(1) account /(2) ACCOUNT_ID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const contextEnforcedQueryParams: EntityDetailsQueryParams = { accountId: fixedAccountId };

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchAccountById,
        dispatchClearEntityDetails: dispatchClearAccountDetails,
        dispatchSetQueryParams,
    });

    const tabsConfig = useMemo(
        () => (fixedAccountId && enhancedCurrentLocation
            ? createAccountDetailsTabsConfig({
                fixedAccountId,
                accessControl,
                t,
            })
            : undefined),
        [fixedAccountId, enhancedCurrentLocation?.pathname],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={`Account #${fixedAccountId} Details`}
            tabsConfig={tabsConfig}
            baseURLSegmentsCount={baseURLSegmentsCount}
            entityClientId={entityDetailsData?.entityDetails?.clientId}
        >
            <AccountInfo
                locationPathname={baseUrlWithoutTabsSlugs}
                areDetailsReady={isDetailsPageInitialStateDefined}
                isLoading={entityDetailsData?.isLoadingDetails}
                accountDetails={entityDetailsData?.entityDetails}
                onRefresh={() => dispatchFetchAccountById(contextEnforcedQueryParams, baseUrlWithoutTabsSlugs)}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
            />
        </StandardEntityDetailsWithTabs>
    );
}


AccountDetailsPage.propTypes = {
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
    accessControl: AccessControlProps,
    dispatchClearAccountDetails: PropTypes.func,
    dispatchFetchAccountById: PropTypes.func,
    dispatchSetQueryParams: PropTypes.func,
    t: PropTypes.func,
};
export default AccountDetailsPage;
