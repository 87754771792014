import { AccountOwnerTypes } from 'constants/AccountModel';
import withAccessControl from 'hocs/withAccessControl';
import { openAddAccountModal } from 'redux/accounts/actions';
import { clearReadOnlyMode, setQueryParams, setReadOnlyMode } from 'redux/application/actions';
import { fetchCardDetails } from 'redux/card/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import {
    hideDrawer as rxHideDrawer,
    showDrawer as rxShowDrawer,
} from 'redux/drawer/actions';
import { clearMemberDetails, fetchMemberDetails } from 'redux/member/actions';
import { memberDetailsPerLocationsStoreKeyName, memberReducerName } from 'redux/member/reducer';
import {
    hideModal as rxHideModal,
    setModalProps as rxSetModalProps,
    showModal as rxShowModal,
} from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import MemberDetails from './MemberDetails.layout';
import { MemberDetailsDispatchProps, MemberDetailsStateProps } from './MemberDetails.types';


const mapStateToProps = (state: RootState): MemberDetailsStateProps => ({
    modalState: state.modal,
    currencies: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: memberReducerName,
        fieldName: memberDetailsPerLocationsStoreKeyName,
        baseURLSegmentsCount: 2,
    }),

    enhancedCurrentLocation: state[navigationReducerName]?.enhancedCurrentLocation,
});

const mapDispatchToProps = (dispatch): MemberDetailsDispatchProps => ({
    dispatchFetchMemberDetails: (queryParams, locationPathname) => dispatch(fetchMemberDetails(queryParams, locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: memberReducerName,
        fieldName: memberDetailsPerLocationsStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchClearMemberDetails: () => dispatch(clearMemberDetails()),

    fetchCardById: (cardId) => dispatch(fetchCardDetails(cardId)),

    showDrawer: (type) => dispatch(rxShowDrawer(type)),
    hideDrawer: () => dispatch(rxHideDrawer()),

    showModal: (data) => dispatch(rxShowModal(data)),
    setModalProps: (data) => dispatch(rxSetModalProps(data)),
    hideModal: () => dispatch(rxHideModal()),

    dispatchOpenAddAccountModal: (configData, locationPathname) => dispatch(openAddAccountModal(AccountOwnerTypes.MEMBER, configData, locationPathname)),

    dispatchSetReadOnlyMode: (readOnlyInfo) => dispatch(setReadOnlyMode(readOnlyInfo)),
    dispatchClearReadOnlyMode: () => dispatch(clearReadOnlyMode()),

});


const decorate = compose(
    withTranslation('member'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(MemberDetails);
