import { Button } from 'antd';
import styled, { css } from 'styled-components';


export const CopyButton = styled(Button)`
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;

  ${({ isInClipboard }) => isInClipboard && css`
    color: green !important;
    cursor: none;
  `};
`;

export default { CopyButton };
