import StandardListTable from 'components/common/organisms/StandardListTable';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { FeeTypeProp } from 'constants/FeeModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultFeeTiersListQueryParams } from 'redux/fee-tiers/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createFeeTiersTableColumns from './createTableColumns';


function FeeTiersList({
    // own props
    feeUuid,
    feeType,
    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    t,
    dispatchFetchFeeTiersList,
    dispatchClearFeeTiersList,
    dispatchSetQueryParams,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { ...(feeUuid?.length > 0 ? { feeUuid } : {}) };

    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultFeeTiersListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchFeeTiersList,
        dispatchClearItemsList: dispatchClearFeeTiersList,
        dispatchSetQueryParams,
    });

    // 4. list specific stuff - local search, local sorting is in table configuration
    const filteredFeeTiers = useMemo(
        () => {
            if (listData?.queryParams?.search > 0) {
                return (listData?.items || []).filter((feeTier) => searchCaseInsensitive(feeTier.name, listData?.queryParams?.search));
            }

            return listData?.items || [];
        }, [listData],
    );


    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchFeeTiersList({ ...contextEnforcedQueryParams }, enhancedCurrentLocation.pathname)}
            items={filteredFeeTiers}
            totalItemsCount={filteredFeeTiers.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createFeeTiersTableColumns({
                t,
                queryParams: listData?.queryParams,
                feeType,
            })}
        />
    );
}

FeeTiersList.propTypes = {
    // mapped props
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    feeType: FeeTypeProp,
    t: PropTypes.func.isRequired,
    dispatchFetchFeeTiersList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchClearFeeTiersList: PropTypes.func.isRequired,
};


export default FeeTiersList;
