import { sessionUuidKey } from 'config/config';
import { forgotPasswordRootRoutePath, setPasswordRootRoutePath } from 'config/routes';
import { getJwtTokenFormStorage, initI18next, setSessionUuid, showErrorToast } from 'redux/application/actions';
import { fetchCardTiers } from 'redux/card-tiers/actions';
import { fetchClientsList } from 'redux/clients/actions';
import { defaultClientsListQueryParams } from 'redux/clients/reducer';
import { fetchCountriesList } from 'redux/countries/actions';
import { fetchCurrencies } from 'redux/currencies/actions';
import { fetchIndustriesList } from 'redux/industries/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { getTransactionsFilters } from 'redux/transactions/actions';
import { getSessionStorageObjectItem, setSessionStorageObjectItem } from 'utils/browser-storage';
import { isTokenNotExpired } from 'utils/jwtToken';
import { generateSessionUuid } from 'utils/session-uuid';

import { jwtDecode } from 'jwt-decode';
import { of } from 'rxjs';


export function createAppInitActionsAndSideEffects(httpService, history) {
    const existingSessionUuid = getSessionStorageObjectItem(sessionUuidKey);
    const newSessionUuid = generateSessionUuid();

    const pageDuplicated = window.performance.getEntriesByType('navigation').map((nav) => nav.type.includes('back_forward'));

    const setUuid = (uuid) => {
        httpService.setSessionUuid(uuid); // axios instance
        setSessionStorageObjectItem(sessionUuidKey, uuid); // session storage
    };

    const handlers = [
        { /* sessionUuid from tab that invoked reset (for safety that source tab's uuid was changed on success )  */
            predicate: () => history.location.pathname === setPasswordRootRoutePath,
            handler: () => {
                const params = new URLSearchParams(history.location.search);
                const sessionUuidFromLink = params.get('session-uuid');
                const tokenFromLink = params.get('token');
                const decodedToken = jwtDecode(tokenFromLink);
                const tokenExpirationTime = decodedToken.exp * 1000; // XXX in milliseconds!

                if (isTokenNotExpired(tokenExpirationTime, 2)) {
                    setUuid(sessionUuidFromLink);
                    return of(
                        setSessionUuid(sessionUuidFromLink),
                        initI18next(),
                        // XXX we ignore JWT tokens in session storage in this case
                    );
                }

                setUuid(newSessionUuid);
                return of(
                    initI18next(),
                    setSessionUuid(newSessionUuid),
                    getJwtTokenFormStorage(),
                    showErrorToast('Reset password link has expired. In order to reset password you need to start over.', undefined, { duration: 10 }),
                    requestNavigation(forgotPasswordRootRoutePath, { replace: true }),
                );
            },
        },
        { /* duplicated tab (except Safari & FF - new tab will be unauthorised) */
            predicate: () => existingSessionUuid && pageDuplicated && pageDuplicated[0],
            handler: () => {
                setUuid(newSessionUuid);
                return of(
                    initI18next(),
                    setSessionUuid(newSessionUuid),
                    getJwtTokenFormStorage(existingSessionUuid), // XXX with param!
                );
            },
        },
        { /*  tab reloaded or standard navigation caused by URL change */
            predicate: () => existingSessionUuid,
            handler: () => {
                setUuid(existingSessionUuid);
                return of(
                    initI18next(),
                    setSessionUuid(existingSessionUuid),
                    getJwtTokenFormStorage(),
                );
            },
        },

        { /* standard new tab */
            predicate: () => true,
            handler: () => {
                setUuid(newSessionUuid);
                return of(
                    setSessionUuid(newSessionUuid),
                    initI18next(),
                    getJwtTokenFormStorage(),
                );
            },
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export const commonAuthorisedInitApplicationFetches = [
    fetchClientsList(defaultClientsListQueryParams),
    fetchCountriesList(),
    fetchCurrencies(),
    fetchIndustriesList(),
    getTransactionsFilters(),
    fetchCardTiers(),
];

export default { createAppInitActionsAndSideEffects, commonAuthorisedInitApplicationFetches };

