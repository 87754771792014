import { Dispatch } from 'models/meta/action';
import { updateAddress } from 'redux/address/actions';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import EditMemberAddressModal from './EditMemberAddressModal.layout';
import { EditMemberAddressModalDispatchProps, EditMemberAddressModalStateProps } from './EditMemberAddressModal.types';


const mapStateToProps = (state: RootState): EditMemberAddressModalStateProps => ({ enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation });

const mapDispatchToProps = (dispatch: Dispatch): EditMemberAddressModalDispatchProps => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchUpdateAddress: (...payload) => dispatch(updateAddress(...payload)),
});


const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(EditMemberAddressModal);
