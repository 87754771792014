import ClientStatusTag from 'components/common/atoms/tags/ClientStatusTag';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createClientDetailsPath } from 'config/routes.helpers';
import { SortDirections } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import { enumValueToReadableString } from 'utils/string-tools';
import { sortingOptions, sortNumbers, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import { Space } from 'antd';
import React from 'react';


export default ({
    accessControl,
    queryParams,
    dispatchRequestNavigation,
    t,
}) => [
    {
        title: t('common:table.columnNames.id'),
        dataIndex: 'id',
        align: 'right',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        defaultSortOrder: SortDirections.ASCEND,
        ...sortingOptions({
            queryParams,
            fieldName: 'id',
            sorter: (a, b) => sortNumbers(a, b, 'id'),
        }),
    },
    {
        title: t('clientsList.table.columnNames.dbaName'),
        dataIndex: 'company_dba_name',
        render: (text, record) => (accessControl.hasPermission(PN.CLIENTS.READ_BY_ID)
            ? (
                <StandardLink
                    title={text || 'company DBA name'}
                    path={createClientDetailsPath(record.id)}
                    filterValue={queryParams?.search}
                    showTooltip
                />
            )
            : (<StandardValue value={text || 'company DBA name'} searchQuery={queryParams?.search} showTooltip />)),
        ...sortingOptions({
            queryParams,
            fieldName: 'company_dba_name',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'company_dba_name'),
        }),
    },
    {
        title: t('clientsList.table.columnNames.companyBusinessType'),
        dataIndex: 'company_business_type',
        render: (text) => <StandardValue value={enumValueToReadableString(text)} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'company_business_type',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'company_business_type'),
        }),
    },
    {
        title: t('clientsList.table.columnNames.companyRegistrationNumber'),
        dataIndex: 'company_registration_number',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'company_registration_number',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'company_registration_number'),
        }),
    },
    {
        title: t('clientsList.table.columnNames.companyVatRegistrationNumber'),
        dataIndex: 'company_vat_registration_number',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'company_vat_registration_number',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'company_vat_registration_number'),
        }),
    },
    {
        title: t('clientsList.table.columnNames.descriptor'),
        dataIndex: 'descriptor',
        render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'descriptor',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'descriptor'),
        }),
    },
    {
        title: t('common:table.columnNames.status'),
        dataIndex: 'active',
        align: 'center',
        render: (text) => <ClientStatusTag active={text} showTooltip />,
        ...sortingOptions({
            queryParams,
            fieldName: 'active',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'active'),
        }),
    },

    ...(accessControl.hasOneOfPermissions([PN.CLIENTS.READ_BY_ID, PN.CLIENTS.CREATE])
        ? [
            {
                title: t('common:table.columnNames.actions'),
                dataIndex: '',
                align: 'center',
                render: (_, record) => (
                    <Space>
                        {accessControl.hasPermission(PN.CLIENTS.READ_BY_ID) && viewButtonRenderer({
                            record,
                            onClick: (event, record) => dispatchRequestNavigation(createClientDetailsPath(record.id)),
                            tooltipContent: t('clientsList.table.buttons.viewRecordDetails'),
                            dataTestIdSuffix: 'view-client-details',
                        })}
                    </Space>
                ),
            },
        ]
        : []
    ),
];
