import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';

import {
    fetchUserDetails, fetchUserDetailsSuccess, fetchUserDetailsFailure,
    deleteUser, deleteUserSuccess, deleteUserFailure,
    resendConfirmationEmail, resendConfirmationEmailSuccess, resendConfirmationEmailFailure,
    banUserSuccess, banUser, banUserFailure, clearUserDetailsState, clearUserDetails,
} from './actions';
import { EDIT_USER, EDIT_USER_FAILURE, EDIT_USER_SUCCESS } from './actions.types';


export const userReducerName = 'user';
export const userDetailsPerLocationStoreKeyName = 'userDetailsPerLocationStoreKey';

const defaultUserDetailsValues = {
    [userDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    updateUserLoading: false,
    deleteUserLoading: false,
    resendConfirmationEmailLoading: false,
};


const initialState = { ...defaultUserDetailsValues };

const handlers = {

    [fetchUserDetails]: (state, action) => {
        state[userDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[userDetailsPerLocationStoreKeyName], action);
    },
    [fetchUserDetailsSuccess]: (state, action) => {
        state[userDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[userDetailsPerLocationStoreKeyName], action);
    },
    [fetchUserDetailsFailure]: (state, action) => {
        state[userDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[userDetailsPerLocationStoreKeyName], action);
    },
    [clearUserDetails]: (state, action) => {
        state[userDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[userDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === userReducerName
      && action.payload?.fieldName === userDetailsPerLocationStoreKeyName
        ) {
            state[userDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[userDetailsPerLocationStoreKeyName] }, action);
        }
    },

    [EDIT_USER]: (state) => {
        state.updateUserLoading = true;
    },
    [EDIT_USER_SUCCESS]: (state, action) => {
        state.updateUserLoading = false;
        state[userDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[userDetailsPerLocationStoreKeyName], action);
    },
    [EDIT_USER_FAILURE]: (state) => {
        state.updateUserLoading = false;
    },

    [banUser]: (state) => {
        state.updateUserLoading = true;
    },
    [banUserSuccess]: (state) => {
        state.updateUserLoading = false;
    },
    [banUserFailure]: (state) => {
        state.updateUserLoading = true;
    },

    [deleteUser]: (state) => {
        state.deleteUserLoading = false;
    },
    [deleteUserSuccess]: (state) => {
        state.deleteUserLoading = false;
    },
    [deleteUserFailure]: (state) => {
        state.deleteUserLoading = false;
    },


    [resendConfirmationEmail]: (state) => {
        state.resendConfirmationEmailLoading = true;
    },
    [resendConfirmationEmailSuccess]: (state) => {
        state.resendConfirmationEmailLoading = true;
    },
    [resendConfirmationEmailFailure]: (state) => {
        state.resendConfirmationEmailLoading = false;
    },


    [clearUserDetailsState]: (state) => ({
        ...state,
        ...defaultUserDetailsValues,
    }),
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
