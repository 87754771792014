import withAccessControl from 'hocs/withAccessControl';
import { fetchStakeholdersList } from 'redux/business-stakeholders/actions';
import { businessStakeholdersListsPerLocationStoreKeyName, businessStakeholdersReducersName } from 'redux/business-stakeholders/reducer';
import { addBusinessUser } from 'redux/business-users/actions';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddBusinessUserModal from './AddBusinessUserModal.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: businessStakeholdersReducersName,
        listsByLocationStoreKeyName: businessStakeholdersListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAddBusinessUser: (formData, locationPathname) => dispatch(addBusinessUser(formData, locationPathname)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
    dispatchFetchStakeholdersList: (queryParams, locationPathname) => dispatch(fetchStakeholdersList(queryParams, locationPathname)),
});


const decorate = compose(
    withAccessControl,
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddBusinessUserModal);
