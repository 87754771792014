import { Card } from 'antd';
import styled from 'styled-components';


const UnauthorisedOuterWrapper = styled.div`
  margin: 0 auto;
  padding: 16px;

  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-items: center;
`;

const UnauthorisedContentWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  margin-top: 10vh;
  
  .ant-form {
    padding: 0 20px;
    
  }

  .ant-input {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  
  .ant-btn {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
  }
`;

const UnauthorisedLogoWrapper = styled.div`
  margin: 0 auto 32px;
  text-align: center;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 32px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  
  
  h1 {
    margin: 0;
    font-family: monospace;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
    word-spacing: 3px;
  }
`;

const UnauthorisedLogo = styled.img`
  height: 50px;
  width: auto;
`;

const UnauthorisedCard = styled(Card)`
  box-shadow: 0px 2px 10px -5px rgba(0,0,0, .75);
`;

export const UnauthorisedScreenTitle = styled.h1`
  text-align: center;
  margin: 15px 0 40px 0;
  font-weight: 500;
  font-size: 16px;
`;

// eslint-disable-next-line spellcheck/spell-checker
export const UnauthorisedLinkWrapper = styled.div`
  .ant-btn {
    color: var(--primary-color);
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    
    &:hover {
      text-decoration: underline;
      color: var(--secondary-color);
    }
  }
`;

export const CopyrightNoticeWrapper = styled.div`
    text-align: center;
    padding: 10px 0;
    font-size: 12px;
  
    a {
      color: var(--primary-color);
      
      &:hover {
        text-decoration: underline;
      }
    }
`;


export default {
    UnauthorisedOuterWrapper,
    UnauthorisedContentWrapper,
    UnauthorisedLogoWrapper,
    UnauthorisedLogo,
    UnauthorisedCard,
    UnauthorisedLinkWrapper,
    CopyrightNoticeWrapper,
};
