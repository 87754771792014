import withAccessControl from 'hocs/withAccessControl';
import { clearReadOnlyMode, setQueryParams, setReadOnlyMode } from 'redux/application/actions';
import {
    clearCardDetails,
    deleteCard,
    fetchCardDetails,
    updateCard,
} from 'redux/card/actions';
import { cardDetailsPerLocationStoreKeyName, cardReducerName } from 'redux/card/reducer';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { getStandardListData } from 'redux/standardLists.helpers';


import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import CardDetails from './CardDetails.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,

    clients: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,

    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: cardReducerName,
        fieldName: cardDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
    isLoadingUpdateCard: state[cardReducerName].isLoadingUpdateCard,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateCard: (cardId, requestPayload) => dispatch(updateCard(cardId, requestPayload)),
    dispatchDeleteCard: (cardId) => dispatch(deleteCard(cardId)),

    dispatchFetchCardDetails: (queryParams, locationPathname) => dispatch(fetchCardDetails(queryParams, locationPathname)),
    dispatchClearCardDetails: (locationPathname) => dispatch(clearCardDetails(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: cardReducerName,
        fieldName: cardDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchSetReadOnlyMode: (readOnlyInfo) => dispatch(setReadOnlyMode(readOnlyInfo)),
    dispatchClearReadOnlyMode: () => dispatch(clearReadOnlyMode()),
});


const decorate = compose(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CardDetails);
