import StandardTooltip from 'components/common/molecules/StandardTooltip';
import { AccessControlProps } from 'constants/AccessControlModel';
import { ClientListItemProps } from 'constants/ClientModel';

import { Popover } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import { createContextIndicatorTooltipContent } from './ClientContextIndicator.helpers';
import Styled from './ClientContextIndicator.styled';


function ClientContextIndicator({
    accessControl,
    clientIdContext,
    clients,
}) {
    return (
        <Styled.ContentIndicatorWrapper>
            <StandardTooltip
                placement="bottom"
                title={'Active partner context. Click to for more info.'}
            >
                <Popover
                    content={createContextIndicatorTooltipContent({
                        accessControl,
                        clientIdContext,
                        clients,
                    })}
                    trigger="click"
                >
                    <Styled.InfoIcon />
                </Popover>
            </StandardTooltip>
        </Styled.ContentIndicatorWrapper>
    );
}

ClientContextIndicator.propTypes = {
    accessControl: AccessControlProps,
    clientIdContext: PropTypes.number,
    clients: PropTypes.arrayOf(ClientListItemProps),
};


export default ClientContextIndicator;
