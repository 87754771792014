import { CommonQueryParamsMetaProps, CommonQueryParamsProps, ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { ApplicationsListProp } from 'constants/ApplicationsModel';
import { RoleProps } from 'constants/RolesModel';
import { findProperApplicationByName } from 'redux/applications/helpers';
import { isFormPristine } from 'utils/forms-tools';
import { createRolesOptions } from 'utils/options-creators';
import { commonValidation, handleWhitespaceOnBlur } from 'utils/validation-tools';

import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


function AddApiKeyModal({
    initialValues,


    enhancedCurrentLocation,
    isBlockNavigationReasonSet,
    rolesListData,
    applicationsListData,

    dispatchSetModalProps,
    dispatchCreateApiKey,
    dispatchFetchRolesList,
    dispatchBlockNavigation,
    dispatchUnblockNavigation,
    dispatchFetchApplicationsList,
    t,
}) {
    const [form] = Form.useForm();

    const clientId = useMemo(
        () => initialValues?.clientId,
        [initialValues],
    );

    useEffect(() => {
        if (enhancedCurrentLocation.pathname && !applicationsListData?.items) {
            dispatchFetchApplicationsList({ clientId, limit: 100 }, enhancedCurrentLocation.pathname);
        }

        dispatchSetModalProps({ onOk: () => form.submit() });

        return () => {
            dispatchUnblockNavigation();
        };
    }, []);

    const applicationForSelectedClientId = useMemo(
        () => findProperApplicationByName(applicationsListData?.items || []),
        [applicationsListData],
    );


    useEffect(() => {
        if (applicationForSelectedClientId?.uuid) {
            dispatchFetchRolesList({
                clientId,
                applicationUuid: applicationForSelectedClientId?.uuid,
            }, enhancedCurrentLocation.pathname);
        }
    }, [applicationForSelectedClientId]);


    const rolesOptions = useMemo(
        () => createRolesOptions(rolesListData?.items || []),
        [rolesListData],
    );


    const onFinish = (values) => {
        const { roleId, label } = values;
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchCreateApiKey({ roleId, label }, enhancedCurrentLocation.pathname);
    };

    const handleFormValuesChanges = (changedValues, allValues) => {
        const isPristine = isFormPristine({ ...allValues });
        if (!isPristine && !isBlockNavigationReasonSet) {
            dispatchBlockNavigation(ReasonsForBlockedNavigation.NOT_PRISTINE_ADD_API_KEY_FORM);
        }

        if (isBlockNavigationReasonSet && isPristine) {
            dispatchUnblockNavigation();
        }
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={undefined}
            onFinish={onFinish}
            onValuesChange={handleFormValuesChanges}
        >
            <Form.Item
                name="label"
                label={t('apiKeysFields.label.label')}
                rules={[...commonValidation({ t, isRequired: true })]}
            >
                <Input
                    placeholder={t('apiKeysFields.label.placeholder')}
                    onBlur={(e) => handleWhitespaceOnBlur(e, 'label', form)}
                />
            </Form.Item>

            <Form.Item
                name="roleId"
                label={t('apiKeysFields.roleId.label')}
                rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
            >
                <Select
                    placeholder={t('apiKeysFields.roleId.placeholder')}
                    loading={rolesListData?.isLoadingList || applicationsListData?.isLoadingList}
                >
                    {rolesOptions}
                </Select>
            </Form.Item>
        </Form>
    );
}


AddApiKeyModal.propTypes = {
    applicationsListData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(RoleProps),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),

    rolesListData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: ApplicationsListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    initialValues: PropTypes.shape({ clientId: PropTypes.number.isRequired }),

    dispatchFetchRolesList: PropTypes.func.isRequired,
    dispatchCreateApiKey: PropTypes.func.isRequired,
    dispatchBlockNavigation: PropTypes.func.isRequired,
    dispatchUnblockNavigation: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchFetchApplicationsList: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default AddApiKeyModal;
