import { createClientDetailsPath } from 'config/routes.helpers';
import withAccessControl from 'hocs/withAccessControl';
import { updateClientSettings, updateClientAnnouncement } from 'redux/client/actions';
import { clientDetailsPerLocationStoreKeyName, clientReducerName } from 'redux/client/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';


import Announcement from './Announcement.layout';


const mapStateToProps = (state, ownProps) => {
    const entityDetailsData = getStandardEntityDetailsData({
        state,
        reducerName: clientReducerName,
        fieldName: clientDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
        customLocation: createClientDetailsPath(ownProps?.enforcedClientId),
    });

    return {
        isLoading: state.client.isLoadingClientConfigUpdate || state.client.isLoadingClientAnnouncementUpdate,
        isLoadingClientDetails: entityDetailsData?.isLoading,
        announcement: entityDetailsData?.entityDetails?.announcement,
        configuration: entityDetailsData?.entityDetails?.configuration,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateClientSettings: (clientId, configData) => dispatch(updateClientSettings(clientId, configData, true)),
    dispatchUpdateClientAnnouncements: (clientId, configData) => dispatch(updateClientAnnouncement(clientId, configData)),
});

const decorate = compose(
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(Announcement);
