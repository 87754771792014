import { AddEditBeneficiarySteps } from 'constants/BeneficiariesModel';
import { showSuccessToast } from 'redux/application/actions';
import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';
import { requestNavigation } from 'redux/navigation/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    createBeneficiaryFailure,
    createBeneficiarySuccess,
    fetchBeneficiaryRequirementsFailure,
    fetchBeneficiaryRequirementsSuccess,
    setAddEditBeneficiaryFormStep,
} from './actions';
import {
    CREATE_BENEFICIARY,
    FETCH_BENEFICIARY_REQUIREMENTS,
    GO_TO_PREVIOUS_ADD_EDIT_BENEFICIARY_FORM_STEP, INITIALISE_ADD_EDIT_BENEFICIARY_FORM,
    SUBMIT_ADD_EDIT_BENEFICIARY_FORM_STEP,
} from './actions.types';
import {
    createOnGoToPreviousFormStepActions,
    createOnInitialiseAddEditBeneficiaryFormActions,
    createOnSubmitFormStepActions,
} from './helpers';


export const onFetchBeneficiaryRequirements = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_BENEFICIARY_REQUIREMENTS),
    switchMap(({ payload }) => from(beneficiaries.fetchBeneficiaryRequirements(payload)).pipe(
        switchMap((response) => of(
            fetchBeneficiaryRequirementsSuccess(response.data),
            setAddEditBeneficiaryFormStep(AddEditBeneficiarySteps.ADDRESS, payload),
        )),
        catchError(() => of(fetchBeneficiaryRequirementsFailure())),
    )),
);

export const onCreateBeneficiary = (action$, state$, { beneficiaries, i18n }) => action$.pipe(
    ofType(CREATE_BENEFICIARY),
    switchMap(({ payload }) => from(beneficiaries.createBeneficiary(payload)).pipe(
        switchMap((response) => of(
            createBeneficiarySuccess(response.data),
            showSuccessToast(i18n.t('beneficiaries:actionMessages.createBeneficiarySuccess')),
            requestNavigation(state$.value[beneficiaryAddReducerName].contextData?.returnPath),
        )),
        catchError(() => of(createBeneficiaryFailure())),
    )),
);


export const onInitialiseAddEditBeneficiaryForm = (action$, state$) => action$.pipe(
    ofType(INITIALISE_ADD_EDIT_BENEFICIARY_FORM),
    switchMap(({ payload }) => createOnInitialiseAddEditBeneficiaryFormActions({
        ownerContext: payload.ownerContext,
        formData: payload.formData,
        state: state$.value,
    })),
);

export const onSubmitAddEditBeneficiaryFormStep = (action$, state$) => action$.pipe(
    ofType(SUBMIT_ADD_EDIT_BENEFICIARY_FORM_STEP),
    switchMap(({ payload }) => createOnSubmitFormStepActions({
        currentStep: payload.step,
        newData: payload.formData,
        contextData: state$.value[beneficiaryAddReducerName].contextData,
        currentData: state$.value[beneficiaryAddReducerName].formData,
    })),
);

export const onGoToPreviousAddEditBeneficiaryFormStep = (action$) => action$.pipe(
    ofType(GO_TO_PREVIOUS_ADD_EDIT_BENEFICIARY_FORM_STEP),
    switchMap(({ payload }) => createOnGoToPreviousFormStepActions(payload)),
);

export default [
    onFetchBeneficiaryRequirements,
    onCreateBeneficiary,
    onInitialiseAddEditBeneficiaryForm,
    onSubmitAddEditBeneficiaryFormStep,
    onGoToPreviousAddEditBeneficiaryFormStep,
];

