import { globalLocation } from 'constants/NavigationModel';
import { CLEAR_INDUSTRIES_LIST, FETCH_INDUSTRIES_LIST, FETCH_INDUSTRIES_LIST_FAILURE, FETCH_INDUSTRIES_LIST_SUCCESS } from 'redux/industries/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchIndustriesList = createAction(FETCH_INDUSTRIES_LIST, (queryParams = {}, locationPathname = globalLocation) => ({ payload: { queryParams, locationPathname } }));
export const fetchIndustriesListSuccess = createAction(FETCH_INDUSTRIES_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchIndustriesListFailure = createAction(FETCH_INDUSTRIES_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearIndustriesList = createAction(CLEAR_INDUSTRIES_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export default {
    fetchIndustriesList,
    fetchIndustriesListSuccess,
    fetchIndustriesListFailure,
    clearIndustriesList,
};
