import PropTypes from 'prop-types';

import { AccountIdentifierProps } from './AccountModel';
import { AddressCommonProps } from './AddressModel';


export const BeneficiaryType = Object.freeze({
    INDIVIDUAL: 'INDIVIDUAL',
    COMPANY: 'COMPANY',
});

export const AddEditBeneficiarySteps = Object.freeze({
    BASIC_DATA: 'BASIC_DATA',
    ADDRESS: 'ADDRESS',
    ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
    REVIEW: 'REVIEW',
});

export const BeneficiaryTypeProp = PropTypes.oneOf(Object.keys(BeneficiaryType));

export const BeneficiaryProps = {
    beneficiary_type: BeneficiaryTypeProp,
    client_id: PropTypes.number,
    company_id: PropTypes.number,
    company_name: PropTypes.string,
    country: PropTypes.string, // POL
    currency: PropTypes.string, // EUR
    first_name: PropTypes.string,
    // TODO should be renamed to uuid on API level
    id: PropTypes.string.isRequired, // XXX v4 UUID b41c69b8-f510-4bf2-a7ff-67a3315e8d3a
    last_name: PropTypes.string,
    member_id: PropTypes.number,
    middle_name: PropTypes.string,
    address: AddressCommonProps,
};

export const BeneficiaryListItemProps = PropTypes.shape({ ...BeneficiaryProps });
export const BeneficiariesListProp = PropTypes.arrayOf(BeneficiaryListItemProps);
export const AddEditBeneficiaryFormCurrentStep = PropTypes.oneOf(Object.keys(AddEditBeneficiarySteps));


export const BeneficiaryDetailsProps = PropTypes.shape({
    ...BeneficiaryProps,
    account_identifications: PropTypes.arrayOf(AccountIdentifierProps),
});


export default {
    BeneficiaryType,
    BeneficiariesListProp,
    BeneficiaryDetailsProps,
};
