import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import { Button, Col, Row } from 'antd';
import styled from 'styled-components';


const CommonSection = styled(Col)`
  width: auto;
`;

const CommonSectionHeadingWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 10px;
  align-items: center;
  max-width: 100%;
  padding: 10px;
  min-height: 32px; /* to eliminate diff in height coming form buttons */
`;

const CommonSectionHeadingText = styled.div`
  text-align: left;
  color: #CACACA;
  text-transform: uppercase;
  max-width: fit-content;
  ${commonTextEllipsisStyles};
`;

const CommonSectionEditButton = styled(Button)`
  justify-self: left;
`;

const CommonSectionContentWrapper = styled.div`
  max-width: 100%;
`;

const CommonSectionOptionalContentWrapper = styled.div`
  padding: 20px 0;
`;

const LabelsValuesRow = styled(Row)`
  background: transparent;
`;

const LabelsColumn = styled(Col)`
  max-width: 300px;
`;

const ValuesColumn = styled(Col)`
  max-width: 300px;
`;

export const ValueWithEllipsis = styled.div`
  width: 100%;
  max-width: fit-content;
  ${commonTextEllipsisStyles};
`;


export default {
    CommonSection,
    CommonSectionHeadingWrapper,
    CommonSectionHeadingText,
    CommonSectionEditButton,
    CommonSectionContentWrapper,
    CommonSectionOptionalContentWrapper,
    LabelsValuesRow,
    LabelsColumn,
    ValuesColumn,
    ValueWithEllipsis,
};
