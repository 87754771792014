import ModalName from 'constants/Modals';
import { enumValueToReadableString } from 'utils/string-tools';

import { PlayCircleOutlined, PauseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { MemberCommonData, MemberStatuses } from '@manigo/manigo-domain-typings';
import { ButtonType } from 'antd/es/button';
import { TFunction } from 'i18next';
import React from 'react';


const createModalPayload = (title: string, memberId: MemberCommonData['memberId'], dispatchUpdateStatusAction) => ({
    modalType: ModalName.UPDATE_MEMBER_STATUS_MODAL,
    modalProps: {
        title,
        dispatchUpdateStatusAction,
        memberId,
    },
});

const createTButtonNamespace = (t: TFunction, key: string) => t(`member:buttons.${key}.text`);
export const createButtonConfigs = ({
    t,
    memberId,
    canSuspend,
    canUnSuspend,
    canActivate,
    canBan,
    canUnBan,
    dispatchSuspendMember,
    dispatchBanMember,
    dispatchActivateMember,
    dispatchUnsuspendMember,
    dispatchUnbanMember,
}): {
    predicate: () => boolean,
    action: any,
    text: string,
    type: ButtonType,
    icon?: React.ReactNode,
  }[] => [
    {
        predicate: () => canSuspend,
        action: createModalPayload('Suspend member', memberId, dispatchSuspendMember),
        text: createTButtonNamespace(t, 'suspendMember'),
        type: 'primary',
        icon: <PauseCircleOutlined />,
    },
    {
        predicate: () => canUnSuspend,
        action: createModalPayload('Un suspend member', memberId, dispatchUnsuspendMember),
        text: createTButtonNamespace(t, 'unSuspendMember'),
        type: 'primary',
        icon: <PlayCircleOutlined />,
    },
    {
        predicate: () => canActivate,
        action: createModalPayload('Activate member', memberId, dispatchActivateMember),
        text: createTButtonNamespace(t, 'activateMember'),
        type: 'primary',
        icon: <CheckCircleOutlined />,
    },
    {
        predicate: () => canBan,
        action: createModalPayload('Ban member', memberId, dispatchBanMember),
        text: createTButtonNamespace(t, 'banMember'),
        type: 'primary',
        icon: <PauseCircleOutlined />,
    },
    {
        predicate: () => canUnBan,
        action: createModalPayload('Unban member', memberId, dispatchUnbanMember),
        text: createTButtonNamespace(t, 'unBanMember'),
        type: 'primary',
        icon: <PlayCircleOutlined />,
    },
];

export const getExternalKycKybCompanyStatusOptions = (status) => {
    const handlers = [
        {
            predicate: () => status === MemberStatuses.UNVERIFIED,
            handler: () => [MemberStatuses.KYC_PENDING],
        },
        {
            predicate: () => status === MemberStatuses.KYC_PENDING,
            handler: () => [MemberStatuses.KYC_UPLOADED],
        },
        {
            predicate: () => status === MemberStatuses.KYC_UPLOADED,
            handler: () => [MemberStatuses.ACTIVE, MemberStatuses.KYC_MANUAL_REVIEW, MemberStatuses.KYC_REJECTED],
        },
        {
            predicate: () => status === MemberStatuses.KYC_MANUAL_REVIEW,
            handler: () => [MemberStatuses.KYC_PENDING, MemberStatuses.ACTIVE, MemberStatuses.KYC_REJECTED],
        },
        {
            predicate: () => status === MemberStatuses.ACTIVE,
            handler: () => [MemberStatuses.KYC_PENDING],
        },
        {
            predicate: () => status === MemberStatuses.KYC_REJECTED,
            handler: () => [MemberStatuses.KYC_PENDING, MemberStatuses.KYC_UPLOADED],
        },
    ];

    const options = handlers.find(({ predicate }) => predicate())?.handler().map((status) => ({
        value: status,
        label: enumValueToReadableString(status),
    }));

    return options || [];
};

export default {
    createButtonConfigs,
    getExternalKycKybCompanyStatusOptions,
};
