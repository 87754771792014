import {
    createClientDetailsBeneficiariesListPath,
    createCompanyDetailsBeneficiariesListPath,
    createMemberDetailsBeneficiariesListPath,
} from 'config/routes.helpers';
import { showSuccessToast } from 'redux/application/actions';
import { fetchBeneficiariesList } from 'redux/beneficiaries/actions';
import { beneficiariesListsPerLocationStoreKeyName, beneficiariesReducerName } from 'redux/beneficiaries/reducer';
import { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';
import { requestNavigation, requestNavigationBack } from 'redux/navigation/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    deleteBeneficiaryFailure, deleteBeneficiarySuccess,
    fetchBeneficiaryDetailsFailure, fetchBeneficiaryDetailsSuccess,
    updateBeneficiaryFailure, updateBeneficiarySuccess,
} from './actions';
import { DELETE_BENEFICIARY, FETCH_BENEFICIARY_DETAILS, UPDATE_BENEFICIARY } from './actions.types';


export const onFetchBeneficiaryDetails = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_BENEFICIARY_DETAILS),
    switchMap(({ payload }) => from(beneficiaries.fetchBeneficiaryDetails(payload?.queryParams?.beneficiaryUuid)).pipe(
        switchMap((response) => of(fetchBeneficiaryDetailsSuccess(response.data, payload?.locationPathname))),
        catchError(() => of(fetchBeneficiaryDetailsFailure(payload?.locationPathname))),
    )),
);


export const onUpdateBeneficiary = (action$, state$, { beneficiaries, i18n }) => action$.pipe(
    ofType(UPDATE_BENEFICIARY),
    switchMap(({ payload }) => from(beneficiaries.updateBeneficiary(payload.beneficiaryUuid, payload.formData)).pipe(
        switchMap((response) => of(
            updateBeneficiarySuccess(response.data, payload?.locationPathname),
            showSuccessToast(i18n.t('beneficiaries:actionMessages.updateBeneficiarySuccess')),
            requestNavigation(state$.value[beneficiaryAddReducerName].contextData?.returnPath),
        )),
        catchError(() => of(updateBeneficiaryFailure(payload?.locationPathname))),
    )),
);

export const onDeleteBeneficiary = (action$, state$, { beneficiaries, i18n }) => action$.pipe(
    ofType(DELETE_BENEFICIARY),
    switchMap(({ payload }) => from(beneficiaries.deleteBeneficiary(payload?.beneficiaryUuid)).pipe(
        switchMap(() => {
            const handlers = [
                {
                    predicate: () => payload?.ownerContext?.memberId > 0,
                    handler: () => createMemberDetailsBeneficiariesListPath(payload.ownerContext.memberId),
                },

                {
                    predicate: () => payload?.ownerContext?.companyId > 0,
                    handler: () => createCompanyDetailsBeneficiariesListPath(payload.ownerContext.companyId),
                },
                {
                    predicate: () => payload?.ownerContext?.clientId > 0,
                    handler: () => createClientDetailsBeneficiariesListPath(payload.ownerContext.clientId),
                },
                {
                    predicate: () => true,
                    handler: () => undefined,
                },
            ];

            const pathname = handlers.filter(({ predicate }) => predicate())[0].handler();
            const currentBeneficiaryListQueryParams = pathname
                ? state$.value[beneficiariesReducerName][beneficiariesListsPerLocationStoreKeyName]?.[pathname]?.queryParams
                : undefined;

            const commonActions = [
                showSuccessToast(i18n.t('beneficiaries:actionMessages.deleteBeneficiarySuccess')),
                deleteBeneficiarySuccess(payload.beneficiaryUuid),
                requestNavigationBack(),
            ];

            if (pathname && currentBeneficiaryListQueryParams) {
                return of(
                    fetchBeneficiariesList(currentBeneficiaryListQueryParams, pathname),
                    ...commonActions,
                );
            }

            return of(
                ...commonActions,
            );
        }),
        catchError(() => of(deleteBeneficiaryFailure(payload?.locationPathname))),
    )),
);

export default [
    onFetchBeneficiaryDetails,
    onUpdateBeneficiary,
    onDeleteBeneficiary,
];

