import LoadingStatus from 'constants/LoadingStatus';
import {
    openEditCardSecure3dPhoneNumberModal,
    fetchCardSecure3dData,
    fetchCardSecure3dActivityLogs,
    updateCardSecure3dSettings,
    clearCardSecure3d,
} from 'redux/card-secure3d/actions';
import { card3dSecureReducerName } from 'redux/card-secure3d/reducer';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Secure3dTab from './Secure3d.layout';


const mapStateToProps = (state) => ({
    data: state[card3dSecureReducerName].data,
    logs: state[card3dSecureReducerName].data,

    isLoadingData: state[card3dSecureReducerName].isLoadingData === LoadingStatus.PENDING,
    isLoadingActivityLogs: state[card3dSecureReducerName].isLoadingActivityLogs === LoadingStatus.PENDING,
    isBusyUpdatingSettings: state[card3dSecureReducerName].isBusyUpdatingSettings === LoadingStatus.PENDING,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchOpenEdit3DSecurePhoneNumberModal: (cardId, phoneNumber) => dispatch(openEditCardSecure3dPhoneNumberModal(cardId, phoneNumber)),
    dispatchFetch3DSecureData: (cardId) => dispatch(fetchCardSecure3dData(cardId)),
    dispatchFetch3DSecureActivityLogs: (cardId) => dispatch(fetchCardSecure3dActivityLogs(cardId)),
    dispatchUpdateCardIdSecure3dStatus: (cardId, payload) => dispatch(updateCardSecure3dSettings(cardId, payload)),
    dispatchClearCardSecure3d: () => dispatch(clearCardSecure3d()),

});

const connectedSecure3DTab = connect(mapStateToProps, mapDispatchToProps)(Secure3dTab);

export default withTranslation('cards')(connectedSecure3DTab);
