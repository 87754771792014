// PermissionsNames -> PN
export const PN = Object.freeze(
    {
        _3DS_CHALLENGE: {
            ACTIVE_CHALLENGES: '3ds-challenge:active-challenges',
            UPDATE_STATUS: '3ds-challenge:update-status',
        },
        ACCOUNTS: {
            CREATE: 'accounts:create',
            DELETE: 'accounts:delete',
            READ: 'accounts:read',
            READ_BALANCES: 'accounts:read-balances',
            READ_BY_ID: 'accounts:read-by-id',
            SET_PRIMARY: 'accounts:set-primary',
            UPDATE: 'accounts:update',
            UPDATE_FLAGS: 'accounts:update-flags',
        },
        ACTIVITIES: {
            ACCEPT: 'activities:accept',
            CLOSE: 'activities:close',
            CREATE: 'activities:create',
            DELETE_IMAGES: 'activities:delete-images',
            READ_ALL: 'activities:read-all',
            READ_BY_ID: 'activities:read-by-id',
            READ_DESCRIPTION: 'activities:read-description',
            READ_IMAGES: 'activities:read-images',
            REJECT: 'activities:reject',
            REMIND: 'activities:remind',
            SET_CHAT_ID: 'activities:set-chat-id',
            SET_MCC: 'activities:set-mcc',
            UPDATE_DESCRIPTION: 'activities:update-description',
            UPLOAD_IMAGE: 'activities:upload-image',
        },
        ADDRESS: { READ_DELIVERY: 'address:read-delivery' },
        ADDRESSES: {
            CREATE: 'addresses:create',
            DELETE: 'addresses:delete',
            READ: 'addresses:read',
            READ_BY_ID: 'addresses:read-by-id',
            UPDATE: 'addresses:update',
        },
        ANNOUNCEMENTS: {
            READ: 'announcements:read',
            UPDATE: 'announcements:update',
        },
        AUTH: {
            ACTIVITY: 'auth:activity',
            UNLOCK: 'auth:unlock',
        },

        APPLICATION: {
            GET_ALL: 'applications:read-all',
            GET_BY_UUID: 'applications:read-by-id',
            CREATE: 'applications:create',
            UPDATE: 'applications:update',
            DELETE: 'applications:delete',
        },

        API_KEY: {
            READ_ALL: 'api-key:read-all',
            READ_BY_ID: 'api-key:read-by-id',
            CREATE: 'api-key:create',
            DELETE: 'api-key:delete',
            READ_KEY_VALUE_BY_ID: 'api-key:read-key-value-by-id',
        },
        BENEFICIARIES: {
            CREATE: 'beneficiaries:create',
            DELETE: 'beneficiaries:delete',
            GET_ACTIVITY_LOG: 'beneficiaries:get-activity-log',
            GET_ALL: 'beneficiaries:get-all',
            GET_RISK_DETAILS: 'beneficiaries:get-risk-details',
            READ_BY_ID: 'beneficiaries:read-by-id',
            REQUIRED_DETAILS: 'beneficiaries:required-details',
            SEARCH: 'beneficiaries:search',
            UPDATE: 'beneficiaries:update',
            UPDATE_RISK_SCORE: 'beneficiaries:update-risk-score',
        },
        BPS: {
            FUNDING: 'bps:funding',
            PAYMENT: 'bps:payment',
        },
        BUSINESS_USERS: {
            GET_KYC_LOGS: 'business-users:get-kyc-logs',
            READ_BUSINESS_USERS_STATUS_HISTORY: 'business-users:read-business-users-status-history',
            READ_BY_ID: 'business-users:read-by-id',
            UPDATE: 'business-users:update',
            UPDATE_STATUS: 'business-users:update-status',
            READ: 'business-users:read',
        },

        BUSINESS_SHAREHOLDERS: {
            CREATE: 'business-shareholders:create',
            UPDATE: 'business-shareholders:update',
            READ_BY_ID: 'business-shareholders:read-by-id',
            READ_ALL: 'business-shareholders:read-all',
            GET_KYC_LOGS: 'business-shareholders:get-kyc-logs', // OUTDATED replaced by kyckyb:get-logs
        },

        CARD_USAGE_INFO: {
            CREATE: 'card-usage-info:create',
            GET: 'card-usage-info:get',
        },
        CARDS: {
            ACTIVATION: 'cards:activation',
            CVV: 'cards:cvv',
            DELETE: 'cards:delete',
            GET_IMAGE: 'cards:get-image',
            LINK: 'cards:link',
            ORDER_BULK: 'cards:order-bulk',
            ORDER_SINGLE: 'cards:order-single',
            PROCESS_CARD_ORDERS: 'cards:process-card-orders',
            READ: 'cards:read',
            READ_BY_ACCOUNT: 'cards:read-by-account',
            READ_BY_CLIENT: 'cards:read-by-client',
            READ_BY_CLIENT_NOT_LINKED: 'cards:read-by-client-not-linked',
            READ_BY_COMPANY: 'cards:read-by-company',
            READ_BY_COMPANY_NOT_LINKED: 'cards:read-by-company-not-linked',
            READ_BY_EMPLOYEE: 'cards:read-by-employee',
            READ_BY_ID: 'cards:read-by-id',
            READ_BY_MEMBER: 'cards:read-by-member',
            READ_BY_MEMBER_NOT_LINKED: 'cards:read-by-member-not-linked',
            READ_CARD_NUMBER: 'cards:read-card-number',
            READ_CONTACTLESS_LIMIT: 'cards:read-contactless-limit',
            READ_ENROLLMENT_LOGS: 'cards:read-enrollment-logs',
            READ_ENROLLMENT_STATUS: 'cards:read-enrollment-status',
            READ_MATRIXES: 'cards:read-matrixes',
            READ_NOT_LINKED: 'cards:read-not-linked',
            READ_PIN: 'cards:read-pin',
            READ_STATUS_HISTORY: 'cards:read-status-history',
            READ_TARGETS: 'cards:read-targets',
            RESET_CONTACTLESS_LIMIT: 'cards:reset-contactless-limit',
            RESET_PIN: 'cards:reset-pin',
            TOGGLING: 'cards:toggling',
            UNBLOCK_PIN: 'cards:unblock-pin',
            UNLINK: 'cards:unlink',
            UPDATE_ENROLLMENT: 'cards:update-enrollment',
            UPDATE_LIMITS: 'cards:update-limits',
        },
        CHAT: { SEND_MESSAGE: 'chat:send-message' },
        CLIENTS: {
            MIGRATIONS: {
                GET: 'clients:migrations:get',
                BALANCES: {
                    CREATE: 'clients:migrations:balances:create',
                    GET: { CSV: 'clients:migrations:balances:get:csv' },
                },
                MEMBERS: {
                    CREATE: 'clients:migrations:members:create',
                    GET: { CSV: 'clients:migrations:members:get:csv' },
                },
                REPORT: { GET: { CSV: 'clients:migrations:report:get:csv' } },
            },

            CREATE: 'clients:create',
            CREATE_PRODUCT: 'clients:create-product',
            CREATE_RISK_DETAIL: 'clients:create-risk-detail',
            DELETE: 'clients:delete',
            GET_ALL: 'clients:get_all',

            READ: 'clients:read',
            READ_BY_ID: 'clients:read-by-id',
            READ_PRODUCTS: 'clients:read-products',
            READ_RISK_DETAILS: 'clients:read-risk-details',
            UPDATE: 'clients:update',
            WRITE: 'clients:write',
        },
        COMPANIES: {
            CREATE: 'companies:create',
            CREATE_EMPLOYEE: 'companies:create-employee',
            CREATE_RISK_DETAIL: 'companies:create-risk-detail',
            DELETE: 'companies:delete',
            GET_KYB_LOGS: 'companies:get-kyb-logs', // OUTDATED replaced by kyckyb:get-logs
            READ: 'companies:read',
            READ_BY_CLIENT: 'companies:read-by-client',
            READ_BY_ID: 'companies:read-by-id',
            READ_RISK_DETAILS: 'companies:read-risk-details',
            READ_COMPANY_STATUS_HISTORY: 'companies:read-company-status-history',
            READ_STAKEHOLDERS: 'companies:read-stakeholders',
            RESEND_INVITATION: 'companies:resend-invitation',
            UPDATE: 'companies:update',
            UPDATE_ADDRESS: 'companies:update-address',
            UPDATE_EMPLOYEE_PASSWORD: 'companies:update-employee-password',
            UPDATE_RISK_SCORE: 'companies:update-risk-score',
            UPDATE_STATUS: 'companies:update-status',
        },
        CONFIG: {
            MEMBER_MARKETING: {
                CREATE: 'config:member-marketing:create',
                GET: 'config:member-marketing:get',
                GET_ALL: 'config:member-marketing:get_all',
                EXPORT: 'config:member-marketing:export',
                GET_HISTORY: 'config:member-marketing:get_history',
            },
        },
        CONTACTS: {
            GET_BY_FIREBASE_UID: 'contacts:get-by-firebase-uid',
            SYNC_BY_EMAIL: 'contacts:sync-by-email',
            SYNC_BY_PHONE_NUMBER: 'contacts:sync-by-phone-number',
        },
        CURRENCIES: {
            READ: 'currencies:read',
            READ_WITHDRAWAL: 'currencies:read-withdrawal',
        },
        EXCHANGE: {
            CONFIRM: 'exchange:confirm',
            IMMEDIATE: 'exchange:immediate',
            INIT: 'exchange:init',
            REJECT: 'exchange:reject',
        },
        EXCHANGE_RATES: {
            CURRENCY: 'exchange-rates:currency',
            FROM_TO: 'exchange-rates:from-to',
        },
        FEES: {
            READ_BY_ID: 'fees:read-by-id',
            READ_FOR_CURRENT_MEMBER: 'fees:read-for-current-member',
            READ_FOR_GROUP: 'fees:read-for-group',
            READ_LIST: 'fees:read-list',
            CHARGE_CUSTOM: 'fees:charge-custom',
            READ_MANUAL_FEES: 'fees:read-manual-fees',
            TRIGGER_MANUAL_FEE: 'fees:trigger',
        },

        FEE_TIERS: { READ_BY_FEE_ID: 'fee-tiers:read-by-fee-id' },

        GROUPS: {
            ASSIGN: 'groups:assign',
            CREATE: 'groups:create',
            DELETE: 'groups:delete',
            READ_BY_ID: 'groups:read-by-id',
            READ_FOR_FEE: 'groups:read-for-fee',
            READ_FOR_PROMOTION: 'groups:read-for-promotion',
            READ_LIST: 'groups:read-list',
            UPDATE: 'groups:update',
        },
        INDUSTRIES: { GET_ALL: 'industries:get-all' },
        INTERNAL: { DB_SYNC: { TRANSACTIONS_SA: 'internal:db-sync:transactions-sa' } },
        JOBS: { RETRY: 'jobs:retry' },
        KYB: {
            GET_ACCESS_TOKEN: 'kyb:get-access-token',
            READ: 'kyb:read',
            UPLOAD_DOCUMENT: 'kyb:upload-document',
            WRITE: 'kyb:write',
        },
        KYC: {
            GET_ACCESS_TOKEN: 'kyc:get-access-token',
            READ: 'kyc:read',
            WRITE: 'kyc:write',
        },
        KYCKYB: {
            CHANGES: 'kyckyb:changes',
            GET_LEVEL: 'kyckyb:get-level',
            RESTART: 'kyckyb:restart',
            SET_APPLICANT_LEVEL: 'kyckyb:set-applicant-level',
            GET_LOGS: 'kyckyb:get-logs',
            BULK_RESTART_EXPIRED: 'bulk-restart-expired',
        },
        LIMITS: { GET_ALL: 'limits:get_all' },
        MEMBERS: {
            CHANGE_PASSCODE: 'members:change-passcode',
            CHECK_PASSCODE: 'members:check-passcode',
            CREATE: 'members:create',
            CREATE_RISK_DETAILS: 'members:create-risk-details',
            DELETE: 'members:delete',
            GET_KYC_LOGS: 'members:get-kyc-logs', // OUTDATED replaced by kyckyb:get-logs
            GET_RISK_DETAILS: 'members:get-risk-details',
            LOGOUT: 'members:logout',
            READ: 'members:read',
            READ_ALL: 'members:read-all',
            READ_FOR_GROUP: 'members:read-for-group',
            READ_FOR_GROUP_INVERT: 'members:read-for-group-invert',
            READ_PROFILE: 'members:read-profile',
            UPDATE: 'members:update',
            UPDATE_ADDRESS: 'members:update-address',
            UPDATE_CONFIRM: 'members:update-confirm',
            UPDATE_INIT: 'members:update-init',
            UPDATE_PN_TOKEN: 'members:update-pn-token',
            UPDATE_PROFILE: 'members:update-profile',
            UPDATE_RISK_SCORE: 'members:update-risk-score',

            SUSPEND: 'members:suspend',
            // eslint-disable-next-line spellcheck/spell-checker
            UNSUSPEND: 'members:unsuspend',
            BAN: 'members:ban',
            UNBAN: 'members:unban',
            ACTIVATE: 'members:activate',
            READ_STATUS_HISTORY: 'members:read-status-history',
            UPDATE_STATUS: 'members:update-status',
        },
        OFFBOARDING: {
            SET_PENDING_CLOSURE: 'offboarding:set-pending-closure',
            REINSTATE_PENDING_CLOSURE: 'offboarding:reinstate-pending-closure',
            FINALISE_CLOSED: 'offboarding:finalise-closed',
        },
        OFFICERS: {
            READ: 'officers:read',
            WRITE: 'officers:write',
        },
        OPERATIONS: {
            CREATE: 'operations:create',
            DELETE: 'operations:delete',
            DELETE_HARD: 'operations:delete-hard',
            GET: 'operations:get',
            TOGGLE: 'operations:toggle',
            UPDATE_TEXTUAL: 'operations:update-textual',
        },
        PAYMENT_GATEWAY: {
            AVAILABLE_METHODS: 'payment-gateway:available-methods',
            PAYMENT: 'payment-gateway:payment',
        },
        PAYMENTS: { CANCEL: 'payments:cancel' },
        PERMISSIONS: {
            ASSIGN_TO_ROLE: 'permissions:assign-to-role',
            ASSIGN_TO_USER: 'permissions:assign-to-user',
            READ: 'permissions:read',
            READ_BY_ROLE: 'permissions:read-by-role',
            REMOVE_FROM_ROLE: 'permissions:remove-from-role',
            REMOVE_FROM_USER: 'permissions:remove-from-user',
            WRITE: 'permissions:write',
        },
        PROMOTIONS: {
            ASSIGN_TO_GROUP: 'promotions:assign-to-group',
            CREATE: 'promotions:create',
            DELETE: 'promotions:delete',
            DISABLE: 'promotions:disable',
            ENABLE: 'promotions:enable',
            READ_BY_ID: 'promotions:read-by-id',
            READ_FOR_CURRENT_MEMBER: 'promotions:read-for-current-member',
            READ_FOR_FEE: 'promotions:read-for-fee',
            READ_FOR_GROUP: 'promotions:read-for-group',
            READ_GROUPS: 'promotions:read-groups',
            READ_LIST: 'promotions:read-list',
            REMOVE_FROM_GROUP: 'promotions:remove-from-group',
        },
        RECOVER: { CARDS: { CVV: 'recover:cards:cvv' } },
        REPORTS: { TRANSACTION_STATEMENT: { PDF: 'reports:transaction-statement:pdf' } },
        RISK_ASSESSMENT: {
            GET_TEMPLATE: 'risk-assessment:get-template',
            GET_DATA: 'risk-assessment:get-data',
            CREATE_DATA: 'risk-assessment:create-data',
        },
        ROLES: {
            CREATE: 'roles:create',
            DELETE: 'roles:delete',
            READ: 'roles:read',
            UPDATE: 'roles:update',
        },
        RULES: {
            READ_ALL: 'rules:read-all',
            READ_BY_UUID: 'rules:read-by-uuid',
        },
        SCHEDULER: {
            CREATE: 'schedulers:create',
            READ_ALL: 'schedulers:read-all',
            READ_BY_ID: 'schedulers:read-by-id',
            UPDATE: 'schedulers:update',
        },
        STANDING_ORDERS: {
            CREATE: 'standing-orders:create',
            DELETE: 'standing-orders:delete',
            READ: 'standing-orders:read',
            READ_HISTORY_OF_CHANGES: 'standing-orders:read-history-of-changes',
            TOGGLE: 'standing-orders:toggle',
            UPDATE: 'standing-orders:update',
        },
        TOPUP: {
            ADD_CARD: 'topup:add-card',
            CANCEL_TOPUP: 'topup:cancel-topup',
            CARD_ACTIVATE: 'topup:card-activate',
            CARD_VALIDATE: 'topup:card-validate',
            DELETE_CARD: 'topup:delete-card',
            GET_ALL_CARDS: 'topup:get-all-cards',
            GET_CARD_ACTIVATION_CODE: 'get-card-activation-code',
            PAYMENT: 'topup:payment',
            REFUND: 'topup:refund',
            VERIFY_CARD: 'topup:verify_card',
        },
        TRANSACTIONS: {
            AUDIT_PAYMENT: 'transactions:audit-payment',
            BUDGET: 'transactions:budget',
            DELETE_IMAGES: 'transactions:delete-images',
            DESCRIPTION: 'transactions:description',
            DESCRIPTION_UPDATE: 'transactions:description-update',
            MAKE_PAYMENT: 'transactions:make-payment',
            READ_ALL: 'transactions:read-all',
            READ_BY_CLIENT_AND_MEMBER: 'read-by-client-and-member',
            READ_BY_ID: 'transactions:read-by-id',
            READ_BY_STANDING_ORDER: 'transactions:read-by-standing-order',
            RECEIPTS: 'transactions:receipts',
            RECEIPTS_UPLOAD: 'transactions:receipts-upload',
            SCHEDULE_PAYMENT: 'transactions:schedule-payment',
            SEND_MONEY: 'transactions:send-money',
            SET_MCC: 'transactions:set-mcc',
            UNBLOCK: 'transactions:unblock',
            WITHDRAW_BALANCE: 'transactions:withdraw-balance',
            ADD_MONEY: 'transactions:add-money', // v2 load funds
            BALANCE_ADJUSTMENT: 'transactions:balance-adjustment',
            READ_HISTORY_BY_ID: 'transactions:read-history-by-id',
        },
        USERS: {
            DELETE: 'users:delete',
            READ: 'users:read',
            READ_BY_ID: 'users:read-by-id',
            WRITE: 'users:write',
        },
        WALLETS: { READ: 'wallets:read' },
        WEBHOOKS: {
            AVAILABLE: 'webhooks:available',
            READ_ALL: 'webhooks:read_all',
            REGISTER: 'webhooks:register',
            UPDATE: 'webhooks:update',
            UNREGISTER: 'webhooks:unregister',
            READ_ALL_FAILED: 'webhooks:read_all_failed',
            REPLAY: 'webhooks:replay',
            REPLAY_ALL_FAILED: 'webhooks:replay_all_failed',
            FAILED: { HISTORY: 'webhooks:failed:history' },
        },
    },
);

export const permissionSetOrderCardsTargetPreSelected = [
    PN.CARDS.ORDER_BULK,
    PN.CARDS.ORDER_SINGLE,
    PN.CARDS.PROCESS_CARD_ORDERS,
    PN.CARDS.READ_MATRIXES,
];

export const permissionSetOrderCardsTargetSelection = [
    ...permissionSetOrderCardsTargetPreSelected,
    PN.COMPANIES.READ,
    PN.MEMBERS.READ, // TODO READ-ALL?
    PN.CLIENTS.READ,
];

export const permissionSetAddMembersToGroup = [
    PN.GROUPS.UPDATE, // TODO  ASSIGN?
    PN.MEMBERS.READ_FOR_GROUP_INVERT,
];

export const permissionSetLinkCard = [
    PN.CARDS.LINK,
    PN.ACCOUNTS.READ,
    PN.CARDS.READ_NOT_LINKED,
];


export const permissionSetMinRequiredByBOP = [
    PN.INDUSTRIES,
    PN.CURRENCIES.READ,
    PN.CLIENTS.READ, // full list for super admins, one entry for WL Admin and lower roles
    // TODO  get v2/countries is a public route?
    // TODO  get v3/transactions/filters is a public route?
];

export const permissionSetNewTransaction = [
    PN.TRANSACTIONS.SEND_MONEY, // TODO PAYMENTS vs PAYMENT_GATEWAY.PAYMENT vs both  vs ???
    PN.PAYMENT_GATEWAY.PAYMENT,
    PN.ACCOUNTS.READ, // TODO remove if READ_BALANCES is enough
    PN.ACCOUNTS.READ_BALANCES,
    PN.MEMBERS.READ,
    PN.CLIENTS.READ,
    PN.COMPANIES.READ,
    PN.EXCHANGE.INIT,
    PN.EXCHANGE.CONFIRM,
];

export default {
    PN,
    permissionSetOrderCardsTargetPreSelected,
    permissionSetOrderCardsTargetSelection,
    permissionSetAddMembersToGroup,
    permissionSetLinkCard,
    permissionSetNewTransaction,
};
