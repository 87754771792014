export const FETCH_ROLE_DETAILS = 'FETCH_ROLE_DETAILS';
export const FETCH_ROLE_DETAILS_SUCCESS = 'FETCH_ROLE_DETAILS_SUCCESS';
export const FETCH_ROLE_DETAILS_FAILURE = 'FETCH_ROLE_DETAILS_FAILURE';
export const CLEAR_ROLE_DETAILS = 'CLEAR_ROLE_DETAILS';


export const SELECT_ROLE_TEMPLATE = 'SELECT_ROLE_TEMPLATE';
export const CLEAR_ROLE_TEMPLATE = 'CLEAR_ROLE_TEMPLATE';

export const ADD_PERMISSIONS_TO_ROLE = 'ADD_PERMISSIONS_TO_ROLE';
export const REMOVE_PERMISSIONS_FROM_ROLE = 'REMOVE_PERMISSIONS_FROM_ROLE';
export const REMOVE_ALL_PERMISSIONS_FROM_ROLE = 'REMOVE_ALL_PERMISSIONS_FROM_ROLE';

export const TOGGLE_SAVE_ROLE_AS_ROLE_TEMPLATE = 'TOGGLE_SAVE_ROLE_AS_ROLE_TEMPLATE';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';

export const CLEAR_ADD_EDIT_ROLE_FORM_STATE = 'CLEAR_ADD_EDIT_ROLE_FORM_STATE';


export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';


export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
