import StatusHistoryList from 'components/common/templates/lists/StatusHistoryList';
import TransactionsList from 'components/common/templates/lists/TransactionsList';
import {
    limitsRouteSegmentPath,
    requestRouteSegmentPath,
    responseRouteSegmentPath,
    secure3DsRouteSegmentPath,
    settingsRouteSegmentPath,
    statusHistoryRouteSegmentPath, transactionsRouteSegmentPath,
} from 'config/routes';
import { PN } from 'constants/PermissionsModel';
import { clearCardStatusHistory, fetchCardStatusHistory } from 'redux/card-status-history/actions';
import { cardStatusHistoryListPerLocationStoreKeyName, cardStatusHistoryReducerName } from 'redux/card-status-history/reducer';

import {
    SettingOutlined,
    AreaChartOutlined,
    UnlockOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    HistoryOutlined, TransactionOutlined,
} from '@ant-design/icons';
import React from 'react';

import LimitsTab from './Tabs/Limits';
import RequestTab from './Tabs/Request';
import ResponseTab from './Tabs/Response';
import Secure3D from './Tabs/Secure3d';
import SettingsTab from './Tabs/Settings';
import createCardStatusColumns from './Tabs/StatusHistory/tableColumns';


export const createTabsConfig = ({
    accessControl,
    isInReadOnlyMode,
    fixedId,
    entityDetails,
    onCardUpdate,
    isLoadingDetails,
    isLoadingUpdateCard,
    locationPathname,
    t,
}) => [
    {
        icon: <SettingOutlined />,
        label: t('cardDetails.tabs.settings.tabName'),
        activeMainTabSegmentURL: settingsRouteSegmentPath,
        isDefault: true,
        content: (
            <SettingsTab
                t={t}
                isInReadOnlyMode={isInReadOnlyMode}
                onUpdate={onCardUpdate}
                cardDetails={entityDetails}
                isLoadingUpdateCard={isLoadingUpdateCard}
                isLoadingCardDetails={isLoadingDetails}
                locationPathname={locationPathname}
            />
        ),
    },
    {
        icon: <AreaChartOutlined />,
        label: t('cardDetails.tabs.limits.tabName'),
        activeMainTabSegmentURL: limitsRouteSegmentPath,
        content: (
            <LimitsTab isInReadOnlyMode={isInReadOnlyMode} />
        ),
    },
    {
        icon: <UnlockOutlined />,
        label: t('cardDetails.tabs.3dSecure.tabName'),
        activeMainTabSegmentURL: secure3DsRouteSegmentPath,
        content: (
            <Secure3D
                isInReadOnlyMode={isInReadOnlyMode}
                accessControl={accessControl}
                cardId={fixedId}
                cardDetails={entityDetails}
            />
        ),
    },
    ...(entityDetails?.request && entityDetails?.request !== '{}' ? [{
        icon: <ArrowUpOutlined />,
        label: t('cardDetails.tabs.request.tabName'),
        activeMainTabSegmentURL: requestRouteSegmentPath,
        content: <RequestTab cardDetails={entityDetails} />,
    }] : []),
    ...(entityDetails?.response && entityDetails?.response !== '{}' ? [{
        icon: <ArrowDownOutlined />,
        activeMainTabSegmentURL: responseRouteSegmentPath,
        label: t('cardDetails.tabs.response.tabName'),
        content: <ResponseTab cardDetails={entityDetails} />,
    }] : []),

    ...(accessControl.hasPermission(PN.CARDS.READ_STATUS_HISTORY) ? [{
        icon: <HistoryOutlined />,
        label: t('cardDetails.tabs.statusHistory.tabName'),
        activeMainTabSegmentURL: statusHistoryRouteSegmentPath,
        content: <StatusHistoryList
            reducerName={cardStatusHistoryReducerName}
            listsByLocationStoreKeyName={cardStatusHistoryListPerLocationStoreKeyName}
            fetcherListAction={fetchCardStatusHistory}
            clearListAction={clearCardStatusHistory}
            contextEnforcedQueryParams={{ cardId: fixedId }}
            entityData={entityDetails}
            createTableColumns={createCardStatusColumns}
        />,
    }] : []),

    {
        icon: <TransactionOutlined />,
        label: 'Transactions',
        hasList: true,
        activeMainTabSegmentURL: transactionsRouteSegmentPath,
        content: <TransactionsList cardId={fixedId} />,
    },
];


export default { createTabsConfig };
