import React, { useMemo } from 'react';

import Styled from './StandardTooltip.styled';
import { StandardTooltipProps } from './StandardTooltip.types';


function StandardTooltip({
    showCopyToClipboard = false,
    title,
    children,
    clipboardContent,
    dispatchSetClipboardContent,
    trigger = 'hover',
    placement,
    overlayClassName,
    isAmount = false,
    arrowPointAtCenter,
    autoAdjustOverflow,
    overlayInnerStyle,
    overlayStyle,
    defaultOpen,
    useRootContainer = false,
    t,
}: StandardTooltipProps) {
    const isInClipboard = useMemo(
        () => showCopyToClipboard && clipboardContent === title,
        [clipboardContent, title, showCopyToClipboard],
    );

    const finalTitle = showCopyToClipboard
        ? (
            <>
                <Styled.TooltipValue $isAmount={isAmount}>{title}</Styled.TooltipValue>
                <Styled.TooltipCopy2Clipboard>{t(`${isInClipboard ? 'Copied!' : 'Click to copy to clipboard'}`)}</Styled.TooltipCopy2Clipboard>
            </>
        )
        : (<Styled.TooltipValue>{title}</Styled.TooltipValue>);

    return (
        <Styled.StandardTooltipWithOverriddenStyles
            trigger={trigger}
            title={finalTitle}
            autoAdjustOverflow={autoAdjustOverflow}
            arrow={arrowPointAtCenter}
            placement={placement}
            overlayStyle={overlayStyle}
            overlayInnerStyle={overlayInnerStyle}
            overlayClassName={overlayClassName}
            defaultOpen={defaultOpen}
            getPopupContainer={(triggerEl) => (useRootContainer ? document.body : triggerEl.parentElement as HTMLElement)}
        >
            {showCopyToClipboard
                ? (
                    <Styled.CopyTransparentOverlay
                        data-test-id={`copy-to-clipboard-${title}`}
                        onClick={() => dispatchSetClipboardContent && dispatchSetClipboardContent(title)}
                    >
                        {children || title}
                    </Styled.CopyTransparentOverlay>
                )
                : <Styled.StandardOverlay>{children || title}</Styled.StandardOverlay>}
        </Styled.StandardTooltipWithOverriddenStyles>
    );
}

export default StandardTooltip;
