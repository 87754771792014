import StandardTooltip from 'components/common/molecules/StandardTooltip';
import TextWithHighlightedSearch from 'components/common/molecules/value-renderers/TextWithHighlightedSearch';

import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from './PermissionRecord.styled';


function PermissionRecordDisplay({
    permission,
    onAddPermission,
    searchQuery,
    t,
}) {
    return (
        <Styled.PermissionItemWrapper>
            {onAddPermission && (
                <Button
                    size="small"
                    icon={<PlusCircleOutlined />}
                    type="primary"
                    onClick={() => onAddPermission(permission)}
                >
                    {t('Add')}
                </Button>
            )}


            <Styled.PermissionName>
                <TextWithHighlightedSearch filterValue={searchQuery} rawValue={permission.name} />

                {permission.description?.length > 0 && (
                    <StandardTooltip title={permission.description}>
                        <InfoCircleOutlined />
                    </StandardTooltip>
                )}
            </Styled.PermissionName>


        </Styled.PermissionItemWrapper>
    );
}

PermissionRecordDisplay.propTypes = {
    permission: PropTypes.shape({}),
    // t: PropTypes.func,
};

export default PermissionRecordDisplay;
