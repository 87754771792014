import svgIcons from 'assets/images/icons/svgIcons';

import PropTypes from 'prop-types';
import React from 'react';


import { renderInstanceConfigErrors } from './AppHasInvalidConfigPlaceholder.helpers';
import Styled from './AppHasInvalidConfigPlaceholder.styled';


function AppHasInvalidConfigPlaceholder({ instanceConfigurationErrors }) {
    const copy = 'Decoration icon picturing very injured man who made this happen.\n'
    + '         Congratulations for unlocking \'Saw AppHasInvalidConfigPlaceholder\' achievement!';

    return (
        <Styled.AppHasInvalidConfigPlaceholderWrapper>

            <Styled.InjuredManIconDecoration
                src={svgIcons.injuredManIcon}
                alt={copy}
                title={copy}
            />

            {renderInstanceConfigErrors({ instanceConfigurationErrors })}
        </Styled.AppHasInvalidConfigPlaceholderWrapper>
    );
}

AppHasInvalidConfigPlaceholder.propTypes = { instanceConfigurationErrors: PropTypes.arrayOf(PropTypes.string) };

export default AppHasInvalidConfigPlaceholder;

