import styled from 'styled-components';


const AccountOptionWrapper = styled.span`
  > div:first-of-type{
    display: grid;
    grid-template-columns: 3fr auto 3fr;
    grid-column-gap: 10px;
    position: relative;
    width: 100%;
    white-space: nowrap;
  }

  .ant-tag {
    margin-inline-end: 0;
  }
`;

const Left = styled.div`
  white-space: nowrap;
`;

const Right = styled.div`
  margin-left: auto;
  white-space: nowrap;
  font-family: monospace;
  > :first-of-type {
    margin-right: 4px;
  }
`;

export default {
    AccountOptionWrapper,
    Left,
    Right,
};
