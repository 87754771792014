import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';
import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { rolesRootRoutePath } from 'config/routes';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import { RoleProps } from 'constants/RolesModel';

import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';


import { createRoleDetailsTabsConfig, createRoleFieldsConfig } from './RoleDetails.helpers';


function RoleDetails({
    accessControl,
    rolesListData,
    // enhancedCurrentLocation,

    permissionsListStates,
    isLoadingPermissions,

    dispatchFetchRolesList,
    // dispatchFetchRoleDetails,
    dispatchFetchPermissionsForRole,
    dispatchSetQueryParams,
    // dispatchRequestNavigation,
    t,
}) {
    // TODO Restore in PDK-2842 once BE PDK-2880 will be done
    //          <Button
    //             style={{ position: 'absolute', top: '16px', right: '16px', display: 'block' }}
    //             icon={<SettingOutlined />}
    //             type="primary"
    //             onClick={() => dispatchRequestNavigation(createEditRolePath(fixedId))}
    //           >
    //             Edit
    //           </Button>

    const { roleId } = useParams();
    const fixedId = Number(roleId);

    const roleData = useMemo(
        () => (rolesListData?.items || []).find((role) => role?.id === fixedId),
        [rolesListData, fixedId],
    );

    useEffect(() => {
    // TODO remove list fetching and replace it with role details
    // dispatchFetchRoleDetails({ roleId: fixedId }, enhancedCurrentLocation?.pathname);

        if (!roleData?.name && accessControl.hasPermission(PN.ROLES.READ)) {
            dispatchFetchRolesList({}, rolesRootRoutePath);
        }
    }, []);

    useEffect(() => {
        if (fixedId && accessControl.hasPermission(PN.PERMISSIONS.READ_BY_ROLE)) {
            dispatchFetchPermissionsForRole(fixedId);
        }
    }, []);

    const fieldsConfig = useMemo(
        () => createRoleFieldsConfig({ roleData, t }),
        [roleData],
    );

    const rolePermissionsListData = useMemo(
        () => {
            if (!isLoadingPermissions && permissionsListStates && permissionsListStates[fixedId]) {
                return permissionsListStates[fixedId];
            }
            return [];
        },
        [isLoadingPermissions, permissionsListStates],
    );

    const tabsConfig = useMemo(
        () => createRoleDetailsTabsConfig({
            t,
            rolePermissionsListData,
            isLoadingPermissions,
            dispatchSetQueryParams,
            dispatchFetchPermissionsForRole: () => dispatchFetchPermissionsForRole(fixedId),
        }),
        [rolePermissionsListData, isLoadingPermissions],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('roleDetails.screenTitle', { roleId: fixedId, roleName: roleData?.name })}
            tabsConfig={tabsConfig}
            baseURLSegmentsCount={2}
        >
            <Col
                justify="center"
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                style={{ background: 'white', padding: '10px' }}
            >
                <EntityDetailsInfoSkeleton
                    isLoading={rolesListData?.isLoadingList}
                    minHeight={'360px'}
                    sectionsRowsCount={7}
                    sectionsCount={2}
                >
                    <Row
                        gutter={24}
                        size={10}
                        direction="horizontal"
                        style={{ background: 'white', position: 'relative' }}
                    >
                        <CommonSection
                            fieldsConfig={fieldsConfig}
                            isLoading={isLoadingPermissions}
                        />

                        <QueryParamsMetaInfo
                            detailsContext
                            isLoading={rolesListData?.isLoadingList}
                            onListRefresh={() => dispatchFetchRolesList({}, rolesRootRoutePath)}
                            queryParams={{ ...rolesListData?.queryParams, roleId: fixedId }}
                            queryParamsMeta={rolesListData?.queryParamsMeta}
                        />

                    </Row>
                </EntityDetailsInfoSkeleton>
            </Col>
        </StandardEntityDetailsWithTabs>
    );
}


RoleDetails.propTypes = {
    accessControl: AccessControlProps,
    isLoadingPermissions: PropTypes.bool.isRequired,
    permissions: PropTypes.shape({}),
    dispatchFetchRolesList: PropTypes.func.isRequired,
    dispatchFetchPermissionsForRole: PropTypes.func.isRequired,
    // dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    rolesListData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(RoleProps),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
};


export default RoleDetails;
