import StandardTooltip from 'components/common/molecules/StandardTooltip';
import { currentStepNumberInAvailableSteps } from 'redux/multi-steps-form/epics.helpers';
import { roundBig } from 'utils/math-tools';

import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';


import Styled from './MultiStepFormStepsIndicator.styled';


export const calculateSubStepsProgress = ({
    availableSubSteps,
    currentMultiStepsFormSubStep,
}) => {
    const currentSubStepNumber = 1 + currentStepNumberInAvailableSteps(availableSubSteps, currentMultiStepsFormSubStep);
    const subStepsCount = availableSubSteps?.length;

    if (currentSubStepNumber > 0) {
        return roundBig({
            value: (currentSubStepNumber * 100) / subStepsCount,
            precision: 0,
            roundingMode: 0,
        });
    }
    return 0;
};

const createStep = ({
    stepConfig,
    isLoading,
    subStepPercentage,
    currentMultiStepsFormStep,
    lastStepExceptSuccess,
}) => {
    const stepContent = (
        <Styled.MainStepLabelWrapper>
            <div className="title-icon-wrapper">
                {isLoading && currentMultiStepsFormStep === lastStepExceptSuccess
                    ? <LoadingOutlined />
                    : stepConfig.icon}
            </div>

            <Styled.MainStepLabel>
                {stepConfig.title}
            </Styled.MainStepLabel>

        </Styled.MainStepLabelWrapper>
    );

    return {
        key: stepConfig.stepName,
        title: (
            <Styled.MainStepLabelWrapper>
                {stepConfig.subStepsConfiguration?.length > 0 && currentMultiStepsFormStep === stepConfig.stepName
                    ? (
                        <StandardTooltip
                            title={`Sub-steps progress: ${subStepPercentage}%`}
                            trigger="hover"
                        >
                            {stepContent}
                        </StandardTooltip>
                    ) : stepContent}
            </Styled.MainStepLabelWrapper>
        ),
    };
};


export const createSteps = ({
    stepsConfiguration,
    isLoading,
    subStepPercentage,
    currentMultiStepsFormStep,
    lastStepExceptSuccess,
    t,
}) => (stepsConfiguration || []).map((stepConfig) => createStep({
    stepConfig,
    isLoading,
    subStepPercentage,
    currentMultiStepsFormStep,
    lastStepExceptSuccess,
    t,
}));


export default {
    calculateSubStepsProgress,
    createStep,
    createSteps,
};
