import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import CommonAddressFrom from 'components/common/organisms/CommonAddressForm';
import { CardOrderSteps } from 'redux/card-order/helpers';
import { commonValidation, isValidFirstNameRule, isValidLastNameRule } from 'utils/validation-tools';

import { Button, Form, Input, Row, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function DeliveryDetailsStep({
    isLoadingProcessCard,
    isLoadingDeliveryAddress,
    data,
    form,
    dispatchGoToPreviousOrderStep,
    t,
}) {
    useEffect(() => {
        form.setFieldsValue({ ...data });
    }, [data]);

    return (
        <Skeleton style={{ minWidth: 350 }} active loading={isLoadingDeliveryAddress}>
            <Form.Item
                label={t('common:personalFields.firstName.label')}
                name="first_name"
                rules={[
                    ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                    ...isValidFirstNameRule(t),
                ]}
                style={{ minWidth: 350 }}
            >
                <Input placeholder={t('common:personalFields.firstName.placeholder')} />
            </Form.Item>

            <Form.Item
                label={t('common:personalFields.lastName.label')}
                name="last_name"
                rules={[
                    ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                    ...isValidLastNameRule(t),
                ]}
            >
                <Input placeholder={t('common:personalFields.lastName.placeholder')} />
            </Form.Item>


            <CommonAddressFrom form={form} />

            <PhoneInput t={t} name="phone_number" />

            <EmailInput
                label={t('common:personalFields.email.label')}
                t={t}
                placeholder={t('common:personalFields.email.placeholder')}
            />

            <Row style={{ marginTop: 20 }}>
                <Form.Item>
                    <Button onClick={() => dispatchGoToPreviousOrderStep(CardOrderSteps.DELIVERY_DETAILS)} disabled={isLoadingProcessCard}>
                        {t('common:buttons.back.text')}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ marginLeft: 10 }}
                        type="primary"
                        loading={isLoadingProcessCard}
                        disabled={isLoadingProcessCard}
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </Skeleton>
    );
}

DeliveryDetailsStep.propTypes = {
    isLoadingProcessCard: PropTypes.bool.isRequired,
    isLoadingDeliveryAddress: PropTypes.bool.isRequired,
    data: PropTypes.shape({}).isRequired,
    form: PropTypes.shape({ getFieldValue: PropTypes.func.isRequired }).isRequired,
    dispatchGoToPreviousOrderStep: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default DeliveryDetailsStep;
