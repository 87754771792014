import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import { createTransactionScreeningResolutionActionsOptions } from 'utils/options-creators';
import { commonValidation, minLengthRule } from 'utils/validation-tools';

import { TransactionScope, TransactionType } from '@manigo/manigo-domain-typings';
import { Alert, Form, Input, Row, Select } from 'antd';
import React, { JSX, useEffect, useMemo } from 'react';


import { TransactionScreeningResolutionModalProps } from './TransactionScreeningResolutionModal.types';


function TransactionScreeningResolutionModal({
    initialValues,
    dispatchAuditPendingTransaction,
    dispatchSetModalProps,
    t,
}: TransactionScreeningResolutionModalProps): JSX.Element {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);


    const onFinish = (values) => {
        dispatchSetModalProps({ confirmLoading: true });
        dispatchAuditPendingTransaction(
            {
                transactionUuid: initialValues?.transactionDetails?.transactionUuid,
                action: values.action,
                comment: values.comment,
            },
            initialValues?.baseUrlWithoutTabsSlugs,
        );
    };

    const showAlert = useMemo(
        () => ((initialValues?.transactionDetails?.transactionScope === TransactionScope.CT && initialValues?.transactionDetails?.transactionType === TransactionType.EXT)
      || (initialValues?.transactionDetails?.transactionScope === TransactionScope.CV && initialValues?.transactionDetails?.transactionType === TransactionType.EXT)
        ),
        [initialValues?.transactionDetails],
    );

    return (
        <div>
            {initialValues?.transactionDetails && initialValues?.transactionDetails?.customDescription && (
                <Alert description={initialValues?.transactionDetails?.customDescription} type="info" />
            )}

            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    comment: '',
                    action: undefined,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="comment"
                    label={t('transactions:modals.screeningResolution.fields.comment.text')}
                    rules={[
                        ...commonValidation({ t, isRequired: true }),
                        ...minLengthRule(10, t),
                    ]}
                >
                    <Input.TextArea
                        showCount
                        placeholder={t('transactions:modals.screeningResolution.fields.comment.placeholder')}
                        maxLength={100}
                    />
                </Form.Item>

                <Form.Item
                    name="action"
                    label={t('transactions:modals.screeningResolution.fields.action.text')}
                    rules={[...commonValidation({ t, isRequired: true })]}
                >
                    <Select placeholder={t('transactions:modals.screeningResolution.fields.action.placeholder')}>
                        {createTransactionScreeningResolutionActionsOptions()}
                    </Select>
                </Form.Item>


                {showAlert && (
                    <Row style={{ marginTop: 20 }}>
                        <Alert
                            description={(
                                <StandardTranslationWithMarkup
                                    translationKey={'transactions:modals.screeningResolution.alertInfo'}
                                    namespace="transactions"
                                    t={t}
                                />
                            )}
                            type="warning"
                            showIcon
                        />
                    </Row>
                )}
            </Form>
        </div>
    );
}


export default TransactionScreeningResolutionModal;
