import { addClient, clearAddClientStateData, fetchBpsProducts, fetchDefaultClientConfiguration } from 'redux/client-add/actions';
import { addClientReducerName } from 'redux/client-add/reducer';
import { requestSetMultiStepsFormStep, requestSetMultiStepsFormSubStep } from 'redux/multi-steps-form/actions';
import { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddClientForm from './AddClientForm.layout';


const mapStateToProps = (state, ownProps) => {
    const { locationPathname } = ownProps;
    const currentMultiStepsFormStep = state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormStep;

    return {
    // Add client form specific
        isLoadingAddClient: state[addClientReducerName].isLoadingAddClient,
        newlyCreatedPartnerData: state[addClientReducerName].newlyCreatedPartnerData,

        // mandatory only for partner creation, sub-partner creation doesn't need this
        defaultNewClientConfiguration: state[addClientReducerName].defaultNewClientConfiguration,
        isLoadingDefaultNewClientConfiguration: state[addClientReducerName].isLoadingDefaultNewClientConfiguration,

        shippingAddressSameAsCompanyAddress: state[addClientReducerName].shippingAddressSameAsCompanyAddress,
        billingAddressSameAsCompanyAddress: state[addClientReducerName].billingAddressSameAsCompanyAddress,


        bpsProductsList: state[addClientReducerName].bpsProductsList,
        isLoadingBpsProductsList: state[addClientReducerName].isLoadingBpsProductsList,

        // standard multi step form related
        availableSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSteps,
        availableSubSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSubSteps?.[currentMultiStepsFormStep],
        currentMultiStepsFormStep,
        currentMultiStepsFormSubStep: state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormSubStep?.[currentMultiStepsFormStep],
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    // Add client form specific
    dispatchFetchDefaultClientConfiguration: () => dispatch(fetchDefaultClientConfiguration(ownProps?.locationPathname)),
    dispatchFetchBpsProducts: () => dispatch(fetchBpsProducts(ownProps?.locationPathname)),
    dispatchClearAddClientStateData: () => dispatch(clearAddClientStateData()),
    dispatchAddClient: (formData) => dispatch(addClient(formData, ownProps?.locationPathname)),
    // standard multi step form related
    dispatchRequestSetMultiStepsFormStep: (currentStep, direction) => dispatch(requestSetMultiStepsFormStep(
        currentStep,
        direction,
        ownProps?.locationPathname,
    )),
    dispatchRequestSetMultiStepsFormSubStep: (parentStep, currentSubStep, direction) => dispatch(requestSetMultiStepsFormSubStep(
        {
            parentStep,
            currentSubStep,
            direction,
        },
        ownProps?.locationPathname,
    )),


});

const decorate = compose(
    withTranslation('clients'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddClientForm);
