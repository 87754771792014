import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    createApiKeyFailure, createApiKeySuccess,
    deleteApiKeyFailure, deleteApiKeySuccess,
    fetchApiKeysList, fetchApiKeysListFailure, fetchApiKeysListSuccess, fetchApiKeyValueFailure, fetchApiKeyValueSuccess, refreshApiKeysList,
} from './actions';
import {
    CREATE_API_KEY, DELETE_API_KEY, FETCH_API_KEY_VALUE, FETCH_API_KEYS_LIST, OPEN_CREATE_API_KEY_MODAL, REFRESH_API_KEYS_LIST,
} from './actions.types';
import { apiKeysListsPerLocationStoreKeyName, apiKeysReducerName } from './reducer';


export const onFetchApiKeys = (action$, state$, { apiKeys }) => action$.pipe(
    ofType(FETCH_API_KEYS_LIST),
    switchMap(({ payload }) => from(apiKeys.fetchApiKeys(payload.queryParams)).pipe(
        switchMap((response) => of(fetchApiKeysListSuccess(
            response.data?.rows,
            response.data.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchApiKeysListFailure(payload.locationPathname))),
    )),
);

export const onFetchApiKeyValue = (action$, state$, { apiKeys }) => action$.pipe(
    ofType(FETCH_API_KEY_VALUE),
    switchMap(({ payload }) => from(apiKeys.fetchApiKeyKeValue(payload)).pipe(
        switchMap((response) => of(fetchApiKeyValueSuccess({ apiKeyId: payload, value: response?.data?.key }))),
        catchError(() => of(fetchApiKeyValueFailure())),
    )),
);


export const onOpenCreateApiKeyModal = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_CREATE_API_KEY_MODAL),
    switchMap(({ payload }) => of(showModal({
        modalType: ModalName.ADD_API_KEY_MODAL,
        modalProps: {
            title: i18n.t('apiKeys:modals.addApiKeyModal.modalTitle'),
            okButtonProps: { disabled: false },
            initialValues: { clientId: payload },
        },
    }))),
);

export const onCreateApiKey = (action$, state$, { apiKeys, i18n }) => action$.pipe(
    ofType(CREATE_API_KEY),
    switchMap(({ payload }) => from(apiKeys.createApiKey(payload.queryParams)).pipe(
        switchMap((response) => of(
            createApiKeySuccess(response.data),
            showSuccessToast(i18n.t('apiKeys:actionMessages.createApiKeySuccess')),
            hideModal(),
            refreshApiKeysList(payload.locationPathname),
        )),
        catchError(() => of(
            createApiKeyFailure(),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);


export const onDeleteApiKey = (action$, state$, { apiKeys, i18n }) => action$.pipe(
    ofType(DELETE_API_KEY),
    switchMap(({ payload }) => from(apiKeys.deleteApiKey(payload.apiKeyId)).pipe(
        switchMap((response) => of(
            deleteApiKeySuccess(response.data),
            showSuccessToast(i18n.t('apiKeys:actionMessages.deleteApiKeySuccess')),
            refreshApiKeysList(payload.locationPathname),
        )),
        catchError(() => of(deleteApiKeyFailure())),
    )),
);


export const onRefreshApiKeysList = (action$, state$) => action$.pipe(
    ofType(REFRESH_API_KEYS_LIST),
    switchMap(({ payload }) => {
        const currentQueryParams = state$.value[apiKeysReducerName][apiKeysListsPerLocationStoreKeyName]?.[payload.locationPathname]?.queryParams;
        return of(fetchApiKeysList(currentQueryParams, payload.locationPathname));
    }),
);

export default [
    onFetchApiKeys,
    onFetchApiKeyValue,
    onOpenCreateApiKeyModal,
    onCreateApiKey,
    onDeleteApiKey,
    onRefreshApiKeysList,
];
