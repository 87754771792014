import AppLoadingPlaceholder from 'components/layouts/AppLoadingPlaceholder';
import AppMaintenancePlaceholder from 'components/layouts/AppMaintenancePlaceholder';
import { hasAccessRights, renderApplicationContent, renderRoutes } from 'components/layouts/AppRoutes/AppRoutes.helpers';
import { createPublicRoutesConfiguration, createProtectedRoutesConfiguration, createDefaultRedirectionPath } from 'components/layouts/AppRoutes/createRoutesConfiguration';
import AppLayout from 'components/layouts/AuthorisedLayout';
import PublicLayout from 'components/layouts/PublicLayout';
import { isMaintenanceModeEnabled } from 'config/config';
import { AccessControlProps } from 'constants/AccessControlModel';
import { APPLICATION_STATE, ApplicationStateProps } from 'constants/ApplicationStateModel';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function AppRoutes({
    applicationState,
    clientId,
    accessControl,
}) {
    const publicRoutesConfiguration = createPublicRoutesConfiguration();
    const protectedRoutesConfiguration = createProtectedRoutesConfiguration();

    const isAuthorised = useMemo(
        () => accessControl?.isAuthorised,
        [accessControl],
    );
    const isSuperAdmin = useMemo(
        () => accessControl?.isSuperAdmin,
        [accessControl],
    );

    const showSpinner = useMemo(
        () => applicationState === APPLICATION_STATE.APPLICATION_STARTED
      || (isAuthorised && applicationState === APPLICATION_STATE.INIT_AUTHORISED_GLOBAL_DATA_FETCHING),
        [applicationState, isAuthorised],
    );

    const defaultRedirectionPath = useMemo(
        () => createDefaultRedirectionPath({ isAuthorised, isSuperAdmin, clientId }),
        [isAuthorised, clientId, isSuperAdmin],
    );


    const publicRoutes = useMemo(
        () => (!isAuthorised ? renderRoutes({
            routesConfiguration: publicRoutesConfiguration,
            accessControl,
            defaultRedirectionPath,
        }) : []),
        [publicRoutesConfiguration, defaultRedirectionPath, accessControl],
    );

    const availableProtectedRoutes = useMemo(
        () => {
            const availableRoutes = protectedRoutesConfiguration.filter((routeConfiguration) => hasAccessRights({
                routeConfiguration,
                accessControl,
            }));

            return renderRoutes({
                routesConfiguration: availableRoutes,
                accessControl,
                defaultRedirectionPath,
            });
        },
        [protectedRoutesConfiguration, isAuthorised, accessControl],
    );

    return (
        <>
            {isMaintenanceModeEnabled ? <AppMaintenancePlaceholder /> : null}

            {showSpinner ? <AppLoadingPlaceholder /> : null}


            {!isMaintenanceModeEnabled && !showSpinner && !isAuthorised
                ? renderApplicationContent({
                    Layout: PublicLayout,
                    routesConfiguration: publicRoutes,
                    defaultRedirectionPath,
                })
                : null}

            {!isMaintenanceModeEnabled && !showSpinner && isAuthorised
                ? renderApplicationContent({
                    Layout: AppLayout,
                    routesConfiguration: availableProtectedRoutes,
                    defaultRedirectionPath,
                })
                : null}

        </>
    );
}


AppRoutes.propTypes = {
    clientId: PropTypes.number,
    accessControl: AccessControlProps,
    applicationState: ApplicationStateProps,
};

export default AppRoutes;

