import { createMemberDetailsPath } from 'config/routes.helpers';
import { AllEntityTypes, defaultQueryParamsMeta } from 'constants/ApplicationStateModel';
import { updateAddress, updateAddressFailure, updateAddressSuccess } from 'redux/address/actions';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';

import { createReducer } from '@reduxjs/toolkit';


import { updateMember, updateMemberSuccess, updateMemberFailure } from './actions';
import {
    FETCH_MEMBER_DETAILS,
    FETCH_MEMBER_DETAILS_SUCCESS,
    FETCH_MEMBER_DETAILS_FAILURE,
} from './actions.types';


export const memberReducerName = 'member';
export const memberDetailsPerLocationsStoreKeyName = 'memberDetailsPerLocations';

const initialState = {
    [memberDetailsPerLocationsStoreKeyName]: {},

    isLoadingUpdateMember: false,
    isLoadingUpdateMemberAddress: false,
    memberDetailsQueryParamsMeta: defaultQueryParamsMeta,

    riskAssessment: undefined,
    isLoadingRiskAssessment: false,
};

const handlers = {
    [FETCH_MEMBER_DETAILS]: (state, action) => {
        state[memberDetailsPerLocationsStoreKeyName] = updateStandardEntityDetails(state[memberDetailsPerLocationsStoreKeyName], action);
    },
    [FETCH_MEMBER_DETAILS_SUCCESS]: (state, action) => {
        state[memberDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[memberDetailsPerLocationsStoreKeyName], action);
    },
    [FETCH_MEMBER_DETAILS_FAILURE]: (state, action) => {
        state[memberDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnFailure(state[memberDetailsPerLocationsStoreKeyName], action);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === memberReducerName
      && action.payload?.fieldName === memberDetailsPerLocationsStoreKeyName
        ) {
            state[memberDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[memberDetailsPerLocationsStoreKeyName] }, action);
        }
    },


    [updateMember]: (state) => {
        state.isLoadingUpdateMember = true;
    },
    [updateMemberSuccess]: (state, action) => {
        state.isLoadingUpdateMember = false;
        state[memberDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[memberDetailsPerLocationsStoreKeyName], action);
    },
    [updateMemberFailure]: (state) => {
        state.isLoadingUpdateMember = false;
    },


    [updateAddress]: (state, action) => {
        if (action.payload.addressType.entityType === AllEntityTypes.MEMBER) {
            state.isLoadingUpdateMemberAddress = true;
        }
    },
    [updateAddressSuccess]: (state, action) => {
        if (action.payload.addressType.entityType === AllEntityTypes.MEMBER) {
            const locationPathname = createMemberDetailsPath(action.payload.addressType?.entityId);
            const entityDetails = state[memberDetailsPerLocationsStoreKeyName]?.[locationPathname]?.entityDetails;
            const mockAction = {
                payload: {
                    locationPathname,
                    responsePayload: {
                        ...entityDetails,
                        // remove this after the migration of address service
                        memberAddress: convertSnakeCaseKeysToCamelCase(action.payload.responseData),
                    },
                },
            };

            state.isLoadingUpdateMemberAddress = false;

            state[memberDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[memberDetailsPerLocationsStoreKeyName], mockAction);
        }
    },
    [updateAddressFailure]: (state, action) => {
        if (action.payload.entityType === AllEntityTypes.MEMBER) {
            state.isLoadingUpdateMemberAddress = false;
        }
    },
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
