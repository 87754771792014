import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import EditClientSettingsPage from './EditClientSettings.layout';


const decorate = compose(
    withTranslation('clients'),
);

export default decorate(EditClientSettingsPage);
