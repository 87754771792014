import { scrollBar } from 'assets/styles/scrollbar';

import styled from 'styled-components';


const RiskDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 50px;
`;

const CurrentRiskDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-column-gap: 15px;
  justify-content: center;
  align-items: center;
`;

const ChangeRiskScoreWrapper = styled.div`
  padding: 20px 0;
  width: fit-content;
  margin: 0 auto;
`;

const HistoryRiskDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-column-gap: 15px;
  max-height: 300px;
  overflow-y: auto;
  ${scrollBar}
`;

export default {
    RiskDetailsWrapper,
    CurrentRiskDetailsWrapper,
    ChangeRiskScoreWrapper,
    HistoryRiskDetailsWrapper,
};
