import {
    getTargetList,
    fetchCardMatrix,
    setData,
    submitOrderStep, setMemberEmbossName,
    clearMembers, clearCompanies, clearCardsDetails, clearAddress,
} from 'redux/card-order/actions';
import { cardsOrderReducerName } from 'redux/card-order/reducer';
import { requestNavigationBack } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import SelectTargetStep from './SelectTargetStep.layout';


const mapStateToProps = (state) => ({
    data: state[cardsOrderReducerName].data,
    members: state[cardsOrderReducerName].members,
    companies: state[cardsOrderReducerName].companies,
    isLoadingCompanies: state[cardsOrderReducerName].isLoadingCompanies,
    isLoadingMembers: state[cardsOrderReducerName].isLoadingMembers,
});


const mapDispatchToProps = (dispatch) => ({
    dispatchGetTargetList: (targetType, id, search) => dispatch(getTargetList(targetType, id, search)),
    fetchCardMatrix: (id) => dispatch(fetchCardMatrix(id)),
    dispatchSetData: (data) => dispatch(setData(data)),
    dispatchSubmitOrderStep: (step) => dispatch(submitOrderStep(step)),
    dispatchRequestNavigationBack: () => dispatch(requestNavigationBack()),
    dispatchClearMembers: () => dispatch(clearMembers()),
    dispatchClearCompanies: () => dispatch(clearCompanies()),
    dispatchSetMemberEmbossName: (embossName) => dispatch(setMemberEmbossName(embossName)),
    dispatchClearCardsDetails: () => dispatch(clearCardsDetails()),
    dispatchClearAddress: () => dispatch(clearAddress()),
});

const decorate = compose(
    withTranslation('cards'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(SelectTargetStep);
