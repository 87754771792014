export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS';
export const FETCH_ACCOUNT_DETAILS_SUCCESS = 'FETCH_ACCOUNT_DETAILS_SUCCESS';
export const FETCH_ACCOUNT_DETAILS_FAILURE = 'FETCH_ACCOUNT_DETAILS_FAILURE';
export const CLEAR_ACCOUNT_DETAILS = 'CLEAR_ACCOUNT_DETAILS';

export const SHOW_DOWNLOAD_PDF_STATEMENT_FORM = 'SHOW_DOWNLOAD_PDF_STATEMENT_FORM';
export const HIDE_DOWNLOAD_PDF_STATEMENT_FORM = 'HIDE_DOWNLOAD_PDF_STATEMENT_FORM';

export const DOWNLOAD_ACCOUNT_STATEMENT_PDF = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF';
export const DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS';
export const DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE';

export const BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT';
export const BALANCE_ADJUSTMENT_SUCCESS = 'BALANCE_ADJUSTMENT_SUCCESS';
export const BALANCE_ADJUSTMENT_FAILURE = 'BALANCE_ADJUSTMENT_FAILURE';

export const SET_AS_PRIMARY_ACCOUNT = 'SET_AS_PRIMARY_ACCOUNT';
export const SET_AS_PRIMARY_ACCOUNT_SUCCESS = 'SET_AS_PRIMARY_ACCOUNT_SUCCESS';
export const SET_AS_PRIMARY_ACCOUNT_FAILURE = 'SET_AS_PRIMARY_ACCOUNT_FAILURE';

export const WITHDRAW_BALANCE = 'WITHDRAW_BALANCE';
export const WITHDRAW_BALANCE_SUCCESS = 'WITHDRAW_BALANCE_SUCCESS';
export const WITHDRAW_BALANCE_FAILURE = 'WITHDRAW_BALANCE_FAILURE';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const OPEN_BALANCE_ADJUSTMENT_MODAL = 'OPEN_BALANCE_ADJUSTMENT_MODAL';
export const OPEN_WITHDRAW_ACCOUNT_BALANCE_MODAL = 'OPEN_WITHDRAW_ACCOUNT_BALANCE_MODAL';
