import TabSubHeading from 'components/common/atoms/TabSubHeading';
import StandardFormItem from 'components/common/organisms/StandardFormItem';

import PropTypes from 'prop-types';
import React from 'react';

import Styled from './StandardFormSection.styled';


function StandardFormSection({
    formSectionConfig,
    t,
}) {
    return (
        <Styled.StandardFormSectionWrapper style={formSectionConfig?.style}>
            <Styled.StandardFormSectionTitle>
                {formSectionConfig?.title && (
                    <TabSubHeading text={formSectionConfig?.title} />
                )}
            </Styled.StandardFormSectionTitle>

            <Styled.StandardFormSectionFieldsWrapper $fieldsLayout={formSectionConfig?.fieldsLayout}>
                {formSectionConfig?.fieldsConfiguration?.length > 0
                    ? formSectionConfig?.fieldsConfiguration.map((fieldConfig) => (
                        <StandardFormItem
                            key={fieldConfig.name}
                            fieldConfig={fieldConfig}
                            t={t}
                        />
                    ))
                    : formSectionConfig?.children}
            </Styled.StandardFormSectionFieldsWrapper>
        </Styled.StandardFormSectionWrapper>
    );
}

StandardFormSection.propTypes = {
    formSectionConfig: PropTypes.shape({}),
    t: PropTypes.func,
};


export default StandardFormSection;
