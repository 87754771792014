import CardTypeTag from 'components/common/atoms/tags/CardTypeTag';

import { List } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function CardItem({ card, t }) {
    return (
        <>
            <List.Item.Meta
                title={`${card.pan_mask} ${card.card_type ? ` - ${card.card_type}` : ''} ${card.emboss_name ? ` - ${card.emboss_name}` : ''}`}
                description={`${card.token}`}
            />
            <CardTypeTag cardType={card.c_type || card.type} t={t} />
        </>
    );
}


CardItem.propTypes = {
    card: PropTypes.shape({}),
    t: PropTypes.func.isRequired,
};

export default CardItem;
