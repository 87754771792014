import PropTypes from 'prop-types';
import React from 'react';


import Styled from './OptionalButtonsWrapper.styled';


function OptionalButtonsWrapper({ children }) {
    return (
        <Styled.OptionalButtonsAbsoluteWrapper>
            {children}
        </Styled.OptionalButtonsAbsoluteWrapper>
    );
}

OptionalButtonsWrapper.propTypes = { children: PropTypes.node };

export default OptionalButtonsWrapper;
