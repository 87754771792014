import { createListActions } from 'redux/standardLists.helpers';

import { FETCH_LIMITS, FETCH_LIMITS_SUCCESS, FETCH_LIMITS_FAILURE, CLEAR_LIMITS_LIST } from './actions.types';


const listActions = createListActions(
    {
        base: FETCH_LIMITS,
        success: FETCH_LIMITS_SUCCESS,
        failure: FETCH_LIMITS_FAILURE,
        clear: CLEAR_LIMITS_LIST,
    },
);

export const fetchLimits = listActions.fetchList;
export const fetchLimitsSuccess = listActions.fetchListSuccess;
export const fetchLimitsFailure = listActions.fetchListFailure;
export const clearLimitsList = listActions.clearList;
