import { standardPageSize } from 'config/config';
import { clientRootRoutePath, companiesRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    addCompany, addCompanySuccess, addCompanyFailure,
    fetchCompanies, fetchCompaniesSuccess, fetchCompaniesFailure,
    clearCompaniesState, clearCompaniesList,
} from './actions';


export const companiesReducerName = 'companies';
export const companiesListsPerLocationStoreKeyName = 'companiesListsPerLocationStoreKey';

export const defaultCompaniesListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    clientId: undefined,
    includeInactivePartners: false,
    sortBy: 'company_id',
    sortOrder: SortDirections.DESCEND,
    ...withTotalCount,
};


const initialState = {
    [companiesListsPerLocationStoreKeyName]: {},

    isLoadingAddCompany: false,
    newlyCreatedCompanyData: undefined,
};

const handlers = {
    [fetchCompanies]: (state, action) => {
        state[companiesListsPerLocationStoreKeyName] = updateStandardLists(state[companiesListsPerLocationStoreKeyName], action);
    },
    [fetchCompaniesSuccess]: (state, action) => {
        state[companiesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[companiesListsPerLocationStoreKeyName], action);
    },
    [fetchCompaniesFailure]: (state, action) => {
        state[companiesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[companiesListsPerLocationStoreKeyName], action);
    },
    [clearCompaniesList]: (state, action) => {
        state[companiesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[companiesListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[companiesListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[companiesListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [companiesRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === companiesReducerName
      && action.payload?.fieldName === companiesListsPerLocationStoreKeyName
        ) {
            state[companiesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[companiesListsPerLocationStoreKeyName] }, action);
        }
    },


    [addCompany]: (state) => {
        state.isLoadingAddCompany = true;
    },
    [addCompanySuccess]: (state, action) => {
        state.isLoadingAddCompany = false;
        state.newlyCreatedCompanyData = action.payload.responsePayload;
    },
    [addCompanyFailure]: (state) => {
        state.isLoadingAddCompany = false;
    },


    [clearCompaniesState]: () => initialState,
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
