import { HttpService } from 'services/http';

import { Uuid } from '@manigo/manigo-domain-typings';


export const fetchWebhooksAPI = (config: { convertSnakeCaseKeysToCamelCase: boolean }) => HttpService.get('/v2/webhooks/all', config);

export const fetchFailedWebhooksAPI = (config: { convertSnakeCaseKeysToCamelCase: boolean }) => HttpService.get('/v2/webhooks/failed', config);
export const fetchFailedWebhookDetailsAPI = (
    webhookUuid: Uuid, config: { convertSnakeCaseKeysToCamelCase: boolean },
) => HttpService.get(`/v2/webhooks/failed/${webhookUuid}`, config);

export const fetchFailedWebhookErrorsHistoryAPI = (
    webhookUuid: Uuid, config: { convertSnakeCaseKeysToCamelCase: boolean },
) => HttpService.get(`/v2/webhooks/failed/${webhookUuid}/history`, config);


export const fetchWebhooksActionTypesAPI = () => HttpService.get('/v2/webhooks/available-actions');

export const registerWebhookAPI = (requestPayload) => HttpService.post('/v2/webhooks/register', requestPayload);

export const updateWebhookUuid = (webhookUuid: Uuid, requestPayload) => HttpService.put(`/v2/webhooks/edit/${webhookUuid}`, requestPayload);

export const unregisterWebhookUuid = (webhookUuid: Uuid) => HttpService.delete(`/v2/webhooks/unregister/${webhookUuid}`);

export const replayFailedWebhookUuid = (webhookUuid: Uuid) => HttpService.post(`/v2/webhooks/replay-failed/${webhookUuid}`);

export const replayAllFailedWebhooksAPI = () => HttpService.post('/v2/webhooks/replay-all-failed');

