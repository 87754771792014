import { AccountOwnerTypes, AccountTypes } from 'constants/AccountModel';
import { showSuccessToast } from 'redux/application/actions';
import {
    createOutgoingTransferFailure, createOutgoingTransferSuccess, fetchAllAccountsFailure, fetchAllAccountsSuccess,
    fetchAllBeneficiariesFailure, fetchAllBeneficiariesSuccess,
} from 'redux/outgoing-transfer/actions';
import { outgoingTransferReducerName } from 'redux/outgoing-transfer/reducer';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    CREATE_OUTGOING_TRANSFER,
    FETCH_ALL_ACCOUNTS,
    FETCH_ALL_BENEFICIARIES,
    GO_TO_PREVIOUS_OUTGOING_TRANSFER_FORM_STEP,
    SUBMIT_OUTGOING_TRANSFER_FORM_STEP,
} from './actions.types';
import {
    createOnGoToPreviousOutgoingTransferFormStepActions,
    createOnSubmitFormStepActions,
} from './epics.helpers';


export const onFetchAllBeneficiaries = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_ALL_BENEFICIARIES),
    switchMap(() => from(beneficiaries.fetchBeneficiaries({
        limit: 1000,
        offset: 0,
        // TODO no search
        clientId: state$.value[outgoingTransferReducerName].contextData.clientId,
        memberId: state$.value[outgoingTransferReducerName].contextData.memberId,
        companyId: state$.value[outgoingTransferReducerName].contextData.companyId,
    })).pipe(
        switchMap((response) => of(fetchAllBeneficiariesSuccess(convertSnakeCaseKeysToCamelCase(response.data)))),
        catchError(() => of(fetchAllBeneficiariesFailure())),
    )),
);

export const onFetchAllAccounts = (action$, state$, { accounts }) => action$.pipe(
    ofType(FETCH_ALL_ACCOUNTS),
    switchMap(() => {
        const contextData = state$.value[outgoingTransferReducerName]?.contextData;
        const handlers = [
            {
                predicate: contextData.clientId,
                handler: () => ({
                    ownerType: AccountOwnerTypes.CLIENT,
                    ownerId: contextData.clientId,
                    type: AccountTypes.Base,
                }),
            },
            {
                predicate: contextData.memberId,
                handler: () => ({
                    ownerType: AccountOwnerTypes.MEMBER,
                    ownerId: contextData.memberId,
                    type: AccountTypes.Personal,
                }),
            },
            {
                predicate: contextData.companyId,
                handler: () => ({
                    ownerType: AccountOwnerTypes.COMPANY,
                    ownerId: contextData.companyId,
                    type: AccountTypes.Business,
                }),
            },
        ];

        return from(accounts.getAccountsList({ ...(handlers.find((handler) => handler.predicate) || {}).handler() }))
            .pipe(
                switchMap((response) => of(fetchAllAccountsSuccess(convertSnakeCaseKeysToCamelCase(response.data)))),
                catchError(() => of(fetchAllAccountsFailure())),
            );
    }),
);

export const onCreateOutgoingTransfer = (action$, state$, { transactions, i18n }) => action$.pipe(
    ofType(CREATE_OUTGOING_TRANSFER),
    switchMap(() => {
        const { accountDetails, beneficiaryDetails, formData } = state$.value[outgoingTransferReducerName];
        const requestPayload = {
            account_id: accountDetails.id,
            amount: formData.amount,
            reason: formData.reason,
            receiver: { beneficiary_id: beneficiaryDetails.id },
            ...(formData.reference ? { reference: formData.reference } : {}),
        };

        return from(transactions.createNewPayment(requestPayload)).pipe(
            switchMap((response) => of(
                createOutgoingTransferSuccess(response.data),
                showSuccessToast(i18n.t('transactions:actionMessages.createNewPaymentSuccess')),
            )),
            catchError(() => of(createOutgoingTransferFailure())),
        );
    }),
);

export const onSubmitOutgoingTransferFormStep = (action$) => action$.pipe(
    ofType(SUBMIT_OUTGOING_TRANSFER_FORM_STEP),
    switchMap(({ payload }) => createOnSubmitFormStepActions({
        currentStep: payload.step,
        newData: payload.formData,
    })),
);

export const onGoToPreviousOutgoingTransferFormStep = (action$) => action$.pipe(
    ofType(GO_TO_PREVIOUS_OUTGOING_TRANSFER_FORM_STEP),
    switchMap(({ payload }) => createOnGoToPreviousOutgoingTransferFormStepActions(payload)),
);

export default [
    onFetchAllBeneficiaries,
    onFetchAllAccounts,
    onCreateOutgoingTransfer,
    onSubmitOutgoingTransferFormStep,
    onGoToPreviousOutgoingTransferFormStep,
];
