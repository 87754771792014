import StandardListTable from 'components/common/organisms/StandardListTable';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { CountriesListProps } from 'constants/CountriesModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultCountriesListQueryParams } from 'redux/countries/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createCountriesListTableColumns from './createTableColumns';


function CountriesList({
    enhancedCurrentLocation,
    listData,

    dispatchSetQueryParams,
    dispatchFetchCountriesList,
    dispatchClearCountriesList,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { };


    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        useGlobalLocation: true,
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultCountriesListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchCountriesList,
        dispatchClearItemsList: dispatchClearCountriesList,
        dispatchSetQueryParams,
    });

    // 4a. list specific stuff - local search, local sorting is in table configuration, see 'createCountriesListTableColumns'
    const filteredCountries = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                // TODO
                // eslint-disable-next-line complexity
                return (listData?.items || []).filter((c) => searchCaseInsensitive(c?.name, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.full_name, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.country_code, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.iso_31663, listData?.queryParams?.search)
          || searchCaseInsensitive(`${c?.iso_31662}`, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.capital, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.citizenship, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.currency_code, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.currency_sub_unit, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.currency, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.currency_symbol, listData?.queryParams?.search)
          || searchCaseInsensitive(c?.currency, listData?.queryParams?.search));
            }
            return listData?.items || [];
        }, [listData],
    );

    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchCountriesList(contextEnforcedQueryParams, enhancedCurrentLocation.pathname)}
            items={filteredCountries}
            totalItemsCount={filteredCountries.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createCountriesListTableColumns({
                queryParams: listData?.queryParams || {},
                t,
            })}
        />
    );
}

CountriesList.propTypes = {
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: CountriesListProps,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    dispatchFetchCountriesList: PropTypes.func.isRequired,
    dispatchClearCountriesList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default CountriesList;
