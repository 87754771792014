import styled, { css } from 'styled-components';


const AppLoadingPlaceholder = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
  position: relative;

  

  ${({ $asOverlay }) => $asOverlay && css`
    position: fixed;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: #fff;
  `};
`;

const BrandAnimatedLoadingPlaceholder = styled.div`
  text-align: center;
  
  img {
    height: auto;
    max-height: 300px;

    width: 100%;
    min-width: 280px;
    max-width: 70%;
  }
`;

const LoadingProgressInfoWrapper = styled.div`
  position: absolute;
  bottom: 30px;
`;

const LoadingProgressInfo = styled.div`
  font-family: monospace;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  
  ${({ $isLoaded }) => $isLoaded && css`
    color: green;
  `};
`;

const Label = styled.span`
  font-size: 12px;
`;

const LoadedLabelWrapper = styled.span`
  font-size: 12px;
`;
const LoadedLabel = styled.span`
  padding-right: 2ch;
`;

const RestartApplicationInfoWrapper = styled.div`
  position: absolute;
  bottom: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  > div:first-of-type {
    font-size: 16px;
  }
`;

export default {
    AppLoadingPlaceholder,
    BrandAnimatedLoadingPlaceholder,
    LoadingProgressInfoWrapper,
    LoadingProgressInfo,
    Label,
    LoadedLabelWrapper,
    LoadedLabel,
    RestartApplicationInfoWrapper,
};

