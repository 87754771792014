import StakeholderStatusTag from 'components/common/atoms/tags/StakeholderStatusTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import ModalName from 'constants/Modals';
import { PN } from 'constants/PermissionsModel';

import { PlusOutlined } from '@ant-design/icons';
import React from 'react';


export default ({
    dispatchShowModal,
    accessControl,
    isInReadOnlyMode,
    enhancedCurrentLocation,
    companyId,
    t,
}) => [
    {
        title: t('stakeholders:stakeholderFields.firstName.label'),
        dataIndex: 'first_name',
        align: 'center',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('stakeholders:stakeholderFields.lastName.label'),
        dataIndex: 'last_name',
        align: 'center',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('stakeholders:stakeholderFields.status.label'),
        align: 'center',
        dataIndex: 'stakeholder_status',
        render: (text) => <StakeholderStatusTag status={text} />,
    },
    {
        title: t('stakeholders:stakeholderFields.extId.label'),
        dataIndex: 'stakeholder_user_uuid',
        align: 'center',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('stakeholders:stakeholderFields.applicantId.label'),
        dataIndex: 'applicant_id',
        align: 'center',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: 'Is Business User',
        align: 'center',
        dataIndex: 'is_business_user',
        render: (text, record) => booleanAsIconsRenderer(
            record,
            'is_business_user',
            t,
        ),
    },
    ...(accessControl.hasPermission(PN.COMPANIES.CREATE_EMPLOYEE)
  && !isInReadOnlyMode ? [{
            title: t('common:table.columnNames.actions'),
            align: 'center',
            render: (_, record) => (!record.is_business_user ? (
                viewButtonRenderer({
                    record,
                    onClick: (event, record) => dispatchShowModal({
                        modalType: ModalName.ADD_BUSINESS_USER_MODAL,
                        modalProps: {
                            title: 'Create business user',
                            locationPathname: enhancedCurrentLocation?.pathname,
                            initialValues: {
                                preSelectedStakeholder: record,
                                companyId,
                            },
                        },
                    }),
                    icon: <PlusOutlined />,
                    tooltipContent: 'Create Business User',
                    dataTestIdSuffix: 'create-business-user-from-stakeholder',
                })
            ) : null),
        }] : []),


];
