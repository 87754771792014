import { standardPageSize } from 'config/config';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    addUser, addUserSuccess, addUserFailure,
    fetchUsers, fetchUsersSuccess, fetchUsersFailure, clearUsersList,
} from './actions';


export const usersReducerName = 'users';
export const usersListsPerLocationStoreKeyName = 'usersListsPerLocationStoreKey';

export const defaultUserListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    clientId: undefined, // all clients
    includeInactivePartners: false,
};

const defaultUserListValues = {
    [usersListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    addUserLoading: false,
};


const initialState = { ...defaultUserListValues };

const handlers = {
    [fetchUsers]: (state, action) => {
        state[usersListsPerLocationStoreKeyName] = updateStandardLists(state[usersListsPerLocationStoreKeyName], action);
    },
    [fetchUsersSuccess]: (state, action) => {
        state[usersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[usersListsPerLocationStoreKeyName], action);
    },
    [fetchUsersFailure]: (state, action) => {
        state[usersListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[usersListsPerLocationStoreKeyName], action);
    },
    [clearUsersList]: (state, action) => {
        state[usersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[usersListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === usersReducerName
      && action.payload?.fieldName === usersListsPerLocationStoreKeyName
        ) {
            state[usersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[usersListsPerLocationStoreKeyName] }, action);
        }
    },

    [addUser]: (state) => {
        state.addUserLoading = true;
    },
    [addUserSuccess]: (state) => {
        state.addUserLoading = false;
    },
    [addUserFailure]: (state) => {
        state.addUserLoading = false;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
