import StandardListTable from 'components/common/organisms/StandardListTable';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultWebhooksListQueryParams } from 'redux/webhooks/reducer';
import { removeUnderscores, searchCaseInsensitive } from 'utils/string-tools';

import { PlusOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';


import { WebhooksListProps } from './WebhooksList.types';
import tableColumns from './tableColumns';


function WebhooksList({
    // ownProps
    clientId,

    // mapped props
    accessControl,

    enhancedCurrentLocation,
    listData,

    isLoadingUpdateWebhook,
    isLoadingRegisterWebhook,

    dispatchOpenRegisterOrEditWebhookModal,
    dispatchSetQueryParams,
    dispatchFetchWebhooksList,
    dispatchClearWebhooksList,
    isLoadingUnregisterWebhook,
    dispatchUnregisterWebhook,
    dispatchShowViewJsonModal,
    t,
}: WebhooksListProps) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { clientId };

    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultWebhooksListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchWebhooksList,
        dispatchClearItemsList: dispatchClearWebhooksList,
        dispatchSetQueryParams,
    });

    // 4a. list specific stuff - local search, local sorting is in table configuration, see 'tableColumns'
    const filteredWebhooks = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((webhook) => searchCaseInsensitive(`${webhook?.uuid}`, listData?.queryParams?.search)
          || searchCaseInsensitive(webhook?.apiKey, listData?.queryParams?.search)
          || searchCaseInsensitive(removeUnderscores(webhook?.action), listData?.queryParams?.search)
          || searchCaseInsensitive(webhook?.webhookUrl, listData?.queryParams?.search));
            }
            return listData?.items || [];
        }, [listData],
    );


    const buttonsConfig = useMemo(
        () => (accessControl.hasPermission(PN.WEBHOOKS.REGISTER)
            ? [{
                type: 'primary',
                text: t('buttons.registerWebhook.text'),
                icon: <PlusOutlined />,
                isLoading: isLoadingRegisterWebhook,
                onClick: () => dispatchOpenRegisterOrEditWebhookModal({ clientId }, enhancedCurrentLocation?.pathname),
            }]
            : []),
        [clientId, accessControl, enhancedCurrentLocation],
    );


    return (
        <StandardListTable
            rowKey={'uuid'}
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchWebhooksList({ ...contextEnforcedQueryParams }, enhancedCurrentLocation.pathname)}
            items={filteredWebhooks}
            totalItemsCount={filteredWebhooks.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList || isLoadingUpdateWebhook || isLoadingUnregisterWebhook || isLoadingRegisterWebhook}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={tableColumns({
                accessControl,
                queryParams: listData?.queryParams,
                dispatchUnregisterWebhook,
                dispatchOpenRegisterOrEditWebhookModal,
                dispatchShowViewJsonModal,
                enhancedCurrentLocation,
                t,
            })}
            filtersButtonsConfig={buttonsConfig}
        />
    );
}


export default WebhooksList;
