import PropTypes from 'prop-types';


export const StakeholderStatus = Object.freeze({
    KYC_PENDING: 'KYC_PENDING',
    KYC_UPLOADED: 'KYC_UPLOADED',
    KYC_REJECTED: 'KYC_REJECTED',
    VERIFIED: 'VERIFIED',
});

export const StakeholderProps = {
    stakeholder_user_uuid: PropTypes.string, // UUID
    client_id: PropTypes.number.isRequired,
    client_uuid: PropTypes.string.isRequired,
    ext_company_id: PropTypes.string,
    applicant_id: PropTypes.string,
    company_uuid: PropTypes.string.isRequired,
    company_id: PropTypes.number.isRequired,
    stakeholder_status: PropTypes.oneOf(Object.values(StakeholderStatus)),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    is_business_user: PropTypes.bool,
    stakeholder_positions: PropTypes.arrayOf(PropTypes.string),
    updated_at: PropTypes.string,
    created_at: PropTypes.string.isRequired,
};

export const StakeholderListItemProps = PropTypes.shape({ ...StakeholderProps });

export const StakeholderListProp = PropTypes.arrayOf(StakeholderListItemProps);


export default {
    StakeholderListItemProps,
    StakeholderListProp,
};
