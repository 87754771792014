import { renderStandardFormControlFromItemLabel } from 'components/common/organisms/StandardFormItem/StandardFormItem.helpers';
import Styled from 'components/common/organisms/StandardFormItem/StandardFormItem.styled';
import { commonValidation, isValidEmail } from 'utils/validation-tools';

import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function EmailInput({
    id,
    name = 'email',
    label,
    placeholder,
    additionalValidationRules = [],
    isRequired,
    dataTestId = 'email-input',
    dataTestIdPrefix,
    hideLabel = false,
    t,
}) {
    const defaultValidationRules = [
        ...commonValidation({ t, isRequired, maxLength: 128 }),
        ...isValidEmail(t),
    ];

    const rules = useMemo(() => [
        ...defaultValidationRules,
        ...(additionalValidationRules || []),
    ],
    [additionalValidationRules]);


    return (
        <div style={{ marginBottom: 10 }}>
            <Styled.StandardFormItemWrapper key={`form-field-${name}`}>
                <Form.Item
                    name={name}
                    label={hideLabel ? undefined : renderStandardFormControlFromItemLabel({
                        fieldConfig: {
                            infoTooltip: 'e.g. user.name@company-name.international',
                            label: label || t('common:personalFields.email.label'),
                        },
                        t,
                    })}
                    rules={rules}
                >
                    <Input
                        id={id}
                        type="email"
                        data-test-id={`${dataTestIdPrefix ? `${dataTestIdPrefix}-` : ''}${dataTestId}`}
                        placeholder={placeholder || t('common:personalFields.email.placeholder')}
                    />
                </Form.Item>
            </Styled.StandardFormItemWrapper>
        </div>
    );
}

EmailInput.propTypes = {
    name: PropTypes.string,
    t: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    hideLabel: PropTypes.bool,
    additionalValidationRules: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.objectOf({})), PropTypes.arrayOf(PropTypes.func), PropTypes.bool]),
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    dataTestId: PropTypes.string,
    dataTestIdPrefix: PropTypes.string,
};

export default EmailInput;
