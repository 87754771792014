import { requestSetMultiStepsFormStep } from 'redux/multi-steps-form/actions';
import { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';
import { fetchOperationsList, fetchRolesTemplatesList } from 'redux/operations/actions';
import { operationsListsPerLocationStoreKeyName, operationsReducerName, roleTemplatesListsPerLocationStoreKeyName } from 'redux/operations/reducer';
import { clearAddEditRoleFormState, createRole } from 'redux/role/actions';
import { roleReducerName } from 'redux/role/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddRoleForm from './AddRoleForm.layout';


const mapStateToProps = (state, ownProps) => {
    const { locationPathname } = ownProps;
    const currentMultiStepsFormStep = state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormStep;
    const operationsLists = state[operationsReducerName]?.[operationsListsPerLocationStoreKeyName]?.[locationPathname]?.items;
    const rolesTemplatesLists = state[operationsReducerName]?.[roleTemplatesListsPerLocationStoreKeyName]?.[locationPathname]?.items;

    return {
        operationsLists,
        rolesTemplatesLists,
        isLoadingRoleDetails: state[roleReducerName]?.isLoadingClientDetails,
        isLoadingCreateRole: state[roleReducerName]?.isLoadingCreateRole,
        newlyCreatedRoleData: state[roleReducerName]?.newlyCreatedRoleData,
        selectedPermissions: state[roleReducerName]?.selectedPermissions,
        selectedRoleTemplate: state[roleReducerName]?.selectedRoleTemplate,
        // standard multi step form related
        availableSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSteps,
        currentMultiStepsFormStep,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchCreateRole: (requestData) => dispatch(createRole(requestData)),
    dispatchFetchOperationsList: (queryParams) => dispatch(fetchOperationsList(queryParams, ownProps?.locationPathname)),
    dispatchFetchRolesTemplatesList: (queryParams) => dispatch(fetchRolesTemplatesList(queryParams, ownProps?.locationPathname)),
    dispatchClearAddEditRoleFormState: () => dispatch(clearAddEditRoleFormState()),

    // standard multi step form related
    dispatchRequestSetMultiStepsFormStep: (currentStep, direction) => dispatch(requestSetMultiStepsFormStep(
        currentStep,
        direction,
        ownProps?.locationPathname,
    )),
});

const decorate = compose(
    withTranslation('roles'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddRoleForm);
