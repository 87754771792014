import FlagAndValue from 'components/common/atoms/FlagAndValue';
import { CountriesListProps, CountryIdType, CountryIdTypeProp } from 'constants/CountriesModel';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function CountryRenderer({
    countriesList,
    value,
    propertyName = CountryIdType.iso_31662,
    showCountryName = true,
    showTooltip,
    isTextEllipsisDisabled,
}) {
    const countryData = useMemo(
        () => {
            if (countriesList && countriesList.length > 0) {
                return countriesList.find((country) => country?.[propertyName] === value);
            }

            return undefined;
        },
        [countriesList, value, propertyName],
    );


    return countryData ? (
        <FlagAndValue
            countryCode={countryData.iso_31662}
            text={showCountryName ? countryData.name : value}
            inlineMode
            isTextEllipsisDisabled={isTextEllipsisDisabled}
            showTooltip={showTooltip}
        />
    ) : '-';
}


CountryRenderer.propTypes = {
    countriesList: CountriesListProps.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    propertyName: CountryIdTypeProp,
    showCountryName: PropTypes.bool,
    showTooltip: PropTypes.bool,
    isTextEllipsisDisabled: PropTypes.bool,
};

export default CountryRenderer;
