import { locationServiceKey } from 'config/config';
import { NavigationMeta } from 'models/app/navigation';
import { Action } from 'models/meta/action';
import { createLocation$, createStop$ } from 'services/location/LocationService.helpers';
import { getSessionStorageObjectItem } from 'utils/browser-storage';
import { isDeepEqual } from 'utils/object-tools';

import { BehaviorSubject, EMPTY, merge, Observable, Subject } from 'rxjs';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';


class LocationService {
    history; // XXX shared/common (the only one allowed to be used) history object created form window object at the very beginning of app. init.

    trace = [];

    current = { value: -1 };

    location; // form window.location

    readonly location$: Observable<any>; // observable form history.listen()

    readonly lock$: Subject<Action[] | any> = new BehaviorSubject(undefined);

    readonly stop$; // eventually observable form history.block()


    constructor(history, location) {
        this.history = history;
        this.location = location;

        const storedLocationData = getSessionStorageObjectItem(locationServiceKey);
        if (storedLocationData) {
            this.trace = storedLocationData.trace;
            this.current = storedLocationData.current;
        } else {
            // @ts-expect-error TODO
            this.trace.push(location);
            this.current.value = 0;
        }

        this.location$ = createLocation$({
            history,
            currentObject: this.current,
            trace: this.trace,
        }).pipe();


        this.stop$ = this.lock$.pipe(
            distinctUntilChanged(isDeepEqual), // less noise
            switchMap((actions) => (actions
                ? createStop$({
                    history,
                    actions,
                    currentObject: this.current,
                    trace: this.trace,
                })
                : EMPTY)),
        );
    }


    // XXX uber important - affects middleware dependencies behaviour,
    // see 'createStore(dependencies)' and services filtering by `filter(isObservable)`
    get action$() {
        return merge(this.location$, this.stop$);
    }

    lock(onStop = []) {
        this.lock$.next(onStop);
    }

    unlock(value?: any) {
        this.lock$.next(value);
    }


    goBack() {
        this.history.back();
    }

    // XXX forward push/replace case - actual history modification
    navigate(path: string, meta: NavigationMeta) {
        const push = (pathParam, metaParam) => this.history.push(pathParam, metaParam?.state);
        const replace = (pathParam, metaParam) => this.history.replace(pathParam, metaParam?.state);
        const action = meta?.replace ? replace : push;
        action(path, meta);
    }

    // XXX back case - actual history modification
}

export default LocationService;
