import { DisconnectOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import React from 'react';


const unlinkButtonRenderer = (
    record,
    onClick,
    tooltipContent,
    dataTestId = 'unlink-card-button',
) => ((record?.linked || record?.is_linked) ? (
    <div>
        <Tooltip title={tooltipContent}>
            <Button
                htmlType="button"
                shape="circle"
                data-test-id={`${dataTestId}-${record.id}`}
                onClick={(event) => onClick(event, record)}
            >
                <DisconnectOutlined />
            </Button>
        </Tooltip>
    </div>
) : null);


export default unlinkButtonRenderer;
