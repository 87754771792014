/* eslint-disable class-methods-use-this */
import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';
import { withTotalCount } from 'services/http/http.helpers';
import { cleanNilValues } from 'utils/dataMappers';

import {
    getCardIdDetails,
    updateCardIdDetails,
    bulkCardOrderApiRequest,
    unlinkCardId,
    linkCardId,
    getCardIdPin,
    getCardIdImage,
    getAllCardsApi,
    processCardsOrderApi,
    getNotLinkedCardsApi,
    deleteCardId,
    unblockCardPinByCardToken,
    updateCardIdLimits,
    getCardIdStatusHistory,
} from './index';


class CardsService {
    public fetchAllCards(rawQuery): Promise<ApiResponse | ApiError> {
        const { sortOrder, clientId, search, card_status, ...rest } = rawQuery;
        const query = {
            ...rest,
            cardStatus: card_status?.length > 0 ? card_status : undefined,
            ...(search && search?.length > 0 ? { search } : {}),
            ...(clientId && clientId > 0 ? { clientId } : {}),
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };

        return getAllCardsApi({ params: { ...cleanNilValues(query, true) } });
    }

    public fetchCardDetails(cardId): Promise<ApiResponse | ApiError> {
        return getCardIdDetails(cardId);
    }

    public updateCardDetails(cardId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateCardIdDetails(cardId, requestPayload);
    }

    public updateCardLimits(cardId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateCardIdLimits(cardId, requestPayload);
    }


    public bulkCardOrder(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { member_id, company_id, business_user_id, ...rest } = rawRequestPayload;

        const requestPayload = {
            ...rest,
            ...(member_id > 0 && { member_id }),
            ...(company_id > 0 && { company_id }),
            ...(business_user_id > 0 && { business_user_id }),
        };
        return bulkCardOrderApiRequest(requestPayload);
    }

    public unlinkCard(cardId): Promise<ApiResponse | ApiError> {
        return unlinkCardId(cardId);
    }

    public linkCard(cardId, rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { businessUserId, ...rest } = rawRequestPayload;

        // XXX userId not businessUserId !
        return linkCardId(cardId, { ...rest, userId: businessUserId });
    }

    public fetchCardProducts(cardId, requestPayload): Promise<ApiResponse | ApiError> {
        return linkCardId(cardId, requestPayload);
    }

    public getCardPin(cardId): Promise<ApiResponse | ApiError> {
        return getCardIdPin(cardId);
    }

    public getCardImage(cardId): Promise<ApiResponse | ApiError> {
        return getCardIdImage(cardId);
    }

    public unblockCardPin(cardToken): Promise<ApiResponse | ApiError> {
        const requestPayload = { token: cardToken };
        return unblockCardPinByCardToken(requestPayload);
    }

    public processCardsOrder(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { member_id, company_id, business_user_id, ...rest } = rawRequestPayload;

        const requestPayload = {
            ...rest,
            ...(member_id > 0 && { member_id }),
            ...(company_id > 0 && { company_id }),
            ...(business_user_id > 0 && { business_user_id }),
        };
        // XXX usePluralTargetValue - should be temporal and eventually removed (BE issue 'members' vs 'member' on target field)
        return processCardsOrderApi(requestPayload, { params: { usePluralTargetValue: true } });
    }

    public getNotLinkedCards(rawQuery): Promise<ApiResponse | ApiError> {
        const { companyId, clientId, memberId, search, ...rest } = rawQuery;

        const query = {
            ...rest,
            ...withTotalCount,
            ...(companyId > 0 && { companyId }),
            ...(clientId > 0 && { clientId }),
            ...(memberId > 0 && { memberId }),
            ...(search && search.length > 0 && { search }),
        };

        return getNotLinkedCardsApi({ params: query });
    }

    public deleteCard(cardId): Promise<ApiResponse | ApiError> {
        return deleteCardId(cardId);
    }

    public getCardStatusHistory(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { cardId, sortOrder, ...rest } = rawQueryParams;
        const queryParams = {
            ...rest,
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };
        return getCardIdStatusHistory(cardId, { params: { ...cleanNilValues(queryParams) } });
    }
}

export default CardsService;

