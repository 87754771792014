import CompaniesList from 'components/common/templates/lists/CompaniesList';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { AccessControlProps } from 'constants/AccessControlModel';

import PropTypes from 'prop-types';
import React from 'react';


function Companies({
    currentUserClientId,
    accessControl,
    t,
}) {
    return (
        <>
            <StandardPageHeading title={t('Companies')} />
            <StandardPageWrapper>
                <CompaniesList
                    enforcedClientId={accessControl.isSuperAdmin ? undefined : currentUserClientId} // XXX allowed - client filter related on global companies list
                    allowInactivePartners
                />
            </StandardPageWrapper>
        </>
    );
}

Companies.propTypes = {
    currentUserClientId: PropTypes.number.isRequired,
    accessControl: AccessControlProps,
    t: PropTypes.func.isRequired,
};


export default Companies;
