import StandardTag from 'components/common/atoms/StandardTag';
import { AccountTypes } from 'constants/AccountModel';

import PropTypes from 'prop-types';
import React from 'react';


const createHandlers = (cardCategory, t) => {
    const handlers = [
        {
            predicate: () => cardCategory === AccountTypes.Business || cardCategory === AccountTypes.Business.toLowerCase(),
            handler: () => ({ color: 'geekblue', text: t('cards:cardFields.category.values.commercial') }),
        },
        {
            predicate: () => cardCategory === AccountTypes.Personal || cardCategory === AccountTypes.Personal.toLowerCase() || cardCategory === 'consumer',
            handler: () => ({ color: 'green', text: t('cards:cardFields.category.values.consumer') }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: 'Unknown' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function CardCategoryTag({ cardCategory, t, showTooltip = false }) {
    const tagData = createHandlers(cardCategory, t);
    return <StandardTag color={tagData.color} text={tagData.text} showTooltip={showTooltip} />;
}

CardCategoryTag.propTypes = {
    t: PropTypes.func.isRequired,
    cardCategory: PropTypes.oneOf([
        AccountTypes.Business,
        AccountTypes.Business.toLowerCase(),
        AccountTypes.Personal,
        AccountTypes.Personal.toLowerCase(),
        'consumer',
    ]),
    showTooltip: PropTypes.bool,
};

export default CardCategoryTag;

