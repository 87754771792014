import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import Styled from './AppPageHeader.styled';
import { AppPageHeaderProps } from './AppPageHeader.types';


const AppPageHeader = ({
    onBack,
    title,
}: AppPageHeaderProps) => {
    return (
        <Styled.AppPageHeaderContainer>
            <Styled.AppPageHeaderBackButtonContainer>
                <Styled.AppPageHeaderBackButtonContainer>
                    <Button
                        type="link"
                        icon={<ArrowLeftOutlined />}
                        onClick={onBack}
                    />
                </Styled.AppPageHeaderBackButtonContainer>
            </Styled.AppPageHeaderBackButtonContainer>
            <Styled.AppPageHeaderTitle>{title}</Styled.AppPageHeaderTitle>
        </Styled.AppPageHeaderContainer>
    );
};

export default AppPageHeader;
