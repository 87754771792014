import KycKybAudit from 'components/common/templates/lists/ComplianceTab/KycKybAuditList';
import KycKybLevelSubTab from 'components/common/templates/lists/ComplianceTab/KycKybLevel';
import StatusHistoryList from 'components/common/templates/lists/StatusHistoryList';
import TransactionsList from 'components/common/templates/lists/TransactionsList';
import CompanyCardsList from 'components/pages/authorised/CompanyDetails/Tabs/CompanyCardsList';
import {
    cardsRouteSegmentPath,
    changeStatusRouteSegmentPath,
    complianceRouteSegmentPath, dueDiligenceKycRouteSegmentPath,
    kycLevelRouteSegmentPath,
    statusHistoryRouteSegmentPath,
    statusManagementRouteSegmentPath,
    transactionsRouteSegmentPath,
} from 'config/routes';
import { BusinessUserStatus } from 'constants/BusinessUserModel';
import { KycKybEntityTypes } from 'constants/KycKybModel';
import { PN } from 'constants/PermissionsModel';
import { clearBusinessUserStatusHistoryList, fetchBusinessUserStatusHistoryList } from 'redux/business-user-status/actions';
import { businessUserStatusHistoryListsPerLocationStoreKeyName, businessUserStatusReducerName } from 'redux/business-user-status/reducer';

import {
    CheckCircleOutlined,
    CreditCardOutlined,
    TransactionOutlined,
} from '@ant-design/icons';
import React from 'react';


import createBusinessUserStatusHistoryListColumns from './Tabs/StatusHistory/tableColumns';
import UpdateBusinessUserStatusForm from './Tabs/UpdateBusinessUserStatusForm';


const createStatusManagementSubTabsConfig = ({
    accessControl,
    businessUserDetails,
    businessUserId,
    parentActiveMainTabSegmentURL,
    t,
}) => [
    ...(accessControl.hasPermission(PN.BUSINESS_USERS.UPDATE_STATUS)
        ? [{
            label: t('Change Status'),
            isDefault: true,
            activeSubTabSegmentURL: changeStatusRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: <UpdateBusinessUserStatusForm
                businessUserId={businessUserId}
                entityData={businessUserDetails}
            />,
        }]
        : []
    ),

    ...(accessControl.hasPermission(PN.BUSINESS_USERS.READ_BUSINESS_USERS_STATUS_HISTORY)
        ? [{
            label: t('Status History'),
            activeSubTabSegmentURL: statusHistoryRouteSegmentPath,
            parentActiveMainTabSegmentURL,
            content: <StatusHistoryList
                reducerName={businessUserStatusReducerName}
                listsByLocationStoreKeyName={businessUserStatusHistoryListsPerLocationStoreKeyName}
                fetcherListAction={fetchBusinessUserStatusHistoryList}
                clearListAction={clearBusinessUserStatusHistoryList}
                contextEnforcedQueryParams={{ businessUserId }}
                entityData={businessUserDetails}
                createTableColumns={createBusinessUserStatusHistoryListColumns}
            />,
        }]
        : []),
];

export const createBusinessUserDetailsTabsConfig = ({
    entityIsInReadOnlyMode,
    businessUserDetails,
    fixedId,
    accessControl,
    t,
}) => [
    ...(accessControl.hasPermission(PN.CARDS.READ_BY_EMPLOYEE) ? [{
        icon: <CreditCardOutlined />,
        label: t('Cards'),
        activeMainTabSegmentURL: cardsRouteSegmentPath,
        isDefault: true,
        content: (
            <CompanyCardsList
                isInReadOnlyMode={entityIsInReadOnlyMode || businessUserDetails?.business_user_status !== BusinessUserStatus.ACTIVE}
                businessUserId={fixedId}
                businessUserName={`${businessUserDetails?.first_name} ${businessUserDetails?.last_name}`}
                companyId={businessUserDetails?.company_id}
                clientId={businessUserDetails?.client_id}
            />
        ),
    }] : []),

    ...(accessControl.hasPermission(PN.TRANSACTIONS.READ_ALL) ? [{
        icon: <TransactionOutlined />,
        label: 'Transactions',
        activeMainTabSegmentURL: transactionsRouteSegmentPath,
        content: (
            <TransactionsList
                businessUserId={fixedId}
            />
        ),
    }] : []),

    ...(accessControl.hasOneOfPermissions([
        PN.KYCKYB.GET_LEVEL,
        PN.KYCKYB.GET_LOGS,
    ]) && accessControl.isKycKybApprovalEnabled()
        ? [
            {
                icon: <CheckCircleOutlined />,
                label: 'Compliance',
                activeMainTabSegmentURL: complianceRouteSegmentPath,
                // XXX no 'content' but 'subTabsConfig'
                subTabsConfig: [
                    ...(accessControl.hasPermission(PN.KYCKYB.GET_LEVEL)
                        ? [{
                            label: 'KYC level',
                            activeSubTabSegmentURL: kycLevelRouteSegmentPath,
                            parentActiveMainTabSegmentURL: complianceRouteSegmentPath,
                            content: <KycKybLevelSubTab
                                entityType={KycKybEntityTypes.BUSINESS_USER}
                                entityData={businessUserDetails}
                                entityId={fixedId}
                                isInReadOnlyMode={entityIsInReadOnlyMode}
                            />,
                        }]
                        : []),

                    ...((accessControl.hasPermission(PN.KYCKYB.GET_LOGS))
                        ? [
                            {
                                label: 'Due Diligence KYC',
                                activeSubTabSegmentURL: dueDiligenceKycRouteSegmentPath,
                                parentActiveMainTabSegmentURL: complianceRouteSegmentPath,
                                content: <KycKybAudit businessUserId={fixedId} />,
                            }]
                        : []),
                ],
            }] : []),

    ...(accessControl.hasOneOfPermissions([
        PN.BUSINESS_USERS.UPDATE_STATUS,
        PN.BUSINESS_USERS.READ_BUSINESS_USERS_STATUS_HISTORY,
    ])
        ? [{
            icon: <CheckCircleOutlined />,
            label: 'Status Management',
            activeMainTabSegmentURL: statusManagementRouteSegmentPath,
            subTabsConfig: createStatusManagementSubTabsConfig({
                businessUserDetails,
                accessControl,
                businessUserId: fixedId,
                parentActiveMainTabSegmentURL: statusManagementRouteSegmentPath,
                t,
            }),
        }]
        : []
    ),
];


export default { createBusinessUserDetailsTabsConfig };
