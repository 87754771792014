import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';
import { AccessControlProps } from 'constants/AccessControlModel';
import ListProps from 'constants/ListModel';

import { Col, Row, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createFeeDetailsFields, createSuperAdminOnlyFeeDetailsFields } from './FeeInfo.helpers';


function FeeInfo({
    t,
    accessControl,
    feeDetails,
    feeTiersListData,
    queryParamsMeta,
    onRefresh,
    areDetailsReady,
    isBusy,
}) {
    const feeTiersDetails = feeTiersListData?.items?.[0];

    const standardFeeDetailsFields = useMemo(
        () => createFeeDetailsFields(feeDetails, feeTiersDetails, t),
        [feeDetails, feeTiersDetails],
    );

    const superAdminOnlyFields = useMemo(
        () => createSuperAdminOnlyFeeDetailsFields(feeDetails, t),
        [feeDetails],
    );

    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >

            <Skeleton
                loading={(!areDetailsReady && isBusy) || feeTiersListData?.isLoadingList}
                active
            >
                <DetailsSectionsWrapper>
                    <Row gutter={24} size={10} direction="horizontal">
                        <CommonSection
                            fieldsConfig={standardFeeDetailsFields}
                            sectionLabel={t('Fee details')}
                        />

                        {accessControl.isSuperAdmin && ( // XXX allowed - super admin only
                            <CommonSection
                                fieldsConfig={superAdminOnlyFields}
                                sectionLabel={t('Super Admin details')}
                            />
                        )}
                    </Row>

                    <QueryParamsMetaInfo
                        detailsContext
                        onListRefresh={onRefresh}
                        queryParams={{ feeUuid: feeDetails?.ext_id }}
                        queryParamsMeta={queryParamsMeta}
                    />

                </DetailsSectionsWrapper>
            </Skeleton>
        </Col>
    );
}

FeeInfo.propTypes = {
    accessControl: AccessControlProps,
    t: PropTypes.func.isRequired,
    onRefresh: PropTypes.func,
    queryParamsMeta: PropTypes.shape({}),
    feeDetails: PropTypes.shape({}),
    feeTiersListData: ListProps,
    areDetailsReady: PropTypes.bool,
    isBusy: PropTypes.bool,
};

export default FeeInfo;
