import StandardMultiStepForm from 'components/common/organisms/StandardMultiStepForm';
import { createActionsForStepSuccessfulValidation } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/common.helpers';
import { createRoleDetailsPath, createRolesListPath } from 'config/routes.helpers';
import { ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { getLastStepExceptSuccessFromAvailableSteps } from 'redux/multi-steps-form/epics.helpers';
import { cleanNilValues } from 'utils/dataMappers';

import { Form, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import { createAddRoleStepsConfiguration } from './AddRoleForm.helpers';


function AddRoleForm({
    selectedPermissions,
    selectedRoleTemplate,

    locationPathname,
    operationsLists,
    rolesTemplatesLists,
    isLoadingCreateRole,


    dispatchCreateRole,
    dispatchFetchOperationsList,
    dispatchFetchRolesTemplatesList,


    availableSteps,
    currentMultiStepsFormStep,

    dispatchRequestSetMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormSubStep,
    dispatchClearAddEditRoleFormState,

    newlyCreatedRoleData,

    t,
}) {
    const [form] = Form.useForm();

    const lastStepExceptSuccess = useMemo(
        () => getLastStepExceptSuccessFromAvailableSteps(availableSteps),
        [availableSteps],
    );

    const onSuccessViewNewEntityPathname = useMemo(
        () => {
            if (newlyCreatedRoleData && newlyCreatedRoleData?.id > 0) {
                return createRoleDetailsPath(newlyCreatedRoleData?.id);
            }
            return undefined;
        },
        [newlyCreatedRoleData],
    );

    useEffect(
        () => {
            if (!rolesTemplatesLists) {
                dispatchFetchRolesTemplatesList();
            }
            // semi-static data it seems there is no n eed to re-fetch it
            if (!operationsLists) {
                dispatchFetchOperationsList();
            }

            return () => {
                dispatchClearAddEditRoleFormState();
            };
        }, [],
    );

    const stepsConfiguration = useMemo(
        () => {
            if (operationsLists && rolesTemplatesLists) {
                return createAddRoleStepsConfiguration({
                    t,
                    locationPathname,
                    operationsLists,
                    rolesTemplatesLists,
                    selectedRoleTemplate,
                });
            }

            return undefined;
        },
        [operationsLists, rolesTemplatesLists, selectedRoleTemplate],
    );


    const onFinishHandler = (rawValues) => {
        const values = cleanNilValues({
            details: {
                all: rawValues.all,
                client: rawValues.client,
                company: rawValues.company,
                own: rawValues.own,
                reserved: rawValues.reserved,
                description: rawValues.description,
                display_name: rawValues.display_name,
                name: rawValues.name,
                client_id: rawValues.client_id,
            },
            permissions: [...selectedPermissions],
            ...(rawValues['template.name'] ? { template: { name: rawValues['template.name'] } } : {}),
        });


        createActionsForStepSuccessfulValidation({
            values,
            locationPathname,
            currentMultiStepsFormStep,
            dispatchFinalApiCall: dispatchCreateRole,
            dispatchRequestSetMultiStepsFormStep,
            dispatchRequestSetMultiStepsFormSubStep,

            lastStepExceptSuccess,
        });
    };

    return stepsConfiguration
        ? (
            <StandardMultiStepForm
                // formName="addRole"
                form={form}
                isLoading={isLoadingCreateRole}
                locationPathname={locationPathname}
                stepsConfiguration={stepsConfiguration}
                initialFormData={{}}
                reasonForBlockedNavigation={ReasonsForBlockedNavigation.NOT_PRISTINE_ADD_ROLE_FORM}
                onFinishHandler={onFinishHandler}
                onSuccessNewLocationPathname={onSuccessViewNewEntityPathname}
                onSuccessNewLocationLabel="View new role"
                onSuccessGoBackLabel="View roles list"
                goBackPathname={createRolesListPath()}
                onSubmitSpinnerCopy={'Submitting new role...'}
            />
        )
        : (<Skeleton loading active />);
}

AddRoleForm.propTypes = {
    // mapped props
    isLoadingCreateRole: PropTypes.bool,
    dispatchCreateRole: PropTypes.func,
    dispatchFetchOperationsList: PropTypes.func,
    dispatchFetchRolesTemplatesList: PropTypes.func,
    dispatchRequestSetMultiStepsFormStep: PropTypes.func,
    dispatchRequestSetMultiStepsFormSubStep: PropTypes.func,
    dispatchClearAddEditRoleFormState: PropTypes.func,
};

export default AddRoleForm;
