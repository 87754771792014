import {
    CLEAR_MEMBERS_LIST,
    CREATE_MEMBER, CREATE_MEMBER_FAILURE, CREATE_MEMBER_SUCCESS,
    FETCH_MEMBERS, FETCH_MEMBERS_FAILURE, FETCH_MEMBERS_SUCCESS, SET_CREATE_MEMBER_FORM_DATA, SET_CREATE_MEMBER_FORM_STEP,
} from 'redux/members/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchMembers = createAction(FETCH_MEMBERS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));

export const fetchMembersSuccess = createAction(FETCH_MEMBERS_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));

export const fetchMembersFailure = createAction(FETCH_MEMBERS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearMembersList = createAction(CLEAR_MEMBERS_LIST, (locationPathname) => ({ payload: { locationPathname } }));

export const clearMembersState = createAction('members/clearMembersState');

export const createMember = createAction(CREATE_MEMBER, (requestPayload, listOfOrigin) => ({ payload: { requestPayload, listOfOrigin } }));
export const createMemberSuccess = createAction(CREATE_MEMBER_SUCCESS, (responseData) => ({ payload: responseData }));
export const createMemberFailure = createAction(CREATE_MEMBER_FAILURE);


export const setCreateMemberFormStep = createAction(SET_CREATE_MEMBER_FORM_STEP, (step) => ({ payload: step }));


export const setCreateMemberFormData = createAction(SET_CREATE_MEMBER_FORM_DATA, (data) => ({ payload: data }));
