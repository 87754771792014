import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchApplicationsListFailure, fetchApplicationsListSuccess } from './actions';
import { FETCH_APPLICATIONS_LIST } from './actions.types';


export const onFetchApplicationsList = (action$, state$, { applications }) => action$.pipe(
    ofType(FETCH_APPLICATIONS_LIST),
    switchMap(({ payload }) => from(applications.fetchApplications(payload.queryParams)).pipe(
        switchMap((response) => of(fetchApplicationsListSuccess(
            response.data?.rows,
            response.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchApplicationsListFailure(payload.locationPathname))),
    )),
);

export default [onFetchApplicationsList];
