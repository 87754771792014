import { fetchClientDetails, updateClientSettings } from 'redux/client/actions';
import { clientDetailsPerLocationStoreKeyName, clientReducerName } from 'redux/client/reducer';
import { fetchDefaultClientConfiguration } from 'redux/client-add/actions';
import { addClientReducerName } from 'redux/client-add/reducer';
import { requestSetMultiStepsFormStep, requestSetMultiStepsFormSubStep } from 'redux/multi-steps-form/actions';
import { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import EditClientSettingsForm from './EditClientSettingsForm.layout';


const mapStateToProps = (state, ownProps) => {
    const { locationPathname } = ownProps;
    const currentMultiStepsFormStep = state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormStep;

    const entityDetailsData = getStandardEntityDetailsData({
        state,
        reducerName: clientReducerName,
        fieldName: clientDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    });

    return {
    // Add client form specific,
        clientDetails: entityDetailsData?.entityDetails,
        isLoadingClientDetails: state[clientReducerName]?.isLoadingClientDetails,
        isLoadingClientConfigUpdate: state[clientReducerName]?.isLoadingClientConfigUpdate,

        defaultNewClientConfiguration: state[addClientReducerName].defaultNewClientConfiguration,
        isLoadingDefaultNewClientConfiguration: state[addClientReducerName].isLoadingDefaultNewClientConfiguration,

        // standard multi step form related
        availableSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSteps,
        availableSubSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSubSteps?.[currentMultiStepsFormStep],
        currentMultiStepsFormStep,
        currentMultiStepsFormSubStep: state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormSubStep?.[currentMultiStepsFormStep],
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    // edit client settings form specific
    dispatchFetchClientDetails: (queryParams, locationPathname) => dispatch(fetchClientDetails(queryParams, locationPathname)),
    dispatchFetchDefaultClientConfiguration: () => dispatch(fetchDefaultClientConfiguration(ownProps?.locationPathname)),
    dispatchUpdateClientSettings: (requestData) => dispatch(updateClientSettings(ownProps.clientId, requestData)),

    // standard multi step form related
    dispatchRequestSetMultiStepsFormStep: (currentStep, direction) => dispatch(requestSetMultiStepsFormStep(
        currentStep,
        direction,
        ownProps?.locationPathname,
    )),
    dispatchRequestSetMultiStepsFormSubStep: (parentStep, currentSubStep, direction) => dispatch(requestSetMultiStepsFormSubStep(
        {
            parentStep,
            currentSubStep,
            direction,
        },
        ownProps?.locationPathname,
    )),
});

const decorate = compose(
    withTranslation('clients'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(EditClientSettingsForm);
