import { mapCurrentStepToNumber } from 'redux/outgoing-transfer/epics.helpers';

import { BankOutlined, UserOutlined, CheckCircleOutlined, DollarOutlined } from '@ant-design/icons';
import { Col, Steps } from 'antd';
import React, { useMemo } from 'react';

import { StepsIndicatorProps } from './StepsIndicator.types';


export function StepsIndicatorLayout({
    t,
    currentStep,
} : StepsIndicatorProps) {
    const currentStepNumber = useMemo(
        () => mapCurrentStepToNumber(currentStep),
        [currentStep],
    );

    const stepItems = useMemo(() => [
        {
            icon: <BankOutlined />,
            title: t('transactions:outgoingTransfer.steps.accountSelection.title'),
        },
        {
            icon: <UserOutlined />,
            title: t('transactions:outgoingTransfer.steps.beneficiarySelection.title'),
        },
        {
            icon: <DollarOutlined />,
            title: t('transactions:outgoingTransfer.steps.transferDetails.title'),
        },
        {
            icon: <CheckCircleOutlined />,
            title: t('transactions:outgoingTransfer.steps.review.title'),
        },
    ], [t]);


    return (
        <Col style={{ background: 'white', padding: 20, paddingBottom: 30 }}>
            <Steps current={currentStepNumber} items={stepItems} />
        </Col>
    );
}

export default StepsIndicatorLayout;
