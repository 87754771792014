import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setAsPrimaryAccount } from 'redux/account/actions';
import { accountReducerName } from 'redux/account/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import SetAsPrimaryButton from './SetAsPrimaryButton.layout';
import { SetAsPrimaryButtonDispatchProps, SetAsPrimaryButtonOwnProps, SetAsPrimaryButtonStateProps } from './SetAsPrimaryButton.types';


const mapStateToProps = (state: RootState): SetAsPrimaryButtonStateProps => ({ isLoadingSetAsPrimaryAccount: state[accountReducerName].isLoadingSetAsPrimaryAccount });

const mapDispatchToProps = (dispatch: Dispatch): SetAsPrimaryButtonDispatchProps => ({
    //
    dispatchSetAsPrimaryAccount: (accountDetails, locationPathname) => dispatch(setAsPrimaryAccount(accountDetails, locationPathname)),
});

const decorate = compose<ComponentType<SetAsPrimaryButtonOwnProps>>(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(SetAsPrimaryButton);

