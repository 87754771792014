import { AccountIdentificationCategory } from 'constants/AccountModel';

import { Skeleton, Col, Tabs } from 'antd';
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';


import { splitAccountIdentifications } from './AccountInfoLayout.helpers';
import { AccountInfoLayoutProps } from './AccountInfoLayout.types';
import BankDetails from './tabs/BankDetails';
import GeneralAccountData from './tabs/GeneralAccountData';


function AccountInfoLayout({
    accountDetails,
    isLoadingAccountDetails,
    t,
}: AccountInfoLayoutProps) {
    const accountIdentifications = useMemo(
        () => splitAccountIdentifications(t, accountDetails.accountIdentifications),
        [accountDetails],
    );

    const tabsConfig = useMemo(
        () => ([
            {
                key: '1',
                label: t('General'),
                children: (
                    <GeneralAccountData
                        accountDetails={accountDetails}
                    />
                ),
            },
            ...(accountIdentifications[`${AccountIdentificationCategory.LOCAL}`].length ? [
                {
                    key: '2',
                    label: t('Local Details'),
                    children: (
                        <BankDetails
                            accountIdentifications={accountIdentifications[`${AccountIdentificationCategory.LOCAL}`]}
                        />
                    ),
                },
            ] : []),

            ...(accountIdentifications[`${AccountIdentificationCategory.GLOBAL}`].length ? [
                {
                    key: '3',
                    label: t('Global Details'),
                    children: (
                        <BankDetails
                            accountIdentifications={accountIdentifications[`${AccountIdentificationCategory.GLOBAL}`]}
                        />
                    ),
                },
            ] : []),
        ]),
        [accountDetails, accountIdentifications],
    );

    return (
        <div>
            <Skeleton
                active
                loading={isLoadingAccountDetails}
            >
                <Col>
                    <div>
                        <Tabs
                            defaultActiveKey="1"
                            animated={false}
                            style={{ height: '100%' }}
                            items={tabsConfig}
                        />
                    </div>

                </Col>
            </Skeleton>
        </div>
    );
}

export default withTranslation()(AccountInfoLayout);
