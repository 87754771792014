/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import {
    fetchBeneficiariesAPI,
    fetchBeneficiaryUuidDetails,
    fetchBeneficiaryRequirementsApi,
    createBeneficiaryApi,
    updateBeneficiaryUuid,
    deleteBeneficiaryUuid, fetchSupportedCurrenciesAPI,
} from './index';


class BeneficiariesService {
    public fetchBeneficiaries(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { limit, offset, memberId, companyId, clientId } = rawQueryParams;

        const queryParams = {
            limit,
            offset,
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(clientId > 0 && !(companyId > 0) && !(memberId > 0) ? { clientId } : {}),
        };

        return fetchBeneficiariesAPI({ params: queryParams });
    }

    public fetchBeneficiaryDetails(beneficiaryUuid): Promise<ApiResponse | ApiError> {
        return fetchBeneficiaryUuidDetails(beneficiaryUuid);
    }

    public fetchBeneficiaryRequirements(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { country, currency, memberId, companyId, clientId } = rawQueryParams;

        return fetchBeneficiaryRequirementsApi({
            params: {
                beneficiary_country: country,
                beneficiary_currency: currency,
                ...(memberId > 0 ? { memberId } : {}),
                ...(companyId > 0 ? { companyId } : {}),
                ...(clientId > 0 && !(companyId > 0) && !(memberId > 0) ? { clientId } : {}),
            },
        });
    }

    public createBeneficiary(formData): Promise<ApiResponse | ApiError> {
        return createBeneficiaryApi(formData);
    }

    public updateBeneficiary(beneficiaryUuid, formData): Promise<ApiResponse | ApiError> {
        return updateBeneficiaryUuid(beneficiaryUuid, formData);
    }

    public deleteBeneficiary(beneficiaryUuid): Promise<ApiResponse | ApiError> {
        return deleteBeneficiaryUuid(beneficiaryUuid);
    }

    public fetchSupportedCurrencies(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { memberId, companyId, clientId, currencyMode = 'extended' } = rawQueryParams;

        const queryParams = {
            currencyMode,
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(clientId > 0 && !(companyId > 0) && !(memberId > 0) ? { clientId } : {}),
        };

        return fetchSupportedCurrenciesAPI({ params: queryParams });
    }
}

export default BeneficiariesService;
