import { createAddressTabs } from 'components/pages/authorised/ClientDetails/AddressTabs/AddressTabs.helpers';
import { AddressCommonProps } from 'constants/AddressModel';

import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import Styled from './AddressTabs.styled';


function AddressTabs({
    clientId,
    isLoadingAddress,
    client,
    shippingAddress,
    billingAddress,
    canUpdateClient,
    dispatchOpenEditAddressModal,
    t,
}) {
    const addressTabsConfig = useMemo(
        () => createAddressTabs({
            isLoadingAddress,
            canUpdateClient,
            client,
            clientId,
            billingAddress,
            dispatchOpenEditAddressModal,
            shippingAddress,
            t,
        }),
        [isLoadingAddress, shippingAddress, billingAddress, canUpdateClient, client],
    );


    return (
        <Styled.AddressTabsWrapper>
            <Tabs
                size={'small'}
                defaultActiveKey="1"
                type={'card'}
                items={addressTabsConfig}
            />
        </Styled.AddressTabsWrapper>
    );
}

AddressTabs.propTypes = {
    clientId: PropTypes.number.isRequired,
    dispatchOpenEditAddressModal: PropTypes.func.isRequired,
    isLoadingAddress: PropTypes.bool,
    canUpdateClient: PropTypes.bool,
    shippingAddress: AddressCommonProps,
    billingAddress: AddressCommonProps,
    t: PropTypes.func,
};

export default AddressTabs;
