import StandardListTable from 'components/common/organisms/StandardListTable';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { RuleScope } from 'models/domain/rules';
import { defaultLimitsListQueryParams } from 'redux/limits/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import React, { useEffect, useMemo } from 'react';


import { createAdditionalFilterConfig } from './LimitsList.helpers';
import { LimitsListProps } from './LimitsList.types';
import createTableColumns from './createTableColumns';


function LimitsList({
    t,

    accessControl,
    currentClientExtId,

    enhancedCurrentLocation,
    clients,
    listData,
    groupsListData,

    dispatchRequestNavigation,
    dispatchFetchLimitsList,
    dispatchClearLimitsList,
    dispatchSetQueryParams,

    dispatchFetchGroups,
    dispatchClearGroupsList,
}: LimitsListProps) {
    const queryParams = listData?.queryParams;
    const isSuperAdmin = accessControl?.isSuperAdmin;

    const contextEnforcedQueryParams = useMemo(() => (isSuperAdmin ? {} : {
        clientExtId: currentClientExtId,
        ruleScope: RuleScope.INSTITUTION,
    }), [currentClientExtId]);

    const fetchData = (newQueryParams) => {
        const { filters, ...rest } = newQueryParams;
        const updatedQueryParams = {
            ...rest,
            ...filters,
        };

        const prams = {
            ...listData?.queryParams,
            ...updatedQueryParams,
        };

        const shouldFetch = prams?.ruleScope !== queryParams?.ruleScope
    || prams?.ruleTransactionType?.[0] !== queryParams?.ruleTransactionType?.[0]
    || prams?.ruleType?.[0] !== queryParams?.ruleType?.[0]
    || (prams.ruleScope === RuleScope.INSTITUTION && prams?.clientExtId !== queryParams?.clientExtId)
    || (prams?.ruleScope === RuleScope.GROUP && queryParams?.groupExtId !== prams?.groupExtId)
    || JSON.stringify(prams) === JSON.stringify(queryParams);

        if (enhancedCurrentLocation?.pathname) {
            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            if (shouldFetch) dispatchFetchLimitsList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultLimitsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchLimitsList,
        dispatchClearItemsList: dispatchClearLimitsList,
        dispatchSetQueryParams,
    });

    const filteredLimits = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((limit) => searchCaseInsensitive(limit?.extInstitutionId, listData?.queryParams?.search)
        || searchCaseInsensitive(limit?.extGroupId, listData?.queryParams?.search)
        || searchCaseInsensitive(limit?.extId, listData?.queryParams?.search)
        || searchCaseInsensitive(limit?.ruleName, listData?.queryParams?.search));
            }
            return listData?.items || [];
        }, [listData],
    );

    useEffect(() => {
        if (queryParams?.clientExtId && queryParams?.ruleScope === RuleScope.GROUP) {
            const client = clients?.find((client) => client?.ext_client_id === queryParams?.clientExtId);

            dispatchFetchGroups({ clientId: client?.id }, enhancedCurrentLocation?.pathname);
        }
    }, [queryParams?.clientExtId, queryParams?.ruleScope]);

    const additionalFiltersConfig = useMemo(
        () => createAdditionalFilterConfig({
            t,
            isSuperAdmin,
            accessControl,

            enhancedCurrentLocation,
            queryParams,
            groupsListData,

            fetchData,
            dispatchClearGroupsList,
        }), [groupsListData, queryParams],
    );

    return (
        <StandardListTable
            onUpdate={fetchData}
            items={filteredLimits}
            showSearch={false}
            totalItemsCount={filteredLimits?.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createTableColumns({ t, queryParams: listData?.queryParams, accessControl, dispatchRequestNavigation })}
            showClientSelect={false}
            additionalFiltersConfig={additionalFiltersConfig}
        />
    );
}

export default LimitsList;
