import { toUpper } from 'lodash';


export const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const TIME_TO_LOGOUT_COUNTDOWN = Number(process.env.REACT_APP_TIME_TO_AUTO_LOGOUT_COUNTDOWN) || 4;

// REACT_APP_TIME_TO_AUTO_LOGOUT_COUNTDOWN - in minutes
export const timeToAutoLogoutCountDownBanner = TIME_TO_LOGOUT_COUNTDOWN * 60 * 1000; // in milliseconds


export const isMaintenanceModeEnabled = process.env.REACT_APP_MAINTANCE_MODE_ENABLED === 'true';
export const isReduxLoggerEnabled = process.env.REACT_APP_ENABLE_REDUX_LOGGER === 'true';
export const areDevToolsEnabled = process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true';

const wlNames = JSON.parse(process.env.WL_NAMES);
const wlAssetsURLs = JSON.parse(process.env.WL_ASSETS_URLS);

export const WL_CONFIG = {
    brandShortName: wlNames.brandShortName,
    brandFullName: wlNames.brandFullName,
    applicationName: wlNames.applicationName,
    brandLandingPageUrl: wlNames.brandLandingPageUrl,
    brandBaseDomain: wlNames.brandBaseDomain,

    ...(wlAssetsURLs.brandFullLogoUrl?.length > 0 ? { brandFullLogoUrl: wlAssetsURLs.brandFullLogoUrl } : {}),
    ...(wlAssetsURLs.brandSignetLogoUrl?.length > 0 ? { brandSignetLogoUrl: wlAssetsURLs.brandSignetLogoUrl } : {}),
    ...(wlAssetsURLs.brandAnimatedLoadingPlaceholderUrl?.length > 0 ? { brandAnimatedLoadingPlaceholderUrl: wlAssetsURLs.brandAnimatedLoadingPlaceholderUrl } : {}),
    ...(wlAssetsURLs.brandFaviconUrl?.length > 0 ? { brandFaviconUrl: wlAssetsURLs.brandFaviconUrl } : {}),

    externalLinks: JSON.parse(process.env.EXTERNAL_LINKS),
    themeVariables: JSON.parse(process.env.THEME_VARIABLES),
};


export const applicationVersion = JSON.parse(process.env.APP_VERSION) || '2.1.0';
export const getApplicationEnvironment = () => {
    if (process.env.REACT_APP_BASE_API_URL.includes('dev')) {
        return 'DEV';
    }

    if (process.env.REACT_APP_BASE_API_URL.includes('test')) {
        return 'STG';
    }
    if (process.env.REACT_APP_BASE_API_URL.includes('qa')) {
        return 'QA';
    }
    return undefined;
};

export const developmentMode = process.env.NODE_ENV === 'development';
export const nodeEnv = process.env.NODE_ENV;

export const jwtTokenKey = 'jwt';
export const sessionUuidKey = 'sessionUuid';
export const permissionsKey = 'permissions';
export const clientConfigKey = 'clientConfig';
export const userPreferencesKey = 'preferences';
export const locationServiceKey = 'location';

export const rememberMeLoginForm = 'remember';

export const standardPaginationOptions = [5, 10, 20, 50];
export const standardDateFormat = 'DD/MM/YYYY';
export const standardDateAndTimeFormat = 'DD/MM/YYYY HH:mm:ss';
export const standardTimeFormat = 'HH:mm:ss';
export const usTimeFormat = 'h:mm:ss A';

export const startOfDayTime = '00:00:00';
export const endOfDayTime = '23:59:59';


export const isoDateFormat = 'YYYY-MM-DD';
export const standardPageSize = 20;
export const defaultLocale = 'en-GB';
export const debugMode = developmentMode;

export const superAdminRoleTemplateName = 'Admin';

// iso_3166_3 codes of European Economic Area countries
export const EEA = (process.env.REACT_APP_EEA_COUNTRIES || '').split(',').filter((iso) => iso?.length).map(toUpper);


export const appVersion = () => ({
    APP_VERSION: JSON.parse(process.env.APP_VERSION),
    APP_BUILD_DATE: new Date(JSON.parse(process.env.APP_BUILD_DATE)).toISOString(),
    GIT_COMMIT_HASH: JSON.parse(process.env.GIT_COMMIT_HASH),
    GIT_LAST_COMMIT_DATETIME: JSON.parse(process.env.GIT_LAST_COMMIT_DATETIME),
    ...(developmentMode && {
        NODE_ENV: process.env.NODE_ENV,
        REACT_APP_SERVER_URL: baseApiUrl,
        TIME_TO_AUTO_LOGOUT_COUNTDOWN: `${process.env.REACT_APP_TIME_TO_AUTO_LOGOUT_COUNTDOWN} minutes`,
        EEA_COUNTRIES: process.env.REACT_APP_EEA_COUNTRIES,
    }),
});
