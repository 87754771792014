import FlagAndValue from 'components/common/atoms/FlagAndValue';
import CommonSection from 'components/common/organisms/CommonSection';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';

import { CountryIdType, CountryListItem } from '@manigo/manigo-domain-typings';
import React, { useMemo } from 'react';

import { CommonAddressDisplayProps } from './CommonAddressDisplay.types';


function CommonAddressDisplay({
    sectionLabel,
    sectionEditTooltip,
    sectionEditOnClick,
    showPhoneNumber = false,
    addressData,
    isLoading,
    isCamelCase,
    countries,
    countryIdType = CountryIdType.iso31663,
    t,
}: CommonAddressDisplayProps) {
    const countryData: CountryListItem | undefined = useMemo(
        () => {
            if (countries?.length > 0 && addressData?.country) {
                // TODO: After Countries service all places are migrated to camelCase, remove the conversion
                const countryData = convertSnakeCaseKeysToCamelCase(countries).find((country) => country?.[countryIdType] === addressData.country);
                return countryData;
            }
            return undefined;
        },
        [countries, addressData],
    );

    // TODO: Remove isCamelCase when all components are migrated to camelCase
    const fieldsConfig = useMemo(
        () => {
            if (addressData) {
                const baseFields = [
                    {
                        label: t('common:addressFields.addressLine1.label'),
                        showEmpty: true,
                        value: isCamelCase ? addressData?.addressLine1 : (addressData as any)?.address_line_1,
                    },
                    {
                        label: t('common:addressFields.addressLine2.label'),
                        value: isCamelCase ? addressData?.addressLine2 : (addressData as any)?.address_line_2,
                    },
                    {
                        label: t('common:addressFields.premise.label'),
                        value: addressData?.premise,
                    },
                    {
                        label: t('common:addressFields.postCode.label'),
                        showEmpty: true,
                        value: isCamelCase ? addressData?.zipCode : (addressData as any)?.zip_code,
                    },
                    {
                        label: t('common:addressFields.city.label'),
                        showEmpty: true,
                        value: addressData?.city,
                    },
                    {
                        label: t('common:addressFields.country.label'),
                        showEmpty: true,
                        value: <FlagAndValue
                            countryCode={countryData?.iso31662}
                            text={countryData?.name}
                            inlineMode
                        />,
                    },
                    {
                        label: t('common:addressFields.state.label'),
                        value: addressData?.state,
                    },
                    {
                        label: t('common:addressFields.additionals.label'),
                        value: addressData?.additionals,
                    },
                ];

                const phoneField = [{
                    label: t('common:addressFields.phoneNumber.label'),
                    showEmpty: true,
                    value: isCamelCase ? addressData?.phoneNumber : (countryData as any)?.phone_number,
                }];


                return [
                    ...baseFields,
                    ...(showPhoneNumber ? phoneField : []),
                ];
            }

            return [];
        },
        [countryData, addressData, showPhoneNumber],
    );

    return (
        <CommonSection
            isLoading={isLoading}
            sectionLabel={sectionLabel}
            sectionEditTooltip={sectionEditTooltip}
            sectionEditOnClick={sectionEditOnClick}
            fieldsConfig={fieldsConfig}
        />
    );
}

export default CommonAddressDisplay;

