import { OutgoingTransferFormData, OutgoingTransferForms, OutgoingTransferStep } from 'models/app/outgoingTransfer';

import { AccountListItem, Beneficiary } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_OUTGOING_TRANSFER_DATA,
    CREATE_OUTGOING_TRANSFER,
    CREATE_OUTGOING_TRANSFER_FAILURE,
    CREATE_OUTGOING_TRANSFER_SUCCESS,
    FETCH_ALL_ACCOUNTS,
    FETCH_ALL_ACCOUNTS_FAILURE,
    FETCH_ALL_ACCOUNTS_SUCCESS,
    FETCH_ALL_BENEFICIARIES,
    FETCH_ALL_BENEFICIARIES_FAILURE,
    FETCH_ALL_BENEFICIARIES_SUCCESS,
    GO_TO_PREVIOUS_OUTGOING_TRANSFER_FORM_STEP,
    INITIALISE_OUTGOING_TRANSFER_FORM,
    REQUEST_RESET_FORM,
    SET_OUTGOING_TRANSFER_FORM_STEP,
    SET_SELECTED_ACCOUNT_DETAILS,
    SET_SELECTED_BENEFICIARY_DETAILS,
    SUBMIT_OUTGOING_TRANSFER_FORM_STEP,
} from './actions.types';
import { OutgoingTransferStateContextData } from './reducer.types';


export const fetchAllBeneficiaries = createAction(FETCH_ALL_BENEFICIARIES);
export const fetchAllBeneficiariesSuccess = createAction(FETCH_ALL_BENEFICIARIES_SUCCESS, (responseData: Beneficiary[]) => ({ payload: responseData }));
export const fetchAllBeneficiariesFailure = createAction(FETCH_ALL_BENEFICIARIES_FAILURE);

export const fetchAllAccounts = createAction(FETCH_ALL_ACCOUNTS);
export const fetchAllAccountsSuccess = createAction(FETCH_ALL_ACCOUNTS_SUCCESS, (responseData: AccountListItem[]) => ({ payload: responseData }));
export const fetchAllAccountsFailure = createAction(FETCH_ALL_ACCOUNTS_FAILURE);


export const initialiseOutgoingTransferForm = createAction(INITIALISE_OUTGOING_TRANSFER_FORM, (ownerContext: OutgoingTransferStateContextData) => ({ payload: ownerContext }));

export const setSelectedBeneficiaryDetails = createAction(SET_SELECTED_BENEFICIARY_DETAILS, (beneficiaryDetails?: Beneficiary) => ({ payload: beneficiaryDetails }));
export const setSelectedAccountDetails = createAction(SET_SELECTED_ACCOUNT_DETAILS, (accountDetails?: AccountListItem) => ({ payload: accountDetails }));

export const submitOutgoingTransferFormStep = createAction(SUBMIT_OUTGOING_TRANSFER_FORM_STEP, (step: OutgoingTransferStep, formData) => ({ payload: { step, formData } }));
export const setOutgoingTransferFormStep = createAction(SET_OUTGOING_TRANSFER_FORM_STEP,
    (step:OutgoingTransferStep, formData?: OutgoingTransferForms) => ({ payload: { step, formData } }));

export const goToPreviousOutgoingTransferFormStep = createAction(GO_TO_PREVIOUS_OUTGOING_TRANSFER_FORM_STEP, (currentStep: OutgoingTransferStep) => ({ payload: currentStep }));

export const createOutgoingTransfer = createAction(CREATE_OUTGOING_TRANSFER, (formData: OutgoingTransferFormData) => ({ payload: formData }));

// TODO: Update the type of response payload
export const createOutgoingTransferSuccess = createAction(CREATE_OUTGOING_TRANSFER_SUCCESS, (responseData: any) => ({ payload: responseData }));
export const createOutgoingTransferFailure = createAction(CREATE_OUTGOING_TRANSFER_FAILURE);


export const clearOutgoingTransferData = createAction(CLEAR_OUTGOING_TRANSFER_DATA);

export const requestResetForm = createAction(REQUEST_RESET_FORM, (shouldReset: boolean) => ({ payload: shouldReset }));
