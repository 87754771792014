import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { endOfDayTime, standardDateFormat, standardTimeFormat, startOfDayTime } from 'config/config';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps, ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { PromotionTriggerType, PromotionType } from 'constants/promotions';
import { mapTimeFormat } from 'utils/date-time-tools';
import { filterOptions } from 'utils/filterOptions';
import { isFormPristine } from 'utils/forms-tools';
import {
    createCurrencyOptions,
    createPromotionTypeOptions,
    createPromotionTriggerTypeOptions,
    createGroupsOptions,
    createFeesOptions, createPromotionTriggerEntityOptions,
} from 'utils/options-creators';

import {
    Row, Button, Form, Col, Input, Select, Switch,
} from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';


import { handleSubmit } from './AddPromotion.helpers';
import Styled from './AddPromotion.styled';


function AddPromotion({
    // ownProps
    fixedClientId,
    preSelectedGroup,
    t,
    currenciesList,

    enhancedCurrentLocation,
    feesListData,
    groupsListData,

    isLoading,
    isBlockNavigationReasonSet,

    dispatchFetchFees,
    dispatchFetchGroups,

    dispatchCreatePromotion,
    dispatchBlockNavigation,
    dispatchUnblockNavigation,

    userPreferences,
}) {
    const [form] = Form.useForm();

    const isLoadingCurrencies = useMemo(
        () => currenciesList?.isLoadingList,
        [currenciesList],
    );

    const currencies = useMemo(
        () => currenciesList?.items,
        [currenciesList, isLoadingCurrencies],
    );


    const [isFeeIdRequired, setIsFeeIdRequired] = useState(false);
    const [isTriggerAmountRequired, setIsTriggerAmountRequired] = useState(undefined);
    const [selectedPromotionType, setSelectedPromotionType] = useState(undefined);

    useEffect(() => () => {
        dispatchUnblockNavigation();
    }, []);

    useEffect(() => {
        if (fixedClientId > 0) {
            if (!feesListData?.items) {
                dispatchFetchFees({ clientId: fixedClientId });
            }
            if (!groupsListData?.items) {
                dispatchFetchGroups({ clientId: fixedClientId });
            }
        }
    }, [fixedClientId]);

    const clearField = (fieldName) => {
        form.setFieldsValue({ [fieldName]: undefined });
    };

    const currencyOptions = useMemo(
        () => createCurrencyOptions(currencies, t),
        [currencies],
    );


    const groupsOptions = useMemo(
        () => createGroupsOptions(groupsListData?.items || []),
        [groupsListData],
    );

    const promotionTriggerTypeOptions = useMemo(
        () => {
            const { ADDITIONAL_AMOUNT, ...rest } = PromotionTriggerType;
            const availableOptions = selectedPromotionType === PromotionType.FEE_DISCOUNT
                ? Object.keys({ ...rest })
                : Object.keys({ ADDITIONAL_AMOUNT, ...rest });

            return createPromotionTriggerTypeOptions(availableOptions);
        },
        [selectedPromotionType],
    );

    const feesOptions = useMemo(
        () => createFeesOptions(feesListData?.items || []),
        [feesListData],
    );

    const format = useMemo(
        () => `${userPreferences?.dateFormat || standardDateFormat} ${mapTimeFormat(userPreferences?.timeFormat) || standardTimeFormat}`,
        [userPreferences],
    );

    const handlePromotionTypeChange = (value) => {
        if (value === PromotionType.FEE_DISCOUNT) {
            setIsFeeIdRequired(true);
        } else {
            setIsFeeIdRequired(false);
        }
        setSelectedPromotionType(value);
        clearField('transactionType');
        clearField('feeId');
        clearField('triggerType');
    };


    const handleTriggerTypeChange = (value) => {
        if ([PromotionTriggerType.OVER_PERIOD].includes(value)) {
            setIsTriggerAmountRequired(true);
        } else {
            setIsTriggerAmountRequired(false);
        }
    };

    const initialValues = preSelectedGroup ? { groups: [preSelectedGroup], overAmount: 0 } : undefined;

    const handleFormValuesChanges = (changedValues, allValues) => {
        const isPristine = isFormPristine({ ...allValues });
        if (!isPristine && !isBlockNavigationReasonSet) {
            dispatchBlockNavigation(ReasonsForBlockedNavigation.NOT_PRISTINE_ADD_PROMOTION_FORM);
        }

        if (isBlockNavigationReasonSet && isPristine) {
            dispatchUnblockNavigation();
        }
    };


    const preventDatesFormPast = (currentDate) => currentDate && currentDate <= dayjs().startOf('day');

    // Mqc's TODO: redo this to StandardMultiStepForm
    return (
        <>
            <StandardPageHeading title={t('createPromotionScreenTitle')} />

            <StandardPageWrapper>
                <div style={{ background: 'white', padding: '16px' }}>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={initialValues}
                        onFinish={(values) => handleSubmit({
                            values,
                            clientId: fixedClientId,
                            dispatchCreatePromotion,
                            locationPathname: enhancedCurrentLocation.pathname,
                        })}
                        onValuesChange={handleFormValuesChanges}
                    >

                        <Row gutter={[30, 5]}>
                            <Col md={12}>
                                <Form.Item
                                    name="name"
                                    label={t('createPromotionForm.name.label')}
                                    rules={[{ required: true, message: t('createPromotionForm.name.validation.required') }]}
                                >
                                    <Input placeholder={t('createPromotionForm.name.placeholder')} />
                                </Form.Item>
                            </Col>

                            <Col md={12}>
                                <Form.Item
                                    name="description"
                                    label={t('createPromotionForm.description.label')}
                                    rules={[{ required: true, message: t('createPromotionForm.description.validation.required') }]}
                                >
                                    <Input placeholder={t('createPromotionForm.description.placeholder')} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[30, 5]}>
                            <Col md={12}>
                                <Styled.DatePickerWrapper>
                                    <Form.Item
                                        name="timeRange"
                                        label={t('createPromotionForm.timeRange.label')}
                                        rules={[
                                            () => ({
                                                validator(_, value) {
                                                    if (value && value[0] && value[1]) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t('createPromotionForm.timeRange.validation.required')));
                                                },
                                            }),
                                        ]}
                                    >

                                        <Styled.FullWidthDatePicker.RangePicker
                                            placeholder={[t('createPromotionForm.timeRange.placeholderStart'), t('createPromotionForm.timeRange.placeholderEnd')]}
                                            disabledDate={preventDatesFormPast}
                                            showTime={{ defaultValue: [dayjs(startOfDayTime, standardTimeFormat), dayjs(endOfDayTime, standardTimeFormat)] }}
                                            format={format}
                                        />
                                    </Form.Item>
                                </Styled.DatePickerWrapper>
                            </Col>
                            <Col md={12}>
                                <Form.Item
                                    name="triggerEntity"
                                    label={t('createPromotionForm.triggerEntity.label')}
                                    rules={[{ required: true, message: t('createPromotionForm.triggerEntity.validation.required') }]}
                                >
                                    <Select
                                        placeholder={t('createPromotionForm.triggerEntity.placeholder')}
                                    >
                                        {createPromotionTriggerEntityOptions()}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[30, 5]}>
                            <Col md={12}>
                                <Form.Item
                                    name="promotionType"
                                    label={t('createPromotionForm.promotionType.label')}
                                    rules={[{ required: true, message: t('createPromotionForm.promotionType.validation.required') }]}
                                >
                                    <Select
                                        placeholder={t('createPromotionForm.promotionType.placeholder')}
                                        onChange={handlePromotionTypeChange}
                                    >
                                        {createPromotionTypeOptions()}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col md={12}>
                                {isFeeIdRequired && (
                                    <Form.Item
                                        name="feeId"
                                        label={t('createPromotionForm.feeId.label')}
                                        rules={[{ required: isFeeIdRequired, message: t('createPromotionForm.feeId.validation.required') }]}
                                    >
                                        <Select
                                            showSearch
                                            disabled={!isFeeIdRequired}
                                            filterOption={filterOptions}
                                            placeholder={t('createPromotionForm.feeId.placeholder')}
                                        >
                                            {feesOptions}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>


                        <Row gutter={[30, 5]}>
                            <Col md={12}>
                                <Form.Item
                                    name="amount"
                                    label={t('createPromotionForm.amount.label')}
                                    rules={[
                                        { required: true, message: t('createPromotionForm.amount.validation.required') },
                                        () => ({
                                            validator(_, value) {
                                                if (value > 0) { return Promise.resolve(); }
                                                return Promise.reject(new Error(t('createPromotionForm.amount.validation.nonZero')));
                                            },
                                        }),
                                    ]}
                                >
                                    <Styled.FullWidthInputNumber
                                        placeholder={t('createPromotionForm.amount.placeholder')}
                                        min={0}
                                        precision={2}
                                    />
                                </Form.Item>
                            </Col>

                            <Col md={12}>
                                <Form.Item
                                    name="currency"
                                    label={t('createPromotionForm.currency.label')}
                                    rules={[{ required: true, message: t('createPromotionForm.currency.validation.required') }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder={t('createPromotionForm.currency.placeholder')}
                                        filterOption={filterOptions}
                                        loading={isLoadingCurrencies}
                                    >
                                        {currencyOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row gutter={[30, 5]}>
                            <Col md={12}>
                                <Form.Item
                                    name="triggerType"
                                    label={t('createPromotionForm.triggerType.label')}
                                    rules={[{ required: true, message: t('createPromotionForm.triggerType.validation.required') }]}
                                >
                                    <Select
                                        placeholder={t(`createPromotionForm.triggerType.${selectedPromotionType ? 'placeholder' : 'placeholderDisabled'}`)}
                                        onChange={handleTriggerTypeChange}
                                        disabled={!selectedPromotionType}
                                    >
                                        {promotionTriggerTypeOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col md={12}>
                                <Form.Item
                                    name="triggerAmount"
                                    label={t('createPromotionForm.triggerAmount.label')}
                                    rules={[
                                        { required: isTriggerAmountRequired, message: t('createPromotionForm.triggerAmount.validation.required') },
                                        () => ({
                                            validator(_, value) {
                                                if (!value || (value && value > 0)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(t('createPromotionForm.triggerAmount.validation.nonZero')));
                                            },
                                        }),
                                    ]}
                                >
                                    <Styled.FullWidthInputNumber
                                        placeholder={t('createPromotionForm.triggerAmount.placeholder')}
                                        min={0}
                                        precision={2}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[30, 5]}>
                            <Col md={12}>
                                <Form.Item
                                    name="groups"
                                    label={t('createPromotionForm.groups.label')}
                                >
                                    <Select
                                        mode="multiple"
                                        disabled={initialValues?.groups?.length > 0}
                                        showSearch
                                        allowClear
                                        filterOption={filterOptions}
                                        placeholder={t('createPromotionForm.groups.placeholder')}
                                    >
                                        {groupsOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col md={12}>
                                <Form.Item
                                    name="overAmount"
                                    label={t('createPromotionForm.overAmount.label')}
                                >
                                    <Styled.FullWidthInputNumber
                                        placeholder={t('createPromotionForm.overAmount.placeholder')}
                                        min={0}
                                        precision={2}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row gutter={[30, 5]}>
                            <Col md={12}>
                                <Form.Item
                                    name="isPercentage"
                                    label={t('createPromotionForm.isPercentage.label')}
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item
                                    name="allowExchange"
                                    label={t('createPromotionForm.allowExchange.label')}
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Styled.SubmitButtonWrapper>
                            <Form.Item>
                                <Button
                                    loading={isLoading}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {t('common:buttons.submit.text')}
                                </Button>
                            </Form.Item>
                        </Styled.SubmitButtonWrapper>
                    </Form>
                </div>
            </StandardPageWrapper>
        </>
    );
}


AddPromotion.propTypes = {
    fixedClientId: PropTypes.number,
    preSelectedGroup: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    feesListData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    groupsListData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    currenciesList: PropTypes.shape({}).isRequired,

    t: PropTypes.func.isRequired,
    dispatchCreatePromotion: PropTypes.func.isRequired,
    dispatchFetchFees: PropTypes.func.isRequired,
    dispatchFetchGroups: PropTypes.func.isRequired,
    dispatchBlockNavigation: PropTypes.func.isRequired,
    dispatchUnblockNavigation: PropTypes.func.isRequired,
};

export default AddPromotion;
