import {
    ADD_CLIENT, ADD_CLIENT_FAILURE, ADD_CLIENT_SUCCESS, CLEAR_ADD_CLIENT_STATE_DATA,
    FETCH_BPS_PRODUCTS, FETCH_BPS_PRODUCTS_FAILURE, FETCH_BPS_PRODUCTS_SUCCESS,
    FETCH_DEFAULT_CLIENT_CONFIGURATION, FETCH_DEFAULT_CLIENT_CONFIGURATION_FAILURE, FETCH_DEFAULT_CLIENT_CONFIGURATION_SUCCESS, TOGGLE_ADDRESS_SAME_AS_OTHER_ADDRESS,
} from 'redux/client-add/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const addClient = createAction(ADD_CLIENT, (requestData, locationPathname) => ({ payload: { requestData, locationPathname } }));
export const addClientSuccess = createAction(ADD_CLIENT_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const addClientFailure = createAction(ADD_CLIENT_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const fetchDefaultClientConfiguration = createAction(FETCH_DEFAULT_CLIENT_CONFIGURATION, (locationPathname) => ({ payload: { locationPathname } }));
export const fetchDefaultClientConfigurationSuccess = createAction(
    FETCH_DEFAULT_CLIENT_CONFIGURATION_SUCCESS,
    (responseData, locationPathname) => ({ payload: { responseData, locationPathname } }),
);
export const fetchDefaultClientConfigurationFailure = createAction(FETCH_DEFAULT_CLIENT_CONFIGURATION_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const fetchBpsProducts = createAction(FETCH_BPS_PRODUCTS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchBpsProductsSuccess = createAction(FETCH_BPS_PRODUCTS_SUCCESS, (responseData, locationPathname) => ({ payload: { responseData, locationPathname } }));
export const fetchBpsProductsFailure = createAction(FETCH_BPS_PRODUCTS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const clearAddClientStateData = createAction(CLEAR_ADD_CLIENT_STATE_DATA, (locationPathname) => ({ payload: locationPathname }));


export const toggleAddressSameAsOtherAddress = createAction(
    TOGGLE_ADDRESS_SAME_AS_OTHER_ADDRESS,
    (addressName, currentValue, locationPathname) => ({ payload: { addressName, currentValue, locationPathname } }),
);

