import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import styled from 'styled-components';


const MultiValueWrapper = styled.div`
  padding: 0;
  max-width: 100%;
  ${commonTextEllipsisStyles};
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-column-gap: 10px;
`;

const InlineValues = styled.div`
  padding: 0;
  max-width: 100%;
  ${commonTextEllipsisStyles};
  display: flex;
  
  & > div {
    width: auto !important;
  }
`;

const TooltipValues = styled.div`
  text-align: left;
`;

const SeeMoreBadge = styled.div`
  cursor: help;
  font-weight: 600;
`;

export default {
    MultiValueWrapper,
    InlineValues,
    TooltipValues,
    SeeMoreBadge,
};
