import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CountriesSelect from './CountriesSelect.layout';


const mapStateToProps = (state) => ({
    countries: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
});

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(CountriesSelect);
