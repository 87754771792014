import styled from 'styled-components';


const PrivacyPolicyWrapper = styled.div`
  p {
    position: relative;
    padding-right: 180px;
    min-height: 150px;
  }
`;

const IconDecoration = styled.img`
  width: 150px;
  height: auto;
  position: absolute;
  right: 0;
  top: -30px;
`;


export default { PrivacyPolicyWrapper, IconDecoration };
