import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';


import {
    startOffboarding, startOffboardingSuccess, startOffboardingFailure,
    stopOffboarding, stopOffboardingSuccess, stopOffboardingFailure,
    finaliseOffboarding, finaliseOffboardingSuccess, finaliseOffboardingFailure,
} from './actions';


export const offboardingReducerName = 'offboarding';

const initialState = {
    isLoadingStartOffboarding: false,
    isLoadingStopOffboarding: false,
    isLoadingFinalizeOffboarding: false,
};

const handlers = {
    [startOffboarding]: (state) => {
        state.isLoadingStartOffboarding = true;
    },
    [startOffboardingSuccess]: (state) => {
        state.isLoadingStartOffboarding = false;
    },
    [startOffboardingFailure]: (state) => {
        state.isLoadingStartOffboarding = false;
    },


    [stopOffboarding]: (state) => {
        state.isLoadingStopOffboarding = true;
    },
    [stopOffboardingSuccess]: (state) => {
        state.isLoadingStopOffboarding = false;
    },
    [stopOffboardingFailure]: (state) => {
        state.isLoadingStopOffboarding = false;
    },

    [finaliseOffboarding]: (state) => {
        state.isLoadingFinalizeOffboarding = true;
    },
    [finaliseOffboardingSuccess]: (state) => {
        state.isLoadingFinalizeOffboarding = false;
    },
    [finaliseOffboardingFailure]: (state) => {
        state.isLoadingFinalizeOffboarding = false;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
