import {
    FETCH_API_KEYS_LIST,
    FETCH_API_KEYS_LIST_FAILURE,
    FETCH_API_KEYS_LIST_SUCCESS,
    CLEAR_API_KEYS_LIST,
    DELETE_API_KEY,
    DELETE_API_KEY_FAILURE,
    DELETE_API_KEY_SUCCESS,
    CREATE_API_KEY,
    CREATE_API_KEY_SUCCESS,
    CREATE_API_KEY_FAILURE,
    OPEN_CREATE_API_KEY_MODAL,
    FETCH_API_KEY_VALUE,
    FETCH_API_KEY_VALUE_SUCCESS,
    FETCH_API_KEY_VALUE_FAILURE, REFRESH_API_KEYS_LIST,
} from 'redux/api-keys/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchApiKeysList = createAction(FETCH_API_KEYS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchApiKeysListSuccess = createAction(
    FETCH_API_KEYS_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchApiKeysListFailure = createAction(FETCH_API_KEYS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearApiKeysList = createAction(CLEAR_API_KEYS_LIST, (locationPathname) => ({ payload: { locationPathname } }));
export const refreshApiKeysList = createAction(REFRESH_API_KEYS_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export const fetchApiKeyValue = createAction(FETCH_API_KEY_VALUE, (apiKeyId) => ({ payload: apiKeyId }));
export const fetchApiKeyValueSuccess = createAction(FETCH_API_KEY_VALUE_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const fetchApiKeyValueFailure = createAction(FETCH_API_KEY_VALUE_FAILURE);

export const openCreateApiKeyModal = createAction(OPEN_CREATE_API_KEY_MODAL, (clientId) => ({ payload: clientId }));
export const createApiKey = createAction(CREATE_API_KEY, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const createApiKeySuccess = createAction(CREATE_API_KEY_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const createApiKeyFailure = createAction(CREATE_API_KEY_FAILURE);


export const deleteApiKey = createAction(DELETE_API_KEY, (apiKeyId, locationPathname) => ({ payload: { apiKeyId, locationPathname } }));
export const deleteApiKeySuccess = createAction(DELETE_API_KEY_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const deleteApiKeyFailure = createAction(DELETE_API_KEY_FAILURE);
