export const getCountryOfIncorporation = (value, countries) => {
    const country = countries.find((country) => country.iso_31663 === value);
    return country;
};

export const getCountriesOfOperation = (values, countries) => {
    const countriesOfOperation = (values.countries_of_operations || []).reduce((acc, iso) => {
        const country = countries.find((country) => country.iso_31663 === iso);
        if (country) {
            return [...acc, country];
        }
        return acc;
    }, []);

    return countriesOfOperation || [];
};

export const getOperatingIndustries = (values, industries) => {
    const operationIndustries = (values.industries || []).reduce((acc, id) => {
        const industry = industries.find((industry) => industry.id === Number(id));

        if (industry && industry.id !== 31) {
            return [...acc, industry];
        }
        return acc;
    }, []);

    return operationIndustries || [];
};


export const getCurrentRiskValues = (
    riskScoreChangesHistory,
) => {
    const initialState = {
        countries_of_operations: {},
        operating_industries: {},
    };

    const currentRiskValues = (riskScoreChangesHistory || []).reduce((acc, historyItem) => {
        const itemDate = new Date(historyItem.updated_at);
        const industryId = historyItem.industry_id;
        const operationCountryId = historyItem.operation_country_id;

        if (historyItem.industry_id > 0) {
            if (acc.operating_industries[industryId]) {
                // check if it is newer than existing
                const { date } = acc.operating_industries[industryId];
                if (itemDate > date) {
                    return {
                        ...acc,
                        operating_industries: {
                            ...acc.operating_industries,
                            [industryId]: {
                                value: historyItem.to,
                                date: itemDate,
                            },
                        },
                    };
                }
            } else {
                // simply add record
                return {
                    ...acc,
                    operating_industries: {
                        ...acc.operating_industries,
                        [industryId]: {
                            value: historyItem.to,
                            date: itemDate,
                        },
                    },
                };
            }
        }

        if (historyItem.operation_country_id > 0) {
            if (acc.countries_of_operations[operationCountryId]) {
                // check if it is newer than existing
                const { date } = acc.countries_of_operations[operationCountryId];
                if (itemDate > date) {
                    return {
                        ...acc,
                        countries_of_operations: {
                            ...acc.countries_of_operations,
                            [operationCountryId]: {
                                value: historyItem.to,
                                date: itemDate,
                            },
                        },
                    };
                }
            } else {
                // simply add record
                return {
                    ...acc,
                    countries_of_operations: {
                        ...acc.countries_of_operations,
                        [operationCountryId]: {
                            value: historyItem.to,
                            date: new Date(historyItem.updated_at),
                        },
                    },
                };
            }
        }
        return acc;
    }, initialState);

    return currentRiskValues;
};


export default {
    getCountryOfIncorporation,
    getCountriesOfOperation,
    getOperatingIndustries,
    getCurrentRiskValues,
};
