import TabSubHeading from 'components/common/atoms/TabSubHeading';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { AccessControlProps } from 'constants/AccessControlModel';
import { cardMaxLimitValue } from 'constants/CardModel';
import { PN } from 'constants/PermissionsModel';
import { numberGreaterThan, numberRule, numberSmallerThan } from 'utils/validation-tools';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    Button, Col, Form, InputNumber, Row, Switch, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';


import Styled from './Limits.styled';


function Limits({
    entityDetailsData,
    accessControl,
    t,
    isChangeLimitFormVisible,
    isLoadingUpdateLimits,
    hasLimitFormValue,
    dispatchUpdateCardLimits,
    dispatchToggleChangeCardLimitFormVisibility,
    dispatchSetChangeCardLimitFormHasLimitValue,
    isInReadOnlyMode,
}) {
    const cardDetails = entityDetailsData?.entityDetails;

    const [form] = Form.useForm();
    const valueRender = (value) => (value !== undefined ? value : '-');

    const canUpdateCardLimit = useMemo(
        () => accessControl.hasPermission(PN.CARDS.UPDATE_LIMITS) && !isInReadOnlyMode,
        [accessControl, isInReadOnlyMode],
    );

    useEffect(() => {
        if (cardDetails?.id > 0) {
            form.setFieldsValue({ has_limit: cardDetails?.has_limit });
            dispatchSetChangeCardLimitFormHasLimitValue(cardDetails?.has_limit);
        }
    }, [cardDetails]);

    const onFinishHandler = (values) => {
        dispatchUpdateCardLimits(cardDetails.id, {
            has_limit: values?.has_limit,
            ...(values?.has_limit ? { limit_monthly: values?.limit_monthly } : {}),
        });
    };

    const onTogglerChange = (value) => {
        dispatchSetChangeCardLimitFormHasLimitValue(value);

        if (!value) {
            form.setFieldsValue({ limit_monthly: cardDetails?.card_limit });
        }
    };


    const fields = [
        {
            label: t('cardDetails.tabs.limits.fields.hasLimit.text'),
            value: booleanAsIconsRenderer(
                cardDetails,
                'has_limit',
                t,
                t('cardDetails.tabs.limits.fields.hasLimit.yesTooltip'),
                t('cardDetails.tabs.limits.fields.hasLimit.noTooltip'),
            ),
        },
        {
            label: t('cardDetails.tabs.limits.fields.monthlyLimit.text'),
            value: `${valueRender(cardDetails?.card_spent)} / ${valueRender(cardDetails?.card_limit)}`,
        },
    ];


    return (
        <Col span={8}>
            <TabSubHeading text={t('cardDetails.tabs.limits.subHeadings.cardLimits')} />

            {fields.map((field, index) => (
                <Row
                    key={`field-${index}`}
                    justify="space-between"
                    style={{ marginBottom: 5 }}
                >
                    <div>{field.label}</div>
                    <div>
                        {field.value}
                    </div>
                </Row>
            ))}

            {canUpdateCardLimit && (
                <Styled.ChangeCardLimitButtonWrapper>
                    {isChangeLimitFormVisible ? (
                        <Spin spinning={isLoadingUpdateLimits} tip={'Updating limit...'}>
                            <Form
                                form={form}
                                layout="vertical"
                                name="limits_form"
                                initialValues={{
                                    has_limit: !!cardDetails?.has_limit,
                                    limit_monthly: cardDetails?.card_limit,
                                }}
                                onFinish={onFinishHandler}
                            >
                                <Form.Item
                                    name="has_limit"
                                    label={'Enable/disable limit'}
                                >
                                    <Switch
                                        onChange={(value) => onTogglerChange(value)}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={hasLimitFormValue}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="limit_monthly"
                                    label={'Monthly limit value'}
                                    rules={[
                                        ...numberRule({ t, fieldName: 'limit_monthly' }),
                                        ...numberGreaterThan('limit_monthly', t, 0),
                                        ...numberSmallerThan({
                                            t,
                                            fieldName: 'limit_monthly',
                                            maxValue: cardMaxLimitValue,
                                            allowEquality: true,
                                        }),
                                    ]}
                                >
                                    <InputNumber
                                        min={0}
                                        max={cardMaxLimitValue}
                                        disabled={!hasLimitFormValue}
                                        placeholder="Please enter new Limit"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>

                                <Styled.ChangeCardLimitFormButtonsWrapper>
                                    <Button type="default" htmlType="reset" onClick={() => dispatchToggleChangeCardLimitFormVisibility(false)}>
                    Cancel
                                    </Button>

                                    <Button type="primary" htmlType="submit">
                    Update limits
                                    </Button>
                                </Styled.ChangeCardLimitFormButtonsWrapper>
                            </Form>
                        </Spin>
                    ) : (

                        <Button
                            type="primary"
                            style={{ marginLeft: 5 }}
                            onClick={() => dispatchToggleChangeCardLimitFormVisibility(true)}
                        >
              Change Limit
                        </Button>

                    )}
                </Styled.ChangeCardLimitButtonWrapper>
            )}

        </Col>
    );
}

Limits.propTypes = {
    accessControl: AccessControlProps,
    dispatchToggleChangeCardLimitFormVisibility: PropTypes.func.isRequired,
    dispatchSetChangeCardLimitFormHasLimitValue: PropTypes.func.isRequired,
    dispatchUpdateCardLimits: PropTypes.func.isRequired,
    isLoadingUpdateLimits: PropTypes.bool.isRequired,
    hasLimitFormValue: PropTypes.bool,
    isInReadOnlyMode: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

export default Limits;
