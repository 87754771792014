import { HttpService } from 'services/http';


export const initAPI = (requestPayload) => HttpService.post('/v3/exchange/init', requestPayload);

export const confirmAPI = (exchangeUuid) => HttpService.post(`/v3/exchange/${exchangeUuid}/confirm`);

export const rejectAPI = (exchangeUuid) => HttpService.delete(`/v3/exchange/${exchangeUuid}/reject`);

export const fetchRateAPI = (from, to) => HttpService.get(`/v2/exchange-rates/${from}/${to}`);

