import { CardDeliveryMethod, CardDeliveryType, CardOrderTarget, CardType } from 'constants/CardModel';
import { CardOrderSteps } from 'redux/card-order/helpers';
import { clearCurrentUser } from 'redux/current-user/actions';
import { capitalizeFirstLetter } from 'utils/string-tools';

import { createReducer } from '@reduxjs/toolkit';


import {
    getTargetList, getTargetListSuccess, getTargetListFailure,
    fetchCardMatrix, fetchCardMatrixSuccess, fetchCardMatrixFailure,
    selectMatrix,
    clearMatrix,
    fetchDeliveryAddress, fetchDeliveryAddressSuccess, fetchDeliveryAddressFailure,
    clearAddress,
    setCardType,
    processCardsOrder, processCardsOrderSuccess, processCardsOrderFailure,
    setCardMatrix,
    setProductType,
    setData,
    resetDataToInitState,
    orderCards, orderCardsSuccess, orderCardsFailure,
    setOrderStep, startCardsOrdering, setMemberEmbossName,
    clearMembers, clearCompanies, clearCardsDetails,
} from './actions';


export const cardsOrderReducerName = 'cardOrder';

const addressFields = {
    phone_number: null,
    address_line_1: null,
    address_line_2: null,
    zip_code: null,
    first_name: null,
    last_name: null,
    email: null,
    city: null,
    country: null,
};

const cardDetailsData = {
    card_type: CardType.PHYSICAL,
    delivery_type: CardDeliveryType.SINGLE,
    delivery_method: CardDeliveryMethod.STANDARD,
    units: 1,

    product_type: null,
    carrier_ref: null,
    envelope_ref: null,
    issuing_country: null,
    card_image: null,
    embossNames: [undefined],
    acsAuthMethod: null,
    acsAuthManager: null,
};


export const initialFormState = {
    client_id: null,
    company_id: null,
    member_id: null,

    target: CardOrderTarget.CLIENTS,

    ...cardDetailsData,

    ...addressFields,
};


const initialState = {
    initialSetupFinished: false,
    showSelectTargetStep: true,
    currentStep: CardOrderSteps.TARGET_SELECTION,
    memberEmbossName: '',
    selectedMatrix: {},

    data: initialFormState,

    products: [],
    cardTypes: [],

    members: [],
    companies: [],

    cardMatrixes: [],
    cardOrderReview: { cards: [] },

    isLoadingMembers: false,
    isLoadingCompanies: false,

    isLoadingCardMatrixes: false,
    isLoadingDeliveryAddress: false,

    isLoadingProcessCard: false,
    isLoadingOrderCards: false,

    error: undefined,
};

const handlers = {
    [startCardsOrdering]: (state, action) => {
    // company is given
        if (action.payload?.clientId && action.payload?.companyId) {
            state.showSelectTargetStep = false;
            state.currentStep = CardOrderSteps.CARD_DETAILS;
            state.data.company_id = Number(action.payload?.companyId);
            // member is given
        } else if (action.payload?.clientId && action.payload?.memberId && action.payload.memberEmbossName) {
            state.showSelectTargetStep = false;
            state.currentStep = CardOrderSteps.CARD_DETAILS;
            state.data.target = CardOrderTarget.MEMBERS;
            state.data.member_id = Number(action.payload?.memberId);
            state.memberEmbossName = action.payload?.memberEmbossName;
        }
        state.data.client_id = Number(action.payload?.clientId);
        state.initialSetupFinished = true;
    },

    [setData]: (state, action) => {
        state.data = {
            ...state.data,
            ...action.payload,
        };
    },

    [setProductType]: (state, action) => {
        state.data = {
            ...state.data,
            product_type: action.payload,
        };
    },

    [setCardType]: (state, action) => {
        state.data = {
            ...state.data,
            card_type: action.payload.card_type,
        };
    },

    [setCardMatrix]: (state, action) => {
        state.data = {
            ...state.data,
            card_matrix: action.payload,
        };
    },

    [clearMatrix]: (state) => {
        state.selectedMatrix = {};
        state.data = {
            ...state.data,
            country: null,
            card_image: null,
            type: null,
            card_matrix: null,
        };
    },

    [clearMembers]: (state) => {
        state.members = [];
    },

    [clearCompanies]: (state) => {
        state.companies = [];
    },

    [selectMatrix]: (state, action) => {
        state.selectedMatrix = action.payload;
        state.data = {
            ...state.data,
            country: null,
            card_image: null,
            type: null,
            issuing_country: null,
            envelope_ref: null,
            carrier_ref: null,
            card_matrix: null,
        };
    },


    [getTargetList]: (state, action) => {
        state[`isLoading${capitalizeFirstLetter(action.payload.targetType)}`] = true;
    },
    [getTargetListSuccess]: (state, action) => {
        state[`isLoading${capitalizeFirstLetter(action.payload.targetType)}`] = false;
        state[action.payload.targetType] = action.payload.responseData || [];
        state.error = undefined;
    },
    [getTargetListFailure]: (state, action) => {
        state[`isLoading${capitalizeFirstLetter(action.payload.targetType)}`] = false;
        state.error = action.error;
        state[action.payload] = [];
    },

    [fetchCardMatrix]: (state) => {
        state.isLoadingCardMatrixes = true;
    },
    [fetchCardMatrixSuccess]: (state, action) => {
        state.isLoadingCardMatrixes = false;
        state.cardMatrixes = action.payload;
        state.error = undefined;
    },
    [fetchCardMatrixFailure]: (state, action) => {
        state.isLoadingCardMatrixes = false;
        state.error = action.error;
        state.cardMatrixes = [];
    },

    [processCardsOrder]: (state) => {
        state.isLoadingProcessCard = true;
    },
    [processCardsOrderSuccess]: (state, action) => {
        state.isLoadingProcessCard = false;
        const { cards, ...rest } = action.payload;

        const cardsWithEmbossName = cards.map((card, index) => ({
            ...card,
            emboss_name: state.data.embossNames[index],
        }));

        state.cardOrderReview = { cards: cardsWithEmbossName, ...rest };
        state.error = undefined;
    },
    [processCardsOrderFailure]: (state, action) => {
        state.isLoadingProcessCard = false;
        state.error = action.error;
        state.cardOrderReview = {};
    },

    [fetchDeliveryAddress]: (state) => {
        state.isLoadingDeliveryAddress = true;
    },
    [fetchDeliveryAddressSuccess]: (state, action) => {
        state.isLoadingDeliveryAddress = false;
        const { phone_number, address_line_1, address_line_2, zip_code, first_name, last_name, email, city, country } = action.payload;
        state.data = {
            ...state.data,
            phone_number,
            address_line_1,
            address_line_2,
            zip_code,
            first_name,
            last_name,
            email,
            city,
            country,
        };
        state.error = undefined;
    },
    [fetchDeliveryAddressFailure]: (state, action) => {
        state.isLoadingDeliveryAddress = false;
        state.error = action.error;
        state.data = {
            ...state.data,
            ...addressFields,
        };
    },
    [setOrderStep]: (state, action) => {
        state.currentStep = action.payload;
    },


    [clearCardsDetails]: (state) => {
        state.data = {
            ...state.data,
            ...cardDetailsData,
        };
    },

    [clearAddress]: (state) => {
        state.data = {
            ...state.data,
            ...addressFields,
        };
    },

    [orderCards]: (state) => {
        state.isLoadingOrderCards = true;
    },
    [orderCardsSuccess]: (state) => {
        state.isLoadingOrderCards = false;
        state.data = {
            ...initialState.data,
            card_type: state.data.card_type,
        };
        state.cardOrderReview = initialState.cardOrderReview;
        state.error = undefined;
    },
    [orderCardsFailure]: (state, action) => {
        state.isLoadingOrderCards = true;
        state.error = action.error;
    },

    [setMemberEmbossName]: (state, action) => {
        state.memberEmbossName = action.payload;
    },

    [resetDataToInitState]: () => initialState,
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
