import { AccessControlProps } from 'constants/AccessControlModel';
import { AccountOwnerTypes, AccountsListProp } from 'constants/AccountModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { LinkCardSteps } from 'redux/card-linking/helpers';

import { Steps, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AccountSelectionStep from './components/AccountSelection.layout';
import CardSelectionStep from './components/CardSelectionStep.layout';
import FinalLinkStep from './components/FinalLinkStep';


const { Step } = Steps;

function LinkCardModal({
    accessControl,

    cardLinkingStep,
    isLoadingNotLinkedCards,
    isBusyLinkingCard,

    cardSearchQuery,
    withPartnerCards,

    listData,

    memberId,
    clientId,
    businessUserId,
    companyId,
    notLinkedCards,

    selectedAccount,
    selectedCard,
    client3DsSettings,

    dispatchSetCardLinkingStep,
    dispatchFetchNotLinkedCards,
    dispatchSelectCardAccountForLink,
    dispatchSelectCardForLink,
    dispatchFetchAccountsList,
    dispatchSetCardsSearchQuery,
    dispatchSetWithPartnerCards,
    dispatchFetchClient3DsSettings,
    dispatchSetModalProps,
    dispatchLinkCardToBusinessUser,
    dispatchLinkCardToMember,
    dispatchLinkCardToCompany,
    enhancedCurrentLocation,
    dispatchClearLinkCardProcess,
    t,
}) {
    const [form] = useForm();
    const [initLoading, setInitLoading] = useState(true);
    const properMemberId = memberId && Number(memberId);


    const fetchNotLinkedCards = (rawQuery = {}) => {
        const query = {
            ...rawQuery,
            clientId,
            companyId,
            memberId: properMemberId,
        };

        dispatchFetchNotLinkedCards(query);
    };

    const fetchAccounts = () => {
        if (properMemberId > 0) {
            dispatchFetchAccountsList({ ownerType: AccountOwnerTypes.MEMBER, ownerId: properMemberId }, enhancedCurrentLocation.pathname);
        }
        if (companyId > 0) {
            dispatchFetchAccountsList({ ownerType: AccountOwnerTypes.COMPANY, ownerId: companyId }, enhancedCurrentLocation.pathname);
        }
    };


    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
        fetchNotLinkedCards();
        fetchAccounts();
        dispatchFetchClient3DsSettings(clientId);
        setInitLoading(false);

        return () => {
            dispatchClearLinkCardProcess();
        };
    }, []);


    const onFinish = (values) => {
        const commonPayloadPart = {
            accountId: selectedAccount?.id,
            phoneNumber: values.phoneNumber,
            ...(accessControl.isSuperAdmin && { // XXX allowed - only super admins can override client 3DS config
                acsAuthManager: values.challengeManager,
                acsAuthMethod: values.challengeMethod,
            }),
        };

        if (memberId > 0) {
            dispatchLinkCardToMember(selectedCard.id, {
                memberId,
                ...commonPayloadPart,
            });
        }

        if (businessUserId > 0) {
            dispatchLinkCardToBusinessUser(selectedCard.id, {
                // userId: Number(businessUserId), TODO check userId
                businessUserId: Number(businessUserId),
                ...commonPayloadPart,
            });
        }

        if (companyId > 0 && !businessUserId > 0) {
            dispatchLinkCardToCompany(selectedCard.id, {
                companyId: Number(companyId),
                ...commonPayloadPart,
            });
        }

        dispatchSetModalProps({ confirmLoading: true });
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ phoneNumber: undefined }}
            onFinish={onFinish}
        >
            <div style={{ marginTop: '16px', marginBottom: '32px' }}>
                <Steps current={cardLinkingStep}>
                    <Step title={t('modals.linkCard.stepNames.selectCard')} />
                    <Step title={t('modals.linkCard.stepNames.selectAccount')} />
                    <Step title={t('modals.linkCard.stepNames.summary')} />
                </Steps>
            </div>

            {cardLinkingStep === LinkCardSteps.CARD_SELECTION && (
                <CardSelectionStep
                    memberId={properMemberId}
                    companyId={companyId}
                    businessUserId={businessUserId}
                    initLoading={initLoading}
                    cardSearchQuery={cardSearchQuery}
                    withPartnerCards={withPartnerCards}
                    notLinkedCards={notLinkedCards}
                    isLoadingNotLinkedCards={isLoadingNotLinkedCards}
                    dispatchSelectCardForLink={dispatchSelectCardForLink}
                    fetchNotLinkedCards={fetchNotLinkedCards}
                    dispatchSetCardLinkingStep={dispatchSetCardLinkingStep}
                    dispatchSetCardsSearchQuery={dispatchSetCardsSearchQuery}
                    dispatchSetWithPartnerCards={dispatchSetWithPartnerCards}
                    t={t}
                />
            )}

            {cardLinkingStep === LinkCardSteps.ACCOUNT_SELECTION && (
                <AccountSelectionStep
                    t={t}
                    companyId={companyId}
                    businessUserId={businessUserId}
                    initLoading={initLoading}
                    accountsList={listData?.items}
                    isLoadingAccounts={listData?.isLoadingList}
                    dispatchSelectCardAccountForLink={dispatchSelectCardAccountForLink}
                    dispatchSetCardLinkingStep={dispatchSetCardLinkingStep}
                />
            )}

            {cardLinkingStep === LinkCardSteps.SUMMARY
        && (
            <FinalLinkStep
                accessControl={accessControl}
                isBusyLinkingCard={isBusyLinkingCard}
                memberId={properMemberId}
                companyId={companyId}
                businessUserId={businessUserId}
                card={selectedCard}
                client3DsSettings={client3DsSettings}
                account={selectedAccount}
                form={form}
                t={t}
            />
        )}
        </Form>
    );
}


LinkCardModal.propTypes = {
    accessControl: AccessControlProps,
    cardLinkingStep: PropTypes.number.isRequired,
    isLoadingNotLinkedCards: PropTypes.bool.isRequired,
    isBusyLinkingCard: PropTypes.bool.isRequired,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: AccountsListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    clientId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    memberId: PropTypes.number.isRequired,
    businessUserId: PropTypes.number.isRequired,
    notLinkedCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

    selectedAccount: PropTypes.shape({}).isRequired,
    selectedCard: PropTypes.shape({}).isRequired,
    cardSearchQuery: PropTypes.string.isRequired,
    withPartnerCards: PropTypes.bool.isRequired,
    client3DsSettings: PropTypes.shape({}).isRequired,

    t: PropTypes.func.isRequired,
    dispatchFetchNotLinkedCards: PropTypes.func.isRequired,
    dispatchSelectCardAccountForLink: PropTypes.func.isRequired,
    dispatchSelectCardForLink: PropTypes.func.isRequired,
    dispatchFetchAccountsList: PropTypes.func.isRequired,
    dispatchSetCardsSearchQuery: PropTypes.func.isRequired,
    dispatchSetWithPartnerCards: PropTypes.func.isRequired,
    dispatchFetchClient3DsSettings: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchLinkCardToMember: PropTypes.func.isRequired,
    dispatchLinkCardToBusinessUser: PropTypes.func.isRequired,
    dispatchLinkCardToCompany: PropTypes.func.isRequired,
    dispatchSetCardLinkingStep: PropTypes.func.isRequired,
    dispatchClearLinkCardProcess: PropTypes.func.isRequired,
};

export default LinkCardModal;
