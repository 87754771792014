import { AddEditRoleStepDefinitions } from 'constants/AddEditRoleModel';
import { MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';

import { BookOutlined } from '@ant-design/icons';
import React from 'react';


import SelectRoleTemplateStep from './index';


export function createSelectRoleTemplateStepConfig({ t, rolesTemplatesLists }) {
    return {
        stepName: AddEditRoleStepDefinitions.TEMPLATE_SELECTION,
        title: t('Template Selection'),
        icon: <BookOutlined />,
        sectionsLayout: MultiStepFormStepSectionTypes.EVEN_COLUMNS,
        stepSections: [
            {
                title: 'Select existing role template',
                children: <SelectRoleTemplateStep rolesTemplatesLists={rolesTemplatesLists} />,
            },
        ],
    };
}


export default { createSelectRoleTemplateStepConfig };
