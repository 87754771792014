import FlagAndValue from 'components/common/atoms/FlagAndValue';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanIconRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { createFeeDetailsPath } from 'config/routes.helpers';
import { multiplyBig } from 'utils/math-tools';
import { removeUnderscores } from 'utils/string-tools';

import { Switch } from 'antd';
import React from 'react';


export const createPromotionFieldsCol1 = ({
    promotionDetails,
    isLoadingToggleEnableDisable,
    dispatchEnablePromotion,
    dispatchDisablePromotion,
    locationPathname,
    t,
}) => {
    if (promotionDetails?.ext_id) {
        return [
            {
                value: <StandardValue value={promotionDetails.ext_id} />,
                label: t('promotions:promotionsFields.promotionUuid'),
            },
            {
                value: <StandardValue value={promotionDetails.name} />,
                label: t('promotions:promotionsFields.name'),
            },
            {
                value: <StandardValue value={promotionDetails.description} />,
                label: t('promotions:promotionsFields.description'),
            },
            {
                value: promotionDetails?.is_percentage
                    ? <StandardValue value={multiplyBig({ x: promotionDetails.amount, y: 100 })} valueType="PERCENTAGE" />
                    : <StandardValue value={promotionDetails.amount} valueType="AMOUNT" />,
                label: t('promotions:promotionsFields.amount'),
            },
            {
                label: t('promotions:promotionsFields.currency'),
                value: <FlagAndValue currencyCode={promotionDetails.currency} text={promotionDetails.currency} />,
            },

            {
                label: t('promotions:promotionsFields.enabled'),
                value: (
                    <Switch
                        loading={isLoadingToggleEnableDisable}
                        checked={promotionDetails.is_enabled}
                        onChange={(value) => (
                            value
                                ? dispatchEnablePromotion(promotionDetails.ext_id, locationPathname)
                                : dispatchDisablePromotion(promotionDetails.ext_id, locationPathname)
                        )}
                    />
                ),
            },
            {
                label: t('promotions:promotionsFields.percentage'),
                value: booleanIconRenderer(promotionDetails, 'is_percentage', t),
            },
            {
                label: t('promotions:promotionsFields.feeUuid'),
                value: (
                    <StandardLink
                        title={promotionDetails?.fee?.ext_id}
                        path={createFeeDetailsPath(promotionDetails?.fee?.ext_id)}
                    />
                ),
            },
            {
                label: t('promotions:promotionsFields.feeName'),
                value: <StandardValue value={promotionDetails?.fee?.name} />,
            },
            {
                label: t('promotions:promotionsFields.allowExchange'),
                value: booleanIconRenderer(promotionDetails, 'allow_exchange', t),
            },
        ];
    }
    return [];
};

export const createPromotionFieldsCol2 = (promotionDetails, t) => {
    if (promotionDetails?.ext_id) {
        return [
            {
                value: <StandardValue value={removeUnderscores(promotionDetails.promotion_type)} />,
                showEmpty: true,
                label: t('promotions:promotionsFields.promotionType'),

            },
            {
                value: <StandardValue value={removeUnderscores(promotionDetails.trigger_type)} />,
                showEmpty: true,
                label: t('promotions:promotionsFields.triggerType'),
            },
            {
                value: <StandardValue value={promotionDetails.trigger_amount} valueType="AMOUNT" />,
                showEmpty: true,
                label: t('promotions:promotionsFields.triggerAmount'),
            },
            {
                value: <StandardValue value={promotionDetails.trigger_entity} />,
                showEmpty: true,
                label: t('promotions:promotionsFields.triggerEntity'),
            },
            {
                value: <StandardValue value={promotionDetails.over_amount} valueType="AMOUNT" />,
                label: t('promotions:promotionsFields.overAmount'),
            },

            {
                label: t('promotions:promotionsFields.activeFrom'),
                value: <StandardValue value={promotionDetails.active_from} valueType="DATE" showTimeInTooltip />,
            },
            {
                label: t('promotions:promotionsFields.activeUntil'),
                value: <StandardValue value={promotionDetails.active_until} valueType="DATE" showTimeInTooltip />,
            },
        ];
    }
    return [];
};


export default {
    createPromotionFieldsCol1,
    createPromotionFieldsCol2,
};
