import { createAction } from '@reduxjs/toolkit';

import {
    ADD_CLIENT_RISK_DETAILS,
    ADD_CLIENT_RISK_DETAILS_FAILURE,
    ADD_CLIENT_RISK_DETAILS_SUCCESS,
    CLEAR_CLIENT_DETAILS, FETCH_CLIENT_3DS_SETTINGS, FETCH_CLIENT_3DS_SETTINGS_FAILURE, FETCH_CLIENT_3DS_SETTINGS_SUCCESS,
    FETCH_CLIENT_ADDRESSES,
    FETCH_CLIENT_ADDRESSES_FAILURE,
    FETCH_CLIENT_ADDRESSES_SUCCESS,
    FETCH_CLIENT_DETAILS,
    FETCH_CLIENT_DETAILS_FAILURE,
    FETCH_CLIENT_DETAILS_SUCCESS,
    OPEN_ADD_CLIENT_RISK_DETAILS_MODAL,
    OPEN_EDIT_CLIENT_PROFILE_MODAL, UPDATE_CLIENT_ANNOUNCEMENT, UPDATE_CLIENT_ANNOUNCEMENT_FAILURE, UPDATE_CLIENT_ANNOUNCEMENT_SUCCESS,
    UPDATE_CLIENT_PROFILE,
    UPDATE_CLIENT_PROFILE_FAILURE,
    UPDATE_CLIENT_PROFILE_SUCCESS,
    UPDATE_CLIENT_SETTINGS,
    UPDATE_CLIENT_SETTINGS_FAILURE,
    UPDATE_CLIENT_SETTINGS_SUCCESS,
} from './actions.types';


export const fetchClientDetails = createAction(FETCH_CLIENT_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchClientDetailsSuccess = createAction(FETCH_CLIENT_DETAILS_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchClientDetailsFailure = createAction(FETCH_CLIENT_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearClientDetails = createAction(CLEAR_CLIENT_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));

export const fetchClientAddresses = createAction(FETCH_CLIENT_ADDRESSES, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchClientAddressesSuccess = createAction(FETCH_CLIENT_ADDRESSES_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchClientAddressesFailure = createAction(FETCH_CLIENT_ADDRESSES_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const openEditClientProfileModal = createAction(OPEN_EDIT_CLIENT_PROFILE_MODAL, (clientDetails) => ({ payload: clientDetails }));
export const updateClientProfile = createAction(UPDATE_CLIENT_PROFILE, (clientId, formData) => ({ payload: { clientId, formData } }));
export const updateClientProfileSuccess = createAction(UPDATE_CLIENT_PROFILE_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const updateClientProfileFailure = createAction(UPDATE_CLIENT_PROFILE_FAILURE);

export const updateClientSettings = createAction(
    UPDATE_CLIENT_SETTINGS,
    (clientId, formData, isAnnouncementsEnabledUpdateOnly) => ({ payload: { clientId, formData, isAnnouncementsEnabledUpdateOnly } }),
);
export const updateClientSettingsSuccess = createAction(UPDATE_CLIENT_SETTINGS_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const updateClientSettingsFailure = createAction(UPDATE_CLIENT_SETTINGS_FAILURE);


export const updateClientAnnouncement = createAction(UPDATE_CLIENT_ANNOUNCEMENT, (clientId, formData) => ({ payload: { clientId, formData } }));
export const updateClientAnnouncementSuccess = createAction(UPDATE_CLIENT_ANNOUNCEMENT_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const updateClientAnnouncementFailure = createAction(UPDATE_CLIENT_ANNOUNCEMENT_FAILURE);


export const openAddClientRiskDetailsModal = createAction(OPEN_ADD_CLIENT_RISK_DETAILS_MODAL,
    (initialValues, locationPathname) => ({ payload: { initialValues, locationPathname } }));

export const addClientRiskDetails = createAction(ADD_CLIENT_RISK_DETAILS, (formData, locationPathname) => ({ payload: { formData, locationPathname } }));
export const addClientRiskDetailsSuccess = createAction(ADD_CLIENT_RISK_DETAILS_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const addClientRiskDetailsFailure = createAction(ADD_CLIENT_RISK_DETAILS_FAILURE);


export const fetchClient3DsSettings = createAction(FETCH_CLIENT_3DS_SETTINGS, (clientId, reducerName) => ({ payload: { clientId, reducerName } }));
export const fetchClient3DsSettingsSuccess = createAction(FETCH_CLIENT_3DS_SETTINGS_SUCCESS, (data, reducerName) => ({ payload: { data, reducerName } }));
export const fetchClient3DsSettingsFailure = createAction(FETCH_CLIENT_3DS_SETTINGS_FAILURE, (reducerName) => ({ payload: { reducerName } }));

