const ModalName = Object.freeze({
    ADD_ACCOUNT_MODAL: 'ADD_ACCOUNT_MODAL',
    ADD_API_KEY_MODAL: 'ADD_API_KEY_MODAL',
    ADD_EDIT_USER_MODAL: 'ADD_EDIT_USER_MODAL',
    ADD_BUSINESS_USER_MODAL: 'ADD_BUSINESS_USER_MODAL',
    ADD_MIGRATION_MODAL: 'ADD_MIGRATION_MODAL',
    ADD_PRODUCT_MODAL: 'ADD_PRODUCT_MODAL',
    ADD_PROMOTION_TO_GROUP: 'ADD_PROMOTION_TO_GROUP',
    ADD_EDIT_WEBHOOK_MODAL: 'ADD_EDIT_WEBHOOK_MODAL',
    LINK_CARD_MODAL: 'LINK_CARD_MODAL',
    UNLINK_CARD_MODAL: 'UNLINK_CARD_MODAL',
    EDIT_MEMBER_MODAL: 'EDIT_MEMBER_MODAL',
    EDIT_MEMBER_ADDRESS_MODAL: 'EDIT_MEMBER_ADDRESS_MODAL',
    EDIT_CLIENT_MODAL: 'EDIT_CLIENT_MODAL',
    EDIT_CLIENT_ADDRESS_MODAL: 'EDIT_CLIENT_ADDRESS_MODAL',
    EDIT_COMPANY_MODAL: 'EDIT_COMPANY_MODAL',
    EDIT_COMPANY_ADDRESS: 'EDIT_COMPANY_ADDRESS',
    EDIT_BUSINESS_USER_MODAL: 'EDIT_BUSINESS_USER_MODAL',
    CHANGE_CLIENT_RISK_SCORE_MODAL: 'CHANGE_CLIENT_RISK_SCORE_MODAL',
    CHANGE_MEMBER_RISK_SCORE_MODAL: 'CHANGE_MEMBER_RISK_SCORE_MODAL',
    CHANGE_COMPANY_RISK_SCORE_MODAL: 'CHANGE_COMPANY_RISK_SCORE_MODAL',
    DOWNLOAD_MIGRATION_REPORT_MODAL: 'DOWNLOAD_MIGRATION_REPORT_MODAL',
    REASSIGN_MEMBER_GROUP: 'REASSIGN_MEMBER_GROUP',
    CREATE_NEW_GROUP: 'CREATE_NEW_GROUP',
    EDIT_GROUP_NAME: 'EDIT_GROUP_NAME',
    DELETE_GROUP: 'DELETE_GROUP',
    EDIT_PHONE_NUMBER: 'EDIT_PHONE_NUMBER',
    VIEW_JSON_MODAL: 'VIEW_JSON_MODAL',
    WITHDRAW_ACCOUNT_BALANCE_MODAL: 'WITHDRAW_ACCOUNT_BALANCE_MODAL',
    BALANCE_ADJUSTMENT_MODAL: 'BALANCE_ADJUSTMENT_MODAL',
    CHARGE_FEE_MODAL: 'CHARGE_FEE_MODAL',
    UNBLOCK_TRANSACTION: 'UNBLOCK_TRANSACTION',
    TRANSACTION_SCREENING_RESOLUTION_MODAL: 'TRANSACTION_SCREENING_RESOLUTION_MODAL',
    REQUEST_MARKETING_PREFERENCES_MODAL: 'REQUEST_MARKETING_PREFERENCES_MODAL',
    UPDATE_MEMBER_STATUS_MODAL: 'UPDATE_MEMBER_STATUS_MODAL',
});


export default ModalName;
