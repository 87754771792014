import { showSuccessToast } from 'redux/application/actions';
import { fetchBusinessUserDetails } from 'redux/business-user/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchBusinessUserStatusHistoryListFailure, fetchBusinessUserStatusHistoryListSuccess, updateBusinessUserStatusFailure, updateBusinessUserStatusSuccess } from './actions';
import { FETCH_BUSINESS_USER_STATUS_HISTORY_LIST, UPDATE_BUSINESS_USER_STATUS } from './actions.types';


export const onFetchBusinessStatusHistory = (action$, state$, { businessUsers }) => action$.pipe(
    ofType(FETCH_BUSINESS_USER_STATUS_HISTORY_LIST),
    switchMap(({ payload }) => from(businessUsers.getBusinessUserStatusHistory({ ...payload.queryParams }))
        .pipe(
            switchMap((response) => of(fetchBusinessUserStatusHistoryListSuccess(
                response.data.rows,
                response?.data?.count,
                payload.locationPathname,
            ))),
            catchError(() => of(fetchBusinessUserStatusHistoryListFailure())),
        )),
);

export const onUpdateBusinessUserStatus = (action$, state$, { i18n, businessUsers }) => action$.pipe(
    ofType(UPDATE_BUSINESS_USER_STATUS),
    switchMap(({ payload }) => from(businessUsers.updateBusinessUserStatus(payload.businessUserId, payload.requestPayload))
        .pipe(
            switchMap(() => of(
                fetchBusinessUserDetails({ businessUserId: payload.businessUserId }, payload.locationPathname),
                updateBusinessUserStatusSuccess(),
                showSuccessToast(i18n.t('businessUsers:actionMessages.updateBusinessUserStatusSuccess')),
            )),
            catchError(() => of(updateBusinessUserStatusFailure())),
        )),
);


export default [
    onFetchBusinessStatusHistory,
    onUpdateBusinessUserStatus,
];
