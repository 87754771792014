import withAccessControl from 'hocs/withAccessControl';
import { clearClientIdContext, setClientIdContext } from 'redux/client-context/actions';
import { clientContextReducerName } from 'redux/client-context/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';


import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';


import StandardEntityDetailsWithTabs from './StandardEntityDetailsWithTabs.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state[navigationReducerName].enhancedCurrentLocation,
    currentUserClientId: state[currentUserReducerName].userData?.clientId,
    clientIdContext: state[clientContextReducerName].clientIdContext,
});


const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchSetClientIdContext: (clientId) => dispatch(setClientIdContext(clientId)),
    dispatchClearClientIdContext: () => dispatch(clearClientIdContext()),
});


const decorate = compose(
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(StandardEntityDetailsWithTabs);
