import { CommonQueryParamsMetaProps, CommonQueryParamsProps, ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { filterOptions } from 'utils/filterOptions';
import { isFormPristine } from 'utils/forms-tools';
import { createGroupsOptions } from 'utils/options-creators';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


function ReassignMemberGroup({
    // ~own (modal) props
    currentGroup,
    memberClientId,
    memberId,
    locationPathname,
    isBlockNavigationReasonSet,
    // mapped props
    t,
    listData,
    dispatchFetchGroupsList,
    dispatchUnblockNavigation,
    dispatchBlockNavigation,
    dispatchSetModalProps,
    dispatchReassignMemberGroup,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });

        if (!listData?.items) {
            dispatchFetchGroupsList({ clientId: memberClientId });
        }
        return () => {
            dispatchUnblockNavigation();
        };
    }, []);


    const groupsOptions = useMemo(
        () => {
            if (listData?.items) {
                const groupsWithoutCurrent = (listData?.items || []).filter((group) => group?.id !== currentGroup?.id);
                return createGroupsOptions(groupsWithoutCurrent);
            }
            return [];
        }, [listData],
    );

    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchReassignMemberGroup({
            memberId,
            groupId: values.group,
            clientId: memberClientId,
            oldGroupId: currentGroup?.id,
        }, locationPathname);
    };

    const handleFormValuesChanges = (changedValues, allValues) => {
        const isPristine = isFormPristine({ ...allValues });
        if (!isPristine && !isBlockNavigationReasonSet) {
            dispatchBlockNavigation(ReasonsForBlockedNavigation.NOT_PRISTINE_REASSIGN_MEMBER_GROUP_FORM);
        }

        if (isBlockNavigationReasonSet && isPristine) {
            dispatchUnblockNavigation();
        }
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={undefined}
            onFinish={onFinish}
            onValuesChange={handleFormValuesChanges}
        >
            <Form.Item
                name="group"
                label={t('member:modals.reassign.fields.newGroup.label')}
                rules={[{ required: true, message: t('member:modals.reassign.fields.newGroup.validationRules.required') }]}
            >
                <Select
                    disabled={listData?.isLoadingList}
                    loading={listData?.isLoadingList}
                    filterOption={filterOptions}
                    placeholder={listData?.isLoadingList ? 'Loading groups...' : t('member:modals.reassign.fields.newGroup.placeholder')}
                    showSearch
                >
                    {groupsOptions}
                </Select>
            </Form.Item>
        </Form>
    );
}

ReassignMemberGroup.propTypes = {
    t: PropTypes.func.isRequired,
    dispatchFetchGroupsList: PropTypes.func.isRequired,
    dispatchUnblockNavigation: PropTypes.func.isRequired,
    dispatchBlockNavigation: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchReassignMemberGroup: PropTypes.func.isRequired,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    memberClientId: PropTypes.number.isRequired,
    currentGroup: PropTypes.shape({}).isRequired,

};

export default ReassignMemberGroup;

