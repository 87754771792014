/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { NumericId, Uuid } from '@manigo/manigo-domain-typings';


import {
    fetchPromotionUuidDetails,
    fetchClientIdPromotions,
    removePromotionUuid,
    createPromotion as createPromotionAPI,
    disablePromotionUuid,
    enablePromotionUuid,
    fetchPromotionUuidGroups,
} from './index';


class PromotionsService {
    public fetchPromotionDetails(promotionUuid: Uuid): Promise<ApiResponse | ApiError> {
        return fetchPromotionUuidDetails(promotionUuid);
    }

    public fetchPromotionGroups(promotionUuid: Uuid): Promise<ApiResponse | ApiError> {
        return fetchPromotionUuidGroups(promotionUuid);
    }

    public fetchClientPromotions(clientId: NumericId): Promise<ApiResponse | ApiError> {
        return fetchClientIdPromotions(clientId);
    }

    public removePromotion(promotionUuid): Promise<ApiResponse | ApiError> {
        return removePromotionUuid(promotionUuid);
    }

    public createPromotion(requestPayload): Promise<ApiResponse | ApiError> {
        return createPromotionAPI(requestPayload);
    }

    public disablePromotion(promotionUuid: Uuid): Promise<ApiResponse | ApiError> {
        return disablePromotionUuid(promotionUuid);
    }

    public enablePromotion(promotionUuid: Uuid): Promise<ApiResponse | ApiError> {
        return enablePromotionUuid(promotionUuid);
    }
}

export default PromotionsService;
