export const FETCH_CLIENT_PRODUCTS = 'FETCH_CLIENT_PRODUCTS';
export const FETCH_CLIENT_PRODUCTS_SUCCESS = 'FETCH_CLIENT_PRODUCTS_SUCCESS';
export const FETCH_CLIENT_PRODUCTS_FAILURE = 'FETCH_CLIENT_PRODUCTS_FAILURE';
export const CLEAR_CLIENT_PRODUCTS = 'CLEAR_CLIENT_PRODUCTS';

export const OPEN_ADD_CLIENT_PRODUCT_MODAL = 'OPEN_ADD_CLIENT_PRODUCT_MODAL';

export const ADD_CLIENT_PRODUCT = 'ADD_CLIENT_PRODUCT';
export const ADD_CLIENT_PRODUCT_SUCCESS = 'ADD_CLIENT_PRODUCT_SUCCESS';
export const ADD_CLIENT_PRODUCT_FAILURE = 'ADD_CLIENT_PRODUCT_FAILURE';

