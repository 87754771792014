import { isValidIBAN, isValidBIC } from 'utils/account-tools';

import { Rule } from 'antd/es/form';
import { TFunction } from 'i18next';

import { isValidPhoneNumber } from './phone-number-tools';


const defaultMinLength = 3;
const defaultMaxLength = 255;

export const trimValue = (value = '') => value.trim();
/* eslint-disable spellcheck/spell-checker */
export const ipv4HttpsOnlyWebURLRegex = new RegExp(
    '^'
  // protocol identifier (mandatory)
  // short syntax // still required
  + '(?:(?:(?:https):)\\/\\/)'
  // user:pass BasicAuth (optional)
  + '(?:\\S+(?::\\S*)?@)?'
  + '(?:'
  // IP address exclusion
  // private & local networks
  + '(?!(?:10|127)(?:\\.\\d{1,3}){3})'
  + '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})'
  + '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})'
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  + '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])'
  + '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}'
  + '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))'
  + '|'
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  + '(?:'
  + '(?:'
  + '[a-z0-9\\u00a1-\\uffff]'
  + '[a-z0-9\\u00a1-\\uffff_-]{0,62}'
  + ')?'
  + '[a-z0-9\\u00a1-\\uffff]\\.'
  + ')+'
  // TLD identifier name, may end with dot
  + '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)'
  + ')'
  // port number (optional)
  + '(?::\\d{2,5})?'
  // resource path (optional)
  + '(?:[/?#]\\S*)?'
  + '$', 'i',
);

export const publicIpv4NoFtpWebURLRegex = new RegExp(
    '^'
  // protocol identifier (mandatory)
  // short syntax // still required
  + '(?:(?:(?:https?):)\\/\\/)'
  // user:pass BasicAuth (optional)
  + '(?:\\S+(?::\\S*)?@)?'
  + '(?:'
  // IP address exclusion
  // private & local networks
  + '(?!(?:10|127)(?:\\.\\d{1,3}){3})'
  + '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})'
  + '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})'
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  + '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])'
  + '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}'
  + '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))'
  + '|'
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  + '(?:'
  + '(?:'
  + '[a-z0-9\\u00a1-\\uffff]'
  + '[a-z0-9\\u00a1-\\uffff_-]{0,62}'
  + ')?'
  + '[a-z0-9\\u00a1-\\uffff]\\.'
  + ')+'
  // TLD identifier name, may end with dot
  + '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)'
  + ')'
  // port number (optional)
  + '(?::\\d{2,5})?'
  // resource path (optional)
  + '(?:[/?#]\\S*)?'
  + '$', 'i',
);
/* eslint-enabled spellcheck/spell-checker */

export const passwordRequirementsRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;

export const embossedNameRegex = /^[a-zA-Z\d]+(?:[',. -][a-zA-Z\d]+)*$/;


export const minLengthRule = (value, t, minLength = defaultMinLength): Rule[] => [{
    type: 'string',
    min: value || minLength,
    message: t('common:validationMsg.minimumLength', { minCount: value || minLength }),
}];

export const maxLengthRule = (value, t, maxLength = defaultMaxLength): Rule[] => [{
    type: 'string',
    max: value || maxLength,
    message: t('common:validationMsg.maximumLength', { maxCount: value || maxLength }),
}];

export const whitespaceRule = (t): Rule[] => [() => ({
    validator(_, value) {
        if (!value || (value && trimValue(value).length > 0)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('common:validationMsg.whitespaceOnly')));
    },
})];

export const numberGreaterThan = (fieldName, t, minValue = 0): Rule[] => [({ getFieldValue }) => ({
    validator: async () => {
        const fieldValue = getFieldValue(fieldName);
        if (fieldValue <= minValue) {
            return Promise.reject(new Error(t('common:validationMsg.minimumValue', { minValue })));
        }
        return Promise.resolve();
    },
})];

export const numberSmallerThan = ({
    fieldName,
    t,
    maxValue = 1000,
    allowEquality = true,
}): Rule[] => [({ getFieldValue }) => ({
    validator: async () => {
        const fieldValue = getFieldValue(fieldName);
        if (allowEquality && fieldValue > maxValue) {
            return Promise.reject(new Error(t('common:validationMsg.maximumEqualValue', { maxValue })));
        }
        if (!allowEquality && fieldValue >= maxValue) {
            return Promise.reject(new Error(t('common:validationMsg.maximumValue', { maxValue })));
        }

        return Promise.resolve();
    },
})];

export const numberRule = ({
    // fieldName,
    t,
    isRequired = true,
    validationMessageKey = 'common:validationMsg.amountInvalid',
}): Rule[] => [{
    type: 'number',
    required: isRequired,
    whitespace: true,
    message: t(`${validationMessageKey}`),
}];


export const isValidIbanRule = (fieldName, t): Rule[] => [({ getFieldValue }) => ({
    validator(_, value) {
        if (!value || isValidIBAN(getFieldValue(fieldName))) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('common:validationMsg.invalidIban')));
    },
})];

export const isMatchingGivenRegexRule = ({
    t,
    fieldName,
    regexPattern,
    messageError,
    // identificationType,
}): Rule[] => [() => ({
    validator(_, value) {
        const regex = new RegExp(regexPattern, 'g');
        if (!value || value.match(regex)) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(messageError || t('common:validationMsg.invalidRegexp', { fieldName })));
    },
})];


export const isValidFirstNameRule = (t): Rule[] => [() => ({
    validator(_, value) {
        if (!value || /^(?=([\p{Script=Latin}]+))\1(?=(([  -]?[\p{Script=Latin}]+)*))\2([\p{Script=Latin}]{0,2}[.]?)?$/u.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('common:validationMsg.invalidFirstName')));
    },
})];


export const isValidEmail = (t): Rule[] => [() => ({
    validator(_, value) {
        if (!value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('common:validationMsg.emailInvalidFormat')));
    },
})];

/**
 * @param {string} fieldName - field name to match
 * @param {string} regexPattern - regex pattern to match
 */
export const isValidBrandDomainEmail = ({
    t,
    fieldName,
    brandBaseDomain,
}) => isMatchingGivenRegexRule({
    t,
    fieldName,
    regexPattern: `^.*@${brandBaseDomain}$`,
    messageError: t('common:validationMsg.invalidBrandDomainEmail', { brandBaseDomain }),
});

export const isValidLastNameRule = (t): Rule[] => [() => ({
    validator(_, value) {
        if (!value || /^(?=([\p{Script=Latin}]+['’]?[\p{Script=Latin}]+))\1(?=(([ -][\p{Script=Latin}]+['’]?[\p{Script=Latin}]+)*)?)\2$/u.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('common:validationMsg.invalidLastName')));
    },
})];

export const isValidBicRule = (fieldName, t): Rule[] => [({ getFieldValue }) => ({
    validator(_, value) {
        if (!value || isValidBIC(getFieldValue(fieldName))) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('common:validationMsg.invalidBic')));
    },
})];

export const requiredRule = (t, isRequiredMessage = undefined): Rule[] => [{
    required: true,
    message: isRequiredMessage || t('common:validationMsg.fieldMandatory'),
}];

export const isValidURLRule = (fieldName, t, allowHttp = false): Rule[] => [() => ({
    validator(_, value) {
        if (allowHttp) {
            if (!value || value.match(publicIpv4NoFtpWebURLRegex)) {
                return Promise.resolve();
            }
            return Promise.reject(new Error(t('common:validationMsg.invalidURLNoFTP')));
        }

        if (!value || value.match(ipv4HttpsOnlyWebURLRegex)) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(t('common:validationMsg.invalidURL')));
    },
})];

/**
 * This function will return `false` for any valid json primitive.
 * EG, 'true' -> false
 *     '123' -> false
 *     'null' -> false
 *     '"I'm a string"' -> false
 */
function tryParseJSONObject(jsonString) {
    try {
        const result = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (result && typeof result === 'object') {
            return result;
        }
    } catch (e) {
    // DO not pollute the console.log('tryParseJSONObject catch:', e);
    }

    return false;
}


export const isValidJsonStringRule = (fieldName, t): Rule[] => [() => ({
    validator(_, value) {
        if (!value || tryParseJSONObject(value)) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(t('common:validationMsg.invalidJsonString')));
    },
})];

export const isUniqueValue = ({ t, existingValues = [] } : { t: TFunction, existingValues: string[] }): Rule[] => [() => ({
    validator(_, value) {
        if (!value || !existingValues.includes(value)) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(`${t('common:validationMsg.valueAlreadyExist')}`));
    },
})];

export const isDifferentThanPreFilledValue = ({ t, prefilledValue = '' }): Rule[] => [() => ({
    validator(_, value) {
        if (!value || value !== prefilledValue) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(t('common:validationMsg.valueIsSameAsPrefilled')));
    },
})];

export const commonValidation = ({
    t,
    isRequired = false,
    maxLength = defaultMaxLength,
    isRequiredMessage = t('common:validationMsg.fieldMandatory'),
}): Rule[] => [
    ...(isRequired ? requiredRule(t, isRequiredMessage) : []),
    ...(whitespaceRule(t)),
    ...(maxLengthRule(maxLength, t)),
];

export const handleWhitespaceOnBlur = (event, fieldName, form) => {
    const trimmedValue = trimValue(event?.target?.value || '');
    // TODO handle array indexes
    if (Array.isArray(fieldName)) {
        const [objectName, objectKey] = fieldName;
        const object = form.getFieldValue(objectName);

        const updatedValue = {
            ...object,
            [objectKey]: trimmedValue,
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        form && form.setFieldsValue({ [`${objectName}`]: updatedValue });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    form && form.setFieldsValue({ [`${fieldName}`]: trimmedValue });
};


export const isValidPhoneNumberRuleLEGACY = (fieldName, t) => [({ getFieldValue }) => ({
    validator(_, value) {
        if (!value || isValidPhoneNumber(getFieldValue(fieldName)) === true) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('common:validationMsg.phoneInvalidFormat')));
    },
})];

export const isValidPhoneNumberRule = (t) => [() => ({
    validator(_, value) {
        if (!value || isValidPhoneNumber(value) === true) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(t('common:validationMsg.phoneInvalidFormat')));
    },
})];

export const isValidV4UuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;


export const isValidV4Uuid = (uuid = '') => isValidV4UuidRegex.test(uuid);

export default {
    trimValue,
    maxLengthRule,
    whitespaceRule,
    requiredRule,
    commonValidation,
    handleWhitespaceOnBlur,
    isValidIbanRule,
    isValidBicRule,
    isValidEmail,
    isMatchingGivenRegexRule,
    publicIpv4NoFtpWebURLRegex,
    isValidV4Uuid,
    isValidPhoneNumberRuleLEGACY,
    isValidPhoneNumberRule,
    isDifferentThanPreFilledValue,
};
