import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';

import {
    updateBusinessUser, updateBusinessUserSuccess, updateBusinessUserFailure,
    fetchBusinessUserDetails, fetchBusinessUserDetailsSuccess, fetchBusinessUserDetailsFailure,
    clearBusinessUserDetails,
} from './actions';


export const businessUserReducerName = 'businessUser';
export const businessUserDetailsPerLocationStoreKeyName = 'businessUserDetailsPerLocation';


const initialDetailsState = {
    companyId: undefined,
    [businessUserDetailsPerLocationStoreKeyName]: {}, // X XX 'pathname': {...standard }

    isLoadingUpdateBusinessUser: false,
};


const initialState = { ...initialDetailsState };

const handlers = {
    [fetchBusinessUserDetails]: (state, action) => {
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[businessUserDetailsPerLocationStoreKeyName], action);
    },
    [fetchBusinessUserDetailsSuccess]: (state, action) => {
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[businessUserDetailsPerLocationStoreKeyName], action);
    },
    [fetchBusinessUserDetailsFailure]: (state, action) => {
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[businessUserDetailsPerLocationStoreKeyName], action);
    },
    [clearBusinessUserDetails]: (state, action) => {
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(
            state[businessUserDetailsPerLocationStoreKeyName],
            action.payload.locationPathname,
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === businessUserReducerName
      && action.payload?.fieldName === businessUserDetailsPerLocationStoreKeyName
        ) {
            state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[businessUserDetailsPerLocationStoreKeyName] }, action);
        }
    },

    [updateBusinessUser]: (state) => {
        state.isLoadingUpdateBusinessUser = true;
    },
    [updateBusinessUserSuccess]: (state, action) => {
        state.isLoadingUpdateBusinessUser = false;
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[businessUserDetailsPerLocationStoreKeyName], action);
    },
    [updateBusinessUserFailure]: (state) => {
        state.isLoadingUpdateBusinessUser = false;
    },


    [clearCurrentUser]: () => initialState,
};


export default createReducer(initialState, handlers);
