import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';


import UserInformation from './UserInformation';


function UserDetails({
    t,
    accessControl,
    enhancedCurrentLocation,

    entityDetailsData,

    dispatchFetchUserDetails,
    dispatchClearUserDetails,
    dispatchSetQueryParams,
    dispatchDeleteUser,
    dispatchResendConfirmationEmail,
    dispatchOpenAddEditUserModal,
    dispatchBanUser,
}) {
    const { userId } = useParams();

    const contextEnforcedQueryParams = { userId };
    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);
    const queryParamsMeta = entityDetailsData?.queryParamsMeta;

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        baseUrlWithoutTabsSlugs,
        contextEnforcedQueryParams,
        enhancedCurrentLocation,

        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchUserDetails,
        dispatchClearEntityDetails: dispatchClearUserDetails,
        dispatchSetQueryParams,
    });


    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('User Details')}
            entityClientId={entityDetailsData?.entityDetails?.client_id}
            tabsConfig={[]}
        >
            <UserInformation
                t={t}
                isLoadingUserDetails={!isDetailsPageInitialStateDefined}
                accessControl={accessControl}
                enhancedCurrentLocation={enhancedCurrentLocation}
                queryParamsMeta={queryParamsMeta}
                entityDetailsData={entityDetailsData}
                dispatchBanUser={dispatchBanUser}
                dispatchDeleteUser={dispatchDeleteUser}
                dispatchFetchUserDetails={dispatchFetchUserDetails}
                dispatchOpenAddEditUserModal={dispatchOpenAddEditUserModal}
                dispatchResendConfirmationEmail={dispatchResendConfirmationEmail}
            />
        </StandardEntityDetailsWithTabs>
    );
}


UserDetails.propTypes = {
    accessControl: AccessControlProps,
    t: PropTypes.func.isRequired,

    entityDetailsData: PropTypes.shape({
        entityDetails: PropTypes.shape({}),
        isLoadingDetails: PropTypes.bool,
    }),

    dispatchFetchUserDetails: PropTypes.func,
    dispatchDeleteUser: PropTypes.func,
    dispatchBanUser: PropTypes.func,
    dispatchResendConfirmationEmail: PropTypes.func,
    dispatchOpenAddEditUserModal: PropTypes.func,
    dispatchClearUserDetails: PropTypes.func,
    dispatchSetQueryParams: PropTypes.func,

};


export default UserDetails;
