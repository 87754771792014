import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { deleteAccount } from 'redux/account/actions';
import { accountReducerName } from 'redux/account/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DeleteAccountButton from './DeleteAccountButton.layout';
import { DeleteAccountButtonDispatchProps, DeleteAccountButtonOwnProps, DeleteAccountButtonStateProps } from './DeleteAccountButton.types';


const mapStateToProps = (state: RootState): DeleteAccountButtonStateProps => ({ isLoadingDeleteAccount: state[accountReducerName].isLoadingDeleteAccount });

const mapDispatchToProps = (dispatch: Dispatch): DeleteAccountButtonDispatchProps => ({
    //
    dispatchDeleteAccount: (accountDetails, locationPathname) => dispatch(deleteAccount(accountDetails, locationPathname)),
});

const decorate = compose<ComponentType<DeleteAccountButtonOwnProps>>(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(DeleteAccountButton);

