import CardTypeTag from 'components/common/atoms/tags/CardTypeTag';

import { Col, Row, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


import Styled from './UnlinkCardModal.styled';


function UnlinkCardModal({
    card,
    meta,
    dispatchConfirmUnlinkCard,
    dispatchSetModalProps,
    t,
}) {
    const [form] = useForm();
    const onFinish = () => dispatchConfirmUnlinkCard(card.id, meta);

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);
    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
        >
            <div>
                <Styled.Warning>
                    <Styled.WarningIcon />
                    {t('modals.unlinkCard.warning')}
                </Styled.Warning>
                <Row gutter={24}>
                    <Col>
                        <div>
                            <strong>
                                {t('modals.unlinkCard.fields.cardId')}
                :
                            </strong>
                        </div>
                        <div><strong>{t('modals.unlinkCard.fields.accountId')}</strong></div>
                        <div><strong>{t('modals.unlinkCard.fields.cardType')}</strong></div>
                        <div><strong>{t('modals.unlinkCard.fields.token')}</strong></div>
                        <div><strong>{t('modals.unlinkCard.fields.maskedPan')}</strong></div>
                        <div><strong>{t('modals.unlinkCard.fields.embossName')}</strong></div>
                    </Col>

                    <Col>
                        <div>{card?.id || '-'}</div>
                        <div>{card?.account_id || '-'}</div>
                        <div>{card?.type ? <CardTypeTag cardType={card?.type} t={t} /> : '-'}</div>
                        <div>{card?.token || '-'}</div>
                        <div>{card?.pan_mask || '-'}</div>
                        <div>{card?.emboss_name || '-'}</div>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}


UnlinkCardModal.propTypes = {
    meta: PropTypes.shape({}).isRequired,
    card: PropTypes.shape({}),
    dispatchConfirmUnlinkCard: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default UnlinkCardModal;
