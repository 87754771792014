import StandardListTable from 'components/common/organisms/StandardListTable';
import { createAddBeneficiaryPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { BeneficiariesListProp } from 'constants/BeneficiariesModel';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { beneficiaryListQueryParams } from 'redux/beneficiaries/reducer';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createBeneficiariesListColumns } from './tableColumns';


function BeneficiariesList({
    ownerContext,

    // XXX  list state data
    enhancedCurrentLocation,
    listData,

    accessControl,
    dispatchDeleteBeneficiary,
    dispatchFetchBeneficiariesList,
    dispatchClearBeneficiariesList,
    dispatchRequestNavigation,
    dispatchSetQueryParams,
    t,
}) {
    const { memberId, companyId, clientId } = ownerContext;

    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ...(memberId > 0 ? { memberId } : {}),
        ...(companyId > 0 ? { companyId } : {}),
        ...(clientId > 0 ? { clientId } : {}),
    };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchBeneficiariesList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: beneficiaryListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchBeneficiariesList,
        dispatchClearItemsList: dispatchClearBeneficiariesList,
        dispatchSetQueryParams,
    });

    // 4. context & permission dependent optional B. list  button
    const buttons = useMemo(
        () => (accessControl.hasPermissions([
            PN.BENEFICIARIES.CREATE,
            PN.BENEFICIARIES.REQUIRED_DETAILS,
        ]) && !ownerContext.isInReadOnlyMode
            ? [{
                type: 'primary',
                text: t('buttons.addBeneficiary.text'),
                icon: <PlusOutlined />,
                onClick: () => dispatchRequestNavigation(createAddBeneficiaryPath(), { state: { ownerContext } }),
            }]
            : []), [accessControl, ownerContext],
    );


    // 5. standard render component
    return (
        <StandardListTable
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createBeneficiariesListColumns({
                t,
                enhancedCurrentLocation,
                dispatchDeleteBeneficiary,
                dispatchRequestNavigation,
                accessControl,
            })}
            filtersButtonsConfig={buttons}
        />
    );
}


BeneficiariesList.propTypes = {
    accessControl: AccessControlProps,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchDeleteBeneficiary: PropTypes.func.isRequired,
    dispatchFetchBeneficiariesList: PropTypes.func.isRequired,
    dispatchClearBeneficiariesList: PropTypes.func.isRequired,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: BeneficiariesListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    t: PropTypes.func.isRequired,
};

export default BeneficiariesList;

