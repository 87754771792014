import { createAction } from '@reduxjs/toolkit';


export const START_OFFBOARDING = 'START_OFFBOARDING';
export const START_OFFBOARDING_SUCCESS = 'START_OFFBOARDING_SUCCESS';
export const START_OFFBOARDING_FAILURE = 'START_OFFBOARDING_FAILURE';

export const startOffboarding = createAction(START_OFFBOARDING, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const startOffboardingSuccess = createAction(START_OFFBOARDING_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const startOffboardingFailure = createAction(START_OFFBOARDING_FAILURE);


export const STOP_OFFBOARDING = 'STOP_OFFBOARDING';
export const STOP_OFFBOARDING_SUCCESS = 'STOP_OFFBOARDING_SUCCESS';
export const STOP_OFFBOARDING_FAILURE = 'STOP_OFFBOARDING_FAILURE';

export const stopOffboarding = createAction(STOP_OFFBOARDING, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const stopOffboardingSuccess = createAction(STOP_OFFBOARDING_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const stopOffboardingFailure = createAction(STOP_OFFBOARDING_FAILURE);

export const FINALISE_OFFBOARDING = 'FINALISE_OFFBOARDING';
export const FINALISE_OFFBOARDING_SUCCESS = 'FINALISE_OFFBOARDING_SUCCESS';
export const FINALISE_OFFBOARDING_FAILURE = 'FINALISE_OFFBOARDING_FAILURE';

export const finaliseOffboarding = createAction(FINALISE_OFFBOARDING, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const finaliseOffboardingSuccess = createAction(FINALISE_OFFBOARDING_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const finaliseOffboardingFailure = createAction(FINALISE_OFFBOARDING_FAILURE);

