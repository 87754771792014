export const AuthorisationErrors = Object.freeze({
    PERMANENTLY_BLOCKED: 'Your account is locked due to multiple failed login attempts. For assistance, please contact your administrator.',

    //  if user fails login 3 times in a row backend throws this error
    TEMPORARILY_BLOCKED_FOR: 'User is temporarily locked for 3 minutes',

    // if user fails login 3 times backend throws this error
    TEMPORARILY_BLOCKED: 'User is temporarily locked',
    INVALID_CREDENTIALS: 'Invalid credentials.',
    CONFIRMATION_TOKEN_EXPIRED: 'jwt expired',
});


export default { AuthorisationErrors };
