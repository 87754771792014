import MembersList from 'components/common/templates/lists/MembersList';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';

import PropTypes from 'prop-types';
import React from 'react';


function Members({ t }) {
    return (
        <>
            <StandardPageHeading title={t('screenTitles.membersList')} />

            <StandardPageWrapper>
                <MembersList allowInactivePartners />
            </StandardPageWrapper>
        </>
    );
}

Members.propTypes = { t: PropTypes.func.isRequired };

export default Members;
