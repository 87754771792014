import styled from 'styled-components';


const SaveAndDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0,1fr) minmax(0,1fr);
  grid-column-gap: 30px;
  white-space: nowrap;
  margin-bottom: 20px;
`;


const SaveRoleAsRoleTemplateWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0,1fr);
  grid-column-gap: 10px;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 20px;
`;

const RoleNameWrapper = styled.div`
  min-height: 80px;
`;

const SelectedPermissionsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
`;


export default {
    SaveAndDetailsWrapper,
    SaveRoleAsRoleTemplateWrapper,
    RoleNameWrapper,
    SelectedPermissionsWrapper,
};
