import { createAction } from '@reduxjs/toolkit';


export const fetchCardSecure3dData = createAction('card3dSecure/fetchCardSecure3dData', (cardId) => ({ payload: cardId }));
export const fetchCardSecure3dDataSuccess = createAction('card3dSecure/fetchCardSecure3dDataSuccess', (secure3dData) => ({ payload: secure3dData }));
export const fetchCardSecure3dDataFailure = createAction('card3dSecure/fetchCardSecure3dDataFailure');


export const fetchCardSecure3dActivityLogs = createAction('card3dSecure/fetchCardSecure3dActivityLogs', (cardId) => ({ payload: cardId }));
export const fetchCardSecure3dActivityLogsSuccess = createAction('card3dSecure/fetchCardSecure3dActivityLogsSuccess', (secure3dActivity) => ({ payload: secure3dActivity }));
export const fetchCardSecure3dActivityLogsFailure = createAction('card3dSecure/fetchCardSecure3dActivityLogsFailure');


export const updateCardSecure3dSettings = createAction(
    'card3dSecure/updateCardSecure3dSettings',
    (cardId, requestPayload) => ({ payload: { cardId, requestPayload } }),
);
export const updateCardSecure3dSettingsSuccess = createAction(
    'card3dSecure/updateCardSecure3dSettingsSuccess',
    (updatedData) => ({ payload: updatedData }),
);
export const updateCardSecure3dSettingsFailure = createAction('card3dSecure/updateCardSecure3dSettingsFailure');


export const openEditCardSecure3dPhoneNumberModal = createAction(
    'card3dSecure/openEditCardSecure3dPhoneNumberModal',
    (cardId, phoneNumber) => ({ payload: { cardId, phoneNumber } }),
);

export const submitCardSecure3dPhoneNumberModal = createAction(
    'card3dSecure/submitCardSecure3dPhoneNumberModal',
    (cardId, newPhoneNumber) => ({ payload: { cardId, newPhoneNumber } }),
);

export const clearCardSecure3d = createAction('card3dSecure/clearCardSecure3d');
