import { scrollBar } from 'assets/styles/scrollbar';
import { MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';

import styled, { css } from 'styled-components';


const FormStepWrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  
  .ant-tabs {
    padding: 0 !important;
    background-color: transparent !important;
  }
`;

const StepWithSubStepsSubStepIndicatorAndContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 16px;
  height: 100%;
`;

const StepWithSubStepsOuterWrapper = styled.div`
  position: relative;
`;

const StepWithSubStepsWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  ${scrollBar};
`;

const StepWithSubStepsInnerWrapper = styled.div`
  position: relative;
  padding: 0 16px;
  background-color: #fff;
  min-height: 100%;
`;

const StepWithoutSubStepsOuterWrapper = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
`;

const StepWithoutSubStepsWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  ${scrollBar};
`;

const StepWithoutSubStepsInnerWrapper = styled.div`
  position: relative;
  padding: 0 16px;
  background-color: #fff;
  min-height: 100%;
`;


const DummyTabsBar = styled.div`
    display: none;
`;


const StandardFormSectionWrapper = styled.div`
  display: grid;
  
  ${({ $sectionsLayout }) => $sectionsLayout === MultiStepFormStepSectionTypes.EVEN_COLUMNS && css`
    grid-template-columns: repeat(${({ $colCount = 1 }) => $colCount}, minmax(0, 1fr));
    grid-column-gap: 51px;
    
    & > div + div {
      position: relative;
      
      &:before {
        content:'';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -26px;
        border-left: 1px solid #F0F0F0;
      }
    }
  `};

  ${({ $sectionsLayout }) => $sectionsLayout === MultiStepFormStepSectionTypes.AUTO_ROWS && css`
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-row-gap: 31px;
    
    & > div + div {
      position: relative;
      
      &:before {
        content:'';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -16px;
        border-left: 1px solid #F0F0F0;
      }
    }
  `};

  ${({ $sectionsLayout }) => $sectionsLayout === MultiStepFormStepSectionTypes.GRID && css`
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-column-gap: 31px;
    
    & > div + div {
      position: relative;
      
      &:before {
        content:'';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -16px;
        border-left: 1px solid #F0F0F0;
      }
    }
  `};

`;


export default {
    FormStepWrapper,

    StepWithSubStepsSubStepIndicatorAndContentWrapper,
    StepWithSubStepsOuterWrapper,
    StepWithSubStepsWrapper,
    StepWithSubStepsInnerWrapper,

    StepWithoutSubStepsOuterWrapper,
    StepWithoutSubStepsWrapper,
    StepWithoutSubStepsInnerWrapper,

    DummyTabsBar,
    StandardFormSectionWrapper,
};
