import StandardListTable from 'components/common/organisms/StandardListTable';
import amendTableColumnFilterValue from 'components/common/organisms/StandardListTable/StandardListTable.helpers';
import { optionalTransactionsScopeFilters } from 'constants/TransactionModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { QueryParams } from 'models/app/common';
import { defaultTransactionsQueryParams } from 'redux/transactions/reducer';

import { TransactionStatus } from '@manigo/manigo-domain-typings';
import React, { useMemo, JSX } from 'react';


import { TransactionsListProps } from './TransactionsList.types';
import { createTransactionsTableColumns } from './createTableColumns';


function TransactionsList({
    currentUserClientId,
    // context enforced queryParams
    showPartnerSelect,
    enforcePendingOnly,
    allowInactivePartners = false,
    hideCurrencyFilter,
    clientId,
    memberId,
    companyId,
    accountId,
    cardId,
    beneficiaryUuid,
    businessUserId,

    // list state data
    enhancedCurrentLocation,
    listData,

    // other mapped props
    accessControl,
    isClientActive,
    transactionsFilters,
    currencies,
    dispatchRequestNavigation,
    dispatchSetQueryParams,
    dispatchGetTransactions,
    dispatchClearTransactionsList,
    t,
}: TransactionsListProps): JSX.Element {
    // 1. context-specific / ownProps-driven list configuration
    const isPartnerActive = useMemo(
        () => (isClientActive && clientId ? isClientActive(clientId) : undefined),
        [clientId],
    );
    const contextEnforcedQueryParams = useMemo(
        // TODO reduce complexity
        // eslint-disable-next-line complexity
        () => ({
            ...(memberId && memberId > 0 ? { memberId } : {}),
            ...(companyId && companyId > 0 ? { companyId } : {}),
            ...(accountId && accountId > 0 ? { accountId } : {}),
            ...(cardId && cardId > 0 ? { cardId } : {}),
            ...(beneficiaryUuid && beneficiaryUuid?.length > 0 ? { beneficiaryUuid } : {}), // uuid
            ...(businessUserId && businessUserId > 0 ? { businessUserId } : {}),
            ...(clientId && clientId > 0 ? { clientId } : {}),
            ...(showPartnerSelect ? { clientId: currentUserClientId } : {}),
            ...(enforcePendingOnly && { status: [TransactionStatus.Pending], amlReview: true }), // status as array because corresponding filter exist
            ...(clientId && clientId > 0 && !isPartnerActive ? { includeInactivePartners: true } : { }),
        }),
        [enforcePendingOnly, memberId, companyId, accountId, cardId, beneficiaryUuid, businessUserId, clientId, isPartnerActive],
    );

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (incomingNewQueryParams) => {
        const { filters, ...rest } = incomingNewQueryParams;

        const updatedQueryParams = {
            ...rest,
            ...amendTableColumnFilterValue('status', filters),
            ...amendTableColumnFilterValue('transactionScope', filters),
            ...amendTableColumnFilterValue('originalCurrency', filters),

            ...(enforcePendingOnly ? { status: contextEnforcedQueryParams.status } : {}),
        };

        const newQueryParams: QueryParams = {
            ...listData?.queryParams,
            ...updatedQueryParams,
        };

        dispatchSetQueryParams(newQueryParams, enhancedCurrentLocation?.pathname);
        dispatchGetTransactions(newQueryParams, enhancedCurrentLocation?.pathname);
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultTransactionsQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchGetTransactions,
        dispatchClearItemsList: dispatchClearTransactionsList,
        dispatchSetQueryParams,
    });


    // 4. context & permission dependent optional account list filters & buttons

    const onShowCardVerificationTransactionsChange = (event) => {
        fetchData({
            ...listData?.queryParams,
            showCardVerification: !!event?.target?.checked,
            offset: 0,
            ...(!event?.target?.checked && optionalTransactionsScopeFilters.includes(`${listData?.queryParams?.transactionScope?.[0]}`)
                ? { transactionScope: null }
                : {}
            ),
        });
    };

    const additionalFilters = useMemo(
        () => [
            {
                type: 'checkbox',
                placeholder: 'Show card verification transactions',
                value: listData?.queryParams?.showCardVerification,
                onChange: onShowCardVerificationTransactionsChange,
            },
        ], [listData, onShowCardVerificationTransactionsChange],
    );

    return (
        <StandardListTable
            rowKey="transactionUuid"
            onUpdate={fetchData}
            showClientSelect={showPartnerSelect && accessControl.isSuperAdmin} // XXX allowed - client filter on global transactions list
            allowAllClients={false}
            items={listData?.items}
            showIncludeInactivePartners={allowInactivePartners && accessControl.isSuperAdmin} // XXX allowed - client filter on global transactions list
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createTransactionsTableColumns({
                accessControl,
                enforcePendingOnly,
                hideCurrencyFilter,
                hidePartnerColumn: !showPartnerSelect,
                dispatchRequestNavigation,
                queryParams: listData?.queryParams,
                currencies,
                t,
                transactionsFilters,
            })}
            additionalFiltersConfig={additionalFilters}
        />
    );
}

export default TransactionsList;

