import { HttpService } from 'services/http';


export const fetchPromotionUuidDetails = (promotionUuid) => HttpService.get(`/v2/promotions/${promotionUuid}`);

export const fetchClientIdPromotions = (clientId) => HttpService.get(`/v2/clients/${clientId}/promotions`);

export const removePromotionUuid = (promotionUuid) => HttpService.delete(`/v2/promotions/${promotionUuid}`);

export const createPromotion = (requestPayload) => HttpService.post('/v2/promotions', requestPayload);

export const enablePromotionUuid = (promotionUuid) => HttpService.post(`/v2/promotions/${promotionUuid}/enable`);

export const disablePromotionUuid = (promotionUuid) => HttpService.post(`/v2/promotions/${promotionUuid}/disable`);

export const fetchPromotionUuidGroups = (promotionUuid) => HttpService.post(`/v2/promotions/${promotionUuid}/groups`);


export default {
    fetchPromotionUuidDetails,
    fetchClientIdPromotions,
    removePromotionUuid,
    createPromotion,
    enablePromotionUuid,
    disablePromotionUuid,
    fetchPromotionUuidGroups,
};
