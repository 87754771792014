import ModalName from 'constants/Modals';
import {
    fetchCardSecure3dActivityLogsFailure,
    fetchCardSecure3dActivityLogsSuccess,
    fetchCardSecure3dDataFailure,
    fetchCardSecure3dDataSuccess, updateCardSecure3dSettingsFailure, updateCardSecure3dSettingsSuccess,
} from 'redux/card-secure3d/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const onFetchCardSecure3dData = (action$, state$, { cardSecure3d }) => action$.pipe(
    ofType('card3dSecure/fetchCardSecure3dData'),
    switchMap(({ payload }) => from(cardSecure3d.fetchCardSecure3dStatus(payload)).pipe(
        switchMap((response) => of(fetchCardSecure3dDataSuccess(response.data))),
        catchError(() => of(fetchCardSecure3dDataFailure())),
    )),
);

export const onFetchCardSecure3dActivityLogs = (action$, state$, { cardSecure3d }) => action$.pipe(
    ofType('card3dSecure/fetchCardSecure3dActivityLogs'),
    switchMap(({ payload }) => from(cardSecure3d.fetchCardSecure3dActivityLog(payload)).pipe(
        switchMap((response) => of(fetchCardSecure3dActivityLogsSuccess(response.data))),
        catchError(() => of(fetchCardSecure3dActivityLogsFailure())),
    )),
);

export const onOpenEditCardSecure3dPhoneNumberModal = (action$, _, { i18n }) => action$.pipe(
    ofType('card3dSecure/openEditCardSecure3dPhoneNumberModal'),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.EDIT_PHONE_NUMBER,
            modalProps: {
                title: i18n.t('cards:modals.editSecure3DPhoneNumber.title'),
                cardId: payload.cardId,
                phoneNumber: payload.phoneNumber,
            },
        }),
    )),
);

export const onSubmitCardSecure3dPhoneNumberModal = (action$, state$, { cardSecure3d }) => action$.pipe(
    ofType('card3dSecure/submitCardSecure3dPhoneNumberModal'),
    switchMap(({ payload }) => from(cardSecure3d.updateCardSecure3dStatus(payload.cardId, { phoneNumber: payload?.newPhoneNumber })).pipe(
        switchMap((response) => of(
            updateCardSecure3dSettingsSuccess(response.data),
            hideModal(),
        )),
        catchError(() => of(
            updateCardSecure3dSettingsFailure(),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);


export const onUpdateCardSecure3dSettings = (action$, state$, { cardSecure3d }) => action$.pipe(
    ofType('card3dSecure/updateCardSecure3dSettings'),
    switchMap(({ payload }) => from(cardSecure3d.updateCardSecure3dStatus(payload.cardId, payload.requestPayload)).pipe(
        switchMap((response) => of(updateCardSecure3dSettingsSuccess(response.data))),
        catchError(() => of(updateCardSecure3dSettingsFailure())),
    )),
);

export default [
    onFetchCardSecure3dData,
    onFetchCardSecure3dActivityLogs,
    onOpenEditCardSecure3dPhoneNumberModal,
    onSubmitCardSecure3dPhoneNumberModal,
    onUpdateCardSecure3dSettings,
];
