import FAQ from 'components/common/molecules/FAQ';
import ChangeLog from 'components/common/templates/ChangeLog';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { WL_CONFIG } from 'config/config';

import React from 'react';

import Styled from './Dashboard.styled';


function Dashboard() {
    return (
        <>
            <StandardPageHeading
                showGoBack={false}
                title="Dashboard! - Welcome"
            />

            <StandardPageWrapper>
                <Styled.DashboardHeading>
                    {`Greetings all-mighty ${WL_CONFIG.brandShortName} Admin User!`}
                </Styled.DashboardHeading>

                <Styled.DashboardColumns>
                    <FAQ />
                    <ChangeLog />
                </Styled.DashboardColumns>

            </StandardPageWrapper>
        </>
    );
}

export default Dashboard;
