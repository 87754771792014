import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { fetchFailedWebhookErrorsList, clearFailedWebhookErrorsList } from 'redux/failed-webhook-history/actions';
import {
    failedWebhookErrorsListPerLocationStoreKeyName,
    failedWebhookErrorsListReducerName,
} from 'redux/failed-webhook-history/reducer';
import { showViewJsonModal } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import FailedWebhookHistoryList from './FailedWebhookHistoryList.layout';
import {
    FailedWebhookHistoryListDispatchProps, FailedWebhookHistoryListOwnProps,
    FailedWebhookHistoryListStateProps,
} from './FailedWebhookHistoryList.types';


const mapStateToProps = (state: RootState): FailedWebhookHistoryListStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: failedWebhookErrorsListReducerName,
        listsByLocationStoreKeyName: failedWebhookErrorsListPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch: Dispatch): FailedWebhookHistoryListDispatchProps => ({
    dispatchFetchFailedWebhookErrorsList: (queryParams, locationPathname) => dispatch(fetchFailedWebhookErrorsList(queryParams, locationPathname)),
    dispatchClearFailedWebhookErrorsList: (locationPathname) => dispatch(clearFailedWebhookErrorsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: failedWebhookErrorsListReducerName,
        fieldName: failedWebhookErrorsListPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchShowViewJsonModal: (dataToDisplay, modalTitle) => dispatch(showViewJsonModal(dataToDisplay, modalTitle)),
});

const decorate = compose<ComponentType<FailedWebhookHistoryListOwnProps>>(
    withTranslation('webhooks'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(FailedWebhookHistoryList);
