import BeneficiariesDropdown from 'components/common/molecules/custom-dropdowns/BeneficiariesDropdown';
import BeneficiaryInfoLayout from 'components/common/organisms/BeneficiaryInfoLayout';
import { renderStandardFormControlFromItemLabel } from 'components/common/organisms/StandardFormItem/StandardFormItem.helpers';

import { Button, Form, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { BeneficiarySelectionProps } from './BeneficiarySelection.types';
import BackButton from '../../BackButton';


function BeneficiarySelection({
    t,
    dispatchFetchAllBeneficiaries,
    dispatchSetSelectedBeneficiaryDetails,
    beneficiariesList,
    beneficiaryDetails,
    isLoadingBeneficiaries,
    accountDetails,
}: BeneficiarySelectionProps) {
    useEffect(() => {
        if (beneficiariesList.length === 0) {
            dispatchFetchAllBeneficiaries();
        }
    }, []);

    const handleSelection = (value) => {
        dispatchSetSelectedBeneficiaryDetails(beneficiariesList.find((beneficiary) => beneficiary.id === value));
    };

    const filteredBeneficiaries = useMemo(
        () => (beneficiariesList || []).filter((beneficiary) => beneficiary?.currency === accountDetails?.currency), [beneficiariesList],
    );

    // XXX minHeight: "350px" - magic number, space for dropdown
    return (
        <>
            <div style={{ maxWidth: '500px', minHeight: '350px' }}>
                <BeneficiariesDropdown
                    beneficiariesList={filteredBeneficiaries}
                    isLoadingBeneficiaries={isLoadingBeneficiaries}
                    fieldName="beneficiaryId"
                    fieldLabel={renderStandardFormControlFromItemLabel({
                        fieldConfig: {
                            label: t('transactions:outgoingTransfer.steps.beneficiarySelection.fields.beneficiary.text'),
                            infoTooltip: t('transactions:outgoingTransfer.steps.beneficiarySelection.fields.beneficiary.tooltip', { currency: accountDetails?.currency }),
                        },
                        t,
                    })}
                    onChange={handleSelection}
                    isRequired
                />

                {beneficiaryDetails && (
                    <BeneficiaryInfoLayout
                        beneficiaryDetails={beneficiaryDetails}
                    />
                )}
            </div>

            <Row key="form buttons" style={{ marginTop: 20 }}>
                <BackButton />
                <Form.Item>
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </>
    );
}

export default BeneficiarySelection;
