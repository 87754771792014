import { standardPageSize } from 'config/config';
import { companyRootRoutePath, businessUsersRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchBusinessUsersListFailure, fetchBusinessUsersListSuccess, fetchBusinessUsersList,
    addBusinessUser, addBusinessUserSuccess, addBusinessUserFailure, clearBusinessUsersList,
} from './actions';


export const businessUsersReducerName = 'businessUsers';
export const businessUsersListsPerLocationStoreKeyName = 'businessUsersListsPerLocation';


export const defaultBusinessUsersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'business_user_id',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
};

const initialListState = {
    companyId: undefined,

    [businessUsersListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    isLoadingAddBusinessUser: false,
};


const initialState = { ...initialListState };

const handlers = {
    [addBusinessUser]: (state, action) => {
        state.isLoadingAddBusinessUser = true;
        state.companyId = action.payload.companyId;
    },
    [addBusinessUserSuccess]: (state) => {
        state.isLoadingAddBusinessUser = false;
    },
    [addBusinessUserFailure]: (state) => {
        state.isLoadingAddBusinessUser = false;
    },

    [fetchBusinessUsersList]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = updateStandardLists(state[businessUsersListsPerLocationStoreKeyName], action);
    },
    [fetchBusinessUsersListSuccess]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[businessUsersListsPerLocationStoreKeyName], action);
    },
    [fetchBusinessUsersListFailure]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[businessUsersListsPerLocationStoreKeyName], action);
    },
    [clearBusinessUsersList]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[businessUsersListsPerLocationStoreKeyName], action.payload.locationPathname);
    },

    [preformNavigationSuccess]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[businessUsersListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [businessUsersRouteSegmentPath],
                observedRootPaths: [companyRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === businessUsersReducerName
      && action.payload?.fieldName === businessUsersListsPerLocationStoreKeyName
        ) {
            state[businessUsersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[businessUsersListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCurrentUser]: () => initialState,
};


export default createReducer(initialState, handlers);
