import ModalName from 'constants/Modals';
import { SHOW_VIEW_JSON_MODAL, showModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


export const onShowViewJsonModal = (action$) => action$.pipe(
    ofType(SHOW_VIEW_JSON_MODAL),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.VIEW_JSON_MODAL,
            modalProps: {
                title: payload.modalTitle,
                dataToDisplay: payload.dataToDisplay,
                okButtonProps: { hidden: true },
                cancelText: 'Close',
            },
        }),
    )),
);

export default { onShowViewJsonModal };
