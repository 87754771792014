import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import { splitAccountIdentifications } from 'components/common/organisms/AccountInfoLayout/AccountInfoLayout.helpers';
import {
    createAccountDetailsAmountFieldsConfig,
    createAccountDetailsPersonalFieldsConfig,
    createAccountDetailsSystemFieldsConfig,
} from 'components/common/organisms/AccountInfoLayout/tabs/GeneralAccountData/GeneralAccountsData.helpers';
import CommonSection from 'components/common/organisms/CommonSection';
import { AccountIdentificationCategory } from 'constants/AccountModel';
import { PN } from 'constants/PermissionsModel';

import { Skeleton, Row } from 'antd';
import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { AccountInfoProps } from './AccountInfo.types';
import DeleteAccountButton from './DeleteAccountButton';
import SetAsPrimaryButton from './SetAsPrimaryButton';
import { createAccountDetailsAccountIdentificationsFieldsConfig } from '../AccountDetailsPage.helpers';


function AccountInfo({
    locationPathname,
    areDetailsReady,
    accessControl,
    accountDetails,
    onRefresh,
    queryParamsMeta,
    isLoading,
    t,
}: AccountInfoProps) {
    const accountIdentifications = useMemo(
        () => splitAccountIdentifications(t, accountDetails?.accountIdentifications),
        [accountDetails?.accountIdentifications],
    );

    const fieldsConfigCol1 = useMemo(
        () => createAccountDetailsPersonalFieldsConfig(accountDetails, t),
        [accountDetails],
    );

    const fieldsConfigCol2 = useMemo(
        () => createAccountDetailsAmountFieldsConfig(accountDetails, t),
        [accountDetails],
    );

    const fieldsConfigCol3 = useMemo(
        () => createAccountDetailsSystemFieldsConfig(accountDetails, t),
        [accountDetails?.id],
    );

    const localAccountIdentificationsFieldsConfig = useMemo(
        () => createAccountDetailsAccountIdentificationsFieldsConfig(accountIdentifications?.[`${AccountIdentificationCategory.LOCAL}`]),
        [accountIdentifications],
    );

    const globalAccountIdentificationsFieldsConfig = useMemo(
        () => createAccountDetailsAccountIdentificationsFieldsConfig(accountIdentifications?.[`${AccountIdentificationCategory.GLOBAL}`]),
        [accountIdentifications],
    );

    const canDelete = accessControl.hasPermission(PN.ACCOUNTS.DELETE);
    const canUpdate = accessControl.hasPermission(PN.ACCOUNTS.UPDATE);

    const isPrimaryAccount = useMemo(() => (accountDetails?.isPrimary || false), [accountDetails?.isPrimary]);

    const isDeletable = useMemo(() => (accountDetails?.accountDeletable || false), [accountDetails?.accountDeletable]);


    return (
        <Skeleton
            loading={!areDetailsReady || isLoading}
            active
            style={{ minHeight: '456px' }}
        >
            <DetailsSectionsWrapper>
                <Row gutter={24}>
                    <CommonSection
                        sectionLabel="Account details"
                        fieldsConfig={fieldsConfigCol1}
                    />
                    <CommonSection
                        sectionLabel="Balances"
                        fieldsConfig={fieldsConfigCol2}
                    />
                    <CommonSection
                        sectionLabel="System details"
                        fieldsConfig={fieldsConfigCol3}
                    />

                    {accountIdentifications?.[`${AccountIdentificationCategory.LOCAL}`]?.length > 0 && (
                        <CommonSection
                            sectionLabel="Local details"
                            fieldsConfig={localAccountIdentificationsFieldsConfig}
                        />
                    )}

                    {accountIdentifications?.[`${AccountIdentificationCategory.GLOBAL}`]?.length > 0 && (
                        <CommonSection
                            sectionLabel="Global details"
                            fieldsConfig={globalAccountIdentificationsFieldsConfig}
                        />
                    )}
                </Row>

                <OptionalButtonsWrapper>
                    {canUpdate && !isPrimaryAccount
                        ? (
                            <SetAsPrimaryButton
                                accountDetails={accountDetails}
                                locationPathname={locationPathname}
                            />
                        )
                        : null}

                    {canDelete
                        ? (
                            <DeleteAccountButton
                                isDeletable={isDeletable}
                                isPrimaryAccount={isPrimaryAccount}
                                accountDetails={accountDetails}
                                locationPathname={locationPathname}
                            />
                        )
                        : null}
                </OptionalButtonsWrapper>


                <QueryParamsMetaInfo
                    detailsContext
                    onListRefresh={onRefresh}
                    queryParams={{ accountId: accountDetails?.id }}
                    queryParamsMeta={queryParamsMeta}
                />
            </DetailsSectionsWrapper>
        </Skeleton>
    );
}

export default withTranslation('accounts')(AccountInfo);
