import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { IndustriesListProps } from 'constants/IndustriesModel';
import { filterOptions } from 'utils/filterOptions';
import { createIndustryOptions } from 'utils/options-creators';

import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

// XXX Customized form controls can be used but controls must follow this convention:
// - It has a controlled property `value` or other name which is equal to the value of `valuePropName`.
// - It has event `onChange` or an event which name is equal to the value of `trigger`.

function IndustriesSelect({
    listData,

    placeholder,
    initialValue,
    isMultiValue,
    allowClear,
    value,
    onChange,
    isDisabled,
}) {
    const [localValue, setLocalValue] = useState(initialValue);

    const industryOptions = useMemo(
        () => createIndustryOptions(listData?.items || []),
        [listData],
    );

    const handleOnChange = (newValue) => {
        setLocalValue(newValue);
        onChange(newValue);
    };

    return (
        <Select
            onChange={handleOnChange}
            mode={isMultiValue ? 'multiple' : undefined}
            allowClear={allowClear}
            className="countries-select"
            placeholder={placeholder}
            optionFilterProp="label"
            filterOption={filterOptions}
            loading={listData?.isLoadingList}
            value={initialValue || value || localValue}
            disabled={isDisabled}
        >
            {industryOptions}
        </Select>
    );
}

IndustriesSelect.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: IndustriesListProps,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
};

export default IndustriesSelect;
