import { Dispatch } from 'models/meta/action';
import { fetchAllBeneficiaries, setSelectedBeneficiaryDetails } from 'redux/outgoing-transfer/actions';
import { outgoingTransferReducerName } from 'redux/outgoing-transfer/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import BeneficiarySelection from './BeneficiarySelection.layout';
import { BeneficiarySelectionDispatchProps, BeneficiarySelectionStateProps } from './BeneficiarySelection.types';


const mapStateToProps = (state: RootState): BeneficiarySelectionStateProps => ({
    beneficiariesList: state[outgoingTransferReducerName].beneficiariesList,
    beneficiaryDetails: state[outgoingTransferReducerName].beneficiaryDetails,
    isLoadingBeneficiaries: state[outgoingTransferReducerName].isLoadingBeneficiaries,
    accountDetails: state[outgoingTransferReducerName].accountDetails,
});

const mapDispatchToProps = (dispatch: Dispatch): BeneficiarySelectionDispatchProps => ({
    dispatchFetchAllBeneficiaries: () => dispatch(fetchAllBeneficiaries()),
    dispatchSetSelectedBeneficiaryDetails: (beneficiaryDetails) => dispatch(setSelectedBeneficiaryDetails(beneficiaryDetails)),
});


const decorate = compose<ComponentType>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('beneficiaries'),
);

export default decorate(BeneficiarySelection);
