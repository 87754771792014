import { MultiStepFormStepChangeDirections, MultiStepFormCommonStepsDefinitions } from 'constants/MultiStepsFormModel';
import { setMultiStepsFormStep, setMultiStepsFormSubStep } from 'redux/multi-steps-form/actions';

import { EMPTY, of } from 'rxjs';


export function currentStepNumberInAvailableSteps(availableSteps = [], currentStep = '') {
    return availableSteps?.findIndex((value) => value === currentStep);
}
export function getLastStepExceptSuccessFromAvailableSteps(availableSteps = []) {
    return availableSteps?.filter((stepName) => stepName !== MultiStepFormCommonStepsDefinitions.SUCCESS)?.slice(-1)?.[0];
}


export const mapCurrentMultiStepStepToNumber = (
    currentMultiStepsFormStep,
    availableSteps,
) => {
    const stepNumber = currentStepNumberInAvailableSteps(availableSteps, currentMultiStepsFormStep);

    const handlers = [
        {
            predicate: () => stepNumber >= 0,
            handler: () => stepNumber,
        },
        {
            predicate: () => true,
            handler: () => -1,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createOnRequestSetMultiStepsFormStepActions = ({
    currentStep,
    direction,
    locationPathname,
    availableSteps = [],
}) => {
    const stepNumber = currentStepNumberInAvailableSteps(availableSteps, currentStep);
    const availableSubStepsLength = availableSteps?.length;

    const handlers = [
        {
            predicate: () => stepNumber > 0 && direction === MultiStepFormStepChangeDirections.BACK,
            handler: () => of(setMultiStepsFormStep(availableSteps[stepNumber - 1], locationPathname)),
        },
        {
            predicate: () => stepNumber < availableSubStepsLength && direction === MultiStepFormStepChangeDirections.FORWARD,
            handler: () => of(setMultiStepsFormStep(availableSteps[stepNumber + 1], locationPathname)),
        },

        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];
    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export const createOnRequestSetMultiStepsFormSubStepActions = ({
    parentStep,
    currentSubStep,
    direction,
    locationPathname,
    availableSubSteps = [],
}) => {
    const currentSubStepNumber = currentStepNumberInAvailableSteps(availableSubSteps, currentSubStep);
    const availableSubStepsLength = availableSubSteps?.length;

    const handlers = [
        {
            predicate: () => currentSubStepNumber > 0 && direction === MultiStepFormStepChangeDirections.BACK,
            handler: () => of(setMultiStepsFormSubStep(
                parentStep,
                availableSubSteps[currentSubStepNumber - 1],
                locationPathname,
            )),
        },
        {
            predicate: () => currentSubStepNumber < availableSubStepsLength && direction === MultiStepFormStepChangeDirections.FORWARD,
            handler: () => of(setMultiStepsFormSubStep(
                parentStep,
                availableSubSteps[currentSubStepNumber + 1],
                locationPathname,
            )),
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];
    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export default {
    mapCurrentMultiStepStepToNumber,
    createOnRequestSetMultiStepsFormStepActions,
    createOnRequestSetMultiStepsFormSubStepActions,
};
