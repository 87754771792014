import {
    CLEAR_PROMOTION_DETAILS, DISABLE_PROMOTION, DISABLE_PROMOTION_FAILURE, DISABLE_PROMOTION_SUCCESS, ENABLE_PROMOTION, ENABLE_PROMOTION_FAILURE, ENABLE_PROMOTION_SUCCESS,
    FETCH_PROMOTION_DETAILS,
    FETCH_PROMOTION_DETAILS_FAILURE,
    FETCH_PROMOTION_DETAILS_SUCCESS,
    REMOVE_PROMOTION, REMOVE_PROMOTION_FAILURE, REMOVE_PROMOTION_FROM_GROUP, REMOVE_PROMOTION_FROM_GROUP_FAILURE, REMOVE_PROMOTION_FROM_GROUP_SUCCESS, REMOVE_PROMOTION_SUCCESS,
} from 'redux/promotion/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchPromotionDetails = createAction(FETCH_PROMOTION_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchPromotionDetailsSuccess = createAction(
    FETCH_PROMOTION_DETAILS_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }),
);
export const fetchPromotionDetailsFailure = createAction(FETCH_PROMOTION_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const clearPromotionDetails = createAction(CLEAR_PROMOTION_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));


export const removePromotion = createAction(
    REMOVE_PROMOTION,
    (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }),
);
export const removePromotionSuccess = createAction(REMOVE_PROMOTION_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const removePromotionFailure = createAction(REMOVE_PROMOTION_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const removePromotionFromGroup = createAction(
    REMOVE_PROMOTION_FROM_GROUP,
    (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }),
);
export const removePromotionFromGroupSuccess = createAction(REMOVE_PROMOTION_FROM_GROUP_SUCCESS, (locationPathname) => ({ payload: { locationPathname } }));
export const removePromotionFromGroupFailure = createAction(REMOVE_PROMOTION_FROM_GROUP_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const disablePromotion = createAction(DISABLE_PROMOTION, (promotionUuid, locationPathname) => ({ payload: { promotionUuid, locationPathname } }));
export const disablePromotionSuccess = createAction(DISABLE_PROMOTION_SUCCESS, (promotionUuid, locationPathname) => ({ payload: { promotionUuid, locationPathname } }));
export const disablePromotionFailure = createAction(DISABLE_PROMOTION_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const enablePromotion = createAction(ENABLE_PROMOTION, (promotionUuid, locationPathname) => ({ payload: { promotionUuid, locationPathname } }));
export const enablePromotionSuccess = createAction(ENABLE_PROMOTION_SUCCESS, (promotionUuid, locationPathname) => ({ payload: { promotionUuid, locationPathname } }));
export const enablePromotionFailure = createAction(ENABLE_PROMOTION_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
