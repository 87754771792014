import { createClientDetailsPath } from 'config/routes.helpers';
import { addAccount } from 'redux/accounts/actions';
import { fetchClientDetails } from 'redux/client/actions';
import { clientDetailsPerLocationStoreKeyName, clientReducerName } from 'redux/client/reducer';
import { fetchClientProducts } from 'redux/client-products/actions';
import { clientProductsListsPerLocationStoreKeyName, clientProductsReducerName } from 'redux/client-products/reducer';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddAccountModal from './AddAccountModal.layout';


const mapStateToProps = (state, ownProps) => {
    const entityDetailsData = getStandardEntityDetailsData({
        state,
        reducerName: clientReducerName,
        fieldName: clientDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
        customLocation: createClientDetailsPath(ownProps?.config?.clientId),
    });
    return {
        clientConfiguration: entityDetailsData?.entityDetails?.configuration,
        currenciesListsData: getStandardListData({
            state,
            reducerName: currenciesReducerName,
            listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
            useGlobalLocation: true,
        }),
        enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
        listData: getStandardListData({
            state,
            reducerName: clientProductsReducerName,
            listsByLocationStoreKeyName: clientProductsListsPerLocationStoreKeyName,
        }),
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchClientById: (queryParams, locationPathname) => dispatch(fetchClientDetails(queryParams, locationPathname)),
    dispatchFetchClientProducts: (queryParams, locationPathname) => dispatch(fetchClientProducts(queryParams, locationPathname)),
    dispatchAddAccount: (ownerType, formData, locationPathname) => dispatch(addAccount(ownerType, formData, locationPathname)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});


const decorate = compose(
    withTranslation('accounts'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddAccountModal);
