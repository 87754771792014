import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';

import { Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createGlobalListPageTabsConfig } from './GlobalListsPage.helpers';


function GlobalListsPage({
    enhancedCurrentLocation,
    t,
}) {
    // XXX
    const baseURLSegmentsCount = 1; // e.g. /(1) global-list /(2) [1st-level-only tab slug]

    const tabsConfig = useMemo(
        () => (enhancedCurrentLocation
            ? createGlobalListPageTabsConfig({ t })
            : undefined),
        [enhancedCurrentLocation],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('Global Lists')}
            baseURLSegmentsCount={baseURLSegmentsCount}
            tabsConfig={tabsConfig}
        >
            <Col
                justify="center"
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                style={{ background: 'white', padding: '10px' }}
            >
                <h2>Ecosystem-wide lists can be viewed here.</h2>
                <h3>Currently, from standard CRUD set we have only Read endpoints, so all changes are preformed directly on the respective database tables.</h3>
            </Col>
        </StandardEntityDetailsWithTabs>
    );
}

GlobalListsPage.propTypes = {
    t: PropTypes.func.isRequired,
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
};


export default GlobalListsPage;
