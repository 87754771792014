import PropTypes from 'prop-types';

import { AllEntityTypes, CommonQueryParamsMetaProps } from './ApplicationStateModel';


const EntityDetailsProps = PropTypes.shape({
    entityDetails: PropTypes.shape({}),
    isLoadingDetails: PropTypes.bool,
    queryParams: PropTypes.shape({}),
    queryParamsMeta: CommonQueryParamsMetaProps,
});

export const createEntityDetailsProps = (entityDetailsProps) => PropTypes.shape({
    entityDetails: entityDetailsProps,
    isLoadingDetails: PropTypes.bool,
    queryParams: PropTypes.shape({}),
    queryParamsMeta: CommonQueryParamsMetaProps,
});

export const EntityTypeProps = PropTypes.oneOf(Object.values(AllEntityTypes).map((entity) => entity.toLowerCase())).isRequired;
export default EntityDetailsProps;
