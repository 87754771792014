import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import FAQ from './FAQ.layout';


const decorate = compose(
    withTranslation('faq'),
);

export default decorate(FAQ);

