import TransactionLedgerStatusTag from 'components/common/atoms/tags/TransactionLedgerStatusTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';

import React, { JSX } from 'react';

import Styled from './TransactionStatusTimelineItem.styled';
import { TransactionStatusTimelineItemProps } from './TransactionStatusTimelineItem.types';


function TransactionStatusTimelineItem({ item, isLastItem, t }: TransactionStatusTimelineItemProps): JSX.Element {
    return (
        <Styled.StatusWrapper>
            {isLastItem && (
                <Styled.FinalBalanceWrapper>
                    {`${t('transactions:transactionFields.balanceAfter.text')}:`}

                    <StandardValue value={item.balanceAfterTransaction} valueType={ValueTypes.AMOUNT} />
                    {` ${item.processedCurrency}`}
                </Styled.FinalBalanceWrapper>
            )}

            <TransactionLedgerStatusTag transactionLedgerStatus={item.status} showTooltip={false} />
        </Styled.StatusWrapper>

    );
}

export default TransactionStatusTimelineItem;
