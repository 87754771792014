import CountryOrCurrencyFlag from 'components/common/atoms/CountryOrCurrencyFlag';

import PropTypes from 'prop-types';
import React from 'react';


import Styled from './CountryOptionContent.styled';


function CountryOptionContent({ country }) {
    return (
        <Styled.CurrencyOptionWrapper>
            <CountryOrCurrencyFlag countryCode={country.iso_31662} size={20} />
            <Styled.CurrencyOptionText>{country.name}</Styled.CurrencyOptionText>
        </Styled.CurrencyOptionWrapper>
    );
}


CountryOptionContent.propTypes = {
    country: PropTypes.shape({
        short: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
};


export default CountryOptionContent;
