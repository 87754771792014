import { Dispatch } from 'models/meta/action';
import {
    initialiseOutgoingTransferForm,
    submitOutgoingTransferFormStep,
    clearOutgoingTransferData, requestResetForm,
} from 'redux/outgoing-transfer/actions';
import { outgoingTransferReducerName } from 'redux/outgoing-transfer/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import OutgoingTransfer from './OutgoingTransfer.layout';
import { OutgoingTransferDispatchProps, OutgoingTransferOwnProps, OutgoingTransferStateProps } from './OutgoingTransfer.types';


const mapStateToProps = (state: RootState): OutgoingTransferStateProps => ({
    contextData: state[outgoingTransferReducerName].contextData,
    formData: state[outgoingTransferReducerName].formData,

    currentStep: state[outgoingTransferReducerName].currentStep,
    requestResetForm: state[outgoingTransferReducerName].requestResetForm,
});

const mapDispatchToProps = (dispatch: Dispatch): OutgoingTransferDispatchProps => ({
    dispatchInitialiseOutgoingTransferForm: (ownerContext) => dispatch(initialiseOutgoingTransferForm(ownerContext)),
    dispatchSubmitOutgoingTransferFormStep: (step, formData) => dispatch(submitOutgoingTransferFormStep(step, formData)),
    dispatchClearOutgoingTransferData: () => dispatch(clearOutgoingTransferData()),
    dispatchRequestResetForm: () => dispatch(requestResetForm(false)),
});


const decorate = compose<ComponentType<OutgoingTransferOwnProps>>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('beneficiaries'),
);

export default decorate(OutgoingTransfer);
