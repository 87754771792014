import withAccessControl from 'hocs/withAccessControl';
import { cardDetailsPerLocationStoreKeyName, cardReducerName } from 'redux/card/reducer';
import { toggleChangeCardStatusFormVisibility, updateCardStatus } from 'redux/card-status-history/actions';
import { cardStatusHistoryReducerName } from 'redux/card-status-history/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UpdateCardStatusForm from './UpdateCardStatusForm.layout';


const mapStateToProps = (state) => ({
    isChangeStatusFormVisible: state[cardStatusHistoryReducerName].isChangeStatusFormVisible,
    isLoadingChangeStatus: state[cardStatusHistoryReducerName].isLoadingChangeStatus,

    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: cardReducerName,
        fieldName: cardDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleChangeCardStatusFormVisibility: (value) => dispatch(toggleChangeCardStatusFormVisibility(value)),
    dispatchUpdateCardStatus: (requestPayload, locationPathname) => dispatch(updateCardStatus(requestPayload, locationPathname)),
});


const decorate = compose(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UpdateCardStatusForm);
