import { showAutoLogoutBanner } from 'redux/application/actions';
import { applicationUIReducerName } from 'redux/application/reducer';
import { userActivity } from 'redux/current-user/actions';

import { connect } from 'react-redux';

import IdleTimer from './IdleTimer.layout';


const mapStateToProps = (state) => ({ isAutoLogoutBannerVisible: state[applicationUIReducerName].isAutoLogoutBannerVisible });

const mapDispatchToProp = (dispatch) => ({
    dispatchShowAutoLogoutBanner: () => dispatch(showAutoLogoutBanner()),
    dispatchUserActivity: () => dispatch(userActivity()),
});

export default connect(mapStateToProps, mapDispatchToProp)(IdleTimer);

