import styled from 'styled-components';


const OffboardingButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

const OffboardingHasBeenStartedInfoIconWrapper = styled.div`
  margin-bottom: 20px;
  font-size: 50px;
  color: red;
`;


export default { OffboardingButtonWrapper, OffboardingHasBeenStartedInfoIconWrapper };
