import InfoTooltip from 'components/common/atoms/InfoTooltip';
import CustomSelect from 'components/common/atoms/Select';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import ClientSelect from 'components/common/molecules/form-controls/ClientSelect';
import { StandardFilterTypes } from 'constants/ApplicationStateModel';
import { filterOptions } from 'utils/filterOptions';

import { Button, Select, Input, Checkbox } from 'antd';
import React from 'react';


import Styled from './FiltersSection.styled';


function renderFilterSectionButton(button, index) {
    return (
        <Button
            icon={button.icon}
            data-test-id={button.dataTestId}
            key={`${button.type}_${button.id || index}`}
            type={button.type}
            onClick={button.onClick}
            disabled={button.disabled}
            loading={button.isLoading}
        >
            {button.text}
        </Button>
    );
}


export function renderFilterSectionButtons(buttons = []) {
    return (
        <Styled.ButtonsWrapper $buttonsCount={buttons.length}>
            {buttons.map((button, index) => (button?.tooltipContent
                ? (
                    <StandardTooltip title={button.tooltipContent} key={`filter-section-button-${index}`}>
                        {renderFilterSectionButton(button, index)}
                    </StandardTooltip>
                )
                : renderFilterSectionButton(button, index)))}
        </Styled.ButtonsWrapper>
    );
}


const defaultFilterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export function renderFilter({
    filterItemConfig,
    onSearchInputChange,
    searchQuery,
    onGenericDropdownChange,
    queryParams,
    t,
}) {
    const handlers = [
        {
            predicate: () => filterItemConfig?.type === StandardFilterTypes.SEARCH,
            handler: () => (
                <div key="search-filter">
                    <Input
                        onChange={onSearchInputChange}
                        placeholder={filterItemConfig.placeholder || t('Type to search...')}
                        value={searchQuery}
                        allowClear
                    />
                </div>
            ),
        },
        {
            predicate: () => filterItemConfig?.type === StandardFilterTypes.CHECKBOX,
            handler: () => (
                <Styled.CheckboxWrapper
                    key={`checkbox-filter-${filterItemConfig.placeholder}`}
                >
                    <Checkbox
                        onChange={filterItemConfig.onChange}
                        checked={filterItemConfig.value}
                    >
                        {filterItemConfig.placeholder}
                    </Checkbox>

                    {filterItemConfig.hint?.length > 0 ? (
                        <InfoTooltip tooltipContent={filterItemConfig.hint} />
                    ) : null}

                </Styled.CheckboxWrapper>
            ),
        },
        {
            predicate: () => filterItemConfig?.type === StandardFilterTypes.CLIENT_SELECT,
            handler: () => (
                <div key="client-select-filter">
                    <ClientSelect
                        allowInactivePartners={queryParams?.includeInactivePartners}
                        isFormItem={false}
                        placeholder={filterItemConfig.placeholder || t('All partners, select to narrow')}
                        onChange={filterItemConfig.onChange}
                        initialValue={filterItemConfig.value}
                        allowClear={filterItemConfig.allowClear}
                        useUuid={filterItemConfig.handleClientSelectWithUuid}
                        style={{ width: '245px' }}
                    />
                </div>
            ),
        },

        {
            predicate: () => filterItemConfig?.type === StandardFilterTypes.DROPDOWN && filterItemConfig.optionsAsChildren,
            handler: () => (
                <div key={`legacy-dropdown-${filterItemConfig.placeholder}`}>
                    <Select
                        showSearch={filterItemConfig.showSearch}
                        style={filterItemConfig.style || { width: '100%' }}
                        placeholder={filterItemConfig.placeholder}
                        filterOption={filterOptions}
                        onChange={(value) => (filterItemConfig.queryParamName
                            ? onGenericDropdownChange(value, filterItemConfig.queryParamName)
                            : filterItemConfig?.onChange(value))}
                        value={filterItemConfig.value}
                        allowClear={filterItemConfig.allowClear}
                    >
                        {filterItemConfig.optionsAsChildren}
                    </Select>
                </div>
            ),
        },

        {
            predicate: () => filterItemConfig?.type === StandardFilterTypes.DROPDOWN && filterItemConfig.options,
            handler: () => (
                <div key={`dropdown-${filterItemConfig.placeholder}`}>
                    <CustomSelect
                        showSearch={filterItemConfig.showSearch}
                        style={filterItemConfig.style || { width: '100%' }}
                        placeholder={filterItemConfig.placeholder}
                        optionFilterProp="label"
                        filterOption={defaultFilterOption}
                        options={filterItemConfig.options}
                        onChange={(value) => (filterItemConfig.queryParamName
                            ? onGenericDropdownChange(value, filterItemConfig.queryParamName)
                            : filterItemConfig?.onChange(value))}
                        value={filterItemConfig.value}
                        allowClear={filterItemConfig.allowClear}
                        loading={filterItemConfig.loading}
                    />
                </div>
            ),
        },

        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export function createCurrencyFilterConfig({
    currencyOptions,
    value,
    queryParamName = 'currency',
    showSearch = true,
    allowClear = true,
    t,
}) {
    return {
        type: StandardFilterTypes.DROPDOWN,
        optionsAsChildren: currencyOptions,
        value,
        queryParamName,
        showSearch,
        allowClear,
        placeholder: t('All currencies, select to narrow'),
        style: { width: '245px' },
    };
}

export function createAccountTypeFilterConfig({
    accountTypeOptions,
    value,
    queryParamName = 'type',
    allowClear = true,
    t,
}) {
    return {
        type: StandardFilterTypes.DROPDOWN,
        optionsAsChildren: accountTypeOptions,
        value,
        queryParamName,
        allowClear,
        placeholder: t('All account types, select to narrow'),
        style: { width: '245px' },
    };
}


export default {
    renderFilterSectionButtons,
    renderFilter,
    createCurrencyFilterConfig,
    createAccountTypeFilterConfig,
};
