import { StepsConfigurationProps } from 'constants/MultiStepsFormModel';

import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import {
    createMultiStepsFormStep,
    renderMultiStepsFormStepWithSubSteps,
} from './FormStepsRenderer.helpers';
import Styled from './FormStepsRenderer.styled';


function FormStepsRenderer({
    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,
    availableSubSteps,
    stepsConfiguration = [],
    isEditMode,
    locationPathname,
    t,
}) {
    return (
        <Styled.FormStepWrapper>
            <Tabs
                animated
                activeKey={currentMultiStepsFormStep}
                tabPosition={'top'}
                destroyInactiveTabPane
                renderTabBar={() => <Styled.DummyTabsBar />}
                items={stepsConfiguration.map((stepConfig) => {
                    if (stepConfig?.subStepsConfiguration && stepConfig?.subStepsConfiguration?.length > 0) {
                        return renderMultiStepsFormStepWithSubSteps({
                            isEditMode,
                            stepConfig,
                            locationPathname,
                            currentMultiStepsFormStep,
                            currentMultiStepsFormSubStep,
                            availableSubSteps,
                            t,
                        });
                    }

                    return createMultiStepsFormStep({
                        stepConfig,
                        t,
                    });
                })}
            />
        </Styled.FormStepWrapper>
    );
}

FormStepsRenderer.propTypes = {
    currentMultiStepsFormStep: PropTypes.string,
    currentMultiStepsFormSubStep: PropTypes.string,
    t: PropTypes.func,
    stepsConfiguration: StepsConfigurationProps,
};

export default FormStepsRenderer;
