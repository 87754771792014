import FlagAndValue from 'components/common/atoms/FlagAndValue';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createBeneficiaryDetailsPath } from 'config/routes.helpers';
import { BeneficiaryType } from 'constants/BeneficiariesModel';
import { CountryIdType } from 'constants/CountriesModel';
import { PN } from 'constants/PermissionsModel';

import { Space } from 'antd';
import React from 'react';


export const createBeneficiariesListColumns = ({
    accessControl,
    dispatchRequestNavigation,
    // enhancedCurrentLocation,
    t,
}) => [
    {
        title: t('beneficiaryFields.uuid'),
        dataIndex: 'id',
        render: (id) => <StandardValue value={id} />,
    },
    {
        title: t('beneficiaryFields.beneficiaryType.label'),
        dataIndex: 'beneficiary_type',
        render: (text) => <StandardValue value={text} />,
    },
    {
        title: t('beneficiaryFields.beneficiaryName'),
        dataIndex: 'name',
        render: (text, record) => {
            const value = record?.beneficiary_type === BeneficiaryType.INDIVIDUAL
                ? `${record.first_name} ${record.last_name}`
                : record.company_name;

            return <StandardValue value={value} />;
        },
    },
    {
        title: t('beneficiaryFields.country.label'),
        dataIndex: 'country',
        render: (text) => <CountryRenderer propertyName={CountryIdType.iso_31663} value={text} showTooltip />,
    },
    {
        title: t('beneficiaryFields.currency.label'),
        dataIndex: 'currency',
        render: (text) => <FlagAndValue currencyCode={text} text={text} showTooltip />,
    },
    {
        title: t('beneficiaryFields.identificationCategory.label'),
        dataIndex: 'account_identifications',
        render: (_, record) => <StandardValue value={record.account_identifications?.[0]?.identification_category} />,
    },
    {
        title: t('beneficiaryFields.createdAt'),
        dataIndex: 'created_at',
        render: (date) => <StandardValue value={date} valueType="DATE" />,
    },
    {
        title: t('beneficiaryFields.updatedAt'),
        dataIndex: 'updated_at',
        render: (date) => <StandardValue value={date} valueType="DATE" />,
    },
    {
        title: t('common:table.columnNames.actions'),
        dataIndex: '',
        align: 'center',
        render: (_, record) => (
            <Space>
                {accessControl.hasPermission(PN.TRANSACTIONS.READ_ALL) && viewButtonRenderer({
                    record,
                    onClick: (event, record) => dispatchRequestNavigation(createBeneficiaryDetailsPath(record.id)),
                    tooltipContent: t('View beneficiary details'),
                    dataTestId: 'view-beneficiary-details',
                })}
            </Space>
        ),
    },
];


export default { createBeneficiariesListColumns };
