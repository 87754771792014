import SwitchWithInfoTooltip from 'components/common/atoms/SwitchWithInfoTooltip';
import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import { AccessControlProps } from 'constants/AccessControlModel';
import { filterOptions } from 'utils/filterOptions';
import { createBusinessUserRoleOptions, createShareHolderOptions } from 'utils/options-creators';
import { commonValidation, isValidFirstNameRule, isValidLastNameRule } from 'utils/validation-tools';

import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';


function AddBusinessUserModal({
    accessControl,
    enhancedCurrentLocation,
    listData,
    initialValues,
    t,
    locationPathname,
    dispatchSetModalProps,
    dispatchAddBusinessUser,
    dispatchFetchStakeholdersList,
}) {
    const [form] = Form.useForm();
    const { preSelectedStakeholder, companyId } = initialValues;
    const [selectedStakeholder, setSelectedStakeholder] = useState();

    useEffect(() => {
        if (!preSelectedStakeholder?.stakeholder_user_uuid) {
            dispatchFetchStakeholdersList({ limit: 100, companyId, withTotal: true }, enhancedCurrentLocation.pathname);
        }
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);

    const shareholdersOptions = useMemo(
        () => createShareHolderOptions(preSelectedStakeholder?.stakeholder_user_uuid ? [preSelectedStakeholder] : listData?.items),
        [preSelectedStakeholder, listData?.items],
    );
    const rolesOptions = useMemo(
        () => createBusinessUserRoleOptions(t),
        [t],
    );

    const onSelect = useCallback((selectedOptionValue) => {
        const stakeholder = (listData?.items || []).find((item) => item.stakeholder_user_uuid === selectedOptionValue);
        form.setFieldsValue({ firstName: stakeholder.first_name, lastName: stakeholder.last_name });
        setSelectedStakeholder(selectedOptionValue);
    }, [listData?.items]);

    const onClear = useCallback(() => {
        form.resetFields(['firstName', 'lastName']);
        setSelectedStakeholder(undefined);
    }, []);

    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchAddBusinessUser({ companyId, ...values }, locationPathname);
    };


    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={preSelectedStakeholder?.stakeholder_user_uuid
                    ? {
                        firstName: preSelectedStakeholder.first_name,
                        lastName: preSelectedStakeholder.last_name,
                        extStakeholderId: preSelectedStakeholder.stakeholder_user_uuid,
                    } : {}}
                onFinish={onFinish}
            >
                <Form.Item
                    name="extStakeholderId"
                    label={t('businessUsers:businessUserFields.stakeholder.text')}
                >
                    <Select
                        showSearch={!selectedStakeholder}
                        disabled={preSelectedStakeholder?.stakeholder_user_uuid}
                        allowClear={!preSelectedStakeholder?.stakeholder_user_uuid}
                        filterOption={filterOptions}
                        loading={!preSelectedStakeholder?.stakeholder_user_uuid && listData?.isLoadingList}
                        placeholder={t('businessUsers:businessUserFields.stakeholder.placeholder')}
                        onSelect={onSelect}
                        onClear={onClear}
                    >
                        {shareholdersOptions}
                    </Select>
                </Form.Item>


                {/* TODO extract to config and then map result to form Form.Item */}
                <Form.Item
                    name="firstName"
                    label={t('personalFields.firstName.label')}
                    rules={[
                        ...commonValidation({
                            t,
                            fieldName: 'firstName',
                            isRequired: true,
                            maxLength: 128,
                        }),
                        ...isValidFirstNameRule(t),
                    ]}
                >
                    <Input
                        placeholder={t('personalFields.firstName.placeholder')}
                        disabled={preSelectedStakeholder?.stakeholder_user_uuid || selectedStakeholder}
                    />
                </Form.Item>

                <Form.Item
                    name="lastName"
                    label={t('personalFields.lastName.label')}
                    rules={[
                        ...commonValidation({
                            t,
                            fieldName: 'lastName',
                            isRequired: true,
                            maxLength: 128,
                        }),
                        ...isValidLastNameRule(t),
                    ]}
                >
                    <Input
                        placeholder={t('personalFields.lastName.placeholder')}
                        disabled={preSelectedStakeholder?.stakeholder_user_uuid || selectedStakeholder}
                    />
                </Form.Item>

                <PhoneInput t={t} name="businessUserPhoneNumber" />

                <EmailInput
                    t={t}
                    name="businessUserEmail"
                    placeholder={t('personalFields.email.placeholder')}
                    label={t('personalFields.email.label')}
                />
                <Form.Item
                    name="businessUserRole"
                    label={t('businessUsers:businessUserFields.role.text')}
                    rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                >
                    <Select
                        filterOption={filterOptions}
                        placeholder={t('businessUsers:businessUserFields.role.placeholder')}
                    >
                        {rolesOptions}
                    </Select>
                </Form.Item>

                {accessControl.clientConfig?.generalSettings?.bwpAppClient && (
                    <Form.Item
                        name="sendInvitation"
                        label={t('businessUsers:businessUserFields.sendInvitation.text')}
                    >
                        <SwitchWithInfoTooltip />
                    </Form.Item>
                )}
            </Form>
        </div>
    );
}
AddBusinessUserModal.propTypes = {
    accessControl: AccessControlProps,
    initialValues: PropTypes.shape({
        clientId: PropTypes.number.isRequired,
        companyId: PropTypes.number.isRequired,
        preSelectedStakeholder: PropTypes.shape({
            first_name: PropTypes.string.isRequired,
            last_name: PropTypes.string.isRequired,
            ext_id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchFetchStakeholdersList: PropTypes.func.isRequired,
    dispatchAddBusinessUser: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    locationPathname: PropTypes.string.isRequired,
};

export default AddBusinessUserModal;
