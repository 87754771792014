import StandardTag from 'components/common/atoms/StandardTag';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Popover } from 'antd';
import React from 'react';


function ClientRiskTag({
    score,
    client,
    t,
}) {
    const handlers = [
        {
            predicate: () => score <= 6,
            handler: () => ({
                color: 'green',
                text: t('riskScore:riskScoreTag.values.low'),
            }),
        },
        {
            predicate: () => score > 6 && score <= 11,
            handler: () => ({
                color: 'yellow',
                text: t('riskScore:riskScoreTag.values.medium'),
            }),
        },
        {
            predicate: () => score > 11 && score <= 16,
            handler: () => ({
                color: 'purple',
                text: t('riskScore:riskScoreTag.values.moderate'),
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                color: 'red',
                text: t('riskScore:riskScoreTag.values.high'),
            }),
        },
    ];

    const tagData = handlers.filter(({ predicate }) => predicate())[0].handler();

    return tagData ? (
        <StandardTag
            color={tagData.color}
            text={tagData.text}
            showTooltip={false}
            isTextEllipsisDisabled
            style={client?.total_risk_score > 0 ? { cursor: 'help' } : undefined}
        >
            {client?.total_risk_score > 0
                ? (
                    <Popover
                        title={t('riskScore:riskScoreTag.tooltip')}
                        content={<ClientRiskTagContent client={client} t={t} />}

                    >
                        {tagData.text}
                        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Popover>
                )
                : tagData.text}
        </StandardTag>
    ) : <>-</>;
}

export function ClientRiskTagContent({
    client: {
        country_of_incorporation_risk,
        countries_of_operation_risk,
        operating_industries_risk,
        total_risk_score,
    },
    t,
}) {
    return (
        <>
            <div>
                {t('riskScore:riskScoreFields.countryOfIncorporation.text')}
        :
                <strong>{country_of_incorporation_risk}</strong>
            </div>
            <div>
                {t('riskScore:riskScoreFields.countryOfOperation.text')}
        :
                <strong>{countries_of_operation_risk}</strong>
            </div>
            <div>
                {t('riskScore:riskScoreFields.operatingIndustries.text')}
        :
                <strong>{operating_industries_risk}</strong>
            </div>
            <Divider style={{ margin: '5px 0' }} />
            <div>
                {t('riskScore:riskScoreFields.totalRiskScore.text')}
                {' '}
                <strong>{total_risk_score}</strong>
            </div>
        </>
    );
}

export default ClientRiskTag;
