import { standardPageSize } from 'config/config';
import { addMembersRouteSegmentPath, groupRootRoutePath, membersRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchMembersNotInGroup,
    fetchMembersNotInGroupFailure,
    fetchMembersNotInGroupSuccess,
    clearMembersNotInGroup,
    clearGroupMembers, fetchGroupMembers, fetchGroupMembersSuccess, fetchGroupMembersFailure,
} from './actions';


export const groupMembersReducerName = 'groupMembers';

export const groupMembersListsPerLocationStoreKeyName = 'groupMembersListsPerLocation';
export const groupNonMembersListsPerLocationStoreKeyName = 'groupNonMembersListsPerLocationStoreKeyName';


export const defaultGroupMembersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: 'member_id',
    sortOrder: SortDirections.ASCEND,
};

export const defaultGroupNonMembersQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: 'member_id',
    sortOrder: SortDirections.ASCEND,
};


const initialState = {
    [groupMembersListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    [groupNonMembersListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
};


const handlers = {
    [fetchGroupMembers]: (state, action) => {
        state[groupMembersListsPerLocationStoreKeyName] = updateStandardLists(state[groupMembersListsPerLocationStoreKeyName], action);
    },
    [fetchGroupMembersSuccess]: (state, action) => {
        state[groupMembersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[groupMembersListsPerLocationStoreKeyName], action);
    },
    [fetchGroupMembersFailure]: (state, action) => {
        state[groupMembersListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[groupMembersListsPerLocationStoreKeyName], action);
    },
    [clearGroupMembers]: (state, action) => {
        state[groupMembersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[groupMembersListsPerLocationStoreKeyName], action.payload.locationPathname);
    },


    [fetchMembersNotInGroup]: (state, action) => {
        state[groupNonMembersListsPerLocationStoreKeyName] = updateStandardLists(state[groupNonMembersListsPerLocationStoreKeyName], action);
    },
    [fetchMembersNotInGroupSuccess]: (state, action) => {
        state[groupNonMembersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[groupNonMembersListsPerLocationStoreKeyName], action);
    },
    [fetchMembersNotInGroupFailure]: (state, action) => {
        state[groupNonMembersListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[groupNonMembersListsPerLocationStoreKeyName], action);
    },
    [clearMembersNotInGroup]: (state, action) => {
        state[groupNonMembersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[groupNonMembersListsPerLocationStoreKeyName], action.payload.locationPathname);
    },


    [preformNavigationSuccess]: (state, action) => {
        state[groupMembersListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[groupMembersListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [membersRouteSegmentPath],
                observedRootPaths: [groupRootRoutePath],
            },
        );

        state[groupNonMembersListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[groupNonMembersListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [addMembersRouteSegmentPath],
                observedRootPaths: [groupRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === groupMembersReducerName) {
            if (action.payload?.fieldName === groupMembersListsPerLocationStoreKeyName) {
                state[groupMembersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[groupMembersListsPerLocationStoreKeyName] }, action);
            }
            if (action.payload?.fieldName === groupNonMembersListsPerLocationStoreKeyName) {
                state[groupNonMembersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[groupNonMembersListsPerLocationStoreKeyName] }, action);
            }
        }
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
