import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import MyPermissions from 'components/pages/authorised/UserProfile/MyPermissions';
import TokenDebug from 'components/pages/authorised/UserProfile/TokenDebug';
import { AccessControlProps } from 'constants/AccessControlModel';
import { UserListItemProps } from 'constants/UserModel';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';

import { KeyOutlined, BugOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';

import CurrentUserInformation from './CurrentUserInformation';


function UserProfile({
    enhancedCurrentLocation,
    accessControl,
    currentUser,
    entityDetailsData,
    dispatchFetchUserDetails,
    dispatchOpenAddEditUserModal,
    dispatchSetQueryParams,
    dispatchClearUserDetails,
    t,
}) {
    const contextEnforcedQueryParams = { userId: Number(currentUser.userData.userId) };
    const baseURLSegmentsCount = 1;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic({
        baseUrlWithoutTabsSlugs,
        contextEnforcedQueryParams,
        enhancedCurrentLocation,

        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchUserDetails,
        dispatchClearEntityDetails: dispatchClearUserDetails,
        dispatchSetQueryParams,
    });


    return (
        <StandardEntityDetailsWithTabs
            baseURLSegmentsCount={baseURLSegmentsCount}
            pageHeadingTitle={t('userDetails.screenTitle', { userId: currentUser.userData?.userId })}
            tabsConfig={[
                {
                    icon: <KeyOutlined />,
                    label: t('My Permissions'),
                    content: <MyPermissions permissions={currentUser?.permissions} t={t} />,
                    activeMainTabSegmentURL: 'my-permissions',
                    isDefault: true,
                },
                ...(accessControl.isSuperAdmin ? [ // XXX allowed - debug data, super admin only
                    {
                        icon: <BugOutlined />,
                        label: t('Token data'),
                        content: <TokenDebug token={currentUser?.userData} t={t} />,
                        activeMainTabSegmentURL: 'token-data',
                    }] : []),
            ]}
        >
            <CurrentUserInformation
                t={t}
                isLoadingUserDetails={!isDetailsPageInitialStateDefined}
                accessControl={accessControl}
                currentUser={currentUser}
                entityDetailsData={entityDetailsData}
                dispatchOpenAddEditUserModal={dispatchOpenAddEditUserModal}
            />
        </StandardEntityDetailsWithTabs>
    );
}


UserProfile.propTypes = {
    accessControl: AccessControlProps,
    entityDetailsData: PropTypes.shape({
        entityDetails: UserListItemProps,
        isLoadingDetails: PropTypes.bool,
    }),
    currentUser: PropTypes.shape({
        userData: PropTypes.shape({ userId: PropTypes.number }),
        userPreferences: PropTypes.shape({}),
        permissions: PropTypes.shape({}),
    }),
    dispatchFetchUserDetails: PropTypes.func.isRequired,
    dispatchOpenAddEditUserModal: PropTypes.func.isRequired,
    dispatchClearUserDetails: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default UserProfile;
