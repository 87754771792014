import { scrollBar } from 'assets/styles/scrollbar';

import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const SelectedPermissionsWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  padding: 56px 16px 16px 0;
`;

const SelectedPermissionsSearchWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0,1fr) auto;
  grid-column-gap: 20px;
  white-space: nowrap;
  margin-bottom: 30px;
`;

const SelectedPermissionsListOuterWrapper = styled.div`
  position: relative;
  height: 100%;
`;


const SelectedPermissionsListWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto !important;
  ${scrollBar};
`;

const InfoIcon = styled(WarningOutlined)`
  color: orange;
  cursor: help;
  font-size: 25px;
`;

export default {
    SelectedPermissionsWrapper,
    SelectedPermissionsSearchWrapper,
    SelectedPermissionsListWrapper,
    SelectedPermissionsListOuterWrapper,
    InfoIcon,
};
