import StandardListTable from 'components/common/organisms/StandardListTable';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { createAddClientPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { ClientListItemProps } from 'constants/ClientModel';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultClientsListQueryParams } from 'redux/clients/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import generateColumns from './tableColumns';


function ClientsList({
    accessControl,

    enhancedCurrentLocation,
    listData,

    dispatchRequestNavigation,
    dispatchSetQueryParams,
    dispatchFetchClientsList,
    dispatchClearClientsList,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { };


    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        useGlobalLocation: true,
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultClientsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchClientsList,
        dispatchClearItemsList: dispatchClearClientsList,
        dispatchSetQueryParams,
    });

    // 4. list specific stuff - local search, local sorting is in table configuration, see 'generateColumns'
    const filteredClients = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((client) => searchCaseInsensitive(client?.company_dba_name, listData?.queryParams?.search)
            || searchCaseInsensitive(client?.company_business_type, listData?.queryParams?.search)
            || searchCaseInsensitive(client?.company_registration_number, listData?.queryParams?.search)
            || searchCaseInsensitive(client?.company_vat_registration_number, listData?.queryParams?.search)
            || searchCaseInsensitive(`${client?.id}`, listData?.queryParams?.search)
            || searchCaseInsensitive(client?.descriptor, listData?.queryParams?.search));
            }
            return listData?.items || [];
        }, [listData],
    );

    // 4b. list specific stuff - add partner button
    const buttons = accessControl.hasPermission(PN.CLIENTS.CREATE)
        ? [{
            text: t('clientsList.buttons.addPartner.text'),
            type: 'primary',
            icon: <PlusOutlined />,
            id: 'add_client',
            onClick: () => dispatchRequestNavigation(createAddClientPath()),
        }]
        : undefined;

    return (
        <>
            <StandardPageHeading title={t('clientsList.screenTitle')} />

            <StandardPageWrapper>
                <StandardListTable
                    onUpdate={fetchData}
                    onListRefresh={() => dispatchFetchClientsList({ ...listData?.queryParams }, enhancedCurrentLocation.pathname)}
                    items={filteredClients}
                    totalItemsCount={filteredClients.length}
                    queryParams={listData?.queryParams}
                    queryParamsMeta={listData?.queryParamsMeta}
                    isTableLoading={listData?.isLoadingList}
                    isLoadingPlaceholder={!isListInitialStateDefined}
                    columnsConfig={generateColumns({
                        accessControl,
                        queryParams: listData?.queryParams || {},
                        dispatchRequestNavigation,
                        t,
                    })}
                    filtersButtonsConfig={buttons}
                />
            </StandardPageWrapper>
        </>
    );
}

ClientsList.propTypes = {
    accessControl: AccessControlProps,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(ClientListItemProps),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchFetchClientsList: PropTypes.func.isRequired,
    dispatchClearClientsList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,

};

export default ClientsList;
