import { CLEAR_TRANSACTIONS_LIST } from 'redux/transactions/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const getTransactions = createAction('transactions/getTransactions', (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const getTransactionsSuccess = createAction(
    'transactions/getTransactionsSuccess',
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }),
);
export const getTransactionsFailure = createAction('transactions/getTransactionsFailure', (locationPathname) => ({ payload: { locationPathname } }));
export const clearTransactionsList = createAction(CLEAR_TRANSACTIONS_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export const getTransactionsFilters = createAction('transactions/getTransactionsFilters');
export const getTransactionsFiltersSuccess = createAction('transactions/getTransactionsFiltersSuccess', (data) => ({ payload: data }));
export const getTransactionsFiltersFailure = createAction('transactions/getTransactionsFiltersFailure');

export const refreshTransactions = createAction('transactions/refreshTransactions', (locationPathname) => ({ payload: { locationPathname } }));
