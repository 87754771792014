import { currentUserReducerName } from 'redux/current-user/reducer';
import { requestMarketingPreferences } from 'redux/marketing-preferences/actions';
import { setModalProps } from 'redux/modal/actions';
import { modalReducerName } from 'redux/modal/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import RequestMarketingPreferencesModal from './RequestMarketingPreferencesModal.layout';


const mapStateToProps = (state) => ({
    emailCurrentUser: state[currentUserReducerName]?.userData?.email,
    modalProps: state[modalReducerName]?.modalProps,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestMarketingPreferences: (queryParams) => dispatch(requestMarketingPreferences(queryParams)),
    dispatchSetModalProps: (modalProps) => dispatch(setModalProps(modalProps)),
});

const decorate = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
);

export default decorate(RequestMarketingPreferencesModal);
