import { QueryParams } from 'models/app/common';
import { LocationPathname } from 'models/app/navigation';

import { createAction } from '@reduxjs/toolkit';


import {

    CLEAR_TRANSACTION_STATUS_HISTORY,
    FETCH_TRANSACTION_STATUS_HISTORY,
    FETCH_TRANSACTION_STATUS_HISTORY_FAILURE,
    FETCH_TRANSACTION_STATUS_HISTORY_SUCCESS,
} from './action.types';


export const fetchTransactionStatusHistory = createAction(FETCH_TRANSACTION_STATUS_HISTORY,
    (queryParams: QueryParams, locationPathname: LocationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchTransactionStatusHistorySuccess = createAction(
    FETCH_TRANSACTION_STATUS_HISTORY_SUCCESS,
    (items: [], totalCount: number, locationPathname: LocationPathname) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchTransactionStatusHistoryFailure = createAction(FETCH_TRANSACTION_STATUS_HISTORY_FAILURE,
    (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));
export const clearTransactionStatusHistory = createAction(CLEAR_TRANSACTION_STATUS_HISTORY,
    (locationPathname: LocationPathname) => ({ payload: { locationPathname } }));
