/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { Uuid } from '@manigo/manigo-domain-typings';


import {
    fetchFeesAPI,
    fetchGroupIdFees,
    fetchClientIdFees,
    fetchFeeUuidDetails,
    fetchFeeUuidGroups,
    fetchFeeUuidPromotions,
    fetchFeeUuidTiers,
    createCustomFeeAPI,
    fetchManualFeesAPI,
    triggerManualFeeAPI,
} from './index';


class FeesService {
    public fetchFeeDetails(feeUuid: Uuid): Promise<ApiResponse | ApiError> {
        return fetchFeeUuidDetails(feeUuid);
    }

    public fetchFeeTiers(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { feeUuid } = rawQueryParams;
        return fetchFeeUuidTiers(feeUuid);
    }

    public fetchFees(rawQuery): Promise<ApiResponse | ApiError> {
        const { clientId, groupId, clientUuid } = rawQuery;

        const handlers = [
            {
                predicate: () => clientUuid?.length > 0,
                handler: () => fetchFeesAPI({ params: { clientId: clientUuid } }),
            },
            {
                predicate: () => groupId?.length > 0,
                handler: () => fetchFeesAPI({ params: { groupId } }),
            },
            {
                predicate: () => groupId > 0,
                handler: () => fetchGroupIdFees(groupId),
            },
            {
                predicate: () => clientId > 0,
                handler: () => fetchClientIdFees(clientId),
            },
            {
                predicate: () => true,
                handler: () => Promise.reject(),
            },

        ];

        return handlers.filter(({ predicate }) => predicate())[0].handler();
    }

    public fetchManualFees(rawQueryParams): Promise<ApiResponse | ApiError> {
        return fetchManualFeesAPI({ params: { clientId: rawQueryParams.clientExtId } });
    }

    public fetchFeePromotions(feeUuid: Uuid): Promise<ApiResponse | ApiError> {
        return fetchFeeUuidPromotions(feeUuid);
    }

    public fetchFeeGroups(feeUuid: Uuid): Promise<ApiResponse | ApiError> {
        return fetchFeeUuidGroups(feeUuid);
    }

    public createCustomFee(rawQueryParams): Promise<ApiResponse | ApiError> {
        return createCustomFeeAPI(rawQueryParams);
    }

    public triggerManualFee(rawQueryParams): Promise<ApiResponse | ApiError> {
        return triggerManualFeeAPI(rawQueryParams);
    }
}

export default FeesService;
