import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import { standardPageSize, standardPaginationOptions } from 'config/config';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsProps, StandardFilterTypes } from 'constants/ApplicationStateModel';
import { isDeepEqual } from 'utils/object-tools';

import { Pagination } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';


import FilterSection from './FilterSection';
import StandardTable from './StandardTable/StandardTable.layout';


function StandardListTable({
    accessControl,
    items,
    totalItemsCount,
    isTableLoading,
    isLoadingPlaceholder,
    queryParams,
    queryParamsMeta,
    onUpdate,
    onListRefresh,
    columnsConfig,
    filtersButtonsConfig = [],
    additionalFiltersConfig = [],

    rowKey,
    showClientSelect = false,
    handleClientSelectWithUuid = false,
    allowAllClients = true,
    allowInactivePartners = false,
    showIncludeInactivePartners = false,
    showSearch,
    isClientActive,
    removeFilterSection,
    t,
    customHeadingContent,
}) {
    const [activeFilters, setActiveFilters] = useState({});
    // pagination calculation fix
    // XXX this shouldn't be needed, WIP on finding the root cause
    const amendedQueryParams = useMemo(
        () => ({
            ...queryParams,
            ...(!queryParams?.offset ? { offset: 0 } : {}),
            ...(!queryParams?.limit ? { limit: standardPageSize } : {}),
        }), [queryParams],
    );

    const onClientSelect = (value) => {
        const newRequestedData = {
            ...queryParams,
            offset: 0,
            ...(handleClientSelectWithUuid ? { clientExtId: value } : { clientId: value }),
        };
        onUpdate(newRequestedData);
    };

    const onGenericDropdownChange = (value, queryParamName) => {
        const newRequestedData = {
            ...queryParams,
            offset: 0,
            [queryParamName]: value,
        };
        onUpdate(newRequestedData);
    };

    const onSearchHandler = (searchQuery) => {
        const newRequestedData = {
            ...queryParams,
            offset: 0,
            search: searchQuery,
        };
        onUpdate(newRequestedData);
    };

    const onTableChangeHandler = (pagination, filters, sorter) => {
        const { field, order } = sorter;

        onUpdate({
            ...queryParams,
            sortBy: field,
            sortOrder: order,
            offset: isDeepEqual(filters, activeFilters) ? queryParams.offset : 0,
            filters,
        });

        setActiveFilters(filters);
    };

    const hasSearchQueryParam = useMemo(
        () => queryParams?.search?.length >= 0,
        [queryParams],
    );

    const onIncludeInactivePartnersChange = (event) => {
        const newValue = !!event?.target?.checked;
        const { clientId, ...otherQueryParams } = queryParams;
        const isActive = isClientActive(clientId);

        const newRequestedData = {
            ...otherQueryParams,
            offset: 0,
            includeInactivePartners: newValue,
            // XXX deselect inactive partner when unchecked
            ...(clientId > 0 && !isActive && !newValue ? { clientId: undefined } : { clientId }),
        };
        onUpdate(newRequestedData);
    };

    const memorisedFiltersConfiguration = useMemo(
        () => [
            // XXX non standard one
            //  TODO make it standard -> main obstacle: debounced search field -> solution:  extract it to component and handle debouncing there
            ...(hasSearchQueryParam || showSearch

                ? [{ type: StandardFilterTypes.SEARCH }]
                : []
            ),


            ...(accessControl.isSuperAdmin && showClientSelect // XXX allowed - client filter
                ? [{
                    type: StandardFilterTypes.CLIENT_SELECT,
                    allowInactivePartners,
                    value: handleClientSelectWithUuid ? queryParams?.clientExtId : queryParams?.clientId,
                    placeholder: 'All partners, select to narrow',
                    allowClear: allowAllClients,
                    onChange: onClientSelect,
                    handleClientSelectWithUuid,
                }] : []),

            ...(showClientSelect && showIncludeInactivePartners
                ? [{
                    type: StandardFilterTypes.CHECKBOX,
                    placeholder: 'Include inactive partners',
                    value: queryParams?.includeInactivePartners,
                    onChange: onIncludeInactivePartnersChange,
                }] : []),

            ...additionalFiltersConfig,
        ], [additionalFiltersConfig, hasSearchQueryParam, allowInactivePartners],
    );

    return (
        <div className="standardListTable-headingAndStandardTable-wrapper">
            {!removeFilterSection
                ? (
                    <FilterSection
                        isLoading={isTableLoading}
                        queryParams={queryParams}
                        onSearchUpdate={hasSearchQueryParam ? onSearchHandler : undefined}
                        onGenericDropdownChange={onGenericDropdownChange}
                        filtersConfiguration={memorisedFiltersConfiguration}
                        buttonsConfiguration={filtersButtonsConfig}
                    />
                ) : customHeadingContent || <div className="standardListTable-headingDummy" />}


            <div className="standardListTable-standardTable-wrapper">
                <StandardTable
                    t={t}
                    rowKey={rowKey || 'id'}
                    isTableLoading={isTableLoading}
                    isLoadingPlaceholder={isLoadingPlaceholder}
                    dataSource={items || []}
                    totalItemsCount={totalItemsCount}
                    queryParams={amendedQueryParams}
                    columnsConfig={columnsConfig}
                    onChange={onTableChangeHandler}
                    onListRefresh={() => (onListRefresh ? onListRefresh(queryParams) : onUpdate(queryParams))}
                />

                {!isLoadingPlaceholder && (
                    <div className={`standardTable--footerWrapper ${isTableLoading ? 'isLoading' : ''}`}>
                        <QueryParamsMetaInfo
                            onListRefresh={() => (onListRefresh ? onListRefresh(queryParams) : onUpdate(queryParams))}
                            queryParams={queryParams}
                            queryParamsMeta={queryParamsMeta}
                            isLoading={isTableLoading}
                        />

                        <Pagination
                            responsive
                            showSizeChanger
                            hideOnSinglePage={false}
                            total={totalItemsCount}
                            current={amendedQueryParams.offset / amendedQueryParams.limit + 1}
                            pageSize={amendedQueryParams?.limit}
                            onChange={(page, pageSize) => {
                                const offset = (page - 1) * pageSize;
                                onUpdate({
                                    ...amendedQueryParams,
                                    limit: pageSize,
                                    offset,
                                });
                            }}
                            showTotal={(total, range) => `Viewing ${range[0]}-${range[1]} of ${total} items`}
                            pageSizeOptions={standardPaginationOptions}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}


StandardListTable.propTypes = {
    accessControl: AccessControlProps,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    totalItemsCount: PropTypes.number,
    isTableLoading: PropTypes.bool,
    isLoadingPlaceholder: PropTypes.bool,

    showClientSelect: PropTypes.bool,
    showIncludeInactivePartners: PropTypes.bool,

    queryParams: CommonQueryParamsProps,
    queryParamsMeta: PropTypes.shape({
        timestamp: PropTypes.number,
        location: PropTypes.shape({
            pathname: PropTypes.string,
            state: PropTypes.shape({ replace: PropTypes.bool }),
        }),
    }),

    onUpdate: PropTypes.func,
    rowKey: PropTypes.string,
    showSearch: PropTypes.bool,
    allowAllClients: PropTypes.bool,
    allowInactivePartners: PropTypes.bool,
    onListRefresh: PropTypes.func,
    // onClientSelect: PropTypes.func,
    columnsConfig: PropTypes.arrayOf(PropTypes.shape({})),
    filtersButtonsConfig: PropTypes.arrayOf(PropTypes.shape({})),
    additionalFiltersConfig: PropTypes.arrayOf(PropTypes.shape({})),
    isClientActive: PropTypes.func,

    handleClientSelectWithUuid: PropTypes.bool,
    removeFilterSection: PropTypes.bool,
    customHeadingContent: PropTypes.node,
};

export default StandardListTable;
