import { renderContent } from 'components/common/molecules/OffboardingButtons/OffboardingButtons.helpers';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CompanyStatuses } from 'constants/CompaniesModel';
import { MemberStatuses } from 'constants/MemberModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import { PN } from 'constants/PermissionsModel';


import { CloseOutlined, StopOutlined, UndoOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function OffboardingButtons({
    entityName,
    entityStatus,
    memberId,
    companyId,
    enhancedCurrentLocation,
    t,
    accessControl,
    isEntityLoading,
    isLoadingStartOffboarding,
    isLoadingStopOffboarding,
    isLoadingFinalizeOffboarding,
    dispatchStartOffboarding,
    dispatchStopOffboarding,
    dispatchFinaliseOffboarding,
}) {
    const entityType = useMemo(
        () => (companyId > 0 ? 'Company' : 'Member'),
        [companyId, memberId],
    );

    const isApplicableForStartOffboarding = useMemo(
        () => {
            if (memberId > 0) {
                return ![MemberStatuses.PENDING_CLOSURE, MemberStatuses.CLOSED].includes(entityStatus)
          && accessControl.hasPermission(PN.OFFBOARDING.SET_PENDING_CLOSURE);
            }
            if (companyId > 0) {
                return [CompanyStatuses.ACTIVE, CompanyStatuses.SUSPENDED].includes(entityStatus)
          && accessControl.hasPermission(PN.OFFBOARDING.SET_PENDING_CLOSURE);
            }
            return false;
        },
        [accessControl, memberId, companyId, entityStatus],
    );

    const isApplicableForStopOffboarding = useMemo(
        () => {
            if (memberId > 0) {
                return entityStatus === MemberStatuses.PENDING_CLOSURE && accessControl.hasPermission(PN.OFFBOARDING.REINSTATE_PENDING_CLOSURE);
            }
            if (companyId > 0) {
                return entityStatus === CompanyStatuses.PENDING_CLOSURE && accessControl.hasPermission(PN.OFFBOARDING.REINSTATE_PENDING_CLOSURE);
            }
            return false;
        },
        [accessControl, memberId, companyId, entityStatus],
    );

    const isApplicableForFinaliseOffboarding = useMemo(
        () => {
            if (memberId > 0) {
                return entityStatus === MemberStatuses.PENDING_CLOSURE && accessControl.hasPermission(PN.OFFBOARDING.FINALISE_CLOSED);
            }
            if (companyId > 0) {
                return entityStatus === CompanyStatuses.PENDING_CLOSURE && accessControl.hasPermission(PN.OFFBOARDING.FINALISE_CLOSED);
            }
            return false;
        },
        [accessControl, memberId, companyId, entityStatus],
    );

    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);


    const createButtonsConfigs = useMemo(
        () => ({
            startOffboarding: {
                dispatchAction: () => dispatchStartOffboarding({ memberId, companyId }, baseUrlWithoutTabsSlugs),
                icon: <StopOutlined />,
                isDanger: true,
                confirmationQuestionKey: 'buttons.startOffboardingButton.confirmationQuestion',
                buttonLabelKey: 'buttons.startOffboardingButton.text',
                isLoading: isLoadingStartOffboarding,
            },
            stopOffboarding: {
                dispatchAction: () => dispatchStopOffboarding({ memberId, companyId }, baseUrlWithoutTabsSlugs),
                icon: <UndoOutlined />,
                confirmationQuestionKey: 'buttons.stopOffboardingButton.confirmationQuestion',
                buttonLabelKey: 'buttons.stopOffboardingButton.text',
                isLoading: isLoadingStopOffboarding,
            },
            finaliseOffboarding: {
                dispatchAction: () => dispatchFinaliseOffboarding({ memberId, companyId }, baseUrlWithoutTabsSlugs),
                icon: <CloseOutlined />,
                isDanger: true,
                confirmationQuestionKey: 'buttons.finaliseOffboardingButton.confirmationQuestion',
                buttonLabelKey: 'buttons.finaliseOffboardingButton.text',
                isLoading: isLoadingFinalizeOffboarding,
            },
        }),
        [baseUrlWithoutTabsSlugs, memberId, companyId, isLoadingStartOffboarding, isLoadingStopOffboarding, isLoadingFinalizeOffboarding],
    );


    return renderContent({
        isEntityLoading,
        isApplicableForStartOffboarding,
        isApplicableForStopOffboarding,
        isApplicableForFinaliseOffboarding,
        createButtonsConfigs,
        t,
        entityType,
        entityName,
    });
}

OffboardingButtons.propTypes = {
    // own props
    entityName: PropTypes.string,
    memberId: PropTypes.number,
    companyId: PropTypes.number,
    entityStatus: PropTypes.string,
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
    // mapped props
    accessControl: AccessControlProps,
    isLoadingStartOffboarding: PropTypes.bool.isRequired,
    isLoadingStopOffboarding: PropTypes.bool.isRequired,
    isLoadingFinalizeOffboarding: PropTypes.bool.isRequired,
    dispatchStartOffboarding: PropTypes.func.isRequired,
    dispatchStopOffboarding: PropTypes.func.isRequired,
    dispatchFinaliseOffboarding: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default OffboardingButtons;
