import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import AddClientForm from 'components/pages/authorised/AddClient/AddClientForm';
import { AddClientFormVariants } from 'constants/AddClientModel';

import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';


function AddClientPage({ t }) {
    const location = useLocation();

    const parentClientDataFromNavigation = useMemo(
        () => location?.state,
        [location],
    );

    return (
        <>
            <StandardPageHeading
                title={
                    parentClientDataFromNavigation?.parentClientId > 0
                        ? t('addSubClient.screenTitle', {
                            parentClientId: parentClientDataFromNavigation.parentClientId,
                            parentClientName: parentClientDataFromNavigation.parentClientName,
                        })
                        : t('addClient.screenTitle')
                }
            />

            <StandardPageWrapper>
                {location?.pathname
                    ? (
                        <AddClientForm
                            locationPathname={location.pathname}
                            parentClientId={parentClientDataFromNavigation?.parentClientId}
                            formVariant={parentClientDataFromNavigation?.parentClientId > 0
                                ? AddClientFormVariants.ADD_SUB_PARTNER
                                : AddClientFormVariants.ADD_PARTNER}
                        />
                    )
                    : (<Skeleton loading active />)}

            </StandardPageWrapper>
        </>
    );
}

AddClientPage.propTypes = { t: PropTypes.func.isRequired };

export default AddClientPage;
