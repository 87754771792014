import StandardTag from 'components/common/atoms/StandardTag';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import { AllCardStatuses, CardStatusProp } from 'constants/CardModel';
import { removeUnderscores } from 'utils/string-tools';

import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';


const createHandlers = (cardStatus) => {
    const handlers = [
        {
            predicate: () => cardStatus === AllCardStatuses.ACTIVE,
            handler: () => ({ color: 'green', text: removeUnderscores(AllCardStatuses.ACTIVE) }),
        },
        {
            predicate: () => [AllCardStatuses.NOT_ACTIVATED, AllCardStatuses.SUSPENDED].includes(cardStatus),
            handler: () => ({ color: 'orange', text: removeUnderscores(cardStatus) }),
        },
        {
            predicate: () => [
                AllCardStatuses.NOT_DELIVERED,
                AllCardStatuses.LOST,
                AllCardStatuses.STOLEN,
                AllCardStatuses.FRAUD,
                AllCardStatuses.VOIDED,
                AllCardStatuses.EXPIRED,
            ].includes(cardStatus),
            handler: () => ({ color: 'red', text: removeUnderscores(cardStatus) }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: 'Unknown' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function CardStatusTag({
    cardStatus,
    cardStatusComment = undefined,
}) {
    const tagData = createHandlers(cardStatus);

    return (
        <StandardTag
            isTextEllipsisDisabled={false}
            color={tagData.color}
            text={tagData.text}
            showTooltip={false}
        >
            {cardStatusComment
                ? (
                    <StandardTooltip
                        title={(
                            <div style={{ textAlign: 'left' }}>
                                <strong>Card status Comment</strong>
                                <hr />
                                {cardStatusComment}
                            </div>
                        )}
                        showCopyToClipboard={false}
                        isTextEllipsisDisabled={false}
                    >
                        {tagData.text}
                        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </StandardTooltip>
                )
                : tagData.text}
        </StandardTag>
    );
}

CardStatusTag.propTypes = {
    cardStatus: CardStatusProp,
    cardStatusComment: PropTypes.string,
};

export default CardStatusTag;
