import styled from 'styled-components';


const FieldLabelWithExtraData = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(max-content, 1fr);
    grid-column-gap: 50px;
`;


export default { FieldLabelWithExtraData };
