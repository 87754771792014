import { promotionsNotAssignedToGroupListSuffix } from 'constants/promotions';
import { createPromotionOptions } from 'utils/options-creators';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


function AddPromotionToGroupModal({
    promotionsListData,
    clientId,
    groupId,
    locationPathname,

    existingPromotionsUuids = [],

    dispatchAssignPromotions,
    dispatchFetchPromotionsList,
    dispatchSetModalProps,
    t,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
        if (!promotionsListData?.items) {
            dispatchFetchPromotionsList({ clientId }, `${locationPathname}${promotionsNotAssignedToGroupListSuffix}`);
        }
    }, []);

    const availablePromotionsOptions = useMemo(
        () => {
            const filteredPromotions = (promotionsListData?.items || []).filter((promotion) => !existingPromotionsUuids.includes(promotion.ext_id));
            return createPromotionOptions(filteredPromotions);
        }, [promotionsListData],
    );


    const onFinish = (values) => {
        const requestPayload = {
            groupId,
            clientId,
            ...(values?.promotionUuids?.length > 0 ? { promotionUuids: values?.promotionUuids } : {}),
        };
        if (requestPayload?.promotionUuids) {
            dispatchSetModalProps({
                confirmLoading: true,
                cancelButtonProps: { disabled: true },
            });
            dispatchAssignPromotions(requestPayload, locationPathname);
        }
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="AddPromotionToGroupModal"
            onFinish={onFinish}
        >
            <Form.Item
                name="promotionUuids"
                label={t('modals.addPromotionsToGroup.fields.promotionIds.label')}
                rules={[{ required: true, message: t('modals.addPromotionsToGroup.fields.promotionIds.validationRules.required') }]}
            >
                <Select
                    loading={promotionsListData?.isLoadingList}
                    mode="multiple"
                    placeholder={t('modals.addPromotionsToGroup.fields.promotionIds.placeholder')}
                >
                    {availablePromotionsOptions}
                </Select>
            </Form.Item>
        </Form>
    );
}


AddPromotionToGroupModal.propTypes = {
    promotionsListData: PropTypes.shape({}),
    existingPromotionsUuids: PropTypes.arrayOf(PropTypes.string),
    clientId: PropTypes.number,
    groupId: PropTypes.number,

    t: PropTypes.func.isRequired,
    dispatchFetchPromotionsList: PropTypes.func.isRequired,
    dispatchAssignPromotions: PropTypes.func.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
};


export default AddPromotionToGroupModal;
