import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Styled from './InfoTooltip.styled';


function InfoTooltip({ tooltipContent, content }) {
    return (
        <Styled.InfoIconAndText>
            {content}
            <Tooltip title={tooltipContent}>
                <Styled.InfoIcon />
            </Tooltip>
        </Styled.InfoIconAndText>
    );
}


InfoTooltip.propTypes = {
    content: PropTypes.node,
    tooltipContent: PropTypes.string.isRequired,
};

export default InfoTooltip;
