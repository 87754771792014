import { HttpService } from 'services/http';


export const fetchDefaultClientConfigurationAPI = () => HttpService.get('/v2/configuration/default');

export const fetchBPSAccountProductsAPI = (queryParams = {}) => HttpService.get('/v2/configuration/bps-account-products', queryParams);

export default {
    fetchDefaultClientConfigurationAPI,
    fetchBPSAccountProductsAPI,
};
