import { HttpService } from 'services/http';


export const getAccountIdDetails = (accountId, config) => HttpService.get(`/v3/accounts/${accountId}`, config);

export const createNewAccountApi = (requestPayload) => HttpService.post('/v3/accounts', requestPayload);

export const getAccountsAPI = (queryParams) => HttpService.get('/v3/accounts/', queryParams);

export const deleteAccountId = (accountId) => HttpService.delete(`/v3/accounts/${accountId}`);
export const updateExistingAccountApi = (accountId, requestPayload, config) => HttpService.put(`/v3/accounts/${accountId}`, requestPayload, config);
