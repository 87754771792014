import { StandardTagConfigItem } from 'models/app/standardTag';

import { TransactionLedgerStatus } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';


const mapTransactionLedgerStatusTagToColorAndLabel = (ledgerStatus: TransactionLedgerStatus, t: TFunction): StandardTagConfigItem => {
    const handlers = [
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.APPROVED,
            handler: () => ({
                color: 'green',
                label: t('transactions:transactionStatuses.approved.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.COMPLETED,
            handler: () => ({
                color: 'green',
                label: t('transactions:transactionStatuses.completed.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.DECLINED,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.declined.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.INITIALIZED,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionStatuses.initialized.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.FAILED,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.failed.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.LEGACY,
            handler: () => ({
                color: 'orange',
                label: t('transactions:transactionStatuses.legacy.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.PENDING_AML_REVIEW,
            handler: () => ({
                color: 'geekblue',
                label: t('transactions:transactionStatuses.pendingAmlReview.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.PENDING_REVERSAL,
            handler: () => ({
                color: 'orange',
                label: t('transactions:transactionStatuses.pendingReversal.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.REVERSED,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.reversed.text'),
            }),
        },

        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.REFUNDED,
            handler: () => ({
                color: 'orange',
                label: t('transactions:transactionStatuses.refunded.text'),
            }),
        },
        {
            predicate: () => ledgerStatus === TransactionLedgerStatus.REJECTED,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.rejected.text'),
            }),
        },

        {
            predicate: () => true,
            handler: () => ({ label: t('transactions:transactionStatuses.unknown') }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default mapTransactionLedgerStatusTagToColorAndLabel;
