import CountryOrCurrencyFlag from 'components/common/atoms/CountryOrCurrencyFlag';

import { BankOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from './CurrencyOption.styled';


function CurrencyOptionContent({ currency, showBankingDetails, t }) {
    const label = `${currency.short} - ${currency.full_name}`;

    return (
        <Styled.CurrencyOptionWrapper>
            <CountryOrCurrencyFlag currencyCode={currency.short} size={20} />
            <Styled.CurrencyOptionText>{label}</Styled.CurrencyOptionText>

            {showBankingDetails && currency.banking_details_available && (
                <Styled.IconWrapper>
                    <Tooltip title={t('accounts:tooltips.currencyHasExternalBankingDetails')}>
                        <BankOutlined />
                    </Tooltip>
                </Styled.IconWrapper>
            )}
        </Styled.CurrencyOptionWrapper>
    );
}


CurrencyOptionContent.propTypes = {
    currency: PropTypes.shape({
        short: PropTypes.string.isRequired,
        full_name: PropTypes.string.isRequired,
        business_account_enabled: PropTypes.bool.isRequired,
    }).isRequired,
    banking_details_available: PropTypes.bool,
    t: PropTypes.func.isRequired,
};


export default CurrencyOptionContent;
