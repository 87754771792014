// eslint-disable-next-line no-restricted-imports
import { parsePhoneNumberFromString, isValidPhoneNumber as isValidPhoneNumberLIB } from 'libphonenumber-js'; // legit use;


/**
 * parse phone number thus checking it's validity
 * @param phoneNumber
 * @returns {boolean}
 */

export const isValidPhoneNumber = (phoneNumber) => isValidPhoneNumberLIB(phoneNumber);


/**
 * parse and format phone number to E.164 standard
 * @param phoneNumber
 * @returns {string|undefined}
 */
export const formatPhoneNumber = (phoneNumber, format = 'INTERNATIONAL') => {
    if (phoneNumber) {
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
        if (parsedPhoneNumber) {
            return parsedPhoneNumber.format(format);
        }
        return phoneNumber;
    }
    return phoneNumber;
};


export default { isValidPhoneNumber, formatPhoneNumber };
