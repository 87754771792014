export const FETCH_DEFAULT_CLIENT_CONFIGURATION = 'FETCH_DEFAULT_CLIENT_CONFIGURATION';
export const FETCH_DEFAULT_CLIENT_CONFIGURATION_SUCCESS = 'FETCH_DEFAULT_CLIENT_CONFIGURATION_SUCCESS';
export const FETCH_DEFAULT_CLIENT_CONFIGURATION_FAILURE = 'FETCH_DEFAULT_CLIENT_CONFIGURATION_FAILURE';

export const FETCH_BPS_PRODUCTS = 'FETCH_BPS_PRODUCTS';
export const FETCH_BPS_PRODUCTS_SUCCESS = 'FETCH_BPS_PRODUCTS_SUCCESS';
export const FETCH_BPS_PRODUCTS_FAILURE = 'FETCH_BPS_PRODUCTS_FAILURE';


export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';

export const TOGGLE_ADDRESS_SAME_AS_OTHER_ADDRESS = 'TOGGLE_ADDRESS_SAME_AS_OTHER_ADDRESS';

export const CLEAR_ADD_CLIENT_STATE_DATA = 'CLEAR_ADD_CLIENT_STATE_DATA';

