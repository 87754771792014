import { standardPageSize } from 'config/config';
import { apiKeysRouteSegmentPath, clientRootRoutePath } from 'config/routes';
import { SortDirections, updateQueryParamsMeta } from 'constants/ApplicationStateModel';
import {
    createApiKey, createApiKeySuccess, createApiKeyFailure,
    deleteApiKey, deleteApiKeyFailure, deleteApiKeySuccess,
    fetchApiKeysList, fetchApiKeysListFailure, fetchApiKeysListSuccess,
    fetchApiKeyValue, fetchApiKeyValueSuccess, fetchApiKeyValueFailure, clearApiKeysList,
} from 'redux/api-keys/actions';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const apiKeysReducerName = 'apiKeys';
export const apiKeysListsPerLocationStoreKeyName = 'apiKeysListsPerLocation';


export const defaultApiKeysListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
    clientId: undefined,
};

const initialApiKeysManagementState = {
    [apiKeysListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }


    apiKeyValuePerApiKeyId: {},

    isLoadingDeleteApiKey: false,
    isLoadingCreateApiKey: false,
};

const initialState = { ...initialApiKeysManagementState };

const handlers = {


    [fetchApiKeysList]: (state, action) => {
    // state.clientId = action.payload ( .clientId ?) ;
        state[apiKeysListsPerLocationStoreKeyName] = updateStandardLists(state[apiKeysListsPerLocationStoreKeyName], action);
    },
    [fetchApiKeysListSuccess]: (state, action) => {
    // TODO  move to epic & restore: state.promotions = markActivatedPromotions(action.payload || []);
        state[apiKeysListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[apiKeysListsPerLocationStoreKeyName], action);
    },
    [fetchApiKeysListFailure]: (state, action) => {
        state[apiKeysListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[apiKeysListsPerLocationStoreKeyName], action);
    },
    [clearApiKeysList]: (state, action) => {
        state[apiKeysListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[apiKeysListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[apiKeysListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[apiKeysListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [apiKeysRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === apiKeysReducerName
      && action.payload?.fieldName === apiKeysListsPerLocationStoreKeyName
        ) {
            state[apiKeysListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[apiKeysListsPerLocationStoreKeyName] }, action);
        }
    },


    [fetchApiKeyValue]: (state, action) => {
        state.apiKeyValuePerApiKeyId = {
            ...state.apiKeyValuePerApiKeyId,
            [action?.payload]: {
                isLoading: true,
                details: undefined,
                queryParams: { apiKeyUuid: action?.payload },
                queryParamsMeta: updateQueryParamsMeta(),
            },
        };
    },
    [fetchApiKeyValueSuccess]: (state, action) => {
        state.apiKeyValuePerApiKeyId = {
            ...state.apiKeyValuePerApiKeyId,
            [action?.payload?.apiKeyId]: {
                isLoading: false,
                value: action.payload?.value,
                queryParams: { apiKeyUuid: action?.payload?.apiKeyId },
                queryParamsMeta: updateQueryParamsMeta(),
            },
        };
    },
    [fetchApiKeyValueFailure]: (state, action) => {
        state.apiKeyValuePerApiKeyId = {
            ...state.apiKeyValuePerApiKeyId,
            [action?.payload]: {
                isLoading: false,
                value: undefined,
                queryParams: { apiKeyUuid: action?.payload },
                queryParamsMeta: updateQueryParamsMeta(),
            },
        };
    },

    [deleteApiKey]: (state) => {
        state.isLoadingDeleteApiKey = true;
    },
    [deleteApiKeySuccess]: (state) => {
        state.isLoadingDeleteApiKey = false;
    },
    [deleteApiKeyFailure]: (state) => {
        state.isLoadingDeleteApiKey = false;
    },


    [createApiKey]: (state) => {
        state.isLoadingCreateApiKey = true;
    },
    [createApiKeySuccess]: (state) => {
        state.isLoadingCreateApiKey = false;
    },
    [createApiKeyFailure]: (state) => {
        state.isLoadingCreateApiKey = false;
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
