import StandardListTable from 'components/common/organisms/StandardListTable';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import MarketingPreferencesListItemProps from 'constants/MarketingPreferences';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultMarketingPreferencesListQueryParams } from 'redux/marketing-preferences/reducer';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import createMarketingPreferencesListTableColumns from './createTableColumns';


function MarketingPreferencesList({
    t,

    clientId,
    memberId,

    listData,
    enhancedCurrentLocation,

    accessControl,
    dispatchFetchMarketingPreferencesList,
    dispatchClearMarketingPreferencesList,
    dispatchSetQueryParams,

    dispatchShowRequestMarketingPreferencesModal,
}) {
    const contextEnforcedQueryParams = { ...(clientId ? { clientId } : {}), ...(memberId ? { memberId } : []) };

    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };
            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchMarketingPreferencesList(prams, enhancedCurrentLocation?.pathname);
        }
    };

    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultMarketingPreferencesListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchMarketingPreferencesList,
        dispatchClearItemsList: dispatchClearMarketingPreferencesList,
        dispatchSetQueryParams,
    });


    const additionalButtons = useMemo(
        () => (
            [
                ...(accessControl.hasPermission(PN.CONFIG.MEMBER_MARKETING.EXPORT) ? [
                    {
                        type: 'primary',
                        text: t('marketingPreferences:buttons.showRequestMarkingPreferencesModal'),
                        onClick: () => dispatchShowRequestMarketingPreferencesModal({
                            memberId,
                            clientId,
                        }),
                    },
                ] : []),
            ]
        ), [],
    );


    return (
        <StandardListTable
            filtersButtonsConfig={additionalButtons}
            onUpdate={fetchData}
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createMarketingPreferencesListTableColumns({
                t,
                memberId,
            })}

        />
    );
}
MarketingPreferencesList.propTypes = {
    t: PropTypes.func.isRequired,

    clientId: PropTypes.number,
    memberId: PropTypes.number,

    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape(MarketingPreferencesListItemProps)),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    enhancedCurrentLocation: EnhancedCurrentLocationProps,

    accessControl: AccessControlProps,

    dispatchFetchMarketingPreferencesList: PropTypes.func.isRequired,
    dispatchClearMarketingPreferencesList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,

    dispatchShowRequestMarketingPreferencesModal: PropTypes.func.isRequired,
};

export default MarketingPreferencesList;
