import MigrationStatusTag from 'components/common/atoms/tags/MigrationStatusTag';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { createUserDetailsPath } from 'config/routes.helpers';
import { sortingOptions, sortNumbers, sortStringDates, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import { Space } from 'antd';
import React from 'react';

import {
    downloadResultsRenderer,
    migrationTypeRenderer,
    infoRenderer,
} from './MembersMigration.helpers';


const createClientMigrationsListColumnsConfig = ({
    clientId,
    dispatchDownloadMigrationResult,
    queryParams,
    enhancedCurrentLocation,
}) => [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'right',
        render: (text) => <StandardValue value={text} />,
        ...sortingOptions({
            queryParams,
            fieldName: 'id',
            sorter: (a, b) => sortNumbers(a, b, 'id'),
        }),
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        align: 'right',
        render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
        ...sortingOptions({
            queryParams,
            fieldName: 'created_at',
            sorter: (a, b) => sortStringDates(a, b, 'created_at'),
        }),
    },
    {
        title: 'Created by',
        dataIndex: 'creator',
        align: 'center',
        render: (creator) => (creator
            ? (
                <StandardLink
                    path={createUserDetailsPath(creator.id)}
                    title={`${creator.first_name} ${creator.last_name}`}
                />
            ) : '-'),
        ...sortingOptions({
            queryParams,
            fieldName: 'creator',
            sorter: (a, b) => sortNumbers(a, b, 'creator.id'),
        }),
    },
    {
        title: 'Migration type',
        dataIndex: 'type',
        align: 'center',
        render: migrationTypeRenderer,
        ...sortingOptions({
            queryParams,
            fieldName: 'type',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'type'),
        }),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: (status) => <MigrationStatusTag status={status} showTooltip />,
        ...sortingOptions({
            queryParams,
            fieldName: 'status',
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'status'),
        }),
    },
    {
        title: 'Update date',
        dataIndex: 'updated_at',
        align: 'right',
        render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
        ...sortingOptions({
            queryParams,
            fieldName: 'updated_at',
            sorter: (a, b) => sortStringDates(a, b, 'updated_at'),
        }),
    },

    {
        title: 'Date Finished',
        dataIndex: 'finished_at',
        align: 'right',
        render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
        ...sortingOptions({
            queryParams,
            fieldName: 'finished_at',
            sorter: (a, b) => sortStringDates(a, b, 'finished_at'),
        }),
    },

    {
        title: 'Results Failed',
        dataIndex: 'entity_failed_count',
        align: 'right',
        render: (text, record) => (
            <Space>
                {downloadResultsRenderer({
                    record,
                    clientId,
                    dispatchDownloadMigrationResult,
                    enhancedCurrentLocation,
                    fieldName: 'entity_failed_count',
                })}
                {infoRenderer(record)}
            </Space>
        ),
        ...sortingOptions({
            queryParams,
            fieldName: 'entity_failed_count',
            sorter: (a, b) => sortNumbers(a, b, 'entity_failed_count'),
        }),
    },
    {
        title: 'Results Created',
        dataIndex: 'entity_created_count',
        align: 'right',
        render: (text, record) => (
            <Space>
                {downloadResultsRenderer({
                    record,
                    clientId,
                    dispatchDownloadMigrationResult,
                    enhancedCurrentLocation,
                    fieldName: 'entity_created_count',
                })}

                <StandardValue
                    value={record.entity_created_count}
                    valueType={ValueTypes.COUNTER}
                />
            </Space>
        ),
        ...sortingOptions({
            queryParams,
            fieldName: 'entity_created_count',
            sorter: (a, b) => sortNumbers(a, b, 'entity_created_count'),
        }),
    },
];

export default createClientMigrationsListColumnsConfig;
