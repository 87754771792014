import withAccessControl from 'hocs/withAccessControl';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';


import AccountInfo from './AccountInfo.layout';
import { AccountInfoOwnProps } from './AccountInfo.types';


const decorate = compose<ComponentType<AccountInfoOwnProps>>(
    withTranslation('accounts'),
    withAccessControl,
);

export default decorate(AccountInfo);

