import styled from 'styled-components';


const MissingFlagPlaceholder = styled.div`
  width: ${({ size }) => (`${size}`)};
  height: ${({ size }) => (`${size}`)};
`;


export default { MissingFlagPlaceholder };
