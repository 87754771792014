import { commonValidation } from 'utils/validation-tools';

import { Form, Input } from 'antd';
import React, { useEffect, JSX } from 'react';


import { UnblockTransactionModalProps } from './UnblockTransactionModal.types';


function UnblockTransactionModal({
    dispatchUnblockTransaction,
    dispatchSetModalProps,
    initialValues,
    t,
}: UnblockTransactionModalProps): JSX.Element {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);


    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchUnblockTransaction({
            reason: values.reason,
            transactionUuid: initialValues.transactionUuid,
        },
        initialValues.baseUrlWithoutTabsSlugs);
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onFinish}
                initialValues={{ reason: '' }}
            >
                <Form.Item
                    name="reason"
                    label={t('transactions:modals.unblockTransaction.fields.reason.text')}
                    rules={[...commonValidation({ isRequired: true, t })]}
                >
                    <Input placeholder={t('transactions:modals.unblockTransaction.fields.reason.text')} />
                </Form.Item>

            </Form>
        </div>
    );
}

export default UnblockTransactionModal;
