import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import styled from 'styled-components';


const CurrencyOptionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const CurrencyOptionText = styled.span`
  max-width: 100%;
  margin-left: 10px;
  ${commonTextEllipsisStyles};
`;

const IconWrapper = styled.div`
  justify-self: right;
  margin: 0 0 0 auto;
  cursor: help;
`;


export default {
    CurrencyOptionWrapper,
    CurrencyOptionText,
    IconWrapper,
};
