export const REQUEST_SET_MULTI_STEPS_FORM_STEP = 'REQUEST_SET_MULTI_STEPS_FORM_STEP';
export const SET_MULTI_STEPS_FORM_STEP = 'SET_MULTI_STEPS_FORM_STEP';

export const REQUEST_SET_MULTI_STEPS_FORM_SUB_STEP = 'REQUEST_SET_MULTI_STEPS_FORM_SUB_STEP';
export const SET_MULTI_STEPS_FORM_SUB_STEP = 'SET_MULTI_STEPS_FORM_SUB_STEP';

export const SET_MULTI_STEPS_FORM_META_DATA = 'SET_MULTI_STEPS_FORM_META_DATA';
export const CLEAR_MULTI_STEPS_FORM_META_DATA = 'CLEAR_MULTI_STEPS_FORM_META_DATA';


export const SET_MULTI_STEPS_FORM_DATA = 'SET_MULTI_STEPS_FORM_DATA';
export const CLEAR_MULTI_STEPS_FORM_DATA = 'CLEAR_MULTI_STEPS_FORM_DATA';
