import AppLoadingPlaceholder from 'components/layouts/AppLoadingPlaceholder';
import DrawerRoot from 'components/layouts/AuthorisedLayout/DrawerRoot';
import AppIdleTimer from 'components/layouts/AuthorisedLayout/IdleTimer';
import ModalRoot from 'components/layouts/AuthorisedLayout/ModalRoot';
import NavigationGuard from 'components/layouts/AuthorisedLayout/NavigationGuard';
import SideBar from 'components/layouts/AuthorisedLayout/SideBar';
import { APPLICATION_STATE } from 'constants/ApplicationStateModel';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


import Styled from './AuthorisedLayout.styled';


function AuthorisedLayout({
    children,
    applicationState,
    dispatchClearCurrentUser,
    isRefreshingSession,
    shouldFetchGlobalData,
}) {
    useEffect(() => {
        document.body.classList.toggle('no-scroll', isRefreshingSession);
    }, [isRefreshingSession]);

    const shouldShowLoadingPlaceholder = isRefreshingSession
  || shouldFetchGlobalData
   || applicationState === APPLICATION_STATE.AUTHORISED_GLOBAL_DATA_FETCHING;

    return (
        <AppIdleTimer
            dispatchClearCurrentUser={dispatchClearCurrentUser}
        >
            {/* XXX overlay to prevent initiating new requests during token refresh or failed fetches on global data */}
            {
                shouldShowLoadingPlaceholder
        && <AppLoadingPlaceholder asOverlay isRefreshingSession={isRefreshingSession} shouldFetchGlobalData={shouldFetchGlobalData} />
            }

            <Styled.LayoutOuterWrapper id="application-wrapper">
                <Styled.LayoutUiWrapper>
                    <SideBar />
                    <Styled.LayoutRouteContentWrapper>
                        {children}
                    </Styled.LayoutRouteContentWrapper>
                </Styled.LayoutUiWrapper>


                <Styled.LayoutSpecialElementsWrapper id="modals-wrapper">
                    <NavigationGuard />
                    <ModalRoot />
                    <DrawerRoot />
                </Styled.LayoutSpecialElementsWrapper>

            </Styled.LayoutOuterWrapper>
        </AppIdleTimer>
    );
}

AuthorisedLayout.propTypes = {
    children: PropTypes.node.isRequired,
    applicationState: PropTypes.oneOf(Object.values(APPLICATION_STATE)).isRequired,
    dispatchClearCurrentUser: PropTypes.func.isRequired,
    isRefreshingSession: PropTypes.bool,
    shouldFetchGlobalData: PropTypes.bool,
};

export default AuthorisedLayout;
