import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import OffboardingButtons from 'components/common/molecules/OffboardingButtons';
import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import ResetKycKybButton from 'components/common/molecules/ResetKycKybButton';
import UnlockLogin from 'components/common/molecules/UnlockLogin';
import CommonSection from 'components/common/organisms/CommonSection';
import {
    createBusinessUserBasicDetailsFields,
    createBusinessUserSystemDetailsFields,
} from 'components/pages/authorised/BusinessUserDetailsPage/BusinessUserPersonalDetails/BusinessUserPersonalDetails.helpers';
import { CommonQueryParamsMetaProps } from 'constants/ApplicationStateModel';
import { BusinessUserProps, BusinessUserStatus } from 'constants/BusinessUserModel';

import { Row, Spin, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function BusinessUserPersonalDetails({
    entityDetails = {},
    areDetailsReady,
    isBusy,
    onDetailsEdit,
    parentEntityIsInReadOnlyMode,
    parentEntityName,
    queryParamsMeta,
    onRefresh,
    baseUrlWithoutTabsSlugs,
    t,
}) {
    const businessUserBasicDetailsFields = useMemo(
        () => createBusinessUserBasicDetailsFields({ entityDetails, t }),
        [entityDetails],
    );

    const isUserEditable = useMemo(
        () => BusinessUserStatus.ACTIVE === entityDetails?.business_user_status,
        [entityDetails],
    );

    const businessUserSystemDetailsFields = useMemo(
        () => createBusinessUserSystemDetailsFields({ entityDetails, t }),
        [entityDetails],
    );


    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={!areDetailsReady && isBusy}
                minHeight={'370px'}
                sectionsRowsCount={8}
                sectionsCount={2}
            >
                <Spin tip="Processing..." spinning={isBusy}>
                    <DetailsSectionsWrapper>
                        <Row gutter={24} size={10} direction="horizontal">
                            <CommonSection
                                sectionLabel="General Information"
                                sectionEditTooltip={onDetailsEdit && isUserEditable ? 'Edit business user details' : undefined}
                                fieldsConfig={businessUserBasicDetailsFields}
                                sectionInfoTooltip={onDetailsEdit && !isUserEditable ? 'Only business users in ACTIVE status can be edited ' : undefined}
                                sectionEditOnClick={onDetailsEdit && isUserEditable ? onDetailsEdit : undefined}
                            />

                            <CommonSection
                                sectionLabel="System Information"
                                fieldsConfig={businessUserSystemDetailsFields}
                            />
                        </Row>

                        <OptionalButtonsWrapper>
                            <OffboardingButtons
                                entityName={parentEntityName}
                                parentEntityIsNotOffboarding={parentEntityIsInReadOnlyMode}
                            />

                            <ResetKycKybButton
                                parentEntityIsInReadOnlyMode={parentEntityIsInReadOnlyMode}
                                businessUserId={entityDetails?.business_user_id}
                                entityName={`${entityDetails?.first_name} ${entityDetails?.last_name}`}
                                baseUrlWithoutTabsSlugs={baseUrlWithoutTabsSlugs}
                            />


                            <UnlockLogin
                                extUserUuid={entityDetails?.business_user_uuid}
                                isLocked={entityDetails?.is_permanently_locked}
                                context={{ businessUserId: entityDetails?.business_user_id, locationPathname: baseUrlWithoutTabsSlugs }}
                            />

                        </OptionalButtonsWrapper>

                        <QueryParamsMetaInfo
                            detailsContext
                            onListRefresh={onRefresh}
                            queryParams={{ businessUserId: entityDetails?.business_user_id }}
                            queryParamsMeta={queryParamsMeta}
                        />
                    </DetailsSectionsWrapper>
                </Spin>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}

BusinessUserPersonalDetails.propTypes = {
    entityDetails: BusinessUserProps,
    onDetailsEdit: PropTypes.func.isRequired,
    baseUrlWithoutTabsSlugs: PropTypes.string.isRequired,
    onRefresh: PropTypes.func.isRequired,
    parentEntityIsInReadOnlyMode: PropTypes.bool,
    parentEntityName: PropTypes.string,
    queryParamsMeta: CommonQueryParamsMetaProps,
    areDetailsReady: PropTypes.bool,
    isBusy: PropTypes.bool,
};

export default BusinessUserPersonalDetails;

