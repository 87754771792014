import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import { WL_CONFIG } from 'config/config';
import { clientRootRoutePath } from 'config/routes';
import { AccessControlProps } from 'constants/AccessControlModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import { OutstandingDebtTypes } from 'constants/OutstandingDebtModel';

import { Alert } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


import Styled from './OverdueInvoiceWarning.styled';


function OverdueInvoiceWarning({
    t,
    enhancedCurrentLocation,
    accessControl,
}) {
    const { isSuperAdmin, clientConfig } = accessControl;
    const outstandingDebt = clientConfig?.generalSettings?.outstandingDebt;
    const [shouldShowWarning, setShouldShowWarning] = useState(false);

    useEffect(() => {
        if (isSuperAdmin) {
            const regex = new RegExp(`^${clientRootRoutePath}/.*edit-settings$`);
            const isAdminEditingClientSettings = regex.test(enhancedCurrentLocation.pathname);

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            [OutstandingDebtTypes.WARNING_BANNER, OutstandingDebtTypes.RESTRICTED_BANNER].includes(outstandingDebt) && !isAdminEditingClientSettings
                ? setShouldShowWarning(true)
                : setShouldShowWarning(false);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            [OutstandingDebtTypes.WARNING_BANNER, OutstandingDebtTypes.RESTRICTED_BANNER].includes(outstandingDebt)
                ? setShouldShowWarning(true)
                : setShouldShowWarning(false);
        }
    }, [isSuperAdmin, outstandingDebt]);

    return shouldShowWarning && (
        <Styled.OverdueInvoiceWarningWrapper>
            <Alert
                description={
                    outstandingDebt === OutstandingDebtTypes.WARNING_BANNER
                        ? t(`common:overdueInvoiceWarnings.warningBanner.${isSuperAdmin ? 'superAdmin' : 'nonSuperAdmin'}`)
                        : (
                            <StandardTranslationWithMarkup
                                translationKey={'overdueInvoiceWarnings.restrictedBanner'}
                                namespace="common"
                                values={{ contactEmail: WL_CONFIG.externalLinks.overdueInvoiceRestrictedBannerContactEmail }}
                                t={t}
                            />
                        )
                }
                type={outstandingDebt === OutstandingDebtTypes.WARNING_BANNER ? 'warning' : 'error'}
                showIcon
            />
        </Styled.OverdueInvoiceWarningWrapper>
    );
}

OverdueInvoiceWarning.propTypes = {
    accessControl: AccessControlProps,
    enhancedCurrentLocation: EnhancedCurrentLocationProps,
    t: PropTypes.func,
};
export default OverdueInvoiceWarning;

