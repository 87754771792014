import { CurrenciesListProp } from 'constants/CurrencyModel';
import { filterOptions } from 'utils/filterOptions';
import { createCurrencyOptions } from 'utils/options-creators';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import Styled from './AddNewProductTile.styled';


function AddNewProductTile({
    currencies,
    sectionAccountType,
    parentListFieldName,
    t,
}) {
    const formInstance = Form.useFormInstance();
    const currentFieldValue = formInstance.getFieldValue(parentListFieldName);

    const [isMiniFormVisible, setIsMiniFormVisible] = useState(false);
    const [selectedNewCurrencies, setSelectedNewCurrencies] = useState([]);

    const existingCurrencies = useMemo(
        () => {
            const tmp = currentFieldValue.reduce((acc, product) => {
                if (product.account_type === sectionAccountType) {
                    if (acc[product.currency]) {
                        return acc;
                    }

                    return {
                        ...acc,
                        [product.currency]: true,
                    };
                }

                return acc;
            }, {});

            return Object.keys(tmp);
        },
        [currentFieldValue, isMiniFormVisible],
    );

    const availableCurrencies = useMemo(
        () => createCurrencyOptions(currencies.filter((currency) => !existingCurrencies.includes(currency.short)), t),
        [currencies, existingCurrencies],
    );

    const onChange = (values) => {
        setSelectedNewCurrencies(values);
    };

    const onCancel = () => {
        setIsMiniFormVisible(false);
        setSelectedNewCurrencies([]);
    };

    const onSubmit = () => {
        const values = [...selectedNewCurrencies];

        const newProducts = values.map((value) => ({
            account_type: sectionAccountType,
            currency: value,
            bps_product_id: undefined,
        }));

        // XXX set new value on form
        formInstance.setFieldsValue({
            [parentListFieldName]: [
                ...currentFieldValue,
                ...newProducts,
            ],
        });

        setIsMiniFormVisible(false);
        setSelectedNewCurrencies([]);
    };


    return (
        <Styled.AddProductItemTileWrapper>
            {isMiniFormVisible ? (
                <Styled.AddProductItemTileFromWrapper>
                    <Styled.FormHeadingWrapper>
            Select new currency
                    </Styled.FormHeadingWrapper>

                    <Styled.MultiCurrencyFieldWrapper>
                        <Select
                            allowClear
                            showSearch
                            value={selectedNewCurrencies}
                            mode={'multiple'}
                            onChange={onChange}
                            filterOption={filterOptions}
                            placeholder={t('Type to search')}
                            notFoundContent={t('That is all, no more currencies to add')}
                        >
                            {availableCurrencies}
                        </Select>
                    </Styled.MultiCurrencyFieldWrapper>

                    <Styled.FormButtonsWrapper>
                        <Button
                            type="default"
                            onClick={onCancel}
                        >
              Cancel
                        </Button>

                        <Button
                            disabled={!selectedNewCurrencies?.length > 0}
                            type="primary"
                            onClick={onSubmit}
                        >
              Add
                        </Button>
                    </Styled.FormButtonsWrapper>

                </Styled.AddProductItemTileFromWrapper>

            ) : (
                <Styled.AddProductItemTileAddPlaceholderWrapper>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setIsMiniFormVisible(true)}
                    >
            Add new product
                    </Button>
                </Styled.AddProductItemTileAddPlaceholderWrapper>
            )}
        </Styled.AddProductItemTileWrapper>
    );
}

AddNewProductTile.propTypes = {
    currencies: CurrenciesListProp,
    sectionAccountType: PropTypes.string.isRequired,
    t: PropTypes.func,
};

export default AddNewProductTile;
