import StandardListSkeleton from 'components/common/atoms/StandardListSkeleton';

import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import RawTable from './RawTable.layout';
import Styled from './StandardTable.styled';
import { CustomTableProps } from './propTypes';


function StandardTable({
    loadable = true,
    isLoadingPlaceholder,
    isTableLoading,
    totalItemsCount,
    onListRefresh,
    t,
    columnsConfig,
    queryParams,
    removeFilterSection,
    ...restProps
}) {
    return (
        <StandardListSkeleton
            isLoading={isLoadingPlaceholder}
            active={loadable}
            columnsCount={columnsConfig?.length}
            sectionsRowsCount={queryParams?.limit}
        >
            {!isTableLoading && onListRefresh && totalItemsCount === null
                ? (
                    <Styled.ErrorOuterWrapper>
                        <Styled.ErrorWrapper>
                            <p>{t('common:standardListApiError')}</p>
                            <Button
                                onClick={onListRefresh}
                                type="primary"
                                icon={<RedoOutlined />}
                                loading={isTableLoading}
                                disabled={isTableLoading}
                            >
                                {t('common:buttons.refresh.text')}
                            </Button>
                        </Styled.ErrorWrapper>
                    </Styled.ErrorOuterWrapper>
                )
                : (
                    <RawTable
                        queryParams={queryParams}
                        columnsConfig={columnsConfig}
                        {...restProps}
                        totalItemsCount={totalItemsCount}
                        isTableLoading={isTableLoading}
                        loadable={loadable}
                    />
                )}

        </StandardListSkeleton>
    );
}

StandardTable.propTypes = CustomTableProps.props;
// TODO  Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead.
StandardTable.defaultProps = CustomTableProps.default;

export default StandardTable;
