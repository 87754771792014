/* eslint-disable class-methods-use-this */
import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';


import {
    getCompanyIdDetails,
    updateCompanyId,
    getCompaniesApi,
    createCompanyAPI,
    getCompanyIdRiskDetails,
    createCompanyIdRiskDetails,
    getStakeholdersByCompanyId,
    createBusinessUserForCompanyId,
    getCompanyStatusHistoryAPI,
    updateCompanyStatusAPI,
} from './index';


class CompaniesService {
    public getCompanies(rawQuery): Promise<ApiResponse | ApiError> {
        const {
            clientId,
            search,
            includeInactivePartners,
            sortOrder,
            sortBy = 'id',
            ...restOfQueryParams
        } = rawQuery;

        const queryParams = {
            ...restOfQueryParams,
            ...(includeInactivePartners === true ? { includeInactivePartners } : {}),
            ...({ order: [`${sortOrder === SortDirections.DESCEND ? `-${sortBy}` : `${sortBy}`}`] }), // TODO v2+ Inconsistencies!!!
            ...(search && search.length > 0 ? { search } : {}),
            ...(clientId && clientId !== 0 ? { clientId } : {}),
        };

        return getCompaniesApi({ params: queryParams });
    }


    public getCompanyDetails(queryParams): Promise<ApiResponse | ApiError> {
        const { companyId } = queryParams;
        return getCompanyIdDetails(companyId);
    }

    public updateCompany(companyId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateCompanyId(companyId, requestPayload);
    }

    public createCompany(requestPayload): Promise<ApiResponse | ApiError> {
        return createCompanyAPI(requestPayload);
    }

    public getCompanyRiskDetails(companyId): Promise<ApiResponse | ApiError> {
        return getCompanyIdRiskDetails(companyId);
    }

    public createCompanyRiskDetails(companyId, requestPayload): Promise<ApiResponse | ApiError> {
        return createCompanyIdRiskDetails(companyId, requestPayload);
    }

    public getStakeholdersByCompany(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { companyId, limit, offset, withTotal } = rawQueryParams;

        return getStakeholdersByCompanyId(companyId, { params: { limit, offset, withTotal } });
    }

    public createBusinessUserForCompany(formData): Promise<ApiResponse | ApiError> {
        const { companyId, clientId, ...rest } = formData;

        return createBusinessUserForCompanyId(companyId, rest);
    }

    public getCompanyStatusHistory(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { companyId, sortOrder, ...rest } = rawQueryParams;
        return getCompanyStatusHistoryAPI(companyId, {
            params: {
                ...rest,
                ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
            },
        });
    }

    public updateCompanyStatus(companyId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateCompanyStatusAPI(companyId, requestPayload);
    }
}

export default CompaniesService;

