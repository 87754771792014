import StandardListTable from 'components/common/organisms/StandardListTable';
import { createAddPromotionPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultPromotionsListQueryParams } from 'redux/promotions/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createPromotionsTableColumns from './createTableColumns';


function PromotionsList({
    // own props
    clientId,
    groupId,
    feeUuid,
    buttonsConfig,

    // list state data
    enhancedCurrentLocation,
    listData,

    // other mapped props
    t,
    accessControl,
    dispatchRemovePromotion,
    dispatchRemovePromotionFromGroup,
    dispatchFetchPromotionsList,
    dispatchClearPromotionsList,
    dispatchSetQueryParams,
    dispatchRequestNavigation,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ...((clientId > 0 && (!groupId > 0 || !feeUuid?.length > 0)) ? { clientId } : {}),
        ...(groupId > 0 ? { groupId } : {}),
        ...(feeUuid?.length > 0 ? { feeUuid } : {}),
    };

    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };
            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultPromotionsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchPromotionsList,
        dispatchClearItemsList: dispatchClearPromotionsList,
        dispatchSetQueryParams,
    });

    // 4a. list specific stuff - local search, local sorting is in table configuration
    const filteredPromotions = useMemo(
        () => {
            if (listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((promotion) => searchCaseInsensitive(promotion.name, listData?.queryParams?.search));
            }

            return listData?.items || [];
        }, [listData],
    );

    const ownButtons = accessControl.hasPermission(PN.PROMOTIONS.CREATE)
        ? [
            {
                type: 'primary',
                text: t('promotions:buttons:addPromotion.text'),
                dataTestId: 'add-promotion-button',
                icon: <PlusOutlined />,
                onClick: () => dispatchRequestNavigation(createAddPromotionPath({ clientId })),
            },
        ] : [];


    // 4b. list specific stuff - action buttons
    const buttons = buttonsConfig || ownButtons;

    return (
        <StandardListTable
            rowKey="ext_id"
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchPromotionsList({ ...contextEnforcedQueryParams }, enhancedCurrentLocation.pathname)}
            items={filteredPromotions}
            totalItemsCount={filteredPromotions.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createPromotionsTableColumns({
                accessControl,
                t,
                queryParams: listData?.queryParams,
                clientId,
                groupId,
                dispatchRemovePromotion,
                dispatchRemovePromotionFromGroup,
                dispatchRequestNavigation,
                enhancedCurrentLocation,
            })}
            filtersButtonsConfig={buttons}
        />
    );
}

PromotionsList.propTypes = {
    accessControl: AccessControlProps,
    t: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchRemovePromotionFromGroup: PropTypes.func.isRequired,
    dispatchRemovePromotion: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    clientId: PropTypes.number,
    feeUuid: PropTypes.string,
    groupId: PropTypes.number,
    // mapped props
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),

    buttonsConfig: PropTypes.arrayOf(PropTypes.shape({})),
};


export default PromotionsList;
