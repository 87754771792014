import GlobalCommonElements from 'assets/styles/common.styled';
import GlobalFonts from 'assets/styles/fonts.styled';
import AppHasInvalidConfigPlaceholder from 'components/layouts/AppHasInvalidConfigPlaceholder';
import { getInstanceConfigurationErrors } from 'components/layouts/AppHasInvalidConfigPlaceholder/AppHasInvalidConfigPlaceholder.helpers';
import App from 'components/layouts/AppRoutes';
import { appVersion, debugMode, WL_CONFIG } from 'config/config';
import initializeSentry from 'config/sentry';
import { defaultTheme } from 'config/theme';
import { Dependencies } from 'models/app/store';
import { browserWindow } from 'models/app/window';
import { initApplication } from 'redux/application/actions';
import { createStore } from 'redux/createStore';
import FileService from 'services/FileService';
import AccountsService from 'services/accounts/AccountsService';
import AddressesService from 'services/addresses/AddressesService';
import ApiKeysService from 'services/api-keys/ApiKeysService';
import ApplicationsService from 'services/applications/ApplicationsService';
import AuthorisationService from 'services/authorisation/AuthorisationService';
import BeneficiariesService from 'services/beneficiaries/BeneficiariesService';
import BusinessUsersService from 'services/business-users/BusinessUsersService';
import CardSecure3dService from 'services/card-secure3d/CardSecure3dService';
import CardTiersService from 'services/card-tiers/CardTiersService';
import CardsService from 'services/cards/CardsService';
import ClientsService from 'services/clients/ClientsService';
import CompaniesService from 'services/companies/CompaniesService';
import ConfigService from 'services/config/ConfigService';
import ConfigurationService from 'services/configuration/ConfigurationService';
import CountriesService from 'services/countries/CountriesService';
import CurrenciesService from 'services/currencies/CurrenciesService';
import ExchangeService from 'services/exchange/ExchangeService';
import FeesService from 'services/fees/FeesService';
import GroupsService from 'services/groups/GroupsService';
import { createHttpService, HttpService } from 'services/http';
import IndustriesService from 'services/industries/IndustriesService';
import KycKybService from 'services/kyc-kyb/KycKybService';
import LocationService from 'services/location/LocationService';
import MembersService from 'services/members/MembersService';
import OffboardingService from 'services/offboarding/OffboardingService';
import OperationsService from 'services/operations/OperationsService';
import PermissionsService from 'services/permissions/PermissionsService';
import PromotionsService from 'services/promotions/PromotionsService';
import ReportsService from 'services/reports/ReportsService';
import RiskService from 'services/risk/RiskService';
import RolesService from 'services/roles/RolesService';
import RulesService from 'services/rules/RulesService';
import TopupService from 'services/topup/TopupService';
import TransactionsService from 'services/transactions/TransactionsService';
import UsersService from 'services/users/UsersService';
import WebhooksService from 'services/webhooks/WebhooksService';
import { appWindow } from 'utils/dom-tools';

import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import { createBrowserHistory } from 'history';
// eslint-disable-next-line no-restricted-imports
import i18next from 'i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';


dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

// XXX for debug
appWindow.applicationVersionData = () => {
    // eslint-disable-next-line no-console
    console.dir(appVersion());
};

// DOM mounting point
const wrapper = document.getElementById('root') || document.body;

const root = createRoot(wrapper);
// all refactored v2 (and up) endpoints are served by this service
const http = createHttpService();

// XXX history object common for react-router-dom, store/epics & browser navigation
const history = createBrowserHistory({ window });

const location = new LocationService(history, window.location);

// error tracker
initializeSentry(history);

// redux-observables (RxJS) middleware dependencies
const dependencies: Dependencies = {
    document,
    history,
    i18n: i18next,
    http,
    location,

    accounts: new AccountsService(),
    addresses: new AddressesService(),
    applications: new ApplicationsService(),
    apiKeys: new ApiKeysService(),
    authorisation: new AuthorisationService(),
    beneficiaries: new BeneficiariesService(),
    cards: new CardsService(),
    cardSecure3d: new CardSecure3dService(),
    clients: new ClientsService(),
    companies: new CompaniesService(),
    countries: new CountriesService(),
    currencies: new CurrenciesService(),
    cardTiers: new CardTiersService(),
    configuration: new ConfigurationService(),
    config: new ConfigService(),
    businessUsers: new BusinessUsersService(),
    exchange: new ExchangeService(),
    fees: new FeesService(),
    file: new FileService(),
    groups: new GroupsService(),
    kycKyb: new KycKybService(),
    industries: new IndustriesService(),
    members: new MembersService(),
    offboarding: new OffboardingService(),
    operations: new OperationsService(),
    permissions: new PermissionsService(),
    promotions: new PromotionsService(),
    risks: new RiskService(),
    roles: new RolesService(),
    rules: new RulesService(),
    reports: new ReportsService(),
    transactions: new TransactionsService(),
    topup: new TopupService(),
    users: new UsersService(),
    webhooks: new WebhooksService(),
};

// redux store initialisation
const storeInstance = createStore(dependencies);

//  allow HttpService dispatch of actions
HttpService.configure(storeInstance.dispatch);
storeInstance.dispatch(initApplication());

const instanceConfigurationErrors = getInstanceConfigurationErrors();

if (browserWindow?.Cypress) {
    browserWindow.store = storeInstance;
}

const appContent = instanceConfigurationErrors && instanceConfigurationErrors.length > 0
    ? <AppHasInvalidConfigPlaceholder instanceConfigurationErrors={instanceConfigurationErrors} />
    : (
        <>
            <Helmet>
                <title>{`${WL_CONFIG.brandShortName} ${WL_CONFIG.applicationName}`}</title>
                {WL_CONFIG.brandFaviconUrl && (
                    <link rel="icon" href={WL_CONFIG.brandFaviconUrl} />
                )}
            </Helmet>

            <Provider store={storeInstance}>
                { /* @ts-expect-error since TS 5.3.3 -  Type 'BrowserHistory' is missing the following properties from type 'History': createURL, encodeLocation */ }
                <HistoryRouter history={history}>
                    <ConfigProvider theme={defaultTheme}>
                        <App />

                        <GlobalCommonElements />
                        <GlobalFonts />
                    </ConfigProvider>
                </HistoryRouter>
            </Provider>
        </>
    );


root.render(debugMode
    ? appContent
    : appContent);

