export const FETCH_FAILED_WEBHOOK_DETAILS = 'FETCH_FAILED_WEBHOOK_DETAILS';
export const FETCH_FAILED_WEBHOOK_DETAILS_SUCCESS = 'FETCH_FAILED_WEBHOOK_DETAILS_SUCCESS';
export const FETCH_FAILED_WEBHOOK_DETAILS_FAILURE = 'FETCH_FAILED_WEBHOOK_DETAILS_FAILURE';

export const CLEAR_FAILED_WEBHOOK_DETAILS = 'CLEAR_MEMBER_DETAILS';

export const REPLAY_FAILED_WEBHOOK = 'REPLAY_FAILED_WEBHOOK';
export const REPLAY_FAILED_WEBHOOK_SUCCESS = 'REPLAY_FAILED_WEBHOOK_SUCCESS';
export const REPLAY_FAILED_WEBHOOK_FAILURE = 'REPLAY_FAILED_WEBHOOK_FAILURE';

