import { AccountIdentificationCategory } from 'constants/AccountModel';
import { AddEditBeneficiarySteps } from 'constants/BeneficiariesModel';
import { updateBeneficiary } from 'redux/beneficiary/actions';
import {
    fetchBeneficiaryRequirements,
    createBeneficiary,
    setAddEditBeneficiaryFormStep, initialiseAddEditBeneficiaryFormSuccess,
} from 'redux/beneficiary-add/actions';
import { cleanNilValues } from 'utils/dataMappers';

import { EMPTY, of } from 'rxjs';


export const mapCurrentStepToNumber = (
    currentStep,
    isEditMode = false,
) => {
    const handlers = [
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.BASIC_DATA,
            handler: () => 0,
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.ADDRESS && isEditMode,
            handler: () => 0,
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.ADDRESS && !isEditMode,
            handler: () => 1,
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.ACCOUNT_DETAILS && !isEditMode,
            handler: () => 2,
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.ACCOUNT_DETAILS && isEditMode,
            handler: () => 1,
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.REVIEW && !isEditMode,
            handler: () => 3,
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.REVIEW && isEditMode,
            handler: () => 2,
        },
        {
            predicate: () => true,
            handler: () => -1,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


const sameRequirements = (newData, currentData) => currentData?.country === newData?.country && currentData?.currency === newData?.currency;


export const createOnSubmitFormStepActions = ({
    currentStep,
    contextData,
    newData,
    currentData,
}) => {
    const { isEditMode, beneficiaryId, memberId, companyId, clientId } = contextData;

    const handlers = [
        { // 1.b on go back when only beneficiaryType was changed (or nothing at all) - skip requirements fetching
            predicate: () => currentStep === AddEditBeneficiarySteps.BASIC_DATA && sameRequirements(newData, currentData),
            handler: () => of(setAddEditBeneficiaryFormStep(AddEditBeneficiarySteps.ADDRESS, newData)),
        },
        { // 1.a standard flow
            predicate: () => currentStep === AddEditBeneficiarySteps.BASIC_DATA && !sameRequirements(newData, currentData),
            handler: () => of(fetchBeneficiaryRequirements({
                ...newData,
                ...(memberId > 0 ? { memberId } : {}),
                ...(companyId > 0 ? { companyId } : {}),
                ...(clientId > 0 && !memberId > 0 && !companyId > 0
                    ? { clientId }
                    : {}),
            })),
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.ADDRESS,
            handler: () => of(setAddEditBeneficiaryFormStep(AddEditBeneficiarySteps.ACCOUNT_DETAILS, newData)),
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.ACCOUNT_DETAILS,
            handler: () => of(setAddEditBeneficiaryFormStep(AddEditBeneficiarySteps.REVIEW, newData)),
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.REVIEW,
            handler: () => {
                const {
                    // TODO
                    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-shadow
                    id, updated_at, client_id, clientId, company_id, companyId, member_id, memberId, created_at, identification_category,
                    country,
                    currency,
                    beneficiary_type,
                    account_identifications,
                    ...allowedFields
                } = { ...currentData, ...newData };

                const filteredIdentifications = account_identifications.map(
                    // TODO
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    ({ identification_category, identification_type, identification_value }) => ({ identification_category, identification_type, identification_value }),
                );

                const fixedPayload = cleanNilValues({
                    ...allowedFields,
                    accountIdentifications: filteredIdentifications,
                    ...(isEditMode ? {} : { country }),
                    ...(isEditMode ? {} : { currency }),
                    ...(isEditMode ? {} : { company_id: companyId }),
                    ...(isEditMode ? {} : { member_id: memberId }),
                    ...(isEditMode ? {} : { client_id: clientId }),
                    ...(isEditMode ? {} : { beneficiary_type }),
                }, true);

                if (isEditMode) {
                    return of(updateBeneficiary(beneficiaryId, fixedPayload));
                }

                return of(createBeneficiary(fixedPayload));
            },
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createOnGoToPreviousFormStepActions = (currentStep) => {
    const handlers = [
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.REVIEW,
            handler: () => of(setAddEditBeneficiaryFormStep(AddEditBeneficiarySteps.ACCOUNT_DETAILS)),
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.ACCOUNT_DETAILS,
            handler: () => of(setAddEditBeneficiaryFormStep(AddEditBeneficiarySteps.ADDRESS)),
        },
        {
            predicate: () => currentStep === AddEditBeneficiarySteps.ADDRESS,
            handler: () => of(setAddEditBeneficiaryFormStep(AddEditBeneficiarySteps.BASIC_DATA)),
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export const createContext = ({ ownerContext, currentUserClientId }) => {
    const handlers = [
        {
            predicate: () => ownerContext?.beneficiaryId?.length > 0,
            handler: () => ({
                returnPath: ownerContext?.returnPath,
                beneficiaryId: ownerContext?.beneficiaryId,
                name: ownerContext?.beneficiaryName,
                isEditMode: true,
            }),
        },
        {
            predicate: () => ownerContext?.clientId > 0,
            handler: () => ({
                returnPath: ownerContext?.returnPath,
                clientId: ownerContext?.clientId,
                name: ownerContext?.ownerName,
            }),
        },
        {
            predicate: () => ownerContext?.memberId > 0,
            handler: () => ({
                returnPath: ownerContext.returnPath,
                memberId: ownerContext?.memberId,
                name: ownerContext?.ownerName,
            }),
        },
        {
            predicate: () => ownerContext?.companyId > 0,
            handler: () => ({
                returnPath: ownerContext.returnPath,
                companyId: ownerContext?.companyId,
                name: ownerContext.ownerName,
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                returnPath: ownerContext?.returnPath,
                clientId: currentUserClientId,
                name: ownerContext?.ownerName,
            }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createOnInitialiseAddEditBeneficiaryFormActions = ({ ownerContext, formData, state }) => {
    const contextData = createContext({
        ownerContext,
        currentUserClientId: state.currentUser.userData?.clientId,
    });

    const handlers = [
        {
            predicate: () => contextData.isEditMode && formData,
            handler: () => of(
                fetchBeneficiaryRequirements({
                    ...formData,
                    ...(ownerContext?.memberId > 0 ? { memberId: ownerContext?.memberId } : {}),
                    ...(ownerContext?.companyId > 0 ? { companyId: ownerContext?.companyId } : {}),
                    ...(ownerContext?.clientId > 0 && !ownerContext?.memberId > 0 && !ownerContext?.companyId > 0
                        ? { clientId: ownerContext?.clientId }
                        : {}),
                }),
                initialiseAddEditBeneficiaryFormSuccess(contextData, formData),
            ),
        },
        {
            predicate: () => true,
            handler: () => of(initialiseAddEditBeneficiaryFormSuccess(contextData)),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export const extractDataForEditForm = (rawBeneficiaryDetails) => {
    const {
        first_name,
        middle_name,
        last_name,
        company_name,
        currency,
        country,
        address,
        account_identifications,
        beneficiary_type,
    } = rawBeneficiaryDetails;


    return {
        first_name,
        middle_name,
        last_name,
        company_name,
        currency,
        country,
        address,
        account_identifications,
        beneficiary_type,
    };
};

export const parseRawBeneficiariesRequirements = (rawBeneficiariesRequirements) => {
    const beneficiariesRequirements = (rawBeneficiariesRequirements || []).reduce((acc, requirement) => {
        if (requirement.identification_category === AccountIdentificationCategory.LOCAL) {
            acc[AccountIdentificationCategory.LOCAL] = [
                ...(acc[AccountIdentificationCategory.LOCAL] || []),
                { ...requirement, identification_value: '' },

            ];
        }
        if (requirement.identification_category === AccountIdentificationCategory.GLOBAL) {
            acc[AccountIdentificationCategory.GLOBAL] = [
                ...(acc[AccountIdentificationCategory.GLOBAL] || []),
                { ...requirement, identification_value: '' },
            ];
        }
        return acc;
    }, {});

    return beneficiariesRequirements;
};


export default {
    createContext,
    mapCurrentStepToNumber,
    createOnSubmitFormStepActions,
    createOnGoToPreviousFormStepActions,
    createOnInitialiseAddEditBeneficiaryFormActions,
    extractDataForEditForm,
    parseRawBeneficiariesRequirements,
};
