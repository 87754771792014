import styled from 'styled-components';


// eslint-disable-next-line spellcheck/spell-checker
const SelectRoleTemplateOuterWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
`;

const SelectRoleTemplateWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0,1fr) minmax(0,1fr);
  grid-column-gap: 50px;
  width: 100%;
`;

const PermissionsWrapper = styled.div`
  max-width: 100%;

`;

const Placeholder = styled.div`
  min-height: 400px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const PreloadedPermissionsWrapper = styled.div`
  display: grid;
  white-space: nowrap;
  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
`;

export default {
    SelectRoleTemplateOuterWrapper,
    SelectRoleTemplateWrapper,
    PermissionsWrapper,
    Placeholder,
    PreloadedPermissionsWrapper,
};
