import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchRolesListSuccess, fetchRolesListFailure } from './actions';
import { FETCH_ROLES_LIST } from './actions.types';


export const onFetchRolesList = (action$, state$, { roles }) => action$.pipe(
    ofType(FETCH_ROLES_LIST),
    switchMap(({ payload }) => from(roles.getRoles(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchRolesListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchRolesListFailure(payload.locationPathname))),
    )),
);

export default [
    onFetchRolesList,
];
