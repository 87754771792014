/*
  XXX note that in some queryParams updates  table column filters are completely missing, in such case their queryParam value remains unchanged
  except when tableFilters?.[fieldName] === null -  for sure the filter exists and is set to be empty, e.g. using filter's 'Reset' button
* */
export function amendTableColumnFilterValue(fieldName, tableFilters = {}) {
    return {
        ...(tableFilters?.[fieldName] ? { [fieldName]: tableFilters?.[fieldName] } : {}), // XXX update filter
        ...(tableFilters?.[fieldName] === null ? { [fieldName]: undefined } : {}), // XXX clear filter in queryParams
    };
}


export default amendTableColumnFilterValue;

