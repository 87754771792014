import { toggleAddressSameAsOtherAddress } from 'redux/client-add/actions';
import { addClientReducerName } from 'redux/client-add/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AddressSameAsOtherAddress from './AddressSameAsOtherAddress.layout';


const mapStateToProps = (state) => ({
    shippingAddressSameAsCompanyAddress: state[addClientReducerName].shippingAddressSameAsCompanyAddress,
    billingAddressSameAsCompanyAddress: state[addClientReducerName].billingAddressSameAsCompanyAddress,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleAddressSameAsOtherAddress: (addressName, currentValue) => dispatch(toggleAddressSameAsOtherAddress(addressName, currentValue)),
//
});

const decorate = compose(
    withTranslation('clients'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddressSameAsOtherAddress);
