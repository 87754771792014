import { MultiStepFormResetScrollElements, MultiStepFormStepChangeDirections } from 'constants/MultiStepsFormModel';
import {
    REQUEST_SET_MULTI_STEPS_FORM_STEP,
    REQUEST_SET_MULTI_STEPS_FORM_SUB_STEP,
    SET_MULTI_STEPS_FORM_STEP,
    SET_MULTI_STEPS_FORM_SUB_STEP,
} from 'redux/multi-steps-form/actions.types';
import {
    createOnRequestSetMultiStepsFormStepActions,
    createOnRequestSetMultiStepsFormSubStepActions,
} from 'redux/multi-steps-form/epics.helpers';
import { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';

import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { switchMap, switchMapTo, tap } from 'rxjs/operators';


export const onRequestSetMultiStepsFormStep = (action$, state$) => action$.pipe(
    ofType(REQUEST_SET_MULTI_STEPS_FORM_STEP),
    switchMap(({ payload: { currentStep, direction, locationPathname } }) => createOnRequestSetMultiStepsFormStepActions({
        currentStep,
        direction,
        locationPathname,
        availableSteps: state$.value[multiStepsFormReducerName]?.[locationPathname].availableSteps,
    })),
);

export const onRequestSetMultiStepsFormSubStep = (action$, state$) => action$.pipe(
    ofType(REQUEST_SET_MULTI_STEPS_FORM_SUB_STEP),
    switchMap(({ payload: { parentStep, currentSubStep, direction, locationPathname } }) => createOnRequestSetMultiStepsFormSubStepActions({
        parentStep,
        currentSubStep,
        direction,
        locationPathname,
        availableSubSteps: state$.value[multiStepsFormReducerName]?.[locationPathname].availableSubSteps?.[parentStep],
    })),
);


export const onMultiStepsFormStepOrSubSepChange = (action$, _, { document }) => action$.pipe(
    ofType(SET_MULTI_STEPS_FORM_STEP, SET_MULTI_STEPS_FORM_SUB_STEP),
    tap(({ payload }) => {
        if (payload.direction === MultiStepFormStepChangeDirections.FORWARD) {
            document.getElementsByClassName(MultiStepFormResetScrollElements.subStepsClassName).item(0).scrollTop = 0;
            document.getElementsByClassName(MultiStepFormResetScrollElements.mainStepsClassName).item(0).scrollTop = 0;
        }
    }),
    switchMapTo(EMPTY),
);

export default [
    onRequestSetMultiStepsFormStep,
    onRequestSetMultiStepsFormSubStep,
    onMultiStepsFormStepOrSubSepChange,
];
