import { setNewPassword } from 'redux/authorisation/actions';
import { RootState } from 'redux/rootReducer';

import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import SetPassword from './SetPassword.layout';
import { SetPasswordDispatchProps, SetPasswordStateProps } from './SetPassword.types';


const mapStateToProps = (state: RootState): SetPasswordStateProps => ({
    isLoadingSetNewPassword: state.authorisation.isLoadingSetNewPassword,
    //
});

const mapDispatchToProps = (dispatch: Dispatch): SetPasswordDispatchProps => ({
    dispatchSetNewPassword: (requestPayload) => dispatch(setNewPassword(requestPayload)),
    //
});


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(SetPassword);
