import { formatIBAN } from 'utils/account-tools';
import { removeUnderscores } from 'utils/string-tools';

import { AccountDetails, AccountIdentification, AccountIdentificationCategory } from '@manigo/manigo-domain-typings';


import { AccountIdentificationStaticField, AccountIdentificationsAmended } from './AccountInfoLayout.types';


const staticFieldNames = ['BANK_NAME', 'BANK_ADDRESS', 'BANK_CODE', 'BRANCH_CODE'];

function createStaticFields(t): AccountIdentificationStaticField {
    return {
        BANK_NAME: { value: '', label: t('Bank Name') },
        BANK_ADDRESS: { value: '', label: t('Bank address') },
        BANK_CODE: { value: '', label: t('Bank code') },
        BRANCH_CODE: { value: '', label: t('Branch code') },
    };
}

function createInitialValues(t): AccountIdentificationsAmended {
    return {
        [AccountIdentificationCategory.LOCAL]: {
            dynamicFields: [],
            staticFields: createStaticFields(t),
        },
        [AccountIdentificationCategory.GLOBAL]: {
            dynamicFields: [],
            staticFields: createStaticFields(t),
        },
    };
}

function updateAcc(acc: AccountIdentificationsAmended, keyName: AccountIdentificationCategory, identifier: AccountIdentification): AccountIdentificationsAmended {
    const inputObject = acc[`${keyName}`];

    const handlers = [
        {
            predicate: () => staticFieldNames.includes(identifier.identificationType),
            handler: () => ({
                ...acc,
                [`${keyName}`]: {
                    dynamicFields: inputObject.dynamicFields,
                    staticFields: {
                        ...inputObject.staticFields,
                        [`${identifier.identificationType}`]: {
                            ...inputObject.staticFields[`${identifier.identificationType}`],
                            value: identifier.identificationValue,
                        },
                    },
                },
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                ...acc,
                [`${keyName}`]: {
                    staticFields: inputObject.staticFields,
                    dynamicFields: [
                        ...inputObject.dynamicFields,
                        {
                            label: removeUnderscores(identifier.identificationType),
                            value: identifier.identificationType === 'IBAN' ? formatIBAN(identifier.identificationValue) : identifier.identificationValue,
                        },
                    ],
                },
            }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

function filterEmptyStaticFieldsAndFlattenResults(rawData: AccountIdentificationsAmended) {
    return {
        [AccountIdentificationCategory.LOCAL]: [
            ...rawData[AccountIdentificationCategory.LOCAL].dynamicFields,
            ...Object.values(rawData[AccountIdentificationCategory.LOCAL].staticFields).filter((field) => field.value !== ''),
        ],
        [`${AccountIdentificationCategory.GLOBAL}`]: [
            ...rawData[`${AccountIdentificationCategory.GLOBAL}`].dynamicFields,
            ...Object.values(rawData[AccountIdentificationCategory.GLOBAL].staticFields).filter((field) => field.value !== ''),
        ],
    };
}

export function splitAccountIdentifications(t, accountIdentifications: AccountDetails['accountIdentifications'] = []) {
    const rawData = accountIdentifications.reduce((acc, identifier) => {
        if (identifier.identificationCategory === AccountIdentificationCategory.LOCAL) {
            return updateAcc(acc, AccountIdentificationCategory.LOCAL, identifier);
        }

        if (identifier.identificationCategory === AccountIdentificationCategory.GLOBAL) {
            return updateAcc(acc, AccountIdentificationCategory.GLOBAL, identifier);
        }

        return acc;
    }, createInitialValues(t));


    return filterEmptyStaticFieldsAndFlattenResults(rawData);
}

export default { splitAccountIdentifications };
