import withAccessControl from 'hocs/withAccessControl';
import { toggleSidebar } from 'redux/application/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { RootState } from 'redux/rootReducer';

import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import SideBar from './SideBar.layout';
import { SideBarDispatchProps } from './SideBar.types';


const mapStateToProps = (state: RootState) => ({
    clientId: state.currentUser.userData?.clientId,
    isSidebarCollapsed: state.application.isSidebarCollapsed,
});

const mapDispatchToProps = (dispatch: Dispatch): SideBarDispatchProps => ({
    dispatchRequestNavigation: (location) => dispatch(requestNavigation(location)),
    dispatchToggleSidebar: () => dispatch(toggleSidebar()),
});


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(SideBar);
