import { downloadMigrationReport } from 'redux/client-migrations/actions';
import { setModalProps } from 'redux/modal/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import DownloadUserMigrationReportModal from './DownloadUserMigrationReportModal.layout';


const mapDispatchToProps = (dispatch) => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchDownloadMigrationReport: (queryParams, locationPathname) => dispatch(downloadMigrationReport(queryParams, locationPathname)),
});


const decorate = compose(
    withTranslation('common'),
    connect(null, mapDispatchToProps),
);

export default decorate(DownloadUserMigrationReportModal);

