export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE';
export const CLEAR_MEMBERS_LIST = 'CLEAR_MEMBERS_LIST';

export const CREATE_MEMBER = 'CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE';

export const SET_CREATE_MEMBER_FORM_STEP = 'SET_CREATE_MEMBER_FORM_STEP';
export const SET_CREATE_MEMBER_FORM_DATA = 'SET_CREATE_MEMBER_FORM_DATA';
