import { filterOptions } from 'utils/filterOptions';
import { createCurrencyOptions, createProductTypeOptions } from 'utils/options-creators';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


function AddProductModal({
    dispatchAddProduct,
    dispatchSetModalProps,
    currenciesList,

    clientId,
    locationPathname,
    t,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => form.submit() });
    }, []);

    const currencyOptions = useMemo(
        () => createCurrencyOptions(currenciesList.items, t),
        [currenciesList],
    );


    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });
        dispatchAddProduct({ clientId, ...values }, locationPathname);
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onFinish}
            >
                <Form.Item
                    name="currency_code"
                    label={t('modals.addProduct.fields.currencyCode.label')}
                    rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                >
                    <Select
                        showSearch
                        placeholder={t('modals.addProduct.fields.currencyCode.placeholder')}
                        filterOption={filterOptions}
                        loading={currenciesList?.isLoadingList}
                    >
                        {currencyOptions}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="account_type"
                    label={t('modals.addProduct.fields.accountType.label')}
                    rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
                >
                    <Select placeholder={t('modals.addProduct.fields.accountType.placeholder')}>
                        {createProductTypeOptions()}
                    </Select>
                </Form.Item>
            </Form>
        </div>
    );
}

AddProductModal.propTypes = {
    clientId: PropTypes.number.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchAddProduct: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default AddProductModal;
