import styled from 'styled-components';


export const AppPageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AppPageHeaderBackButtonContainer = styled.div`
    > button {
        color: rgba(0, 0, 0, 0.88);
        &:hover {
            color: var(--btn-primary-bg) !important;
        }
    }
    margin-inline-end: 4px;
`;


export const AppPageHeaderTitle = styled.div`
    margin-inline-end: 12px;
    margin-block-end: 0;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
`;

export default {
    AppPageHeaderContainer,
    AppPageHeaderBackButtonContainer,
    AppPageHeaderTitle,
};
