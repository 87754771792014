import { createAddRolePath } from 'config/routes.helpers';
import { MultiStepFormCommonStepsDefinitions } from 'constants/MultiStepsFormModel';
import { showSuccessToast } from 'redux/application/actions';
import { setMultiStepsFormStep } from 'redux/multi-steps-form/actions';
import { unblockNavigation } from 'redux/navigation/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    fetchRoleDetailsFailure,
    fetchRoleDetailsSuccess,
    updateRoleFailure,
    updateRoleSuccess,
    createRoleSuccess,
    createRoleFailure,
    deleteRoleSuccess,
    deleteRoleFailure,
} from './actions';
import { FETCH_ROLE_DETAILS, UPDATE_ROLE, CREATE_ROLE, DELETE_ROLE } from './actions.types';


export const onFetchRoleDetails = (action$, state$, { roles }) => action$.pipe(
    ofType(FETCH_ROLE_DETAILS),
    switchMap(({ payload }) => from(roles.fetchRoleDetails(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchRoleDetailsSuccess(response.data, payload?.locationPathname))),
        catchError(() => of(fetchRoleDetailsFailure(payload.locationPathname))),
    )),
);

export const onUpdateRole = (action$, state$, { roles, i18n }) => action$.pipe(
    ofType(UPDATE_ROLE),
    switchMap(({ payload }) => from(roles.updateRole(payload.requestPayload)).pipe(
        switchMap((response) => of(
            updateRoleSuccess(response.data, payload?.locationPathname),
            showSuccessToast(i18n.t(`Role #${payload.requestPayload.roleId} has been successfully updated.`)),
        )),
        catchError(() => of(updateRoleFailure(payload?.locationPathname))),
    )),
);


export const onCreateRole = (action$, state$, { roles, i18n }) => action$.pipe(
    ofType(CREATE_ROLE),
    switchMap(({ payload }) => from(roles.createRole(payload.requestPayload)).pipe(
        switchMap((response) => {
            const addRolePath = createAddRolePath();

            return of(
                unblockNavigation(),
                createRoleSuccess(response.data),
                setMultiStepsFormStep(MultiStepFormCommonStepsDefinitions.SUCCESS, addRolePath),
                showSuccessToast(i18n.t('New role has been successfully created.')),
            );
        }),
        catchError(() => of(createRoleFailure(payload?.locationPathname))),
    )),
);

export const onDeleteRole = (action$, state$, { roles, i18n }) => action$.pipe(
    ofType(DELETE_ROLE),
    switchMap(({ payload }) => from(roles.deleteRole(payload.roleId)).pipe(
        switchMap((response) => of(
            deleteRoleSuccess(response.data, payload?.locationPathname),
            showSuccessToast(i18n.t(`Role #${payload.requestPayload.roleId} has been successfully updated`)),
        )),
        catchError(() => of(deleteRoleFailure(payload?.locationPathname))),
    )),
);


export default [
    onFetchRoleDetails,
    onUpdateRole,
    onCreateRole,
    onDeleteRole,
];
