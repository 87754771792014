import { CLEAR_USER_DETAILS, CLEAR_USER_DETAILS_STATE, EDIT_USER, EDIT_USER_FAILURE, EDIT_USER_SUCCESS } from 'redux/user/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchUserDetails = createAction('user/fetchUserDetails', (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchUserDetailsSuccess = createAction('user/fetchUserDetailsSuccess', (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchUserDetailsFailure = createAction('user/fetchUserDetailsFailure', (locationPathname) => ({ payload: { locationPathname } }));
export const clearUserDetails = createAction(CLEAR_USER_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));

export const editUser = createAction(EDIT_USER,
    (queryParams, locationPathname, isCurrentUserSelfEditMode) => ({ payload: { queryParams, locationPathname, isCurrentUserSelfEditMode } }));
export const editUserSuccess = createAction(EDIT_USER_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const editUserFailure = createAction(EDIT_USER_FAILURE);


export const banUser = createAction('user/banUser', (queryParams) => ({ payload: { queryParams } }));
export const banUserSuccess = createAction('user/banUserSuccess', (responsePayload) => ({ payload: responsePayload }));
export const banUserFailure = createAction('user/banUserFailure');


export const deleteUser = createAction('user/deleteUser', (queryParams) => ({ payload: { queryParams } }));
export const deleteUserSuccess = createAction('user/deleteUserSuccess');
export const deleteUserFailure = createAction('user/deleteUserFailure');


export const resendConfirmationEmail = createAction('user/resendConfirmationEmail', (queryParams) => ({ payload: { queryParams } }));
export const resendConfirmationEmailSuccess = createAction('user/resendConfirmationEmailSuccess');
export const resendConfirmationEmailFailure = createAction('user/resendConfirmationEmailFailure');

export const clearUserDetailsState = createAction(CLEAR_USER_DETAILS_STATE);
