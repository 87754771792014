import FlagAndValue from 'components/common/atoms/FlagAndValue';
import AccountTypeTag from 'components/common/atoms/tags/AccountTypeTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createAccountDetailsPath } from 'config/routes.helpers';
import { AccountTypes } from 'constants/AccountModel';
import { PN } from 'constants/PermissionsModel';
import { sortingOptions } from 'utils/table-columns-sorters';

import {
    ClearOutlined,
    PrinterOutlined,
} from '@ant-design/icons';
import { BalanceAdjustmentAdjustmentAccountType } from '@manigo/manigo-domain-typings';
import { Space } from 'antd';
import React from 'react';

import DownloadAccountStatement from './DownlaodAccountStatemnet';


const createIdColumn = (queryParams) => ([{
    title: 'ID',
    dataIndex: 'id',
    align: 'right',
    render: (text) => <StandardValue value={text} />,
    ...sortingOptions({ queryParams, fieldName: 'id' }),
}]);

const shouldShowBalanceAdjustmentButton = (record, clientConfig, currentUserClientId) => {
    const { generalSettings: { balanceAdjustmentAccountTypes } } = clientConfig;
    const handlers = [
        {
            predicate: () => balanceAdjustmentAccountTypes === BalanceAdjustmentAdjustmentAccountType.NONE,
            handler: () => false,
        },
        {
            predicate: () => balanceAdjustmentAccountTypes === BalanceAdjustmentAdjustmentAccountType.BASE,
            handler: () => record.type === AccountTypes.Base,
        },
        {
            predicate: () => balanceAdjustmentAccountTypes === BalanceAdjustmentAdjustmentAccountType.ALL,
            handler: () => true,
        },
    ];


    return record.client_id === currentUserClientId
    && handlers.find(({ predicate }) => predicate())?.handler();
};

const createCommonColumns = (
    {
        accessControl,
        queryParams,
        enhancedCurrentLocation,
        isInReadOnlyMode,
        currentUserClientId,
        clientConfig,
        dispatchRequestNavigation,
        dispatchOpenWithdrawAccountBalanceModal,
        dispatchOpenBalanceAdjustmentModal,
        t,
    },
) => [
    {
        title: 'Account Type',
        dataIndex: 'type',
        align: 'center',
        render: (type) => <AccountTypeTag accountType={type} showTooltip />,
        ...sortingOptions({ queryParams, fieldName: 'type' }),
    },
    {
        title: 'Primary',
        align: 'center',
        dataIndex: 'is_primary',
        render: (text, record) => booleanAsIconsRenderer(record, 'is_primary', t),
    },
    {
        title: 'Account Balance',
        align: 'right',
        dataIndex: 'balance',
        render: (balance) => <StandardValue value={balance} valueType="AMOUNT" />,
    },
    {
        title: 'Blocked balance',
        align: 'right',
        dataIndex: 'blocked_balance',
        render: (blocked_balance) => <StandardValue value={blocked_balance} valueType="AMOUNT" />,
    },
    {
        title: 'Available balance',
        align: 'right',
        dataIndex: 'available_balance',
        render: (available_balance) => <StandardValue value={available_balance} valueType="AMOUNT" />,
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        render: (text, record) => <FlagAndValue currencyCode={record.currency.iso ?? record.currency} text={record.currency.iso ?? record.currency} showTooltip />,
        ...sortingOptions({ queryParams, fieldName: 'currency' }),
    },
    ...(accessControl.hasOneOfPermissions([
        PN.ACCOUNTS.READ_BY_ID,
        PN.TRANSACTIONS.READ_ALL,
        PN.TRANSACTIONS.WITHDRAW_BALANCE,
    ])
        ? [
            {
                title: 'Actions',
                dataIndex: '',
                align: 'center',
                // eslint-disable-next-line complexity
                render: (_, record) => (
                    <Space>
                        {/* XXX accessControl.hasPermission(PN.TRANSACTIONS.ADD_MONEY)
                        && record.client_id === currentUserClientId in practice should act as only-manigo-accounts-allowed  */}
                        {accessControl.hasPermission(PN.TRANSACTIONS.BALANCE_ADJUSTMENT)
                && shouldShowBalanceAdjustmentButton(record, clientConfig, currentUserClientId)
                && viewButtonRenderer({
                    record,
                    onClick: (event, record) => dispatchOpenBalanceAdjustmentModal(record, enhancedCurrentLocation.pathname),
                    tooltipContent: 'Load/unload funds',
                    icon: <PrinterOutlined />,
                    dataTestId: 'open-load-unload-funds-modal',
                })}

                        {accessControl.hasPermission(PN.TRANSACTIONS.WITHDRAW_BALANCE)
                && isInReadOnlyMode
                && record.blocked_balance === 0
                && record.available_balance !== 0
                && viewButtonRenderer({
                    record,
                    onClick: (event, record) => dispatchOpenWithdrawAccountBalanceModal(record, enhancedCurrentLocation.pathname),
                    tooltipContent: 'Withdraw account balance',
                    icon: <ClearOutlined />,
                    dataTestId: 'open-withdraw-account-balance-modal',
                })}

                        {accessControl.hasPermission(PN.REPORTS.TRANSACTION_STATEMENT.PDF)
                && (record?.member_id > 0 || record?.company_id > 0)
                && <DownloadAccountStatement accountId={record.id} currency={record.currency} />}


                        {accessControl.hasPermission(PN.ACCOUNTS.READ_BY_ID) && viewButtonRenderer({
                            record,
                            // onClick: (event, record) => dispatchShowAccountDetailsDrawer(record.id), // OLD drawer
                            onClick: (event, record) => dispatchRequestNavigation(createAccountDetailsPath(record.id)),
                            tooltipContent: 'View account details',
                            dataTestIdSuffix: 'account',
                        })}
                    </Space>
                ),
            },
        ]
        : []
    ),

];

const createNameColumn = () => [{
    title: 'Name',
    dataIndex: 'name',
    render: (text) => <StandardValue value={text} />,
}];


export const createAccountsListColumns = ({
    accessControl,
    queryParams,
    currentUserClientId,
    clientConfig,
    isInReadOnlyMode,
    dispatchRequestNavigation,
    dispatchOpenWithdrawAccountBalanceModal,
    dispatchOpenBalanceAdjustmentModal,
    enhancedCurrentLocation,
    t,
}) => [
    ...createIdColumn(queryParams),
    ...createNameColumn(),
    ...createCommonColumns({
        accessControl,
        isInReadOnlyMode,
        currentUserClientId,
        clientConfig,
        dispatchRequestNavigation,
        dispatchOpenWithdrawAccountBalanceModal,
        dispatchOpenBalanceAdjustmentModal,
        queryParams,
        enhancedCurrentLocation,
        t,
    }),
];


export default { createAccountsListColumns };
