import { AuthorisationErrors } from 'constants/AuthorisationModel';

import { ApiError } from '@manigo/manigo-commons';

import { LOGIN_COUNTDOWN_TIME } from './actions.types';
import { AuthorisationStateType } from './reducer.types';


export const handleLoginFailure = (state: AuthorisationStateType, error: ApiError) => {
    const initLoginFailureHandlers = [{
        predicate: () => error.message === AuthorisationErrors.TEMPORARILY_BLOCKED_FOR || error.message === AuthorisationErrors.TEMPORARILY_BLOCKED,
        handler: () => {
            state.loginBlockUntil = new Date(
                new Date().getTime() + LOGIN_COUNTDOWN_TIME,
            );
            state.loginBlockUntil = new Date(new Date().getTime() + LOGIN_COUNTDOWN_TIME);
            state.loginFormErrorMessage = error.message;
        },
    },
    {
        predicate: () => error.message === AuthorisationErrors.PERMANENTLY_BLOCKED,
        handler: () => {
            state.loginFormErrorMessage = error.message;
        },
    },
    ];

    initLoginFailureHandlers.find((h) => h.predicate())?.handler();
};

export default { handleLoginFailure };
