// eslint-disable-next-line no-restricted-imports
import {
    isArray, isDate, isNumber, isNaNValue, isUndefined, isNull, isObject,
} from 'is-what';
// legit use
// eslint-disable-next-line no-restricted-imports
export { flattenObject, flattenArray, flatten } from 'flatten-anything'; // legit use
// eslint-disable-next-line no-restricted-imports
export { nestifyObject } from 'nestify-anything'; // legit use

// eslint-disable-next-line no-restricted-imports
export { default as isDeepEqual } from 'fast-deep-equal'; // legit use


export function getObjectKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

export {
    isArray, isDate, isNumber, isNaNValue, isUndefined, isNull, isObject,
};
