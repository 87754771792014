import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import CommonSection from 'components/common/organisms/CommonSection';
import { enumValueToReadableString } from 'utils/string-tools';

import React from 'react';

import Styled from './ClientContextIndicator.styled';


export function createContextIndicatorTooltipContent({
    accessControl,
    clientIdContext,
    clients,
}) {
    if (!clients || !clientIdContext) return null;

    const clientData = clients.find((client) => client.id === clientIdContext);
    const label = clientData?.company_dba_name
        ? `${clientIdContext} - ${clientData.company_dba_name} ${enumValueToReadableString(clientData.company_business_type)}`
        : clientIdContext;

    const data = accessControl?.clientConfig?.generalSettings || {};

    const configFields = [
        {
            value: <StandardValue value={data.bwpAppClient} showTooltip={false} valueType={ValueTypes.BOOLEAN} />,
            label: 'BWP application enabled',
        },
        {
            value: <StandardValue value={data.allowOnlinePayments} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Allow Online payments',
        },
        {
            value: <StandardValue value={data.allowPhysicalCards} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Allow physical Cards',
        },
        {
            value: <StandardValue value={data.physicalCardsLimit} showTooltip={false} />,
            label: 'Physical cards limit',
        },
        {
            value: <StandardValue value={data.unlockPhysicalCardsAfterActivated} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Unlock physical cards after activated',
        },
        {
            value: <StandardValue value={data.personalCardType} showTooltip={false} />,
            label: 'Personal card type',
        },
        {
            value: <StandardValue value={data.allowVirtualCards} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Allow virtual cards',
        },
        {
            value: <StandardValue value={data.virtualCardsLimit} showTooltip={false} />,
            label: 'Virtual cards limit',
        },
        {
            value: <StandardValue value={data.allowTechnicalAccounts} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Allow technical accounts',
        },
        {
            value: <StandardValue value={data.allowTopup} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Allow topup',
        },
        {
            value: <StandardValue value={data.alwaysEnforce3DsForTopups} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Always enforce 3DS for topups',
        },
        {
            value: <StandardValue value={data.beneficiariesEnabled} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Beneficiaries enabled',
        },

        {
            value: <StandardValue value={data.businessCardType} showTooltip={false} />,
            label: 'Business Card Type',
        },
        {
            value: <StandardValue value={data.enableChat} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Enable chat',
        },
        {
            value: <StandardValue value={data.externalCardVerificationEnabled} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'External card verification enabled',
        },

        {
            value: <StandardValue value={data.groupsEnabled} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Groups enabled',
        },
        {
            value: <StandardValue value={data.isAnnouncementEnabled} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Is announcement enabled',
        },
        {
            value: <StandardValue value={data.kyckybApproval} showTooltip={false} />,
            label: 'KYC & KYB approval',
        },
        {
            value: <StandardValue value={data.externalKyc} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'External KYC',
        },
        {
            value: <StandardValue value={data.kycRedirectUrl} showTooltip={false} />,
            label: 'KYC redirect URL',
        },
        {
            value: <StandardValue value={data.smsEnabled} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'SMS enabled',
        },

        {
            value: <StandardValue value={data.autoAcceptTermsAndConditions} valueType={ValueTypes.BOOLEAN} showTooltip={false} />,
            label: 'Auto accept terms and conditions',
        },
    ];


    return (
        <Styled.TooltipContent>
            {`Current partner context - #${label}`}
            <hr />
            <CommonSection
                fieldsConfig={configFields}
            />
        </Styled.TooltipContent>
    );
}

export default { createContextIndicatorTooltipContent };

