import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchClientsListFailure, fetchClientsListSuccess } from './actions';
import { FETCH_CLIENTS_LIST } from './actions.types';


export const onFetchClientsList = (action$, state$, { clients }) => action$.pipe(
    ofType(FETCH_CLIENTS_LIST),
    switchMap(({ payload }) => from(clients.getClients(payload?.queryParams))
        .pipe(
            switchMap((response) => of(fetchClientsListSuccess(
                response.data?.rows,
                response.data?.count,
                // XXX  locationPathname omitted -> global location
            ))),
            catchError(() => of(fetchClientsListFailure())), // XXX locationPathname omitted -> global location
        )),
);

export default [onFetchClientsList];
