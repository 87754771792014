import StandardEntityDetailsWithTabs from 'components/common/organisms/StandardEntityDetailsWithTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import useStandardDetailsPageCachingLogic from 'hooks/useStandardDetailsPageCachingLogic';
import { isValidV4Uuid } from 'utils/validation-tools';

import { TransactionDetails } from '@manigo/manigo-domain-typings';
import React, { useMemo, JSX } from 'react';
import { useParams } from 'react-router-dom';


import { createTransactionDetailsTabsConfig } from './TransactionDetailsPage.helpers';
import { TransactionDetailsPageProps } from './TransactionDetailsPage.types';
import TransactionInfo from './TransactionInfo';


function TransactionDetailsPage({
    accessControl,

    entityDetailsData,
    enhancedCurrentLocation,

    dispatchFetchTransactionDetails,
    dispatchClearTransactionDetails,
    dispatchSetQueryParams,
    isLoadingUnblockTransaction,
    isLoadingRefundTopUp,
    dispatchRequestNavigation,
    t,
}: TransactionDetailsPageProps): JSX.Element {
    const { transactionUuid } = useParams();
    const baseURLSegmentsCount = 2; // e.g. /(1) transaction /(2) transactionUuid /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    const contextEnforcedQueryParams = { transactionUuid };

    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic<TransactionDetails>({
        contextEnforcedQueryParams,

        enhancedCurrentLocation,
        baseUrlWithoutTabsSlugs,
        entityDetailsData,

        dispatchFetchEntityDetails: dispatchFetchTransactionDetails,
        dispatchClearEntityDetails: dispatchClearTransactionDetails,
        dispatchSetQueryParams,
    });


    const tabsConfig = useMemo(
        () => (transactionUuid && isValidV4Uuid(entityDetailsData?.entityDetails?.transactionUuid)
            ? createTransactionDetailsTabsConfig({
                transactionDetails: entityDetailsData?.entityDetails,
                accessControl,
                dispatchRequestNavigation,
                t,
            })
            : undefined),
        [entityDetailsData?.entityDetails?.transactionUuid, transactionUuid],
    );

    return (
        <StandardEntityDetailsWithTabs
            pageHeadingTitle={t('transactionDetails.tabs.general.subHeadings.transaction', { id: transactionUuid })}
            baseURLSegmentsCount={baseURLSegmentsCount}
            tabsConfig={tabsConfig}
            entityClientId={entityDetailsData?.entityDetails?.clientId}
        >
            <TransactionInfo
                areDetailsReady={isDetailsPageInitialStateDefined}
                isBusy={entityDetailsData?.isLoadingDetails}
                isLoadingRefundTopUp={isLoadingRefundTopUp}
                isLoadingUnblockTransaction={isLoadingUnblockTransaction}
                transactionDetails={entityDetailsData?.entityDetails}
                onRefresh={() => dispatchFetchTransactionDetails({ transactionUuid }, baseUrlWithoutTabsSlugs)}
                queryParamsMeta={entityDetailsData?.queryParamsMeta}
                baseUrlWithoutTabsSlugs={baseUrlWithoutTabsSlugs}
            />
        </StandardEntityDetailsWithTabs>
    );
}


export default TransactionDetailsPage;
