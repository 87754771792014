import { Select } from 'antd';
import {
    arrayOf,
    shape,
    string,
    any,
} from 'prop-types';
import React from 'react';


const { Option } = Select;

function CustomSelect({ options, ...props }) {
    return (
        <Select
            {...props}
        >
            {options.map((option) => (
                <Option key={option.key} value={option.value}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
}

CustomSelect.propTypes = {
    options: arrayOf(shape({
    // eslint-disable-next-line react/forbid-prop-types
        value: any.isRequired,
        label: string.isRequired,
    })).isRequired,
    defaultValue: string,
    placeholder: string,
};

export default CustomSelect;
