export const FETCH_API_KEYS_LIST = 'FETCH_API_KEYS_LIST';
export const FETCH_API_KEYS_LIST_SUCCESS = 'FETCH_API_KEYS_LIST_SUCCESS';
export const FETCH_API_KEYS_LIST_FAILURE = 'FETCH_API_KEYS_LIST_FAILURE';
export const CLEAR_API_KEYS_LIST = 'CLEAR_API_KEYS_LIST';
export const REFRESH_API_KEYS_LIST = 'REFRESH_API_KEYS_LIST';

export const FETCH_API_KEY_VALUE = 'FETCH_API_KEY_VALUE';
export const FETCH_API_KEY_VALUE_SUCCESS = 'FETCH_API_KEY_VALUE_SUCCESS';
export const FETCH_API_KEY_VALUE_FAILURE = 'FETCH_API_KEY_VALUE_FAILURE';

export const OPEN_CREATE_API_KEY_MODAL = 'OPEN_CREATE_API_KEY_MODAL';

export const CREATE_API_KEY = 'CREATE_API_KEY';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_FAILURE = 'CREATE_API_KEY_FAILURE';


export const DELETE_API_KEY = 'DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAILURE = 'DELETE_API_KEY_FAILURE';

