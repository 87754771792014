export const CARD_PIN_UNBLOCK = 'CARD_PIN_UNBLOCK';
export const CARD_PIN_UNBLOCK_SUCCESS = 'CARD_PIN_UNBLOCK_SUCCESS';
export const CARD_PIN_UNBLOCK_FAILURE = 'CARD_PIN_UNBLOCK_FAILURE';

export const UNLINK_CARD = 'UNLINK_CARD';
export const UNLINK_CARD_SUCCESS = 'UNLINK_CARD_SUCCESS';
export const UNLINK_CARD_FAILURE = 'UNLINK_CARD_FAILURE';

export const FETCH_CARD_DETAILS = 'FETCH_CARD_DETAILS';
export const FETCH_CARD_DETAILS_SUCCESS = 'FETCH_CARD_DETAILS_SUCCESS';
export const FETCH_CARD_DETAILS_FAILURE = 'FETCH_CARD_DETAILS_FAILURE';
export const CLEAR_CARD_DETAILS = 'CLEAR_CARD_DETAILS';

export const UPDATE_CARD = 'UPDATE_CARD';
export const UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS';
export const UPDATE_CARD_FAILURE = 'UPDATE_CARD_FAILURE';

export const UPDATE_CARD_LIMITS = 'UPDATE_CARD_LIMITS';
export const UPDATE_CARD_LIMITS_SUCCESS = 'UPDATE_CARD_LIMITS_SUCCESS';
export const UPDATE_CARD_LIMITS_FAILURE = 'UPDATE_CARD_LIMITS_FAILURE';

export const TOGGLE_CHANGE_CARD_LIMIT_FORM_VISIBILITY = 'TOGGLE_CHANGE_CARD_LIMIT_FORM_VISIBILITY';

export const SET_CHANGE_CARD_LIMIT_FORM_HAS_LIMIT_VALUE = 'SET_CHANGE_CARD_LIMIT_FORM_HAS_LIMIT_VALUE';

export const SHOW_UNLINK_CARD_MODAL = 'SHOW_UNLINK_CARD_MODAL';
export const CONFIRM_UNLINK_CARD = 'CONFIRM_UNLINK_CARD';

export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';
