import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo/QueryParamsMetaInfo.layout';
import withAccessControl from 'hocs/withAccessControl';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
);

export default decorate(QueryParamsMetaInfo);
