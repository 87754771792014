import PropTypes from 'prop-types';
import React from 'react';


function TokenDebug({
    token = [],
    t,
}) {
    return (
        <div>
            <h1>{t('For debug only - Super Admins only')}</h1>
            <pre>{JSON.stringify(token, null, 2)}</pre>
        </div>
    );
}

TokenDebug.propTypes = { t: PropTypes.func.isRequired, token: PropTypes.shape({}) };


export default TokenDebug;
