import { AddClientStepsDefinitions } from 'constants/AddClientModel';
import { MultiStepFormStepSectionFieldsLayout, MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';
import { removeUnderscores } from 'utils/string-tools';
import { sortObjectKeysAlphabetically } from 'utils/table-columns-sorters';

import { SettingOutlined } from '@ant-design/icons';
import React from 'react';


export function createClientSettingsSubStepsConfig({
    t,
    parsedSettingsConfig,
}) {
    return {
        stepName: AddClientStepsDefinitions.SETTINGS,
        title: t('addClient.steps.settings'),
        icon: <SettingOutlined />,
        subStepsConfiguration: Object.keys(sortObjectKeysAlphabetically(parsedSettingsConfig)).map((settingsSectionKeyName) => {
            // sub sections
            if (
                typeof parsedSettingsConfig[settingsSectionKeyName] === 'object'
        && !Array.isArray(parsedSettingsConfig[settingsSectionKeyName])
        && parsedSettingsConfig[settingsSectionKeyName] !== null
            ) {
                return {
                    parentStepName: AddClientStepsDefinitions.SETTINGS,
                    subStepName: settingsSectionKeyName,
                    title: `${removeUnderscores(settingsSectionKeyName)} `,
                    sectionsLayout: MultiStepFormStepSectionTypes.GRID,
                    stepSections: Object.keys(sortObjectKeysAlphabetically(parsedSettingsConfig[settingsSectionKeyName])).map((subContextName) => ({
                        fieldsLayout: MultiStepFormStepSectionFieldsLayout.VERTICAL,
                        title: `${removeUnderscores(settingsSectionKeyName)} - ${removeUnderscores(subContextName)}`,
                        fieldsConfiguration: parsedSettingsConfig[settingsSectionKeyName][subContextName],
                    })),
                };
            }

            // just all fields in one section
            return {
                parentStepName: AddClientStepsDefinitions.SETTINGS,
                subStepName: settingsSectionKeyName,
                title: `${removeUnderscores(settingsSectionKeyName)} `,
                sectionsLayout: MultiStepFormStepSectionTypes.EVEN_COLUMNS,
                stepSections: [
                    {
                        fieldsLayout: MultiStepFormStepSectionFieldsLayout.EVEN_HORIZONTAL,
                        title: `${removeUnderscores(settingsSectionKeyName)} `,
                        fieldsConfiguration: parsedSettingsConfig[settingsSectionKeyName],
                    },
                ],
            };
        }),
    };
}


export default { createClientSettingsSubStepsConfig };
