import withAccessControl from 'hocs/withAccessControl';
import { triggerKycKybFlow } from 'redux/kyc-kyb/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ResetKycKybButton from './ResetKycKybButton.layout';


const mapStateToProps = (state) => ({ isLoadingTriggerKycKyb: state.kycKyb.isLoadingTriggerKycKyb });

const mapDispatchToProps = (dispatch) => ({ dispatchTriggerKycKybFlow: (queryParams, locationPathname) => dispatch(triggerKycKybFlow(queryParams, locationPathname)) });


const decorate = compose(
    withTranslation('kycKyb'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ResetKycKybButton);
