/* eslint-disable class-methods-use-this */
import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';

import { getRiskAssessmentAPI } from './index';


class MembersService {
    public getRiskAssessment(queryParams): Promise<ApiResponse | ApiError> {
        const { sortOrder, ...restOfQueryParams } = queryParams;
        const query = {
            ...restOfQueryParams,

            ...({ sortOrder: sortOrder === SortDirections.ASCEND ? SortDirectionsAPI.ASC : SortDirectionsAPI.DESC }), // TODO v2 Inconsistencies!!!
        };

        return getRiskAssessmentAPI({ params: query });
    }
}

export default MembersService;
