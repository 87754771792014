/* eslint-disable class-methods-use-this */
import { UserTypes } from 'constants/UserModel';
import {
    InitialiseLoginResponse,
    SendConfirmationCodePayload,
    SetNewPasswordPayload,
    UnlockUserPayload,
} from 'redux/authorisation/actions.types';

import { ApiResponse, ApiError } from '@manigo/manigo-commons';


import {
    loginInitAPI,
    loginConfirmAPI,
    setNewPasswordAPI,
    requestPasswordResetAPI,
    extendTokenValidityAPI,
    expireTokenAPI, getTokensForDuplicatedSessionAPI, unlockLoginAPI, sendConfirmationCodeAPI,
} from './index';


class AuthorisationService {
    public loginInit(emailOrUsername: string, password: string): Promise<InitialiseLoginResponse> {
        const requestPayload = { emailOrUsername, password };
        return loginInitAPI(requestPayload);
    }

    public loginConfirm(passcode: string, emailOrUsername: string, accessToken: string): Promise<ApiResponse | ApiError> {
        const requestPayload = { passcode, emailOrUsername, token: accessToken, groupPermissions: true };
        return loginConfirmAPI(requestPayload, { noErrorHandling: true, convertSnakeCaseKeysToCamelCase: true, ignoreConvertKeys: ['permissions'] });
    }

    public setNewPassword(requestPayload: SetNewPasswordPayload): Promise<ApiResponse | ApiError> {
        return setNewPasswordAPI(requestPayload);
    }

    public requestPasswordReset(requestPayload): Promise<ApiResponse | ApiError> {
        return requestPasswordResetAPI(requestPayload);
    }

    public extendTokenValidity(refresh_token): Promise<ApiResponse | ApiError> {
        return extendTokenValidityAPI({ refresh_token }, { convertSnakeCaseKeysToCamelCase: true, ignoreConvertKeys: ['permissions'] });
    }

    public getTokensForDuplicatedSession(requestPayload): Promise<ApiResponse | ApiError> {
        return getTokensForDuplicatedSessionAPI(requestPayload, { convertSnakeCaseKeysToCamelCase: true, ignoreConvertKeys: ['permissions'] });
    }

    public expireToken(): Promise<ApiResponse | ApiError> {
        return expireTokenAPI();
    }

    public unlockLogin(rawRequestPayload: UnlockUserPayload): Promise<ApiResponse | ApiError> {
        const { context, extUserUuid } = rawRequestPayload;
        const requestPayload = {
            extUserUuid,
            ...(context?.memberId && context?.memberId > 0 ? { userType: UserTypes.MEMBER } : {}),
            ...(context?.userId && context?.userId > 0 ? { userType: UserTypes.BOP_USER } : {}),
            ...(context?.businessUserId && context?.businessUserId > 0 ? { userType: UserTypes.BWP_USER } : {}),
        };

        return unlockLoginAPI(requestPayload);
    }


    public sendConfirmationCode(requestPayload: SendConfirmationCodePayload): Promise<ApiResponse> {
        return sendConfirmationCodeAPI(requestPayload);
    }
}

export default AuthorisationService;
