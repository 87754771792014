import { clearRequestPasswordReset, requestPasswordReset } from 'redux/authorisation/actions';
import { authorisationReducerName } from 'redux/authorisation/reducer';
import { RootState } from 'redux/rootReducer';

import { Dispatch } from '@manigo/manigo-commons';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ForgotPassword from './ForgotPassword.layout';
import { ForgotPasswordDispatchProps, ForgotPasswordStateProps } from './ForgotPassword.types';


const mapStateToProps = (state: RootState): ForgotPasswordStateProps => ({
    isLoadingForgetPassword: state[authorisationReducerName].isLoadingForgetPassword,
    passwordResetLinkSent: state[authorisationReducerName].passwordResetLinkSent,
});

const mapDispatchToProps = (dispatch: Dispatch): ForgotPasswordDispatchProps => ({
    dispatchRequestPasswordReset: (requestPayload) => dispatch(requestPasswordReset(requestPayload)),
    dispatchClearRequestPasswordReset: () => dispatch(clearRequestPasswordReset()),
});


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ForgotPassword);
