import { globalLocation } from 'constants/NavigationModel';

import { createAction } from '@reduxjs/toolkit';

import { CLEAR_COUNTRIES_LIST, FETCH_COUNTRIES_LIST, FETCH_COUNTRIES_LIST_FAILURE, FETCH_COUNTRIES_LIST_SUCCESS } from './actions.types';


export const fetchCountriesList = createAction(FETCH_COUNTRIES_LIST, (queryParams = {}, locationPathname = globalLocation) => ({ payload: { queryParams, locationPathname } }));
export const fetchCountriesListSuccess = createAction(FETCH_COUNTRIES_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchCountriesListFailure = createAction(FETCH_COUNTRIES_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearCountriesList = createAction(CLEAR_COUNTRIES_LIST, (locationPathname) => ({ payload: { locationPathname } }));
