import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import Limits from './Limits.layout';


const decorate = compose(
    withTranslation(),
);

export default decorate(Limits);
