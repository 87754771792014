import StandardListTable from 'components/common/organisms/StandardListTable';
import amendTableColumnFilterValue from 'components/common/organisms/StandardListTable/StandardListTable.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AllEntityTypes, AllEntityTypesProps } from 'constants/ApplicationStateModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultCardListQueryParams } from 'redux/cards/reducer';

import { LinkOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import createColumns from './tableColumns';


function CardsList({
    accessControl,
    currentUserClientId,
    allowInactivePartners = false,
    entityId,
    entityType,

    enhancedCurrentLocation,
    listData,

    showPartnerSelect = false,

    onShowLinkCardButtonClick,
    onShowUnLinkCardButtonClick,
    onOrderCardsButtonClick,
    dispatchFetchAllCards,
    dispatchSetQueryParams,
    dispatchRequestNavigation,
    dispatchClearCardsList,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = useMemo(
        () => ({
            ...(entityType === AllEntityTypes.MEMBER ? { memberId: entityId } : {}),
            ...(entityType === AllEntityTypes.BUSINESS_USER ? { businessUserId: entityId } : {}),
            ...(entityType === AllEntityTypes.COMPANY ? { companyId: entityId } : {}),
            ...(entityType === AllEntityTypes.CLIENT ? { clientId: entityId } : {}),
            ...(entityType === AllEntityTypes.ACCOUNT ? { accountId: entityId } : {}),
            ...(showPartnerSelect ? { clientId: currentUserClientId } : {}),
        }),
        [entityType, entityId, showPartnerSelect],
    );

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const { filters, ...rest } = newQueryParams;

            // list specific table filters
            const newRequestedData = {
                ...rest,
                ...amendTableColumnFilterValue('card_status', filters),
            };

            const prams = {
                ...listData?.queryParams,
                ...newRequestedData,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchAllCards(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultCardListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchAllCards,
        dispatchClearItemsList: dispatchClearCardsList,
        dispatchSetQueryParams,
    });


    // 4. context & permission dependent optional card list buttons
    // XXX permission checks are preformed in parent component, onShowLinkCardButtonClick & onOrderCardsButtonClick can be undefined
    const cardsButtons = [
        ...(onShowLinkCardButtonClick ? [{
            type: 'primary',
            text: t('buttons.linkCard.text'),
            icon: <LinkOutlined />,
            dataTestId: 'link-cards-button',
            onClick: onShowLinkCardButtonClick,
        }] : []),
        ...(onOrderCardsButtonClick ? [{
            type: 'primary',
            text: t('buttons.orderCard.text'),
            icon: <PlusOutlined />,
            dataTestId: 'order-cards-button',
            onClick: onOrderCardsButtonClick,
        }] : []),
    ];

    // 5. standard render component
    return (
        <StandardListTable
            onUpdate={fetchData}
            items={listData?.items}
            showIncludeInactivePartners={allowInactivePartners && accessControl.isSuperAdmin} // XXX allowed - partner list related
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            showClientSelect={showPartnerSelect && accessControl.isSuperAdmin} // XXX allowed - partner list filter
            allowAllClients={false}
            columnsConfig={createColumns({
                accessControl,
                t,
                dispatchRequestNavigation,
                onShowUnLinkCardButtonClick,
                queryParams: listData?.queryParams,
            })}
            filtersButtonsConfig={cardsButtons}
        />
    );
}

CardsList.propTypes = {
    showPartnerSelect: PropTypes.bool,
    allowInactivePartners: PropTypes.bool,
    entityId: PropTypes.number,
    currentUserClientId: PropTypes.number.isRequired,
    entityType: AllEntityTypesProps,
    accessControl: AccessControlProps,


    onShowLinkCardButtonClick: PropTypes.func,
    onShowUnLinkCardButtonClick: PropTypes.func,
    onOrderCardsButtonClick: PropTypes.func,

    dispatchFetchAllCards: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchClearCardsList: PropTypes.func.isRequired,
};

export default CardsList;
