/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { downloadTransactionStatementAPI } from './index';


class ReportsService {
    public downloadTransactionStatement(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { accountId, dateFrom, dateTo } = rawQueryParams;


        return downloadTransactionStatementAPI({ params: { accountId, dateFrom, dateTo } });
    }
}

export default ReportsService;
