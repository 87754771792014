import { usersRootRoutePath } from 'config/routes';
import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';
import {
    addUserFailure, addUserSuccess,
    fetchUsers, fetchUsersFailure, fetchUsersSuccess,
} from 'redux/users/actions';
import { usersListsPerLocationStoreKeyName, usersReducerName } from 'redux/users/reducer';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const onFetchUsers = (action$, state$, { users }) => action$.pipe(
    ofType('users/fetchUsers'),
    switchMap(({ payload }) => from(users.fetchUsers(payload.queryParams)).pipe(
        switchMap((response) => of(fetchUsersSuccess(
            response.data?.rows,
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchUsersFailure())),
    )),
);

export const onOpenAddEditUserModal = (action$) => action$.pipe(
    ofType('users/openAddEditUserModal'),
    switchMap(({ payload }) => of(
        showModal({
            modalType: ModalName.ADD_EDIT_USER_MODAL,
            modalProps: {
                title: payload.title,
                locationPathname: payload.locationPathname,
                width: '50%',
                okButtonProps: { disabled: false },
                config: { ...payload },
            },
        }),
    )),
);

export const onAddUser = (action$, state$, { users, i18n }) => action$.pipe(
    ofType('users/addUser'),
    switchMap(({ payload }) => from(users.createUser(payload.requestPayload)).pipe(
        switchMap((response) => {
            const currentQueryParams = state$.value[usersReducerName][usersListsPerLocationStoreKeyName]?.[usersRootRoutePath]?.queryParams;

            return of(
                addUserSuccess(response.data),
                showSuccessToast(i18n.t('users:actionMessages.addUserSuccess')),
                hideModal(),
                fetchUsers(currentQueryParams, usersRootRoutePath),
            );
        }),
        catchError(() => of(
            addUserFailure(),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);


export default [
    onFetchUsers,
    onAddUser,
    onOpenAddEditUserModal,
];
