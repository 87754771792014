import CompanyRiskTag, { CompanyRiskTagContent } from 'components/common/atoms/tags/CompanyRiskTag';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import EntityDetailsProps from 'constants/EntityDetailsModel';
import { PN } from 'constants/PermissionsModel';

import { Button, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import Styled from '../RiskDetailsSubTab.styled';


function CompanyRiskDetails({
    t,
    isInReadOnlyMode,
    enhancedCurrentLocation,
    companyEntityDetailsData,
    accessControl,
    isLoadingRiskDetails,
    dispatchOpenAddCompanyRiskDetailsModal,
}) {
    const companyDetails = companyEntityDetailsData?.entityDetails;

    const baseURLSegmentsCount = 2;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

    return (
        <Skeleton loading={isLoadingRiskDetails} active>
            <Styled.RiskDetailsWrapper>
                <div>
                    <h2>{t('Current Risk Score')}</h2>
                    <Styled.CurrentRiskDetailsWrapper>
                        <div>
                            <CompanyRiskTagContent countryRisk={companyDetails.company_total_risk_score} />
                        </div>

                        <CompanyRiskTag score={companyDetails?.company_total_risk_score} />
                    </Styled.CurrentRiskDetailsWrapper>

                    <Styled.ChangeRiskScoreWrapper>
                        {accessControl.hasPermission(PN.COMPANIES.UPDATE_RISK_SCORE)
              && !isInReadOnlyMode
              && (
                  <Button
                      type="primary"
                      size="small"
                      onClick={() => dispatchOpenAddCompanyRiskDetailsModal(baseUrlWithoutTabsSlugs)}
                  >
                  Change Risk Score
                  </Button>
              )}
                    </Styled.ChangeRiskScoreWrapper>
                </div>
            </Styled.RiskDetailsWrapper>
        </Skeleton>
    );
}


CompanyRiskDetails.propTypes = {
    t: PropTypes.func.isRequired,
    isInReadOnlyMode: PropTypes.string,
    accessControl: AccessControlProps,
    isLoadingRiskDetails: PropTypes.bool,
    companyEntityDetailsData: EntityDetailsProps,
    dispatchOpenAddCompanyRiskDetailsModal: PropTypes.func.isRequired,
};


export default CompanyRiskDetails;

