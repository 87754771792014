import InfoTooltip from 'components/common/atoms/InfoTooltip';
import CopyToClipboard from 'components/common/molecules/CopyToClipboard';

import React from 'react';


import Styled from './LabelAndValueRow.styled';
import { LabelAndValueRowProps } from './LabelAndValueRow.types';


function LabelAndValueRow({
    label,
    value,
    autoWidthOnValue = false,
    showCopyToClipboard = false,
    whiteSpaceNoWrap = true,
    highlightValue = false,
    tooltipContentForMisleadingLabels = undefined,
}: LabelAndValueRowProps) {
    return (
        <Styled.LabelAndValueRow
            $autoWidthOnValue={autoWidthOnValue}
            $showCopyToClipboard={showCopyToClipboard}
        >
            <Styled.Label>
                {label}
                {tooltipContentForMisleadingLabels && (
                    <Styled.LabelTooltip>
                        <InfoTooltip tooltipContent={tooltipContentForMisleadingLabels} />
                    </Styled.LabelTooltip>
                )}
            </Styled.Label>
            <Styled.Value
                $whiteSpaceNoWrap={whiteSpaceNoWrap}
                $highlightValue={highlightValue}
            >
                {value || '-'}
            </Styled.Value>
            {showCopyToClipboard && <CopyToClipboard value={value} />}
        </Styled.LabelAndValueRow>
    );
}

export default LabelAndValueRow;
