import { createAction } from '@reduxjs/toolkit';

import { CLEAR_STAKEHOLDERS_LIST, FETCH_STAKEHOLDERS_LIST, FETCH_STAKEHOLDERS_LIST_FAILURE, FETCH_STAKEHOLDERS_LIST_SUCCESS } from './actions.types';


export const fetchStakeholdersList = createAction(FETCH_STAKEHOLDERS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchStakeholdersListSuccess = createAction(FETCH_STAKEHOLDERS_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchStakeholdersListFailure = createAction(FETCH_STAKEHOLDERS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearStakeholdersList = createAction(CLEAR_STAKEHOLDERS_LIST, (locationPathname) => ({ payload: { locationPathname } }));

