import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import {
    downloadMigrationResult,
    downloadMigrationReport,
    fetchClientMigrationsList, clearClientMigrationsList,
} from 'redux/client-migrations/actions';
import { clientMigrationsListsPerLocationStoreKeyName, clientMigrationsReducerName } from 'redux/client-migrations/reducer';
import {
    showModal,
    hideModal,
} from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import MembersMigration from './MembersMigration.layout';


const mapStateToProps = (state) => ({
    isLoadingClientMigrationReport: state[clientMigrationsReducerName].isLoadingClientMigrationReport,
    isUploadingClientMigrationReport: state[clientMigrationsReducerName].isUploadingClientMigrationReport,

    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: clientMigrationsReducerName,
        listsByLocationStoreKeyName: clientMigrationsListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchShowModal: (data) => dispatch(showModal(data)),
    dispatchHideModal: () => dispatch(hideModal()),
    dispatchDownloadMigrationResult: (queryParams, locationPathname) => dispatch(downloadMigrationResult(queryParams, locationPathname)),
    dispatchDownloadMigrationReport: (queryParams, locationPathname) => dispatch(downloadMigrationReport(queryParams, locationPathname)),

    dispatchFetchClientMigrationsList: (queryParams, locationPathname) => dispatch(fetchClientMigrationsList(queryParams, locationPathname)),
    dispatchClearClientMigrationsList: (locationPathname) => dispatch(clearClientMigrationsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: clientMigrationsReducerName,
        fieldName: clientMigrationsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(MembersMigration);

