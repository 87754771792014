import { AccountTypes } from 'constants/AccountModel';


const createValidCurrenciesData = (rawData = {}, availableCurrenciesNames = {}) => Object.values(rawData).reduce((acc, currencyFromProduct) => {
    const currencyName = availableCurrenciesNames[currencyFromProduct.currency_code];

    return [
        ...acc,
        ...(currencyName ? [{
            short: currencyFromProduct.currency_code,
            banking_details_available: currencyFromProduct.banking_details_available,
            full_name: currencyName,

        }] : []),
    ];
}, []);

const flatterCurrencies = (rawData, availableCurrenciesNames) => Object.keys(rawData).reduce((acc, accountType) => ({
    ...acc,
    [accountType]: createValidCurrenciesData(rawData[accountType], availableCurrenciesNames),
}), {});

const initialCombinations = ({
    allProducts,
    allowedAccountTypes,
    allowTechnical,
}) => (allProducts).reduce((acc, product) => {
    const { currency_code, account_type, banking_details_available } = product;
    const existingOfType = acc.accountTypes[account_type] || {};
    const existingTechnical = acc.accountTypes[AccountTypes.Technical] || {};

    if (allowedAccountTypes.includes(account_type)) {
        return {
            ...acc,
            accountTypes: {
                [account_type]: { ...existingOfType, [currency_code]: { currency_code, banking_details_available } },
                ...(allowTechnical ? {
                    [AccountTypes.Technical]: {
                        ...existingTechnical,
                        [currency_code]: { currency_code, banking_details_available: false },
                    },
                } : {}),
            },
            allCurrencies: {
                ...acc.allCurrencies,
                [currency_code]: currency_code,
            },
        };
    }

    return acc;
}, { accountTypes: {}, allCurrencies: {} });


const extractCurrenciesNames = (input, currencies) => Object.keys(input).reduce((acc, currencyCode) => {
    const currencyData = currencies.find((currency) => currency.short === currencyCode);

    return {
        ...acc,
        ...(currencyData?.full_name ? { [currencyCode]: currencyData.full_name } : {}),
    };
}, {});

export function parseProductsList({
    allProducts = [],
    allowedAccountTypes = [],
    allowTechnical = false,
    currencies,
}) {
    const combinations = initialCombinations({ allProducts, allowedAccountTypes, allowTechnical });
    const availableAccountTypes = Object.keys(combinations.accountTypes);
    const availableCurrenciesNames = extractCurrenciesNames(combinations.allCurrencies, currencies);

    return {
        combinations: flatterCurrencies(combinations.accountTypes, availableCurrenciesNames),
        availableAccountTypes,
    };
}


export default { parseProductsList };
