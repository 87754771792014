/* eslint no-underscore-dangle: 0 */

import SwitchWithInfoTooltip from 'components/common/atoms/SwitchWithInfoTooltip';
import TabSubHeading from 'components/common/atoms/TabSubHeading';
import { AccessControlProps } from 'constants/AccessControlModel';
import { AllCardStatuses } from 'constants/CardModel';
import { PN } from 'constants/PermissionsModel';
import noop from 'utils/function-tools';

import { Col, Row, Spin, Skeleton, Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import ActivityLogTable from './ActivityLogTable';


function Secure3DTab({
    accessControl,
    cardDetails,
    cardId,
    isLoadingData,
    isBusyUpdatingSettings,
    data,
    t,
    dispatchFetch3DSecureData,
    dispatchOpenEdit3DSecurePhoneNumberModal,
    dispatchUpdateCardIdSecure3dStatus,
    dispatchClearCardSecure3d,

}) {
    useEffect(() => () => dispatchClearCardSecure3d(), []);

    useEffect(() => {
        if (cardDetails?.enrolled) {
            dispatchFetch3DSecureData(cardId);
        }
    }, [cardDetails]);

    const isEnrolled = useMemo(
        () => cardDetails?.enrolled === true, [cardDetails],
    );

    const isOnlinePaymentDisabled = useMemo(
        () => cardDetails?.online_payment_enabled === false, [cardDetails],
    );


    const tooltipFor3dsUnblockSwitch = useMemo(
        () => {
            if (isOnlinePaymentDisabled) {
                return t('cardDetails.tabs.3dSecure.fields.block.tooltipBlockedAndManualChangeDisabled');
            }
            return t(`cardDetails.tabs.3dSecure.fields.block.${data?.blocked ? 'tooltipBlocked' : 'tooltipUnblocked'}`);
        }, [isOnlinePaymentDisabled, data],
    );

    const canUpdateEnrollment = useMemo(() => accessControl.hasPermission(PN.CARDS.UPDATE_ENROLLMENT)
    && cardDetails?.card_status === AllCardStatuses.ACTIVE,
    [accessControl, cardDetails]);
    const onSwitchChange = (value, key) => (canUpdateEnrollment ? dispatchUpdateCardIdSecure3dStatus(cardId, { [key]: value }) : undefined);


    return (
        <Spin tip="Updating..." spinning={isBusyUpdatingSettings}>
            <Col span={8}>
                <TabSubHeading text={t('cardDetails.tabs.3dSecure.subHeadings.enrolmentStatus')} />
                <Row justify="space-between" style={{ marginBottom: 5 }}>
                    <div>{t('cardDetails.tabs.3dSecure.fields.enroll.text')}</div>
                    <SwitchWithInfoTooltip
                        tooltipContent={t(`cardDetails.tabs.3dSecure.fields.enroll.${isEnrolled ? 'tooltipEnrolled' : 'tooltipDisenrolled'}`)}
                        checked={isEnrolled}
                        onChange={noop}
                        disabled
                    />
                </Row>
            </Col>

            {isEnrolled && (
                <Skeleton loading={isLoadingData} active>
                    <Col span={8}>
                        <TabSubHeading text={t('cardDetails.tabs.3dSecure.subHeadings.blockedStatus')} />
                        <Row justify="space-between" style={{ marginBottom: 5 }}>
                            <div>{t('cardDetails.tabs.3dSecure.fields.block.text')}</div>
                            <SwitchWithInfoTooltip
                                tooltipContent={tooltipFor3dsUnblockSwitch}
                                checked={data?.blocked}
                                disabled={isOnlinePaymentDisabled || !canUpdateEnrollment}
                                onChange={(checked) => onSwitchChange(checked, 'blocked')}
                            />
                        </Row>

                        {(data?.phone_number || canUpdateEnrollment) && (
                            <>
                                <TabSubHeading text={t('cardDetails.tabs.3dSecure.subHeadings.phoneNumber')} />
                                <Row justify="space-between" style={{ marginBottom: 5 }}>
                                    <div>{data?.phone_number || '-'}</div>
                                    {canUpdateEnrollment && (
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={() => dispatchOpenEdit3DSecurePhoneNumberModal(cardId, data?.phone_number)}
                                        >
                                            {t('common:buttons.editSection.text')}
                                        </Button>
                                    )}
                                </Row>
                            </>
                        )}

                    </Col>
                    {/* TODO: cardDetails -> Secure3DTab -> ActivityLogTable - PN._3DS_CHALLENGE.ACTIVE_CHALLENGES is this a correct permission to control display?  */}
                    {accessControl.hasPermission(PN._3DS_CHALLENGE.ACTIVE_CHALLENGES) && (
                        <Col>
                            <TabSubHeading text={t('cardDetails.tabs.3dSecure.subHeadings.logs')} />

                            <ActivityLogTable cardId={cardId} />
                        </Col>
                    )}

                </Skeleton>
            )}
        </Spin>
    );
}

Secure3DTab.propTypes = {
    accessControl: AccessControlProps,
    isLoadingData: PropTypes.bool.isRequired,
    isBusyUpdatingSettings: PropTypes.bool.isRequired,
    cardId: PropTypes.number.isRequired,
    data: PropTypes.shape({
        enrolled: PropTypes.bool.isRequired,
        blocked: PropTypes.bool.isRequired,
        phone_number: PropTypes.bool.isRequired,
    }),
    t: PropTypes.func.isRequired,
    dispatchOpenEdit3DSecurePhoneNumberModal: PropTypes.func.isRequired,
    dispatchUpdateCardIdSecure3dStatus: PropTypes.func.isRequired,
    dispatchFetch3DSecureData: PropTypes.func.isRequired,
    dispatchClearCardSecure3d: PropTypes.func.isRequired,
};

export default Secure3DTab;
