import { NavigationDirection } from 'constants/NavigationModel';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';


function NavigationGuard({
    requestedNextLocation,
    isNavigationConfirmationVisible,
    dispatchNavigationCanceled,
    dispatchUnblockNavigation,
    dispatchRequestNavigation,
    dispatchRequestNavigationBack,
    dispatchHideNavigationConfirmation,
    t,
}) {
    const [modal, contextHolder] = Modal.useModal();

    const confirmationDialogConfig = {
        title: t('navigationGuard.dialogTitle'),
        okText: t('navigationGuard.confirmButtonLabel'),
        okType: 'danger',
        cancelText: t('navigationGuard.abortButtonLabel'),
        zIndex: 9999,
        content: t('navigationGuard.warningText.generic'),

        onCancel: () => {
            dispatchNavigationCanceled();
            dispatchHideNavigationConfirmation();
        },
        onOk: () => {
            dispatchHideNavigationConfirmation();
            dispatchUnblockNavigation();
            if (!requestedNextLocation?.location || (requestedNextLocation?.location && requestedNextLocation?.meta?.direction === NavigationDirection.BACKWARD)) {
                dispatchRequestNavigationBack();
            } else {
                dispatchRequestNavigation(requestedNextLocation?.location, requestedNextLocation?.meta);
            }
        },
    };


    useEffect(() => {
        if (isNavigationConfirmationVisible) {
            modal.confirm(confirmationDialogConfig);
        }
    }, [isNavigationConfirmationVisible]);

    return contextHolder;
}

NavigationGuard.propTypes = {
    isNavigationConfirmationVisible: PropTypes.bool,
    requestedNextLocation: PropTypes.shape({
        location: PropTypes.string,
        meta: PropTypes.shape({}),
    }),
    dispatchNavigationCanceled: PropTypes.func.isRequired,
    dispatchUnblockNavigation: PropTypes.func.isRequired,
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchRequestNavigationBack: PropTypes.func.isRequired,
    dispatchHideNavigationConfirmation: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default NavigationGuard;
