import { HttpService } from 'services/http';

import { SendConfirmationCodePayload } from '../../redux/authorisation/actions.types';


export const loginInitAPI = (requestPayload) => HttpService.post('/v2/bop/auth/login/init', requestPayload, { noErrorHandling: true });

export const loginConfirmAPI = (requestPayload, config) => HttpService.post('/v2/bop/auth/login/confirm', requestPayload, config);

export const setNewPasswordAPI = (requestPayload) => HttpService.post('/v2/bop/auth/password/reset', requestPayload, { noErrorHandling: true });

export const requestPasswordResetAPI = (requestPayload) => HttpService.post('/v2/bop/auth/password/forgot', requestPayload, { noErrorHandling: true });

export const extendTokenValidityAPI = (requestPayload, config) => HttpService.put('/v2/bop/auth/session-refresh', requestPayload, config);

export const getTokensForDuplicatedSessionAPI = (requestPayload, config) => HttpService.put('/v2/bop/auth/session-duplicate', requestPayload, config);

export const expireTokenAPI = () => HttpService.put('/v2/auth/session-logout', undefined, { noErrorHandling: true });

export const unlockLoginAPI = (requestPayload) => HttpService.post('/v2/bop/auth/user/unlock', requestPayload);

export const sendConfirmationCodeAPI = (requestPayload: SendConfirmationCodePayload) => HttpService.post(
    '/v3/bop/auth/send-confirmation-code',
    requestPayload,
    { noErrorHandling: true },
);
