import { submitCardSecure3dPhoneNumberModal } from 'redux/card-secure3d/actions';
import { setModalProps } from 'redux/modal/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import EditPhoneNumberModal from './EditPhoneNumberModal.layout';


const mapDispatchToProps = (dispatch) => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    dispatchSubmitCardSecure3dPhoneNumberModal:
    (cardId, newPhoneNumber) => dispatch(submitCardSecure3dPhoneNumberModal(cardId, newPhoneNumber)),
});


const decorate = compose(
    withTranslation('cards'),
    connect(null, mapDispatchToProps),
);


export default decorate(EditPhoneNumberModal);
