import { showSuccessToast } from 'redux/application/actions';
import { hideModal, setModalProps } from 'redux/modal/actions';
import { createOnOpenRegisterOrEditWebhookAction } from 'redux/webhooks/epics.helpers';
import { webhooksListsPerLocationStoreKeyName, webhooksReducerName } from 'redux/webhooks/reducer';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    fetchWebhooksActionTypesSuccess,
    fetchWebhooksActionTypesFailure,
    registerWebhookFailure,
    registerWebhookSuccess,
    unregisterWebhookFailure,
    unregisterWebhookSuccess,
    updateWebhookFailure,
    updateWebhookSuccess,
    fetchWebhooksListSuccess,
    fetchWebhooksListFailure,
    fetchWebhooksList,
} from './actions';
import {
    FETCH_WEBHOOKS_ACTION_TYPES,
    FETCH_WEBHOOKS_LIST,
    OPEN_REGISTER_OR_EDIT_WEBHOOK_MODAL,
    REGISTER_WEBHOOK,
    UNREGISTER_WEBHOOK,
    UPDATE_WEBHOOK,
} from './actions.types';


export const onFetchWebhooks = (action$, state$, { webhooks }) => action$.pipe(
    ofType(FETCH_WEBHOOKS_LIST),
    switchMap(({ payload }) => from(webhooks.fetchWebhooks(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchWebhooksListSuccess(
            response.data,
            response.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchWebhooksListFailure(payload.locationPathname))),
    )),
);

export const onFetchWebhooksActionTypes = (action$, state$, { webhooks }) => action$.pipe(
    ofType(FETCH_WEBHOOKS_ACTION_TYPES),
    switchMap(() => from(webhooks.fetchWebhooksActionTypes()).pipe(
        switchMap((response) => of(fetchWebhooksActionTypesSuccess(response.data))),
        catchError(() => of(fetchWebhooksActionTypesFailure())),
    )),
);

export const onOpenRegisterOrEditWebhookModal = (action$, state$, { i18n }) => action$.pipe(
    ofType(OPEN_REGISTER_OR_EDIT_WEBHOOK_MODAL),
    switchMap(({ payload }) => of(createOnOpenRegisterOrEditWebhookAction(payload, i18n))),
);

export const onRegisterWebhook = (action$, state$, { webhooks, i18n }) => action$.pipe(
    ofType(REGISTER_WEBHOOK),
    switchMap(({ payload }) => from(webhooks.registerWebhook(payload.formData)).pipe(
        switchMap((response) => {
            const pathname = payload.locationPathname;
            const currentWebhookListQueryParams = state$.value[webhooksReducerName][webhooksListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

            return of(
                registerWebhookSuccess(response.data),
                showSuccessToast(i18n.t('webhooks:actionMessages.registerWebhookSuccess')),
                hideModal(),
                fetchWebhooksList({ ...currentWebhookListQueryParams }, pathname),
            );
        }),
        catchError(() => of(
            registerWebhookFailure(payload.locationPathname),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);

export const onUpdateWebhook = (action$, state$, { webhooks, i18n }) => action$.pipe(
    ofType(UPDATE_WEBHOOK),
    switchMap(({ payload }) => from(webhooks.updateWebhook(payload.webhookUuid, payload.formData)).pipe(
        switchMap((response) => {
            const pathname = payload.locationPathname;
            const currentWebhookListQueryParams = state$.value[webhooksReducerName][webhooksListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

            return of(
                updateWebhookSuccess(response.data, pathname),
                hideModal(),
                showSuccessToast(i18n.t('webhooks:actionMessages.editWebhookSuccess')),
                fetchWebhooksList({ ...currentWebhookListQueryParams }, pathname),
            );
        }),
        catchError(() => of(
            updateWebhookFailure(payload.locationPathname),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);

export const onUnregisterWebhook = (action$, state$, { webhooks, i18n }) => action$.pipe(
    ofType(UNREGISTER_WEBHOOK),
    switchMap(({ payload }) => from(webhooks.unregisterWebhook(payload.webhookUuid)).pipe(
        switchMap((response) => {
            const pathname = payload.locationPathname;
            const currentWebhookListQueryParams = state$.value[webhooksReducerName][webhooksListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

            return of(
                unregisterWebhookSuccess(response.data, pathname),
                showSuccessToast(i18n.t('webhooks:actionMessages.unregisterWebhookSuccess')),
                fetchWebhooksList({ ...currentWebhookListQueryParams }, pathname),
            );
        }),
        catchError(() => of(unregisterWebhookFailure(payload.locationPathname))),
    )),
);


export default [
    onFetchWebhooks,
    onFetchWebhooksActionTypes,
    onOpenRegisterOrEditWebhookModal,
    onRegisterWebhook,
    onUpdateWebhook,
    onUnregisterWebhook,
];
