import { addPermissionsToRole, clearRoleTemplate, removeAllPermissionsFromRole, selectRoleTemplate } from 'redux/role/actions';
import { roleReducerName } from 'redux/role/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import SelectRoleTemplateStep from './SelectRoleTemplateStep.layout';


const mapStateToProps = (state) => ({ selectedRoleTemplate: state[roleReducerName]?.selectedRoleTemplate });

const mapDispatchToProps = (dispatch) => ({
    dispatchSelectRoleTemplate: (selectedRole) => dispatch(selectRoleTemplate(selectedRole)),
    dispatchClearRoleTemplate: () => dispatch(clearRoleTemplate()),
    dispatchAddPermissionsToRole: (permissions) => dispatch(addPermissionsToRole(permissions)),
    dispatchRemoveAllPermissionsFromRole: () => dispatch(removeAllPermissionsFromRole()),
});

const decorate = compose(
    withTranslation('roles'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(SelectRoleTemplateStep);
