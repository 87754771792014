import PasswordInput from 'components/common/molecules/form-controls/PasswordInputField';
import { UnauthorisedScreenTitle } from 'components/layouts/PublicLayout/PublicLayout.styled';
import { isMatchingGivenRegexRule, passwordRequirementsRegex } from 'utils/validation-tools';

import { Form, Button } from 'antd';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';


import { SetPasswordProps } from './SetPassword.types';


function SetPassword({
    isLoadingSetNewPassword,
    dispatchSetNewPassword,
    t,
}: SetPasswordProps) {
    const location = useLocation();

    const linkQueryParams = useMemo(
        () => {
            if (location?.search && location?.search?.length > 0) {
                const params = new URLSearchParams(location?.search);
                return {
                    token: params.get('token'),
                    confirmationCode: params.get('code'),
                };
            }
            return undefined;
        },
        [location.search],
    );

    const onSubmitHandler = (values) => {
        dispatchSetNewPassword({ ...values, ...linkQueryParams });
    };


    return (
        <>
            <UnauthorisedScreenTitle>
                {t('unauthorised:screenTitles.setNewPassword')}
            </UnauthorisedScreenTitle>

            <Form
                initialValues={{ password: '', confirmPassword: '' }}
                onFinish={onSubmitHandler}
            >

                <PasswordInput
                    t={t}
                    placeholder={t('unauthorised:formsElements.newPassword.placeholder')}
                    isRequiredMessage={t('unauthorised:formsElements.newPassword.required')}
                    /* TODO passwordRequirementsRegex check should be eventually a part of PasswordInput default validationRules
               but that requires SA change and will affect end-user */
                    additionalValidationRules={isMatchingGivenRegexRule({
                        t,
                        fieldName: 'new password',
                        regexPattern: passwordRequirementsRegex,
                        messageError: t(t('unauthorised:validationMsg.passwordUnmetRequirements')),
                    })}
                />

                <PasswordInput
                    t={t}
                    name="confirmPassword"
                    dataTestIdPrefix="confirm-password"
                    dependencies={['password']}
                    placeholder={t('unauthorised:formsElements.confirmPassword.placeholder')}
                    isRequiredMessage={t('unauthorised:formsElements.confirmPassword.required')}
                    additionalValidationRules={[
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(t('unauthorised:validationMsg.passwordMismatch'));
                            },
                        }),
                    ]}
                />

                <Form.Item style={{ paddingTop: 10 }}>
                    <Button
                        block
                        data-test-id="submit-set-new-password-button"
                        htmlType="submit"
                        type="primary"
                        loading={isLoadingSetNewPassword}
                    >
                        {t('unauthorised:formsElements.setNewPassword.text')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default SetPassword;
