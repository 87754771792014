import { CLEAR_USERS_LIST } from 'redux/users/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchUsers = createAction('users/fetchUsers', (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));

export const fetchUsersSuccess = createAction('users/fetchUsersSuccess', (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchUsersFailure = createAction('users/fetchUsersFailure', (locationPathname) => ({ payload: { locationPathname } }));
export const clearUsersList = createAction(CLEAR_USERS_LIST, (locationPathname) => ({ payload: { locationPathname } }));

export const openAddEditUserModal = createAction('users/openAddEditUserModal', (configData) => ({ payload: configData }));

export const addUser = createAction('users/addUser', (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const addUserSuccess = createAction('users/addUserSuccess', (responsePayload) => ({ payload: responsePayload }));
export const addUserFailure = createAction('users/addUserFailure');
