import withAccessControl from 'hocs/withAccessControl';
import {
    fetchCurrentKycKybFlow,
    setKycKybFlow,
    setIsInEditLevelMode,
} from 'redux/kyc-kyb/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import KycKybLevelSubTab from './KycKybLevel.layout';


const mapStateToProps = (state) => ({
    currentFlow: state.kycKyb.currentFlow,
    isLoadingCurrentKycKybFlow: state.kycKyb.isLoadingCurrentKycKybFlow,
    isLoadingSetKycKyb: state.kycKyb.isLoadingSetKycKyb,
    isInEditLevelMode: state.kycKyb.isInEditLevelMode,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchCurrentKycKybFlow: (queryParams) => dispatch(fetchCurrentKycKybFlow(queryParams)),
    dispatchSetKycKybFlow: (requestPayload) => dispatch(setKycKybFlow(requestPayload)),
    dispatchSetIsInEditLevelMode: (value) => dispatch(setIsInEditLevelMode(value)),
});

const decorate = compose(
    withTranslation('kycKyb'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(KycKybLevelSubTab);
