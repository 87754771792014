import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import CommonSection from 'components/common/organisms/CommonSection';
import { AccessControlProps } from 'constants/AccessControlModel';
import { PN } from 'constants/PermissionsModel';
import { UserListItemProps } from 'constants/UserModel';

import { Skeleton, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import { createCurrentUserFieldsConfig } from './CurrentUserInformation.helpers';


function CurrentUserInformation({
    t,
    accessControl,
    currentUser,
    entityDetailsData,
    isLoadingUserDetails,
    dispatchOpenAddEditUserModal,
}) {
    const isLoadingDetails = entityDetailsData?.isLoadingDetails && isLoadingUserDetails;
    const onEditClickHandler = () => dispatchOpenAddEditUserModal({
        title: t('modals.addEditUserModal.editTitle'),
        initialValues: { ...entityDetailsData?.entityDetails, userPreferences: currentUser.userPreferences },
        isCurrentUserSelfEditMode: true,
    });

    const fieldsConfig = useMemo(
        () => createCurrentUserFieldsConfig({ userDetails: entityDetailsData?.entityDetails, t, accessControl }),
        [entityDetailsData],
    );

    return (
        <Col
            justify="center"
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <Skeleton
                loading={isLoadingDetails}
                active
                style={{ minHeight: '432px' }}
            >
                <DetailsSectionsWrapper>
                    <Row gutter={24} size={10} direction="horizontal">
                        <CommonSection
                            sectionLabel={t('userDetails.sectionLabel')}
                            sectionEditTooltip={t('userDetails.sectionEditTooltip')}
                            fieldsConfig={fieldsConfig}
                            isLoading={entityDetailsData?.isLoadingDetails}
                            sectionEditOnClick={accessControl.hasPermission(PN.USERS.WRITE) ? onEditClickHandler : undefined}
                            sectionDeleteConfirmationText={t('userDetails.sectionDeleteConfirmationText')}
                        />
                    </Row>
                </DetailsSectionsWrapper>
            </Skeleton>
        </Col>
    );
}
CurrentUserInformation.propTypes = {
    accessControl: AccessControlProps,
    t: PropTypes.func.isRequired,
    entityDetailsData: PropTypes.shape({
        entityDetails: UserListItemProps,
        isLoadingDetails: PropTypes.bool,
    }),
    currentUser: PropTypes.shape({
        userData: PropTypes.shape({ userId: PropTypes.number }),
        userPreferences: PropTypes.shape({}),
        permissions: PropTypes.shape({}),
    }),
    isLoadingUserDetails: PropTypes.bool.isRequired,
    dispatchOpenAddEditUserModal: PropTypes.func.isRequired,

};
export default CurrentUserInformation;
