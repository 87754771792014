import {
    fetchFeeDetailsSuccess,
    fetchFeeDetailsFailure,
} from 'redux/fee/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { FETCH_FEE_DETAILS } from './actions.types';


export const onFetchFeeDetails = (action$, state$, { fees }) => action$.pipe(
    ofType(FETCH_FEE_DETAILS),
    switchMap(({ payload }) => from(fees.fetchFeeDetails(payload.queryParams.feeUuid)).pipe(
        switchMap((response) => of(fetchFeeDetailsSuccess(response.data, payload.locationPathname))),
        catchError(() => of(fetchFeeDetailsFailure(payload.locationPathname))),
    )),
);


export default [onFetchFeeDetails];
