import { cardRootRoutePath, cardsRootRoutePath, companyRootRoutePath, businessUserRootRoutePath, memberRootRoutePath } from 'config/routes';
import { fetchAllCards } from 'redux/cards/actions';
import { cardsListsPerLocationStoreKeyName, cardsReducerName } from 'redux/cards/reducer';

import { cardDetailsPerLocationStoreKeyName, cardReducerName } from './reducer';


export const UnlinkCardViews = {
    MEMBER: 'MEMBER',
    COMPANY: 'COMPANY',
    BUSINESS_USER: 'BUSINESS_USER',
};

export const getCardsListPathname = (prefix, id) => `${prefix}/${id}${cardsRootRoutePath}`;

export const createUnlinkCardUpdateAction = (meta, state) => {
    const { id, view } = meta;

    const handlers = [
        {
            predicate: () => view === UnlinkCardViews.MEMBER,
            handler: () => getCardsListPathname(memberRootRoutePath, id),
        },
        {
            predicate: () => view === UnlinkCardViews.COMPANY,
            handler: () => getCardsListPathname(companyRootRoutePath, id),
        },
        {
            predicate: () => view === UnlinkCardViews.BUSINESS_USER,
            handler: () => getCardsListPathname(businessUserRootRoutePath, id),
        },
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];
    const pathname = handlers.filter(({ predicate }) => predicate())[0].handler();
    if (pathname) {
        const currentCardsListQueryParams = state[cardsReducerName][cardsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;
        return [
            fetchAllCards(currentCardsListQueryParams, pathname),
        ];
    }
    return [];
};

export default {
    UnlinkCardViews,
    createUnlinkCardUpdateAction,
};

export const createCardPath = (cardId) => `${cardRootRoutePath}/${cardId}`;

export const getEntityDetails = (state$, cardId) => state$.value[cardReducerName]?.[cardDetailsPerLocationStoreKeyName]?.[createCardPath(cardId)]?.entityDetails;
