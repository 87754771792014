import { BusinessUserStatus } from 'constants/BusinessUserModel';
import { enumValueToReadableString } from 'utils/string-tools';


export const getBusinessUserStatusOptions = (status, accessControl) => {
    const handlers = [
        {
            predicate: () => status === BusinessUserStatus.ACTIVE,
            handler: () => [BusinessUserStatus.SUSPENDED, BusinessUserStatus.CLOSED],
        },
        {
            predicate: () => status === BusinessUserStatus.SUSPENDED,
            handler: () => [BusinessUserStatus.ACTIVE, BusinessUserStatus.CLOSED],
        },

        {
            predicate: () => [BusinessUserStatus.KYC_PENDING, BusinessUserStatus.KYC_UPLOADED, BusinessUserStatus.KYC_REJECTED].includes(status),
            handler: () => [BusinessUserStatus.CLOSED],
        },

        // XXX for isKycKybApproval === 'EXTERNAL'
        ...(!accessControl.isKycKybApprovalEnabled()
            ? [{
                predicate: () => [BusinessUserStatus.UNVERIFIED].includes(status),
                handler: () => [BusinessUserStatus.CLOSED, BusinessUserStatus.ACTIVE, BusinessUserStatus.BANNED],
            }]
            : []),

        {
            predicate: () => true,
            handler: () => [],
        },
    ];

    return handlers.find(({ predicate }) => predicate())?.handler().map((status) => ({
        value: status,
        label: enumValueToReadableString(status),
    }));
};


export const getHintAlertProps = ({ alertObject, getTranslation, statusOptionsHasItems }) => {
    const handlers = [
        {
            predicate: () => alertObject?.text,
            handler: () => ({
                message: getTranslation(`fields.businessUserStatus.businessUserStatusesDescriptions.${alertObject?.text}`),
                type: alertObject.color,
            }),
        },
        {
            predicate: () => !statusOptionsHasItems,
            handler: () => ({
                message: getTranslation('fields.businessUserStatus.transitionNotAvailableHint'),
                type: 'warning',
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                message: getTranslation('fields.businessUserStatus.businessUserStatusesDescriptionsPlaceholder'),
                type: 'info',
            }),
        },
    ];

    return handlers.find(({ predicate }) => predicate()).handler();
};

export const getStatusHint = (status) => {
    const handlers = [
        {
            predicate: () => status === BusinessUserStatus.SUSPENDED,
            handler: () => ({
                color: 'warning',
                text: BusinessUserStatus.SUSPENDED,
            }),
        },
        {
            predicate: () => status === BusinessUserStatus.ACTIVE,
            handler: () => ({
                color: 'success',
                text: BusinessUserStatus.ACTIVE,
            }),
        },
        {
            predicate: () => status === BusinessUserStatus.CLOSED,
            handler: () => ({
                color: 'error',
                text: BusinessUserStatus.CLOSED,
            }),
        },
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];
    return handlers.find((handler) => handler.predicate(status)).handler();
};
