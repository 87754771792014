import StandardTooltip from 'components/common/molecules/StandardTooltip';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createGroupDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { sortDirections, sortBooleansByKey, sortNumbers, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import { StarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';


function createGroupsTableColumns({
    accessControl,
    clientId,
    t,
    filterValue,
    dispatchRequestNavigation,
}) {
    const canViewGroupDetails = accessControl.hasPermission(PN.GROUPS.READ_BY_ID);

    return [
        {
            title: t('common:table.columnNames.id'),
            dataIndex: 'id',
            key: 'id',
            render: (text) => <StandardValue value={text} />,
            sortDirections,
            sorter: (a, b) => sortNumbers(a, b, 'id'),
        },
        {
            title: t('common:table.columnNames.groupName'),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <StandardValue value={text} searchQuery={filterValue} />,
            sortDirections,
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'name'),
        },
        {
            title: t('common:table.columnNames.default'),
            dataIndex: 'is_default',
            key: 'is_default',
            align: 'center',
            render: (text, record) => record.is_default && (
                <StandardTooltip title={'Default group'}>
                    <StarOutlined style={{ fontSize: '20px', color: 'gold' }} />
                </StandardTooltip>
            ),
            sortDirections,
            sorter: (a, b) => sortBooleansByKey(a, b, 'is_default'),
        },
        {
            title: t('groups:groupsFields.institutionId'),
            dataIndex: 'institution_id',
            key: 'institution_id',
            align: 'center',
            render: (text) => <StandardValue value={text} />,
            sortDirections,
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'institution_id'),
        },
        ...(canViewGroupDetails ? [{
            title: 'Actions',
            dataIndex: '',
            align: 'center',
            render: (_, record) => viewButtonRenderer({
                record,
                onClick: (event, record) => dispatchRequestNavigation(createGroupDetailsPath(clientId, record.id)),
                tooltipContent: "View Group's details",
                dataTestId: 'view-group-details',
            }),
        }] : []),
    ];
}


createGroupsTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    filterValue: PropTypes.string,
};


export default createGroupsTableColumns;
