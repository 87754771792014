import StandardTag from 'components/common/atoms/StandardTag';
import { UserStatuses } from 'constants/UserModel';

import PropTypes from 'prop-types';
import React from 'react';


function UserStatusTag({ userStatus, t, showTooltip }) {
    const status = userStatus || UserStatuses.UNCONFIRMED;

    const handlers = [
        {
            predicate: () => status === UserStatuses.UNCONFIRMED,
            handler: () => ({
                color: 'orange',
                label: t('users:userStatuses.unconfirmed'),
            }),
        },
        {
            predicate: () => status === UserStatuses.ACTIVE,
            handler: () => ({
                color: 'green',
                label: t('users:userStatuses.active'),
            }),
        },
        {
            predicate: () => status === UserStatuses.BANNED,
            handler: () => ({
                color: 'red',
                label: t('users:userStatuses.banned'),
            }),
        },
        {
            predicate: () => true,
            handler: () => ({ label: 'Unknown' }),
        },
    ];

    const tagData = handlers.filter(({ predicate }) => predicate())[0].handler();

    if (tagData) {
        return <StandardTag color={tagData.color} text={tagData.label} showTooltip={showTooltip} />;
    }
    return <>-</>;
}

UserStatusTag.propTypes = {
    userStatus: PropTypes.oneOf([
        UserStatuses.UNCONFIRMED,
        UserStatuses.BANNED,
        UserStatuses.ACTIVE,
    ]),
    showTooltip: PropTypes.bool,
};

export default UserStatusTag;
