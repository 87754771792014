import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearApplicationsList, fetchApplicationsList } from 'redux/applications/actions';
import { applicationsListsPerLocationStoreKeyName, applicationsReducerName } from 'redux/applications/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ApplicationsList from './ApplicationsList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: applicationsReducerName,
        listsByLocationStoreKeyName: applicationsListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchApplicationsList: (queryParams, locationPathname) => dispatch(fetchApplicationsList(queryParams, locationPathname)),
    dispatchClearApplicationsList: (locationPathname) => dispatch(clearApplicationsList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: applicationsReducerName,
        fieldName: applicationsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('fees'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ApplicationsList);
