import accountReducer, { accountReducerName } from 'redux/account/reducer';
import accountsReducer, { accountsReducerName } from 'redux/accounts/reducer';
import apiKeysReducer, { apiKeysReducerName } from 'redux/api-keys/reducer';
import applicationReducer, { applicationUIReducerName } from 'redux/application/reducer';
import applicationsReducer, { applicationsReducerName } from 'redux/applications/reducer';
import authorisationReducer, { authorisationReducerName } from 'redux/authorisation/reducer';
import beneficiariesReducer, { beneficiariesReducerName } from 'redux/beneficiaries/reducer';
import beneficiaryReducer, { beneficiaryReducerName } from 'redux/beneficiary/reducer';
import beneficiaryAddReducer, { beneficiaryAddReducerName } from 'redux/beneficiary-add/reducer';
import businessStakeholdersReducer, { businessStakeholdersReducersName } from 'redux/business-stakeholders/reducer';
import businessUserReducer, { businessUserReducerName } from 'redux/business-user/reducer';
import businessUserStatusReducer, { businessUserStatusReducerName } from 'redux/business-user-status/reducer';
import businessUsersReducer, { businessUsersReducerName } from 'redux/business-users/reducer';
import cardReducer, { cardReducerName } from 'redux/card/reducer';
import cardLinkingReducer, { cardLinkingReducerName } from 'redux/card-linking/reducer';
import cardOrderReducer, { cardsOrderReducerName } from 'redux/card-order/reducer';
import card3dSecureReducer, { card3dSecureReducerName } from 'redux/card-secure3d/reducer';
import cardStatusHistoryReducer, { cardStatusHistoryReducerName } from 'redux/card-status-history/reducer';
import cardTiersReducer, { cardTiersReducerName } from 'redux/card-tiers/reducer';
import cardsReducer, { cardsReducerName } from 'redux/cards/reducer';
import clientReducer, { clientReducerName } from 'redux/client/reducer';
import addClientReducer, { addClientReducerName } from 'redux/client-add/reducer';
import clientContextReducer, { clientContextReducerName } from 'redux/client-context/reducer';
import clientMigrationsReducer, { clientMigrationsReducerName } from 'redux/client-migrations/reducer';
import clientProductsReducer, { clientProductsReducerName } from 'redux/client-products/reducer';
import clientsReducer, { clientsReducerName } from 'redux/clients/reducer';
import companiesReducer, { companiesReducerName } from 'redux/companies/reducer';
import companyReducer, { companyReducerName } from 'redux/company/reducer';
import countriesReducer, { countriesReducerName } from 'redux/countries/reducer';
import currencyReducer, { currenciesReducerName } from 'redux/currencies/reducer';
import supportedCurrenciesReducer, { supportedCurrenciesReducerName } from 'redux/currencies-supported/reducer';
import currentUserReducer, { currentUserReducerName } from 'redux/current-user/reducer';
import drawerReducer, { drawerReducerName } from 'redux/drawer/reducer';
import exchangeReducer, { exchangeReducerName } from 'redux/exchange/reducer';
import failedWebhooksReducer, { failedWebhooksReducerName } from 'redux/failed-webhooks/reducer';
import feeReducer, { feeReducerName } from 'redux/fee/reducer';
import feeTiersReducer, { feeTiersReducerName } from 'redux/fee-tiers/reducer';
import feesReducer, { feesReducerName } from 'redux/fees/reducer';
import groupReducer, { groupReducerName } from 'redux/group/reducer';
import groupMembersReducer, { groupMembersReducerName } from 'redux/group-members/reducer';
import groupsReducer, { groupsReducerName } from 'redux/groups/reducer';
import industriesReducer, { industriesReducerName } from 'redux/industries/reducer';
import kycKybReducer, { kycKybReducerName } from 'redux/kyc-kyb/reducer';
import limitReducer, { limitReducerName } from 'redux/limit/reducer';
import limitsReducer, { limitsReducerName } from 'redux/limits/reducer';
import marketingPreferencesReducer, { marketingPreferencesReducerName } from 'redux/marketing-preferences/reducer';
import memberReducer, { memberReducerName } from 'redux/member/reducer';
import membersReducer, { membersReducerName } from 'redux/members/reducer';
import modalReducer, { modalReducerName } from 'redux/modal/reducer';
import multiStepsFormReducer, { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';
import navigationReducer, { navigationReducerName } from 'redux/navigation/reducer';
import offboardingReducer, { offboardingReducerName } from 'redux/offboarding/reducer';
import operationsReducer, { operationsReducerName } from 'redux/operations/reducer';
import outgoingTransferReducer, { outgoingTransferReducerName } from 'redux/outgoing-transfer/reducer';
import permissionsReducer, { permissionsReducerName } from 'redux/permissions/reducer';
import promotionReducer, { promotionReducerName } from 'redux/promotion/reducer';
import promotionsReducer, { promotionsReducerName } from 'redux/promotions/reducer';
import riskAssessmentsReducer, { riskAssessmentsReducerName } from 'redux/risk-assessments/reducer';
import roleReducer, { roleReducerName } from 'redux/role/reducer';
import rolesReducer, { rolesReducerName } from 'redux/roles/reducer';
import topupReducer, { topupReducerName } from 'redux/topup/reducer';
import transactionReducer, { transactionReducerName } from 'redux/transaction/reducer';
import transactionsReducer, { transactionsReducerName } from 'redux/transactions/reducer';
import userReducer, { userReducerName } from 'redux/user/reducer';
import usersReducer, { usersReducerName } from 'redux/users/reducer';
import webhooksReducer, { webhooksReducerName } from 'redux/webhooks/reducer';

import { combineReducers } from '@reduxjs/toolkit';

import companyStatusReducer, { companyStatusReducerName } from './company-status/reducer';
import failedWebhookReducer, { failedWebhookReducerName } from './failed-webhook/reducer';
import failedWebhookErrorsListReducer, { failedWebhookErrorsListReducerName } from './failed-webhook-history/reducer';
import manualFeesReducer, { manualFeesReducerName } from './manual-fees/reducer';
import memberStatusReducer, { memberStatusReducerName } from './member-status/reducer';
import riskScoreHistoryReducer, { riskScoreHistoryReducerName } from './risk-score-history/reducer';
import transactionStatusHistoryReducer, { transactionStatusHistoryReducerName } from './transaction-status-history/reducer';


const rootReducer = combineReducers({
    // XXX very, very important reducers
    [authorisationReducerName]: authorisationReducer,
    [applicationUIReducerName]: applicationReducer,
    [clientContextReducerName]: clientContextReducer,
    [currentUserReducerName]: currentUserReducer,
    [drawerReducerName]: drawerReducer,
    [navigationReducerName]: navigationReducer,
    [modalReducerName]: modalReducer,

    // screen/entity/controller specific reducers
    [accountReducerName]: accountReducer,
    [accountsReducerName]: accountsReducer,
    [apiKeysReducerName]: apiKeysReducer,
    [applicationsReducerName]: applicationsReducer,
    [beneficiariesReducerName]: beneficiariesReducer,
    [beneficiaryReducerName]: beneficiaryReducer,
    [beneficiaryAddReducerName]: beneficiaryAddReducer,
    [businessStakeholdersReducersName]: businessStakeholdersReducer,
    [businessUserReducerName]: businessUserReducer,
    [businessUsersReducerName]: businessUsersReducer,
    [businessUserStatusReducerName]: businessUserStatusReducer,
    [cardReducerName]: cardReducer,
    [cardsReducerName]: cardsReducer,
    [card3dSecureReducerName]: card3dSecureReducer,
    [cardLinkingReducerName]: cardLinkingReducer,
    [cardsOrderReducerName]: cardOrderReducer,
    [cardStatusHistoryReducerName]: cardStatusHistoryReducer,
    [clientReducerName]: clientReducer,
    [clientMigrationsReducerName]: clientMigrationsReducer,
    [addClientReducerName]: addClientReducer,
    [clientProductsReducerName]: clientProductsReducer,
    [clientsReducerName]: clientsReducer,
    [companyReducerName]: companyReducer,
    [companyStatusReducerName]: companyStatusReducer,
    [companiesReducerName]: companiesReducer,
    [countriesReducerName]: countriesReducer,
    [currenciesReducerName]: currencyReducer,
    [cardTiersReducerName]: cardTiersReducer,
    [supportedCurrenciesReducerName]: supportedCurrenciesReducer,
    [exchangeReducerName]: exchangeReducer,
    [feeReducerName]: feeReducer,
    [feesReducerName]: feesReducer,
    [manualFeesReducerName]: manualFeesReducer,
    [feeTiersReducerName]: feeTiersReducer,
    [groupReducerName]: groupReducer,
    [groupMembersReducerName]: groupMembersReducer,
    [groupsReducerName]: groupsReducer,
    [industriesReducerName]: industriesReducer,
    [kycKybReducerName]: kycKybReducer,
    [limitsReducerName]: limitsReducer,
    [limitReducerName]: limitReducer,
    [marketingPreferencesReducerName]: marketingPreferencesReducer,
    [memberReducerName]: memberReducer,
    [memberStatusReducerName]: memberStatusReducer,
    [membersReducerName]: membersReducer,
    [multiStepsFormReducerName]: multiStepsFormReducer,
    [offboardingReducerName]: offboardingReducer,
    [operationsReducerName]: operationsReducer,
    [outgoingTransferReducerName]: outgoingTransferReducer,
    [permissionsReducerName]: permissionsReducer,
    [promotionReducerName]: promotionReducer,
    [promotionsReducerName]: promotionsReducer,
    [riskAssessmentsReducerName]: riskAssessmentsReducer,
    [riskScoreHistoryReducerName]: riskScoreHistoryReducer,
    [roleReducerName]: roleReducer,
    [rolesReducerName]: rolesReducer,
    [topupReducerName]: topupReducer,
    [transactionReducerName]: transactionReducer,
    [transactionsReducerName]: transactionsReducer,
    [transactionStatusHistoryReducerName]: transactionStatusHistoryReducer,
    [userReducerName]: userReducer,
    [usersReducerName]: usersReducer,
    [webhooksReducerName]: webhooksReducer,
    [failedWebhooksReducerName]: failedWebhooksReducer,
    [failedWebhookReducerName]: failedWebhookReducer,
    [failedWebhookErrorsListReducerName]: failedWebhookErrorsListReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
