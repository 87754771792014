import { createAction } from '@reduxjs/toolkit';

import { CLEAR_LIMIT_DETAILS, FETCH_LIMIT_DETAILS, FETCH_LIMIT_DETAILS_FAILURE, FETCH_LIMIT_DETAILS_SUCCESS } from './actions.types';


export const fetchLimitDetails = createAction(FETCH_LIMIT_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchLimitDetailsSuccess = createAction(FETCH_LIMIT_DETAILS_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchLimitDetailsFailure = createAction(FETCH_LIMIT_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const clearLimitDetails = createAction(CLEAR_LIMIT_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));
