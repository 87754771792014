import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createFeeDetailsPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { PN } from 'constants/PermissionsModel';
import { removeUnderscores } from 'utils/string-tools';
import { sortBooleansByKey, sortingOptions, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import { PlayCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function createManualFeesListTableColumns({
    t,
    accessControl,
    queryParams,
    dispatchRequestNavigation,
    dispatchOpenTriggerManualFeeModal,
}) {
    const canViewFeeDetails = accessControl.hasPermission(PN.FEES.READ_BY_ID);

    return [
        {
            title: t('fees:feeFields.feeUuid'),
            dataIndex: 'ext_id',
            render: (text, record) => (canViewFeeDetails ? (
                <StandardLink
                    path={createFeeDetailsPath(record.ext_id)}
                    title={text}
                    showTooltip
                />
            ) : <StandardValue value={text} />),
            ...sortingOptions({
                queryParams,
                fieldName: 'ext_id',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'ext_id'),
            }),
        },
        {
            title: t('fees:feeFields.feeName'),
            dataIndex: 'name',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'name',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'name'),
            }),
        },
        {
            title: t('fees:feeFields.feeDescription'),
            dataIndex: 'description',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'description',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'description'),
            }),
        },
        {
            title: t('fees:feeFields.feeEnabled'),
            dataIndex: 'enabled',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(record, 'enabled', t),
            ...sortingOptions({
                queryParams,
                fieldName: 'enabled',
                sorter: (a, b) => sortBooleansByKey(a, b, 'enabled'),
            }),
        },

        {
            title: t('fees:feeFields.feeChargeType'),
            dataIndex: 'charge_type',
            render: (chargeType) => <StandardValue value={removeUnderscores(chargeType)} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'charge_type',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'charge_type'),
            }),
        },
        ...(canViewFeeDetails ? [{
            title: 'Actions',
            dataIndex: '',
            align: 'center',
            render: (_, record) => (
                <Space>
                    {
                        accessControl.hasPermission(PN.FEES.TRIGGER_MANUAL_FEE) && viewButtonRenderer({
                            record,
                            icon: <PlayCircleOutlined />,
                            onClick: (event, record) => dispatchOpenTriggerManualFeeModal({
                                feeUuid: record.ext_id,
                                name: record.name,
                                clientId: record.client_id,
                            }),
                            tooltipContent: 'Trigger fee',
                            dataTestId: 'trigger-manual-fee',
                        })
                    }
                    {
                        viewButtonRenderer({
                            record,
                            onClick: (event, record) => dispatchRequestNavigation(createFeeDetailsPath(record.ext_id)),
                            tooltipContent: "View fee's details",
                            dataTestId: 'view-fee-details',
                        })
                    }
                </Space>
            ),
        }] : []),
    ];
}


createManualFeesListTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    accessControl: AccessControlProps,
    dispatchOpenTriggerManualFeeModal: PropTypes.func.isRequired,
};


export default createManualFeesListTableColumns;
