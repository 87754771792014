import styled from 'styled-components';


const UpdateStatusButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    > div:first-of-type {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    > div:last-of-type > div {
        margin-bottom: 8px;
    }
    button {
        width: 12rem;
    }
`;

export default { UpdateStatusButtonsWrapper };
