import CommonAddressDisplay from 'components/common/organisms/CommonAddressDisplay';
import { AllEntityTypes } from 'constants/ApplicationStateModel';
import ModalName from 'constants/Modals';

import React from 'react';


export const createAddressTabs = ({
    isLoadingAddress,
    canUpdateClient,
    client,
    clientId,
    billingAddress,
    dispatchOpenEditAddressModal,
    shippingAddress,
    t,
}) => [
    {
        key: '1',
        label: t('clients:clientDetails.sections.addresses.company.sectionLabel'),
        children: (
            <CommonAddressDisplay
                isLoading={isLoadingAddress}
                key="company-address"
                sectionLabel={t('clients:clientDetails.sections.addresses.company.sectionLabel')}
                sectionEditTooltip={canUpdateClient ? t('clients:clientDetails.sections.addresses.company.sectionEditTooltip') : undefined}
                addressData={client?.client_address}
                sectionEditOnClick={canUpdateClient ? () => dispatchOpenEditAddressModal({
                    modalType: ModalName.EDIT_CLIENT_ADDRESS_MODAL,
                    modalTitle: t('clients:modals.editClientCompanyAddress.title'),
                    addressId: client?.client_address.id,
                    initialValues: client?.client_address,
                    addressType: {
                        addressType: 'client_address',
                        entityId: clientId,
                        entityType: AllEntityTypes.CLIENT,
                    },
                }) : undefined}
            />
        ),
    },
    {
        key: '2',
        label: t('clients:clientDetails.sections.addresses.billing.sectionLabel'),
        children: (
            <CommonAddressDisplay
                key="billing-address"
                sectionLabel={t('clients:clientDetails.sections.addresses.billing.sectionLabel')}
                sectionEditTooltip={canUpdateClient ? t('clients:clientDetails.sections.addresses.billing.sectionEditTooltip') : undefined}
                addressData={billingAddress}
                sectionEditOnClick={canUpdateClient ? () => dispatchOpenEditAddressModal({
                    modalType: ModalName.EDIT_CLIENT_ADDRESS_MODAL,
                    modalTitle: t('clients:modals.editClientBillingAddress.title'),
                    addressId: billingAddress?.id,
                    initialValues: billingAddress,
                    addressType: {
                        addressType: billingAddress?.type,
                        entityId: clientId,
                        entityType: AllEntityTypes.CLIENT,
                    },
                }) : undefined}
            />
        ),
    },
    {
        key: '3',
        label: t('clients:clientDetails.sections.addresses.shipping.sectionLabel'),
        children: (
            <CommonAddressDisplay
                key="shipping-address"
                sectionLabel={t('clients:clientDetails.sections.addresses.shipping.sectionLabel')}
                sectionEditTooltip={canUpdateClient ? t('clients:clientDetails.sections.addresses.shipping.sectionEditTooltip') : undefined}
                addressData={shippingAddress}
                sectionEditOnClick={() => dispatchOpenEditAddressModal({
                    modalType: ModalName.EDIT_CLIENT_ADDRESS_MODAL,
                    modalTitle: t('clients:modals.editClientShippingAddress.title'),
                    addressId: shippingAddress?.id,
                    initialValues: shippingAddress,
                    addressType: {
                        addressType: shippingAddress?.type,
                        entityId: clientId,
                        entityType: AllEntityTypes.CLIENT,
                    },
                })}
            />
        ),
    },
];


export default { createAddressTabs };
