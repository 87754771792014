import StandardTag from 'components/common/atoms/StandardTag';

import { TransactionType } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React, { JSX } from 'react';


import mapTransactionTypeToColorAndLabel from './TransactionTypeTag.helpers';


function TransactionTypeTag({ transactionType, t, showTooltip }: {
  transactionType: TransactionType,
  t: TFunction,
  showTooltip?: boolean
}): JSX.Element {
    const tagData = mapTransactionTypeToColorAndLabel(transactionType, t);

    if (tagData) {
        return <StandardTag color={tagData.color} text={tagData.label} showTooltip={showTooltip} />;
    }
    return <>-</>;
}


export default TransactionTypeTag;
