import { Dispatch } from 'models/meta/action';
import { goToPreviousOutgoingTransferFormStep } from 'redux/outgoing-transfer/actions';
import { outgoingTransferReducerName } from 'redux/outgoing-transfer/reducer';
import { RootState } from 'redux/rootReducer';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import BackButton from './BackButton.layout';
import { BackButtonDispatchProps, BackButtonStateProps } from './BackButton.types';


const mapStateToProps = (state: RootState): BackButtonStateProps => ({
    //
    currentStep: state[outgoingTransferReducerName].currentStep,
});


const mapDispatchToProps = (dispatch: Dispatch): BackButtonDispatchProps => ({
    //
    dispatchGoToPreviousOutgoingTransferFormStep: (currentStep) => dispatch(goToPreviousOutgoingTransferFormStep(currentStep)),
});

const decorate = compose<ComponentType>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation('beneficiaries'),
);

export default decorate(BackButton);

