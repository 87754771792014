import { fetchBusinessUserDetails } from 'redux/business-user/actions';
import { fetchCompanyDetails } from 'redux/company/actions';
import { fetchMemberDetails } from 'redux/member/actions';


function createResetKycKybRefreshActions(payload) {
    const { requestPayload, locationPathname } = payload || {};

    const handlers = [
        {
            predicate: () => requestPayload?.memberId > 0 && locationPathname,
            handler: () => [fetchMemberDetails({ memberId: requestPayload?.memberId }, locationPathname)],
        },
        {
            predicate: () => requestPayload?.companyId > 0 && locationPathname,
            handler: () => [fetchCompanyDetails({ companyId: requestPayload?.companyId }, locationPathname)],
        },
        {
            predicate: () => requestPayload?.businessUserId > 0 && locationPathname,
            handler: () => [
                fetchBusinessUserDetails({ businessUserId: requestPayload?.businessUserId }, locationPathname),
            ],
        },
        {
            predicate: () => true,
            handler: () => [],
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export default createResetKycKybRefreshActions;
