import StandardListTable from 'components/common/organisms/StandardListTable';
import { permissionsListQueryParamsName } from 'redux/permissions/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import createPermissionsListColumns from './createTableColumns';


function PermissionsList({
    isLoading,
    onUpdate,
    onRefresh,
    rolePermissionsListData = { },
    t,
}) {
    const filteredPermissions = useMemo(
        () => {
            if (rolePermissionsListData?.[permissionsListQueryParamsName]?.search?.length > 0) {
                return (rolePermissionsListData?.permissions || []).filter((permission) => searchCaseInsensitive(
                    permission?.name,
                    rolePermissionsListData?.[permissionsListQueryParamsName]?.search,
                ));
            }

            return rolePermissionsListData?.permissions || [];
        }, [rolePermissionsListData],
    );

    return (
        <StandardListTable
            rowKey="name" // xxx
            onUpdate={onUpdate}
            onListRefresh={onRefresh}
            items={filteredPermissions || []}
            totalItemsCount={filteredPermissions?.length}
            queryParams={rolePermissionsListData?.[permissionsListQueryParamsName] || {}}
            queryParamsMeta={rolePermissionsListData?.queryParamsMeta}
            isTableLoading={isLoading}
            isLoadingPlaceholder={isLoading}
            columnsConfig={createPermissionsListColumns({
                t,
                searchQuery: rolePermissionsListData?.[permissionsListQueryParamsName]?.search,
            })}
        />
    );
}

PermissionsList.propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
};


export default PermissionsList;
