import TransactionScopeTag from 'components/common/atoms/tags/TransactionScopeTag';
import TransactionStatusTag from 'components/common/atoms/tags/TransactionStatusTag';
import TransactionTypeTag from 'components/common/atoms/tags/TransactionTypeTag';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import TransactionPartyRenderer from 'components/common/molecules/value-renderers/TransactionPartyRenderer';
import { createAccountDetailsPath } from 'config/routes.helpers';

import { TransactionDetails, TransactionScope } from '@manigo/manigo-domain-typings';
import { Space } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';


export const createTransactionStatusFields = (transactionDetails: TransactionDetails, t: TFunction) => {
    if (transactionDetails?.transactionUuid) {
        return [
            {
                value: <StandardValue value={transactionDetails.createdAt} valueType={ValueTypes.DATE} />,
                label: t('transactionFields.createdAt.text'),
            },
            {
                value: <TransactionStatusTag transactionStatus={transactionDetails.status} />,
                label: t('transactionFields.status.text'),
            },

            ...(transactionDetails.updatedAt && transactionDetails.createdAt !== transactionDetails.updatedAt
                ? [{
                    value: <StandardValue value={transactionDetails.updatedAt} valueType={ValueTypes.DATE} />,
                    label: t('transactionFields.updatedAt.text'),
                }] : []),

            {
                value: <TransactionTypeTag transactionType={transactionDetails.transactionType} />,
                label: t('transactionFields.type.text'),
            },
            {
                value: <TransactionScopeTag transactionRecord={transactionDetails} />,
                label: t('transactionFields.scope.text'),
            },
            ...(transactionDetails?.transactionFlags?.length > 0 ? [{
                value: <StandardValue value={transactionDetails?.transactionFlags.join(', ')} />,
                label: t('transactions:transactionFields.transactionFlags.text'),
            }] : []),


        ];
    }

    return [];
};

export const createTransactionAmountFields = (transactionDetails: TransactionDetails, t: TFunction) => {
    if (transactionDetails?.transactionUuid) {
        return [
            {
                value: (
                    <Space>
                        <StandardValue value={transactionDetails.originalAmount} valueType={ValueTypes.AMOUNT} />
                        {` ${transactionDetails?.originalCurrency?.iso}`}
                    </Space>
                ),
                label: t('transactionFields.amount.text'),
            },

            {
                value: (
                    <Space>
                        <StandardValue value={transactionDetails.processedAmount} valueType={ValueTypes.AMOUNT} />
                        {` ${transactionDetails?.processedCurrency?.iso}`}

                    </Space>
                ),
                label: t('transactionFields.processedAmount.text'),
            },

            ...(transactionDetails?.exchangeTransactionData?.exchangeRate ? [
                {
                    value: (
                        <Space>
                            {`1 ${transactionDetails?.exchangeTransactionData?.sendCurrency?.iso} =  `}
                            <StandardValue value={transactionDetails?.exchangeTransactionData?.exchangeRate} valueType={ValueTypes.AMOUNT} decimalPrecision={4} />
                            {`  ${transactionDetails?.exchangeTransactionData?.receiveCurrency?.iso}`}
                        </Space>
                    ),
                    label: t('transactionFields.rate.text'),
                }] : []),

            ...(transactionDetails?.exchangeTransactionData?.receiveAmount
          && transactionDetails.transactionScope === TransactionScope.EXF
                ? [{
                    value: (
                        <Space>
                            <StandardValue value={transactionDetails.exchangeTransactionData.receiveAmount} valueType={ValueTypes.AMOUNT} />
                            {` ${transactionDetails?.exchangeTransactionData?.receiveCurrency?.iso}`}
                        </Space>
                    ),
                    label: t('transactionFields.receivedAmount.text'),
                }] : []),

            ...(transactionDetails?.exchangeTransactionData?.sendAmount
      && transactionDetails.transactionScope === TransactionScope.EXT
                ? [{
                    value: (
                        <Space>
                            <StandardValue value={transactionDetails.exchangeTransactionData.sendAmount} valueType={ValueTypes.AMOUNT} />
                            {` ${transactionDetails?.exchangeTransactionData?.sendCurrency?.iso}`}
                        </Space>
                    ),
                    label: t('transactionFields.sendAmount.text'),
                }] : []),
        ];
    }


    return [];
};

export const createTransactionSenderFields = (transactionDetails: TransactionDetails, t: TFunction) => {
    if (transactionDetails?.sender?.type) {
        return [
            {
                label: t('Type'),
                value: <StandardValue value={transactionDetails?.sender?.type} />,
            },

            {
                label: t('Name/Info'),
                value: <TransactionPartyRenderer transaction={transactionDetails} t={t} />,
            },

            ...(transactionDetails?.sender.accountId > 0 ? [
                {
                    label: t('Account ID'),
                    value: (<StandardLink
                        path={createAccountDetailsPath(transactionDetails.sender.accountId)}
                        title={transactionDetails.sender.accountId}
                        showTooltip
                    />),
                },
            ] : []),

            // @ts-expect-error bankCode should be added to TransactionParty?
            ...(transactionDetails?.sender.bankCode ? [
                {
                    label: t('transactionFields.bankCode.text'),
                    // @ts-expect-error bankCode should be added to TransactionParty?
                    value: <StandardValue value={transactionDetails?.sender?.bankCode} />,
                },
            ] : []),
            ...(transactionDetails?.sender.accountNumber ? [
                {
                    label: t('transactionFields.accountNumber.text'),
                    value: <StandardValue value={transactionDetails?.sender?.accountNumber} />,
                },
            ] : []),

        ];
    }

    return [];
};

export const createTransactionReceiverFields = (transactionDetails: TransactionDetails, t: TFunction) => {
    if (transactionDetails?.receiver?.type) {
        return [
            {
                label: t('Type'),
                value: <StandardValue value={transactionDetails?.receiver?.type} />,
            },

            {
                label: t('Name/Info'),
                value: <TransactionPartyRenderer transaction={transactionDetails} t={t} side="receiver" />,
            },

            ...(transactionDetails?.receiver.accountId > 0 ? [
                {
                    label: t('Account ID'),
                    value: (<StandardLink
                        path={createAccountDetailsPath(transactionDetails.receiver.accountId)}
                        title={transactionDetails.receiver.accountId}
                        showTooltip
                    />),
                },
            ] : []),


        ];
    }

    return [];
};

export default {
    createTransactionStatusFields,
    createTransactionAmountFields,
    createTransactionSenderFields,
    createTransactionReceiverFields,
};
