import StandardListTable from 'components/common/organisms/StandardListTable';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { IndustriesListProps } from 'constants/IndustriesModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultIndustriesListQueryParams } from 'redux/industries/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createIndustriesListTableColumns from './createTableColumns';


function IndustriesList({
    enhancedCurrentLocation,
    listData,

    dispatchSetQueryParams,
    dispatchFetchIndustriesList,
    dispatchClearIndustriesList,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { };


    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        useGlobalLocation: true,
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultIndustriesListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchIndustriesList,
        dispatchClearItemsList: dispatchClearIndustriesList,
        dispatchSetQueryParams,
    });

    // 4a. list specific stuff - local search, local sorting is in table configuration, see 'createIndustriesListTableColumns'
    const filteredIndustries = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((industry) => searchCaseInsensitive(industry?.name, listData?.queryParams?.search)
          || searchCaseInsensitive(`${industry?.risk_score}`, listData?.queryParams?.search)
          || searchCaseInsensitive(`${industry?.id}`, listData?.queryParams?.search));
            }
            return listData?.items || [];
        }, [listData],
    );

    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchIndustriesList(listData?.queryParams, enhancedCurrentLocation.pathname)}
            items={filteredIndustries}
            totalItemsCount={filteredIndustries.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createIndustriesListTableColumns({
                queryParams: listData?.queryParams,
                t,
            })}
        />
    );
}

IndustriesList.propTypes = {
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: IndustriesListProps,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    dispatchFetchIndustriesList: PropTypes.func.isRequired,
    dispatchClearIndustriesList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default IndustriesList;
