import PropTypes from 'prop-types';


const MarketingPreferencesListItemProps = {
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    member_id: PropTypes.number.isRequired,

    marketing_email: PropTypes.bool,
    marketing_partners_email: PropTypes.bool,
    marketing_partners_phone: PropTypes.bool,
    marketing_partners_post: PropTypes.bool,
    marketing_partners_push: PropTypes.bool,
    marketing_phone: PropTypes.bool,
    marketing_post: PropTypes.bool,
    marketing_push: PropTypes.bool,

    start_date: PropTypes.string,
    end_date: PropTypes.string,

    created_at: PropTypes.string,
    updated_at: PropTypes.string,
};

export default MarketingPreferencesListItemProps;
