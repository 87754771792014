/* eslint-disable class-methods-use-this */
import { mapTransactionScopeFilterValueToQuery } from 'components/common/templates/lists/TransactionsList/TransactionsList.helpers';
import { SortDirections } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';
import { QueryParams } from 'models/app/common';
import { FetchStandardListWithTotalCountResponsePayload } from 'models/app/standardList';
import { cleanNilValues } from 'utils/dataMappers';
import { camelToSnakeCase } from 'utils/string-tools';

import { TransactionListItem, Uuid, TransactionStatusHistoryListItem } from '@manigo/manigo-domain-typings';

import {
    getTransactionUuidDetails,
    getAllTransactionsAPI,
    createNewPaymentAPI,
    getTransactionsFiltersAPI,
    refundTopUpUuid,
    unblockTransactionAPI,
    withdrawBalanceAPI,
    auditPendingTransactionUuid, getTransactionUuidStatusHistory,
    balanceAdjustmentAPI,
} from './index';


class TransactionsService {
    public getTransactionDetails(transactionId: Uuid): Promise<ApiResponse | ApiError> {
        return getTransactionUuidDetails(transactionId);
    }

    public createNewPayment(requestPayload): Promise<ApiResponse | ApiError> {
        return createNewPaymentAPI(requestPayload);
    }


    public getAllTransactions(
        rawQueryParams: QueryParams,
        transactionsFilters,
    ): Promise<ApiResponse<FetchStandardListWithTotalCountResponsePayload<TransactionListItem>> | ApiError> {
        const {
            limit = 10,
            offset = 0,
            search,
            status,
            clientId,
            cardId,
            accountId,
            beneficiaryUuid,
            businessUserId,
            companyId,
            memberId,
            originalCurrency,
            transactionScope,
            amlReview,
            sortBy,
            sortOrder,
            showCardVerification,
            showOutgoingRecall,
            includeInactivePartners,
        } = rawQueryParams || {};

        const transactionScopeQueryParams = mapTransactionScopeFilterValueToQuery(transactionScope?.[0], transactionsFilters);

        const clearedQueryParams = cleanNilValues({
            limit,
            offset,
            sortBy: sortBy ? camelToSnakeCase(sortBy) : undefined,
            sortOrder: `${sortOrder === SortDirections.DESCEND ? 'desc' : 'asc'}`,
            search: search?.length && search?.length > 0 ? search : null,
            clientId,
            accountId,
            beneficiaryId: beneficiaryUuid, // XXX bad API naming
            cardId,
            businessUserId,
            companyId,
            memberId,
            status: status?.[0],
            amlReview,
            currency: originalCurrency?.[0], // XXX API should accept `originalCurrency`, `currency` is from legacy endpoint version
            transactionType: transactionScopeQueryParams.transactionType,
            transactionScope: transactionScopeQueryParams.transactionScope,
            showCardVerification,
            showOutgoingRecall: true,
            ...(includeInactivePartners ? { includeInactivePartners } : {}),
        });

        const requestConfig = { params: { ...clearedQueryParams }, convertSnakeCaseKeysToCamelCase: true };

        return getAllTransactionsAPI(requestConfig);
    }


    public getTransactionsFilters(): Promise<ApiResponse | ApiError> {
        const requestConfig = { params: { showCardVerification: true }, convertSnakeCaseKeysToCamelCase: true };

        return getTransactionsFiltersAPI(requestConfig);
    }


    public unblockTransaction(requestPayload): Promise<ApiResponse | ApiError> {
        return unblockTransactionAPI(requestPayload);
    }

    public withdrawBalance(requestPayload): Promise<ApiResponse | ApiError> {
        return withdrawBalanceAPI(requestPayload);
    }

    public balanceAdjustment(requestPayload): Promise<ApiResponse | ApiError> {
        return balanceAdjustmentAPI(requestPayload);
    }

    public auditPendingTransaction(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { transactionUuid, comment, action } = rawRequestPayload;

        return auditPendingTransactionUuid(transactionUuid, { comment, action });
    }

    public refundTopUp(transactionUuid: Uuid): Promise<ApiResponse | ApiError> {
        return refundTopUpUuid(transactionUuid);
    }

    public getTransactionStatusHistory(transactionUuid: Uuid): Promise<ApiResponse<FetchStandardListWithTotalCountResponsePayload<TransactionStatusHistoryListItem>> | ApiError> {
        return getTransactionUuidStatusHistory(transactionUuid);
    }
}

export default TransactionsService;
