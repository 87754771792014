import { standardPageSize } from 'config/config';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    clearCompanyStatusHistoryList,
    clearResetForm,
    fetchCompanyStatusHistoryList,
    fetchCompanyStatusHistoryListFailure,
    fetchCompanyStatusHistoryListSuccess,
    updateCompanyStatus,
    updateCompanyStatusFailure,
    updateCompanyStatusSuccess,
} from './actions';


export const companyStatusReducerName = 'companyStatuses';
export const companyStatusHistoryListsPerLocationStoreKeyName = 'companyStatusHistoryListsPerLocationStoreKey';

export const defaultCompanyStatusHistoryListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.DESCEND,
    ...withTotalCount,
};

const initialState = {
    [companyStatusHistoryListsPerLocationStoreKeyName]: {},
    isLoadingStatusUpdate: false,
};

const handlers = {
    [fetchCompanyStatusHistoryList]: (state, action) => {
        state[companyStatusHistoryListsPerLocationStoreKeyName] = updateStandardLists(state[companyStatusHistoryListsPerLocationStoreKeyName], action);
    },
    [fetchCompanyStatusHistoryListSuccess]: (state, action) => {
        state[companyStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[companyStatusHistoryListsPerLocationStoreKeyName], action);
    },
    [fetchCompanyStatusHistoryListFailure]: (state, action) => {
        state[companyStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[companyStatusHistoryListsPerLocationStoreKeyName], action);
    },
    [clearCompanyStatusHistoryList]: (state, action) => {
        state[companyStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[companyStatusHistoryListsPerLocationStoreKeyName],
            action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === companyStatusReducerName
        && action.payload?.fieldName === companyStatusHistoryListsPerLocationStoreKeyName
        ) {
            state[companyStatusHistoryListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[companyStatusHistoryListsPerLocationStoreKeyName] }, action);
        }
    },

    [updateCompanyStatus]: (state) => {
        state.isLoadingStatusUpdate = true;
    },
    [updateCompanyStatusSuccess]: (state) => {
        state.isLoadingStatusUpdate = false;
        state.shouldResetForm = true;
    },
    [updateCompanyStatusFailure]: (state) => {
        state.isLoadingStatusUpdate = false;
    },
    [clearResetForm]: (state) => {
        state.shouldResetForm = false;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
