import { standardDateFormat } from 'config/config';
import { getObjectKeyByValue } from 'utils/object-tools';

import PropTypes from 'prop-types';


export const UserStatuses = Object.freeze({
    UNCONFIRMED: 'Unconfirmed',
    ACTIVE: 'Active',
    BANNED: 'Banned',
});

export const UserTypes = Object.freeze({
    BOP_USER: 'BOP_USER',
    BWP_USER: 'BWP_USER',
    MEMBER: 'MEMBER',
});


export const UserPreferencesTimeFormat = Object.freeze({
    HOURS_24: '24',
    HOURS_12: '12',
});

export const UserPreferencesDateFormat = Object.freeze({
    DAY_MONTH_YEAR_SLASH: standardDateFormat,
    DAY_MONTH_YEAR_DOT: 'DD.MM.YYYY',
    DAY_MONTH_YEAR_DASH: 'DD-MM-YYYY',
    YEAR_MONTH_DAY_SLASH: 'YYYY/MM/DD',
    YEAR_MONTH_DAY_DOT: 'YYYY.MM.DD',
    YEAR_MONTH_DAY_DASH: 'YYYY-MM-DD',
    MONTH_DAY_YEAR_SLASH: 'MM/DD/YYYY',
    MONTH_DAY_YEAR_DOT: 'MM.DD.YYYY',
    MONTH_DAY_YEAR_DASH: 'MM-DD-YYYY',
});

export const UserPreferencesDecimalSeparators = Object.freeze({
    COMMA: ',',
    DOT: '.',
    DECIMAL_KEY: '⎖',
});

export const UserPreferencesThousandsSeparators = Object.freeze({
    COMMA: ',', // Comma, the thousands separator used in most English-speaking countries.
    DOT: '.', // Period (or full stop), the thousands separator used in many non-English speaking countries.
    SPACE: ' ', // internationally recommended
    ARABIC: '٬', // internationally recommended
});

export const UserPreferencesNumbersFormat = Object.freeze({
    SPACE__COMMA: `999${UserPreferencesThousandsSeparators.SPACE}999${UserPreferencesDecimalSeparators.COMMA}99`,
    SPACE__DOT: `999${UserPreferencesThousandsSeparators.SPACE}999${UserPreferencesDecimalSeparators.DOT}99`,
    COMMA__DOT: `999${UserPreferencesThousandsSeparators.COMMA}999${UserPreferencesDecimalSeparators.DOT}99`,
    DOT__COMMA: `999${UserPreferencesThousandsSeparators.DOT}999${UserPreferencesDecimalSeparators.COMMA}99`,
    ARABIC__DOT: `999${UserPreferencesThousandsSeparators.ARABIC}999${UserPreferencesDecimalSeparators.DOT}99`,
    SPACE__DECIMAL_KEY: `999${UserPreferencesThousandsSeparators.SPACE}999${UserPreferencesDecimalSeparators.DECIMAL_KEY}99`,
});


export const mapSeparatorsToNumbersFormat = ({ decimal_separator, thousands_separator }) => {
    const decimalSeparatorKey = getObjectKeyByValue(UserPreferencesDecimalSeparators, decimal_separator);
    const thousandsSeparatorKey = getObjectKeyByValue(UserPreferencesThousandsSeparators, thousands_separator);

    return `${thousandsSeparatorKey}__${decimalSeparatorKey}` || UserPreferencesNumbersFormat.SPACE__DOT;
};


export const UserRoleProps = PropTypes.shape({
    display_name: PropTypes.string,
    description: PropTypes.string,
});


export const UserPreferencesProps = PropTypes.shape({
    language: PropTypes.string,
    date_format: PropTypes.oneOf(Object.values(UserPreferencesDateFormat)),
    time_format: PropTypes.oneOf(Object.values(UserPreferencesTimeFormat)),
    decimal_separator: PropTypes.oneOf(Object.values(UserPreferencesDecimalSeparators)),
    thousands_separator: PropTypes.oneOf(Object.values(UserPreferencesThousandsSeparators)),
});
export const UserListItemProps = PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    role_id: PropTypes.number.isRequired,
    birthday: PropTypes.string,
    status: PropTypes.oneOf([UserStatuses.ACTIVE, UserStatuses.BANNED, UserStatuses.UNCONFIRMED]).isRequired,
    country_id: PropTypes.number,
    first_name: PropTypes.string,
    last_login: PropTypes.string,
    last_name: PropTypes.string,
    role: UserRoleProps,
    username: PropTypes.string,
});


export default {
    UserStatuses,
    UserRoleProps,
    UserListItemProps,
    UserPreferencesProps,
};
