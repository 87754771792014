import PropTypes from 'prop-types';


export const GroupListItemProps = PropTypes.shape({
    is_default: PropTypes.bool,
    ext_group_id: PropTypes.string, // UUID
    ext_client_id: PropTypes.string, // UUID
    consumer_count: PropTypes.number,
    fee_count: PropTypes.number,
    promotion_count: PropTypes.number,
    customer_count: PropTypes.number,
    client_id: PropTypes.number,
    institution_id: PropTypes.number,
    id: PropTypes.number,
    local_currency: PropTypes.string, // e.g. GBP
});

export default { GroupListItemProps };
