import { standardPageSize } from 'config/config';
import {
    companyRootRoutePath,
    dueDiligenceKybRouteSegmentPath,
    dueDiligenceKycRouteSegmentPath, businessUserRootRoutePath,
    memberRootRoutePath,
} from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchCurrentKycKybFlow, fetchCurrentKycKybFlowSuccess, fetchCurrentKycKybFlowFailure,
    setKycKybFlow, setKycKybFlowSuccess, setKycKybFlowFailure,
    triggerKycKybFlow, triggerKycKybFlowSuccess, triggerKycKybFlowFailure, setIsInEditLevelMode,
    fetchKycKybLogsList, fetchKycKybLogsListSuccess, fetchKycKybLogsListFailure, clearKycKybLogsList,
} from './actions';


export const kycKybReducerName = 'kycKyb';
export const kycKybAuditsListPerLocationStoreKeyName = 'kycKybAuditsListPerLocation';

export const defaultKycKybAuditsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,

    memberId: undefined,
    companyId: undefined,
    businessUserId: undefined,
};

const initialKycKybAuditsListState = {
    [kycKybAuditsListPerLocationStoreKeyName]: { }, // XXX 'pathname': {...standard }
    // ?
};

const initialState = {
    currentFlow: undefined,
    isLoadingCurrentKycKybFlow: false,

    isLoadingSetKycKyb: false,
    isLoadingTriggerKycKyb: false,
    isInEditLevelMode: false,

    ...initialKycKybAuditsListState,
};

const handlers = {
    [fetchCurrentKycKybFlow]: (state) => {
        state.isLoadingCurrentKycKybFlow = true;
    },
    [fetchCurrentKycKybFlowSuccess]: (state, action) => {
        state.isLoadingCurrentKycKybFlow = false;
        state.currentFlow = action.payload;
    },
    [fetchCurrentKycKybFlowFailure]: (state) => {
        state.isLoadingCurrentKycKybFlow = false;
    },

    [setKycKybFlow]: (state) => {
        state.isLoadingSetKycKyb = true;
    },
    [setKycKybFlowSuccess]: (state) => {
        state.isLoadingSetKycKyb = false;
    },
    [setKycKybFlowFailure]: (state) => {
        state.isLoadingSetKycKyb = false;
    },

    [triggerKycKybFlow]: (state) => {
        state.isLoadingTriggerKycKyb = true;
    },
    [triggerKycKybFlowSuccess]: (state) => {
        state.isLoadingTriggerKycKyb = false;
    },
    [triggerKycKybFlowFailure]: (state) => {
        state.isLoadingTriggerKycKyb = false;
    },
    [setIsInEditLevelMode]: (state, action) => {
        state.isInEditLevelMode = action.payload;
    },


    [fetchKycKybLogsList]: (state, action) => {
        state[kycKybAuditsListPerLocationStoreKeyName] = updateStandardLists(state[kycKybAuditsListPerLocationStoreKeyName], action);
    },
    [fetchKycKybLogsListSuccess]: (state, action) => {
        state[kycKybAuditsListPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[kycKybAuditsListPerLocationStoreKeyName], action);
    },
    [fetchKycKybLogsListFailure]: (state, action) => {
        state[kycKybAuditsListPerLocationStoreKeyName] = updateStandardListsOnFailure(state[kycKybAuditsListPerLocationStoreKeyName], action);
    },
    [clearKycKybLogsList]: (state, action) => {
        state[kycKybAuditsListPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[kycKybAuditsListPerLocationStoreKeyName], action.payload.locationPathname);
    },

    [preformNavigationSuccess]: (state, action) => {
        state[kycKybAuditsListPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[kycKybAuditsListPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [dueDiligenceKycRouteSegmentPath, dueDiligenceKybRouteSegmentPath],
                observedRootPaths: [
                    memberRootRoutePath, companyRootRoutePath, businessUserRootRoutePath,
                ],
            },
        );
    },


    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === kycKybReducerName
      && action.payload?.fieldName === kycKybAuditsListPerLocationStoreKeyName
        ) {
            state[kycKybAuditsListPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[kycKybAuditsListPerLocationStoreKeyName] }, action);
        }
    },
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
