import { Select } from 'antd';
import React from 'react';

import Styled from './BpsProductsSelect.styled';


export const createBpsProductsOptions = (
    bpsProducts,
) => (
    <>
        {(bpsProducts || []).map((bpsProduct) => (
            <Select.Option
                key={bpsProduct.product_id}
                value={bpsProduct.product_id}
                label={`${bpsProduct.tpp_code}`}
            >
                <Styled.BpsOptionWrapper>
                    <Styled.OptionSubValueWrapper>
                        <Styled.BpsOptionLabel>Tpp code:</Styled.BpsOptionLabel>
                        <Styled.BpsOptionValue>{bpsProduct.tpp_code}</Styled.BpsOptionValue>
                    </Styled.OptionSubValueWrapper>

                    <Styled.OptionSubValueWrapper>
                        <Styled.BpsOptionLabel>Supported currency:</Styled.BpsOptionLabel>
                        <Styled.BpsOptionValue>{bpsProduct.supported_currency}</Styled.BpsOptionValue>
                    </Styled.OptionSubValueWrapper>

                    <Styled.OptionSubValueWrapper>
                        <Styled.BpsOptionLabel>Payment type:</Styled.BpsOptionLabel>
                        <Styled.BpsOptionValue>{bpsProduct.payment_type}</Styled.BpsOptionValue>
                    </Styled.OptionSubValueWrapper>

                    <Styled.OptionSubValueWrapper>
                        <Styled.BpsOptionLabel>Product ID:</Styled.BpsOptionLabel>
                        <Styled.BpsOptionValue>{bpsProduct.product_id}</Styled.BpsOptionValue>
                    </Styled.OptionSubValueWrapper>
                </Styled.BpsOptionWrapper>
            </Select.Option>
        ))}
    </>
);

export default { createBpsProductsOptions };
