import InitialAccountsProducts from 'components/pages/authorised/AddClient/AddClientForm/steps/InitialAccountsProducts';
import { NewAccountTypes } from 'constants/AccountModel';
import {
    AddClientAccountsProductsAvailableSubStepsDefinitions,
    AddClientStepsDefinitions,
}
    from 'constants/AddClientModel';
import { MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';

import { PoundOutlined } from '@ant-design/icons';
import React from 'react';


export function createClientAccountProductsSubStepsConfig({ t }) {
    return {
        stepName: AddClientStepsDefinitions.ACCOUNT_PRODUCTS,
        title: t('addClient.steps.accountsProducts'),
        icon: <PoundOutlined />,
        subStepsConfiguration: [
            {
                parentStepName: AddClientAccountsProductsAvailableSubStepsDefinitions.BASE_ACCOUNTS,
                subStepName: 'BASE_ACCOUNTS',
                title: t('Base accounts'),
                sectionsLayout: MultiStepFormStepSectionTypes.GRID,
                children: (
                    <InitialAccountsProducts
                        sectionAccountType={NewAccountTypes.BASE}
                    />
                ),
            },
            {
                parentStepName: AddClientAccountsProductsAvailableSubStepsDefinitions.COMPANY_ACCOUNTS,
                subStepName: 'COMPANY_ACCOUNTS',
                title: t('Company accounts'),
                sectionsLayout: MultiStepFormStepSectionTypes.GRID,
                children: (
                    <InitialAccountsProducts
                        sectionAccountType={NewAccountTypes.BUSINESS}
                    />
                ),
            },
            {
                parentStepName: AddClientAccountsProductsAvailableSubStepsDefinitions.PERSONAL_ACCOUNTS,
                subStepName: 'PERSONAL_ACCOUNTS',
                sectionsLayout: MultiStepFormStepSectionTypes.GRID,
                title: t('Personal accounts'),
                children: (
                    <InitialAccountsProducts
                        sectionAccountType={NewAccountTypes.PERSONAL}
                    />
                ),
            },
        ],
    };
}


export default { createClientAccountProductsSubStepsConfig };
