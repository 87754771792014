import CommonAddressFrom from 'components/common/organisms/CommonAddressForm';
import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';

import { Form } from 'antd';
import React, { useEffect } from 'react';

import { EditMemberAddressModalProps } from './EditMemberAddressModal.types';


function EditMemberAddressModal({
    initialValues,
    enhancedCurrentLocation,
    dispatchSetModalProps,
    dispatchUpdateAddress,
    addressType,
    addressId,
}: EditMemberAddressModalProps) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);


    const onFinish = (values) => {
        const baseURLSegmentsCount = 2;
        const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);

        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchUpdateAddress(addressId, values, addressType, baseUrlWithoutTabsSlugs);
    };


    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
            initialValues={{
                addressLine1: initialValues.addressLine1,
                addressLine2: initialValues.addressLine2,
                zipCode: initialValues.zipCode,
                city: initialValues.city,
                country: initialValues.country,
            }}
        >
            <CommonAddressFrom form={form} isCamelCase />
        </Form>
    );
}


export default EditMemberAddressModal;

