import {
    AccountSelectionStepFormData,
    BeneficiarySelectionStepFormData,
    OutgoingTransferFormData,
    OutgoingTransferStep,
    TransferDetailsStepFormData,
} from 'models/app/outgoingTransfer';
import { createOutgoingTransfer, setOutgoingTransferFormStep } from 'redux/outgoing-transfer/actions';
import { cleanNilValues } from 'utils/dataMappers';

import { EMPTY, of } from 'rxjs';


export const mapCurrentStepToNumber = (currentStep: OutgoingTransferStep | null) => {
    const handlers = [
        {
            predicate: () => currentStep === OutgoingTransferStep.ACCOUNT_SELECTION,
            handler: () => 0,
        },
        {
            predicate: () => currentStep === OutgoingTransferStep.BENEFICIARY_SELECTION,
            handler: () => 1,
        },
        {
            predicate: () => currentStep === OutgoingTransferStep.TRANSFER_DETAILS,
            handler: () => 2,
        },
        {
            predicate: () => currentStep === OutgoingTransferStep.REVIEW,
            handler: () => 3,
        },
        {
            predicate: () => true,
            handler: () => -1,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export const createOnSubmitFormStepActions = ({
    currentStep,
    newData,
}: {
  currentStep: OutgoingTransferStep,
  newData: AccountSelectionStepFormData | BeneficiarySelectionStepFormData | TransferDetailsStepFormData | OutgoingTransferFormData,
}) => {
    const handlers = [
        {
            predicate: () => currentStep === OutgoingTransferStep.ACCOUNT_SELECTION,
            handler: () => of(setOutgoingTransferFormStep(OutgoingTransferStep.BENEFICIARY_SELECTION, newData)),
        },
        {
            predicate: () => currentStep === OutgoingTransferStep.BENEFICIARY_SELECTION,
            handler: () => of(setOutgoingTransferFormStep(OutgoingTransferStep.TRANSFER_DETAILS, newData)),
        },
        {
            predicate: () => currentStep === OutgoingTransferStep.TRANSFER_DETAILS,
            handler: () => of(setOutgoingTransferFormStep(OutgoingTransferStep.REVIEW, newData)),
        },
        {
            predicate: () => currentStep === OutgoingTransferStep.REVIEW,
            handler: () => {
                const fixedPayload = cleanNilValues({ ...newData }, true);
                return of(createOutgoingTransfer(fixedPayload as OutgoingTransferFormData));
            },
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createOnGoToPreviousOutgoingTransferFormStepActions = (currentStep: OutgoingTransferStep) => {
    const handlers = [
        {
            predicate: () => currentStep === OutgoingTransferStep.REVIEW,
            handler: () => of(setOutgoingTransferFormStep(OutgoingTransferStep.TRANSFER_DETAILS)),
        },
        {
            predicate: () => currentStep === OutgoingTransferStep.TRANSFER_DETAILS,
            handler: () => of(setOutgoingTransferFormStep(OutgoingTransferStep.BENEFICIARY_SELECTION)),
        },
        {
            predicate: () => currentStep === OutgoingTransferStep.BENEFICIARY_SELECTION,
            handler: () => of(setOutgoingTransferFormStep(OutgoingTransferStep.ACCOUNT_SELECTION)),
        },
        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export default {
    mapCurrentStepToNumber,
    createOnSubmitFormStepActions,
    createOnGoToPreviousOutgoingTransferFormStepActions,
};
