import Limits from 'components/pages/authorised/CardDetails/Tabs/Limits/Limits.layout';
import withAccessControl from 'hocs/withAccessControl';
import { updateCardLimits, setChangeCardLimitFormHasLimitValue, toggleChangeCardLimitFormVisibility } from 'redux/card/actions';
import { cardDetailsPerLocationStoreKeyName, cardReducerName } from 'redux/card/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


const mapStateToProps = (state) => ({
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: cardReducerName,
        fieldName: cardDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
    isChangeLimitFormVisible: state[cardReducerName].isChangeLimitFormVisible,
    isLoadingUpdateLimits: state[cardReducerName].isLoadingUpdateLimits,
    hasLimitFormValue: state[cardReducerName].hasLimitFormValue,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateCardLimits: (cardId, requestPayload) => dispatch(updateCardLimits(cardId, requestPayload)),
    dispatchToggleChangeCardLimitFormVisibility: (value) => dispatch(toggleChangeCardLimitFormVisibility(value)),
    dispatchSetChangeCardLimitFormHasLimitValue: (value) => dispatch(setChangeCardLimitFormHasLimitValue(value)),
});


const decorate = compose(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(Limits);
