import { limitsRouteSegmentPath } from 'config/routes';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import { PREFORM_NAVIGATION_SUCCESS } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';

import {
    CLEAR_LIMITS_LIST,
    FETCH_LIMITS,
    FETCH_LIMITS_SUCCESS,
    FETCH_LIMITS_FAILURE,
} from './actions.types';


export const limitsReducerName = 'limits';
export const limitsListsPerLocationStoreKeyName = 'limitsListsPerLocationStoreKeyName';

export const defaultLimitsListQueryParams = {
    ruleScope: undefined,
    ruleType: undefined,
    ruleTransactionType: undefined,
};

const initialState = { [limitsListsPerLocationStoreKeyName]: {} };

export default createReducer(initialState, (builder) => {
    builder.addCase(FETCH_LIMITS, (state, action) => {
        state[limitsListsPerLocationStoreKeyName] = updateStandardLists(state[limitsListsPerLocationStoreKeyName], action);
    })
        .addCase(FETCH_LIMITS_SUCCESS, (state, action) => {
            state[limitsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[limitsListsPerLocationStoreKeyName], action);
        })
        .addCase(FETCH_LIMITS_FAILURE, (state, action) => {
            state[limitsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[limitsListsPerLocationStoreKeyName], action);
        })
        .addCase(CLEAR_LIMITS_LIST, (state, action:any) => {
            state[limitsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[limitsListsPerLocationStoreKeyName],
                action.payload.locationPathname);
        })
        .addCase(SET_QUERY_PARAMS, (state, action:any) => {
            if (action.payload?.reducerName === limitsReducerName
              && action.payload?.fieldName === limitsListsPerLocationStoreKeyName
            ) {
                state[limitsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[limitsListsPerLocationStoreKeyName] }, action);
            }
        })
        .addCase(PREFORM_NAVIGATION_SUCCESS, (state, action) => {
            state[limitsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
                state[limitsListsPerLocationStoreKeyName],
                {
                    action,
                    listRouteSegments: [limitsRouteSegmentPath],
                    observedRootPaths: [],
                },
            );
        })
        .addCase(CLEAR_CURRENT_USER, () => initialState);
});
