import svgIcons from 'assets/images/icons/svgIcons';
import NewStar from 'components/common/atoms/NewStar';
import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import { TIME_TO_LOGOUT_COUNTDOWN } from 'config/config';

import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import Styled from './FAQ.styled';


const { Panel } = Collapse;

function FAQ({
    t,
    showHeading = true,
    showDecorationImage = false,
}) {
    const questions = useMemo(
        () => Object.keys(t('faq:questions', { returnObjects: true }) || {}) || [],
        [t],
    );

    const values = { idleTimeToCountDown: TIME_TO_LOGOUT_COUNTDOWN };


    const renderQuestion = (questionName, index) => {
        const questionData = Object.keys(t(`questions.${questionName}`, { returnObjects: true }) || {});

        return (
            <Panel
                header={(
                    <Styled.HeaderWrapper>
                        <StandardTranslationWithMarkup
                            translationKey={`questions.${questionName}.q`}
                            namespace="faq"
                            values={values}
                            t={t}
                        />

                        {questionData.includes('isNew') && (
                            <Styled.NewIndicatorWrapper>
                                <NewStar />
                            </Styled.NewIndicatorWrapper>
                        )}
                    </Styled.HeaderWrapper>
                )}
                key={index}
            >
                <StandardTranslationWithMarkup
                    translationKey={`questions.${questionName}.a`}
                    namespace="faq"
                    values={values}
                    t={t}
                />
            </Panel>

        );
    };


    return (
        <Styled.PageWrapper>
            {showHeading && (<h2>{t('screenTitle')}</h2>)}

            <Styled.AccordionsWrapper>
                <Collapse>
                    {questions.map((questionName, index) => renderQuestion(questionName, index))}
                </Collapse>
            </Styled.AccordionsWrapper>

            {showDecorationImage && (
                <Styled.FaqIconDecoration
                    src={svgIcons.faqIcon}
                    alt="Decoration illustration picturing Q %A conversation bubbles"
                />
            )}
        </Styled.PageWrapper>
    );
}

FAQ.propTypes = {
    t: PropTypes.func.isRequired,
    showHeading: PropTypes.bool,
    showDecorationImage: PropTypes.bool,
};


export default FAQ;
