import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_GROUP_MEMBERS, CLEAR_MEMBERS_NOT_IN_GROUP,
    FETCH_GROUP_MEMBERS,
    FETCH_GROUP_MEMBERS_FAILURE,
    FETCH_GROUP_MEMBERS_SUCCESS,
    FETCH_MEMBERS_NOT_IN_GROUP, FETCH_MEMBERS_NOT_IN_GROUP_FAILURE,
    FETCH_MEMBERS_NOT_IN_GROUP_SUCCESS,
} from './actions.types';


export const fetchGroupMembers = createAction(FETCH_GROUP_MEMBERS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchGroupMembersSuccess = createAction(FETCH_GROUP_MEMBERS_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchGroupMembersFailure = createAction(FETCH_GROUP_MEMBERS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearGroupMembers = createAction(CLEAR_GROUP_MEMBERS, (locationPathname) => ({ payload: { locationPathname } }));

export const fetchMembersNotInGroup = createAction(FETCH_MEMBERS_NOT_IN_GROUP, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchMembersNotInGroupSuccess = createAction(
    FETCH_MEMBERS_NOT_IN_GROUP_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchMembersNotInGroupFailure = createAction(FETCH_MEMBERS_NOT_IN_GROUP_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearMembersNotInGroup = createAction(CLEAR_MEMBERS_NOT_IN_GROUP, (locationPathname) => ({ payload: { locationPathname } }));
