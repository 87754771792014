import { clientConfigKey, userPreferencesKey } from 'config/config';
import { ConfirmLoginResponse, CurrentUser } from 'models/api/auth';
import { FETCH_CLIENT_DETAILS_SUCCESS, UPDATE_CLIENT_SETTINGS_SUCCESS } from 'redux/client/actions.types';
import { CLEAR_CURRENT_USER, EXTEND_TOKEN_VALIDITY_SUCCESS, SET_CURRENT_USER, SET_CURRENT_USER_SUCCESS } from 'redux/current-user/actions.types';
import { EDIT_USER_SUCCESS } from 'redux/user/actions.types';
import { setSessionStorageObjectItem } from 'utils/browser-storage';

import { UserDataFormToken, UserPreferences } from '@manigo/manigo-domain-typings';
import { createReducer } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';


export const currentUserReducerName = 'currentUser';

const initialState:{
  jwtToken: string | null,
  accessTokenExpirationTimeStamp: number | null,
  refreshTokenExpirationTimeStamp: number | null,

  userData: CurrentUser | null,
  isSuperAdmin: boolean | null,
  clientConfig: object | null,
  permissions: object | null,
  userPreferences: UserPreferences | null,
  variant: string | null,
} = {
    jwtToken: null,
    accessTokenExpirationTimeStamp: null,
    refreshTokenExpirationTimeStamp: null,

    userData: null,
    isSuperAdmin: null,
    clientConfig: null,
    permissions: null,
    userPreferences: null,
    variant: null,
};

export const normaliseObjectKeys = (rawData) => Object.keys(rawData || {})
    .reduce((acc, key) => {
        const fixedKey = key.replace(/(_\w)/g, (item) => item[1].toUpperCase());

        return {
            ...acc,
            [fixedKey]: typeof rawData[key] === 'object' && rawData[key] !== null
                ? normaliseObjectKeys(rawData[key]) // XXX recurrent call
                : rawData[key],
        };
    }, {});

const handleNewToken = (state, data) => {
    const {
        accessToken,
        expirationTimeOfAccessToken,
        expirationTimeOfRefreshToken,
    } = data as ConfirmLoginResponse;

    const decodedUserData = jwtDecode(accessToken) as UserDataFormToken;

    const userData = {
        ...decodedUserData.identifiers,
        ...decodedUserData.user,
        role: decodedUserData.role,
        device: decodedUserData.device,
        userPreferences: decodedUserData.preferences,
    };

    state.jwtToken = accessToken;
    state.accessTokenExpirationTimeStamp = new Date(expirationTimeOfAccessToken).getTime();
    state.refreshTokenExpirationTimeStamp = new Date(expirationTimeOfRefreshToken).getTime();

    state.userData = userData;
    // XXX quite important line!
    state.isSuperAdmin = userData?.role?.isAllAccessGranted;

    // XXX bit tricky part... you get user preferences on login, you need to store it because of page reload or navigation scenarios
    // ...but you need to update it when successful (self)user edition occurs
    // so although it is a data duplication IMHO it is safer/cleaner to move it to separate state & session object key
    if (decodedUserData) {
        state.userPreferences = userData.userPreferences;
    } else {
    // getSessionStorageObjectItem()
    }

    setSessionStorageObjectItem(userPreferencesKey, decodedUserData.preferences);
};


const handlers = {
    [SET_CURRENT_USER]: (state, action) => {
        state.variant = action.payload?.variant;
    },
    [SET_CURRENT_USER_SUCCESS]: (state, action) => {
        handleNewToken(state, action.payload);
        state.permissions = action.payload.permissions;
        state.clientConfig = normaliseObjectKeys(action.payload.configuration);
    },
    [EXTEND_TOKEN_VALIDITY_SUCCESS]: (state, action) => {
        handleNewToken(state, action.payload);
    },

    [UPDATE_CLIENT_SETTINGS_SUCCESS]: (state, action) => {
        if (state.userData.clientId === action.payload.id) {
            setSessionStorageObjectItem(clientConfigKey, action.payload.configuration);
            state.clientConfig = normaliseObjectKeys(action.payload.configuration);
        }
    },
    [FETCH_CLIENT_DETAILS_SUCCESS]: (state, action) => {
        if (state.userData.clientId === action.payload.responsePayload.id) {
            setSessionStorageObjectItem(clientConfigKey, action.payload?.responsePayload?.configuration);
            state.clientConfig = normaliseObjectKeys(action.payload?.responsePayload?.configuration);
        }
    },

    [EDIT_USER_SUCCESS]: (state, action) => {
        if (state.userData.id === action.payload.responsePayload.id) {
            state.userPreferences = action.payload.responsePayload.user_preferences;
            setSessionStorageObjectItem(userPreferencesKey, action.payload.responsePayload.user_preferences);
            state.userData = {
                ...state.userData,
                ...normaliseObjectKeys(action.payload.responsePayload),
            };
        }
    },

    [CLEAR_CURRENT_USER]: () => initialState,

};


export default createReducer(initialState, handlers);
