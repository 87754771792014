import { Action } from 'models/meta/action';
import { Epic } from 'models/meta/epic';
import { showSuccessToast } from 'redux/application/actions';
import { fetchMemberDetails } from 'redux/member/actions';
import { memberDetailsPerLocationsStoreKeyName, memberReducerName } from 'redux/member/reducer';
import { hideModal, setModalProps } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { catchError, from, of, switchMap } from 'rxjs';


import {
    clearResetUpdateMemberStatusForm,
    fetchMemberStatusHistoryListFailure,
    fetchMemberStatusHistoryListSuccess,
} from './actions';
import {
    ACTIVATE_MEMBER,
    BAN_MEMBER, EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS,
    FETCH_MEMBER_STATUS_HISTORY_LIST,

    FetchMemberStatusHistoryPayload,
    SUSPEND_MEMBER,
    UNBAN_MEMBER,
    UNSUSPEND_MEMBER,
    UpdateMemberStatusPayload,
} from './actions.types';
import { reassignMemberGroupSuccess } from '../group/actions';


export const onFetchMemberStatusHistory: Epic = (action$, state$, { members }) => action$.pipe(
    ofType(FETCH_MEMBER_STATUS_HISTORY_LIST),
    switchMap(({ payload } : Action<FetchMemberStatusHistoryPayload>) => from(members.getMemberStatusHistory({ ...payload.queryParams }))
        .pipe(
            switchMap((response) => of(fetchMemberStatusHistoryListSuccess(
                response.data.rows,
                response?.data?.count,
                payload.locationPathname,
            ))),
            catchError(() => of(fetchMemberStatusHistoryListFailure(payload.locationPathname))),
        )),
);


export const onUpdateMemberStatus: Epic = (action$, state$, { i18n, members }) => action$.pipe(
    ofType(
        SUSPEND_MEMBER,
        UNSUSPEND_MEMBER,
        BAN_MEMBER,
        UNBAN_MEMBER,
        ACTIVATE_MEMBER,
        EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS,
    ),
    switchMap(({ type, payload }: Action<UpdateMemberStatusPayload>) => from(members.updateMemberStatus({
        memberId: payload?.queryParams?.memberId,
        actionType: type,
    }, payload.requestPayload))
        .pipe(
            switchMap(() => {
                const currentEntityDetailsQueryParams = state$.value[memberReducerName][memberDetailsPerLocationsStoreKeyName][payload.locationPathname]?.queryParams;
                const extraActions = [
                    ...(type === EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS ? [clearResetUpdateMemberStatusForm()] : []),
                ];

                return of(
                    ...extraActions,
                    fetchMemberDetails(currentEntityDetailsQueryParams, payload.locationPathname),
                    hideModal(),
                    showSuccessToast(i18n.t('member:actionMessages.updateMemberStatusSuccess')),
                );
            }),
            catchError(() => of(
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);

export default [
    onFetchMemberStatusHistory,
    onUpdateMemberStatus,
];
