import { locationServiceKey } from 'config/config';
import { LocationChangeActionTypes, NavigationDirection } from 'constants/NavigationModel';
import { hideDrawer } from 'redux/drawer/actions';
import { hideModal } from 'redux/modal/actions';
import { navigationStopped, preformNavigationBackSuccess, preformNavigationSuccess } from 'redux/navigation/actions';
import { setSessionStorageObjectItem } from 'utils/browser-storage';

import { Observable } from 'rxjs';


// XXX crucial for navigation guards
export const createStop$ = ({
    history,
    actions,
    trace,
    currentObject,
}) => {
    const current = currentObject.value;
    const currentLocationPathname = history.location.pathname;

    return new Observable(
        (subscriber) => history.block(({ location, action }) => {
            const index = trace.findIndex((historyLocation) => historyLocation.key === location.key);

            // eslint-disable-next-line no-nested-ternary
            const direction = index >= 0
      && action === LocationChangeActionTypes.POP
      && current > index
                ? NavigationDirection.BACKWARD
                : current < 0 || index !== current
                    ? NavigationDirection.FORWARD : NavigationDirection.SELF;

            if (currentLocationPathname !== location?.pathname) {
                const notification = navigationStopped(location?.pathname, {
                    action,
                    direction,
                });
                [notification, actions].forEach(subscriber.next.bind(subscriber));
                return undefined;
            }

            // prevent navigation
            return false;
        }),
    );
};


const createHistoryListenActions = ({
    direction,
    action,
    location,
}) => {
    const commonActions = [
        hideModal(),
        hideDrawer(),
    ];

    const enhancedLocation = {
        ...location,
        action,
        direction,
    };

    const handlers = [
        {
            predicate: () => direction === NavigationDirection.BACKWARD,
            handler: () => [preformNavigationBackSuccess(enhancedLocation)],
        },
        {
            predicate: () => true,
            handler: () => [preformNavigationSuccess(enhancedLocation)],
        },
    ];

    const handler = handlers.filter(({ predicate }) => predicate())[0].handler();

    return [
        ...commonActions,
        ...handler,
    ];
};

// XXX trace & current are kept as local state
// this is done to normalise browser back & forward arrows behaviour asd both those navigation events are actions of type POP,
// but forward arrow should look the same as regular forward (action === PUSH) navigation form UX point of view,
// and only the browser back should look as back.


// XXX crucial for proper application behaviour
export const createLocation$ = ({ history, trace, currentObject }) =>
    new Observable((subscriber) =>
        // eslint-disable-next-line complexity
        history.listen((listener) => {
            const { location, action } = listener;

            const index = trace.findIndex((historyLocation) => historyLocation.key === location.key);
            let direction;

            if (action === LocationChangeActionTypes.PUSH) {
                if (currentObject.value < trace.length - 1) {
                    // If we're navigating forward, remove the forward history
                    trace.splice(currentObject.value + 1);
                }

                // add to trace
                trace.push(location);
                currentObject.value = trace.length - 1;
                direction = NavigationDirection.FORWARD;
            }

            if (action === LocationChangeActionTypes.REPLACE) {
                if (currentObject.value < trace.length - 1) {
                    // If we're navigating forward, remove the forward history
                    trace.splice(currentObject.value + 1);
                }

                direction = NavigationDirection.FORWARD;
                // replace in trace
                if (trace.length > 0) {
                    trace[trace.length - 1] = location;
                }
                currentObject.value = trace.length;
            }

            if (action === LocationChangeActionTypes.POP) {
                if (index >= 0 && index > currentObject.value) {
                    currentObject.value = index;
                    direction = NavigationDirection.FORWARD;
                } else if (index >= 0 && index < currentObject.value) {
                    currentObject.value = index;
                    direction = NavigationDirection.BACKWARD;
                } else {
                    direction = NavigationDirection.FORWARD;
                }
            }


            const { state, ...standardLocationKeys } = location;
            const { type, ...rest } = state || {};

            const amendedLocation = {
                ...standardLocationKeys,
                ...(
                    action === LocationChangeActionTypes.POP
        && direction === NavigationDirection.FORWARD

                    // XXX strip redirection  & tab change markers! it is essential in order
                    // to `clearStandardListDataQueryParamsOnNavigation` work as expected in this specific case
                        ? { state: { ...rest } }
                        : { state }
                ),
            };

            setSessionStorageObjectItem(locationServiceKey, {
                currentLocation: amendedLocation,
                current: currentObject,
                trace,
            });

            const actions = createHistoryListenActions({
                direction,
                action,
                location: { ...amendedLocation },
            });

            actions.forEach(subscriber.next.bind(subscriber));
        }));

