import StandardTooltip from 'components/common/molecules/StandardTooltip';
import TextWithHighlightedSearch from 'components/common/molecules/value-renderers/TextWithHighlightedSearch';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { standardDateFormat } from 'config/config';
import { UserPreferencesDecimalSeparators, UserPreferencesThousandsSeparators } from 'constants/UserModel';
import { formatIBAN } from 'utils/account-tools';
import { formatDate, formatTime, mapTimeFormat, removeYearFormDateFormat } from 'utils/date-time-tools';
import { amountFormatter } from 'utils/number-tools';
import { formatPhoneNumber } from 'utils/phone-number-tools';

import React from 'react';


export const ValueTypes = Object.freeze({
    TEXT: 'TEXT',
    AMOUNT: 'AMOUNT',
    COUNTER: 'COUNTER',
    PERCENTAGE: 'PERCENTAGE',
    DATE: 'DATE',
    DATE_WITHOUT_YEAR: 'DATE_WITHOUT_YEAR',
    TIME: 'TIME',
    PHONE: 'PHONE',
    IBAN: 'IBAN',
    BOOLEAN: 'BOOLEAN',
});

export enum StandardValueTypes {
  TEXT = 'TEXT',
  AMOUNT = 'AMOUNT',
  COUNTER = 'COUNTER',
  PERCENTAGE = 'PERCENTAGE',
  DATE = 'DATE',
  DATE_WITHOUT_YEAR = 'DATE_WITHOUT_YEAR',
  TIME = 'TIME',
  PHONE = 'PHONE',
  IBAN = 'IBAN',
  BOOLEAN = 'BOOLEAN',
}

export const emptyValue = '-';

export function formatValue({
    userPreferences,
    valueType,
    value,
    dateFormat,
    showTime,
    timeFormat,
    decimalPrecision,
    t,
}) {
    const handlers = [
        {
            predicate: () => valueType === ValueTypes.BOOLEAN,
            handler: () => booleanAsIconsRenderer({ value }, 'value', t),
        },

        {
            predicate: () => value && valueType === ValueTypes.AMOUNT,
            handler: () => amountFormatter({
                value: value || 0,
                decimalSeparator: userPreferences?.decimalSeparator || UserPreferencesDecimalSeparators.DOT,
                thousandsSeparator: userPreferences?.thousandsSeparator || UserPreferencesThousandsSeparators.SPACE,
                precision: decimalPrecision,
            }),
        },
        {
            predicate: () => valueType === ValueTypes.COUNTER,
            handler: () => value || 0,
        },
        {
            predicate: () => value && valueType === ValueTypes.PERCENTAGE,
            handler: () => `${amountFormatter({
                value,
                decimalSeparator: userPreferences?.decimalSeparator || UserPreferencesDecimalSeparators.DOT,
                thousandsSeparator: userPreferences?.thousandsSeparator || UserPreferencesThousandsSeparators.SPACE,
            })} %`,
        },
        {
            predicate: () => value && valueType === ValueTypes.DATE,
            handler: () => formatDate({
                date: value,
                dateFormat: showTime
                    ? `${dateFormat || userPreferences?.dateFormat || standardDateFormat} ${timeFormat || mapTimeFormat(userPreferences?.timeFormat)}`
                    : dateFormat || userPreferences?.dateFormat || standardDateFormat,
            }),
        },
        {
            predicate: () => value && valueType === ValueTypes.DATE_WITHOUT_YEAR,
            handler: () => formatDate({
                date: value,
                dateFormat: removeYearFormDateFormat(dateFormat || userPreferences?.dateFormat || standardDateFormat),
            }),
        },


        {
            predicate: () => value && valueType === ValueTypes.TIME,
            handler: () => formatTime({
                date: value,
                timeFormat: mapTimeFormat(userPreferences?.timeFormat),
            }),
        },
        {
            predicate: () => value && valueType === ValueTypes.IBAN,
            handler: () => formatIBAN(value),
        },
        {
            predicate: () => value && valueType === ValueTypes.PHONE,
            handler: () => formatPhoneNumber(value),
        },
        {
            predicate: () => value || (valueType === ValueTypes.AMOUNT && value === 0),
            handler: () => value,
        },
        {
            predicate: () => true,
            handler: () => emptyValue,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export function decorateValue({
    userPreferences,
    timeFormat,
    dateFormat,
    valueType,
    value,
    formattedValue,
    searchQuery,
    showCopyToClipboard,
    isTextEllipsisDisabled,
    isEmpty,
    isAmount,
    showTooltip,
    showTimeInTooltip,
    useTooltipRootContainer,
    t,
}) {
    const tooltipValueHandlers = [
        {
            predicate: () => valueType === ValueTypes.DATE,
            handler: () => formatDate({
                date: value,
                dateFormat: showTimeInTooltip
                    ? `${dateFormat || userPreferences?.dateFormat || standardDateFormat} ${timeFormat || mapTimeFormat(userPreferences?.timeFormat)}`
                    : dateFormat || userPreferences?.dateFormat || standardDateFormat,
            }),
        },
        {
            predicate: () => valueType === ValueTypes.BOOLEAN,
            handler: () => (value ? t('common:yes') : t('common:no')),
        },
        {
            // default happy-path scenario, render component
            predicate: () => true,
            handler: () => formattedValue,
        },
    ];


    const tooltipValue = tooltipValueHandlers.filter(({ predicate }) => predicate())[0].handler();


    const withTooltip = (content) => (
        <StandardTooltip
            overlayStyle={{ maxWidth: '700px' }}
            title={tooltipValue}
            showCopyToClipboard={showCopyToClipboard}
            isTextEllipsisDisabled={isTextEllipsisDisabled}
            isAmount={isAmount}
            useRootContainer={useTooltipRootContainer}
        >
            {content}
        </StandardTooltip>
    );

    const handlers = [
        {
            predicate: () => isEmpty,
            handler: () => formattedValue,
        },
        {
            predicate: () => searchQuery && searchQuery.length > 0 && showTooltip === false,
            handler: () => <TextWithHighlightedSearch rawValue={formattedValue} filterValue={searchQuery} />,
        },
        {
            predicate: () => searchQuery && searchQuery.length > 0,
            handler: () => withTooltip(<TextWithHighlightedSearch rawValue={formattedValue} filterValue={searchQuery} />),
        },
        {
            predicate: () => showTooltip === false,
            handler: () => formattedValue,
        },
        {
            predicate: () => true,
            handler: () => withTooltip(formattedValue),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export default {
    ValueTypes,
    emptyValue,
    formatValue,
    decorateValue,
};
