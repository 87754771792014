import accountsEN from 'assets/locales/en-GB/accounts.json';
import apiKeysEN from 'assets/locales/en-GB/api-keys.json';
import beneficiariesEN from 'assets/locales/en-GB/beneficiaries.json';
import businessUsersEN from 'assets/locales/en-GB/business-users.json';
import cardsEN from 'assets/locales/en-GB/cards.json';
import changeLogEN from 'assets/locales/en-GB/change-log.json';
import clientsEN from 'assets/locales/en-GB/clients.json';
import commonEN from 'assets/locales/en-GB/common.json';
import companiesEN from 'assets/locales/en-GB/companies.json';
import faqEN from 'assets/locales/en-GB/faq.json';
import feesEN from 'assets/locales/en-GB/fees.json';
import groupsEN from 'assets/locales/en-GB/groups.json';
import kycKybEN from 'assets/locales/en-GB/kycKyb.json';
import limitsEN from 'assets/locales/en-GB/limits.json';
import marketingPreferencesEN from 'assets/locales/en-GB/marketingPreferences.json';
import memberEN from 'assets/locales/en-GB/member.json';
import offboardingEN from 'assets/locales/en-GB/offboarding.json';
import privacyPolicyEN from 'assets/locales/en-GB/privacy-policy.json';
import productsEN from 'assets/locales/en-GB/products.json';
import promotionsEN from 'assets/locales/en-GB/promotions.json';
import riskScoreEN from 'assets/locales/en-GB/risk-score.json';
import rolesEN from 'assets/locales/en-GB/roles.json';
import stakeholdersEN from 'assets/locales/en-GB/stakeholders.json';
import transactionsEN from 'assets/locales/en-GB/transactions.json';
import unauthorisedEN from 'assets/locales/en-GB/unauthorised.json';
import usersEN from 'assets/locales/en-GB/users.json';
import webhooksEN from 'assets/locales/en-GB/webhooks.json';
import { formatIBAN } from 'utils/account-tools';

// the translations
export const resources = {
    'en-GB': {
        accounts: accountsEN,
        apiKeys: apiKeysEN,
        beneficiaries: beneficiariesEN,
        cards: cardsEN,
        clients: clientsEN,
        common: commonEN,
        companies: companiesEN,
        fees: feesEN,
        groups: groupsEN,
        kycKyb: kycKybEN,
        limits: limitsEN,
        member: memberEN,
        marketingPreferences: marketingPreferencesEN,
        products: productsEN,
        promotions: promotionsEN,
        transactions: transactionsEN,
        unauthorised: unauthorisedEN,
        users: usersEN,
        roles: rolesEN,
        faq: faqEN,
        changeLog: changeLogEN,
        offboarding: offboardingEN,
        webhooks: webhooksEN,
        privacyPolicy: privacyPolicyEN,
        stakeholders: stakeholdersEN,
        businessUsers: businessUsersEN,
        riskScore: riskScoreEN,
    },
};


export const namespaces = [
    'accounts',
    'apiKeys',
    'beneficiaries',
    'cards',
    'clients',
    'common',
    'companies',
    'fees',
    'groups',
    'kycKyb',
    'member',
    'marketingPreferences',
    'products',
    'promotions',
    'transactions',
    'unauthorised',
    'users',
    'roles',
    'faq',
    'changeLog',
    'offboarding',
    'webhooks',
    'privacyPolicy',
    'stakeholders',
    'businessUsers',
    'riskScore',
];

export const i18nFormat = (value, format) => {
    if (!value) {
        return '';
    }

    if (format === 'iban') {
        return formatIBAN(value);
    }

    return value;
};
