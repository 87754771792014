import { setQueryParams } from 'redux/application/actions';
import { addMemberToGroup } from 'redux/group/actions';
import { groupReducerName } from 'redux/group/reducer';
import { clearGroupMembers, clearMembersNotInGroup, fetchGroupMembers, fetchMembersNotInGroup } from 'redux/group-members/actions';
import {
    groupMembersListsPerLocationStoreKeyName,
    groupNonMembersListsPerLocationStoreKeyName,
    groupMembersReducerName,
} from 'redux/group-members/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import GroupMembersList from './GroupMembersList.layout';


const mapStateToProps = (state, ownProps) => {
    const selector = !ownProps.isNonMembersVersion ? groupMembersListsPerLocationStoreKeyName : groupNonMembersListsPerLocationStoreKeyName;

    return {
        idsOfMembersCurrentlyBeingAddedToGroup: state[groupReducerName].idsOfMembersCurrentlyBeingAddedToGroup,
        idsOfMembersSuccessfullyAddedToTheGroup: state[groupReducerName].idsOfMembersSuccessfullyAddedToTheGroup,

        enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
        listData: getStandardListData({
            state,
            reducerName: groupMembersReducerName,
            listsByLocationStoreKeyName: selector,
        }),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const selector = !ownProps.isNonMembersVersion ? groupMembersListsPerLocationStoreKeyName : groupNonMembersListsPerLocationStoreKeyName;
    return {
        dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),
        dispatchAddMemberToGroup: ({ memberId, groupId, clientId }) => dispatch(addMemberToGroup({ memberId, groupId, clientId })),
        dispatchFetchItemsList: (queryParams, locationPathname) => (!ownProps.isNonMembersVersion
            ? dispatch(fetchGroupMembers(queryParams, locationPathname))
            : dispatch(fetchMembersNotInGroup(queryParams, locationPathname))),
        dispatchClearItemsList: (locationPathname) => (!ownProps.isNonMembersVersion
            ? dispatch(clearGroupMembers(locationPathname))
            : dispatch(clearMembersNotInGroup(locationPathname))),
        dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
            reducerName: groupMembersReducerName,
            fieldName: selector,
            locationPathname,
            queryParams,
        })),
    };
};

const decorate = compose(
    withTranslation('member'),
    connect(mapStateToProps, mapDispatchToProps),
);


export default decorate(GroupMembersList);
