import CommonAddressFrom from 'components/common/organisms/CommonAddressForm';
import BackButton from 'components/pages/authorised/AddEditBeneficiary/BackButton';
import { BeneficiaryType, BeneficiaryTypeProp } from 'constants/BeneficiariesModel';
import { commonValidation, handleWhitespaceOnBlur, isValidFirstNameRule, isValidLastNameRule } from 'utils/validation-tools';

import { Button, Form, Row, Col, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Styled from './Address.styled';


function AddressStep({
    t,
    form,
    formData,
}) {
    return (
        <Col style={{ maxWidth: '700px' }}>
            <Styled.TwoColumns>
                <div>
                    {formData?.beneficiary_type === BeneficiaryType.INDIVIDUAL && (
                        <>
                            <Form.Item
                                label={t('beneficiaryFields.firstName.label')}
                                name="first_name"
                                rules={[
                                    ...commonValidation({ t, isRequired: true }),
                                    ...isValidFirstNameRule(t),
                                ]}

                            >
                                <Input
                                    placeholder={t('beneficiaryFields.firstName.placeholder')}
                                    onBlur={(e) => handleWhitespaceOnBlur(e, 'first_name', form)}
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('beneficiaryFields.middleName.label')}
                                name="middle_name"
                                rules={[
                                    ...commonValidation({ t, maxLength: 128 }),
                                    ...isValidFirstNameRule(t),
                                ]}
                            >
                                <Input
                                    placeholder={t('beneficiaryFields.middleName.placeholder')}
                                    onBlur={(e) => handleWhitespaceOnBlur(e, 'middle_name', form)}
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('beneficiaryFields.lastName.label')}
                                name="last_name"
                                rules={[
                                    ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                                    ...isValidLastNameRule(t),
                                ]}
                            >
                                <Input
                                    placeholder={t('beneficiaryFields.lastName.placeholder')}
                                    onBlur={(e) => handleWhitespaceOnBlur(e, 'last_name', form)}
                                />
                            </Form.Item>
                        </>
                    )}

                    {formData?.beneficiary_type === BeneficiaryType.COMPANY && (
                        <Form.Item
                            label={t('beneficiaryFields.companyName.label')}
                            name="company_name"
                            rules={commonValidation({ t, isRequired: true })}
                        >
                            <Input
                                placeholder={t('beneficiaryFields.companyName.placeholder')}
                                onBlur={(e) => handleWhitespaceOnBlur(e, 'company_name', form)}
                            />
                        </Form.Item>
                    )}
                </div>

                <div>
                    <CommonAddressFrom
                        fieldNamePrefix="address"
                        stateFieldName="state_province"
                        form={form}
                        useStateField
                    />
                </div>
            </Styled.TwoColumns>
      `
            <Row style={{ marginTop: 20 }}>
                <BackButton />

                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ marginLeft: 10 }}
                        type="primary"
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </Col>
    );
}

AddressStep.propTypes = {
    t: PropTypes.func.isRequired,
    formData: PropTypes.shape({ beneficiary_type: BeneficiaryTypeProp }),
};


export default AddressStep;
