import { Dispatch } from 'models/meta/action';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { unblockTransaction } from 'redux/transaction/actions';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UnblockTransactionModal from './UnblockTransactionModal.layout';
import { UnblockTransactionModalDispatchProps, UnblockTransactionModalStateProps } from './UnblockTransactionModal.types';


const mapStateToProps = (state: RootState): UnblockTransactionModalStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    //
});

const mapDispatchToProps = (dispatch: Dispatch): UnblockTransactionModalDispatchProps => ({
    dispatchUnblockTransaction: (requestPayload, locationPathname) => dispatch(unblockTransaction(requestPayload, locationPathname)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});


const decorate = compose<ComponentType>(
    withTranslation('transactions'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UnblockTransactionModal);
