import svgIllustrations from 'assets/images/illustrations/svgIllustrations';
import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import CommonAddressFrom from 'components/common/organisms/CommonAddressForm';
import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import { isoDateFormat, standardDateFormat } from 'config/config';
import { ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import calcIs18YearsOld from 'utils/calcIs18YearsOld';
import { filterOptions } from 'utils/filterOptions';
import { isFormPristine } from 'utils/forms-tools';
import { createClientOptions } from 'utils/options-creators';
import { formatPhoneNumber } from 'utils/phone-number-tools';
import { commonValidation, handleWhitespaceOnBlur, isValidFirstNameRule, isValidLastNameRule } from 'utils/validation-tools';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
    Steps, Row, Button, Input, Form, Col, Typography, Divider, Select, DatePicker, Switch, Spin,
} from 'antd';
import dayjs from 'dayjs';
import dotObject from 'dot-object';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';


import { AddMemberProps } from './AddMember.types';


const { Step } = Steps;
const { Title } = Typography;

// TODO reduce complexity
// eslint-disable-next-line complexity
function AddMember({
    isBlockNavigationReasonSet,
    accessControl,
    currentUserClientId,
    clientsData,

    isLoadingCreateMember,
    dispatchCreateMember,
    dispatchSetCreateMemberFormStep,
    dispatchSetCreateMemberFormData,
    currentStep,
    createMemberFormData,
    dispatchRequestNavigationBack,
    userPreferences,
    dispatchBlockNavigation,
    dispatchUnblockNavigation,
    t,
}: AddMemberProps) {
    const { clientId } = useParams();
    const [form] = Form.useForm();

    // listOfOrigin
    useEffect(() => () => {
        dispatchSetCreateMemberFormStep(0);
        dispatchSetCreateMemberFormData({});
        dispatchUnblockNavigation();
    }, []);

    const clientOptions = useMemo(
        () => createClientOptions(clientsData?.items, accessControl.isSuperAdmin), // XXX client select
        [clientsData, accessControl],
    );

    const format = useMemo(
        () => userPreferences?.dateFormat || standardDateFormat,
        [userPreferences],
    );


    const onFinishHandler = (values) => {
        const dataToSend = dotObject.object({ ...createMemberFormData, ...values });

        const payload = { ...dataToSend };
        if (currentStep === 2) {
            payload.memberDateOfBirth = dataToSend.memberDateOfBirth.format(isoDateFormat);
            payload.clientId = dataToSend.clientId ?? clientId ?? currentUserClientId;
            payload.memberAddress = {
                ...dataToSend.memberAddress,
                state: dataToSend.memberAddress.state ?? 'n/a',
            };
            payload.memberConfig.enableOnCreation = dataToSend.memberConfig.enableOnCreation ?? false;
            payload.memberConfig.enforcePasscodeSetup = dataToSend.memberConfig.enforcePasscodeSetup ?? false;
            payload.memberPhoneNumber = formatPhoneNumber(payload.memberPhoneNumber, 'E.164');
            payload.isClientContext = !!clientId;
            dispatchCreateMember(payload);
        } else {
            dispatchSetCreateMemberFormStep(currentStep + 1);
            dispatchSetCreateMemberFormData({
                ...createMemberFormData,
                ...values,
            });
        }
    };


    const handleFormValuesChanges = (changedValues, allValues) => {
        const isPristine = isFormPristine({ ...allValues });
        if (!isPristine && !isBlockNavigationReasonSet) {
            dispatchBlockNavigation(ReasonsForBlockedNavigation.NOT_PRISTINE_ADD_MEMBER_FORM);
        }

        if (isBlockNavigationReasonSet && isPristine) {
            dispatchUnblockNavigation();
        }
    };

    return (
        <>
            <StandardPageHeading title="Add Member" />

            <StandardPageWrapper>
                <Spin
                    tip={t('common:actionMsg.processingRequestSpinnerText')}
                    spinning={isLoadingCreateMember}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={createMemberFormData}
                        style={{ padding: 10, background: 'white' }}
                        scrollToFirstError
                        onValuesChange={handleFormValuesChanges}
                        onFinish={onFinishHandler}
                    >
                        <Steps
                            size="small"
                            current={currentStep}
                            style={{ marginBottom: 50, paddingTop: 15, paddingRight: 10, paddingLeft: 10 }}
                        >
                            <Step title="Personal Details" />
                            <Step title="Address" />
                            <Step title="Configuration" />
                            <Step title="Finished" />
                        </Steps>

                        <Row justify="center">
                            <Col style={{ width: '100%' }}>
                                {/* Step 1 */}
                                {currentStep === 0 && (
                                    <Row gutter={[12, 12]}>
                                        <Col md={12}>
                                            <Title level={4}>Personal Details</Title>
                                            <Divider />

                                            {accessControl.isSuperAdmin && !clientId && ( // XXX allowed -client select
                                                <Form.Item
                                                    name="clientId" // check which field to send here
                                                    label="Partner"
                                                    rules={[{ required: true, message: 'Partner is a required field' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select Partner"
                                                        filterOption={filterOptions}
                                                        loading={clientsData?.isLoadingList}
                                                    >
                                                        {clientOptions}
                                                    </Select>
                                                </Form.Item>
                                            )}

                                            <Form.Item
                                                name="memberFirstName"
                                                label="First Name"
                                                rules={[
                                                    ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                                                    ...isValidFirstNameRule(t),
                                                ]}
                                            >
                                                <Input
                                                    placeholder="First Name"
                                                    onBlur={(e) => handleWhitespaceOnBlur(e, 'memberFirstName', form)}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="memberLastName"
                                                label="Last Name"
                                                rules={[
                                                    ...commonValidation({ t, isRequired: true, maxLength: 128 }),
                                                    ...isValidLastNameRule(t),
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Enter Last Name"
                                                    onBlur={(e) => handleWhitespaceOnBlur(e, 'memberLastName', form)}
                                                />
                                            </Form.Item>

                                            <PhoneInput
                                                t={t}
                                                isRequired
                                                name="memberPhoneNumber"
                                            />

                                            <EmailInput
                                                name="memberEmail"
                                                t={t}
                                                isRequired
                                            />


                                            <Form.Item
                                                name="memberDateOfBirth"
                                                label="Date Of Birth"
                                                rules={[
                                                    {
                                                        type: 'object',
                                                        required: true,
                                                        message: 'Date of Birth is a required field',
                                                    },
                                                    {
                                                        validator: async (_, value) => {
                                                            if (!calcIs18YearsOld(value)) throw new Error('Member must be at least 18 years old');
                                                        },
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    format={format}
                                                    style={{ width: '100%' }}
                                                    disabledDate={(today) => !calcIs18YearsOld(today)}
                                                    defaultPickerValue={dayjs().subtract(18, 'year') as any}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                {/* Step 2 */}
                                {currentStep === 1 && (
                                    <Row gutter={[12, 12]}>
                                        <Col md={12}>
                                            <Title level={4}>Address</Title>
                                            <Divider />
                                            <CommonAddressFrom
                                                form={form}
                                                fieldNamePrefix="memberAddress"
                                                useStateField
                                            />
                                        </Col>
                                    </Row>
                                )}

                                {/* Step 3 */}
                                {currentStep === 2 && (
                                    <Row gutter={[12, 12]}>
                                        <Col md={12}>
                                            <Title level={4}>Configuration</Title>
                                            <Divider />

                                            <Form.Item
                                                name="memberConfig.enableOnCreation"
                                                label="Enable on creation"
                                                valuePropName="checked"
                                            >
                                                <Switch
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    checked={false}
                                                />
                                            </Form.Item>


                                            <Form.Item
                                                name="memberConfig.enforcePasscodeSetup"
                                                label="Enforce passcode setup"
                                                valuePropName="checked"
                                            >
                                                <Switch
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    checked={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                {/* Step 4 */}
                                {currentStep === 3 && (
                                    <Row justify="center">
                                        <Col style={{ paddingBottom: 20 }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <img
                                                    src={svgIllustrations.successWoman}
                                                    alt="Decoration illustration picturing success woman"
                                                    width="100%"
                                                    style={{ maxWidth: 250 }}
                                                />
                                            </div>

                                            <div style={{ textAlign: 'center' }}>
                                                <div style={{ fontSize: 20 }}>
                          Member successfully added!
                                                    <Row style={{ marginTop: 20 }} justify="center">
                                                        <Button
                                                            type="primary"
                                                            htmlType="button"
                                                            onClick={() => dispatchRequestNavigationBack()}
                                                        >
                                                            {t('common:buttons.finish.text')}
                                                        </Button>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    {currentStep !== 4 && (
                                        <Form.Item>
                                            {currentStep !== 3 && (
                                                <Button
                                                    disabled={currentStep === 0}
                                                    onClick={() => dispatchSetCreateMemberFormStep(currentStep - 1)}
                                                >
                          Back
                                                </Button>
                                            )}
                                        </Form.Item>
                                    )}

                                    {currentStep !== 3 && (
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                style={{ marginLeft: 5 }}
                                                onClick={() => {
                                                    if (currentStep === 3) {
                                                        dispatchSetCreateMemberFormData({});
                                                        dispatchSetCreateMemberFormStep(0);
                                                    }
                                                }}
                                            >
                                                {/* eslint-disable-next-line no-nested-ternary */}
                                                {currentStep === 3 ? 'Add New Member' : 'Next'}
                                            </Button>
                                        </Form.Item>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </StandardPageWrapper>
        </>
    );
}

export default AddMember;
