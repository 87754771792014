import styled from 'styled-components';


const MainStepsWrapper = styled.div`
  padding: 10px 16px;
  background: white;
  user-select: none;
  
  .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
    overflow: unset !important;
  }

  .ant-steps-item-title::after{
    content: none !important;
  }
  .ant-steps-item{
    display: flex;
  }
  .ant-steps-item:last-of-type{
    >div.ant-steps-divider-container{
      display: none !important;
    }
  }

  .ant-steps-divider-container{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-steps-divider-container::after{
    content: "";
    display: inline-block;
    height: 1px;
    background: rgba(5, 5, 5, 0.06);
    margin-bottom: -2px;
    width: 95%;
    }

  .ant-steps-item-container {
    position: relative;
    
    .ant-steps-item-title {
      padding-right: 0;
      span{
        overflow: unset !important;
      }
    }
    
    .title-icon-wrapper {
      pointer-events: none;
      position: absolute;
      left: -34px; /* yes, it is a hand-picked magic number */
      font-size: 20px;
    }
  }


  .ant-steps-item-finish {
    .title-icon-wrapper {
      display: none;
    }
  }
  .ant-steps-item-active {
    .title-icon-wrapper {
      color: var(--primary-color);
    }
  }

  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: var(--primary-color);
  }
  
  .ant-steps-item-process {
    .title-icon-wrapper {
      pointer-events: initial;
    }
      
    .ant-steps-item-icon {
      background: white;
    }
  }
  
   .ant-steps-item-wait {
     .ant-steps-icon {
       display: none;
     }
     .title-icon-wrapper {
       color: grey;
     }
     
     .ant-steps-item-title {
       color: #000 !important;
     }
   }
`;

const MainStepLabelWrapper = styled.div`
  display: grid;
  padding: 0 5px;
  grid-template-columns: auto minmax(0, 1fr);
  grid-column-gap: 5px;
  justify-content: right;
  align-items: center;
`;

const MainStepLabel = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
`;

export default { MainStepLabelWrapper, MainStepLabel, MainStepsWrapper };
