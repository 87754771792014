import { setQueryParams } from 'redux/application/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { fetchPromotionDetails, enablePromotion, disablePromotion, clearPromotionDetails } from 'redux/promotion/actions';
import { promotionDetailsPerLocationStoreKeyName, promotionReducerName } from 'redux/promotion/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import PromotionDetails from './PromotionDetails.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: promotionReducerName,
        fieldName: promotionDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),

    isLoadingToggleEnableDisable: state[promotionReducerName].isLoadingToggleEnableDisable,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchPromotionDetails: (queryParams, locationPathname) => dispatch(fetchPromotionDetails(queryParams, locationPathname)),
    dispatchClearPromotionDetails: (locationPathname) => dispatch(clearPromotionDetails(locationPathname)),
    dispatchEnablePromotion: (promotionUuid, locationPathname) => dispatch(enablePromotion(promotionUuid, locationPathname)),
    dispatchDisablePromotion: (promotionUuid, locationPathname) => dispatch(disablePromotion(promotionUuid, locationPathname)),

    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: promotionReducerName,
        fieldName: promotionDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('promotions'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(PromotionDetails);

