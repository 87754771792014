import BackButton from 'components/pages/authorised/AddEditBeneficiary/BackButton';
import { CountriesListProps } from 'constants/CountriesModel';
import { CurrenciesListProp } from 'constants/CurrencyModel';
import { filterOptions } from 'utils/filterOptions';
import { createBeneficiaryTypeOptions, createCountriesOptions, createCurrencyOptions } from 'utils/options-creators';

import { Button, Form, Row, Select, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function BasicDataStep({
    t,
    countries,
    supportedCurrencies,
}) {
    const countriesOptions = useMemo(
        () => createCountriesOptions(countries),
        [countries],
    );

    const currencyOptions = useMemo(
        () => createCurrencyOptions((supportedCurrencies?.items || []), t),
        [supportedCurrencies],
    );

    return (
        <Col style={{ maxWidth: '400px' }}>
            <Form.Item
                name="beneficiary_type"
                label={t('beneficiaryFields.beneficiaryType.label')}
                rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
            >
                <Select
                    placeholder={t('beneficiaryFields.beneficiaryType.placeholder')}
                    disabled={supportedCurrencies.isLoadingList}
                    loading={supportedCurrencies.isLoadingList}
                >
                    {createBeneficiaryTypeOptions()}
                </Select>
            </Form.Item>

            <Form.Item
                name="country"
                label={t('beneficiaryFields.country.label')}
                rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
            >
                <Select
                    showSearch
                    placeholder={t('beneficiaryFields.country.placeholder')}
                    filterOption={filterOptions}
                >
                    {countriesOptions}
                </Select>
            </Form.Item>

            <Form.Item
                name="currency"
                label={t('beneficiaryFields.currency.label')}
                rules={[{ required: true, message: t('common:validationMsg.fieldMandatory') }]}
            >
                <Select
                    showSearch
                    filterOption={filterOptions}
                    placeholder={t('beneficiaryFields.currency.placeholder')}
                >
                    {currencyOptions}
                </Select>
            </Form.Item>

            <Row key="form buttons" style={{ marginTop: 20 }}>
                <BackButton />
                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ marginLeft: 10 }}
                        type="primary"
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </Col>
    );
}

BasicDataStep.propTypes = {
    t: PropTypes.func.isRequired,
    countries: CountriesListProps,
    supportedCurrencies: CurrenciesListProp,
};


export default BasicDataStep;
