import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { getRuleScope } from 'components/common/templates/lists/LimitsList/createTableColumns';
import { RuleDetails } from 'models/domain/rules';
import { enumValueToReadableString, removeUnderscores } from 'utils/string-tools';

import { TFunction } from 'i18next';
import React from 'react';


export const createLimitBaseFieldsConfig = ({
    t,
    limitDetails,
}: {
  t: TFunction,
  limitDetails: RuleDetails,
}) => [
    {
        label: t('limits:limitFields.name'),
        value: <StandardValue value={limitDetails?.ruleName} />,
    },

    {
        label: t('limits:limitFields.ruleScope'),
        value: <StandardValue value={t(`limits:ruleScopes.${getRuleScope(limitDetails)}`)} />,
    },

    ...(limitDetails?.ruleDescription ? [{
        label: t('limits:limitFields.ruleDescription'),
        value: <StandardValue value={limitDetails?.ruleDescription} />,
    }] : []),

    ...(limitDetails?.extInstitutionId ? [{
        label: t('limits:limitFields.extInstitutionId'),
        value: <StandardValue value={limitDetails?.extInstitutionId} />,
    }] : []),
    ...(limitDetails?.extGroupId ? [{
        label: t('limits:limitFields.extGroupId'),
        value: <StandardValue value={limitDetails?.extGroupId} />,
    }] : []),

];

export const createLimitDetailsFieldsConfig = ({
    t,
    limitDetails,
}: {
  t: TFunction,
  limitDetails: RuleDetails,
}) => [
    {
        label: t('limits:limitFields.ruleType'),
        value: <StandardValue value={enumValueToReadableString(limitDetails?.ruleType)} />,
    },
    {
        label: t('limits:limitFields.ruleTransactionType'),
        value: <StandardValue value={removeUnderscores(limitDetails?.ruleTransactionType)} />,
    },
    ...(limitDetails?.limitOccurrence ? [{
        label: t('limits:limitFields.limitOccurrence'),
        value: <StandardValue value={enumValueToReadableString(limitDetails?.limitOccurrence)} />,
    }] : []),

    ...(limitDetails?.limitType ? [{
        label: t('limits:limitFields.limitType'),
        value: <StandardValue value={enumValueToReadableString(limitDetails?.limitType)} />,
    }] : []),
    ...(limitDetails?.ruleTriggerAmount ? [{
        label: t('limits:limitFields.triggerAmount'),
        value: <StandardValue value={limitDetails?.ruleTriggerAmount} />,
    }] : []),
    ...(limitDetails?.ruleActions ? [{
        label: t('limits:limitFields.ruleActions'),
        value: <StandardValue value={`${limitDetails?.ruleActions.map((action) => enumValueToReadableString(action)).join(', ')}`} />,
    }] : []),
];

export const createLimitBlockedFieldsConfig = ({
    t,
    limitDetails,
}: {
  t: TFunction,
  limitDetails: RuleDetails,
}) => [
    {
        label: t('limits:limitFields.countries'),
        value: <StandardValue value={`${limitDetails?.countries?.join(', ')}`} />,
    },
    {
        label: t('limits:limitFields.mcc'),
        value: <StandardValue value={`${limitDetails?.mcc?.join(', ')}`} />,
    },
];
