import CopyToClipboard from 'components/common/molecules/CopyToClipboard';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';

import { Popover, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function FetchAndViewValueButton({
    itemId,
    title = '',
    dispatchFetchValue,
    data,
    t,
}) {
    return (
        <Popover
            title={title}
            showCancel={false}
            okText={t('Close')}
            trigger="click"
            content={(
                <div
                    style={{
                        minWidth: '100px',
                        overflow: 'hidden',
                    }}
                >

                    <Spin spinning={data?.isLoading}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                            }}
                        >
                            <div style={{ paddingRight: '10px' }}>{data?.value}</div>
                            <CopyToClipboard value={data?.value} />
                        </div>
                    </Spin>
                </div>
            )}
        >
            {viewButtonRenderer({
                record: { itemId },
                onClick: () => (data?.value ? undefined : dispatchFetchValue(itemId)),
                // tooltipContent: t('buttons.viewApiKeyValue.tooltip'),
                dataTestId: 'fetch-and-show-api-key-value',
            })}
        </Popover>
    );
}

FetchAndViewValueButton.propTypes = {
    // ownProps
    itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    stateFieldName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    reducerName: PropTypes.string.isRequired,
    dispatchFetchValue: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    data: PropTypes.shape({
        isLoading: PropTypes.bool,
        value: PropTypes.string,
    }),
};

export default FetchAndViewValueButton;
