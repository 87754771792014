import StandardTag from 'components/common/atoms/StandardTag';
import MembersMigrationStatus from 'constants/MembersMigrationStatus';
import { capitalizeFirstLetter, removeUnderscores } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React from 'react';


const mapMigrationStatusToColor = (status) => {
    const handlers = [
        {
            predicate: () => status === MembersMigrationStatus.FINISHED,
            handler: () => 'green',
        },
        {
            predicate: () => status === MembersMigrationStatus.FAILED,
            handler: () => 'red',
        },
        {
            predicate: () => status === MembersMigrationStatus.IN_PROGRESS,
            handler: () => 'orange',
        },
        {
            predicate: () => true,
            handler: () => 'blue',
        },
    ];
    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export function MigrationStatusTag({ status, showTooltip = false }) {
    return (
        <StandardTag
            color={mapMigrationStatusToColor(status)}
            text={removeUnderscores(capitalizeFirstLetter(status))}
            showTooltip={showTooltip}
        />
    );
}

MigrationStatusTag.propTypes = {
    status: PropTypes.oneOf(Object.values(MembersMigrationStatus)),
    showTooltip: PropTypes.bool,
};


export default MigrationStatusTag;
