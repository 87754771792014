import { locationServiceKey } from 'config/config';
import { LocationChangeActionTypes, NavigationDirection } from 'constants/NavigationModel';
import { EnhancedLocation } from 'models/app/navigation';
import {
    BLOCK_NAVIGATION,
    UNBLOCK_NAVIGATION,
    REQUEST_NAVIGATION,
    NAVIGATION_CANCELED,
    NAVIGATION_STOPPED,
    PREFORM_NAVIGATION_SUCCESS,
    REDIRECTION_TO_DEFAULT_PATH,
    PREFORM_NAVIGATION_BACK_SUCCESS,
    SHOW_NAVIGATION_CONFIRMATION,
    HIDE_NAVIGATION_CONFIRMATION,
} from 'redux/navigation/actions';
import { getSessionStorageObjectItem } from 'utils/browser-storage';

import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from '../current-user/actions.types';


export const navigationReducerName = 'navigation';

export interface NavigationStateType {
  readonly previousLocation?: EnhancedLocation;
  readonly enhancedCurrentLocation: EnhancedLocation;
  readonly requestedNextLocation?: any;
  readonly blockNavigationReason?: any;

  readonly isNavigationConfirmationVisible: boolean;
}

const initialState: NavigationStateType = {
    previousLocation: undefined,
    enhancedCurrentLocation: {} as EnhancedLocation,

    requestedNextLocation: undefined,

    blockNavigationReason: undefined,

    isNavigationConfirmationVisible: false,
};

const handlers = {
    [BLOCK_NAVIGATION]: (state, action) => {
        state.blockNavigationReason = action.meta;
    },
    [UNBLOCK_NAVIGATION]: (state) => {
        state.blockNavigationReason = undefined;
    },

    [REQUEST_NAVIGATION]: (state, action) => {
        state.requestedNextLocation = {
            location: action.payload,
            meta: action.meta,
        };
    },

    [NAVIGATION_CANCELED]: (state) => {
        state.requestedNextLocation = undefined;
    },

    [NAVIGATION_STOPPED]: (state, action) => {
        state.requestedNextLocation = {
            location: action.payload,
            meta: action.meta,
        };
    },

    [PREFORM_NAVIGATION_SUCCESS]: (state, action) => {
        state.previousLocation = { ...state.enhancedCurrentLocation };
        state.enhancedCurrentLocation = action.payload;
        state.requestedNextLocation = undefined;
    },
    [REDIRECTION_TO_DEFAULT_PATH]: (state, action) => {
        state.previousLocation = { ...state.enhancedCurrentLocation };
        state.enhancedCurrentLocation = action.payload;
        state.requestedNextLocation = undefined;
    },
    [PREFORM_NAVIGATION_BACK_SUCCESS]: (state, action) => {
        state.requestedNextLocation = undefined;
        state.previousLocation = { ...state.enhancedCurrentLocation };
        state.enhancedCurrentLocation = action.payload;
    },
    [SHOW_NAVIGATION_CONFIRMATION]: (state) => {
        state.isNavigationConfirmationVisible = true;
    },
    [HIDE_NAVIGATION_CONFIRMATION]: (state) => {
        state.isNavigationConfirmationVisible = false;
    },

    'application/initApplication': (state) => {
        const pageReloaded = window.performance.getEntriesByType('navigation').map((nav:any) => nav.type.includes('reload'));
        const storedLocationData = getSessionStorageObjectItem(locationServiceKey);

        const commonEnhancedLocationParams = {
            hash: window.location.hash,
            key: pageReloaded ? 'PAGE_RELOAD' : 'INIT',
            pathname: window.location.pathname,
            search: window.location.search,
            // Enhance it to match data standard of history.location listener-driven location updates
            action: LocationChangeActionTypes.PUSH,
            direction: NavigationDirection.FORWARD,
        };

        state.enhancedCurrentLocation = pageReloaded && storedLocationData?.currentLocation
            ? {
                ...commonEnhancedLocationParams,
                ...storedLocationData?.currentLocation,

            } : commonEnhancedLocationParams;
    },

    [CLEAR_CURRENT_USER]: () => initialState,
};

export default createReducer(initialState, handlers);
