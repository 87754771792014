import { standardPageSize } from 'config/config';
import { clientRootRoutePath, promotionsRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation, updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    createPromotion,
    createPromotionFailure,
    createPromotionSuccess,
    fetchPromotionsList,
    fetchPromotionsListSuccess,
    fetchPromotionsListFailure,
    clearPromotionsList,
} from './actions';


export const promotionsReducerName = 'promotions';
export const promotionsListsPerLocationStoreKeyName = 'promotionsListsPerLocation';


export const defaultPromotionsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};

const initialListState = {
    clientId: undefined,
    [promotionsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }

    isLoadingAddPromotion: false,
};


const initialState = { ...initialListState };


const handlers = {
    [fetchPromotionsList]: (state, action) => {
    // state.clientId = action.payload ( .clientId ?) ;
        state[promotionsListsPerLocationStoreKeyName] = updateStandardLists(state[promotionsListsPerLocationStoreKeyName], action);
    },
    [fetchPromotionsListSuccess]: (state, action) => {
    // TODO  move to epic & restore: state.promotions = markActivatedPromotions(action.payload || []);
        state[promotionsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[promotionsListsPerLocationStoreKeyName], action);
    },
    [fetchPromotionsListFailure]: (state, action) => {
        state[promotionsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[promotionsListsPerLocationStoreKeyName], action);
    },
    [clearPromotionsList]: (state, action) => {
        state[promotionsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[promotionsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[promotionsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[promotionsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [promotionsRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === promotionsReducerName
      && action.payload?.fieldName === promotionsListsPerLocationStoreKeyName
        ) {
            state[promotionsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[promotionsListsPerLocationStoreKeyName] }, action);
        }
    },


    [createPromotion]: (state) => {
        state.isLoadingAddPromotion = true;
    },
    [createPromotionFailure]: (state) => {
        state.isLoadingAddPromotion = false;
    },
    [createPromotionSuccess]: (state) => {
        state.isLoadingAddPromotion = false;
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
