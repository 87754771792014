import { HttpService } from 'services/http';


export const fetchCardIdSecure3dStatus = (cardId) => HttpService.get(`/v2/cards/${cardId}/3ds/management/status`);

export const updateCardIdSecure3dStatus = (cardId, requestPayload) => HttpService.patch(`/v2/cards/${cardId}/3ds/management/update`, requestPayload);

export const fetchCardIdSecure3dActivityLog = (cardId) => HttpService.get(`/v2/cards/${cardId}/3ds/management/logs`);


export default {
    fetchCardIdSecure3dStatus,
    updateCardIdSecure3dStatus,
    fetchCardIdSecure3dActivityLog,
};
