export const promotionsNotAssignedToGroupListSuffix = '/NOT_ASSIGNED_TO_GROUP';

export const PromotionType = Object.freeze({
    FEE_DISCOUNT: 'FEE_DISCOUNT',
    CASH_INCENTIVE: 'CASH_INCENTIVE',
});
export const PromotionTriggerEntity = Object.freeze({
    ACCOUNTS: 'ACCOUNTS',
    CARDS: 'CARDS',
    CONSUMERS: 'CONSUMERS',
});

export const PromotionTriggerType = Object.freeze({
    FIRST_TIME: 'FIRST_TIME',
    OVER_PERIOD: 'OVER_PERIOD',
    ADDITIONAL_AMOUNT: 'ADDITIONAL_AMOUNT',
    ALWAYS: 'ALWAYS',
});


export default {
    PromotionType,
    PromotionTriggerType,
    promotionsNotAssignedToGroupListSuffix,
    PromotionTriggerEntity,
};
