import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { clearAccountDetails, fetchAccountById } from 'redux/account/actions';
import { accountDetailsPerLocationStoreKeyName, accountReducerName } from 'redux/account/reducer';
import { setQueryParams } from 'redux/application/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AccountDetailsPage from './AccountDetailsPage.layout';
import { AccountDetailsPageButtonDispatchProps, AccountDetailsPageButtonStateProps } from './AccountDetailsPage.types';


const mapStateToProps = (state: RootState): AccountDetailsPageButtonStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    isLoadingDeleteAccount: state[accountReducerName].isLoadingDeleteAccount,

    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: accountReducerName,
        fieldName: accountDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
});

const mapDispatchToProps = (dispatch: Dispatch): AccountDetailsPageButtonDispatchProps => ({
    dispatchClearAccountDetails: (locationPathname) => dispatch(clearAccountDetails(locationPathname)),
    dispatchFetchAccountById: (queryParams, locationPathname) => dispatch(fetchAccountById(queryParams, locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: accountReducerName,
        fieldName: accountDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AccountDetailsPage);

