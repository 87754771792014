import { CommonFormFieldTypes } from 'constants/MultiStepsFormModel';
import { createBusinessUserPositionOptions, createBusinessUserTypeOptions, createCompanyTypesOptions } from 'utils/options-creators';
import {
    commonValidation, isValidEmail, isValidFirstNameRule, isValidLastNameRule, isValidPhoneNumberRule, requiredRule,
} from 'utils/validation-tools';


export function createFirstBusinessUserFromFieldsConfig({ t }) {
    return [
        {
            name: 'businessUser.firstName',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('common:personalFields.firstName.label'),
            placeholder: t('common:personalFields.firstName.placeholder'),
            validationRules: [
                ...commonValidation({
                    t,
                    fieldName: 'businessUser.firstName',
                    isRequired: true,
                    maxLength: 128,
                }),
                ...isValidFirstNameRule(t),
            ],
            customOptions: createCompanyTypesOptions(),
        },
        {
            name: 'businessUser.lastName',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('common:personalFields.lastName.label'),
            placeholder: t('common:personalFields.lastName.placeholder'),
            validationRules: [
                ...commonValidation({
                    t,
                    fieldName: 'businessUser.lastName',
                    isRequired: true,
                    maxLength: 128,
                }),
                ...isValidLastNameRule(t),
            ],
        },
        {
            name: 'businessUser.businessUserEmail',
            fieldType: CommonFormFieldTypes.EMAIL,
            label: t('common:personalFields.email.label'),
            placeholder: t('common:personalFields.email.placeholder'),
            validationRules: [
                ...commonValidation({
                    t,
                    fieldName: 'businessUser.businessUserEmail',
                    isRequired: true,
                }),
                ...isValidEmail(t),
            ],

        },
        {
            name: 'businessUser.businessUserPhoneNumber',
            fieldType: CommonFormFieldTypes.PHONE_NUMBER,
            label: t('common:personalFields.phoneNumber.label'),
            placeholder: t('common:personalFields.phoneNumber.placeholder'),
            validationRules: [
                ...requiredRule(t),
                ...isValidPhoneNumberRule(t),
            ],
        },

    ];
}


export function createFirstBusinessUserFromOptionalFieldsConfig({ t, isAStakeholder }) {
    return [
        {
            name: 'businessUser.stakeholderType',
            fieldType: CommonFormFieldTypes.SELECT,
            label: t('businessUsers:businessUserFields.businessUserType.text'),
            validationRules: isAStakeholder ? requiredRule(t) : undefined,
            customOptions: createBusinessUserTypeOptions({ t }),
        },
        {
            name: 'businessUser.stakeholderPositions',
            fieldType: CommonFormFieldTypes.MULTI_SELECT,
            label: t('businessUsers:businessUserFields.businessUserPosition.text'),
            validationRules: isAStakeholder ? requiredRule(t) : undefined,
            customOptions: createBusinessUserPositionOptions({ t }),
        },

    ];
}

export default { createFirstBusinessUserFromFieldsConfig, createFirstBusinessUserFromOptionalFieldsConfig };
