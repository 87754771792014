import PropTypes from 'prop-types';

import { emptyString } from './ApplicationStateModel';
import { StatusChangesChannelProp } from './CommonModel';
import { MultiStepFormCommonStepsDefinitions } from './MultiStepsFormModel';
import { SharedOffboardingStatuses } from './StatusModel';


export const CompanyStatuses = Object.freeze({
    ...SharedOffboardingStatuses,
    ACTIVE: 'ACTIVE',
    INVITED: 'INVITED',
    UNVERIFIED: 'UNVERIFIED',
    KYB_PENDING: 'KYB_PENDING',
    KYB_UPLOADED: 'KYB_UPLOADED',
    KYB_MANUAL_REVIEW: 'KYB_MANUAL_REVIEW',
    KYB_REJECTED: 'KYB_REJECTED',
    DORMANT: 'DORMANT',
    SUSPENDED: 'SUSPENDED',
    BANNED: 'BANNED',
});

export const ManigoKybStatuses = Object.freeze({
    [CompanyStatuses.ACTIVE]: CompanyStatuses.ACTIVE,
    [CompanyStatuses.SUSPENDED]: CompanyStatuses.SUSPENDED,
    [CompanyStatuses.DORMANT]: CompanyStatuses.DORMANT,
});

export const AddCompanyStepsDefinitions = Object.freeze({
    COMPANY_DETAILS: 'COMPANY_DETAILS',
    ADDRESSES: MultiStepFormCommonStepsDefinitions.ADDRESSES,
    FIRST_BUSINESS_USER: 'FIRST_BUSINESS_USER',
    SUCCESS: MultiStepFormCommonStepsDefinitions.SUCCESS,
});

export const CompanyType = {
    CHARITABLE_INCORPORATED_ORGANISATION: 'CHARITABLE_INCORPORATED_ORGANISATION',
    COMMUNITY_INTEREST_COMPANY: 'COMMUNITY_INTEREST_COMPANY',
    INDIVIDUAL: 'INDIVIDUAL',
    LIMITED_LIABILITY_PARTNERSHIP: 'LIMITED_LIABILITY_PARTNERSHIP',
    LIMITED_PARTNERSHIP: 'LIMITED_PARTNERSHIP',
    PARTNERSHIP: 'PARTNERSHIP',
    PRIVATE_LIMITED_COMPANY: 'PRIVATE_LIMITED_COMPANY',
    PUBLIC_LIMITED_COMPANY: 'PUBLIC_LIMITED_COMPANY',
    SOLE_TRADER: 'SOLE_TRADER',
};

export const CompanyStatusChangeEntryProps = PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    company_id: PropTypes.number,
    internal_comment: PropTypes.string.isRequired,
    channel: StatusChangesChannelProp.isRequired,
    bo_user_id: PropTypes.number,
    api_user_id: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
});

// TODO remove emptyString once it will be possible
export const CompanyStatusProp = PropTypes.oneOf([emptyString, ...Object.keys(CompanyStatuses)]);

export const CompanyTypeProp = PropTypes.oneOf([...Object.keys(CompanyType)]);

export const CompanyListItemProps = PropTypes.shape({
    client_id: PropTypes.number,
    client_uuid: PropTypes.string,
    company_email: PropTypes.string,
    company_id: PropTypes.number,
    company_name: PropTypes.string,
    company_registration_number: PropTypes.string,
    company_phone_number: PropTypes.string,
    company_status: CompanyStatusProp,
    company_total_risk_score: PropTypes.number,
    company_type: PropTypes.string,
    company_uuid: PropTypes.string,
    created_at: PropTypes.string,
    group: PropTypes.shape({
        group_uuid: PropTypes.string,
        group_id: PropTypes.number,
        group_name: PropTypes.string,
    }),
    updated_at: PropTypes.string,
});


export const CompanyDetailsProps = PropTypes.shape({
    ...CompanyListItemProps,
    applicant_id: PropTypes.string,
    external_user_id: PropTypes.string,
    country_of_incorporation: PropTypes.string,
    closure_start_date: PropTypes.string,
    closure_end_date: PropTypes.string,
    company_address: PropTypes.objectOf({
        id: PropTypes.number,
        country: PropTypes.string,
        city: PropTypes.string,
        address_line_1: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        address_line_2: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        state: PropTypes.string,
        zip_code: PropTypes.string,
        premise: PropTypes.string,
    }),
});

// TODO move this two after merging this branch with rest of companies changes
export const StakeholderPosition = Object.freeze({
    DIRECTOR: 'DIRECTOR',
    SHAREHOLDER: 'SHAREHOLDER',
    OTHER: 'OTHER',
});
export const StakeholderType = Object.freeze({
    UBO: 'DIRECTOR',
    SHAREHOLDER: 'SHAREHOLDER',
    REPRESENTATIVE: 'REPRESENTATIVE',
});

export default {
    CompanyListItemProps,
    CompanyStatusProp,
    CompanyType,
    CompanyTypeProp,
};
