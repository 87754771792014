import { FormInstance } from 'antd';
import { useEffect } from 'react';


const usePrepareFormModal = ({
    form,
    dispatchSetModalProps,
}: {
  form: FormInstance,
  dispatchSetModalProps: (options: any) => void
}) => {
    useEffect(() => {
        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);
};

export default usePrepareFormModal;
