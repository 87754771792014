import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearManualFeesList, fetchManualFeesList } from 'redux/manual-fees/actions';
import { manualFeesListsPerLocationStoreKeyName, manualFeesReducerName } from 'redux/manual-fees/reducer';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ManualFeesList from './ManualFeesList.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: manualFeesReducerName,
        listsByLocationStoreKeyName: manualFeesListsPerLocationStoreKeyName,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRequestNavigation: (path) => dispatch(requestNavigation(path)),

    dispatchFetchManualFeesList: (queryParams, locationPathname) => dispatch(fetchManualFeesList(queryParams, locationPathname)),
    dispatchClearManualFeesList: (locationPathname) => dispatch(clearManualFeesList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: manualFeesReducerName,
        fieldName: manualFeesListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withAccessControl,
    withTranslation('fees'),
    connect(mapStateToProps, mapDispatchToProps),
);
export default decorate(ManualFeesList);
