import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearBusinessUsersList, fetchBusinessUsersList } from 'redux/business-users/actions';
import { businessUsersReducerName, businessUsersListsPerLocationStoreKeyName } from 'redux/business-users/reducer';
import { showModal } from 'redux/modal/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import BusinessUsersList from './BusinessUsersList.layout';


const mapStateToProps = (state) => ({
    modalState: state.modal,
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: businessUsersReducerName,
        listsByLocationStoreKeyName: businessUsersListsPerLocationStoreKeyName,
    }),

});

const mapDispatchToProps = (dispatch) => ({
    dispatchShowModal: (data) => dispatch(showModal(data)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchFetchBusinessUsersList: (queryParams, locationPathname) => dispatch(fetchBusinessUsersList(queryParams, locationPathname)),
    dispatchClearBusinessUsersList: (locationPathname) => dispatch(clearBusinessUsersList(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: businessUsersReducerName,
        fieldName: businessUsersListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BusinessUsersList);

