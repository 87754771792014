import styled, { css } from 'styled-components';


const QueryParamsMetaInfoWrapper = styled.div`
  visibility: visible;
  pointer-events: initial;
  display: flex;
  gap: 10px;
  align-items: center;

 ${({ $isLoading }) => $isLoading && css`
    pointer-events: none !important;
    user-select: none !important;
 `};
 
 
 ${({ $detailsContext = false }) => $detailsContext && css`
   position: absolute;
   right: 0;
   bottom: 0;
   white-space: nowrap;
 `};
`;


export default { QueryParamsMetaInfoWrapper };
