/* eslint-disable no-unused-vars */
import { DateFullIsoString, NumericId, Uuid } from '@manigo/manigo-domain-typings';


export interface ConfirmLoginResponse {
  accessToken: string, // encoded
  refreshToken: string, // encoded
  configuration: object,
  expirationTimeOfAccessToken: DateFullIsoString,
  expirationTimeOfRefreshToken: DateFullIsoString,
  defaultApplications: object[],
  permissions: object,
}

export enum AuthErrors {
  PERMANENTLY_BLOCKED = 'Your account is locked due to multiple failed login attempts. For assistance, please contact your administrator.',
  //  if user fails login 3 times in a row backend throws this error
  TEMPORARILY_BLOCKED_FOR = 'User is temporarily locked for 3 minutes',
  // if user fails login 3 times backend throws this error
  TEMPORARILY_BLOCKED = 'User is temporarily locked',
  INVALID_CREDENTIALS = 'Invalid credentials.',
  CONFIRMATION_TOKEN_EXPIRED = 'jwt expired',
}

export enum AuthErrorCodes {
  CONFIRMATION_TOKEN_EXPIRED = 300002,
}
interface DecodedJWTTokenIdentifiers {
  id: NumericId,
  extUserUuid: Uuid,
  roleId: NumericId,
  clientId: NumericId,
  extClientUuid: Uuid,
  userId: NumericId,
  sessionUuid: Uuid,
}

interface DecodedJWTTokenUser {
  email: string,
  firstName: string,
  lastName: string,
  fullName: string,
  avatar: string | null,
  userType: number,
  status: number,
}

interface DecodedJWTTokenPreferences {
  dateFormat: string,
  timeFormat: string,
  decimalSeparator: string,
  thousandsSeparator: string,
  language: string,
}
interface DecodedJWTTokenRole {
  id: NumericId,
  isAllAccessGranted: boolean,
  isClientAccessGranted: boolean,
  isCompanyAccessGranted: boolean,
  isOwnAccessGranted: boolean,
}

interface DecodedJWTTokenDevice {
  extDeviceUuid: Uuid,
  appOs: string,
}
export interface DecodedJWTToken {
  identifiers: DecodedJWTTokenIdentifiers,
  user: DecodedJWTTokenUser,
  preferences: DecodedJWTTokenPreferences,
  role: DecodedJWTTokenRole,
  device: DecodedJWTTokenDevice,
  iat: number,
  exp: number,
}

export interface CurrentUser extends DecodedJWTTokenUser, DecodedJWTTokenIdentifiers {
  role: DecodedJWTTokenRole,
  device: DecodedJWTTokenDevice,
  userPreferences: DecodedJWTTokenPreferences,
}

