import { HttpService } from 'services/http';


export const createBusinessUserForCompanyId = (companyId, requestPayload) => HttpService.post(
    `/v3/companies/${companyId}/business-user/`,
    requestPayload,
    { noErrorHandling: true },
);

export const getCompanyIdRiskDetails = (companyId) => HttpService.get(`/v2/companies/${companyId}/risk-details`);

export const createCompanyIdRiskDetails = (companyId, requestPayload) => HttpService.post(`/v2/companies/${companyId}/risk-details`, requestPayload);

export const createCompanyAPI = (requestPayload) => HttpService.post('/v4/companies', requestPayload, { noErrorHandling: true });

export const getCompaniesApi = (queryParams) => HttpService.get('/v3/companies', queryParams);

// Legacy callback usage in newTransaction form, to be redone
export const getAllCompanies = (queryParams) => HttpService.get('/v3/companies', { params: { ...queryParams } });


export const getStakeholdersByCompanyId = (companyId, queryParams) => HttpService.get(`/v2/companies/${companyId}/stakeholders`, queryParams);

export const getCompanyIdDetails = (companyId) => HttpService.get(`/v3/companies/${companyId}`);

export const updateCompanyId = (companyId, requestPayload) => HttpService.patch(`/v4/companies/${companyId}`, requestPayload);

export const updateCompanyAddressId = (companyId, requestPayload) => HttpService.put(`/v2/company-addresses/${companyId}`, requestPayload);

export const getCompanyStatusHistoryAPI = (companyId, queryParams) => HttpService.get(`/v2/companies/${companyId}/status-history`, queryParams);

export const updateCompanyStatusAPI = (companyId, requestPayload) => HttpService.put(`/v4/companies/${companyId}/status`, requestPayload);
