import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_COMPANY_STATUS_HISTORY_LIST,
    FETCH_COMPANY_STATUS_HISTORY_LIST_FAILURE,
    FETCH_COMPANY_STATUS_HISTORY_LIST_SUCCESS,
    CLEAR_COMPANY_STATUS_LIST_HISTORY,
    UPDATE_COMPANY_STATUS,
    UPDATE_COMPANY_STATUS_SUCCESS,
    UPDATE_COMPANY_STATUS_FAILURE,
    CLEAR_RESET_FORM,
} from './actions.types';


export const fetchCompanyStatusHistoryList = createAction(FETCH_COMPANY_STATUS_HISTORY_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchCompanyStatusHistoryListSuccess = createAction(FETCH_COMPANY_STATUS_HISTORY_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchCompanyStatusHistoryListFailure = createAction(FETCH_COMPANY_STATUS_HISTORY_LIST_FAILURE);
export const clearCompanyStatusHistoryList = createAction(CLEAR_COMPANY_STATUS_LIST_HISTORY, (locationPathname) => ({ payload: { locationPathname } }));

export const updateCompanyStatus = createAction(UPDATE_COMPANY_STATUS,
    (companyId, requestPayload, locationPathname) => ({ payload: { companyId, requestPayload, locationPathname } }));
export const updateCompanyStatusSuccess = createAction(UPDATE_COMPANY_STATUS_SUCCESS);
export const updateCompanyStatusFailure = createAction(UPDATE_COMPANY_STATUS_FAILURE);

export const clearResetForm = createAction(CLEAR_RESET_FORM);
