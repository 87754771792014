/* eslint-disable class-methods-use-this */
import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse, PaginatedList } from 'models/api/http';
import { cleanNilValues } from 'utils/dataMappers';
import { camelToSnakeCase } from 'utils/string-tools';

import { WebhookListItem, FailedWebhookListItem, FailedWebhookHistoryListItem, Uuid } from '@manigo/manigo-domain-typings';


import {
    fetchWebhooksAPI,
    fetchWebhooksActionTypesAPI,
    registerWebhookAPI,
    unregisterWebhookUuid,
    updateWebhookUuid,
    fetchFailedWebhooksAPI,
    replayFailedWebhookUuid,
    replayAllFailedWebhooksAPI, fetchFailedWebhookErrorsHistoryAPI,
    fetchFailedWebhookDetailsAPI,
} from './index';


class WebhooksService {
    public fetchWebhooks(rawQueryParams): Promise<ApiResponse<PaginatedList<WebhookListItem>> | ApiError> {
        const { clientId } = rawQueryParams;

        const config = {
            params: { ...cleanNilValues({ clientId }) },
            convertSnakeCaseKeysToCamelCase: true,
        };
        return fetchWebhooksAPI(config);
    }

    public fetchFailedWebhooks(rawQueryParams): Promise<ApiResponse<PaginatedList<FailedWebhookListItem>> | ApiError> {
        const { clientId, sortBy, sortOrder, withTotal, limit, offset } = rawQueryParams;

        const config = {
            params: {
                ...cleanNilValues({
                    withTotal,
                    limit,
                    offset,
                    clientId,
                    sortBy: camelToSnakeCase(sortBy), // XXX important
                    ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
                }),
            },
            convertSnakeCaseKeysToCamelCase: true,
        };

        return fetchFailedWebhooksAPI(config);
    }

    public fetchFailedWebhookDetails(queryParams): Promise<ApiResponse | ApiError> {
        const failedWebhookUuid = queryParams?.failedWebhookUuid;
        return fetchFailedWebhookDetailsAPI(failedWebhookUuid, { convertSnakeCaseKeysToCamelCase: true });
    }


    public fetchFailedWebhookErrorsHistory(rawQueryParams: { failedWebhookUuid: Uuid; }): Promise<ApiResponse<PaginatedList<FailedWebhookHistoryListItem>> | ApiError> {
        const { failedWebhookUuid } = rawQueryParams;
        return fetchFailedWebhookErrorsHistoryAPI(failedWebhookUuid, { convertSnakeCaseKeysToCamelCase: true });
    }

    public fetchWebhooksActionTypes(): Promise<ApiResponse | ApiError> {
        return fetchWebhooksActionTypesAPI();
    }

    public registerWebhook(requestPayload): Promise<ApiResponse | ApiError> {
        return registerWebhookAPI(requestPayload);
    }

    public updateWebhook(webhookUuid, requestPayload): Promise<ApiResponse | ApiError> {
        return updateWebhookUuid(webhookUuid, requestPayload);
    }

    public unregisterWebhook(webhookUuid): Promise<ApiResponse | ApiError> {
        return unregisterWebhookUuid(webhookUuid);
    }


    public replayFailedWebhook(webhookUuid: Uuid): Promise<ApiResponse | ApiError> {
        return replayFailedWebhookUuid(webhookUuid);
    }

    public replayAllFailedWebhooks(): Promise<ApiResponse | ApiError> {
        return replayAllFailedWebhooksAPI();
    }
}

export default WebhooksService;
