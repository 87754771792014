import { createAction } from '@reduxjs/toolkit';

import { OPEN_EDIT_ADDRESS_MODAL, UPDATE_ADDRESS, UPDATE_ADDRESS_FAILURE, UPDATE_ADDRESS_SUCCESS } from './actions.types';


export const openEditAddressModal = createAction(
    OPEN_EDIT_ADDRESS_MODAL,
    ({ modalType, modalTitle, addressId, initialValues, addressType }) => ({ payload: { modalType, modalTitle, addressId, initialValues, addressType } }),
);

// eslint-disable-next-line max-params
export const updateAddress = createAction(UPDATE_ADDRESS, (addressId, requestPayload, addressType, locationPathname) => ({
    payload: {
        addressId,
        requestPayload,
        addressType,
        locationPathname,
    },
}));
export const updateAddressSuccess = createAction(UPDATE_ADDRESS_SUCCESS,
    (responseData, addressType, locationPathname) => ({ payload: { responseData, addressType, locationPathname } }));
export const updateAddressFailure = createAction(UPDATE_ADDRESS_FAILURE, (addressType) => ({ payload: addressType }));

export default {
    openEditAddressModal,
    updateAddress,
    updateAddressSuccess,
    updateAddressFailure,
};
