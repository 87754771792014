/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { fetchCountriesAPI } from './index';


class CountriesService {
    public fetchCountries(): Promise<ApiResponse | ApiError> {
        return fetchCountriesAPI();
    }
}

export default CountriesService;
