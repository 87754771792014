import { SortDirections } from 'constants/ApplicationStateModel';
import { globalLocation } from 'constants/NavigationModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCountriesList, fetchCountriesList, fetchCountriesListFailure, fetchCountriesListSuccess } from 'redux/countries/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';


import { createReducer } from '@reduxjs/toolkit';


export const countriesReducerName = 'countries';
export const countriesListsPerLocationStoreKeyName = 'countriesListsPerLocation';


export const defaultCountriesListQueryParams = {
    limit: 20,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};

const initialState = { [countriesListsPerLocationStoreKeyName]: { [globalLocation]: { queryParams: defaultCountriesListQueryParams } } };

const handlers = {

    [fetchCountriesList]: (state, action) => {
        state[countriesListsPerLocationStoreKeyName] = updateStandardLists(state[countriesListsPerLocationStoreKeyName], action);
    },
    [fetchCountriesListSuccess]: (state, action) => {
        state[countriesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[countriesListsPerLocationStoreKeyName], action);
    },
    [fetchCountriesListFailure]: (state, action) => {
        state[countriesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[countriesListsPerLocationStoreKeyName], action);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === countriesReducerName
      && action.payload?.fieldName === countriesListsPerLocationStoreKeyName
        ) {
            state[countriesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[countriesListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCountriesList]: (state, action) => {
        state[countriesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[countriesListsPerLocationStoreKeyName], action.payload?.locationPathname);
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
