import { createBusinessUserDetailsPath } from 'config/routes.helpers';
import { showErrorToast, showSuccessToast } from 'redux/application/actions';
import { FETCH_BUSINESS_USER_DETAILS, UPDATE_BUSINESS_USER } from 'redux/business-user/action.types';
import { fetchBusinessUserDetailsFailure, fetchBusinessUserDetailsSuccess, updateBusinessUserFailure, updateBusinessUserSuccess } from 'redux/business-user/actions';
import { fetchBusinessUsersList } from 'redux/business-users/actions';
import { createCreateBusinessUserErrorMessage } from 'redux/business-users/epics.helpers';
import { businessUsersReducerName, businessUsersListsPerLocationStoreKeyName } from 'redux/business-users/reducer';
import { hideModal, setModalProps } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


export const onFetchBusinessUserDetails = (action$, state$, { businessUsers }) => action$.pipe(
    ofType(FETCH_BUSINESS_USER_DETAILS),
    switchMap(({ payload }) => from(businessUsers.getBusinessUserDetails(payload.queryParams.businessUserId)).pipe(
        switchMap((response) => of(fetchBusinessUserDetailsSuccess(response.data, payload.locationPathname))),
        catchError(() => of(fetchBusinessUserDetailsFailure(payload.locationPathname))),
    )),
);

export const onUpdateBusinessUser = (action$, state$, { businessUsers, i18n }) => action$.pipe(
    ofType(UPDATE_BUSINESS_USER),
    switchMap(({ payload }) => from(businessUsers.updateBusinessUser(payload.formData))
        .pipe(
            switchMap((response) => {
                // eslint-disable-next-line max-len
                const currentBusinessUsersListQueryParams = state$.value[businessUsersReducerName][businessUsersListsPerLocationStoreKeyName]?.[payload.listOfOriginLocationPathname]?.queryParams;

                return of(
                    updateBusinessUserSuccess(response.data, createBusinessUserDetailsPath(payload.formData.businessUserId)),
                    ...(currentBusinessUsersListQueryParams ? [fetchBusinessUsersList(currentBusinessUsersListQueryParams, payload.listOfOriginLocationPathname)] : []),
                    showSuccessToast(i18n.t('companies:actionMessages.updateBusinessUserSuccess')),
                    hideModal(),
                );
            }),
            catchError((error) => of(
                showErrorToast(createCreateBusinessUserErrorMessage(i18n.t, error)),
                updateBusinessUserFailure(),
                setModalProps({
                    confirmLoading: false,
                    cancelButtonProps: { disabled: false },
                }),
            )),
        )),
);


export default [
    onUpdateBusinessUser,
    onFetchBusinessUserDetails,
];
