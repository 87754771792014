import { extractBaseURLWithoutActiveTab } from 'components/common/organisms/StandardTabsConnectedToUrl/StandardTabsConnectedToUrl.helpers';
import { defaultQueryParamsMeta, updateQueryParamsMeta } from 'constants/ApplicationStateModel';
import { navigationReducerName } from 'redux/navigation/reducer';


export const standardEntityDetailsStateDataPlaceholder = {
    entityDetails: undefined,
    isLoadingDetails: false,
    // eslint-disable-next-line object-curly-newline
    queryParams: {
    // id: undefined, // one of those
    // uuid: undefined,
    // ext_id: undefined,
    // eslint-disable-next-line object-curly-newline
    },
    queryParamsMeta: defaultQueryParamsMeta,
};

export function getStandardEntityDetailsData({
    state,
    reducerName,
    fieldName,
    baseURLSegmentsCount = 2,
    customLocation,
}:{
  state,
  reducerName,
  fieldName: string,
  baseURLSegmentsCount?: number,
  customLocation?: string

}) {
    const location = customLocation || state?.[navigationReducerName]?.enhancedCurrentLocation?.pathname;
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(location, baseURLSegmentsCount);
    const entityDetailsData = state?.[reducerName]?.[fieldName][baseUrlWithoutTabsSlugs];
    return entityDetailsData;
}

export function updateStandardEntityDetails(stateEntityDetailsKey = {}, action) {
    const locationKey = action.payload?.locationPathname;
    const queryParamsMeta = updateQueryParamsMeta();
    return {
        ...stateEntityDetailsKey,
        [locationKey]: {
            ...stateEntityDetailsKey[locationKey],
            queryParamsMeta,
            isLoadingDetails: true,
        },
    };
}

export function updateStandardEntityDetailsOnSuccess(
    stateEntityDetailsKey = {},
    action,
) {
    const queryParamsMeta = updateQueryParamsMeta();
    const locationKey = action.payload?.locationPathname;

    if (locationKey) {
        return {
            ...stateEntityDetailsKey,
            [locationKey]: {
                ...stateEntityDetailsKey[locationKey],
                queryParamsMeta,
                isLoadingDetails: false,
                entityDetails: {
                    ...stateEntityDetailsKey[locationKey]?.entityDetails,
                    ...action.payload.responsePayload,
                },
            },
        };
    }
    return stateEntityDetailsKey;
}

export function updateStandardEntityDetailsOnFailure(stateEntityDetailsKey = {}, action) {
    const queryParamsMeta = updateQueryParamsMeta(true);
    const locationKey = action.payload?.locationPathname;

    return {
        ...stateEntityDetailsKey,
        [locationKey]: {
            ...stateEntityDetailsKey[locationKey],
            queryParamsMeta,
            isLoadingDetails: false,
            entityDetails: null,
        },
    };
}


export function updateStandardEntityDetailsDataQueryParams(
    entityDetailsByLocationStoreData = {},
    action,
) {
    const locationPathname = action?.payload?.locationPathname;
    const listCurrentData = entityDetailsByLocationStoreData[action?.payload?.locationPathname];


    if (locationPathname) {
        const currentData = listCurrentData || standardEntityDetailsStateDataPlaceholder;

        const clearedState = {
            ...entityDetailsByLocationStoreData,
            [locationPathname]: {
                ...currentData,
                queryParams: {
                    ...currentData?.queryParams,
                    ...action.payload?.queryParams,
                },
            },
        };
        return clearedState;
    }

    return entityDetailsByLocationStoreData;
}


export function clearStandardEntityDetails(
    state = {},
    entityDetailsByLocationStoreKeyName = '',
    action,
) {
    const { [action.payload?.locationPathname]: thisLocation, ...rest } = state[entityDetailsByLocationStoreKeyName];
    return { ...rest };
}

export function updateStandardEntityDetailsOnCleanup(state, locationKey:string) {
    const existingData = state?.[locationKey];
    if (existingData) {
        const {
            [locationKey]: omitKey,
            ...rest
        } = state;
        return rest;
    }

    return state;
}


export default {
    standardEntityDetailsStateDataPlaceholder,
    // map state to props store getter
    getStandardEntityDetailsData,

    // standard 3 actions for API call + clear
    updateStandardEntityDetails,
    updateStandardEntityDetailsOnSuccess,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnCleanup,

    // standard query params
    updateStandardEntityDetailsDataQueryParams,
    clearStandardEntityDetails,
};
