import { CLEAR_APPLICATIONS_LIST, FETCH_APPLICATIONS_LIST, FETCH_APPLICATIONS_LIST_FAILURE, FETCH_APPLICATIONS_LIST_SUCCESS } from 'redux/applications/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchApplicationsList = createAction(FETCH_APPLICATIONS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchApplicationsListSuccess = createAction(FETCH_APPLICATIONS_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchApplicationsListFailure = createAction(FETCH_APPLICATIONS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearApplicationsList = createAction(CLEAR_APPLICATIONS_LIST, (locationPathname) => ({ payload: { locationPathname } }));

