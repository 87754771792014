import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';


import ClientRenderer from './ClientRenderer';


const mapStateToProps = (state) => ({
    clients: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});

const decorate = compose(connect(mapStateToProps));

export default decorate(ClientRenderer);
