import { createAction } from '@reduxjs/toolkit';

/**
 *
 * @param {*} payload { modalType: 'Client-Edit-Modal', modalProps: {} }
 * Action which shows modal
 */
export const showModal = createAction('modal/show', ({ modalType, modalProps }) => ({
    payload: {
        modalType,
        modalProps,
    },
}));

/**
 * Action which hides modal
 */
export const hideModal = createAction('modal/hide');

/**
 * Set/Update modal props - use this method to se title, and buttons props
 */
export const setModalProps = createAction('modal/setProps', (modalProps) => ({ payload: modalProps }));
export const SHOW_VIEW_JSON_MODAL = 'SHOW_VIEW_JSON_MODAL';
export const showViewJsonModal = createAction(SHOW_VIEW_JSON_MODAL, (dataToDisplay, modalTitle) => ({
    payload: {
        dataToDisplay,
        modalTitle,
    },
}));
