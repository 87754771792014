import PropTypes from 'prop-types';


export const CurrencyObjectProps = PropTypes.shape({
    beneficiary: PropTypes.bool,
    business_account_enabled: PropTypes.bool,
    full_name: PropTypes.string, // "Pound Sterling"
    icon: PropTypes.string, //
    short: PropTypes.string, // "GBP"
    symbol: PropTypes.string, // "£"
    wallet: PropTypes.bool, // "£"
});

export const CurrenciesListProp = PropTypes.arrayOf(CurrencyObjectProps);


export default {
    CurrencyObjectProps,
    CurrenciesListProp,
};
