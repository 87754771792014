import StandardListTable from 'components/common/organisms/StandardListTable';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultGroupsListQueryParams } from 'redux/groups/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createGroupsTableColumns from './createTableColumns';


function GroupsList({
    // own props
    clientId,
    feeUuid,
    promotionUuid,
    showAddButton,

    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    accessControl,
    dispatchFetchGroupsList,
    dispatchClearGroupsList,
    dispatchSetQueryParams,
    dispatchRequestNavigation,
    dispatchOpenCreateNewGroupModal,
    t,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        ...(clientId > 0 ? { clientId } : {}),
        ...(feeUuid?.length > 0 ? { feeUuid } : {}),
        ...(promotionUuid?.length > 0 ? { promotionUuid } : {}),
    };


    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };


    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultGroupsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchGroupsList,
        dispatchClearItemsList: dispatchClearGroupsList,
        dispatchSetQueryParams,
    });


    // 4a. list specific stuff - local search, local sorting is in table configuration, see 'createGroupsTableColumns'
    const filteredGroups = useMemo(
        () => {
            if (listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((group) => searchCaseInsensitive(group.name, listData?.queryParams?.search));
            }

            return listData?.items || [];
        }, [listData],
    );

    // 4b. list specific stuff - action buttons
    const buttons = showAddButton && accessControl.hasPermission(PN.GROUPS.CREATE)
        ? [{
            type: 'primary',
            text: 'Add group',
            dataTestId: 'add-group-button',
            icon: <PlusOutlined />,
            onClick: () => dispatchOpenCreateNewGroupModal({ clientId, locationPathname: enhancedCurrentLocation.pathname }),
        }]
        : undefined;


    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchGroupsList({ ...listData?.queryParams }, enhancedCurrentLocation.pathname)}
            items={filteredGroups}
            totalItemsCount={filteredGroups.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createGroupsTableColumns({
                accessControl,
                t,
                queryParams: listData?.queryParams,
                clientId,
                dispatchRequestNavigation,
            })}
            filtersButtonsConfig={buttons}
        />
    );
}

GroupsList.propTypes = {
    accessControl: AccessControlProps,
    t: PropTypes.func.isRequired,
    // mapped props
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    dispatchRequestNavigation: PropTypes.func.isRequired,
    dispatchFetchGroupsList: PropTypes.func.isRequired,
    dispatchOpenCreateNewGroupModal: PropTypes.func.isRequired,
    clientId: PropTypes.number,
    feeUuid: PropTypes.string,
    promotionUuid: PropTypes.string,
    showAddButton: PropTypes.bool,
    dispatchSetQueryParams: PropTypes.func.isRequired,
};


export default GroupsList;
