import PropTypes from 'prop-types';

import ModalName from './Modals';


const CreateModalProps = (props) => ({
    open: PropTypes.bool,
    modalType: PropTypes.oneOf(Object.keys(ModalName)),
    modalProps: PropTypes.shape({
        title: PropTypes.string,
        okButtonProps: PropTypes.shape({ disabled: PropTypes.bool }),
        ...props,
    }),
});

export default CreateModalProps;
