/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';
import { cleanNilValues } from 'utils/dataMappers';
import { sortingDirectionMapper } from 'utils/string-tools';

import {
    addNewClientApi, updateClientId, getClientIdDetails,
    createClientIdProduct, getClientIdProducts,
    getClientsApi,
    getClientIdCards,
    getClientIdCardMatrixes,
    updateClientIdAnnouncement,
    createClientIdRiskDetails,
    getClientIdRiskDetails,
    getClientIdMigrations,
    getClientIdMigrationReport,
    getClientIdMigrationIdResult,
    uploadClientIdMigrationCsv,
} from './index';


class ClientsService {
    public addNewClient(requestPayload): Promise<ApiResponse | ApiError> {
        return addNewClientApi(requestPayload);
    }

    public updateClient(clientId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateClientId(clientId, requestPayload);
    }

    public updateClientAnnouncement(clientId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateClientIdAnnouncement(clientId, requestPayload);
    }

    public getClientCards(clientId, queryParams): Promise<ApiResponse | ApiError> {
        return getClientIdCards(clientId, queryParams);
    }

    public getClientCardMatrixes(clientId): Promise<ApiResponse | ApiError> {
        return getClientIdCardMatrixes(clientId);
    }

    public getClients(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { withTotalCount, withSkipImages, enforcedLimit } = rawQueryParams;
        const query = {
            limit: enforcedLimit,
            ...withTotalCount,
            ...withSkipImages,
        };

        return getClientsApi({ params: query });
    }


    public getClientDetails(clientId): Promise<ApiResponse | ApiError> {
        const queryParams = {
            params: {
                withAddress: true,
                withAnnouncement: true,
                withCardMatrix: true,
            },
        };
        return getClientIdDetails(clientId, queryParams);
    }


    public getClientProducts(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, ...rest } = rawQueryParams;
        const queryParams = cleanNilValues(rest);
        return getClientIdProducts(clientId, { params: queryParams });
    }

    public createClientProduct(requestPayload): Promise<ApiResponse | ApiError> {
        const { clientId, ...rest } = requestPayload;
        return createClientIdProduct(clientId, rest);
    }

    public createClientRiskDetails(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, ...rest } = rawQueryParams;
        return createClientIdRiskDetails(clientId, rest);
    }

    public getClientRiskDetails(clientId): Promise<ApiResponse | ApiError> {
        return getClientIdRiskDetails(clientId);
    }

    public getClientMigrations(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, ...rest } = rawQueryParams;
        const queryParams = {
            ...rest,
            sort_order: sortingDirectionMapper(rest.sort_order),
        };

        return getClientIdMigrations(clientId, queryParams);
    }


    public getClientMigrationReport(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, reportType } = rawQueryParams;

        const queryParams = { params: { type: reportType } };

        return getClientIdMigrationReport(clientId, queryParams);
    }

    public getClientMigrationResult(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, migrationType, migrationId, migrationStatus } = rawQueryParams;

        const queryParams = { params: { status: migrationStatus } };

        return getClientIdMigrationIdResult({
            clientId,
            migrationId,
            migrationType,
            queryParams,
        });
    }

    public uploadClientMigrationCsv(rawQueryParams): Promise<ApiResponse | ApiError> {
        const {
            clientId,
            migrationType,
            blob,
        } = rawQueryParams;

        return uploadClientIdMigrationCsv({
            clientId,
            migrationType,
            blob,
        });
    }
}

export default ClientsService;

