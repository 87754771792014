import svgIllustrations from 'assets/images/illustrations/svgIllustrations';
import svgCountries from 'assets/images/svgCountries';


export const attributionsList = [
    {
    // eslint-disable-next-line spellcheck/spell-checker
        name: 'Flag of Bouvet Island - Circle',
        attributionURL: 'https://vectorflags.com/bouvet-island/bv-circle-01',
        assetSrc: svgCountries.bv,
    },
    {
        name: 'Vector Flag of Antarctica - Circle',
        attributionURL: 'https://vectorflags.com/antarctica/aq-circle-01',
        assetSrc: svgCountries.aq,
    },
    {
        name: 'Empowered woman',
        attributionURL: 'TODO: Where did I found this one?',
        assetSrc: svgIllustrations.successWoman,
    },
];


export default { attributionsList };
