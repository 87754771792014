import TabSubHeading from 'components/common/atoms/TabSubHeading';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { cleanNilValues } from 'utils/dataMappers';
import { getRelativeTimeFromNowString } from 'utils/date-time-tools';

import { QuestionCircleOutlined, RedoOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Popover } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { map, timer } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


import Styled from './QueryParamsMetaInfo.styled';


function QueryParamsMetaInfo({
    detailsContext = false,
    queryParams,
    isLoading,
    queryParamsMeta,
    onListRefresh,
    t,
}) {
    const [observable, setObservable] = useState();
    const [relativeTimeFromNowString, setRelativeTimeFromNowString] = useState();

    const createUpdater = () => {
        if (observable?.unsubscribe) {
            observable?.unsubscribe();
        }

        if (queryParamsMeta?.timestamp) {
            setObservable(timer(100, 1000).pipe(
                map(() => getRelativeTimeFromNowString(queryParamsMeta?.timestamp)),
                distinctUntilChanged(),
            ).subscribe((data) => {
                setRelativeTimeFromNowString(data);
            }));
        }
    };


    const filterQueryParams = (queryParams) => {
        const { clientId, ...rest } = cleanNilValues(queryParams);

        return {
            ...rest,
            ...(clientId > 0 ? { clientId } : undefined),
        };
    };

    useEffect(() => {
        createUpdater();
    }, [queryParamsMeta]);

    useEffect(() => () => {
        if (observable?.unsubscribe) {
            observable?.unsubscribe();
        }
    }, []);


    const refreshButton = (
        <StandardTooltip title={t('Click to re-fetch the data')}>
            <Button
                type="default"
                disabled={isLoading}
                onClick={onListRefresh}
            >
                <RedoOutlined />
            </Button>
        </StandardTooltip>
    );


    const buttons = (
        <>
            {detailsContext && refreshButton}

            <StandardTooltip
                title={t('Click to see detailed info about current query prams')}
            >
                <Popover
                    content={(
                        <>
                            {queryParams && (
                                <>
                                    <TabSubHeading text={t('Query Params')} />
                                    <pre>{JSON.stringify(filterQueryParams(queryParams), null, 2)}</pre>

                                    {!detailsContext && (
                                        <Alert
                                            style={{ maxWidth: '350px' }}
                                            showIcon
                                            type="info"
                                            description={(
                                                <StandardTranslationWithMarkup
                                                    translationKey={'common:queryParamsMetaInfo.infoTooltip'}
                                                    namespace="common"
                                                    t={t}
                                                />
                                            )}
                                        />
                                    )}
                                </>
                            )}

                            <TabSubHeading text={t('Query Params Meta')} />
                            <pre>
                                {JSON.stringify({
                                    pathname: queryParamsMeta?.location?.pathname,
                                    timestamp: queryParamsMeta?.timestamp,
                                }, null, 2)}
                            </pre>
                        </>
                    )}
                    trigger="click"
                >
                    <Button
                        type="default"
                        disabled={isLoading}
                    >
                        <QuestionCircleOutlined />
                    </Button>
                </Popover>
            </StandardTooltip>

            {!detailsContext && refreshButton}
        </>
    );


    const relativeTime = (
        relativeTimeFromNowString && (
            <StandardTooltip
                title={(
                    <>
                        {`${detailsContext ? 'Details' : 'List'} fetched at `}
                        <StandardValue value={queryParamsMeta?.timestamp} valueType="DATE" showTime />
                    </>
                )}
            >
                <>
                    {`${detailsContext ? 'Details' : 'List'} fetched ${relativeTimeFromNowString} `}
                    <InfoCircleOutlined />
                </>
            </StandardTooltip>
        )
    );

    const content = (
        <Styled.QueryParamsMetaInfoWrapper $detailsContext={detailsContext} $isLoading={isLoading}>
            {detailsContext
                ? (
                    <>
                        {relativeTime}
                        {buttons}
                    </>
                )
                : (
                    <>
                        {buttons}
                        {relativeTime}
                    </>
                )}
        </Styled.QueryParamsMetaInfoWrapper>
    );

    return content;
}

QueryParamsMetaInfo.propTypes = {
    queryParams: CommonQueryParamsProps,
    queryParamsMeta: CommonQueryParamsMetaProps,
    isLoading: PropTypes.bool,
    detailsContext: PropTypes.bool,
    onListRefresh: PropTypes.func,
};

export default QueryParamsMetaInfo;
