import Secure3dActionTag from 'components/common/atoms/tags/Secure3dActionTag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';

import PropTypes from 'prop-types';
import React from 'react';


function createSecure3dActivityLogsTableColumns(t, dispatchShowViewJsonModal) {
    return [
        {
            title: t('cardDetails.tabs.3dSecure.table.headers.timestamp'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (_, record) => <StandardValue value={record.enrolled_at || record.unenrolled_at} valueType="DATE" />,
        },
        {
            title: t('cardDetails.tabs.3dSecure.table.headers.action'),
            dataIndex: '-',
            key: 'action',
            align: 'center',
            render: (text, record) => <Secure3dActionTag t={t} logRecord={record} />,
        },
        {
            title: t('cardDetails.tabs.3dSecure.table.headers.acsToken'),
            dataIndex: 'acs_token',
            key: 'acs_token',
            render: (token) => <StandardValue value={token} />,
        },
        {
            title: t('cardDetails.tabs.3dSecure.table.headers.requestContent'),
            dataIndex: '',
            align: 'center',
            key: 'see-request',
            render: (balance, record) => viewButtonRenderer({
                record,
                onClick: (event, record) => dispatchShowViewJsonModal(record, t('cardDetails.tabs.3dSecure.table.filedValues.see-request.modalTitle')),
                tooltipContent: t('cardDetails.tabs.3dSecure.table.filedValues.see-request.tooltip'),
                dataTestId: 'view-request',
            }),
        },
    ];
}

createSecure3dActivityLogsTableColumns.propTypes = { t: PropTypes.func.isRequired };


export default createSecure3dActivityLogsTableColumns;
