import { MultiStepFormCommonStepsDefinitions } from './MultiStepsFormModel';


export const AddClientStepsDefinitions = Object.freeze({
    PARTNER_DETAILS: 'PARTNER_DETAILS',
    ADDRESSES: MultiStepFormCommonStepsDefinitions.ADDRESSES,
    SETTINGS: 'SETTINGS',
    ACCOUNT_PRODUCTS: 'ACCOUNT_PRODUCTS',
    SUCCESS: MultiStepFormCommonStepsDefinitions.SUCCESS,
});


export const AddClientAddressesAvailableStepsDefinitions = Object.freeze({
    COMPANY_ADDRESS: 'COMPANY_ADDRESS',
    SHIPPING_ADDRESS: 'SHIPPING_ADDRESS',
    BILLING_ADDRESS: 'BILLING_ADDRESS',
});

export const AddClientAccountsProductsAvailableSubStepsDefinitions = Object.freeze({
    BASE_ACCOUNTS: 'BASE_ACCOUNTS',
    COMPANY_ACCOUNTS: 'COMPANY_ACCOUNTS',
    PERSONAL_ACCOUNTS: 'PERSONAL_ACCOUNTS',
});

// XXX order DOES matter
export const AddClientAvailableSteps = Object.freeze([
    AddClientStepsDefinitions.PARTNER_DETAILS,
    AddClientStepsDefinitions.ADDRESSES,
    AddClientStepsDefinitions.SETTINGS,
    AddClientStepsDefinitions.ACCOUNT_PRODUCTS,
    AddClientStepsDefinitions.SUCCESS,
]);

// XXX order DOES matter
export const AddSubClientAvailableSteps = Object.freeze([
    AddClientStepsDefinitions.PARTNER_DETAILS,
    AddClientStepsDefinitions.ADDRESSES,
    AddClientStepsDefinitions.SUCCESS,
]);

// XXX order DOES matter
export const AddClientAddressesAvailableSubSteps = Object.freeze([
    AddClientAddressesAvailableStepsDefinitions.COMPANY_ADDRESS,
    AddClientAddressesAvailableStepsDefinitions.SHIPPING_ADDRESS,
    AddClientAddressesAvailableStepsDefinitions.BILLING_ADDRESS,
]);


// XXX order DOES matter
export const AddClientAccountsProductsAvailableSubSteps = Object.freeze([
    AddClientAccountsProductsAvailableSubStepsDefinitions.BASE_ACCOUNTS,
    AddClientAccountsProductsAvailableSubStepsDefinitions.COMPANY_ACCOUNTS,
    AddClientAccountsProductsAvailableSubStepsDefinitions.PERSONAL_ACCOUNTS,
]);


export const AddClientFormVariants = Object.freeze({
    ADD_PARTNER: 'ADD_PARTNER',
    ADD_SUB_PARTNER: 'ADD_SUB_PARTNER',
});
export const EditClientFormVariants = Object.freeze({
    EDIT_PARTNER: 'EDIT_PARTNER',
    EDIT_PARTNER_SUPER_ADMIN: 'EDIT_PARTNER_SUPER_ADMIN', // vs EDIT_SETTINGS ?
});


export default {
    AddClientFormVariants,
    AddClientStepsDefinitions,
    AddClientAddressesAvailableStepsDefinitions,
    AddClientAccountsProductsAvailableSubStepsDefinitions,
    EditClientFormVariants,

    AddClientAvailableSteps,
    AddSubClientAvailableSteps,

    AddClientAddressesAvailableSubSteps,
    AddClientAccountsProductsAvailableSubSteps,
};
