import { currentStepNumberInAvailableSteps, getLastStepExceptSuccessFromAvailableSteps } from 'redux/multi-steps-form/epics.helpers';

import { Steps } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import { calculateSubStepsProgress, createSteps } from './MultiStepFormStepsIndicator.helpers';
import Styled from './MultiStepFormStepsIndicator.styled';


function MultiStepFormStepsIndicator({
    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,
    isLoading,
    stepsConfiguration = [],
    availableSteps = [],
    availableSubSteps = {},
    t,
}) {
    const lastStepExceptSuccess = useMemo(
        () => getLastStepExceptSuccessFromAvailableSteps(availableSteps),
        [availableSteps],
    );

    const currentStepNumber = useMemo(
        () => currentStepNumberInAvailableSteps(availableSteps, currentMultiStepsFormStep),
        [currentMultiStepsFormStep, availableSteps],
    );

    const subStepPercentage = useMemo(
        () => (stepsConfiguration.find((stepConfig) => stepConfig.stepName === currentMultiStepsFormStep)?.subStepsConfiguration?.length > 0
            ? calculateSubStepsProgress({
                stepName: currentMultiStepsFormStep,
                availableSubSteps,
                currentMultiStepsFormSubStep,
            })
            : undefined),
        [currentMultiStepsFormSubStep, currentMultiStepsFormSubStep, availableSteps, stepsConfiguration],
    );

    const items = useMemo(() => createSteps({
        stepsConfiguration,
        isLoading,
        subStepPercentage,
        currentMultiStepsFormStep,
        lastStepExceptSuccess,
        t,
    }), [isLoading, stepsConfiguration, subStepPercentage, currentMultiStepsFormStep, lastStepExceptSuccess]);

    useEffect(() => {
        if (items) {
            const elements = document.getElementsByClassName('ant-steps-item');

            if (!elements) return;

            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < elements.length; index++) {
                const antStepItem = elements[index];
                if (antStepItem.querySelector('.ant-steps-divider-container')) return;

                const appendElement = document.createElement('div');
                appendElement.className = 'ant-steps-divider-container';
                appendElement.style = "display: 'none'";
                elements[index].appendChild(appendElement);
            }
        }
    }, [items]);
    return (
        <Styled.MainStepsWrapper>
            <Steps
                current={currentStepNumber}
                percent={subStepPercentage}
                items={items}
            />
        </Styled.MainStepsWrapper>
    );
}

MultiStepFormStepsIndicator.propTypes = {
    availableSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
    isLoading: PropTypes.bool,
    currentMultiStepsFormSubStep: PropTypes.string,
    t: PropTypes.func,
};


export default MultiStepFormStepsIndicator;
