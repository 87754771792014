import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';
import {
    banUser,
    clearUserDetails,
    deleteUser,
    fetchUserDetails,
    resendConfirmationEmail,
} from 'redux/user/actions';
import { userDetailsPerLocationStoreKeyName, userReducerName } from 'redux/user/reducer';
import { openAddEditUserModal } from 'redux/users/actions';


import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import UserDetails from './UserDetails.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,

    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: userReducerName,
        fieldName: userDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchBanUser: (queryParams, locationPathname) => dispatch(banUser(queryParams, locationPathname)),
    dispatchDeleteUser: (queryParams) => dispatch(deleteUser(queryParams)),
    dispatchFetchUserDetails: (queryParams, locationPathname) => dispatch(fetchUserDetails(queryParams, locationPathname)),
    dispatchClearUserDetails: (locationPathname) => dispatch(clearUserDetails(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: userReducerName,
        fieldName: userDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchOpenAddEditUserModal: (configData) => dispatch(openAddEditUserModal(configData)),
    dispatchResendConfirmationEmail: (queryParams) => dispatch(resendConfirmationEmail(queryParams)),
});


const decorate = compose(
    withTranslation('users'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UserDetails);
