import { CommonFormFieldTypes, MultiStepFormStepChangeDirections } from 'constants/MultiStepsFormModel';
import { nestifyObject } from 'utils/object-tools';
import {
    isMatchingGivenRegexRule,
    isValidEmail,
    isValidJsonStringRule,
    isValidPhoneNumberRule,
    isValidURLRule,
    maxLengthRule,
    numberGreaterThan,
    numberSmallerThan,
    requiredRule,
    whitespaceRule,
} from 'utils/validation-tools';


function mapBackendFieldTypeAndMetaToFrontendType(fieldConfig) {
    const isStringType = fieldConfig?.field_type === 'STRING';
    const isSelectType = fieldConfig?.field_type === 'ENUM';

    const handlers = [
        {
            predicate: () => fieldConfig?.metadata?.is_hidden,
            handler: () => ({ fieldType: CommonFormFieldTypes.HIDDEN }),
        },
        {
            predicate: () => isStringType
        && fieldConfig?.metadata?.is_multiline
        && fieldConfig?.metadata?.validation_rules?.is_valid_json,
            handler: () => ({ fieldType: CommonFormFieldTypes.JSON }),
        },
        {
            predicate: () => isStringType && fieldConfig?.metadata?.is_multiline,
            handler: () => ({ fieldType: CommonFormFieldTypes.TEXT_MULTI_LINE }),
        },
        {
            predicate: () => isStringType && fieldConfig?.metadata?.is_email,
            handler: () => ({ fieldType: CommonFormFieldTypes.EMAIL }),
        },
        {
            predicate: () => isStringType && fieldConfig?.metadata?.is_password,
            handler: () => ({ fieldType: CommonFormFieldTypes.PASSWORD }),
        },
        {
            predicate: () => isStringType && fieldConfig?.metadata?.is_color,
            handler: () => ({ fieldType: CommonFormFieldTypes.COLOR }),
        },

        {
            predicate: () => isStringType && fieldConfig?.metadata?.is_url,
            handler: () => ({ fieldType: CommonFormFieldTypes.URL }),
        },

        {
            predicate: () => isStringType && fieldConfig?.metadata?.is_multi_value,
            handler: () => ({ fieldType: CommonFormFieldTypes.TEXT_MULTI_VALUES }),
        },
        {
            predicate: () => isStringType,
            handler: () => ({ fieldType: CommonFormFieldTypes.TEXT }),
        },

        {
            predicate: () => isSelectType && fieldConfig?.metadata?.is_multi_value,
            handler: () => ({ fieldType: CommonFormFieldTypes.MULTI_SELECT }),
        },

        {
            predicate: () => fieldConfig?.metadata?.is_client_select,
            handler: () => ({ fieldType: CommonFormFieldTypes.SELECT_CLIENT }),
        },

        {
            predicate: () => isSelectType && fieldConfig?.metadata?.is_multi_value,
            handler: () => ({ fieldType: CommonFormFieldTypes.MULTI_SELECT }),
        },

        {
            predicate: () => isSelectType,
            handler: () => ({ fieldType: CommonFormFieldTypes.SELECT }),
        },
        {
            predicate: () => isStringType
        && fieldConfig?.metadata?.is_multiline
        && fieldConfig?.metadata?.validation_rules?.is_valid_json,
            handler: () => ({ fieldType: CommonFormFieldTypes.JSON }),
        },

        {
            predicate: () => true,
            handler: () => ({ fieldType: fieldConfig?.field_type }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


function createValidationRules(fieldConfig, fieldName, t) {
    const handlers = [
        {
            predicate: () => fieldConfig?.metadata?.validation_rules?.is_valid_json,
            handler: () => isValidJsonStringRule(fieldName, t),
        },
        {
            predicate: () => fieldConfig?.field_type === 'STRING'
      && !fieldConfig?.metadata?.is_multi_value,
            handler: () => whitespaceRule(fieldName, t),
        },
        {
            predicate: () => fieldConfig?.metadata?.validation_rules?.max_length,
            handler: () => maxLengthRule(fieldConfig?.metadata?.validation_rules?.max_length, t),
        },
        {
            predicate: () => fieldConfig?.metadata?.validation_rules?.min_length,
            handler: () => maxLengthRule(fieldConfig?.metadata?.validation_rules?.max_length, t),
        },
        {
            predicate: () => fieldConfig?.metadata?.validation_rules?.min_value,
            handler: () => numberGreaterThan(fieldName, t, fieldConfig?.metadata?.validation_rules?.min_value),
        },
        {
            predicate: () => fieldConfig?.metadata?.validation_rules?.max_value,
            handler: () => numberSmallerThan({
                fieldName,
                t,
                maxValue: fieldConfig?.metadata?.validation_rules?.max_value,
            }),
        },
        {
            predicate: () => fieldConfig?.metadata?.is_email,
            handler: () => isValidEmail(t),
        },
        {
            predicate: () => fieldConfig?.metadata?.is_url,
            handler: () => isValidURLRule(fieldName, t, true),
        },
        {
            predicate: () => fieldConfig?.metadata?.is_phone_number,
            handler: () => isValidPhoneNumberRule(t),
        },
        {
            predicate: () => fieldConfig?.metadata?.validation_rules?.regex
      && !fieldConfig?.metadata?.is_url
      && !fieldConfig?.metadata?.is_email,
            handler: () => isMatchingGivenRegexRule({
                t,
                regexPattern: fieldConfig?.metadata?.validation_rules?.regex.pattern,
                messageError: fieldConfig?.metadata?.validation_rules?.regex.error_message,
            }),
        },
        {
            predicate: () => fieldConfig?.metadata?.validation_rules?.is_required,
            handler: () => requiredRule(t),
        },
        {
            predicate: () => true,
            handler: () => [],
        },
    ];


    return {
        validationRules: [
            ...handlers.filter(({ predicate }) => predicate()).reduce((acc, matchingHandler) => [
                ...acc,
                ...matchingHandler.handler(),
            ], []),
        ],
    };
}


function parseFieldMetaData(
    fieldConfig,
    fieldName,
    t,
) {
    return {
        name: fieldName,
        defaultValue: fieldConfig?.default_value,
        label: fieldConfig?.metadata?.label,
        placeholder: fieldConfig?.metadata?.label,
        infoTooltip: fieldConfig?.metadata?.info_tooltip,
        metadata: fieldConfig?.metadata,
        isDisabled: fieldConfig?.metadata?.is_disabled,
        ...mapBackendFieldTypeAndMetaToFrontendType(fieldConfig),
        ...createValidationRules(fieldConfig, fieldName, t),
    };
}


function recurrentlyExtractContexts({
    configToParse,
    parentKeysArray = [],
    parentAcc,
    t,
}) {
    const currentLevels = Object.keys(configToParse);

    return currentLevels.reduce((acc, currentLevelKey) => {
        const contextValue = configToParse[currentLevelKey]?.context;
        const beFieldTypeValue = configToParse[currentLevelKey]?.field_type;

        if (contextValue && beFieldTypeValue) {
            return {
                ...acc,
                [contextValue]: [
                    ...(acc?.[contextValue]?.length > 0 ? [...acc[contextValue]] : []),
                    parseFieldMetaData(
                        configToParse[currentLevelKey],
                        ['configuration', ...parentKeysArray, currentLevelKey].join('.'),
                        t,
                    ),
                ],
            };
        }

        // XXX recurrent call
        return {
            ...acc,
            ...recurrentlyExtractContexts({
                configToParse: configToParse[currentLevelKey],
                parentKeysArray: [...parentKeysArray, currentLevelKey],
                parentAcc: acc,
                t,
            }),
        };
    }, parentAcc);
}

function handleDefaultValue({
    fieldName,
    fieldConfig,
    flatClientConfig,
}) {
    const handlers = [
        {
            predicate: () => flatClientConfig,
            handler: () => flatClientConfig?.[fieldName],
        },
        {
            predicate: () => fieldConfig?.metadata?.is_multiline && fieldConfig.metadata?.validation_rules?.is_valid_json,
            handler: () => {
                try {
                    return JSON.parse(fieldConfig.default_value);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log('XXX Wrong JSON default value:', fieldConfig.default_value, 'fieldConfiguration:', fieldConfig);
                    // eslint-disable-next-line no-console
                    console.warn(e);
                    return undefined;
                }
            },
        },
        {
            predicate: () => fieldConfig?.metadata?.is_client_select && fieldConfig?.default_value === 0,
            handler: () => undefined,
        },
        {
            predicate: () => true,
            handler: () => fieldConfig?.default_value,
        },
    ];
    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


function recurrentlyExtractInitialValues({
    configToParse,
    parentKeysArray = [],
    parentAcc,
    flatClientConfig,
}) {
    const currentLevels = Object.keys(configToParse);

    // TODO if metadata.is_client_select -> Number(default_value)
    return currentLevels.reduce((acc, currentLevelKey) => {
        const contextValue = configToParse[currentLevelKey]?.context;
        const beFieldTypeValue = configToParse[currentLevelKey]?.field_type;

        const fieldName = ['configuration', ...parentKeysArray, currentLevelKey].join('.');

        const defaultValue = handleDefaultValue({
            fieldName,
            fieldConfig: configToParse[currentLevelKey],
            flatClientConfig,
        });

        if (
            contextValue?.length > 0
      && beFieldTypeValue?.length > 0
        ) {
            if (
                defaultValue !== undefined
        && defaultValue !== null // TODO remove once BE filters this out
        && defaultValue !== 'null' // TODO remove once BE filters this out
        // && configToParse[currentLevelKey]?.default_value !== ''// TODO remove once BE filters this out
            ) {
                return {
                    ...acc,
                    [fieldName]: defaultValue,
                };
            }

            return acc;
        }

        // XXX recurrent call
        return {
            ...acc,
            ...recurrentlyExtractInitialValues({
                configToParse: configToParse[currentLevelKey],
                parentKeysArray: [...parentKeysArray, currentLevelKey],
                parentAcc,
                flatClientConfig,
            }),
        };
    }, parentAcc);
}


export function parseDefaultClientConfigurationToSubStepsConfig(
    defaultSettingsConfig,
    t,
) {
    const flatContextsList = recurrentlyExtractContexts({ configToParse: defaultSettingsConfig, t });

    const nestedSubSteps = Object.keys(flatContextsList).reduce((acc, contextName) => {
        const contextParts = contextName ? contextName.split('.') : undefined;
        const mainContext = contextParts?.length > 0 ? contextParts[0] : contextName;
        const subContext = contextParts?.[1];

        if (subContext) {
            return {
                ...acc,
                [mainContext]: {
                    ...acc[mainContext],
                    [subContext]: [
                        ...flatContextsList[contextName],
                    ],
                },
            };
        }

        return {
            ...acc,
            [mainContext]: [
                ...flatContextsList[contextName],
            ],
        };
    }, {});

    return nestedSubSteps;
}


export function parseDefaultClientConfigurationToInitialFormData(
    stepsConfiguration,
    flatClientConfig,
) {
    return recurrentlyExtractInitialValues({
        configToParse: stepsConfiguration,
        parentKeysArray: [],
        parentAcc: {},
        flatClientConfig,
    });
}


export function createActionsForStepSuccessfulValidation({
    values,

    locationPathname,
    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,
    availableSubSteps,

    dispatchFinalApiCall,

    dispatchRequestSetMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormSubStep,

    currentSubStepNumber,
    lastStepExceptSuccess,
}) {
    const handlers = [
        {
            // last step or last sub step in last step (last expect SUCCESS step)
            predicate: () => (
                currentMultiStepsFormStep === lastStepExceptSuccess
        && !availableSubSteps?.length > 0
            ) || (
                currentMultiStepsFormStep === lastStepExceptSuccess
        && availableSubSteps?.length > 0
        && currentSubStepNumber + 1 === availableSubSteps?.length
            ),
            handler: () => dispatchFinalApiCall(nestifyObject(values)),
        },
        {
            // step with sub steps, all sub steps expect last one
            predicate: () => currentMultiStepsFormSubStep
        && availableSubSteps?.length > 0
        && currentSubStepNumber + 1 !== availableSubSteps?.length,
            handler: () => dispatchRequestSetMultiStepsFormSubStep(
                currentMultiStepsFormStep,
                currentMultiStepsFormSubStep,
                MultiStepFormStepChangeDirections.FORWARD,
                locationPathname,
            ),
        },

        {
            // regular case -> advance step
            predicate: () => true,
            handler: () => dispatchRequestSetMultiStepsFormStep(
                currentMultiStepsFormStep,
                MultiStepFormStepChangeDirections.FORWARD,
                locationPathname,
            ),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export default {
    parseDefaultClientConfigurationToSubStepsConfig,
    parseDefaultClientConfigurationToInitialFormData,
    createActionsForStepSuccessfulValidation,
};
