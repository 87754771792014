import { defaultQueryParamsMeta } from 'constants/ApplicationStateModel';
import { AddEditBeneficiarySteps } from 'constants/BeneficiariesModel';
import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchBeneficiaryRequirements,
    fetchBeneficiaryRequirementsSuccess,
    fetchBeneficiaryRequirementsFailure,
    clearBeneficiaryRequirements,
    setAddEditBeneficiaryFormStep,
    createBeneficiary,
    createBeneficiarySuccess,
    createBeneficiaryFailure,
    clearAddEditBeneficiaryForm,
    initialiseAddEditBeneficiaryFormSuccess,
    setBeneficiaryIdentificationCategory,
} from './actions';
import {
    extractDataForEditForm,
    parseRawBeneficiariesRequirements,
} from './helpers';


export const beneficiaryAddReducerName = 'beneficiaryAdd';


const addEditFormState = {
    contextData: null,
    addEditBeneficiaryFormStep: null,

    beneficiariesRequirements: null,
    selectedIdentificationCategory: null,
    formData: null,
    initialValues: null,

    isLoadingBeneficiariesRequirements: false,
    isLoadingAddNewBeneficiary: false,
};

const initialState = {
    // details
    beneficiaryDetails: null,
    isLoadingBeneficiary: false,
    beneficiariesDetailsQueryParamsMeta: defaultQueryParamsMeta,

    isLoadingDeleteBeneficiary: false,

    // add/edit
    ...addEditFormState,
};


const handlers = {
    [fetchBeneficiaryRequirements]: (state) => {
        state.isLoadingBeneficiariesRequirements = true;
    },
    [fetchBeneficiaryRequirementsSuccess]: (state, action) => {
        state.isLoadingBeneficiariesRequirements = false;
        state.beneficiariesRequirements = parseRawBeneficiariesRequirements(action.payload);
        state.selectedIdentificationCategory = null;
    },
    [fetchBeneficiaryRequirementsFailure]: (state) => {
        state.isLoadingBeneficiariesRequirements = false;
    },

    [clearBeneficiaryRequirements]: (state) => {
        state.beneficiariesRequirements = null;
    },
    [setBeneficiaryIdentificationCategory]: (state, action) => {
        state.selectedIdentificationCategory = action.payload;
    },

    [setAddEditBeneficiaryFormStep]: (state, action) => {
        state.currentStep = action.payload.step;
        state.formData = {
            ...state.formData,
            ...action.payload.formData,
        };
    },

    [initialiseAddEditBeneficiaryFormSuccess]: (state, action) => {
        state.contextData = action.payload.contextData;
        state.currentStep = action.payload.contextData.isEditMode ? AddEditBeneficiarySteps.ADDRESS : AddEditBeneficiarySteps.BASIC_DATA;

        if (action.payload.contextData.isEditMode && action.payload.formData) {
            state.formData = extractDataForEditForm(action.payload.formData);
            state.initialValues = action.payload.formData;
        }
    },

    [createBeneficiary]: (state) => {
        state.isLoadingAddNewBeneficiary = true;
    },
    [createBeneficiarySuccess]: (state) => {
        state.isLoadingAddNewBeneficiary = false;
    },
    [createBeneficiaryFailure]: (state) => {
        state.isLoadingAddNewBeneficiary = false;
    },

    [clearAddEditBeneficiaryForm]: (state) => ({
        ...state,
        ...addEditFormState,
    }),

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
