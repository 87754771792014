import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';
import { PN } from 'constants/PermissionsModel';

import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { NumericId, Uuid } from '@manigo/manigo-domain-typings';
import { Row, Col, Button, Spin } from 'antd';
import React, { useMemo } from 'react';


import { createFailedWebhookDetailsCol1FieldsConfig, createFailedWebhookDetailsCol2FieldsConfig } from './FailedWebhookInfo.helpers';
import { FailedWebhookInfoProps } from './FailedWebhookInfo.types';


function FailedWebhookInfo({
    t,
    onRefresh,
    locationPathname,
    accessControl,
    entityDetailsData,
    areDetailsReady,
    isLoadingReplayFailedWebhook,
    dispatchReplayFailedWebhook,
    queryParams,
}: FailedWebhookInfoProps) {
    const isLoading = useMemo(
        () => isLoadingReplayFailedWebhook.includes(entityDetailsData?.entityDetails?.uuid),
        [isLoadingReplayFailedWebhook],
    );

    const fieldsCol1 = useMemo(
        () => createFailedWebhookDetailsCol1FieldsConfig({ t, failedWebhook: entityDetailsData?.entityDetails }),
        [entityDetailsData],
    );

    const fieldsCol2 = useMemo(
        () => createFailedWebhookDetailsCol2FieldsConfig({ t, failedWebhook: entityDetailsData?.entityDetails }),
        [entityDetailsData],
    );


    return (
        <Col
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{ background: 'white', padding: '10px' }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={!areDetailsReady}
                minHeight={'130px'}
                sectionsRowsCount={4}
                sectionsCount={2}
            >
                <Spin
                    tip="Processing..."
                    indicator={<LoadingOutlined spin />}
                    spinning={isLoading || entityDetailsData?.isLoadingDetails}
                >
                    <DetailsSectionsWrapper>
                        <Row gutter={24}>
                            <CommonSection fieldsConfig={fieldsCol1} />

                            <CommonSection fieldsConfig={fieldsCol2} />
                        </Row>

                        <OptionalButtonsWrapper>
                            {accessControl.hasPermission(PN.WEBHOOKS.REPLAY) && (
                                <Button
                                    style={{ position: 'absolute', top: 0, right: 0, display: 'block' }}
                                    icon={<RedoOutlined />}
                                    disabled={isLoading}
                                    loading={isLoading}
                                    onClick={() => dispatchReplayFailedWebhook({
                                        webhookUuid: entityDetailsData?.entityDetails?.uuid as Uuid,
                                        clientId: entityDetailsData?.entityDetails?.clientId as NumericId,
                                    }, locationPathname)}
                                >
                                    {t('buttons.replayFailedWebhook.tooltip')}
                                </Button>
                            )}
                        </OptionalButtonsWrapper>

                        <QueryParamsMetaInfo
                            detailsContext
                            onListRefresh={onRefresh}
                            queryParams={queryParams}
                            queryParamsMeta={entityDetailsData?.queryParamsMeta}
                        />
                    </DetailsSectionsWrapper>
                </Spin>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}


export default FailedWebhookInfo;
