import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { clearLimitDetails, fetchLimitDetails } from 'redux/limit/actions';
import { limitDetailsPerLocationsStoreKeyName, limitReducerName } from 'redux/limit/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import LimitDetails from './LimitDetails.layout';
import { LimitDetailsDispatchProps, LimitDetailsStateProps } from './LimitDetails.types';


const mapStateToProps = (state: RootState): LimitDetailsStateProps => ({
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: limitReducerName,
        fieldName: limitDetailsPerLocationsStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
    enhancedCurrentLocation: state[navigationReducerName]?.enhancedCurrentLocation,
});

const mapDispatchToProps = (dispatch:Dispatch): LimitDetailsDispatchProps => ({
    dispatchFetchLimitDetails: (queryParams, locationPathname) => dispatch(fetchLimitDetails(queryParams, locationPathname)),
    dispatchClearLimitDetails: (locationPathname) => dispatch(clearLimitDetails(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: limitReducerName,
        fieldName: limitDetailsPerLocationsStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation(),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(LimitDetails);
