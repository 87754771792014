const createUploaderProps = ({
    clientId,
    locationPathname,
    migrationType,
    dispatchUploadClientMigrationFile,
}) => ({
    name: 'file',
    multiple: false,
    accept: '.csv',
    customRequest: ({
        onProgress,
        onError,
        onSuccess,
        data,
        filename,
        file,
        withCredentials,
        action,
        headers,
    }) => {
    // eslint-disable-next-line no-console
        console.log(
            'createUploaderProps data:',
            data,
            'filename',
            filename,
            'file',
            file,
            'withCredentials',
            withCredentials,
            'action',
            action,
            'headers',
            headers,
            clientId,
            migrationType,
        );

        dispatchUploadClientMigrationFile(
            {
                clientId,
                migrationType,
                blob: file,
                onProgress,
                onError,
                onSuccess,
            },
            locationPathname,
        );
    },
});


export default createUploaderProps;
