import { clearMultiStepsFormMetaData, requestSetMultiStepsFormStep, requestSetMultiStepsFormSubStep, setMultiStepsFormMetaData } from 'redux/multi-steps-form/actions';
import { multiStepsFormReducerName } from 'redux/multi-steps-form/reducer';
import { blockNavigation, requestNavigation, showNavigationConfirmation, unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import StandardMultiStepForm from './StandardMultiStepForm.layout';


const mapStateToProps = (state, ownProps) => {
    const { locationPathname } = ownProps;
    const currentMultiStepsFormStep = state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormStep;
    return {
    // form meta data
        availableSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSteps,
        availableSubSteps: state[multiStepsFormReducerName]?.[locationPathname]?.availableSubSteps?.[currentMultiStepsFormStep],

        isBlockNavigationReasonSet: state[navigationReducerName].blockNavigationReason,

        currentMultiStepsFormStep,
        currentMultiStepsFormSubStep: state[multiStepsFormReducerName]?.[locationPathname]?.currentMultiStepsFormSubStep?.[currentMultiStepsFormStep],
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { locationPathname } = ownProps;

    return {
        dispatchSetMultiStepsFormMetaData: (formMetaData) => dispatch(setMultiStepsFormMetaData(formMetaData, locationPathname)),
        dispatchClearAddClientFormMetaData: () => dispatch(clearMultiStepsFormMetaData(locationPathname)),

        dispatchRequestSetMultiStepsFormStep: (currentStep, direction) => dispatch(requestSetMultiStepsFormStep(
            currentStep,
            direction,
            locationPathname,
        )),
        dispatchRequestSetMultiStepsFormSubStep: (parentStep, currentSubStep, direction) => dispatch(requestSetMultiStepsFormSubStep(
            {
                parentStep,
                currentSubStep,
                direction,
            },
            locationPathname,
        )),


        dispatchBlockNavigation: (reason) => dispatch(blockNavigation(showNavigationConfirmation(), reason)),
        dispatchUnblockNavigation: () => dispatch(unblockNavigation()),
        dispatchRequestNavigation: (pathname, state) => dispatch(requestNavigation(pathname, state)),
    };
};

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(StandardMultiStepForm);
