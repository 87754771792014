import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';

import { fetchSupportedCurrencies, fetchSupportedCurrenciesFailure, fetchSupportedCurrenciesSuccess, clearSupportedCurrencies } from './actions';


export const supportedCurrenciesReducerName = 'supportedCurrencies';
export const supportedCurrenciesListsPerLocationStoreKeyName = 'supportedCurrenciesPerLocation';


export const defaultSupportedCurrenciesListQueryParams = {
    clientId: undefined,
    memberId: undefined,
    companyId: undefined,
    currencyMode: 'extended', // 'extended' for non-standard currency object vs 'minimal' for array of currency  ISO codes (strings)
};

const initialState = { [supportedCurrenciesListsPerLocationStoreKeyName]: {} };

const handlers = {
    [fetchSupportedCurrencies]: (state, action) => {
        state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardLists(state[supportedCurrenciesListsPerLocationStoreKeyName], action);
    },
    [fetchSupportedCurrenciesSuccess]: (state, action) => {
        state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[supportedCurrenciesListsPerLocationStoreKeyName], action);
    },
    [fetchSupportedCurrenciesFailure]: (state, action) => {
        state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[supportedCurrenciesListsPerLocationStoreKeyName], action);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === supportedCurrenciesReducerName
      && action.payload?.fieldName === supportedCurrenciesListsPerLocationStoreKeyName
        ) {
            state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[supportedCurrenciesListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearSupportedCurrencies]: (state, action) => {
        state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(
            state[supportedCurrenciesListsPerLocationStoreKeyName],
            action.payload?.locationPathname,
        );
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
