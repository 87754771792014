import ModalName from 'constants/Modals';
import { showSuccessToast } from 'redux/application/actions';
import {
    CARD_PIN_UNBLOCK,

    UNLINK_CARD, UNLINK_CARD_SUCCESS,

    FETCH_CARD_DETAILS,

    UPDATE_CARD,

    UPDATE_CARD_LIMITS,

    SHOW_UNLINK_CARD_MODAL,

    CONFIRM_UNLINK_CARD,

    DELETE_CARD, DELETE_CARD_SUCCESS,
} from 'redux/card/action.types';
import {
    fetchCardDetails, fetchCardDetailsFailure, fetchCardDetailsSuccess,
    updateCardSuccess, updateCardFailure, unlinkCardSuccess,
    unlinkCardFailure, unlinkCard,
    deleteCardFailure, deleteCardSuccess,
    cardPinUnblockFailure, cardPinUnblockSuccess,
    updateCardLimitsSuccess,
} from 'redux/card/actions';
import { createCardPath, createUnlinkCardUpdateAction, getEntityDetails } from 'redux/card/epic.helpers';
import { setModalProps, showModal, hideModal } from 'redux/modal/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { cardDetailsPerLocationStoreKeyName, cardReducerName } from './reducer';


export const onFetchCardDetails = (action$, state$, { cards }) => action$.pipe(
    ofType(FETCH_CARD_DETAILS),
    switchMap(({ payload }) => from(cards.fetchCardDetails(payload?.queryParams?.cardId)).pipe(
        switchMap((response) => of(fetchCardDetailsSuccess(response.data, payload.locationPathname))),
        catchError(() => of(fetchCardDetailsFailure(payload.locationPathname))),
    )),
);

export const onUpdateCard = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(UPDATE_CARD),
    switchMap(({ payload }) => from(cards.updateCardDetails(payload.cardId, payload.requestPayload)).pipe(
        switchMap((response) => {
            const entityDetails = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName][createCardPath(payload.cardId)];
            return of(
                updateCardSuccess({
                    responsePayload: {
                        ...response.data,
                        ...entityDetails,
                    },
                    locationPathname: createCardPath(payload.cardId),
                }),
                showSuccessToast(i18n.t('cards:actionMessages.changeCardSettings')),
            );
        }),
        catchError(() => of(updateCardFailure())),
    )),
);

export const onUpdateCardLimits = (action$, state$, { cards }) => action$.pipe(
    ofType(UPDATE_CARD_LIMITS),
    switchMap(({ payload }) => from(cards.updateCardLimits(payload.cardId, payload.requestPayload)).pipe(
        switchMap(() => {
            const entityDetails = getEntityDetails(state$, payload.cardId);

            return of(updateCardLimitsSuccess({
                responsePayload: {
                    ...entityDetails,
                    has_limit: payload.requestPayload?.has_limit,
                    card_limit: payload.requestPayload?.limit_monthly,
                },
                locationPathname: createCardPath(payload.cardId),
            }));
        }),
        catchError(() => of(updateCardFailure())),
    )),
);


export const onShowUnlinkCardModal = (action$, state$, { i18n }) => action$.pipe(
    ofType(SHOW_UNLINK_CARD_MODAL),
    switchMap(({ payload, meta }) => of(
        showModal({
            modalType: ModalName.UNLINK_CARD_MODAL,
            modalProps: {
                okText: 'Unlink',
                title: i18n.t('cards:modals.unlinkCard.title'),
                card: payload,
                meta,
            },
        }),
    )),
);

export const onConfirmUnlinkCard = (action$) => action$.pipe(
    ofType(CONFIRM_UNLINK_CARD),
    switchMap(({ payload, meta }) => of(
        setModalProps({ confirmLoading: true, cancelButtonProps: { disabled: true } }),
        unlinkCard(payload, meta),
    )),
);

export const onUnlinkCard = (action$, state$, { cards }) => action$.pipe(
    ofType(UNLINK_CARD),
    switchMap(({ payload, meta }) => from(cards.unlinkCard(payload)).pipe(
        switchMap((response) => of(unlinkCardSuccess(response.data, meta))),
        catchError(() => of(
            unlinkCardFailure(),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);

export const onUnlinkCardSuccess = (action$, state$, { i18n }) => action$.pipe(
    ofType(UNLINK_CARD_SUCCESS),
    switchMap(({ meta }) => {
        const updateAction = createUnlinkCardUpdateAction(meta, state$.value);
        return of(
            ...updateAction,
            showSuccessToast(i18n.t('cards:actionMessages.unlinkCardSuccess')),
            hideModal(),
        );
    }),
);

export const onDeleteCard = (action$, state$, { cards }) => action$.pipe(
    ofType(DELETE_CARD),
    switchMap(({ payload }) => from(cards.deleteCard(payload)).pipe(
        switchMap(() => of(deleteCardSuccess(payload))),
        catchError(() => of(deleteCardFailure())),
    )),
);


export const onDeleteCardSuccess = (action$, state$, { i18n }) => action$.pipe(
    ofType(DELETE_CARD_SUCCESS),
    switchMap(({ payload }) => {
        const pathname = createCardPath(payload);
        const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

        return of(
            showSuccessToast(i18n.t('cards:actionMessages.deleteCardSuccess')),
            fetchCardDetails(currentCardDetailsQueryParams, pathname),
        );
    }),
);


export const onCardPinUnblock = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(CARD_PIN_UNBLOCK),
    switchMap(({ payload }) => from(cards.unblockCardPin(payload.cardToken)).pipe(
        switchMap(() => {
            const pathname = payload.locationPathname;
            const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

            return of(
                cardPinUnblockSuccess(),
                showSuccessToast(i18n.t('cards:actionMessages.unblockCardPinSuccess')),
                fetchCardDetails(currentCardDetailsQueryParams, pathname),
            );
        }),
        catchError(() => of(cardPinUnblockFailure())),
    )),
);


export default [
    onFetchCardDetails,
    onUpdateCard,
    onUnlinkCard,
    onShowUnlinkCardModal,
    onConfirmUnlinkCard,
    onUnlinkCardSuccess,
    onDeleteCard,
    onDeleteCardSuccess,
    onCardPinUnblock,
    onUpdateCardLimits,
];
