import SelectedPermissions from 'components/common/organisms/forms-shared-parts/add-edit-role-form/SelectPermissionsStep/SelecctedPermissions';

import PropTypes from 'prop-types';
import React from 'react';


import AvailablePermissions from './AvailablePermissions';
import Styled from './SelectPermissionsStep.styled';


function SelectPermissionsStep({
    operationsLists,
    selectedPermissions,
    dispatchAddPermissionsToRole,
    dispatchRemovePermissionsFromRole,
    dispatchRemoveAllPermissionsFromRole,
    t,
}) {
    return (
        <Styled.SelectPermissionsWrapper>

            <AvailablePermissions
                selectedPermissions={selectedPermissions}
                dispatchAddPermissionsToRole={dispatchAddPermissionsToRole}
                operationsLists={operationsLists}
                t={t}
            />

            <SelectedPermissions
                selectedPermissions={selectedPermissions}
                dispatchRemoveAllPermissionsFromRole={dispatchRemoveAllPermissionsFromRole}
                dispatchRemovePermissionsFromRole={dispatchRemovePermissionsFromRole}
                t={t}
            />


        </Styled.SelectPermissionsWrapper>
    );
}

SelectPermissionsStep.propTypes = {
    // mapped props
    selectedRoleTemplate: PropTypes.shape({}),
    operationsLists: PropTypes.arrayOf(PropTypes.shape({})),
    dispatchRemovePermissionsFromRole: PropTypes.func,
    dispatchAddPermissionsToRole: PropTypes.func,
};

export default SelectPermissionsStep;
