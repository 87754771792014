import TabSubHeading from 'components/common/atoms/TabSubHeading';
import { AccessControlProps } from 'constants/AccessControlModel';
import { PN } from 'constants/PermissionsModel';
import { commonValidation } from 'utils/validation-tools';

import {
    Space, Switch, Button, Spin, Input, Skeleton, Form, Alert,
} from 'antd';
import { merge, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';


import Styled from './Announcement.styled';


const defaultAnnouncement = {
    header: '',
    content: '',
    button_label: '',
};


function Announcement({
    accessControl,
    announcement,
    configuration,
    isLoading,
    isLoadingClientDetails,
    dispatchUpdateClientSettings,
    dispatchUpdateClientAnnouncements,
    enforcedClientId,
    t,
}) {
    const [form] = Form.useForm();

    const clientAnnouncement = useMemo(
        () => merge({}, defaultAnnouncement, announcement),
        [announcement],
    );
    const [updatedAnnouncement, setUpdatedAnnouncement] = useState(clientAnnouncement);

    const canEnableDisableAnnouncements = useMemo(
        () => accessControl.hasPermission(PN.CLIENTS.UPDATE),
        [accessControl],
    );

    const canUpdateAnnouncement = useMemo(
        () => accessControl.hasPermission(PN.ANNOUNCEMENTS.UPDATE)
      && configuration?.general_settings?.is_announcement_enabled,
        [accessControl],
    );


    useEffect(() => {
        setUpdatedAnnouncement(announcement);
    }, [announcement]);

    const changeIsAnnouncementEnabled = (value) => {
        const newConfig = { general_settings: { is_announcement_enabled: value } };
        dispatchUpdateClientSettings(enforcedClientId, { configuration: newConfig });
    };

    const onFinish = (values) => {
        dispatchUpdateClientAnnouncements(enforcedClientId, values);
    };


    return (
        <Skeleton loading={isLoadingClientDetails} active>
            <Spin
                tip={t('common:actionMsg.genericSpinnerText')}
                spinning={isLoading}
            >
                <Styled.TwoColumns>
                    <div>
                        <TabSubHeading text={t('clients:clientDetails.tabs.announcements.title')} />
                        <div style={{ paddingBottom: '20px' }}>
                            <Space>
                Enable announcements
                                <Switch
                                    disabled={!canEnableDisableAnnouncements}
                                    checked={configuration?.general_settings?.is_announcement_enabled}
                                    onChange={canEnableDisableAnnouncements ? (value) => changeIsAnnouncementEnabled(value) : undefined}
                                />
                            </Space>
                        </div>

                        <Alert
                            showIcon
                            type="info"
                            description={t('clients:clientDetails.tabs.announcements.subtitle')}
                        />

                    </div>

                    <div>
                        <TabSubHeading text={t('clients:clientDetails.tabs.announcements.columnTitle')} />
                        <Form
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                            initialValues={{ ...announcement }}
                            onFinish={onFinish}
                        >

                            <Form.Item
                                name="button_label"
                                label={t('clients:clientDetails.tabs.announcements.buttonLabel')}
                                rules={[...commonValidation({ t, isRequired: true, maxLength: 32 })]}
                            >
                                <Input
                                    disabled={!canUpdateAnnouncement}
                                    placeholder="Enter button label"
                                    onChange={(e) => setUpdatedAnnouncement({ ...updatedAnnouncement, button_label: e.target.value })}
                                />
                            </Form.Item>

                            <Form.Item
                                name="header"
                                label={t('clients:clientDetails.tabs.announcements.header')}
                                rules={[...commonValidation({ t, isRequired: true, maxLength: 128 })]}
                            >
                                <Input.TextArea
                                    disabled={!canUpdateAnnouncement}
                                    placeholder="Enter header"
                                    onChange={(e) => setUpdatedAnnouncement({ ...updatedAnnouncement, header: e.target.value })}
                                />
                            </Form.Item>

                            <Form.Item
                                name="content"
                                label={t('clients:clientDetails.tabs.announcements.content')}
                                rules={[...commonValidation({ t, isRequired: true, maxLength: 1024 })]}
                            >
                                <Input.TextArea
                                    disabled={!canUpdateAnnouncement}
                                    rows={5}
                                    placeholder="Enter content"
                                    onChange={(e) => setUpdatedAnnouncement({ ...updatedAnnouncement, content: e.target.value })}
                                />
                            </Form.Item>

                            <Styled.ButtonsWrapper>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!canUpdateAnnouncement || isEqual(clientAnnouncement, updatedAnnouncement)}
                                >
                                    {t('clients:clientDetails.tabs.announcements.update')}
                                </Button>

                            </Styled.ButtonsWrapper>
                        </Form>
                    </div>
                </Styled.TwoColumns>
            </Spin>
        </Skeleton>
    );
}

Announcement.propTypes = {
    accessControl: AccessControlProps,
    enforcedClientId: PropTypes.number,
    announcement: PropTypes.shape({
        header: PropTypes.string,
        content: PropTypes.string,
        button_label: PropTypes.string,
    }),
    isLoading: PropTypes.bool.isRequired,
    isLoadingClientDetails: PropTypes.bool,
    dispatchUpdateClientAnnouncements: PropTypes.func.isRequired,
    dispatchUpdateClientSettings: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default Announcement;
