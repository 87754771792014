export const FETCH_BUSINESS_USER_DETAILS = 'FETCH_BUSINESS_USER_DETAILS';
export const FETCH_BUSINESS_USER_DETAILS_SUCCESS = 'FETCH_BUSINESS_USER_DETAILS_SUCCESS';
export const FETCH_BUSINESS_USER_DETAILS_FAILURE = 'FETCH_BUSINESS_USER_DETAILS_FAILURE';


export const UPDATE_BUSINESS_USER = 'UPDATE_BUSINESS_USER';
export const UPDATE_BUSINESS_USER_SUCCESS = 'UPDATE_BUSINESS_USER_SUCCESS';
export const UPDATE_BUSINESS_USER_FAILURE = 'UPDATE_BUSINESS_USER_FAILURE';

export const CLEAR_BUSINESS_USER_DETAILS = 'CLEAR_BUSINESS_USER_DETAILS';
