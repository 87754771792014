export const FETCH_WEBHOOKS_LIST = 'FETCH_WEBHOOKS_LIST';
export const FETCH_WEBHOOKS_LIST_SUCCESS = 'FETCH_WEBHOOKS_LIST_SUCCESS';
export const FETCH_WEBHOOKS_LIST_FAILURE = 'FETCH_WEBHOOKS_LIST_FAILURE';
export const CLEAR_WEBHOOKS_LIST = 'CLEAR_WEBHOOKS_LIST';

export const FETCH_WEBHOOKS_ACTION_TYPES = 'FETCH_WEBHOOKS_ACTION_TYPES';
export const FETCH_WEBHOOKS_ACTION_TYPES_SUCCESS = 'FETCH_WEBHOOKS_ACTION_TYPES_SUCCESS';
export const FETCH_WEBHOOKS_ACTION_TYPES_FAILURE = 'FETCH_WEBHOOKS_ACTION_TYPES_FAILURE';

export const OPEN_REGISTER_OR_EDIT_WEBHOOK_MODAL = 'OPEN_REGISTER_OR_EDIT_WEBHOOK_MODAL';

export const REGISTER_WEBHOOK = 'REGISTER_WEBHOOK';
export const REGISTER_WEBHOOK_SUCCESS = 'REGISTER_WEBHOOK_SUCCESS';
export const REGISTER_WEBHOOK_FAILURE = 'REGISTER_WEBHOOK_FAILURE';

export const UNREGISTER_WEBHOOK = 'UNREGISTER_WEBHOOK';
export const UNREGISTER_WEBHOOK_SUCCESS = 'UNREGISTER_WEBHOOK_SUCCESS';
export const UNREGISTER_WEBHOOK_FAILURE = 'UNREGISTER_WEBHOOK_FAILURE';

export const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK';
export const UPDATE_WEBHOOK_SUCCESS = 'UPDATE_WEBHOOK_SUCCESS';
export const UPDATE_WEBHOOK_FAILURE = 'UPDATE_WEBHOOK_FAILURE';

