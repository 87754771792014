import InfoTooltip from 'components/common/atoms/InfoTooltip';
import SwitchWithInfoTooltip from 'components/common/atoms/SwitchWithInfoTooltip';
import TabSubHeading from 'components/common/atoms/TabSubHeading';
import Common from 'components/common/atoms/styled/UpdateStatus.styled';
import { AccessControlProps } from 'constants/AccessControlModel';
import { CardDetailsProps } from 'constants/CardModel';
import { PN } from 'constants/PermissionsModel';

import { CloseOutlined, CheckOutlined, UnlockOutlined } from '@ant-design/icons';
import {
    Button, Col, Row, Skeleton, Spin, Switch, Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import UpdateCardStatusForm from './UpdateCardStatusForm';


function Settings({
    locationPathname,
    accessControl,
    isInReadOnlyMode,
    cardDetails,
    onUpdate,
    isLoadingCardDetails,
    isLoadingUpdateCard,
    isLoadingUnblockPin,
    dispatchCardPinUnblock,
    t,
}) {
    const canUpdateCard = useMemo(() => accessControl.hasPermission(PN.CARDS.TOGGLING) && !isInReadOnlyMode, [accessControl, isInReadOnlyMode]);

    const updateBooleanField = (field, enabled) => {
        onUpdate({ [field]: enabled });
    };

    return (
        <Skeleton loading={isLoadingCardDetails} active>
            <Spin tip="Updating..." spinning={isLoadingUpdateCard || isLoadingUnblockPin}>
                <Row>
                    <Col span={8}>
                        <TabSubHeading text={t('cardDetails.tabs.settings.subHeadings.basicSettings')} />
                        <Row justify="space-between" style={{ marginBottom: 10 }}>
                            <div>{t('cardDetails.tabs.settings.fields.onlinePayments.text')}</div>
                            <SwitchWithInfoTooltip
                                onChange={(checked) => updateBooleanField('online_payment_enabled', checked)}
                                checked={cardDetails?.online_payment_enabled}
                                disabled={!canUpdateCard}
                                tooltipContent={t(`cardDetails.tabs.settings.fields.onlinePayments.${cardDetails?.online_payment_enabled ? 'tooltipEnabled' : 'tooltipDisabled'}`)}
                            />
                        </Row>

                        <Row justify="space-between" style={{ marginBottom: 10 }}>
                            <div>{t('cardDetails.tabs.settings.fields.atm.text')}</div>
                            <Switch
                                onClick={(checked) => updateBooleanField('atm_withdrawal_enabled', checked)}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                disabled={!canUpdateCard}
                                checked={cardDetails?.atm_withdrawal_enabled}
                            />
                        </Row>

                        <Row justify="space-between" style={{ marginBottom: 10 }}>
                            <div>{t('cardDetails.tabs.settings.fields.magstripe.text')}</div>
                            <Tooltip title={t('cardDetails.tabs.settings.fields.magstripe.tooltip')}>
                                <Switch
                                    onClick={(checked) => updateBooleanField('magstripe_enabled', checked)}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    disabled={!canUpdateCard}
                                    checked={cardDetails?.magstripe_enabled}
                                />
                            </Tooltip>

                        </Row>

                        <Row justify="space-between" style={{ marginBottom: 20 }}>
                            <div>{t('cardDetails.tabs.settings.fields.contactless.text')}</div>
                            <Switch
                                onClick={(checked) => updateBooleanField('contactless_payment_enabled', checked)}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                disabled={!canUpdateCard}
                                checked={cardDetails?.contactless_payment_enabled}
                            />
                        </Row>


                        {accessControl.hasPermission(PN.CARDS.UNBLOCK_PIN)
            && !isInReadOnlyMode
            && (
                <>
                    <TabSubHeading text={t('cardDetails.tabs.settings.subHeadings.pin')} />
                    <Row justify="space-between" style={{ marginBottom: 20 }}>
                        <InfoTooltip
                            content={(
                                <Button
                                    type="primary"
                                    loading={isLoadingUnblockPin}
                                    icon={<UnlockOutlined />}
                                    onClick={() => dispatchCardPinUnblock(cardDetails.token, locationPathname)}
                                >
                                    {t('cardDetails.tabs.settings.fields.pin.buttonLabel')}
                                </Button>
                            )}
                            tooltipContent={t('cardDetails.tabs.settings.fields.pin.tooltip')}
                        />
                    </Row>
                </>
            )}
                    </Col>

                    <Common.FormCol span={8}>
                        <UpdateCardStatusForm canUpdateCard={canUpdateCard} locationPathname={locationPathname} />
                    </Common.FormCol>
                </Row>
            </Spin>
        </Skeleton>
    );
}

Settings.propTypes = {
    accessControl: AccessControlProps,
    locationPathname: PropTypes.bool.isRequired,
    isInReadOnlyMode: PropTypes.bool.isRequired,
    isLoadingCardDetails: PropTypes.bool.isRequired,
    isLoadingUnblockPin: PropTypes.bool.isRequired,
    isLoadingUpdateCard: PropTypes.bool.isRequired,
    cardDetails: CardDetailsProps,
    onUpdate: PropTypes.func.isRequired,
    dispatchCardPinUnblock: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default Settings;
