import { standardPageSize } from 'config/config';
import { memberRootRoutePath, riskAssessmentsRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    CLEAR_RISK_ASSESMENTS_LIST,
    FETCH_RISK_ASSESMENTS,
    FETCH_RISK_ASSESMENTS_FAILURE,
    FETCH_RISK_ASSESMENTS_SUCCESS,
} from 'redux/risk-assessments/actions.types';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


export const riskAssessmentsReducerName = 'riskAssessments';
export const riskAssessmentsListsPerLocationStoreKeyName = 'riskAssessmentsListsPerLocationStoreKey';

export const defaultRiskAssessmentsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.DESCEND,
    with_template: true,
    ...withTotalCount,
};

const initialState = { [riskAssessmentsListsPerLocationStoreKeyName]: {} };

const handlers = {
    [FETCH_RISK_ASSESMENTS]: (state, action) => {
        state[riskAssessmentsListsPerLocationStoreKeyName] = updateStandardLists(state[riskAssessmentsListsPerLocationStoreKeyName], action);
    },
    [FETCH_RISK_ASSESMENTS_SUCCESS]: (state, action) => {
        state[riskAssessmentsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[riskAssessmentsListsPerLocationStoreKeyName], action);
    },
    [FETCH_RISK_ASSESMENTS_FAILURE]: (state, action) => {
        state[riskAssessmentsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[riskAssessmentsListsPerLocationStoreKeyName], action);
    },

    [CLEAR_RISK_ASSESMENTS_LIST]: (state, action) => {
        state[riskAssessmentsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[riskAssessmentsListsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[riskAssessmentsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[riskAssessmentsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [riskAssessmentsRouteSegmentPath],
                observedRootPaths: [memberRootRoutePath],
            },
        );
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === riskAssessmentsReducerName
          && action.payload?.fieldName === riskAssessmentsListsPerLocationStoreKeyName
        ) {
            state[riskAssessmentsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[riskAssessmentsListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
