import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import FiltersSection from './FiltersSection.layout';


const mapStateToProps = (state) => ({ userPreferences: state.currentUser?.userPreferences });

const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps),
);
export default decorate(FiltersSection);
