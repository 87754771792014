import styled from 'styled-components';


const PermissionItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0,1fr) minmax(0, auto) minmax(0, auto);
  grid-column-gap: 10px;
  white-space: nowrap;
  align-items: center;
  
  & + & {
    margin-top: 10px;
  }
`;


const HiddenFieldsWrapper = styled.div`
  visibility: hidden;
  height: 0;
  line-height: 0;
`;

const PermissionName = styled.div`
  font-family: monospace;
  font-weight: bold;
  letter-spacing: 1px;
  display: grid;
  grid-template-columns: min-content minmax(0, min-content);
  grid-column-gap: 10px;
`;

const RemovePermission = styled.button`
  background: none;
  border: none;
  outline: none;
  color: #CACACA;
  font-size: 24px;
  cursor: pointer;
  
  :hover {
    color: red;
  }
`;

const OperationId = styled.div`
  font-family: monospace;
`;


export default {
    PermissionItemWrapper,
    PermissionName,
    OperationId,
    RemovePermission,
    HiddenFieldsWrapper,
};
