import FormStepsRenderer from 'components/common/organisms/StandardMultiStepForm/FormStepsRenderer';
import { StepsConfigurationProps } from 'constants/MultiStepsFormModel';
import { isFormPristine } from 'utils/forms-tools';

import { LoadingOutlined } from '@ant-design/icons';
import { Form, Skeleton, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


import MultiStepFormButtons from './MultiStepFormButtons';
import MultiStepFormStepsIndicator from './MultiStepFormStepsIndicator';
import Styled from './StandardMultiStepForm.styled';


const loadingIcon = <LoadingOutlined spin />;

function StandardMultiStepForm({
    // ownProps
    isEditMode,
    stepsConfiguration,
    formName,
    locationPathname,
    reasonForBlockedNavigation,
    initialFormData,
    form,
    onFinishHandler,
    onValuesChangeHandler,
    onSubmitSpinnerCopy,
    onSuccessNewLocationPathname,
    onSuccessNewLocationLabel,
    onSuccessGoBackLabel,
    goBackPathname,

    isBlockNavigationReasonSet,

    isLoading,
    availableSteps,
    availableSubSteps,
    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,

    dispatchUnblockNavigation,
    dispatchBlockNavigation,

    dispatchSetMultiStepsFormMetaData,
    dispatchClearAddClientFormMetaData,
    t,
}) {
    const [allStepsFormValues, setAllStepsFormValues] = useState(initialFormData);

    useEffect(() => () => {
        dispatchClearAddClientFormMetaData();
    }, []);

    useEffect(() => {
        if (locationPathname
      && stepsConfiguration
      && stepsConfiguration.length > 0
        ) {
            dispatchSetMultiStepsFormMetaData({ stepsConfiguration });
        }
    }, []);

    const handleFormValuesChanges = (changedValues, allValues) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onValuesChangeHandler ? onValuesChangeHandler(changedValues, allValues) : undefined;

        const isPristine = isFormPristine({ ...allValues });
        if (!isPristine && !isBlockNavigationReasonSet) {
            dispatchBlockNavigation(reasonForBlockedNavigation);
        }

        if (isBlockNavigationReasonSet && isPristine) {
            dispatchUnblockNavigation();
        }
    };

    return locationPathname
  && availableSteps
  && currentMultiStepsFormStep
        ? (
            <>
                <Styled.DummyDivToMatchGlobalLayoutStyles />
                <Styled.StandardMultiStepFormLayout>

                    <MultiStepFormStepsIndicator
                        locationPathname={locationPathname}
                        stepsConfiguration={stepsConfiguration}
                        isLoading={isLoading}
                        availableSteps={availableSteps}
                        availableSubSteps={availableSubSteps}
                        currentMultiStepsFormStep={currentMultiStepsFormStep}
                        currentMultiStepsFormSubStep={currentMultiStepsFormSubStep}
                    />

                    <Styled.StandardMultiStepFormWrapper>
                        <Form
                            scrollToFirstError
                            name={formName}
                            form={form}
                            layout="vertical"
                            preserve
                            initialValues={initialFormData ? { ...initialFormData } : undefined}
                            onFinish={(currentStepValues) => {
                                const updatedValues = {
                                    ...allStepsFormValues,
                                    ...currentStepValues,
                                };

                                setAllStepsFormValues(updatedValues);
                                onFinishHandler(updatedValues);
                            }}
                            onValuesChange={handleFormValuesChanges}
                        >
                            <Styled.StandardMultiStepFormContentAndButtonsWrapper>
                                <Styled.StandardMultiStepFormContent>
                                    <Spin
                                        indicator={loadingIcon}
                                        tip={onSubmitSpinnerCopy}
                                        spinning={isLoading}
                                    >
                                        <FormStepsRenderer
                                            isEditMode={isEditMode}
                                            currentMultiStepsFormStep={currentMultiStepsFormStep}
                                            currentMultiStepsFormSubStep={currentMultiStepsFormSubStep}
                                            availableSubSteps={availableSubSteps}
                                            stepsConfiguration={stepsConfiguration}
                                            locationPathname={locationPathname}
                                            t={t}
                                        />
                                    </Spin>
                                </Styled.StandardMultiStepFormContent>

                                <MultiStepFormButtons
                                    isLoading={isLoading}
                                    isEditMode={isEditMode}
                                    locationPathname={locationPathname}
                                    stepsConfiguration={stepsConfiguration}
                                    onSuccessNewLocationPathname={onSuccessNewLocationPathname}
                                    onSuccessGoBackLabel={onSuccessGoBackLabel}
                                    onSuccessNewLocationLabel={onSuccessNewLocationLabel}
                                    goBackPathname={goBackPathname}
                                />

                            </Styled.StandardMultiStepFormContentAndButtonsWrapper>
                        </Form>

                    </Styled.StandardMultiStepFormWrapper>
                </Styled.StandardMultiStepFormLayout>
            </>
        )
        : (<Skeleton loading active />);
}

StandardMultiStepForm.propTypes = {
    // own props
    locationPathname: PropTypes.string.isRequired,
    reasonForBlockedNavigation: PropTypes.string.isRequired,
    stepsConfiguration: StepsConfigurationProps,
    isLoading: PropTypes.bool,
    initialFormData: PropTypes.shape({}),
    onSubmitSpinnerCopy: PropTypes.string,
    onSuccessNewLocationPathname: PropTypes.string,
    goBackPathname: PropTypes.string.isRequired,
    onSuccessNewLocationLabel: PropTypes.string.isRequired,
    onSuccessGoBackLabel: PropTypes.string,
    onFinishHandler: PropTypes.func,
    onValuesChangeHandler: PropTypes.func,


    // mapped props
    t: PropTypes.func,
    dispatchClearAddClientFormMetaData: PropTypes.func,
    dispatchUnblockNavigation: PropTypes.func,
    dispatchBlockNavigation: PropTypes.func,
    dispatchSetMultiStepsFormMetaData: PropTypes.func,
    isEditMode: PropTypes.bool,
    currentMultiStepsFormStep: PropTypes.string,
    currentMultiStepsFormSubStep: PropTypes.string,
    formName: PropTypes.string,
    availableSteps: PropTypes.arrayOf(PropTypes.string),
    availableSubSteps: PropTypes.arrayOf(PropTypes.string),
    isBlockNavigationReasonSet: PropTypes.bool,
};


export default StandardMultiStepForm;
