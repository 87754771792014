import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';

import { hideModal, showModal, setModalProps } from './actions';


export const modalReducerName = 'modal';

const initialState = {
    open: false,
    modalType: null,
    modalProps: null,
};

const handlers = {
    [showModal]: (state, action) => {
        state.open = true;
        state.modalType = action.payload.modalType;
        state.modalProps = action.payload.modalProps;
    },
    [hideModal]: (state) => {
        state.open = false;
        state.modalType = null;
        state.modalProps = null;
    },
    [setModalProps]: (state, action) => {
        state.modalProps = {
            ...state.modalProps,
            ...action.payload,
        };
    },
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
