import withAccessControl from 'hocs/withAccessControl';
import { clearReadOnlyMode, setQueryParams, setReadOnlyMode } from 'redux/application/actions';
import { clearBusinessUserDetails, fetchBusinessUserDetails } from 'redux/business-user/actions';
import { businessUserDetailsPerLocationStoreKeyName, businessUserReducerName } from 'redux/business-user/reducer';
import { fetchCompanyDetails } from 'redux/company/actions';
import { companyDetailsPerLocationsStoreKeyName, companyReducerName } from 'redux/company/reducer';
import { showModal } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import BusinessUserDetailsPage from './BusinessUserDetailsPage.layout';


const mapStateToProps = (state) => ({
    modalState: state.modal,

    companyEntityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: companyReducerName,
        fieldName: companyDetailsPerLocationsStoreKeyName,
        baseURLSegmentsCount: 2,
    }),

    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: businessUserReducerName,
        fieldName: businessUserDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchBusinessUserDetails: (queryParams, locationPathname) => dispatch(fetchBusinessUserDetails(queryParams, locationPathname)),
    dispatchClearBusinessUserDetails: (locationPathname) => dispatch(clearBusinessUserDetails(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: businessUserReducerName,
        fieldName: businessUserDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchFetchCompanyDetails: (queryParams, locationPathname) => dispatch(fetchCompanyDetails(queryParams, locationPathname)),
    dispatchShowModal: (data) => dispatch(showModal(data)),
    dispatchSetReadOnlyMode: (readOnlyInfo) => dispatch(setReadOnlyMode(readOnlyInfo)),
    dispatchClearReadOnlyMode: () => dispatch(clearReadOnlyMode()),
});


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BusinessUserDetailsPage);
