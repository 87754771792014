import PropTypes from 'prop-types';


export const CountryObjectProps = PropTypes.shape({
    calling_code: PropTypes.string.isRequired, // "354"
    capital: PropTypes.string, // "Reykjavik"
    citizenship: PropTypes.string.isRequired, // "Icelander"
    country_code: PropTypes.string.isRequired, // "352"
    created_at: PropTypes.string,
    currency: PropTypes.string,
    currency_code: PropTypes.string, // "ISK"
    currency_sub_unit: PropTypes.string, // ""
    currency_symbol: PropTypes.string,
    deleted_at: PropTypes.string,
    document: PropTypes.number, // 0
    eea: PropTypes.number, // 0
    enabled: PropTypes.number, // 0
    flag: PropTypes.string, // "IS.png"
    full_name: PropTypes.string, // "Republic of Iceland"
    id: PropTypes.number.isRequired, // 352 XXX country_code duplicates this
    iso_31662: PropTypes.string.isRequired, // "IS"
    iso_31663: PropTypes.string.isRequired, // "ISL"
    name: PropTypes.string.isRequired, // "Iceland"
    region_code: PropTypes.string.isRequired, // "150"
    risk_clients: PropTypes.number.isRequired, // 2
    risk_consumer: PropTypes.number.isRequired, // 2
    sub_region_code: PropTypes.string.isRequired, // 2 "154"
    updated_at: PropTypes.string,
});

export const CountriesListProps = PropTypes.arrayOf(CountryObjectProps);

export const CountryIdType = Object.freeze({
    id: 'id',
    iso_31663: 'iso_31663',
    iso_31662: 'iso_31662',
});

export const CountryIdTypeProp = PropTypes.oneOf(Object.keys(CountryIdType));

export default {
    CountryObjectProps,
    CountriesListProps,
    CountryIdType,
    CountryIdTypeProp,
};
