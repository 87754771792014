import { standardPaginationOptions } from 'config/config';

import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton, Table } from 'antd';
import React from 'react';


import { LoadWrapperProps, TableComponentProps, CustomTableProps } from './propTypes';


const antIcon = <LoadingOutlined spin />;

// Wrapper which adds loader to Table
function LoadableWrapper({
    isLoading,
    children,
    active,
}) {
    return <Skeleton loading={isLoading} active={active}>{children}</Skeleton>;
}

LoadableWrapper.propTypes = LoadWrapperProps.props;
LoadableWrapper.defaultProps = LoadWrapperProps.default;

// Table component itself
function TableComponent({
    rowKey,
    pagination,
    onChange,
    columns,
    dataSource,
    loading,
}) {
    return (
        <Table
            tableLayout="auto"
            rowKey={rowKey}
            scroll={{ x: true }}
            loading={loading ? {
                indicator: antIcon,
                size: 'large',
                spinning: true,
            } : false}
            pagination={{
                showSizeChanger: true,
                pageSizeOptions: standardPaginationOptions,
                ...pagination,
            }}
            onChange={onChange}
            columns={columns}
            dataSource={dataSource}
        />
    );
}

TableComponent.propTypes = TableComponentProps.props;
TableComponent.defaultProps = TableComponentProps.default;

// Custom Table component - exportable Component
function CustomTable({
    isLoading,
    loadable,
    isTableLoading,
    ...restProps
}) {
    return (
        <LoadableWrapper isLoading={isLoading} active={loadable}>
            <TableComponent {...restProps} loading={isTableLoading} loadable={loadable} />
        </LoadableWrapper>
    );
}

CustomTable.propTypes = CustomTableProps.props;
CustomTable.defaultProps = CustomTableProps.default;

export default CustomTable;
