export const createCreateBusinessUserErrorMessage = (t, error) => {
    const handlers = [
        {
            predicate: () => error?.messageCode === 'USER_EMAIL_NOT_UNIQUE',
            handler: () => (t('businessUsers:validationMsg.userEmailNotUnique')),
        },
        {
            predicate: () => true,
            handler: () => (t('common:genericApiError')),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export default { createCreateBusinessUserErrorMessage };
