import { FETCH_CLIENT_DETAILS_SUCCESS, UPDATE_CLIENT_SETTINGS_SUCCESS } from 'redux/client/actions.types';
import { ADD_CLIENT_SUCCESS } from 'redux/client-add/actions.types';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import { normaliseObjectKeys } from 'redux/current-user/reducer';

import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CLIENT_CONTEXT, SET_CLIENT_CONTEXT } from './actions.types';


export const clientContextReducerName = 'clientContext';

const initialState = {
    clientIdContext: null,
    clientsConfigs: {},
};

const updateSuperAdminsClientsConfigs = (state, action) => {
    state.clientsConfigs = {
        ...state.clientsConfigs,
        [`${action.payload?.responsePayload.id}`]: {
            configuration: normaliseObjectKeys(action.payload?.responsePayload?.configuration),
            timestamp: new Date().getTime(),
        },
    };
};


const handlers = {
    [SET_CLIENT_CONTEXT]: (state, action) => {
        state.clientIdContext = action.payload;
    },
    [CLEAR_CLIENT_CONTEXT]: (state) => {
        state.clientIdContext = null;
    },
    [FETCH_CLIENT_DETAILS_SUCCESS]: (state, action) => {
        updateSuperAdminsClientsConfigs(state, action);
    },
    [UPDATE_CLIENT_SETTINGS_SUCCESS]: (state, action) => {
        updateSuperAdminsClientsConfigs(state, action);
    },
    [ADD_CLIENT_SUCCESS]: (state, action) => {
        updateSuperAdminsClientsConfigs(state, action);
    },
    [CLEAR_CURRENT_USER]: () => initialState,
};

export default createReducer(initialState, handlers);
