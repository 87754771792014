import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import PermissionsList from 'components/common/templates/lists/PermissionsList';

import { WalletOutlined } from '@ant-design/icons';
import React from 'react';


export function createRoleFieldsConfig({
    roleData,
    t,
}) {
    if (roleData?.id) {
        return [
            {
                label: t('roleFields.id.label'),
                value: <StandardValue value={roleData.id} />,
            },
            {
                label: t('roleFields.name.label'),
                showEmpty: true,
                value: <StandardValue value={roleData.display_name} />,
            },
            {
                label: t('roleFields.description.label'),
                showEmpty: true,
                value: <StandardValue value={roleData.description} />,
            },
            {
                label: t('roleFields.all.label'),
                showEmpty: true,
                value: booleanAsIconsRenderer(roleData, 'all', t),
            },
            {
                label: t('roleFields.client.label'),
                showEmpty: true,
                value: booleanAsIconsRenderer(roleData, 'client', t),
            },
            {
                label: t('roleFields.company.label'),
                showEmpty: true,
                value: booleanAsIconsRenderer(roleData, 'company', t),
            },
            {
                label: t('roleFields.own.label'),
                showEmpty: true,
                value: booleanAsIconsRenderer(roleData, 'own', t),
            },
            {
                label: t('roleFields.reserved.label'),
                showEmpty: true,
                value: booleanAsIconsRenderer(roleData, 'reserved', t),
            },
        ];
    }

    return [];
}


export const createRoleDetailsTabsConfig = ({
    rolePermissionsListData = [],
    isLoadingPermissions,
    dispatchSetQueryParams,
    dispatchFetchPermissionsForRole,
    t,
}) => [
    ...(rolePermissionsListData ? [{
        icon: <WalletOutlined />,
        label: t('Permissions'),
        hasList: true,
        activeMainTabSegmentURL: 'permissions',
        content: (
            <PermissionsList
                rolePermissionsListData={rolePermissionsListData}
                isLoading={isLoadingPermissions}
                onUpdate={dispatchSetQueryParams}
                onRefresh={dispatchFetchPermissionsForRole}
            />
        ),
    }] : []),

];


export default { createRoleFieldsConfig, createRoleDetailsTabsConfig };
