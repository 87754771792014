/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import {
    getAddressesAPI,
    getTargetIdDeliveryAddress,
    updateAddressId,
} from './index';


class AddressesService {
    public getTargetDeliveryAddress(targetType, id): Promise<ApiResponse | ApiError> {
        return getTargetIdDeliveryAddress(targetType, id);
    }

    public updateAddress(addressId, requestPayload): Promise<ApiResponse | ApiError> {
        return updateAddressId(addressId, requestPayload);
    }

    public getAddresses(rawQueryParams): Promise<ApiResponse | ApiError> {
        const queryParams = {
            ...(rawQueryParams.limit && { limit: rawQueryParams.limit }),
            ...(rawQueryParams.offset && { offset: rawQueryParams.offset }),
            ...(rawQueryParams.q && { q: rawQueryParams.q }),
            ...(rawQueryParams.type && { type: rawQueryParams.type }),
            ...(rawQueryParams.order && { order: rawQueryParams.order }),
            ...(rawQueryParams.clientId && { clientId: rawQueryParams.clientId }),
            ...(rawQueryParams.memberId && { memberId: rawQueryParams.memberId }),
            ...(rawQueryParams.companyId && { companyId: rawQueryParams.companyId }),
            ...(rawQueryParams.businessUserId && { businessUserId: rawQueryParams.businessUserId }),

        };

        return getAddressesAPI({ params: queryParams });
    }
}

export default AddressesService;

