import StatusTimeline from 'components/common/organisms/StatusTimeline';
import createTransactionStatusColumns
    from 'components/pages/authorised/TransactionDetailsPage/tabs/StatusHistory/tableColumns';

import React, { useCallback } from 'react';


import { ListWithStatusTimelineProps } from './ListWithStatusTimeline.types';
import StatusHistoryList from '../lists/StatusHistoryList';


function ListWithStatusTimeline({
    reducerName,
    listsByLocationStoreKeyName,
    fetcherListAction,
    clearListAction,
    contextEnforcedQueryParams,
    statusItemRenderer,
    arrowRenderer,
    defaultItemsListQueryParams,
    t,
}: ListWithStatusTimelineProps) {
    const createCustomHeadingContent = useCallback((listData) => (
        <StatusTimeline
            t={t}
            listData={listData}
            statusItemRenderer={statusItemRenderer}
            arrowRenderer={arrowRenderer}
        />
    ), [statusItemRenderer, arrowRenderer]);


    return (
        <StatusHistoryList
            removeFilterSection
            defaultItemsListQueryParams={defaultItemsListQueryParams}
            reducerName={reducerName}
            listsByLocationStoreKeyName={listsByLocationStoreKeyName}
            fetcherListAction={fetcherListAction}
            clearListAction={clearListAction}
            contextEnforcedQueryParams={contextEnforcedQueryParams}
            createTableColumns={createTransactionStatusColumns}
            createCustomHeadingContent={createCustomHeadingContent}
        />
    );
}


export default ListWithStatusTimeline;
