import { HttpService } from 'services/http';


export const fetchApplicationsAPI = (queryParams = {}) => HttpService.get('/v2/applications', queryParams);
export const fetchApplicationIdDetails = (applicationId) => HttpService.get(`/v2/applications/${applicationId}`);

export const createApplicationAPI = (requestPayload) => HttpService.post('/v2/applications', requestPayload);

export const deleteApplicationId = (applicationId) => HttpService.delete(`/v2/applications/${applicationId}`);

export const updateApplicationId = (applicationId, requestPayload) => HttpService.put(`/v2/applications/${applicationId}`, requestPayload);

