import StandardPageHeading from 'components/layouts/AuthorisedLayout/StandardPageHeading';
import StandardPageWrapper from 'components/layouts/AuthorisedLayout/StandardPageWrapper';
import AddRoleForm from 'components/pages/authorised/AddRole/AddRoleForm';

import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';


function AddRolePage({ t }) {
    const location = useLocation();
    const { clientId } = useParams();

    return (
        <>
            <StandardPageHeading
                title={t('Add role')}
            />

            <StandardPageWrapper>
                {location?.pathname
                    ? (
                        <AddRoleForm
                            locationPathname={location.pathname}
                            clientId={clientId}
                        />
                    )
                    : (<Skeleton loading active />)}

            </StandardPageWrapper>
        </>
    );
}

AddRolePage.propTypes = { t: PropTypes.func.isRequired };

export default AddRolePage;
