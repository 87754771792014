import { statusHistoryRouteSegmentPath, transactionRootRoutePath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import { PREFORM_NAVIGATION_SUCCESS } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists, updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    CLEAR_TRANSACTION_STATUS_HISTORY,
    FETCH_TRANSACTION_STATUS_HISTORY,
    FETCH_TRANSACTION_STATUS_HISTORY_FAILURE,
    FETCH_TRANSACTION_STATUS_HISTORY_SUCCESS,
} from './action.types';


export const transactionStatusHistoryReducerName = 'transactionStatusHistory';
export const transactionStatusHistoryListPerLocationStoreKeyName = 'transactionStatusHistoryListPerLocation';


export const defaultTransactionStatusHistoryQueryParams = {
    limit: 100,
    offset: 0,
    sortBy: 'createdBy',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
};

const defaultTransactionStatusHistoryValues = { [transactionStatusHistoryListPerLocationStoreKeyName]: {} };


const initialState = {
    //
    ...defaultTransactionStatusHistoryValues,
};

const handlers = {
    [FETCH_TRANSACTION_STATUS_HISTORY]: (state, action) => {
        state[transactionStatusHistoryListPerLocationStoreKeyName] = updateStandardLists(state[transactionStatusHistoryListPerLocationStoreKeyName], action);
    },
    [FETCH_TRANSACTION_STATUS_HISTORY_SUCCESS]: (state, action) => {
        state[transactionStatusHistoryListPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[transactionStatusHistoryListPerLocationStoreKeyName], action);
    },
    [FETCH_TRANSACTION_STATUS_HISTORY_FAILURE]: (state, action) => {
        state[transactionStatusHistoryListPerLocationStoreKeyName] = updateStandardListsOnFailure(state[transactionStatusHistoryListPerLocationStoreKeyName], action);
    },
    [CLEAR_TRANSACTION_STATUS_HISTORY]: (state, action) => {
        state[transactionStatusHistoryListPerLocationStoreKeyName] = updateStandardListsOnCleanup(
            state[transactionStatusHistoryListPerLocationStoreKeyName],
            action.payload.locationPathname,
        );
    },

    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === transactionStatusHistoryReducerName
      && action.payload?.fieldName === transactionStatusHistoryListPerLocationStoreKeyName
        ) {
            state[transactionStatusHistoryListPerLocationStoreKeyName] = updateStandardListDataQueryParams(
                { ...state?.[transactionStatusHistoryListPerLocationStoreKeyName] },
                action,
            );
        }
    },

    [PREFORM_NAVIGATION_SUCCESS]: (state, action) => {
        state[transactionStatusHistoryListPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[transactionStatusHistoryListPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [statusHistoryRouteSegmentPath],
                observedRootPaths: [transactionRootRoutePath],
            },
        );
    },


    [CLEAR_CURRENT_USER]: () => initialState,
};

export default createReducer(initialState, handlers);
