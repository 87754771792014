import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const InfoIconAndText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  max-width: 100%;
`;

const InfoIcon = styled(InfoCircleOutlined)`
  margin-left: 20px;
  cursor: help;
`;

export default {
    InfoIconAndText,
    InfoIcon,
};
