import { createClientDetailsGroupsListPath } from 'config/routes.helpers';
import { updateGroupName } from 'redux/group/actions';
import { createNewGroup, fetchGroupsList } from 'redux/groups/actions';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import CreateEditGroup from './CreateEditGroup.layout';


const mapStateToProps = (state, ownProps) => ({
    clientGroupsListData: getStandardListData({
        state,
        reducerName: groupsReducerName,
        listsByLocationStoreKeyName: groupsListsPerLocationStoreKeyName,
        customLocation: createClientDetailsGroupsListPath(ownProps?.initialValues?.clientId),
    }),
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,

});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchFetchGroups: ({ clientId }) => dispatch(fetchGroupsList({ clientId }, createClientDetailsGroupsListPath(ownProps?.initialValues?.clientId))),
    dispatchUpdateGroupName: ({ clientId, groupId, newGroupName, locationPathname }) => dispatch(updateGroupName({ clientId, groupId, newGroupName, locationPathname })),
    dispatchCreateNewGroup: ({ clientId, groupName, locationPathname }) => dispatch(createNewGroup({ clientId, groupName, locationPathname })),
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
});

const decorate = compose(
    withTranslation('groups'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CreateEditGroup);
