import AccountDetailsPage from 'components/pages/authorised/AccountDetailsPage';
import AddClientPage from 'components/pages/authorised/AddClient';
import AddCompanyPage from 'components/pages/authorised/AddCompanyPage';
import AddEditBeneficiary from 'components/pages/authorised/AddEditBeneficiary';
import AddMember from 'components/pages/authorised/AddMember';
import AddMembersToGroup from 'components/pages/authorised/AddMembersToGroup';
import AddPromotionWrapper from 'components/pages/authorised/AddPromotion/AddPromotionWrapper';
import AddRolePage from 'components/pages/authorised/AddRole';
import Attributions from 'components/pages/authorised/Attributions';
import BeneficiaryDetailsPage from 'components/pages/authorised/BeneficiaryDetailsPage';
import BusinessUserDetails from 'components/pages/authorised/BusinessUserDetailsPage';
import CardDetails from 'components/pages/authorised/CardDetails';
import Cards from 'components/pages/authorised/Cards';
import OrderCard from 'components/pages/authorised/CardsOrderForm';
import ClientDetails from 'components/pages/authorised/ClientDetails';
import Clients from 'components/pages/authorised/Clients';
import Companies from 'components/pages/authorised/Companies';
import CompanyDetails from 'components/pages/authorised/CompanyDetails';
import Dashboard from 'components/pages/authorised/Dashboard';
import EditClientSettingsPage from 'components/pages/authorised/EditClientSettings';
import EditRolePage from 'components/pages/authorised/EditRolePage';
import FailedWebhookDetailsPage from 'components/pages/authorised/FailedWebhookDetailsPage';
import Faq from 'components/pages/authorised/Faq';
import FeeDetails from 'components/pages/authorised/FeeDetailsPage';
import GlobalListsPage from 'components/pages/authorised/GlobalListsPage';
import GroupDetailsPage from 'components/pages/authorised/GroupDetailsPage';
import LimitDetails from 'components/pages/authorised/LimitDetails';
import Limits from 'components/pages/authorised/Limits';
import MemberDetails from 'components/pages/authorised/MemberDetails';
import Members from 'components/pages/authorised/Members';
import PrivacyPolicy from 'components/pages/authorised/PrivacyPolicy';
import PromotionDetails from 'components/pages/authorised/PromotionDetails';
import RoleDetails from 'components/pages/authorised/RoleDetails';
import Roles from 'components/pages/authorised/Roles';
import TransactionDetailsPage from 'components/pages/authorised/TransactionDetailsPage';
import Transactions from 'components/pages/authorised/Transactions';
import UserDetails from 'components/pages/authorised/UserDetails';
import UserProfile from 'components/pages/authorised/UserProfile';
import Users from 'components/pages/authorised/Users';
import ForgotPassword from 'components/pages/unauthorised/ForgotPassword';
import Login from 'components/pages/unauthorised/Login';
import PasscodeConfirm from 'components/pages/unauthorised/PasscodeConfirm';
import SetPassword from 'components/pages/unauthorised/SetPassword';
import {
    accountRootRoutePath, addMembersRouteSegmentPath,
    addPromotionRouteSegmentPath, attributionsRoutePath, beneficiaryRootRoutePath, cardRootRoutePath,
    cardsOrderRouteSegmentPath,
    cardsRootRoutePath, clientRootRoutePath, clientRouteSegmentPath, clientsRootRoutePath, companiesRootRoutePath, companyRootRoutePath, currentUserProfileRootRoutePath,
    dashboardRootRoutePath, editRoleRootRouteSegmentPath, editSettingsRouteSegmentPath,
    businessUserRootRoutePath, faqRootRoutePath, feeRootRoutePath, firstLevelTabsSlugName,
    forgotPasswordRootRoutePath, genericAddRouteSegmentPath, genericEditRouteSegmentPath, globalListsRootRoutePath, groupRootRoutePath,
    loginRootRoutePath, memberRootRoutePath, membersRootRoutePath,
    passcodeConfirmRootRoutePath, privacyPolicyRootRoutePath, promotionRootRoutePath, roleRootRoutePath, rolesRootRoutePath, secondLevelTabsSlugName,
    setPasswordRootRoutePath,
    transactionRootRoutePath,
    transactionsRootRoutePath, userRootRoutePath, usersRootRoutePath, failedWebhookRootRoutePath,
    limitsRootRoutePath, limitRootPath,
} from 'config/routes';
import { createAddRolePath } from 'config/routes.helpers';
import { permissionSetAddMembersToGroup, permissionSetOrderCardsTargetSelection, PN } from 'constants/PermissionsModel';


export const firstLevelTabsParam = `:${firstLevelTabsSlugName}`;
export const secondLevelTabsParam = `:${secondLevelTabsSlugName}`;


function createClientsRoutes() {
    return [
        {
            isIndex: true,
            path: clientsRootRoutePath,
            component: Clients,
            isAuthorisedOnly: true,
            isSuperAdminOnly: true,
            hasPermission: PN.CLIENTS.READ,
            childrenRoutes: [
                {
                    path: genericAddRouteSegmentPath,
                    component: AddClientPage,
                    isAuthorisedOnly: true,
                    hasPermission: PN.CLIENTS.CREATE,
                },
            ],
        },
    ];
}

function createPromotionsRoutes() {
    return [
        {
            isIndex: true,
            path: `/${addPromotionRouteSegmentPath}/:clientId`,
            component: AddPromotionWrapper,
            isAuthorisedOnly: true,
            requiredFeatures: ['groupsEnabled'],
            hasPermission: PN.PROMOTIONS.CREATE,
            childrenRoutes: [{
                path: ':selectedGroup',
                component: AddPromotionWrapper,
                isAuthorisedOnly: true,
                requiredFeatures: ['groupsEnabled'],
                hasPermission: PN.PROMOTIONS.CREATE,
            }],
        },
        {
            isIndex: true,
            path: `${promotionRootRoutePath}/:promotionUuid`,
            component: PromotionDetails,
            isAuthorisedOnly: true,
            requiredFeatures: ['groupsEnabled'],
            hasPermission: PN.PROMOTIONS.READ_BY_ID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: PromotionDetails,
                isAuthorisedOnly: true,
                requiredFeatures: ['groupsEnabled'],
                hasPermission: PN.PROMOTIONS.READ_BY_ID,
            }],
        },
    ];
}

function createFeesRoutes() {
    return [
        {
            isIndex: true,
            path: `${feeRootRoutePath}/:feeUuid`,
            component: FeeDetails,
            isAuthorisedOnly: true,
            requiredFeatures: ['groupsEnabled'],
            hasPermission: PN.FEES.READ_BY_ID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: FeeDetails,
                isAuthorisedOnly: true,
                requiredFeatures: ['groupsEnabled'],
                hasPermission: PN.FEES.READ_BY_ID,
            }],
        },
    ];
}

function createGroupsRoutes() {
    return [
        {
            isIndex: true,
            path: `${groupRootRoutePath}/:groupId/${clientRouteSegmentPath}/:clientId`,
            component: GroupDetailsPage,
            isAuthorisedOnly: true,
            requiredFeatures: ['groupsEnabled'],
            hasPermission: PN.GROUPS.READ_BY_ID,
            childrenRoutes: [
                {
                    path: addMembersRouteSegmentPath,
                    component: AddMembersToGroup,

                    isAuthorisedOnly: true,
                    requiredFeatures: ['groupsEnabled'],
                    hasPermissions: permissionSetAddMembersToGroup,
                },
                {
                    path: firstLevelTabsParam,
                    component: GroupDetailsPage,

                    isAuthorisedOnly: true,
                    requiredFeatures: ['groupsEnabled'],
                    hasPermission: PN.GROUPS.READ_BY_ID,
                },
            ],
        },
    ];
}

function createClientRoutes() {
    return [
        {
            isIndex: true,
            path: `${clientRootRoutePath}/:clientId`,
            component: ClientDetails,
            isAuthorisedOnly: true,
            hasPermission: PN.CLIENTS.READ_BY_ID,
            childrenRoutes: [
                {
                    path: editSettingsRouteSegmentPath,
                    component: EditClientSettingsPage,

                    isAuthorisedOnly: true,
                    isSuperAdminOnly: true,
                    hasPermission: PN.CLIENTS.UPDATE,
                },
                {
                    path: firstLevelTabsParam,
                    component: ClientDetails,

                    isAuthorisedOnly: true,
                    hasPermission: PN.CLIENTS.READ_BY_ID,
                    childrenRoutes: [{
                        path: secondLevelTabsParam,
                        component: ClientDetails,

                        isAuthorisedOnly: true,
                        hasPermission: PN.CLIENTS.READ_BY_ID,
                    }],
                },
            ],
        },
    ];
}

function createMembersRoutes() {
    return [
        {
            isIndex: true,
            path: membersRootRoutePath,
            component: Members,
            isAuthorisedOnly: true,
            hasPermission: PN.MEMBERS.READ_ALL,
            childrenRoutes: [
                {
                    path: genericAddRouteSegmentPath,
                    component: AddMember,
                    isIndex: true,
                    isAuthorisedOnly: true,
                    hasPermission: PN.MEMBERS.CREATE,
                    childrenRoutes: [
                        {
                            path: ':clientId',
                            component: AddMember,
                            isAuthorisedOnly: true,
                            hasPermission: PN.MEMBERS.CREATE,
                        }],
                },
            ],
        },
    ];
}

function createAccountRoutes() {
    return [
        {
            isIndex: true,
            path: `${accountRootRoutePath}/:accountId`,
            component: AccountDetailsPage,

            isAuthorisedOnly: true,
            hasPermission: PN.ACCOUNTS.READ_BY_ID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: AccountDetailsPage,
                isAuthorisedOnly: true,
                hasPermission: PN.ACCOUNTS.READ_BY_ID,
            }],
        },
    ];
}


function createBeneficiaryRoutes() {
    return [
        {
            path: `${beneficiaryRootRoutePath}/${genericAddRouteSegmentPath}`,
            component: AddEditBeneficiary,

            isAuthorisedOnly: true,
            requiredFeatures: ['beneficiariesEnabled'],
            hasPermissions: [PN.BENEFICIARIES.CREATE, PN.BENEFICIARIES.REQUIRED_DETAILS],
        },
        {
            path: `${beneficiaryRootRoutePath}/${genericEditRouteSegmentPath}/:beneficiaryUuid`,
            component: AddEditBeneficiary,

            isAuthorisedOnly: true,
            requiredFeatures: ['beneficiariesEnabled'],
            hasPermissions: [PN.BENEFICIARIES.UPDATE, PN.BENEFICIARIES.REQUIRED_DETAILS],
        },
        {
            path: `${beneficiaryRootRoutePath}/:beneficiaryUuid`,
            component: BeneficiaryDetailsPage,
            isAuthorisedOnly: true,
            requiredFeatures: ['beneficiariesEnabled'],
            hasPermission: PN.BENEFICIARIES.READ_BY_ID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: BeneficiaryDetailsPage,
                isAuthorisedOnly: true,
                requiredFeatures: ['beneficiariesEnabled'],
                hasPermission: PN.BENEFICIARIES.READ_BY_ID,
            }],
        },

    ];
}

function createCardsRoutes() {
    return [
        {
            isIndex: true,
            path: cardsRootRoutePath,
            component: Cards,
            isAuthorisedOnly: true,
            hasPermission: PN.CARDS.READ,
            childrenRoutes: [
                {
                    path: cardsOrderRouteSegmentPath,
                    component: OrderCard,
                    isAuthorisedOnly: true,
                    hasPermissions: permissionSetOrderCardsTargetSelection,
                },
            ],
        }];
}

function createCompaniesRoutes() {
    return [
        {
            isIndex: true,
            path: companiesRootRoutePath,
            component: Companies,
            isAuthorisedOnly: true,
            hasOneOfPermissions: [PN.COMPANIES.READ, PN.COMPANIES.READ_BY_CLIENT],
            childrenRoutes: [
                {
                    path: genericAddRouteSegmentPath,
                    component: AddCompanyPage,
                    isIndex: true,
                    isAuthorisedOnly: true,
                    hasPermission: PN.COMPANIES.CREATE,
                    childrenRoutes: [
                        {
                            path: ':clientId',
                            component: AddCompanyPage,
                            isAuthorisedOnly: true,
                            hasPermission: PN.COMPANIES.CREATE,
                        }],
                },
            ],
        }];
}


function createSideBarGeneralListsRoutes() {
    return [
        {
            path: transactionsRootRoutePath,
            component: Transactions,
            isAuthorisedOnly: true,
            hasPermission: PN.TRANSACTIONS.READ_ALL,
        },
        ...createCompaniesRoutes(),
        {
            path: usersRootRoutePath,
            component: Users,
            isAuthorisedOnly: true,
            hasPermission: PN.USERS.READ,
        },
        {
            path: rolesRootRoutePath,
            component: Roles,
            isAuthorisedOnly: true,
            isSuperAdminOnly: true,
            hasPermission: PN.ROLES.READ,
        },
        {
            path: limitsRootRoutePath,
            component: Limits,
            isAuthorisedOnly: true,
            isSuperAdminOnly: true,
            hasPermission: PN.RULES.READ_ALL,
        },
    ];
}


function createEntitiesDetailsPagesRoutes() {
    return [
        {
            isIndex: true,
            path: `${businessUserRootRoutePath}/:businessUserId`,
            component: BusinessUserDetails,
            isAuthorisedOnly: true,
            hasPermission: PN.BUSINESS_USERS.READ_BY_ID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: BusinessUserDetails,
                isAuthorisedOnly: true,
                hasPermission: PN.BUSINESS_USERS.READ_BY_ID,
                childrenRoutes: [{
                    path: secondLevelTabsParam,
                    component: BusinessUserDetails,

                    isAuthorisedOnly: true,
                    hasPermission: PN.BUSINESS_USERS.READ_BY_ID,
                }],
            }],
        },
        {
            isIndex: true,
            path: `${userRootRoutePath}/:userId`,
            component: UserDetails,
            isAuthorisedOnly: true,
            hasPermission: PN.USERS.READ_BY_ID,
        },
        {
            isIndex: true,
            path: `${roleRootRoutePath}/:roleId`,
            component: RoleDetails,
            isAuthorisedOnly: true,
            hasPermission: PN.PERMISSIONS.READ_BY_ROLE,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: RoleDetails,
                isAuthorisedOnly: true,
                hasPermission: PN.PERMISSIONS.READ_BY_ROLE,
            }],
        },

        {
            isIndex: true,
            path: `${editRoleRootRouteSegmentPath}/:roleId`,
            component: EditRolePage,
            isAuthorisedOnly: true,
        },

        {
            isIndex: true,
            path: createAddRolePath(),
            component: AddRolePage,
            isAuthorisedOnly: true,
        },

        {
            path: currentUserProfileRootRoutePath,
            component: UserProfile,
            isAuthorisedOnly: true,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: UserProfile,
                isAuthorisedOnly: true,
            }],
        },
        {
            isIndex: true,
            path: `${memberRootRoutePath}/:memberId`,
            component: MemberDetails,
            isAuthorisedOnly: true,
            hasPermission: PN.MEMBERS.READ_PROFILE,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: MemberDetails,
                isAuthorisedOnly: true,
                hasPermission: PN.MEMBERS.READ_PROFILE,
                childrenRoutes: [{
                    path: secondLevelTabsParam,
                    component: MemberDetails,
                    isAuthorisedOnly: true,
                    hasPermission: PN.MEMBERS.READ_PROFILE,
                }],
            }],
        },
        {
            isIndex: true,
            path: `${cardRootRoutePath}/:cardId`,
            component: CardDetails,
            isAuthorisedOnly: true,
            hasPermission: PN.CARDS.READ_BY_ID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: CardDetails,
                isAuthorisedOnly: true,
                hasPermission: PN.CARDS.READ_BY_ID,
                childrenRoutes: [{
                    path: secondLevelTabsParam,
                    component: CardDetails,
                    isAuthorisedOnly: true,
                    hasPermission: PN.CARDS.READ_BY_ID,
                }],
            }],
        },
        {
            isIndex: true,
            path: `${companyRootRoutePath}/:companyId`,
            component: CompanyDetails,
            isAuthorisedOnly: true,
            hasPermission: PN.COMPANIES.READ_BY_ID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: CompanyDetails,
                isAuthorisedOnly: true,
                hasPermission: PN.COMPANIES.READ_BY_ID,
                childrenRoutes: [{
                    path: secondLevelTabsParam,
                    component: CompanyDetails,
                    isAuthorisedOnly: true,
                    hasPermission: PN.COMPANIES.READ_BY_ID,
                }],
            }],
        },
        {
            isIndex: true,
            path: `${transactionRootRoutePath}/:transactionUuid`,
            component: TransactionDetailsPage,
            isAuthorisedOnly: true,
            hasPermission: PN.TRANSACTIONS.READ_BY_ID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: TransactionDetailsPage,
                isAuthorisedOnly: true,
                hasPermission: PN.TRANSACTIONS.READ_BY_ID,
            }],
        },
        {
            isIndex: true,
            path: `${limitRootPath}/:limitExtId`,
            component: LimitDetails,
            isAuthorisedOnly: true,
            hasPermission: PN.RULES.READ_BY_UUID,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: LimitDetails,
                isAuthorisedOnly: true,
                hasPermission: PN.RULES.READ_BY_UUID,
            }],
        },


        {
            isIndex: true,
            path: `${failedWebhookRootRoutePath}/:failedWebhookUuid`,
            component: FailedWebhookDetailsPage,
            isAuthorisedOnly: true,
            hasPermission: PN.WEBHOOKS.READ_ALL_FAILED,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: FailedWebhookDetailsPage,
                isAuthorisedOnly: true,
                hasPermission: PN.WEBHOOKS.READ_ALL_FAILED,
            }],
        },
    ];
}

export function createProtectedRoutesConfiguration() {
    return [
        {
            path: dashboardRootRoutePath,
            component: Dashboard,

            isAuthorisedOnly: true,
            isSuperAdminOnly: true,
        },
        {
            isIndex: true,
            path: globalListsRootRoutePath,
            component: GlobalListsPage,
            isAuthorisedOnly: true,
            isSuperAdminOnly: true,
            childrenRoutes: [{
                path: firstLevelTabsParam,
                component: GlobalListsPage,
                isAuthorisedOnly: true,
                isSuperAdminOnly: true,
            }],
        },
        {
            path: faqRootRoutePath,
            component: Faq,
            isAuthorisedOnly: true,
        },
        {
            path: privacyPolicyRootRoutePath,
            component: PrivacyPolicy,
            isAuthorisedOnly: true,
        },

        {
            path: attributionsRoutePath,
            component: Attributions,
            isSuperAdminOnly: true,
            isAuthorisedOnly: true,
        },

        // general lists exposed in sidebar navigation
        ...createSideBarGeneralListsRoutes(),
        ...createCardsRoutes(),
        ...createClientsRoutes(),
        ...createMembersRoutes(),


        // DETAILS PAGES
        ...createEntitiesDetailsPagesRoutes(),
        ...createAccountRoutes(),
        ...createClientRoutes(),
        ...createBeneficiaryRoutes(),
        ...createFeesRoutes(),
        ...createGroupsRoutes(),
        ...createPromotionsRoutes(),
    ];
}

export function createPublicRoutesConfiguration() {
    return [
        {
            path: loginRootRoutePath,
            component: Login,
        },
        {
            path: passcodeConfirmRootRoutePath,
            component: PasscodeConfirm,
        },
        {
            path: forgotPasswordRootRoutePath,
            component: ForgotPassword,
        },
        {
            path: setPasswordRootRoutePath,
            component: SetPassword,
        },
    ];
}


export const createDefaultRedirectionPath = ({ isAuthorised, isSuperAdmin, clientId }) => {
    if (isAuthorised && isSuperAdmin) {
        return dashboardRootRoutePath;
    }
    if (isAuthorised && !isSuperAdmin) {
        return `${clientRootRoutePath}/${clientId}`;
    }
    return loginRootRoutePath;
};

export default {
    createPublicRoutesConfiguration,
    createProtectedRoutesConfiguration,
    createDefaultRedirectionPath,
};

