export const FETCH_PROMOTION_DETAILS = 'FETCH_PROMOTION_DETAILS';
export const FETCH_PROMOTION_DETAILS_SUCCESS = 'FETCH_PROMOTION_DETAILS_SUCCESS';
export const FETCH_PROMOTION_DETAILS_FAILURE = 'FETCH_PROMOTION_DETAILS_FAILURE';
export const CLEAR_PROMOTION_DETAILS = 'CLEAR_PROMOTION_DETAILS';


export const REMOVE_PROMOTION = 'REMOVE_PROMOTION';
export const REMOVE_PROMOTION_SUCCESS = 'REMOVE_PROMOTION_SUCCESS';
export const REMOVE_PROMOTION_FAILURE = 'REMOVE_PROMOTION_FAILURE';


export const REMOVE_PROMOTION_FROM_GROUP = 'REMOVE_PROMOTION_FROM_GROUP';
export const REMOVE_PROMOTION_FROM_GROUP_SUCCESS = 'REMOVE_PROMOTION_SUCCESS';
export const REMOVE_PROMOTION_FROM_GROUP_FAILURE = 'REMOVE_PROMOTION_FAILURE';


export const DISABLE_PROMOTION = 'DISABLE_PROMOTION';
export const DISABLE_PROMOTION_SUCCESS = 'DISABLE_PROMOTION_SUCCESS';
export const DISABLE_PROMOTION_FAILURE = 'DISABLE_PROMOTION_FAILURE';


export const ENABLE_PROMOTION = 'ENABLE_PROMOTION';
export const ENABLE_PROMOTION_SUCCESS = 'ENABLE_PROMOTION_SUCCESS';
export const ENABLE_PROMOTION_FAILURE = 'ENABLE_PROMOTION_FAILURE';
