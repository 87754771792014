export const getProperGetRiskScoreHistoryEndpoint = ({
    payload,
    clients,
    companies,
}) => {
    const handlers = [
        {
            predicate: () => payload?.queryParams?.companyId > 0,
            handler: () => companies.getCompanyRiskDetails(payload?.queryParams?.companyId),
        },
        {
            predicate: () => true,
            handler: () => clients.getClientRiskDetails(payload?.queryParams?.clientId),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export default { getProperGetRiskScoreHistoryEndpoint };
