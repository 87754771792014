import { Alert, Col } from 'antd';
import styled from 'styled-components';


const FormCol = styled(Col)`
  margin-left: 40px;
  padding-left: 40px;
  border-left: 1px solid #F0F0F0;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  justify-content: right;
  align-items: center;
`;


const HintAlert = styled(Alert)`
  margin: 20px 0;
`;
export default {
    FormCol,
    ButtonsWrapper,
    HintAlert,
};
