import withAccessControl from 'hocs/withAccessControl';
import { showUnlinkCardModal } from 'redux/card/actions';
import { showModal } from 'redux/modal/actions';
import { requestNavigation } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import CompanyCardsList from './CompanyCardsList.layout';


const mapDispatchToProps = (dispatch) => ({
    dispatchShowModal: (data) => dispatch(showModal(data)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
    dispatchShowUnlinkCardModal: (cardRecord, meta) => dispatch(showUnlinkCardModal(cardRecord, meta)),
});


const decorate = compose(
    withTranslation('cards'),
    withAccessControl,
    connect(null, mapDispatchToProps),
);


export default decorate(CompanyCardsList);
