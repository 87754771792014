import { AddClientStepsDefinitions } from 'constants/AddClientModel';
import { globalLocation } from 'constants/NavigationModel';
import { fetchClientsList } from 'redux/clients/actions';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { setMultiStepsFormStep } from 'redux/multi-steps-form/actions';
import { unblockNavigation } from 'redux/navigation/actions';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    addClientFailure, addClientSuccess,
    fetchBpsProductsSuccess, fetchBpsProductsFailure,
    fetchDefaultClientConfigurationSuccess, fetchDefaultClientConfigurationFailure,
} from './actions';
import { ADD_CLIENT, FETCH_BPS_PRODUCTS, FETCH_DEFAULT_CLIENT_CONFIGURATION } from './actions.types';


export const onFetchDefaultClientConfiguration = (action$, state$, { configuration }) => action$.pipe(
    ofType(FETCH_DEFAULT_CLIENT_CONFIGURATION),
    switchMap(({ payload }) => from(configuration.fetchDefaultClientConfiguration())
        .pipe(
            switchMap((response) => of(fetchDefaultClientConfigurationSuccess(response.data, payload.locationPathname))),
            catchError(() => of(fetchDefaultClientConfigurationFailure(payload.locationPathname))),
        )),
);

export const onFetchBpsProducts = (action$, state$, { configuration }) => action$.pipe(
    ofType(FETCH_BPS_PRODUCTS),
    switchMap(({ payload }) => from(configuration.fetchBPSAccountProducts(payload.queryParams))
        .pipe(
            switchMap((response) => of(fetchBpsProductsSuccess(response.data, payload.locationPathname))),
            catchError(() => of(fetchBpsProductsFailure(payload.locationPathname))),
        )),
);

export const onAddClient = (action$, state$, { clients }) => action$.pipe(
    ofType(ADD_CLIENT),
    switchMap(({ payload }) => from(clients.addNewClient(payload.requestData))
        .pipe(
            switchMap((response) => {
                const currentClientListQueryParams = state$.value[clientsReducerName][clientsListsPerLocationStoreKeyName]?.[globalLocation]?.queryParams;

                return of(
                    unblockNavigation(),
                    addClientSuccess(response.data, payload.locationPathname),
                    setMultiStepsFormStep(AddClientStepsDefinitions.SUCCESS, payload.locationPathname),
                    fetchClientsList(currentClientListQueryParams, globalLocation, true),
                );
            }),
            catchError(() => of(addClientFailure(payload.locationPathname))),
        )),
);


export default [
    onFetchDefaultClientConfiguration,
    onFetchBpsProducts,
    onAddClient,
];
