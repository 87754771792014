import CopyrightNotice from 'components/common/molecules/CopyrightNotice';
import { WL_CONFIG } from 'config/config';

import React from 'react';


import Styled from './PublicLayout.styled';


function PublicLayout({ children }) {
    return (
        <Styled.UnauthorisedOuterWrapper>
            <Styled.UnauthorisedContentWrapper>
                <Styled.UnauthorisedLogoWrapper>
                    {WL_CONFIG?.brandFullLogoUrl || WL_CONFIG?.brandSignetLogoUrl
                        ? (
                            <Styled.UnauthorisedLogo
                                src={WL_CONFIG?.brandFullLogoUrl || WL_CONFIG?.brandSignetLogoUrl}
                                alt={`${WL_CONFIG?.brandFullName} logo`}
                            />
                        )
                        : null}

                    <h1>{WL_CONFIG.applicationName}</h1>
                </Styled.UnauthorisedLogoWrapper>

                <Styled.UnauthorisedCard>
                    {children}
                </Styled.UnauthorisedCard>
                <Styled.CopyrightNoticeWrapper>
                    <CopyrightNotice />
                </Styled.CopyrightNoticeWrapper>

            </Styled.UnauthorisedContentWrapper>
        </Styled.UnauthorisedOuterWrapper>
    );
}

export default PublicLayout;
