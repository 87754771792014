/* eslint-disable class-methods-use-this */
import { SortDirections, SortDirectionsAPI } from 'constants/ApplicationStateModel';
import { ApiError, ApiResponse } from 'models/api/http';

import {
    getKycKybFlowsApi, getKycKybLogsApi,
    setKycKybFlowApi,
    triggerKycKybFlowApi,
} from './index';


class KycKybService {
    public getKycKybFlows(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { entityType, clientId, memberId, companyId, businessUserId } = rawQueryParams;

        const queryParams = {
            ...(entityType ? { entityType } : {}),
            ...(clientId > 0 ? { clientId } : {}),
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(businessUserId > 0 ? { businessUserId } : {}),
        };

        return getKycKybFlowsApi({ params: queryParams });
    }

    public setKycKybFlow(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { flowType, memberId, companyId, businessUserId } = rawRequestPayload;

        const requestPayload = {
            flow_type: flowType,
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(businessUserId > 0 ? { businessUserId } : {}),
        };
        return setKycKybFlowApi(requestPayload);
    }

    public triggerKycKybFlow(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { memberId, companyId, businessUserId } = rawRequestPayload;

        const requestPayload = {
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(businessUserId > 0 ? { businessUserId } : {}),
        };
        return triggerKycKybFlowApi(requestPayload);
    }

    public getKycKybLogs(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { memberId, companyId, businessUserId, sortOrder, ...restOfRawQueryParams } = rawQueryParams;

        const queryParams = {
            ...restOfRawQueryParams,
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(businessUserId > 0 ? { businessUserId } : {}),
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };

        return getKycKybLogsApi({ params: queryParams });
    }
}

export default KycKybService;
