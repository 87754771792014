import StandardListTable from 'components/common/organisms/StandardListTable';
import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';
import { ApplicationsListProp } from 'constants/ApplicationsModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultApplicationsListQueryParams } from 'redux/applications/reducer';
import { searchCaseInsensitive } from 'utils/string-tools';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import createApplicationsListTableColumns from './createTableColumns';


function ApplicationsList({
    // own props
    clientId,

    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    t,
    dispatchFetchApplicationsList,
    dispatchClearApplicationsList,
    dispatchSetQueryParams,
}) {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = { ...(clientId > 0 ? { clientId } : {}) };

    // 2. NON-standard BE endpoint -> queryParams & listFetching are NOT in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            // NO actual API call DISPATCH
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultApplicationsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchApplicationsList,
        dispatchClearItemsList: dispatchClearApplicationsList,
        dispatchSetQueryParams,
    });

    // 4. list specific stuff - local search, local sorting is in table configuration
    const filteredApplications = useMemo(
        () => {
            if (listData?.queryParams?.search && listData?.queryParams?.search?.length > 0) {
                return (listData?.items || []).filter((application) => searchCaseInsensitive(application?.name, listData?.queryParams?.search)
          || searchCaseInsensitive(application?.uuid, listData?.queryParams?.search)
          || searchCaseInsensitive(application?.scope, listData?.queryParams?.search)
          || searchCaseInsensitive(`${application?.id}`, listData?.queryParams?.search));
            }

            return listData?.items || [];
        }, [listData],
    );


    return (
        <StandardListTable
            onUpdate={fetchData}
            onListRefresh={() => dispatchFetchApplicationsList({ ...listData?.queryParams, ...contextEnforcedQueryParams }, enhancedCurrentLocation.pathname)}
            items={filteredApplications}
            totalItemsCount={filteredApplications.length}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createApplicationsListTableColumns({
                t,
                queryParams: listData?.queryParams,
            })}
        />
    );
}

ApplicationsList.propTypes = {
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: ApplicationsListProp,
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    t: PropTypes.func.isRequired,
    dispatchClearApplicationsList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
    dispatchFetchApplicationsList: PropTypes.func.isRequired,
    clientId: PropTypes.number,
};


export default ApplicationsList;
