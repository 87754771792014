import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_MEMBER_DETAILS,
    FETCH_MEMBER_DETAILS,
    FETCH_MEMBER_DETAILS_FAILURE,
    FETCH_MEMBER_DETAILS_SUCCESS,
    OPEN_UPDATE_MEMBER_MODAL,
    UPDATE_MEMBER,
    UPDATE_MEMBER_FAILURE,
    UPDATE_MEMBER_SUCCESS,
} from './actions.types';


export const fetchMemberDetails = createAction(FETCH_MEMBER_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchMemberDetailsSuccess = createAction(FETCH_MEMBER_DETAILS_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchMemberDetailsFailure = createAction(FETCH_MEMBER_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));

export const clearMemberDetails = createAction(CLEAR_MEMBER_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));

export const openUpdateMemberModal = createAction(OPEN_UPDATE_MEMBER_MODAL, (initialValues) => ({ payload: { initialValues } }));

export const updateMember = createAction(UPDATE_MEMBER,
    (memberId, requestPayload, locationPathname) => ({ payload: { memberId, requestPayload, locationPathname } }));
export const updateMemberSuccess = createAction(UPDATE_MEMBER_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const updateMemberFailure = createAction(UPDATE_MEMBER_FAILURE);
