import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import viewButtonRenderer from 'components/common/molecules/value-renderers/viewButtonRendener';
import { createRoleDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { sortBooleansByKey, sortDirections, sortNumbers, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import React from 'react';


function createColumns({
    accessControl,
    queryParams,
    dispatchRequestNavigation,
    t,
}) {
    const actionsColumn = [{
        title: t('roleFields.actions.columnName'),
        align: 'center',
        render: (_, record) => viewButtonRenderer({
            record,
            onClick: (event, record) => dispatchRequestNavigation(createRoleDetailsPath(record.id)),
            tooltipContent: t('roleFields.actions.viewDetails'),
            dataTestIdSuffix: 'role',
        }),
    }];

    const baseColumns = [
        {
            title: t('roleFields.id.label'),
            dataIndex: 'id',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            sortDirections,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => sortNumbers(a, b, 'id'),
        },
        {
            title: t('roleFields.name.label'),
            dataIndex: 'display_name',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            sortDirections,
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'display_name'),
        },
        {
            title: t('roleFields.description.label'),
            dataIndex: 'description',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            sortDirections,
            sorter: (a, b) => sortStringsAlphabetically(a, b, 'description'),
        },
        {
            title: t('roleFields.all.label'),
            dataIndex: 'all',
            render: (text, record) => booleanAsIconsRenderer(record, 'all', t),
            sortDirections,
            sorter: (a, b) => sortBooleansByKey(a, b, 'all'),
        },
        {
            title: t('roleFields.client.label'),
            dataIndex: 'client',
            render: (text, record) => booleanAsIconsRenderer(record, 'client', t),
            sortDirections,
            sorter: (a, b) => sortBooleansByKey(a, b, 'client'),
        },
        {
            title: t('roleFields.company.label'),
            dataIndex: 'company',
            render: (text, record) => booleanAsIconsRenderer(record, 'company', t),
            sortDirections,
            sorter: (a, b) => sortBooleansByKey(a, b, 'company'),
        },
        {
            title: t('roleFields.own.label'),
            dataIndex: 'own',
            render: (text, record) => booleanAsIconsRenderer(record, 'own', t),
            sortDirections,
            sorter: (a, b) => sortBooleansByKey(a, b, 'own'),
        },
        {
            title: t('roleFields.reserved.label'),
            dataIndex: 'reserved',
            render: (text, record) => booleanAsIconsRenderer(record, 'reserved', t),
            sortDirections,
            sorter: (a, b) => sortBooleansByKey(a, b, 'reserved'),
        },
    ];


    return [
        ...baseColumns,
        ...(accessControl.hasPermissions([PN.PERMISSIONS.READ_BY_ROLE, PN.ROLES.READ]) ? actionsColumn : []),
    ];
}


export default createColumns;

