import { EEA } from 'config/config';

import {
    isNil, isArray, pull, uniq, omit, keys,
} from 'lodash';

/**
 * Removes null and undefined fields, optionally also empty strings.
 * @param obj
 * @returns {Pick<object, Exclude<keyof object, [(null | undefined)[]][number]>>}
 */
export const cleanNilValues = (obj, removeEmptyStrings = true) => {
    const filterFunction = (key) => (removeEmptyStrings ? isNil(obj[key]) || obj?.[key].length === 0 : isNil(obj[key]));
    return omit(obj, keys(obj).filter(filterFunction));
};


/**
 * Prepare data before sending to API
 * @param countries
 * @returns {string|*}
 */
export const prepareCountriesOfOperations = (countries, returnArray = false) => {
    if (isArray(countries)) {
    // if EEA is selected remove it from array and add all EEA countries
        if (countries.includes('EEA')) {
            // remove EEA because its not real country iso code
            pull(countries, 'EEA');
            // add all countries that are part of EEA
            countries.push(...EEA);
        }
        if (returnArray) {
            return uniq(countries);
        }
        return uniq(countries).join('~');
    }
    return countries;
};
