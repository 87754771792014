import { HttpService } from 'services/http';


export const fetchGroupIdDetails = (groupId) => HttpService.get(`/v2/groups/${groupId}`);

export const createGroup = (payload) => HttpService.post('/v2/groups', payload);

export const editGroupIdName = (groupId, payload) => HttpService.patch(`/v2/groups/${groupId}`, payload);

export const assignPromotionIdsToGroupId = (groupId, payload) => HttpService.post(`/v2/groups/${groupId}/promotions`, payload);

export const fetchPromotionsForGroupId = (groupId) => HttpService.get(`/v2/groups/${groupId}/promotions`);

export const removePromotionIdFromGroupId = (groupId, promotionUuid) => HttpService.delete(`/v2/groups/${groupId}/promotions/${promotionUuid}`);

export const deleteGroupId = (groupId, newGroupIdAsQueryParam) => HttpService.delete(`/v2/groups/${groupId}`, undefined, newGroupIdAsQueryParam);

export const fetchMembersFormGroupId = (groupId, queryParams) => HttpService.get(`/v2/groups/${groupId}/members`, queryParams);

export const fetchMembersNotFormGroupId = (groupId, queryParams) => HttpService.get(`/v2/groups/${groupId}/members/inverse`, queryParams);

export const fetchGroupIdFees = (groupId) => HttpService.get(`/v2/groups/${groupId}/fees`);

// TODO investigate those ... potentially move this form here
export const fetchAllGroupsForClientId = (clientId) => HttpService.get(`/v2/clients/${clientId}/groups`);

export const assignMemberIdToGroupId = (memberId, groupId) => HttpService.put(`/v2/members/${memberId}/groups/${groupId}`);

export default {

    createGroup,
    fetchGroupIdDetails,
    assignPromotionIdsToGroupId,
    fetchPromotionsForGroupId,
    removePromotionIdFromGroupId,
    deleteGroupId,
    fetchMembersFormGroupId,
    fetchMembersNotFormGroupId,
    fetchGroupIdFees, // TODO amend epic,

    fetchAllGroupsForClientId,
    assignMemberIdToGroupId,
};
