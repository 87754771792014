import StandardListTable from 'components/common/organisms/StandardListTable';

import React, { JSX } from 'react';

import { AppliedFeesListProps } from './AppliedFeesList.types';
import createAppliedFeesListTableColumns from './createTableColumns';


function AppliedFeesList({
    appliedFees,
    dispatchRequestNavigation,
    accessControl,
    t,
}: AppliedFeesListProps): JSX.Element {
    return (
        <StandardListTable
            items={appliedFees}
            totalItemsCount={appliedFees?.length}
            isTableLoading={false}
            isLoadingPlaceholder={false}
            columnsConfig={createAppliedFeesListTableColumns({ dispatchRequestNavigation, accessControl, t })}
        />
    );
}


export default AppliedFeesList;
