import ErrorMessage from 'components/common/atoms/ErrorMessage/ErrorMessage';
import Countdown from 'components/common/molecules/Countdown';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import PasswordInput from 'components/common/molecules/form-controls/PasswordInputField';
import { UnauthorisedScreenTitle, UnauthorisedLinkWrapper } from 'components/layouts/PublicLayout/PublicLayout.styled';
import { WL_CONFIG } from 'config/config';
import { forgotPasswordRootRoutePath } from 'config/routes';
import { AuthorisationErrors } from 'constants/AuthorisationModel';
import useUpdateEffect from 'hooks/useUpdateEffect';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Button, Checkbox, Input } from 'antd';
import React, { Fragment, useEffect, useMemo, JSX } from 'react';


import Styled from './Login.styled';
import { LoginProps } from './Login.types';


const { useWatch } = Form;

function Login({
    loginFormErrorMessage,
    isLoadingLoginForm,
    emailOrUsername,

    loginBlockUntil,
    loginBlockPermanent,
    submittedUserNameOrEmail,

    dispatchInitialiseLogin,
    dispatchGetRememberedUsernameOrEmail,
    dispatchClearLoginBlock,
    t,
}: LoginProps): JSX.Element {
    const [form] = Form.useForm();
    const emailOrUsernameWatch = useWatch('emailOrUsername', form);

    useEffect(() => {
        dispatchGetRememberedUsernameOrEmail();
    }, []);

    useEffect(() => {
        if (emailOrUsername) {
            form.setFieldsValue({ emailOrUsername });
        }
    }, [emailOrUsername]);

    useUpdateEffect(() => {
        if ((loginBlockUntil || loginBlockPermanent) && emailOrUsernameWatch !== submittedUserNameOrEmail) {
            dispatchClearLoginBlock();
        }
    }, [emailOrUsernameWatch]);

    const onLogin = (values) => {
        const { emailOrUsername, password, remember } = values;
        dispatchInitialiseLogin({ emailOrUsername, password, remember });
    };

    const ButtonContainer: {
    Component: React.ElementType;
    props?: Record<string, unknown>;
  } = useMemo(() => (loginBlockUntil || loginBlockPermanent ? {
      Component: StandardTooltip,
      props: { title: t('unauthorised:actionMessages.loginBlocked.tooltip') },
  } : { Component: Fragment }), [loginBlockUntil, loginBlockPermanent]);

    const loginErrorMessageAmended = useMemo(() => (loginFormErrorMessage === AuthorisationErrors.TEMPORARILY_BLOCKED_FOR
        ? 'User is temporarily locked for'
        : loginFormErrorMessage), [loginFormErrorMessage]);

    return (
        <>
            <UnauthorisedScreenTitle>
                {t('unauthorised:screenTitles.login')}
            </UnauthorisedScreenTitle>

            <Form
                form={form}
                initialValues={{ emailOrUsername, password: '', remember: true }}
                onFinish={onLogin}
            >
                <Form.Item
                    name="emailOrUsername"
                    rules={[
                        {
                            required: true,
                            message: t('unauthorised:formsElements.emailOrUsername.required'),
                        },
                    ]}
                >
                    <Input
                        data-test-id={'email-or-username-input'}
                        placeholder={t('unauthorised:formsElements.emailOrUsername.placeholder')}
                    />
                </Form.Item>

                <PasswordInput
                    t={t}
                    placeholder={t('unauthorised:formsElements.password.placeholder')}
                    isRequiredMessage={t('unauthorised:formsElements.password.required')}
                />

                <Form.Item
                    name="remember"
                    valuePropName="checked"
                >
                    <Checkbox data-test-id="remember-me-checkbox">
                        {t('unauthorised:formsElements.remember.label')}
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <ButtonContainer.Component {...ButtonContainer.props}>
                        <Button
                            block
                            htmlType={'submit'}
                            type="primary"
                            data-test-id="submit-login-button"
                            loading={isLoadingLoginForm}
                            disabled={!!loginBlockUntil || loginBlockPermanent}
                        >
                            {t('unauthorised:formsElements.login.text')}
                        </Button>
                    </ButtonContainer.Component>
                </Form.Item>

                <Styled.ErrorMessageContainer>
                    {loginErrorMessageAmended ? (
                        <ErrorMessage
                            text={loginErrorMessageAmended}
                            dataTestIdPrefix="login-error"
                        />
                    ) : null}

                    {loginBlockUntil && (
                        <Countdown
                            date={loginBlockUntil}
                            onCountdownEnd={dispatchClearLoginBlock}
                            color={WL_CONFIG.themeVariables.colorError}
                            withSuffix
                        />
                    )}
                </Styled.ErrorMessageContainer>

                <Form.Item>
                    <UnauthorisedLinkWrapper>
                        <StandardLink
                            icon={<QuestionCircleOutlined />}
                            path={forgotPasswordRootRoutePath}
                            title={t('unauthorised:formsElements.forgotLink.text')}
                            dataTestId="forgot-password-link"
                        />
                    </UnauthorisedLinkWrapper>
                </Form.Item>
            </Form>
        </>
    );
}


export default Login;
