import { AuthorisationErrors } from 'constants/AuthorisationModel';
import { AuthErrorCodes } from 'models/api/auth';
import { handleLoginFailure } from 'redux/authorisation/reducer.helpers';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';

import { createReducer } from '@reduxjs/toolkit';


import {
    CLEAR_ALL_PASSCODE_DIGITS, CLEAR_BLOCK_SEND_CONFIRMATION_CODE,
    CLEAR_LOGIN_BLOCK,
    CLEAR_REQUEST_PASSWORD_RESET,
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_FAILURE,
    CONFIRM_LOGIN_SUCCESS,
    GET_REMEMBERED_USERNAME_OR_EMAIL_SUCCESS,
    INITIALISE_LOGIN,
    INITIALISE_LOGIN_FAILURE,
    INITIALISE_LOGIN_SUCCESS,
    REFRESH_TOKEN,
    REFRESH_TOKEN_FAILURE,
    REFRESH_TOKEN_SUCCESS,
    REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_FAILURE,
    REQUEST_PASSWORD_RESET_SUCCESS,
    RESEND_CONFIRMATION_CODE_COUNTDOWN_TIME,
    SEND_CONFIRMATION_CODE,
    SEND_CONFIRMATION_CODE_FAILURE,
    SEND_CONFIRMATION_CODE_SUCCESS,
    SET_FAILED_LOGIN_ATTEMPTS,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_FAILURE,
    SET_NEW_PASSWORD_SUCCESS,

    SET_PASSCODE_DIGIT,
    UNLOCK_LOGIN,
    UNLOCK_LOGIN_FAILURE,
    UNLOCK_LOGIN_SUCCESS,
} from './actions.types';
import { AuthorisationStateType } from './reducer.types';


export const authorisationReducerName = 'authorisation';

const initialLoginConfirmationValues = {
    digit1Value: '',
    digit2Value: '',
    digit3Value: '',
    digit4Value: '',
    digit5Value: '',
    digit6Value: '',
};

const initialState: AuthorisationStateType = {
    submittedUserNameOrEmail: undefined,
    loginFormRememberedUserNameOrEmail: undefined,
    fieldLoginAttempts: undefined,
    loginRequestId: undefined,
    accessToken: undefined,

    passwordResetLinkSent: undefined,

    loginFormErrorMessage: undefined,
    loginConfirmationValues: { ...initialLoginConfirmationValues },
    loginConfirmFormError: undefined,

    loginBlockUntil: undefined,
    sendConfirmationCodeBlockUntil: undefined,
    loginBlockPermanent: undefined,

    isLoadingLoginForm: false,
    isLoadingLoginConfirmationForm: false,
    isLoadingForgetPassword: false,
    isLoadingSetNewPassword: false,
    isLoadingUnlockLogin: false,
    isRefreshingSession: false,
    isLoadingSendConfirmationCode: false,
};

const handlers = {
    [INITIALISE_LOGIN]: (state, action) => {
        state.isLoadingLoginForm = true;
        state.loginFormErrorMessage = undefined;
        state.submittedUserNameOrEmail = action.payload.emailOrUsername;
    },
    [INITIALISE_LOGIN_SUCCESS]: (state, action) => {
        state.isLoadingLoginForm = false;
        state.loginRequestId = Number(action.payload.id);
        state.accessToken = action.payload.token;
    },
    [INITIALISE_LOGIN_FAILURE]: (state, action) => {
        state.isLoadingLoginForm = false;
        state.loginFormErrorMessage = action.payload.message;
        handleLoginFailure(state, action.payload);
    },

    [CLEAR_LOGIN_BLOCK]: (state) => {
        state.loginBlockUntil = undefined;
        state.loginBlockPermanent = undefined;
        state.submittedUserNameOrEmail = undefined;
        state.loginFormErrorMessage = undefined;
    },

    [CONFIRM_LOGIN]: (state) => {
        state.isLoadingLoginConfirmationForm = true;
    },
    [CONFIRM_LOGIN_SUCCESS]: (state) => {
        state.isLoadingLoginConfirmationForm = false;
        state.loginConfirmFormError = undefined;
    },
    [CONFIRM_LOGIN_FAILURE]: (state, action) => {
        state.isLoadingLoginConfirmationForm = false;
        if (action.payload.message === AuthorisationErrors.INVALID_CREDENTIALS && action.payload.messageCode !== AuthErrorCodes.CONFIRMATION_TOKEN_EXPIRED) {
            state.loginConfirmFormError = true;
        }

        handleLoginFailure(state, action.payload);
    },

    [SET_FAILED_LOGIN_ATTEMPTS]: (state, action) => {
        state.setFailedLoginAttempts = action.payload;
    },

    [REQUEST_PASSWORD_RESET]: (state) => {
        state.isLoadingForgetPassword = true;
    },
    [REQUEST_PASSWORD_RESET_SUCCESS]: (state) => {
        state.isLoadingForgetPassword = false;
        state.passwordResetLinkSent = true;
    },
    [REQUEST_PASSWORD_RESET_FAILURE]: (state) => {
        state.isLoadingForgetPassword = false;
    },

    [CLEAR_REQUEST_PASSWORD_RESET]: (state) => {
        state.passwordResetLinkSent = undefined;
    },

    [SET_NEW_PASSWORD]: (state) => {
        state.isLoadingSetNewPassword = true;
    },
    [SET_NEW_PASSWORD_SUCCESS]: (state) => {
        state.isLoadingSetNewPassword = false;
    },
    [SET_NEW_PASSWORD_FAILURE]: (state) => {
        state.isLoadingSetNewPassword = false;
    },

    [GET_REMEMBERED_USERNAME_OR_EMAIL_SUCCESS]: (state, action) => {
        state.loginFormRememberedUserNameOrEmail = action.payload;
    },

    [UNLOCK_LOGIN]: (state) => {
        state.isLoadingUnlockLogin = true;
    },
    [UNLOCK_LOGIN_SUCCESS]: (state) => {
        state.isLoadingUnlockLogin = false;
    },
    [UNLOCK_LOGIN_FAILURE]: (state) => {
        state.isLoadingUnlockLogin = false;
    },

    [REFRESH_TOKEN]: (state) => {
        state.isRefreshingSession = true;
    },
    [REFRESH_TOKEN_SUCCESS]: (state) => {
        state.isRefreshingSession = false;
    },
    [REFRESH_TOKEN_FAILURE]: (state) => {
        state.isRefreshingSession = false;
    },

    [CLEAR_ALL_PASSCODE_DIGITS]: (state) => {
        state.loginConfirmationValues = { ...initialLoginConfirmationValues };
        state.loginConfirmFormError = undefined;
    },

    [SET_PASSCODE_DIGIT]: (state, action) => {
        state.loginConfirmationValues[action.payload?.key] = action.payload?.value;
    },
    [SEND_CONFIRMATION_CODE]: (state) => {
        state.isLoadingSendConfirmationCode = true;
    },
    [SEND_CONFIRMATION_CODE_SUCCESS]: (state, action) => {
        state.accessToken = action.payload.token;
        state.isLoadingSendConfirmationCode = false;
        state.sendConfirmationCodeBlockUntil = new Date(new Date().getTime() + RESEND_CONFIRMATION_CODE_COUNTDOWN_TIME);
    },
    [SEND_CONFIRMATION_CODE_FAILURE]: (state) => {
        state.isLoadingSendConfirmationCode = false;
    },
    [CLEAR_BLOCK_SEND_CONFIRMATION_CODE]: (state) => {
        state.sendConfirmationCodeBlockUntil = undefined;
    },

    [CLEAR_CURRENT_USER]: () => initialState,
};

export default createReducer(initialState, handlers);
