import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';

import { fetchFeeDetails, fetchFeeDetailsSuccess, fetchFeeDetailsFailure, clearFeeDetails } from './actions';


export const feeReducerName = 'fee';
export const feeDetailsPerLocationStoreKeyName = 'feeDetailsPerLocation';


const initialFeeDetailsState = {
    [feeDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    //
};

const initialState = {
    ...initialFeeDetailsState,
    //
};


const handlers = {
    [fetchFeeDetails]: (state, action) => {
        state[feeDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[feeDetailsPerLocationStoreKeyName], action);
    },
    [fetchFeeDetailsSuccess]: (state, action) => {
        state[feeDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[feeDetailsPerLocationStoreKeyName], action);
    },
    [fetchFeeDetailsFailure]: (state, action) => {
        state[feeDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[feeDetailsPerLocationStoreKeyName], action);
    },
    [clearFeeDetails]: (state, action) => {
        state[feeDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[feeDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === feeReducerName
      && action.payload?.fieldName === feeDetailsPerLocationStoreKeyName
        ) {
            state[feeDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[feeDetailsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
