import withAccessControl from 'hocs/withAccessControl';
import { applicationUIReducerName } from 'redux/application/reducer';
import { clientContextReducerName } from 'redux/client-context/reducer';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { requestNavigationBack } from 'redux/navigation/actions';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import StandardPageHeading from './StandardPageHeading.layout';


const mapStateToProps = (state) => ({
    clients: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    clientIdContext: state[clientContextReducerName].clientIdContext,
    currentUserClientId: state[currentUserReducerName].userData?.clientId,
    readOnlyInfo: state[applicationUIReducerName].readOnlyInfo,
});

const mapDispatchToProps = (dispatch) => ({ dispatchRequestNavigationBack: () => dispatch(requestNavigationBack()) });


const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(StandardPageHeading);
