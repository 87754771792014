import styled from 'styled-components';


const ChangeCardLimitFormButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  justify-content: right;
  align-items: center;
`;

const ChangeCardLimitButtonWrapper = styled.div`
  padding-top: 25px;
  
  .ant-btn.ant-btn-primary {
    margin-left: 0;
  }
`;

export default { ChangeCardLimitButtonWrapper, ChangeCardLimitFormButtonsWrapper };
