import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { AccountIdentificationsField } from 'components/common/organisms/AccountInfoLayout/AccountInfoLayout.types';
import CardsList from 'components/common/templates/lists/CardsList';
import TransactionsList from 'components/common/templates/lists/TransactionsList';
import {
    cardsRouteSegmentPath,
    transactionsRouteSegmentPath,
} from 'config/routes';
import { AllEntityTypes } from 'constants/ApplicationStateModel';
import { PN } from 'constants/PermissionsModel';
import { AccessControlContent } from 'models/domain/accessControl';

import {
    CreditCardOutlined,
    TransactionOutlined,
} from '@ant-design/icons';
import { AccountDetails } from '@manigo/manigo-domain-typings';
import { TFunction } from 'i18next';
import React from 'react';


export const createAccountDetailsTabsConfig = ({
    fixedAccountId,
    accessControl,
    t,
}: {
  fixedAccountId: AccountDetails['id'],
  accessControl: AccessControlContent,
  t: TFunction
}) => [
    ...(accessControl.hasPermission(PN.TRANSACTIONS.READ_ALL) ? [
        {
            icon: <TransactionOutlined />,
            label: 'Transactions',
            hasList: true,
            activeMainTabSegmentURL: transactionsRouteSegmentPath,
            content: (
                <TransactionsList
                    accountId={fixedAccountId}
                    hideCurrencyFilter
                />
            ),
        },
    ] : []),
    ...(accessControl.hasPermission(PN.CARDS.READ_BY_ACCOUNT) ? [
        {
            icon: <CreditCardOutlined />,
            label: t('Cards'),
            hasList: true,
            activeMainTabSegmentURL: cardsRouteSegmentPath,
            content: (
                <CardsList
                    entityId={fixedAccountId}
                    entityType={AllEntityTypes.ACCOUNT}
                    showSearchField
                />
            ),
        },
    ] : []),
];


export const createAccountDetailsAccountIdentificationsFieldsConfig = (accountIdentifications: AccountIdentificationsField[] = []) => accountIdentifications.map((item) => ({
    label: item.label,
    value: <StandardValue value={item.value} showCopyToClipboard />,
}));

export default { createAccountDetailsTabsConfig, createAccountDetailsAccountIdentificationsFieldsConfig };
