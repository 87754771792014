import CountryOrCurrencyFlag from 'components/common/atoms/CountryOrCurrencyFlag';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import booleanAsIconsRenderer from 'components/common/molecules/value-renderers/booleanIconRenderer';
import { SortDirections } from 'constants/ApplicationStateModel';
import { sortingOptions, sortNumbers, sortStringsAlphabetically } from 'utils/table-columns-sorters';

import PropTypes from 'prop-types';
import React from 'react';


function createCountriesListTableColumns({
    t,
    queryParams,
}) {
    return [
        {
            title: t('common:table.columnNames.id'),
            dataIndex: 'id',
            align: 'right',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            defaultSortOrder: SortDirections.ASCEND,
            ...sortingOptions({
                queryParams,
                fieldName: 'id',
                sorter: (a, b) => sortNumbers(a, b, 'id'),
            }),
        },
        {
            title: t('ISO-31662'),
            dataIndex: 'iso_31662',
            align: 'center',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'iso_31662',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'iso_31662'),
            }),
        },
        {
            title: t('ISO-31663'),
            dataIndex: 'iso_31663',
            align: 'center',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'iso_31663',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'iso_31663'),
            }),
        },
        {
            title: t('ISO code'),
            align: 'right',
            dataIndex: 'country_code',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} valueType={ValueTypes.COUNTER} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'country_code',
                sorter: (a, b) => sortNumbers(a, b, 'country_code'),
            }),
        },
        {
            title: t('Enabled'),
            dataIndex: 'enabled',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(record, 'enabled', t),
            ...sortingOptions({
                queryParams,
                fieldName: 'eea',
                sorter: (a, b) => sortNumbers(a, b, 'enabled'),
            }),
        },
        {
            title: t('EEA'),
            dataIndex: 'eea',
            align: 'center',
            render: (text, record) => booleanAsIconsRenderer(record, 'eea', t),
            ...sortingOptions({
                queryParams,
                fieldName: 'eea',
                sorter: (a, b) => sortNumbers(a, b, 'eea'),
            }),
        },
        {
            title: t('Flag'),
            dataIndex: 'flag',
            align: 'center',
            render: (text, record) => <CountryOrCurrencyFlag countryCode={record.iso_31662} size={20} />,
        },
        {
            title: t('Name'),
            dataIndex: 'name',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'name',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'name'),
            }),
        },
        {
            title: t('Full name'),
            dataIndex: 'full_name',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'full_name',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'full_name'),
            }),
        },
        {
            title: t('Calling code'),
            dataIndex: 'calling_code',
            align: 'right',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'calling_code',
                sorter: (a, b) => sortNumbers(a, b, 'calling_code'),
            }),
        },
        {
            title: t('Capital'),
            dataIndex: 'capital',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'calling_code',
                sorter: (a, b) => sortNumbers(a, b, 'calling_code'),
            }),
        },
        {
            title: t('Citizenship'),
            dataIndex: 'citizenship',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'citizenship',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'citizenship'),
            }),
        },
        {
            title: t('Risk clients'),
            dataIndex: 'risk_clients',
            align: 'right',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.COUNTER} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'risk_clients',
                sorter: (a, b) => sortNumbers(a, b, 'risk_clients'),
            }),
        },
        {
            title: t('Risk consumer'),
            dataIndex: 'risk_consumer',
            align: 'right',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.COUNTER} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'risk_consumer',
                sorter: (a, b) => sortNumbers(a, b, 'risk_consumer'),
            }),
        },
        {
            title: t('Currency'),
            dataIndex: 'currency',
            align: 'center',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'currency',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'currency'),
            }),
        },
        {
            title: t('Currency code'),
            dataIndex: 'currency_code',
            align: 'center',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'currency_code',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'currency_code'),
            }),
        },
        {
            title: t('currency sub unit'),
            dataIndex: 'currency_sub_unit',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'currency_sub_unit',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'currency_sub_unit'),
            }),
        },

        {
            title: t('Currency symbol'),
            dataIndex: 'currency_symbol',
            align: 'center',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'currency_symbol',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'currency_symbol'),
            }),
        },
    ];
}


createCountriesListTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({ search: PropTypes.string }),
};


export default createCountriesListTableColumns;
