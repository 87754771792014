import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setModalProps } from 'redux/modal/actions';
import { auditPendingTransaction } from 'redux/transaction/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import TransactionScreeningResolutionModal from './TransactionScreeningResolutionModal.layout';
import { TransactionScreeningResolutionModalDispatchProps } from './TransactionScreeningResolutionModal.types';


const mapDispatchToProps = (dispatch: Dispatch): TransactionScreeningResolutionModalDispatchProps => ({
    dispatchAuditPendingTransaction: (requestPayload, locationPathname) => dispatch(auditPendingTransaction(requestPayload, locationPathname)),
    dispatchSetModalProps: (options) => dispatch(setModalProps(options)),
});


const decorate = compose(
    withTranslation('transactions'),
    withAccessControl,
    connect(null, mapDispatchToProps),
);


export default decorate(TransactionScreeningResolutionModal);
