export function convertHexToRGB(rawHex6digit, asString = true) {
    if (rawHex6digit.length !== 7) {
        throw new Error('Only six-digit hex colors are allowed.');
    }
    const noHash = rawHex6digit.replace(/#/g, '');

    const aRgbHex = noHash.match(/.{1,2}/g);
    const aRgb = aRgbHex.map((item) => parseInt(item, 16));

    if (asString) {
        return aRgb.join(', ');
    }
    return aRgb;
}
