import { AllEntityTypes } from 'constants/ApplicationStateModel';
import { UPDATE_ADDRESS, UPDATE_ADDRESS_FAILURE, UPDATE_ADDRESS_SUCCESS } from 'redux/address/actions.types';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    ADD_CLIENT_RISK_DETAILS, ADD_CLIENT_RISK_DETAILS_FAILURE, ADD_CLIENT_RISK_DETAILS_SUCCESS,
    CLEAR_CLIENT_DETAILS,
    FETCH_CLIENT_ADDRESSES,
    FETCH_CLIENT_ADDRESSES_FAILURE,
    FETCH_CLIENT_ADDRESSES_SUCCESS,
    FETCH_CLIENT_DETAILS,
    FETCH_CLIENT_DETAILS_FAILURE,
    FETCH_CLIENT_DETAILS_SUCCESS, UPDATE_CLIENT_ANNOUNCEMENT, UPDATE_CLIENT_ANNOUNCEMENT_FAILURE, UPDATE_CLIENT_ANNOUNCEMENT_SUCCESS,
    UPDATE_CLIENT_PROFILE,
    UPDATE_CLIENT_PROFILE_FAILURE,
    UPDATE_CLIENT_PROFILE_SUCCESS,
    UPDATE_CLIENT_SETTINGS,
    UPDATE_CLIENT_SETTINGS_FAILURE,
    UPDATE_CLIENT_SETTINGS_SUCCESS,
} from './actions.types';


export const clientReducerName = 'client';
export const clientDetailsPerLocationStoreKeyName = 'clientDetailsPerLocationStoreKeyName';
export const clientAddressesPerLocationStoreKeyName = 'clientAddressesPerLocationStoreKeyName';

const initialState = {
    [clientDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    [clientAddressesPerLocationStoreKeyName]: {}, // XXX 'pathname': { shippingAddress: {}, billingAddress: {}}

    isLoadingClientConfigUpdate: false,
    isLoadingClientAnnouncementUpdate: false,
    isLoadingUpdateClientAddress: false,
    isLoadingAddRiskDetails: false,
};


const handlers = {
    [FETCH_CLIENT_DETAILS]: (state, action) => {
        state[clientDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[clientDetailsPerLocationStoreKeyName], action);
    },
    [FETCH_CLIENT_DETAILS_SUCCESS]: (state, action) => {
        state[clientDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[clientDetailsPerLocationStoreKeyName], action);
    },
    [FETCH_CLIENT_DETAILS_FAILURE]: (state, action) => {
        state[clientDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[clientDetailsPerLocationStoreKeyName], action);
    },
    [CLEAR_CLIENT_DETAILS]: (state, action) => {
        state[clientDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[clientDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === clientReducerName
      && action.payload?.fieldName === clientDetailsPerLocationStoreKeyName
        ) {
            state[clientDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[clientDetailsPerLocationStoreKeyName] }, action);
        }
    },

    [FETCH_CLIENT_ADDRESSES]: (state, action) => {
        state[clientAddressesPerLocationStoreKeyName] = updateStandardEntityDetails(state[clientAddressesPerLocationStoreKeyName], action);
    },
    [FETCH_CLIENT_ADDRESSES_SUCCESS]: (state, action) => {
        state[clientAddressesPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[clientAddressesPerLocationStoreKeyName], action);
    },
    [FETCH_CLIENT_ADDRESSES_FAILURE]: (state, action) => {
        state[clientAddressesPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[clientAddressesPerLocationStoreKeyName], action);
    },

    [UPDATE_CLIENT_PROFILE]: (state) => {
        state.isLoadingClientConfigUpdate = true;
    },
    [UPDATE_CLIENT_PROFILE_SUCCESS]: (state, action) => {
        state.isLoadingClientConfigUpdate = false;
        state[clientDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[clientDetailsPerLocationStoreKeyName], action);
    },
    [UPDATE_CLIENT_PROFILE_FAILURE]: (state) => {
        state.isLoadingClientConfigUpdate = false;
    },

    [UPDATE_CLIENT_SETTINGS]: (state) => {
        state.isLoadingClientConfigUpdate = true;
    },
    [UPDATE_CLIENT_SETTINGS_SUCCESS]: (state, action) => {
        state.isLoadingClientConfigUpdate = false;
        state[clientDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[clientDetailsPerLocationStoreKeyName], action);
    },
    [UPDATE_CLIENT_SETTINGS_FAILURE]: (state) => {
        state.isLoadingClientConfigUpdate = false;
    },


    [UPDATE_CLIENT_ANNOUNCEMENT]: (state) => {
        state.isLoadingClientAnnouncementUpdate = true;
    },
    [UPDATE_CLIENT_ANNOUNCEMENT_SUCCESS]: (state, action) => {
        state.isLoadingClientAnnouncementUpdate = false;
        state[clientDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[clientDetailsPerLocationStoreKeyName], action);
    },
    [UPDATE_CLIENT_ANNOUNCEMENT_FAILURE]: (state) => {
        state.isLoadingClientAnnouncementUpdate = false;
    },


    [UPDATE_ADDRESS]: (state, action) => {
        if (action.payload.addressType.entityType === AllEntityTypes.CLIENT) {
            state.isLoadingUpdateClientAddress = true;
        }
    },
    [UPDATE_ADDRESS_SUCCESS]: (state, action) => {
        if (action.payload.addressType.entityType === AllEntityTypes.CLIENT) {
            state.isLoadingUpdateClientAddress = false;
            const location = action.payload.locationPathname;

            if (action.payload.addressType.addressType === 'billing_address') {
                state[clientAddressesPerLocationStoreKeyName][location].entityDetails.billingAddress = action.payload.responseData;
            } else if (action.payload.addressType.addressType === 'shipping_address') {
                state[clientAddressesPerLocationStoreKeyName][location].entityDetails.shippingAddress = action.payload.responseData;
            } else {
                state[clientDetailsPerLocationStoreKeyName][location].entityDetails.client_address = action.payload.responseData;
            }
        }
    },
    [UPDATE_ADDRESS_FAILURE]: (state, action) => {
        if (action.payload.entityType === AllEntityTypes.CLIENT) {
            state.isLoadingUpdateClientAddress = false;
        }
    },


    [ADD_CLIENT_RISK_DETAILS]: (state) => {
        state.addRiskDetailsLoading = true;
    },
    [ADD_CLIENT_RISK_DETAILS_SUCCESS]: (state) => {
        state.addRiskDetailsLoading = false;
    },
    [ADD_CLIENT_RISK_DETAILS_FAILURE]: (state) => {
        state.addRiskDetailsLoading = false;
    },


    [CLEAR_CURRENT_USER]: () => initialState,
};

export default createReducer(initialState, handlers);
