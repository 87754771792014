import { standardPageSize } from 'config/config';
import { memberRootRoutePath, statusHistoryRouteSegmentPath } from 'config/routes';
import { SortDirections } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import { PREFORM_NAVIGATION_SUCCESS } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    FETCH_MEMBER_STATUS_HISTORY_LIST,
    FETCH_MEMBER_STATUS_HISTORY_LIST_SUCCESS,
    FETCH_MEMBER_STATUS_HISTORY_LIST_FAILURE,

    CLEAR_MEMBER_STATUS_HISTORY_LIST,
    CLEAR_RESET_UPDATE_MEMBER_STATUS_FORM, EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS,
} from './actions.types';


export const memberStatusReducerName = 'memberStatuses';
export const memberStatusHistoryListsPerLocationStoreKeyName = 'memberStatusHistoryListsPerLocationStoreKeyName';

export const defaultMemberStatusHistoryListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.DESCEND,
    ...withTotalCount,
};

const initialState = {
    [memberStatusHistoryListsPerLocationStoreKeyName]: {},

    isLoadingUpdateStatus: false,
    shouldResetForm: false,
};


export default createReducer(initialState, (builder) => {
    builder.addCase(FETCH_MEMBER_STATUS_HISTORY_LIST, (state, action) => {
        state[memberStatusHistoryListsPerLocationStoreKeyName] = updateStandardLists(state[memberStatusHistoryListsPerLocationStoreKeyName], action);
    })
        .addCase(FETCH_MEMBER_STATUS_HISTORY_LIST_SUCCESS, (state, action) => {
            state[memberStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[memberStatusHistoryListsPerLocationStoreKeyName], action);
        })
        .addCase(FETCH_MEMBER_STATUS_HISTORY_LIST_FAILURE, (state, action) => {
            state[memberStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[memberStatusHistoryListsPerLocationStoreKeyName], action);
        })
        .addCase(CLEAR_MEMBER_STATUS_HISTORY_LIST, (state, action:any) => {
            state[memberStatusHistoryListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[memberStatusHistoryListsPerLocationStoreKeyName],
                action.payload.locationPathname);
        })
        .addCase(setQueryParams, (state, action) => {
            if (action.payload?.reducerName === memberStatusReducerName
            && action.payload?.fieldName === memberStatusHistoryListsPerLocationStoreKeyName
            ) {
                state[memberStatusHistoryListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[memberStatusHistoryListsPerLocationStoreKeyName] }, action);
            }
        })
        .addCase(PREFORM_NAVIGATION_SUCCESS, (state, action) => {
            state[memberStatusHistoryListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
                state[memberStatusHistoryListsPerLocationStoreKeyName],
                {
                    action,
                    listRouteSegments: [statusHistoryRouteSegmentPath],
                    observedRootPaths: [memberRootRoutePath],
                },
            );
        })
        .addCase(EXTERNAL_KYC_KYB_UPDATE_MEMBER_STATUS, (state) => {
            state.shouldResetForm = true;
            state.isLoadingUpdateStatus = true;
        })
        .addCase(CLEAR_RESET_UPDATE_MEMBER_STATUS_FORM, (state) => {
            state.shouldResetForm = false;
            state.isLoadingUpdateStatus = false;
        })
        .addCase(CLEAR_CURRENT_USER, () => initialState);
});
