import PropTypes from 'prop-types';
import React from 'react';
import Highlighter from 'react-highlight-words';

import Styled from './TextWithHighlightedSearch.styled';


function TextWithHighlightedSearch({
    record,
    key,
    filterValue,
    rawValue,
}) {
    const value = rawValue || record?.[key] || '-';

    return (
        <Styled.TextWithHighlightedSearchWrapper title={value}>
            {filterValue && filterValue.length
                ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[filterValue]}
                        autoEscape
                        textToHighlight={value ? value.toString() : ''}
                        title={value}
                    />
                )
                : value }
        </Styled.TextWithHighlightedSearchWrapper>
    );
}

TextWithHighlightedSearch.propTypes = {
    record: PropTypes.shape({}),
    rawValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    key: PropTypes.string,
    filterValue: PropTypes.string,
};


export default TextWithHighlightedSearch;

