import { createReviewStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/ReviewStep/reviewStep.helpers';
import { createSelectPermissionsStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/SelectPermissionsStep/selectPermissionsStep.helpers';
import { createRoleDetailsStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/roleDetailsStep.helpers';
import { createSuccessStepConfig } from 'components/common/organisms/forms-shared-parts/add-edit-role-form/successStep.helpers';


export function createEditRoleStepsConfiguration({ t, operationsLists }) {
    // XXX order DOES matter
    return [
        createRoleDetailsStepConfig({ t }),

        createSelectPermissionsStepConfig({ operationsLists, t }),

        createReviewStepConfig({ t }),

        createSuccessStepConfig({ t }),
    ];
}

export default { createEditRoleStepsConfiguration };
