import StandardMultiStepForm from 'components/common/organisms/StandardMultiStepForm';
import {
    parseDefaultClientConfigurationToInitialFormData,
    parseDefaultClientConfigurationToSubStepsConfig,
    createActionsForStepSuccessfulValidation,
} from 'components/common/organisms/forms-shared-parts/add-edit-client-form/common.helpers';
import { createClientDetailsPath } from 'config/routes.helpers';
import { ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { ClientDetailsProps } from 'constants/ClientModel';
import { currentStepNumberInAvailableSteps, getLastStepExceptSuccessFromAvailableSteps } from 'redux/multi-steps-form/epics.helpers';
import { flattenObject } from 'utils/object-tools';

import { Form, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import { createEditClientSettingsStepsConfiguration } from './EditClientSettingsForm.helpers';


function EditClientSettingsForm({
    locationPathname,

    isLoadingClientConfigUpdate,
    clientId,
    clientDetails,

    defaultNewClientConfiguration,

    dispatchFetchDefaultClientConfiguration,
    dispatchUpdateClientSettings,
    dispatchFetchClientDetails,

    availableSteps,
    availableSubSteps,
    currentMultiStepsFormStep,
    currentMultiStepsFormSubStep,

    dispatchRequestSetMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormSubStep,

    t,
}) {
    const [form] = Form.useForm();

    const lastStepExceptSuccess = useMemo(
        () => getLastStepExceptSuccessFromAvailableSteps(availableSteps),
        [availableSteps],
    );

    const currentSubStepNumber = useMemo(
        () => {
            if (availableSubSteps && availableSubSteps?.length > 0 && currentMultiStepsFormSubStep) {
                return currentStepNumberInAvailableSteps(availableSubSteps, currentMultiStepsFormSubStep);
            }
            return undefined;
        },
        [currentMultiStepsFormSubStep, availableSubSteps],
    );

    useEffect(
        () => {
            if (!defaultNewClientConfiguration) {
                dispatchFetchDefaultClientConfiguration();
            }
            if (!clientDetails || clientDetails?.id !== clientId) {
                dispatchFetchClientDetails({ clientId }, createClientDetailsPath(clientId));
            }
        },
        [],
    );

    const finalInitialFormData = useMemo(
        () => {
            if (defaultNewClientConfiguration?.configuration && clientDetails?.id) {
                return {
                    ...parseDefaultClientConfigurationToInitialFormData(
                        defaultNewClientConfiguration.configuration,
                        flattenObject({ configuration: { ...clientDetails.configuration } }),
                    ),
                };
            }

            return undefined;
        },
        [defaultNewClientConfiguration, clientDetails],
    );

    const stepsConfiguration = useMemo(
        () => {
            if (finalInitialFormData) {
                return createEditClientSettingsStepsConfiguration({
                    t,
                    parsedSettingsConfig: parseDefaultClientConfigurationToSubStepsConfig(defaultNewClientConfiguration.configuration, t),
                    initialFormData: finalInitialFormData,
                });
            }

            return undefined;
        },
        [finalInitialFormData],
    );

    const onFinishHandler = (values) => {
        createActionsForStepSuccessfulValidation({
            values,

            locationPathname,
            currentMultiStepsFormStep,
            currentMultiStepsFormSubStep,
            availableSubSteps,
            dispatchFinalApiCall: dispatchUpdateClientSettings,
            dispatchRequestSetMultiStepsFormStep,
            dispatchRequestSetMultiStepsFormSubStep,

            currentSubStepNumber,
            lastStepExceptSuccess,
        });
    };

    return finalInitialFormData && stepsConfiguration
        ? (
            <StandardMultiStepForm
                isEditMode
                formName="editClientSettings"
                form={form}
                isLoading={isLoadingClientConfigUpdate}
                locationPathname={locationPathname}
                stepsConfiguration={stepsConfiguration}
                initialFormData={finalInitialFormData}
                reasonForBlockedNavigation={ReasonsForBlockedNavigation.NOT_PRISTINE_EDIT_CLIENT_SETTINGS_FORM}
                onFinishHandler={onFinishHandler}
                onSubmitSpinnerCopy={"Updating partner's settings..."}
                goBackPathname={createClientDetailsPath(clientId)}
            />
        )
        : (<Skeleton loading active />);
}

EditClientSettingsForm.propTypes = {
    locationPathname: PropTypes.string,
    clientId: PropTypes.number,
    clientDetails: ClientDetailsProps,
    defaultNewClientConfiguration: PropTypes.shape({}),
    // mapped props
    isLoadingClientConfigUpdate: PropTypes.bool,
    dispatchFetchDefaultClientConfiguration: PropTypes.func,
    dispatchUpdateClientSettings: PropTypes.func,
    dispatchRequestSetMultiStepsFormStep: PropTypes.func,
    dispatchRequestSetMultiStepsFormSubStep: PropTypes.func,
    dispatchFetchClientDetails: PropTypes.func,
    availableSteps: PropTypes.arrayOf(PropTypes.string),
    availableSubSteps: PropTypes.arrayOf(PropTypes.string),
    currentMultiStepsFormStep: PropTypes.string,
    currentMultiStepsFormSubStep: PropTypes.string,
};


export default EditClientSettingsForm;
