import { transactionsListsPerLocationStoreKeyName, transactionsReducerName } from 'redux/transactions/reducer';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import {
    getTransactions, getTransactionsSuccess, getTransactionsFailure,
    getTransactionsFiltersFailure, getTransactionsFiltersSuccess,
} from './actions';


export const onRefreshTransactions = (action$, state$) => action$.pipe(
    ofType('transactions/refreshTransactions'),
    switchMap(({ payload }) => {
        const currentQueryParams = state$.value[transactionsReducerName][transactionsListsPerLocationStoreKeyName]?.[payload.locationPathname]?.queryParams;
        return of(getTransactions(currentQueryParams, payload.locationPathname));
    }),
);


export const onGetTransactions = (action$, state$, { transactions }) => action$.pipe(
    ofType('transactions/getTransactions'),
    switchMap(({ payload }) => from(transactions.getAllTransactions(payload.queryParams, state$.value.transactions.filters)).pipe(
        switchMap((response) => of(getTransactionsSuccess(
            response?.data?.rows,
            response?.data?.count,
            payload.locationPathname,
        ))),
        catchError(() => of(getTransactionsFailure(payload.locationPathname))),
    )),
);


export const onGetTransactionsFilters = (action$, state$, { transactions }) => action$.pipe(
    ofType('transactions/getTransactionsFilters'),
    switchMap(() => from(transactions.getTransactionsFilters()).pipe(
        switchMap((response) => of(getTransactionsFiltersSuccess(response.data))),
        catchError(() => of(getTransactionsFiltersFailure())),
    )),
);


export default [
    onGetTransactionsFilters,
    onGetTransactions,
];
