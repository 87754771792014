import InfoTooltip from 'components/common/atoms/InfoTooltip';
import BackButton from 'components/pages/authorised/AddEditBeneficiary/BackButton';
import { AccountIdentificationCategoryProp, AccountIdentificationType } from 'constants/AccountModel';
import { createIdentificationCategoryOptions } from 'utils/options-creators';
import { commonValidation, isMatchingGivenRegexRule, isValidBicRule, isValidIbanRule } from 'utils/validation-tools';

import {
    Button, Form, Row, Select, Col, Input,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


import Styled from './AccountDetails.styled';


function AccountDetailsStep({
    t,
    form,
    formData,
    beneficiariesRequirements,
    isEditMode,
    selectedIdentificationCategory,
    dispatchSetBeneficiaryIdentificationCategory,
}) {
    const getIdentificationValue = (identificationType) => {
        const accountIdentifications = form.getFieldValue('account_identifications');
        return accountIdentifications?.find((item) => item.identification_type === identificationType)?.identification_value;
    };

    const onIdentificationCategoryChange = (category) => {
        dispatchSetBeneficiaryIdentificationCategory(category);
        form.setFieldsValue({
            identification_category: category,
            account_identifications: [...beneficiariesRequirements[category]?.map((requirementsIdentification) => ({
                ...requirementsIdentification,
                identification_value: getIdentificationValue(requirementsIdentification.identification_type) || requirementsIdentification.identification_value,
            })) || []],
        });
    };

    const identificationCategoryOptions = useMemo(
        () => {
            if (!isEditMode) {
                const options = createIdentificationCategoryOptions(beneficiariesRequirements);
                if (options.length === 1) {
                    const category = Object.keys(beneficiariesRequirements)[0];
                    dispatchSetBeneficiaryIdentificationCategory(category);
                    form.setFieldsValue({
                        identification_category: category,
                        account_identifications: [...beneficiariesRequirements[category]]?.map((requirementsIdentification) => ({
                            ...requirementsIdentification,
                            identification_value: getIdentificationValue(requirementsIdentification.identification_type) || requirementsIdentification.identification_value,
                        })),
                    });
                }
                return options;
            }

            if (isEditMode) {
                const category = formData.account_identifications?.[0]?.identification_category;

                //  Combine existing accountIdentifications values with regex form beneficiariesRequirements
                const valuesAndRegexp = formData.account_identifications.map((item) => {
                    const { identification_category, identification_value, identification_type } = item;
                    const reqObject = beneficiariesRequirements?.[category].find((item) => item.identification_type === identification_type);
                    return {
                        identification_type,
                        identification_value,
                        identification_category,
                        identification_regex: reqObject.identification_regex,
                    };
                });

                form.setFieldsValue({ identification_category: category, account_identifications: [...valuesAndRegexp] });
                dispatchSetBeneficiaryIdentificationCategory(category);
            }

            return [];
        },
        [beneficiariesRequirements, isEditMode, formData],
    );


    const tooltip = useMemo(
        () => {
            if (isEditMode) {
                return <InfoTooltip tooltipContent="Account identification category can't be changed." />;
            }

            if (!isEditMode && identificationCategoryOptions.length === 1) {
                return <InfoTooltip tooltipContent="Only one identification category is available." />;
            }

            return null;
        },
        [identificationCategoryOptions, isEditMode],
    );

    const createValidationRules = (identificationType, index, identification_regex) => {
        const fieldName = ['account_identifications', `${index}`, 'identification_value'];

        if (identificationType === AccountIdentificationType.IBAN) {
            return [
                ...commonValidation({ t, isRequired: true }),
                ...isValidIbanRule(fieldName, t),
            ];
        }

        if (identificationType === AccountIdentificationType.BIC) {
            return [
                ...isValidBicRule(fieldName, t),
                ...commonValidation({ t, isRequired: true }),
            ];
        }

        return [
            ...isMatchingGivenRegexRule({
                t,
                identificationType,
                regexPattern: identification_regex,
            }),
            ...commonValidation({ t, isRequired: true }),
        ];
    };


    return (
        <Col style={{ maxWidth: '400px' }}>
            <Form.Item
                name="identification_category"
                label={t('beneficiaryFields.identificationCategory.label')}
                rules={commonValidation({ t, isRequired: true })}
            >
                <Styled.ControlWithOptionalTooltip>
                    <Select
                        onChange={onIdentificationCategoryChange}
                        value={selectedIdentificationCategory}
                        disabled={isEditMode || identificationCategoryOptions.length === 1}
                        placeholder={t('beneficiaryFields.identificationCategory.placeholder')}
                    >
                        {identificationCategoryOptions}
                    </Select>
                    {tooltip}
                </Styled.ControlWithOptionalTooltip>
            </Form.Item>

            {selectedIdentificationCategory ? (
                <Form.List name="account_identifications">
                    {(fields) => {
                        // TODO extract this to helper function
                        const fieldValues = form.getFieldValue('account_identifications');
                        return fields.map((field, index) => {
                            const fieldName = fieldValues[index].identification_type.replace('_', ' ');
                            return (
                                <div key={field.key}>
                                    <Form.Item key="identification_type" name={[index, 'identification_type']} hidden>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item name={[index, 'identification_category']} key="identification_category" hidden>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item
                                        name={[index, 'identification_value']}
                                        key="identification_value"
                                        label={fieldName}
                                        rules={createValidationRules(fieldValues[index].identification_type, index, fieldValues[index].identification_regex)}
                                    >
                                        <Input
                                            type="text"
                                            placeholder={`Please enter valid ${fieldName}`}
                                        />
                                    </Form.Item>
                                </div>
                            );
                        });
                    }}
                </Form.List>
            ) : (
                <Styled.AccountIdentificationsPlaceholder>
          Please select identification category to continue.
                </Styled.AccountIdentificationsPlaceholder>
            )}


            <Row key="form buttons" style={{ marginTop: 20 }}>
                <BackButton />
                <Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ marginLeft: 10 }}
                        type="primary"
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </Col>
    );
}

AccountDetailsStep.propTypes = {
    selectedIdentificationCategory: AccountIdentificationCategoryProp,
    isEditMode: PropTypes.bool,
    dispatchSetBeneficiaryIdentificationCategory: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};


export default AccountDetailsStep;
