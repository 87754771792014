import StandardLink from 'components/common/molecules/StandardLink';
import CountryRenderer from 'components/common/molecules/value-renderers/CountryRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { createUserDetailsPath } from 'config/routes.helpers';
import { PN } from 'constants/PermissionsModel';
import { sortNumbers, sortStringsAlphabetically, sortingOptions, sortStringDates } from 'utils/table-columns-sorters';

import dotObject from 'dot-object';
import PropTypes from 'prop-types';
import React from 'react';


const getChangeLabel = (item, t) => {
    const handlers = [
        {
            predicate: () => item.industry?.id > 0,
            handler: () => t('riskScore:riskScoreFields.changedAttribute.values.industry'),
        },
        {
            predicate: () => item.country?.id > 0,
            handler: () => t('riskScore:riskScoreFields.changedAttribute.values.countryOfIncorporation'),
        },
        {
            predicate: () => item.operation_country?.id > 0,
            handler: () => t('riskScore:riskScoreFields.changedAttribute.values.countryOfOperation'),
        },
        {
            predicate: () => true,
            handler: () => '',
        },
    ];
    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

function createGroupsTableColumns({
    accessControl,
    t,
    queryParams,
    clientId,
}) {
    const canViewUserDetails = accessControl.hasPermission(PN.USERS.READ_BY_ID);

    return [
        {
            title: t('riskScore:riskScoreFields.changedAttribute.text'),
            dataIndex: '',
            align: 'center',
            render: (text, item) => getChangeLabel(item, t),
            ...sortingOptions({
                queryParams,
                fieldName: 'attribute',
                sorter: (a, b) => {
                    const A = getChangeLabel(a, t);
                    const B = getChangeLabel(b, t);
                    if (A && B) { return A.localeCompare(B); }
                    if (A && !B) { return 1; }
                    if (!A && B) { return -1; }
                    return 0;
                },
            }),
        },
        {
            title: t('riskScore:riskScoreFields.changedValue.text'),
            dataIndex: '',
            align: 'center',
            render: (text, item) => (
                <>
                    {!!item.industry?.id && (<StandardValue value={item?.industry?.name} />)}
                    {!!item.country?.id && (<CountryRenderer propertyName="id" value={item?.country?.id} showTooltip={false} />)}
                    {!!item.operation_country?.id && (<CountryRenderer propertyName="id" value={item?.operation_country?.id} showTooltip={false} />)}
                </>
            ),
            ...sortingOptions({
                queryParams,
                fieldName: 'value',
                // TODO reduce & extract to utils sorters
                // eslint-disable-next-line complexity
                sorter: (a, b) => {
                    const A = dotObject.pick('industry.id', a) || dotObject.pick('country.id', a) || dotObject.pick('operation_country.id', a);
                    const B = dotObject.pick('industry.id', b) || dotObject.pick('country.id', b) || dotObject.pick('operation_country.id', b);
                    if (A && B) { return A - B; }
                    if (A && !B) { return 1; }
                    if (!A && B) { return -1; }
                    return 0;
                },
            }),
        },
        {
            title: t('riskScore:riskScoreFields.changedFrom.text'),
            dataIndex: 'from',
            align: 'right',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} valueType={ValueTypes.COUNTER} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'from',
                sorter: (a, b) => sortNumbers(a, b, 'from'),
            }),
        },
        {
            title: t('riskScore:riskScoreFields.changedTo.text'),
            dataIndex: 'to',
            align: 'right',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} valueType={ValueTypes.COUNTER} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'to',
                sorter: (a, b) => sortNumbers(a, b, 'to'),
            }),
        },
        {
            title: t('riskScore:riskScoreFields.changedBy.text'),
            dataIndex: 'user',
            align: 'right',
            render: (text, record) => (canViewUserDetails && record?.user?.id
                ? (
                    <StandardLink
                        path={createUserDetailsPath(clientId, record?.user?.id)}
                        title={`${record?.user.first_name} ${record?.user.last_name}`}
                        filterValue={queryParams?.search}
                        showTooltip
                    />
                ) : <StandardValue value={`${record?.user?.first_name} ${record?.user?.last_name}`} searchQuery={queryParams?.search} />),
            ...sortingOptions({
                queryParams,
                fieldName: 'user',
                sorter: (a, b) => {
                    const A = `${a?.user?.first_name} ${a?.user?.last_name}`;
                    const B = `${b?.user?.first_name} ${b?.user?.last_name}`;
                    if (A && B) { return A.localeCompare(B); }
                    if (A && !B) { return 1; }
                    if (!A && B) { return -1; }
                    return 0;
                },
            }),
        },
        {
            title: t('riskScore:riskScoreFields.comment.text'),
            dataIndex: 'comment',
            align: 'right',
            render: (text) => <StandardValue value={text} searchQuery={queryParams?.search} />,
            ...sortingOptions({
                queryParams,
                fieldName: 'comment',
                sorter: (a, b) => sortStringsAlphabetically(a, b, 'comment'),
            }),
        },

        {
            title: t('riskScore:riskScoreFields.createdAt.text'),
            dataIndex: 'created_at',
            align: 'right',
            render: (text) => <StandardValue value={text} valueType={ValueTypes.DATE} showTime />,
            ...sortingOptions({
                queryParams,
                fieldName: 'created_at',
                sorter: (a, b) => sortStringDates(a, b, 'created_at'),
            }),
        },
    ];
}


createGroupsTableColumns.propTypes = {
    t: PropTypes.func.isRequired,
    filterValue: PropTypes.string,
    clientId: PropTypes.number.isRequired,
};


export default createGroupsTableColumns;
