import { reassignMemberGroup } from 'redux/group/actions';
import { fetchGroupsList } from 'redux/groups/actions';
import { groupsListsPerLocationStoreKeyName, groupsReducerName } from 'redux/groups/reducer';
import { setModalProps } from 'redux/modal/actions';
import { blockNavigation, showNavigationConfirmation, unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ReassignMemberGroup from './ReassignMemberGroup.layout';


const mapStateToProps = (state, ownProps) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    isBlockNavigationReasonSet: state[navigationReducerName].blockNavigationReason,
    listData: getStandardListData({
        state,
        reducerName: groupsReducerName,
        listsByLocationStoreKeyName: groupsListsPerLocationStoreKeyName,
        customLocation: ownProps.locationPathname,
    }),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchFetchGroupsList: (queryParams) => dispatch(fetchGroupsList(queryParams, ownProps?.locationPathname)),
    dispatchBlockNavigation: (reason) => dispatch(blockNavigation(showNavigationConfirmation(), reason)),
    dispatchUnblockNavigation: () => dispatch(unblockNavigation()),
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),

    dispatchReassignMemberGroup: (requestPayload, locationPathname) => dispatch(reassignMemberGroup(requestPayload, locationPathname)),
});

const decorate = compose(
    withTranslation('member'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ReassignMemberGroup);
