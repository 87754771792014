import FlagAndValue from 'components/common/atoms/FlagAndValue';
import AccountTypeTag from 'components/common/atoms/tags/AccountTypeTag';
import CardCategoryTag from 'components/common/atoms/tags/CardCategoryTag';
import CardStatusTag from 'components/common/atoms/tags/CardStatusTag';
import CardTypeTag from 'components/common/atoms/tags/CardTypeTag';
import StandardLink from 'components/common/molecules/StandardLink';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import { clientRootRoutePath, companyRootRoutePath, businessUserRootRoutePath, memberRootRoutePath } from 'config/routes';

import React from 'react';


// TODO use path creators helper functions
const renderAccountHolder = (cardDetails, t) => {
    const handlers = [
        {
            predicate: () => cardDetails?.business_user_id > 0,
            handler: () => `${businessUserRootRoutePath}/${cardDetails?.business_user_id}/cards`,
        },
        {
            predicate: () => cardDetails?.member_id > 0,
            handler: () => `${memberRootRoutePath}/${cardDetails?.member_id}/cards`,
        },
        {
            predicate: () => cardDetails?.company_id > 0 && cardDetails?.business_user_id === 0,
            handler: () => `${companyRootRoutePath}/${cardDetails?.company_id}/cards`,
        },
        {
            predicate: () => cardDetails?.member_id === 0
                && cardDetails?.business_user_id === 0
                && cardDetails?.company_id === 0
                && cardDetails?.client_id,
            handler: () => `${clientRootRoutePath}/${cardDetails?.client_id}`,
        },
        {
            predicate: () => true,
            handler: () => null,
        },
    ];

    const linkTo = handlers.filter(({ predicate }) => predicate())[0].handler();

    return linkTo ? <StandardLink path={`${linkTo}`} title={cardDetails?.emboss_name || t('cardDetails.tabs.general.fields.profile.genericLabel')} /> : '-';
};


export const createCardDetailsFieldsConfig = (
    cardDetails,
    clientName,
    cardTierName,
    t,
) => {
    if (cardDetails) {
        return [
            {
                label: t('cardFields.cardId.text'),
                value: <StandardValue value={cardDetails?.id} valueType={ValueTypes.COUNTER} />,
            },
            {
                label: t('cardFields.expiry.text'),
                value: <StandardValue value={cardDetails?.expiry} />,
            },
            {
                label: t('cardFields.type.text'),
                value: <CardTypeTag cardType={cardDetails?.type} t={t} />,
            },
            {
                label: t('cardFields.category.text'),
                value: <CardCategoryTag cardCategory={cardDetails?.card_type} t={t} />,
            },
            {
                label: t('cardFields.cardStatus.text'),
                value: <CardStatusTag cardStatus={cardDetails?.card_status} t={t} cardStatusComment={cardDetails?.card_status_comment} />,
            },
            {
                label: t('cardFields.maskedPan.text'),
                value: <StandardValue value={cardDetails?.pan_mask} />,
            },
            {
                label: t('cardFields.cardToken.text'),
                value: <StandardValue value={cardDetails?.token} />,
            },
            {
                label: t('cardFields.embossName.text'),
                value: <StandardValue value={cardDetails?.emboss_name} />,
            },
            {
                label: t('cardFields.partner.text'),
                showEmpty: true,
                value: <StandardLink path={`${clientRootRoutePath}/${cardDetails?.client_id}`} title={clientName} showTooltip={false} />,
            },
            {
                label: t('cardDetails.tabs.general.subHeadings.cardholder'),
                value: renderAccountHolder(cardDetails, t),
            },
            {
                label: t('cardFields.cardTier.text'),
                value: <StandardValue value={cardTierName} />,
            },
        ];
    }
    return [];
};


export const createAccountDetailsFieldsConfig = (
    account,
    t,
) => {
    if (account) {
        return [
            {
                label: t('cardDetails.tabs.general.fields.accountType.text'),
                showEmpty: true,
                value: <AccountTypeTag accountType={account.type} />,
            },
            {
                label: t('cardDetails.tabs.general.fields.accountName.text'),
                value: <StandardValue value={account.name} />,
            },
            {
                label: t('cardDetails.tabs.general.fields.accountCurrency.text'),
                value: <FlagAndValue currencyCode={account.currency} text={account.currency} showTooltip={false} />,
            },
            {
                label: t('cardDetails.tabs.general.fields.accountBalance.text'),
                showEmpty: true,
                value: <StandardValue value={account.balance} valueType={ValueTypes.AMOUNT} />,
            },
        ];
    }
    return [];
};

export default {
    renderAccountHolder,
    createCardDetailsFieldsConfig,
    createAccountDetailsFieldsConfig,
};
