import StandardTag from 'components/common/atoms/StandardTag';

import { MemberStatuses, SharedEntityStatuses } from '@manigo/manigo-domain-typings';
import React from 'react';


import { MemberStatusTagProps } from './MemberStatusTag.types';


function MemberStatusTag({ status, showTooltip, t }: MemberStatusTagProps) {
    const colorHandlers:{
    predicate: () => boolean,
    handler: () => { color?: string } | undefined
  }[] = [
      {
          predicate: () => status === MemberStatuses.BANNED,
          handler: () => ({ color: 'red' }),
      },
      {
          predicate: () => status === MemberStatuses.ACTIVE,
          handler: () => ({ color: 'green' }),
      },
      {
          predicate: () => status === MemberStatuses.INVITED,
          handler: () => ({ color: 'orange' }),
      },
      {
          predicate: () => status === MemberStatuses.UNVERIFIED,
          handler: () => ({ color: 'orange' }),
      },
      {
          predicate: () => status === MemberStatuses.KYC_PENDING,
          handler: () => ({ color: 'orange' }),
      },
      {
          predicate: () => status === MemberStatuses.KYC_UPLOADED,
          handler: () => ({ color: 'orange' }),
      },

      {
          predicate: () => status === MemberStatuses.KYC_MANUAL_REVIEW,
          handler: () => ({ color: 'orange' }),
      },
      {
          predicate: () => status === MemberStatuses.KYC_REJECTED,
          handler: () => ({ color: 'red' }),
      },
      {
          predicate: () => status === MemberStatuses.DORMANT,
          handler: () => ({ color: 'green' }),
      },
      {
          predicate: () => status === MemberStatuses.SUSPENDED,
          handler: () => ({ color: 'orange' }),
      },
      {
          predicate: () => status === SharedEntityStatuses.PENDING_CLOSURE,
          handler: () => ({ color: 'orange' }),
      },
      {
          predicate: () => status === SharedEntityStatuses.CLOSED,
          handler: () => ({ color: 'red' }),
      },
      {
          predicate: () => true,
          handler: () => undefined,
      },
  ];

    const tagData = colorHandlers.filter(({ predicate }) => predicate())[0].handler();

    return (
        <StandardTag
            color={tagData?.color}
            text={Object.keys(MemberStatuses).includes(status) || Object.keys(SharedEntityStatuses).includes(status)
                ? t(`member:memberStatuses.${status as string}`)
                : 'Unknown'}
            showTooltip={showTooltip}
        />
    );
}

export default MemberStatusTag;
