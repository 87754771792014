import StandardTooltip from 'components/common/molecules/StandardTooltip';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { ValueTypes } from 'components/common/molecules/value-renderers/StandardValue/StandardValue.helpers';
import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';
import { applicationVersion, appVersion, baseApiUrl } from 'config/config';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Styled from './AppVersionInfo.styled';


function AppVersionInfo({ t, environmentName }) {
    // export const appVersion = () => ({
    //   APP_VERSION: JSON.parse(process.env.APP_VERSION),
    //   APP_BUILD_DATE: new Date(JSON.parse(process.env.APP_BUILD_DATE)).toISOString(),
    //   GIT_COMMIT_HASH: JSON.parse(process.env.GIT_COMMIT_HASH),
    //   GIT_LAST_COMMIT_DATETIME: JSON.parse(process.env.GIT_LAST_COMMIT_DATETIME),


    const buildInfo = appVersion();


    return (
        <Styled.AppVersionInfoWrapper>
            <StandardTooltip
                title={t('common:versionInfo.infoTooltipText')}
                placement={'topRight'}
            >
                <Popover
                    placement="right"
                    content={(
                        <Styled.AppVersionInfoPopupWrapper>
                            <LabelAndValueRow
                                label={t('common:versionInfo.fields.apiUrl')}
                                value={<StandardValue valueType={ValueTypes.TEXT} value={baseApiUrl} />}
                            />
                            <LabelAndValueRow
                                label={t('common:versionInfo.fields.appBuildDate')}
                                value={<StandardValue valueType={ValueTypes.DATE} value={buildInfo.APP_BUILD_DATE} showTime />}
                            />
                            <LabelAndValueRow
                                label={t('common:versionInfo.fields.releaseHash')}
                                value={<StandardValue valueType={ValueTypes.TEXT} value={buildInfo.GIT_COMMIT_HASH} />}
                            />
                        </Styled.AppVersionInfoPopupWrapper>
                    )}
                    trigger="click"
                >
                    <Button type="text">
                        <>
                            {environmentName && <span className="app-environment">{environmentName}</span>}
                            {`v${applicationVersion}`}
                        </>
                        <QuestionCircleOutlined />
                    </Button>
                </Popover>
            </StandardTooltip>
        </Styled.AppVersionInfoWrapper>
    );
}

AppVersionInfo.propTypes = {
    t: PropTypes.func.isRequired,
    environmentName: PropTypes.string,
};

export default AppVersionInfo;
