import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchSupportedCurrenciesFailure, fetchSupportedCurrenciesSuccess } from './actions';
import { FETCH_SUPPORTED_CURRENCIES } from './actions.types';


export const onFetchSupportedCurrencies = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_SUPPORTED_CURRENCIES),
    switchMap(({ payload }) => from(beneficiaries.fetchSupportedCurrencies(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchSupportedCurrenciesSuccess(
            response.data?.length > 0
                ? response.data.map((currency) => ({
                    full_name: currency.name,
                    short: currency.iso,
                    symbol: currency.symbol,
                }))
                : response.data,
            response?.data?.length,
            payload?.locationPathname,
        ))),
        catchError(() => of(fetchSupportedCurrenciesFailure(payload?.locationPathname))),
    )),
);


export default [
    onFetchSupportedCurrencies,
];
