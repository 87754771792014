import {
    AUDIT_PENDING_TRANSACTION,
    AUDIT_PENDING_TRANSACTION_FAILURE,
    AUDIT_PENDING_TRANSACTION_SUCCESS, CLEAR_TRANSACTION_DETAILS, FETCH_TRANSACTION_DETAILS,
    FETCH_TRANSACTION_DETAILS_FAILURE, FETCH_TRANSACTION_DETAILS_SUCCESS, UNBLOCK_TRANSACTION,
    UNBLOCK_TRANSACTION_FAILURE, UNBLOCK_TRANSACTION_SUCCESS,
} from 'redux/transaction/actions.types';

import { createAction } from '@reduxjs/toolkit';


export const fetchTransactionDetails = createAction(FETCH_TRANSACTION_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchTransactionDetailsSuccess = createAction(FETCH_TRANSACTION_DETAILS_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const fetchTransactionDetailsFailure = createAction(FETCH_TRANSACTION_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearTransactionDetails = createAction(CLEAR_TRANSACTION_DETAILS, (locationPathname) => ({ payload: { locationPathname } }));


export const createP2PTransaction = createAction('transaction/createP2PTransaction', (transactionUuid) => ({ payload: transactionUuid }));
export const createP2PTransactionSuccess = createAction('transaction/createP2PTransactionSuccess', (data) => ({ payload: data }));
export const createP2PTransactionFailure = createAction('transaction/createP2PTransactionFailure');

export const clearResetNewTransactionFormState = createAction('transaction/clearResetNewTransactionFormState');

export const refundTopUp = createAction('transaction/refundTopUp', (transactionUuid) => ({ payload: transactionUuid }));
export const refundTopUpSuccess = createAction('transaction/refundTopUpSuccess', (data) => ({ payload: data }));
export const refundTopUpFailure = createAction('transaction/refundTopUpFailure');

export const unblockTransaction = createAction(UNBLOCK_TRANSACTION, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const unblockTransactionSuccess = createAction(UNBLOCK_TRANSACTION_SUCCESS, (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const unblockTransactionFailure = createAction(UNBLOCK_TRANSACTION_FAILURE);

export const auditPendingTransaction = createAction(AUDIT_PENDING_TRANSACTION, (requestPayload, locationPathname) => ({ payload: { requestPayload, locationPathname } }));
export const auditPendingTransactionSuccess = createAction(AUDIT_PENDING_TRANSACTION_SUCCESS,
    (responseData, locationPathname) => ({ payload: { responseData, locationPathname } }));
export const auditPendingTransactionFailure = createAction(AUDIT_PENDING_TRANSACTION_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export default {
    fetchTransactionDetails,
    fetchTransactionDetailsSuccess,
    fetchTransactionDetailsFailure,
    createP2PTransaction,
    createP2PTransactionSuccess,
    createP2PTransactionFailure,
    refundTopUp,
    refundTopUpSuccess,
    refundTopUpFailure,
    clearResetNewTransactionFormState,
    unblockTransaction,
    unblockTransactionSuccess,
    unblockTransactionFailure,
    auditPendingTransaction,
    auditPendingTransactionSuccess,
    auditPendingTransactionFailure,
};

