import PropTypes from 'prop-types';
import { useEffect } from 'react';


function DefaultRedirection({ defaultRedirectionPath, dispatchRequestNavigation, navigationState }) {
    useEffect(() => {
        dispatchRequestNavigation(defaultRedirectionPath, navigationState);
    }, []);

    return null;
}

DefaultRedirection.propTypes = {
    defaultRedirectionPath: PropTypes.string.isRequired,
    navigationState: PropTypes.shape({}),
    dispatchRequestNavigation: PropTypes.func.isRequired,
};

export default DefaultRedirection;
