import StandardMultiStepForm from 'components/common/organisms/StandardMultiStepForm';
import { companiesRootRoutePath } from 'config/routes';
import { createClientDetailsCompaniesListPath, createCompanyDetailsPath } from 'config/routes.helpers';
import { AccessControlProps } from 'constants/AccessControlModel';
import { ReasonsForBlockedNavigation } from 'constants/ApplicationStateModel';
import { getLastStepExceptSuccessFromAvailableSteps } from 'redux/multi-steps-form/epics.helpers';

import { Form, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


import { createAddCompanyFormStepsConfiguration } from './AddCompanyForm.helpers';
import { createActionsForStepSuccessfulValidation } from './common.helpers';


function AddCompanyForm({
    currentUserClientId,
    accessControl,
    locationPathname,
    clientId,
    isLoadingAddCompany,

    dispatchAddCompany,
    newlyCreatedCompanyData,

    availableSteps,
    currentMultiStepsFormStep,

    dispatchRequestSetMultiStepsFormStep,
    dispatchRequestSetMultiStepsFormSubStep,
    dispatchClearClientIdContext,
    dispatchSetClientIdContext,
    t,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        if (clientId > 0 && accessControl.isSuperAdmin && currentUserClientId !== clientId) { // XXX allowed - client context
            dispatchSetClientIdContext(clientId);
        }

        return () => {
            if (accessControl.isSuperAdmin) { // XXX allowed -client context
                dispatchClearClientIdContext();
            }
        };
    }, []);

    const lastStepExceptSuccess = useMemo(
        () => getLastStepExceptSuccessFromAvailableSteps(availableSteps),
        [availableSteps],
    );

    const onSuccessViewNewEntityPathname = useMemo(
        () => {
            if (newlyCreatedCompanyData && newlyCreatedCompanyData?.company_id > 0) {
                return createCompanyDetailsPath(newlyCreatedCompanyData?.company_id);
            }
            return undefined;
        },
        [newlyCreatedCompanyData],
    );


    const stepsConfiguration = useMemo(
        () => createAddCompanyFormStepsConfiguration({
            t,
            clientId,
            locationPathname,
        }),
        [locationPathname],
    );


    const onFinishHandler = (values) => {
        createActionsForStepSuccessfulValidation({
            values,
            locationPathname,
            currentMultiStepsFormStep,
            dispatchFinalApiCall: dispatchAddCompany,
            dispatchRequestSetMultiStepsFormStep,
            dispatchRequestSetMultiStepsFormSubStep,

            lastStepExceptSuccess,
        });
    };

    return stepsConfiguration
        ? (
            <StandardMultiStepForm
                formName="addCompany"
                form={form}
                isLoading={isLoadingAddCompany}
                locationPathname={locationPathname}
                stepsConfiguration={stepsConfiguration}
                initialFormData={clientId > 0 ? { clientId } : {}}
                reasonForBlockedNavigation={ReasonsForBlockedNavigation.NOT_PRISTINE_ADD_COMPANY_FORM}
                onFinishHandler={onFinishHandler}
                onSuccessNewLocationPathname={onSuccessViewNewEntityPathname}
                onSuccessNewLocationLabel="View new company"
                onSuccessGoBackLabel="View companies list"
                goBackPathname={clientId > 0 ? createClientDetailsCompaniesListPath(clientId) : companiesRootRoutePath}
                onSubmitSpinnerCopy={'Submitting new company...'}
            />
        )
        : (<Skeleton loading active />);
}

AddCompanyForm.propTypes = {
    // mapped props
    accessControl: AccessControlProps,
    isLoadingAddCompany: PropTypes.bool,
    clientId: PropTypes.number,
    locationPathname: PropTypes.string,
    currentUserClientId: PropTypes.number,
    dispatchAddCompany: PropTypes.func,
    dispatchRequestSetMultiStepsFormStep: PropTypes.func,
    dispatchRequestSetMultiStepsFormSubStep: PropTypes.func,
    dispatchClearClientIdContext: PropTypes.func,
    dispatchSetClientIdContext: PropTypes.func,
};

export default AddCompanyForm;
