import { standardPageSize } from 'config/config';
import { clientRootRoutePath, marketingPreferencesRouteSegmentPath } from 'config/routes';
import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { clearCurrentUser } from 'redux/current-user/actions';
import { PREFORM_NAVIGATION_SUCCESS } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists, updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';
import { withTotalCount } from 'services/http/http.helpers';

import { createReducer } from '@reduxjs/toolkit';


import { FETCH_MARKETING_PREFERENCES, FETCH_MARKETING_PREFERENCES_FAILURE, FETCH_MARKETING_PREFERENCES_SUCCESS, CLEAR_MARKETING_PREFERENCES_LIST } from './actions.types';


export const marketingPreferencesReducerName = 'marketingPreferences';
export const marketingPreferencesListsPerLocationStoreKeyName = 'marketingPreferencesListsPerLocationStoreKey';


export const defaultMarketingPreferencesListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    clientId: undefined,
    memberId: undefined,
    search: '',
    ...withTotalCount,
};

const initialState = { [marketingPreferencesListsPerLocationStoreKeyName]: {} };

const handlers = {
    [FETCH_MARKETING_PREFERENCES]: (state, action) => {
        state[marketingPreferencesListsPerLocationStoreKeyName] = updateStandardLists(state[marketingPreferencesListsPerLocationStoreKeyName], action);
    },
    [FETCH_MARKETING_PREFERENCES_SUCCESS]: (state, action) => {
        state[marketingPreferencesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[marketingPreferencesListsPerLocationStoreKeyName], action);
    },
    [FETCH_MARKETING_PREFERENCES_FAILURE]: (state, action) => {
        state[marketingPreferencesListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[marketingPreferencesListsPerLocationStoreKeyName], action);
    },
    [CLEAR_MARKETING_PREFERENCES_LIST]: (state, action) => {
        state[marketingPreferencesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(
            state[marketingPreferencesListsPerLocationStoreKeyName],
            action.payload.locationPathname,
        );
    },
    [PREFORM_NAVIGATION_SUCCESS]: (state, action) => {
        state[marketingPreferencesListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[marketingPreferencesListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [marketingPreferencesRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === marketingPreferencesReducerName
      && action.payload?.fieldName === marketingPreferencesListsPerLocationStoreKeyName
        ) {
            state[marketingPreferencesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[marketingPreferencesListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
