import BeneficiaryInfoLayout from 'components/common/organisms/BeneficiaryInfoLayout';
import BackButton from 'components/pages/authorised/AddEditBeneficiary/BackButton';
import { BeneficiaryTypeProp } from 'constants/BeneficiariesModel';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';

import { Button, Form, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


import EditModePreview from './EditModePreview';


function ReviewStep({
    t,
    formData,
    initialValues,
    isEditMode,
}) {
    return isEditMode
        ? (
            <EditModePreview
                t={t}
                formData={convertSnakeCaseKeysToCamelCase(formData)}
                initialValues={convertSnakeCaseKeysToCamelCase(initialValues)}
            />
        )
        : (
            <Col style={{ maxWidth: '700px' }}>
                <BeneficiaryInfoLayout
                    beneficiaryDetails={convertSnakeCaseKeysToCamelCase(formData)}
                />

                <Row key="form buttons" style={{ marginTop: 20 }}>
                    <BackButton />

                    <Form.Item>
                        <Button
                            htmlType="submit"
                            style={{ marginLeft: 10 }}
                            type="primary"
                        >
                            {t('common:buttons.submit.text')}
                        </Button>
                    </Form.Item>
                </Row>
            </Col>
        );
}

ReviewStep.propTypes = {
    t: PropTypes.func.isRequired,
    formData: PropTypes.shape({ beneficiary_type: BeneficiaryTypeProp }),
};


export default ReviewStep;
