import { SortDirections } from 'constants/ApplicationStateModel';
import { globalLocation } from 'constants/NavigationModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import { fetchCardTiers, fetchCardTiersFailure, fetchCardTiersSuccess, clearCardTiers } from './actions';


export const cardTiersReducerName = 'cardTiers';
export const cardTiersListsPerLocationStoreKeyName = 'cardTiersListsPerLocation';


export const defaultCardTiersListQueryParams = {
    limit: 20,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};


const initialState = { [cardTiersListsPerLocationStoreKeyName]: { [globalLocation]: { queryParams: defaultCardTiersListQueryParams } } };

const handlers = {
    [fetchCardTiers]: (state, action) => {
        state[cardTiersListsPerLocationStoreKeyName] = updateStandardLists(state[cardTiersListsPerLocationStoreKeyName], action);
    },
    [fetchCardTiersSuccess]: (state, action) => {
        state[cardTiersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[cardTiersListsPerLocationStoreKeyName], action);
    },
    [fetchCardTiersFailure]: (state, action) => {
        state[cardTiersListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[cardTiersListsPerLocationStoreKeyName], action);
    },

    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === cardTiersReducerName
            && action.payload?.fieldName === cardTiersListsPerLocationStoreKeyName
        ) {
            state[cardTiersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[cardTiersListsPerLocationStoreKeyName] }, action);
        }
    },


    [clearCardTiers]: (state, action) => {
        state[cardTiersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[cardTiersListsPerLocationStoreKeyName], action.payload?.locationPathname);
    },


    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
