import StandardTooltip from 'components/common/molecules/StandardTooltip';
import { ReadOnlyInfoProps } from 'constants/ApplicationStateModel';

import PropTypes from 'prop-types';
import React from 'react';


import { createReadOnlyIndicatorTooltipContent } from './ReadOnlyIndicator.helpers';
import Styled from './ReadOnlyIndicator.styled';


function ReadOnlyIndicator({ readOnlyInfo, t }) {
    return readOnlyInfo.entityId > 0
    && readOnlyInfo.readOnlyStateReason
        ? (
            <Styled.ReadOnlyIndicatorWrapper>
                <StandardTooltip
                    placement="bottom"
                    overlayInnerStyle={{ maxWidth: 'none !important' }}
                    title={createReadOnlyIndicatorTooltipContent({ readOnlyInfo, t })}
                >
                    <Styled.Content>
                        <Styled.DecorIcon />
                        <Styled.WarnCopy>{t('readOnlyInfo.readOnlyNavbarTitle')}</Styled.WarnCopy>
                        <Styled.InfoIcon />
                    </Styled.Content>
                </StandardTooltip>
            </Styled.ReadOnlyIndicatorWrapper>
        ) : <div />;
}

ReadOnlyIndicator.propTypes = {
    readOnlyInfo: ReadOnlyInfoProps,
    t: PropTypes.func.isRequired,
};


export default ReadOnlyIndicator;
