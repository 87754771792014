/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';

import { getRolesAPI, createRoleAPI, updateRoleAPI, deleteRoleAPI, getRoleById } from './index';


class RolesService {
    public getRoles(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { clientId, applicationUuid } = rawQueryParams;

        const query = {
            params: {
                ...(clientId && clientId > 0 ? { clientId } : {}),
                ...(applicationUuid ? { applicationUuid } : {}),
            },
        };
        return getRolesAPI(query);
    }

    public fetchRoleDetails(rawQueryParams): Promise<ApiResponse | ApiError> {
        const { roleId } = rawQueryParams;
        return getRoleById(roleId);
    }

    public createRole(requestPayload = {}): Promise<ApiResponse | ApiError> {
        return createRoleAPI(requestPayload);
    }

    public updateRole(rawRequestPayload): Promise<ApiResponse | ApiError> {
        const { roleId, ...requestPayload } = rawRequestPayload;
        return updateRoleAPI(roleId, requestPayload);
    }

    public deleteRole(roleId): Promise<ApiResponse | ApiError> {
        return deleteRoleAPI(roleId);
    }
}

export default RolesService;
