import { SET_QUERY_PARAMS } from 'redux/application/actions.types';
import { CLEAR_CURRENT_USER } from 'redux/current-user/actions.types';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';


import { createReducer } from '@reduxjs/toolkit';

import {

    FETCH_FAILED_WEBHOOK_DETAILS,
    FETCH_FAILED_WEBHOOK_DETAILS_SUCCESS,
    FETCH_FAILED_WEBHOOK_DETAILS_FAILURE,
    REPLAY_FAILED_WEBHOOK,
    REPLAY_FAILED_WEBHOOK_SUCCESS, REPLAY_FAILED_WEBHOOK_FAILURE,
} from './actions.types';


export const failedWebhookReducerName = 'failedWebhook';
export const failedWebhookDetailsPerLocationsStoreKeyName = 'failedWebhookDetailsPerLocations';


const initialState = {
    [failedWebhookDetailsPerLocationsStoreKeyName]: {},
    isLoadingReplayFailedWebhook: [],
};

const handlers = {
    [FETCH_FAILED_WEBHOOK_DETAILS]: (state, action) => {
        state[failedWebhookDetailsPerLocationsStoreKeyName] = updateStandardEntityDetails(state[failedWebhookDetailsPerLocationsStoreKeyName], action);
    },
    [FETCH_FAILED_WEBHOOK_DETAILS_SUCCESS]: (state, action) => {
        state[failedWebhookDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[failedWebhookDetailsPerLocationsStoreKeyName], action);
    },
    [FETCH_FAILED_WEBHOOK_DETAILS_FAILURE]: (state, action) => {
        state[failedWebhookDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsOnFailure(state[failedWebhookDetailsPerLocationsStoreKeyName], action);
    },

    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === failedWebhookReducerName
      && action.payload?.fieldName === failedWebhookDetailsPerLocationsStoreKeyName
        ) {
            state[failedWebhookDetailsPerLocationsStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[failedWebhookDetailsPerLocationsStoreKeyName] }, action);
        }
    },


    [REPLAY_FAILED_WEBHOOK]: (state, action) => {
        state.isLoadingReplayFailedWebhook = Array.from(new Set(state.isLoadingReplayFailedWebhook).add(action.payload.queryParams?.webhookUuid));
    },
    [REPLAY_FAILED_WEBHOOK_SUCCESS]: (state, action) => {
        state.isLoadingReplayFailedWebhook = Array.from(new Set(state.isLoadingReplayFailedWebhook).delete(action.payload?.responsePayload.webhookUuid));
    },
    [REPLAY_FAILED_WEBHOOK_FAILURE]: (state, action) => {
        state.isLoadingReplayFailedWebhook = Array.from(new Set(state.isLoadingReplayFailedWebhook).delete(action.payload?.webhookUuid));
    },

    [CLEAR_CURRENT_USER]: () => initialState,
};

export default createReducer(initialState, handlers);
