export const extractRelevantClientConfig = (clientConfig) => ({
    allow_physical_cards: clientConfig.allow_physical_cards,
    allow_virtual_cards: clientConfig.allow_virtual_cards,
    allow_online_payments: clientConfig.allow_online_payments,
    allow_topup: clientConfig.allow_topup,
    enable_chat: clientConfig.enable_chat,
    physical_cards_limit: clientConfig.physical_cards_limit,
    virtual_cards_limit: clientConfig.virtual_cards_limit,
});

export const isMemberValueSet = (object, key) => {
    if (typeof object === 'undefined' || object === null) {
        return false;
    }
    return Object.prototype.hasOwnProperty.call(object, key);
};

// TODO add translations
export const createColumns = (t) => [
    {
        title: t('Setting Name'),
        dataIndex: 'name',
        width: '50%',
    },
    {
        title: t('Setting Value'),
        dataIndex: 'value',
    },
    {
        title: '',
        dataIndex: 'reset',
    },
];

export default {
    extractRelevantClientConfig,
    isMemberValueSet,
};

