import withAccessControl from 'hocs/withAccessControl';
import { setQueryParams } from 'redux/application/actions';
import { clearBeneficiaryDetails, fetchBeneficiaryDetails } from 'redux/beneficiary/actions';
import { beneficiaryDetailsPerLocationStoreKeyName, beneficiaryReducerName } from 'redux/beneficiary/reducer';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import BeneficiaryDetailsPage from './BeneficiaryDetailsPage.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    isLoadingDeleteBeneficiary: state?.[beneficiaryReducerName]?.isLoadingDeleteBeneficiary,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: beneficiaryReducerName,
        fieldName: beneficiaryDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
});


const mapDispatchToProps = (dispatch) => ({
    dispatchFetchBeneficiaryDetails: (queryParams, locationPathname) => dispatch(fetchBeneficiaryDetails(queryParams, locationPathname)),
    dispatchClearBeneficiaryDetails: (locationPathname) => dispatch(clearBeneficiaryDetails(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: beneficiaryReducerName,
        fieldName: beneficiaryDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose(
    withTranslation('beneficiaries'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BeneficiaryDetailsPage);

