import { currentUserProfileRootRoutePath, usersRootRoutePath } from 'config/routes';
import { createUserDetailsPath } from 'config/routes.helpers';
import { UserStatuses } from 'constants/UserModel';
import { showSuccessToast } from 'redux/application/actions';
import { refreshToken } from 'redux/authorisation/actions';
import { hideModal, setModalProps } from 'redux/modal/actions';
import { requestNavigation, requestNavigationBack } from 'redux/navigation/actions';
import {
    banUserFailure,
    banUserSuccess,
    deleteUserFailure,
    deleteUserSuccess,
    editUserFailure,
    editUserSuccess,
    fetchUserDetails,
    fetchUserDetailsFailure,
    fetchUserDetailsSuccess, resendConfirmationEmailFailure, resendConfirmationEmailSuccess,
} from 'redux/user/actions';
import { fetchUsers } from 'redux/users/actions';
import { usersListsPerLocationStoreKeyName, usersReducerName } from 'redux/users/reducer';
import { getJwt } from 'utils/jwtToken';

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { EDIT_USER } from './actions.types';


export const onFetchUserDetails = (action$, state$, { users }) => action$.pipe(
    ofType('user/fetchUserDetails'),
    switchMap(({ payload: { queryParams, locationPathname } }) => from(users.fetchUserDetails(queryParams?.userId)).pipe(
        switchMap((response) => of(fetchUserDetailsSuccess(response.data, locationPathname))),
        catchError(() => of(fetchUserDetailsFailure())),
    )),
);


export const onEditUser = (action$, state$, { users, i18n }) => action$.pipe(
    ofType(EDIT_USER),
    switchMap(({ payload }) => from(users.updateUser(payload.queryParams)).pipe(
        switchMap((response) => {
            const jwtToken = getJwt();
            const commonActions = [
                editUserSuccess(response.data, payload?.isCurrentUserSelfEditMode ? currentUserProfileRootRoutePath : payload.locationPathname),
                hideModal(),
                showSuccessToast(i18n.t('users:actionMessages.editUserSuccess')),
            ];

            const currentQueryParams = state$.value[usersReducerName][usersListsPerLocationStoreKeyName]?.[usersRootRoutePath]?.queryParams;

            return of(
                ...(payload?.isCurrentUserSelfEditMode ? [refreshToken(jwtToken)] : []),
                ...commonActions,
                ...(currentQueryParams ? [fetchUsers(currentQueryParams, usersRootRoutePath)] : []),
            );
        }),
        catchError(() => of(
            editUserFailure(),
            setModalProps({
                confirmLoading: false,
                cancelButtonProps: { disabled: false },
            }),
        )),
    )),
);

export const onBanUser = (action$, state$, { users, i18n }) => action$.pipe(
    ofType('user/banUser'),
    switchMap(({ payload }) => from(users.updateUser({ ...payload?.queryParams, status: UserStatuses.BANNED })).pipe(
        switchMap(() => {
            const currentQueryParams = state$.value[usersReducerName][usersListsPerLocationStoreKeyName]?.[usersRootRoutePath]?.queryParams ?? {};
            return of(
                banUserSuccess(),
                fetchUserDetails({ userId: payload?.queryParams?.userId }, createUserDetailsPath(payload?.queryParams?.userId)),
                showSuccessToast(i18n.t('users:actionMessages.banUserSuccess')),
                ...(currentQueryParams ? [fetchUsers(currentQueryParams, usersRootRoutePath)] : []),
            );
        }),
        catchError(() => of(banUserFailure())),
    )),
);

export const onDeleteUser = (action$, state$, { users, i18n }) => action$.pipe(
    ofType('user/deleteUser'),
    switchMap(({ payload }) => from(users.deleteUser(payload.queryParams.userId)).pipe(
        switchMap((response) => {
            const currentQueryParams = state$.value[usersReducerName][usersListsPerLocationStoreKeyName]?.[usersRootRoutePath]?.queryParams ?? {};

            return of(
                deleteUserSuccess(response.data),
                showSuccessToast(i18n.t('users:actionMessages.deleteUserSuccess')),
                ...(currentQueryParams ? [fetchUsers(currentQueryParams, usersRootRoutePath), requestNavigationBack()] : [requestNavigation(usersRootRoutePath)]),
            );
        }),
        catchError(() => of(deleteUserFailure())),
    )),
);

export const onResendConfirmationEmail = (action$, state$, { users, i18n }) => action$.pipe(
    ofType('user/resendConfirmationEmail'),
    switchMap(({ payload }) => from(users.resendConfirmationEmail(payload.queryParams.userId)).pipe(
        switchMap(() => of(
            showSuccessToast(i18n.t('users:actionMessages.resendConfirmationEmailSuccess')),
            resendConfirmationEmailSuccess(),
        )),
        catchError(() => of(resendConfirmationEmailFailure())),
    )),
);

export default [
    onFetchUserDetails,
    onEditUser,
    onBanUser,
    onDeleteUser,
    onResendConfirmationEmail,
];
