import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';

import {
    addClient,
    addClientFailure,
    addClientSuccess, clearAddClientStateData,
    fetchBpsProducts,
    fetchBpsProductsFailure,
    fetchBpsProductsSuccess, fetchDefaultClientConfiguration,
    fetchDefaultClientConfigurationFailure,
    fetchDefaultClientConfigurationSuccess, toggleAddressSameAsOtherAddress,
} from './actions';


export const addClientReducerName = 'addClient';

// form meta data
const defaultFormMetaDataState = {
    parentClientId: undefined,
    formVariant: undefined,
};

// API driven data for new client creation (but not needed [now] for sub-client creation)
const defaultOptionalAPIDrivenDataState = {
    defaultNewClientConfiguration: undefined,
    isLoadingDefaultNewClientConfiguration: false,

    bpsProductsList: undefined,
    isLoadingBpsProductsList: undefined,
};

// form data submission loading state
const defaultFormDataState = {
    defaultNewClientConfiguration: undefined,
    isLoadingDefaultNewClientConfiguration: false,
    isLoadingAddClient: false,
    newlyCreatedPartnerData: undefined,

    shippingAddressSameAsCompanyAddress: true,
    billingAddressSameAsCompanyAddress: true,
};


const initialState = {
    ...defaultFormMetaDataState,
    ...defaultOptionalAPIDrivenDataState,
    ...defaultFormDataState,
};

const handlers = {
    [fetchDefaultClientConfiguration]: (state) => {
        state.isLoadingDefaultConfiguration = true;
    },

    [fetchDefaultClientConfigurationSuccess]: (state, action) => {
        state.isLoadingDefaultConfiguration = false;
        state.defaultNewClientConfiguration = action.payload?.responseData;
    },

    [fetchDefaultClientConfigurationFailure]: (state) => {
        state.isLoadingDefaultConfiguration = false;
        state.defaultNewClientConfiguration = undefined;
    },


    [fetchBpsProducts]: (state) => {
        state.isLoadingBpsProductsList = true;
    },

    [fetchBpsProductsSuccess]: (state, action) => {
        state.isLoadingBpsProductsList = false;
        state.bpsProductsList = action.payload?.responseData;
    },

    [fetchBpsProductsFailure]: (state) => {
        state.isLoadingBpsProductsList = false;
        state.bpsProductsList = undefined;
    },

    [addClient]: (state) => {
        state.isLoadingAddClient = true;
    },

    [addClientSuccess]: (state, action) => {
        state.isLoadingAddClient = false;
        state.newlyCreatedPartnerData = action.payload.responsePayload;
    },

    [addClientFailure]: (state) => {
        state.isLoadingAddClient = false;
        state.newlyCreatedPartnerData = undefined;
    },

    [toggleAddressSameAsOtherAddress]: (state, action) => {
        if (action.payload?.addressName === 'shippingAddressSameAsCompanyAddress') {
            state.shippingAddressSameAsCompanyAddress = action.payload?.currentValue;
        }

        if (action.payload?.addressName === 'billingAddressSameAsCompanyAddress') {
            state.billingAddressSameAsCompanyAddress = action.payload?.currentValue;
        }
    },

    [clearAddClientStateData]: () => initialState,

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
