import React, { JSX } from 'react';

import Styled from './ErrorMessage.styled';


function ErrorMessage({ text, dataTestIdPrefix }: {
  text: string,
  dataTestIdPrefix?: string
}): JSX.Element {
    return (
        <Styled.ErrorWrapper>
            <Styled.ErrorMessage data-test-id={`${dataTestIdPrefix ? `${dataTestIdPrefix}-` : ''}error-message`}>
                {text}
            </Styled.ErrorMessage>
        </Styled.ErrorWrapper>
    );
}

export default ErrorMessage;
