import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';
import { AccountIdentificationType } from 'constants/AccountModel';

import React from 'react';


import { BankDetailsProps } from './BankDetails.types';


function BankDetails({ accountIdentifications }:BankDetailsProps) {
    return (
        <div>
            {accountIdentifications.map((item, index) => (
                <LabelAndValueRow
                    key={`${index}-${item.label}`}
                    label={item.label}
                    value={item.value}
                    whiteSpaceNoWrap={item.identificationType === AccountIdentificationType.BANK_ADDRESS}
                    autoWidthOnValue
                    showCopyToClipboard
                />
            ))}
        </div>
    );
}

export default BankDetails;
