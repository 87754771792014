import EntityDetailsInfoSkeleton from 'components/common/atoms/EntityDetailsInfoSkeleton';
import OptionalButtonsWrapper from 'components/common/molecules/OptionalButtonsWrapper';
import { DetailsSectionsWrapper } from 'components/common/molecules/OptionalButtonsWrapper/OptionalButtonsWrapper.styled';
import QueryParamsMetaInfo from 'components/common/molecules/QueryParamsMetaInfo';
import CommonSection from 'components/common/organisms/CommonSection';
import { createTransactionDetailsPath } from 'config/routes.helpers';
import ModalName from 'constants/Modals';
import { PN } from 'constants/PermissionsModel';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';

import { RollbackOutlined, UnlockOutlined, EyeOutlined, BranchesOutlined } from '@ant-design/icons';
import { TransactionScope, TransactionStatus, TransactionType } from '@manigo/manigo-domain-typings';
import { Button, Col, Popconfirm, Row, Spin } from 'antd';
import React, { useMemo, JSX } from 'react';


import {
    createTransactionStatusFields,
    createTransactionAmountFields,
    createTransactionSenderFields, createTransactionReceiverFields,
} from './TransactionInfo.helpers';
import { TransactionInfoProps } from './TransactionInfo.types';


function TransactionInfo({
    baseUrlWithoutTabsSlugs,
    accessControl,
    t,
    transactionDetails,
    queryParamsMeta,
    onRefresh,
    areDetailsReady,
    isBusy,
    isLoadingRefundTopUp,
    dispatchRefundTopUp,
    dispatchShowModal,
    dispatchRequestNavigation,
}: TransactionInfoProps): JSX.Element {
    const transactionStatusFields = useMemo(
        () => createTransactionStatusFields(transactionDetails, t),
        [transactionDetails],
    );
    const transactionAmountFields = useMemo(
        () => createTransactionAmountFields(transactionDetails, t),
        [transactionDetails],
    );
    const transactionSenderFields = useMemo(
        () => createTransactionSenderFields(transactionDetails, t),
        [transactionDetails],
    );
    const transactionReceiverFields = useMemo(
        () => createTransactionReceiverFields(transactionDetails, t),
        [transactionDetails],
    );


    const canBeRefund = useMemo(
        () => accessControl.hasPermission(PN.TOPUP.REFUND)
      && transactionDetails?.status === TransactionStatus.Completed
      && transactionDetails?.transactionScope === TransactionScope.CT
      && transactionDetails?.transactionType === TransactionType.EXT
      && transactionDetails?.originalAmount > 0
      && !transactionDetails?.correspondingTransactionUuid,
        [transactionDetails],
    );

    const canBeUnblocked = useMemo(
        () => accessControl.hasPermission(PN.TRANSACTIONS.UNBLOCK)
      && transactionDetails?.isBlocked
      && ![TransactionStatus.Completed, TransactionStatus.Reversed].includes(transactionDetails?.status),
        [transactionDetails],
    );

    const canResolvePending = useMemo(
        () => accessControl.hasPermission(PN.TRANSACTIONS.AUDIT_PAYMENT)
      && transactionDetails?.status === TransactionStatus.Pending
      && [TransactionType.EXT, TransactionType.INT].includes(transactionDetails.transactionType)
      && transactionDetails?.amlReview,
        [transactionDetails],
    );


    return (
        <Col
            xs={{ span: 24 }}
            lg={{ span: 24 }}
            style={{
                background: 'white',
                padding: '10px',
            }}
        >
            <EntityDetailsInfoSkeleton
                isLoading={!areDetailsReady && isBusy}
                minHeight={'150px'}
                sectionsRowsCount={3}
                sectionsCount={3}
            >
                <Spin
                    tip="Processing..."
                    spinning={isBusy || isLoadingRefundTopUp}
                >
                    <DetailsSectionsWrapper>
                        <Row gutter={24}>
                            <CommonSection
                                sectionLabel={t('')}
                                fieldsConfig={transactionAmountFields}
                            />

                            <CommonSection
                                sectionLabel={t('')}
                                fieldsConfig={transactionStatusFields}
                            />

                            <CommonSection
                                sectionLabel={t('transactionDetails.tabs.general.subHeadings.sender')}
                                fieldsConfig={transactionSenderFields}
                            />

                            <CommonSection
                                sectionLabel={t('transactionDetails.tabs.general.subHeadings.receiver')}
                                fieldsConfig={transactionReceiverFields}
                            />

                        </Row>

                        <OptionalButtonsWrapper>
                            <>

                                {transactionDetails?.correspondingTransactionUuid ? (
                                    <Button
                                        icon={<EyeOutlined />}
                                        onClick={() => dispatchRequestNavigation(createTransactionDetailsPath(transactionDetails?.correspondingTransactionUuid))}
                                        type="primary"
                                    >
                                        {t('transactions:buttons.viewLinked.text')}
                                    </Button>
                                ) : null}

                                {transactionDetails?.feeTransactionData?.feeOriginTransactionUuid ? (
                                    <Button
                                        icon={<EyeOutlined />}
                                        onClick={() => dispatchRequestNavigation(createTransactionDetailsPath(transactionDetails?.feeTransactionData?.feeOriginTransactionUuid))}
                                        type="primary"
                                    >
                                        {t('transactions:buttons.viewSourceTransaction.text')}
                                    </Button>
                                ) : null}


                                {canBeRefund ? (
                                    <Popconfirm
                                        title={t('buttons.refund.confirmationQuestion')}
                                        okText={t('buttons.refund.text')}
                                        onConfirm={() => dispatchRefundTopUp(transactionDetails?.transactionUuid)}
                                    >
                                        <Button
                                            danger
                                            icon={<RollbackOutlined />}
                                            loading={isLoadingRefundTopUp}
                                            disabled={isLoadingRefundTopUp}
                                        >
                                            {t('buttons.refund.text')}
                                        </Button>
                                    </Popconfirm>
                                ) : null}

                                {canBeUnblocked ? (
                                    <Button
                                        icon={<UnlockOutlined />}
                                        type="primary"
                                        onClick={() => dispatchShowModal({
                                            modalType: ModalName.UNBLOCK_TRANSACTION,
                                            modalProps: {
                                                title: t('transactions:modals.unblockTransaction.title', { reference: transactionDetails.transactionReference }),
                                                initialValues: {
                                                    transactionUuid: transactionDetails?.transactionUuid,
                                                    baseUrlWithoutTabsSlugs,
                                                },
                                            },
                                        })}
                                    >
                                        {t('transactions:modals.unblockTransaction.buttons.unblock.text')}
                                    </Button>
                                ) : null}

                                {canResolvePending ? (
                                    <Button
                                        icon={<BranchesOutlined />}
                                        type="primary"
                                        onClick={() => dispatchShowModal({
                                            modalType: ModalName.TRANSACTION_SCREENING_RESOLUTION_MODAL,
                                            modalProps: {
                                                title: t('transactions:modals.screeningResolution.title'),
                                                initialValues: {
                                                    // TODO should be done on service level
                                                    transactionDetails: convertSnakeCaseKeysToCamelCase(transactionDetails),
                                                    baseUrlWithoutTabsSlugs,
                                                },
                                            },
                                        })}
                                    >
                                        {t('transactions:modals.screeningResolution.title')}
                                    </Button>
                                ) : null}

                            </>
                        </OptionalButtonsWrapper>

                        <QueryParamsMetaInfo
                            detailsContext
                            onListRefresh={onRefresh}
                            queryParams={{ transaction_uuid: transactionDetails?.transactionUuid }}
                            queryParamsMeta={queryParamsMeta}
                        />
                    </DetailsSectionsWrapper>
                </Spin>
            </EntityDetailsInfoSkeleton>
        </Col>
    );
}

export default TransactionInfo;
