import withAccessControl from 'hocs/withAccessControl';
import { currentUserReducerName } from 'redux/current-user/reducer';
import { fetchExchangeRate, initExchange } from 'redux/exchange/actions';
import { exchangeReducerName } from 'redux/exchange/reducer';
import { clearResetNewTransactionFormState, createP2PTransaction } from 'redux/transaction/actions';
import { transactionReducerName } from 'redux/transaction/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import NewTransaction from './NewTransaction.layout';


const mapStateToProps = (state) => ({
    userClientId: state[currentUserReducerName].userData.clientId,

    isLoading: state[transactionReducerName].isBusyCreatingNewTransaction,
    resetFormState: state[transactionReducerName].resetNewTransactionFormState || state[exchangeReducerName].resetNewTransactionFormState,

    isLoadingExchangeRate: state[exchangeReducerName].isLoadingExchangeRate,
    isLoadingExchange: state[exchangeReducerName].isLoading,
    exchangeRate: state[exchangeReducerName].exchangeRate,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchExchangeRate: (data) => dispatch(fetchExchangeRate(data)),
    dispatchInitExchange: (data) => dispatch(initExchange(data)),
    dispatchCreateP2PTransaction: (data) => dispatch(createP2PTransaction(data)),
    dispatchClearResetNewTransactionFormState: () => dispatch(clearResetNewTransactionFormState()),
});

const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);


export default decorate(NewTransaction);
