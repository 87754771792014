import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import styled, { css } from 'styled-components';


const LabelAndValueRow = styled.div<{ $whiteSpaceNoWrap?: boolean, $autoWidthOnValue?: boolean; $showCopyToClipboard?: boolean }>`
  display: grid;
  margin: 0 0 5px 0;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 30px;
  align-items: center;
  max-width: 100%;

  ${({ $showCopyToClipboard }) => $showCopyToClipboard && css`
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) auto;
    grid-column-gap: 10px;
  `};
  
  ${({ $autoWidthOnValue }) => $autoWidthOnValue && css`
    grid-template-columns: minmax(0, 1fr) minmax(auto, 70%);
    grid-column-gap: 20px;
  `};
  
  ${({ $autoWidthOnValue, $showCopyToClipboard }) => $autoWidthOnValue && $showCopyToClipboard && css`
    grid-template-columns: minmax(0, 1fr) minmax(auto, 60%) auto;
    grid-column-gap: 10px;
  `};
`;

const Label = styled.div`
  justify-self: left;
  display:grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  grid-column-gap: 1ch;
  
  ${commonTextEllipsisStyles};
`;

// eslint-disable-next-line spellcheck/spell-checker
const LabelTooltip = styled.div`
  text-align: left;
  
  .anticon {
    color: #397dcc;
    margin-left: 0;
  }
`;


const Value = styled.div<{ $whiteSpaceNoWrap?: boolean; $highlightValue?: boolean }>`
  justify-self: right;
  max-width: 100%;

  ${({ $whiteSpaceNoWrap }) => $whiteSpaceNoWrap && css`
    white-space: nowrap;
  `};
  
  ${({ $highlightValue }) => $highlightValue && css`
    background-color: rgb(255, 192, 105);
  `};
`;

export default {
    LabelAndValueRow,
    LabelTooltip,
    Label,
    Value,
};
