import withAccessControl from 'hocs/withAccessControl';
import { deleteBeneficiary } from 'redux/beneficiary/actions';
import { beneficiaryReducerName } from 'redux/beneficiary/reducer';
import { requestNavigation } from 'redux/navigation/actions';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import BeneficiaryInfo from './BeneficiaryInfo.layout';


const mapStateToProps = (state) => ({ isLoadingDeleteBeneficiary: state[beneficiaryReducerName].isLoadingDeleteBeneficiary });

const mapDispatchToProps = (dispatch) => ({
    dispatchDeleteBeneficiary: (beneficiaryUuid, ownerContext) => dispatch(deleteBeneficiary(beneficiaryUuid, ownerContext)),
    dispatchRequestNavigation: (path, state) => dispatch(requestNavigation(path, state)),
});

const decorate = compose(
    withTranslation('beneficiaries'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BeneficiaryInfo);

