import { defaultQueryParamsMeta } from 'constants/ApplicationStateModel';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'redux/standardEntityDetails.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    CARD_PIN_UNBLOCK, CARD_PIN_UNBLOCK_FAILURE, CARD_PIN_UNBLOCK_SUCCESS,
    CLEAR_CARD_DETAILS,
    DELETE_CARD, DELETE_CARD_FAILURE, DELETE_CARD_SUCCESS,
    FETCH_CARD_DETAILS, FETCH_CARD_DETAILS_FAILURE, FETCH_CARD_DETAILS_SUCCESS,
    SET_CHANGE_CARD_LIMIT_FORM_HAS_LIMIT_VALUE, TOGGLE_CHANGE_CARD_LIMIT_FORM_VISIBILITY,
    UPDATE_CARD, UPDATE_CARD_FAILURE, UPDATE_CARD_LIMITS, UPDATE_CARD_LIMITS_SUCCESS, UPDATE_CARD_SUCCESS,
} from './action.types';


export const cardReducerName = 'card';
export const cardDetailsPerLocationStoreKeyName = 'cardDetailsPerLocationStoreKeyName';

const initialState = {
    [cardDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    cardDetails: undefined,
    isLoadingCardDetails: false,
    isLoadingUpdateCard: false,
    isLoadingDeleteCard: false,
    isLoadingUnblockPin: false,
    isLoadingUpdateLimits: false,
    isChangeLimitFormVisible: false,
    hasLimitFormValue: undefined,
    cardDetailsQueryParamsMeta: defaultQueryParamsMeta,
};

const handlers = {
    // unblock pin
    [CARD_PIN_UNBLOCK]: (state) => {
        state.isLoadingUnblockPin = true;
    },
    [CARD_PIN_UNBLOCK_SUCCESS]: (state) => {
        state.isLoadingUnblockPin = false;
    },
    [CARD_PIN_UNBLOCK_FAILURE]: (state) => {
        state.isLoadingUnblockPin = false;
    },

    [FETCH_CARD_DETAILS]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetails(state[cardDetailsPerLocationStoreKeyName], action);
    },
    [FETCH_CARD_DETAILS_SUCCESS]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[cardDetailsPerLocationStoreKeyName], action);
    },
    [FETCH_CARD_DETAILS_FAILURE]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure(state[cardDetailsPerLocationStoreKeyName], action);
    },
    [CLEAR_CARD_DETAILS]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup(state[cardDetailsPerLocationStoreKeyName], action.payload.locationPathname);
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === cardReducerName
      && action.payload?.fieldName === cardDetailsPerLocationStoreKeyName
        ) {
            state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({ ...state?.[cardDetailsPerLocationStoreKeyName] }, action);
        }
    },

    [UPDATE_CARD]: (state) => {
        state.isLoadingUpdateCard = true;
    },
    [UPDATE_CARD_SUCCESS]: (state, action) => {
        state.isLoadingUpdateCard = false;
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[cardDetailsPerLocationStoreKeyName], action);
    },
    [UPDATE_CARD_FAILURE]: (state) => {
        state.isLoadingUpdateCard = false;
    },


    [DELETE_CARD]: (state) => {
        state.isLoadingDeleteCard = true;
    },
    [DELETE_CARD_SUCCESS]: (state) => {
        state.isLoadingDeleteCard = false;
    },
    [DELETE_CARD_FAILURE]: (state) => {
        state.isLoadingDeleteCard = false;
    },

    [SET_CHANGE_CARD_LIMIT_FORM_HAS_LIMIT_VALUE]: (state, action) => {
        state.hasLimitFormValue = action.payload;
    },
    [TOGGLE_CHANGE_CARD_LIMIT_FORM_VISIBILITY]: (state, action) => {
        state.isChangeLimitFormVisible = action.payload;
    },

    [UPDATE_CARD_LIMITS]: (state) => {
        state.isLoadingUpdateLimits = true;
    },
    [UPDATE_CARD_LIMITS_SUCCESS]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess(state[cardDetailsPerLocationStoreKeyName], action);

        state.isLoadingUpdateLimits = false;
        state.isChangeLimitFormVisible = false;
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
