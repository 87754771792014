import { Descriptions } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { calculateSecondsLeft, renderSeconds } from './Countdown.helpers';


function Countdown({ t, label, date, color, onCountdownEnd, withSuffix }) {
    const timer = useRef(undefined);
    const [secondsLeft, setSecondsLeft] = useState(undefined);

    useEffect(() => {
        if (date) {
            const seconds = calculateSecondsLeft(date);
            if (seconds > 1) {
                setSecondsLeft(seconds);
            }
        }
        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            timer ? clearTimeout(timer.current) : undefined;
        };
    }, [date]);

    useEffect(() => {
        if (secondsLeft && secondsLeft > 1) {
            timer.current = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
        } else if (secondsLeft) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onCountdownEnd && onCountdownEnd();
        }

        return () => {
            if (timer) {
                clearTimeout(timer.current);
            }
        };
    }, [secondsLeft]);


    return (
        <Descriptions.Item label={label}>
            <span style={{ color }}>{renderSeconds(withSuffix, t, secondsLeft)}</span>
        </Descriptions.Item>
    );
}

Countdown.propTypes = {
    t: PropTypes.func.isRequired,
    label: PropTypes.string,
    date: PropTypes.instanceOf(Date).isRequired,
    color: PropTypes.string,
    onCountdownEnd: PropTypes.func,
    withSuffix: PropTypes.bool,
};

export default withTranslation('common')(Countdown);
