import BeneficiaryInfoLayout from 'components/common/organisms/BeneficiaryInfoLayout';
import BackButton from 'components/pages/authorised/AddEditBeneficiary/BackButton';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';

import { Button, Col, Form, Row, Space } from 'antd';
import React, { useMemo } from 'react';


import { EditModePreviewProps } from './EditModePreview.types';
import Styled from '../Review.styled';


function EditModePreview({
    t,
    formData,
    initialValues,
}: EditModePreviewProps) {
    const normalisedValue = (value) => (value === '' ? null : value); // form manager enforces empty strings
    const editedFieldsNames = useMemo(() => {
        let fieldsNames: string[] = [];
        if (formData.companyName !== initialValues.companyName) {
            fieldsNames = [...fieldsNames, 'companyName'];
        }

        if (formData.firstName !== initialValues.firstName) {
            fieldsNames = [...fieldsNames, 'firstName'];
        }

        if (normalisedValue(formData.middleName) !== initialValues.middleName) {
            fieldsNames = [...fieldsNames, 'middleName'];
        }

        if (formData.lastName !== initialValues.lastName) {
            fieldsNames = [...fieldsNames, 'lastName'];
        }

        Object.keys(initialValues.address).forEach((addressKey) => {
            if (normalisedValue(formData.address[addressKey]) !== initialValues.address[addressKey]) {
                fieldsNames = [...fieldsNames, `address.${convertSnakeCaseKeysToCamelCase(addressKey)}`];
            }
        });

        (initialValues?.accountIdentifications || []).forEach((item, index) => {
            if (item?.identificationValue !== formData.accountIdentifications?.[index]?.identificationValue) {
                fieldsNames = [...fieldsNames, `${item.identificationType}`];
            }
        });
        return fieldsNames;
    },
    [formData, initialValues]);

    const noChangesDetected = useMemo(() => !editedFieldsNames?.length,
        [editedFieldsNames]);

    return (
        <Col style={{ maxWidth: '900px' }}>
            <Styled.EditModeColumns>
                <div>
                    <Styled.ColumnHeading>{t('beneficiaryUpdate.subHeadings.dataBefore')}</Styled.ColumnHeading>
                    <BeneficiaryInfoLayout
                        editedFieldsNames={editedFieldsNames}
                        beneficiaryDetails={initialValues}
                    />
                </div>

                <div>
                    <Styled.ColumnHeading>{t('beneficiaryUpdate.subHeadings.dataAfter')}</Styled.ColumnHeading>
                    <BeneficiaryInfoLayout
                        editedFieldsNames={editedFieldsNames}
                        beneficiaryDetails={formData}
                    />
                </div>
            </Styled.EditModeColumns>

            <Row key="form buttons" style={{ marginTop: 20 }}>
                <BackButton />

                <Form.Item>
                    <Space>
                        <Button
                            htmlType="submit"
                            style={{ marginLeft: 10 }}
                            disabled={noChangesDetected}
                            type="primary"
                        >
                            {t('common:buttons.update.text')}
                        </Button>

                        {noChangesDetected && t('beneficiaryUpdate.noChangesDetected')}
                    </Space>
                </Form.Item>

            </Row>
        </Col>
    );
}

export default EditModePreview;
