import PropTypes from 'prop-types';


export const TopupCardsListItemProps = PropTypes.shape({
    account_id: PropTypes.number,
    member_id: PropTypes.number,
    business_user_id: PropTypes.number,
    id: PropTypes.number,

    card_holder: PropTypes.string,
    card_image: PropTypes.string, // XXX useless empty field  TODO for BE
    cvv: PropTypes.string, // XXX useless field with constant value "***" TODO for BE
    expiry: PropTypes.string, // "10/26"
    scheme: PropTypes.string, // "VISA"
    pan_masked: PropTypes.string, // "4462****0000"
    is_verified: PropTypes.bool,
    verification_pending: PropTypes.number, // 0/1 TODO for BE it should be boolean
});


export const TopupCardsListProp = PropTypes.arrayOf(TopupCardsListItemProps);


export default {
    TopupCardsListItemProps,
    TopupCardsListProp,
};
