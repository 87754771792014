import { standardPageSize } from 'config/config';
import { clientRootRoutePath, productsRouteSegmentPath } from 'config/routes';
import { setQueryParams } from 'redux/application/actions';
import { clearCurrentUser } from 'redux/current-user/actions';
import { preformNavigationSuccess } from 'redux/navigation/actions';
import {
    clearStandardListDataQueryParamsOnNavigation,
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'redux/standardLists.helpers';

import { createReducer } from '@reduxjs/toolkit';


import {
    fetchClientProducts,
    fetchClientProductsSuccess,
    fetchClientProductsFailure,
    clearClientProducts,
} from './actions';


export const clientProductsReducerName = 'clientProducts';
export const clientProductsListsPerLocationStoreKeyName = 'clientProductsListsPerLocation';

export const clientProductsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    type: undefined,
    currency: undefined,
    // TODO no sorting supported on BE, add FE sorting
};


const initialState = { [clientProductsListsPerLocationStoreKeyName]: {} };

const handlers = {
    [fetchClientProducts]: (state, action) => {
        state[clientProductsListsPerLocationStoreKeyName] = updateStandardLists(state[clientProductsListsPerLocationStoreKeyName], action);
    },
    [fetchClientProductsSuccess]: (state, action) => {
        state[clientProductsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(state[clientProductsListsPerLocationStoreKeyName], action);
    },
    [fetchClientProductsFailure]: (state, action) => {
        state[clientProductsListsPerLocationStoreKeyName] = updateStandardListsOnFailure(state[clientProductsListsPerLocationStoreKeyName], action);
    },
    [preformNavigationSuccess]: (state, action) => {
        state[clientProductsListsPerLocationStoreKeyName] = clearStandardListDataQueryParamsOnNavigation(
            state[clientProductsListsPerLocationStoreKeyName],
            {
                action,
                listRouteSegments: [productsRouteSegmentPath],
                observedRootPaths: [clientRootRoutePath],
            },
        );
    },
    [setQueryParams]: (state, action) => {
        if (action.payload?.reducerName === clientProductsReducerName
      && action.payload?.fieldName === clientProductsListsPerLocationStoreKeyName
        ) {
            state[clientProductsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({ ...state?.[clientProductsListsPerLocationStoreKeyName] }, action);
        }
    },

    [clearClientProducts]: (state, action) => {
        state[clientProductsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup(state[clientProductsListsPerLocationStoreKeyName], action.payload?.locationPathname);
    },

    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
