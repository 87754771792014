import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function BpsProductsSelect({
    availableBpsProducts,
    isLoadingBpsProductsList,
    isDisabled,
    onChange,
    value,
}) {
    return (
        <Select
            placeholder={'Select BPS product'}
            loading={isLoadingBpsProductsList}
            dropdownMatchSelectWidth={false}
            optionLabelProp="label"
            disabled={isDisabled}
            onChange={onChange}
            value={value}
        >
            {availableBpsProducts}
        </Select>
    );
}


BpsProductsSelect.propTypes = {
    isLoadingBpsProductsList: PropTypes.bool,
    isDisabled: PropTypes.bool,
    availableBpsProducts: PropTypes.arrayOf(PropTypes.shape()),
};

export default BpsProductsSelect;
