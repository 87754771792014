import { CommonQueryParamsMetaProps, CommonQueryParamsProps } from 'constants/ApplicationStateModel';

import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';


function CreateEditGroup({
    t,
    clientGroupsListData,

    isEditMode = false,
    initialValues,
    dispatchSetModalProps,
    dispatchUpdateGroupName,
    dispatchFetchGroups,
    dispatchCreateNewGroup,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        if (!clientGroupsListData?.items) {
            dispatchFetchGroups({ clientId: initialValues?.clientId });
        }

        dispatchSetModalProps({
            onOk: () => {
                form.submit();
            },
        });
    }, []);

    const existingGroupsNames = useMemo(
        () => {
            if (clientGroupsListData?.items) {
                return (clientGroupsListData?.items || []).map((group) => group.name.toLowerCase());
            }
            return [];
        }, [clientGroupsListData],
    );

    const fieldName = isEditMode ? 'newGroupName' : 'groupName';


    const onFinish = (values) => {
        if ((isEditMode && values.newGroupName) || values.groupName) {
            dispatchSetModalProps({
                confirmLoading: true,
                cancelButtonProps: { disabled: true },
            });

            if (isEditMode) {
                dispatchUpdateGroupName({
                    groupId: initialValues.groupId,
                    clientId: initialValues.clientId,
                    newGroupName: values.newGroupName,
                    locationPathname: initialValues?.locationPathname,
                });
            } else {
                dispatchCreateNewGroup({
                    clientId: initialValues.clientId,
                    groupName: values.groupName,
                    locationPathname: initialValues?.locationPathname,
                });
            }
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onFinish={onFinish}
        >
            <Form.Item
                name={fieldName}
                label={t(`groups:modals.createEditGroup.fields.${fieldName}.label`)}
                rules={[
                    {
                        required: true,
                        message: t(`groups:modals.createEditGroup.fields.${fieldName}.validationRules.required`),

                    },
                    {
                        validator: (_, value) => (!existingGroupsNames.includes(value.toLowerCase())
                            ? Promise.resolve()
                            : Promise.reject(new Error(t(`groups:modals.createEditGroup.fields.${fieldName}.validationRules.unique`)))),
                    },
                ]}
            >
                <Input placeholder={t(`groups:modals.createEditGroup.fields.${fieldName}.placeholder`)} />
            </Form.Item>
        </Form>
    );
}

CreateEditGroup.propTypes = {
    t: PropTypes.func.isRequired,
    listData: PropTypes.shape({
        isLoadingList: PropTypes.bool,
        totalCount: PropTypes.number,
        items: PropTypes.arrayOf(PropTypes.shape({})),
        queryParams: CommonQueryParamsProps,
        queryParamsMeta: CommonQueryParamsMetaProps,
    }),
    initialValues: PropTypes.shape({
        clientId: PropTypes.number,
        groupId: PropTypes.number,
    }),
    isEditMode: PropTypes.bool,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchUpdateGroupName: PropTypes.func.isRequired,
    dispatchFetchGroups: PropTypes.func.isRequired,
};


export default CreateEditGroup;

