import StandardTooltip from 'components/common/molecules/StandardTooltip';

import PropTypes from 'prop-types';
import React from 'react';

import Styled from './StandardTag.styled';


function StandardTag({
    text,
    color,
    children,
    isTextEllipsisDisabled = false,
    dataTestId = 'tag',
    dataTestIdPrefix = '',
    dataTestIdSuffix = '',
    showTooltip = true,
}) {
    const tag = (
        <Styled.StandardTagWrapper $enableTextEllipsis={!isTextEllipsisDisabled}>
            <Styled.StandardTag
                className="standard-tag"
                color={color}
                text={text}
                data-test-id={`${dataTestIdPrefix ? `${dataTestIdPrefix}-` : ''}${dataTestId}${dataTestIdSuffix ? `-${dataTestIdSuffix}` : ''}`}
            >
                {children || text}
            </Styled.StandardTag>
        </Styled.StandardTagWrapper>
    );

    return showTooltip ? (
        <StandardTooltip
            title={text}
            showCopyToClipboard={false}
            isTextEllipsisDisabled={isTextEllipsisDisabled}
        >
            {tag}
        </StandardTooltip>
    ) : tag;
}


StandardTag.propTypes = {
    text: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    children: PropTypes.node,
    color: PropTypes.string,
    dataTestId: PropTypes.string,
    dataTestIdPrefix: PropTypes.string,
    dataTestIdSuffix: PropTypes.string,
    isTextEllipsisDisabled: PropTypes.bool,
    showTooltip: PropTypes.bool,
};

export default StandardTag;
