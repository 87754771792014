import styled from 'styled-components';


const Columns = styled.div`
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) 1fr minmax(0, 1fr);
    grid-column-gap: 50px;
`;

const EditModeColumns = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 50px;
`;

const ColumnHeading = styled.h2`
  border-bottom: 2px solid #f0f0f0;
`;

export default { Columns, EditModeColumns, ColumnHeading };
