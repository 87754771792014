/* eslint-disable class-methods-use-this */
import { ApiError, ApiResponse } from 'models/api/http';
import { RuleScope } from 'models/domain/rules';
import { cleanNilValues } from 'utils/dataMappers';

import { getRuleAPI, getRulesAPI } from '.';


class RulesService {
    public getRules(queryParams): Promise<ApiResponse | ApiError> {
        const { ruleScope, ruleType, ruleTransactionType, clientExtId, groupExtId } = queryParams;
        return getRulesAPI({
            params: cleanNilValues({
                ruleScope,
                ruleType: ruleType?.[0],
                ruleTransactionType: ruleTransactionType?.[0],
                clientExtId: ruleScope === RuleScope.INSTITUTION ? clientExtId : undefined,
                groupExtId: ruleScope === RuleScope.GROUP ? groupExtId : undefined,
            }),
            convertSnakeCaseKeysToCamelCase: true,
        });
    }

    public getRule(queryParams): Promise<ApiResponse | ApiError> {
        return getRuleAPI(queryParams.limitExtId, { convertSnakeCaseKeysToCamelCase: true });
    }
}
export default RulesService;
