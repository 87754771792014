import EmailInput from 'components/common/molecules/form-controls/EmailInputField';
import PhoneInput from 'components/common/molecules/form-controls/PhoneInputField/PhoneInput.layout';
import { AccessControlProps } from 'constants/AccessControlModel';
import { BusinessUserProps } from 'constants/BusinessUserModel';
import convertSnakeCaseKeysToCamelCase from 'utils/convertSnakeCaseKeysToCamelCase';
import { commonValidation, isValidFirstNameRule, isValidLastNameRule } from 'utils/validation-tools';

import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';


function EditBusinessUserModal({
    accessControl,
    initialValues,
    listOfOriginLocationPathname,
    businessUserId,
    dispatchUpdateBusinessUser,
    dispatchSetModalProps,
    t,
}) {
    const [form] = Form.useForm();

    useEffect(() => {
        dispatchSetModalProps({ onOk: () => { form.submit(); } });
    }, []);


    const onFinish = (values) => {
        dispatchSetModalProps({
            confirmLoading: true,
            cancelButtonProps: { disabled: true },
        });

        dispatchUpdateBusinessUser({ businessUserId, ...values }, listOfOriginLocationPathname);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ ...convertSnakeCaseKeysToCamelCase(initialValues) }}
            onFinish={onFinish}
        >
            <Form.Item
                name="firstName"
                label={t('personalFields.firstName.label')}
                rules={[
                    ...commonValidation({ fieldName: 'firstName', t, isRequired: true, maxLength: 128 }),
                    ...isValidFirstNameRule(t),
                ]}
            >
                <Input disabled={accessControl.isKycKybApprovalEnabled()} />
            </Form.Item>
            <Form.Item
                name="lastName"
                label={t('personalFields.lastName.label')}
                rules={[
                    ...commonValidation({ fieldName: 'lastName', t, isRequired: true, maxLength: 128 }),
                    ...isValidLastNameRule(t),
                ]}
            >
                <Input disabled={accessControl.isKycKybApprovalEnabled()} />
            </Form.Item>

            <EmailInput
                name={'businessUserEmail'}
                placeholder={t('personalFields.email.placeholder')}
                label={t('personalFields.email.label')}
                t={t}
            />

            <PhoneInput
                t={t}
                name="businessUserPhoneNumber"
            />
        </Form>
    );
}

EditBusinessUserModal.propTypes = {
    accessControl: AccessControlProps,
    businessUserId: PropTypes.number.isRequired,
    dispatchSetModalProps: PropTypes.func.isRequired,
    dispatchUpdateBusinessUser: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    listOfOriginLocationPathname: PropTypes.string.isRequired,
    initialValues: BusinessUserProps,
};

export default EditBusinessUserModal;

