import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ListWithStatusTimeline from './ListWithStatusTimeline.layout';
import { ListWithStatusTimelineDispatchProps, ListWithStatusTimelineOwnProps, ListWithStatusTimelineStateProps } from './ListWithStatusTimeline.types';


const mapStateToProps = (state: RootState, ownProps: ListWithStatusTimelineOwnProps): ListWithStatusTimelineStateProps => {
    const { reducerName, listsByLocationStoreKeyName } = ownProps;
    return {
        enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
        listData: getStandardListData({
            state,
            reducerName,
            listsByLocationStoreKeyName,
        }),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ListWithStatusTimelineOwnProps): ListWithStatusTimelineDispatchProps => {
    const { reducerName, listsByLocationStoreKeyName, fetcherListAction, clearListAction } = ownProps;
    return {
        dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
            reducerName,
            fieldName: listsByLocationStoreKeyName,
            locationPathname,
            queryParams,
        })),
        dispatchFetcherListAction: (queryParams, locationPathname) => dispatch(fetcherListAction(queryParams, locationPathname)),
        dispatchClearListAction: (locationPathname) => dispatch(clearListAction(locationPathname)),
    };
};

const decorate = compose<ComponentType<ListWithStatusTimelineOwnProps>>(
    withTranslation(),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ListWithStatusTimeline);
