import withAccessControl from 'hocs/withAccessControl';
import { fetchGlobalData } from 'redux/application/actions';
import { clientsListsPerLocationStoreKeyName, clientsReducerName } from 'redux/clients/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'redux/countries/reducer';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'redux/currencies/reducer';
import { industriesListsPerLocationStoreKeyName, industriesReducerName } from 'redux/industries/reducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import AppLoadingPlaceholder from './AppLoadingPlaceholder.layout';


const mapStateToProps = (state) => ({
    currenciesList: getStandardListData({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
    countriesList: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
    industriesList: getStandardListData({
        state,
        reducerName: industriesReducerName,
        listsByLocationStoreKeyName: industriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
    isLoadingTransactionsFilters: state.transactions.isLoadingTransactionsFilters,
    clientsList: getStandardListData({
        state,
        reducerName: clientsReducerName,
        listsByLocationStoreKeyName: clientsListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
});

const mapDispatchToProps = (dispatch) => ({ dispatchReFetchGlobalData: () => dispatch(fetchGlobalData()) });

const decorate = compose(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);
export default decorate(AppLoadingPlaceholder);
