import styled, { css } from 'styled-components';


export const commonTextEllipsisStyles = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const CommonTextEllipsisWrapper = styled.div`
  max-width: 100%;
  ${commonTextEllipsisStyles};
`;
