import { globalLocation } from 'constants/NavigationModel';

import { createAction } from '@reduxjs/toolkit';

import { CLEAR_CLIENTS_LIST, FETCH_CLIENTS_LIST, FETCH_CLIENTS_LIST_FAILURE, FETCH_CLIENTS_LIST_SUCCESS } from './actions.types';


export const fetchClientsList = createAction(FETCH_CLIENTS_LIST, (queryParams, locationPathname = globalLocation) => ({ payload: { queryParams, locationPathname } }));
export const fetchClientsListSuccess = createAction(FETCH_CLIENTS_LIST_SUCCESS,
    (items, totalCount, locationPathname = globalLocation) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchClientsListFailure = createAction(FETCH_CLIENTS_LIST_FAILURE, (locationPathname = globalLocation) => ({ payload: { locationPathname } }));
export const clearClientsList = createAction(CLEAR_CLIENTS_LIST, (locationPathname = globalLocation) => ({ payload: { locationPathname } }));
