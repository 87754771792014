import { AccountOwnerTypes } from 'constants/AccountModel';
import withAccessControl from 'hocs/withAccessControl';
import { openAddAccountModal } from 'redux/accounts/actions';
import { setQueryParams } from 'redux/application/actions';
import { clearClientDetails, fetchClientAddresses, fetchClientDetails } from 'redux/client/actions';
import { clientAddressesPerLocationStoreKeyName, clientDetailsPerLocationStoreKeyName, clientReducerName } from 'redux/client/reducer';
import { openChargeCustomFeeModal } from 'redux/fees/actions';
import { fetchGroupsList } from 'redux/groups/actions';
import { openTriggerManualFeeModal } from 'redux/manual-fees/actions';
import { hideModal, setModalProps, showModal } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { getStandardEntityDetailsData } from 'redux/standardEntityDetails.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import ClientDetails from './ClientDetails.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state[navigationReducerName]?.enhancedCurrentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: clientReducerName,
        fieldName: clientDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
    addressData: getStandardEntityDetailsData({
        state,
        reducerName: clientReducerName,
        fieldName: clientAddressesPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchClientDetails: (queryParams, locationPathname) => dispatch(fetchClientDetails(queryParams, locationPathname)),
    dispatchClearClientDetails: (locationPathname) => dispatch(clearClientDetails(locationPathname)),
    dispatchFetchClientAddress: (queryParams, locationPathname) => dispatch(fetchClientAddresses(queryParams, locationPathname)),

    dispatchFetchClientGroups: (clientId) => dispatch(fetchGroupsList(clientId)),
    dispatchShowModal: (data) => dispatch(showModal(data)),
    dispatchSetModalProps: (data) => dispatch(setModalProps(data)),
    dispatchHideModal: () => dispatch(hideModal()),
    dispatchOpenAddAccountModal: (configData, locationPathname) => dispatch(openAddAccountModal(AccountOwnerTypes.CLIENT, configData, locationPathname)),
    dispatchOpenChargeCustomFeeModal: () => dispatch(openChargeCustomFeeModal()),
    dispatchOpenTriggerManualFeeModal: (feeUuid) => dispatch(openTriggerManualFeeModal(feeUuid)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: clientReducerName,
        fieldName: clientDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});


const decorate = compose(
    withTranslation('clients'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ClientDetails);
