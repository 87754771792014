import NewStar from 'components/common/atoms/NewStar';
import StandardTranslationWithMarkup from 'components/common/molecules/StandardTranslationWithMarkup';
import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';
import { WL_CONFIG } from 'config/config';
import { sortNumbers } from 'utils/table-columns-sorters';

import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Styled from './ChangeLog.styled';


const { Panel } = Collapse;

function ChangeLog({ t }) {
    const normaliseVersion = (str) => Number(str.replace(/\D/g, ''));

    const versions = useMemo(
        () => {
            const versionRawKeys = Object.keys(t('changeLog:versions', { returnObjects: true }) || {}) || [];
            return [...versionRawKeys].sort((a, b) => sortNumbers(normaliseVersion(a), normaliseVersion(b))).reverse();
        },
        [t],
    );

    const renderVersion = (version, index) => (
        <Panel
            header={(
                <Styled.HeaderWrapper>
                    {version}
                    {index === 0 && (
                        <Styled.NewIndicatorWrapper>
                            <NewStar />
                        </Styled.NewIndicatorWrapper>
                    )}
                </Styled.HeaderWrapper>

            )}
            key={index}
        >
            <Styled.ChangeLogEntryWrapper>
                <Styled.ChangeLogStatusInfoWrapper>
                    <LabelAndValueRow
                        label="Status:"
                        value={t(`versions.${version}.status`)}
                    />

                    <LabelAndValueRow
                        label="Required API version:"
                        value={t(`versions.${version}.requiredApiVersion`)}
                    />

                </Styled.ChangeLogStatusInfoWrapper>


                <StandardTranslationWithMarkup
                    translationKey={`versions.${version}.changes`}
                    namespace="changeLog"
                    values={{
                        brandFullName: WL_CONFIG.brandFullName,
                        brandShortName: WL_CONFIG.brandShortName,
                        applicationName: WL_CONFIG.applicationName,
                    }}
                    t={t}
                />

            </Styled.ChangeLogEntryWrapper>
        </Panel>
    );


    return (
        <div>
            <h2>{t('screenTitle')}</h2>
            <Collapse defaultActiveKey="0">
                {versions.map((version, index) => renderVersion(version, index))}
            </Collapse>
        </div>
    );
}

ChangeLog.propTypes = { t: PropTypes.func.isRequired };


export default ChangeLog;
