import withAccessControl from 'hocs/withAccessControl';
import { Dispatch } from 'models/meta/action';
import { setQueryParams } from 'redux/application/actions';
import { replayFailedWebhook } from 'redux/failed-webhook/actions';
import { failedWebhookReducerName } from 'redux/failed-webhook/reducer';
import { clearFailedWebhooksList, fetchFailedWebhooksList, replayAllFieldWebhooks } from 'redux/failed-webhooks/actions';
import { failedWebhooksListPerLocationStoreKeyName, failedWebhooksReducerName } from 'redux/failed-webhooks/reducer';
import { showViewJsonModal } from 'redux/modal/actions';
import { requestNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';
import { RootState } from 'redux/rootReducer';
import { getStandardListData } from 'redux/standardLists.helpers';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import FailedWebhooks from './FailedWebhooks.layout';
import { FailedWebhooksListDispatchProps, FailedWebhooksListStateProps } from './FailedWebhooks.types';


const mapStateToProps = (state: RootState): FailedWebhooksListStateProps => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
    listData: getStandardListData({
        state,
        reducerName: failedWebhooksReducerName,
        listsByLocationStoreKeyName: failedWebhooksListPerLocationStoreKeyName,
    }),

    isLoadingReplayFailedWebhook: state[failedWebhookReducerName].isLoadingReplayFailedWebhook,
    isLoadingReplayAllFailedWebhooks: state[failedWebhooksReducerName].isLoadingReplayAllFailedWebhooks,
});
const mapDispatchToProps = (dispatch: Dispatch): FailedWebhooksListDispatchProps => ({
    dispatchFetchFailedWebhooksList: (queryParams, locationPathname) => dispatch(fetchFailedWebhooksList(queryParams, locationPathname)),
    dispatchClearFailedWebhooksList: (locationPathname) => dispatch(clearFailedWebhooksList(locationPathname)),
    dispatchReplayFailedWebhook: (queryParams, locationPathname) => dispatch(replayFailedWebhook(queryParams, locationPathname)),
    dispatchReplayAllFieldWebhooks: (locationPathname) => dispatch(replayAllFieldWebhooks(locationPathname)),
    dispatchSetQueryParams: (queryParams, locationPathname) => dispatch(setQueryParams({
        reducerName: failedWebhooksReducerName,
        fieldName: failedWebhooksListPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
    dispatchShowViewJsonModal: (dataToDisplay, modalTitle) => dispatch(showViewJsonModal(dataToDisplay, modalTitle)),
    dispatchRequestNavigation: (locationPathname, meta) => dispatch(requestNavigation(locationPathname, meta)),
});

const decorate = compose(
    withTranslation('webhooks'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(FailedWebhooks);
