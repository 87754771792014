import StandardTag from 'components/common/atoms/StandardTag';
import { CompanyStatuses, CompanyStatusProp } from 'constants/CompaniesModel';

import PropTypes from 'prop-types';
import React from 'react';


function CompanyStatusTag({ status, showTooltip, t }) {
    const handlers = [
        {
            predicate: () => CompanyStatuses.PENDING_CLOSURE === status,
            handler: () => ({
                color: 'orange',
                text: t('companies:companyStatuses.pendingClosure'),
            }),
        },
        {
            predicate: () => CompanyStatuses.INVITED === status,
            handler: () => ({
                color: 'orange',
                text: t('companies:companyStatuses.invited'),
            }),
        },
        {
            predicate: () => CompanyStatuses.UNVERIFIED === status,
            handler: () => ({
                color: 'orange',
                text: t('companies:companyStatuses.unverified'),
            }),
        },
        {
            predicate: () => CompanyStatuses.KYB_PENDING === status,
            handler: () => ({
                color: 'orange',
                text: t('companies:companyStatuses.kybPending'),
            }),
        },
        {
            predicate: () => CompanyStatuses.KYB_REJECTED === status,
            handler: () => ({
                color: 'orange',
                text: t('companies:companyStatuses.kybRejected'),
            }),
        },
        {
            predicate: () => CompanyStatuses.KYB_UPLOADED === status,
            handler: () => ({
                color: 'orange',
                text: t('companies:companyStatuses.kybUploaded'),
            }),
        },
        {
            predicate: () => CompanyStatuses.KYB_MANUAL_REVIEW === status,
            handler: () => ({
                color: 'orange',
                text: t('companies:companyStatuses.kybManualReview'),
            }),
        },

        {
            predicate: () => CompanyStatuses.DORMANT === status,
            handler: () => ({
                color: 'green',
                text: t('companies:companyStatuses.dormant'),
            }),
        },
        {
            predicate: () => CompanyStatuses.SUSPENDED === status,
            handler: () => ({
                color: 'orange',
                text: t('companies:companyStatuses.suspended'),
            }),
        },
        {
            predicate: () => CompanyStatuses.CLOSED === status,
            handler: () => ({
                color: 'red',
                text: t('companies:companyStatuses.closed'),
            }),
        },
        {
            predicate: () => CompanyStatuses.BANNED === status,
            handler: () => ({
                color: 'red',
                text: t('companies:companyStatuses.banned'),
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                color: 'green',
                text: t('companies:companyStatuses.active'),
            }),
        },
    ];

    const tagData = handlers.filter(({ predicate }) => predicate())[0].handler();


    return (
        <StandardTag
            color={tagData.color}
            text={tagData.text}
            showTooltip={showTooltip}
        />
    );
}

CompanyStatusTag.propTypes = {
    status: CompanyStatusProp,
    showTooltip: PropTypes.bool,
};

export default CompanyStatusTag;
