import { EyeFilled } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import React from 'react';


const viewButtonRenderer = ({
    record,
    onClick,
    tooltipContent,
    icon = <EyeFilled />,
    dataTestId = 'view-details',
    dataTestIdPrefix,
    dataTestIdSuffix,
    isLoading,
    isDisabled,
}: {
  record: any,
  onClick: (event: any, record: any) => void,
  tooltipContent?: string | React.ReactNode,
  dataTestId?: string,
  icon?: any,
  isDisabled?: boolean,
  isLoading?: boolean,
  dataTestIdPrefix?: string,
  dataTestIdSuffix?: string,
}) => (
    <div>
        <Tooltip title={tooltipContent}>
            <Button
                htmlType="button"
                shape="circle"
                loading={isLoading}
                icon={icon}
                disabled={isDisabled}
                data-test-id={`${dataTestIdPrefix ? `${dataTestIdPrefix}-` : ''}${dataTestId}${dataTestIdSuffix ? `-${dataTestIdSuffix}` : ''}-${record?.id}`}
                onClick={(event) => onClick(event, record)}
            />
        </Tooltip>
    </div>
);


export default viewButtonRenderer;

