import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import PrivacyPolicy from './PrivacyPolicy.layout';


const decorate = compose(
    withTranslation('privacyPolicy'),
);

export default decorate(PrivacyPolicy);
