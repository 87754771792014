import styled from 'styled-components';


const ErrorMessage = styled.p`
  color: #ff0000;
`;

const ErrorWrapper = styled.div`
  text-align: center;
`;


export default {
    ErrorWrapper,
    ErrorMessage,
};
