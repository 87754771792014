/* eslint-disable max-len */
import { WL_CONFIG } from 'config/config';
import { AccessControlProps } from 'constants/AccessControlModel';
import ListProps from 'constants/ListModel';

import PropTypes from 'prop-types';
import React from 'react';


import { renderLoadingDetails, renderRestartApplication } from './AppLoadingPlaceholder.helpers';
import Styled from './AppLoadingPlaceholder.styled';


function AppLoadingPlaceholder({
    t,
    accessControl,

    currenciesList,
    countriesList,
    industriesList,
    clientsList,
    isLoadingTransactionsFilters,
    isRefreshingSession,
    shouldFetchGlobalData,

    asOverlay = false,

    dispatchReFetchGlobalData,
}) {
    return (
        <Styled.AppLoadingPlaceholder $asOverlay={asOverlay} data-test-id="app-loading-placeholder">
            <Styled.BrandAnimatedLoadingPlaceholder>
                <img
                    src={
                        WL_CONFIG.brandAnimatedLoadingPlaceholderUrl
            || '/default-animated-loading-placeholder.svg'
                    }
                    alt="animated placeholder"
                />
            </Styled.BrandAnimatedLoadingPlaceholder>

            {!shouldFetchGlobalData && renderLoadingDetails({
                t,
                accessControl,

                isCurrenciesLoaded: !currenciesList?.isLoadingList,
                isCountriesLoaded: !countriesList?.isLoadingList,
                isIndustriesLoaded: !industriesList?.isLoadingList,
                isTransactionsFiltersLoaded: !isLoadingTransactionsFilters,
                isClientsListLoaded: !clientsList?.isLoadingList,

                isRefreshingSession,
            })}

            {renderRestartApplication({
                t,
                shouldFetchGlobalData,
                dispatchReFetchGlobalData,
            })}
        </Styled.AppLoadingPlaceholder>
    );
}

AppLoadingPlaceholder.propTypes = {
    t: PropTypes.func.isRequired,
    accessControl: AccessControlProps,

    // TODO: createListProps will be used instead of ListProps
    currenciesList: ListProps,
    countriesList: ListProps,
    industriesList: ListProps,
    clientsList: ListProps,

    isRefreshingSession: PropTypes.bool,
    isLoadingTransactionsFilters: PropTypes.bool,
    shouldFetchGlobalData: PropTypes.bool,

    asOverlay: PropTypes.bool,
    dispatchReFetchGlobalData: PropTypes.func.isRequired,
};

export default AppLoadingPlaceholder;
