import LoadingStatus from 'constants/LoadingStatus';
import {
    fetchCardSecure3dData, fetchCardSecure3dDataSuccess, fetchCardSecure3dDataFailure,
    fetchCardSecure3dActivityLogs, fetchCardSecure3dActivityLogsSuccess, fetchCardSecure3dActivityLogsFailure,
    updateCardSecure3dSettingsSuccess, updateCardSecure3dSettingsFailure, updateCardSecure3dSettings,
    submitCardSecure3dPhoneNumberModal,
    clearCardSecure3d,
} from 'redux/card-secure3d/actions';
import { clearCurrentUser } from 'redux/current-user/actions';

import { createReducer } from '@reduxjs/toolkit';


export const card3dSecureReducerName = 'card3dSecure';

const initialState = {
    data: null,
    logs: null,

    isLoadingData: LoadingStatus.IDLE,
    isLoadingActivityLogs: LoadingStatus.IDLE,
    isBusyUpdatingSettings: LoadingStatus.IDLE,
};


const handlers = {
    [fetchCardSecure3dData]: (state) => {
        state.isLoadingData = LoadingStatus.PENDING;
    },
    [fetchCardSecure3dDataSuccess]: (state, action) => {
        state.isLoadingData = LoadingStatus.IDLE;
        state.data = action.payload;
    },
    [fetchCardSecure3dDataFailure]: (state) => {
        state.isLoadingData = LoadingStatus.IDLE;
    },


    [fetchCardSecure3dActivityLogs]: (state) => {
        state.isLoadingActivityLogs = LoadingStatus.PENDING;
    },
    [fetchCardSecure3dActivityLogsSuccess]: (state, action) => {
        state.isLoadingActivityLogs = LoadingStatus.IDLE;
        state.logs = action.payload;
    },
    [fetchCardSecure3dActivityLogsFailure]: (state) => {
        state.isLoadingActivityLogs = LoadingStatus.IDLE;
    },

    [updateCardSecure3dSettings]: (state) => {
        state.isBusyUpdatingSettings = LoadingStatus.PENDING;
    },
    [updateCardSecure3dSettingsSuccess]: (state, action) => {
        state.data = {
            ...state.data,
            ...action.payload,
        };
        state.isBusyUpdatingSettings = LoadingStatus.IDLE;
    },
    [updateCardSecure3dSettingsFailure]: (state) => {
        state.isBusyUpdatingSettings = LoadingStatus.IDLE;
    },

    [submitCardSecure3dPhoneNumberModal]: (state) => {
        state.isBusyUpdatingSettings = LoadingStatus.PENDING;
    },

    [clearCardSecure3d]: () => initialState,
    [clearCurrentUser]: () => initialState,
};

export default createReducer(initialState, handlers);
