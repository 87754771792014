import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    fetchFailedWebhookErrorsListFailure,
    fetchFailedWebhookErrorsListSuccess,
} from './actions';
import { FETCH_FAILED_WEBHOOK_ERRORS_LIST } from './actions.types';


export const onFetchFailedWebhookErrorsList = (action$, _, { webhooks }) => action$.pipe(
    ofType(FETCH_FAILED_WEBHOOK_ERRORS_LIST),
    switchMap(({ payload }) => from(webhooks.fetchFailedWebhookErrorsHistory(payload?.queryParams)).pipe(
        switchMap((response) => of(fetchFailedWebhookErrorsListSuccess(
            response?.data,
            response?.data?.length,
            payload.locationPathname,
        ))),
        catchError(() => of(fetchFailedWebhookErrorsListFailure(payload.locationPathname))),
    )),
);


export default [
    onFetchFailedWebhookErrorsList,
];
