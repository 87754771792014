import CountryOrCurrencyFlag from 'components/common/atoms/CountryOrCurrencyFlag';
import StandardTooltip from 'components/common/molecules/StandardTooltip';
import BpsProductsSelect from 'components/common/molecules/form-controls/BpsProductsSelect';
import { createBpsProductsOptions } from 'components/common/molecules/form-controls/BpsProductsSelect/BpsProductsSelect.helpers';
import { DefaultRequiredCurrency, NewAccountTypes } from 'constants/AccountModel';
import { CurrenciesListProp } from 'constants/CurrencyModel';

import { CloseSquareOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Styled from './ProductItemTile.styled';


function ProductItemTile({
    field,
    index,
    parentListFieldName,
    bpsProductsList,
    currencies,
    sectionAccountType,
    isLoadingBpsProductsList,
    onRemove,
    t,
}) {
    const formInstance = Form.useFormInstance();
    const fieldCurrency = formInstance.getFieldValue([parentListFieldName, index, 'currency']);
    const fieldAccountType = formInstance.getFieldValue([parentListFieldName, index, 'account_type']);

    const currencyInfo = currencies.find((currency) => fieldCurrency === currency.short);

    const isRemovable = useMemo(
        () => !(fieldAccountType === NewAccountTypes.BASE && fieldCurrency === DefaultRequiredCurrency),
        [fieldCurrency, fieldAccountType],
    );
    const availableBpsProducts = useMemo(
        () => createBpsProductsOptions(bpsProductsList.filter((bpsProduct) => bpsProduct.currency === fieldCurrency && bpsProduct.account_type === fieldAccountType), t),
        [currencies, bpsProductsList],
    );


    return sectionAccountType === fieldAccountType ? (
        <Styled.ProductItemTileWrapper>
            <Styled.ProductItemTileDecorWrapper>
                <CountryOrCurrencyFlag currencyCode={fieldCurrency} />
            </Styled.ProductItemTileDecorWrapper>

            {isRemovable && (
                <Styled.RemoveProductWrapper>
                    <StandardTooltip title={'Remove Product'}>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button type="button" onClick={() => onRemove(field.name)}>
                            <CloseSquareOutlined />
                        </button>
                    </StandardTooltip>
                </Styled.RemoveProductWrapper>
            )}

            <Styled.ProductItemContentWrapper>
                <div>
                    <Styled.CurrencySymbol>
                        {currencyInfo?.symbol || currencyInfo?.short}
                    </Styled.CurrencySymbol>
                    <Styled.CurrencyName>
                        {currencyInfo?.full_name}
                    </Styled.CurrencyName>
                </div>

                <Styled.HiddenFieldsWrapper>
                    <Form.Item name={[index, 'account_type']}><Input type="hidden" /></Form.Item>
                    <Form.Item name={[index, 'currency']}><Input type="hidden" /></Form.Item>
                </Styled.HiddenFieldsWrapper>


                <Form.Item
                    name={[index, 'bps_product_id']}
                    label={t('Assign BPS product')}
                    style={{ margin: 0 }}
                >
                    <BpsProductsSelect
                        loading={isLoadingBpsProductsList}
                        availableBpsProducts={availableBpsProducts}
                    />
                </Form.Item>

            </Styled.ProductItemContentWrapper>
        </Styled.ProductItemTileWrapper>
    ) : null;
}

ProductItemTile.propTypes = {
    bpsProductsList: PropTypes.arrayOf(PropTypes.shape({})),
    currencies: CurrenciesListProp,
    t: PropTypes.func,
    sectionAccountType: PropTypes.string.isRequired,
};

export default ProductItemTile;
