import svgIllustrations from 'assets/images/illustrations/svgIllustrations';

import { Button, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


function SuccessStep({
    t,
    dispatchRequestNavigationBack,
}) {
    return (
        <>
            <Row justify="center">
                <Col style={{ paddingBottom: 20 }}>
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={svgIllustrations.successWoman}
                            alt="Decoration illustration picturing success woman"
                            width="100%"
                            style={{ maxWidth: '250px' }}
                        />
                    </div>

                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontSize: 20 }}>
                            {' '}
              Cards successfully ordered!
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 20 }} justify="center">
                <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => dispatchRequestNavigationBack()}
                >
                    {t('common:buttons.finish.text')}
                </Button>
            </Row>
        </>
    );
}

SuccessStep.propTypes = {
    t: PropTypes.func.isRequired,
    dispatchRequestNavigationBack: PropTypes.func.isRequired,
};


export default SuccessStep;
