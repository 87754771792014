import { areDevToolsEnabled, isReduxLoggerEnabled } from 'config/config';
import rootEpics from 'redux/rootEpics';
import rootReducer from 'redux/rootReducer';

import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import logger from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { isObservable } from 'rxjs';


const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state) => {
        if (state?.currentUser?.userData) {
            Sentry.setUser({
                sessionUuid: state.application.state,
                id: state.currentUser.userData.id,
                email: state.currentUser.userData.email,
                username: state.currentUser.userData.username,
                clientId: state.currentUser.userData.clientId,
                roleId: state.currentUser.userData.roleId,
            });
        }
    },
});


export function createStore(dependencies) {
    const epicMiddleware = createEpicMiddleware({ dependencies });

    const middleware = [
        ...(isReduxLoggerEnabled ? [logger] : []),
        epicMiddleware,
    ];

    const storeInstance = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }).concat(middleware),
        devTools: areDevToolsEnabled,
        enhancers: [sentryReduxEnhancer],
    });


    const services = [...Object.values(dependencies)];
    // XXX see `get action$()` in Location Service!
    services
        .map((item) => item.action$)
        .filter(isObservable)
        .forEach((action$) => action$.subscribe(storeInstance.dispatch));

    epicMiddleware.run(rootEpics);

    return storeInstance;
}


export default createStore;
