import { AddClientStepsDefinitions } from 'constants/AddClientModel';
import { CommonFormFieldTypes, MultiStepFormStepSectionFieldsLayout, MultiStepFormStepSectionTypes } from 'constants/MultiStepsFormModel';
import { commonValidation, isDifferentThanPreFilledValue, requiredRule } from 'utils/validation-tools';

import { TagOutlined } from '@ant-design/icons';
import React from 'react';


function createRoleBasicDataFromFieldsConfig({ t, selectedRoleTemplate }) {
    return [
        {
            name: 'name',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Role name'),
            placeholder: 'Enter role name',
            validationRules: [
                ...commonValidation({
                    t,
                    isRequired: true,
                }),
                ...isDifferentThanPreFilledValue({
                    t,
                    prefilledValue: selectedRoleTemplate?.details?.name,
                }),

            ],
        },
        {
            name: 'display_name',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Role display name'),
            placeholder: 'Enter role display name',
            validationRules: commonValidation({
                t,
                isRequired: true,
            }),
        },
        {
            name: 'description',
            fieldType: CommonFormFieldTypes.TEXT,
            label: t('Description'),
            placeholder: 'Enter roles ',
            validationRules: commonValidation({
                t,
                isRequired: true,
            }),
        },
        {
            name: 'client_id',
            fieldType: CommonFormFieldTypes.SELECT_CLIENT,
            label: t('Client'),
            validationRules: requiredRule(t),
        },
    ];
}

function createRoleFlagDataFromFieldsConfig({ t }) {
    return [
        {
            name: 'all',
            fieldType: CommonFormFieldTypes.BOOLEAN,
            label: t('All?'),
        },
        {
            name: 'client',
            fieldType: CommonFormFieldTypes.BOOLEAN,
            label: t('Client'),
        },
        {
            name: 'company',
            fieldType: CommonFormFieldTypes.BOOLEAN,
            label: t('Company'),
        },
        {
            name: 'own',
            fieldType: CommonFormFieldTypes.BOOLEAN,
            label: t('Own'),
        },
        {
            name: 'reserved',
            fieldType: CommonFormFieldTypes.BOOLEAN,
            label: t('is Reserved?'),
        },
    ];
}

export function createRoleDetailsStepConfig({ t, selectedRoleTemplate }) {
    return {
        stepName: AddClientStepsDefinitions.PARTNER_DETAILS,
        title: t('Labels & Scope'),
        icon: <TagOutlined />,
        sectionsLayout: MultiStepFormStepSectionTypes.EVEN_COLUMNS,
        stepSections: [
            {
                fieldsLayout: MultiStepFormStepSectionFieldsLayout.VERTICAL,
                title: 'Labels',
                fieldsConfiguration: createRoleBasicDataFromFieldsConfig({ t, selectedRoleTemplate }),
            },
            {
                fieldsLayout: MultiStepFormStepSectionFieldsLayout.VERTICAL,
                title: 'Scope',
                fieldsConfiguration: createRoleFlagDataFromFieldsConfig({ t }),
            },
        ],
    };
}


export default { createRoleDetailsStepConfig };
