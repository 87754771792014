import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_CLIENT_MIGRATIONS_LIST,
    DOWNLOAD_MIGRATION_REPORT,
    DOWNLOAD_MIGRATION_REPORT_FAILURE,
    DOWNLOAD_MIGRATION_REPORT_SUCCESS, DOWNLOAD_MIGRATION_RESULT, DOWNLOAD_MIGRATION_RESULT_FAILURE, DOWNLOAD_MIGRATION_RESULT_SUCCESS,
    FETCH_CLIENT_MIGRATIONS_LIST, FETCH_CLIENT_MIGRATIONS_LIST_FAILURE, FETCH_CLIENT_MIGRATIONS_LIST_SUCCESS,
    UPLOAD_CLIENT_MIGRATION_FILE, UPLOAD_CLIENT_MIGRATION_FILE_FAILURE, UPLOAD_CLIENT_MIGRATION_FILE_SUCCESS,
} from './actions.types';


export const fetchClientMigrationsList = createAction(FETCH_CLIENT_MIGRATIONS_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchClientMigrationsListSuccess = createAction(FETCH_CLIENT_MIGRATIONS_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchClientMigrationsListFailure = createAction(FETCH_CLIENT_MIGRATIONS_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearClientMigrationsList = createAction(CLEAR_CLIENT_MIGRATIONS_LIST, (locationPathname) => ({ payload: { locationPathname } }));


export const downloadMigrationResult = createAction(DOWNLOAD_MIGRATION_RESULT, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const downloadMigrationResultSuccess = createAction(DOWNLOAD_MIGRATION_RESULT_SUCCESS,
    (responseData, locationPathname) => ({ payload: { responseData, locationPathname } }));
export const downloadMigrationResultFailure = createAction(DOWNLOAD_MIGRATION_RESULT_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const downloadMigrationReport = createAction(DOWNLOAD_MIGRATION_REPORT, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const downloadMigrationReportSuccess = createAction(DOWNLOAD_MIGRATION_REPORT_SUCCESS,
    (responseData, locationPathname) => ({ payload: { responseData, locationPathname } }));
export const downloadMigrationReportFailure = createAction(DOWNLOAD_MIGRATION_REPORT_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));


export const uploadClientMigrationFile = createAction(UPLOAD_CLIENT_MIGRATION_FILE, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const uploadClientMigrationFileSuccess = createAction(UPLOAD_CLIENT_MIGRATION_FILE_SUCCESS,
    (responseData, locationPathname) => ({ payload: { responseData, locationPathname } }));
export const uploadClientMigrationFileFailure = createAction(UPLOAD_CLIENT_MIGRATION_FILE_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
