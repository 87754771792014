import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';
import { WL_CONFIG } from 'config/config';

import styled, { css } from 'styled-components';


const StandardValueWrapper = styled.div<{ $enableTextEllipsis?: boolean; $isAmount?: boolean }>`
  max-width: 100%;
  display: inline-grid;
  
  ${({ $enableTextEllipsis }) => $enableTextEllipsis && css`
    & > * {
      ${commonTextEllipsisStyles};
    }
  `};

  ${({ $isAmount }) => $isAmount && css`
    ${WL_CONFIG.themeVariables?.monoFontFamily};
  `};
`;


export default { StandardValueWrapper };
