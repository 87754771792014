import { Table } from 'antd';
import styled from 'styled-components';


const Heading = styled.div`
  margin-bottom: 10px;
  color: #CACACA;
  text-transform: uppercase;
  padding-left: 12px;
`;

const ReviewTable = styled(Table)`
  height: fit-content !important;
  width: 100%;
  
  .ant-table-container {
    min-height: fit-content !important;

    .ant-table-content {
      position: static;
    }
    
    .ant-table-wrapper {
      height: fit-content;
    }
  }
`;


export default { Heading, ReviewTable };
