import StandardListTable from 'components/common/organisms/StandardListTable';
import { EntityTypeProps } from 'constants/EntityDetailsModel';
import { createListProps } from 'constants/ListModel';
import { EnhancedCurrentLocationProps } from 'constants/NavigationModel';
import RiskAssessmentModel from 'constants/RiskAssessmentModel';
import useStandardListCachingLogic from 'hooks/useStandardListCachingLogic';
import { defaultRiskAssessmentsListQueryParams } from 'redux/risk-assessments/reducer';

import PropTypes from 'prop-types';
import React from 'react';

import createTableColumns from './createTableColumns';


function RiskAssessmentList({
    // Own props
    contextEnforcedQueryParams,
    entityType,

    // HOC props
    t,

    // State props
    enhancedCurrentLocation,
    listData,

    // Dispatch props
    dispatchFetchRiskAssessments,
    dispatchClearRiskAssessmentsList,
    dispatchSetQueryParams,
}) {
    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams(prams, enhancedCurrentLocation?.pathname);
            dispatchFetchRiskAssessments(prams, enhancedCurrentLocation?.pathname);
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultRiskAssessmentsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchRiskAssessments,
        dispatchClearItemsList: dispatchClearRiskAssessmentsList,
        dispatchSetQueryParams,
    });

    return (
        <StandardListTable
            onUpdate={fetchData}
            rowKey="id"
            items={listData?.items}
            totalItemsCount={listData?.totalCount}
            queryParams={listData?.queryParams}
            queryParamsMeta={listData?.queryParamsMeta}
            isTableLoading={listData?.isLoadingList}
            isLoadingPlaceholder={!isListInitialStateDefined}
            columnsConfig={createTableColumns({ t, queryParams: listData?.queryParams, entityType })}
        />
    );
}

export default RiskAssessmentList;

RiskAssessmentList.propTypes = {
    contextEnforcedQueryParams: PropTypes.shape({
        ext_member_id: PropTypes.string,
        ext_client_id: PropTypes.string,
        ext_business_id: PropTypes.string,
        ext_company_id: PropTypes.string,
    }).isRequired,
    entityType: EntityTypeProps,

    t: PropTypes.func.isRequired,

    enhancedCurrentLocation: EnhancedCurrentLocationProps,
    listData: createListProps(PropTypes.arrayOf(PropTypes.shape(RiskAssessmentModel))),

    dispatchFetchRiskAssessments: PropTypes.func.isRequired,
    dispatchClearRiskAssessmentsList: PropTypes.func.isRequired,
    dispatchSetQueryParams: PropTypes.func.isRequired,
};
