import { isNil } from 'lodash';

import { flattenObject, isDeepEqual } from './object-tools';


const removeEmptyFormValues = (obj) => Object.keys(obj).reduce((acc, objectKey) => {
    const rawValue = obj[objectKey];
    const isString = typeof rawValue === 'string';
    const value = isString ? rawValue.trim() : rawValue;

    if ((isString && value?.length > 0 && !isNil(value))
      || (!isString && !isNil(value))
    ) {
        return {
            ...acc,
            [objectKey]: value,
        };
    }

    return acc;
}, {});


export function isFormPristine(rawFormDataObject, rawInitialFormData = undefined) {
    const initialFormData = flattenObject(rawInitialFormData);
    const formDataObject = flattenObject(rawFormDataObject);

    if (initialFormData) {
    // compare data, if it is the same then it is considered pristine, even if was touched
        return isDeepEqual(formDataObject, initialFormData);
    }

    const nonEmptyFormFields = removeEmptyFormValues(formDataObject);
    return Object.keys(nonEmptyFormFields)?.length === 0;
}


export default { isFormPristine };
