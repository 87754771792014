import { cardsRootRoutePath } from 'config/routes';
import { showSuccessToast } from 'redux/application/actions';
import {
    CardOrderSteps,
    createAmendedDeliveryAddress,
    createOnGetTargetListActions,
    createOnGoToPreviousOrderStepActions,
    createOnSubmitOrderStepActions,
} from 'redux/card-order/helpers';
import { fetchAllCards } from 'redux/cards/actions';
import { cardsListsPerLocationStoreKeyName, cardsReducerName } from 'redux/cards/reducer';
import { unblockNavigation } from 'redux/navigation/actions';
import { navigationReducerName } from 'redux/navigation/reducer';

import { ofType } from 'redux-observable';
import { from, of, asyncScheduler, EMPTY } from 'rxjs';
import { catchError, switchMap, throttleTime } from 'rxjs/operators';

import {
    orderCardsSuccess,
    orderCardsFailure,
    fetchCardMatrixSuccess, fetchCardMatrixFailure,
    fetchDeliveryAddressFailure,
    processCardsOrderSuccess, processCardsOrderFailure,
    setOrderStep, fetchCardMatrix, clearMembers, clearCompanies,
} from './actions';
import { ORDER_CARDS, ORDER_CARDS_SUCCESS } from './actions.types';


export const onStartCardsOrdering = (action$) => action$.pipe(
    ofType('card-order/startCardsOrdering'),
    switchMap(({ payload }) => {
        if (payload?.clientId) {
            return of(fetchCardMatrix(payload?.clientId));
        }
        return EMPTY;
    }),
);

export const onSetData = (action$, state$) => action$.pipe(
    ofType('card-order/setData'),
    switchMap(({ payload }) => {
        if (payload.client_id && payload.client_id !== state$.value.cardOrder.data.client_id) {
            return of(
                clearMembers(),
                clearCompanies(),
            );
        }

        return EMPTY;
    }),
);

export const onFetchCardMatrix = (action$, _, { clients }) => action$.pipe(
    ofType('card-order/fetchCardMatrix'),
    switchMap(({ payload }) => from(clients.getClientCardMatrixes(payload)).pipe(
        switchMap((response) => of(fetchCardMatrixSuccess(response.data))),
        catchError(() => of(fetchCardMatrixFailure())),
    )),
);

export const onGetTargetList = (action$, state$, { members, companies }) => action$.pipe(
    ofType('card-order/getTargetList'),
    throttleTime(500, asyncScheduler, { trailing: true, leading: false }),
    switchMap(({ payload: { targetType, id, search } }) => createOnGetTargetListActions({
        targetType,
        id,
        search,
        members,
        companies,
    })),
);

export const onFetchDeliveryAddress = (action$, state$, { addresses }) => action$.pipe(
    ofType('card-order/fetchDeliveryAddress'),
    switchMap(({ payload }) => from(addresses.getTargetDeliveryAddress(payload.targetType, payload.id)).pipe(
        switchMap((response) => createAmendedDeliveryAddress({
            entityType: payload.targetType,
            entityId: payload.id,
            rawDeliveryAddress: response.data,
            state$,
        })),
        catchError(() => of(fetchDeliveryAddressFailure())),
    )),
);

export const onProcessCardsOrder = (action$, _, { cards }) => action$.pipe(
    ofType('card-order/processCardsOrder'),
    switchMap(({ payload }) => from(cards.processCardsOrder(payload)).pipe(
        switchMap((response) => of(
            processCardsOrderSuccess(response.data),
            setOrderStep(CardOrderSteps.REVIEW),
        )),
        catchError(() => of(processCardsOrderFailure())),
    )),
);

export const onOrderCards = (action$, state$, { cards }) => action$.pipe(
    ofType(ORDER_CARDS),
    switchMap(({ payload }) => from(cards.bulkCardOrder(payload)).pipe(
        switchMap((response) => of(orderCardsSuccess(response.data))),
        catchError(() => of(orderCardsFailure())),
    )),
);

export const onOrderCardsSuccess = (action$, state$, { i18n }) => action$.pipe(
    ofType(ORDER_CARDS_SUCCESS),
    switchMap(() => {
        const prevPathname = state$.value[navigationReducerName]?.previousLocation?.pathname;
        const cardsQueryParams = prevPathname?.includes(cardsRootRoutePath) && state$.value[cardsReducerName][cardsListsPerLocationStoreKeyName]?.[prevPathname]?.queryParams;

        return of(
            setOrderStep(CardOrderSteps.SUCCESS),
            unblockNavigation(),
            showSuccessToast(i18n.t('cards:actionMessages.cardsOrderSuccess')),
            ...(cardsQueryParams ? [fetchAllCards(cardsQueryParams, prevPathname)] : []),
        );
    }),
);

export const onSubmitOrderStep = (action$, state$) => action$.pipe(
    ofType('card-order/submitOrderStep'),
    switchMap(({ payload }) => createOnSubmitOrderStepActions(payload, state$.value.cardOrder)),
);

export const onGoToPreviousOrderStep = (action$, state$) => action$.pipe(
    ofType('card-order/goToPreviousOrderStep'),
    switchMap(({ payload }) => createOnGoToPreviousOrderStepActions(payload, state$.value.cardOrder)),
);


export default [
    onStartCardsOrdering,
    onSetData,
    onGetTargetList,
    onFetchCardMatrix,
    onFetchDeliveryAddress,
    onProcessCardsOrder,
    onOrderCards,
    onOrderCardsSuccess,
    onSubmitOrderStep,
    onGoToPreviousOrderStep,
];
