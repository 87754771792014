import ClientInvitationEnabledTag from 'components/common/atoms/tags/ClientInvitationEnabledTag';
import ClientRiskTag from 'components/common/atoms/tags/ClientRiskTag';
import ClientStatusTag from 'components/common/atoms/tags/ClientStatusTag';
import CountriesOfOperations from 'components/common/molecules/value-renderers/CountriesOfOperations';
import IndustriesRenderer from 'components/common/molecules/value-renderers/IndustriesRenderer';
import StandardValue from 'components/common/molecules/value-renderers/StandardValue';
import { enumValueToReadableString } from 'utils/string-tools';

import React from 'react';


export const createFieldsConfig = ({
    client,
    countries,
    industries,
    t,
}) => {
    if (client?.id) {
        return [
            {
                label: t('clients:clientFields.companyRegistrationNumber.text'),
                value: <StandardValue value={client.company_registration_number} showCopyToClipboard />,
            },
            {
                label: t('clients:clientFields.companyLegalName.text'),
                value: <StandardValue value={client.company_legal_name} showCopyToClipboard />,
            },
            {
                label: t('clients:clientFields.companyDbaName.text'),
                value: <StandardValue value={client.company_dba_name} showCopyToClipboard />,
            },
            {
                label: t('clients:clientFields.businessType.text'),
                value: <StandardValue value={enumValueToReadableString(client.company_business_type)} showCopyToClipboard />,
            },
            {
                label: t('clients:clientFields.website.text'),
                value: client.website ? <a href={client.website} target="_blank" rel="noopener noreferrer">{client.website}</a> : undefined,
            },
            {
                label: t('clients:clientFields.operatingIndustries.text'),
                showEmpty: true,
                value: <IndustriesRenderer industries={industries} clientIndustries={client.industries} itemsToBeShownInline={3} />,
            },
            {
                label: t('clients:clientFields.countriesOfOperations.text'),
                value: <CountriesOfOperations countriesOfOperations={client.countries_of_operations} countries={countries} />,
            },
            {
                label: t('clients:clientFields.companyVatRegistrationNumber.text'),
                showEmpty: true,
                value: <StandardValue value={client.company_vat_registration_number} showCopyToClipboard />,
            },

        ];
    }
    return [];
};
export const createSystemFieldsConfig = ({ client, t }) => {
    if (client?.id) {
        return [
            {
                label: t('clients:clientFields.extClientUuid.text'),
                value: <StandardValue value={client.ext_client_id} showCopyToClipboard />,
            },
            {
                label: t('clients:clientFields.status.text'),
                value: <ClientStatusTag active={client?.active} />,
            },
            {
                label: t('clients:clientFields.riskScore.text'),
                value: (
                    <ClientRiskTag
                        score={client?.total_risk_score}
                        client={client}
                        showTooltip={false}
                        isTextEllipsisDisabled
                        t={t}
                    />
                ),
            },
            {
                label: t('clients:clientFields.iosAppVersion.text'),
                value: <StandardValue value={client?.configuration?.client?.ios_app_version} showCopyToClipboard />,
            },
            {
                label: t('clients:clientFields.androidAppVersion.text'),
                value: <StandardValue value={client?.configuration?.client?.android_app_version} showCopyToClipboard />,
            },
            {
                label: t('clients:clientFields.invitationEnabled.text'),
                value: <ClientInvitationEnabledTag invitationEnabled={client?.configuration?.client?.invitation_enabled} />,
            },

            ...(client?.configuration?.client?.parent_id > 0
                ? [
                    {
                        label: t('clients:clientFields.mobileParentClient.text'),
                        value: <StandardValue value={client?.configuration?.client?.parent_id} showCopyToClipboard />, // TODO use ClientRenderer
                    },
                    {
                        label: t('clients:clientFields.mobilePartnerCode.text'),
                        value: <StandardValue value={client?.partner_code} showCopyToClipboard />,
                    },
                ] : []),


            ...(client?.configuration?.general_settings?.parent_partner_id > 0
                ? [{
                    label: t('clients:clientFields.parentClient.text'),
                    value: <StandardValue value={client?.configuration?.general_settings?.parent_partner_id} showCopyToClipboard />, // TODO use ClientRenderer
                }]
                : []),


        ];
    }
    return [];
};

export default { createFieldsConfig, createSystemFieldsConfig };
