import { clientConfigKey, permissionsKey, rememberMeLoginForm, sessionUuidKey, userPreferencesKey } from 'config/config';
import { forgotPasswordRootRoutePath, loginRootRoutePath, passcodeConfirmRootRoutePath } from 'config/routes';
import { AuthorisationErrors } from 'constants/AuthorisationModel';
import { AuthErrorCodes } from 'models/api/auth';
import { setSessionUuid, showErrorToast, showSuccessToast } from 'redux/application/actions';
import {
    CONFIRM_LOGIN,
    GET_REMEMBERED_USERNAME_OR_EMAIL,
    GET_TOKENS_FOR_DUPLICATED_TAB,
    INITIALISE_LOGIN,
    REFRESH_TOKEN, REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_SUCCESS,
    SEND_CONFIRMATION_CODE, SET_NEW_PASSWORD, SET_NEW_PASSWORD_SUCCESS,
    UNLOCK_LOGIN,
} from 'redux/authorisation/actions.types';
import { createRefreshActions } from 'redux/authorisation/epics.helpers';
import { clearCurrentUser, setCurrentUser } from 'redux/current-user/actions';
import SET_CURRENT_USER_VARIANT from 'redux/current-user/epics.helpers';
import { requestNavigation } from 'redux/navigation/actions';
import {
    getLocalStorageObjectItem, setLocalStorageObjectItem,
    removeLocalStorageObjectItem, setSessionStorageObjectItem, getSessionStorageObjectItem,
} from 'utils/browser-storage';
import { isTokenNotExpired } from 'utils/jwtToken';
import { generateSessionUuid } from 'utils/session-uuid';

import { jwtDecode } from 'jwt-decode';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';


import {
    initialiseLoginSuccess,
    initialiseLoginFailure,
    confirmLoginSuccess,
    confirmLoginFailure,
    setNewPasswordSuccess,
    setNewPasswordFailure,
    requestPasswordResetSuccess,
    requestPasswordResetFailure,
    getRememberedUsernameOrEmailSuccess,
    getRememberedUsernameOrEmailFailure,
    unlockUserSuccess,
    unlockUserFailure,
    refreshTokenSuccess,
    refreshTokenFailure,
    clearAllPasscodeDigits,
    sendConfirmationCodeFailure, sendConfirmationCodeSuccess,
} from './actions';
import { authorisationReducerName } from './reducer';


const generateNewSessionUuid = (http) => {
    const newSessionUuid = generateSessionUuid();
    setSessionStorageObjectItem(sessionUuidKey, newSessionUuid);
    http.setSessionUuid(newSessionUuid);

    return setSessionUuid(newSessionUuid);
};

export const onInitialiseLogin = (action$, state$, { authorisation }) => action$.pipe(
    ofType(INITIALISE_LOGIN),
    tap(({ payload }) => {
        if (payload.remember) {
            setLocalStorageObjectItem(rememberMeLoginForm, payload.emailOrUsername);
        } else {
            removeLocalStorageObjectItem(rememberMeLoginForm);
        }
    }),
    switchMap(({ payload: { emailOrUsername, password } }) => from(authorisation.loginInit(emailOrUsername, password)).pipe(
        switchMap((response) => of(
            clearAllPasscodeDigits(),
            initialiseLoginSuccess(response.data),
            requestNavigation(passcodeConfirmRootRoutePath),
        )),
        catchError((error) => of(initialiseLoginFailure(error))),
    )),
);

export const onConfirmLogin = (action$, state$, { authorisation, i18n }) => action$.pipe(
    ofType(CONFIRM_LOGIN),
    switchMap(({ payload: { emailOrUsername, passcode } }) => {
        const { accessToken } = state$.value[authorisationReducerName];

        return from(authorisation.loginConfirm(passcode, emailOrUsername, accessToken)).pipe(
            switchMap((response) => of(
                confirmLoginSuccess(response.data),
                setCurrentUser(response.data, SET_CURRENT_USER_VARIANT.LOGIN),
                clearAllPasscodeDigits(),
            )),
            catchError((error) => {
                const shouldNavigateToLogin = error.message === AuthorisationErrors.TEMPORARILY_BLOCKED_FOR
            || error.message === AuthorisationErrors.TEMPORARILY_BLOCKED
            || error.message === AuthorisationErrors.CONFIRMATION_TOKEN_EXPIRED
            || error.message === AuthorisationErrors.PERMANENTLY_BLOCKED
            || error.messageCode === AuthErrorCodes.CONFIRMATION_TOKEN_EXPIRED;

                const shouldShowAlert = error.message === AuthorisationErrors.CONFIRMATION_TOKEN_EXPIRED
          || error.messageCode === AuthErrorCodes.CONFIRMATION_TOKEN_EXPIRED;
                return of(
                    confirmLoginFailure(error),
                    ...(shouldNavigateToLogin ? [requestNavigation(loginRootRoutePath)] : []),
                    ...(shouldShowAlert ? [showErrorToast(i18n.t('unauthorised:actionMessages.confirmLoginTokenHasExpired', undefined, { duration: 10 }))] : []),
                );
            }),
        );
    }),
);


export const onSetNewPassword = (action$, state$, { authorisation, i18n, http }) => action$.pipe(
    ofType(SET_NEW_PASSWORD),
    switchMap(({ payload }) => {
        const decodedToken = jwtDecode(payload.token);
        const tokenExpirationTime = decodedToken.exp * 1000; // XXX in milliseconds!

        if (isTokenNotExpired(tokenExpirationTime, 2)) {
            return from(authorisation.setNewPassword(payload)).pipe(
                switchMap((response) => of(setNewPasswordSuccess(response.data))),
                catchError((error) => of(
                    setNewPasswordFailure(),
                    showErrorToast(error.message),
                )),
            );
        }

        return of(
            generateNewSessionUuid(http),
            setNewPasswordFailure(),
            showErrorToast(i18n.t('unauthorised:actionMessages.setNewPasswordTokenHasExpired', undefined, { duration: 10 })),
            requestNavigation(forgotPasswordRootRoutePath, { replace: true }),
        );
    }),
);

export const onSetNewPasswordSuccess = (action$, _, { i18n, http }) => action$.pipe(
    ofType(SET_NEW_PASSWORD_SUCCESS),
    switchMap(() => of(
        generateNewSessionUuid(http),
        showSuccessToast(i18n.t('unauthorised:actionMessages.setNewPasswordSuccess', undefined, { duration: 10 })),
        requestNavigation(loginRootRoutePath, { replace: true }),
    )),
);

export const onRequestPasswordReset = (action$, state$, { authorisation }) => action$.pipe(
    ofType(REQUEST_PASSWORD_RESET),
    switchMap(({ payload }) => from(authorisation.requestPasswordReset(payload)).pipe(
        switchMap((response) => of(requestPasswordResetSuccess(response.data))),
        catchError(() => of(requestPasswordResetFailure())),
    )),
);

export const onRequestPasswordResetSuccess = (action$, _, { http }) => action$.pipe(
    ofType(REQUEST_PASSWORD_RESET_SUCCESS),
    switchMap(() => of(generateNewSessionUuid(http))),
);

export const onGetRememberedUsernameOrEmail = (action$) => action$.pipe(
    ofType(GET_REMEMBERED_USERNAME_OR_EMAIL),
    switchMap(() => {
        const storedUserNameOrEmail = getLocalStorageObjectItem(rememberMeLoginForm);
        if (storedUserNameOrEmail) {
            return of(getRememberedUsernameOrEmailSuccess(storedUserNameOrEmail));
        }

        return of(getRememberedUsernameOrEmailFailure());
    }),
);

export const onGetTokensForDuplicatedTab = (action$, _, { authorisation }) => action$.pipe(
    ofType(GET_TOKENS_FOR_DUPLICATED_TAB),
    switchMap(({ payload }) => from(authorisation.getTokensForDuplicatedSession(payload.requestPayload)).pipe(
        switchMap((response) => of(setCurrentUser({ ...response.data, ...payload.sessionStorageData }, SET_CURRENT_USER_VARIANT.DUPLICATE_TAB))),
        catchError(() => of(clearCurrentUser())),
    )),
);
export const onUnlockUser = (action$, state$, { authorisation, i18n }) => action$.pipe(
    ofType(UNLOCK_LOGIN),
    switchMap(({ payload: { context, extUserUuid } }) => from(authorisation.unlockLogin({ context, extUserUuid })).pipe(
        switchMap(() => {
            const commonActions = [
                unlockUserSuccess(),
                showSuccessToast(i18n.t('common:actionMsg.unlockLoginSuccess')),
            ];
            const refreshAction = createRefreshActions({ context, state$ });

            return of(
                ...commonActions,
                ...refreshAction,
            );
        }),
        catchError(() => of(unlockUserFailure())),
    )),
);

export const onRefreshToken = (action$, state$, { authorisation }) => action$.pipe(
    ofType(REFRESH_TOKEN),
    mergeMap(({ payload: { jwtToken } }) => {
        const permissions = getSessionStorageObjectItem(permissionsKey);
        const configuration = getSessionStorageObjectItem(clientConfigKey);
        const userPreferences = getSessionStorageObjectItem(userPreferencesKey);
        return from(authorisation.extendTokenValidity(jwtToken.refreshToken)).pipe(
            switchMap((response) => of(
                refreshTokenSuccess(),
                setCurrentUser({ ...response.data, permissions, configuration, userPreferences }, SET_CURRENT_USER_VARIANT.REFRESH_SESSION),
            )),
            catchError(() => of(refreshTokenFailure(), clearCurrentUser())),
        );
    }),
);

export const onSendConfirmationCode = (action$, _, { authorisation, i18n }) => action$.pipe(
    ofType(SEND_CONFIRMATION_CODE),
    switchMap(({ payload }) => from(authorisation.sendConfirmationCode(payload)).pipe(
        switchMap((response) => of(
            sendConfirmationCodeSuccess(response.data),
            showSuccessToast(i18n.t('unauthorised:actionMessages.sendConfirmationCodeSuccess')),
        )),
        catchError(() => of(
            sendConfirmationCodeFailure(),
            showErrorToast(i18n.t('unauthorised:actionMessages.sendConfirmationCodeFailure')),
        )),
    )),
);

export default [
    onInitialiseLogin,
    onConfirmLogin,
    onSetNewPassword,
    onSetNewPasswordSuccess,
    onRequestPasswordReset,
    onGetRememberedUsernameOrEmail,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
    onUnlockUser,
    onRefreshToken,
    onSendConfirmationCode,
];
