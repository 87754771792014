import { commonTextEllipsisStyles } from 'assets/styles/commonTextEllipsisStyles';

import { Select } from 'antd';
import styled from 'styled-components';


export const PartnerOption = styled(Select.Option)`
  display: block;
`;
export const PartnerOptionLabelWrapper = styled.span`
  display: block;
`;

export const PartnerOptionLabel = styled.span`
  display: block;
  max-width: 100%;
  ${commonTextEllipsisStyles}
`;
export const PartnerStatus = styled.span`
    display: block;
  
  .ant-tag {
    font-size: 8px;
    padding: 1px 3px;
    line-height: 10px;
  }
`;

export default { PartnerOption, PartnerOptionLabelWrapper, PartnerOptionLabel, PartnerStatus };
