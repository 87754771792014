import PropTypes from 'prop-types';


export const globalLocation = 'GLOBAL';

export const LocationChangeActionTypes = Object.freeze({
    REPLACE: 'REPLACE',
    PUSH: 'PUSH',
    POP: 'POP',
});

export const LocationChangeActionScopeTypes = Object.freeze({
    TAB_CHANGE: 'TAB_CHANGE',
    PAGE_CHANGE: 'PAGE_CHANGE',
});

export const NavigationDirection = Object.freeze({
    BACKWARD: 'BACKWARD',
    FORWARD: 'FORWARD',
    SELF: 'SELF',
});

export const Here = Symbol('Refresh');
export const Back = Symbol('Go back');
export const Forward = Symbol('Go forward');


export const RedirectionToDefaultPathTypes = Object.freeze({
    ROUTER_REDIRECTION_TO_DEFAULT_PATH: 'ROUTER_REDIRECTION_TO_DEFAULT_PATH',
    STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT: 'STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT',
    STANDARD_TABS_ACTIVE_SUB_TAB_REPLACEMENT: 'STANDARD_TABS_ACTIVE_SUB_TAB_REPLACEMENT',
    BROWSER_GO_FORWARD_CLEAN_UP_EDGE_CASE: 'BROWSER_GO_FORWARD_CLEAN_UP_EDGE_CASE',
});

export const EnhancedCurrentLocationProps = PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    action: PropTypes.oneOf(Object.values(LocationChangeActionTypes)),
    direction: PropTypes.oneOf(Object.values(NavigationDirection)),
});

export default {
    LocationChangeActionTypes,
    NavigationDirection,
    RedirectionToDefaultPathTypes,
    EnhancedCurrentLocationProps,
};
