export const FETCH_GROUP_DETAILS = 'FETCH_GROUP_DETAILS';
export const FETCH_GROUP_DETAILS_SUCCESS = 'FETCH_GROUP_DETAILS_SUCCESS';
export const FETCH_GROUP_DETAILS_FAILURE = 'FETCH_GROUP_DETAILS_FAILURE';
export const CLEAR_GROUP_DETAILS = 'CLEAR_GROUP_DETAILS';


export const OPEN_EDIT_GROUP_NAME_MODAL = 'OPEN_EDIT_GROUP_NAME_MODAL';
export const UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME';
export const UPDATE_GROUP_NAME_SUCCESS = 'UPDATE_GROUP_NAME_SUCCESS';
export const UPDATE_GROUP_NAME_FAILURE = 'UPDATE_GROUP_NAME_FAILURE';

export const OPEN_REASSIGN_MEMBER_GROUP = 'OPEN_REASSIGN_MEMBER_GROUP';
export const REASSIGN_MEMBER_GROUP = 'REASSIGN_MEMBER_GROUP';
export const REASSIGN_MEMBER_GROUP_SUCCESS = 'REASSIGN_MEMBER_GROUP_SUCCESS';
export const REASSIGN_MEMBER_GROUP_FAILURE = 'REASSIGN_MEMBER_GROUP_FAILURE';


export const OPEN_ASSIGN_PROMOTIONS_TO_GROUP_MODAL = 'OPEN_ASSIGN_PROMOTIONS_TO_GROUP_MODAL';
export const ASSIGN_PROMOTIONS_TO_GROUP = 'ASSIGN_PROMOTIONS_TO_GROUP';
export const ASSIGN_PROMOTIONS_TO_GROUP_SUCCESS = 'ASSIGN_PROMOTIONS_TO_GROUP_SUCCESS';
export const ASSIGN_PROMOTIONS_TO_GROUP_FAILURE = 'ASSIGN_PROMOTIONS_TO_GROUP_FAILURE';


export const OPEN_DELETE_GROUP_MODAL = 'OPEN_DELETE_GROUP_MODAL';
export const OPEN_DELETE_GROUP_WITH_MEMBERS_RE_ASSIGMENT_MODAL = 'OPEN_DELETE_GROUP_WITH_MEMBERS_RE_ASSIGMENT_MODAL';
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';


export const ADD_MEMBER_TO_GROUP = 'ADD_MEMBER_TO_GROUP';
export const ADD_MEMBER_TO_GROUP_SUCCESS = 'ADD_MEMBER_TO_GROUP_SUCCESS';
export const ADD_MEMBER_TO_GROUP_FAILURE = 'ADD_MEMBER_TO_GROUP_FAILURE';
export const CLEANUP_ADD_MEMBER_TO_GROUP_METADATA = 'CLEANUP_ADD_MEMBER_TO_GROUP_METADATA';
