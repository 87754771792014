import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { fetchCountriesListSuccess, fetchCountriesListFailure } from './actions';
import { FETCH_COUNTRIES_LIST } from './actions.types';


export const onFetchCountriesList = (action$, state$, { countries }) => action$.pipe(
    ofType(FETCH_COUNTRIES_LIST),
    switchMap(({ payload }) => from(countries.fetchCountries(payload.queryParams)).pipe(
        switchMap((response) => of(fetchCountriesListSuccess(
            response.data,
            response?.data?.length,
            payload?.locationPathname,
        ))),
        catchError(() => of(fetchCountriesListFailure(payload?.locationPathname))),
    )),
);


export default [
    onFetchCountriesList,
];
