import { updateAddress } from 'redux/address/actions';
import { setModalProps } from 'redux/modal/actions';
import { navigationReducerName } from 'redux/navigation/reducer';

import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


import EditClientAddressModal from './EditClientAddressModal.layout';


const mapStateToProps = (state) => ({
    enhancedCurrentLocation: state?.[navigationReducerName]?.enhancedCurrentLocation,
//
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetModalProps: (configData) => dispatch(setModalProps(configData)),
    // eslint-disable-next-line max-params
    dispatchUpdateAddress: (addressId, data, addressType, locationPathname) => dispatch(updateAddress(addressId, data, addressType, locationPathname)),
});


const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(EditClientAddressModal);
