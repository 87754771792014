import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_RISK_SCORE_HISTORY_LIST,
    FETCH_RISK_SCORE_HISTORY_LIST,
    FETCH_RISK_SCORE_HISTORY_LIST_FAILURE,
    FETCH_RISK_SCORE_HISTORY_LIST_SUCCESS,
} from './actions.types';


export const fetchRiskScoreHistoryList = createAction(FETCH_RISK_SCORE_HISTORY_LIST, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
export const fetchRiskScoreHistoryListSuccess = createAction(FETCH_RISK_SCORE_HISTORY_LIST_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchRiskScoreHistoryListFailure = createAction(FETCH_RISK_SCORE_HISTORY_LIST_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearRiskScoreHistoryList = createAction(CLEAR_RISK_SCORE_HISTORY_LIST, (locationPathname) => ({ payload: { locationPathname } }));

// export const fetchClientRiskDetails = createAction(FETCH_CLIENT_RISK_DETAILS, (queryParams, locationPathname) => ({ payload: { queryParams, locationPathname } }));
// export const fetchClientRiskDetailsSuccess = createAction(FETCH_CLIENT_RISK_DETAILS_SUCCESS,
//   (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
// export const fetchClientRiskDetailsFailure = createAction(FETCH_CLIENT_RISK_DETAILS_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
// export const clearClientRiskDetailsList = createAction(CLEAR_CLIENT_RISK_DETAILS_LIST, (locationPathname) => ({ payload: { locationPathname } }));
