/* eslint-disable camelcase */
import StandardTag from 'components/common/atoms/StandardTag';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Popover } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';


export function CompanyRiskTag({ score, company }) {
    const handlers = [
        {
            predicate: () => score <= 6,
            handler: () => ({
                color: 'green',
                text: `${score} - LOW`,
            }),
        },
        {
            predicate: () => score > 6 && score <= 11,
            handler: () => ({
                color: 'yellow',
                text: `${score} - MEDIUM`,
            }),
        },
        {
            predicate: () => score > 11 && score <= 16,
            handler: () => ({
                color: 'purple',
                text: `${score} - MODERATE`,
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                text: `${score} - HIGH`,
                color: 'red',
            }),
        },
    ];

    const tagData = handlers.filter(({ predicate }) => predicate())[0].handler();

    return tagData ? (
        <StandardTag
            color={tagData.color}
            text={tagData.text}
            showTooltip={false}
            isTextEllipsisDisabled
            style={company?.total_risk_score > 0 ? { cursor: 'help' } : undefined}
        >
            {company?.id > 0
                ? (
                    <Popover
                        title="Risk Score Details"
                        content={<CompanyRiskTagContent countryRisk={company.country_risk} />}
                    >
                        {tagData.text}
                        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Popover>
                )
                : tagData.text}
        </StandardTag>
    ) : <>-</>;
}

CompanyRiskTag.propTypes = { score: PropTypes.number };


export function CompanyRiskTagContent({ countryRisk }) {
    return (
        <>
            <div>
        Country Of Incorporation:
                {' '}
                <strong>{countryRisk}</strong>
            </div>
            <Divider style={{ margin: '5px 0' }} />
            <div>
        Total score:
                {' '}
                <strong>{countryRisk}</strong>
            </div>
        </>
    );
}

CompanyRiskTagContent.propTypes = { countryRisk: PropTypes.number };

export default CompanyRiskTag;
