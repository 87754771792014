import StandardTag from 'components/common/atoms/StandardTag';
import { CardType } from 'constants/CardModel';

import PropTypes from 'prop-types';
import React from 'react';


const createHandlers = (normalisedCardType, t) => {
    const handlers = [
        {
            predicate: () => normalisedCardType === CardType.PHYSICAL,
            handler: () => ({ color: 'green', text: t('cards:cardFields.type.values.physical') }),
        },
        {
            predicate: () => normalisedCardType === CardType.VIRTUAL,
            handler: () => ({ color: 'geekblue', text: t('cards:cardFields.type.values.virtual') }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: 'Unknown' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


function CardTypeTag({ cardType, t, showTooltip = false }) {
    const normalisedCardType = Number(cardType);
    const tagData = createHandlers(normalisedCardType, t);

    return <StandardTag color={tagData.color} text={tagData.text} showTooltip={showTooltip} />;
}

CardTypeTag.propTypes = {
    t: PropTypes.func.isRequired,
    showTooltip: PropTypes.bool,
    cardType: PropTypes.oneOfType([
        PropTypes.oneOf(Object.values(CardType)),
        PropTypes.bool,
    ]),
};

export default CardTypeTag;

