import FlagAndValue from 'components/common/atoms/FlagAndValue';
import LabelAndValueRow from 'components/common/templates/lists/LabelAndValueRow';
import { amountFormatter } from 'utils/number-tools';
import { commonValidation, handleWhitespaceOnBlur, numberGreaterThan, numberRule, numberSmallerThan } from 'utils/validation-tools';

import {
    Button, Form, Row, Col, Input, InputNumber,
} from 'antd';
import React, { useMemo } from 'react';

import Styled from './TransferDetails.styled';
import { TransferDetailsProps } from './TransferDetails.types';
import BackButton from '../../BackButton';


function TransferDetails({
    t,
    accountDetails,
    form,
}: TransferDetailsProps) {
    const amountValidation = useMemo(
        () => ([
            ...numberRule({ t }),
            ...numberGreaterThan('amount', t, 0),
            ...numberSmallerThan({
                t,
                fieldName: 'amount',
                maxValue: accountDetails?.availableBalance,
            }),
        ]),
        [accountDetails, t],
    );
    return (
        <Col>
            <div style={{ maxWidth: '500px', minHeight: '350px' }}>
                <Form.Item
                    name="amount"
                    label={(
                        <Styled.FieldLabelWithExtraData>
                            {t('transactions:outgoingTransfer.steps.transferDetails.fields.amount.text')}
                            <LabelAndValueRow
                                label={t('transactions:outgoingTransfer.steps.transferDetails.fields.availableBalance.text')}
                                value={(
                                    <FlagAndValue
                                        currencyCode={accountDetails?.currency}
                                        text={`${amountFormatter({ value: accountDetails?.availableBalance })} ${accountDetails?.currency}`}
                                        valueFirst
                                    />
                                )}
                            />
                        </Styled.FieldLabelWithExtraData>
                    )}
                    rules={amountValidation}
                >
                    <InputNumber
                        placeholder={t('transactions:outgoingTransfer.steps.transferDetails.fields.amount.placeholder')}
                        style={{ width: '100%' }}
                    />

                </Form.Item>

                <Form.Item
                    name="reference"
                    label={t('transactions:outgoingTransfer.steps.transferDetails.fields.reference.text')}
                    rules={[...commonValidation({ t, isRequired: true })]}
                >
                    <Input
                        placeholder={t('transactions:outgoingTransfer.steps.transferDetails.fields.reference.placeholder')}
                        onBlur={(e) => handleWhitespaceOnBlur(e, 'reference', form)}
                    />
                </Form.Item>

                <Form.Item
                    name="reason"
                    label={t('transactions:outgoingTransfer.steps.transferDetails.fields.reason.text')}
                    rules={[...commonValidation({ t })]}
                >
                    <Input
                        placeholder={t('transactions:outgoingTransfer.steps.transferDetails.fields.reason.placeholder')}
                        onBlur={(e) => handleWhitespaceOnBlur(e, 'reason', form)}
                    />
                </Form.Item>
            </div>

            <Row key="form buttons" style={{ marginTop: 20 }}>
                <BackButton />
                <Form.Item>
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        {t('common:buttons.next.text')}
                    </Button>
                </Form.Item>
            </Row>
        </Col>
    );
}

export default TransferDetails;
