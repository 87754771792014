import styled from 'styled-components';


const DigitsCodeInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  
  input {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem !important;
    text-align: center;
    caret-color: transparent;
  }
`;

export default { DigitsCodeInputWrapper };
